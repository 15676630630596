import { http } from '../http/axios.instance';
import { IProjectDeliverableReason } from '@models/project-deliverable-reason.model';
import { IQueryParams } from '@models/pagination';
import { StringORNumber } from './utils.repository';

const path = "project-deliverable-reasons"

export const projectDeliverableRepository = {
  getAll: async ({ page, size, sort }: IQueryParams) => {
    const requestUrl = `${path}?cacheBuster=${new Date().getTime()}`;
    return http.get<IProjectDeliverableReason[]>(requestUrl);
  },
  getById: async (id: StringORNumber) => {

    const requestUrl = `${path}/${id}?cacheBuster=${new Date().getTime()}`;
    const promiseGetByID = await http.get<IProjectDeliverableReason>(requestUrl);
    return promiseGetByID;
  },
  create: async (entity: IProjectDeliverableReason): Promise<IProjectDeliverableReason> => {

    const requestUrl = `${path}`;
    const promiseCreate = await http.post<IProjectDeliverableReason>(requestUrl, entity);
    return promiseCreate.data;
  },
  update: async (id: string | number, entity: IProjectDeliverableReason): Promise<IProjectDeliverableReason> => {

    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.put<IProjectDeliverableReason>(requestUrl, entity);
    return promiseUpdate.data;
  },
  delete: async (id: string | number) => {

    const requestUrl = `${path}/${id}`;
    const promiseDelete = await http.delete<IProjectDeliverableReason>(requestUrl);
    return promiseDelete;
  },
  reorder: async (ids: number[]) => {
    const requestUrl = `${path}/reorder`;
    const promiseUpdate = await http.put<IProjectDeliverableReason>(requestUrl, ids);
    return promiseUpdate;
  },
}