import { createAsyncThunk, createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { permisionsService } from '@services/permisions.service';

export interface IUtil {
  data: any,
  loading: boolean,
  errorMessage: any
}

export interface IState extends IUtil {
  data: {
    permisionsList: string[],
    permisionsLoaded: boolean
  }
}

const initialState: IState = {
  data: {
    permisionsList: [],
    permisionsLoaded: false
  },
  loading: false,
  errorMessage: null
};

const sliceName: string = "permisions"

export const getPermisions = createAsyncThunk(
  `${sliceName}/getPermisions`,
  async (_, thunkApi) => {
    const permisions = await permisionsService.getPermisions();
    return permisions;
  }
)

export const PermisionsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reset: (state,) => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getPermisions.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.data.permisionsList = data;
        state.data.permisionsLoaded = true;
        state.errorMessage = null;
        state.loading = false;
      })
      .addMatcher(isPending(getPermisions), state => {
        state.data.permisionsLoaded = false;
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isRejected(getPermisions), (state, action) => {
        state.data.permisionsLoaded = false;
        state.errorMessage = action?.error?.message || null;
        state.loading = false;
      });
  }
});

export const { reset } = PermisionsSlice.actions;

// Reducer
export default PermisionsSlice.reducer;