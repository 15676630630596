/* eslint-disable react-hooks/exhaustive-deps */
import { AlbumListContainerEmpty } from "./AlbumListContainerEmpty";
import { theme } from "antd";
import { useAppSelector } from "@store/store";
import { If } from "@components/Utils/Structural";
import { AlbumListCards } from "./AlbumListCards";
import { useMobileNavigation } from "./PhotoTabLayout";
import { isNumber } from "@shared/util/number-util";

interface IAlbumListContainerProps {}

export const AlbumListContainer = (props: IAlbumListContainerProps) => {

    const { token: { colorBgBase, colorBorderSecondary }} = theme.useToken();

    const { totalItems } = useAppSelector((store) => store.PhotoAlbum);

    const [isMobileNavigation] = useMobileNavigation();
    
    return (
        <div className={`h-full ${isMobileNavigation ? "w-full" : Boolean(!isNumber(totalItems) || (totalItems === 0)) ? "w-full" : "" }`}>   
            
            <If condition={Boolean(isNumber(totalItems) && (totalItems! > 0))}>
                <div 
                    className="h-full pt-20 pb-20 overflow-y-scroll" 
                    style={{ 
                        background: colorBgBase, 
                        width: isMobileNavigation ? "100vw": "380px", 
                        borderRight: `1px solid ${colorBorderSecondary}`
                    }} 
                >
                    <AlbumListCards />
                </div>
            </If>

            <If condition={Boolean(!isNumber(totalItems) || (totalItems === 0))}>
                <div className="h-full pt-20 w-full" style={{ background: colorBgBase, width: "100%" }}>
                    <AlbumListContainerEmpty />
                </div>
            </If>

        </div>
    )
}
