import { configuration } from "../environments/env";
import { createContext, useContext, useEffect, useState } from "react";
import { AnalyticsService } from "@services/analytics.service";

interface IAnalyticsContext {
    analyticsInstance: AnalyticsService | null,
}
export const AnalyticsContext = createContext({} as IAnalyticsContext);

export const AnalyticsProvider = ({ children }: any) => {

    const [analyticsInstance, setAnalyticsInstance] = useState(null);

    useEffect(() => {
        loadAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadAnalytics = () => {

        if (!configuration.GOOGLE_ANALYTICS_API_KEY) return;

        const AnalyticsInstance = new AnalyticsService(configuration.GOOGLE_ANALYTICS_API_KEY);

        AnalyticsInstance.load()

        if (AnalyticsInstance.isLoaded()) {
            setAnalyticsInstance(analyticsInstance)
        }
    }

    const value = {
        analyticsInstance
    }

    return (
        <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>
    )
};

export const useAnalyticsProvider = () => {
    return useContext(AnalyticsContext);
};