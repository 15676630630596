import { useEffect, useState } from 'react';
import { EmptyLabel } from '@components/Utils/EmptyLabel';
import { Row, Col, Typography, theme } from 'antd';
import { AppDispatch, useAppSelector } from '@store/store';
import { useDispatch } from 'react-redux';
import { formatDatePipe } from '@shared/util/date-utils';
import { DATE_FORMAT_FULL_NAME_MONTH } from '@shared/util/date-utils';
import { useProviderColor } from '@HOOKs/UseProviderColor';
import { SECONDARY_TEXT_COLOR } from '@providers/ThemeProvider';
import { SiteMenu } from '@components/Locations/Menu/SiteMenu';
import { useTranslation } from 'react-i18next';
import { If } from '@components/Utils/Structural';
import { getEntities as getBrands } from '@store/slices/brand';

export const SiteOverviewHeaderTab = () => {
  const {
    token: { marginLG, fontSizeHeading5 },
  } = theme.useToken();
  const { t } = useTranslation();
  const { entity: siteEntity } = useAppSelector(state => state.Sites);
  const { entity: locationEntity } = useAppSelector(state => state.Location);

  const dispatch = useDispatch<AppDispatch>();
  const brands = useAppSelector(state => state.Brand.entities);
  const [singularBrand, setSingularBrand] = useState<boolean>(false);
  useEffect(() => {
    if (brands.length === 0) {
      dispatch(getBrands({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSingularBrand(brands.length === 1);
  }, [brands]);
  const [colorTextSecondary] = useProviderColor(SECONDARY_TEXT_COLOR);

  const padding = marginLG * 2;

  return (
    <Row style={{ paddingLeft: `${padding}px`, paddingRight: `${padding}px` }} className="pt-20 pb-20 w-full">
      <Col span={12}>
        <div className="w-full flex flex-row pb-20">
          <div className="flex flex-col pr-20">
            <span style={{ color: colorTextSecondary }}>{t('site.openingDate')}:</span>
            <Typography.Title level={5} className="font-bold pt-10" style={{ fontSize: fontSizeHeading5 }}>
              {(siteEntity?.openDate && formatDatePipe(siteEntity?.openDate, DATE_FORMAT_FULL_NAME_MONTH)) || <EmptyLabel />}
            </Typography.Title>
          </div>
          <If condition={Boolean(siteEntity?.closeDate)}>
            <div className="flex flex-col pr-20">
              <span style={{ color: colorTextSecondary }}>{t('site.closingDate')}:</span>
              <Typography.Title level={5} className="font-bold pt-10" style={{ fontSize: fontSizeHeading5 }}>
                {(siteEntity?.closeDate && formatDatePipe(siteEntity?.closeDate, DATE_FORMAT_FULL_NAME_MONTH)) || <EmptyLabel />}
              </Typography.Title>
            </div>
          </If>
          <div className="flex flex-col pl-20">
            <span style={{ color: colorTextSecondary }}>Status:</span>
            <Typography.Title level={5} className="font-bold pt-10" style={{ fontSize: fontSizeHeading5 }}>
              {siteEntity?.status?.description || <EmptyLabel />}
            </Typography.Title>
          </div>
          <div className="flex flex-row items-center pl-20">
            <div className="w-min ml-20">
              <SiteMenu site={siteEntity} />
            </div>
          </div>
        </div>
      </Col>
      <Col span={12}>
        <div className="w-full flex flex-row justify-end pb-20">
          <div className="flex flex-col pr-40">
            <span className="font-bold">{t('location.locationNumber')}:</span>
            <span style={{ color: colorTextSecondary }} className="pt-10">
              {locationEntity.locationNumber || <EmptyLabel />}
            </span>
          </div>
          <If condition={Boolean(!singularBrand)}>
            <div className="flex flex-col pr-40">
              <span className="font-bold">{t('location.brandName')}:</span>
              <span style={{ color: colorTextSecondary }} className="pt-10">
                {locationEntity.brand?.description || <EmptyLabel />}
              </span>
            </div>
          </If>
          <div className="flex flex-col pr-10">
            <span className="font-bold">{t('location.market')}:</span>
            <span style={{ color: colorTextSecondary }} className="pt-10">
              {locationEntity.market?.description || <EmptyLabel />}
            </span>
          </div>
        </div>
      </Col>
    </Row>
  );
};
