import { theme, Tooltip } from 'antd';
interface ProjectTag {
  tagLabel: string;
  tagValue: string;
}
interface ProjectTagsProps {
  projectTags: ProjectTag[];
}

const ProjectTags = ({ projectTags }: ProjectTagsProps) => {
  const {
    token: { colorFillSecondary, colorTextSecondary },
  } = theme.useToken();

  return (
    <div className="flex content-center">
      {projectTags.map((tag, index) => (
        <Tooltip placement="top" title={tag.tagLabel}>
          <span
            key={index}
            className="ml-10 mr-5 content-center items-center"
            style={{
              maxHeight: '1.75rem',
              backgroundColor: colorFillSecondary,
              border: `1px solid ${colorFillSecondary}`,
              borderRadius: '0.25rem',
              display: 'inline-flex',
            }}
          >
            <div
              className="pl-10 pr-10 content-center items-center"
              style={{
                color: `${colorTextSecondary} !important`,
                fontSize: '0.75rem',
                fontWeight: '400',
              }}
            >
              {tag.tagValue}
            </div>
          </span>
        </Tooltip>
      ))}
    </div>
  );
};

export default ProjectTags;
