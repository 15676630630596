import usePermisions, { ENTITIES, IEntities } from "@HOOKs/UsePermisions";
import { FeatureFlagOptionEnum } from "@models/enumerations/feature-flag-option.enum";
import { useAuth } from "@providers/AuthProvider";
import { useFeatureFlagsProvider } from "@providers/FeatureFlagsProvider";
import { isArray } from "@shared/util/array-util";
import { getKey } from "@shared/util/enum-util";
import { createContext, useContext, useEffect, useState } from "react";

interface IRevealContext {
    isPermisionsLoaded: boolean;
    canEditDashboard: () => boolean;
}

export const RevealContext = createContext({} as IRevealContext);

export const RevealContextProvider = ({ children }: any) => {

    const { can, permisions } = usePermisions();
    
    const { flags, isFlagEnabled } = useFeatureFlagsProvider();

    const { isAdmin } = useAuth();

    const canEditDashboard = () : boolean => {

        if (!isFlagEnabled(FeatureFlagOptionEnum.ACL_EVALUATOR)) { 
            const canIEditDashboard = isAdmin?.();
            return Boolean(canIEditDashboard);
        } else {
            const keyEntity = getKey({ obj: ENTITIES, value: ENTITIES.BI_DASHBOARD }) as IEntities;
            const canIEditDashboard = can({ I: "EDIT", A: keyEntity as IEntities });
            return Boolean(canIEditDashboard);
        }
    }

    const [isPermisionsLoaded, setIsPermisionsLoaded] = useState(false);

    useEffect(() => {
        if (permisions !== null && isArray(flags)) {
            setIsPermisionsLoaded(true);
        }
    }, [flags, permisions])

    const value = {
        isPermisionsLoaded,
        canEditDashboard
    }

    return (
        <RevealContext.Provider value={value}>{children}</RevealContext.Provider>
    )
};

export const useRevealContextProvider = () => {
    return useContext(RevealContext);
};