import { Button, Col, Row, Space } from "antd";
import Title from "antd/es/typography/Title";
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@store/store';
import { getEntities } from '@store/slices/setting';
import { useEffect } from 'react';

export const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntities());
  }, [dispatch]);

  return (
    <>
      <Row justify="space-between" className="mb-20">
        <Col span={12}>
          <Space size={12}>
            <Title level={4} className="imb-0">
              Settings
            </Title>
          </Space>
        </Col>
      </Row>

      <Row className="mb-15">
        <Space>Api Access:</Space>
        <Button className="ml-10" onClick={() => navigate('/settings/api-access')}>
          Manage
        </Button>
      </Row>
    </>
  );
};
