import { IFeatureFlagEvalResult } from "@models/feature-flag-eval-result.model";
import { isLoggedIn } from "@services/auth.service";
import { getEntities as getFlags } from '@store/slices/feature-flag-evaluation';
import { useAppDispatch, useAppSelector } from "@store/store";
import { createContext, useContext, useEffect } from "react";
import { useAuth } from "./AuthProvider";
import { FeatureFlagOptionEnum } from "@models/enumerations/feature-flag-option.enum";

interface IFeatureFlagsContext {
    flags: IFeatureFlagEvalResult[] | undefined,
    isFlagEnabled: (flag: FeatureFlagOptionEnum) => Boolean | undefined;
}

export const FeatureFlagsContext = createContext({} as IFeatureFlagsContext);

export const FeatureFlagsProvider = ({ children }: any) => {
    const dispatch = useAppDispatch();
    const { flags } = useAppSelector((state) => state.FeatureFlagEvaluation);
    const { user } = useAuth();

    useEffect(() => {
        if (user && isLoggedIn() === true) {
            dispatch(getFlags());
        }
    }, [user, dispatch])

    const value = {
        flags: flags,
        isFlagEnabled(flag: FeatureFlagOptionEnum): Boolean | undefined {
            if (!flags) return undefined;
            const flagList = flags.filter(item => item.featureKey === flag);
            if (flagList.length > 0) {
                return flagList[0].evalResult;
            } else {
                return undefined;
            }
        },
    }

    return <FeatureFlagsContext.Provider value={value}>{children}</FeatureFlagsContext.Provider>;
}

export const useFeatureFlagsProvider = (): IFeatureFlagsContext => {
    return useContext(FeatureFlagsContext) as IFeatureFlagsContext;
};
