import { EnvelopeSvgIcon } from "@components/Icons/EnvelopeSgvIcon";
import { NEUTRAL_6_COLOR, SITE_RISE_OFF_WHITE } from "@providers/ThemeProvider";
import { theme } from "antd";

export const FooterChangePassword = () => {

    const { token: { fontSize, fontSizeSM } } = theme.useToken();
    
    return (
        <div className="flex flex-col items-center justify-center">
            <div style={{ fontSize: fontSize -  2, textAlign: 'center' }} className="pt-40 pb-30">
            <i>Adding complexity to complex problems is easy. Simplifying a complex problem is difficult.</i>
            </div> 
            <div className="flex flex-col items-center justify-center w-full" style={{ backgroundColor: SITE_RISE_OFF_WHITE }}>
                <div className="pb-15 pt-20" >
                    <img src={"/media/client/siterise/powered_by_siterise_dark.svg"} alt="" className='block'/>
                </div>
                <div className="pb-5"> 
                    <p className="text-color-neutral-10 font-light mb-0" style={{ fontSize: fontSizeSM }} >For support contact us via email:</p>
                </div>
                <div className="flex flex-row items-center justify-center pb-20 pb-5"> 
                    <EnvelopeSvgIcon style={{ color: NEUTRAL_6_COLOR }} className="mr-5"/>
                    <p className="font-medium mb-0" style={{ fontSize: fontSizeSM }}>
                        <a className="no-underline hover-text-current text-color-neutral-10" href="mailto: support@siterise.com">support@siterise.com</a>  
                    </p>
                </div>  
            </div>
        </div>
    )
}
