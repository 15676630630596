import { MAX_RESULT_AUDIT_HISTORY_DEFAULT } from "@constants/core";
import { AuditHistoryEntities } from "@models/enumerations/audit-history-entities-enum.model";
import { IPunchListItem } from "@models/punch-list.model";
import { getPunchListItemHistory, showAuditHistory } from "@store/slices/audit-history";
import { asyncLaunchNotification } from "@store/slices/notification";
import { useAppDispatch } from "@store/store";
import { theme } from "antd";

interface IViewHistoryChangesLinkPunchListItemProps {
  punchListItem: IPunchListItem
}

export const ViewHistoryChangesLinkPunchListItem = (props: IViewHistoryChangesLinkPunchListItemProps) => {

    const { 
        punchListItem
    } = props;

    const { token: { colorPrimaryText } } = theme.useToken();

    const dispatch = useAppDispatch();

    const onShowHistory = () => {
        dispatch(getPunchListItemHistory({
            referenceId: String(punchListItem.id),
            referenceType: AuditHistoryEntities.PUNCH_LIST_ITEM,
            entityLabel: 'Punch List Item',
            entityTranslation: 'punchListItem',
            entityNameToObserve: String(`#${punchListItem?.punchlistNumber || ""}`),
            lastFetchQueryParams: {
                maxResults: MAX_RESULT_AUDIT_HISTORY_DEFAULT,
                newestFirst: true
            },
            repositoryMethod: "getPunchListItemHistory"
        }))
            .unwrap()
            .then(() => dispatch(showAuditHistory({ showHistory: true })) )
            .catch(() => {
                dispatch(asyncLaunchNotification({
                    type: "error",
                    config: {
                        message: `Audit History`,
                        description: `There was an error trying to get the Audit History, please try again later.`
                    }
                }));
            })
        
    }
    
    return (
        <div className="pr-8 pl-8" style={{ color: colorPrimaryText }}>
            <span className="underline cursor-pointer" onClick={onShowHistory}>
                View History
            </span>
        </div>
    )
}
