import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { EntityState, createEntitySlice, serializeAxiosError } from '@store/reducer.util';
import { IBudgetApprovalRangeSetting, IBudgetApprovalRangeSettingSave } from '@models/budget-approval-range-setting.model';
import { defaultValue } from '@models/budget-approver-setting.model';
import { budgetApprovalRangeSettingService } from '@services/budget-approval-range-setting.service';


const initialState: EntityState<IBudgetApprovalRangeSetting> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

// Actions

export const getEntities = createAsyncThunk('budgetApprovalRangeSetting/fetch_entity_list_extended', async () => {
  return await budgetApprovalRangeSettingService.getBudgetApprovalRangeSettingsWithApprovers();
});

export const saveComplete = createAsyncThunk(
  'budgetApprovalRangeSetting/save_complete',
  async (entity: IBudgetApprovalRangeSettingSave, thunkAPI) => {
    const result = await budgetApprovalRangeSettingService.saveComplete(entity);
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const BudgetApprovalRangeSettingExtendedSlice = createEntitySlice({
  name: 'budgetApprovalRangeSettingExtended',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: data.length,
        };
      })
      .addMatcher(isFulfilled(saveComplete), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(saveComplete), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = BudgetApprovalRangeSettingExtendedSlice.actions;

// Reducer
export default BudgetApprovalRangeSettingExtendedSlice.reducer;
