import { IResultMatch } from "@models/search";
import { theme } from "antd";
import React, { useEffect, useState } from "react";

interface IResultHighlightedStringProps {
    matchText: IResultMatch
}

export const ResultHighlightedString = (props: IResultHighlightedStringProps) => {

    const { matchText } = props;

    const { token: { colorPrimaryTextActive } } = theme.useToken();

    const [beforeHightLight, setBeforeHightLight] = useState("")
    const [hightLight, setHightLight] = useState("")
    const [afterHighLight, setAfterHighLight] = useState("")

    useEffect(() => {
        matchText.body && processMatch(matchText);
    }, [matchText]);

    const processMatch = (matchText: IResultMatch) => {
        setBeforeHightLight(matchText.body.substring(0, matchText.highlightStart - 1));
        setHightLight(matchText.body.substring(matchText.highlightStart - 1, (matchText.highlightStart + matchText.highlightLen - 1) ));
        setAfterHighLight(matchText.body.substring(matchText.highlightStart - 1 + matchText.highlightLen));
    }
    
    return (
        <span>{beforeHightLight}<b style={{ color: colorPrimaryTextActive, fontWeight: 500 }}>{hightLight}</b>{afterHighLight}</span>
    )
}
