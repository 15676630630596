import { useLayoutEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import { configuration } from '../environments/env';
import { useLayoutContex } from '@providers/LayoutProvider';
import { MainHeader } from '@components/Layout/HeaderLayout/MainHeader';
import { usePageContentSize } from '@HOOKs/UsePageContentSize';

export const MarkupPage = () => {
  const PageContent = () => {
    const { styleContent, setOldStylePropertiesInContentLayout, setHeaderLabelEntityState } = useLayoutContex();

    useLayoutEffect(() => {
      setOldStylePropertiesInContentLayout && setOldStylePropertiesInContentLayout();
      setHeaderLabelEntityState && setHeaderLabelEntityState('');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [layoutRef] = usePageContentSize();

    return (
      <Layout>
        <MainHeader />
        <Content ref={layoutRef} style={{ ...styleContent, overflow: 'scroll' }}>
          <Outlet />
        </Content>
      </Layout>
    );
  };

  const Page = () => {
    let { t } = useTranslation();
    return (
      <>
        <Helmet>
          <title>
            {t('markup.title')} | {configuration.APP_NAME}
          </title>
        </Helmet>
        <PageContent />
      </>
    );
  };

  return <Page />;
};

export default MarkupPage;
