import { IPhotoAlbum } from "@models/photo-album.model";
import { Card, Col, Row, Tooltip, Typography, theme } from "antd";
import { ThumbnailContainer } from "./ThumbnailContainer";
import { CustomButtonText } from "@components/Utils/CustomButtonText";
import { ChevronRightSvgIcon } from "@components/Icons/ChevronRightSvgIcon";
import { useAppDispatch } from "@store/store";
import { getEntityById } from "@store/slices/photo-album";
import { useSearchParams } from "react-router-dom";
import { WHITE_LIGHT, useThemeProvider } from "@providers/ThemeProvider";
import { formatDatePipe, formatFullDatetimeWhitoutDayName } from "@shared/util/date-utils";
import { reset } from "@store/slices/generic-album-gallery-multimedia";
import { getPhotosByReferenceTypeAndReferenceId } from "@store/slices/generic-album-gallery-multimedia";
import { AttachmentReferenceEnum } from "@models/enumerations/attachment-reference-enum.model";
import { PAGINATION_SIZE_DEFAULT_IN_PHOTOS } from "@constants/core";

const { Title } = Typography;

interface IAlbumCardProps { 
    album: IPhotoAlbum
}

export const NAVIGATION_KEY_URL_PARAM = "navigation";

export const AlbumCard = (props: IAlbumCardProps) => {

    const { album } = props;

    const dispatch = useAppDispatch()

    const { themeConfig } = useThemeProvider();
    const { token: { colorPrimaryHover, padding, colorBgElevated, colorBgContainer }} = theme.useToken();

    let [searchURLParams, setURLSearchParams] = useSearchParams();
    
    const viewAlbumDetails = () => {

      if (searchURLParams.get("albumSelected") !== String(album.id)) {
        dispatch(reset());
      }

      dispatch(getEntityById(String(album.id)));
      
      dispatch(getPhotosByReferenceTypeAndReferenceId({
        referenceId: String(album.id),
        referenceType: AttachmentReferenceEnum.PHOTO_ALBUM,
        page: 0,
        size: PAGINATION_SIZE_DEFAULT_IN_PHOTOS 
      }));

      setURLSearchParams((prev) => {
        prev.set("albumSelected", String(album.id))
        return prev;
      });

      if (searchURLParams.get(NAVIGATION_KEY_URL_PARAM) === "mobile") {
        setURLSearchParams((prev) => {
          prev.set("view", "details")
          return prev;
        })
      }

    }
  
    return (
      <div  className="mt-15">
        <Card
          style={{ 
            border: `1px solid ${colorPrimaryHover}`, 
            borderRadius: 8,
            background: (searchURLParams.get("albumSelected") === String(album.id)) ? themeConfig.darkMode ? colorBgElevated : WHITE_LIGHT : colorBgContainer, 
            outline: (searchURLParams.get("albumSelected") === String(album.id)) ? `1.5px solid ${colorPrimaryHover}` : `0px solid transparent`, 
          }}
          styles={{ body: { padding }}}
          hoverable={true}
          onClick={viewAlbumDetails}
        >
          <Row wrap={false}>
            <Col flex="auto">
                <Title 
                  className="text-color-neutral-8 mt-0 imb-0 pr-12" 
                  level={5} 
                  style={{ fontWeight: 400 }} 
                  ellipsis={true}
                >
              
                    {album.name}
                </Title>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <ThumbnailContainer album={album} />
            </Col>
          </Row>

          <Row justify={"space-between"} className="items-center">
            <Col flex={"none"} className="items-center">
              <Tooltip title={formatFullDatetimeWhitoutDayName(album?.albumDate)}>
                <span className="text-color-neutral-6 mr-10">
                  { formatDatePipe(album?.albumDate) }
                </span>
              </Tooltip>
            </Col>
            <Col flex={"none"}>
              <CustomButtonText onClick={viewAlbumDetails}> 
                <ChevronRightSvgIcon className="mr-10" />
                View More
              </CustomButtonText>
            </Col>
          </Row>
          
        </Card>
      </div>
    )
}
