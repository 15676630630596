import { FeatureFlagOptionEnum } from '@models/enumerations/feature-flag-option.enum';
import { http } from '../http/axios.instance';
import { IFeatureFlagEvalResult } from '@models/feature-flag-eval-result.model';
import { MARKUP_FEATURE_FLAG_ENABLED } from '@constants/core';

const path = 'eval-feature-flags';

export const featureFlagEvaluationRepository = {
  getAll: async () => {
    const requestUrl = `${path}?cacheBuster=${new Date().getTime()}`;
    let promiseGetAll = await http.get<IFeatureFlagEvalResult[]>(requestUrl).then(response => {
      const markupFeatureFlag: IFeatureFlagEvalResult = {
        featureKey: FeatureFlagOptionEnum.MARKUP,
        evalResult: MARKUP_FEATURE_FLAG_ENABLED,
      };
      response.data.push(markupFeatureFlag);
      return response;
    });
    return promiseGetAll;
  },
};
