import { IMarket } from '../../models/market.model';
import { IQueryParams } from '../../models/pagination';
import { http } from '../http/axios.instance';
import { StringORNumber } from './utils.repository';

const path = 'markets';

export const marketRepository = {
  getMarkets: async (queryParams: IQueryParams) => {
    
    const { sort, page, size } = queryParams;
    const requestUrl = `${path}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  
    const markets = await http.get<IMarket[]>(requestUrl);
    return markets;
  },
  getMarketById: async (id: StringORNumber) => {

    const requestUrl = `${path}/${id}`;
    const market = await http.get<IMarket>(requestUrl);
    return market;
  },
  createMarket: async (entity: IMarket): Promise<IMarket> => {

    const requestUrl = `${path}`;
    const market = await http.post<IMarket>(requestUrl, entity);
    return market.data;
  },
  updateMarket: async (id: string | number, entity: IMarket): Promise<IMarket> => {

    const requestUrl = `${path}/${id}`;
    const market = await http.put<IMarket>(requestUrl, entity);
    return market.data;
  },
  deleteMarket: async (id: string | number) => {

    const requestUrl = `${path}/${id}`;
    const market = await http.delete<IMarket>(requestUrl);
    return market;
  },
}