import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IProjectCreationForm, goToProject } from './../../models/project-template-model';
import { applyTemplateToCreateAProject } from './project-templates-extended';

import { RootState } from "../store";

export enum Steps {
  STEP1 = 1,
  STEP2 = 2,
  STEP3 = 3,
  STEP4 = 4,
}

const FIRST_STEP = 1;
const LAST_STEP = 4;

export interface IValidateStep {
  isValid?: boolean
}

export interface IStepProp extends IValidateStep {
  on?: {
    next?: number,
    prev?: number
  }
}

export type IStepNumber = Steps.STEP1 | Steps.STEP2 | Steps.STEP3 | Steps.STEP4

export interface IProjectSteps {
  steps: {
    [Steps.STEP1]: IStepProp,
    [Steps.STEP2]: IStepProp,
    [Steps.STEP3]: IStepProp,
    [Steps.STEP4]: IStepProp,
  }
  currentStep: number,
  lastStep: number,
  isComplete: boolean,
  isInProcess: boolean
  isLastStep: boolean,
  stepInProgress: IStepProp,
  siteId?: number,
  projectToCreate: IProjectCreationForm
}

export interface IPayloadUpdateStep {
  step: IStepNumber,
  modification: IValidateStep
}

export const asyncFinishProcessAddProject = createAsyncThunk(
  'projectSteps/asyncFinishProcessAddProject',
  async ({ goToProject }: { goToProject: goToProject }, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { projectToCreate, siteId } = state.ProjectSteps;

    const result = await thunkApi.dispatch(applyTemplateToCreateAProject({ siteId: siteId!, body: projectToCreate, goToProject: goToProject }));
    thunkApi.dispatch(reset())
    
    return result;
  }
)


const INITIAL_STEP = {
  on: {
    next: Steps.STEP2,
    prev: Steps.STEP1
  },
  isValid: false
}

const initialState: IProjectSteps = {
  steps: {
    [Steps.STEP1]: INITIAL_STEP,
    [Steps.STEP2]: {
      on: {
        next: Steps.STEP3,
        prev: Steps.STEP1
      },
      isValid: false
    },
    [Steps.STEP3]: {
      on: {
        next: Steps.STEP4,
        prev: Steps.STEP2
      },
      isValid: false
    },
    [Steps.STEP4]: {
      on: {
        next: FIRST_STEP,
        prev: Steps.STEP3
      },
      isValid: true
    }
  },
  currentStep: FIRST_STEP,
  lastStep: LAST_STEP,
  isComplete: false,
  isInProcess: false,
  stepInProgress: INITIAL_STEP,
  isLastStep: Number(FIRST_STEP) === Number(LAST_STEP),
  siteId: undefined,
  projectToCreate: {
    projectTypeId: null,
    projectStatusId: null,
    templateId: null,
    roleBindingList: []
  }
}

export const ProjectSteps = createSlice({
  name: 'projectSteps',
  initialState,
  reducers: {
    setValidStep: (state, action) => {
      const { step, modification } = action.payload as IPayloadUpdateStep;
      const currentState = { ...state };
      const currentStep = currentState.steps[step] as IStepProp;
      currentStep.isValid = Boolean(modification.isValid) || false;
      state = { ...currentState };
    },
    completeProcess: (state, action) => {
      state.isComplete = action.payload;
    },
    nextStep: (state) => {
      if (state.stepInProgress?.on?.next) {
        state.currentStep = state.stepInProgress?.on?.next;
      }
    },
    prevStep: (state) => {
      if (state.stepInProgress?.on?.prev) {
        state.currentStep = state.stepInProgress?.on?.prev;
      }
    },
    goFirstStep: (state) => {
      state.currentStep = FIRST_STEP;
    },
    setProjectToCreate: (state, action) => {
      state.projectToCreate = {
        ...state.projectToCreate,
        ...action.payload
      }
      return state
    },
    assignUserToRole: (state, action) => {
      const roleUserPair = {
        "user": {
          "id": action.payload.selectedUserId
        },
        "role": {
          "id": action.payload.roleId
        }
      };
      const oldCollection = state.projectToCreate.roleBindingList
      const newCollection = oldCollection.filter(item => item.role.id !== action.payload.roleId)
      newCollection.push(roleUserPair)
      state.projectToCreate.roleBindingList = newCollection
    },
    clearAssignUserToRole: (state, action) => {
      const oldCollection = state.projectToCreate.roleBindingList
      const newCollection = oldCollection.filter(item => item.role.id !== action.payload)
      state.projectToCreate.roleBindingList = newCollection
    },
    setSiteId: (state, action) => {
      state.siteId = action.payload
    },
    reset: (state) => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder.addMatcher((action) => action.type.startsWith('projectSteps'), (state, action) => {
      state.isInProcess = state.currentStep > Steps.STEP1;
      const step = state.currentStep as IStepNumber;
      state.stepInProgress = state.steps[step];
      state.isLastStep = state.currentStep === state.lastStep;
    });
  },
});

export const { setValidStep, setProjectToCreate, completeProcess, nextStep, prevStep, goFirstStep, reset, assignUserToRole, clearAssignUserToRole, setSiteId } = ProjectSteps.actions;

// Reducer
export default ProjectSteps.reducer;