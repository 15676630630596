import React, { SVGProps } from 'react' 

type EnvelopeSvgIconProps = SVGProps<SVGSVGElement>

export const EnvelopeSvgIcon = (props: EnvelopeSvgIconProps) => {

    const { ...restProps } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none" {...restProps}>
            <rect x="1.5" y="0.699219" width="11" height="7.8" stroke="currentColor"/>
            <path d="M12.5469 0.951563L6.99687 6.35156L1.44688 0.951562" stroke="currentColor"/>
            <path d="M1.29688 8.54687L5.04687 4.79688" stroke="currentColor"/>
            <path d="M12.7031 8.54687L8.95312 4.79688" stroke="currentColor"/>
        </svg>
    );
};
  