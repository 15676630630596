import { EditOutlined } from "@ant-design/icons";
import { CustomButtonLink } from "@components/Utils/CustomButtonLink";
import { useTranslation } from "react-i18next";
import { If } from "@components/Utils/Structural";
import { MetadataMarkerDrawer } from "../MatadataMarkerDrawer/MetadataMarkerDrawer";
import { IPunchListItem } from "@models/punch-list.model";
import { IAsset } from "../../FloorPlanEditor/interfaces/interfaces";
import { useAppDispatch } from "@store/store";
import { getPuchListItemById, selectPunchListItem } from '@store/slices/punch-list';
import useVisible from "@HOOKs/UseVisible";

interface IEditPunchListItemButtonProps {
    assetSelected?: IAsset | null;
}

export const EditPunchListItemButton = (props: IEditPunchListItemButtonProps) => {

    const { assetSelected } = props;

    const dispatch = useAppDispatch();

    const [visibleDrawerEditPunchListItem, toggleVisibleDrawerEditPunchListItem] = useVisible();

    const { t } = useTranslation();

    const onEditPunchListItem = () => { 
        const punchListItem = assetSelected?.data as IPunchListItem;
        punchListItem && dispatch(selectPunchListItem(punchListItem!));
        toggleVisibleDrawerEditPunchListItem();
    }

    const onEditSuccess = (punchListItemModified) => {
        dispatch(getPuchListItemById(String(punchListItemModified.id)));
    }

    return (
        <>
            <CustomButtonLink onClick={onEditPunchListItem} style={{ paddingRight: 0, paddingLeft: 0}}>
                <EditOutlined />
                {t("generic.edit")}
            </CustomButtonLink>
            <If condition={visibleDrawerEditPunchListItem && !!assetSelected}>
                <MetadataMarkerDrawer 
                    open={visibleDrawerEditPunchListItem} 
                    toogle={toggleVisibleDrawerEditPunchListItem} 
                    destroyAssets={() => {}} 
                    asset={assetSelected!}
                    onEditSuccess={onEditSuccess}
                />
            </If>
        </>
    )
}
