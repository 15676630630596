
import { ISetting } from '@models/setting.model';
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { settingsService } from '@services/settings.service';
import { cleanEntity } from '@shared/util/entity-utils';
import { serializeGenericHandleError } from '@store/thunk.util';

export const getEntities = createAsyncThunk(
    'setting/fetch_entity_list',
    async () => {
        return settingsService.getAllSettings();
    },
    { serializeError: serializeGenericHandleError }
);

export interface IUpdateSettingArgs {
    entity: ISetting,
    onSuccess?: () => void
}

export interface IUpdateSettingsArgs {
    entities: ISetting[],
    onSuccess?: () => void
}

export const updateEntity = createAsyncThunk(
    'setting/update_entity',
    async ({ entity, onSuccess }: IUpdateSettingArgs, thunkAPI) => {
        const result = entity.id && await settingsService.updateSetting(entity.id, cleanEntity(entity));
        onSuccess?.();
        return result;
    },
    { serializeError: serializeGenericHandleError }
);

export const updateEntities = createAsyncThunk(
    'setting/update_entities',
    async ({ entities, onSuccess }: IUpdateSettingsArgs, thunkAPI) => {
        for (let k = 0; k < entities.length; k++) {
            const entity = entities[k];
            if (entity.id)
                await settingsService.updateSetting(entity.id, cleanEntity(entity));
        }
        onSuccess?.();
        return;
    },
    { serializeError: serializeGenericHandleError }
);

interface ISettingState {
    loading: boolean;
    errorMessage: string | null;
    updating: boolean;
    updateSuccess: boolean;
    entities: ReadonlyArray<ISetting>;
}

const initialState: ISettingState = {
    loading: false,
    errorMessage: null,
    updating: false,
    updateSuccess: false,
    entities: []
};

export const SettingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        reset: (state,) => {
            return initialState;
        },
    },
    extraReducers(builder) {
        builder
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const { data } = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data
                };
            })
            .addMatcher(isFulfilled(updateEntity), (state, action) => {
                state.updating = false;
                state.loading = false;
                state.updateSuccess = true;
            })
            .addMatcher(isPending(getEntities), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
            .addMatcher(isPending(updateEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.updating = true;
            });
    }
});

export const { reset } = SettingSlice.actions;

// Reducer
export default SettingSlice.reducer;

