import { InfoCircleOutlined } from "@ant-design/icons";
import { ICurrency } from "@models/currency.model";
import { Col, Row, Tooltip, theme } from "antd";
import { useTranslation } from "react-i18next";

export interface IListHeaderProps {
    currency: ICurrency | undefined
}

export const ListHeader = (props: IListHeaderProps) => {

    const { currency } = props;
    const { token: { colorTextTertiary, colorFillQuaternary } } = theme.useToken();
    const { t } = useTranslation();

    return (
        <Row className='ml-25 pl-15 font-bold' style={{ backgroundColor: colorFillQuaternary, lineHeight: "28px" }}>
            <Col span={5}>
                {t("changeOrder.costsDetail.csiDivisionTitle")}
            </Col>
            <Col span={5}>
                {t("changeOrder.costsDetail.csiSubdivisionTitle")}
            </Col>
            <Col span={7}>
                {t("changeOrder.costsDetail.descriptionTitle")}
            </Col>
            <Col span={3}>
                {t("changeOrder.costsDetail.qtyTitle")}
            </Col>
            <Col span={3}>
                {t("changeOrder.costsDetail.costTitle")} <Tooltip title={"Currency: " + currency?.name}><InfoCircleOutlined style={{ color: colorTextTertiary }} /></Tooltip>
            </Col>
            <Col span={1}>
            </Col>
        </Row>
    );
}