/* eslint-disable react-hooks/exhaustive-deps */
import { AttachmentReferenceEnum } from "@models/enumerations/attachment-reference-enum.model";
import { IPunchListItem } from "@models/punch-list.model";
import { getByReferenceTypeAndReferenceId } from "@store/slices/attachment";
import { useAppDispatch, useAppSelector } from "@store/store";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Badge, theme } from "antd";
import { UploadFilePunchListItem } from "./UploadFilePunchListItem";
import { isNumber } from "@shared/util/number-util";
import { ThumbnailContainerList } from "./ThumbnailContainerList";

interface IAttachmentListOfPunchListItemContainerProps {
  punchListItem: IPunchListItem
}

export const AttachmentListOfPunchListItemContainer = (props: IAttachmentListOfPunchListItemContainerProps) => {

    const { punchListItem } = props;

    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    const { token: { colorText, colorPrimary, colorFillQuaternary } } = theme.useToken();

    const { entities: attachmentList, totalItems, loading } = useAppSelector((state) => state.Attachment);

    const fetchAttachmentList = useRef<Promise<typeof getByReferenceTypeAndReferenceId> | unknown>();  
    
    useEffect(() => { 
      const punchListItemId = Number(punchListItem?.id);
      const isPunchListItemIdNumber = isNumber(Number(punchListItemId));

      if (isPunchListItemIdNumber) {
        fetchAttachmentList.current = dispatch(getByReferenceTypeAndReferenceId({ 
          referenceType: AttachmentReferenceEnum.PUNCH_LIST_ITEM,
          referenceId: punchListItemId
        }))
      }

      return () => {
        if (fetchAttachmentList.current) {
          const promiseFetchAttachmentList = fetchAttachmentList.current as { abort: () => void };
          promiseFetchAttachmentList?.abort?.();
        }
      }
    }, []);
  
    return (
      <>
        <div>
          <div className="flex flex-row items-center justify-between mt-5 mb-5">
            <div className="flex flex-row items-center">
              <span style={{ color: colorText }}>{ t("generic.attachments")}</span>
              <Badge
                  count={totalItems}
                  className='ml-10'
                  style={{ backgroundColor: colorPrimary, borderColor: colorFillQuaternary, paddingLeft: 5, paddingRight: 5 }} 
              />
            </div>
            <div className="flex flex-row">
              <UploadFilePunchListItem punchListItem={punchListItem} />
            </div>
          </div>
          <ThumbnailContainerList attachmentList={[...attachmentList]} loading={loading} /> 
        </div>
      </>
    )
}
