
export const richCommentJsonToPlainText = (json: string): string => {
    if (json === undefined || json === null || json.trim() === '') return '';
    const questionJson = JSON.parse(json);
    if (questionJson?.root?.children) {
        return concatenateChildren(questionJson.root.children);
    } else {
        return '';
    }
}

const concatenateChildren = (childrenCollection: any[]): string => {
    let result = '';
    if (childrenCollection) {
        childrenCollection.forEach(child => {
            if (child.text) {
                result += ` ${child.text}`;
            } else if (child.children) {
                result += ` ${concatenateChildren(child.children)}`;
            }
        });
    }
    return result;
}