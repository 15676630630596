import { Helmet } from 'react-helmet-async'
import { Outlet } from 'react-router-dom'
import { configuration } from '../environments/env'
import { useLayoutEffect } from 'react';
import { useLayoutContex } from '@providers/LayoutProvider';
import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { MainHeader } from '@components/Layout/HeaderLayout/MainHeader';
import { usePageContentSize } from '@HOOKs/UsePageContentSize';

export default function SettingsPage() {

  const PageContent = () => {

    const { styleContent, setOldStylePropertiesInContentLayout, setHeaderLabelEntityState } = useLayoutContex();
  
    useLayoutEffect(() => { 
      setOldStylePropertiesInContentLayout && setOldStylePropertiesInContentLayout();
      setHeaderLabelEntityState && setHeaderLabelEntityState("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const [layoutRef]= usePageContentSize()

    return (
      <Layout >
          <MainHeader />
          <Content ref={layoutRef} style={styleContent}>
            <Outlet />
          </Content>
      </Layout>
    )
  }
  
  const Page = () => (
    <>
      <Helmet>
        <title>Settings | {configuration.APP_NAME}</title>
      </Helmet>
      <PageContent />
    </>
  );
  return (<Page />)
}