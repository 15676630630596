import { Card, Col, Row } from "antd";
import React from "react";

interface IToolBarProps {
    title?: React.ReactNode;
    totalItems?: React.ReactNode;
    search?: React.ReactNode;
    pagination?: React.ReactNode;
    actionButton?: React.ReactNode;
    settingTool?: React.ReactNode;
}

export const ToolBar = (props: IToolBarProps) => {

    const { 
        title,
        totalItems,
        settingTool,
        pagination,
        search,
        actionButton
    } = props;
    
    return (
      <>
        <Card
            styles={{ 
                body: { paddingLeft: 12, paddingRight: 12, paddingTop: 0, paddingBottom: 0 }
            }}
            style={{ borderRadius: '4px' }}>
            <Row className="flex flex-row items-center justify-between" justify={"space-between"}>
                <Col flex="auto" className="flex flex-row items-center" style={{ paddingTop: 8, paddingBottom: 8 }}>
                    { title }
                    { totalItems }
                    { settingTool }
                </Col>
                <Col flex="auto">
                    <Row justify={"end"}>
                        <Col className="flex flex-row items-center" style={{ paddingTop: 8, paddingBottom: 8  }}>
                            { search }
                            { pagination }
                            { actionButton}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
      </>
    )
}
