import { SVGProps } from "react";

type IProps = SVGProps<SVGSVGElement>

interface IOneLoginSvgIconProps extends IProps { 
    cicleBgColor?: string;
    oneBgColor?: string;
}


export const OneLoginSvgIcon = (props: IOneLoginSvgIconProps) => {  

    const { cicleBgColor, oneBgColor, ...restProps } = props;

    return (
        <svg version="1.1" id="Logomark_black" xmlns="http://www.w3.org/2000/svg" x="0px"
             y="0px" viewBox="0 0 222.6 222.6" width="14" height="14" enableBackground="new 0 0 222.6 222.6" {...restProps} >
            <g>
                <path fill={ cicleBgColor ? cicleBgColor : "#1C1F2A" } d="M111.3,0C49.8,0,0,49.8,0,111.3c0,61.5,49.8,111.3,111.3,111.3s111.3-49.8,111.3-111.3
                    C222.6,49.8,172.8,0,111.3,0z"/>
                <path fill={ oneBgColor ? oneBgColor : "#FFFFFF" } d="M127.8,150.7c0,2.1-1.1,3.2-3.2,3.2h-20c-2.1,0-3.2-1.1-3.2-3.2v-48.2H86.2c-2.1,0-3.2-1.1-3.2-3.2v-20
                    c0-2.1,1.1-3.2,3.2-3.2h39c2.1,0,2.6,1.1,2.6,2.6V150.7z"/>
            </g>
        </svg>
        
    )
}
