import { CustomBox } from '@components/Utils/CustomBox';
import { useAppDispatch, useAppSelector } from "@store/store";
import { getOxBlueProjects, getOxBlueSession, getOxBlueProjectById, reset } from "@store/slices/ox-blue-projects"
import { Button, Col, Divider, Row, Select, Skeleton, theme } from "antd";
import { useEffect, useState } from "react";
import { linkOxBlueIdToProject, unlinkOxBlueIdToProject } from '@store/slices/project-deliverable-timeline';
import { getEntity as getProject } from "@store/slices/projects";
import { If } from '@components/Utils/Structural';
import { isArrayWithValues } from '@shared/util/array-util';
import { DisconnectOutlined } from '@ant-design/icons';


const OxBlueSetting = () => {

    const { token: { colorPrimary, colorText, colorFillTertiary,
        colorFillQuaternary, colorPrimaryBg, fontSizeHeading3,
        colorTextTertiary, colorTextSecondary, colorBorderSecondary, colorWarningBorder, colorWarningTextActive, colorWarningBg, colorFill, colorFillSecondary, colorBgContainer } } = theme.useToken();

    const { data: { oxBlueSession } } = useAppSelector((state) => state.OxBlueProjects);
    const { data: { oxBlueProjects }, loading: loadingOxBlueProjects } = useAppSelector((state) => state.OxBlueProjects);
    const { data: { oxBlueProjectEntity } } = useAppSelector((state) => state.OxBlueProjects);

    const [isManaging, setIsManaging] = useState<boolean>(false);
    const [status, setStatus] = useState<string>("LINKED");
    const [errorInConfiguration, setErrorInConfiguration] = useState<boolean>(false);


    const { entity: projectEntity, loading } = useAppSelector((state) => state.Project);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (oxBlueSession.validSession) {
            dispatch(getOxBlueProjects())
        }
        return () => setIsManaging(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oxBlueSession])

    useEffect(() => {
        if (projectEntity.oxblueProjectId) {
            dispatch(getOxBlueProjectById({ id: projectEntity.oxblueProjectId }));
            setStatus("LINKED")
        }
        else {
            setStatus("NOT_LINKED")
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectEntity])

    useEffect(() => {
        getOxBlueSessionId();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getOxBlueSessionId = () => {
        dispatch(getOxBlueSession()).unwrap().then((value: any) => {
            if (value.payload && !value.payload.validSession) {
                setErrorInConfiguration(true)
            }
        }).catch(() => {
            setErrorInConfiguration(true)
        })

    }

    const [selectedOxBlueId, setSelectedOxBlueId] = useState<string | null>(null)

    const onChangeAutocomplete = (oxBlueProjectId: string) => {
        setSelectedOxBlueId(oxBlueProjectId)
    }


    const linkOxBlueProject = () => {
        const requestData = {
            id: projectEntity.id,
            oxblueProjectId: selectedOxBlueId
        }
        selectedOxBlueId && dispatch(linkOxBlueIdToProject(requestData)).then(() => {
            dispatch(getProject(Number(projectEntity.id)));
            setIsManaging(false)
            dispatch(reset())
        })
    }

    const unlinkOxBlueWithProject = () => {
        const requestData = {
            id: projectEntity.id,
        }
        dispatch(unlinkOxBlueIdToProject(requestData)).then(() => {
            setIsManaging(false)
            setSelectedOxBlueId(null)
            dispatch(getProject(Number(projectEntity.id)))
        })

    }

    const toggleManage = () => {
        setIsManaging(!isManaging);
        if (projectEntity.oxblueProjectId){
            setSelectedOxBlueId(projectEntity.oxblueProjectId)
            dispatch(getOxBlueProjects())
        }
    }

    const SETTING_STATUS_MAP = {
        LINKED: {
            label: "Linked",
            backgroundColor: colorPrimaryBg,
            borderColor: colorPrimary,
            textColor: colorPrimary
        },
        NOT_LINKED: {
            label: "Unlinked",
            backgroundColor: colorBgContainer,
            borderColor: colorFill,
            textColor: colorTextTertiary
        }
    }

    return (
        <div className={`block sticky top-0 mb-20 ${isManaging && "border-container-focus"}`}
            style={{
                border: !isManaging ? `1.2px solid ${colorBorderSecondary}` : "",
                borderRadius: 10, background: colorBgContainer, zIndex: 10, padding: 10
            }}>
            <Row justify={"space-between"} align="middle" style={{ paddingTop: 10 }}>
                <Col className='pt-3 pb-3'>
                    <span className='font-semibold pl-15' style={{ fontSize: fontSizeHeading3, color: colorTextSecondary }}>
                        OxBlue
                    </span>
                </Col>
                <Col className='pt-3 pb-3'>
                    <Row className="mb-10 mt-10 flex-row align-end items-center" style={{ color: colorText }}>
                        <If condition={isManaging && Boolean(projectEntity.oxblueProjectId)}>
                            <Button size='small' type="link" danger className='pr-0 pl-20 mr-10' onClick={() => unlinkOxBlueWithProject()} >
                                <DisconnectOutlined className="cursor-pointer" />
                                Unlink
                            </Button>
                        </If>
                        <CustomBox
                            style={{
                                backgroundColor: SETTING_STATUS_MAP[status].backgroundColor,
                                border: `1px solid ${SETTING_STATUS_MAP[status].borderColor}`
                            }}
                        >
                            <span className='pt-4 pb-4 pl-8 pr-8 select-none' style={{ color: SETTING_STATUS_MAP[status].textColor }}>
                                {SETTING_STATUS_MAP[status].label}
                            </span>
                        </CustomBox>
                    </Row>
                </Col>
            </Row>
            <Divider className='mt-8 mb-8' style={{ borderColor: colorFillSecondary }} />
            <If condition={errorInConfiguration}>
                <Row justify={"space-between"} align="middle" style={{ paddingTop: 10 }}>
                    <div
                        className="pt-3 pb-3 mb-3 cursor-pointer"
                        style={{
                            border: `1px solid ${colorWarningBorder}`,
                            flex: 1,
                            backgroundColor: colorWarningBg,
                            borderRadius: "4px",
                            padding: "8px 16px 8px 16px",
                            marginBottom: "8px"
                        }}
                    >
                        <Row className="mb-10 mt-10 flex-col align-end">
                            <div className='flex flex-col' style={{ color: colorWarningTextActive }}>
                                OxBlue not configured, please contact your admin
                            </div>
                        </Row>
                    </div >
                </Row>
            </If>
            <If condition={!errorInConfiguration}>
                <Row className='flex flex-row items-center justify-between'>
                    <Row className="mb-0 mt-0 flex-row space-between align-end align-center" style={{ color: colorText, flex: 1 }}>
                        <If condition={!isManaging && Boolean(projectEntity.oxblueProjectId)}>
                            <>
                                <If condition={Boolean(oxBlueProjectEntity.name)}>
                                    <Row className="mb-10 mt-10 flex-col align-end ml-5">
                                        <div className='flex flex-col font-bold' style={{ color: colorTextTertiary }}>
                                            {oxBlueProjectEntity?.name}
                                        </div>
                                        <div className='flex flex-col' style={{ color: colorPrimary }}>
                                            Working
                                        </div>
                                    </Row>
                                </If>
                                <If condition={!Boolean(oxBlueProjectEntity.name)}>
                                    <Skeleton paragraph={{ rows: 2, width: ["90%", "90%"] }} active title={false} />
                                </If>
                            </>

                        </If>
                        <If condition={!isManaging && !projectEntity.oxblueProjectId}>
                            <Row className="mb-10 mt-10 flex-col align-end pl-5">
                                <div className='flex flex-col font-bold' style={{ color: colorTextTertiary }}>
                                    No Project Selected
                                </div>
                                <div className='flex flex-col' style={{ color: colorPrimary }}>
                                    Not Working
                                </div>
                            </Row>
                        </If>
                        <If condition={isManaging}>
                            <Row style={{ width: "100%" }}>
                                <div
                                    className="pt-3 mb-3 mr-5 mb-10 mt-10 cursor-pointer"
                                    style={{
                                        border: `1px solid ${colorFillTertiary}`,
                                        flex: 1,
                                        backgroundColor: colorFillQuaternary,
                                        borderRadius: "4px",
                                        padding: "5px",
                                    }}
                                >
                                    <Select
                                        loading={loading || loadingOxBlueProjects}
                                        showSearch
                                        disabled={!isArrayWithValues(oxBlueProjects)}
                                        className="w-full"
                                        value={isArrayWithValues(oxBlueProjects) ? selectedOxBlueId : ""}
                                        onChange={(valueSelected) => onChangeAutocomplete(valueSelected)}
                                        placeholder="Please select an Project"
                                        options={oxBlueProjects?.map!((project: any) => ({ value: project.id, label: project?.name }))}
                                        filterOption={(input, option) => String(option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    />
                                </div >
                            </Row>
                        </If>
                    </Row>

                    <Row className='flex items-center'>
                        <If condition={!isManaging}>
                            <Button type='primary' onClick={toggleManage}>Manage</Button>
                        </If>
                        <If condition={isManaging}>
                            <>
                                <Button className='mr-5' onClick={toggleManage}>Cancel</Button>
                                <Button type='primary' onClick={linkOxBlueProject}>Save</Button>
                            </>
                        </If>
                    </Row>
                </Row>
            </If>
        </div >
    )
}

export default OxBlueSetting;