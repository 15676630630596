import { CloseCircleOutlined } from "@ant-design/icons";
import { Divider, theme } from "antd";

interface IAlertUploadingErrorProps {
    textError: string;
    textSuggestion: string;
    className: string;
}

export const AlertUploadingError = (props: IAlertUploadingErrorProps) => {

    const { token: { colorErrorBorder, colorErrorBg, colorErrorText, colorErrorTextHover, padding, paddingXL, fontSizeSM } } = theme.useToken();

    const { textError = "", textSuggestion = "", className } = props;
    
    return (
        <div className={`${className}`}>
            <div
                className="flex flex-col justify-center items-center"
                style={{
                    borderRadius: 8,
                    border: `1px solid ${colorErrorBorder}`,
                    background: colorErrorBg,
                    paddingTop: paddingXL,
                    paddingBottom: paddingXL,
                    paddingLeft: padding,
                    paddingRight: padding
                }}
            >
                <div className="flex flex-row items-center">
                    <CloseCircleOutlined style={{ color: colorErrorBorder }} />
                    <span className="font-bold ml-8" style={{ color: colorErrorText }} >Uploading Error</span>
                    <span className="ml-8" style={{ color: colorErrorText }} >{textError}</span>
                </div>
                <div style={{ width: "2rem"}}>
                    <Divider className="mt-10 mb-10" />
                </div>
                <span className="font-normal text-center" style={{ color: colorErrorTextHover, maxWidth: 380, fontSize: fontSizeSM }}>
                    {textSuggestion}
                </span>
            </div>
        </div>
    )
}
