import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { blue } from '@ant-design/colors';
import { Button, Col, Drawer, Form, Input, Row, Space } from 'antd';

import { AppDispatch, useAppSelector } from '@store/store';
import { useTranslation } from 'react-i18next';
import { useNotificationsProvider } from '@providers/NotificationsProvider';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { updateEntity } from '@store/slices/company-extended';
import { ICompany } from '@models/company.model';
import { isFulfilled } from '@reduxjs/toolkit';
import { getEntity } from '@store/slices/company';
import { AddressGenericCardHandler } from '@components/AddressGeneric/AddressGenericCardHandler';
import { validateURL } from '@shared/util/validations';

const EditCompany = ({ open, setOpen }) => {
  const { openNotification } = useNotificationsProvider();

  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { updating } = useAppSelector(state => state.Location);

  const { entity: companyEntity } = useAppSelector(state => state.Company);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      name: companyEntity.name,
      website: companyEntity.website,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyEntity, form, open]);

  const [animateError, setAnimateError] = useState(false);

  const onClose = (): void => {
    setOpen(false);
  };

  const onFinish = (entity: Partial<ICompany>): void => {
    const adjustedURL = entity?.website
      ? /^(http:\/\/|https:\/\/)/.test(entity?.website)
        ? entity?.website
        : `http://${entity?.website}`
      : '';
    const requestData = {
      name: entity.name,
      website: adjustedURL,
      id: companyEntity.id,
    };

    const onSuccessEditCompany = (response: any) => {
      if (isFulfilled(response)) {
        if (companyEntity.id) {
          dispatch(getEntity(String(companyEntity.id)));
        }
        openNotification({
          type: 'info',
          config: {
            icon: (
              <CheckCircleTwoTone
                style={{
                  fontSize: 24,
                }}
                twoToneColor={[blue[5], blue[0]]}
              />
            ),
            message: `Company info edited successfully`,
            description: `Changes saved`,
          },
        });
        onClose();
      } else {
        playAnimationOfError();
      }
    };

    dispatch(
      updateEntity({
        ...requestData,
      })
    ).then(onSuccessEditCompany);
  };

  const playAnimationOfError = () => {
    setAnimateError(true);
    setTimeout(() => {
      setAnimateError(false);
    }, 500);
  };

  const onFinishFailed = (errorInfo: any) => {
    playAnimationOfError();
  };

  const onSubmit = () => {
    form.submit();
  };

  return (
    <>
      <Drawer
        title={`Edit ${t('generic.company')} Info`}
        width={580}
        onClose={onClose}
        open={open}
        styles={{ body: { paddingBottom: 80 } }}
        extra={
          <Space>
            <Button type="primary" ghost onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={onSubmit} type="primary" loading={updating}>
              Save
            </Button>
          </Space>
        }
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className={`${animateError && 'error-animation'}`}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="name"
                label={`${t('generic.company')} Name`}
                rules={[
                  {
                    required: true,
                    message: `Please enter ${t('generic.site')} Name`,
                  },
                ]}
              >
                <Input placeholder={`Please enter ${t('generic.site')} Name`} allowClear />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="website"
                label="Website"
                rules={[
                  {
                    validator: validateURL,
                  },
                ]}
              >
                <Input
                  style={{
                    marginLeft: '-3px',
                  }}
                  placeholder={`http://www.example.com`}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <AddressGenericCardHandler referenceType="COMPANY" referenceId={companyEntity.id} />
      </Drawer>
    </>
  );
};

export default EditCompany;
