
import { IChangeOrderRejectionReason } from '@models/change-order-rejection-reason.model';
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { changeOrderRejectionReasonsService } from '@services/change-order-rejection-reasons.service';
import { serializeAxiosError } from "../reducer.util";
import { cleanEntity } from '@shared/util/entity-utils';

export const getEnabledEntities = createAsyncThunk(
    'changeOrderRejectionReasons/fetch_enabled_entity_list',
    async () => {
        return changeOrderRejectionReasonsService.getEnabledChangeOrderRejectionReasons();
    },
    { serializeError: serializeAxiosError }
);

export const getEntities = createAsyncThunk(
    'changeOrderRejectionReasons/fetch_entity_list',
    async () => {
        return changeOrderRejectionReasonsService.getChangeOrderRejectionReasons();
    },
    { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
    `changeOrderRejectionReasons/create_entity`,
    async (entity: IChangeOrderRejectionReason, thunkAPI) => {
      const result = await changeOrderRejectionReasonsService.create(cleanEntity(entity));
      thunkAPI.dispatch(getEntities());
      return result;
    },
    { serializeError: serializeAxiosError }
  );
  
  export const updateEntity = createAsyncThunk(
    `changeOrderRejectionReasons/update_entity`,
    async (entity: IChangeOrderRejectionReason, thunkAPI) => {
      const result = entity.id && await changeOrderRejectionReasonsService.update(entity.id, cleanEntity(entity));
      thunkAPI.dispatch(getEntities());
      return result;
    },
    { serializeError: serializeAxiosError }
  );

interface IChangeOrdersReasonsState {
    loading: boolean;
    errorMessage: string | null;
    entities: ReadonlyArray<IChangeOrderRejectionReason>;
    allEntities: ReadonlyArray<IChangeOrderRejectionReason>;
}

const initialState: IChangeOrdersReasonsState = {
    loading: false,
    errorMessage: null,
    entities: [],
    allEntities: []
};

export const ChangeOrderRejectionReasonsSlice = createSlice({
    name: 'changeOrderRejectionReasons',
    initialState,
    reducers: {
        reset: (state,) => {
            return initialState;
        },
    },
    extraReducers(builder) {
        builder
            .addMatcher(isFulfilled(getEnabledEntities), (state, action) => {
                const { data } = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data,
                };
            })
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const { data } = action.payload;
                return {
                    ...state,
                    loading: false,
                    allEntities: data,
                };
            })
            .addMatcher(isPending(getEnabledEntities), state => {
                state.errorMessage = null;
                state.loading = true;
            })
            .addMatcher(isPending(getEntities), state => {
                state.errorMessage = null;
                state.loading = true;
            })
            .addMatcher(isFulfilled(createEntity, updateEntity), (state) => {
                state.loading = false;
            })
            .addMatcher(isPending(createEntity, updateEntity), state => {
                state.errorMessage = null;
            });
    }
});

export const { reset } = ChangeOrderRejectionReasonsSlice.actions;

// Reducer
export default ChangeOrderRejectionReasonsSlice.reducer;

