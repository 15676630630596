import React, { useState, useEffect } from 'react';
import { ConfigProvider, Table, theme } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState, useAppSelector } from '@store/store';
import { getMyPastDeliverables } from '@store/slices/dashboard';
import { useNavigate } from 'react-router-dom';
import { OnRowEvent } from '@models/utils/table.utils.model';
import { EmptyCellTable } from '@components/Utils/EmptyCellTable';
import { useThemeProvider } from '@providers/ThemeProvider';
import { IMyPastDeliverable } from '@models/dashboard.model';
import { dateSorter, defaultSorter } from '@shared/util/table-utils';
import type { ColumnsType, TableProps } from 'antd/es/table';
import '@styles/components/table.less';
import { useTranslation } from 'react-i18next';
import { getEntities as getBrands } from '@store/slices/brand';

const TitleBrand = () => {
  const { t } = useTranslation();
  return <span>{`${t('location.brand')}`}</span>;
};

const TitleProject = () => {
  const { t } = useTranslation();
  return <span>{`${t('generic.project')}`}</span>;
};

const TitleDeliverable = () => {
  const { t } = useTranslation();
  return <span>{`${t('projectDeliverable.deliverable')}`}</span>;
};

const TitleStartDate = () => {
  const { t } = useTranslation();
  return <span>{`${t('generic.startDate')}`}</span>;
};

const TitleEndDate = () => {
  const { t } = useTranslation();
  return <span>{`${t('generic.endDate')}`}</span>;
};

const LocationHashSymbollabel = () => {
  const { t } = useTranslation();
  return <span>{t('generic.location')}</span>;
};

const columns: ColumnsType<IMyPastDeliverable> = [
  {
    title: <LocationHashSymbollabel />,
    dataIndex: 'locationName',
    key: 'locationName',
    className: 'border-left-in-hover',
    ellipsis: true,
    sorter: (a, b) => defaultSorter(a?.locationName, b?.locationName),
    render: value => {
      return value || <EmptyCellTable />;
    },
  },
  {
    title: <TitleBrand />,
    dataIndex: 'brandDescription',
    key: 'brandDescription',
    className: 'border-left-in-hover',
    ellipsis: true,
    sorter: (a, b) => defaultSorter(a?.brandDescription, b?.brandDescription),
    render: value => {
      return value || <EmptyCellTable />;
    },
  },
  {
    title: <TitleProject />,
    dataIndex: 'projectId',
    key: 'projectId',
    ellipsis: true,
    sorter: (a, b) => defaultSorter(a?.projectId, b?.projectId),
    render: (_, record: IMyPastDeliverable) => {
      return record?.projectNickname ? (
        `${record.projectNickname} (#${record.projectId})`
      ) : record?.projectId ? (
        `#${record.projectId}`
      ) : (
        <EmptyCellTable />
      );
    },
  },
  {
    title: <TitleDeliverable />,
    dataIndex: 'deliverableName',
    key: 'deliverableName',
    sorter: (a, b) => defaultSorter(a?.deliverableName, b?.deliverableName),
    render: value => {
      return value || <EmptyCellTable />;
    },
    ellipsis: true,
  },
  {
    title: <TitleStartDate />,
    dataIndex: 'startDate',
    key: 'startDate',
    sorter: (a, b) => dateSorter(a?.startDate, b?.startDate),
    render: value => {
      return value || <EmptyCellTable />;
    },
    ellipsis: true,
  },
  {
    title: <TitleEndDate />,
    dataIndex: 'endDate',
    key: 'endDate',
    ellipsis: true,
    sorter: (a, b) => dateSorter(a?.endDate, b?.endDate),
    render: value => {
      return value || <EmptyCellTable />;
    },
  },
];

export const MyPastDeliverablesTable: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { themeConfig } = useThemeProvider();
  const algorithmSelected = themeConfig?.darkMode ? [theme.compactAlgorithm, theme.darkAlgorithm] : theme.compactAlgorithm;
  const {
    data: {
      myPastDeliverables: { list, loading, totalItems },
    },
  } = useSelector((state: RootState) => state.Dashboard);

  const [tableParams, setTableParams] = useState({
    size: 20,
    current: 0,
    // sort: `openDate,desc`
  });

  //autohide columns
  const brands = useAppSelector(state => state.Brand.entities);
  useEffect(() => {
    if (brands.length === 0) {
      dispatch(getBrands({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [filteredColumns, setFilteredColumns] = useState<ColumnsType<IMyPastDeliverable>>([]);

  useEffect(() => {
    const tempColumns = brands.length > 1 ? columns : columns.filter(column => column.key !== 'brandDescription');
    setFilteredColumns(tempColumns);
  }, [brands, filteredColumns]);

  const filterLocations = () => {
    const { current, size } = tableParams;
    dispatch(
      getMyPastDeliverables({
        page: current,
        size: size,
      })
    );
  };

  useEffect(() => {
    filterLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableParams]);

  const onChange: TableProps<IMyPastDeliverable>['onChange'] = pagination => {
    setTableParams(oldValue => {
      return {
        ...oldValue,
        ...(pagination?.pageSize && { size: pagination.pageSize }),
        current: pagination.current !== undefined ? pagination.current - 1 : 0,
      };
    });
  };

  const onRowClick = (eventOnClick: OnRowEvent<IMyPastDeliverable>) => {
    const { event, record } = eventOnClick;
    const { locationId = '', siteId = '', projectId = '', projectDeliverableId = '' } = record;

    const urlToNavigate = `/location/${locationId}/sites/${siteId}/project/${projectId}/deliverables${
      projectDeliverableId && `/${record?.projectDeliverableId}`
    }`;
    event?.metaKey ? window.open(`${urlToNavigate}`) : navigate(`${urlToNavigate}`);
  };

  const addUniqueKey = (list: IMyPastDeliverable[]) => {
    return list.map((item, index) => ({ ...item, id: item?.projectDeliverableId || index }));
  };

  return (
    <div className="overflow-y-auto">
      <div>
        <ConfigProvider theme={{ token: { borderRadius: 0 }, algorithm: algorithmSelected }}>
          <Table
            loading={loading}
            rowKey="id"
            size="small"
            columns={filteredColumns}
            dataSource={addUniqueKey(list)}
            onChange={onChange}
            onRow={(record, rowIndex) => ({ onClick: event => onRowClick({ event, record, rowIndex }) })}
            className={list?.length > 0 ? 'app-table' : ''}
            rowClassName={'table-with-selector'}
            pagination={{ pageSize: tableParams.size, total: totalItems }}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};
