
import { InboxOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Modal, Upload, UploadProps } from 'antd';

import { Callable } from '@faker-js/faker/utils/types';
import { IAttachment } from 'models/attachment.model';
import { AttachmentReferenceEnum } from '../../models/enumerations/attachment-reference-enum.model';
import { attachmentService } from '../../services/attachment.service';
import { asyncLaunchNotification } from '@store/slices/notification';
import { useAppDispatch } from '@store/store';
import { ReactNode } from 'react';
const { Dragger } = Upload;

export interface IUploadModalProps {
  entityType: AttachmentReferenceEnum,
  referenceId: number,
  attachmentId: number | null,
  getAllAttachments: Callable,
  handleCloseuploadModals: Callable,
  onUploaded: (props: { fileName?: string  }) => void,
  titleModal?: ReactNode,
  draggerContent?: ReactNode
  uploadPropsModal?: UploadProps<any>
}

export default function UploadModal(props: IUploadModalProps) {

  const { 
    entityType, 
    referenceId, 
    attachmentId, 
    getAllAttachments, 
    handleCloseuploadModals, 
    onUploaded, 
    titleModal, 
    draggerContent, 
    uploadPropsModal 
  } = props;

  const dispatch = useAppDispatch();

  const onErrorUpload = () => {
    dispatch(asyncLaunchNotification({
      type: "error",
      config: {
        message: `Attachments`,
        description: `There was an error trying to upload the file, please try again later.`
      }
    }));
  }

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    customRequest: ({ file, onSuccess, onError }) => {

      // handleCloseuploadModals();
      // onUploaded({});
      // getAllAttachments();

      const attachment: IAttachment = {};
      attachment.fileName = (file as File).name;
      attachment.referenceType = entityType;
      attachment.referenceId = referenceId;
      attachment.fileSize = (file as File).size;

      // 1 - Create Attachment Entity
      // 2 - Request Signed url to Upload
      // 3 - Put file to s3 signed url
      // 4 - confirm the file was uploaded
      // 5 - Refresh 
      if (attachmentId !== null) {
        const parentAttachment: IAttachment = { id: attachmentId }
        attachment.attachment = parentAttachment
      }

      attachmentService.create({ attachment, file }).then((response) => {
        handleCloseuploadModals();
        onUploaded({});
        getAllAttachments();
      }).catch((error) => {
        onErrorUpload();
        handleCloseuploadModals();
      });

    }
  };


  return (
    <>
      <Modal
        title={titleModal ? titleModal : ((attachmentId === null) ? "Drag and Drop New File" : "Drag and Drop New Revision") }
        closable={true}
        open={true}
        getContainer={false}
        destroyOnClose={true}
        onCancel={handleCloseuploadModals}
        footer={[
          <Button key="back" onClick={handleCloseuploadModals}>
            Back
          </Button>
        ]}
      >
        <ConfigProvider theme={{
          components: {
            Upload: {
              borderRadiusLG: 8
            }
          }
        }}>
          <Dragger {...{...uploadProps, ...uploadPropsModal}} height={400}>
            { draggerContent ? draggerContent : <DefaultDraggerContent />}
          </Dragger>
        </ConfigProvider>
      </Modal>
    </>
  )
}

export const DefaultDraggerContent = () => {
  return (
    <>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">
        Support for a single upload.
      </p>
    </>
  )
}