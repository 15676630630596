import React, { useRef } from 'react';
import usePermisions, { ICan } from "@HOOKs/UsePermisions";
import { Tooltip } from "antd";

export const EFFECTS = {
    BLOCK: "BLOCK",
    HIDE: "HIDE"
}

export type IEffects = keyof typeof EFFECTS;

export interface ICanArgs extends ICan { 
    effect?: IEffects
    tooltip?: boolean
    tooltipProps?: React.ComponentPropsWithoutRef<typeof Tooltip>
}

export interface CanProps extends ICanArgs { 
    children?: React.ReactNode;
}

export const Can: React.FC<CanProps> = ({ I, A, Entity, effect, children, tooltip, tooltipProps }) => {

    const { can } = usePermisions()

    const effectToApply = effect ? effect : EFFECTS.BLOCK;
    
    const disableClassName = "pointer-events-none"
    const selectNoneClass = "select-none"

    const divRef = useRef<HTMLDivElement>(null);

    const addDisableClass = () => {
        if (divRef !== null) {
            divRef && divRef.current && divRef.current.classList.add(disableClassName); 
            divRef && divRef.current && divRef.current.classList.add(selectNoneClass); 
        }
    }

    const childrenBlocked = (
        <div className="cursor-not-allowed">
            <div 
                ref={divRef}
                className={`${disableClassName} ${selectNoneClass}`}
                onMouseEnter={() => addDisableClass() }
                onMouseLeave={() => addDisableClass() }
            >
                {children}
            </div>
        </div>
    )
    
    const defaultTooltipProps: React.ComponentPropsWithoutRef<typeof Tooltip> = {
        placement: "top",
        title: "",
        ...tooltipProps
    }

    const contentBlocked = tooltip ? (<Tooltip {...defaultTooltipProps} > {childrenBlocked} </Tooltip>) : childrenBlocked;

    const canDoTheAction = can({ I, A, Entity });

    if ( canDoTheAction ) { return (<>{children}</>); }
    if (!canDoTheAction && effectToApply === EFFECTS.BLOCK ) { return contentBlocked };
    if (!canDoTheAction && effectToApply === EFFECTS.HIDE ) { return null };

    return null;
}
