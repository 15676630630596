import { useLayoutContex } from "@providers/LayoutProvider";
import { theme } from "antd";
import { configuration } from './../../environments/env'

export const PoweredByBrand = () => {
    
    const { collapseNav } = useLayoutContex();
    const { token: { fontSize, colorPrimaryBorder } } = theme.useToken();

    return (
        <div className='flex flex-col justify-center items-center w-full mb-30'>
            {
                collapseNav 
                    ? (<img src={"/media/client/siterise/siterise-icon.svg"} alt="" width={"44px"} style={{ maxWidth: 55, color: "#525656" }} className='block'/>)
                    : <img src={"/media/client/siterise/powered_by_siterise.svg"} alt="" width={"100%"} style={{ maxWidth: 140 }}className='block'/>
            }
            {
                !collapseNav && <span style={{ color: colorPrimaryBorder, fontSize: fontSize - 2 }}>
                    { configuration.VERSION }
                </span>
            }
        </div>
    )
}
