/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import { IBudgetTemplateExtended } from "@models/budget-template.model";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getTemplateLineItems } from "@store/slices/budget-template-extended";
import { Badge, Button, Col, Drawer, Row, Space, Tag, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { ICostSubCategoryExtended } from "@models/cost-sub-category.model";

interface ICostCategoryFlat {
    id?: number;
    name?: string;
    order?: number;
    code?: string;
    glCode?: string | null;
    active?: boolean | null;
    costGroupingId?: number;
    costSubCategories?: ICostSubCategoryExtended[];
}

interface IBudgetTemplateDetailsProps {
    open: boolean,
    toogle: () => void,
    budgetTemplate: IBudgetTemplateExtended
}

export const BudgetTemplateDetailsPanel = (props: IBudgetTemplateDetailsProps) => {

    const { open, toogle, budgetTemplate } = props;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { token: {
        colorBorderSecondary,
        colorFill,
        colorFillSecondary,
        colorTextSecondary,
        colorTextTertiary,
        colorFillTertiary } } = theme.useToken();

    const loadTemplateDeliverables = () => {
        if (budgetTemplate?.id) {
            dispatch(getTemplateLineItems(String(budgetTemplate?.id)));
        }
    }

    useEffect(() => {
        loadTemplateDeliverables();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [budgetTemplate]);

    const { lineItemList } = useAppSelector((state) => state.BudgetTemplateExtended);

    const costGroupings = useMemo(() => {
        const costSubCategories = lineItemList.map(x => x.costSubCategory);
        const costCategories = costSubCategories.map(x => x?.costCategory);
        const costGroupings = costCategories.map(x => { 
            return { 
                ...x?.costGrouping, 
                costCategories: [] }})
            .filter((x, idx, arr) => idx === arr.findIndex(i => i.id === x.id));
        for (let i = 0; i < costGroupings.length; i++) {
            // @ts-ignore
            costGroupings[i].costCategories = costCategories
                .filter(x => x?.costGrouping?.id === costGroupings[i].id)
                .map(c => { 
                    return {
                        id: c?.id,
                        active: c?.active,
                        code: c?.code,
                        glCode: c?.glCode,
                        name: c?.name,
                        order: c?.order,
                        costGroupingId: c?.costGrouping?.id,
                        costSubCategories: []
                    }
                })
                .filter((x, idx, arr) => idx === arr.findIndex(ix => ix.id === x.id));

                for (let j = 0; j < costGroupings[i].costCategories.length; j++) {
                    // @ts-ignore
                    costGroupings[i].costCategories[j].costSubCategories = costSubCategories
                        // @ts-ignore
                        .filter(s => s?.costCategory?.id === costGroupings[i].costCategories[j].id)
                        .map(s => { return {
                            id: s?.id,
                            name: s?.name,
                            order: s?.order,
                            glCode: s?.glCode,
                            active: s?.active,
                            code: s?.code
                        }});
                }
        }
        return costGroupings;
    }, [lineItemList]);

    const onClose = () => {
        toogle();
    }

    const navigateToEdit = () => {
        if (budgetTemplate) {
            navigate(`/admin/module-configuration/budget/templates/${budgetTemplate?.id}`)
        }
    }

    return (
        <>
            <Drawer
                width={448}
                title={`${budgetTemplate?.name}`}
                placement="right"
                onClose={onClose}
                open={open}
                closable={true}
                extra={
                    <Space>
                        <Button onClick={navigateToEdit} type="primary">
                            Edit
                        </Button>
                    </Space>
                }
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <div className="pr-8 pl-8 mb-16" style={{ color: colorTextSecondary, backgroundColor: colorFillTertiary, border: `1px solid ${colorBorderSecondary}`, borderRadius: '4px', lineHeight: '34px', display: 'flex', alignItems: 'center' }}>
                            <span style={{ fontWeight: 'bold', flex: 1 }}>Cost Group</span>
                            <Badge
                                count={costGroupings.length}
                                className='ml-10'
                                style={{ backgroundColor: colorFillSecondary, color: colorTextTertiary }} />
                        </div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        {costGroupings.map(costGrouping => {
                            return <div className="pr-8 pl-8">
                                <div style={{ borderBottom: `1px solid ${colorFill}`, color: colorTextSecondary, display: 'flex', gap: '4px', padding: '0.25rem 0' }}>
                                    <div style={{ flex: 1 }}>{costGrouping.name}</div>
                                    <Badge
                                        count={costGrouping.costCategories.length}
                                        className='ml-10'
                                        style={{ backgroundColor: colorFillSecondary, color: colorTextTertiary }} />
                                </div>
                                {costGrouping.costCategories.map((costCategory: ICostCategoryFlat) => {
                                    return <div style={{ marginBottom: '8px' }}>
                                        <Tag style={{ margin: '0.25rem' }}>{costCategory.name}</Tag>
                                        <div style={{ marginLeft: '1rem' }}>
                                            {costCategory.costSubCategories?.map(costSubCategory => {
                                                return <div><Tag style={{ margin: '0.25rem' }}>{costSubCategory.name}</Tag></div>
                                            })}
                                        </div>
                                    </div>
                                })}
                            </div>
                        })}
                    </Col>
                </Row>
            </Drawer>
        </>
    );
}
