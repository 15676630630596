export const isString = (value: any): boolean => {
    return typeof value === "string";
}

export const isStringWithContent = (value: any): boolean => {
    return isString(value) && value !== "";
}

export const isStringEmpty = (value: any): boolean => {
    return isString(value) && value === "";
}

export const isStringOrNumber = (value: any): boolean => {
    return typeof value === "string" || typeof value === "number";
}

export const validateURL = (_: any, value: string) => {
  if (!value || value === '') {
    return Promise.resolve();
  }

  if (/\s/.test(value)) {
    return Promise.reject('URL should not contain spaces');
  }

  const adjustedValue = /^(http:\/\/|https:\/\/)/.test(value) ? value : `http://${value}`;

  // Pattern to match URLs (with or without www.)
  const pattern = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}.*$/);

  if (!pattern.test(adjustedValue)) {
    return Promise.reject('Invalid URL');
  }
  return Promise.resolve();
};

export const isBoolean = (value: any): boolean => {
    return typeof value === "boolean";
}

export const isEqual = (value1: any, value2: any) => {
    if (value1 === value2) {
      return true;
    }
  
    if (typeof value1 !== 'object' || value1 === null || typeof value2 !== 'object' || value2 === null) {
      return false;
    }
  
    const keys1 = Object.keys(value1);
    const keys2 = Object.keys(value2);
  
    if (keys1.length !== keys2.length) {
      return false;
    }
  
    for (let key of keys1) {
      if (!keys2.includes(key) || !isEqual(value1[key], value2[key])) {
        return false;
      }
    }
  
    return true;
  }