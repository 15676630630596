import { IChangeOrderResume, defaultChangeOrderResume } from '@models/change-order.model';
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { changeOrdersService } from '@services/change-orders.service';
import { EntityState, serializeAxiosError } from "../reducer.util";
import { ICreateChangeOrderRequest } from '@models/create-change-order-request';
import { IChangeOrderRejectionRequest } from '@models/change-order-rejection-request';
import { IChangeOrderApprovalRequest } from '@models/change-order-approval-request';
import { IChangeOrderItem } from '@models/change-order-item.model';

export interface IGetChangeOrdersArgs {
    projectId: string | number;
    statuses?: number[];
    page: number;
    size: number;
    sort?: string;
}

export interface ICreateChangeOrderArgs {
    entity: ICreateChangeOrderRequest,
    onSuccess?: () => void
}

export interface IChangeOrdersIdArgs {
    id: string | number,
    onSuccess?: () => void
}
export interface IChangeOrdersDetailSubmitArgs extends IChangeOrdersIdArgs {
    items: IChangeOrderItem[]
}

export interface IRejectChangeOrderArgs extends IChangeOrdersIdArgs {
    rejectionRequest: IChangeOrderRejectionRequest
}

export interface IApproveChangeOrderArgs extends IChangeOrdersIdArgs {
    approvalRequest: IChangeOrderApprovalRequest
}

export interface IChangeOrdersUpdateReasonArgs extends IChangeOrdersIdArgs {
    reasonId: number | null
}

export const getEntities = createAsyncThunk(
    'changeOrders/fetch_entity_list',
    async ({ projectId, statuses, page, size, sort }: IGetChangeOrdersArgs) => {
        return changeOrdersService.getChangeOrders(projectId, page, size, sort, statuses);
    },
    { serializeError: serializeAxiosError }
);

export const countChangeOrders = createAsyncThunk(
    'changeOrders/fetch_quantity',
    async (projectId: string | number) => {
        return changeOrdersService.countChangeOrders(projectId);
    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
    'changeOrders/fetch_entity',
    async (id: string | number) => {
        return changeOrdersService.getChangeOrderById(id);
    },
    { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
    'changeOrders/create_entity',
    async ({ entity, onSuccess }: ICreateChangeOrderArgs, thunkAPI) => {
        const result = await changeOrdersService.createChangeOrder(entity);
        onSuccess?.();
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const publish = createAsyncThunk(
    'changeOrders/publish',
    async ({ id, onSuccess }: IChangeOrdersIdArgs, thunkAPI) => {
        const result = await changeOrdersService.publish(id);
        onSuccess?.();
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const reject = createAsyncThunk(
    'changeOrders/reject',
    async ({ id, rejectionRequest, onSuccess }: IRejectChangeOrderArgs, thunkAPI) => {
        const result = await changeOrdersService.reject(id, rejectionRequest);
        onSuccess?.();
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const requestResubmission = createAsyncThunk(
    'changeOrders/askResubmission',
    async ({ id, rejectionRequest, onSuccess }: IRejectChangeOrderArgs, thunkAPI) => {
        const result = await changeOrdersService.requestResubmission(id, rejectionRequest);
        onSuccess?.();
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const approve = createAsyncThunk(
    'changeOrders/approve',
    async ({ id, approvalRequest, onSuccess }: IApproveChangeOrderArgs, thunkAPI) => {
        const result = await changeOrdersService.approve(id, approvalRequest);
        onSuccess?.();
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const submitDetail = createAsyncThunk(
    'changeOrders/submitDetail',
    async ({ id, items, onSuccess }: IChangeOrdersDetailSubmitArgs, thunkAPI) => {
        const result = await changeOrdersService.submitDetail(id, items);
        onSuccess?.();
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const updateReason = createAsyncThunk(
    'changeOrders/updateReason',
    async ({ id, reasonId, onSuccess }: IChangeOrdersUpdateReasonArgs, thunkAPI) => {
        const result = await changeOrdersService.updateReason(id, reasonId);
        onSuccess?.();
        return result;
    },
    { serializeError: serializeAxiosError }
);

interface ChangeOrdersState extends EntityState<IChangeOrderResume> {
    entitiesQuantity: number | null;
}

const initialState: ChangeOrdersState = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultChangeOrderResume,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
    entitiesQuantity: null,
};

export const ChangeOrdersSlice = createSlice({
    name: 'changeOrders',
    initialState,
    reducers: {
        clearEntity: (state,) => {
            state.entity = defaultChangeOrderResume;
        },
        reset: (state,) => {
            return initialState;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data;
            })
            .addCase(countChangeOrders.fulfilled, (state, action) => {
                state.loading = false;
                state.entitiesQuantity = action.payload.data;
            })
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const { data, headers } = action.payload;
                const xTotalCount: string = headers['x-total-count'] || "0";
                return {
                    ...state,
                    loading: false,
                    entities: data,
                    totalItems: parseInt(xTotalCount, 10),
                };
            })
            .addMatcher(isFulfilled(createEntity, publish, reject, requestResubmission, approve), (state, action) => {
                state.updating = false;
                state.loading = false;
                state.updateSuccess = true;
                state.entity = action.payload as IChangeOrderResume;
            })
            .addMatcher(isPending(getEntities, getEntity, countChangeOrders), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
            .addMatcher(isPending(createEntity, publish, reject, requestResubmission, approve), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.updating = true;
            });
    }
});

export const { reset, clearEntity } = ChangeOrdersSlice.actions;

// Reducer
export default ChangeOrdersSlice.reducer;

