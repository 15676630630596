import { IHeaders } from 'infrastructure/http/axios.instance';
import pick from 'lodash/pick';

/**
 * Removes fields with an 'id' field that equals ''.
 * This function was created to prevent entities to be sent to
 * the server with an empty id and thus resulting in a 500.
 *
 * @param entity Object to clean.
 */
export const cleanEntity = (entity: any) => {
  const keysToKeep = Object.keys(entity).filter(k => !(entity[k] instanceof Object) || (entity[k]['id'] !== '' && entity[k]['id'] !== -1));

  return pick(entity, keysToKeep);
};

/**
 * Sort Entities by string passed
 * This function was created to prevent sort quickly by a field belogns to that entity
 *
 * @param entities Array of objects.
 * @param field String
 */
export const entitiesSortedByStrings = (entities: any[], field: string) => {
    return entities.sort((a, b) => {
      try {
        const stringA = a[field];
        const stringB = b[field];
        if (typeof stringA === 'string' && typeof stringB === 'string') { 
          return (stringA.toLowerCase() > stringB.toLowerCase()) ? 1 : -1 
        }
        return -1;
      }catch {
        return -1;
      }
    })
}

export const getTotalItems = (headers: IHeaders ) => {
  const xTotalCount: string = headers['x-total-count'] || "0";
  return  parseInt(xTotalCount, 10);
}