import { useAppSelector } from "@store/store";
import { Empty, Row, Skeleton, theme } from "antd";
import { DeliverableDetails } from "../DeliverableDetails";

interface IDeliverableDetailsContainerProps {
  
}

export const DeliverableDetailsContainer = (props: IDeliverableDetailsContainerProps) => {

    const { loadingDetails, data: { deliverableSelected } } = useAppSelector((state) => state.ProjectDeliverables);

    const { token: { padding } } = theme.useToken();
    
    return (
        <Skeleton loading={loadingDetails} paragraph={{ rows: 24 }} className="mt-15" active={true} style={{ paddingLeft: `${padding}px`, paddingRight: `${padding}px` }}>
            {
                (!deliverableSelected)
                    ? (
                        <Row align={"middle"} className="pt-40 mt-40 w-full items-center justify-center">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </Row>
                    ) :
                    (
                        deliverableSelected && deliverableSelected.id && <DeliverableDetails deliverableActive={deliverableSelected} referenceId={deliverableSelected.id} />
                    )
            }
        </Skeleton>
    )
}
