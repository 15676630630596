import { DragZoneComponentRef, PunchListUploadFileDragZone, onUploadedProps } from "./PunchListUploadFileDragZone";
import { AttachmentReferenceEnum } from "@models/enumerations/attachment-reference-enum.model";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { UploadFloorPlanStepsEnum } from "./UploadFloorPlan";
import { VALID_IMAGES_COMMON_EXTENSIONS } from "@constants/fileImagesCommonValidExtensions";
import { If } from "@components/Utils/Structural";
import { AlertUploadingError } from "./AlertUploadingError";
import { IErrorUploading } from "@models/alert-error-uploading";
import { isNotEmpty } from "@shared/util/string-utils";

interface IUploadFileStepProps {
  error: IErrorUploading;
  setCurrentStep: (step: UploadFloorPlanStepsEnum) => void;
  setPunchListGenerationId?: (punchListGenerationId: string | number) => void;
}

export const MAX_LIMIT_FILES_TO_UPLOAD = 1;
export const MAX_LIMIT_SIZE_FILE_TO_UPLOAD_IN_MB = 100;
export const TIME_UNTIL_TRANSITION = 2000;
export const VALID_EXTENSIONS_UPLOAD_FILE = [...VALID_IMAGES_COMMON_EXTENSIONS, ".pdf"];

export const UploadFileStep = (props: IUploadFileStepProps) => {

    const { setCurrentStep, setPunchListGenerationId, error } = props;

    const dragZoneRef = useRef<DragZoneComponentRef>(null);

    const { projectId } = useParams<"projectId">();

    const [showError, setShowError] = useState<boolean>(false);

    useEffect(() => {
      if (isNotEmpty(error?.textError) && isNotEmpty(error?.textSuggestion)) {
        setShowError(true);
      }
    }, [error]);

    const onUploaded = (props: onUploadedProps) => {
      const { referenceId } = props;
      
      setTimeout(() => {
        if (referenceId !== null) {
          setPunchListGenerationId?.(referenceId); 
        }
        setCurrentStep?.(UploadFloorPlanStepsEnum.PROCESSING_FILE);
      }, TIME_UNTIL_TRANSITION);
    }

    const onStartUpload = () => {
      setShowError(false);
    }
    
    return (
      <>
        <If condition={Boolean(showError && error?.textError && error?.textSuggestion)}>
          <AlertUploadingError textError={error?.textError} textSuggestion={error?.textSuggestion} className="mb-15" />
        </If>
        <div className="flex">
          {
            projectId && 
            (
              <PunchListUploadFileDragZone
                ref={dragZoneRef}
                referenceId={projectId}
                entityType={AttachmentReferenceEnum.PUNCH_LIST_GENERATE}
                onUploaded={onUploaded}
                onStartUpload={onStartUpload}
                validExtensions={VALID_EXTENSIONS_UPLOAD_FILE}
                maxLimitFilesToUpload={MAX_LIMIT_FILES_TO_UPLOAD}
                maxLimitSizeFileToUploadInMB={MAX_LIMIT_SIZE_FILE_TO_UPLOAD_IN_MB}
              />
            )
          }
        </div>
      </>
    )
}
