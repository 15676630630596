import { TabContainer } from "@components/Utils/TabContainer"
import { ProjectStatusesCard } from "./ProjectStatusesCard"
import { ProjectTypesCard } from "./ProjectTypesCard"
import { Col, Row, theme } from "antd"
import { BackButton } from "@components/Utils/BackButton"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useLayoutContex } from "@providers/LayoutProvider"
import { useEffect } from "react"

export const ProjectModuleConfig = () => {

    const { token: { colorBgContainer, colorBorderSecondary } } = theme.useToken();

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { setHeaderLabelEntityState } = useLayoutContex();

    useEffect(() => {
        setHeaderLabelEntityState(t("admin.projects"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const navigateBack = () => {
        navigate('/admin/module-configuration')
    }

    return (
        <TabContainer>
            <div className="flex flex-col overflow-auto">
                <Row>
                    <Col span={24}>
                        <div className="flex flex-row items-center pl-10 pt-11 pb-11" style={{ borderTop: `1px solid ${colorBorderSecondary}`, borderBottom: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                            <BackButton onClick={navigateBack} >{t("admin.moduleConfiguration")}</BackButton>
                        </div>
                    </Col>
                </Row>
            </div>
            <div style={{ marginLeft: '24px', marginTop: '16px' }}>
                <ProjectStatusesCard />
                <ProjectTypesCard />
            </div>
        </TabContainer>
    )
}