/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useErrorAnimation } from "@HOOKs/UseErrorAnimation";
import { IProjectType } from "@models/project-type.model";
import { isFulfilled } from "@reduxjs/toolkit";
import { asyncLaunchNotification } from "@store/slices/notification";
import { createEntity, updateEntity } from "@store/slices/project-types";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Button, Col, Drawer, Form, Input, Row, Space } from "antd";
interface IProjectTypeEditProps {
  open: boolean,
  toogle: () => void,
  onModifiedChanges?: (group: IProjectType) => void,
  projectType: IProjectType
}

export const ProjectTypeEdit = (props: IProjectTypeEditProps) => {

    const { open, toogle, projectType, onModifiedChanges } = props;

    const dispatch = useAppDispatch();

    const [form] = Form.useForm();

    const { loading, updating } = useAppSelector((state) => state.Groups);

    const [animateError, playAnimationOfError] = useErrorAnimation();

    const onFinishFailed = () => playAnimationOfError();

    const onFinish = (entity: any): void => {
        const hasStatus = projectType?.id;

        if (hasStatus) {
            dispatch(
                updateEntity({
                    ...projectType,
                    ...entity
                })  
            ).then(onSuccessUpdatedType);
            
        } else {
            dispatch(createEntity({
                id: "",
                order: 0,
                ...entity
            })).then(onSuccessCreateType);
        }
    };

    const onSuccessUpdatedType = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: `Changes saved`,
                  description: `Your changes have been saved successfully`
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onSuccessCreateType = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: `Changes saved`,
                  description: `Type created successfully`
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onClose = () => { 
        form.resetFields();
        toogle(); 
    }

    const onSubmit = () => form.submit();

    useEffect(() => { setTypeFormFields() }, [projectType])

    const setTypeFormFields = () => {
        form.setFieldsValue({
            ...projectType
        });
    }

    return (
        <>
            <Drawer 
                width={448} 
                title={`${projectType?.id ? "Edit": "Add"} type`}
                placement="right" 
                onClose={onClose} 
                open={open}
                closable={false}
                extra={
                    <Space>
                      <Button type="primary" ghost onClick={onClose}>
                        Cancel
                      </Button>
                      <Button loading={loading} onClick={onSubmit} type="primary" disabled={updating}>
                        {`${projectType?.id ? "Save": "Create"}`}
                      </Button>
                    </Space>
                  }
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className={`${animateError && "error-animation"}`}
                >

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[
                                    { required: true, message: "Name field is Required" },
                                ]}
                            >
                                <Input placeholder="Name" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Drawer>
        </>
  );
}
