import { HTMLProps, useEffect } from "react";

type IStatusUserIndicatorProps = HTMLProps<HTMLDivElement>;

export const StatusUserIndicator = (props: IStatusUserIndicatorProps) => {

    const { style, className, ...restProps } = props;

    useEffect(() => {
    }, [])
    
    return (
        <div style={{ width: 6, height: 6, ...style }} className={`rounded-full ${className}`} {...restProps}></div>
    )
}
