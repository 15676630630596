import { IQueryParamsGeneric } from '@models/utils';
import { ILocation } from '../../models/location.model';
import { IQueryParams } from '../../models/pagination';
import { http } from '../http/axios.instance';
import { StringORNumber } from './utils.repository';
import { LocationMapper } from '@mappers/Location.mapper';

const path = 'locations';
const extendedPath = `locations-extended`;

export const locationRepository = {
  getLocations: async (queryParams: IQueryParams) => {
    
    const { sort, page, size, nameFilter } = queryParams;
    const requestUrl = `${extendedPath}/sort-by-params${sort ? `?page=${page}&size=${size}&sort=${sort}&name.contains=${nameFilter}&city.contains=${nameFilter}&stateProvince.contains=${nameFilter}&locationNumber.contains=${nameFilter}&` : '?'}cacheBuster=${new Date().getTime()}`;
  
    const locations = await http.get<ILocation[]>(requestUrl);
    return locations;
  },
  getLocationById: async (id: StringORNumber) => {

    const requestUrl = `${path}/${id}`;
    const location = await http.get<ILocation>(requestUrl);
    const response = await LocationMapper.toDomain(location.data);
    return response;
  },
  createLocation: async (entity: ILocation): Promise<ILocation> => {

    const location = await http.post<ILocation>(extendedPath, entity);
    return location.data;
  },
  updateLocation: async (id: string | number, entity: ILocation): Promise<ILocation> => {

    const requestUrl = `${path}/${id}`;
    const location = await http.put<ILocation>(requestUrl, entity);
    return location.data;
  },
  deleteLocation: async (id: string | number) => {

    const requestUrl = `${path}/${id}`;
    const location = await http.delete<ILocation>(requestUrl);
    return location;
  },
  getAllWithMainAddress: async (queryParams: IQueryParamsGeneric) => {

    const params = new URLSearchParams({
      ...queryParams,
      cacheBuster: `${new Date().getTime()}`
    })
    const requestUrl = `${extendedPath}?${params.toString()}`;

    const promiseGetAll = await http.get<ILocation[]>(requestUrl);
    return promiseGetAll;
  }
}