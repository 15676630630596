import { WarningOutlined } from "@ant-design/icons";
import { TimelineEntryTypeEnum } from "@models/enumerations/timeline-entry-type-enum.model";
import { ITimelineEntry } from "@models/timeline.model";
import { formatFullNameMonth } from "@shared/util/date-utils";
import { useAppSelector } from "@store/store";
import { Button, Col, Row, theme } from "antd";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";

export interface IEntryBodyProps {
    value: ITimelineEntry
}

export const EntryBody = (props: IEntryBodyProps) => {
    const { value } = props;
    const { token: { colorTextTertiary } } = theme.useToken();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const { entity: changeOrder } = useAppSelector((state) => state.ChangeOrder);

    const publishClick = () => {
        if (value.type === TimelineEntryTypeEnum.CHANGE_ORDER_PENDING_DETAIL_SUBMISSION) {
            navigate(`${pathname}/add-costs-detail`);
        } else if (value.type === TimelineEntryTypeEnum.CHANGE_ORDER_PENDING_DETAIL_RESUBMISSION) {
            navigate(`${pathname}/update-costs-detail`);
        } else {
            navigate(`${pathname}/publish`);
        }
    }

    return (
        <Row className="w-full pr-10">
            <Col span={15}>
                <Row>
                    <Col className="font-bold" style={{ color: '#FA8C16' }}>
                        {value.title} <WarningOutlined />
                    </Col>
                </Row>
                {changeOrder?.dueDate &&
                    <Row className="w-full pr-10">
                        <Col span={24} style={{ color: colorTextTertiary, fontSize: 12 }}>Required by {formatFullNameMonth(changeOrder?.dueDate)}</Col>
                    </Row>
                }
            </Col>
            <Col span={9} className="text-right">
                {(value.type === TimelineEntryTypeEnum.CHANGE_ORDER_PENDING_PUBLISHING
                    || value.type === TimelineEntryTypeEnum.CHANGE_ORDER_PENDING_ESTIMATE_RESUBMISSION
                    || value.type === TimelineEntryTypeEnum.CHANGE_ORDER_PENDING_DETAIL_SUBMISSION
                    || value.type === TimelineEntryTypeEnum.CHANGE_ORDER_PENDING_DETAIL_RESUBMISSION)
                    && <Button type="primary" onClick={publishClick}>
                        {value.type === TimelineEntryTypeEnum.CHANGE_ORDER_PENDING_PUBLISHING && t("changeOrder.overviewAndPublishButton")}
                        {value.type === TimelineEntryTypeEnum.CHANGE_ORDER_PENDING_ESTIMATE_RESUBMISSION && t("changeOrder.submitButton")}
                        {value.type === TimelineEntryTypeEnum.CHANGE_ORDER_PENDING_DETAIL_SUBMISSION && t("changeOrder.submitButton")}
                        {value.type === TimelineEntryTypeEnum.CHANGE_ORDER_PENDING_DETAIL_RESUBMISSION && t("changeOrder.submitButton")}
                    </Button>
                }
            </Col>
        </Row>
    );
}
