/* eslint-disable react-hooks/exhaustive-deps */
import { Tabs, TabsProps, theme } from 'antd'
import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useLayoutContex } from '@providers/LayoutProvider';
import { getEntity } from '@store/slices/site';
import { useAppDispatch, useAppSelector } from '@store/store';
import { SiteOverviewTab } from './OverviewTab/SiteOverviewTab';
import { useBaseSitesTabUrl } from './RouteBaseSiteTabs';
import { SitePhotoAlbumTab } from './PhotosTab/SitePhotoAlbumTab';
import { useTranslation } from 'react-i18next';

export const SitesTabsMap = Object.freeze({
  OVERVIEW: 'overview',
  PHOTOS: 'photos',
});

export type SitesTabsMapType = keyof typeof SitesTabsMap;
export type SitesTabsMapTypeValues = (typeof SitesTabsMap)[SitesTabsMapType];

export interface ISitesTabsProps {
  tab: string;
}

export const SiteTabs = (props: ISitesTabsProps) => {
  const { tab } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const {
    token: { colorBgLayout, colorBgContainer, marginLG },
  } = theme.useToken();
  const { setNewStylePropertiesInContentLayout, setHeaderLabelEntityState } = useLayoutContex();

  const { entity: siteEntity } = useAppSelector(state => state.Sites);
  const { siteId } = useParams<'siteId'>();

  const navigate = useNavigate();
  const { validBaseUrl } = useBaseSitesTabUrl();
  const [defaultActiveKey, setDefaultActiveKey] = useState<`${SitesTabsMapTypeValues}` | undefined>(SitesTabsMap.OVERVIEW);

  const resetActiveKey = () => setDefaultActiveKey(undefined);

  useEffect(() => {
    setNewStylePropertiesInContentLayout && setNewStylePropertiesInContentLayout();
  }, []);

  useEffect(() => {
    if (siteId && siteEntity && String(siteEntity.id) !== siteId) {
      dispatch(getEntity(siteId));
    }
  }, [siteId]);

  useEffect(() => {
    setHeaderLabelEntityState && setHeaderLabelEntityState(siteEntity?.name);
    return () => setHeaderLabelEntityState && setHeaderLabelEntityState('');
  }, [siteEntity]);

  useEffect(() => {
    if (tab && Object.values<string>(SitesTabsMap).includes(tab)) {
      setDefaultActiveKey(tab as SitesTabsMapTypeValues);
    }
    return () => {
      resetActiveKey();
    };
  }, [tab]);

  const getItems = (): TabsProps['items'] => {
    const items: TabsProps['items'] = [];
    items.push({
      key: SitesTabsMap.OVERVIEW,
      label: `${t('site.navigation.overview')}`,
      className: 'h-full',
      children: <SiteOverviewTab />,
    });
    items.push({
      key: SitesTabsMap.PHOTOS,
      label: `${t('site.navigation.photos')}`,
      className: 'h-full',
      children: defaultActiveKey === SitesTabsMap.PHOTOS ? <SitePhotoAlbumTab /> : null,
    });
    return items;
  };

  const onTabClick = (activeKey: string, e: React.KeyboardEvent | React.MouseEvent) => {
    if (e?.metaKey) {
      activeKey === SitesTabsMap.OVERVIEW ? window.open(`${validBaseUrl}`) : window.open(`${validBaseUrl}/${activeKey}`);
    } else {
      activeKey === SitesTabsMap.OVERVIEW
        ? navigate(`${validBaseUrl}`, { relative: 'route' })
        : navigate(`${validBaseUrl}/${activeKey}`, { relative: 'route' });

      setDefaultActiveKey(activeKey as SitesTabsMapTypeValues);
    }
  };

  return (
    <>
      <Tabs
        defaultActiveKey={defaultActiveKey}
        activeKey={defaultActiveKey}
        items={getItems()}
        className="customTabs overflow-y-scroll"
        style={{ backgroundColor: colorBgLayout }}
        onTabClick={onTabClick}
        tabBarStyle={{ backgroundColor: colorBgContainer, paddingLeft: `${marginLG}px`, marginBottom: 0 }}
      />
      <Outlet />
    </>
  );
};
