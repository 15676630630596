import { useToken } from 'antd/es/theme/internal';

interface ILocationMapProps {
    widthContainerMap?: number,
    aspectRatio?: {
        width: number,
        height: number
    }
}
export const LocationMap = ({ widthContainerMap, aspectRatio }: ILocationMapProps ) => {

    const maxWidthContainerMap = widthContainerMap || 0;
    const selectedAspectRation = aspectRatio || { width: 21, height: 9};
    const [ , globalToken ] = useToken();

    return (
        <div id="map" className="w-full hidden" style={{ 
                border: `1px solid ${globalToken.colorBorder}`,
                height: `${( maxWidthContainerMap * selectedAspectRation.height ) / selectedAspectRation.width }px` 
            }}>
        </div>
    )
}
