/* eslint-disable react-hooks/exhaustive-deps */
import { getProjectDeliverableDetailById, getProjectDeliverablesData } from '@store/slices/project-deliverables';
import { useAppDispatch } from '@store/store';
import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useBaseProjectTabUrl } from '../../RouteBaseProjectTabs';

export const ProjectDeliverableList = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { validBaseUrl } = useBaseProjectTabUrl();
    const { projectId } = useParams<"projectId">();
    const { RouteParamDeliverableId } = useParams<"RouteParamDeliverableId">();
    let [searchURLParams] = useSearchParams();

    useEffect(() => {
        projectId && dispatch(getProjectDeliverablesData(projectId)).unwrap().then((list) => {
           
            const routeParamDeliverableId = RouteParamDeliverableId && parseInt(RouteParamDeliverableId, 10);
            const isDeliverableListArray = Array.isArray(list);
            const searchParams = searchURLParams?.toString() ? `?${searchURLParams?.toString()}` : '';

            if(routeParamDeliverableId) {
                navigate(`${validBaseUrl}/deliverables/${routeParamDeliverableId}${searchParams}`, { relative: "route" })
                dispatch(getProjectDeliverableDetailById({projectId, projectDeliverableId: String(routeParamDeliverableId) }));
            }

            if (!routeParamDeliverableId && isDeliverableListArray && (list.length > 0)) {
                const deliverableSelected = list[0];
                if (deliverableSelected) {
                    navigate(`${validBaseUrl}/deliverables/${deliverableSelected.id}${searchParams}`, { relative: "route" })
                    dispatch(getProjectDeliverableDetailById({projectId, projectDeliverableId: String(deliverableSelected.id) }));
                }
            }
        })
    }, [])
    
    return (
        <></>
    )
}
