/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from 'antd';
import { Search } from '@components/Layout/HeaderLayout/Search';
import { IHandleOnSearchProps } from '@components/Utils/SearchTable';
import { useAppDispatch, useAppSelector } from '@store/store';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { FloatDropdownFilters } from './ButtonDropDownFilter';
import { filterDeliverablesByAdvancedFilters, filterDeliverablesByBasicFilters, reset } from '@store/slices/project-deliverables';
import DOMPurify from 'dompurify';
// import { ManageDeliverablesMenu } from '../ManageDeliverables/ManageDeliverablesMenu';

interface IProjectDeliverableFilterListProps {}

export const ProjectDeliverableFilterContainer = (props: IProjectDeliverableFilterListProps) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  let [searchURLParams, setURLSearchParams] = useSearchParams();

  const { allFilters } = useAppSelector(store => store.ProjectDeliverables);

  const [initalValueSearch, setInitalvalueSearch] = useState('');

  const handleOnSearch = (props?: IHandleOnSearchProps) => {
    if (typeof props?.valueToSearch === 'string') {
      dispatch(filterDeliverablesByBasicFilters({ deliverableName: props.valueToSearch }));
    }
  };
  const handleSearchOnInput = (props?: IHandleOnSearchProps) => {
    if (typeof props?.valueToSearch === 'string') {
      dispatch(
        filterDeliverablesByBasicFilters({
          ...(props.valueToSearch !== '' && { deliverableName: props.valueToSearch }),
        })
      );
    }
  };
  const handleOnClearSearch = () => {
    dispatch(filterDeliverablesByBasicFilters({}));
  };

  // Get Basic Filter Params and Set the First Time in Store.
  useEffect(() => {
    if (searchURLParams?.get?.('deliverableName')) {
      const deliverableNameToSearchRaw = searchURLParams?.get?.('deliverableName');
      if (typeof deliverableNameToSearchRaw === 'string') {
        const deliverableNameToSearchFieldCleaned = DOMPurify.sanitize(deliverableNameToSearchRaw);
        dispatch(filterDeliverablesByBasicFilters({ deliverableName: String(deliverableNameToSearchFieldCleaned) }));
        setInitalvalueSearch(String(deliverableNameToSearchFieldCleaned));
      }
    }
  }, []);

  // Get Advanced Filter Params and Set the First Time in Store.
  useEffect(() => {
    const status = searchURLParams?.get?.('status');
    const disciplines = searchURLParams?.get?.('disciplines');
    const roles = searchURLParams?.get?.('roles');
    const showOnlyKeyMilestone = searchURLParams?.get?.('showOnlyKeyMilestone');
    const hideNotApplicable = searchURLParams?.get?.('hideNotApplicable');

    dispatch(
      filterDeliverablesByAdvancedFilters({
        ...(typeof status === 'string' && { status: DOMPurify.sanitize(status) }),
        ...(typeof disciplines === 'string' && { disciplines: DOMPurify.sanitize(disciplines) }),
        ...(typeof roles === 'string' && { roles: DOMPurify.sanitize(roles) }),
        ...(typeof showOnlyKeyMilestone === 'string' && { showOnlyKeyMilestone: Boolean(DOMPurify.sanitize(showOnlyKeyMilestone)) }),
        ...(typeof hideNotApplicable === 'string' && { hideNotApplicable: Boolean(DOMPurify.sanitize(hideNotApplicable)) }),
      })
    );
  }, []);

  // Listener of State of Filters and set in the URL
  useEffect(() => {
    setURLSearchParams({
      ...(typeof allFilters?.deliverableName === 'string' && { deliverableName: allFilters.deliverableName }),
      ...(typeof allFilters?.status === 'string' && { status: allFilters?.status }),
      ...(typeof allFilters?.disciplines === 'string' && { disciplines: allFilters?.disciplines }),
      ...(typeof allFilters?.roles === 'string' && { roles: allFilters?.roles }),
      ...(Boolean(allFilters?.showOnlyKeyMilestone) && { showOnlyKeyMilestone: 'true' }),
      ...(Boolean(allFilters?.hideNotApplicable) && { hideNotApplicable: 'true' }),
    });
  }, [allFilters]);

  // On Destroy Component Reset Flow Filters
  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <div className="pb-12 pt-12">
      <Row className="flex flex-wrap w-full justify-between">
        <Col flex={'auto'} className="pl-9 w-min">
          <Search
            enabledAutoExpand={false}
            suffixType={'clear'}
            className="placeholder-italic"
            style={{ width: '100%' }}
            placeholder={t('projectDeliverable.filterByName')}
            handleOnSearch={handleOnSearch}
            handleOnClearSearch={handleOnClearSearch}
            handleSearchOnInput={handleSearchOnInput}
            defaultValue={initalValueSearch}
          />
        </Col>
        <Col flex="none" className="pl-20 w-min">
          <FloatDropdownFilters />
        </Col>
        {/* For pending 'add deliverable to project' feature */}
        {/* <Col>
          <div className="w-min ml-20">
            <ManageDeliverablesMenu />
          </div>
        </Col> */}
      </Row>
    </div>
  );
};
