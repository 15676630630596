import { useEffect, useLayoutEffect } from 'react'
import { Breadcrumb, Typography, theme } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { useResizeDetector } from 'react-resize-detector';
import { UserMenu } from '@components/UserMenu/UserMenu';
import { Support } from '@components/Layout/HeaderLayout/Support';
import { useLayoutContex } from '@providers/LayoutProvider';
import { RightOutlined } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getResults, setQueryString, setFilterByString } from '@store/slices/search';
import { useAppDispatch, useAppSelector } from '@store/store';
import DOMPurify from 'dompurify';
import { FiltersActives } from './Filters';

export const SearchHeader = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { token: { colorBgLayout, marginLG, margin } } = theme.useToken();

    const { height: headerHeight, ref: headerRef } = useResizeDetector({
      refreshMode: 'debounce',
      refreshRate: 50,
    });

    const { setHeaderSizeState } = useLayoutContex();
    
    useLayoutEffect(() => {
      setHeaderSizeState && setHeaderSizeState(headerHeight)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[headerHeight]);

    const { q, filterBy } = useAppSelector((state) => state.Search);

    const [searchParams] = useSearchParams();

    useEffect(() => {
    
      const querySearch =  searchParams.get('q')
      const sanitizedQuerySearch = querySearch ? DOMPurify.sanitize(querySearch): ""
      dispatch(setQueryString(sanitizedQuerySearch));
      dispatch(setFilterByString(searchParams.get('filterBy') || ""))
      dispatch(getResults());
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])  

    const getAllResults = () => {

      dispatch(setFilterByString(""));
      searchParams.delete("filterBy");
      const updatedSearch = searchParams.toString();
      const sanitizedQuerySearch = DOMPurify.sanitize(updatedSearch)
      navigate(`?${sanitizedQuerySearch}`, { replace: true });

    }
    
    return (
      <Header
        ref={headerRef}
        className="flex flex-row justify-between mb-10"
        style={{ padding: 0, background: colorBgLayout, height: 'auto' }}
      >
        <div className="flex flex-col">
          <div className="flex flex-col" style={{ marginLeft: `${marginLG}px`, marginTop: `${margin}px` }}>
            <div>
              <Breadcrumb separator={<RightOutlined style={{ fontSize: 10 }} />}>
                <Breadcrumb.Item>Search</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <span onClick={getAllResults} className={`cursor-pointer ${filterBy === '' ? 'font-bold' : ''}`}>
                    Results
                  </span>
                </Breadcrumb.Item>
                {filterBy !== '' && (
                  <Breadcrumb.Item>
                    <span className="font-bold cursor-pointer" onClick={getAllResults}>
                      {FiltersActives[filterBy as keyof typeof FiltersActives]}
                    </span>
                  </Breadcrumb.Item>
                )}
              </Breadcrumb>
            </div>

            <div className="flex flex-row items-center">
              <Typography.Title level={3} style={{ marginTop: `${margin}px`, marginBottom: `${margin}px`, height: 'auto' }}>
                Showing results for ‘{q}’
              </Typography.Title>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-center" style={{ height: 'auto' }}>
          <div className="realtive h-auto mt-5 mr-20">
            <Support />
          </div>
          <div style={{ height: 'auto' }}>
            <UserMenu />
          </div>
        </div>
      </Header>
    );
}

