import ReactDOMServer from 'react-dom/server';
import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useResizeDetector } from "react-resize-detector"
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Tooltip } from 'antd';
import Title from "antd/es/typography/Title";
import { isFulfilled } from "@reduxjs/toolkit";
import { PlusOutlined } from '@ant-design/icons';
import { AppDispatch, useAppSelector } from "@store/store";
import { clearToCreateEntity, createEntity } from '@store/slices/location';
import { getEntities as getBrands } from "@store/slices/brand";
import { getEntities as getMarkets } from "@store/slices/market";
import { DATE_FORMAT_DEFAULT, generateUniqueStringWithTimestamp } from "@shared/util/date-utils";
import { ILocation } from "../../models/location.model";
import { ADDRESS_TYPE_PRIMARY } from "../../constants";
import { IAddress } from "../../models/address.model";
import { useLayoutContex } from "../../providers/LayoutProvider";
import { LocationMap } from './LocationMap';
import { ArrowBack } from '@components/Icons/ArrowBack';
import { AutoCompleteAddressCustom } from "./AutocompleteAddress/AutoCompleteAddressCustom";
import { useTranslation } from 'react-i18next';
import { GoogleAdvancedMarkerActive } from '@components/Icons/GoogleAdvancedMarker';

const { Option } = Select;
const parser = new DOMParser();

const LocationCreate = () => {

  const navigate = useNavigate();
  const goBack = () => navigate(-1)

  const { setHeaderLabelEntityState, setOldStylePropertiesInContentLayout } = useLayoutContex();

  useLayoutEffect(() => { 
    setOldStylePropertiesInContentLayout && setOldStylePropertiesInContentLayout();
    setHeaderLabelEntityState && setHeaderLabelEntityState("");
    
    return () => setHeaderLabelEntityState && setHeaderLabelEntityState("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const { width: widthContainerMap = 0,  ref: layoutRef } = useResizeDetector();

  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { entity: locationEntity } = useAppSelector((state) => state.Location);
  const brands = useAppSelector((state) => state.Brand.entities);
  const markets = useAppSelector((state) => state.Market.entities);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [animateError, setAnimateError] = useState(false);

  const [hiddenFields, setHiddenFields] = useState<Partial<IAddress>>({ addressType: ADDRESS_TYPE_PRIMARY });
  const [addressInputValue, setAddressInputValue] = useState("");
  
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(clearToCreateEntity());
    dispatch(getBrands({}));
    dispatch(getMarkets({}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [singularBrand, setSingularBrand] = useState<boolean>(false);
  const [defaultBrand, setDefaultBrand] = useState<string | undefined>(undefined);
  useEffect(() => {
    setSingularBrand(brands.length === 1);
    if (brands.length === 1) {
      setDefaultBrand(JSON.stringify(brands[0]));
    }
  }, [brands]);

  useEffect(() => {
    if (singularBrand) {
      form.setFieldsValue({ brand: defaultBrand });
    }
  }, [singularBrand, defaultBrand, form]);

  const onSubmit = () => {
    form.submit();
  };

  const onFinish = (entity: ILocation): void => {
    if (formSubmitting) return;

    setFormSubmitting(true);
    const objectRequestLocation = {
      ...entity,
      ...(entity?.brand && { brand: JSON.parse(entity?.brand as string) }),
      ...(entity?.market && { market: JSON.parse(entity?.market as string) }),
      ...(entity?.address && {
        address: {
          ...entity.address,
          streetAddress: addressInputValue,
          ...hiddenFields,
        },
      }),
      ...(entity?.address?.city && { city: entity?.address?.city }),
      ...(entity?.address?.stateProvince && { stateProvince: entity?.address?.stateProvince }),
    };

    dispatch(createEntity(objectRequestLocation))
      .then(data => {
        if (isFulfilled(data)) {
          navigate(`/location/${data?.payload?.id}`);
        }
      })
      .finally(() => {
        setFormSubmitting(false);
      });
  };

  const playAnimationOfError = () => {
    setAnimateError(true);
    setTimeout(() => {
      setAnimateError(false);
    }, 500);
  };

  const onFinishFailed = (errorInfo: any) => {
    playAnimationOfError();
  };

  const onSelectItemInAutocomplete = (address: any) => {
    const { subAddress = '', city = '', stateProvince = '', postalCode = '', country, latitude, longitude, googleMapPlace } = address;
    form.setFieldsValue({
      address: {
        streetAddress2: subAddress,
        city: city,
        stateProvince: stateProvince,
        postalCode: postalCode,
        country: country,
      },
    });
    setHiddenFields({
      ...hiddenFields,
      latitude,
      longitude,
    });
    addMap(googleMapPlace);
  };

  const addMap = (place: google.maps.places.PlaceResult) => {
    if (!window.google) return;

    const { geometry = {} } = place;
    if (!geometry) return;

    const { location } = geometry;

    document.getElementById('map')?.classList.remove('hidden');
    const map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      center: location?.toJSON(),
      zoom: 15,
      mapTypeControl: false,
      controlSize: 24,
      mapId: `MAP_ID_${generateUniqueStringWithTimestamp()}`,
    });

    const pinSvg = parser.parseFromString(ReactDOMServer.renderToString(<GoogleAdvancedMarkerActive />), 'image/svg+xml').documentElement;

    const marker = new google.maps.marker.AdvancedMarkerElement({
      map,
      content: pinSvg,
      position: geometry.location,
    });
    const InfoPlacePopover = (place: any) => {
      return (
        <div id="infowindow-content" style={{ color: '#000' }}>
          <span id="place-name" className="font-bold">
            {place.name}
          </span>
          <br />
          <span id="place-address">{place?.formatted_address}</span>
        </div>
      );
    };

    const infowindow = new google.maps.InfoWindow({
      content: ReactDOMServer.renderToString(InfoPlacePopover(place)),
    });
    infowindow.open(map, marker);
  };

  const onChangeAddressValue = (text: string) => {
    setAddressInputValue(text);
  };

  return (
    <>
      <Row justify="space-between" className="mb-40">
        <Col span={12}>
          <Space size={12}>
            <ArrowBack onClick={() => goBack()} className="font-bold" />
            <Title level={4} className="imb-0">
              {t('generic.createNew')} {t('generic.location')}
            </Title>
          </Space>
        </Col>
        <Col span={12}>
          <Row justify="end">
            <Space size={12}>
              <Tooltip placement="top" title={t('generic.cancel')}>
                <Button onClick={() => goBack()}>{t('generic.cancel')}</Button>
              </Tooltip>
              <Tooltip placement="top" title={`${t('generic.create')} ${t('generic.location')}`}>
                <Button id="button_submit_create_location" type={'primary'} onClick={() => onSubmit()}>
                  <PlusOutlined className="cursor-pointer" />
                  {t('generic.create')}
                </Button>
              </Tooltip>
            </Space>
          </Row>
        </Col>
      </Row>

      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={locationEntity}
        onFinishFailed={onFinishFailed}
        className={`${animateError ? 'error-animation' : ''}`}
      >
        <Row gutter={8}>
          <Col span={10}>
            <Form.Item name="locationNumber" data-testid="locationNumber" label={t('generic.locationHashSymbol')}>
              <Input placeholder={`Please enter ${t('generic.locationHashSymbol')}`} allowClear />
            </Form.Item>

            <Form.Item
              data-testid="locationName"
              name="name"
              label={`${t('generic.location')} Name`}
              rules={[{ required: true, message: `Please enter ${t('generic.location')} name` }]}
            >
              <Input placeholder={`Please enter ${t('generic.location')} Name`} allowClear />
            </Form.Item>

            <Row gutter={8}>
              <Col span={12}>
                <Form.Item data-testid="brandSelect" name="brand" label={t('location.brand')}>
                  <Select
                    placeholder={`Please select a ${t('location.brand')}`}
                    allowClear
                    value={singularBrand ? defaultBrand : undefined}
                  >
                    {brands.map((item, i) => {
                      return (
                        <Option id={`brandSelect_optionId_${i}`} value={JSON.stringify(item)} key={item.id}>
                          {item.description}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item data-testid="marketSelect" name="market" label={t('location.market')}>
                  <Select placeholder={`Please select a ${t('location.market')}`} allowClear>
                    {markets.map((item, i) => {
                      return (
                        <Option id={`marketSelect_optionId_${i}`} value={JSON.stringify(item)} key={item.id}>
                          {item.description}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item name="openDate" label={t('location.openDate')}>
                  <DatePicker name="openDate" className="w-full" format={DATE_FORMAT_DEFAULT} allowClear={true} />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={12} offset={2} ref={layoutRef}>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item name={['address', 'streetAddress']} label={t('generic.address.addressLine1')}>
                  <AutoCompleteAddressCustom
                    id="address_1"
                    onChangeAutocomplete={onSelectItemInAutocomplete}
                    onChangeValueInput={onChangeAddressValue}
                    placeholder={t('generic.address.addressLine1Placeholder')}
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={24}>
                <Form.Item name={['address', 'streetAddress2']} label={t('generic.address.addressLine2')}>
                  <Input id="address_2" placeholder={t('generic.address.addressLine2Placeholder')} allowClear />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={8}>
                <Form.Item name={['address', 'city']} label={t('generic.address.city')}>
                  <Input placeholder={t('generic.address.city')} allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={['address', 'stateProvince']} label={t('generic.address.state')}>
                  <Input placeholder={t('generic.address.state')} allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={['address', 'postalCode']} label={t('generic.address.postalCode')}>
                  <Input placeholder={t('generic.address.postalCode')} allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item name={['address', 'country']} label={t('generic.address.country')}>
                  <Input id="country" placeholder={t('generic.address.country')} allowClear />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <LocationMap widthContainerMap={widthContainerMap} />
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default LocationCreate;
