/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, theme } from 'antd';
import { useLayoutEffect, useRef, useState } from 'react';
import { useLayoutContex } from '@providers/LayoutProvider';
import { Divider } from 'antd';
import { useResizeDetector } from 'react-resize-detector';

export interface IProjectDeliverablesTabLayoutProps {
    filterArea: React.ReactNode;
    listArea: React.ReactNode;
    detailsArea: React.ReactNode;
}

export const ProjectDeliverablesTabLayout = (props: IProjectDeliverablesTabLayoutProps) => {

    const { filterArea, listArea, detailsArea } = props;

    const { token: { padding, colorBorder, colorBgContainer } } = theme.useToken();

    const { layoutSize, headerSize } = useLayoutContex();

    const tabContainerReference = useRef<HTMLDivElement>(null);

    const containerDeliverablesRef = useRef<HTMLDivElement>(null);

    const [heightTab, setheightTab] = useState(1);

    useLayoutEffect(() => {
        setTimeout(() => {
            const topPosition = (tabContainerReference?.current && tabContainerReference?.current?.getBoundingClientRect().top) || 0;
            const headerSizeLayout = typeof headerSize === "undefined" ? 0 : headerSize;
            const tabBarHeightRelative = topPosition > 0 ? (topPosition - headerSizeLayout) : headerSizeLayout;
            if(typeof layoutSize.layoutHeight !== "undefined") {
                setheightTab(layoutSize?.layoutHeight - tabBarHeightRelative);
            }
        }, 0);
    }, [layoutSize.layoutHeight])

    const { height: heightFilter = 1,  ref: filtersContainerReference } = useResizeDetector({refreshMode: 'debounce', refreshRate: 50 });

    const heightCalculated = { height: `${heightTab}px` };

    return (
        <div ref={tabContainerReference}>
            <div style={heightCalculated} className='relative'>
                
                <Row className='flex flex-row overflow-auto relative' style={{ ...heightCalculated, backgroundColor: colorBgContainer, maxHeight: heightTab }} >
                    <Col
                        span={7}
                        ref={containerDeliverablesRef} 
                        className="relative" 
                        style={{ borderRight: `1.2px solid ${colorBorder}`, ...heightCalculated }}
                    >   
                        <div ref={filtersContainerReference} style={{ position: "sticky", top: 0 , zIndex: 2 }}>
                            <div style={{paddingLeft: `${padding}px`, paddingRight: `${padding}px`}}>
                                {filterArea}
                            </div>
                            <Divider className="mt-0 mb-0" />
                        </div>
                        
                        <div className='flex flex-col relative overflow-auto pt-12' id="containterDeliverableList" 
                            style={{ paddingLeft: `${padding}px`, paddingRight: `${padding}px`, height: `${heightTab - heightFilter - 1}px` }}
                        >   
                            {listArea}
                        </div>
                    </Col>
                    <Col span={17} >
                        <div className='flex flex-col overflow-auto relative' style={heightCalculated}>
                            <div className='flex flex-col justify-center w-full'>
                                {detailsArea}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )

}