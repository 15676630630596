import { theme } from 'antd';
import CompanyContactsTable from './ContactsTable';

export default function CompanyContacts() {
    const { token: { marginLG } } = theme.useToken();


    return (
        <div className="pr-10 pt-20 pb-20" style={{ marginLeft: `${marginLG}px` }}>
            <CompanyContactsTable />
        </div>
    )
}

