export const isArray = (value: unknown): boolean => {
    return Array.isArray(value);
}

export const isArrayWithValues = (value: unknown) => {
    return Array.isArray(value) && value.length > 0
}

export const hasOnlyOne = (value: unknown) => {
    return Array.isArray(value) && value.length === 1
}

export const hasMoreThanOne = (value: unknown) => {
    return Array.isArray(value) && value.length > 1
}

export const getLastItem = (propArray: (any[] | unknown)) => {
    return Array.isArray(propArray) && [...propArray].pop();
}

export const getFirstItem = (propArray: (any[] | unknown)) => {
    return Array.isArray(propArray) && [...propArray].shift();
}

export const sortCollectionByFieldString = (collection: readonly any[], field: string, type: 'asc' | 'desc' = "asc") => {
    return collection.slice().sort((a, b) => {
       const fieldA = a[field].toLowerCase?.();
       const fieldB = b[field].toLowerCase?.();
       const asc = fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0;
       const desc = fieldA > fieldB ? -1 : fieldA < fieldB ? 1 : 0;
       return type === "asc" ? asc : desc
    });
} 

export const sortByOrderProperty = (a: any, b: any) => (a?.order || 0) - (b?.order || 0)