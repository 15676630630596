import { formatDatetimeWithAmPm } from "@shared/util/date-utils";
import { useAppSelector } from "@store/store";
import {  Col, Divider, Row, theme } from "antd";
import { useTranslation } from 'react-i18next';



const ProjectInformation = () => {

    const { token: { fontSizeHeading3, colorBorderSecondary, colorTextSecondary, colorTextTertiary, colorFillSecondary, colorBgContainer, fontSize } } = theme.useToken();

    const { t } = useTranslation();

    const { entity: projectEntity } = useAppSelector((state) => state.Project);

    return (
      <div
        className={`block sticky top-0 mb-20`}
        style={{ border: `1.2px solid ${colorBorderSecondary}`, borderRadius: 10, background: colorBgContainer, zIndex: 10, padding: 10 }}
      >
        <Row justify={'space-between'} align="middle" style={{ paddingTop: 10 }}>
          <Col className="pt-3 pb-3">
            <span className="font-semibold pl-15" style={{ fontSize: fontSizeHeading3, color: colorTextSecondary }}>
              {`${t('projectSettings.projectInformation')}`}
            </span>
          </Col>
        </Row>
        <Divider className="mt-8 mb-8" style={{ borderColor: colorFillSecondary }} />
        <Row className="flex flex-row items-center justify-between">
            <Row className="mb-10 mt-10 flex-col align-end ml-5">
              <div className="flex flex-col pl-10 font-semibold" style={{ color: colorTextSecondary, fontSize: fontSize + 3 }}>
                {t(`projectSettings.createdOn`)}:
              </div>
              <div className="flex flex-col pl-10" style={{ color: colorTextTertiary, fontSize: fontSize + 1 }}>
                {formatDatetimeWithAmPm(projectEntity.createdDate)}
              </div>
            </Row>
        </Row>
        <Row className="mb-10 mt-10 flex-col align-end ml-5">
          <div className="flex flex-col pl-10 font-semibold" style={{ color: colorTextSecondary, fontSize: fontSize + 3 }}>
            {t(`projectSettings.projectTemplate`)}:
          </div>
          <div className="flex flex-col pl-10" style={{ color: colorTextTertiary, fontSize: fontSize + 1 }}>
            {projectEntity.template?.name}
          </div>
        </Row>
      </div>
    );
}

export default ProjectInformation;