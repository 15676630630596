import { DotsHorizontalSvgIcon } from "@components/Icons/DotsHorizontal";
import { NEUTRAL_6_COLOR, NEUTRAL_7_COLOR, NEUTRAL_8_COLOR } from "@providers/ThemeProvider";
import { Popover, theme } from "antd";
import { useState } from "react";
import { MenuOptionsMobile } from "./MenuOptionsMobile";
import { IAttachment } from "@models/attachment.model";

interface IIconMoreMenuMobileProps {
    attachment: IAttachment;
    closeFilePreview: () => void;
    onSuccessDeleteAttachment: () => void;
}

export const IconMoreMenuMobile = (props: IIconMoreMenuMobileProps) => {

    const { attachment, closeFilePreview, onSuccessDeleteAttachment } = props;

    const { token: { colorBgLayout } } = theme.useToken();

    const [isVisiblePopover, setIsVisiblePopover] = useState(false);
    
    const onOpenChange = (open: boolean) => {
        setIsVisiblePopover(open);
    }

    const afterOpenChange = (visible: boolean) => {
        setIsVisiblePopover(visible);
    }
    
    return (
        <Popover 
            className="pt-0 pb-0 pl-0 pr-0"
            rootClassName="pb-20"
            overlayClassName={"pt-0 pb-0 pl-0 pr-0"}
            content={
                <MenuOptionsMobile 
                    isVisible={isVisiblePopover}
                    attachment={attachment} 
                    setIsVisiblePopover={setIsVisiblePopover} 
                    closeFilePreview={closeFilePreview}
                    onSuccessDeleteAttachment={onSuccessDeleteAttachment}
                />
            }
            placement="top" 
            trigger="click"
            arrow={false}
            onOpenChange={onOpenChange}
            afterOpenChange={afterOpenChange}
        >
            <div
                className="flex flex-row items-center justify-center"
                style={{
                    width: 40,
                    height: 40,
                    borderRadius: 44, 
                    border: `1px solid ${ isVisiblePopover ? NEUTRAL_7_COLOR : NEUTRAL_6_COLOR}`,
                    backgroundColor: isVisiblePopover ? NEUTRAL_8_COLOR : 'transparent',
                }}
            >
                <DotsHorizontalSvgIcon style={{ color: colorBgLayout }}/>
            </div>
        </Popover>
    )
}
