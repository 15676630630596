
import { isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from "../reducer.util";
import { createAsyncThunk } from "@reduxjs/toolkit"
import { cleanEntity, getTotalItems } from '@shared/util/entity-utils';
import { addressTypesService } from '@services/address-types.service';
import { IAddressType, defaultValue } from '@models/address-type.model';

const nameOfEntity = "addressType";
const service = addressTypesService;

export const getEntities = createAsyncThunk(
  `${nameOfEntity}/fetch_entity_list`,
  async () => {
      return service.getAddressTypes();
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  `${nameOfEntity}/create_entity`,
  async (entity: IAddressType, thunkAPI) => {
    const result = await service.createAddressType(cleanEntity(entity));
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  `${nameOfEntity}/update_entity`,
  async (entity: IAddressType, thunkAPI) => {
    const result = entity.id && await service.updateAddressType(entity.id, cleanEntity(entity));
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError }
);

const initialState: EntityState<IAddressType> = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

export const AddressTypeSlice = createEntitySlice({
    name: nameOfEntity,
    initialState,
    reducers: {
      clearEntity: (state,) => {
        state.entity = defaultValue;
      },
    },
    extraReducers(builder) {
        builder
          .addMatcher(isFulfilled(getEntities), (state, action) => {
            const { data, headers } = action.payload;
            return {
              ...state,
              loading: false,
              entities: data,
              totalItems: getTotalItems(headers),
            };
          })
          .addMatcher(isPending(getEntities), state => {
            state.errorMessage = null;
            state.updateSuccess = false;
            state.loading = true;
          })
      }
  });
  
  export const { reset, clearEntity } = AddressTypeSlice.actions;
  
  // Reducer
  export default AddressTypeSlice.reducer;

  