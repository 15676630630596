import { http } from '@infrastructure/http/axios.instance';
import { IBIDashboard } from '@models/bi-dashboard.model';

const path = 'bi-dashboards';

export const biDashboardRepository = {
  findAll: async () => {
    return await http.get<IBIDashboard[]>(`${path}/find-all`);
  },
};
