
import { isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from "../reducer.util";
import { createAsyncThunk } from "@reduxjs/toolkit"
import { cleanEntity, getTotalItems } from '../../shared/util/entity-utils';
import { IProject, IProjectTypeOverview, defaultValue } from '../../models/project-model';
import { projectsService } from '../../services/projects.service';
import { asyncLaunchNotification } from './notification';
import { IQueryParamsGeneric } from 'models/utils';
import { updateProjectStatusLastModified } from './project-overview';
import { ThunkRequest } from '@store/thunk.util';

const nameOfEntity = "projects";
const service = projectsService;

export const getEntities = createAsyncThunk(
  `${nameOfEntity}/fetch_entity_list`,
  async (queryParams: IQueryParamsGeneric) => {
    return service.getAll(queryParams);
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  `${nameOfEntity}/fetch_entity`,
  async (id: string | number) => {
    return service.getById(id);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  `${nameOfEntity}/create_entity`,
  async (entity: IProject, thunkAPI) => {
    const result = await service.create(cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    thunkAPI.dispatch(asyncLaunchNotification({
      type: "success",
      config: {
        message: `Project`,
        description: `Project created successfully`
      }
    }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  `${nameOfEntity}/update_entity`,
  async (entity: IProject, thunkAPI) => {
    const result = entity.id && await service.update(entity.id, cleanEntity(entity));
    thunkAPI.dispatch(asyncLaunchNotification({
      type: "success",
      config: { message: `Project`, description: `Project updated successfully` }
    }));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntityArchiveStatus = createAsyncThunk(
  `${nameOfEntity}/update_entity`,
  async (entity: IProject, thunkAPI) => {
    const result = entity.id && await service.updateArchiveStatus(entity.id, cleanEntity(entity));
    thunkAPI.dispatch(asyncLaunchNotification({
      type: "success",
      config: { message: `Changes Saved`, description: entity.archive ?  `Project archived` : 'Project unarchived' }
    }));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntityStatus = createAsyncThunk(
  `${nameOfEntity}/update_entity_status`,
  async (entity: IProject, thunkAPI) => {
    const result = entity.id && await service.updateStatus(entity.id, cleanEntity(entity));
    if (result) {
      thunkAPI.dispatch(asyncLaunchNotification({
        type: "success",
        config: { message: `Project Status`, description: `Project Status updated successfully` }
      }));
      thunkAPI.dispatch(updateProjectStatusLastModified(result.projectStatusLastModified));
    }
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntityType = createAsyncThunk(
  `${nameOfEntity}/update_entity_status`,
  async (request: ThunkRequest<IProjectTypeOverview>, thunkAPI) => {
    const { payload, onSuccess, onError } = request;
    const result = payload.id && await service.updateType(payload).catch(onError);
    if (result) {
      thunkAPI.dispatch(asyncLaunchNotification({
        type: "success",
        config: { message: `Project Type`, description: `Project Type updated successfully` }
      }));
      onSuccess && onSuccess();
    }
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  `${nameOfEntity}/delete_entity`,
  async (id: string | number, thunkAPI) => {
    const result = await service.delete(id);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);


const initialState: EntityState<IProject> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export const ProjectsSlice = createEntitySlice({
  name: nameOfEntity,
  initialState,
  reducers: {
    clearEntity: (state,) => {
      state.entity = defaultValue;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: getTotalItems(headers)
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload as IProject;
      })
      .addMatcher(isFulfilled(updateEntityType), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, updateEntityStatus, updateEntityType), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  }
});

export const { reset, clearEntity } = ProjectsSlice.actions;

// Reducer
export default ProjectsSlice.reducer;
