import React from 'react'
import { IProjectDeliverableUI } from '@models/project-deliverable';
import { Avatar, Button, Col, ConfigProvider, Divider, Row, theme, Timeline } from 'antd';
import { formatDatePipe } from '@shared/util/date-utils';
import { IProjectDeliverableApproval } from '@models/project-deliverable-approval';
import { ModalWrapper } from '@components/Utils/ModalWrapper';
import { HistoryOutlined } from '@ant-design/icons';
import { green, orange } from '@ant-design/colors';
import { NEUTRAL_6_COLOR } from '@providers/ThemeProvider';

interface ViewDetailsApprovalsProps {
    open: boolean,
    setOpen: (state: boolean) => void
    deliverable: IProjectDeliverableUI
}

const isCompleted = (status?: string) => Boolean(status && (getApprovalLabel(status) === approvalTimeLineLabelMap.APPROVED))
const isPendingApproval = (status?: string) => Boolean(status && (getApprovalLabel(status) === approvalTimeLineLabelMap.PENDING_APPROVAL))

export const approvalTimeLineLabelMap = {
  'NOT_STARTED': '',
  'APPROVED': 'Approved Post',
  'PENDING_APPROVAL': 'Pending Approval'
}

export const getApprovalLabel = (status: string) => {
  return approvalTimeLineLabelMap.hasOwnProperty(status) && approvalTimeLineLabelMap[status as keyof typeof approvalTimeLineLabelMap];
}

export const ViewDetailsApprovals:  React.FC<ViewDetailsApprovalsProps>= ({open, setOpen, deliverable}) => {

  const { token: { fontSize } } = theme.useToken();

  const getRequestApproval = () => deliverable?.requestedOn && formatDatePipe(deliverable?.requestedOn)

  const footer = (
    <ConfigProvider theme={{ token: { colorBgElevated: 'rgb(20 20 20)' } }}>
        <Divider className="mt-0 mb-0 pt-0" />
        <Row className="flex flex-row items-center justify-center pt-15 pb-15 pl-30 pr-30">
            <Button  type="primary" onClick={() => { setOpen(false) }} className="pl-30 pr-30">
                Close
            </Button>
        </Row>
    </ConfigProvider>
  )

  const ItemRenderTitle = () => {
    return (
      <Row className='pb-20'>
        <Col>
          <span className='font-semibold'>
            Requested on { getRequestApproval() }
          </span>
        </Col>
      </Row>
    )
  }

  const ItemRenderTimelineApproval: React.FC<{ deliverableApproval: IProjectDeliverableApproval }> = ({deliverableApproval}) => {
    const { token: { fontSize } } = theme.useToken();
    
    const { requestDate } = deliverableApproval;
    const user = deliverableApproval?.user || {};
    const { fullName = "" } = user;
    const isApproved = isCompleted(deliverableApproval.status);
    const colorStatus = isApproved ? green[5] : orange[5];
    const getApprovalText =  deliverableApproval?.status ? getApprovalLabel(deliverableApproval?.status) : "";

    return (
      <Row justify={"center"} align="middle" className='mb-10' >
          <Col>
            <Avatar className="cursor-pointer mr-5" gap={4}>
              { `${fullName.slice(0,2)}`.toUpperCase() }
            </Avatar>
          </Col>
          <Col flex="auto">
            <div className='flex flex-col pl-5 justify-center'>
              <div className='flex flex-col pb-0 font-normal w-full'>
                <span style={{ fontSize: fontSize - 2 }}>{ formatDatePipe(requestDate) }</span>
              </div>
              <div className='flex flex-row justify-start w-full'>
                <span className='font-semibold'>{fullName}</span>
                <span className='ml-10 font-semibold' style={{ color: colorStatus }}>
                  { getApprovalText }
                </span>
              </div>
            </div>
          </Col> 
      </Row>
    )
  }

  
  const FirstDot = ()  => {
    return <div style={{ width: '0.6rem', height: '0.6rem', backgroundColor: NEUTRAL_6_COLOR, borderRadius: '50%' }}></div>
  }
  
  const Dot: React.FC<{ isCompleted: boolean }> = ({isCompleted})  => {
    return <div style={{ width: '0.6rem', height: '0.6rem', 
    borderRadius: '50%',
    backgroundColor: isCompleted ? green[5] : 'transparent', 
    border: isCompleted ? `2px solid ${green[6]}` : `2px solid ${orange[6]}`}}></div>
  }
  
  const DotPendingApproval: React.FC<{ isCompleted?: boolean }> = ({isCompleted})  => {
    return <HistoryOutlined style={{ color: orange[6] }} />
  }

  return (
    <ModalWrapper footer={footer} open={open} >
      <Col flex={"auto"} className="flex items-center justify-center pt-30 pb-20">
          <span className='pt-20 text-center font-bold' style={{ fontSize: fontSize + 4 }}>
              This is the approval flow for this <br/> deliverable to be completed
          </span>
      </Col>
      <Row justify={'center'} className="pt-30 pb-30">
        <Timeline
          className="bg-transparent"
          items={
            [
              { 
                children: <ItemRenderTitle />,
                dot: <FirstDot />
              },

              ...deliverable?.approvals ? deliverable?.approvals?.map((item) => ({ 
                children: <ItemRenderTimelineApproval deliverableApproval={item} />,
                dot: isPendingApproval(item?.status) ? <DotPendingApproval /> : <Dot isCompleted={isCompleted(item?.status)} />
              })) : []
            ]
          }
        />
      </Row>
    </ModalWrapper>
  )
  
}
