/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Skeleton } from "antd";
import { PhotoTabLayout } from "./PhotoTabLayout";
import { TabContainer } from "@components/Utils/TabContainer";
import { useAppDispatch } from "@store/store";
import { getPhotoAlbumEntities, reset } from "@store/slices/photo-album";
import { getPhotosByReferenceTypeAndReferenceId, reset as resetAttachments } from "@store/slices/generic-album-gallery-multimedia";
import { AttachmentReferenceEnum } from "@models/enumerations/attachment-reference-enum.model";
import { PAGINATION_SIZE_DEFAULT_IN_PHOTOS } from "@constants/core";
import { PhotoAlbumTymeEnum } from "@models/enumerations/photo-album-type.enum";
import { PhotoAlbumContextProvider } from "./PhotoAlbumProvider";

export interface IPhotosTabProps {
    referenceId: string | number;
    referenceType: PhotoAlbumTymeEnum
    entityName: string | React.ReactNode;
}

export const PhotosTab = (props: IPhotosTabProps) => {

    const { referenceId, entityName, referenceType } = props;

    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => { dispatch(reset())}
    }, [])

    const [isFirstLoading, setIsFirstLoading] = useState(false)
    
    let [searchURLParams, ] = useSearchParams();

    useEffect(() => {
        setIsFirstLoading(true);

        const albumSelected = searchURLParams.get("albumSelected");

        // Get All Albums
        if (referenceId) {
            dispatch(getPhotoAlbumEntities({
                referenceId: referenceId, 
                referenceType: referenceType
            }))
            .unwrap()
            .finally(() => setIsFirstLoading(false));
            
            if (albumSelected) {
                dispatch(getPhotosByReferenceTypeAndReferenceId({ 
                    referenceId: String(albumSelected),
                    referenceType: AttachmentReferenceEnum.PHOTO_ALBUM,
                    page: 0,
                    size: PAGINATION_SIZE_DEFAULT_IN_PHOTOS 
                }));
            }
        }

        return () => {
            dispatch(resetAttachments())
        }
    }, []);
    
    return (
        <TabContainer>
            <PhotoAlbumContextProvider 
                referenceId={referenceId} 
                entityName={entityName}
                referenceType={referenceType}
            >
                <Skeleton loading={isFirstLoading} paragraph={{ rows: 24 }} active={true} className="pl-16 pr-16 pt-16 h-full">
                    <PhotoTabLayout />
                </Skeleton>
            </PhotoAlbumContextProvider>
        </TabContainer>
    )
}
