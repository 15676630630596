/* eslint-disable react-hooks/exhaustive-deps */
import { URL_PARAM_ATTACHMENT_SELECTED_KEY } from "@constants/photoAlbum";
import { StringORNumber } from "@infrastructure/repositories/utils.repository";
import { isNumber } from "@shared/util/number-util";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { StrategyLoadAttachments } from "../../FilePreviewModal";
import { ChevronLeftSvgIcon } from "@components/Icons/ChevronLeftSvgIcon";
import { IAttachment } from "@models/attachment.model";
import { COLOR_TEXT_BASE, NEUTRAL_5_COLOR, NEUTRAL_8_COLOR, WHITE_ABSOLUTE } from "@providers/ThemeProvider";

export interface IFilePaginatorMobileProps {
    currrentPosition?: number,
    totalAttachments?: number,
    fullFilesCollection: IAttachment[],
    fileSelected: IAttachment,
    strategyLoadAttachments: StrategyLoadAttachments
    loadMoreAttachments?: () => void,
    setFileSelected: (IAttachment) => void,
}

export const FilePaginatorMobile = (props: IFilePaginatorMobileProps) => {

    const { fullFilesCollection, totalAttachments, fileSelected, strategyLoadAttachments, loadMoreAttachments, setFileSelected } = props;

    const [currentPage, setCurrentPage] = useState(0);
    const [totalFiles, setTotalFiles] = useState(0);
    const [isPrevAllowed, setIsPrevAllowed] = useState(false);
    const [isNextAllowed, setIsNextAllowed] = useState(false);

    let [, setURLSearchParams ] = useSearchParams();

    useEffect(() => {
        const findIndex = fullFilesCollection.findIndex((file) => file.id === fileSelected.id) || 0;
        const currentPage = findIndex + 1;
        setCurrentPage(currentPage);
        const totalFiles = (isNumber(totalAttachments) ? totalAttachments! : fullFilesCollection?.length) || 0;
        setTotalFiles(totalFiles);
        setFileSelected(fullFilesCollection[findIndex])
    }, [fullFilesCollection, fileSelected]);

    useEffect(() => {
        if (currentPage === 1) {
            setIsPrevAllowed(false);
        }
        if (currentPage > 1) { 
            setIsPrevAllowed(true);
        }
        if (currentPage < totalFiles) {
            setIsNextAllowed(true);
        }
        if (currentPage === totalFiles) {
            setIsNextAllowed(false);
        }
    }, [currentPage, totalFiles]);

    const setParamAttachmentSelectedId = (id: StringORNumber) => {
        setURLSearchParams((prev) => {
            prev.set(URL_PARAM_ATTACHMENT_SELECTED_KEY, String(id))
            return prev;
        });
    }

    const onHandlePrev = () => {
        if (currentPage > 1) {
            const prevFile = fullFilesCollection[currentPage - 2];
            if (prevFile) {
                setFileSelected(prevFile);
                setParamAttachmentSelectedId(String(prevFile.id));
            }
        }
    }

    const getPositionToAskForMoreAttachments = () => {
        return fullFilesCollection.length - 5;
    }

    const shouldAskForMoreAttachments = () => {
        const isOnDemmandStategy =  strategyLoadAttachments === StrategyLoadAttachments.ON_DEMAND;
        const safePositionToAskForMoreAttachments = getPositionToAskForMoreAttachments();
        return isOnDemmandStategy && currentPage >= safePositionToAskForMoreAttachments;
    }

    const setNextFile = () => {
        const isAllowedNext = currentPage < totalFiles;
        if (isAllowedNext) {
            const nextFile = fullFilesCollection[currentPage];
            if (nextFile) {
                setFileSelected(nextFile);
                setParamAttachmentSelectedId(String(nextFile.id));
            }
        }
    }
    
    const onHandleNext = () => {
        setNextFile();
        if(shouldAskForMoreAttachments()) {
            loadMoreAttachments?.();
        }
    }

    useEffect(() => {

        const handleKeyDown = (event) => {
          switch (event.key) {
            case 'ArrowLeft':
                onHandlePrev();
                // Handle left arrow key press "<-"
                break;
            case 'ArrowRight':
                // Handle right arrow key press "->"
                onHandleNext();
                break;
            default:
                // Do nothing for other keys
                break;
          }
        };

        // Remove event listener when component unmounts
        window?.removeEventListener?.('keydown', handleKeyDown);
    
        // Add event listener when component mounts
        window?.addEventListener?.('keydown', handleKeyDown);
    
        // Clean up event listener when component unmounts
        return () => {
          window?.removeEventListener?.('keydown', handleKeyDown);
        };
      }, [onHandlePrev, onHandleNext]);

    return (
      <>
       <div 
            className="flex flex-row items-center relative z10 justify-between w-full pt-7 pr-15 pb-7 pl-15"
            style={{
                height: 48,
                borderRadius: 48,
                border: `1px solid ${NEUTRAL_8_COLOR}`,
                backgroundColor: COLOR_TEXT_BASE,
            }}
        >
            <ChevronLeftSvgIcon
                className={`ml-4 mr-4 ${isPrevAllowed ? "cursor-pointer" : "cursor-not-allowed" }`}
                style={{ color: WHITE_ABSOLUTE, opacity: isPrevAllowed ? 0.85 : 0.45 }}
                onClick={onHandlePrev}
            />
            <div className="flex flex-row justify-center" style={{ minWidth: 80 }}>
                <span className="mr-6 select-none" style={{ color: WHITE_ABSOLUTE }} >{currentPage}</span>
                <span className="select-none" style={{ color: NEUTRAL_5_COLOR }}>of {totalFiles}</span>
            </div>
            <ChevronLeftSvgIcon 
                className={`mr-4 ml-4 ${isNextAllowed ? "cursor-pointer" : "cursor-not-allowed" }`}
                style={{ color: WHITE_ABSOLUTE, opacity: isNextAllowed ? 0.85 : 0.45 , transform: 'rotate(180deg)' }}
                onClick={onHandleNext}  
            />
        </div>
      </>
    )
}
