import { ITimelineEntry } from "@models/timeline.model";
import { TimelineItemProps } from "antd";
import { TimelineEntryTypeEnum } from "@models/enumerations/timeline-entry-type-enum.model";
import { BaseItemStrategy } from "./BaseEntry";
import { ChangeOrderSimpleItemStrategy } from "./ChangeOrder/SimpleEntry/SimpleEntryStrategy";
import { InitialReviewItemStrategy } from "./ChangeOrder/InitialReviewEntry/InitialReviewStrategy";
import { ChangeOrderFileItemStrategy } from "./ChangeOrder/FileEntry/FileEntryStrategy";
import { ChangeOrderPendingItemStrategy } from "./ChangeOrder/PendingEntry/PendingEntryStrategy";
import { FinalReviewItemStrategy } from "./ChangeOrder/FinalReviewEntry/FinalReviewStrategy";

export interface ITimelineItemStrategy {
    getItem(timelineEntry: ITimelineEntry): TimelineItemProps;
}

const defaultStrategy = new BaseItemStrategy();

export const getTimelineItemStrategy = (status: TimelineEntryTypeEnum): ITimelineItemStrategy => {
    const strategyPool = {
        [TimelineEntryTypeEnum.CHANGE_ORDER_CREATION]: new ChangeOrderSimpleItemStrategy(),
        [TimelineEntryTypeEnum.CHANGE_ORDER_STATUS_CHANGE]: new ChangeOrderSimpleItemStrategy(),
        [TimelineEntryTypeEnum.CHANGE_ORDER_INITIAL_REVIEW]: new InitialReviewItemStrategy(),
        [TimelineEntryTypeEnum.CHANGE_ORDER_FILE_ADDED]: new ChangeOrderFileItemStrategy(),
        [TimelineEntryTypeEnum.CHANGE_ORDER_FILE_REVISION_ADDED]: new ChangeOrderFileItemStrategy(),
        [TimelineEntryTypeEnum.CHANGE_ORDER_FILE_REMOVED]: new ChangeOrderFileItemStrategy(),
        [TimelineEntryTypeEnum.CHANGE_ORDER_FILE_REVISION_REMOVED]: new ChangeOrderFileItemStrategy(),
        [TimelineEntryTypeEnum.CHANGE_ORDER_PENDING_PUBLISHING]: new ChangeOrderPendingItemStrategy(),
        [TimelineEntryTypeEnum.CHANGE_ORDER_PENDING_ESTIMATE_RESUBMISSION]: new ChangeOrderPendingItemStrategy(),
        [TimelineEntryTypeEnum.CHANGE_ORDER_PENDING_DETAIL_SUBMISSION]: new ChangeOrderPendingItemStrategy(),
        [TimelineEntryTypeEnum.CHANGE_ORDER_FINAL_REVIEW]: new FinalReviewItemStrategy(),
        [TimelineEntryTypeEnum.CHANGE_ORDER_PENDING_DETAIL_RESUBMISSION]: new ChangeOrderPendingItemStrategy()
    }

    return strategyPool[status] ?? defaultStrategy;
}