import { AutoComplete, Space, theme } from "antd";
import { useEffect, useState } from "react";
import { EnvironmentOutlined } from "@ant-design/icons";
import { useGoogleMapsProvider } from '../../../providers/GoogleMapsProvider';
import { isArray } from "@shared/util/array-util";

const options = {
    fields: ["address_components", "formatted_address", "geometry","name"],
    types: ["address"]
};

const autocompleteService = () => window.google && new google.maps.places.AutocompleteService();
const div = document.createElement("div")!;

type AutocompleteProps =  React.ComponentPropsWithoutRef<typeof AutoComplete>
interface IAutocompleteProps extends Omit<AutocompleteProps, 'onSearch' | 'options' | 'onSelect' | "onFocus" >{
    onChangeAutocomplete?: (Place: google.maps.places.PlaceResult) => any;
    onChangeValueInput?: (text: string) => any
}

export const AutoCompleteAddressCustom = ({ onChangeAutocomplete, onChangeValueInput, ...restProps }: IAutocompleteProps) => {

    const [sugestions, setSugestions] = useState<any[]>();
    const [rawPredictions, setRawPredictions] = useState<google.maps.places.AutocompletePrediction[] | null>();
    const { token: { colorTextSecondary } } = theme.useToken();
    const { isLoaded } = useGoogleMapsProvider();

    useEffect(() => {
        if (isArray(sugestions)) setSugestions([...sugestions!]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [colorTextSecondary]) 

    useEffect(() => {
        setProcessedSugestions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rawPredictions])

    const onSearch = (text: string) => {
        autocompleteService && autocompleteService().getPlacePredictions(
            { input: text, types: options.types }, 
            displaySuggestions
        ); 
    }

    const onPlaceSelected = (place: google.maps.places.PlaceResult) => {
        let { geometry = {}, address_components: address = []} = place;
        
        console.log(address);
        // Formatting Address
        const subAddress = address.find(a => a.types.some(t => t === 'subpremise'));
        const cityFull = address.find(a => a.types.some(t => t === 'locality' || t === 'postal_town'));
        const stateProvinceFull = address.find((a) => a.types.some(t => t === "administrative_area_level_1"));
        const postalCodeFull = address.find((a) => a.types.some(t => t === "postal_code"));
        const countryFull = address.find((a) => a.types.some(t => t === "country"));

        const formattedAddressResult = {
          name: place?.name,
          subAddress: subAddress?.long_name,
          city: cityFull?.long_name,
          stateProvince: stateProvinceFull?.long_name,
          postalCode: postalCodeFull?.long_name,
          country: countryFull?.long_name,
          latitude: geometry?.location?.lat(),
          longitude: geometry?.location?.lng(),

          googleMapPlace: place,
        };

        const street_address = address.find((a) => a.types.some(t => t === "street_number"));
        const route = address.find((a) => a.types.some(t => t === "route"));
        
        const hasFormatted_addressFields = route?.long_name || street_address?.long_name

        if (hasFormatted_addressFields) {
            const street_address_text = street_address?.long_name || '';
            const route_text = route?.long_name || '';
            const valueInputformatted = `${street_address_text} ${route_text}`;

            if(onChangeValueInput){
                onChangeValueInput(valueInputformatted);
            }
        }
        
        if(!hasFormatted_addressFields && onChangeValueInput && place?.formatted_address){
            onChangeValueInput(place?.formatted_address);
        }

        if(onChangeAutocomplete){
            onChangeAutocomplete(formattedAddressResult);
        }

    }

    const setProcessedSugestions = () => {    
        if (isArray(rawPredictions)) {
            const sugestions = rawPredictions?.map?.((prediction) => processOptionItem(prediction) )
            if (isArray(sugestions)) {
                setSugestions(sugestions);
            }
        }
    }

    const onFocus = (a: any) => {
        setProcessedSugestions();
    }

    const onSelect = (nameSelectedPlace: unknown, optionSelected: any ) => {
        const { prediction } = optionSelected as { prediction: google.maps.places.AutocompletePrediction };
  
        const request = {
            placeId: prediction.place_id,
            fields: options.fields
        };

        if(!isLoaded) return
        
        const servicePlaces = new google.maps.places.PlacesService(div);
        servicePlaces.getDetails(request, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                place && onPlaceSelected(place)
            }
        })
    }
    
    const displaySuggestions = (
        predictions: google.maps.places.AutocompletePrediction[] | null,
        status: google.maps.places.PlacesServiceStatus
    ) => {
        if (isLoaded && status === google.maps.places.PlacesServiceStatus.OK ) {
            setRawPredictions(predictions);
        }
    }

    const formatPredictionText = (prediction: google.maps.places.AutocompletePrediction) => {
        const { structured_formatting } = prediction;
        const { main_text, secondary_text, main_text_matched_substrings,  } = structured_formatting;

        const customFormatOfAllMatches = (str: string, matched_substrings: google.maps.places.PredictionSubstring[]) => {
            let arrOfMatches: string[] | undefined;
            if (isArray(matched_substrings)) {
                arrOfMatches = matched_substrings.map((match) => str.substring(match.offset, (match.offset + match.length)));
            }

            if (isArray(arrOfMatches)) {
                let re = new RegExp(arrOfMatches!.join("|"),"g");
                return str.replace(re, (matched: string) => {
                    return `<b>${matched}</b>`;
                });
            } else {
                return str;
            }
        }

        let mainTextFormatted = customFormatOfAllMatches(main_text, main_text_matched_substrings);

        let secondTextFormatted = ""
        
        // @ts-ignore:next-line
        const secondary_text_matched_substrings = structured_formatting['secondary_text_matched_substrings'];
        if ( isArray(secondary_text_matched_substrings) ) {
            secondTextFormatted = customFormatOfAllMatches(secondary_text, secondary_text_matched_substrings);
        } else if (secondary_text) {
            secondTextFormatted = secondary_text;
        }
        
        return (
            <div>
                { main_text && (<span className="text-xs mr-5" dangerouslySetInnerHTML={{ __html: mainTextFormatted }}></span>) }
                { secondary_text && (<span className="text-tiny-065" style={{ color: colorTextSecondary }}  dangerouslySetInnerHTML={{ __html: secondTextFormatted }} ></span>) }
            </div>
        );
    }

    const customOptionLabel = (prediction: google.maps.places.AutocompletePrediction ) => (
        <Space size={8}>
            <EnvironmentOutlined />
            { formatPredictionText(prediction) }
        </Space>
    )

    const processOptionItem = (prediction: google.maps.places.AutocompletePrediction) => { 
        return {
            value: prediction.description,
            label: customOptionLabel(prediction),
            prediction: prediction
        }
    };

    return (
        <AutoComplete
            options={sugestions}
            onSelect={onSelect}
            onSearch={onSearch}
            onFocus={onFocus}
            {...restProps}
        />
    );
};


