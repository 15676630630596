import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { IAclTarget, defaultValue } from '@models/acl-target.model';
import { createEntitySlice } from '@store/reducer.util';
import { EntityState } from "@store/reducer.util";
import { aclTargetsService } from '@services/acl-targets.service';

const initialState: EntityState<IAclTarget> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

// Actions
export const getEntities = createAsyncThunk('aclTarget/fetch_entity_list', async () => {
  return await aclTargetsService.getAclTargets();
});

// slice

export const AclTargetSlice = createEntitySlice({
  name: 'aclTarget',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isPending(getEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = AclTargetSlice.actions;

// Reducer
export default AclTargetSlice.reducer;
