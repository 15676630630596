import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getEntities as getRfiReasons } from "@store/slices/rfi-reason";
import { Card, Switch, Tag, theme } from "antd";
import useVisible from "@HOOKs/UseVisible";
import { EditOutlined } from "@ant-design/icons";
import { NEUTRAL_7_COLOR } from "@providers/ThemeProvider";
import "@styles/components/admin-item-card.less";
import { RFIReasonEdit } from "./RFIReasonEdit";
import { IRfiReason } from "@models/rfi-reason.model";
import { useTranslation } from "react-i18next";

const ListItem = (rfiReason: IRfiReason) => {
  const { t } = useTranslation();
  return <li style={{ color: NEUTRAL_7_COLOR }} className="pb-4">{rfiReason.name} {rfiReason.disabled && <Tag>{t("generic.disabled")}</Tag>}</li>
}

interface IListItemEditableProps {
  rfiReason: IRfiReason;
  rfiReasonSelected: IRfiReason;
  editRfiReasonClicked: any;
}

const ListItemEditable = (props: IListItemEditableProps) => {

  const { rfiReason, rfiReasonSelected, editRfiReasonClicked } = props;
  const { t } = useTranslation();

  return <div className={`list-item-editable ${(rfiReasonSelected?.id === rfiReason.id ? 'selected' : '')}`} onClick={() => editRfiReasonClicked(rfiReason)}>
    <div>
      <div className="handle-container">
        <div className="handle-dot"></div>
      </div>
    </div>
    <div className="description">
      {rfiReason.name} {rfiReason.disabled && <Tag>{t("generic.disabled")}</Tag>}
    </div>
    <span className="edit-container">
      <span className="edit-link">
        <EditOutlined /> {t("generic.edit")}
      </span>
    </span>
  </div>
}

export const RFIReasonCard = (props: any) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { token: { colorPrimary, colorTextDisabled } } = theme.useToken();

  const loadRfiReasons = () => {
    dispatch(
      getRfiReasons()
    );
  }

  useEffect(() => {
    loadRfiReasons();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { entities: rfiReasons } = useAppSelector((state) => state.RfiReason);

  const rfiReasonsSorted = useMemo(() => {
    const copied = JSON.parse(JSON.stringify(rfiReasons))
    return copied.sort((a: IRfiReason, b: IRfiReason) => {
      const nameA = a?.name || ''; 
      const nameB = b?.name || '';
    
      return String(nameA).localeCompare(String(nameB));
    });
  }, [rfiReasons])

  const [openEditRfiReason, toogleEditRfiReason] = useVisible();
  const [rfiReasonSelected, setRfiReasonSelected] = useState<IRfiReason>({})
  const [isEditing, setIsEditing] = useState<boolean>(false)

  useEffect(() => {
    if (!openEditRfiReason) {
      setRfiReasonSelected({})  
    }
  }, [openEditRfiReason]);

  const onModifiedChanges = (rfiReason: IRfiReason) => {
    loadRfiReasons();
  }

  const toggleEdit = () => {
    setIsEditing(!isEditing)
  };

  const editRfiReason = (rfiReason : IRfiReason) => {
    setRfiReasonSelected(rfiReason)
    toogleEditRfiReason()
  }

  const createNewRfiReason = () => {
    setRfiReasonSelected({})
    toogleEditRfiReason();
  }
  
  return (
    <>
      <RFIReasonEdit open={openEditRfiReason} toogle={toogleEditRfiReason} rfiReason={rfiReasonSelected} onModifiedChanges={onModifiedChanges} />
      <Card title={<>{t("admin.rfiReason")}<span className="item-count">{rfiReasons?.length}</span></>} bordered={false} className="admin-item-card" extra={<div><span className="extra-label" style={{ color: (isEditing ? colorPrimary : colorTextDisabled) }} onClick={toggleEdit}>{isEditing ? t("admin.managing") : t("admin.manage")}</span><Switch checked={isEditing} onClick={toggleEdit} /></div>}>
        <ul className={isEditing ? 'editing' : ''}>
            {rfiReasonsSorted.map(rfiReason => 
            <div
                key={rfiReason.id}>
                <>
                {isEditing && <ListItemEditable rfiReason={rfiReason} rfiReasonSelected={rfiReasonSelected} editRfiReasonClicked={editRfiReason} />}
                {!isEditing && <ListItem {...rfiReason} />}
                </>
            </div>
            )}
        </ul>
        {isEditing && <>
            <div className="spacer"></div>
            <span onClick={createNewRfiReason} className="add-new-btn">+ {t("admin.addNewRFIReason")}</span>
        </>}
        </Card>
    </>
  )
}