import { BackButton } from "@components/Utils/BackButton";
import { TabContainer } from "@components/Utils/TabContainer";
import { FeatureFlagOptionEnum } from "@models/enumerations/feature-flag-option.enum";
import { ISetting, oxblueAppId, oxbluePassword, oxblueUsername } from "@models/setting.model";
import { useFeatureFlagsProvider } from "@providers/FeatureFlagsProvider";
import { getOxBlueSession } from "@store/slices/ox-blue-projects";
import { IUpdateSettingsArgs, getEntities, updateEntities } from "@store/slices/setting";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Card, Col, Row, Switch, theme, Form, Input, Button } from "antd"
import _ from "lodash";
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IResultText {
    success: boolean
    message: string
}

export const IntegrationTab = () => {

    const { token: { colorPrimary, colorTextDisabled, colorBorderSecondary, colorBgContainer, colorSuccessText, colorErrorText } } = theme.useToken();
    const [isEditing, setIsEditing] = useState<boolean>(false)

    const toggleEdit = () => {
        setIsEditing(!isEditing)
    };

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { flags, isFlagEnabled } = useFeatureFlagsProvider();
    const isOxBlueEnabled = isFlagEnabled(FeatureFlagOptionEnum.OX_BLUE_INTEGRATION);

    const { entities: settings } = useAppSelector((state) => state.Setting);
    const [oxblueUsernameSetting, setOxblueUsernameSetting] = useState<ISetting | undefined>(undefined)
    const [oxblueUsernameValue, setOxblueUsernameValue] = useState<string>("")
    const [oxblueAppIdSetting, setOxblueAppIdSetting] = useState<ISetting | undefined>(undefined)
    const [oxblueAppIdValue, setOxblueAppIdValue] = useState<string>("")
    const [oxbluePasswordSetting, setOxbluePasswordSetting] = useState<ISetting | undefined>(undefined)
    const [oxbluePasswordValue, setOxbluePasswordValue] = useState<string>("")
    const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false)
    const [form] = Form.useForm();
    const [resultText, setResultText] = useState<IResultText | null>(null)
    const [isSaving, setIsSaving] = useState<boolean>(false)

    useEffect(() => {
        dispatch(
            getEntities()
        );
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const usernameSetting = settings?.find(setting => setting.key === oxblueUsername);
        if (usernameSetting !== undefined) {
            setOxblueUsernameSetting(usernameSetting)
            setOxblueUsernameValue(usernameSetting.value || "")
        }
        const appIdSetting = settings?.find(setting => setting.key === oxblueAppId);
        if (appIdSetting !== undefined) {
            setOxblueAppIdSetting(appIdSetting)
            setOxblueAppIdValue(appIdSetting.value || "")
        }
        const passwordSetting = settings?.find(setting => setting.key === oxbluePassword);
        if (passwordSetting !== undefined) {
            setOxbluePasswordSetting(passwordSetting)
            setOxbluePasswordValue(passwordSetting.value || "")
        }
        setSettingsLoaded(true)
        // eslint-disable-next-line
    }, [settings])

    const navigateBack = () => {
        navigate('/admin/application-configuration')
    }

    const saveAndTest = () => {
        const updatedSettings: ISetting[] = [
            // @ts-ignore
            _.clone(oxblueUsernameSetting),
            // @ts-ignore
            _.clone(oxbluePasswordSetting),
            // @ts-ignore
            _.clone(oxblueAppIdSetting),
        ];
        // @ts-ignore
        updatedSettings[0].value = oxblueUsernameValue || null;
        // @ts-ignore
        updatedSettings[1].value = oxbluePasswordValue;
        // @ts-ignore
        updatedSettings[2].value = oxblueAppIdValue || null;
        const args: IUpdateSettingsArgs = { 
            entities: updatedSettings,
            onSuccess: () => {
                getEntities();
                dispatch(getOxBlueSession()).unwrap().then((value: any) => {
                    if (value && !value.validSession) {
                        setResultText({ success: false, message: t("admin.notWorking") })
                    }
                    else if (value && value.validSession) {
                        setResultText({ success: true, message: t("admin.working") })
                    }
                    else {
                        setResultText({ success: false, message: t("admin.notWorking") })
                    }
                    setIsSaving(false)
                }).catch(() => {
                    setIsSaving(false)
                    setResultText({ success: false, message: t("admin.notWorking") })
                })
            }
        }
        setIsSaving(true)
        dispatch(
            updateEntities(args)
        );
    }

    return (
        <TabContainer>
            <div className="flex flex-col overflow-auto">
                <Row>
                    <Col span={24}>
                        <div className="flex flex-row items-center pl-10 pt-11 pb-11" style={{ borderTop: `1px solid ${colorBorderSecondary}`, borderBottom: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                            <BackButton onClick={navigateBack} >{t("admin.applicationConfiguration")}</BackButton>
                        </div>
                    </Col>
                </Row>
            </div>
            {isOxBlueEnabled && <div style={{ marginLeft: '24px', marginTop: '16px' }}>
                <Card title={"OxBlue"} bordered={false} className="admin-item-card" extra={<div><span className="extra-label" style={{ color: (isEditing ? colorPrimary : colorTextDisabled) }} onClick={toggleEdit}>{isEditing ? 'Managing' : 'Manage'}</span><Switch checked={isEditing} onClick={toggleEdit} /></div>}>
                {settingsLoaded && <Form
                    className="w-full"
                    form={form}
                    layout="vertical"
                >
                    <Form.Item label={t("admin.username")} rules={[{ required: false }]}>
                        <Input
                            disabled={!isEditing}
                            value={oxblueUsernameValue} 
                            placeholder={t("admin.username")}
                            onChange={(e) => { setOxblueUsernameValue(e.target.value) }} />
                    </Form.Item>
                    <Form.Item label="ID" rules={[{ required: false }]}>
                        <Input
                            disabled={!isEditing}
                            value={oxblueAppIdValue} 
                            placeholder="ID" 
                            onChange={(e) => { setOxblueAppIdValue(e.target.value) }} />
                    </Form.Item>
                    <Form.Item label={t("admin.password")} rules={[{ required: false }]}>
                        <Input
                            disabled={!isEditing}
                            value={oxbluePasswordValue} 
                            placeholder={t("admin.password")}
                            onChange={(e) => { setOxbluePasswordValue(e.target.value) }} />
                    </Form.Item>
                </Form>}
                {settingsLoaded && isEditing && <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            {resultText && <span style={{ color: (resultText.success ? colorSuccessText : colorErrorText)}}>{resultText.message}</span>}
                        </div>
                        <Button loading={isSaving} style={{ flex: 0 }}type="primary" ghost onClick={saveAndTest}>{t("admin.saveAndTest")}</Button>
                    </div>}
                </Card>
            </div>}
        </TabContainer>
    )
}