
import { IChangeOrderRevision } from '@models/change-order-revision.model';
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { changeOrderRevisionsService } from '@services/change-order-revisions.service';
import { serializeAxiosError } from "../reducer.util";
import { cleanEntity } from '../../shared/util/entity-utils';

export const getEntities = createAsyncThunk(
    'changeOrderRevisions/fetch_entity_list',
    async (changeOrderId: string | number) => {
        return changeOrderRevisionsService.getChangeOrderRevisions(changeOrderId);
    },
    { serializeError: serializeAxiosError }
);

export interface IUpdateChangeOrderRevisionArgs {
    entity: IChangeOrderRevision,
    onSuccess?: () => void
}

export const partialUpdateEntity = createAsyncThunk(
    'changeOrderRevisions/update_entity',
    async ({ entity, onSuccess }: IUpdateChangeOrderRevisionArgs, thunkAPI) => {
        const result = entity.id && await changeOrderRevisionsService.partialUpdate(entity.id, cleanEntity(entity));
        onSuccess?.();
        return result;
    },
    { serializeError: serializeAxiosError }
);

interface IChangeOrdersRevisionsState {
    loading: boolean;
    updating: boolean;
    updateSuccess: boolean;
    errorMessage: string | null;
    entities: ReadonlyArray<IChangeOrderRevision>;
}

const initialState: IChangeOrdersRevisionsState = {
    loading: false,
    updating: false,
    updateSuccess: false,
    errorMessage: null,
    entities: []
};

export const ChangeOrderRevisionsSlice = createSlice({
    name: 'changeOrderRevisions',
    initialState,
    reducers: {
        reset: (state,) => {
            return initialState;
        },
    },
    extraReducers(builder) {
        builder
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const { data } = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data,
                };
            })
            .addMatcher(isPending(getEntities), state => {
                state.errorMessage = null;
                state.loading = true;
            })
            .addMatcher(isFulfilled(partialUpdateEntity), (state, action) => {
                state.updating = false;
                state.loading = false;
                state.updateSuccess = true;
            })
            .addMatcher(isPending(partialUpdateEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.updating = true;
            });
    }
});

export const { reset } = ChangeOrderRevisionsSlice.actions;

// Reducer
export default ChangeOrderRevisionsSlice.reducer;

