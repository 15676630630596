import { SVGProps } from "react";

type IGoogleAdvancedMarkerProps = SVGProps<SVGSVGElement>

export const GoogleAdvancedMarker = (props: IGoogleAdvancedMarkerProps) => {
    
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 15.3333C15.1159 15.3333 14.2681 14.9821 13.6429 14.357C13.0178 13.7319 12.6666 12.884 12.6666 12C12.6666 11.1159 13.0178 10.2681 13.6429 9.64294C14.2681 9.01782 15.1159 8.66663 16 8.66663C16.884 8.66663 17.7319 9.01782 18.357 9.64294C18.9821 10.2681 19.3333 11.1159 19.3333 12C19.3333 12.4377 19.2471 12.8712 19.0796 13.2756C18.912 13.68 18.6665 14.0475 18.357 14.357C18.0475 14.6665 17.68 14.912 17.2756 15.0796C16.8712 15.2471 16.4377 15.3333 16 15.3333ZM16 2.66663C13.5246 2.66663 11.1506 3.64996 9.4003 5.4003C7.64996 7.15064 6.66663 9.52461 6.66663 12C6.66663 19 16 29.3333 16 29.3333C16 29.3333 25.3333 19 25.3333 12C25.3333 9.52461 24.35 7.15064 22.5996 5.4003C20.8493 3.64996 18.4753 2.66663 16 2.66663Z" fill="#E6F7FF"/>
            <path d="M16 8.66663C16.884 8.66663 17.7319 9.01782 18.357 9.64294C18.9821 10.2681 19.3333 11.1159 19.3333 12C19.3333 12.4377 19.2471 12.8712 19.0796 13.2756C18.912 13.68 18.6665 14.0475 18.357 14.357C18.0475 14.6665 17.68 14.912 17.2756 15.0796C16.8712 15.2471 16.4377 15.3333 16 15.3333C15.1159 15.3333 14.2681 14.9821 13.6429 14.357C13.0178 13.7319 12.6666 12.884 12.6666 12C12.6666 11.1159 13.0178 10.2681 13.6429 9.64294C14.2681 9.01782 15.1159 8.66663 16 8.66663ZM16 2.66663C18.4753 2.66663 20.8493 3.64996 22.5996 5.4003C24.35 7.15064 25.3333 9.52461 25.3333 12C25.3333 19 16 29.3333 16 29.3333C16 29.3333 6.66663 19 6.66663 12C6.66663 9.52461 7.64996 7.15064 9.4003 5.4003C11.1506 3.64996 13.5246 2.66663 16 2.66663ZM16 5.33329C14.2318 5.33329 12.5362 6.03567 11.2859 7.28591C10.0357 8.53616 9.33329 10.2319 9.33329 12C9.33329 13.3333 9.33329 16 16 24.9466C22.6666 16 22.6666 13.3333 22.6666 12C22.6666 10.2319 21.9642 8.53616 20.714 7.28591C19.4638 6.03567 17.7681 5.33329 16 5.33329Z" fill="#69C0FF"/>
        </svg>
    )
}

export const GoogleAdvancedMarkerActive = (props: IGoogleAdvancedMarkerProps) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_19870_24268)">
                <path d="M16 15.3333C15.1159 15.3333 14.2681 14.9821 13.6429 14.357C13.0178 13.7319 12.6666 12.884 12.6666 12C12.6666 11.1159 13.0178 10.2681 13.6429 9.64294C14.2681 9.01782 15.1159 8.66663 16 8.66663C16.884 8.66663 17.7319 9.01782 18.357 9.64294C18.9821 10.2681 19.3333 11.1159 19.3333 12C19.3333 12.4377 19.2471 12.8712 19.0796 13.2756C18.912 13.68 18.6665 14.0475 18.357 14.357C18.0475 14.6665 17.68 14.912 17.2756 15.0796C16.8712 15.2471 16.4377 15.3333 16 15.3333ZM16 2.66663C13.5246 2.66663 11.1506 3.64996 9.4003 5.4003C7.64996 7.15064 6.66663 9.52461 6.66663 12C6.66663 19 16 29.3333 16 29.3333C16 29.3333 25.3333 19 25.3333 12C25.3333 9.52461 24.35 7.15064 22.5996 5.4003C20.8493 3.64996 18.4753 2.66663 16 2.66663Z" fill="#1890FF"/>
            </g>
            <defs>
                <filter id="filter0_d_19870_24268" x="0" y="0" width="32" height="34" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="2"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.043 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_19870_24268"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_19870_24268" result="shape"/>
                </filter>
            </defs>
        </svg>

    )
}
