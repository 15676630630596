import useColorStatus from '@HOOKs/UseColorStatus';
import { IProjectDeliverableUI } from '@models/project-deliverable';
import { ReactElement, useLayoutEffect } from 'react';

export interface StatusLabelProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
    deliverable: IProjectDeliverableUI,
    renderOnEmpty?: ReactElement<unknown>
}

export const StatusProjectDeliverableLabel = ({ deliverable, style, renderOnEmpty, ...restProps }: StatusLabelProps) => {

    const [colorStatus, setStatus] = useColorStatus();
    
    useLayoutEffect(() => {
        deliverable?.status && setStatus(deliverable?.status)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliverable])

    return (
       <span style={ colorStatus ? { ...style, color: colorStatus } : style }>{ deliverable.statusLabelUI || renderOnEmpty }</span>
    )
}
