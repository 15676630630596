/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@store/store";
import { ConfigProvider, Drawer, Space, theme, Typography } from "antd";
import { showAuditHistory } from "@store/slices/audit-history";
import { useTranslation } from "react-i18next";
import { CloseButtonRounded } from "@components/Utils/CloseButtonRounded";
import { CustomCollapseConfigProps } from "./CustomCollapseViewDetails";
import { useResizeDetector } from "react-resize-detector";
import { useAuditHistoryProvider } from "./AuditHistoryProvider";
import { isNumber } from "lodash";

const { Title } = Typography;

export const AuditHistoryDrawer = ({ children }: { children: React.ReactNode}) => {
    
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { showHistory, settings } = useAppSelector((store) => store.AuditHistory);
    const { token: { colorPrimary } } = theme.useToken();

    const { setHeaderHeight } = useAuditHistoryProvider();
    const { height: layoutHeight, ref: layoutRef } = useResizeDetector({
        refreshMode: 'debounce',
        refreshRate: 50,
    });

    useEffect(() => {
        isNumber(layoutHeight) && setHeaderHeight(layoutHeight!)
    }, [layoutHeight])

    const onClose = () => {
        dispatch(showAuditHistory({ showHistory: false}))
    }

    const titleDrawer = useMemo(() => (
        <div ref={layoutRef}>
            <Title level={5} className="imb-0" style={{ lineHeight: "28px", padding: 16 }}>
                <span className="font-medium pl-8 text-color-neutral-9">
                    { `${t("audit.historyFor")} ${settings.entityLabel}:` }
                </span>
                <span className="font-medium ml-5" style={{ color: colorPrimary }}>
                    { settings.entityNameToObserve }
                </span>
            </Title>
        </div>
    ), [settings, colorPrimary, t]);
    
    return (
        <ConfigProvider theme={{ components: { Collapse: CustomCollapseConfigProps }}}>
          <Drawer
            title={titleDrawer}
            width={590}
            onClose={onClose}
            open={showHistory}
            closable={false}
            styles={{ 
              header: { padding: 0 }, 
              body: { padding: 0 } }
            }
            extra={
              <Space>
                <CloseButtonRounded onClick={onClose} />
              </Space>
            }
          >
            {children}
          </Drawer>
        </ConfigProvider>
      );
}
