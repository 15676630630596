import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Avatar, Button, Col, ConfigProvider, Row, Table, theme, Modal } from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import { useAppDispatch, useAppSelector } from "@store/store";

/* eslint-disable react-hooks/exhaustive-deps */
import { EmptyCellTable } from "@components/Utils/EmptyCellTable";
import { IUser } from "@models/user.model";
import { deleteEntity, getEntities } from "@store/slices/group-bindings";
import { DeleteOutlined, ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import { TabContainer } from "@components/Utils/TabContainer";
import useVisible from "@HOOKs/UseVisible";
import { ChevronRightSvgIcon } from "@components/Icons/ChevronRightSvgIcon";
import { IGroupBinding } from "@models/group-binding.model";
import { GroupUserAdd } from "./GroupUserAdd";


export interface IGroupUserListProps {
    groupId: any,
    groupName: string | undefined
};

export const GroupUserList = (props: IGroupUserListProps) => {

    const { groupId } = props;

    const { token: { marginLG, colorPrimary, colorTextTertiary, fontSize, colorBgContainer, colorBorderSecondary, colorText } } = theme.useToken();

    const [openAddUser, toogleAddUser] = useVisible();

    const columns: ColumnsType<IGroupBinding> = [
        {
            title: (
                <div className="TableCustomizedflex flex-row items-center pl-10">
                    <Avatar className="cursor-pointe h-0" gap={4} style={{ opacity: 0 }} >--</Avatar>
                    <span className="ml-15">First Name</span>
                </div>
            ),
            onCell: (groupBinding: IGroupBinding)  => ({ onClick: () => goToDetailUser(groupBinding.user) }),
            render: (groupBinding: IGroupBinding) => {
                return (
                  (
                    <div className="flex flex-row items-center pl-10 ">
                      <Avatar className="cursor-pointer" gap={4}>
                        {groupBinding?.user?.firstName && groupBinding?.user?.lastName
                          ? `${groupBinding?.user?.firstName?.[0]}${groupBinding?.user?.lastName?.[0]}`.toUpperCase()
                          : ''}
                      </Avatar>
                      <span className="ml-15 font-medium">{groupBinding?.user?.firstName}</span>
                    </div>
                  ) || <EmptyCellTable />
                ); 
            }
        },
        {
            title: 'Last Name',
            onCell: (groupBinding: IGroupBinding, index?: number)  => ({ onClick: () => goToDetailUser(groupBinding.user) }),
            render: (groupBinding: IGroupBinding) => { return <span className="font-medium">{groupBinding?.user?.lastName}</span> || <EmptyCellTable/> }
        },
        {
            title: 'Login',
            onCell: (groupBinding: IGroupBinding)  => ({ onClick: () => goToDetailUser(groupBinding.user) }),
            render: (groupBinding: IGroupBinding) => { return <span style={{ color: colorTextTertiary }}>{groupBinding?.user?.login}</span> || <EmptyCellTable/> },
            ellipsis: true,
        },
        {
            title: 'Email',
            onCell: (groupBinding: IGroupBinding, index?: number)  => ({ onClick: () => goToDetailUser(groupBinding.user) }),
            render: (groupBinding: IGroupBinding) => { return <span style={{ color: colorTextTertiary }}>{groupBinding?.user?.email}</span> || <EmptyCellTable/> },
            ellipsis: true,
        },
        {
            title: '',
            width: "300px",
            render: (groupBinding: IGroupBinding) => { return (
                <Row>
                    <Col>
                        <div 
                            onClick={() => onClickRemoveUserBtn(groupBinding)}
                            style={{ color: '#ff4d4f', width: '120px' }}
                        >
                            <DeleteOutlined className="mr-8 scale-90" />
                            <span>Remove</span>
                        </div>
                    </Col>
                    <Col>
                        <div 
                            onClick={() => goToDetailUser(groupBinding?.user)} 
                            style={{ color: colorPrimary }}
                        >
                            <span>View Details</span>
                            <ChevronRightSvgIcon className="ml-8 mr-8 scale-90" />
                        </div>
                    </Col>
                </Row>
            ) },
        },
    ];

    const onClickRemoveUserBtn = (groupBinding: IGroupBinding | undefined | null) => {
        if (typeof groupBinding !== 'undefined' && groupBinding) {
            const t = groupBinding;
            modal.confirm({
                title: 'You want to remove this user?',
                icon: <ExclamationCircleFilled />,
                content: `If you click 'Confirm', ${t.user?.login} will be removed from ${props.groupName}`,
                onOk() {
                    dispatch(
                        deleteEntity(`${t.id}`)
                    ).then(() => {
                        filterUsers();
                    });
                },
                onCancel() {
                  console.log('Cancel');
                },
            });
        }
    }

    const { entities , totalItems, loading } = useAppSelector((state) => state.GroupBindings);

    const dispatch = useAppDispatch();
    
    const navigate = useNavigate();

    const [groupBindingSelected] = useState<IGroupBinding>({})

    const [tableParams, setTableParams] = useState({
        size: 10,
        current: 0,
        sort: `lastName,asc`
    });

    const filterUsers = () => {
        dispatch(
            getEntities(groupId)
        );
    }

    useEffect(() => {
        filterUsers();
    },[tableParams, props, groupBindingSelected]);

    const onChange: TableProps<IGroupBinding>['onChange'] = (pagination, filters, sorter, extra) => {
        setTableParams((oldValue) => { 
            return {
                ...oldValue, 
                ...pagination?.pageSize && { size: pagination.pageSize },
                ...filters,
                current: pagination.current !== undefined ? pagination.current - 1 : 0
            }
        })
    };

    const [modal, contextHolder] = Modal.useModal();

    const addUser = () => {
        toogleAddUser()
    }

    const onModifiedChanges = () => {
        filterUsers();
    }

    const goToDetailUser = (user: IUser | undefined | null) => {
        if (typeof user !== 'undefined' && user) {
            navigate(`/admin/access-and-permissions/users/${user?.login}`)
        }
    }

    const TableComponentWrapper = useCallback((node: any) => {
        const style = { border: `1px solid ${colorBorderSecondary}`, borderRadius: 4 }
        return (
            <table style={{ ...node.style, ...style }}>
                { node.children.map((child: any) => child) }
            </table>
        ); 
    }, [colorBorderSecondary]);

    return (
        <>
            <>{contextHolder}</>
            <GroupUserAdd open={openAddUser} toogle={toogleAddUser} onModifiedChanges={onModifiedChanges} groupId={groupId} />
            <TabContainer>
                <div className="pt-20 overflow-auto" style={{ paddingLeft: marginLG, paddingRight: marginLG }}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={20}>
                            <div className="flex flex-row items-center justify-between" style={{ borderRadius: 4, border: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                                <span className="pt-10 pb-10 pl-20 font-medium" style={{ color: colorText, fontSize: fontSize + 2 }} >Users in this group</span>
                                <div className="flex flex-row items-center">
                                    <span style={{ color: colorTextTertiary }}>{ totalItems ? `${totalItems}` : "0" } Members</span>
                                    {/*<SearchTable className="ml-20" handleOnSearch={handleOnSearch} onChange={onChangeValueToSearch} />*/}
                                    <Button type="primary" className="ml-20 mr-7" onClick={addUser}>
                                        <PlusOutlined />
                                        Add user
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-10">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={20} >
                            <ConfigProvider theme={{ token: { borderRadius: 2 } }}>
                                <Table 
                                    loading={loading}
                                    rowKey="id" 
                                    size="small" 
                                    columns={columns} 
                                    dataSource={entities} 
                                    onChange={onChange}
                                    rowClassName={"hover-in-row"}
                                    components={{
                                        table: TableComponentWrapper
                                    }}
                                    pagination={ { current: tableParams.current + 1, pageSize: tableParams.size, total: totalItems }}
                                />
                            </ConfigProvider>
                        </Col>
                    </Row>
                </div>
            </TabContainer>
        </>
    )
}