import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Row, Col, theme, Space, Badge, Pagination, ConfigProvider, Avatar } from 'antd';
import { TableProps } from 'antd/es/table';

import { AppDispatch, useAppSelector } from '@store/store';
import { ITeamMemberItemCustom } from '@models/project-model';
import { Can } from '@components/Permisions/Can';
import { CustomButtonText } from '@components/Utils/CustomButtonText';
import { TableCustomized } from '@components/TableCustomized/TableCustomized';
import { useProviderColor } from '@HOOKs/UseProviderColor';
import { TERTIARY_TEXT_COLOR } from '@providers/ThemeProvider';
import { EmptyCellTable } from '@components/Utils/EmptyCellTable';
import { getProjectOverviewTeam } from '@store/slices/project-overview-team';
import { PAGINATION_SIZE_GENERIC_TABLE_SMALL as itemsPerPage, TEAM_MEMBERS_SORTING_KEY as sortingKey } from '@constants/core';
import useVisible from '@HOOKs/UseVisible';
import { TeamManage } from './TeamManage';
import { StringORNumber, getUniqueId } from '@infrastructure/repositories/utils.repository';
import { CompanyReferenceEnumListOptions } from '@models/enumerations/company-reference-enum.model';
import { AddOrEditTeamMember } from './AddOrEditTeamMember';
import { getUsers } from '@store/slices/users-extended';
import { getTeamMembers } from '@store/slices/project-teams-extended';
import { ShieldStarSvgIcon } from '@components/Icons/ShieldStarSvgIcon';

const columns: TableProps<ITeamMemberItemCustom | Object>['columns'] = [
  {
    title: '',
    key: 'avatar',
    width: 100,
    align: 'center',
    render: (member: ITeamMemberItemCustom) =>
      member ? (
        <Avatar>
          {member.user?.firstName[0].toUpperCase()}
          {member.user?.lastName[0].toUpperCase()}
        </Avatar>
      ) : (
        <EmptyCellTable />
      ),
  },
  {
    title: 'Member Name',
    key: 'member name',
    render: (member: ITeamMemberItemCustom) => (
      <div className="flex items-center">
        <span>{member?.user?.fullName || <EmptyCellTable />}</span> {member?.primary && <ShieldStarSvgIcon className="ml-8" />}
      </div>
    ),
  },
  {
    title: 'Role',
    key: 'role',
    render: (member: ITeamMemberItemCustom) => <>{member?.role?.name || <EmptyCellTable />}</>,
  },
  {
    title: 'Discipline',
    key: 'discipline',
    render: (member: ITeamMemberItemCustom) => <>{member?.role?.discipline?.name || <EmptyCellTable />}</>,
  },
];
interface IListTeamProps {
  referenceId?: StringORNumber;
  referenceType: CompanyReferenceEnumListOptions;
}

export const ListTeam = ({ referenceId, referenceType }: IListTeamProps) => {
  const [visibleTeamManageDrawer, toggleVisibleTeamManageDrawer] = useVisible();

  const { projectId } = useParams<'projectId'>();

  const dispatch = useDispatch<AppDispatch>();

  const {
    token: { marginLG, colorPrimaryBg, colorPrimary, colorTextSecondary, fontSizeHeading5 },
  } = theme.useToken();
  const [colorTextTertiary] = useProviderColor(TERTIARY_TEXT_COLOR);

  const { t } = useTranslation();

  const { data: ProjectTeam, loading, totalItems } = useAppSelector(state => state.ProjectOverviewTeam);

  const [isAddMemberTeamOpen, toggleIsAddTeamMemberOpen] = useVisible();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const getTeam = useCallback(() => {
    dispatch(getProjectOverviewTeam({ id: projectId!, queryParams: { page: pageNumber - 1, size: itemsPerPage, sort: sortingKey } }));
    dispatch(getTeamMembers(projectId!));
  }, [projectId, pageNumber, dispatch]);

  useEffect(() => {
    if (!projectId) return;
    getTeam();
    dispatch(getUsers());
  }, [dispatch, projectId, pageNumber, getTeam]);

  const Header = () => (
    <Row justify={'space-between'} align="middle" className="mb-12">
      <Col>
        <Space>
          <span
            className="font-semibold pl-10"
            style={{
              fontSize: fontSizeHeading5,
              color: colorTextTertiary,
            }}
          >
            {t('dashboard.teamManage.teamMembers')}
          </span>
          <Badge
            count={totalItems}
            size="small"
            className="ml-30 mr-30"
            style={{
              backgroundColor: colorPrimaryBg,
              outline: 'none',
              color: colorPrimary,
            }}
          />
        </Space>
      </Col>
      <Col>
        <Space>
          {totalItems > 0 && (
            <ConfigProvider theme={{ token: { borderRadius: 8, colorText: colorTextSecondary } }}>
              <Pagination
                simple
                className="mr-10"
                current={pageNumber}
                total={totalItems}
                pageSize={itemsPerPage}
                onChange={setPageNumber}
              />
            </ConfigProvider>
          )}
          <Can I="EDIT" A="PROJECT" Entity={{ id: projectId }}>
            <CustomButtonText className="mr-8" onClick={toggleVisibleTeamManageDrawer}>
              {t('generic.manage')}
            </CustomButtonText>
            <CustomButtonText onClick={toggleIsAddTeamMemberOpen}>{t('generic.add')}</CustomButtonText>
          </Can>
        </Space>
      </Col>
    </Row>
  );

  return (
    <div className="flex flex-row mt-30" style={{ paddingLeft: `${marginLG}px`, paddingRight: `${marginLG}px` }}>
      <div className="flex flex-col w-full">
        <Row className="overflow-auto relative">
          <TableCustomized
            loading={loading}
            rowKey={record => getUniqueId()}
            columns={columns}
            dataSource={ProjectTeam}
            titleOnEmptyTable={t('dashboard.teamManage.noTeamMembers')}
            header={<Header />}
            {...{ scroll: { y: 320 } }}
          />
          <TeamManage
            open={visibleTeamManageDrawer}
            toggle={toggleVisibleTeamManageDrawer}
            referenceId={referenceId}
            referenceType={referenceType}
            getTeam={getTeam}
            page={pageNumber - 1}
            size={itemsPerPage}
            sort={sortingKey}
          />
          <AddOrEditTeamMember
            open={isAddMemberTeamOpen}
            toggle={toggleIsAddTeamMemberOpen}
            getTeam={getTeam}
            referenceId={referenceId}
            referenceType={referenceType}
          />
        </Row>
      </div>
    </div>
  );
};
