import { IBIDashboard } from '@models/bi-dashboard.model';
import { IUtil } from '@models/utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { biDashboardService } from '@services/bi-dashboard.service';
import { serializeAxiosError } from '@store/reducer.util';

interface IState extends IUtil {
  data: IBIDashboard[];
  loading: boolean;
  errorMessage: any;
}

export const getEntities = createAsyncThunk(
  'bi-dashboards/fetch_entity_list',
  async () => {
    return await biDashboardService.findAll();
  },
  { serializeError: serializeAxiosError }
);

const initialState: IState = {
  data: [],
  loading: false,
  errorMessage: null,
};

export const BIDashboardSlice = createSlice({
  name: 'bi-dashboard',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEntities.fulfilled, (state, action) => {
        return { ...state, loading: false, data: action.payload.data };
      })
      .addCase(getEntities.pending, (state, action) => {
        return { ...state, loading: true };
      });
  },
});

export default BIDashboardSlice.reducer;
