
import { IRfiReason } from '@models/rfi-reason.model';
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { rfiReasonService } from '@services/rfi-reason.service';
import { serializeAxiosError } from "../reducer.util";
import { cleanEntity } from '@shared/util/entity-utils';

export const getEntities = createAsyncThunk(
    'rfiReason/fetch_entity_list',
    async () => {
        return rfiReasonService.getRfiReasons();
    },
    { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
    `rfiCategory/create_entity`,
    async (entity: IRfiReason, thunkAPI) => {
      const result = await rfiReasonService.create(cleanEntity(entity));
      thunkAPI.dispatch(getEntities());
      return result;
    },
    { serializeError: serializeAxiosError }
  );
  
  export const updateEntity = createAsyncThunk(
    `rfiCategory/update_entity`,
    async (entity: IRfiReason, thunkAPI) => {
      const result = entity.id && await rfiReasonService.update(entity.id, cleanEntity(entity));
      thunkAPI.dispatch(getEntities());
      return result;
    },
    { serializeError: serializeAxiosError }
  );

interface IRfiReasonState {
    loading: boolean;
    errorMessage: string | null;
    entities: ReadonlyArray<IRfiReason>;
}

const initialState: IRfiReasonState = {
    loading: false,
    errorMessage: null,
    entities: []
};

export const RfiReasonSlice = createSlice({
    name: 'rfiReasons',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const { data } = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data
                };
            })
            .addMatcher(isPending(getEntities), state => {
                state.errorMessage = null;
                state.loading = true;
            })
            .addMatcher(isFulfilled(createEntity, updateEntity), (state) => {
                state.loading = false;
              })
              .addMatcher(isPending(createEntity, updateEntity), state => {
                state.errorMessage = null;
              });
    }
});

// Reducer
export default RfiReasonSlice.reducer;

