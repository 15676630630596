import { LeftOutlined } from "@ant-design/icons";
import { COLOR_TEXT_TERTIARY, NEUTRAL_6_COLOR, WHITE, useThemeProvider } from "@providers/ThemeProvider";
import { Button, Typography } from "antd";

interface IBackButtonProps extends React.ComponentPropsWithoutRef<typeof Button> {}

export const BackButton = (props: IBackButtonProps) => {

    const { children, ...restProps } = props;

    const { themeConfig } = useThemeProvider();
    
    return (
        <Button type="text" className="flex flex-row items-center" style={{ backgroundColor: "transparent" }} {...restProps}>
            <div className="flex flex-row items-center w-full">
                <LeftOutlined className="pr-10" style={{ color: themeConfig.darkMode ? WHITE : NEUTRAL_6_COLOR }} />
                <Typography.Title level={5} className='pr-10 pb-0 imb-0' style={{ color: themeConfig.darkMode ? WHITE : COLOR_TEXT_TERTIARY, fontWeight: 500 }}>
                    { children }
                </Typography.Title>
            </div>
        </Button>
    )
}
