import { useEffect, useState } from 'react';
import { Button, Col, Drawer, Form, Row, Select, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelector } from '@store/store';
import { getEntities as getCompanyRoles } from '@store/slices/company-roles';
import { getCompanies as getCompaniesForReference, getCompaniesFullList } from '@store/slices/generic-company';
import { getEntities as getCompanies } from '@store/slices/company';
import { getEntityByCompany, reset } from '@store/slices/company-contact';
import { CompanyReferenceEnum, CompanyReferenceEnumListOptions } from '@models/enumerations/company-reference-enum.model';
import { ICompanyBinding, ICompanyBindingExtended } from '@models/company-binding.model';
import { updateEntity } from '@store/slices/company-binding';
import { useTranslation } from 'react-i18next';
import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { asyncLaunchNotification } from '@store/slices/notification';
import { IQueryParams } from '@models/pagination';

const { Option } = Select;

export interface ICompanyGenericUpdateProps extends IQueryParams {
  open: boolean;
  toggle: () => void;
  company: ICompanyBinding | null;
  referenceType: CompanyReferenceEnumListOptions;
  referenceId: StringORNumber | undefined;
}

export const CompanyGenericUpdate = (props: ICompanyGenericUpdateProps) => {
  const { open, toggle, company, referenceType, referenceId, page, size, sort } = props;

  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  // Fetching necessary data
  const companyRoles = useAppSelector(state => state.CompanyRoles.entities);
  const companies = useAppSelector(state => state.Company.entities);

  const { entity: locationEntity, updating } = useAppSelector(state => state.Location);
  const { entities: companyContacts } = useAppSelector(state => state.CompanyContact);

  const [form] = Form.useForm();
  const [animateError, setAnimateError] = useState(false);

  useEffect(() => {
    dispatch(getCompanyRoles({}));
    dispatch(getCompanies({}));
    dispatch(getEntityByCompany(String(company?.company?.id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      company: company?.company?.id,
      companyRole: company?.companyRole?.id,
      companyContact: company?.companyContact?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const onClose = (): void => {
    toggle();
    resetDrawer();
    dispatch(reset());
  };

  const resetDrawer = () => {
    form.resetFields();
  };

  const onFinish = (entity: any): void => {
    let payload: Partial<ICompanyBindingExtended> = {
      id: company?.id,
      company: entity?.company ? companies.find(company => company?.id === entity?.company) : null,
      companyRole: { id: entity?.companyRole ? JSON.parse(entity?.companyRole as string) : null },
      referenceId,
      referenceType: CompanyReferenceEnum[referenceType],
    };

    if (entity.companyContact){
      payload['companyContact'] = {id: entity?.companyContact ? JSON.parse(entity?.companyContact as string) : null }
    }
    dispatch(updateEntity(payload)).then(() => {
      dispatch(
        asyncLaunchNotification({
          type: 'success',
          config: {
            message: `${t('generic.companies')}`,
            description: `The information for that ${t('generic.companies')} has been updated.`,
          },
        })
      );
      if (referenceId && referenceType) {
        dispatch(getCompaniesForReference({ referenceId, referenceType, page, size, sort }));
        dispatch(getCompaniesFullList({ referenceId, referenceType }));
      }
      onClose();
    });
  };


  const playAnimationOfError = () => {
    setAnimateError(true);
    setTimeout(() => {
      setAnimateError(false);
    }, 500);
  };

  const onFinishFailed = (errorInfo: any) => {
    playAnimationOfError();
  };

  const genericFilter = (input: string, option: any) => option?.label?.toLowerCase!()?.indexOf!(input?.toLowerCase!()) >= 0;

  const onChangeCompany = (companyIdSelected: Number) => {
    const isValidId = Number.isInteger(companyIdSelected);
    if (isValidId) {
      dispatch(getEntityByCompany(String(companyIdSelected)));
    }
  };

  const onSubmit = () => {
    form.submit();
  };

  const labelCompany = (
    <Row justify={'space-between'} className="w-full">
      {t('generic.companyName')}
    </Row>
  );

  return (
    <Drawer
      title={`Edit Company`}
      width={580}
      onClose={onClose}
      open={open}
      closable={false}
      styles={{
        body: { paddingBottom: 80 },
      }}
      extra={
        <Space>
          <Button type="primary" ghost onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onSubmit} type="primary" loading={updating}>
            Save
          </Button>
        </Space>
      }
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={locationEntity}
        onFinishFailed={onFinishFailed}
        className={`${animateError && 'error-animation'}`}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="companyRole"
              label={`${t('generic.company')} Role`}
              rules={[{ required: true, message: `Please select a ${t('generic.company')} Role` }]}
            >
              <Select placeholder={`Please select a ${t('generic.company')} Role`} allowClear>
                {companyRoles.map(item => (
                  <Option value={item.id} key={item.id}>
                    {item.description}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="company"
              label={labelCompany}
              className="form-input-company-label"
              rules={[{ required: true, message: `Please enter a ${t('generic.company')}` }]}
            >
              <Select
                placeholder={`Please select a ${t('generic.company')}`}
                allowClear
                optionFilterProp="children"
                showSearch
                filterOption={genericFilter}
                onChange={onChangeCompany}
                options={companies.map(item => ({ value: item.id, label: item.name }))}
              ></Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="companyContact"
              label="Main Point of Contact"
              className="form-input-company-label"
              rules={[{ required: false, message: `Please select a ${t(`generic.company`)} ${t('generic.contact')}` }]}
            >
              <Select
                placeholder={`--`}
                allowClear
                optionFilterProp="children"
                showSearch
                filterOption={genericFilter}
                disabled={!form.getFieldValue('company')}
                options={companyContacts.map(item => ({ value: item.id, label: `${item.firstName} ${item.lastName}` }))}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
