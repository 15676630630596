import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "@store/store";
import { Button, Col, Drawer, Form, Input, Row, Select, Space } from "antd";
import { IAddress, IUpdateAddressRequest } from '@models/address.model';
import { AutoCompleteAddressCustom } from "@components/Locations/AutocompleteAddress/AutoCompleteAddressCustom";
import { getEntities as getAddressTypes } from '@store/slices/address-types';
import { updateAddress } from '@store/slices/generic-address';
import { AddressMap } from "./AddressMap";
import { StringORNumber } from "@infrastructure/repositories/utils.repository";
import { AddressReferenceEnumListOptions } from "@models/enumerations/address-reference-enum.model";
import { useTranslation } from 'react-i18next';

export interface IAddressGenericUpdateProps {
  open: boolean;
  toggle: () => void;
  address: IAddress;
  referenceId?: StringORNumber;
  referenceType: AddressReferenceEnumListOptions;
}

export interface IOptionMap {
  markerOptions: google.maps.marker.AdvancedMarkerElementOptions[] | [];
  placeResult: google.maps.places.PlaceResult | null;
}

export const AddressGenericUpdate = (props: IAddressGenericUpdateProps) => {
  const { open, toggle, address, referenceId, referenceType } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const { entities: addressTypes } = useAppSelector(state => state.AddressTypes);
  const { loading } = useAppSelector(state => state.GenericAddress[referenceType]);

  const [form] = Form.useForm();

  const [hiddenFields, setHiddenFields] = useState<Partial<IAddress>>();
  const [addressInputValue, setAddressInputValue] = useState('');
  const [optionMap, setOptionMap] = useState<IOptionMap>({ markerOptions: [], placeResult: null });

  useEffect(() => {
    dispatch(getAddressTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      setOptionMap({
        markerOptions: [],
        placeResult: null,
      });
    };
  }, []);

  useEffect(() => {
    if (form && address) {
      form.setFieldsValue({
        addressType: address?.addressType?.id,
        address: {
          ...address,
        },
      });
    }
    if (open && address && address.latitude && address.longitude) {
      const marker = [
        {
          position: { lat: +address.latitude, lng: +address.longitude },
          gmpClickable: true,
          extraData: address,
        },
      ];
      setOptionMap({ markerOptions: marker, placeResult: null });
    }
    if (open && address) {
      setHiddenFields({
        latitude: address?.latitude,
        longitude: address?.longitude,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, open]);

  useEffect(() => {
    address && address.streetAddress && setAddressInputValue(address.streetAddress);
  }, [address]);

  const onClose = (): void => {
    if (form) {
      form.resetFields();
    }
    setHiddenFields({});
    setAddressInputValue('');
    setOptionMap({
      markerOptions: [],
      placeResult: null,
    });
    toggle();
  };

  const onFinishFailed = (errorInfo: any) => {
    //
  };

  const onSubmit = () => {
    form.submit();
  };

  const onFinish = (entity: any): void => {
    if (!referenceId) return;

    const payload: IUpdateAddressRequest = {
      referenceId: referenceId,
      referenceType: referenceType,
      address: {
        id: address.id,
        ...entity.address,
        streetAddress: addressInputValue,
        ...hiddenFields,
        addressType: {
          id: entity.addressType,
        },
      },
    };

    dispatch(updateAddress(payload)).then(() => onClose());
  };

  const onSelectItemInAutocomplete = (address: any) => {
    const {
      subAddress = '',
      city = '',
      stateProvince = '',
      postalCode = '',
      country,
      latitude,
      longitude,
      googleMapPlace = null,
    } = address;
    form.setFieldsValue({
      address: {
        streetAddress2: subAddress,
        city: city,
        stateProvince: stateProvince,
        postalCode: postalCode,
        country: country,
      },
    });
    setHiddenFields({
      latitude,
      longitude,
    });

    if (latitude && longitude) {
      const marker = [
        {
          position: { lat: +latitude, lng: +longitude },
          gmpClickable: true,
          extraData: address,
        },
      ];
      setOptionMap({ markerOptions: marker, placeResult: googleMapPlace });
    } else {
      setOptionMap({ markerOptions: [], placeResult: googleMapPlace });
    }
  };

  const onClearAddressInput = () => {
    setAddressInputValue('');
    setHiddenFields({
      latitude: null,
      longitude: null,
    });
    setOptionMap({ markerOptions: [], placeResult: null });
  };

  const onChangeAddressValue = (text: string) => {
    setAddressInputValue(text);
    form.setFieldsValue({
      address: {
        streetAddress: text,
      },
    });
  };

  return (
    <Drawer
      title={t('generic.address.editAddress')}
      width={500}
      onClose={onClose}
      open={open}
      closable={false}
      styles={{
        body: { paddingBottom: 80 },
      }}
      extra={
        <Space>
          <Button type="default" onClick={toggle}>
            {t('generic.cancel')}
          </Button>
          <Button onClick={onSubmit} type="primary" loading={loading}>
            {t('generic.save')}
          </Button>
        </Space>
      }
    >
      <Form layout="vertical" className="h-full" form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="addressType" label={t('generic.address.addressType')} rules={[{ required: true, message: '' }]}>
              <Select
                placeholder={t('generic.address.addressType')}
                options={addressTypes.map(item => ({ value: item.id, label: item?.description }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name={['address', 'streetAddress']} label={t('generic.address.addressLine1')}>
              <AutoCompleteAddressCustom
                onChangeAutocomplete={onSelectItemInAutocomplete}
                onChangeValueInput={onChangeAddressValue}
                placeholder={t('generic.address.addressLine1Placeholder')}
                onClear={onClearAddressInput}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name={['address', 'streetAddress2']} label={t('generic.address.addressLine2')}>
              <Input placeholder={t('generic.address.addressLine2Placeholder')} allowClear />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={8}>
            <Form.Item name={['address', 'city']} label={t('generic.address.city')}>
              <Input placeholder={t('generic.address.city')} allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={['address', 'stateProvince']} label={t('generic.address.state')}>
              <Input placeholder={t('generic.address.state')} allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={['address', 'postalCode']} label={t('generic.address.postalCode')}>
              <Input placeholder={t('generic.address.postalCode')} allowClear />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name={['address', 'country']} label={t('generic.address.country')}>
              <Input placeholder={t('generic.address.country')} allowClear />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name={['address', 'phone']} label={t('generic.address.phoneNumber')}>
              <Input placeholder={t('generic.address.phoneNumber')} allowClear />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={['address', 'email']} label={t('generic.address.email')}>
              <Input placeholder={t('generic.address.email')} allowClear />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>{open && <AddressMap optionMap={optionMap} />}</Col>
        </Row>
      </Form>
    </Drawer>
  );
};
