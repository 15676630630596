
import { isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from "../reducer.util";
import { createAsyncThunk } from "@reduxjs/toolkit"
import { IRole, defaultValue } from '@models/role.model';
import { cleanEntity, getTotalItems } from '@shared/util/entity-utils';
import { rolesService } from '@services/roles.service';
import { serializeGenericHandleError } from '@store/thunk.util';

const nameOfEntity = "roles";
const service = rolesService;

export const getRoles = createAsyncThunk(
  `${nameOfEntity}/fetch_entity_list`,
  async () => {
      return service.getRoles({ sort: 'name' });
  },
  { serializeError: serializeGenericHandleError }
);

export const createEntity = createAsyncThunk(
  `${nameOfEntity}/create_entity`,
  async (entity: IRole, thunkAPI) => {
    const result = await service.create(cleanEntity(entity));
    thunkAPI.dispatch(getRoles());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  `${nameOfEntity}/update_entity`,
  async (entity: IRole, thunkAPI) => {
    const result = entity.id && await service.update(entity.id, cleanEntity(entity));
    thunkAPI.dispatch(getRoles());
    return result;
  },
  { serializeError: serializeAxiosError }
);

const initialState: EntityState<IRole> = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

export const RolesSlice = createEntitySlice({
    name: nameOfEntity,
    initialState,
    reducers: {
      clearEntity: (state,) => {
        state.entity = defaultValue;
      },
    },
    extraReducers(builder) {
        builder
          .addMatcher(isFulfilled(getRoles), (state, action) => {
            const { data, headers } = action.payload;
            return {
              ...state,
              loading: false,
              entities: data,
              totalItems: getTotalItems(headers),
            };
          })
          .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
            state.updating = false;
            state.loading = false;
            state.updateSuccess = true;
            state.entity = action.payload as IRole;
          })
          .addMatcher(isPending(createEntity, updateEntity), state => {
            state.errorMessage = null;
            state.updateSuccess = false;
            state.updating = true;
          })
          .addMatcher(isPending(getRoles), state => {
            state.errorMessage = null;
            state.updateSuccess = false;
            state.loading = true;
          })
      }
  });
  
  export const { reset, clearEntity } = RolesSlice.actions;
  
  // Reducer
  export default RolesSlice.reducer;

  