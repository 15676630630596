import { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { AppDispatch, useAppSelector } from '@store/store';
import { useDispatch } from 'react-redux';
import { getEntity } from "@store/slices/projects";
import { useTranslation } from 'react-i18next';

export const ProjectNameLabel = () => {

    const dispatch = useDispatch<AppDispatch>();
    const { entity: projectEntity } = useAppSelector((state) => state.Project);

    const { siteId } = useParams<"siteId">();
    const { t } = useTranslation()

    useEffect(() => {
        if (siteId && projectEntity && (String(projectEntity.id) !== siteId )) {
            dispatch(getEntity(siteId));
        }
    }, [dispatch, siteId, projectEntity]);

    return (
        <span> {`${t("generic.project")}`} #{ projectEntity.id }</span>
    )
}