/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useLayoutEffect } from 'react';
import { ConfigProvider, theme } from 'antd';
import { ThemeConfig } from "antd/es/config-provider/context";
import { mainFont } from "./ThemeProvider";
import { useIsDevelopment } from '../HOOKs/UseIsDevelopment';
import { useThemeProvider } from '@providers/ThemeProvider';
import AppDevToolSettings from '@components/__AppDevToolSettings__/AppDevToolSettings';

export const THEME_LIGHT = {
  colorLink: '#13c2c2',
  colorTextBase: '#292d2d',
  colorBgBase: '#FCFDFA',
  colorBgLayout: '#EBEBEB',
  colorBorder: '#D7DAD1',
  colorBorderSecondary: '#D7DAD1',
};

export const THEME_DARK = {
  colorLink: '#13C2C2',
  colorTextBase: '#FCFDFA',
  colorBgBase: '#292D2D',
  colorErrorBg: '#463939',
  colorErrorBorder: '#714444',
  colorWarningBg: '#564B39',
  colorWarningBorder: '#8B754C',
  colorSuccessBg: '#2D3B29',
  colorSuccessBorder: '#315320',
  colorPrimaryBg: '#2E3949',
  colorBorder: '#7E8787',
  colorBorderSecondary: '#7E8787',
};

export const AntDesignProvider = ({ children }: any) => {  

    const [themeConfiguration, setThemeConfiguration] = useState({});

    const { themeConfig } = useThemeProvider();

    const themeLight = {
        colorPrimary: themeConfig.primaryColor,
        colorInfo: themeConfig.primaryColor,
        colorFocusBorder:"#91D5FF",
        ...THEME_LIGHT
    }
    
    const themeDark = {  
        colorPrimary: themeConfig.primaryColor,
        colorInfo: themeConfig.primaryColor,
        ...THEME_DARK
    }

    const [isDevelopmentActived] = useIsDevelopment();

    useLayoutEffect(() => {
        const themeCustom: ThemeConfig = {
            algorithm: themeConfig.darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
            token: {
                ...themeConfig.darkMode ? { ...themeDark } : { ...themeLight },
                borderRadius: 0,
                fontFamily: mainFont
            },
            components: {
                Input: {
                    borderRadius: 2
                },
                Select: {
                    borderRadius: 2
                },
                Button: {
                    borderRadius: 2
                },
                Modal: {
                    borderRadiusLG: 8
                },
                Notification: {
                    borderRadiusLG: 2,
                    borderRadius: 2
                },
                Tooltip: {
                    borderRadius: 4,
                    borderRadiusOuter: 0,
                    borderRadiusXS: 2
                },
                Popover: {
                    borderRadiusLG: 4,
                    borderRadiusOuter: 2,
                    borderRadiusXS: 2,
                    boxShadowSecondary: "0px 6px 8px 0px rgba(0, 0, 0, 0.15)"
                },
                Alert: {
                    borderRadius: 8,
                    borderRadiusLG: 8,
                    borderRadiusSM: 6,
                    borderRadiusXS: 4,
                    borderRadiusOuter: 4,
                }
            }
        }
        setThemeConfiguration(themeCustom)
    },[themeConfig])

    return (
        <>
            { isDevelopmentActived && <AppDevToolSettings /> }
            <ConfigProvider theme={themeConfiguration}>{children}</ConfigProvider>
        </>
    )
};