import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getEntities } from "@store/slices/change-order-rejection-reasons";
import { Card, Switch, Tag, theme } from "antd";
import useVisible from "@HOOKs/UseVisible";
import { EditOutlined } from "@ant-design/icons";
import { NEUTRAL_7_COLOR } from "@providers/ThemeProvider";
import "@styles/components/admin-item-card.less";
import { ChangeOrderRejectionReasonEdit } from "./ChangeOrderRejectionReasonEdit";
import { IChangeOrderRejectionReason } from "@models/change-order-rejection-reason.model";
import { useTranslation } from "react-i18next";

const ListItem = (changeOrderRejectionReason: IChangeOrderRejectionReason) => {
  const { t } = useTranslation();
  return <li style={{ color: NEUTRAL_7_COLOR }} className="pb-4">{changeOrderRejectionReason.name} {changeOrderRejectionReason.disabled && <Tag>{t("generic.disabled")}</Tag>}</li>
}

interface IListItemEditableProps {
  changeOrderRejectionReason: IChangeOrderRejectionReason;
  changeOrderRejectionReasonSelected: IChangeOrderRejectionReason;
  editChangeOrderRejectionReasonClicked: any;
}

const ListItemEditable = (props: IListItemEditableProps) => {

  const { changeOrderRejectionReason, changeOrderRejectionReasonSelected, editChangeOrderRejectionReasonClicked } = props;
  const { t } = useTranslation();

  return <div className={`list-item-editable ${(changeOrderRejectionReasonSelected?.id === changeOrderRejectionReason.id ? 'selected' : '')}`} onClick={() => editChangeOrderRejectionReasonClicked(changeOrderRejectionReason)}>
    <div>
      <div className="handle-container">
        <div className="handle-dot"></div>
      </div>
    </div>
    <div className="description">
      {changeOrderRejectionReason.name} {changeOrderRejectionReason.disabled && <Tag>{t("generic.disabled")}</Tag>}
    </div>
    <span className="edit-container">
      <span className="edit-link">
        <EditOutlined /> {t("generic.edit")}
      </span>
    </span>
  </div>
}

export const ChangeOrderRejectionReasonCard = (props: any) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { token: { colorPrimary, colorTextDisabled } } = theme.useToken();

  const loadChangeOrderRejectionReasons = () => {
    dispatch(
      getEntities()
    );
  }

  useEffect(() => {
    loadChangeOrderRejectionReasons();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const { allEntities: changeOrderRejectionReasons } = useAppSelector((state) => state.ChangeOrderRejectionReason);

  const changeOrderRejectionReasonsSorted = useMemo(() => {
    const copied = JSON.parse(JSON.stringify(changeOrderRejectionReasons))
    return copied.sort((a: IChangeOrderRejectionReason, b: IChangeOrderRejectionReason) => {
      const nameA = a?.name || ''; 
      const nameB = b?.name || '';
    
      return String(nameA).localeCompare(String(nameB));
    });
  }, [changeOrderRejectionReasons])

  const [openEditChangeOrderRejectionReason, toogleEditChangeOrderRejectionReason] = useVisible();
  const [changeOrderRejectionReasonSelected, setChangeOrderRejectionReasonSelected] = useState<IChangeOrderRejectionReason>({})
  const [isEditing, setIsEditing] = useState<boolean>(false)

  useEffect(() => {
    if (!openEditChangeOrderRejectionReason) {
      setChangeOrderRejectionReasonSelected({})  
    }
  }, [openEditChangeOrderRejectionReason]);

  const onModifiedChanges = (changeOrderRejectionReason: IChangeOrderRejectionReason) => {
    loadChangeOrderRejectionReasons();
  }

  const toggleEdit = () => {
    setIsEditing(!isEditing)
  };

  const editChangeOrderRejectionReason = (changeOrderRejectionReason : IChangeOrderRejectionReason) => {
    setChangeOrderRejectionReasonSelected(changeOrderRejectionReason)
    toogleEditChangeOrderRejectionReason()
  }

  const createNewChangeOrderRejectionReason = () => {
    setChangeOrderRejectionReasonSelected({})
    toogleEditChangeOrderRejectionReason();
  }
  
  return (
    <>
      <ChangeOrderRejectionReasonEdit open={openEditChangeOrderRejectionReason} toogle={toogleEditChangeOrderRejectionReason} changeOrderRejectionReason={changeOrderRejectionReasonSelected} onModifiedChanges={onModifiedChanges} />
      <Card title={<>{t("admin.changeOrderRejectionReason")}<span className="item-count">{changeOrderRejectionReasons?.length}</span></>} bordered={false} className="admin-item-card" extra={<div><span className="extra-label" style={{ color: (isEditing ? colorPrimary : colorTextDisabled) }} onClick={toggleEdit}>{isEditing ? t("admin.managing") : t("admin.manage")}</span><Switch checked={isEditing} onClick={toggleEdit} /></div>}>
        <ul className={isEditing ? 'editing' : ''}>
            {changeOrderRejectionReasonsSorted.map(changeOrderRejectionReason => 
            <div
                key={changeOrderRejectionReason.id}>
                <>
                {isEditing && <ListItemEditable changeOrderRejectionReason={changeOrderRejectionReason} changeOrderRejectionReasonSelected={changeOrderRejectionReasonSelected} editChangeOrderRejectionReasonClicked={editChangeOrderRejectionReason} />}
                {!isEditing && <ListItem {...changeOrderRejectionReason} />}
                </>
            </div>
            )}
        </ul>
        {isEditing && <>
            <div className="spacer"></div>
            <span onClick={createNewChangeOrderRejectionReason} className="add-new-btn">+ {t("admin.addNewChangeOrderRejectionReason")}</span>
        </>}
        </Card>
    </>
  )
}