/* eslint-disable react-hooks/exhaustive-deps */
import { CloseCircleFilled, SearchOutlined } from '@ant-design/icons'
import { isString } from '@shared/util/validations';
import { ConfigProvider, Input, InputRef, theme } from 'antd'
import { RefObject, useEffect, useRef, useState } from 'react';

export interface IHandleOnSearchProps {
  valueToSearch?: string
}
export interface SearchTableProps extends React.ComponentPropsWithoutRef<typeof Input> {
  handleOnSearch?: (props?: IHandleOnSearchProps) => void;
  stylePrefixComponent?: React.CSSProperties;
  onClear?: () => void;
  queryToSearch?: string;
  onInitRef?: (ref: RefObject<InputRef>) => any;
}

export const SearchTable = (props : SearchTableProps ) => {

  const { handleOnSearch, defaultValue, onInitRef, stylePrefixComponent = {}, onClear, queryToSearch = "", ...restProps} = props;

  const { token: { colorPrimary, colorPrimaryHover, fontSize, colorTextTertiary  } } = theme.useToken();

  const fontSizeTextCalculated = fontSize - 2;
  
  const inputRef = useRef<InputRef>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (onInitRef) {
      onInitRef(inputRef);
    }
  })

  const [valueToSearch, setValueToSearch] = useState(queryToSearch);

  useEffect(() => {
    if (isString(queryToSearch) && queryToSearch !== valueToSearch){
      setValueToSearch(queryToSearch)
    }
  }, [queryToSearch])

  useEffect(() => {
    defaultValue && typeof defaultValue === "string" && setValueToSearch(defaultValue)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  const handleClickSearch = () => {
    const hasHandleOnSearch = Boolean(handleOnSearch);

    if (hasHandleOnSearch) {
      handleOnSearch!({
        valueToSearch
      }) 
    } 
  };

  const handleOnKeyDownSearch = (e: React.KeyboardEvent<Element>) => { 
    if (e.key === 'Enter') handleClickSearch();
  }

  const onChangeSearch = (e: any) => {
    const value = e && e.target.value

    if (typeof value === "string")  {
      setValueToSearch(e.target.value);
    }
  }

  return (
    <>
      <ConfigProvider theme={{ ...theme, token: { borderRadius: 300, fontSize: fontSizeTextCalculated }, components: { Input: { borderRadius: 300 } } }}>
        <div ref={containerRef}>
          <Input
            id="search-in-table"
            ref={inputRef}
            size="middle"
            placeholder='Search'
            onKeyDown={handleOnKeyDownSearch} 
            prefix={<SearchOutlined onClick={() => handleClickSearch() } style={{ color: colorPrimary, ...stylePrefixComponent }} /> } 
            style={{ height: "auto", width: "235px", borderColor: colorPrimaryHover, transition: 'width 0.35s ease-out' }} 
            onInput={onChangeSearch}
            value={valueToSearch}
            disabled={false}
            suffix={typeof onClear !== 'undefined' && <CloseCircleFilled style={{ color: colorTextTertiary }} onClick={() => {
              setValueToSearch('')
              onClear()
            }} />}
            {...restProps}
          />
        </div>
      </ConfigProvider>
    </>
  )
}
