export enum FeatureFlagOptionEnum {
  RFI = 'rfi',
  PROCORE = 'procore',
  ACL_ADMIN_UI = 'acl-admin-ui',
  ACL_EVALUATOR = 'acl-evaluator',
  TABLEAU = 'tableau',
  MICROSOFT_CLARITY = 'clarity-tracking',
  CHANGE_ORDERS = 'change-orders',
  PUNCHLIST = 'punchlist',
  COMPANIES = 'companies',
  BI_REPORTS = 'bi-reports',
  OX_BLUE_INTEGRATION = 'integration-oxblue',
  BUDGET = 'budget',
  MARKUP = 'markup',
}
