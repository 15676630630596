import { CloseOutlined } from "@ant-design/icons";
import { theme } from "antd";

interface ICloseButtonModalProps {
    onCloseModalPreview: () => void;
    containerStyles?: React.CSSProperties;
}

export const CloseButtonModal = (props: ICloseButtonModalProps) => {

    const { onCloseModalPreview, containerStyles = {} } = props;

    const { token: { colorFillTertiary, colorFillSecondary, colorTextTertiary }} = theme.useToken();
    
    return (
       <div
            title={"Close"}
            className="flex flex-row items-center justify-center cursor-pointer mr-4"
            onClick={onCloseModalPreview}
            style={{
                width: "2.5rem",
                height: "2.5rem",
                backgroundColor: colorFillTertiary,
                border: `1px solid ${colorFillSecondary}`,
                borderRadius: '50%',
                ...containerStyles
            }}>
            <CloseOutlined style={{ color: colorTextTertiary }}/>
        </div>
    )
}
