import { Button, ConfigProvider, Divider, Modal, Row } from 'antd';
import { DEFAULT_WIDTH_MODAL_COMPONENT, MODAL_THEME_SECONDARY } from '@providers/ThemeProvider';

export const DEFAULT_MODAL_OPTIONS: Partial<React.ComponentPropsWithoutRef<typeof Modal>> = {
  width: DEFAULT_WIDTH_MODAL_COMPONENT,
  closable: false,
  destroyOnClose: true,
  centered: true
}

export const ModalWrapper: React.FC<React.ComponentPropsWithoutRef<typeof Modal>> = ({ children, ...restProps }) => {
  const props = {...DEFAULT_MODAL_OPTIONS, ...restProps};
  return (
      <ConfigProvider theme={{ components: { Modal: MODAL_THEME_SECONDARY } }}>
          <Modal {...props}>
            {children}
          </Modal>
      </ConfigProvider>
  )
}

export const CommonFooterModal: React.FC<React.ComponentPropsWithoutRef<typeof Row>> = ({ children, ...restProps }) => {
  const {className, ...rowProps} = restProps
  return (
    <ConfigProvider theme={{ token: { colorBgElevated: 'rgb(20 20 20)' } }}>
        <Divider className="mt-0 mb-0 pt-0" />
        <Row className={`flex flex-row pt-15 pb-15 pl-30 pr-30 ${className}`} {...rowProps} >
          {children}
        </Row>
    </ConfigProvider>
  )
}

export const CancelButtonModal: React.FC<React.ComponentPropsWithoutRef<typeof Button>> = ({ children, ...restProps }) => {
  const {className, ...props} = restProps
  return (
    <Button className={`pl-30 pr-30 ${className}`} {...props}>
        {children}
    </Button>
  )
}

export const OKButtonModal: React.FC<React.ComponentPropsWithoutRef<typeof Button>> = ({ children, ...restProps }) => {
  const {className, ...props} = restProps
  return (
    <Button type="primary" className={`pl-30 pr-30 ${className}`} {...props}>
        {children}
    </Button>
  )
}