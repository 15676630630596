import React from 'react'
import { Button, Calendar, Col, ConfigProvider, Divider, Row, theme } from 'antd';
import { useState, useLayoutEffect } from 'react';
import { IProjectDeliverable } from '@models/project-deliverable';
import { useThemeProvider } from '@providers/ThemeProvider';
import { useProviderColor } from '@HOOKs/UseProviderColor';
import { LeftOutlinedIcon } from '@components/Icons/LeftOutlinedIcon';
import { RightOutlinedIcon } from '@components/Icons/RightOutlinedIcon';
import { AppDispatch } from '@store/store';
import { useDispatch } from 'react-redux';
import { getProjectDeliverableDetailById, getProjectDeliverablesData, updateProjectDeliverable } from '@store/slices/project-deliverables';
import { DATE_FORMAT_FULL_NAME_MONTH, removeTimeZone } from '@shared/util/date-utils';
import { useParams } from 'react-router-dom';
import { isArrayWithValues } from '@shared/util/array-util';
import { SubmitForApprovalModal } from './SubmitForApprovalModal';

// Dayjs Settings
import { Dayjs, MonthNames } from 'dayjs';
import dayjs from 'dayjs';
import dayLocaleData from 'dayjs/plugin/localeData';
import { CalendarMode } from 'antd/es/calendar/generateCalendar';
import { ModalWrapper } from '@components/Utils/ModalWrapper';
import { POLICY } from '@models/project-deliverable-approval';
import { EmptyButton } from '@components/Utils/EmptyButton';
import { Can } from '@components/Permisions/Can';
import { getProjectOverviewData } from '@store/slices/project-overview';
import { asyncLaunchNotification } from '@store/slices/notification';

dayjs.extend(dayLocaleData);

interface HandlerActualDateProps {
    deliverable: IProjectDeliverable
}

const WIDTH_COMPONENT = 451;

export const HandlerActualDate: React.FC<HandlerActualDateProps> = ({ deliverable }) => {

    const dispatch = useDispatch<AppDispatch>();
    const { projectId } = useParams<"projectId">();
    const { token } = theme.useToken();
    const { themeConfig } = useThemeProvider();
    const [secondaryColor] = useProviderColor(themeConfig.secondaryColor);
    const [openModal, setOpenModal] = useState(false);
    const [onLayerConfirm, setOnLayerConfirm] = useState(false);
    const [dateSelected, setDateSelected] = useState<Dayjs>(dayjs());

    const [openModalSubmitForApproval, setOpenModalSubmitForApproval] = useState(false);
    const isDeliverableDurationOneDay = Boolean(deliverable.duration === 1);

    const onOpenEdition = () => {
        setOpenModal(true);
    }


    const isThisOneDayDeliverableWithout = () => {
        // To Check if this is one day deliverable without approval needs 
        return (isDeliverableDurationOneDay && !deliverable.approvals?.length)
    }

    const header = (
        <>
            <Row justify="space-evenly" className="pt-30 pb-10" hidden={onLayerConfirm}>
                <Col flex={"auto"} className="flex items-center justify-center">
                    <span>
                        {isThisOneDayDeliverableWithout() ? 'Select the day this deliverable started and ended.' : !!deliverable.actualStartDate ? `Select the day this deliverable ended.` : `Select the day this deliverable started.`}
                    </span>
                </Col>
            </Row>
        </>
    )

    const onCancel = () => {
        onLayerConfirm ? setOnLayerConfirm(false) : setOpenModal(false)
    }

    useLayoutEffect(() => {
        const today = dayjs();
        let selectedDate = today;
    
        if (deliverable.actualStartDate) {
            const plannedFinishDate = dayjs(deliverable.plannedFinishDate);
            if (plannedFinishDate.isBefore(today)){
                if(plannedFinishDate.isBefore(deliverable.actualStartDate))
                    selectedDate = dayjs(deliverable.actualStartDate)
                else
                    selectedDate = plannedFinishDate;
            }
        } else if (deliverable.plannedStartDate) {
            const plannedStartDate = dayjs(deliverable.plannedStartDate);
            if (plannedStartDate.isBefore(today)) selectedDate = plannedStartDate;
        }
        setDateSelected(selectedDate);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModal])

    const onConfirm = () => {
        if (!deliverable.actualFinishDate && !deliverable.actualStartDate && !onLayerConfirm && isThisOneDayDeliverableWithout()) {
            // Handelling for 1 Day Deliverable
            setOnLayerConfirm(true);
        } else if (deliverable.actualStartDate && !onLayerConfirm) {
            setOnLayerConfirm(true);
        } else if (!deliverable.actualStartDate) {
            onSave()
        }
        else if (deliverable.actualStartDate && onLayerConfirm) {
            onSave();
        } else if (!deliverable.actualStartDate && !deliverable.actualFinishDate && onLayerConfirm && !deliverable.approvals?.length) {
            // Handelling for 1 Day Deliverable
            onSave()
        }
    }

    const onSucessUpdateProjectDeliverable = () => {
        dispatch(getProjectOverviewData(String(projectId)));
        dispatch(getProjectDeliverablesData(String(projectId)));
        dispatch(getProjectDeliverableDetailById({ projectDeliverableId: String(deliverable.id) }));
        dispatch(asyncLaunchNotification({
            type: "success",
            config: {
                message: `Project Deliverable`,
                description: `Project deliverable updated successfully`
            }
        }))
    }

    const onSave = () => {
        if (!deliverable?.id || !dateSelected) { return };
        dispatch(updateProjectDeliverable({
            projectId: String(projectId),
            deliverableId: String(deliverable?.id),
            bodyRequest: {
                id: deliverable.id,
                ...((deliverable.actualStartDate || (isDeliverableDurationOneDay && !deliverable.approvals?.length)) && { actualFinishDate: removeTimeZone(dateSelected) }),
                ...!deliverable.actualStartDate && { actualStartDate: removeTimeZone(dateSelected) }
            },
            onSuccess: onSucessUpdateProjectDeliverable
        }));
    }

    const footer = (
        <ConfigProvider theme={{ token: { colorBgElevated: token.colorBgContainer } }}>
            <Divider className="mt-0 mb-0 pt-0" />
            <Row className="flex flex-row items-center justify-between pt-15 pb-15 pl-30 pr-30">
                <Button onClick={onCancel} className="pl-30 pr-30">
                    {onLayerConfirm ? `Go Back` : `Cancel`}
                </Button>
                <Button onClick={() => setDateSelected(dayjs())} type='link'>Today</Button>
                <Button onClick={onConfirm} type="primary" className="pl-30 pr-30">
                    {onLayerConfirm ? `Confirm` : `Save`}
                </Button>
            </Row>
        </ConfigProvider>
    )

    // Calendar
    const wrapperStyle = { width: WIDTH_COMPONENT };

    // Date should be type DateType of ant Design but is not provided.
    const onSelectDate = (date: Dayjs) => {
        setDateSelected(date)
    }

    const disabledDate = (current: Dayjs) => {
        const moreThanToday = current && current > dayjs().endOf('day')
        let lessThanActualStartDate = false;
        if (deliverable.actualStartDate) {
          lessThanActualStartDate = current && current < dayjs(deliverable.actualStartDate)
        }
        return !!moreThanToday || !!lessThanActualStartDate;
    };

    const dateFullCellRender = (date: any): React.ReactNode => (
        <div className="ant-picker-cell-inner">
            {date?.date!()}
        </div>
    )

    const renderHeaderCalendar = ({ value, type, onChange }: { value: Dayjs; type: CalendarMode; onChange: (date: Dayjs) => void; }) => {

        let current = value.clone();
        
        const localeData = dayjs(value).localeData();
        const months: MonthNames[] = [];
        for (let i = 0; i < 12; i++) {
            current = current.month(i);
            current && localeData && months.push(localeData.months(current));
        }
        const year = value.year();
        const month = value.month();

        const PreviousMonth = () => month - 1
        const NextMonth = () => month + 1

        const setMonth = (direction: () => number) => {
            const now = value.clone().month(direction());
            onChange(now);
        }

        return (
            <>
                <Row>
                    <Col className='pr-10 pl-10 cursor-pointer select-none' onClick={() => setMonth(PreviousMonth)} >
                        <LeftOutlinedIcon />
                    </Col>
                    <Col flex={"auto"}>
                        <div className='flex flex-row justify-center items-center select-none pb-10'>
                            <div className='font-semibold pr-10'>{year}</div>
                            <div className='font-semibold'>{months[month]}</div>
                        </div>
                    </Col>
                    <Col className='pr-10 pl-10 cursor-pointer select-none' onClick={() => setMonth(NextMonth)}>
                        <RightOutlinedIcon />
                    </Col>
                </Row>
            </>
        );
    }
    
    const CustomCalendarElement = (
        <Row style={wrapperStyle}>
            <Calendar
                className='pr-30 pl-30 pb-15 border-t-0'
                fullscreen={false}
                disabledDate={disabledDate}
                onChange={onSelectDate}
                fullCellRender={dateFullCellRender}
                headerRender={renderHeaderCalendar}
                value={dateSelected}
                {...dateSelected && { defaultValue: dateSelected }}
            />
        </Row>
    )

    const CustomConfirmElement = (
        <Row className='flex flex-col items center' hidden={!onLayerConfirm}>
            <div className='pt-40 mt-10 pr-40 pl-40 pb-20 mr-5 ml-5 text-center' style={{ fontSize: 20 }}>
                {dateSelected?.format(DATE_FORMAT_FULL_NAME_MONTH)}
            </div>
            <div className='pr-40 pl-40 pb-20 mr-5 ml-5 text-center font-semibold'>
                Please confirm the above date is correct.
            </div>
            <div className='pr-40 pl-40 pb-30 mr-5 ml-5 text-center' style={{ color: token.colorTextSecondary }}>
                Once you confirm this, the deliverable will be submitted and you will no longer be able to make changes.
            </div>
        </Row>
    )


    const renderDeliverableActions = (deliverable: IProjectDeliverable) => {
        if (!deliverable.plannedStartDate) { return }

        if (deliverable.actualStartDate === null) {
            return (<Button type="primary" onClick={onOpenEdition}> {(isDeliverableDurationOneDay && !deliverable.approvals?.length) ? "Start and complete deliverable" : "Start Deliverable"} </Button>)
        }

        if (deliverable.actualStartDate !== null) {
            const hasApprovals = isArrayWithValues(deliverable?.approvals);
            const hasRequestForAppoval = hasApprovals && deliverable?.approvals?.some((item) => item.requestDate !== null);

            if (hasApprovals && !hasRequestForAppoval) {
                const hasError = deliverable?.approvals?.some!((item) => String(item.policy) === POLICY.INVALID)
                if (hasError) {
                    return <EmptyButton />
                }

                return (<Button type="primary" onClick={() => setOpenModalSubmitForApproval(true)}> Request for Approval </Button>)
            }
            if (!hasApprovals) {
                return (<Button type="primary" onClick={onOpenEdition}> Complete Deliverable </Button>)
            }
        }

    }

    const HandlerDeliverableActions = (
        <Can I="EDIT" A="PROJECT_DELIVERABLES" Entity={{ id: deliverable?.id }}>
            <ConfigProvider theme={{ token: { colorPrimary: secondaryColor } }}>
                {renderDeliverableActions(deliverable)}
            </ConfigProvider>
        </Can>
    )

    return (
        <>
            {HandlerDeliverableActions}

            <ModalWrapper
                title={header}
                footer={footer}
                open={openModal}
                rootClassName="rootAppearence"
                style={{ backgroundColor: token.colorBgContainer }}
                bodyStyle={{ backgroundColor: token.colorBgContainer }}
                width="480px"
            >
                {
                    onLayerConfirm ? CustomConfirmElement : CustomCalendarElement
                }
            </ModalWrapper>

            <SubmitForApprovalModal open={openModalSubmitForApproval} setOpen={setOpenModalSubmitForApproval} deliverable={deliverable} />
        </>
    )
}
