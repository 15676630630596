import { If } from "@components/Utils/Structural";
import { useAppDispatch, useAppSelector } from "@store/store";
import { updateEntityType as updateProjectType, getEntity } from '@store/slices/projects';
import { getEntities as getProjectTypes } from '@store/slices/project-types';
import { Button, Col, Divider, Row, theme, Select } from 'antd';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';



const ProjectType = () => {

    const { token: { fontSizeHeading3, colorBorderSecondary, colorTextSecondary, colorTextTertiary, colorFillSecondary, colorBgContainer, fontSize } } = theme.useToken();

    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(getProjectTypes({}));
    }, [dispatch]);

    const [isManaging, setIsManaging] = useState<boolean>(false);

    const { entity: projectEntity } = useAppSelector((state) => state.Project);

    const { entities: projectTypes } = useAppSelector((state) => state.ProjectTypes);

    const [selectedProjectTypeId, setSelectedProjectTypeId] = useState<number | null>(null)

    const toggleManage = () => {
        setIsManaging(!isManaging);
    }

    const confirmUpdateProjectType = () => {
        const projectTypeSelected = projectTypes.find(({ id }) => String(id) === String(selectedProjectTypeId));
        if (projectEntity && projectTypeSelected) {
            dispatch(updateProjectType({
                payload: { id: String(projectEntity.id), projectType: projectTypeSelected },
                onError
            })).then(() => {
                setIsManaging(false)
                dispatch(getEntity(String(projectEntity.id)))
                setSelectedProjectTypeId(null)
            })
        }
    }

    const onError = (error: unknown) => { console.log(error) }


    const handleSelectChange = (value: number) => {
        setSelectedProjectTypeId(value)

    }

    return (
        <div className={`block sticky top-0 mb-20`} style={{ border: `1.2px solid ${colorBorderSecondary}`, borderRadius: 10, background: colorBgContainer, zIndex: 10, padding: 10 }}>
            <Row justify={"space-between"} align="middle" style={{ paddingTop: 10 }}>
                <Col className='pt-3 pb-3'>
                    <span className='font-semibold pl-15' style={{ fontSize: fontSizeHeading3, color: colorTextSecondary }}>
                        {`${t('generic.project')}`} Type
                    </span>
                </Col>
            </Row>
            <Divider className='mt-8 mb-8' style={{ borderColor: colorFillSecondary }} />
            <Row className='flex flex-row items-center justify-between'>
                <If condition={!isManaging && Boolean(projectEntity.projectType?.name)}>
                    <>
                        <If condition={Boolean(projectEntity?.projectType?.name)}>
                            <Row className="mb-10 mt-10 flex-col align-end ml-5">
                                <div className='flex flex-col pl-10' style={{ color: colorTextTertiary, fontSize: fontSize + 3 }}>
                                    {projectEntity?.projectType?.name}
                                </div>
                            </Row>
                        </If>
                    </>
                </If>
                <If condition={isManaging}>
                    <Row className="mt-3">
                        <div className="pt-3 mb-3 mr-5 mb-10 mt-10 cursor-pointer" style={{ flex: 1, borderRadius: "4px", padding: "5px" }}>
                            <Select
                                style={{ width: 300 }}
                                value={selectedProjectTypeId || projectEntity.projectType?.id}
                                onChange={handleSelectChange}
                                options={projectTypes.map((item) => ({ label: item.name, value: item.id }))}
                                placeholder="Select Project Type"
                            />
                        </div>
                    </Row>
                </If>
                <Row className='flex items-center'>
                    <If condition={!isManaging}>
                        <Button type='primary' onClick={toggleManage}>Manage</Button>
                    </If>
                    <If condition={isManaging}>
                        <>
                            <Button className='mr-5' onClick={toggleManage}>Cancel</Button>
                            <Button type='primary' onClick={confirmUpdateProjectType}>Save</Button>
                        </>
                    </If>
                </Row>
            </Row>
        </div>

    )
}

export default ProjectType;