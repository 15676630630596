export enum TimelineEntryTypeEnum {
  CHANGE_ORDER_CREATION = 'CHANGE_ORDER_CREATION',
  CHANGE_ORDER_STATUS_CHANGE = 'CHANGE_ORDER_STATUS_CHANGE',
  CHANGE_ORDER_INITIAL_REVIEW = 'CHANGE_ORDER_INITIAL_REVIEW',
  CHANGE_ORDER_FINAL_REVIEW = 'CHANGE_ORDER_FINAL_REVIEW',
  CHANGE_ORDER_FILE_ADDED = 'CHANGE_ORDER_FILE_ADDED',
  CHANGE_ORDER_FILE_REVISION_ADDED = 'CHANGE_ORDER_FILE_REVISION_ADDED',
  CHANGE_ORDER_FILE_REMOVED = 'CHANGE_ORDER_FILE_REMOVED',
  CHANGE_ORDER_FILE_REVISION_REMOVED = 'CHANGE_ORDER_FILE_REVISION_REMOVED',
  // CHANGE_ORDER_FIELDS_UPDATE = 'CHANGE_ORDER_FIELDS_UPDATE',
  CHANGE_ORDER_PENDING_PUBLISHING = 'CHANGE_ORDER_PENDING_PUBLISHING',
  CHANGE_ORDER_PENDING_ESTIMATE_RESUBMISSION = 'CHANGE_ORDER_PENDING_ESTIMATE_RESUBMISSION',
  CHANGE_ORDER_PENDING_DETAIL_SUBMISSION = 'CHANGE_ORDER_PENDING_DETAIL_SUBMISSION',
  CHANGE_ORDER_PENDING_DETAIL_RESUBMISSION = 'CHANGE_ORDER_PENDING_DETAIL_RESUBMISSION',
}
