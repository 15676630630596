import { TabContainer } from '@components/Utils/TabContainer';
import { Col, Row, theme } from 'antd';
import { ChangeOrdersListBar } from './ChangeOrdersListBar';
import { ChangeOrdersListHeader } from './ChangeOrdersListHeader';
import { ChangeOrdersTable } from './ChangeOrdersTable';
import { useEffect, useState } from 'react';
import { reset } from '@store/slices/change-orders';
import { useAppDispatch } from '@store/store';


export const ChangeOrdersList = () => {
    const { token: { padding, colorBgContainer } } = theme.useToken();

    const [showOpenFilter, setShowOpenFilter] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(reset());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOpenChangeOrderFilterChange = (checked: boolean) => {
        setShowOpenFilter(checked);
    }

    return (
        <TabContainer>
            <div className='overflow-auto'>
                <Row style={{ paddingLeft: `${padding}px`, paddingRight: `${padding}px`, backgroundColor: colorBgContainer}} className="pt-15 pb-15 w-full">
                    <Col span={24}>
                        <ChangeOrdersListHeader />
                    </Col>
                </Row>
                <Row style={{ paddingLeft: `${padding}px`, paddingRight: `${padding}px` }} className="pt-20 w-full">
                    <Col span={24}>
                        <ChangeOrdersListBar openFilterChange={handleOpenChangeOrderFilterChange} />
                    </Col>
                </Row>
                <Row style={{ paddingLeft: `${padding}px`, paddingRight: `${padding}px` }} className="pt-20 w-full">
                    <Col span={24}>
                        <ChangeOrdersTable showOnlyOpen={showOpenFilter} />
                    </Col>
                </Row>
            </div>
        </TabContainer>
    )

}