import { Col, Form, Row, Select } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IRole } from '../../../../models/project-template-model';
import { setValidStep, Steps, assignUserToRole, clearAssignUserToRole } from '../../../../store/slices/project-steps';
import { getTemplatesRoles } from '../../../../store/slices/project-templates-extended';
import { getUsers } from '../../../../store/slices/users-extended';
import { AppDispatch, useAppSelector } from '../../../../store/store';
import { contentStyleSteps } from '../AddProject';

export const Step3 = () => {

  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();

  const { projectToCreate } = useAppSelector((state) => state.ProjectSteps);
  const { rolesList } = useAppSelector((state) => state.ProjectTemplatesExtended);
  const { usersList } = useAppSelector((state) => state.UsersExtended);

  useEffect(() => {
    if (projectToCreate.templateId) {
      dispatch(getTemplatesRoles(projectToCreate.templateId));
    }
    dispatch(getUsers());

  }, [dispatch, projectToCreate])


  const onFinish = (entity: any): void => {
    dispatch(setValidStep({ step: Steps.STEP2, modification: { isValid: true } }))

  };

  const onFinishFailed = () => {
    dispatch(setValidStep({ step: Steps.STEP1, modification: { isValid: false } }))
  };

  const onSelectSubmit = (selectedUserId: number, roleId: number) => {
    dispatch(assignUserToRole({ selectedUserId, roleId }))
  }
  const onClearSubmit = (roleId: number) => {
    dispatch(clearAssignUserToRole(roleId))
  }


  const options = usersList.map(elem => {
    return { label: elem.fullName, value: elem.id }
  })

  return (
    <div className='ml-30 mr-30' style={contentStyleSteps}>
      <span className="flex text-bold pr-30 pt-20 pb-30" style={{ fontSize: '16px' }}>Add your Team</span>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={16}>
          <Col span={24}>
            {rolesList.map((roleElem: IRole, index) => {
              return <Form.Item key={index} name={roleElem.id} label={roleElem.name} >
                <Select
                  showSearch
                  options={options}
                  allowClear
                  placeholder="Select User"
                  filterOption={(inputValue, option) =>
                    option!.label!.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onSelect={(selectedUser: any) => onSelectSubmit(selectedUser, roleElem.id)}
                  onClear={() => onClearSubmit(roleElem.id)}

                />
              </Form.Item>
            })}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div>
              {/* TODO */}
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}


