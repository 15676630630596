import * as d3 from "d3";
import { Camera } from "./camera";

export class Minimap {
  static refresh(
    instanceId: string,
    pixelsPerMeter: number,
    zoomBehavior: any
  ) {
    const minimap = d3.select("#minimap-" + instanceId);
    if (!minimap.node()) return;
    minimap.style("backdrop-filter", "blur(5px)");
    minimap.selectAll("*").remove();

    const containerSvg = d3.select("#" + instanceId);
    const mainG = containerSvg.select("g");

    const mainGBox = mainG.node().getBoundingClientRect();
    let clonedMainG = mainG.clone(true);

    clonedMainG.attr("transform", "");
    clonedMainG.selectAll("*").style("cursor", "pointer");
    clonedMainG.selectAll(".asset-controls").remove();
    clonedMainG.selectAll(".measurement").remove();
    clonedMainG.on("click", (event: MouseEvent) => {
      const minimapWidth = minimap.node().getBoundingClientRect().width;
      const minimapHeight = minimap.node().getBoundingClientRect().height;
      const mainGWidth = mainG.node().getBoundingClientRect().width;
      const mainGHeight = mainG.node().getBoundingClientRect().height;
      const clonedGWidth = clonedMainG.node().getBoundingClientRect().width;
      const clonedGHeight = clonedMainG.node().getBoundingClientRect().height;
      const minimapOffsetX = (minimapWidth - clonedGWidth) / 2;
      const minimapOffsetY = (minimapHeight - clonedGHeight) / 2;

      const xFactor = minimapWidth / mainGWidth;
      const yFactor = minimapHeight / mainGHeight;
      const factor = Math.min(xFactor, yFactor);
      const mainGx = (event.offsetX - minimapOffsetX) / factor;
      const mainGy = (event.offsetY - minimapOffsetY) / factor;

      const currentTransform = d3.zoomTransform(mainG.node());
      const pxPerM = pixelsPerMeter;
      const maingGxM = mainGx / pxPerM / currentTransform.k;
      const maingGyM = mainGy / pxPerM / currentTransform.k;

      Camera.goTo(maingGxM, maingGyM, instanceId, pixelsPerMeter, zoomBehavior);
    });
    minimap.node().appendChild(clonedMainG.node());

    const minimapViewport = minimap
      .append("rect")
      .attr("id", "minimap-viewport-" + instanceId)
      .attr("cursor", "pointer");

    const currentTransform = d3.zoomTransform(mainG.node());

    minimap.attr(
      "viewBox",
      "0 0 " +
        mainGBox.width / currentTransform.k +
        " " +
        mainGBox.height / currentTransform.k
    );

    const modifiedTransform = d3.zoomIdentity
      .scale(1 / currentTransform.k)
      .translate(-currentTransform.x, -currentTransform.y);

    minimapViewport
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("width", containerSvg.node().getBoundingClientRect().width)
      .attr("height", containerSvg.node().getBoundingClientRect().height)
      .attr("fill", "none")
      .attr("stroke", "currentColor")
      .attr("stroke-width", 10 / modifiedTransform.k)
      .attr("transform", modifiedTransform);
  }
}
