import { EditOutlined } from "@ant-design/icons";
import { ManagerAlbumDrawer } from "../ManagerAlbumDrawer";
import { CustomButtonLink } from "@components/Utils/CustomButtonLink";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@store/store";
import useVisible from "@HOOKs/UseVisible";

interface IEditAlbumButtonProps {}

export const EditAlbumButton = (props: IEditAlbumButtonProps) => {

    const [visibleDrawerAddAlbum, setVisibleDrawerAddAlbum] = useVisible();

    const { entity: album } = useAppSelector((store) => store.PhotoAlbum);
    
    const { t } = useTranslation();

    return (
        <>
            <CustomButtonLink onClick={setVisibleDrawerAddAlbum} style={{ paddingRight: 0, paddingLeft: 0}}>
                <EditOutlined />
                {t("generic.edit")}
            </CustomButtonLink>    
            { album && visibleDrawerAddAlbum && <ManagerAlbumDrawer open={visibleDrawerAddAlbum} toogle={setVisibleDrawerAddAlbum} mode={"EDITION"} album={album} /> }
        </>
    )
}
