

import { theme } from "antd";
import { SVGProps } from "react";

interface IProps extends SVGProps<SVGSVGElement> {
    checked?: boolean
}

export const CheckBoxCircleSvgIcon = (props: IProps) => {  

    const { checked = false, ...restProps } = props;

    const  { token: { colorPrimary, colorPrimaryBg }} = theme.useToken();

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none" {...restProps} >
            
            <path d="M18 2.25C9.30234 2.25 2.25 9.30234 2.25 18C2.25 26.6977 9.30234 33.75 18 33.75C26.6977 33.75 33.75 26.6977 33.75 18C33.75 9.30234 26.6977 2.25 18 2.25ZM18 31.0781C10.7789 31.0781 4.92188 25.2211 4.92188 18C4.92188 10.7789 10.7789 4.92188 18 4.92188C25.2211 4.92188 31.0781 10.7789 31.0781 18C31.0781 25.2211 25.2211 31.0781 18 31.0781Z" fill={colorPrimary}/>
            
            {
                checked && 
                (
                    <>
                        <path d="M18 4.92188C10.7789 4.92188 4.92188 10.7789 4.92188 18C4.92188 25.2211 10.7789 31.0781 18 31.0781C25.2211 31.0781 31.0781 25.2211 31.0781 18C31.0781 10.7789 25.2211 4.92188 18 4.92188ZM24.7992 12.8566L17.3953 23.1223C17.2918 23.2667 17.1554 23.3844 16.9974 23.4656C16.8393 23.5468 16.6642 23.5891 16.4865 23.5891C16.3088 23.5891 16.1337 23.5468 15.9757 23.4656C15.8176 23.3844 15.6812 23.2667 15.5777 23.1223L11.1973 17.0473C11.0637 16.8609 11.1973 16.6008 11.4258 16.6008H13.0746C13.4367 16.6008 13.7742 16.7766 13.9852 17.0684L16.4883 20.5418L22.0148 12.8777C22.2258 12.5824 22.5668 12.4102 22.9254 12.4102H24.5742C24.8027 12.4102 24.9363 12.6703 24.7992 12.8566Z" fill={colorPrimaryBg}/>
                        <path 
                            d="M24.5747 12.4102H22.9259C22.5673 12.4102 22.2263 12.5824 22.0153 12.8777L16.4887 20.5418L13.9856 17.0684C13.7747 16.7766 13.4372 16.6008 13.0751 16.6008H11.4262C11.1977 16.6008 11.0641 16.8609 11.1977 17.0473L15.5782 23.1223C15.6817 23.2667 15.8181 23.3844 15.9761 23.4656C16.1342 23.5468 16.3093 23.5891 16.487 23.5891C16.6647 23.5891 16.8398 23.5468 16.9978 23.4656C17.1559 23.3844 17.2923 23.2667 17.3958 23.1223L24.7997 12.8566C24.9368 12.6703 24.8032 12.4102 24.5747 12.4102Z" 
                            fill={colorPrimary}
                        />
                    </>
                )
            }
            
        </svg>
    )
}