import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Space } from "antd";

import { AppDispatch, useAppSelector } from "@store/store";
import { getEntity } from "@store/slices/site";
import { getEntities as getSiteStatuses } from "@store/slices/sites-statuses";
import { updateEntity } from "@store/slices/site";
import { useNavigate } from "react-router-dom";
import { DATE_FORMAT_DEFAULT } from '@shared/util/date-utils';
import { ISite } from '@models/site.model';
import { useErrorAnimation } from "@HOOKs/UseErrorAnimation";
import dayjs from "dayjs";
import { Can } from "@components/Permisions/Can";
import { useTranslation } from "react-i18next";

export const SitesUpdate = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const [animateError, playAnimationOfError] = useErrorAnimation();

    const { entity: siteEntity = {}, updating } = useAppSelector((state) => state.Sites);
    const { entities: siteStatuses, loading: loadingSiteStatuses } = useAppSelector((state) => state.SiteStatuses);

    const [open, setOpen] = useState(true);
    const [form] = Form.useForm();

    const { id } = useParams<"id">();

    useEffect(() => {
        dispatch(getSiteStatuses({ sort: "order,asc" }));
    }, [dispatch]);

    useEffect(() => {
        if (siteEntity) {
            form.setFieldsValue({
                ...siteEntity,
                openDate: siteEntity?.openDate ? dayjs(siteEntity?.openDate) : null,
                closeDate: siteEntity?.closeDate ? dayjs(siteEntity?.closeDate) : null
            });
            siteEntity?.status &&
                form.setFieldValue("status", siteEntity?.status?.id);
        }
    }, [form, siteEntity]);


    const onClose = (): void => {
        const siteId = `${siteEntity.id}`;
        setOpen(false);
        dispatch(getEntity(siteId));
        navigate("./../", { relative: "route" });
    };

    const onFinish = (entity: Partial<ISite>): void => {
        const { name, openDate, closeDate, status } = entity;
        const entityToCreate: Partial<ISite> = {
            id: siteEntity.id,
            ...name && { name },
            ...openDate && { openDate },
            ...closeDate && { closeDate },
            ...status && { status: [...siteStatuses]?.find!((item) => item.id === status) },
            location: {
                id
            }
        };
        dispatch(updateEntity({ ...entityToCreate })).then((result) => {
            const { code } = result.payload as { code: string } || null;
            if (!code) {
                // if not error codes, close the modal
                onClose();
            }
        });
    };

    const onFinishFailed = () => {
        playAnimationOfError();
    };

    const onSubmit = () => {
        form.submit();
    };

    return (
        <>
            <Drawer
                title={`Edit ${t("generic.site")}`}
                width={580}
                onClose={onClose}
                closable={false}
                open={open}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button type="primary" ghost onClick={onClose}>
                            Cancel
                        </Button>
                        <Can I="EDIT" A="group.oak.siterise.service.dto.SiteDTO" effect="BLOCK" >
                            <Button onClick={onSubmit} type="primary" loading={updating}>
                                Save
                            </Button>
                        </Can>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={siteEntity}
                    className={`${animateError && "error-animation"}`}
                >

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label={`${t("generic.site")} Name`}
                                rules={[
                                    { required: true, message: `Please enter ${t("generic.site")} Name` },
                                ]}
                            >
                                <Input placeholder={`Please enter ${t("generic.site")} Name`} allowClear />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="openDate" label="Open Date">
                                <DatePicker
                                    name="openDate"
                                    className="w-full"
                                    format={DATE_FORMAT_DEFAULT}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="closeDate" label="Close Date">
                                <DatePicker
                                    name="closeDate"
                                    className="w-full"
                                    format={DATE_FORMAT_DEFAULT}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="status" label="Status">
                                <Select
                                    loading={loadingSiteStatuses}
                                    placeholder="Please select a Status"
                                    options={siteStatuses?.map((item) => ({ value: item.id, label: item?.description }))}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>

            </Drawer>
        </>
    );
}
