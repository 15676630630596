/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { IGroup } from "@models/group.model";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Col, Divider, Row, Typography, theme } from "antd";
import { GroupEdit } from "./GroupEdit";
import { TabContainer } from "@components/Utils/TabContainer";
import { BackButton } from "@components/Utils/BackButton";
import { COLOR_TEXT_TERTIARY, NEUTRAL_6_COLOR, WHITE, useThemeProvider } from "@providers/ThemeProvider";
import { EditOutlined  } from "@ant-design/icons";
import { useNavigate, useParams } from 'react-router-dom';
import { getEntity } from "@store/slices/groups";
import { GroupUserList } from "./GroupUserList";
import useVisible from "@HOOKs/UseVisible";

export const GroupDetailTab = () => {

    const { themeConfig } = useThemeProvider();

    const { token: { marginLG, colorBgContainer, colorBorderSecondary, colorPrimary } } = theme.useToken();

    const [openEditGroup, toogleEditGroup] = useVisible();

    const navigate = useNavigate();

    const { entity: group } = useAppSelector((state) => state.Groups);

    const dispatch = useAppDispatch();

    // eslint-disable-next-line
    const { groupId } = useParams<"groupId">();

    useEffect(() => {
        groupId && dispatch(getEntity(groupId))
    }, [])

    const [groupSelected, setGroupSelected] = useState<IGroup>({})

    const editGroup = () => {
        setGroupSelected({...group});
        toogleEditGroup()
    }

    const navigateBack = () => {
        navigate(`/admin/access-and-permissions/groups`);
    }

    const onModifiedChanges = (group: IGroup) => {
        group?.id && navigate(`/admin/access-and-permissions/groups/${group?.id}`);
        group?.id && dispatch(getEntity(group?.id));
    }

    return (
        <>
            <GroupEdit open={openEditGroup} toogle={toogleEditGroup} group={groupSelected} onModifiedChanges={onModifiedChanges}/>
            <TabContainer>
                <div className="flex flex-col overflow-auto">
                    <Row>
                        <Col span={24}>
                            <div className="flex flex-row items-center pl-10 pt-11 pb-11" style={{ borderTop: `1px solid ${colorBorderSecondary}`, borderBottom: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                                <BackButton onClick={navigateBack} >Group List</BackButton>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ paddingLeft: marginLG + 25, paddingRight: marginLG, paddingBottom: '10px', background: colorBgContainer }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <Row className="flex flex-row items-center justify-between mt-12">
                                <Row className="flex flex-row items-center">  
                                    <Typography.Title level={5} className='inline pr-10 pb-0 imb-0' style={{ color: themeConfig.darkMode ? WHITE : COLOR_TEXT_TERTIARY, fontWeight: 500  }}>
                                        { `${group?.name || ""}` }
                                    </Typography.Title>
                                    <Typography.Title level={5} className='inline pr-10 pb-0 imt-0 imb-0' style={{ color: themeConfig.darkMode ? WHITE : NEUTRAL_6_COLOR, fontWeight: 500  }}>
                                        #{group?.id || ""} 
                                    </Typography.Title>
                                </Row>
                                <Row className="flex flex-row items-center cursor-pointer pr-20" style={{ color: colorPrimary }} onClick={editGroup} >
                                    <EditOutlined className="mr-8 scale-90" />
                                    <span>Edit Group</span>
                                </Row>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="">
                        <Divider className="mt-0 mb-0 mr-0 ml-0" />
                    </Row>
                    <GroupUserList groupId={group?.id} groupName={group?.name} />
                </div>
            </TabContainer>
        </>
    )
}
