import { ShapeTypeEnum } from './enumerations/shape-type.enum';

export interface IShape {
  id?: string | undefined;
  left?: number;
  top?: number;
  width?: number;
  height?: number;
  type?: ShapeTypeEnum;
  color?: string;
  ref?: any;
  rotate?: number;
  text?: string;
}

export const defaultValue: IShape = {};
