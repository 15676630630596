/* eslint-disable react-hooks/exhaustive-deps */
import { URL_PARAM_ATTACHMENT_SELECTED_KEY } from "@constants/photoAlbum";
import { StringORNumber } from "@infrastructure/repositories/utils.repository";
import { IAttachment } from "@models/attachment.model";
import { asyncLaunchNotification } from "@store/slices/notification";
import { useAppDispatch } from "@store/store";
import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
interface IUseSelectFileByRouteParameterProps { 
    setFileSelected: (file: any) => void;
    setVisible: (visible: boolean) => void;
    attachments: readonly IAttachment[];
    firstLoaded: boolean;
}

const useSelectFileByRouteParameter = (props: IUseSelectFileByRouteParameterProps) => {
    
    const { setFileSelected, setVisible, attachments, firstLoaded } = props;

    let [searchURLParams, ] = useSearchParams();

    const dispatch = useAppDispatch();

    const isFirstRenderRef = useRef<boolean>(false);

    const urlParamWasProcessedRef = useRef<boolean>(false);

    useEffect(() => {
        isFirstRenderRef.current = true;
    }, []);

    useEffect(() => {
        if (firstLoaded) {
            const isUrlParamWasProcessed = urlParamWasProcessedRef?.current;
            if (isFirstRenderRef?.current && !isUrlParamWasProcessed) {
                urlParamWasProcessedRef.current = true;
                const hasAttachmentSelectedIdparamFromUrl = searchURLParams.has(URL_PARAM_ATTACHMENT_SELECTED_KEY);
                if (hasAttachmentSelectedIdparamFromUrl) {
                    processUrlParamAttachmentSelectedId(String(searchURLParams.get(URL_PARAM_ATTACHMENT_SELECTED_KEY)))
                }
            }
        }
    }, [attachments, firstLoaded]);

    const processUrlParamAttachmentSelectedId = (attachmentSelectedIdparamFromUrl: StringORNumber) => {
        const fileFounded = attachments.find((item) => String(item?.id) === String(attachmentSelectedIdparamFromUrl));
        if (fileFounded) {
            setFileSelected(fileFounded);
            setVisible(true);
        } else {
            dispatch(asyncLaunchNotification({
                type: "error",
                config: {
                    message: `Photo Gallery`,
                    description: `The photo you are trying to access does not exist or is not in the gallery.`
                }
            }));
        }
    }
};
  
export default useSelectFileByRouteParameter;