import { CloseOutlined } from "@ant-design/icons";
import { statusCollectionAscSorted } from "@mappers/ProjectDeliverable.mapper";
import { isArrayWithValues, sortCollectionByFieldString } from "@shared/util/array-util";
import { IAdvancedFilters, filterDeliverablesByAdvancedFilters } from "@store/slices/project-deliverables";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Button, Col, Divider, Form, Row, Select, Switch, Typography, theme } from "antd";
import { useTranslation } from "react-i18next";

interface IAdvancedFiltersContainerProps {
    setPopupVisible: (value: boolean) => void;
    initialValues: IInitalValuesForm;
}

export interface IInitalValuesForm {
    status?: string[];
    disciplines?: string[];
    roles?: string[];
    showOnlyKeyMilestone?: boolean;
    hideNotApplicable?: boolean
}

const genericFilter = (input: string, option: any) => (
    option?.label?.toLowerCase!()?.indexOf!(input?.toLowerCase!()) >= 0
)

export const AdvancedFiltersContainer = (props: IAdvancedFiltersContainerProps) => {

    const { setPopupVisible, initialValues } = props;

    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { token: { colorPrimaryTextHover, colorTextQuaternary } } = theme.useToken();
    const { entities: roles } = useAppSelector((store) => store.Roles);
    const { entities: disciplines } = useAppSelector((store) => store.Discipline);
    const { entities: phases } = useAppSelector((store) => store.Phase);

    const onApplyFilter = () => {

        // Read Params from Form
        const status = form.getFieldValue("status");
        const disciplines = form.getFieldValue("disciplines");
        const phases = form.getFieldValue("phases");
        const roles = form.getFieldValue("roles");
        const showOnlyKeyMilestone = form.getFieldValue("showOnlyKeyMilestone");
        const hideNotApplicable = form.getFieldValue("hideNotApplicable");

        // Prepare / Process Params to Save in the Store
        const paramsToSet = {
            ...isArrayWithValues(status) && { status: status.join(",") },
            ...isArrayWithValues(disciplines) && { disciplines: disciplines.join(",") },
            ...isArrayWithValues(phases) && { phases: phases.join(",") },
            ...isArrayWithValues(roles) && { roles: roles.join(",") },
            ...showOnlyKeyMilestone && { showOnlyKeyMilestone },
            ...hideNotApplicable && { hideNotApplicable }
        } as IAdvancedFilters

        // Set Params in Store
        dispatch(filterDeliverablesByAdvancedFilters(paramsToSet))
    }

    const onOk = () => {
        // Close Popover Menu
        setPopupVisible(false);
    }

    const onReset = () => {
        // Clear Form State
        form.resetFields();

        // Set Initial Values Again
        form.setFieldsValue({
            status: [],
            roles: [],
            disciplines: [],
            phases: [],
            showOnlyKeyMilestone: false,
            hideNotApplicable: false,
        })

        // Reset Params in Store
        dispatch(filterDeliverablesByAdvancedFilters({}));
    }
    return (
        <div>
            <div className="pt-12 pr-12 pl-12">
                <div className="flex flex-row items-center justify-between">
                    <div>
                        <Typography.Title level={5} style={{ margin: 0, color: colorPrimaryTextHover }}>
                            Deliverable Filter
                        </Typography.Title>
                    </div>
                    <div>
                        <CloseOutlined className="cursor-pointer" title="close" onClick={() => setPopupVisible?.(false)} />
                    </div>
                </div>
                <Divider className="mt-12 mb-12" />

                {/* Form */}
                <Form
                    layout="vertical"
                    form={form}
                    initialValues={initialValues}
                    preserve={false}
                    onFieldsChange={onApplyFilter}
                    style={{ width: '16rem' }}
                >

                    <Row gutter={8}>
                        <Col span={24}>
                            <Form.Item
                                name="status"
                                label={<LabelForm text={"Status"} />}
                                className="mb-0 pb-5"
                            >
                                <Select
                                    mode="multiple"
                                    filterOption={genericFilter}
                                    options={statusCollectionAscSorted}
                                    allowClear={true}
                                    placeholder="ALL"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider className="mt-8 mb-5" />
                    <Row gutter={8}>
                        <Col span={24}>
                            <Form.Item
                                name="disciplines"
                                label={<LabelForm text={"Discipline"} />}
                                className="mb-0 pb-5"
                            >
                                <Select
                                    mode="multiple"
                                    filterOption={genericFilter}
                                    options={sortCollectionByFieldString(disciplines, 'name').map((item) => ({ value: String(item.id), label: item.name }))}
                                    allowClear={true}
                                    placeholder="ALL"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider className="mt-8 mb-5" />
                    <Row gutter={8}>
                        <Col span={24}>
                            <Form.Item
                                name="roles"
                                label={<LabelForm text={"Role"} />}
                                className="mb-0 pb-5"
                            >
                                <Select
                                    mode="multiple"
                                    filterOption={genericFilter}
                                    options={sortCollectionByFieldString(roles, 'name').map((item) => ({ value: String(item.id), label: item.name }))}
                                    allowClear={true}
                                    placeholder="ALL"
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider className="mt-8 mb-5" />
                    <Row gutter={8}>
                        <Col span={24}>
                            <Form.Item
                                name="phases"
                                label={<LabelForm text={"Phases"} />}
                                className="mb-0 pb-5"
                            >
                                <Select
                                    mode="multiple"
                                    filterOption={genericFilter}
                                    options={phases.map((item) => ({ value: String(item.id), label: item.label }))}
                                    allowClear={true}
                                    placeholder="ALL"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider className="mt-5 mb-5" />
                    <Row gutter={8}>
                        <Col span={24}>
                            <div className="flex flex-row items-center justify-between pb-0 mb-0">
                                <LabelForm text={`Show only ${t("projectDeliverable.keyMilestone")}`} />
                                <Form.Item name="showOnlyKeyMilestone" valuePropName="checked" className="mb-0">
                                    <Switch />
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                    <Divider className="mt-5 mb-5" />
                    <Row gutter={8}>
                        <Col span={24}>
                            <div className="flex flex-row items-center justify-between pb-0 mb-0">
                                <LabelForm text="Hide Not Applicable" />
                                <Form.Item name="hideNotApplicable" valuePropName="checked" className="mb-0">
                                    <Switch />
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>

            </div>
            <Divider className="mt-10 mb-0" />
            <div className="flex flex-row justify-between pr-8 pl-8 pt-15 pb-15">
                <Button type="text" size="small" style={{ color: colorTextQuaternary }} onClick={onReset}>
                    {t("generic.reset")}
                </Button>
                <Button type="primary" size="small" onClick={onOk}>
                    {t("generic.ok")}
                </Button>
            </div>
        </div>
    )
}

export const LabelForm = ({ text }: { text: string }) => {
    const { token: { colorPrimaryTextHover } } = theme.useToken();
    return (
        <div className="font-medium" style={{ color: colorPrimaryTextHover }}>{text}</div>
    )
}
