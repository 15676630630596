import { IChangeOrderRevision } from '@models/change-order-revision.model';
import { http } from '../http/axios.instance';
import { StringORNumber } from './utils.repository';

const path = 'change-order-revisions';

export const changeOrderRevisionsRepository = {
    getChangeOrderRevisions: async (changeOrderId: StringORNumber) => {
        const requestUrl = `${path}/${changeOrderId}?cacheBuster=${new Date().getTime()}`;
        const promiseGetAll = await http.get<IChangeOrderRevision[]>(requestUrl);
        return promiseGetAll;
    },
    partialUpdate: async (id: string | number, entity: IChangeOrderRevision): Promise<IChangeOrderRevision> => {
        const requestUrl = `${path}/${id}`;
        const promiseUpdate = await http.patch<IChangeOrderRevision>(requestUrl, entity);
        return promiseUpdate.data;
    },
    getById: async (id: StringORNumber) => {
        const requestUrl = `${path}/${id}`;
        const promiseGetByID = await http.get<IChangeOrderRevision>(requestUrl);
        return promiseGetByID;
    },
}