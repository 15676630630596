import { CustomBox } from '@components/Utils/CustomBox';
import { useAppDispatch, useAppSelector } from "@store/store";
import { Button, Col, Divider, Modal, Row, Select, theme } from "antd";
import { useEffect, useState } from "react";
import { valueType } from 'antd/es/statistic/utils';
import { getProcoreProjects, linkProcoreProjectToAPP, unlinkProcoreProjectToAPP } from '@store/slices/procore-projects';
import { getEntity as getProject } from "@store/slices/projects";
import { useParams } from 'react-router-dom';
import { If } from '@components/Utils/Structural';
import HyperLink from '../../../../../assets/images/icons/hyper-link.svg'
import Warning from '../../../../../assets/images/icons/warning.svg'
import { DisconnectOutlined } from '@ant-design/icons';
import { IProcoreProject } from '@models/procore-project.model';

const ProcoreProjects = () => {

    const { token: { colorPrimary, colorText, colorFill, colorFillTertiary,
        colorFillQuaternary, colorPrimaryBg, fontSizeHeading3,
        colorTextTertiary, colorTextSecondary, colorFillSecondary, colorBorderSecondary, colorBgContainer } } = theme.useToken();

    const SETTING_STATUS_MAP = {
        LINKED: {
            label: "Linked",
            key: "LINKED",
            backgroundColor: colorPrimaryBg,
            borderColor: colorPrimary,
            textColor: colorPrimary
        },
        NOT_LINKED: {
            label: "Not Linked",
            key: "NOT_LINKED",
            backgroundColor: colorFillTertiary,
            borderColor: colorFill,
            textColor: colorTextTertiary
        }
    }

    const { entity: projectEntity, loading: projectEntityLoading } = useAppSelector((state) => state.Project);

    const { data: { procoreProjects } } = useAppSelector(state => state.ProcoreProjects);

    const [status, setStatus] = useState(SETTING_STATUS_MAP.NOT_LINKED.key);
    const [selectedProcoreProject, setSelectedProcoreProject] = useState<IProcoreProject | null>(null);

    const [projectSelected, setProjectSelected] = useState<number | null>(null);


    const [isManaging, setIsManaging] = useState<boolean>(false);

    const dispatch = useAppDispatch();


    useEffect(() => {
        if (projectEntity.procoreId && procoreProjects.length) {
            setSelectedProcoreProject(procoreProjects.find((item) => item.id === Number(projectEntity.procoreId)))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectEntity, procoreProjects])

    useEffect(() => {
        if (projectEntity.procoreId && projectSelected) {
            setSelectedProcoreProject(procoreProjects.find((item) => item.id === Number(projectSelected)))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectSelected])

    useEffect(() => {
        dispatch(getProcoreProjects())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isManaging])




    useEffect(() => {
        if (projectEntity.procoreId) setStatus(SETTING_STATUS_MAP.LINKED.key)
        else setStatus(SETTING_STATUS_MAP.NOT_LINKED.key)

        return () => setIsManaging(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectEntity])


    const toggleManage = () => {
        setIsManaging(!isManaging);
        if (projectEntity.procoreId) setProjectSelected(projectEntity.procoreId)
    }

    const { projectId } = useParams<"projectId">();



    const linkProject = () => {
        if (projectId && projectSelected) {
            dispatch(linkProcoreProjectToAPP({ id: projectId, procoreId: projectSelected })).then(() => {
                dispatch(getProject(projectId));
                setIsManaging(false);
            })
        }
    }


    const onChangeAutocomplete = (projectId: valueType) => {
        projectId && setProjectSelected(+projectId);
    }

    const onClearAutoComplete = () => {
        setProjectSelected(null);
    }

    const [modal, contextHolder] = Modal.useModal();


    const confirmationDialog = (
        <>
            <div
                className="pt-3 pb-3 mb-3 flex-1"
                style={{
                    border: `1px solid ${colorFillTertiary}`,
                    width: "108%",
                    flex: 1,
                    backgroundColor: colorFillQuaternary,
                    borderRadius: "4px",
                    padding: "8px 10px 8px 10px",
                    marginBottom: "8px"
                }}
            >
                <img className="mx-auto mr-10 " src={Warning} alt="Alert" style={{ width: '15px' }} />
                Unlink project from Procore
            </div>
            <div className='w-full' style={{ color: colorTextSecondary, fontWeight: 400 }}>If you unlink this project, it won't be visible in this section anymore.</div>
        </>
    );


    const unLinkProjectConfirm = () => {

        const { confirm } = modal;
        const modalConfirm = confirm({
            type: "error",
            title: '',
            content: confirmationDialog,
            okText: "Confirm and unlink",
            icon: <></>,
            width: 500,
            zIndex: 2000,
            okButtonProps: {
                danger: true
            },
            cancelButtonProps: {},
            onOk: () => {
                if (projectId) {
                    dispatch(unlinkProcoreProjectToAPP({ id: projectId })).then(() => {
                        dispatch(getProject(projectId));
                        setSelectedProcoreProject(null)
                    })
                }
            }
        });
        return modalConfirm;
    }


    const goToProcoreProject = () => {
        projectEntity?.procoreLink && window.open(projectEntity?.procoreLink);
    }

    return (
        <div
            className={`block sticky mb-30 top-0 ${isManaging && "border-container-focus"}`}
            style={{
                //     border: "1px solid #91D5FF", 
                // filter: `drop-shadow(0px 0px 2px #91D5FF)`, 
                border: !isManaging ? `1.2px solid ${colorBorderSecondary}` : "",
                borderRadius: 10, background: colorBgContainer, zIndex: 10, padding: 10
            }}
        >
            <Row justify={"space-between"} align="middle" style={{ paddingTop: 10 }}>
                <Col className='pt-3 pb-3'>
                    <span className='font-semibold pl-15' style={{ fontSize: fontSizeHeading3, color: colorTextSecondary }}>
                        Procore
                    </span>
                </Col>
                <Col className='pt-3 pb-3'>
                    <Row className="mb-10 mt-10 flex-row align-end items-center" style={{ color: colorText }}>
                        {contextHolder}
                        <If condition={isManaging && Boolean(projectEntity.procoreId)}>
                            <Button size='small' type="link" danger className='pr-0 pl-20 mr-10' onClick={() => unLinkProjectConfirm()} >
                                <DisconnectOutlined className="cursor-pointer" />
                                Unlink
                            </Button>
                        </If>
                        <CustomBox
                            style={{
                                backgroundColor: SETTING_STATUS_MAP[status].backgroundColor,
                                border: `1px solid ${SETTING_STATUS_MAP[status].borderColor}`
                            }}
                        >
                            <span className='pt-4 pb-4 pl-8 pr-8 select-none' style={{ color: SETTING_STATUS_MAP[status].textColor }}>
                                {SETTING_STATUS_MAP[status].label}
                            </span>
                        </CustomBox>
                    </Row>

                </Col>
            </Row>
            <Divider className='mt-8 mb-8' style={{ borderColor: colorFillSecondary }} />

            <Row justify={"space-between"} align={'middle'} className="mt-5" style={{ color: colorText }}>
                <div
                    className="pt-3 pb-15 mb-3 pl-10"
                    style={{
                        flex: 1,
                        borderRadius: "4px",
                    }}
                >
                    <Row className='flex flex-row items-center justify-between' style={{ height: 40 }}>
                        <Row className="mb-0 mt-0 flex-col align-end align-center" style={{ color: colorText, flex: 1 }}>
                            <If condition={!isManaging && Boolean(projectEntity.procoreId)}>
                                <div onClick={() => goToProcoreProject()} className=' cursor-pointer flex flex-row' style={{ color: colorPrimary }}>
                                    <img className="mx-auto mr-5 " src={HyperLink} alt="Alert" style={{ width: '15px' }} />
                                    Access To Procore Projects
                                </div>
                            </If>
                            <If condition={!isManaging && !Boolean(projectEntity.procoreId)}>
                                <Row className="mb-10 mt-10 flex-col align-end" style={{ color: colorText }}>
                                    <div className='flex flex-col' style={{ color: colorTextTertiary }}>
                                        No Project Selected
                                    </div>
                                </Row>
                            </If>

                            <If condition={isManaging}>
                                <div
                                    className="pt-3 pb-3 mb-3 mr-10"
                                    style={{
                                        border: `1px solid ${colorFillTertiary}`,
                                        flex: 1,
                                        backgroundColor: colorFillQuaternary,
                                        borderRadius: "4px",
                                        padding: "5px",
                                        cursor: "pointer",
                                    }}
                                >
                                    <Select
                                        loading={projectEntityLoading}
                                        showSearch
                                        className="w-full"
                                        value={selectedProcoreProject?.display_name}
                                        onChange={(valueSelected) => onChangeAutocomplete(valueSelected)}
                                        placeholder="Please select an Project"
                                        options={procoreProjects?.map!((project: any) => ({ value: project.id, label: project?.display_name }))}
                                        filterOption={(input, option) => String(option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        onClear={() => onClearAutoComplete()}
                                    />
                                </div >
                            </If>
                        </Row>
                        <Row className='flex items-center'>
                            <If condition={!isManaging}>
                                <Button type='primary' onClick={toggleManage}>Manage</Button>
                            </If>
                            <If condition={isManaging}>
                                <>
                                    <Button className='mr-5' onClick={toggleManage}>Cancel</Button>
                                    <Button type='primary' onClick={linkProject}>Save</Button>
                                </>
                            </If>
                        </Row>
                    </Row>
                </div >
            </Row >


        </div >
    )
}

export default ProcoreProjects;