import { CashSvgIcon } from '@components/Icons/CashSvgIcon';
import { Button, Steps, Typography, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TabContainer } from '@components/Utils/TabContainer';
const { Text } = Typography;

export const BudgetKickoffDialog = ({ next }: { next: () => void }) => {
  const { projectId } = useParams<'projectId'>();

  const {
    token: { colorPrimary, colorBgContainer, colorTextTertiary },
  } = theme.useToken();
  const { t } = useTranslation();

  const stepsItems = [{ title: 'Draft' }, { title: 'Approved' }];

  const onStartBudget = () => {
    if (!projectId) return;
    next();
  };

  return (
    <TabContainer>
      <div
        className="flex flex-col items-center w-full p-16"
        style={{
          backgroundColor: colorBgContainer,
          borderRadius: '0.25rem',
          gap: '0.75rem',
          width: '549px',
          marginLeft: '286px',
          marginTop: '109px',
        }}
      >
        <div className="flex justify-center">
          <CashSvgIcon />
        </div>
        <div className="flex justify-center" style={{ width: '293px' }}>
          <Text color={colorTextTertiary} style={{ textAlign: 'center', fontSize: '14px' }}>
            {t('budget.kickoff')}
          </Text>
        </div>
        <div>
          <Steps progressDot items={stepsItems} size="small" current={-1} />
        </div>
        <div className="flex justify-center mt-20">
          <Button style={{ backgroundColor: colorPrimary, color: 'white' }} onClick={onStartBudget}>
            {t('budget.start')}
          </Button>
        </div>
      </div>
    </TabContainer>
  );
};
