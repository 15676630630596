import { Search } from '@components/Layout/HeaderLayout/Search';
import { Row, Col, theme, Divider, Layout, Badge, Typography, Empty, Button } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@store/store';
import { isArrayWithValues } from '@shared/util/array-util';
import { If } from '@components/Utils/Structural';
import { IResultLocation, IResultSite } from '@models/search';
import { Filters, LocationKey, SitesKey } from './Filters';
import { setFilterByString } from '@store/slices/search';
import { ResultLocationCard } from './ResultLocationCard';
import { ResultSitesCard } from './ResultSiteCard';
import { isEmptyResults } from './SearchUtils';

export const BodySearch = () => {

    const { token: { fontSize, marginLG, colorText, colorBgContainer, colorPrimaryTextHover, colorFillTertiary, colorFillQuaternary, colorTextTertiary } } = theme.useToken();
    const padding = marginLG;
    const paddingContainer = { paddingLeft: `${padding}px`, paddingRight: `${padding}px` };

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const { data: { results }, loading } = useAppSelector((state) => state.Search);
    const { locations, sites } = results;

    const goToDashboard = () => { navigate("/dashboard") }

    const { q, filterBy } = useAppSelector((state) => state.Search);

    const selectFilterLocations = () => {
        dispatch(setFilterByString(LocationKey));
        searchParams.set("filterBy", LocationKey);
        const updatedSearch = searchParams.toString();
        navigate(`?${updatedSearch}`, { replace: true });
    }

    const selectFilterSites = () => {
        dispatch(setFilterByString(SitesKey));
        searchParams.set("filterBy", SitesKey);
        const updatedSearch = searchParams.toString();
        navigate(`?${updatedSearch}`, { replace: true });
    }

    return (
        <Layout style={{ backgroundColor: colorBgContainer, height: '100%' }} className='overflow-auto h-full pb-30'>
            
            <Divider style={{ margin: 0, padding: 0 }} />

            <Row style={paddingContainer} className="pt-10 pb-10 w-full" >
                <Col span={16} style={{ color: colorText }} className='flex flex-row justify-between'>
                    <Search 
                        style={{ width: "20rem" }} 
                        defaultValue={ q || '' } 
                        suffixType={"clear"}
                    />
                    <div className='flex flex-row items-center'>
                        <span className='mr-30' style={{ color: colorTextTertiary }} >Filter by:</span>
                        <Filters />
                    </div>
                </Col>
            </Row>

            <Divider style={{ margin: 0, padding: 0 }} />

            <If condition={isArrayWithValues(locations) && (filterBy === LocationKey || filterBy === "")}>
                <Row style={paddingContainer} className="pt-10 pb-10 w-full" >
                    <Col span={16} style={{ color: colorText }}>
                        <div className='flex flex-row items-center pl-15 pt-8 pb-8 mt-10' style={{ backgroundColor: colorFillQuaternary, borderRadius: 4 }}>
                            <Typography.Title level={4} style={{ margin: 0 }}>
                                Locations
                            </Typography.Title>
                            <Badge count={locations?.length} className='ml-10' style={{ backgroundColor: colorFillTertiary, borderColor: colorFillQuaternary, color: colorTextTertiary }} />
                        </div>
                    </Col>
                </Row>
            </If>

            <Row style={paddingContainer} className="pt-10 pb-10 w-full" >
                <If condition={isArrayWithValues(locations)  && (filterBy === LocationKey || filterBy === "")}>
                    <Col span={16} style={{ color: colorText }}>
                        { 
                            locations && [...(filterBy === LocationKey) ? locations : locations?.slice(0, 3)].map((location: IResultLocation) => (
                                <ResultLocationCard location={location} key={location?.resultId || location.id} />
                            )) 
                        } 
                        <If condition={Boolean(locations && locations.length > 3 && filterBy !== LocationKey)}>
                            <Row justify={"end"} className='mt-20'>
                                <Col>
                                    <Button type={"primary"} onClick={selectFilterLocations} >View all Locations</Button>
                                </Col>
                            </Row>
                        </If>

                    </Col>
                </If>
            </Row>

            {/* Sites */}

            <If condition={isArrayWithValues(sites) && (filterBy === SitesKey || filterBy === "") }>
                <Row style={paddingContainer} className="pt-10 pb-10 w-full" >
                    <Col span={16} style={{ color: colorText }}>
                        <div className='flex flex-row items-center pl-15 pt-8 pb-8 mt-10' style={{ backgroundColor: colorFillQuaternary, borderRadius: 4 }}>
                            <Typography.Title level={4} style={{ margin: 0 }}>
                                Sites
                            </Typography.Title>
                            <Badge count={sites?.length} className='ml-10' style={{ backgroundColor: colorFillTertiary, borderColor: colorFillQuaternary, color: colorTextTertiary }} />
                        </div>
                    </Col>
                </Row>
            </If>

            <If condition={isArrayWithValues(sites) && (filterBy === SitesKey || filterBy === "")}>
                <Row style={paddingContainer} className="pt-10 pb-10 w-full" >
                        <Col span={16} style={{ color: colorText }}>
                            { 
                                sites && [...(filterBy === SitesKey) ? sites : sites?.slice(0, 3)].map((site: IResultSite) => (
                                    <ResultSitesCard site={site} key={site?.resultId || site.id} />
                                )) 
                            } 
                            <If condition={Boolean(sites && sites.length > 3 && filterBy !== SitesKey)}>
                                <Row justify={"end"} className='mt-20'>
                                    <Col>
                                        <Button type={"primary"} onClick={selectFilterSites} >View all Sites</Button>
                                    </Col>
                                </Row>
                            </If>

                        </Col>
                </Row>
            </If>

            <If condition={!loading && ((filterBy === "" && isEmptyResults(results)) || (filterBy !== "" && !isArrayWithValues(results[filterBy as keyof typeof results]))) }>
                <Row style={paddingContainer} className="pt-20 mt-20 pb-10 w-full" >
                    <Col span={16} style={{ color: colorText }} > 
                        <div className='flex flex-col items-center justify-center mt-30 w-full'>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            <span style={{ fontSize: fontSize + 10 }} className='pt-10 pb-5 text-center'> There are no matches for ‘{ q || '' }’</span>
                            <span> Try another search or 
                                <span className='ml-5 mt-10 pt-10 cursor-pointer' style={{ color: colorPrimaryTextHover }} onClick={goToDashboard}>go to Dashboard</span>
                            </span>
                        </div>
                    </Col>
                </Row>
            </If>

        </Layout>
    )
}
