import { ICompanyBinding } from '../../models/company-binding.model';
import { IQueryParams } from '../../models/pagination';
import { http } from '../http/axios.instance';
import { StringORNumber } from './utils.repository';

const path = 'company-bindings';

export const companyBindingRepository = {
  getAll: async (queryParams: IQueryParams) => {
    const { sort, page, size } = queryParams;
    const requestUrl = `${path}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;

    const promiseGetAll = await http.get<ICompanyBinding[]>(requestUrl);
    return promiseGetAll;
  },
  getByLocationId: async (locationId: StringORNumber) => {
    const requestUrl = `${path}?referenceId.equals=${locationId}&referenceType.equals=LOCATION`;
    const promiseGetByLocationId = await http.get<ICompanyBinding[]>(requestUrl);
    return promiseGetByLocationId;
  },
  getById: async (id: StringORNumber) => {
    const requestUrl = `${path}/${id}`;
    const promiseGetByID = await http.get<ICompanyBinding>(requestUrl);
    return promiseGetByID;
  },
  create: async (entity: ICompanyBinding): Promise<ICompanyBinding> => {
    const requestUrl = `${path}`;
    const promiseCreate = await http.post<ICompanyBinding>(requestUrl, entity);
    return promiseCreate.data;
  },
  update: async (id: string | number, entity: ICompanyBinding): Promise<ICompanyBinding> => {
    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.put<ICompanyBinding>(requestUrl, entity);
    return promiseUpdate.data;
  },
  delete: async (id: string | number) => {
    const requestUrl = `${path}/${id}`;
    const promiseDelete = await http.delete<ICompanyBinding>(requestUrl);
    return promiseDelete;
  },
};
