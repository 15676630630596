/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { If } from "@components/Utils/Structural";
import { Image, theme } from "antd";
import { isValidUrl } from "@shared/util/url-util";
import { configuration } from "@environments/env";
import { isStringEmpty } from "@shared/util/validations";
import { ILocation } from "@models/location.model";
import { DerivativesEnum } from "@models/enumerations/derivatives-enum.model";
import { isArrayWithValues } from '@shared/util/array-util';
import { IDerivatives } from "@models/attachment.model";
import { LoadingCustomImageSkeleton } from "@components/PhotoAlbum/ThumbnailCard";

const ASPECT_RATIO_CONTAINER_IMG = 1;

const hasDefaultImage = Boolean(configuration?.DEFAULT_IMAGE_KEY_PHOTO && !isStringEmpty(configuration?.DEFAULT_IMAGE_KEY_PHOTO));

interface ILocationKeyPhotoThumbailProps {
    location: ILocation
}

export const LocationKeyPhotoThumbail = (props: ILocationKeyPhotoThumbailProps) => {   

    const { location : locationEntity } = props;

    const [srcKeyPhoto, setSrcKeyPhoto] = useState<string>('');
    const [isUrlImageWithError, setIsUrlImageWithError] = useState<boolean>(false);
    const [isDefaultImageWithError, setIsDefaultImageWithError] = useState<boolean>(false);

    useEffect(() => {
        setSrcKeyPhoto('');
    }, []);

    const locationKeyPhotoSrc = useMemo(() => {
        const derivatives = isArrayWithValues(locationEntity.locationKeyPhoto?.derivatives) ? (locationEntity.locationKeyPhoto?.derivatives || []) : [];
        const derivativeFound = derivatives.find((derivative) => derivative?.derivativeType === DerivativesEnum.THUMBNAIL);
        const src = (derivativeFound as IDerivatives)?.signedUrl || "";
        return isStringEmpty(src) ? "" : (src || "");
    }, [locationEntity])

    useEffect(() => {
        setSrcKeyPhoto(locationKeyPhotoSrc);
    }, [locationKeyPhotoSrc]);

    const container = useMemo(() => {
        const isActiveMainImage = isValidUrl(srcKeyPhoto) && !isUrlImageWithError;
        const isDefaultImageActive = !isActiveMainImage && !isDefaultImageWithError && hasDefaultImage;
        return (
            <div style={{ aspectRatio: ASPECT_RATIO_CONTAINER_IMG }}>
                <If condition={isActiveMainImage}>
                    <ImageKeyLocation srcKeyPhoto={srcKeyPhoto} onError={setIsUrlImageWithError} />
                </If>
                <If condition={!isActiveMainImage && !isDefaultImageWithError && hasDefaultImage}>
                    <ImageKeyLocation srcKeyPhoto={configuration?.DEFAULT_IMAGE_KEY_PHOTO} onError={setIsDefaultImageWithError} />
                </If> 
                <If condition={!isActiveMainImage && !isDefaultImageActive}>
                    <LoadingCustomImageSkeleton />
                </If>
            </div>
        );
    }, [srcKeyPhoto, isUrlImageWithError, isDefaultImageWithError]);

    return container;
};

interface IImageKeyLocationProps {  
    srcKeyPhoto: string,
    onError?: (hasError: boolean) => void
}

export const ImageKeyLocation = (props : IImageKeyLocationProps) => {
    const { srcKeyPhoto = "", onError } = props;
    const { token: { colorBorder } } = theme.useToken();

    const onErrorImage = () => {
        onError?.(true);
    }
    return <Image
        className="w-full"
        src={srcKeyPhoto}
        onError={onErrorImage}
        style={{ 
            border: `0.5px solid ${colorBorder}`,
            borderRadius: 2,
            objectFit: "cover",
            maxWidth: "100%",
            aspectRatio: ASPECT_RATIO_CONTAINER_IMG
        }}
        preview={false}
    />
}