import { http } from '../http/axios.instance';
import { IGroup } from '../../models/group.model';
import { IQueryParamsGeneric } from '@models/utils';
import { StringORNumber } from './utils.repository';

const path = 'groups';
const filterGroupsPath = "groups/filter-by"

export const groupsRepository = {
  getAll: async (params: IQueryParamsGeneric) => {

    const _params = new URLSearchParams({
      ...params,
      cacheBuster: `${new Date().getTime()}`
    })
    const requestUrl = `${filterGroupsPath}?${_params.toString()}`;

    const promiseGetAll = await http.get<IGroup[]>(requestUrl);
    return promiseGetAll;
  },
  getGroupById: async (id: StringORNumber) => {

    const requestUrl = `${path}/${id}`;
    const group = await http.get<IGroup>(requestUrl);
    return group;
  },
  create: async (entity: IGroup) => {

    const requestUrl = `${path}`;
    const group = await http.post<IGroup>(requestUrl, entity);
    return group;
  },
  update: async (id: string | number, entity: IGroup) => {

    const requestUrl = `${path}/${id}`;
    const group = await http.put<IGroup>(requestUrl, entity);
    return group;
  },

}