import { CSSProperties } from "react";
import { FileImageOutlined, FilePdfOutlined } from "@ant-design/icons";
import { EmptyLabel } from "@components/Utils/EmptyLabel";
import { If } from "@components/Utils/Structural";
import { TextEllipsis } from "@components/Utils/TextEllipsisContent";
import { IAttachment } from "@models/attachment.model";
import { formatDatePipe } from "@shared/util/date-utils";
import { convertBytes, getExtensionOfFileName, isAnValidImage } from "@shared/util/document-utils";
import { Col, Row, theme } from "antd";
import { capitalize } from "lodash";
interface IInfoSideProps {
    header?: React.ReactNode,
    body?: React.ReactNode,
    footer?: React.ReactNode,
    containerClassName?: string,
    containerStyles?: CSSProperties,
}

export const InfoSide = (props: IInfoSideProps) => {

    const { header, body, footer, containerClassName, containerStyles } = props;
 
    return (
        <div className={`flex flex-col w-full ${containerClassName}`} style={containerStyles} >
            {header}
            {body}
            {footer}
        </div>
    )
}

export const HeaderInfoSide = () => {

    const { token: { fontSizeLG, colorTextSecondary, colorFillTertiary, colorFillSecondary } } = theme.useToken();

    return (
        <div 
            className="flex flex-row items-center"
            style={{ 
                background: colorFillTertiary,
                borderBottom: `1px solid ${colorFillSecondary}`, 
            }}
        >
            <span className="pl-30 pt-10 pb-10 font-medium" style={{ fontSize: fontSizeLG, color: colorTextSecondary }}>File Info</span>
        </div>
    )
}


interface IBodyInfoSideProps {
    attachment: IAttachment,
    bodyContainerClassName?: string
    bodyContainerStyle?: CSSProperties
}

export const BodyInfoSide = (props: IBodyInfoSideProps) => {

    const { attachment, bodyContainerClassName, bodyContainerStyle } = props;

    const { token: { fontSizeSM, colorTextSecondary, colorTextTertiary } } = theme.useToken();
    
    return (
        <div className={bodyContainerClassName} style={bodyContainerStyle} >
            <Row className="pb-8">
                <Col span={12} style={{ fontSize: fontSizeSM, color: colorTextSecondary }} className="font-bold">Format:</Col>
                <Col span={12} style={{ fontSize: fontSizeSM, color: colorTextTertiary }} className="flex flex-row items-center">
                    {isAnValidImage(getExtensionOfFileName(`${attachment?.fileName}`)) ? <FileImageOutlined className="mr-5" /> : <FilePdfOutlined className="mr-5" />}
                    {isAnValidImage(getExtensionOfFileName(`${attachment?.fileName}`)) ? `${getExtensionOfFileName(`${attachment?.fileName}`).toUpperCase()} Image ` : `${getExtensionOfFileName(`${attachment?.fileName}`).toUpperCase()} File`}
                </Col>
            </Row>
            <Row className="pb-8">
                <Col span={12} style={{ fontSize: fontSizeSM, color: colorTextSecondary }} className="font-bold">Size:</Col>
                <Col span={12} style={{ color: colorTextTertiary, fontSize: fontSizeSM }} >
                    {(attachment.fileSize && convertBytes(attachment.fileSize)) || <EmptyLabel />}
                </Col>
            </Row>
            <Row className="pb-8">
                <Col span={12} style={{ fontSize: fontSizeSM, color: colorTextSecondary }} className="font-bold">Creator:</Col>
                <Col span={12} style={{ fontSize: fontSizeSM, color: colorTextTertiary }} >
                    <If condition={!!(attachment.createdBy)}>
                        <TextEllipsis
                            text={capitalize(`${attachment.createdByFirstName} ${attachment.createdByLastName}`)}
                            ellipsis={{
                                tooltip: {
                                    title: attachment.createdByEmail,
                                    rootClassName: "fullMaxWidth"
                                }
                            }}
                            style={{ fontSize: fontSizeSM, color: colorTextTertiary }}
                        />
                    </If>
                </Col>
            </Row>
            <Row className="pb-8">
                <Col span={12} style={{ fontSize: fontSizeSM, color: colorTextSecondary }} className="font-bold">Upload Date:</Col>
                <Col span={12} style={{ fontSize: fontSizeSM, color: colorTextTertiary }} >
                    {(attachment.createdDate && formatDatePipe(attachment.createdDate)) || <EmptyLabel />}
                </Col>
            </Row>
        </div>
    )
}
