import { ArrowUpOutlined, LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { BudgetStatusEnum } from '@models/budget.model';
import { createRevision } from '@store/slices/budget-extended';
import { useAppDispatch, useAppSelector } from '@store/store';
import { Button, Popconfirm, Spin, theme } from 'antd';
import { useTranslation } from 'react-i18next';

const CreateRevision = () => {
    const { entity: budget, loading } = useAppSelector(state => state.BudgetExtended);
    const { entities: budgetRevisions } = useAppSelector(state => state.BudgetRevision);
    const dispatch = useAppDispatch();

    const {
        token: { colorPrimary },
    } = theme.useToken();

    const { t } = useTranslation();

    const handleCreateRevision = () => {
        if (!budget.id) return;
        dispatch(createRevision(budget.id));
    }

    return (
      <>
        {(budget.status === BudgetStatusEnum.APPROVED || budget.status === BudgetStatusEnum.REJECTED) &&
          budgetRevisions[0] &&
          budgetRevisions[0]?.id === budget?.id && (
            <div style={{ display: 'flex', justifyContent: 'right', marginRight: '35px', marginBottom: '35px' }}>
              {loading ? (
                <Spin indicator={<LoadingOutlined spin />} size="large" />
              ) : (
                <Popconfirm
                  className="budgetCreationPopconfirm"
                  placement="bottomRight"
                  style={{ maxWidth: '5em' }}
                  title={`${t('budget.createBudgetRevision')}`}
                  icon={<WarningOutlined style={{ color: colorPrimary }} />}
                  okText={t('budget.createRevision')}
                  onConfirm={handleCreateRevision}
                  cancelText={t('generic.cancel')}
                  description={t('budget.createBudgetWarningMessage')}
                >
                  <Button type="primary">
                    <ArrowUpOutlined /> {t('budget.createRevision')}
                  </Button>
                </Popconfirm>
              )}
            </div>
          )}
      </>
    );
}

export default CreateRevision;