import { rfiRepository } from "@infrastructure/repositories/rfi.repository"

export const rfiService = {
  getRfis: rfiRepository.getRfis,
  getRfiById: rfiRepository.getRfiById,
  createRfi: rfiRepository.createRfi,
  updateRfi: rfiRepository.updateRfi,
  patchRfi: rfiRepository.patchRfi,
  countOpenRfi: rfiRepository.countOpenRfi,
  countRfi: rfiRepository.countRfi,
  createRfiWithDistributions: rfiRepository.createRfiWithDistributions,
};