import React, { createContext, useContext, useEffect, useState } from "react";

export const ContainerFileContext = createContext(null as any);

export const ContainerFileProvider = ({ children }: any) => {

    const [scale, setScale] = useState<number>(1);
    const [scaleInString, setScaleInString] = useState<string>("100%");

    useEffect(() => {
        setScaleInString(`${((scale * 100)/1).toFixed(0)}%`)
    }, [scale])

    const scaleConfiguration = (scale: number) => {
        if (scale > 0.25 && scale < 3) {
            setScale(scale);
            return;
        }
        if (scale < 0.25) {
            setScale(0.25); 
            return;
        }
        if (scale > 3) {
            setScale(3);
            return;
        }
    }

    const zoomIn = () => {
        const newScale = scale + 0.1;
        scaleConfiguration(newScale);
    }

    const zoomOut = () => {
        const newScale = scale - 0.1;
        scaleConfiguration(newScale);
    }

    const resetZoom = () => {
        scaleConfiguration(1);
    }

    const value = {
        scale, 
        scaleInString,
        setScale: (scale: number) => scaleConfiguration(scale),
        zoomOut: () => zoomOut(),
        zoomIn: () => zoomIn(),
        resetZoom: () => resetZoom(),
    }
    
    return <ContainerFileContext.Provider value={value}>{children}</ContainerFileContext.Provider>;
};

export const useContainerFileContex = () => {
    return useContext(ContainerFileContext);
};