/**
 * Application configuration
 */
import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { GanttConfig } from '@bryntum/gantt';
import { TaskTooltip } from './TaskTooltip';
import { EmptyLabel } from '@components/Utils/EmptyLabel';
import { formatDatePipe } from '@shared/util/date-utils';
import { red, green, orange } from '@ant-design/colors';
import { WHITE } from '@providers/ThemeProvider';
import { useTranslation } from 'react-i18next';


const Deliverable = () => {
const { t } = useTranslation();
  return (
    <span className="pl-15 ml-3 font-semibold no-borders text-color-neutral-11" style={{ textTransform: 'none', fontSize: 14 }}>
        {t("projectDeliverable.deliverableName")}
    </span>
  );
};

const StartDate = () => {
const { t } = useTranslation();
  return (
    <span className="font-normal no-borders text-color-neutral-11" style={{ textTransform: 'none', fontSize: 14 }}>
      {t("generic.startDate")}
    </span>
  );
};

const EndDate = () => {
const { t } = useTranslation();
  return (
    <span className="font-normal no-borders text-color-neutral-11" style={{ textTransform: 'none', fontSize: 14 }}>
      {t("generic.endDate")}
    </span>
  );
};

const StatusColorMap = {
  COMPLETED: '#40A9FF',
  PAST_DUE: red[3],
  ON_TRACK: green[3],
  NOT_STARTED: '#D9D9D9',
  PENDING_APPROVAL: orange[5],
};

const StatusBorderColorMap = {
  COMPLETED: '#1890FF',
  PAST_DUE: '#F5222D',
  ON_TRACK: '#389E0D',
  NOT_STARTED: '#8C8C8C',
  PENDING_APPROVAL: orange[7],
};

type KeyOfStatusColorMap = keyof typeof StatusColorMap;
type KeyOfStatusBorderColorMap = keyof typeof StatusBorderColorMap;

const Status = ({ record }: { record: any }) => {
  const [bgColor, setBgColor] = useState(WHITE);
  useEffect(() => {
    if (StatusColorMap[record?.extraData?.status as KeyOfStatusColorMap]) {
      setBgColor(StatusColorMap[record?.extraData?.status as KeyOfStatusColorMap]);
    }
  }, [record]);
  return <div className="rounded-full" style={{ width: 12, height: 12, backgroundColor: bgColor }}></div>;
};

const NameDeliverable = ({ name }: { name: string }) => {
  return <span className="pl-10 font-semibold text-color-neutral-11">{name || <EmptyLabel />}</span>;
};

const CustomStartDate = (rawRecord: any) => {
  return rawRecord?.record?.data?.startDate ? (
    <span className="text-color-neutral-11">{formatDatePipe(`${rawRecord?.record?.data?.startDate}`)}</span>
  ) : (
    <EmptyLabel />
  );
};

const CustomEndDate = (rawRecord: any) => {
  return rawRecord?.record?.data?.endDate ? (
    <span className="text-color-neutral-11">{formatDatePipe(`${rawRecord?.record?.data?.endDate}`)}</span>
  ) : (
    <EmptyLabel />
  );
};

const ganttConfig: Partial<GanttConfig> = {
  readOnly: true,
  columns: [
    {
      type: 'name',
      field: 'name',
      text: 'Tasks',
      htmlEncodeHeaderText: false,
      headerRenderer: () => {
        return ReactDOMServer.renderToString(<Deliverable />);
      },
      renderer: ({ record }: { record: any }) => {
        return record ? <NameDeliverable name={record.name} /> : <></>;
      },
      leafIconCls: null,
      enableCellContextMenu: false,
      enableHeaderContextMenu: false,
      editor: false,
      cellCls: 'no-borders',
      cls: 'no-borders',
      sortable: false
    },
    {
      type: 'date',
      field: 'startDate',
      text: 'Start Date',
      headerRenderer: () => {
        return ReactDOMServer.renderToString(<StartDate />);
      },
      renderer: ({ record }: { record: any }) => {
        return record ? <CustomStartDate record={record} /> : <></>;
      },
      cellCls: 'no-borders',
      enableCellContextMenu: false,
      enableHeaderContextMenu: false,
      editor: false,
      cls: 'no-borders',
      sortable: false
    },
    {
      type: 'date',
      field: 'endDate',
      text: 'End Date',
      headerRenderer: () => {
        return ReactDOMServer.renderToString(<EndDate />);
      },
      renderer: ({ record }: { record: any }) => {
        return record ? <CustomEndDate record={record} /> : <></>;
      },
      cellCls: 'no-borders',
      enableCellContextMenu: false,
      enableHeaderContextMenu: false,
      editor: false,
      cls: 'no-borders',
      sortable: false
    },
    {
      type: 'name',
      field: '',
      text: '',
      width: 30,
      maxWidth: 30,
      autoWidth: true,
      headerRenderer: () => {
        return '';
      },
      renderer: ({ record }: { record: any }) => {
        return record ? <Status record={record} /> : <></>;
      },
      cellCls: 'no-borders',
      cls: 'no-borders',
      leafIconCls: null,
      enableCellContextMenu: false,
      enableHeaderContextMenu: false,
      editor: false,
      fitMode: 'exact',
      draggable: false,
      callOnFunctions: false,
      readOnly: true,
      searchable: false,
      sortable: false,
    },
  ],

  // The view preset controls the time axis and its header
  viewPreset: {
    base: 'dayAndMonth',
  },

  barMargin: 10,

  taskRenderer({ taskRecord, renderData }: { taskRecord: any; renderData: any }) {
    if (taskRecord?.extraData?.status) {
      const backgroundColor = StatusColorMap[taskRecord?.extraData?.status as KeyOfStatusColorMap];
      const borderColor = StatusBorderColorMap[taskRecord?.extraData?.status as KeyOfStatusBorderColorMap];
      renderData.style = `background-color:${backgroundColor}; border: 1px solid ${borderColor};`;
    }

    // Skip showing names for parent task bars
    return '';
  },

  features: {
    taskMenu: {
      disabled: true,
    },

    taskEdit: false,

    dependencies: {
      // Rounded line joints
      radius: 4,
      // Easier to click on lines
      clickWidth: 4,
    },

    rollups: false,
    filter: false,
    dependencyEdit: false,
    timeRanges: {
      showCurrentTimeLine: true,
    },

    taskTooltip: {
      // Tooltip configs can be used here
      // align    : 't0-b0' // Align left to right
      template: ({ taskRecord }: { taskRecord: unknown }) => (
        <React.StrictMode>
          <TaskTooltip taskRecord={taskRecord} />
        </React.StrictMode>
      ),
      allowOver: true,
      maxWidth: '457px',
      minWidth: '400px',
      bodyCls: 'pt-0 mt-0 pl-0 pr-0',
      layoutStyle: { padding: 0 },
    },
  },

  columnLines: false,
};

export { ganttConfig };
