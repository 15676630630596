import { Card, Col, Row, Skeleton, theme } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { StaticImageMap } from "@components/Google/StaticMap/StaticImageMap";
import { EmptyLabel } from "@components/Utils/EmptyLabel";
import { IResultLocation } from "@models/search";
import { formatDatePipe } from "@shared/util/date-utils";
import { useNavigate } from "react-router-dom";
import { If } from "@components/Utils/Structural";
import { TextEllipsis } from "@components/Utils/TextEllipsisContent";
import { ResultHighlightedString } from './ResultHighlightedString';
import { MapMarkerSvgIcon } from "@components/Icons/MapMarkerSvgIcon";

interface ILocationCardResultProps {
    location: IResultLocation
}

export const ResultLocationCard = (props: ILocationCardResultProps) => {

    const { location } = props;

    const { token: { fontSize, colorFillQuaternary, colorTextSecondary, colorTextQuaternary, colorPrimary } } = theme.useToken();

    const navigate = useNavigate();

    const onCLickCard = (event: any) => {
        if (!location.id) return

        event.metaKey ? window.open(`${window.location.origin}/location/${location.id}`) : navigate(`/location/${location.id}`);
    }
    
    return (
        <Card onClick={onCLickCard} className='mb-15 cursor-pointer' hoverable bodyStyle={{ margin: 0, padding: 0}} style={{ borderRadius: 4 }}>
            <div className='flex flex-row justify-between'>
                <div className='flex flex-col pt-5 pr-10 pb-5 pl-10 w-full'>
                    <span style={{ fontSize: fontSize + 2 }} className='pt-10 pl-15 font-semibold'>
                        { location?.name }
                    </span>
                    <Row className='flex flex-row items-center pl-15 pt-8 pb-8 mt-10 w-full' style={{ backgroundColor: colorFillQuaternary, borderRadius: 4, color: colorTextSecondary }}>
                        <Col span={6} className="pr-15">
                            <div className="flex flex-row w-full">
                                <span className='font-semibold'>Brand</span>: 
                                <TextEllipsis text={location?.brandName} style={{ color: colorTextSecondary }} className="ml-5" />
                            </div>
                        </Col>
                        <Col span={6} className="pr-15">
                            <div className="flex flex-row w-full">
                                <span className='font-semibold'>Market</span>: 
                                <TextEllipsis text={location?.marketName} style={{ color: colorTextSecondary }} className="ml-5" />
                            </div>
                        </Col>
                        <Col span={6} className="pr-15">
                            <span className='font-semibold'>Open Date</span>: { (location?.openDate && formatDatePipe(location?.openDate)) || <EmptyLabel /> }
                        </Col>
                        <Col span={6} className="pr-15">
                            <TextEllipsis text={location?.cityName } style={{ color: colorTextSecondary }} />
                        </Col>
                    </Row>
                    <Row className='mt-10 pl-15' style={{ fontSize: fontSize - 2 }}>
                        <ResultHighlightedString matchText={location.matchText} />
                    </Row>
                    <Row className='mt-15 pl-15 pb-10'>
                        <span className='pr-10'>Go to Location</span>
                        <RightOutlined style={{ fontSize: fontSize - 3, fontWeight: 700, color: colorPrimary }} />
                    </Row>
                </div> 
                <div className='pt-10 pr-10 pb-10 pr-10 h-full'>
                    <If condition={!!(location?.latitude && location?.longitude)}>
                        <StaticImageMap  
                            width={134} 
                            height={156} 
                            markers={[{ latitude: location?.latitude || "", longitude: location?.longitude || "" }]} 
                        />
                    </If>
                    <If condition={!Boolean(location?.latitude && location?.longitude)}>
                        <Skeleton.Node style={{ width: 134, height: 156, borderRadius: 4 }} className="relative w-full flex items-center">
                            <div className="flex items-center justify-center">
                                <MapMarkerSvgIcon width='3rem' height='3rem' iconStyles={{ color: colorTextQuaternary }}  />
                            </div>
                        </Skeleton.Node>
                    </If>
                </div>
            </div>
        </Card>
    )
}
