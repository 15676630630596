import { http } from '../http/axios.instance';
import { ICommentRichText, ICommentRichTextCreateRequest, ICommentRichTextGetRequestParams, ICommentRichTextDeleteRequest, ICommentRichTextGetRequest } from '../../models/comment.model';
import { StringORNumber } from './utils.repository';

const path = 'rich-comments';

export const commentVersion2Repository = {
  getAll: async (queryParams: ICommentRichTextGetRequestParams) => {
    const { referenceType, referenceId, page, size, sort } = queryParams;

    const params = new URLSearchParams({
      ...page && { page },
      ...size && { size },
      ...sort && { sort },
    } as any)
    const requestUrl = `${path}/${referenceType}/${referenceId}?${params.toString()}`;

    const promiseGetAll = await http.get<ICommentRichText[]>(requestUrl);

    return promiseGetAll;
  },
  getById: async (id: StringORNumber) => {

    const requestUrl = `${path}/${id}`;
    const promiseGetByID = await http.get<ICommentRichText>(requestUrl);
    return promiseGetByID;
  },
  create: async (payload: ICommentRichTextCreateRequest): Promise<ICommentRichText> => {

    const { referenceType, referenceId } = payload;

    const requestUrl = `${path}/${referenceType}/${referenceId}`;
    const promiseCreate = await http.post<ICommentRichText>(requestUrl, payload);
    return promiseCreate.data;
  },
  delete: async (payload: ICommentRichTextDeleteRequest) => {

    const { referenceType, referenceId, id } = payload;

    const requestUrl = `${path}/${referenceType}/${referenceId}/${id}`;
    const promiseDelete = await http.delete(requestUrl);
    return promiseDelete;
  },

  /**
   * 
   * Temporary method to get the total items
   * In the future this method should call the API to get the total items through the "/count" endpoint 
   * @param queryParams - { referenceId: StringORNumber }
   * @returns 
   */
  getTotalItems: async (queryParams: ICommentRichTextGetRequest) => {
    const { referenceType, referenceId } = queryParams;
    const requestUrl = `${path}/${referenceType}/${referenceId}?page=0&size=1`;
    const promiseGetTotalItems = await http.get<ICommentRichText[]>(requestUrl);
    return promiseGetTotalItems;
  }
}