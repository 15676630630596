import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getEntities as getBrands } from "@store/slices/brand";
import { Card, Switch, theme } from "antd";
import useVisible from "@HOOKs/UseVisible";
import { EditOutlined } from "@ant-design/icons";
import { NEUTRAL_7_COLOR } from "@providers/ThemeProvider";
import { BrandEdit } from "./BrandEdit";
import "@styles/components/admin-item-card.less";
import { useTranslation } from "react-i18next";
import { IBrand } from "@models/brand.model";

const ListItem = (brand: IBrand) => {
  return <li style={{ color: NEUTRAL_7_COLOR }} className="pb-4">{brand.description}</li>
}

interface ListItemEditableProps {
  brand: IBrand;
  brandSelected: IBrand;
  editBrandClicked: any;
}

const ListItemEditable = (props: ListItemEditableProps) => {

  const { t } = useTranslation();
  const { brand, brandSelected, editBrandClicked } = props;

  return <div className={`list-item-editable ${(brandSelected?.id === brand.id ? 'selected' : '')}`} onClick={() => editBrandClicked(brand)}>
    <div>
      <div className="handle-container">
        <div className="handle-dot"></div>
      </div>
    </div>
    <div className="description">
      {brand.description}
    </div>
    <span className="edit-container">
      <span className="edit-link">
        <EditOutlined /> {t("generic.edit")}
      </span>
    </span>
  </div>
}

export const BrandsCard = (props: any) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { token: { colorPrimary, colorTextDisabled } } = theme.useToken();

  const loadBrands = () => {
    dispatch(
      getBrands({})
    );
}

  useEffect(() => {
    loadBrands();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { entities: brands } = useAppSelector((state) => state.Brand);

  const [openEditBrand, toogleEditBrand] = useVisible();
  const [brandSelected, setBrandSelected] = useState<IBrand>({})
  const [isEditing, setIsEditing] = useState<boolean>(false)

  useEffect(() => {
    if (!openEditBrand) {
      setBrandSelected({})
    }
  }, [openEditBrand]);

  const onModifiedChanges = (brand: IBrand) => {
    loadBrands();
  }

  const toggleEdit = () => {
    setIsEditing(!isEditing)
  };

  const editBrand = (brand : IBrand) => {
    setBrandSelected(brand)
    toogleEditBrand()
  }

  const createNewBrand = () => {
    setBrandSelected({})
    toogleEditBrand();
  }

  const brandsSorted = useMemo(() => {
    return [...brands].sort((a, b) => {
      return (a.description || '').localeCompare((b.description || ''));
    });
  }, [brands]);

  return (
    <>
      <BrandEdit open={openEditBrand} toogle={toogleEditBrand} brand={brandSelected} onModifiedChanges={onModifiedChanges} />
      <Card title={<>{t("admin.brand")}<span className="item-count">{brands?.length}</span></>} bordered={false} className="admin-item-card" extra={<div><span className="extra-label" style={{ color: (isEditing ? colorPrimary : colorTextDisabled) }} onClick={toggleEdit}>{isEditing ? t('admin.managing') : t('admin.manage')}</span><Switch checked={isEditing} onClick={toggleEdit} /></div>}>
        <ul className={isEditing ? 'editing' : ''}>
            {brandsSorted.map(brand => 
            <div
                key={brand.id}>
                <>
                {isEditing && <ListItemEditable brand={brand} brandSelected={brandSelected} editBrandClicked={editBrand} />}
                {!isEditing && <ListItem {...brand} />}
                </>
            </div>
            )}
        </ul>
        {isEditing && <>
            <div className="spacer"></div>
            <span onClick={createNewBrand} className="add-new-btn">+ {t("admin.addNewBrand")}</span>
        </>}
        </Card>
    </>
  )
}