import React from "react";
import { theme } from "antd";

export const BadgeTypeButton: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...restProps }) => {  
    const { token: { colorBorder, colorText, colorFillQuaternary }} = theme.useToken(); 
    return (
        <div style={{ border: `1px solid ${colorBorder}`, color: colorText, backgroundColor: colorFillQuaternary, borderRadius: 4 }} {...restProps}>
            {children}
        </div>
    )
}
