import { Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

interface IUploadFloorPlanButtonProps {}

export const UploadFloorPlanButton = (props: IUploadFloorPlanButtonProps) => {

    const navigate = useNavigate();

    const { pathname } = useLocation();

    const onUploadFloorPlan = () => {
        // navigate()
        const baseUrl = pathname?.split?.("punch-list-item")?.[0] || "";
        const newPath = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
        navigate(`${newPath}/punch-list/upload-floor-plan`);        
    }

    return (
        <>
            <Button type="primary" onClick={onUploadFloorPlan}>
                Upload Floor Plan
            </Button>    
        </>
    )
}
