import { createContext, useContext } from "react";

export interface IChangeOrderTimelineContext {
    changeOrderId?: number;
};

export const ChangeOrderTimelineContext = createContext<IChangeOrderTimelineContext | null | undefined>(undefined);

export const useChangeOrderTimelineContext = () => {
    return useContext(ChangeOrderTimelineContext);
};