/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Badge, Button, ConfigProvider, Divider, Pagination, Typography, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import { IHandleOnSearchProps, SearchTable } from '@components/Utils/SearchTable';
import { If } from '@components/Utils/Structural';
import { isString } from '@shared/util/validations';
import { useUrlParamsUtils } from '@HOOKs/UseParamsUtils';

const { Title } = Typography;

export const TitleLocations = () => {
    const { t } = useTranslation();
    return (
        <div className='ml-8 flex'>
            <Title level={5} style={{ marginBottom: 0 }} className='block w-full'>
                {t("generic.locations")}
            </Title>
        </div>     
    ) 
}

export const TotalItems = ({ totalItems }: { totalItems?: number }) => { 
    const { token: { colorPrimaryBg, colorPrimary } } = theme.useToken();
    return (
        <If condition={Boolean(totalItems)}>
            <Badge 
                count={<span style={{ color: colorPrimary }}>{totalItems || ""}</span>} 
                size="small" 
                rootClassName='ml-15' 
                style={{ 
                    display: "flex", 
                    alignItems: "center",
                    backgroundColor: colorPrimaryBg, 
                    paddingLeft: 8, 
                    paddingRight: 8, 
                    height: 20, 
                    borderRadius: 100 
                }} 
            />
        </If>
    )
}

export const DividerVertical = () => (
    <Divider type="vertical" className='mr-15 ml-15' plain style={{ width: 2, height: 24 }} />
)

export const ButtonNewLocation = ({ openFormNewLocation }: { openFormNewLocation: () => void }  ) => {
    const { t } = useTranslation();
    return (
        <Button type='primary' onClick={openFormNewLocation}>
            <PlusOutlined />
            New {t("generic.location")}
        </Button>
    )
}

export const KEY_LOCATION_SEARCH_URL_PARAM = "textFilter";

export const useTextFilterUrlParam = () => {
    
        const { getUrlParam } = useUrlParamsUtils();
    
        const getTextFilterUrlParam = (): string | null => {
            return getUrlParam(KEY_LOCATION_SEARCH_URL_PARAM);
        }
    
        return { getTextFilterUrlParam }
    
}

export const SearchFilter = ({ handleOnSearch }: {handleOnSearch: ((props?: IHandleOnSearchProps) => void) | undefined }) => {
    
    const { token: { colorPrimaryBorder, colorBorderSecondary } } = theme.useToken();

    const [queryToSearch, setQueryToSearch] = useState<string>("");

    const { getUrlParam, setOrReplaceUrlParam, deleteUrlParam } = useUrlParamsUtils();
    
    const getTextFilterUrlParam = (): string => {
        return getUrlParam(KEY_LOCATION_SEARCH_URL_PARAM) ?? "";
    }

    useEffect(() => {
      const textFilter = getTextFilterUrlParam();
      if (textFilter) {
        setQueryToSearch(textFilter);
      }
    }, []);

    const handleInternalSearch = (props?: IHandleOnSearchProps) => {
      const { valueToSearch = '' } = props as IHandleOnSearchProps;
      valueToSearch && setQueryToSearch(valueToSearch);
      if (valueToSearch) {
        setOrReplaceUrlParam(KEY_LOCATION_SEARCH_URL_PARAM, String(valueToSearch));
      } else {
        deleteUrlParam(KEY_LOCATION_SEARCH_URL_PARAM);
      }
      handleOnSearch && handleOnSearch({ valueToSearch });
    };

    const clearSearch = () => {
      handleOnSearch && handleOnSearch({ valueToSearch: '' });
      deleteUrlParam(KEY_LOCATION_SEARCH_URL_PARAM);
    };

    return (
      <div className="flex flex-row items-center pr-20">
        <SearchTable
          queryToSearch={queryToSearch}
          placeholder="Quick Filter"
          handleOnSearch={handleInternalSearch}
          stylePrefixComponent={{ color: colorPrimaryBorder }}
          style={{ height: 'auto', width: '211px', borderColor: colorBorderSecondary, transition: 'width 0.35s ease-out' }}
          onClear={clearSearch}
        />
      </div>
    );
};

export const KEY_LOCATION_PAGINATION_URL_PARAM_PAGE = "page";
export const KEY_LOCATION_PAGINATION_URL_PARAM_SIZE = "size";
export const KEY_LOCATION_PAGINATION_URL_PARAM_SORT = "sort";

export const usePaginationUrlParams = () => {

    const { getUrlParam, setOrReplaceUrlParam, deleteUrlParam } = useUrlParamsUtils();

    const getPaginationUrlParamPage = (): number | null => {
        const urlParamValue = getUrlParam(KEY_LOCATION_PAGINATION_URL_PARAM_PAGE); 
        if (isString(urlParamValue)) {
            const urlParamValueNumber = Number(urlParamValue);
            return !isNaN(urlParamValueNumber) ? (urlParamValueNumber - 1) : null;
        } else {
            return null;
        }
    }

    const getPaginationUrlParamSize = (): number | null => {
        const urlParamValue = getUrlParam(KEY_LOCATION_PAGINATION_URL_PARAM_SIZE); 
        if (isString(urlParamValue)) {
            const urlParamValueNumber = Number(urlParamValue);
            return !isNaN(urlParamValueNumber) ? urlParamValueNumber : null;
        } else {
            return null;
        }
    };
    
    const getPaginationUrlParamSort = (): string | null => {
        return getUrlParam(KEY_LOCATION_PAGINATION_URL_PARAM_SORT); 
    }

    const setPaginationUrlParamPage = (page: number): void  => {
        setOrReplaceUrlParam(KEY_LOCATION_PAGINATION_URL_PARAM_PAGE, String(page + 1))
    }

    const setPaginationUrlParamSize = (size: number): void => {
        setOrReplaceUrlParam(KEY_LOCATION_PAGINATION_URL_PARAM_SIZE, String(size))
    }

    const setPaginationUrlParamSort = (sort: string): void => {
        setOrReplaceUrlParam(KEY_LOCATION_PAGINATION_URL_PARAM_SORT, sort)
    }

    const removePaginationUrlParamPage = (): void => deleteUrlParam(KEY_LOCATION_PAGINATION_URL_PARAM_PAGE);
    const removePaginationUrlParamSize = (): void => deleteUrlParam(KEY_LOCATION_PAGINATION_URL_PARAM_SIZE);
    const removePaginationUrlParamSort = (): void => deleteUrlParam(KEY_LOCATION_PAGINATION_URL_PARAM_SORT);
    
    return { 
        // Page Param
        getPaginationUrlParamPage, 
        setPaginationUrlParamPage,
        removePaginationUrlParamPage,
        
        // Size Param
        getPaginationUrlParamSize,
        setPaginationUrlParamSize,
        removePaginationUrlParamSize,
        
        // Sort Param
        getPaginationUrlParamSort,
        setPaginationUrlParamSort,
        removePaginationUrlParamSort
    }
}


export interface IPaginationLocationsProps {
    totalItems?: number;
    tableParams: {
        current: number;
        size: number;
        sort: string;
        nameFilter?: string;
    };
    setTableParams: (value: any) => void;
}

export const DEFAULT_PAGINATION = 25;
export const PAGINATION_SIZE_OPTIONS = [DEFAULT_PAGINATION, 50, 100];

export const PaginationLocations = (props: IPaginationLocationsProps) => {
  const [paginationSize, setPaginationSize] = useState(DEFAULT_PAGINATION);

  const {
    setPaginationUrlParamPage,
    removePaginationUrlParamPage,
    setPaginationUrlParamSize,
    removePaginationUrlParamSize,
    setPaginationUrlParamSort,
    removePaginationUrlParamSort,
  } = usePaginationUrlParams();

  const { totalItems, tableParams, setTableParams } = props;

  useEffect(() => {
    if (Number(totalItems) < DEFAULT_PAGINATION) {
      removePaginationUrlParamPage();
      removePaginationUrlParamSize();
    } else {
      setPaginationUrlParamSize(DEFAULT_PAGINATION);
      setPaginationSize(DEFAULT_PAGINATION);
      setPaginationUrlParamPage(tableParams.current ? tableParams.current : 0);
    }
    tableParams.sort ? setPaginationUrlParamSort(tableParams.sort) : removePaginationUrlParamSort();
  }, [totalItems]);

  const {
    token: { colorTextSecondary },
  } = theme.useToken();

  const onChangePagination = (page: number, pageSize: number) => {
    setPaginationSize(pageSize);
    setPaginationUrlParamSize(pageSize);
    setPaginationUrlParamPage(page - 1);

    setTableParams(previousValue => {
      return {
        ...previousValue,
        size: pageSize,
        current: page - 1,
      };
    });
  };

  return (
    <div className="mr-20 flex flex-row items-center justify-center">
      <ConfigProvider theme={{ token: { borderRadius: 8, colorText: colorTextSecondary } }}>
        <Pagination
          className="flex flex-row items-center justify-center"
          simple
          onChange={onChangePagination}
          pageSize={paginationSize}
          current={(tableParams.current ?? 0) + 1}
          total={totalItems ? totalItems : 1}
          pageSizeOptions={[...PAGINATION_SIZE_OPTIONS, totalItems ? totalItems : 1]}
          showSizeChanger={totalItems ? totalItems > DEFAULT_PAGINATION : false}
        />
      </ConfigProvider>
    </div>
  );
};

