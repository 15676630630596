import { isString } from "@shared/util/validations";
import { useSearchParams } from "react-router-dom";
import DOMPurify from "dompurify";

export const useUrlParamsUtils = () => {

    let [searchURLParams, setURLSearchParams] = useSearchParams();
    
    const getUrlParam = (keyUrlParam: string): string | null => {
        const urlParamValue = searchURLParams?.get?.(keyUrlParam);
        if (isString(urlParamValue)) {
            return DOMPurify.sanitize(urlParamValue!);
        } else {
            return null;
        }
    }

    const setOrReplaceUrlParam = (keyUrlParam: string, value: string) => {
        setURLSearchParams((prev) => {
            prev.set(keyUrlParam, value)
            return prev;
        });
    }

    const deleteUrlParam = (keyUrlParam: string) => {
        setURLSearchParams((prev) => {
            prev.delete(keyUrlParam)
            return prev;
        });
    }
    
    return { getUrlParam, setOrReplaceUrlParam, deleteUrlParam }

}