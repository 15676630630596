import { EmptyCellTable } from "@components/Utils/EmptyCellTable";
import { If } from "@components/Utils/Structural";
import { isFulfilled } from "@reduxjs/toolkit";
import { asyncLaunchNotification } from "@store/slices/notification";
import { updateProjectEntity } from "@store/slices/project-deliverable-timeline";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Button, Col, Divider, Row, theme, InputNumber } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { getEntity as getProject } from "@store/slices/projects";





const SquarefeetSetting = () => {

    const { token: { fontSizeHeading3, colorBorderSecondary, colorTextSecondary, colorTextTertiary, colorFillSecondary, colorBgContainer, fontSize } } = theme.useToken();

    const { t } = useTranslation();

    const [isManaging, setIsManaging] = useState<boolean>(false);

    const { entity: projectEntity } = useAppSelector((state) => state.Project);

    const [ sqFeetValue, setSqFeetValue ] = useState('')
    const [ formattedSqFeetValue, setFormattedSqFeetValue ] = useState('');

    useEffect(() => {
        if(projectEntity && projectEntity.squareFootage){
            setSqFeetValue(String(projectEntity.squareFootage))
            setFormattedSqFeetValue(String(projectEntity.squareFootage).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))
        }
    },[projectEntity])


    const dispatch = useAppDispatch()

    const toggleManage = () => {
        setIsManaging(!isManaging);
    }

    const confirmUpdateProjectSquareFeet = () => {

        const onSuccessSqFeetUpdate = (response: any) => {
            if (isFulfilled(response)) {
              dispatch(
                asyncLaunchNotification({
                  type: 'success',
                  config: {
                    message: `${t('generic.project')}`,
                    description: `${t('projectSettings.messages.squareFeetUpdated')}`,
                  },
                })
              );
            }
            setIsManaging(false);
            dispatch(getProject(String(projectEntity.id)))
          };
        dispatch(updateProjectEntity({ id : String(projectEntity.id) , squareFootage: Number(sqFeetValue)})).then(onSuccessSqFeetUpdate)

    }
    const handleOnChange = (value) => {
        const formattedValue = `${value}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        setSqFeetValue(value)
        setFormattedSqFeetValue(formattedValue)
    }
    
    return (
      <div
        className={`block sticky top-0 mb-20`}
        style={{ border: `1.2px solid ${colorBorderSecondary}`, borderRadius: 10, background: colorBgContainer, zIndex: 10, padding: 10 }}
      >
        <Row justify={'space-between'} align="middle" style={{ paddingTop: 10 }}>
          <Col className="pt-3 pb-3">
            <span className="font-semibold pl-15" style={{ fontSize: fontSizeHeading3, color: colorTextSecondary }}>
              {`${t('projectSettings.squareFeet')}`}
            </span>
          </Col>
        </Row>
        <Divider className="mt-8 mb-8" style={{ borderColor: colorFillSecondary }} />
        <Row className="flex flex-row items-center justify-between">
          <If condition={!isManaging && Boolean(projectEntity?.squareFootage)}>
            <Row className="mb-10 mt-10 flex-col align-end ml-5">
              <div className="flex flex-col pl-10" style={{ color: colorTextTertiary, fontSize: fontSize + 3 }}>
                {formattedSqFeetValue}
                &nbsp;{`${t('projectSettings.squareFeetAbbreviation')}`}
              </div>
            </Row>
          </If>
          <If condition={!isManaging && !Boolean(projectEntity?.squareFootage)}>
            <Row className="mb-10 mt-10 ml-15 flex-col align-end ml-5">
              <EmptyCellTable />
            </Row>
          </If>
          <If condition={isManaging}>
            <Row className="mt-3">
              <div className="pt-3 mb-3 mr-5 mb-10 mt-10 cursor-pointer" style={{ flex: 1, borderRadius: '4px', padding: '5px' }}>
                <InputNumber
                  style={{ width: 300 }}
                  placeholder="Square Feet Value"
                  onChange={handleOnChange}
                  value={formattedSqFeetValue}
                />
              </div>
            </Row>
          </If>
          <Row className="flex items-center">
            <If condition={!isManaging}>
              <Button type="primary" onClick={toggleManage}>
                {t(`generic.manage`)}
              </Button>
            </If>
            <If condition={isManaging}>
              <>
                <Button className="mr-5" onClick={toggleManage}>
                  {t(`generic.cancel`)}
                </Button>
                <Button type="primary" onClick={confirmUpdateProjectSquareFeet}>
                  {t(`generic.save`)}
                </Button>
              </>
            </If>
          </Row>
        </Row>
      </div>
    );
}

export default SquarefeetSetting;