import { If } from "@components/Utils/Structural";
import { IPhotoAlbum } from "@models/photo-album.model";
import { isArrayWithValues } from "@shared/util/array-util";
import { theme } from "antd";
import { useMemo } from "react";
import { ThumbnailCard } from "./ThumbnailCard";
import { useResizeDetector } from "react-resize-detector";
import { ThumbnailCustomBadge } from "./ThumbnailCustomBadge";

interface IThumbnailContainerProps {
    album: IPhotoAlbum
}

export const ThumbnailContainer = (props: IThumbnailContainerProps) => {

    const { album } = props;

    const { token:  {colorBgLayout, colorBorderSecondary, lineHeight, marginSM, paddingSM }} = theme.useToken();

    const { width: thumbnailContainerListWidth = 0,  ref: thumbnailContainerRef } = useResizeDetector({refreshMode: 'debounce', refreshRate: 50 });

    const maxNumberOfThumbnailsPossible: number = useMemo(() => {
        const fullWidhtOfImage = 52;
        const halfWidhtOfBadge = 10;
        return Math.floor((thumbnailContainerListWidth - halfWidhtOfBadge) / fullWidhtOfImage);
    }, [thumbnailContainerListWidth])

    const listAttachments = useMemo(() => {
        return isArrayWithValues(album.attachments) ? album.attachments!.slice(0, maxNumberOfThumbnailsPossible): []
    }, [album, maxNumberOfThumbnailsPossible])
    
    return (
      <div 
        ref={thumbnailContainerRef}
        className="flex flex-row items-center justify-center"
        style={{ 
            border: `1px solid ${colorBorderSecondary}`, 
            background: colorBgLayout, 
            borderRadius: 4,
            paddingLeft: 2,
            paddingTop: 4,
            paddingBottom: 4,
            marginTop: marginSM,
            marginBottom: marginSM,
        }}>
            <If condition={isArrayWithValues(listAttachments)}>
                <div className="flex flex-row w-full overflow-hidden justify-start" style={{ paddingRight: 15 }}>
                    {
                        listAttachments.map((attachment, index) => {
                            const isLastItem = index === (maxNumberOfThumbnailsPossible - 1);
                            return (
                                <div key={attachment.id}>
                                    <ThumbnailCard 
                                        attachment={attachment}
                                        {...(isLastItem) && { counterNode: <ThumbnailCustomBadge count={album.totalPhotos} />} } 
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </If>
            <If condition={!isArrayWithValues(listAttachments)}>
                <div className="font-normal text-color-neutral-6" style={{ lineHeight, paddingTop: paddingSM, paddingBottom: paddingSM }}>
                    No photos Yet
                </div>
            </If>

      </div>
    )
}
