
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Col, Row, theme, Input, Button, Card } from 'antd';

import { useAppDispatch } from '@store/store';
import { TabContainer } from '@components/Utils/TabContainer';
import { filePreviewUtilsService } from '@services/file-preview-utils.service';
import { asyncLaunchNotification } from '@store/slices/notification';
import { useLayoutContex } from '@providers/LayoutProvider';

export const FilePreviewUtilsTab = () => {
  const {
    token: { marginLG },
  } = theme.useToken();
  const [form] = Form.useForm();
  const [animateError, setAnimateError] = useState(false);
  const [processing, setProcessing] = useState(false);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { setHeaderLabelEntityState } = useLayoutContex();

  useEffect(() => {
    setHeaderLabelEntityState(t('admin.filePreviewGeneration'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values: any): void => {
    setProcessing(true);
    filePreviewUtilsService
      .retryFilePreviewGeneration(values.attachmentId)
      .then(response => {
        const result: number = response.data;
        if (result === 0) {
          dispatch(
            asyncLaunchNotification({
              type: 'info',
              config: {
                message: t('admin.filePreviewGeneration'),
                description: t('admin.filePreviewGenerationTab.messagePreviewAlreadyGeneratedOrAttachmentNotExist')
              },
            })
          );
        } else {
          dispatch(
            asyncLaunchNotification({
              type: 'success',
              config: {
                message: t('admin.filePreviewGeneration'),
                description: t('admin.filePreviewGenerationTab.messageRetryRanSuccessfully'),
              },
            })
          );
        }
        setProcessing(false);
      })
      .catch(error => {
        dispatch(
          asyncLaunchNotification({
            type: 'error',
            config: {
              message: t('admin.filePreviewGeneration'),
              description: t('admin.filePreviewGenerationTab.messageSomethingHappenedTryingToRerun'),
            },
          })
        );
        console.error(error);
        setProcessing(false);
      });
    form.resetFields();
  };

  const playAnimationOfError = () => {
    setAnimateError(true);
    setTimeout(() => {
      setAnimateError(false);
    }, 500);
  };

  const onFinishFailed = (errorInfo: any) => {
    playAnimationOfError();
  };

  const onSubmit = () => {
    form.submit();
  };

  return (
    <>
      <TabContainer>
        <div className="pt-20 overflow-auto" style={{ paddingLeft: marginLG, paddingRight: marginLG }}>
          <Card title="Generate Derivatives" className="admin-card">
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className={`${animateError && 'error-animation'}`}
            >
              <Row gutter={32}>
                <Col span={16}>
                  <Form.Item
                    name="attachmentId"
                    label={t('admin.filePreviewGenerationTab.attachmentId')}
                    rules={[{ required: true, message: t('admin.filePreviewGenerationTab.enterAttachmentId') }]}
                  >
                    <Input placeholder={t('admin.filePreviewGenerationTab.enterAttachmentId')} allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={16}>
                  <Button onClick={onSubmit} type="primary" loading={processing} className="w-full">
                    {t('generic.run')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
      </TabContainer>
    </>
  );
};
