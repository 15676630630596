import { ITimelineEntry } from "@models/timeline.model";
import { TimelineItemProps } from "antd";
import { ITimelineItemStrategy } from "../../strategy-factory";
import { EntryBody } from "./EntryBody";

export class ChangeOrderPendingItemStrategy implements ITimelineItemStrategy {
    public getItem(timelineEntry: ITimelineEntry): TimelineItemProps {
        const response: TimelineItemProps =
        {
            color: '#FA8C16',
            children: <EntryBody value={timelineEntry} />,
        };
        return response;
    }
}
