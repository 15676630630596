import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getEntities as getProjectTypes, reorderProjectTypes } from "@store/slices/project-types";
import { Card, Switch, theme } from "antd";
import useVisible from "@HOOKs/UseVisible";
import { IProjectType } from "@models/project-type.model";
import { ProjectTypeEdit } from "./ProjectTypeEdit";
import { EditOutlined } from "@ant-design/icons";
import { DragHandle } from "@components/Icons/DragHandle";
import { asyncLaunchNotification } from "@store/slices/notification";

export const ProjectTypesCard = (props: any) => {

  const dispatch = useAppDispatch();

  const { token: { colorPrimary, colorTextDisabled, colorBgBase, colorText } } = theme.useToken();

  const loadTypes = () => {
    dispatch(
      getProjectTypes({})
    );
}

  useEffect(() => {
    loadTypes();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [sortedTypes, setSortedTypes] = useState<IProjectType[]>([])

  const { entities: projectTypes } = useAppSelector((state) => state.ProjectTypes);

  useEffect(() => {
    setSortedTypes(JSON.parse(JSON.stringify(projectTypes)));
  }, [projectTypes]);

  const [openEditType, toogleEditType] = useVisible();
  const [typeSelected, setTypeSelected] = useState<IProjectType>({})
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const onModifiedChanges = (projectType: IProjectType) => {
    loadTypes();
  }

  const toggleEdit = () => {
    setIsEditing(!isEditing)
  };

  const editType = (projectType : IProjectType) => {
    setTypeSelected(projectType)
    toogleEditType()
  }

  const createNewType = () => {
    setTypeSelected({})
    toogleEditType();
  }

  const [draggedItem, setDraggedItem] = useState<IProjectType | null>(null);

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>, item: IProjectType) => {
    setDraggedItem(item);
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>, item: IProjectType) => {
    event.preventDefault();
    setSortedTypes((prevItems) => {
      const newItems = [...prevItems];
      const draggedItemIndex = prevItems.findIndex((i) => i.id === draggedItem?.id);
      const itemIndex = prevItems.findIndex((i) => i.id === item.id);
      newItems.splice(draggedItemIndex, 1);
      newItems.splice(itemIndex, 0, draggedItem!);
      return newItems;
    });
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
    const ids = sortedTypes.map(s => Number(s.id));
    if (sortedTypes.filter(s => Number(s.id)).length !== ids.length) {
      dispatch(asyncLaunchNotification({
        type: "error",
        config: {
            message: `Invalid Type IDs`,
            description: `Type IDs are invalid. Please refresh and try again.`
        }
      }));
      return false;
    }
    dispatch(reorderProjectTypes(ids));
  };

  const ListItem = (type: IProjectType) => {
    return <li style={{ color: '#8C8C8C', paddingBottom: '4px' }}>{type.name}</li>
  }
  
  const ListItemEditable = (type: IProjectType) => {
    return <div style={{ display: 'flex', backgroundColor: colorBgBase, border: '1px solid #F0F0F0', borderRadius: '4px', padding: '4px 8px', marginBottom: '4px' }}>
      <div>
        <div style={{ paddingTop: '4px', marginRight: '8px' }}><DragHandle /></div>
      </div>
      <div style={{ paddingTop: '3px', color: colorText }}>
        {type.name}
      </div>
      <span style={{ flex: '1', textAlign: 'right' }}>
        <span onClick={() => editType(type)} style={{ cursor: 'pointer', color: colorPrimary }}>
          <EditOutlined /> Edit
        </span>
      </span>
    </div>
  }

  return (
    <>
      <ProjectTypeEdit open={openEditType} toogle={toogleEditType} projectType={typeSelected} onModifiedChanges={onModifiedChanges} />
      <Card title="Type" bordered={false} style={{ width: 400, marginBottom: '16px' }} extra={<div><span style={{ cursor: 'pointer', color: (isEditing ? colorPrimary : colorTextDisabled), marginRight: '8px' }} onClick={toggleEdit}>{isEditing ? 'Managing' : 'Manage'}</span><Switch checked={isEditing} onClick={toggleEdit} /></div>}>
        <ul style={{ paddingLeft: '15px' }}>
            {sortedTypes.map(status => 
            <div
                draggable={isEditing}
                onDragStart={(event) => handleDragStart(event, status)}
                onDragEnter={(event) => handleDragEnter(event, status)}
                onDragEnd={handleDragEnd} 
                key={status.id}>
                <>
                {isEditing && <ListItemEditable {...status} />}
                {!isEditing && <ListItem {...status} />}
                </>
            </div>
            )}
        </ul>
        {isEditing && <>
            <div style={{ borderTop: '1px solid #F5F5F5' }}></div>
            <span onClick={createNewType} style={{ cursor: 'pointer', marginTop: '10px', display: 'inline-block', color: colorPrimary }}>+ Add New Type</span>
        </>}
        </Card>
    </>
  )
}