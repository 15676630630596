import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ManagerAlbumDrawer } from "./ManagerAlbumDrawer";
import useVisible from "@HOOKs/UseVisible";

interface ICreateAlbumButtonProps {}

export const CreateAlbumButton = (props: ICreateAlbumButtonProps) => {

    const [visibleDrawerAddAlbum, setVisibleDrawerAddAlbum] = useVisible()

    return (
        <>
            <Button type="primary" ghost onClick={setVisibleDrawerAddAlbum}>
                <PlusOutlined />
                Create Album
            </Button>    
            { visibleDrawerAddAlbum && <ManagerAlbumDrawer open={visibleDrawerAddAlbum} toogle={setVisibleDrawerAddAlbum} mode="CREATION" /> }
        </>
    )
}
