import { HTMLProps } from "react";

export interface IColorizedTextProps extends HTMLProps<HTMLSpanElement> {
    mapKey: string,
    text: string,
    colorMap: Map<string, string>,
}

export const ColorizedText = (props: IColorizedTextProps) => {
    const { mapKey, text, colorMap, style, ...restProps } = props;

    const getColor = (): string => {
        if (colorMap.has(mapKey)) {
            return colorMap.get(mapKey)!;
        } else {
            return 'unset';
        }
    }

    return (
        <span style={{ color: getColor(), ...style }} {...restProps}>
            {text}
        </span>
    );
}
