import { useChangeOrderTimelineContext } from "@components/Locations/Sites/Projects/ChangeOrdersTab/ChangeOrderTimelineContext";
import { IChangeOrderRejectionReason } from "@models/change-order-rejection-reason.model";
import { IChangeOrderRejectionRequest } from "@models/change-order-rejection-request";
import { getEnabledEntities as getRejectionReasons } from '@store/slices/change-order-rejection-reasons';
import { getTimeline } from "@store/slices/change-order-timeline";
import { getEntity as getChangeOrder, reject } from '@store/slices/change-orders';
import { asyncLaunchNotification } from "@store/slices/notification";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Alert, Button, Col, Drawer, Form, Input, Row, Select, Space } from "antd";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { TextArea } = Input;

interface IRejectProps {
    open: boolean
    toggle: () => void,
}

export const Reject = (props: IRejectProps) => {
    const { open, toggle } = props;
    const dispatch = useAppDispatch();

    const [form] = Form.useForm();
    const { entities: rejectionReasons } = useAppSelector((state) => state.ChangeOrderRejectionReason);
    const changeOrderTimelineContext = useChangeOrderTimelineContext();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getRejectionReasons());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onDrawerClose = () => {
        toggle()
    }

    const onDrawerSubmit = () => {
        form.submit();
    }

    const handleRejectSuccess = () => {
        dispatch(asyncLaunchNotification({
            type: "success",
            config: {
                message: t("changeOrder.detailRejectedTitle"),
                description: t("changeOrder.detailRejectedDescription")
            }
        }));
        dispatch(getChangeOrder(changeOrderTimelineContext?.changeOrderId!));
        dispatch(getTimeline(changeOrderTimelineContext?.changeOrderId!));
        toggle();
    }

    const onFinish = (values: any): void => {
        if (changeOrderTimelineContext?.changeOrderId!) {
            const rejectionRequest: IChangeOrderRejectionRequest = {
                reasonId: values.reasonId,
                comment: values.comment
            }
            dispatch(reject({ id: changeOrderTimelineContext?.changeOrderId!, rejectionRequest: rejectionRequest, onSuccess: handleRejectSuccess }));
        }
    };

    const genericFilter = (input: string, option: any) => (
        option?.children?.toLowerCase!()?.indexOf!(input?.toLowerCase!()) >= 0
    )

    return (
        <Drawer
            title={t("changeOrder.rejectDetailTitle")}
            width={400}
            onClose={onDrawerClose}
            open={open}
            bodyStyle={{ paddingBottom: 80 }}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="reasonId"
                            label={t("changeOrder.rejectReasonLabel")}
                        >
                            <Select
                                allowClear
                                optionFilterProp="children"
                                showSearch
                                filterOption={genericFilter}
                                placeholder={t("changeOrder.rejectReasonPlaceholder")}
                            >
                                {rejectionReasons?.map((reason: IChangeOrderRejectionReason) => (
                                    <Option value={reason.id!} key={reason.id!}>
                                        {reason.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="comment"
                            label={t("changeOrder.commentLabel")}
                        >
                            <TextArea rows={3} maxLength={200} placeholder={t("changeOrder.commentPlaceholder")} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24} className="text-center">
                        <Alert
                            message={<div style={{ color: '#CF1322' }}><div>{t("changeOrder.rejectAlertPart1")}</div><div>{t("changeOrder.rejectAlertPart2")}</div></div>}
                            type="error"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24} className="text-right pt-30">
                        <Space>
                            <Button type="default" onClick={onDrawerClose}>
                                {t("generic.cancel")}
                            </Button>
                            <Button onClick={onDrawerSubmit} danger type="primary">
                                {t("changeOrder.rejectAndCloseButton")}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
}