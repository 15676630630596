
import { isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from "../reducer.util";
import { createAsyncThunk } from "@reduxjs/toolkit"
import { sitesService } from "../../services/sites.service";
import { cleanEntity } from '../../shared/util/entity-utils';
import { defaultValue, ISite } from '../../models/site.model';
import { asyncLaunchNotification } from './notification';
import i18next from 'i18next';


const nameOfEntity = "site";
const service = sitesService;

export const getEntity = createAsyncThunk(
  `${nameOfEntity}/fetch_entity`,
  async (id: string | number) => {
    return service.getById(id);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  `${nameOfEntity}/create_entity`,
  async (entity: ISite, thunkAPI) => {
    const result = await service.create(cleanEntity(entity));
    thunkAPI.dispatch(asyncLaunchNotification({
      type: "success",
      config: {
        message: `${i18next.t("generic.site")}`,
        description: `Successfully linked ${i18next.t("generic.site")}`
      }
    }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  `${nameOfEntity}/update_entity`,
  async (entity: ISite, thunkAPI) => {
    try {
      const result = entity.id && await service.update(entity.id, cleanEntity(entity));
      thunkAPI.dispatch(asyncLaunchNotification({
        type: "success",
        config: {
          message: `${i18next.t("generic.site")}`,
          description: `${i18next.t("generic.site")} has been updated successfully.`
        }
      }));
      return result;
    } catch (error) {
      thunkAPI.dispatch(asyncLaunchNotification({
        type: "error",
        config: {
          message: `Location`,
          description: `Error updating ${i18next.t("generic.site")}`
        }
      }));
      return error;
    }
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  `${nameOfEntity}/delete_entity`,
  async (id: string | number, thunkAPI) => {
    try {
      const result = await service.delete(id);
      return result;
    } catch (error) {
      thunkAPI.dispatch(asyncLaunchNotification({
        type: "error",
        config: {
          message: `Location`,
          description: `Error deleting ${i18next.t("generic.site")}`
        }
      }));
      return error;
    }
  },
  { serializeError: serializeAxiosError }
);

const initialState: EntityState<ISite> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export const SiteSlice = createEntitySlice({
  name: 'site',
  initialState,
  reducers: {
    clearEntity: (state,) => {
      state.entity = defaultValue;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.updating = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload as ISite;
      })
      .addMatcher(isPending(createEntity, updateEntity, getEntity), state => {

        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  }
});

export const { reset, clearEntity } = SiteSlice.actions;

// Reducer
export default SiteSlice.reducer;

