import { http } from '../http/axios.instance';
import { IQueryParamsGeneric } from '@models/utils';
import { IAclPermission } from '@models/acl-permission.model';

const path = 'acl-permissions';

export const aclPermissionsRepository = {
  getAclPermissions: async (params: IQueryParamsGeneric, aclTargetId: any) => {
    const _params = new URLSearchParams({
      ...params,
      aclTargetId: aclTargetId,
      cacheBuster: `${new Date().getTime()}`
    });
    const requestUrl = `${path}/filter-by?${_params.toString()}&page=${params.current}`;
    const permissions = await http.get<IAclPermission[]>(requestUrl);
    return permissions;
  },
  createAclPermission: async (entity: IAclPermission): Promise<IAclPermission> => {
    const requestUrl = `${path}`;
    const permission = await http.post<IAclPermission>(requestUrl, entity);
    return permission.data;
  },
  deleteAclPermission: async (id: string | number) => {
    const requestUrl = `${path}/${id}`;
    const permission = await http.delete<IAclPermission>(requestUrl);
    return permission;
  },
}