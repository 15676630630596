import { CommentReferenceEnum } from '@models/enumerations/comment-reference-enum.model';
import { RichTextEditor } from './RichTextEditor';

export interface ICommentGeneratorProps {
    entityType: CommentReferenceEnum
    referenceId: number,
    onSuccessCreateComment?: () => void
}

export const CommentGenerator = (props: ICommentGeneratorProps) => {

    const { entityType, referenceId, onSuccessCreateComment } = props;

    return (
        <RichTextEditor 
            showSend={true} 
            entityType={entityType} 
            referenceId={referenceId} 
            placeholderText='Write a new comment here....'
            onSuccessCreateComment={onSuccessCreateComment}
        />
    );
}