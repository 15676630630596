import { IViewerProps, ViewerRichText } from "@components/Comment/ViewerRichText";
import './DescriptionAlbumRichText.less';

interface IDescriptionAlbumRichTextProps extends IViewerProps {}

export const DescriptionAlbumRichText = (props: IDescriptionAlbumRichTextProps) => {    
    return (
      <div className="description_album_rich_text">
       <ViewerRichText {...props}/>
      </div>
    )
}
