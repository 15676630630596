import { PhotosTab } from "@components/PhotoAlbum/PhotosTab";
import { If } from "@components/Utils/Structural";
import { PhotoAlbumTymeEnum } from "@models/enumerations/photo-album-type.enum";
import { isStringOrNumber } from "@shared/util/validations";
import { useParams } from "react-router-dom";

export const SitePhotoAlbumTab = () => {

    const { siteId } = useParams<"siteId">();
    
    return (
        <If condition={isStringOrNumber(siteId)}>
            <PhotosTab 
                referenceId={siteId!}
                referenceType={PhotoAlbumTymeEnum.SITE}
                entityName={"Site"} 
            />
        </If>
    )
}