import { FloorPlanSvgIcon } from "@components/Icons/FloorPlanSvgIcon";
import { Badge, Col, Row, Typography, theme } from "antd";
import { UploadFloorPlanButton } from "./UploadFloorPlanButton";

const { Title } = Typography;

export const PaginationDefaultState = () => {

    const { token: { colorTextSecondary, colorBgContainer }} = theme.useToken();
    
    return (
      <>
       <Row justify={"center"} style={{ background: colorBgContainer }}>
          <div className="mt-30">
            <Badge dot={true}>
              <FloorPlanSvgIcon className="text-color-neutral-6" />
            </Badge>
          </div>
          <Row className="pt-8 h-full pr-30 pl-30 pb-30 w-full h-full">
              <Col span={24}>
                  <div className="flex flex-col items-center justify-center">
                      <div className="flex flex-col items-center">
                        <Title className="text-color-neutral-9 mt-0 imb-0" level={5} style={{ fontWeight: 500 }}>
                          There are no Floor Plan yet
                        </Title>
                      </div>
                      <div className="pt-5" style={{ color: colorTextSecondary }}>
                        To start adding items to your punch list
                      </div>
                      <div className="flex flex-row items-center pt-12">
                        <UploadFloorPlanButton />
                      </div>
                  </div>
              </Col>
          </Row>
       </Row>
      </>
    )
}
