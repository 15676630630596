/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Col, Form, Row, theme, Input, Button } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { initialSetPassword, passwordReset } from "@store/slices/account";
import { useAppDispatch, useAppSelector } from "@store/store";
import { useContainerErrorAnimation } from "@components/Utils/ContainerErrorAnimation";
import { configuration } from '../../../environments/env';
import { AccountActionTypeEnum } from "@models/enumerations/account-action-type.enum";
import { If } from '@components/Utils/Structural';
import { isNotEmpty } from "@shared/util/string-utils";
import { IErrorCatched } from "@store/thunk.util";

interface  IContainerFormChangePasswordProps {
    typeAction: AccountActionTypeEnum
}

export const ContainerFormChangePassword = (props: IContainerFormChangePasswordProps) => {

    const { typeAction } = props;

    const { playAnimationOnError } = useContainerErrorAnimation();
    
    const dispatch = useAppDispatch();
    
    const [searchParams] = useSearchParams();
    
    const key = searchParams.get('key');

    const { token: { fontSizeXL, colorPrimary } } = theme.useToken();

    const { resetPasswordFailure, error, loading } = useAppSelector(state => state.Account);

    useEffect(() => {
        if (resetPasswordFailure || error) { playAnimationOnError(); }
    }, [resetPasswordFailure, error]);

    const onFinish = (dataForm: any): void => {
        const password = dataForm?.password;

        if (!(password && key)) { return; }

        const isSetInitalPassword = typeAction === AccountActionTypeEnum.SET_INITIAL_PASSWORD;
        if (isSetInitalPassword) {
            dispatch(initialSetPassword({ key, newPassword: password}))
                .unwrap()
                .catch((error) => playAnimationOnError());;
        } 
        
        const isChangeOrResetPassword = typeAction === AccountActionTypeEnum.CHANGE_PASSWORD || typeAction === AccountActionTypeEnum.RESET_PASSWORD;
        if (isChangeOrResetPassword){
            dispatch(passwordReset({ key, newPassword: password}))
                .unwrap()
                .catch((error) => {
                    console.log(error);
                    playAnimationOnError() 
                });
        }
    };

    const onFormError = () => { playAnimationOnError() }

    const [form] = Form.useForm();

    const validatePassword = (_: any, PasswordValue: string) => {
        const confirmPasswordValue = form.getFieldValue("confirmPassword")
        const hasconfirmPasswordValue = isNotEmpty(confirmPasswordValue);
        if (hasconfirmPasswordValue && String(PasswordValue) !== String(confirmPasswordValue)) {
          return Promise.reject('The password does not match.');
        }
        return Promise.resolve();
    };

    const validateConfirmPassword= (_: any, confirmPasswordValue: string) => {
        const passwordValue = form.getFieldValue("password")
        const hasPasswordValue = isNotEmpty(passwordValue);
        if (hasPasswordValue && String(confirmPasswordValue) !== String(passwordValue)) {
          return Promise.reject('The password confirmation does not match.');
        }
        return Promise.resolve();
    };

    return (
        <>
            <Row justify={"center"} >
                <Col span={24} className="flex justify-center pt-20 pb-5" > 
                    <LockOutlined style={{ fontSize: 30, color: colorPrimary  }} />
                </Col>
            </Row>

            <Row justify={"center"} >
                <Col span={24} className="flex justify-center pt-5 pb-5" > 
                    <span className="text-color-neutral-8 font-medium" style={{ fontSize: fontSizeXL }}>
                        { typeAction === AccountActionTypeEnum.SET_INITIAL_PASSWORD && `Choose your password`}
                        { typeAction === AccountActionTypeEnum.RESET_PASSWORD && `Reset password` }
                    </span>
                </Col>
            </Row>

            <Row justify={"center"} >
                <Col span={24} className="flex justify-center pt-5 pb-15" > 
                    <p className="flex justify-center text-center text-color-neutral-7 font-normal">
                        { typeAction === AccountActionTypeEnum.SET_INITIAL_PASSWORD && <ChangePasswordLabelDescription />}
                        { typeAction === AccountActionTypeEnum.RESET_PASSWORD &&  <ChangePasswordLabelDescription /> }
                    </p>
                </Col>
            </Row>

            <Form
                form={form}
                name="formChangePassword"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFormError}
                autoComplete="off"
                requiredMark={false}
            >
                <Row>
                    <Col span={24}>
                    <Form.Item
                        label={<LabelInput text={"New password"} />}
                        name="password"
                        rules={[
                            { required: true, message: "Please input your Password!" },
                            { min: 4, message: "Password must have more than 4 characters" },
                            { max: 100, message: "Password must be less than 4 characterss" },
                            { validator: validatePassword },
                        ]}
                    >
                        <Input.Password
                        className="w-full"
                        placeholder="Password"
                        />
                    </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                    <Form.Item
                        label={<LabelInput text={"Confirm new password"} />}
                        name="confirmPassword"
                        rules={[
                            { required: true, message: "Please confirm your password!" },
                            { validator: validateConfirmPassword }
                        ]}
                    >
                        <Input.Password
                            className="w-full"
                            placeholder="Confirm New Password"
                        />
                    </Form.Item>
                    </Col>
                </Row>

                <Form.Item className="mt-15 mb-15">
                    <Button
                        id="submitChangePassword"
                        type="primary"
                        htmlType="submit"
                        className="w-full"
                        disabled={loading}
                        loading={loading}
                    >
                        { typeAction === AccountActionTypeEnum.SET_INITIAL_PASSWORD && 'Set password' }
                        { typeAction === AccountActionTypeEnum.RESET_PASSWORD && 'Reset password' }
                    </Button>

                </Form.Item>
            </Form>
            
            <If condition={!!(error && error !== null)} >
                <ErrorMessage error={error} />
            </If>
        </>
    )
}

export const ErrorMessage: React.FC<{ error: IErrorCatched }> = ({ error }) => {
    const { token: { colorErrorText } } = theme.useToken();
    return (
      <Row justify="center">
        <Col className="text-center text-xs mb-15 mt-20" style={{ color: colorErrorText }}>
          {error?.message}
        </Col>
      </Row>
    );
};
  
export const LabelInput = ({ text }: { text: string }) => {
    return <span className="text-color-neutral-9 font-medium">{text}</span>
}

export const ChangePasswordLabelDescription = () => {
    const [searchParams] = useSearchParams();
    const name = searchParams.get('name');
    const HelloUserMessage = name ? `Hello ${name}` : 'Hello';
    const appName = configuration.APP_NAME || "";

    return (
        <>
            { HelloUserMessage }, please choose a new password for your { appName } SiteRise account.
        </>
    )
}