import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import { Avatar, Col, Row, Divider} from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { IProjectDeliverable } from '@models/project-deliverable'
import { CancelButtonModal, CommonFooterModal, ModalWrapper, OKButtonModal } from '@components/Utils/ModalWrapper'
import { DATE_FORMAT_FULL_NAME_MONTH, formatDatePipe } from '@shared/util/date-utils';
import { isArrayWithValues } from '@shared/util/array-util';
import { useAppDispatch } from '@store/store';
import { getProjectDeliverableDetailById, getProjectDeliverablesData, RequestForApproval } from '@store/slices/project-deliverables';
import { requestForApproval } from '@store/slices/project-deliverables';
import { IProjectDeliverableApproval } from '@models/project-deliverable-approval';
import { hasIntegerValue } from '@shared/util/number-util';
import useModal from '@HOOKs/UseModal';
import { RequestApprovalSentAlertModal } from './RequestApprovalSentAlertModal';
import { getProjectOverviewData } from '@store/slices/project-overview';
import { asyncLaunchNotification } from '@store/slices/notification';

interface ISubmitForApprovalModalProps {
    open: boolean,
    setOpen: (state: boolean) => void
    deliverable: IProjectDeliverable
}

export const SubmitForApprovalModal: React.FC<ISubmitForApprovalModalProps> = ({ open, setOpen, deliverable}) => {

    const dispatch = useAppDispatch();
    const { projectId } = useParams<"projectId">();
    const { visible: showRequestSentAlert, setVisible: openAlert } = useModal();
    const [firstApproval, setFirstApproval] = useState<IProjectDeliverableApproval>();
    const [userNameNotified, setUserNameNotified] = useState("");

    const sendRequestApproval = () => {   
        if (!hasIntegerValue(deliverable?.id)) return;

        const requestForApprovalPayload: RequestForApproval = {
            projectId: projectId!,
            projectDeliverableId: String(deliverable.id)
        }
        dispatch(requestForApproval(requestForApprovalPayload)).unwrap().then(() => {
            setOpen(false);
            showAlertSuccess();
            projectId && dispatch(getProjectDeliverablesData(projectId));
            projectId && dispatch(getProjectDeliverableDetailById({ projectDeliverableId: String(deliverable.id) })).unwrap().then(() => {
                dispatch(getProjectOverviewData(projectId));
        
                dispatch(asyncLaunchNotification({
                  type: "success",
                  config: {
                    message: `Project Deliverable`,
                    description: `The approval was requested successfully.`
                  }
                }))
            });
        })
    }

    const showAlertSuccess = () => {
        if (!(firstApproval && firstApproval?.user?.fullName)) { return } 
        
        setUserNameNotified(firstApproval.user.fullName)
        openAlert(true); 
    }

    const getFirstApproval = () => {
        if ( deliverable?.approvals && isArrayWithValues(deliverable.approvals)) { return deliverable?.approvals[0] }
    }

    useEffect(() => {
        setFirstApproval(getFirstApproval())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliverable])

    const footer = (
        <CommonFooterModal className='justify-between'>
            <CancelButtonModal onClick={() => setOpen(false) } >Cancel</CancelButtonModal>
            <OKButtonModal onClick={sendRequestApproval} >Submit</OKButtonModal>
        </CommonFooterModal>
    )

    return (
        <>
            <ModalWrapper  
                footer={footer}
                open={open}
                width="480px"
            >
                <Row justify="space-evenly" className="pt-30 pb-10">
                    <Col span={24} flex={"auto"} className="pt-30 pb-15 ml-30 mr-30 flex items-center justify-center">
                        <div className="text-center font-bold tracking-normal" style={{
                            fontSize: "16px",
                            lineHeight: "26px",
                        }}
                        > You are about to submit this deliverable for approval on {formatDatePipe(Date(), DATE_FORMAT_FULL_NAME_MONTH)}.</div>
                    </Col>
                    <Col span={24} flex={"auto"} className="pt-10 pb-20 pl-20 pr-20 ml-30 mr-30 flex items-center justify-center">
                        <div className="text-center tracking-normal text-center" style={{ lineHeight: "18px" }}>
                            Below is everyone that will need to approve this deliverable for it to be completed.
                        </div>
                    </Col>
                    <Col span={24} flex={"auto"} className="pt-20 pb-30 pl-5 ml-20 pr-5 mr-20 flex flex-col">
                        <Divider className='mt-0 mb-5'/>
                        {
                            deliverable && deliverable?.approvals?.map((item) => (
                                <div className='flex flex-col w-full pt-5' key={item.id}>
                                    <div className='flex flex-row w-full justify-between pb-10'>
                                        <div>
                                            <Avatar>
                                                { item?.user?.fullName ? `${item?.user?.fullName.slice(0,2)}`.toUpperCase(): <UserOutlined /> }
                                            </Avatar>
                                            <span className='pl-10 font-medium'>{item?.user?.fullName}</span>
                                        </div>
                                        <div className='flex flex-row items-center'>
                                            <div className='flex flex-row'>{item?.role?.name}</div>
                                        </div>
                                    </div>
                                    <Divider className='mt-0 mb-5'/>
                                </div>
                            ))
                        }
                    </Col>
                    <Col span={24} flex={"auto"} className="pt-10 pb-20 pl-20 pr-20 ml-30 mr-30 flex items-center justify-center">
                        <div className="text-center tracking-normal" style={{ lineHeight: "26px" }}>
                            Once you submit this deliverable, you will no longer be able to make changes.
                        </div>
                    </Col>
                </Row>
            </ModalWrapper>
            <RequestApprovalSentAlertModal open={showRequestSentAlert} setOpen={openAlert} userNotified={userNameNotified} />
        </>
    )
}