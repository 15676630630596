import { useState } from 'react';
/* eslint-disable react-hooks/exhaustive-deps */

import SockJS from 'sockjs-client';

import Stomp, { Client, Subscription } from 'webstomp-client';
import { useCallback, useEffect } from "react";

interface ObjectType {
  [key: string]: any;
}

let stompClient: Client | null;
let connection: Promise<any>;
let connectedPromise: any = null;
const subscriptions: { [key: string]: Subscription } = {};
let alreadyConnectedOnce = false;


export interface StompConfig {
  wsUrl: string
}
export function useStomp(config: StompConfig, callback: () => void) {

  const [isConnected, setIsConnected] = useState(false)
  const getCookie = (name: string) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) {
      const pop = parts.pop()
      if (pop) {
        return pop.split(';').shift()
      }
    }
    return null
  }

  const createConnection = (): Promise<any> => new Promise(resolve => (connectedPromise = resolve));


  const connect = useCallback(() => {
    if (connectedPromise !== null || alreadyConnectedOnce) {
      // the connection is already being established
      return;
    }
    connection = createConnection();

    const headers: any = {};
    const url = config.wsUrl
    headers['X-XSRF-TOKEN'] = getCookie('XSRF-TOKEN');
    const socket = new SockJS(url);
    stompClient = Stomp.over(socket, { protocols: ['v12.stomp'] });



    stompClient.connect(headers, () => {
      connectedPromise('success');
      connectedPromise = null;
      alreadyConnectedOnce = true;
      setIsConnected(true);

      callback();
    }, () => {
      // if there is a problem, try to re-connect;
      setTimeout(() => {
        connectedPromise = null
        alreadyConnectedOnce = false
        connect();
      }, 10000)
    });
  }, []);



  const send = useCallback(
    (path: string, body: ObjectType, headers: ObjectType) => {
      connection?.then(() => {
        stompClient?.send(
          path, // destination
          JSON.stringify({ body }), // body
          headers // header
        );
      });
    },
    [stompClient]
  );

  const subscribe = useCallback(
    (path: string, newMessageCallback: (msg: object) => void) => {
      if (!stompClient) return;

      if (subscriptions[path]) return;

      connection.then(() => {
        if (!stompClient) return;

        const subscriber = stompClient.subscribe(path, data => {
          const { body } = data
          newMessageCallback(JSON.parse(body));
        });

        subscriptions[path] = subscriber;
      });
    },
    []
  );

  const unsubscribe = useCallback((path: string) => {
    subscriptions[path].unsubscribe();
    delete subscriptions[path];
  }, []);

  const disconnect = useCallback(() => {
    if (stompClient !== null) {
      if (stompClient.connected) {
        stompClient.disconnect();
      }
      stompClient = null;
    }
    alreadyConnectedOnce = false;
    setIsConnected(false);
  }, [stompClient]);

  useEffect(() => {
    connect();
  }, []);

  return {
    disconnect,
    subscribe,
    unsubscribe,
    subscriptions,
    send,
    isConnected,
  };
}
