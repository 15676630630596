import { IViewerProps, ViewerRichText } from "@components/Comment/ViewerRichText";
import './DescriptionPunchListItemRichText.less';

interface IPunchListItemRichTextProps extends IViewerProps {}

export const PunchListItemRichText = (props: IPunchListItemRichTextProps) => {    
    return (
      <div className="description_punch_list_item_rich_text">
       <ViewerRichText {...props}/>
      </div>
    )
}

export const PunchListItemRichTextReadOnly = (props: IPunchListItemRichTextProps) => { 
  
  return (
    <div className="description_punch_list_item_rich_text_read_only">
     <ViewerRichText {...props}/>
    </div>
  )
}
