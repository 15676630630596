import { useState } from 'react';
import { Alert, Button, Col, ConfigProvider, Row, Space, theme } from 'antd';
import { IProjectDeliverableUI } from '@models/project-deliverable';
import { formatDatePipe } from '@shared/util/date-utils';
import { ViewDetailsHistory } from './ViewDetailsHistory';
import { If } from '@components/Utils/Structural';

interface ApprovalCompleteInfoProps {
    deliverable: IProjectDeliverableUI
}

export const ApprovalCompleteInfo = ({ deliverable}: ApprovalCompleteInfoProps) => {

    const { token: { colorSuccessActive } } = theme.useToken();
    
    const [openViewDetailsApprovals, setOpenViewDetailsApprovals] = useState(false);

    const ApprovalStatus = ({approvalsInfo}: { approvalsInfo: IProjectDeliverableUI }) => {
        
        const viewDetailsButton = (
            <Button size="small" type="text" className='mr-20' onClick={() => setOpenViewDetailsApprovals(true)}>
                <span className='font-medium' style={{ color: colorSuccessActive }}>
                    View Details
                </span>
            </Button>
        )
        
        const approvedOn = (<span>This deliverable was approved on { formatDatePipe(approvalsInfo?.lastApproval?.approvedDate) }.</span>)

        const RenderMessageContent = () => (<div className='pt-10 pb-10 pl-15'>{approvedOn}</div>)

        return (
            <>
                <Row className='w-full pb-20'>
                    <Col span={24}>
                        <Alert
                            message={<RenderMessageContent />}
                            type="info"
                            banner
                            icon={<></>}
                            action={
                                <Space>
                                    <ConfigProvider theme={{ token: { colorPrimary: colorSuccessActive } }}>
                                        { viewDetailsButton }
                                    </ConfigProvider>
                                </Space>
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <ViewDetailsHistory 
                        deliverable={deliverable} 
                        open={openViewDetailsApprovals} 
                        setOpen={setOpenViewDetailsApprovals} />
                </Row>
            </>
        )
    }

    return (
        <If condition={(deliverable?.isStatusCompleted)} >
            <ApprovalStatus approvalsInfo={deliverable} />
        </If>
    )
}