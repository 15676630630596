import { createAsyncThunk, createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { accountService } from '../../services/account.service';
import { IErrorCatched, serializeGenericHandleError } from '@store/thunk.util';

export const validateToken = createAsyncThunk(
  'account/validateToken',
  async (data: { token: string, actionType: string }, thunkAPI) => {
    return accountService.validateToken(data);
  },
  { serializeError: serializeGenericHandleError }
); 

export const initialSetPassword = createAsyncThunk(
  'account/initialSetPassword',
  async (data: { key: string, newPassword: string }, thunkAPI) => {
    return accountService.initialSetPassword(data);
  },
  { serializeError: serializeGenericHandleError }
);

export const passwordReset = createAsyncThunk(
  'account/password-reset',
  async (data: { key: string, newPassword: string }, thunkAPI) => {
    return accountService.passwordReset(data);
  },
  { serializeError: serializeGenericHandleError }
);
interface IInitialState {
  loading: boolean,
  resetPasswordSuccess: boolean,
  resetPasswordFailure: boolean,
  isTokenValid: null | boolean,
  error: IErrorCatched
}

const initialState: IInitialState = {
  loading: false,
  resetPasswordSuccess: false,
  resetPasswordFailure: false,
  isTokenValid: null,
  error: null
};

export const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    clearState: (state,) => {
      return initialState
    },
  },
  extraReducers(builder) {
    builder
      .addCase(passwordReset.fulfilled, (state) => {
        state.loading = false;
        state.resetPasswordSuccess = true;
        state.resetPasswordFailure = false;
        state.error = null;
      })
      .addCase(initialSetPassword.fulfilled, (state) => {
        state.loading = false;
        state.resetPasswordSuccess = true;
        state.resetPasswordFailure = false;
        state.error = null
      })
      .addCase(validateToken.fulfilled, (state, action) => {
        state.loading = false;
        state.isTokenValid = Boolean(action.payload.data);
      })
      .addMatcher(isPending(passwordReset), state => {
        state.loading = true;
      })
      .addMatcher(isPending(validateToken), state => {
        state.loading = true;
        state.isTokenValid = null;
      })
      .addMatcher(isRejected(passwordReset), (state, action: any) => {
        state.loading = false;
        state.resetPasswordSuccess = false;
        state.resetPasswordFailure = true;
        if (action && action?.error) {
          state.error = action.error 
        };
      })
      .addMatcher(isRejected(validateToken), (state, action) => {
        state.loading = false;
        state.isTokenValid = null;
      })
  }
});

export const { clearState } = slice.actions;

// Reducer
export default slice.reducer;

