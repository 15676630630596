import useVisible from "@HOOKs/UseVisible";
import { EditOutlined, WarningOutlined } from "@ant-design/icons";
import AttachmentsList from "@components/Attachment/AttachmentsList";
import { Comment } from "@components/Comment/Comment";
import { EmptyLabel } from "@components/Utils/EmptyLabel";
import { TabContainer } from "@components/Utils/TabContainer";
import { ICommentRichText } from "@models/comment.model";
import { AttachmentReferenceEnum } from "@models/enumerations/attachment-reference-enum.model";
import { getTimeline } from "@store/slices/change-order-timeline";
import { getEntity } from "@store/slices/change-orders";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Button, Card, Col, Divider, Row, Skeleton, Space, Tag, Tooltip, theme } from "antd";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { ChangeOrderTimeline } from "./ChangeOrderTimeline";
import { CostDetail } from "./CostDetail/CostDetail";
import { ReasonDrawer } from "./ReasonDrawer";
import { BackHeader } from "./shared/BackHeader";

export const ChangeOrderView = () => {

    const { coId } = useParams<"coId">();
    const { token: { padding, colorPrimary, colorFillQuaternary, colorBgLayout, colorTextSecondary, colorInfoBg } } = theme.useToken();
    const { entity, loading } = useAppSelector((state) => state.ChangeOrder);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [reasonDrawerOpen, toggleReasonDrawerOpen, setReasonDrawerOpen] = useVisible();


    useEffect(() => {
        if (coId) {
            dispatch(getEntity(coId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coId])

    const descriptionView = () => {
        const buildComment = () => {
            const comment: ICommentRichText = {
                payload: entity?.description,
                createdDate: entity?.createdDate,
                createdBy: entity?.createdBy,
                createdById: entity?.createdBy,
                createdByFirstName: entity?.createdByFirstName,
                createdByLastName: entity?.createdByLastName,
                createdByEmail: entity?.createdByEmail,
            }

            return comment;
        }

        return (<>
            {(entity?.description && <Comment value={buildComment()} />)}
        </>);
    }

    const handleFileChanges = () => {
        dispatch(getTimeline(coId!));
    }

    const editReason = () => {
        setReasonDrawerOpen(true);
    }

    return (
        <TabContainer>
            <BackHeader />
            <Skeleton loading={loading} paragraph={{ rows: 10, style: { maxWidth: '648px' } }} active={true} >
                <Row style={{ paddingLeft: `${padding}px`, paddingRight: `${padding}px` }} className="pt-20 w-full">
                    <Col flex="none" style={{ width: '100%', maxWidth: '648px' }}>
                        <Card
                            style={{ borderRadius: '4px' }}
                            bodyStyle={{ paddingLeft: 10, paddingRight: 10 }}
                        >
                            <Row>
                                <Col span={8} className='flex flex-row items-center'>
                                    <span style={{ color: colorPrimary, fontSize: '20px', lineHeight: '28px' }}>{t("changeOrder.co")} #{entity?.number}</span>
                                </Col>
                                <Col span={16} className='text-right'>
                                    <Tag className="pt-3 pb-3 pl-10 pr-10"
                                        style={{ borderColor: colorFillQuaternary, borderRadius: '2px' }}>
                                        <strong>{t("changeOrder.status")}:</strong> {entity?.lastStatusTransition?.status?.name}
                                    </Tag>
                                </Col>
                            </Row>
                            <Divider className="mt-8 mb-10" type="horizontal"></Divider>
                            <Row>
                                <Col span={24} className='mb-10'>
                                    {descriptionView()}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className='pl-30 mt-15 mb-10'>
                                    <Card
                                        bodyStyle={{ paddingLeft: 12, paddingRight: 12, paddingTop: 8, paddingBottom: 8 }}
                                        style={{ border: 0, borderRadius: '4px', backgroundColor: colorInfoBg }}>
                                        <Row className="w-full">
                                            <Col>
                                                <span className="font-bold">{t("changeOrder.estimatedCostLabel")}:</span>
                                                <span className="ml-10">
                                                    <Tooltip title={`${entity?.estimatedCost ? parseFloat(entity?.estimatedCost) : ''} ${entity?.currency?.name || ''}`}>
                                                        {entity?.currency?.symbol} {entity?.estimatedCost ? parseFloat(entity?.estimatedCost!).toFixed(2) : ''}
                                                    </Tooltip>
                                                </span>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className='pl-30 mb-10'>
                                    <Card
                                        bodyStyle={{ paddingLeft: 12, paddingRight: 12, paddingTop: 8, paddingBottom: 8 }}
                                        style={{ border: 0, borderRadius: '4px', backgroundColor: colorBgLayout }}>
                                        <Row className="w-full">
                                            <Space>
                                                <span className="font-bold">{t("changeOrder.scheduleImpactLabel")}:</span>
                                            </Space>
                                            <Space>
                                                <div className='flex flex-row ml-10'>
                                                    <span style={{ color: colorTextSecondary }}>{entity?.scheduleImpact === true ? "Yes" : "No"}</span>
                                                </div>
                                            </Space>
                                            {entity?.scheduleImpactDays && <>
                                                <Divider type="vertical" className='ml-20' style={{ height: '22px' }} />
                                                <Space>
                                                    <div className='flex flex-row  ml-10'>
                                                        <span style={{ color: colorTextSecondary }}>+ {entity?.scheduleImpactDays} {t("changeOrder.workDays")}</span>
                                                    </div>
                                                </Space>
                                            </>
                                            }
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className='pl-30 mb-10'>
                                    <Card
                                        bodyStyle={{ paddingLeft: 12, paddingRight: 12, paddingTop: 8, paddingBottom: 8 }}
                                        style={{ border: 0, borderRadius: '4px', backgroundColor: colorBgLayout }}>
                                        <Row className="w-full">
                                            <Col>
                                                <span className="font-bold">{t("changeOrder.drawingReferenceLabel")}:</span>
                                                <span className="ml-10" style={{ color: colorTextSecondary }}>{entity?.drawingReference || <EmptyLabel />}</span>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className='pl-30 mb-10'>
                                    <Card
                                        bodyStyle={{ paddingLeft: 12, paddingRight: 12, paddingTop: 8, paddingBottom: 8 }}
                                        style={{ border: 0, borderRadius: '4px', backgroundColor: colorBgLayout }}>
                                        <Row className="w-full">
                                            <Col>
                                                <span className="font-bold">{t("changeOrder.rfiRelatedLabel")}:</span>
                                                {entity?.relatedRfi ?
                                                    <span className="ml-10" style={{ color: '#1677ff' }}>
                                                        {t("rfi.rfi")} #{entity?.relatedRfi?.number} - {entity?.relatedRfi?.category?.name}
                                                    </span>
                                                    : <EmptyLabel className="ml-10" />
                                                }
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className='pl-30 mb-10'>
                                    <Card
                                        bodyStyle={{ paddingLeft: 12, paddingRight: 12, paddingTop: 8, paddingBottom: 8 }}
                                        style={{ border: 0, borderRadius: '4px', backgroundColor: colorBgLayout }}>
                                        <Row className="w-full">
                                            <Col span={21}>
                                                <span className="font-bold">{t("changeOrder.reasonLabel")}:</span>
                                                <span className="ml-10" style={{ color: colorTextSecondary }}>{entity?.reason?.name ||
                                                    <span>{t("generic.unset")} <WarningOutlined /></span>
                                                }</span>
                                            </Col>
                                            <Col span={3} className="text-right">
                                                <Button
                                                    type="link"
                                                    icon={<EditOutlined />}
                                                    onClick={editReason}
                                                    style={{ padding: 0, height: 20 }}
                                                >{t("generic.edit")}</Button>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                        <Card
                            className='mt-20 pt-10'
                            style={{ borderRadius: '4px' }}
                            bodyStyle={{ paddingLeft: 10, paddingRight: 10, paddingTop: 0 }}
                        >
                            {
                                entity?.currentRevisionId
                                && <AttachmentsList
                                    entityType={AttachmentReferenceEnum.CHANGE_ORDER_REVISION}
                                    referenceId={entity?.currentRevisionId}
                                    onSuccessUpload={handleFileChanges}
                                    onSuccessRemove={handleFileChanges} />
                            }
                        </Card>
                        <CostDetail changeOrderId={Number(coId)} currencySymbol={entity.currency?.symbol} />
                    </Col>
                    <Col flex="auto" style={{ maxWidth: '550px' }} className="pl-20">
                        {entity?.id && <ChangeOrderTimeline changeOrderId={entity.id} />}
                    </Col>
                </Row>
            </Skeleton>
            {reasonDrawerOpen === true &&
                <ReasonDrawer open={reasonDrawerOpen} toggle={toggleReasonDrawerOpen} currentReasonId={entity?.reason?.id} />
            }
        </TabContainer>
    );
}   