import { IChangeOrderRejectionReason } from '@models/change-order-rejection-reason.model';
import { http } from '../http/axios.instance';

const path = 'change-order-rejection-reasons';

export const changeOrderRejectionReasonsRepository = {
    getChangeOrderRejectionReasons: async () => {
        const requestUrl = `${path}?cacheBuster=${new Date().getTime()}`;
        const promiseGetAll = await http.get<IChangeOrderRejectionReason[]>(requestUrl);
        return promiseGetAll;
    },
    getEnabledChangeOrderRejectionReasons: async () => {
        const requestUrl = `${path}/enabled?cacheBuster=${new Date().getTime()}`;
        const promiseGetAll = await http.get<IChangeOrderRejectionReason[]>(requestUrl);
        return promiseGetAll;
    },
    create: async (entity: IChangeOrderRejectionReason): Promise<IChangeOrderRejectionReason> => {

        const requestUrl = `${path}`;
        const promiseCreate = await http.post<IChangeOrderRejectionReason>(requestUrl, entity);
        return promiseCreate.data;
    },
    update: async (id: string | number, entity: IChangeOrderRejectionReason): Promise<IChangeOrderRejectionReason> => {

        const requestUrl = `${path}/${id}`;
        const promiseUpdate = await http.put<IChangeOrderRejectionReason>(requestUrl, entity);
        return promiseUpdate.data;
    },
}