

import { ITimelineEntry } from "@models/timeline.model";
import { Col, Row, TimelineItemProps } from "antd";
import { EntryTime } from "./EntryTime";
import { ITimelineItemStrategy } from "./strategy-factory";

export class BaseItemStrategy implements ITimelineItemStrategy {

    public getItem(timelineEntry: ITimelineEntry): TimelineItemProps {
        const response: TimelineItemProps =
        {
            color: '#1890FF',
            children: <Row className="w-full">
                <Col span={17} className="font-bold">{timelineEntry.title}</Col>
                <Col span={7} className="text-right"><EntryTime date={timelineEntry.date} /></Col>
            </Row>,
        };
        return response;
    }
}