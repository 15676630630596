import useColorStatus from '@HOOKs/UseColorStatus';
import { IProjectDeliverableUI } from '@models/project-deliverable';
import { Button, ConfigProvider, theme } from 'antd';
import { useLayoutEffect, useState } from 'react';
import { EmptyButton } from '@components/Utils/EmptyButton';

export interface StatusButtonProps extends React.ComponentPropsWithoutRef<typeof Button> {
    deliverable: IProjectDeliverableUI
}

export const StatusProjectDeliverableButton: React.FC<StatusButtonProps> = ({ deliverable, style, ...restProps }) => {

    const [colorStatus, setStatus] = useColorStatus();
    const [loading, setLoading] = useState(true);

    useLayoutEffect(() => {
        setLoading(true)
        deliverable.status && setStatus(deliverable.status)
        setTimeout(() => setLoading(false), 10)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliverable])

    return (
        <ConfigProvider theme={{ algorithm: theme.compactAlgorithm, token: { borderRadius: 3, ...colorStatus && { colorPrimary: colorStatus } } }}>
            {
                loading || !deliverable.statusLabelUI
                ? <EmptyButton /> 
                : (
                    <Button className='cursor-default pointer-events-none' style={style} type={"primary"} {...restProps} >
                        { deliverable.statusLabelUI }
                    </Button>
                  )
            }
        </ConfigProvider>
    )
}
