import { isFulfilled } from '@reduxjs/toolkit';
import { IBudgetTemplate, defaultValue } from '@models/budget-template.model';
import { createEntitySlice, EntityState, serializeAxiosError } from '../reducer.util';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IQueryParamsGeneric } from '@models/utils';
import { budgetTemplateService } from '@services/budget-template.service';

export const getEntities = createAsyncThunk(
  'budget-template/fetch_entity_list',
  async (queryParams: IQueryParamsGeneric) => {
    return await budgetTemplateService.getAll(queryParams);
  },
  { serializeError: serializeAxiosError }
);

const initialState: EntityState<IBudgetTemplate> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export const BudgetTemplateSlice = createEntitySlice({
  name: 'budget_templates',
  initialState,
  reducers: {
    clearEntity: state => {
      state.entity = defaultValue;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(isFulfilled(getEntities), (state, action) => {
      const { data, headers } = action.payload;
      const xTotalCount = headers['x-total-count'];

      return {
        ...state,
        loading: false,
        entities: data,
        totalItems: xTotalCount ? parseInt(xTotalCount, 10) : data.length,
      };
    });
  },
});

export const { reset, clearEntity } = BudgetTemplateSlice.actions;

// Reducer
export default BudgetTemplateSlice.reducer;
