import { isFulfilled, isPending, createAsyncThunk } from '@reduxjs/toolkit';

import { ILocation, ILocationUI, defaultValue } from "../../models/location.model";
import { createEntitySlice, EntityState, serializeAxiosError } from "../reducer.util";
import { locationService } from "../../services/locations.service";
import { IQueryParams } from "../../models/pagination";
import { cleanEntity } from '../../shared/util/entity-utils';
import { RootState } from '../store';
import { asyncLaunchNotification } from './notification';
import i18next from 'i18next';

export const getEntities = createAsyncThunk(
  'location/fetch_entity_list',
  async (queryParams: IQueryParams, thunkAPI) => {
    thunkAPI.dispatch(setLastPagination(queryParams))
    return locationService.getLocations(queryParams);
  },
  { serializeError: serializeAxiosError }
);

export const getEntitiesLastPagination = createAsyncThunk(
  'location/fetch_entity_list_last_pagination',
  async (lastPagination: boolean, thunkAPI) => {
    if (lastPagination) {
      const state = thunkAPI.getState() as RootState;
      const lastPagination = state.Location.lastPagination || {};
      thunkAPI.dispatch(getEntities(lastPagination));
    }
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  'location/fetch_entity',
  async (id: string | number) => {
    return locationService.getLocationById(id) as ILocationUI;
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'location/create_entity',
  async (entity: ILocation, thunkAPI) => {
    const result = await locationService.createLocation(cleanEntity(entity));
    thunkAPI.dispatch(getEntitiesLastPagination(true));
    thunkAPI.dispatch(asyncLaunchNotification({
      type: "success",
      config: {
        message: `${i18next.t("generic.location")}`,
        description: `${i18next.t("generic.location")} created successfully`
      }
    }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'location/update_entity',
  async (entity: ILocation, thunkAPI) => {

    try {
      const result = entity.id && await locationService.updateLocation(entity.id, cleanEntity(entity));
      thunkAPI.dispatch(getEntitiesLastPagination(true));
      thunkAPI.dispatch(asyncLaunchNotification({
        type: "success",
        config: {
          message: `${i18next.t("generic.location")}`,
          description: `${i18next.t("generic.location")} updated successfully`
        }
      }));
      return result;
    } catch (error) {
      thunkAPI.dispatch(asyncLaunchNotification({
        type: "error",
        config: {
          message: `${i18next.t("generic.location")}`,
          description: `Error updating ${i18next.t("generic.location")}`
        }
      }));
      return error;
    }

  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'location/delete_entity',
  async (id: string | number, thunkAPI) => {
    try {
      const result = await locationService.deleteLocation(id);
      thunkAPI.dispatch(getEntitiesLastPagination(true));
      thunkAPI.dispatch(asyncLaunchNotification({
        type: "success",
        config: {
          message: `${i18next.t("generic.location")}`,
          description: `${i18next.t("generic.location")} delete successfully`
        }
      }));
      return result;
    } catch (error) {
      thunkAPI.dispatch(asyncLaunchNotification({
        type: "error",
        config: {
          message: `${i18next.t("generic.location")}`,
          description: `Error deleting ${i18next.t("generic.location")}`
        }
      }));
      return error;
    }
  },
  { serializeError: serializeAxiosError }
);

const initialState: EntityState<ILocationUI> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  lastPagination: {} as IQueryParams,
  updateSuccess: false,
};

export const LocationSlice = createEntitySlice({
  name: 'location',
  initialState,
  reducers: {
    clearToCreateEntity: (state,) => {
      state.entity = {
        ...defaultValue,
        locationKeyPhotoSrc: state?.entity?.locationKeyPhotoSrc
      };
    },
    clearEntity: (state,) => {
      state.entity = {
        ...defaultValue,
      };
    },
    clearLocationKeyPhotoSrc: (state,) => {
      const { locationKeyPhotoSrc, ...rest } = state.entity
      state.entity = rest;
      return state;
    },
    setLastPagination: (state, action) => {
      state.lastPagination = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        const xTotalCount: string = headers['x-total-count'] || "0";
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(xTotalCount, 10),
        };
      })
      .addMatcher(isFulfilled(createEntity), (state, action) => {
        state.loading = false;
        state.entity = action.payload as ILocation;
        state.updating = false;
      })
      .addMatcher(isFulfilled(updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  }
});

export const { reset, clearEntity, clearToCreateEntity, setLastPagination, clearLocationKeyPhotoSrc } = LocationSlice.actions;

// Reducer
export default LocationSlice.reducer;

