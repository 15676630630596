export const OFFICIAL_WEBSITE_SITERISE_URL = 'https://www.siterise.app/';
export const APPS_IDS = {
  LEVEL5: 'LEVEL5',
};
export const LOCATION_PATH = '/location';
export const INTERCOM_APP_ID = 'n73neanf';
export const ADMIN_ROLE_KEY = 'ROLE_ADMIN';
export const ADMIN_ID = '1';
export const FORBIDDEN_CODE = 403;
export const ADDRESS_TYPE_PRIMARY = { id: 1 };
export const STORAGE_USER_KEY = 'user';
export const STORAGE_LOGIN_REDIRECT_KEY = 'login_redirect_url';
export const MAX_RESULT_AUDIT_HISTORY_DEFAULT = 30;
export const PAGINATION_SIZE_DEFAULT = 50;
export const PAGINATION_SIZE_GENERIC_TABLE_SMALL = 5;
export const PAGINATION_SIZE_COMPANY_TABLE = 25;
export const PAGINATION_SIZE_DEFAULT_IN_COMMENTS = 50;
export const TEAM_MEMBERS_SORTING_KEY = 'role.name,user.firstName,user.lastName';
export const GENERIC_COMPANIES_SORTING_KEY = 'companyRole.description,company.name';
export const DEFAULT_TIMING_LOADING = 500;
export const PAGINATION_SIZE_DEFAULT_IN_PHOTOS = 25;
export const DEFAULT_TIMING_IN_LOADING_EFFECT = 150;
export const DEFAULT_LABEL_PROJECT_OPENING = 'Target';
export const DSR_LINK = 'https://docs.google.com/spreadsheets/d/1fBQNNU1-L4ufRBvhrcEnhrn4SEgDYww8PTUhIVE7zV8';
export const MAX_LIMIT_FILES_TO_UPLOAD = 10;
export const MAX_LIMIT_SIZE_FILE_TO_UPLOAD_IN_MB = 250;
export const MAX_SUM_FILE_SIZES_TO_UPLOAD_IN_ALBUMS_IN_MB = 200;
export const DASHBOARD_BI_REVEAL_NAME = 'unique-dashboard';
export const DASHBOARD_BI_API_URL = 'bi-api/';
export const DEFAULT_BUDGET_EXPORTED_FILENAME_EXCEL = 'budget-exported.xlsx';
export const DEFAULT_BUDGET_EXPORTED_FILENAME_PDF = 'budget-exported.pdf';
export const MARKUP_FEATURE_FLAG_ENABLED = true;