import { punchListRepository } from "@infrastructure/repositories/punch-list.repository";

export const punchListService = {
    getAll: punchListRepository.getAll,
    getById: punchListRepository.getById,
    addPunchListItem: punchListRepository.addPunchListItem,
    editPunchListItem: punchListRepository.editPunchListItem,
    editPunchListItemStatus: punchListRepository.editPunchListItemStatus,
    getPages: punchListRepository.getPages,
    getCategories: punchListRepository.getCategories,
    getReasons: punchListRepository.getReasons,
    getStatuses: punchListRepository.getStatuses,
    punchListCheckGenerationPreviews: punchListRepository.punchListCheckGenerationPreviews,
    punchListCreatePages: punchListRepository.punchListCreatePages
}