import { IQueryParamsGeneric } from 'models/utils';
import { http } from '../http/axios.instance';
import { IBudgetTemplate, IBudgetTemplateExtended, IBudgetTemplateSave } from '@models/budget-template.model';
import { StringORNumber } from './utils.repository';
import { IBudgetTemplateLineItem } from '@models/budget-template-line-item.model';

const path = 'budget-templates';

export const budgetTemplateRepository = {
  getAll: async (queryParams: IQueryParamsGeneric) => {
    const params = new URLSearchParams({
      ...queryParams,
      cacheBuster: `${new Date().getTime()}`,
    });
    const requestUrl = `${path}?${params.toString()}`;
    return await http.get<IBudgetTemplate[]>(requestUrl);
  },
  getAllExtended: async (search: String) => {
    const params = new URLSearchParams({
      cacheBuster: `${new Date().getTime()}`,
    });
    const searchFilterParam = search ? '&search=' + search : '';
    const requestUrl = `budget-templates-extended?${params.toString()}${searchFilterParam}`;
    return await http.get<IBudgetTemplateExtended[]>(requestUrl);
  },
  getAllLineItems: async (budgetTemplateId: StringORNumber) => {
    const params = new URLSearchParams({
      cacheBuster: `${new Date().getTime()}`,
    });
    const budgetTemplateParams = '&budgetTemplateId.equals=' + budgetTemplateId;
    const requestUrl = `budget-template-line-items-extended?${params.toString()}${budgetTemplateParams}`;
    return await http.get<IBudgetTemplateLineItem[]>(requestUrl);
  },
  getTemplateById: async (id: StringORNumber) => {
    const params = new URLSearchParams({
      cacheBuster: `${new Date().getTime()}`,
    });
    const requestUrl = `budget-templates/${id}?${params.toString()}`;
    const promiseGetByID = await http.get<IBudgetTemplate>(requestUrl);
    return promiseGetByID;
  },
  saveTemplateComplete: async (template: IBudgetTemplateSave) => {
    const promise = await http.put<IBudgetTemplateSave>(`budget-templates-extended/${template?.id}/complete`, template);
    return promise;
  },
};
