/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronLeftSvgIcon } from "@components/Icons/ChevronLeftSvgIcon";
import { theme } from "antd";
import { useEffect, useState } from "react";
import { useAppSelector } from "@store/store";
import { isArrayWithValues } from "@shared/util/array-util";

export const FilePaginator = ({ selectedCameraId, setSelectedCameraId }) => {

    const [currentPage, setCurrentPage] = useState(0);
    const [totalCameras, setTotalCameras] = useState<number>(0);
    const [isPrevAllowed, setIsPrevAllowed] = useState(false);
    const [isNextAllowed, setIsNextAllowed] = useState(false);

    const { data: { oxBlueProjectEntity } } = useAppSelector((state) => state.OxBlueProjects);


    useEffect(() => {
        setIsPrevAllowed((currentPage <= totalCameras && currentPage > 1));
        setIsNextAllowed(currentPage < totalCameras && currentPage >= 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, totalCameras]);



    useEffect(() => {
        if (isArrayWithValues(oxBlueProjectEntity.cameras)) {
            const cameraIndex = (oxBlueProjectEntity.cameras) ? oxBlueProjectEntity.cameras.findIndex(camera => camera === selectedCameraId) : -1;
            if (cameraIndex !== -1) {
                setCurrentPage(Math.floor(cameraIndex) + 1);
            }
            setTotalCameras(Number(oxBlueProjectEntity.cameras?.length))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oxBlueProjectEntity, selectedCameraId])

    const onClickNext = () => {
        const currentCameraIdIndex = (oxBlueProjectEntity.cameras) ? oxBlueProjectEntity.cameras.findIndex(camera => camera === selectedCameraId) : -1;
        if (currentCameraIdIndex !== -1 && oxBlueProjectEntity?.cameras && oxBlueProjectEntity?.cameras[currentCameraIdIndex + 1]) {
            setSelectedCameraId(oxBlueProjectEntity?.cameras[currentCameraIdIndex + 1])
        }
    }

    const onClickPrev = () => {
        const currentCameraIdIndex = (oxBlueProjectEntity.cameras) ? oxBlueProjectEntity.cameras.findIndex(camera => camera === selectedCameraId) : -1;
        if (currentCameraIdIndex !== -1 && oxBlueProjectEntity?.cameras && oxBlueProjectEntity?.cameras[currentCameraIdIndex - 1]) {
            setSelectedCameraId(oxBlueProjectEntity?.cameras[currentCameraIdIndex - 1])
        }
    }


    const { token: { colorTextSecondary, colorTextBase, colorTextTertiary, colorFillTertiary, colorFillSecondary } } = theme.useToken();

    return (
        <>
            <div
                className="flex flex-row items-center pt-6 pr-6 pb-6 pl-6"
                style={{
                    borderRadius: 16,
                    border: `1px solid ${colorFillSecondary}`,
                    backgroundColor: colorFillTertiary,
                }}
            >
                <ChevronLeftSvgIcon
                    className={`ml-4 mr-4 ${isPrevAllowed ? "cursor-pointer" : "cursor-not-allowed"}`}
                    style={{ color: colorTextBase, opacity: isPrevAllowed ? 0.45 : 0.15 }}
                    onClick={onClickPrev}
                />
                <span className="mr-6 select-none" style={{ color: colorTextSecondary }} >{currentPage}</span>
                <span className="select-none" style={{ color: colorTextTertiary }}>of {totalCameras}</span>
                <ChevronLeftSvgIcon
                    className={`mr-4 ml-4 ${isNextAllowed ? "cursor-pointer" : "cursor-not-allowed"}`}
                    style={{ color: colorTextBase, opacity: isNextAllowed ? 0.45 : 0.15, transform: 'rotate(180deg)' }}
                    onClick={onClickNext}
                />
            </div>
        </>
    )
}
