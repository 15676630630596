import { CustomFieldGroupTypeListOptions } from '@models/enumerations/custom-field-group-type-enum.model';
import { http } from '../http/axios.instance';
import { ICustomFieldGroup } from '@models/custom-field-group.model';

const path = 'custom-field-groups';

export const customFieldGroupsRepository = {
    getEntities: async () => {
        const requestUrl = `custom-field-groups-extended?cacheBuster=${new Date().getTime()}`;
        return http.get<ICustomFieldGroup[]>(requestUrl);
    },
    create: async (entity: ICustomFieldGroup) => {
        return http.post<ICustomFieldGroup>(path, entity);
    },
    update: async (id: string | number, entity: ICustomFieldGroup) => {
        return http.put<ICustomFieldGroup>(`${path}/${id}`, entity);
    },
    reorder: async (referenceType: CustomFieldGroupTypeListOptions, projectStatusIds: number[]) => {
        const requestUrl = `custom-field-groups-extended/${referenceType}/reorder`;
        return await http.put(requestUrl, projectStatusIds);
    },
}