
import { isFulfilled, isPending } from '@reduxjs/toolkit';
import { IDeliverable, defaultValue } from "../../models/deliverable.model";
import { createEntitySlice, EntityState, serializeAxiosError } from "../reducer.util";
import { createAsyncThunk } from "@reduxjs/toolkit"
import { deliverablesService } from "../../services/deliverables.service";
import { IQueryParams } from "../../models/pagination";
import { cleanEntity, getTotalItems } from '../../shared/util/entity-utils';


export const getEntities = createAsyncThunk(
  'deliverable/fetch_entity_list', 
  async (queryParams: IQueryParams) => {
      return deliverablesService.getAll(queryParams);
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  'deliverable/fetch_entity',
  async (id: string | number) => {
    return deliverablesService.getById(id);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'deliverable/create_entity',
  async (entity: IDeliverable, thunkAPI) => {
    const result = await deliverablesService.create(cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'deliverable/update_entity',
  async (entity: IDeliverable, thunkAPI) => {
    const result = entity.id && await deliverablesService.update(entity.id, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'deliverable/delete_entity',
  async (id: string | number, thunkAPI) => {
    const result = await deliverablesService.delete(id);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

const initialState: EntityState<IDeliverable> = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

export const DeliverableSlice = createEntitySlice({
    name: 'deliverable',
    initialState,
    reducers: {
      clearEntity: (state,) => {
        state.entity = defaultValue;
      },
    },
    extraReducers(builder) {
        builder
          .addCase(getEntity.fulfilled, (state, action) => {
            state.loading = false;
            state.entity = action.payload.data;
          })
          .addMatcher(isFulfilled(getEntities), (state, action) => {
            const { data, headers } = action.payload;
            return {
              ...state,
              loading: false,
              entities: data,
              totalItems: getTotalItems(headers),
            };
          })
          .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
            state.updating = false;
            state.loading = false;
            state.updateSuccess = true;
            state.entity = action.payload as IDeliverable;
          })
          .addMatcher(isPending(getEntities, getEntity), state => {
            state.errorMessage = null;
            state.updateSuccess = false;
            state.loading = true;
          })
          .addMatcher(isPending(createEntity, updateEntity), state => {
            state.errorMessage = null;
            state.updateSuccess = false;
            state.updating = true;
          });
      }
  });
  
  export const { reset, clearEntity } = DeliverableSlice.actions;
  
  // Reducer
  export default DeliverableSlice.reducer;