/* eslint-disable react-hooks/exhaustive-deps */
import { IAsset } from "../../FloorPlanEditor/interfaces/interfaces";
import { theme } from "antd";
import { useEffect, useRef, useState } from 'react';
import { IFloorplanCallbacks, IFloorplanConfig, AssetType } from "floor-plan-editor-2d";
import { FloorPlanEditor } from "floor-plan-editor-2d";
import { useAppDispatch, useAppSelector } from "@store/store";
import { If } from "@components/Utils/Structural";
import { MetadataMarkerDrawer } from "../MatadataMarkerDrawer/MetadataMarkerDrawer";
import { useThemeProvider } from "@providers/ThemeProvider";
import { isArrayWithValues } from "@shared/util/array-util";
import { editPunchListItemPoints, hightlightPunchListItem, selectPunchListItem } from "@store/slices/punch-list";
import { DerivativesEnum } from "@models/enumerations/derivatives-enum.model";
import { IDerivatives } from "@models/attachment.model";
import { IPunchListItem } from "@models/punch-list.model";
import { getUniqueId } from "@infrastructure/repositories/utils.repository";
import { isStringOrNumber } from '@shared/util/validations';
import { ViewDetailsPunchListItemDrawer } from "../MatadataMarkerDrawer/ViewDetailsPunchListItemDrawer";
import { setNewItemMode } from '@store/slices/punch-list';
import useVisible from "@HOOKs/UseVisible";
import "./FloorPlanViewer.less";

const floorPlanViewerBgLightColor = "rgba(247, 245, 240, 1)";
const pixelsPerMeter = 100;
const drawerWidth = 480;

export interface IFloorPlanViewerProps {
    height: number;
    onMarkerCLick?: (asset: IAsset[] | null) => void;
    initialAssets?: IAsset[];
}

export function useFirstRender() {
    const firstRender = useRef(true);
  
    useEffect(() => {
      firstRender.current = false;
    }, []);
  
    return firstRender.current;
}

export const FloorPlanViewer = (props: IFloorPlanViewerProps) => {

    const  { height = 0 } = props;
    
    const dispatch = useAppDispatch();

    const { themeConfig } = useThemeProvider();
    
    const { token: { colorPrimaryBorderHover, colorBgContainer } } = theme.useToken();

    const [visiblMarkerDrawer, toggleVisiblMarkerDrawer] = useVisible();
    
    const [visibleDetailsMarkerDrawer, toogleVisibleDetailsMarkerDrawer] = useVisible();
    
    const [selectedAsset, setSelectedAsset] = useState<IAsset | null>(null);
    
    const [startAddAssetModeCursor, setStartAddAssetModeCursor] = useState<Boolean>(false);
    
    const [initialAssets, setInitialAssets] = useState<IAsset[] | []>([]);
    
    const { pageSelected, addNewItemMode } = useAppSelector(store => store.PunchList);

    const onAssetAdded  = (asset) => {
        const selection = asset;
        if (!(selection && asset.hasOwnProperty('data'))) { 
            asset && setSelectedAsset({
                ...asset,
                data: null
            });
    
            setTimeout(() => {
                dispatch(selectPunchListItem(null));
                toggleVisiblMarkerDrawer();
                removeAssetModeCursor();
                dispatch(setNewItemMode(false));
            }, 60);
        }

        if(floorPlanEditorIsReady && isStringOrNumber(selection?.data?.id)) {
            dispatch(hightlightPunchListItem(selection.data));
        }
    }

    const onAssetSimpleClick = (asset) => {

        asset && setSelectedAsset({
            ...asset,
            data: {
                ...asset.data,
                markerLocationX: asset.x,
                markerLocationY: asset.y,
            }
        });

        if(asset.data.id) {
            dispatch(hightlightPunchListItem(asset.data));

            if(asset?.data?.id) {
                dispatch(selectPunchListItem(asset.data));
            }
        }

        setTimeout(() => {
            toogleVisibleDetailsMarkerDrawer();
        }, 60)
    }

    const onAssetDragEnd = (asset: IAsset) => {
        dispatch(editPunchListItemPoints({
            punchListItem: {
                ...asset.data,
                markerLocationX: asset?.x || 0.1,
                markerLocationY: asset?.y || 0.1,
            }
        }))
    }

    let floorPlanEditor = useRef<FloorPlanEditor | undefined>();
    const floorPlanContainerRef = useRef<HTMLDivElement >(null);

    const [floorPlanEditorIsReady, setfloorPlanEditorIsReady] = useState<Boolean>(false);

    const initializeEditor = () => {

        const floorPlanInitialAssets: IAsset[] = [...initialAssets];
        
        const isEditMode = true;
        
        const floorplanCallbacks: IFloorplanCallbacks = {
            onAssetSimpleClick: onAssetSimpleClick,
            onAssetAdded: onAssetAdded,
            onAssetDragEnd: onAssetDragEnd,
        };

        const floorplanConfig: IFloorplanConfig = {
            backgroundColor: "transparent",
            defaultAssetColor: colorPrimaryBorderHover,
            backgroundImage: "",
            allowSingleSelection: true,
            allowMultipleAdd: false,
            dimensionsConfig: {
                pixelsPerMeter: pixelsPerMeter,
                officeWidth: 100,
                officeHeight: 67,
            },
            markerRadiusMeters: 0.50,
            strokeConfig: {
                width: 1.2 
            },   
            showFloorplanControls: {
                showMinimap: true,
                showZoomSlider: true,
                showDeleteButton: false,
                showResetButton: false,
                showClearButton: false,
                showMoveButtons: false,
                showRotateButtons: false,
                showDuplicateButton: false
            },
            allowedAssetTypes: []
        }

        if (floorPlanContainerRef?.current) {
            if (floorPlanContainerRef.current.innerHTML !== "") {
                floorPlanContainerRef.current.innerHTML = "";
            }
            floorPlanEditor.current = new FloorPlanEditor(
                floorPlanContainerRef.current,
                floorPlanInitialAssets,
                isEditMode,
                floorplanCallbacks,
                floorplanConfig
            );
        }

        setfloorPlanEditorIsReady(true);
    }

    useEffect(() => {
        
        initializeEditor();

        return () => {
            setfloorPlanEditorIsReady(false);
            dispatch(setNewItemMode(false));
            setInitialAssets([]);
            if (floorPlanEditor) {
                floorPlanEditor?.current?.destroy?.();
            }
        }
    }, []);

    const { punchList, punchListItemHightlighted } = useAppSelector(store => store.PunchList);

    const getAssets = (punchList: IPunchListItem[]) => {
        if(!punchList) { return [] }
        const assets = punchList.reduce((acc: IAsset[], item: IPunchListItem) => {
            acc.push({ 
                id: getUniqueId(),
                data: item, 
                color: colorPrimaryBorderHover,
                label: "",
                type: AssetType.MARKER,
                rotationAngle: 0,
                x: item.markerLocationX,
                y: item.markerLocationY
            });
            return acc
        }, []) || [];

        return assets
    }

    useEffect(() => {
        const assets = getAssets(punchList);
        setInitialAssets(assets);
        floorPlanEditor?.current?.finishAddAssetMode?.();
        setStartAddAssetModeCursor(false);
    }, [punchList]);
    
    useEffect(() => {
        const assets = getAssets(punchList);
        setInitialAssets(assets);
    }, [colorPrimaryBorderHover]);

    useEffect(() => {
        (floorPlanEditor.current && addNewItemMode)
            ? floorPlanEditor?.current?.startAddAssetMode?.(AssetType.MARKER)
            : floorPlanEditor?.current?.finishAddAssetMode?.();

        if (!addNewItemMode && floorPlanEditor) {
            setStartAddAssetModeCursor(false);
        }
    }, [addNewItemMode]);

    useEffect(() => {
        setStartAddAssetModeCursor(false);
        floorPlanEditorIsReady && floorPlanEditor?.current?.setAllAssets?.(initialAssets);
    }, [initialAssets]);

    useEffect(() => {
        if (floorPlanEditorIsReady && floorPlanEditor?.current ) {
            const derivativeFounded = pageSelected?.floorPlan?.derivatives?.find((derivative) => derivative.derivativeType === DerivativesEnum.PREVIEW) ?? {} as IDerivatives;

            if (derivativeFounded && derivativeFounded?.signedUrl) {
                const currentBackgroundImage = floorPlanEditor?.current?.getBackgroundImage() || undefined;
                if (currentBackgroundImage !== derivativeFounded?.signedUrl) {
                    floorPlanEditor?.current?.setBackgroundImage?.(derivativeFounded.signedUrl);
                }
            }
        }
    }, [pageSelected, floorPlanEditor]);

    useEffect(() => {
        const assetToSelect = [...initialAssets].find((asset) => asset?.data?.id === punchListItemHightlighted?.id);
        if (assetToSelect) {
            floorPlanEditor?.current?.setSelectedAsset([assetToSelect]);

            setTimeout(() => {    
                const newX = assetToSelect.x + ( ((drawerWidth / 2) / pixelsPerMeter) / (floorPlanEditor.current?.getCurrentZoomLevel() || 1) );   
                floorPlanEditor.current?.goTo(newX, assetToSelect.y, floorPlanEditor.current?.getCurrentZoomLevel());
                floorPlanEditor.current?.setBlinkingAssets([{ id: assetToSelect.id, color: colorPrimaryBorderHover }])
            }, 300);
            
        }
    }, [punchListItemHightlighted]);

    const destroyAssets = (assets: IAsset[]) => {
        const initialAssets = isArrayWithValues(assets) ? assets : [];
        const ids = (initialAssets.reduce((acc: unknown[], item: IAsset) => {
            item?.id && acc.push(item.id as unknown);
            return acc;
        }, [])) || [];
        
        floorPlanEditor?.current?.deleteAssets?.([...ids] as any[]);
    }

    const addAssetModeCursor = () => { 
        if (addNewItemMode) {
            setStartAddAssetModeCursor(true);
        }
    }

    const removeAssetModeCursor = () => { 
        if (!addNewItemMode) { 
            setStartAddAssetModeCursor(false);
        }
    }

    const onEditSuccess = (punchListItemModified) => {
        dispatch(selectPunchListItem(punchListItemModified));

        setTimeout(() => {
            dispatch(hightlightPunchListItem(punchListItemModified));
        }, 500);
    }

    return (
        <div className="relative">
            <div
                id="floorPlanContainer" 
                ref={floorPlanContainerRef}
                onMouseEnter={addAssetModeCursor}
                onMouseLeave={removeAssetModeCursor}
                style={{
                    width: `100%`, 
                    height: height,
                    background: themeConfig.darkMode ? colorBgContainer : floorPlanViewerBgLightColor,
                    cursor: startAddAssetModeCursor ? "crosshair" : "default"
                }}>
            </div>

            <If condition={visiblMarkerDrawer && !!selectedAsset}>
                <MetadataMarkerDrawer 
                    open={visiblMarkerDrawer} 
                    toogle={toggleVisiblMarkerDrawer} 
                    destroyAssets={destroyAssets} 
                    asset={selectedAsset!} 
                    onEditSuccess={onEditSuccess}
                />
            </If>

            <If condition={visibleDetailsMarkerDrawer && !!selectedAsset}>
                <ViewDetailsPunchListItemDrawer 
                    open={visibleDetailsMarkerDrawer}
                    toogle={toogleVisibleDetailsMarkerDrawer}
                    asset={selectedAsset!} 
                />
            </If>
        </div>
    )
}
