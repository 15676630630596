import { CloseOutlined } from "@ant-design/icons";
import { theme } from "antd";
import { HTMLProps } from "react";
import { useTranslation } from "react-i18next";

interface ICloseButtonRoundedProps extends HTMLProps<HTMLDivElement> {}

export const CloseButtonRounded = (props: ICloseButtonRoundedProps) => {

    const { children, style, ...restProps  } = props;

    const { token : { colorBgLayout, colorBorderSecondary }} = theme.useToken();
    const { t } = useTranslation();
    
    return (
        <div
            title={`${t("generic.close")}`}
            className="flex flex-row items-center justify-center absolute cursor-pointer"
            style={{
                width: "2.5rem",
                height: "2.5rem",
                backgroundColor: colorBgLayout,
                border: `1px solid ${colorBorderSecondary}`,
                top: '10px',
                right: '16px',
                borderRadius: '50%',
                ...style
            }}
            {...restProps}
        >
            <CloseOutlined className="text-color-neutral-8"/>
        </div>
    )
}
