import { FC } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { DragHandle } from "@components/Icons/DragHandle";
import { Button, theme } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "@styles/components/template-edit.less";
import { IDeliverable } from '@models/deliverable.model';
import SvgIconWrapper from '@shared/components/SvgIconWrapper';
import { KeySvgIcon } from '@components/Icons/KeyIcon';
import { useTranslation } from "react-i18next";

interface SystemDeliverableProps {
    id: string | number;
    deliverable: IDeliverable;
    existsInTemplate: boolean;
    addDeliverableToTemplate?(id: string): void;
}

const IconKeyMilestoneInDeliverableCard = () => {
    const { token: { fontSizeSM, colorTextTertiary } } = theme.useToken();
    return (
        <SvgIconWrapper className="flex flex-row items-center justify-center" backgroundColor={colorTextTertiary} >
            <KeySvgIcon style={{ color: '#fff', fontSize: fontSizeSM }} />
        </SvgIconWrapper>
    )
}

export const DeliverableCard: FC<SystemDeliverableProps> = ({ deliverable, existsInTemplate }) => {

    const { token: {
        colorText,
        colorFillSecondary,
        colorTextTertiary } } = theme.useToken();
    
    const { t } = useTranslation();

    return <div className={'flex flex-row deliverable-card ' + (existsInTemplate ? 'already-added' : '')}>
        <div className="flex flex-col left-col">
            <div className="title-row">
                <div className="left-col-title" style={{ color: colorText }}>
                    {deliverable.name}
                </div>
                {deliverable?.isKeyMilestone && <div className="left-col-star">
                    <IconKeyMilestoneInDeliverableCard />
                </div>}
            </div>
            <div className="discipline-row" style={{ color: colorTextTertiary }}>
                <span>{t('admin.discipline')}: </span> {deliverable?.discipline?.name}
            </div>
        </div>
        <div className="right-col" style={{ borderLeft: `1px solid ${colorFillSecondary}` }}>
            <DragHandle />
        </div>
    </div>
}

export const SystemDeliverable: FC<SystemDeliverableProps> = ({ id, deliverable, existsInTemplate, addDeliverableToTemplate }) => {

    const {
        attributes,
        listeners,
        setNodeRef,
    } = useDraggable({
        id,
    });

    const { token: {
        colorText,
        colorPrimaryBg,
        colorFillSecondary,
        colorTextTertiary,
        colorPrimaryText } } = theme.useToken();

    const { t } = useTranslation();

    return (
        <li ref={setNodeRef} style={{ listStyle: 'none' }} {...attributes}>
            <div className={'flex flex-row deliverable-card ' + (existsInTemplate ? 'already-added' : '')}>
                <div className="flex flex-col left-col">
                    <div className="title-row">
                        <div className="left-col-title" style={{ color: colorText }}>
                            {deliverable.name}
                        </div>
                        {deliverable?.isKeyMilestone && <div className="left-col-star">
                            <IconKeyMilestoneInDeliverableCard />
                        </div>}
                    </div>
                    {deliverable?.discipline && <div className="discipline-row" style={{ color: colorTextTertiary }}>
                        <span>{t('admin.discipline')}: </span> {deliverable?.discipline?.name}
                    </div>}
                    {deliverable?.role && <div className="role-row" style={{ color: colorTextTertiary }}>
                        <span>Role: </span> {deliverable?.role?.name}
                    </div>}
                    {deliverable?.isOpeningMilestone && <div>
                        <div className="opening-milestone">Opening Milestone</div>
                    </div>}
                    <div className="already-on-template-msg" style={{ background: colorPrimaryBg, color: colorPrimaryText }}>
                        Already on this template
                    </div>
                    <div className="add-to-template">
                        <Button type='primary' onClick={() => { addDeliverableToTemplate ? addDeliverableToTemplate(`${id}`) : void 0 }}>
                            <PlusOutlined />
                            Add to template
                        </Button>
                    </div>
                </div>
                <div className="right-col" {...listeners} style={{ borderLeft: `1px solid ${colorFillSecondary}` }}>
                    <DragHandle />
                </div>
            </div>
        </li>
    );
}
