import { useCallback, useEffect, useState } from "react";
import { Col, Row, Table, Tag, theme, ConfigProvider } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { useAppDispatch, useAppSelector } from "@store/store";
import { deleteEntity, getEntities }  from "@store/slices/acl-permission";
import { IAclPermission } from "@models/acl-permission.model";
import { defaultSorter } from "@shared/util/table-utils";
import { EmptyCellTable } from "@components/Utils/EmptyCellTable";

interface IPermissionListParams {
    permissionId: any
}

export const PermissionsList = (params: IPermissionListParams) => {

    const { token: { colorBorderSecondary } } = theme.useToken();

    const dispatch = useAppDispatch();

    const { entities, totalItems, loading } = useAppSelector((state) => state.AclPermissions);

    const [tableParams, setTableParams] = useState({
        size: 10,
        current: 0,
        sort: `referenceType,asc`
      });
  
    useEffect(() => {
        dispatch(
            getEntities({ queryParams: tableParams, aclTargetId: params.permissionId })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableParams, params]);

    const permissionTableColumns: ColumnsType<IAclPermission> = [
        {
            sorter: ({ referenceType: a }, {referenceType: b}) => defaultSorter(a, b),
            render: (permission: IAclPermission) => {
                return (
                    <Tag color={'blue'} style={{ textTransform: 'capitalize' }}>{`${permission.referenceType}`.toLowerCase()}</Tag>
                ) || <EmptyCellTable/> 
            },
            title: 'Type'
        },
        {
            render: (permission: IAclPermission) => {
                return (
                    <span>{permission.referenceName}</span>
                ) || <EmptyCellTable/> 
            },
            title: 'Name'
        },
        {
            render: (permission: IAclPermission) => {
                return (
                    <span className="cursor-pointer" style={{ color: 'red' }} onClick={() => { 
                        dispatch(deleteEntity(permission))
                    }}>Remove</span>
                )
            }
        }
    ];

    const TableComponentWrapper = useCallback((node: any) => {
      const style = { border: `1px solid ${colorBorderSecondary}`, borderRadius: 4 }
      return (
          <table style={{ ...node.style, ...style }}>
              { node.children.map((child: any) => child) }
          </table>
      ); 
    }, [colorBorderSecondary]);

    const onChange: TableProps<IAclPermission>['onChange'] = (pagination, filters, sorter, extra) => {
      setTableParams((oldValue) => { 
          return {
              ...oldValue, 
              ...pagination?.pageSize && { size: pagination.pageSize },
              ...filters,
              current: pagination.current !== undefined ? pagination.current - 1 : 0
          }
      })
    };

    return <>
        <Row className="mt-10">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={20} >
                <ConfigProvider theme={{ token: { borderRadius: 2 } }}>
                    <Table 
                        loading={loading}
                        rowKey="id" 
                        size="small" 
                        columns={permissionTableColumns} 
                        dataSource={entities} 
                        onChange={onChange}
                        components={{
                            table: TableComponentWrapper
                        }}
                        pagination={ { current: tableParams.current + 1, pageSize: tableParams.size, total: totalItems }}
                    />
                </ConfigProvider>
            </Col>
        </Row>
    </>;
}