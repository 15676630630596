import { IProjectDeliverable } from "@models/project-deliverable";
import { getStatusLabel, statusMap } from "./ProjectDeliverable.mapper";
import dayjs from "dayjs";
export class ProjectDeliverableGanttMapper  {

  public static toDomain (response: IProjectDeliverable[]) {
    const result = new Promise((resolve, reject) => {
      try {

        const headerGanntProjectProps = {
          success: true,
          project: {
            "calendar": "general",
            "hoursPerDay": 24,
            "daysPerWeek": 5,
            "daysPerMonth": 20
          },
        }

        let taskRows: any[] = [];
        let dependenciesRows: any[] = [];

        response.forEach((deliverable, index ) => { 
          let startDate = deliverable?.actualStartDate ? deliverable.actualStartDate : deliverable?.plannedStartDate ? deliverable.plannedStartDate : null 
          let endDate = deliverable?.actualFinishDate ? deliverable.actualFinishDate : deliverable?.plannedFinishDate ? deliverable.plannedFinishDate : null 

          taskRows.push(
            {
              "id": deliverable?.id,
              "name": deliverable.deliverable?.name,
              "percentDone": 0,
              "startDate" : startDate,
              "endDate": endDate ? dayjs(endDate).endOf('day').toISOString() : "",
              "style": "background:gray",
              "durationUnit": "day",
              "effortUnit": "day",
              "rollup": false,
              "manuallyScheduled": true,
              "expanded": true,
              extraData: {
                ...deliverable,
                statusLabel: deliverable.status ? getStatusLabel(deliverable.status as keyof typeof statusMap): ""
              }
            }
          )
          
          if (deliverable?.dependency?.id) {
            dependenciesRows.push({
              id: index,
              fromTask: deliverable.dependency?.id,
              toTask: deliverable.id,
            })
          }

        });

        resolve({
          ...headerGanntProjectProps,
          tasks: [...taskRows],
          dependencies: dependenciesRows
        });

      } catch (error: any) {
        reject(error);
      }
    })
    return result as Promise<any>;
  }

}