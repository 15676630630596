import { ICustomFieldBinding } from './../../models/custom-field-binding.model';
import { ICustomFieldGroup, ICustomFieldGroupExtended } from '../../models/custom-field-group.model';
import { http } from '../http/axios.instance';
import { ICustomField, ICustomFieldsGetRequest } from '@models/custom-field.model';
import { ICustomFieldGroupExtendedUI } from '@models/custom-field-group.model';
import { CustomFieldExtendedMapper } from '@mappers/CustomFieldExtended.Mapper';


export const customFieldsRepository = {

  getCustomFieldsGroupsByLocationId: async (id: string) => {
    const requestUrl = `locations/${id}/get-custom-fields-groups-by-location-id`;
    const customFieldsGroups = await http.get<ICustomFieldGroup[]>(requestUrl);
    return customFieldsGroups;
  },

  getCustomFieldBindingListOfLocation: async (id: string) => {
    const requestUrl = `locations/${id}/get-custom-fields-bindings-by-location-id`;
    const customFieldsGroups = await http.get<ICustomFieldBinding[]>(requestUrl);
    return customFieldsGroups;
  },

  createCustomFieldBinding: async (customFieldBinding: ICustomFieldBinding) => {
    const requestUrl = `custom-field-bindings`;
    const created = await http.post<ICustomFieldBinding>(requestUrl, customFieldBinding);
    return created;
  },

  updateCustomFieldBinding: async (customFieldBinding: ICustomFieldBinding) => {
    const requestUrl = `custom-field-bindings/${customFieldBinding.id}`;
    const created = await http.put<ICustomFieldBinding>(requestUrl, customFieldBinding);
    return created;
  },

  getCustomFieldBindingListByReferenceId: async (referenceId: number | undefined, referenceType: string) => {
    const requestUrl = `custom-field-bindings?referenceId.equals=${referenceId}&referenceType.equals=${referenceType}`;
    const customFieldsGroups = await http.get<ICustomFieldBinding[]>(requestUrl);
    return customFieldsGroups;
  },


  getCustomFieldsData: async (payload: ICustomFieldsGetRequest): Promise<ICustomFieldGroupExtendedUI[]> => {
    const { referenceType, referenceId } = payload;

    const requestUrl = `custom-fields-extended?referenceType=${referenceType}&referenceId=${referenceId}`;
    const customFields = await http.get<ICustomFieldGroupExtended[]>(requestUrl);

    const result = CustomFieldExtendedMapper.toDomain(customFields.data)
    return result;
  },
  saveOrUpdateCustomFieldsGroup: async (payload: ICustomFieldGroupExtendedUI) => {
    const requestUrl = `custom-fields-extended`;
    const result = await http.post(requestUrl, payload);
    return result.data;
  },
  reorder: async (customFieldGroupId: number | string, customFieldIds: number[]) => {
    const requestUrl = `custom-fields-extended/${customFieldGroupId}/reorder`;
    return await http.put(requestUrl, customFieldIds);
  },
  create: async (entity: ICustomField) => {
    return http.post<ICustomField>(`custom-fields`, entity);
  },
  update: async (id: string | number, entity: ICustomField) => {
      return http.put<ICustomField>(`custom-fields/${id}`, entity);
  },
  getCustomFieldsForGroup: async (customFieldGroupId: number | string) => {
    const requestUrl = `custom-fields?customFieldGroupId.equals=${customFieldGroupId}&cacheBuster=${new Date().getTime()}`;
    return await http.get<ICustomField[]>(requestUrl);
  },
}