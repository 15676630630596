import { IBudgetLineItemCostUpdateRequest, IBudgetLineItemCostUpdateResponse } from '@models/budget.model';
import { StringORNumber } from './utils.repository';
import { http } from '../http/axios.instance';

const path = 'budget-line-items-extended';

export const budgetLineItemRepository = {
  update: async (id: StringORNumber, data: IBudgetLineItemCostUpdateRequest): Promise<IBudgetLineItemCostUpdateResponse> => {
    const requestUrl = `${path}/${id}`;
    const response = await http.patch<IBudgetLineItemCostUpdateResponse>(requestUrl, data);
    return response.data;
  },
};
