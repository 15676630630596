import { theme } from "antd";
import { useRef } from "react";
import useHover from "../../HOOKs/UseHover";
import { componentProps } from "./Utils";

interface IProps extends componentProps {
  width?: string,
  height?: string
  iconStyles?: React.CSSProperties
}

export const SoundMusicOutlinedIcon: React.FC<IProps> = ({ className = "", width, height, iconStyles }) => {
  const arrowhoverRef = useRef(null);
  const isHoverInArrow = useHover(arrowhoverRef);
  const { token: { colorPrimaryTextHover, colorTextBase} } = theme.useToken();

  const defaultStyle: React.CSSProperties = {
      color: isHoverInArrow
      ? colorPrimaryTextHover
      : colorTextBase,
  }

  return (
      <svg 
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="4955"
          className={`${className}`}
          width={width ? width : "1rem"}
          height={height ? height: "1rem"}
          ref={arrowhoverRef}
          style={{ ...defaultStyle, ...iconStyles }}>

          <path fill="currentColor"  d="M789.333333 298.666667a170.666667 170.666667 0 0 1-170.666666-170.666667h-85.333334v416.426667A192 192 0 1 0 618.666667 704V318.506667A256 256 0 0 0 789.333333 384zM426.666667 810.666667a106.666667 106.666667 0 1 1 106.666666-106.666667 106.666667 106.666667 0 0 1-106.666666 106.666667z" p-id="4955"></path>
  
      </svg>
  );
};
