import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ModalFuncProps } from "antd";

export interface INotificationState {
  readyToLaunch: boolean,
  open: boolean,
  settings: Partial<ModalFuncProps>
}

const initialState: INotificationState = {
  readyToLaunch: false,
  open: false,
  settings: {}
};

export const asyncLaunchDisabledAccountNotification = createAsyncThunk(
  'disabledAccountNotification/asyncLaunchDisabledAccountNotification',
  async (_: any, thunkApi) => {
    return _;
  }
)

export const DisabledAccountNotificationSlice = createSlice({
    name: 'disabledAccountNotification',
    initialState,
    reducers: {
      close: (state,) => {
        state.open = false;
      },
    },
    extraReducers(builder) {
      builder
        .addCase(asyncLaunchDisabledAccountNotification.pending, (state, action) => {
            if (action?.meta?.arg) {
              state.settings = {
                ...state.settings,
                ...action?.meta?.arg
              }
            }
            state.readyToLaunch = true
        })
        .addCase(asyncLaunchDisabledAccountNotification.fulfilled, (state, action) => {
          state.readyToLaunch = false
          state.open = true;
        })
    }     
});

export const { close } = DisabledAccountNotificationSlice.actions;
  
// Reducer
export default DisabledAccountNotificationSlice.reducer;