import { SVGProps } from 'react';

type IProps = SVGProps<SVGSVGElement>;

export const CashSvgIcon = (props: IProps) => {
  const { ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" {...restProps}>
      <path
        d="M5.5 6H23.5V18H5.5V6ZM14.5 9C15.2956 9 16.0587 9.31607 16.6213 9.87868C17.1839 10.4413 17.5 11.2044 17.5 12C17.5 12.7956 17.1839 13.5587 16.6213 14.1213C16.0587 14.6839 15.2956 15 14.5 15C13.7044 15 12.9413 14.6839 12.3787 14.1213C11.8161 13.5587 11.5 12.7956 11.5 12C11.5 11.2044 11.8161 10.4413 12.3787 9.87868C12.9413 9.31607 13.7044 9 14.5 9ZM9.5 8C9.5 8.53043 9.28929 9.03914 8.91421 9.41421C8.53914 9.78929 8.03043 10 7.5 10V14C8.03043 14 8.53914 14.2107 8.91421 14.5858C9.28929 14.9609 9.5 15.4696 9.5 16H19.5C19.5 15.4696 19.7107 14.9609 20.0858 14.5858C20.4609 14.2107 20.9696 14 21.5 14V10C20.9696 10 20.4609 9.78929 20.0858 9.41421C19.7107 9.03914 19.5 8.53043 19.5 8H9.5ZM1.5 10H3.5V20H19.5V22H1.5V10Z"
        fill="#1890FF"
      />
    </svg>
  );
};
