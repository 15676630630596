import { useState } from 'react';
import { $getRoot } from 'lexical';

import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';

import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";

import ToolbarPlugin from './Plugins/Toolbar';
import { Can } from '@components/Permisions/Can';
import { Button, ConfigProvider, Row } from 'antd';

import { EditorState } from 'lexical';
import MentionsPlugin from './Plugins/MentionsPlugin';
import { MentionNode } from './Nodes/MentionNode';

import { theme as themeAntd } from "antd";
import { CommentReferenceEnum } from '@models/enumerations/comment-reference-enum.model';
import { useAppDispatch } from '@store/store';
import { createCommentRichText } from '@store/slices/comment-v2';
import { DEFAULT_COLOR, WHITE, useThemeProvider } from '@providers/ThemeProvider';

import "./RichTextEditor.less"
import { isFulfilled } from '@reduxjs/toolkit';
import { getProjectDeliverablesData } from '@store/slices/project-deliverables';
import { useParams } from 'react-router-dom';

const theme = {
    ltr: "ltr",
    rtl: "rtl",
    placeholder: "editor-placeholder",
    paragraph: "editor-paragraph",
    quote: "editor-quote",
    heading: {
        h1: "editor-heading-h1",
        h2: "editor-heading-h2",
        h3: "editor-heading-h3",
        h4: "editor-heading-h4",
        h5: "editor-heading-h5"
    },
    list: {
        nested: {
            listitem: "editor-nested-listitem"
        },
        ol: "editor-list-ol",
        ul: "editor-list-ul",
        listitem: "editor-listitem"
    },
    image: "editor-image",
    link: "editor-link",
    text: {
        bold: "editor-text-bold",
        italic: "editor-text-italic",
        overflowed: "editor-text-overflowed",
        hashtag: "editor-text-hashtag",
        underline: "editor-text-underline",
        strikethrough: "editor-text-strikethrough",
        underlineStrikethrough: "editor-text-underlineStrikethrough",
        code: "editor-text-code"
    },
    code: "editor-code",
    codeHighlight: {
        atrule: "editor-tokenAttr",
        attr: "editor-tokenAttr",
        boolean: "editor-tokenProperty",
        builtin: "editor-tokenSelector",
        cdata: "editor-tokenComment",
        char: "editor-tokenSelector",
        class: "editor-tokenFunction",
        "class-name": "editor-tokenFunction",
        comment: "editor-tokenComment",
        constant: "editor-tokenProperty",
        deleted: "editor-tokenProperty",
        doctype: "editor-tokenComment",
        entity: "editor-tokenOperator",
        function: "editor-tokenFunction",
        important: "editor-tokenVariable",
        inserted: "editor-tokenSelector",
        keyword: "editor-tokenAttr",
        namespace: "editor-tokenVariable",
        number: "editor-tokenProperty",
        operator: "editor-tokenOperator",
        prolog: "editor-tokenComment",
        property: "editor-tokenProperty",
        punctuation: "editor-tokenPunctuation",
        regex: "editor-tokenVariable",
        selector: "editor-tokenSelector",
        string: "editor-tokenSelector",
        symbol: "editor-tokenProperty",
        tag: "editor-tokenProperty",
        url: "editor-tokenOperator",
        variable: "editor-tokenVariable"
    }
}

export interface IEditorProps {
    defaultValue?: string,
    showSend: boolean,
    entityType?: CommentReferenceEnum,
    referenceId?: number,
    placeholderText: string,
    mentionsDisabled?: boolean,
    onChange?: (value: string) => void,
    onSuccessCreateComment?: () => void
}

export const RichTextEditor = (props: IEditorProps) => {

    const { defaultValue, showSend, entityType, referenceId, placeholderText, mentionsDisabled, onSuccessCreateComment } = props;

    const [disableSend, setDisableSend] = useState(true)

    // When the editor changes, you can get notified via the
    // LexicalOnChangePlugin!
    const onChange = (editorState: EditorState) => {
        let emptyText = true;

        editorState.read(() => {
            const root = $getRoot();

            if (root) {
                if (root !== null && root?.isEmpty()) {
                    emptyText = true;
                } else {
                    emptyText = false;
                }
            }
        });

        if (props.onChange) {
            const json = editorState.toJSON();
            props.onChange(emptyText ? '' : JSON.stringify(json));
        }

        if (showSend) {
            setDisableSend(emptyText);
        }
    }

    // Catch any errors that occur during Lexical updates and log them
    // or throw them as needed. If you don't throw them, Lexical will
    // try to recover gracefully without losing user data.
    const onError = (error: any) => { console.error(error); }

    const Placeholder = () => {
        return <div className="editor-placeholder" style={{ color: colorTextTertiary }}>{placeholderText}</div>;
    }

    const initialConfig = {
        namespace: 'CommentsEditor',
        theme,
        editorState: defaultValue,
        onError,
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode,
            MentionNode
        ]
    };

    const { themeConfig } = useThemeProvider();
    const { token: { colorBgContainer, colorText, colorTextTertiary, colorBorder } } = themeAntd.useToken();

    return (
        <>
            <LexicalComposer initialConfig={initialConfig}>
                <div className="editor-container" style={{ border: `1px solid ${colorBorder}`, borderRadius: 4 }}>
                    <ConfigProvider theme={{ token: { colorTextBase: themeConfig.darkMode ? WHITE : DEFAULT_COLOR } }}>
                        <ToolbarPlugin />
                    </ConfigProvider>
                    <div className="editor-inner" style={{ backgroundColor: colorBgContainer, color: colorText, borderBottomLeftRadius: 3, borderBottomRightRadius: 3 }}>
                        <RichTextPlugin
                            contentEditable={<ContentEditable className='editor-input' />}
                            placeholder={Placeholder}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                        { !mentionsDisabled && <MentionsPlugin /> }
                        <OnChangePlugin onChange={onChange} />
                        <HistoryPlugin />
                        <ListPlugin />
                        <LinkPlugin />
                    </div>
                </div>
                <>
                    {showSend && entityType && referenceId &&
                        <Row justify={"end"} className='mt-10'>
                            <ButtonSend entityType={entityType} referenceId={referenceId} disableSend={disableSend} onSuccessCreateComment={onSuccessCreateComment} />
                        </Row>}
                </>
            </LexicalComposer>
        </>
    );
}

export interface IButtonSendProps {
    entityType: CommentReferenceEnum
    referenceId: number,
    disableSend: boolean,
    onSuccessCreateComment?: () => void
}

export const ButtonSend = (props: IButtonSendProps) => {

    const [editor] = useLexicalComposerContext();

    const { entityType: referenceType, referenceId, disableSend, onSuccessCreateComment } = props;

    const dispatch = useAppDispatch();

    const { projectId } = useParams<"projectId">();

    const onSuccess = () => {
        if (onSuccessCreateComment) {
            onSuccessCreateComment?.();
        } else {
            dispatch(getProjectDeliverablesData(String(projectId)));
        }
    };

    const sendComment = () => {
        if (disableSend) return;

        const editorState = editor.getEditorState();
        const json = editorState.toJSON();

        dispatch(createCommentRichText({ referenceType, referenceId, payload: JSON.stringify(json), onSuccess })).then((response) => {
            if (isFulfilled(response)) {
                editor.update(() => {
                    $getRoot().clear();
                });
            }
        })
    }

    return (
        <Can I="CREATE" A="group.oak.siterise.service.dto.RichCommentDTO">
            <Button type="primary" htmlType="submit" onClick={() => sendComment()} disabled={disableSend}>
                Send
            </Button>
        </Can>
    )
}