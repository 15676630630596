import { FolderOpenOutlined, MessageOutlined } from "@ant-design/icons";
import { ChevronRightSvgIcon } from "@components/Icons/ChevronRightSvgIcon";
import { CustomButtonText } from "@components/Utils/CustomButtonText";
import { If } from "@components/Utils/Structural";
import { IPunchListItem } from "@models/punch-list.model";
import { formatDatePipe, formatDatetimeWithAmPm, generateUniqueStringWithTimestamp } from "@shared/util/date-utils";
import { Card, Col, Row, Tooltip, Typography, theme } from "antd";
import { PunchListItemRichText } from "./ViewerRichText/DescriptionPunchListItemRichText";
import { isNumber } from '@shared/util/number-util';
import { CategoryBadge, THEME_BADGE_ENUM } from "../../CategoryBadge";
import { isStringWithContent } from "@shared/util/validations";
import { useAppSelector } from "@store/store";
import "./PunchListItem.less"

interface IPunchListItemProps {
  punchListItem: IPunchListItem,
  viewMoreDetails?: (punchListItem: IPunchListItem) => void
}

const { Title } = Typography;

export const PunchListItem = (props: IPunchListItemProps) => {

    const { punchListItem, viewMoreDetails } = props;

    const { punchListItemHightlighted } = useAppSelector(store => store.PunchList);

    const { token: { colorPrimaryHover, colorBgContainer, padding }} = theme.useToken();

    const onViewMoreDetails = () => {
      viewMoreDetails?.(punchListItem);
    }
    
    return (
      <>
        <Card
          style={{ 
            border: `1px solid ${colorPrimaryHover}`, 
            outline: `${punchListItemHightlighted?.id === punchListItem.id ? 2 : 0}px solid ${colorPrimaryHover}`, 
            borderRadius: 8,
            background: colorBgContainer,
          }}
          bodyStyle={{ padding }}
          hoverable={true}
        >
          <Row wrap={false}>
            <Col flex="auto">
              <Row className="justify-between">
                <div className="flex flex-row items-center">

                  {/* Number Title */}
                  <Title 
                    className="text-color-neutral-8 mt-0 imb-0 pr-12" 
                    level={5} 
                    style={{ fontWeight: 400 }} 
                    ellipsis={true}
                  >
                      #{punchListItem.punchlistNumber}
                  </Title>
                  <If condition={isStringWithContent(punchListItem?.punchlistCategory?.description)} >
                    <CategoryBadge 
                      category={`${punchListItem?.punchlistCategory?.description}`} 
                      themeUI={THEME_BADGE_ENUM.THEME_SMALL}
                    />
                  </If>
                </div>
                
                <div>
                  {/* Date */}
                  <Tooltip title={formatDatetimeWithAmPm(punchListItem?.targetCompletionDate)}>
                    <span className="text-color-neutral-6 mr-10">
                      { formatDatePipe(punchListItem?.targetCompletionDate) }
                    </span>
                  </Tooltip>
                </div>
              </Row>
            </Col>
          </Row>

          {/* Description */}
          <Row className="pt-20">
            <Col>
              <div className="text-color-neutral-7 mr-10 content-rich-text" key={generateUniqueStringWithTimestamp()}>
                { punchListItem?.description && <PunchListItemRichText payload={punchListItem?.description} />}
              </div>
            </Col>
          </Row>

          <Row justify={"space-between"} className="mt-10">
            {/* Comments ands Files */}
            <Col className="flex flex-row items-center">
              <div className="flex flex-row items-center justify-center">
                    <If condition={(isNumber(punchListItem?.totalAttachments) && punchListItem?.totalAttachments! > 0)} >
                        <div className='flex flex-row items-center'>
                            <span className='pr-5'>{ `${isNumber(punchListItem?.totalAttachments) ? punchListItem.totalAttachments : "0"}` }</span>
                            <FolderOpenOutlined className="text-color-neutral-6" style={{ fontSize: 18 }} />
                        </div>
                    </If>
                    <If condition={(isNumber(punchListItem?.totalComments) && punchListItem?.totalComments! > 0)} >
                        <div className='pl-20 flex flex-row items-center'>
                            <span className="mr-5">{ `${isNumber(punchListItem.totalComments) ? punchListItem.totalComments: "0" }` }</span>
                            <MessageOutlined className="text-color-neutral-6" style={{ fontSize: 18 }} />
                        </div>
                    </If>
                </div>
            </Col>

            {/* Button "View More" */}
            <Col>
              <CustomButtonText onClick={onViewMoreDetails}> 
                <ChevronRightSvgIcon className="mr-10" />
                View More
              </CustomButtonText>
            </Col>
          </Row>

        </Card>
      </>
    )
}
