import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { punchListService } from '../../services/punch-list.service';
import { ICreatePunchListPagesWithPreviewsPagesSelectedRequest, IEditPunchListItemStatusRequest, IPunchListCheckGenerationPreviewsRequest, IPunchListItem, IPunchListItemCategory, IPunchListItemPageUI, IPunchListItemStatus, IPunchListItemsFilters, IPunchListPageFilters } from '@models/punch-list.model';
import { isArrayWithValues } from '@shared/util/array-util';
import { serializeGenericHandleError } from '@store/thunk.util';
import { isNumber } from '@shared/util/number-util';
import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { isStringOrNumber } from '@shared/util/validations';
import { isBoolean } from '../../shared/util/validations';

const nameOfEntity = "punchList";
const service = punchListService;

export const getPunchListPage = createAsyncThunk(
`${nameOfEntity}/getPunchListPage`,
  async (filters: IPunchListPageFilters, thunkAPI) => {
    return service.getPages(filters);
  },
  { serializeError: serializeGenericHandleError }
);

export const getPuchListItems = createAsyncThunk(
`${nameOfEntity}/getPuchListItems`,
  async (filters: IPunchListItemsFilters, thunkAPI) => {
    return service.getAll(filters);
  },
  { serializeError: serializeGenericHandleError }
);

export const getPuchListItemById = createAsyncThunk(
  `${nameOfEntity}/getPuchListItemById`,
    async (id: StringORNumber, thunkAPI) => {
      return service.getById(id)
    },
    { serializeError: serializeGenericHandleError }
  );

export const addPunchListItem = createAsyncThunk(
    `${nameOfEntity}/addPunchListItem`,
    async (data: { punchListItem: IPunchListItem }, thunkAPI) => {
        const { punchListItem } = data;
        return service.addPunchListItem({ punchListItem });
    },
    { serializeError: serializeGenericHandleError }
);

export const editPunchListItem = createAsyncThunk(
    `${nameOfEntity}/editPunchListItem`,
    async (data: { punchListItem: IPunchListItem }, thunkAPI) => {
        const { punchListItem } = data;
        return service.editPunchListItem({ punchListItem });
    },
    { serializeError: serializeGenericHandleError }
);

export const editPunchListItemStatus = createAsyncThunk(
  `${nameOfEntity}/editPunchListItemStatus`,
  async (data: IEditPunchListItemStatusRequest, thunkAPI) => {
      await service.editPunchListItemStatus(data);
      return service.getById(data.id);
  },
  { serializeError: serializeGenericHandleError }
);

export const editPunchListItemPoints = createAsyncThunk(
    `${nameOfEntity}/editPunchListItemPoints`,
    async (data: { punchListItem: IPunchListItem }, thunkAPI) => {
        const { punchListItem } = data;
        const punchListItemModified = await service.editPunchListItem({ punchListItem });
        if (punchListItemModified?.id) {
          thunkAPI.dispatch(selectPunchListItem(punchListItemModified));
        }
        return punchListItemModified;
    },
    { serializeError: serializeGenericHandleError }
);

export const getPunchListCategories = createAsyncThunk(
  `${nameOfEntity}/getPuchListCategories`,
    async (_, thunkAPI) => {
      return service.getCategories();
    },
    { serializeError: serializeGenericHandleError }
);

export const getPuchListStatuses = createAsyncThunk(
  `${nameOfEntity}/getPuchListStatuses`,
    async (_, thunkAPI) => {
      return service.getStatuses();
    },
    { serializeError: serializeGenericHandleError }
);

export const punchListCheckGenerationPreviews = createAsyncThunk(
  `${nameOfEntity}/getPuchpunchListCheckGenerationPreviewsListStatuses`,
    async (punchListGeneration: IPunchListCheckGenerationPreviewsRequest, thunkAPI) => {
      return service.punchListCheckGenerationPreviews(punchListGeneration);
    },
    { serializeError: serializeGenericHandleError }
);

export const createPunchListPagesWithPreviewsPagesSelected= createAsyncThunk(
  `${nameOfEntity}/createPunchListPagesWithPreviewsPagesSelected`,
    async (request: ICreatePunchListPagesWithPreviewsPagesSelectedRequest, thunkAPI) => {
      return service.punchListCreatePages(request);
    },
    { serializeError: serializeGenericHandleError }
);

interface IinitialState {
  loading: boolean,
  addNewItemMode: boolean,
  pageSelected?: IPunchListItemPageUI,
  punchList: IPunchListItem[],
  punchListItemHightlighted: IPunchListItem | null,
  selectPunchListItem?: IPunchListItem | null,
  punchListItemSelected?: IPunchListItem,
  punchListPages: IPunchListItemPageUI[]
  punchListCategories: IPunchListItemCategory[],
  punchListStatuses: IPunchListItemStatus[],
}

const initialState: IinitialState = {
  loading: false,
  addNewItemMode: false,
  pageSelected: undefined,
  punchList: [],
  punchListItemHightlighted: null,
  selectPunchListItem: null,
  punchListPages: [],
  punchListCategories: [],
  punchListStatuses: []
};

export const slice = createSlice({
  name: 'punchList',
  initialState,
  reducers: {
    setNewItemMode: (state, action) => {
      if (isBoolean(action.payload)) {
        state.addNewItemMode = Boolean(action.payload);
      }
      return state;
    },
    selectPage: (state, action) => {
      state.pageSelected = action.payload;
      return state;
    },
    selectPunchListItem: (state, action) => {
      state.selectPunchListItem = action.payload;
      return state;
    },
    hightlightPunchListItem: (state, action) => {
      state.punchListItemHightlighted = action.payload;
      return state;
    },
    setPageByIndex: (state, action) => {
      if(isNumber(action.payload)){
        state.pageSelected = state.punchListPages[action.payload];
        state.punchList = [];
      }
      return state;
    },
    setPageById: (state, action) => {
      if(isStringOrNumber(action.payload)){
        const punchListPageIndexFounded = state.punchListPages.find((page) => String(page.id) === String(action.payload));
        if(punchListPageIndexFounded){
          state.pageSelected = punchListPageIndexFounded;
        }
      }
      return state;
    },
    resetPunchListItemSelected: (state) => {
      state.punchListItemSelected = undefined;
      return state;
    },
    reset: (state,) => {
      return initialState
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPuchListItems.fulfilled, (state, action) => {
        state.loading = false;
        state.punchList = isArrayWithValues(action.payload) ? action.payload : [];
      })
      .addMatcher(isPending(getPuchListItems, addPunchListItem), state => {
        state.loading = true;
      })
      .addMatcher(isFulfilled(getPuchListItemById, editPunchListItemStatus), (state, action) => {
        state.loading = false;
        state.punchListItemSelected = action.payload;
      })
      .addMatcher(isFulfilled(addPunchListItem), (state, action) => {
        state.loading = false;
        state.punchList = [...state.punchList, action.payload];
      })
      .addMatcher(isFulfilled(getPunchListPage), (state, action) => {
        state.loading = false;
        state.punchListPages = [...action.payload];
      })
      .addMatcher(isFulfilled(getPunchListCategories), (state, action) => {
        state.loading = false;
        state.punchListCategories = [...action.payload];
      })
      .addMatcher(isFulfilled(getPuchListStatuses), (state, action) => {
        state.loading = false;
        state.punchListStatuses = [...action.payload];
      })
      .addMatcher(isFulfilled(editPunchListItem), (state, action) => {
        state.loading = false;
        
        const punchListUpdated = action.payload;

        // Get Index in List
        const punchListItemInListIndex = isArrayWithValues(state.punchList) ? state.punchList.findIndex((punchListItem) => String(punchListItem.id) ===  String(punchListUpdated?.id)) : -1;

        if (isNumber(punchListItemInListIndex)) {
          state.punchList[punchListItemInListIndex] = { ...state.punchList[punchListItemInListIndex], ...punchListUpdated };
        }

        return state;
      })
  }
});

export const { 
  selectPage, 
  setPageByIndex,
  setPageById,
  selectPunchListItem,
  hightlightPunchListItem,
  reset,
  resetPunchListItemSelected,
  setNewItemMode
} = slice.actions;

// Reducer
export default slice.reducer;
