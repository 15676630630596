import React from 'react';

interface Props {
    text: string;
}

const HyperlinkHighlighter: React.FC<Props> = ({ text }) => {

    if (text === undefined) return <></>;

    if (typeof text !== 'string') {
        return text;
    }


    // regular expression to match hyperlinks
    const pattern = /(https?:\/\/[^\s]+)/gi;

    // Split the text into parts that are either a hyperlink or not
    const parts = text.split(pattern);

    // Map each part to either a hyperlink or plain text
    const highlightedText = parts.map((part, index) =>
        pattern.test(part) ? (
            <a
                title={part}
                style={{
                    textDecoration: 'underline',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '200px',
                    overflow: 'hidden',
                    display: 'inline-block'
                }}
                target="_blank" key={index} href={part} rel="noreferrer">
                {part}
            </a>
        ) : (
            <span key={index}>{part}</span>
        )
    );

    return <>{highlightedText}</>;
};

export default HyperlinkHighlighter;
