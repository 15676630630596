import { InformationSvgIcon } from "@components/Icons/InformationSvgIcon";
import { Tooltip, theme } from "antd";

interface IInfoButonProps {
  handleOnClick?: () => void;
  isActive: boolean;
}

export const InfoButon = (props: IInfoButonProps) => {

    const { handleOnClick, isActive } = props;

    const { token: { colorPrimary, colorTextTertiary }} = theme.useToken();
    
    return (
        <Tooltip placement="bottom" title={"Info"}>
            <InformationSvgIcon 
                className="cursor-pointer" 
                { ...handleOnClick && { onClick: handleOnClick } }
                onClick={handleOnClick} 
                height="22" 
                width="22" 
                style={{ color: isActive ? colorPrimary : colorTextTertiary }}  
            />
        </Tooltip>
    )
}
