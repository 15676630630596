import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Dragger from "antd/es/upload/Dragger";
import { CheckCircleTwoTone, FileAddOutlined, LoadingOutlined } from "@ant-design/icons";
import { MAX_LIMIT_FILES_TO_UPLOAD, MAX_LIMIT_SIZE_FILE_TO_UPLOAD_IN_MB } from "@constants/core";
import { VALID_EXTENSIONS } from "@constants/fileExtensions";
import { isArrayWithValues } from "@shared/util/array-util";
import { hasIntegerValue, isNumber } from "@shared/util/number-util";
import { useAppDispatch } from "@store/store";
import { RcFile, UploadChangeParam, UploadFile, UploadProps } from "antd/es/upload";
import { asyncLaunchNotification } from '@store/slices/notification';
import { useErrorAnimation } from "@HOOKs/UseErrorAnimation";
import { Card, Col, ConfigProvider, Row, Spin, Typography, theme } from "antd";
import { green } from "@ant-design/colors";
import { AttachmentReferenceEnum } from "@models/enumerations/attachment-reference-enum.model";
import { IAttachment, UploadFileCustom } from "@models/attachment.model";
import { If } from "@components/Utils/Structural";
import { convertBytes } from "@shared/util/document-utils";
import { useRollbar } from "@rollbar/react";
import { useAuth } from "@providers/AuthProvider";
import { configuration } from "@environments/env";
import { ItemRender } from "antd/es/upload/interface";
import { punchListUploadFileService } from "@services/punch-list-upload-file.service";

const { Title } = Typography;

export interface onUploadedProps { 
    fileName?: string, 
    referenceId: string | number | null  
}
interface IDragZoneProps {
    referenceId: string,
    entityType: AttachmentReferenceEnum,
    onUploaded: (props: onUploadedProps) => void,
    onStartUpload?: () => void,
    disabled?: boolean,
    validExtensions?: string[],
    maxLimitFilesToUpload?: number,
    maxLimitSizeFileToUploadInMB?: number,
    onMultiUploadProcessFinish?: (props: { filelist: UploadFile[] }) => void
}

type BeforeUploadValueType = void | boolean | string | Blob | File;

export interface DragZoneComponentRef {
    openFileUploader?: () => void
}

export const PunchListUploadFileDragZone = forwardRef<DragZoneComponentRef, IDragZoneProps>((props, ref) => {

    const { 
        referenceId,
        entityType,
        onUploaded,
        onMultiUploadProcessFinish,
        onStartUpload,
        maxLimitFilesToUpload = MAX_LIMIT_FILES_TO_UPLOAD,
        maxLimitSizeFileToUploadInMB = MAX_LIMIT_SIZE_FILE_TO_UPLOAD_IN_MB,
        validExtensions = VALID_EXTENSIONS,
        disabled = false
    } = props;

    const dispatch = useAppDispatch();
    const { token: { colorPrimaryText } } = theme.useToken();
    const [animateError, playAnimationOfError] = useErrorAnimation();
    const [disabledUpload, setDisabledUpload] = useState<boolean>(disabled);
    const rollbar = useRollbar();
    const { user: userLogged } = useAuth();
    const uploadSingleFileRef = useRef<HTMLSpanElement>(null)

    useImperativeHandle(ref, () => ({
        openFileUploader: openDialogFileUploader
    }));

    useEffect(() => {
        setDisabledUpload(disabled)
    }, [disabled])

    const openDialogFileUploader = () => {
        if (uploadSingleFileRef && uploadSingleFileRef?.current) {
            uploadSingleFileRef?.current.click();
        }
    }

    const onErrorUpload = () => {
        dispatch(asyncLaunchNotification({
            type: "error",
            config: {
                message: `Attachments`,
                description: `There was an error trying to upload the file, please try again later.`
            }
        }));
    }

    const beforeUploadFiles = (file: RcFile, FileList: RcFile[]): BeforeUploadValueType | Promise<BeforeUploadValueType> => {

        setDisabledUpload(true);

        onStartUpload?.();

        const indexInList = FileList.findIndex((item) => item.uid === file.uid);

        const maxLimitFilesToUploadIndex = (maxLimitFilesToUpload - 1);

        if (isArrayWithValues(FileList) && hasIntegerValue(maxLimitFilesToUpload) && FileList.length > maxLimitFilesToUpload) {
            dispatch(asyncLaunchNotification({
                type: "error",
                config: {
                    message: `Uploading Error`,
                    description: `You can only upload ${maxLimitFilesToUpload} files at a time.`
                }
            }));
            playAnimationOfError();
        }

        if (indexInList > maxLimitFilesToUploadIndex) {
          return false;  
        } 
    }

    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        if (info?.fileList.every((file) => (file.status !== "uploading"))) {
            onMultiUploadProcessFinish?.({ filelist: info.fileList });
            
            setDisabledUpload(false);
        }
    };

    const isSmallerThanMaxLimitSizeAllowed = (file: string | RcFile | Blob) => {
        if (Boolean(typeof file === 'string')) { return false; }
        try {
            const size = (file as RcFile).size as number;
            return (size / 1024 / 1024) < maxLimitSizeFileToUploadInMB
        } catch (error) {
            return false;
        }
    }

    const getExtension = (file: RcFile) => {
        const splitName  = String((file as RcFile)?.name).split(".");
        const extension = (splitName.length > 1) ? splitName.pop() : "unknown";
        return String(extension).toLowerCase();
    }

    const isValidExtension = (file: string | RcFile | Blob)  => {
        if (Boolean(typeof file === 'string')) { return false; }
        try {
            return validExtensions.includes(`.${getExtension((file as RcFile))}`);
        } catch (error) {
            return false;
        }
    }

    const uploadProps: UploadProps = {
        id: 'punchListUploadFileDragZone',
        name: 'file',
        multiple: false,
        maxCount: maxLimitFilesToUpload,
        type: 'select',
        listType: "picture",
        disabled: disabledUpload,
        className: "example-drager",
        beforeUpload: beforeUploadFiles,
        onChange: handleChange,
        itemRender: customItemRender as ItemRender<unknown>,
        customRequest: ({ file, onSuccess, onError, onProgress }) => {

            const fileName = (file as RcFile).name
            const extension = getExtension((file as RcFile));
            
            const isValidSize = isSmallerThanMaxLimitSizeAllowed(file);
            
            // https://lagarsoft.atlassian.net/browse/SR-459
            if (!isValidSize) {
                dispatch(asyncLaunchNotification({
                    type: "error",
                    config: {
                        message: `Uploading Error`,
                        description: `You can only upload files smaller than ${maxLimitSizeFileToUploadInMB}MB.`
                    }
                }));
                
                playAnimationOfError();

                onError && onError({
                    status: 0,
                    cause: `The file you're trying to upload exceeds our maximum size limit of ${maxLimitSizeFileToUploadInMB}MB.`
                } as any)
                return false;
            }


            // https://lagarsoft.atlassian.net/browse/SR-458
            const isValidExtention = isValidExtension(file);

            if (!isValidExtention) {
                dispatch(asyncLaunchNotification({
                    type: "error",
                    config: {
                        message: `Uploading Error`,
                        description: `You are trying to upload a file with an extension not support yet.`
                    }
                }));

                playAnimationOfError();

                onError && onError({ 
                    status: 0, 
                    cause: `The file you're trying to upload has an extension not support yet.`
                } as any);

                rollbar.info(
                    `File Name [${fileName}] with invalid extension [${extension}] `, 
                    `File extension: [${extension}]`, 
                    { 
                        fileInvalid: { file },
                        fileType: (file as RcFile).type,
                        href: window?.location?.href,
                        entityType,
                        user: { user: userLogged }, 
                        appVersion: configuration?.VERSION 
                    }
                );

                return false;
            }
            
            const attachment: IAttachment = {};
            attachment.fileName = fileName;
            attachment.referenceType = AttachmentReferenceEnum.PUNCH_LIST_GENERATE;
            attachment.referenceId = referenceId;
            attachment.fileSize = (file as RcFile).size;

            // Upload a new File
            punchListUploadFileService.upload({ attachment, file, onProgress }).then((response) => {
                console.log(response);
                const { referenceId = null } = response;
                onSuccess && onSuccess({})
                onUploaded({ fileName, referenceId });
            }).catch((error) => {
                onErrorUpload();
                onError && onError({ status: 0, reason: "There was an error trying to upload the file, please try again later."} as any);
            });

        }
    };

    return (
        <ConfigProvider theme={{ components: { Upload: { borderRadiusLG: 4 } } }}  >
            <Dragger
                { ...uploadProps }
                style={{ borderRadius: 4, marginBottom: 10 }}
                className={`flex flex-col w-full h-full ${animateError && "error-animation"}`}
            >   
                <div className="flex flex-col items-center justify-center w-full pt-8 pb-10">
                    <p className="text-color-neutral-6 mb-0" style={{ fontSize: 36 }}>
                    <FileAddOutlined />
                    </p>
                    <div className="flex flex-row items-center justify-center w-full pt-5">
                        <span ref={uploadSingleFileRef} className="font-semibold pb-0 mb-0" style={{ color: colorPrimaryText }}>Click to upload</span>
                        <span className="pl-5 pb-0 mb-0">
                            or drag and drop
                        </span>
                    </div>
                </div>
            </Dragger>
        </ConfigProvider>
    )
});

const customItemRender = (originNode: React.ReactElement, file: UploadFileCustom<any>, fileList: Array<UploadFile<any>>, actions: {
    download: () => void;
    preview: () => void
    remove: () => void;
}): React.ReactNode => {
    
    const { token: { colorPrimaryHover, colorBgContainer, padding, colorPrimary, colorPrimaryBorderHover }} = theme.useToken();

    return (
        <>
            <If condition={(file.status === "uploading" || file.status === "done")}>
                <Card
                    className="w-full"
                    style={{ 
                        border: `1px solid ${colorPrimaryHover}`, 
                        borderRadius: 8,
                        background: colorBgContainer, 
                        outline: `0px solid transparent`, 
                    }}
                    bodyStyle={{ padding }}
                >
                    <Row justify={"center"} className="mt-15">
                        <Col span={24} className="flex flex-row justify-center">
                            <If condition={!(isNumber(file?.percent))} >
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} spin={false} />} />
                            </If>
                            <If condition={isNumber(file?.percent) && String(file?.percent) === String(100)} >
                                <CheckCircleTwoTone style={{ fontSize: 36 }} twoToneColor={[green[5],green[0]]} />
                            </If>
                        </Col>   
                    </Row>
                    
                    <Row justify={"center"} className="mt-10">
                        <Col span={24} className="flex flex-row justify-center">
                            <Title className="mt-9" level={5} style={{ color: colorPrimary, fontWeight: 500 }} >Uploading file</Title>
                        </Col>   
                    </Row>
                    <Row justify={"center"} className="mt-5">
                        <Col span={24} className="flex flex-row justify-center">
                            
                            <If condition={!(isNumber(file?.percent))}>
                                <span style={{ color: colorPrimaryBorderHover }}>
                                    {`${isNumber(file?.percent?.loaded) ? convertBytes(file?.percent?.loaded!) : '0.0' }` }
                                </span>
                            </If>

                            <If condition={isNumber(file?.percent) && String(file?.percent) === String(100)}>
                                <span style={{ color: colorPrimaryBorderHover }}>
                                    {`${file?.size ? convertBytes(file?.size) : '' }` }
                                </span>
                            </If>

                            <span className="text-color-neutral-8 ml-6">
                                of {`${file?.size ? convertBytes(file?.size) : '' }` }
                            </span>
                            <span className="text-color-neutral-8 ml-6">
                                Uploaded
                            </span>
                        </Col>   
                    </Row>
                    <Row justify={"center"} className="mt-5 mb-15">
                        <Col span={24} className="flex flex-row justify-center ">
                            <span className="text-color-neutral-6">Please don't close this window.</span>
                        </Col>   
                    </Row>
                </Card>
            </If>
        </>
    )
}