import { Col, Row, theme } from "antd";
import { SortingHistory } from "./SortingHistory";
import { UIEvent, useState } from "react";
import { useAuditHistoryProvider } from "./AuditHistoryProvider";
import { isArrayWithValues } from "@shared/util/array-util";
import { useAppSelector } from "@store/store";
import { If } from "@components/Utils/Structural";

const FixedSortingContainerCss: React.CSSProperties = {
    position: "sticky",
    top: "0px",
    zIndex: 10
}

export const ContainerContentHistory = ({ children, ...restProps }: React.HTMLAttributes<HTMLDivElement>) => {

    const { token: { colorBorder, colorBgLayout } } = theme.useToken();
    const { history } = useAppSelector((store) => store.AuditHistory);
    const [isSortingBoxFixed, setIsSortingBoxFixed] = useState(false);
    const { headerHeight } = useAuditHistoryProvider();

    const handleScroll = (event: UIEvent<HTMLElement>) => {
        if (event.target && event?.currentTarget?.scrollTop && event?.currentTarget?.scrollTop > 1 ) {
            setIsSortingBoxFixed(true);
        } else {
            setIsSortingBoxFixed(false);
        }
    };

    return (
        <div 
            onScroll={handleScroll} 
            className="relative"
            style={{
                maxHeight: `calc(100vh - ${headerHeight}px)`,
                overflow: 'auto'
            }}
            {...restProps}
        >
        <Row style={{ ...isSortingBoxFixed && {...FixedSortingContainerCss } }} >
            <Col span={24}>
                <If condition={isArrayWithValues(history)}>
                    <SortingHistory 
                        style={{ 
                            border: `1px solid #ffffff00`,
                            ...isSortingBoxFixed && { border: `1px solid ${colorBorder}`, backgroundColor: colorBgLayout, borderRadius: "9999px" } 
                        }} 
                    />
                </If>
            </Col>
        </Row>

        {children}
    </div>
    )
}