import { IBudgetApprovalRangeSettingSave } from "@models/budget-approval-range-setting.model";
import { Avatar, Badge, Button, theme } from "antd"
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface IThresholdProps {
    threshold: IBudgetApprovalRangeSettingSave;
    thresholds: IBudgetApprovalRangeSettingSave[];
    index: number;
    onClickEdit: (threshold: IBudgetApprovalRangeSettingSave) => void;
    onClickAdd: (bottom: number, top: number) => void;
}

export const Threshold = (props: IThresholdProps) => {

    const { threshold, index, onClickEdit, onClickAdd } = props;

    const { budgetApprovers } = threshold;

    const { t } = useTranslation();

    const { token: { 
        colorPrimary,
        colorFillTertiary,
        colorFillSecondary,
        colorTextSecondary,
        colorTextTertiary,
        colorWarningBg,
        colorWarningText,
        colorWarningBorder,
        colorWarningHover,
        colorWarningTextActive,
        colorWarningActive } } = theme.useToken();

    const noApprovers = useMemo(() => {
        return (!budgetApprovers || budgetApprovers.length === 0)
    }, [budgetApprovers]);

    const arrIndex = index-1;
    // @ts-ignore
    const showGapMessage = index < props.thresholds.length && props.thresholds[arrIndex].maxValue < props.thresholds[arrIndex+1].minValue;

    return <>
        <div className="threshold">
            <div className="left-line">
                <div className="colored-ball" style={{ border: `2px solid ${colorPrimary}` }}></div>
            </div>
            <div className="threshold-content">
                <div className="threshold-header" style={{ backgroundColor: colorFillTertiary, border: `1px solid ${colorFillSecondary}` }}>
                    <div>
                        <h5 style={{ color: colorTextSecondary }}>{t("admin.threshold.threshold")} {index}</h5>
                        <div style={{ flex: 1 }}>
                            <Badge
                                count={threshold.budgetApprovers?.length}
                                className='ml-10'
                                style={{ backgroundColor: colorFillSecondary, color: colorTextTertiary }} />
                        </div>
                        <div><Button size="small" onClick={() => onClickEdit(threshold)}>{t("generic.edit")}</Button></div>
                    </div>
                </div>
                <div className="dollar-amount">
                    ${threshold.minValue} <span style={{ color: colorTextTertiary }}>to</span> ${threshold.maxValue}
                </div>
                {noApprovers && <div className="not-set" style={{ backgroundColor: colorWarningBg, border: `1px solid ${colorWarningBorder}`, color: colorWarningActive }}>
                    {t("admin.threshold.notSet")}
                </div>}
                {!noApprovers && <div className="avatar-container">
                    <Avatar.Group
                        size="large"
                        maxCount={5}
                        maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}
                        >
                            {budgetApprovers?.map(a => {
                                if (a.role?.id) {
                                    return <Avatar style={{ backgroundColor: '#f56a00' }}>{(a.role.name || "-").substring(0, 1)}</Avatar>
                                } else if (a.user?.id) {
                                    if (a.user.imageUrl) {
                                        return <Avatar src={a.user.imageUrl} />
                                    } else {
                                        return <Avatar style={{ backgroundColor: '#f56a00' }}>{(a.user.login || "-").substring(0, 1)}</Avatar>
                                    }
                                }
                                return <></>
                            })}
                    </Avatar.Group>
                </div>}
            </div>
        </div>
        {showGapMessage && <div className="threshold gap">
            <div className="left-line">
                <div className="colored-ball" style={{ border: `2px solid ${colorWarningHover}` }}></div>
            </div>
            <div className="threshold-content" style={{ borderColor: colorWarningHover }}>
                <h5 style={{ color: colorWarningText, fontSize: '16px' }}>{t("admin.threshold.thereIsAGapBetweenThresholds")}</h5>
                <p>{t("admin.threshold.pleaseCreateANewOneOrModify")}</p>
                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                    <div style={{ color: colorWarningTextActive }}>T{index} {t("admin.threshold.max")}: {props.thresholds[arrIndex].maxValue}</div>
                    <div><span className="threshold-not-equal"></span></div>
                    <div style={{ color: colorWarningTextActive, flex: 1 }}>T{index+1} {t("admin.threshold.min")}: {props.thresholds[arrIndex+1].minValue}</div>
                    <div>
                        {/* @ts-ignore */}
                        <Button onClick={() => onClickAdd(props.thresholds[arrIndex].maxValue, props.thresholds[arrIndex+1].minValue) }>{t("admin.threshold.addNewThreshold")}</Button>
                    </div>
                </div>
            </div>
        </div>}
    </>
}