/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Layout } from "antd";
import { MainHeader } from "@components/Layout/HeaderLayout/MainHeader";
import { Content } from "antd/es/layout/layout";
import { usePageContentSize } from "@HOOKs/UsePageContentSize";
import { useLayoutContex } from "@providers/LayoutProvider";
import { configuration } from '../environments/env';
import { useFeatureFlagsProvider } from "@providers/FeatureFlagsProvider";
import { FeatureFlagOptionEnum } from "@models/enumerations/feature-flag-option.enum";
import { useNavigate } from "react-router-dom";

export const ExecutiveReport = () => {

    const navigate = useNavigate();
    const [layoutRef]= usePageContentSize()
    const { isFlagEnabled } = useFeatureFlagsProvider();
    const { layoutSize, headerSize, setHeaderLabelEntityState } = useLayoutContex();
    const reference = useRef<HTMLDivElement>(null);

    const [heightTab, setheightTab] = useState(0);
    const { layoutHeight, layoutWidth} = layoutSize;
    const heightCalculated = { height: `${heightTab}px` }

    useEffect(() => {
        if ( !isFlagEnabled(FeatureFlagOptionEnum.TABLEAU) ) {
            navigate("/403");
        }
    }, [])

    useLayoutEffect(() => {
        const topPosition = (reference?.current && reference?.current?.getBoundingClientRect().top) || 0
        const tabBarHeightRelative = topPosition - headerSize;
        setheightTab(layoutHeight - tabBarHeightRelative)
    }, [layoutSize]);

    useLayoutEffect(() => {
        initTableuDasboard();
    }, [heightTab, layoutWidth])

    useLayoutEffect(() => {
        setHeaderLabelEntityState && setHeaderLabelEntityState("");
    }, []);

    const initTableuDasboard = () => {

        const containerDiv = document.getElementById('tableauViz');

        if (containerDiv) {
            const oldDashboard = document.getElementById('tableauDashboardExecutiveReport');
            if (oldDashboard) {
                containerDiv.removeChild(oldDashboard);
            }
        }

        const tableauElement = document.createElement("tableau-viz");
        tableauElement.setAttribute("id", "tableauDashboardExecutiveReport");
        tableauElement.setAttribute("src", configuration.TABLEAU_DASHBOARD_URL);
        tableauElement.setAttribute("width", `${layoutWidth}px`)
        tableauElement.setAttribute("height", `${heightTab}px`)
        tableauElement.setAttribute("hide-tabs", "true")
        tableauElement.setAttribute("toolbar", "bottom")

        if (containerDiv) {
            containerDiv.appendChild(tableauElement);
        }
    }

    return (
        <Layout >
          <MainHeader />
          <Content ref={layoutRef} >

            <div ref={reference} className="overflow-auto w-full">
                <div className="w-full h-full" style={heightCalculated}>
                    <div id="tableauViz"></div>
                </div>
            </div>
            
          </Content>
        </Layout>
    )
}
