
import { IPunchListPageFilters, IPunchListItem, IPunchListItemsFilters, IEditPunchListItemStatusRequest, IPunchListCheckGenerationPreviewsRequest, ICreatePunchListPagesWithPreviewsPagesSelectedRequest } from '@models/punch-list.model';
import { http } from '@infrastructure/http/axios.instance';
import { PunchListPaginationMapper } from '@mappers/PunchListPagination.Mapper';
import { StringORNumber } from './utils.repository';

export const path = 'punch-list';
export const pathPunchListItems = 'punchlist-items';
export const path_punch_list_categories = 'punchlist-categories';
export const path_punch_list_reasons = 'punchlist-reasons';
export const path_punch_list_statuses = 'punchlist-statuses';

export const punchListRepository = {
    getPages: async (filters: IPunchListPageFilters) => {
        const { projectId } = filters;
        const requestUrl  = `/punchlist-pages?projectId.equals=${projectId}&page=0&size=1000`;
        const getPromise = await http.get(requestUrl);
        
        return PunchListPaginationMapper.toDomain(getPromise.data) ;
    },
    getAll: async (filters: IPunchListItemsFilters) => {
        const { pageId } = filters;
        const requestUrl  = `/${pathPunchListItems}?punchlistPageId.equals=${pageId}&page=0&size=1000&sort=punchlistNumber`;
        const getPromise = await http.get(requestUrl);

        return getPromise.data;
    },
    getById: async (id: StringORNumber) => {
        const requestUrl  = `/${pathPunchListItems}/${id}`;
        const getPromise = await http.get(requestUrl);

        return getPromise.data;
    },
    addPunchListItem: async ({punchListItem}: { punchListItem: IPunchListItem}) => {
        const requestUrl = `/${pathPunchListItems}`;
        const updatePromise = await http.post<IPunchListItem>(requestUrl, punchListItem);
        return updatePromise.data;
    },
    editPunchListItem: async ({punchListItem}: { punchListItem: IPunchListItem}) => {
        if (!punchListItem.id) return;
        
        const requestUrl = `${pathPunchListItems}/${punchListItem.id}`;
        const updatePromise = await http.put<IPunchListItem>(requestUrl, punchListItem);
        return updatePromise.data;
    },
    editPunchListItemStatus: async (punchListItem: IEditPunchListItemStatusRequest) => {
        if (!punchListItem.id) return;
        
        const requestUrl = `${pathPunchListItems}/${punchListItem.id}`;
        const updatePromise = await http.patch<Partial<IPunchListItem>>(requestUrl, punchListItem);
        return updatePromise.data;
    },
    getCategories: async () => {
        const requestUrl  = `/${path_punch_list_categories}?page=0&size=1000`;
        const getPromise = await http.get(requestUrl);

        return getPromise.data;
    },
    getReasons: async () => {
        const requestUrl  = `/${path_punch_list_reasons}?page=0&size=1000`;
        const getPromise = await http.get(requestUrl);

        return getPromise.data;
    },
    getStatuses: async () => {
        const requestUrl  = `/${path_punch_list_statuses}`;
        const getPromise = await http.get(requestUrl);

        return getPromise.data;
    },
    punchListCheckGenerationPreviews: async (punchListGeneration: IPunchListCheckGenerationPreviewsRequest) => {
        const requestUrl  = `/punchlist-generations/${punchListGeneration.id}`;
        const getPromise = await http.get(requestUrl);

        return getPromise.data;
    },
    punchListCreatePages: async (request: ICreatePunchListPagesWithPreviewsPagesSelectedRequest) => {
        
        const requestUrl  = `/punchlist-generations-extended/${request.id}/finish`;
        const getPromise = await http.post(requestUrl, request);

        return getPromise.data;
    },
}