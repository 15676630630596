import { useRef } from 'react'
import { theme } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useLayoutContex } from '../../providers/LayoutProvider';
import { SITE_RISE_OFF_WHITE } from '@providers/ThemeProvider';

export const TriggerColapseNavSide = () => {

    const { token: { colorPrimaryBorderHover } } = theme.useToken();

    const { collapseNav, setCollapseNavState } = useLayoutContex();
    const iconContainerRef = useRef<HTMLDivElement>(null);

    const toogleNav = () => {
        setCollapseNavState(!collapseNav);
    }

    return (
        <div className={`block relative generic-transition`} style={{ height: iconContainerRef?.current?.clientHeight }}>
            <div 
                ref={iconContainerRef} 
                onClick={toogleNav} 
                className={`generic-transition cursor-pointer inline w-max pt-10 pr-10 pb-10 pl-10 absolute ${ collapseNav ? "calculated-center" : 'calculated-left' }`} 
                style={{ backgroundColor: colorPrimaryBorderHover, borderRadius: "5px" }}
            >
                <RightOutlined
                    className="font-bold cursor-pointer generic-transition"
                    style={{ color: SITE_RISE_OFF_WHITE, transform: collapseNav ? "rotateY(0deg)" : "rotateY(180deg)" }}
                />
            </div>
        </div>
    )
}
