import { IRfi } from '@models/rfi.model';
import { http } from '../http/axios.instance';
import { IRfiCategory } from '@models/rfi-category.model';

const path = 'rfi-categories';

export const rfiCategoryRepository = {
    getRfiCategories: async () => {
        const requestUrl = `${path}/all?cacheBuster=${new Date().getTime()}`;

        const promiseGetAll = await http.get<IRfi[]>(requestUrl);
        return promiseGetAll;
    },
    create: async (entity: IRfiCategory): Promise<IRfiCategory> => {

        const requestUrl = `${path}`;
        const promiseCreate = await http.post<IRfiCategory>(requestUrl, entity);
        return promiseCreate.data;
    },
    update: async (id: string | number, entity: IRfiCategory): Promise<IRfiCategory> => {

        const requestUrl = `${path}/${id}`;
        const promiseUpdate = await http.put<IRfiCategory>(requestUrl, entity);
        return promiseUpdate.data;
    },
}