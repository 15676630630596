import { Button, theme } from "antd";
import { blue, red, green, volcano, orange } from "@ant-design/colors";
import { EmptyLabel } from '@components/Utils/EmptyLabel';

interface IDeliverableStatusBadgeProps extends React.ComponentPropsWithoutRef<typeof Button> {
    status: string,
    statusLabel: string
}

// Border Color Map
const BADGE_MAP_BORDER_COLOR = {
    NOT_STARTED: "#F5F5F5",
    ON_TRACK: green[2],
    PAST_DUE: red[2],
    COMPLETED: blue[2],
    PENDING_APPROVAL: volcano[2]
}

// Background Color Map
const BADGE_MAP_BG_COLOR = {
    NOT_STARTED: "#FFF",
    ON_TRACK: green[0],
    PAST_DUE: red[0],
    COMPLETED: blue[0],
    PENDING_APPROVAL: volcano[0]
}

// Text Color Color Map
const BADGE_MAP_TEXT_COLOR = {
    NOT_STARTED: "var(--neutral-7)",
    ON_TRACK: green[5],
    PAST_DUE: red[5],
    COMPLETED: blue[5],
    PENDING_APPROVAL: orange[5]
}

export const DeliverableStatusBadge = (props: IDeliverableStatusBadgeProps) => {

    const { status, statusLabel, ...restProps} = props;
    const { token: { fontSize } } = theme.useToken();
    const fontSizeCalculated = { fontSize: fontSize - 2 };
    const lineHeightCalculated = { lineHight: fontSize + 6 };
    const border = status ? { border: `1px solid ${BADGE_MAP_BORDER_COLOR[status as keyof typeof BADGE_MAP_BORDER_COLOR]}` } : {}
    const background = status ? { background:`${BADGE_MAP_BG_COLOR[status as keyof typeof BADGE_MAP_BG_COLOR]}` } : {}
    const color = status ? { color: `${BADGE_MAP_TEXT_COLOR[status as keyof typeof BADGE_MAP_TEXT_COLOR]}` } : {}

    return (
        <Button style={{ 
            borderRadius: 2,
            ...border,
            ...background,
            ...color,
            ...fontSizeCalculated, 
            ...lineHeightCalculated 
        }} {...restProps} >
            {statusLabel || <EmptyLabel />}
        </Button>
    )
}
