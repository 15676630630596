import { PayloadAction, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import {
  IBudgetApprovalAction,
  IBudgetCreateRequest,
  IBudgetExtended,
  IBudgetLineItemCostUpdateRequest,
  IBudgetLineItemCostUpdateResponse,
  defaultValue,
} from '@models/budget.model';
import { createEntitySlice, EntityState, serializeAxiosError } from '../reducer.util';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { budgetExtendedService } from '@services/budget-extended.service';
import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { budgetLineItemService } from '@services/budget-line-item.service';
import { getAllByProjectId } from './budget-revision';

export const getLatestByProjectId = createAsyncThunk(
  'budget-extended/get_latest_by_project_id',
  async (projectId: StringORNumber) => {
    return await budgetExtendedService.getLatestByProjectId(projectId);
  },
  { serializeError: serializeAxiosError },
);

export const createByTemplate = createAsyncThunk(
  'budget-extended/create_entity',
  async (data: IBudgetCreateRequest, thunkAPI) => {
    const result = await budgetExtendedService.createByTemplate(data).then(() => {
      thunkAPI.dispatch(getLatestByProjectId(data.projectId));
    });
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateLineItemCostRemote = createAsyncThunk(
  'budget-extended/update_line_item_remote',
  async (request: IBudgetLineItemCostUpdateRequest, thunkAPI) => {
    const { id } = request;
    const result = await budgetLineItemService.update(id, request);
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const submitForApproval = createAsyncThunk('budget-extended/submit_for_approval', async (budgetId: StringORNumber, thunkAPI) => {
  return await budgetExtendedService.submitForApproval(budgetId);
});

export const handleApproval = createAsyncThunk('budget-extended/handle_approval', async (request: IBudgetApprovalAction) => {
  return await budgetExtendedService.handleApproval(request);
});

export const getOneByProjectId = createAsyncThunk(
  'budget-extended/get_one_by_project_id',
  async (data:{projectId: StringORNumber, budgetId:StringORNumber}) => {
    return await budgetExtendedService.getOneByProjectId(data.projectId, data.budgetId);
  },
  { serializeError: serializeAxiosError },
);

export const createRevision = createAsyncThunk(
  'budget-extended/create_Revision',
  async (budgetId: StringORNumber, thunkAPI) => {
    const result = await budgetExtendedService.createRevision(budgetId).then(response => {
      if (response.project?.id) {
        thunkAPI.dispatch(getLatestByProjectId(response.project?.id));
        thunkAPI.dispatch(getAllByProjectId(response.project?.id));
      };
    });
    return result;
  },
  { serializeError: serializeAxiosError },
);

const initialState: EntityState<IBudgetExtended> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export const BudgetExtendedSlice = createEntitySlice({
  name: 'budget_extended',
  initialState,
  reducers: {
    clearEntity: state => {
      state.entity = defaultValue;
    },
    updateLineItemCost: (state, action: PayloadAction<{ subCategoryId: number; cost: string }>) => {
      const { subCategoryId, cost } = action.payload;
      state.entity.groups = state.entity.groups?.map(group => {
        if (group.categories) {
          return {
            ...group,
            categories: group.categories.map(category => {
              if (category.subCategories) {
                return {
                  ...category,
                  subCategories: category.subCategories.map(subCategory => {
                    if (subCategory.id === subCategoryId) {
                      return { ...subCategory, budgetLineItem: { ...subCategory.budgetLineItem, cost } };
                    }
                    return subCategory;
                  }),
                };
              }
              return category;
            }),
          };
        }
        return group;
      });
    },
    updateSubTotalCosts: (state, action: PayloadAction<{ subCategoryId: number } & IBudgetLineItemCostUpdateResponse>) => {
      const { subCategoryId, cost, costPerSF, categoryId, categoryCost, categoryCostPerSF, groupingId, groupingCost, groupingCostPerSF } =
        action.payload;
      state.entity.groups = state.entity.groups?.map(group => {
        if (group.id === groupingId) {
          return {
            ...group,
            totalCostCategories: Number.parseFloat(groupingCost || '0.00').toFixed(2),
            totalCostPerSFCategories: Number.parseFloat(groupingCostPerSF || '0.00').toFixed(2),
            categories: group.categories?.map(category => {
              if (category.id === categoryId) {
                return {
                  ...category,
                  totalCostSubCategories: Number.parseFloat(categoryCost || '0.00').toFixed(2),
                  totalCostPerSFSubCategories: Number.parseFloat(categoryCostPerSF || '0.00').toFixed(2),
                  subCategories: category.subCategories?.map(subCategory => {
                    if (subCategory.id === subCategoryId) {
                      return { ...subCategory, budgetLineItem: { ...subCategory.budgetLineItem, cost, costPerSF: costPerSF || '0.00' } };
                    }
                    return subCategory;
                  }),
                };
              }
              return category;
            }),
          };
        }
        return group;
      });
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(createByTemplate), (state, action) => {
        state.errorMessage = null;
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isPending(getLatestByProjectId), (state, action) => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isFulfilled(getLatestByProjectId), (state, action) => {
        state.errorMessage = null;
        state.loading = false;
        state.entity = action.payload as IBudgetExtended;
      })
      .addMatcher(isRejected(getLatestByProjectId), (state, action) => {
        state.loading = false;
        state.entity = {};
        state.errorMessage = 'no-budget';
      })
      .addMatcher(isPending(updateLineItemCostRemote), (state, action) => {
        state.updating = true;
        state.updateSuccess = false;
      })
      .addMatcher(isFulfilled(updateLineItemCostRemote), (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
      })
      .addMatcher(isRejected(updateLineItemCostRemote), (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
      })
      .addMatcher(isFulfilled(createRevision), (state, action) => {
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isPending(createRevision), (state, action) => {
        state.loading = true;
      })
      .addMatcher(isRejected(createRevision), (state, action) => {
        state.loading = false;
        state.updateSuccess = false;
      })
      .addMatcher(isPending(getOneByProjectId), (state, action) => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isFulfilled(getOneByProjectId), (state, action) => {
        state.errorMessage = null;
        state.loading = false;
        state.entity = action.payload as IBudgetExtended;
      })
      .addMatcher(isRejected(getOneByProjectId), (state, action) => {
        state.loading = false;
        state.entity = {};
        state.errorMessage = 'no-budget';
      });
  },
});

export const { reset, clearEntity, updateSubTotalCosts, updateLineItemCost } = BudgetExtendedSlice.actions;

// Reducer
export default BudgetExtendedSlice.reducer;
