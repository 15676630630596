
import { isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from "../reducer.util";
import { createAsyncThunk } from "@reduxjs/toolkit"
import { ISiteStatus, defaultValue } from "../../models/site-status.model";
import { siteStatusesService } from '../../services/sites-statuses.service';
import { IQueryParams } from "../../models/pagination";
import { cleanEntity } from '../../shared/util/entity-utils';
import { asyncLaunchNotification } from './notification';

const nameOfEntity = "siteStatuses";
const service = siteStatusesService;

export const getEntities = createAsyncThunk(
  `${nameOfEntity}/fetch_entity_list`,
  async (queryParams: IQueryParams) => {
      return service.getAll(queryParams);
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  `${nameOfEntity}/fetch_entity`,
  async (id: string | number) => {
    return service.getById(id);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  `${nameOfEntity}/create_entity`,
  async (entity: ISiteStatus, thunkAPI) => {
    const result = await service.create(cleanEntity(entity));
    thunkAPI.dispatch(asyncLaunchNotification({
      type: "success",
      config: {
        message: `Company`,
        description: `Successfully linked company`
      }
    }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const reorderSiteStatuses = createAsyncThunk(
  `${nameOfEntity}/reorder`,
  async (siteStatusIds: number[], thunkAPI) => {
    const result = await service.reorder(siteStatusIds);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  `${nameOfEntity}/update_entity`,
  async (entity: ISiteStatus, thunkAPI) => {
    const result = entity.id && await service.update(entity.id, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  `${nameOfEntity}/delete_entity`,
  async (id: string | number, thunkAPI) => {
    const result = await service.delete(id);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

const initialState: EntityState<ISiteStatus> = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

export const SiteStatusesSlice = createEntitySlice({
    name: nameOfEntity,
    initialState,
    reducers: {
      clearEntity: (state,) => {
        state.entity = defaultValue;
      },
    },
    extraReducers(builder) {
        builder
          .addCase(getEntity.fulfilled, (state, action) => {
            state.loading = false;
            state.entity = action.payload.data;
          })
          .addMatcher(isFulfilled(getEntities), (state, action) => {
            const { data, headers } = action.payload;
            const xTotalCount = headers['x-total-count'];
  
            return {
              ...state,
              loading: false,
              entities: data,
              totalItems: xTotalCount ? parseInt(xTotalCount, 10) : data.length,
            };
          })
          .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
            state.updating = false;
            state.loading = false;
            state.updateSuccess = true;
            state.entity = action.payload as ISiteStatus;
          })
          .addMatcher(isPending(getEntities, getEntity), state => {
            state.errorMessage = null;
            state.updateSuccess = false;
            state.loading = true;
          })
          .addMatcher(isPending(createEntity, updateEntity), state => {
            state.errorMessage = null;
            state.updateSuccess = false;
            state.updating = true;
          });
      }
  });
  
  export const { reset, clearEntity } = SiteStatusesSlice.actions;
  
  // Reducer
  export default SiteStatusesSlice.reducer;

  