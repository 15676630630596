import { IUser } from "@models/user.model";

export const getUserDisplayName = (user: IUser): String => {
    if (user?.fullName) {
        return user.fullName;
    } else if (user?.firstName && user?.lastName) {
        return user.firstName + ' ' + user.lastName;
    } else if (user?.firstName) {
        return user.firstName;
    } else if (user?.lastName) {
        return user.lastName;
    } else {
        return user?.login || '';
    }
}