
import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit"
import { cleanEntity } from '@shared/util/entity-utils';
import { siteAlbumPhotosService } from '@services/site-album-photos.service';
import { IQueryParamsGeneric } from '@models/utils';
import { IPhotoAlbum } from '@models/photo-album.model';
import { serializeGenericHandleError } from '@store/thunk.util';
import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { isArrayWithValues } from '@shared/util/array-util';
import { isNumber } from '@shared/util/number-util';
import { IAttachment } from '@models/attachment.model';
import { RootState } from '@store/store';

const nameOfEntity = "siteAlbumPhotos";
const service = siteAlbumPhotosService;

export const getSitePhotoAlbumsEntities = createAsyncThunk(
  `${nameOfEntity}/fetch_entity_list`,
  async (queryParams: IQueryParamsGeneric) => {
      return service.getSiteAlbumPhotos(queryParams);
  },
  { serializeError: serializeGenericHandleError }
);

export const createEntity = createAsyncThunk(
    `${nameOfEntity}/create_entity`,
    async (entity: IPhotoAlbum, thunkAPI) => {
      const result = await service.createSiteAlbumPhotos(entity);
      return result;
    },
    { serializeError: serializeGenericHandleError }
);

export const getEntityById = createAsyncThunk(
  `${nameOfEntity}/getEntityById`,
  async (albumId: StringORNumber, thunkAPI) => {
    const result = await service.getSiteAlbumPhotosById(albumId);
    return result;
  },
  { serializeError: serializeGenericHandleError }
);
  
export const refreshAlbumAttachmentsById = createAsyncThunk(
  `${nameOfEntity}/refreshAlbumAttachmentsById`,
  async (albumId: StringORNumber, thunkAPI) => {
    const result = await service.getSiteAlbumPhotosById(albumId);
    return result;
  },
  { serializeError: serializeGenericHandleError }
);

export const updateEntity = createAsyncThunk(
    `${nameOfEntity}/update_entity`,
    async ({entity, onSuccess}: {entity: IPhotoAlbum, onSuccess?: () => void }, thunkAPI) => {
      const resultUpdate = entity.id && await service.updateSiteAlbumPhotos(entity.id, cleanEntity(entity) as IPhotoAlbum);
      const resultGetById = resultUpdate && entity.id && thunkAPI.dispatch(getEntityById(entity?.id));
      resultGetById && onSuccess?.();
      return resultGetById;
    },
    { serializeError: serializeGenericHandleError }
);

export const updateAttachmentsOfAlbumSelected = createAsyncThunk(
  `${nameOfEntity}/updateAttachmentsOfAlbumSelected`,
  async ({entity}: {entity: IPhotoAlbum }, thunkAPI) => {
    const albumId = entity.id || null;
    const state  = thunkAPI.getState() as RootState;
    const sitePhotoAlbumsList = state.SiteAlbumPhotos.entities;

    // Get Index in List
    const albumInListIndex = isArrayWithValues(sitePhotoAlbumsList) ? sitePhotoAlbumsList.findIndex((albumStored) => String(albumStored.id) ===  String(albumId)) : -1;

    // If there is an Index, set new Photo Album
    let attachmentsSliced: IAttachment[] = [];
    if (isNumber(albumInListIndex) && Boolean(albumInListIndex! > -1)) {
      attachmentsSliced = state.GenericAlbumGalleryMultimedia.entities.slice(0, 5) || [];
    }

    return { albumInListIndex, attachments: attachmentsSliced }
  }
);

interface IState {
  errorMessage: null,
  entities: IPhotoAlbum[],
  entity: IPhotoAlbum,
  updating: boolean,
  totalItems: number,
  updateSuccess: boolean,
  loadingListAlbums: boolean,
  loadingDetailsAlbumSelected: boolean,
  currentPage: number
}

const initialState: IState = {
    errorMessage: null,
    entities: [],
    entity: {} as IPhotoAlbum,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
    loadingListAlbums: false,
    loadingDetailsAlbumSelected: false,
    currentPage: 0
};

export const SiteAlbumPhotosSlice = createSlice({
    name: nameOfEntity,
    initialState,
    reducers: {
      setAlbum: (state, action: { payload: IPhotoAlbum}) => {
        state.entity = { ...action.payload };
        return state;
      },
      updateTotalAttachmentOfAlbum: (state, action) => {
        const { albumId: albumIdSelected, totalItems} =  action.payload;

        const albumId: StringORNumber = albumIdSelected || null;

        // Get Index in List
        const albumInListIndex = isArrayWithValues(state.entities) ? state.entities.findIndex((albumStored) => String(albumStored.id) ===  String(albumId)) : -1;

        // If there is an Index, set new Photo Album
        if (isNumber(albumInListIndex) && Boolean(albumInListIndex! > -1)) {
          state.entities[albumInListIndex as number] = { ...state.entities[albumInListIndex as number], totalPhotos: totalItems};
          state.entity.totalPhotos = totalItems;
        }

        return state;
      },
      removeAttachmentFromGallery: (state, action: { payload: {album: IPhotoAlbum, attachment: IAttachment }}) => {
        const { album, attachment } = action.payload;

        // Get Index in List
        const albumInListIndex = isArrayWithValues(state.entities) ? state.entities.findIndex((albumStored) => String(albumStored.id) ===  String(album?.id)) : -1;

        // If there is an Index, set new Photo Album
        if (isNumber(albumInListIndex) && Boolean(albumInListIndex! > -1)) { 
          state.entities[albumInListIndex].attachments = state.entities[albumInListIndex].attachments?.filter((item) => item.id !== attachment.id);

        }
        
        return state;
      },
      reset: (state) => {
        return initialState;
      }
    },
    extraReducers(builder) {
        builder
          .addMatcher(isFulfilled(getSitePhotoAlbumsEntities), (state, action) => {
            
            const { data, headers } = action.payload;
            const { page } = action.meta.arg;
            let currentPageLocal;
    
            let newEntities: IPhotoAlbum[] = [];
    
            if (!page || page === 0) {
              newEntities = [...data];
              currentPageLocal = 0;
            } else {
              newEntities = [...state.entities, ...data] 
              currentPageLocal = Number(page);
            }
    
            const totalItemsCount = headers['x-total-count'] ? parseInt(headers['x-total-count'], 10) : 0;

            return {
              ...state,
              loadingListAlbums: false,
              entities: newEntities,
              totalItems: totalItemsCount,
              updating: false,
              currentPage : currentPageLocal
            };
          })
          .addMatcher(isFulfilled(getEntityById), (state, action) => {
            const newPhotoAlbum = action.payload as IPhotoAlbum;
            
            // Get Index in List
            const albumInListIndex = isArrayWithValues(state.entities) ? state.entities.findIndex((albumStored) => String(albumStored.id) ===  String(newPhotoAlbum.id)) : -1;
            
            // If there is an Index, set new Photo Album
            if (isNumber(albumInListIndex) && Boolean(albumInListIndex! > -1)) {
              const { attachments, totalPhotos, ...rest} = newPhotoAlbum;
              state.entities[albumInListIndex as number] = { ...state.entities[albumInListIndex as number], ...rest };
            }

            state.loadingDetailsAlbumSelected = false;
            state.updating = false;
            state.entity = newPhotoAlbum;

            return state;
          })
          .addMatcher(isFulfilled(refreshAlbumAttachmentsById), (state, action) => {
            const newPhotoAlbum = action.payload as IPhotoAlbum;
            
            // Get Index in List
            const albumInListIndex = isArrayWithValues(state.entities) ? state.entities.findIndex((albumStored) => String(albumStored.id) ===  String(newPhotoAlbum.id)) : -1;
          
            // If there is an Index, set new Photo Album
            if (isNumber(albumInListIndex) && Boolean(albumInListIndex! > -1)) {
              state.entities[albumInListIndex as number] = newPhotoAlbum;
            }
            
            state.entity = newPhotoAlbum;
            
            return state;
          })
          .addMatcher(isPending(getEntityById), state => {
            state.loadingDetailsAlbumSelected = true;
            state.errorMessage = null;
          })
          .addMatcher(isPending(updateEntity), state => {
            state.loadingDetailsAlbumSelected = true;
            state.errorMessage = null;
            state.updateSuccess = false;
            state.updating = true;
          })
          .addMatcher(isFulfilled(updateEntity), (state, action) => {
            state.updating = false;
            state.loadingDetailsAlbumSelected = false;
            state.updateSuccess = true;
          })
          .addMatcher(isFulfilled(createEntity), (state, action) => {
            state.updating = false;
            state.loadingListAlbums = false;
            state.updateSuccess = true;
          })
          .addMatcher(isFulfilled(updateAttachmentsOfAlbumSelected), (state, action) => {
            const { albumInListIndex = -1, attachments = undefined } = action.payload;

            if (isNumber(albumInListIndex) && Boolean(albumInListIndex! > -1)) { 
              state.entities[albumInListIndex].attachments = attachments;
            }
          })
          .addMatcher(isPending(getSitePhotoAlbumsEntities, createEntity), state => {
            state.loadingListAlbums = true;
            state.errorMessage = null;
            state.updateSuccess = false;
            state.updating = true;
          })
          .addMatcher(isRejected(getSitePhotoAlbumsEntities, createEntity, updateEntity, getEntityById) , (state) => {
            state.loadingListAlbums = false;
            state.loadingDetailsAlbumSelected = false;
            state.updating = false;
            state.updateSuccess = false;
          })
      }
  });
  
  export const { reset, setAlbum, removeAttachmentFromGallery, updateTotalAttachmentOfAlbum } = SiteAlbumPhotosSlice.actions;
  
  // Reducer
  export default SiteAlbumPhotosSlice.reducer;

  