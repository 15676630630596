import { useLocation } from "react-router-dom";

export const UsePunchListRouteSegment = () => {

    let location = useLocation();

    const pathname = location?.pathname || "";
    const baseSegment = pathname?.split?.("punch-list")?.[0] || "";
    
    return baseSegment
}
