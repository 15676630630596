export const MoonSvgIcon = () => {
  return (
    <svg
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="4756"
      width="1em"
      height="1em"
    >
      <path
        d="M512 981.312A469.632 469.632 0 0 1 42.688 512 469.632 469.632 0 0 1 512 42.688h17.28c18.816 0 36.096 12.544 43.904 31.36a45.76 45.76 0 0 1-12.544 51.84 242.304 242.304 0 0 0-84.736 183.68 241.856 241.856 0 0 0 241.728 241.664c70.656 0 136.576-31.36 183.68-84.736a49.024 49.024 0 0 1 51.776-14.144c18.816 6.272 31.36 23.552 31.36 42.368V512c-3.136 259.008-213.44 469.312-472.448 469.312zM420.928 147.84A375.488 375.488 0 0 0 136.832 512 375.168 375.168 0 0 0 512 887.168a375.488 375.488 0 0 0 364.16-284.16 332.608 332.608 0 0 1-160.128 40.832A336.32 336.32 0 0 1 380.16 307.968c0-56.512 14.08-111.488 40.768-160.128z"
        fill="currentColor"
        p-id="4757"
      ></path>
    </svg>
  );
};
