export interface IOxBlueSession {
    sessionId: string,
    validSession: boolean,
}

export interface IOxBlueCamera {
    hard_hat?: number;
    high_vis?: number;
    overall?: number;
    id?: string
    offsetFromUTC?: string;
}
export interface IOxBlueCameraDates {
    [date: string]: string[];
}

export interface IOxBlueCameraImageFeed {
    savePath?: string;
    path?: string;
    markupPath?: string;
    lowResPath?: string;
    mostRecentPath?: string;
    historicWeatherPath?: string;
  }

export interface IOxBlueProject {
    id?: string;
    name?: string;
    project_name?: string;
    city?: string;
    state?: string;
    country?: string;
    cameras?: string[];
    num_of_cameras?: number;
    num_of_active_cameras?: number;
    num_of_completed_cameras?: number;
    ai?: string;
    num_of_ai_none_selected?: number;
    num_of_ai_activated?: number;
    num_of_ai_disabled?: number;
    requested_full_ai?: boolean;
    requested_full_ai_on?: string | null;
    thumbnail_camera_id?: string;
    thumbnail_path?: string;
    thumbnail?: string;
    is_active?: boolean;
    additional_info?: string;
    latitude?: number;
    longitude?: number;
    status?: number;
    has_sufficient_safety_data?: boolean;
    has_recent_safety_example?: boolean;
}

export const defaultValue: Readonly<IOxBlueProject> = {};

