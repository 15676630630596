import { createAsyncThunk, createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { testingService } from "../../services/testing.service";

export interface Mocks {
    [key: string]: any
}

const initialState: Mocks = {
    testing: {
        loading: false,
        errorMessage: null,
        data: []
    },
};

export const getTestingData = createAsyncThunk(
  'testing/getTestingData',
  async ( path: string , thunkApi) => {
    const url = path;
    return testingService.getTestingData(url);
  }
)

export const TestingSlice = createSlice({
    name: 'testing',
    initialState,
    reducers: {
      reset: (state,) => {
        return initialState;
      },
    },
    extraReducers(builder) {
      builder
        .addCase(getTestingData.fulfilled, (state, action) => {
          if (action?.payload) { 
            state.testing.data = action?.payload;
          }
          state.testing.errorMessage = null;
          state.testing.loading = false;
        })
        .addMatcher(isPending(getTestingData), state => {
          state.testing.errorMessage = null;
          state.testing.loading = true;
        })
        .addMatcher(isRejected(getTestingData), state => {
          state.testing.errorMessage = null;
          state.testing.loading = false;
        });
    }     
});

export const { reset } = TestingSlice.actions;
  
// Reducer
export default TestingSlice.reducer;