import { SVGProps } from "react";

type IProps = SVGProps<SVGSVGElement>

export const TrashSvgIcon = (props: IProps) => {  

    const { ...restProps } = props;

    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <path d="M1.75 3.5H2.91667H12.25" fill="currentColor"/>
            <path d="M1.75 3.5H2.91667H12.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.66406 3.5013V2.33464C4.66406 2.02522 4.78698 1.72847 5.00577 1.50968C5.22456 1.29089 5.52131 1.16797 5.83073 1.16797H8.16406C8.47348 1.16797 8.77023 1.29089 8.98902 1.50968C9.20781 1.72847 9.33073 2.02522 9.33073 2.33464V3.5013M11.0807 3.5013V11.668C11.0807 11.9774 10.9578 12.2741 10.739 12.4929C10.5202 12.7117 10.2235 12.8346 9.91406 12.8346H4.08073C3.77131 12.8346 3.47456 12.7117 3.25577 12.4929C3.03698 12.2741 2.91406 11.9774 2.91406 11.668V3.5013H11.0807Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.83594 6.41797V9.91797" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.16406 6.41797V9.91797" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
