import { IChangeOrderStatus } from '@models/change-order-status.model';
import { http } from '../http/axios.instance';

const path = 'change-order-status';

export const changeOrderStatusRepository = {
    getChangeOrderStatuses: async () => {
        const requestUrl = `${path}?cacheBuster=${new Date().getTime()}`;
        const promiseGetAll = await http.get<IChangeOrderStatus[]>(requestUrl);
        return promiseGetAll;
    },
}