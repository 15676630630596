import { ProjectDeliverableTimeLineResponse, ProjectDeliverableTimeLine, ProjectDeliverableTimeLineAndReasonRequired } from "@models/project-deliverable";
import { getFirstItem } from "@shared/util/array-util";

export class ProjectDeliverableTimelineMapper {

  public static flattenedRecursiveProjectDeliverableTimeline(timeline: ProjectDeliverableTimeLineResponse[]): ProjectDeliverableTimeLine[] {
    let output = timeline.reduce((acc: ProjectDeliverableTimeLine[], value: any) => {
      if (value.projectDeliverableChildren && Array.isArray(value.projectDeliverableChildren)) {
        acc.push(...[value.projectDeliverable, ...this.flattenedRecursiveProjectDeliverableTimeline(value.projectDeliverableChildren)]);
      } else {
        acc.push(value.projectDeliverable);
      }
      return acc;
    }, [])
    return output;
  }


  public static toDomain(response: ProjectDeliverableTimeLineResponse[]): ProjectDeliverableTimeLineAndReasonRequired {
    const dataTransformedToDomain = this.flattenedRecursiveProjectDeliverableTimeline(response)
    const dataTransformedWithReasonCode = {
      timeline: dataTransformedToDomain,
      reasonRequired: getFirstItem(response)?.projectDeliverableReasonRequired?.required

    }
    return dataTransformedWithReasonCode || [];
  }

}
