export enum AttachmentReferenceEnum {
  PROJECT = "PROJECT",
  PROJ_DELIVERABLE = "PROJECT_DELIVERABLE",
  RFI = "RFI",
  CHANGE_ORDER_REVISION = "CHANGE_ORDER_REVISION",
  LOCATION_KEY_PHOTO = "LOCATION_KEY_PHOTO",
  PHOTO_ALBUM = "PHOTO_ALBUM",
  PUNCH_LIST_ITEM = "PUNCH_LIST_ITEM",
  PUNCH_LIST_PAGE = "PUNCH_LIST_PAGE",
  PUNCH_LIST_GENERATE = "PUNCH_LIST_GENERATE"
}
