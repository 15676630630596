export const capitalize = (word: string) => {
  return word[0].toUpperCase() + word.slice(1);
};

export const pascalCaseFirstWord = (word: string) => {
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
};

export const isNotEmpty = (stringValue: string) => {
  return typeof stringValue === 'string' && stringValue.trim() !== '';
};

export const capitalCaseToTitleCase = (word: string) => {
  return word.replace(/(\w)(\w*)/g, (_, firstChar, rest) => firstChar + rest.toLowerCase());
};
