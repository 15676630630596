
import { ICurrency } from '@models/currency.model';
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { currencyService } from '@services/currency.service';
import { serializeAxiosError } from "../reducer.util";

export const getActiveEntities = createAsyncThunk(
    'currency/fetch_active_entity_list',
    async () => {
        return currencyService.getActiveCurrencies();
    },
    { serializeError: serializeAxiosError }
);

interface ICurrencyState {
    loading: boolean;
    errorMessage: string | null;
    activeEntities: ReadonlyArray<ICurrency>;
}

const initialState: ICurrencyState = {
    loading: false,
    errorMessage: null,
    activeEntities: []
};

export const CurrencySlice = createSlice({
    name: 'currency',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(isFulfilled(getActiveEntities), (state, action) => {
                const { data } = action.payload;
                return {
                    ...state,
                    loading: false,
                    activeEntities: data
                };
            })
            .addMatcher(isPending(getActiveEntities), state => {
                state.errorMessage = null;
                state.loading = true;
            });
    }
});

// Reducer
export default CurrencySlice.reducer;

