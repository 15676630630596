/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import { useErrorAnimation } from "@HOOKs/UseErrorAnimation";
import { IRole } from "@models/role.model";
import { isFulfilled } from "@reduxjs/toolkit";
import { asyncLaunchNotification } from "@store/slices/notification";
import { createEntity, updateEntity } from "@store/slices/role";
import { getDisciplines } from "@store/slices/discipline";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Button, Col, Drawer, Form, Input, Row, Select, Space } from "antd";
interface IRoleEditProps {
  open: boolean,
  toogle: () => void,
  onModifiedChanges?: (role: IRole) => void,
  role: IRole
}

export const RoleEdit = (props: IRoleEditProps) => {

    const { open, toogle, role, onModifiedChanges } = props;

    const dispatch = useAppDispatch();

    const [form] = Form.useForm();

    const { loading, updating } = useAppSelector((state) => state.CompanyRoles);

    const [animateError, playAnimationOfError] = useErrorAnimation();

    const onFinishFailed = (errorInfo: any) => playAnimationOfError();

    const onFinish = (entity: any): void => {
        const hasRole = role?.id;
        if (hasRole) {
            dispatch(
                updateEntity({
                    ...role,
                    ...entity,
                    discipline: {
                        id: entity.disciplineId
                    }
                })  
            ).then(onSuccessUpdatedStatus);
            
        } else {
            dispatch(createEntity({
                id: "",
                ...entity,
                discipline: {
                    id: entity.disciplineId
                }
            })).then(onSuccessCreateStatus);
        }
    };

    const onSuccessUpdatedStatus = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: `Changes saved`,
                  description: `Your changes have been saved successfully`
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onSuccessCreateStatus = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: `Changes saved`,
                  description: `Status created successfully`
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onClose = () => { 
        form.resetFields();
        toogle(); 
    }

    const onSubmit = () => form.submit();

    const loadDisciplines = () => {
        dispatch(
          getDisciplines()
        );
      }
    
      useEffect(() => {
        loadDisciplines();
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
    const { entities: disciplines } = useAppSelector((state) => state.Discipline);

    const options = useMemo(() => {
        return disciplines.map((d) => {
            return { label: d.name, value: d.id }
        });
    }, [disciplines]);

    useEffect(() => { setRoleFormFields() }, [role])

    const setRoleFormFields = () => {
        form.setFieldsValue({
            ...role,
            disciplineId: role?.discipline?.id
        });
    }

    return (
        <>
            <Drawer 
                width={448} 
                title={`${role?.id ? "Edit": "Create new"} role`}
                placement="right" 
                onClose={onClose} 
                open={open}
                closable={false}
                extra={
                    <Space>
                      <Button type="primary" ghost onClick={onClose}>
                        Cancel
                      </Button>
                      <Button loading={loading} onClick={onSubmit} type="primary" disabled={updating}>
                        {`${role?.id ? "Save": "Create"}`}
                      </Button>
                    </Space>
                  }
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className={`${animateError && "error-animation"}`}
                >

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                label="Role Name"
                                rules={[
                                    { required: true, message: "Role Name field is Required" },
                                ]}
                            >
                                <Input placeholder="Role Name" allowClear />
                            </Form.Item>
                            <Form.Item 
                                name="disciplineId" 
                                label="Discipline"
                                rules={[
                                    { required: true, message: "Discipline field is Required" },
                                ]}
                            >
                                <Select
                                    placeholder="Select Discipline"
                                    options={options}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Drawer>
        </>
  );
}
