import { useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExclamationCircleFilled, FileAddOutlined } from '@ant-design/icons';
import { Badge, Col, Modal, Row, theme, Typography, UploadFile } from 'antd';
import { deleteEntity as deleteAttachment, getByReferenceTypeAndReferenceId } from '@store/slices/attachment';
import { AppDispatch, RootState } from '@store/store';
import { FilePreviewData, IAttachment } from 'models/attachment.model';
import { IProjectDeliverableUI } from 'models/project-deliverable';
import { AttachmentReferenceEnum } from '../../models/enumerations/attachment-reference-enum.model';
import { reverse, sortBy } from 'lodash';
import { FilePreviewModal } from './FilePreview/FilePreviewModal';
import { getExtensionOfFileName, isAnValidImage } from '@shared/util/document-utils';
import { DerivativesEnum } from '@models/enumerations/derivatives-enum.model';
import { asyncLaunchNotification } from '@store/slices/notification';
import { ContainerFileProvider } from './FilePreview/ContainerFileProvider';
import { useParams } from 'react-router-dom';
import { getProjectDeliverablesData } from '@store/slices/project-deliverables';
import { DragZone, DragZoneComponentRef } from './FilePreview/DragZone';
import { If } from '@components/Utils/Structural';
import { VALID_EXTENSIONS } from "@constants/fileExtensions";
import TableHeader from './TableHeader';
import MainTable from './MainTable';
import UploadModal from './UploadModal';
import useVisible from '@HOOKs/UseVisible';
import { MAX_LIMIT_FILES_TO_UPLOAD, MAX_LIMIT_SIZE_FILE_TO_UPLOAD_IN_MB } from '@constants/core';

export interface IAttachmentProps {
  entityType: AttachmentReferenceEnum
  referenceId: number,
  deliverable?: IProjectDeliverableUI;
  onSuccessUpload?: () => void;
  onSuccessRemove?: () => void;
  showHeader?: boolean,
  showlistOfAttachments?: boolean,
  validExtensions?: string[],
  maxLimitFilesToUpload?: number,
  maxLimitSizeFileToUploadInMB?: number,
  onMultiUploadProcessFinish?: (props: { filelist: UploadFile[] }) => void,
}

export default function AttachmentsList(props: IAttachmentProps) {
  const dispatch = useDispatch<AppDispatch>();

  const { 
    referenceId, 
    entityType, 
    onSuccessUpload, 
    onSuccessRemove, 
    showHeader = true, 
    showlistOfAttachments = true,
    maxLimitFilesToUpload = MAX_LIMIT_FILES_TO_UPLOAD,
    maxLimitSizeFileToUploadInMB = MAX_LIMIT_SIZE_FILE_TO_UPLOAD_IN_MB,
    validExtensions = VALID_EXTENSIONS,
    onMultiUploadProcessFinish
  } = props;

  const { entities: attachmentList, loading, totalItems } = useSelector((state: RootState) => state.Attachment);
  const [modal, contextHolder] = Modal.useModal();

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenRevision, setIsModalOpenRevision] = useState<null | number>(null)

  const [documentSelected, setDocumentSelected] = useState<IAttachment | null>({});

  const [openFilePreview, toogleOpenFilePreview, setVisible] = useVisible();

  const { token: { colorFillQuaternary, colorPrimary, colorPrimaryActive, colorText, colorBorder } } = theme.useToken();

  const dragZoneRef = useRef<DragZoneComponentRef>(null);

  const getAllAttachments = () => {
    if (showlistOfAttachments) {
      dispatch(getByReferenceTypeAndReferenceId({ referenceType: entityType.toString(), referenceId }))
    }
  }

  useLayoutEffect(() => {
    entityType && referenceId && getAllAttachments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityType, referenceId])

  const onViewAction = (record: IAttachment, versions: IAttachment[]) => {

    const dataFile = getDataExtension(record);

    if (dataFile.isAnValidImage) {
      setDocumentSelected({ ...record, ...dataFile, ...versions && { versions } });
      setVisible(true);
      return;
    }

    if (!dataFile.isAnValidImage && record.derivatives?.some((item) => item.derivativeType === DerivativesEnum.PREVIEW)) {
      setDocumentSelected({ ...record, ...dataFile, ...versions && { versions } });
      toogleOpenFilePreview();
      return;
    }
  }

  const previewLatestVersion = () => {
    toogleOpenFilePreview();

    // This is beacuse is too fast and we need to set a normal time to move from one modal to another
    const TIMEOUT = 500;
    setTimeout(() => {
      documentSelected && documentSelected?.versions && onViewAction(documentSelected.versions[0], documentSelected?.versions)
    }, TIMEOUT);
  }

  const onSuccessDeleteAction = () => {
    onSuccessRemove?.();
  };

  const onDeleteAction = (record: IAttachment | FilePreviewData) => {
    modal.confirm({
      title: 'Do you Want to delete these items?',
      icon: <ExclamationCircleFilled />,
      onOk() {
        record.id && dispatch(deleteAttachment({ id: record.id, onSuccess: onSuccessDeleteAction })).then(() => getAllAttachments())
      }
    })
  }


  const showModalForNewRevision = (attachmentId: number) => {
    setIsModalOpenRevision(attachmentId);
  };

  const handleCloseuploadModals = () => {
    setIsModalOpen(false);
    setIsModalOpenRevision(null);
    setDocumentSelected(null);
  };

  const attachmentListWithKeys = attachmentList.map((el, key) => { return { ...el, key } }).map((el) => {
    const orderVersions = reverse(sortBy([...el.versions || [], el], "createdDate"));

    el.versions = orderVersions;

    return el;
  })

  const getDataExtension = (documentSelected: IAttachment) => {
    const extension = documentSelected.fileName ? getExtensionOfFileName(documentSelected.fileName) : "";
    const IsImageValid = isAnValidImage(extension);
    return {
      extension,
      isAnValidImage: IsImageValid
    }
  }
  
  const { projectId } = useParams<"projectId">();

  const onUploadedFile = ({ fileName } : { fileName?: string  }) => {
    dispatch(asyncLaunchNotification({
      type: "info",
      config: {
        message: `Attachments`,
        description: `${ fileName ? `File [${fileName}] was`: 'File' } uploaded Successfully, the document view may take a few minutes to appear.`
      }
    }));

    onSuccessUpload?.()

    if(projectId) {
      dispatch(getProjectDeliverablesData(String(projectId)));
    }
  }

  const addFile = () => {
    if (dragZoneRef?.current && dragZoneRef?.current?.openFileUploader) {
      dragZoneRef?.current?.openFileUploader();
    }
  }

  return (
    <>
      <>{contextHolder}</>
      
      <If condition={showHeader}>
        <Row className="pb-10 w-full" >
          <Col span={24} 
            className='flex flex-row justify-between w-full pl-10 pt-8 pb-8' 
            style={{ border: `1px solid ${colorBorder}`, color: colorText, backgroundColor: colorFillQuaternary, borderRadius: 4 }} 
          >
            <Row className='flex flex-row items-center'>
              <Typography.Title level={5} style={{ margin: 0 }}>
                Files
              </Typography.Title>
              <Badge
                count={totalItems}
                className='ml-10'
                style={{ backgroundColor: colorPrimary, borderColor: colorFillQuaternary }} />
            </Row>
            <Row className='flex flex-row items-center pr-10 cursor-pointer' style={{ color: colorPrimaryActive }} onClick={() => addFile()}>
              <FileAddOutlined />
              <span className='pl-5'>Add File</span>
            </Row>
          </Col>
        </Row>
      </If>

      <Row className='pt-5'>
        <Col span={24} >
          <DragZone
            ref={dragZoneRef}
            entityType={entityType} 
            referenceId={referenceId} 
            attachmentId={null} 
            getAllAttachments={getAllAttachments}  
            onUploaded={onUploadedFile} 
            projectId={String(projectId)}
            validExtensions={validExtensions}
            maxLimitFilesToUpload={maxLimitFilesToUpload}
            maxLimitSizeFileToUploadInMB={maxLimitSizeFileToUploadInMB}
            onMultiUploadProcessFinish={onMultiUploadProcessFinish}
          />
        </Col>
      </Row>


      <If condition={showlistOfAttachments}>
        <>
          <Row className='w-full mt-10 pt-10 justify-between' justify={"center"} align={"middle"} >
            <TableHeader />
          </Row>

          <Row className='w-full justify-between' justify={"center"} align={"middle"} >
            <MainTable
              loading={loading}
              onViewAction={onViewAction}
              onDeleteAction={onDeleteAction}
              showModalForNewRevision={showModalForNewRevision}
              attachmentListWithKeys={attachmentListWithKeys} />
          </Row>
          
          {isModalOpen && <UploadModal entityType={entityType} referenceId={referenceId} attachmentId={null} getAllAttachments={getAllAttachments} handleCloseuploadModals={handleCloseuploadModals} onUploaded={onUploadedFile} />}
          {isModalOpenRevision && <UploadModal entityType={entityType} referenceId={referenceId} attachmentId={isModalOpenRevision} getAllAttachments={getAllAttachments} handleCloseuploadModals={handleCloseuploadModals} onUploaded={onUploadedFile} />}

          {
            openFilePreview && documentSelected && (
              <ContainerFileProvider>
                <FilePreviewModal
                  open={openFilePreview}
                  toggle={toogleOpenFilePreview}
                  fileData={{ ...documentSelected }}
                  getAllAttachments={getAllAttachments}
                  showModalForNewRevision={showModalForNewRevision}
                  previewLatestVersion={previewLatestVersion}
                  onSuccessDeleteAttachment={onSuccessRemove}
                />
              </ContainerFileProvider>
            )
          }
        </>
      </If>

      


    </>
  )
}