import { getResults, setFilterByString } from "@store/slices/search";
import { CustomFilterShip } from "./CustomFilterShip";
import { useAppDispatch, useAppSelector } from "@store/store";
import { useNavigate, useSearchParams } from "react-router-dom";

export const LocationKey = "locations"
export const SitesKey = "sites"

export const FiltersActives = Object.freeze({
    [LocationKey]: "Locations",
    [SitesKey]: "Sites"
})

type keyOfFiltersActives = keyof typeof FiltersActives

export const Filters = () => {

    const dispatch = useAppDispatch();

    const { filterBy } = useAppSelector((state) => state.Search);

    const filterByKey = filterBy as keyof typeof FiltersActives;

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const onSetFilter = (key: keyOfFiltersActives) => {
        dispatch(setFilterByString(key));
        searchParams.set("filterBy", key);
        const updatedSearch = searchParams.toString();
        navigate(`?${updatedSearch}`, { replace: true });
        dispatch(getResults());
    }

    const onRemoveFilter = () => {
        dispatch(setFilterByString(""));
    }

    return (
        <>
            { filterBy && FiltersActives[filterByKey] && <CustomFilterShip filterActive={true} onClick={onRemoveFilter}>{ FiltersActives[filterByKey] }</CustomFilterShip> }

            { !filterBy && (<> { Object.keys(FiltersActives).map((key) => (
                <CustomFilterShip key={key} onClick={ () => onSetFilter(key as keyOfFiltersActives) } >{FiltersActives[key as keyOfFiltersActives]}</CustomFilterShip> )
            ) } </>) }
        </>
    )
}
