export function loadGtag(gaMeasurementId: string) {

    // Create a new script element
    const script = document.createElement('script');
    
    // Set the src attribute to the gtag.js library URL with your Google measurementId
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gaMeasurementId}`;
    
    // Append the script element to the document's head
    document.head.appendChild(script);
    
    // Define the gtag() function
    window['gtag'] = function() {
      window.dataLayer.push(arguments);
    };
    
    // Initialize the dataLayer
    window['dataLayer'] = window['dataLayer'] || [];
    
    // Set the configuration for your Google Analytics property
    window['gtag']('js', new Date());
    window['gtag']('config', gaMeasurementId);
  }
  