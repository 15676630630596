
import Code403NotAllowedComponent from '@components/__codes__/Code403NotAllowedComponent';
import { Helmet } from 'react-helmet-async';
import { configuration } from '../environments/env';

export default function NotAllowedPage() {
  return (
    <>
      <Helmet>
        <title>403 | { configuration.APP_NAME }</title>
      </Helmet>
      <Code403NotAllowedComponent />
    </>
  )
}
