export class Utils {
  static toTitleCase = (str: string) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
  };

  static roundToNearestByStep(
    number: number,
    step: number,
    min: number = Number.NEGATIVE_INFINITY,
    max: number = Number.POSITIVE_INFINITY
  ): number {
    // Ensure the number is within the provided boundaries
    number = this.clamp(number, min, max);

    const rounded = Math.round(number / step) * step;

    // If rounding pushes the number out of bounds, adjust to the closest step inside the bounds
    if (rounded > max) {
      return rounded - step;
    } else if (rounded < min) {
      return rounded + step;
    }

    return rounded;
  }

  static clamp(value: number, minValue: number, maxValue: number): number {
    return Math.max(minValue, Math.min(value, maxValue));
  }

  static roundToNearestStep(number: number, step: number) {
    return Math.round(number / step) * step;
  }

  static getScaleFromTransform(transform: string) {
    const defaultScale = { scaleX: 1, scaleY: 1 };
    if (!transform) {
      return defaultScale;
    }

    const scalePattern = /scale\(([^,]+),([^)]+)\)/;
    const match = transform.match(scalePattern);

    if (match) {
      return {
        scaleX: parseFloat(match[1]),
        scaleY: parseFloat(match[2]),
      };
    } else {
      return defaultScale;
    }
  }

  static convertMetersToFeetAndInches(lengthMeters: number) {
    const feet = Math.floor(lengthMeters * 3.28084);
    const inches = Math.round((lengthMeters * 3.28084 - feet) * 12);
    return { feet, inches };
  }
  static isNumber = (value: any) => {
    return !isNaN(value) && typeof value === "number";
  };
}
