/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { IDerivatives } from '@models/attachment.model';
import { DerivativesEnum } from "@models/enumerations/derivatives-enum.model";
import { isArrayWithValues } from "@shared/util/array-util";
import { If } from "@components/Utils/Structural";
import { isStringEmpty, isStringWithContent } from "@shared/util/validations";
import { Skeleton, theme } from "antd";
import { PictureOutlined } from "@ant-design/icons";
import { CheckBoxCircleSvgIcon } from "@components/Icons/CheckBoxCircleSvgIcon";
import { primaryColor } from "@providers/ThemeProvider";
import Color from "color"
import { IPageSelector } from "./SelectPagesStep";
import { isNumber } from '@shared/util/number-util';


interface IPageGeneratedPreviewItemProps {
  isPageChecked: boolean;
  position: number,
  derivatives: IDerivatives[],
  loading?: "eager" | "lazy",
  onCLickPageSelection?: (selection: IPageSelector) => void,
}

export const PageGeneratedPreviewItem = (props: IPageGeneratedPreviewItemProps) => {

    const { derivatives = [], onCLickPageSelection, isPageChecked = false, position } = props;

    const [signedUrl, setSignedUrl] = useState<string>("");

    const deliverableSelected = useMemo(() => {
        let derivative = {} as IDerivatives;
        if (isArrayWithValues(derivatives)) {
          const derivativeHigthResolution = derivatives.find(derivative => derivative.derivativeType === DerivativesEnum.PREVIEW);
          if (derivativeHigthResolution) {
            derivative = derivativeHigthResolution;
          } else {
            const derivativeImgPreview = derivatives.find(derivative => derivative.derivativeType === DerivativesEnum.LORES);
             if (derivativeImgPreview) {
              derivative = derivativeImgPreview;
             } else {
                const derivativeThumbnail = derivatives.find(derivative => derivative.derivativeType === DerivativesEnum.THUMBNAIL);
                if (derivativeThumbnail) {
                  derivative = derivativeThumbnail; 
                }
             }
          }
        }
        setSignedUrl(derivative?.signedUrl ?? "");
        return derivative ?? {};
    }, [derivatives])

    const { token: { colorBorder, colorBorderSecondary, colorPrimaryBgHover }} = theme.useToken();

    const [onHoverCard, setonHoverCard] = useState<boolean>(false)
    const [isChecked, setIsChecked] = useState<boolean>(isPageChecked)
    const [colorCardSelected, setColorCardSelected] = useState<string>(colorPrimaryBgHover)

    useMemo(() => {
      const newColor = Color(colorPrimaryBgHover);
      setColorCardSelected(newColor.alpha(0.40).toString());
    }, [colorPrimaryBgHover]);

    useEffect(() => {
      setIsChecked(isPageChecked)
    }, [isPageChecked])

    const onCheck = () => {
      setIsChecked((value) => (!value));

      if (isNumber(deliverableSelected?.pageNumber)) {
        onCLickPageSelection?.({
          pageNumber: deliverableSelected?.pageNumber!,
          isSelected: !isChecked,
          position
        });
      }
    }
    
    return (
      <>
        <If condition={isStringWithContent(signedUrl)}>
            <div 
              className="relative"
              onMouseEnter={() => setonHoverCard(true) }
              onMouseLeave={() => setonHoverCard(false) }
            >
                <img 
                    src={signedUrl}
                    className="relative cursor-pointer"
                    style={{
                        backgroundColor: colorBorderSecondary,
                        borderRadius: 4,
                        objectFit: "cover",
                        aspectRatio: 16 / 9,
                        overflow: "hidden",
                        width: '100%'
                    }}
                    { ...props.loading && { loading: props.loading }}
                    alt=""
                />
                <div className="z-10 flex flex-row items-center justify-center" style={{
                    border: `1px solid ${isChecked ? primaryColor : colorBorder}`,
                    backgroundColor: onHoverCard ? 'transparent' : isChecked ? colorCardSelected : 'rgba(32, 32, 32, 0.20)',
                    outline: onHoverCard ? `3px solid ${isChecked ? primaryColor : colorPrimaryBgHover}` : isChecked ? `3px solid ${primaryColor}`: 'unset',
                    borderRadius: 4,
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0
                }}>
                  <CheckBoxCircleSvgIcon 
                    checked={isChecked} 
                    onClick={onCheck} 
                    className="cursor-pointer" 
                  />
                </div>
            </div>
        </If>
        <If condition={isStringEmpty(signedUrl)}>
          <LoadingCustomImageSkeleton />
        </If>
      </>
    )
}

export const LoadingCustomImageSkeleton = (props: { active?: boolean }) => {
  const { active = false } = props;
  const { token } = theme.useToken()
  return (
      <Skeleton.Node active={active} className="relative w-full flex items-center" prefixCls="custom-skeleton" style={{
          borderRadius: 4,
          objectFit: "cover",
          aspectRatio: 1 / 1
      }}>
          <div className="w-full flex items-center justify-center">
              <PictureOutlined style={{ color: token.colorTextQuaternary, fontSize: '3rem' }}  />
          </div>
      </Skeleton.Node>
  )
}