import { http } from '../http/axios.instance';
import { IUser } from '../../models/user.model';
import { IQueryParamsGeneric } from '@models/utils';

const path = 'admin/users';
const filterUsersPath = "users-extended/filter-by"

export const usersRepository = {
  getAll: async (params: IQueryParamsGeneric) => {

    const _params = new URLSearchParams({
      ...params,
      cacheBuster: `${new Date().getTime()}`
    })
    const requestUrl = `${filterUsersPath}?${_params.toString()}`;

    const promiseGetAll = await http.get<IUser[]>(requestUrl);
    return promiseGetAll;
  },
  getByUserLogin: async (userLogin: string) => {

    const requestUrl = `${path}/${userLogin}`;

    const promiseGetByLogin = await http.get<IUser[]>(requestUrl);
    return promiseGetByLogin;
  },
  create: async (entity: IUser) => {

    const requestUrl = `${path}`;
    const user = await http.post<IUser>(requestUrl, entity);
    return user;
  },
  update: async (id: string | number, entity: IUser) => {

    const requestUrl = `${path}`;
    const user = await http.put<IUser>(requestUrl, entity);
    return user;
  },
  resetPassword: async (email: string) => {

    const requestUrl = `/account/reset-password/init`;

    const config = {
      headers: {
          'Content-Length': 0,
          'Content-Type': 'text/plain'
      }
    };

    const user = await http.post(requestUrl, `${email}`, config);
    return user.data;
  },

}