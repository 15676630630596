/* eslint-disable react-hooks/exhaustive-deps */
import { ComponentType, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Badge, ConfigProvider, Layout, theme } from 'antd';
import { Logo } from '../Logo/Logo';
import { useThemeProvider } from '../../providers/ThemeProvider';
import { useLayoutContex } from '../../providers/LayoutProvider';
import { TriggerColapseNavSide } from './TriggerColapseNavSide';
import './LayoutPage.less';
import { PoweredByBrand } from './PoweredByBrand';
import { SITE_RISE_OFF_WHITE } from '@providers/ThemeProvider';
import { MobileHeaderMenu } from './MobileHeaderMenu/MobileHeaderMenu';
import { If } from '../Utils/Structural';
import { useBreakpoints } from '@HOOKs/UseBreakpoints';
import { isDevelopment, isStaging } from '@environments/env';
import { MainMenu } from './SideMenu/Menu';
const { Sider } = Layout;

export interface ILayoutProps {
  children?: any | undefined,
  MenuLayout?: ComponentType<any> | undefined
}

export const LayoutPage = ({
  children,
  MenuLayout
}: ILayoutProps) => {

  const { themeConfig } = useThemeProvider();

  const { collapseNav } = useLayoutContex();

  const { isMobile } = useBreakpoints();

  const stagingBadgeColor = '#FA8C16'

  const { token: { colorBorderSecondary, colorFillQuaternary } } = theme.useToken();
  const dynamicBackground = `linear-gradient(0deg, ${colorFillQuaternary} 0%, ${colorFillQuaternary}  100%), ${themeConfig.secondaryColor} `;

  const shouldShowBadgeRibbon: boolean = Boolean(isDevelopment() || isStaging());

  const logo = useMemo(() => {
    return (
      <div className="logo-container mt-40 pt-5 mb-20">
        <Link to={"/"} className="cursor-pointer" >
          <Logo
            isCollapsed={collapseNav}
            classStyle={(collapseNav ? "logo-container-img-collapsed" : "logo-container-img-expanded")}
          />
        </Link>
      </div>
    )
  }, [collapseNav]);

  return (
    <Layout className='h-screen flex flex-row'>

      <>
        <If condition={!isMobile}>
          <Sider trigger={null} collapsible collapsed={collapseNav}
            style={{
              background: dynamicBackground,
              borderRight: `1px solid ${colorBorderSecondary}`
            }} width={180}>
            <div className='flex flex-col justify-between h-full'>
              <div className='flex flex-col relative pt-5'>

                {/* Logo Conditional Rendering */}
                <If condition={shouldShowBadgeRibbon}>
                  <Badge.Ribbon style={{ zIndex: 1 }} text={<BadgeRibbonTitle collapseNav={collapseNav} />} color={stagingBadgeColor}>
                    {logo}
                  </Badge.Ribbon>
                </If>
                <If condition={!shouldShowBadgeRibbon}>
                  {logo}
                </If>

                {MenuLayout ? <MenuLayout /> :

                  <ConfigProvider theme={{ token: { colorText: SITE_RISE_OFF_WHITE } }}>

                    <MainMenu menuItemSelectedPlacement='right' />

                  </ConfigProvider>

                }
              </div>

              <ConfigProvider theme={{ token: { colorPrimary: themeConfig.secondaryColor }, algorithm: theme.defaultAlgorithm }}>
                <div className='mb-30'>
                  <PoweredByBrand />
                  <TriggerColapseNavSide />
                </div>
              </ConfigProvider>

            </div>
          </Sider>

        </If>


        <If condition={Boolean(isMobile)}>
          <div className='flex flex-col w-full'>
            <MobileHeaderMenu />
            {children}
          </div>
        </If>

        <If condition={!Boolean(isMobile)}>
          {children}
        </If>

      </>

    </Layout >
  );

}

export const BadgeRibbonTitle = ({ collapseNav }: { collapseNav: boolean }) => {
  
  const label = useMemo(() => {
      const shouldShowShortLabel = (collapseNav === true) || (typeof collapseNav !== "boolean");
      if (isDevelopment()) {
        return shouldShowShortLabel ? "Dev" : "Development";
      }
      if (isStaging()) {
        return "Staging";
      }
      return "";
  }, [collapseNav]);

  return (
    <div data-testid="container_badge" className='pt-2 pb-2 pl-5 pr-10'>
      <span className="flex flex-row items-center" >
        <span data-testid="container_badge_text" className='font-semibold' style={{ fontSize: 11 }}>
          { label }
        </span>
      </span>
    </div>
  )
}
