/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useLayoutContex } from '@providers/LayoutProvider';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

export const TabContainer: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...restProps }) => {    
    return (
        <HeightTabObserverContainer>
            {children}
        </HeightTabObserverContainer>
    )
}

export const HeightTabObserverContainer: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...restProps }) => {

    const { layoutSize, headerSize } = useLayoutContex(); 
    
    const reference = useRef<HTMLDivElement>(null);

    const [heightTab, setheightTab] = useState(0);

    const { xs } = useBreakpoint();

    const heightCalculated = useMemo(() => ({ height: xs ? `100%`: `${heightTab}px` }), [heightTab, xs]);

    useLayoutEffect(() => {
        const topPosition = (reference?.current && reference?.current?.getBoundingClientRect().top) || 0
        const tabBarHeightRelative = topPosition - headerSize;
        setheightTab(layoutSize?.layoutHeight - tabBarHeightRelative);
    }, [layoutSize?.layoutHeight])
    
    return (
        <div ref={reference} className="w-full" {...restProps}>
            <ContainerResizableObserved heightCalculated={heightCalculated}>
                {children}
            </ContainerResizableObserved>
        </div>
    )
}

export const ContainerResizableObserved = ({ children, heightCalculated }: { children: ReactNode, heightCalculated: { height: string} }) => {
    return (
        <div style={heightCalculated} className="overflow-y-auto w-full h-full" > 
            {children} 
        </div> 
    )
}
