import React from 'react'
import { useState, useEffect } from 'react';
import { PREFERENCES_KEYS } from './PreferencesModal';
import { PreferencesNotifications } from './PreferencesNotifications';
import { PreferencesTheme } from './PreferencesTheme';

interface IPreferencesContentModalProps {
    keyComponentToSetVisible: string
}

export const PreferencesContentModal: React.FC<IPreferencesContentModalProps> = ({ keyComponentToSetVisible }) => {

    const [preferenceKeySelected, setPreferenceKeySelected] = useState(keyComponentToSetVisible);
        
    useEffect(() => {
        setPreferenceKeySelected(keyComponentToSetVisible);
    }, [keyComponentToSetVisible])

    const ComponentMap = {
        [PREFERENCES_KEYS.THEMES]: (<PreferencesTheme />),
        [PREFERENCES_KEYS.NOTIFICATIONS]: (<PreferencesNotifications />)
    }

    return (<>{ ComponentMap[preferenceKeySelected] && ComponentMap[preferenceKeySelected] }</>)
}
