import { DeleteOutlined, FileAddOutlined, FileSyncOutlined } from "@ant-design/icons";
import { EntryTime } from "@components/Timeline/EntryTime";
import { TimelineEntryTypeEnum } from "@models/enumerations/timeline-entry-type-enum.model";
import { ITimelineEntry } from "@models/timeline.model";
import { Col, Row } from "antd";
import { useTranslation } from 'react-i18next';

export interface IEntryBodyProps {
    value: ITimelineEntry
}

export const EntryBody = (props: IEntryBodyProps) => {
    const { value } = props;
    const { t } = useTranslation();

    const icon = () => {
        return (<>
            {value.type === TimelineEntryTypeEnum.CHANGE_ORDER_FILE_ADDED && <FileAddOutlined />}
            {value.type === TimelineEntryTypeEnum.CHANGE_ORDER_FILE_REVISION_ADDED && <FileSyncOutlined />}
            {value.type === TimelineEntryTypeEnum.CHANGE_ORDER_FILE_REMOVED && <DeleteOutlined />}
            {value.type === TimelineEntryTypeEnum.CHANGE_ORDER_FILE_REVISION_REMOVED && <DeleteOutlined />}
        </>)
    }

    const action = () => {
        return (<>
            {value.type === TimelineEntryTypeEnum.CHANGE_ORDER_FILE_ADDED && "1 " + t("changeOrder.file") + " " + t("changeOrder.added")}
            {value.type === TimelineEntryTypeEnum.CHANGE_ORDER_FILE_REVISION_ADDED && "1 " + t("changeOrder.fileRevision") + " " + t("changeOrder.added")}
            {value.type === TimelineEntryTypeEnum.CHANGE_ORDER_FILE_REMOVED && "1 " + t("changeOrder.file") + " " + t("changeOrder.removed")}
            {value.type === TimelineEntryTypeEnum.CHANGE_ORDER_FILE_REVISION_REMOVED && "1 " + t("changeOrder.fileRevision") + " " + t("changeOrder.removed")}
        </>)
    }

    const getTitle = () => {
        return <>
            {icon()}
            <span className="ml-5">{action()} {t("changeOrder.by")} </span>
            <span className="font-bold">{value.user?.fullName}</span>
        </>
    }

    return (<>
        <Row className="w-full pr-10">
            <Col span={17}>{getTitle()}</Col>
            <Col span={7} className="text-right"><EntryTime date={value.date} /></Col>
        </Row>
    </>);
}
