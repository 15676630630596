import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Badge, Button, Col, ConfigProvider, Row, Table, Tag, theme } from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import { useAppDispatch, useAppSelector } from "@store/store";

/* eslint-disable react-hooks/exhaustive-deps */
import { EmptyCellTable } from "@components/Utils/EmptyCellTable";
import { IDeliverable } from "@models/deliverable.model";
import { getEntities } from "@store/slices/deliverable";
import { PlusOutlined  } from "@ant-design/icons";
import { SearchTable } from "@components/Utils/SearchTable";
import { DeliverableEdit } from "./DeliverableEdit";
import { TabContainer } from "@components/Utils/TabContainer";
import useVisible from "@HOOKs/UseVisible";
import { defaultSorter } from "@shared/util/table-utils";
import { BackButton } from "@components/Utils/BackButton";
import { ChevronRightSvgIcon } from "@components/Icons/ChevronRightSvgIcon";
import { useTranslation } from "react-i18next";
import { useLayoutContex } from "@providers/LayoutProvider";

export const DeliverableListTab = () => {
    
    const { token: { 
        marginLG, 
        colorPrimary, 
        fontSize, 
        colorBgContainer, 
        colorBorderSecondary, 
        colorText,
        colorFillQuaternary } } = theme.useToken();

    const { t } = useTranslation();

    const [openEditDeliverable, toogleEditDeliverable] = useVisible();

    const columns: ColumnsType<IDeliverable> = [
        {
            title: '',
            width: "85px",
            render: (deliverable: IDeliverable) => { return (
                <div 
                    onClick={() => editDeliverable(deliverable)}
                    className="flex flex-row items-center cursor-pointer" 
                    style={{ color: colorPrimary }}
                >
                    <span>Edit</span>
                    <ChevronRightSvgIcon className="ml-8 scale-90" />
                </div>
            ) },
        },
        {
            title: 'Name',
            sorter: ({ name: a }, {name: b}) => defaultSorter(a, b),
            defaultSortOrder: 'ascend',
            render: (deliverable: IDeliverable) => {
                return (
                    <span>{deliverable?.name}</span>
                ) || <EmptyCellTable/> 
            }
        },
        {
            title: t('admin.discipline'),
            dataIndex: 'discipline',
            sorter: ({ discipline: a }, {discipline: b}) => defaultSorter(a?.name, b?.name),
            render: (value) => { return <span>{value?.name}</span> || <EmptyCellTable/> }
        },
        {
            title: 'Role',
            dataIndex: 'role',
            sorter: ({ role: a }, {role: b}) => defaultSorter(a?.name, b?.name),
            render: (value) => { return <span>{value?.name}</span> || <EmptyCellTable/> },
            ellipsis: true,
        },
        {
            title: t('admin.companyRole'),
            dataIndex: 'companyRole',
            sorter: ({ companyRole: a }, {companyRole: b}) => defaultSorter(a?.description, b?.description),
            render: (value) => { return <span>{value?.description}</span> || <EmptyCellTable/> },
            ellipsis: true,
        },
        {
            title: '',
            render: (deliverable: IDeliverable) => { return <>
                {deliverable.isKeyMilestone && <Tag>Key Milestone</Tag>}
                {deliverable.isOpeningMilestone && <Tag>Opening Milestone</Tag>}
            </> || <EmptyCellTable/> },
        }
    ];

    const { entities: deliverableList, totalItems, loading } = useAppSelector((state) => state.Deliverables);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { setHeaderLabelEntityState } = useLayoutContex()
    
    useEffect(() => {
        setHeaderLabelEntityState(t("admin.deliverables"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [deliverableSelected, setDeliverableSelected] = useState<IDeliverable>({})

    const [tableParams, setTableParams] = useState({
        size: 10,
        current: 0,
        sort: `name,asc`
    });

    const filterDeliverables = () => {
        dispatch(
            getEntities({
                ...tableParams,
                ...filterValue && { nameFilter: filterValue },
            })
        );
    }

    useEffect(() => {
        filterDeliverables();
    },[tableParams]);

    const onChange: TableProps<IDeliverable>['onChange'] = (pagination, filters, sorter, extra) => {
        setTableParams((oldValue) => { 
            return {
                ...oldValue, 
                ...pagination?.pageSize && { size: pagination.pageSize },
                ...filters,
                current: pagination.current !== undefined ? pagination.current - 1 : 0
            }
        })
    };

    const editDeliverable = (deliverable: IDeliverable) => {
        setDeliverableSelected({...deliverable});
        toogleEditDeliverable()
    }

    const addDeliverable = () => {
        setDeliverableSelected({ isKeyMilestone: false, isOpeningMilestone: false });
        toogleEditDeliverable()
    }

    const [filterValue, setFilterValue] = useState<string>("");

    const onChangeValueToSearch = (e: any) => {
        setFilterValue(e.target.value);
    }

    const handleOnSearch = () => {
        filterDeliverables();
    }

    const onModifiedChanges = (deliverable: IDeliverable) => {
        filterDeliverables();
    }

    const TableComponentWrapper = useCallback((node: any) => {
        const style = { border: `1px solid ${colorBorderSecondary}`, borderRadius: 4 }
        return (
            <table style={{ ...node.style, ...style }}>
                { node.children.map((child: any) => child) }
            </table>
        ); 
    }, [colorBorderSecondary]);

    const navigateBack = () => {
        navigate('/admin/module-configuration/project-deliverables')
    }

    const onClear = () => {
        setFilterValue('');
        dispatch(
            getEntities({
                ...tableParams
            })
        );
    }

    return (
        <>
            <DeliverableEdit open={openEditDeliverable} toogle={toogleEditDeliverable} deliverable={deliverableSelected} onModifiedChanges={onModifiedChanges} />
            <TabContainer>
                <div className="flex flex-col overflow-auto">
                    <Row>
                        <Col span={24}>
                            <div className="flex flex-row items-center pl-10 pt-11 pb-11" style={{ borderTop: `1px solid ${colorBorderSecondary}`, borderBottom: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                                <BackButton onClick={navigateBack} >{t("admin.projectDeliverables")}</BackButton>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="pt-20 overflow-auto" style={{ paddingLeft: marginLG, paddingRight: marginLG }}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={20}>
                            <div className="flex flex-row items-center justify-between" style={{ borderRadius: 4, border: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                                <span className="pt-10 pb-10 pl-20 font-medium" style={{ color: colorText, fontSize: fontSize + 2 }} >
                                    Deliverables
                                    <Badge
                                        count={totalItems}
                                        className='ml-10'
                                        style={{ backgroundColor: colorPrimary, borderColor: colorFillQuaternary }} />
                                </span>
                                <div className="flex flex-row items-center">
                                    <SearchTable className="ml-20" handleOnSearch={handleOnSearch} onChange={onChangeValueToSearch} placeholder="Quick Filter" onClear={onClear} />
                                    <Button type="primary" className="ml-20 mr-7" onClick={addDeliverable}>
                                        <PlusOutlined />
                                        Add deliverable
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-10">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={20} >
                            <ConfigProvider theme={{ token: { borderRadius: 2 } }}>
                                <Table 
                                    loading={loading}
                                    rowKey="id" 
                                    size="small" 
                                    columns={columns} 
                                    dataSource={deliverableList} 
                                    onChange={onChange}
                                    rowClassName={"hover-in-row"}
                                    components={{
                                        table: TableComponentWrapper
                                    }}
                                    pagination={ { current: tableParams.current + 1, pageSize: tableParams.size, total: totalItems }}
                                />
                            </ConfigProvider>
                        </Col>
                    </Row>
                </div>
            </TabContainer>
        </>
    )
}