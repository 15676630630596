import { Badge, theme } from "antd";

interface IThumbnailCustomBadgeProps extends React.ComponentPropsWithoutRef<typeof Badge> {}

export const ThumbnailCustomBadge = (props: IThumbnailCustomBadgeProps) => {
    
  const { token: { colorPrimaryTextActive }} = theme.useToken();
    return (
      <Badge 
        style={{
          background: "var(--primary-color-1)",
          color: colorPrimaryTextActive
        }}
        {...props} 
      />
    )
}
