import { useState } from "react";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { IAttachment } from "@models/attachment.model";
import { attachmentService } from "@services/attachment.service";
import { Tooltip, theme } from "antd";

interface IDownloadButtonProps {
    attachment: IAttachment;
}

export const DownloadButton = (props: IDownloadButtonProps) => {

    const { attachment } = props;

    const [loadingDownload, setLoadingDownload] = useState(false);

    const { token: { colorPrimary, colorTextTertiary }} = theme.useToken();

    const onDownloadAction = (attachmentId: string, fileName: string): Promise<void> => {
        setLoadingDownload(true);
        const handleGetViewLink = (response) => {
            if (response) window.open(`${response.data}`, "_blank");
        }
        return attachmentService.getViewLink(attachmentId)
                                .then(handleGetViewLink)
                                .finally(() => setLoadingDownload(false));
    }

    const onClicDownload = () => {
        attachment.id && attachment.fileName && onDownloadAction(String(attachment.id), attachment.fileName)
    }
    
    return (
        <Tooltip placement="bottom" title={"Download"}>
            {
                loadingDownload
                    ? <LoadingOutlined className="cursor-pointer" style={{ color: colorPrimary, fontSize: 20 }} />
                    : <DownloadOutlined className="cursor-pointer" onClick={onClicDownload} style={{ color: colorTextTertiary, fontSize: 20 }} />
            }
        </Tooltip>
    )
}
