import { useState } from "react";

export const useErrorAnimation = () => {
  const [animateError, setAnimateError] = useState(false);

  const playAnimationOfError = () => {
    setAnimateError(true);
    setTimeout(() => {
      setAnimateError(false);
    }, 500);
  };

  return [animateError, playAnimationOfError] as const;
};
