import { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { AppDispatch, useAppSelector } from '@store/store';
import { useDispatch } from 'react-redux';
import {
    getEntity,
} from "@store/slices/company";
import { EmptyLabel } from '../Utils/EmptyLabel';
import { ICompany } from '@models/company.model';

export const CompaniesNameLabel = () => {

    const dispatch = useDispatch<AppDispatch>();
    const { entity: companyEntity } = useAppSelector((state) => state.Company);

    const { id } = useParams<"id">();

    useEffect(() => {
        if (id && companyEntity && (String(companyEntity.id) !== id)) {
            dispatch(getEntity(id));
        }
    }, [dispatch, id, companyEntity]);

    const getSiteLabel = (companyEntity: ICompany) => {
        return companyEntity ? companyEntity.name : '';
    }

    return (
        <div>{ `${getSiteLabel(companyEntity)}` || <EmptyLabel />}</div>
    )
}
