import { CompanyExtended, ICompany } from '../../models/company.model';
import { IQueryParams } from '../../models/pagination';
import { http } from '../http/axios.instance';
import { StringORNumber } from './utils.repository';

const path = 'companies';

export const companyRepository = {
  getCompanies: async (queryParams: IQueryParams) => {
    
    const { sort, page, size } = queryParams;
    const requestUrl = `${path}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  
    const promiseGetAll = await http.get<CompanyExtended[]>(requestUrl);
    return promiseGetAll;
  },
  getCompaniesByLocationId: async (id: StringORNumber) => {

    const requestUrl = `${path}/${id}`;
    const promiseGetByID = await http.get<ICompany>(requestUrl);
    return promiseGetByID;
  },
  getCompanyById: async (id: StringORNumber) => {
    const requestUrl = `${path}/${id}`;
    const promiseGetByID = await http.get<ICompany>(requestUrl);
    return promiseGetByID;
  },
  createCompany: async (entity: ICompany): Promise<ICompany> => {

    const requestUrl = `${path}`;
    const promiseCreate = await http.post<ICompany>(requestUrl, entity);
    return promiseCreate.data;
  },
  updateCompany: async (id: string | number, entity: ICompany): Promise<ICompany> => {

    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.put<ICompany>(requestUrl, entity);
    return promiseUpdate.data;
  },
  deleteCompany: async (id: string | number) => {

    const requestUrl = `${path}/${id}`;
    const promiseDelete = await http.delete<ICompany>(requestUrl);
    return promiseDelete;
  },
}