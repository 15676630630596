import { generate } from "@ant-design/colors";
import { useState, useLayoutEffect } from "react";
import { ThemeContextProviderType, useThemeProvider } from "../providers/ThemeProvider";

export const useProviderColor = (color: string) => {
  
  const originalColor = color;

  const colorsDark = generate(originalColor, {
    theme: "dark",
    backgroundColor: "#ffffff",
  });

  const [expectedColor, setExpectedColor] = useState(originalColor);

  const { themeConfig } = useThemeProvider() as ThemeContextProviderType;

  useLayoutEffect(() => {
    themeConfig.darkMode ? setExpectedColor(colorsDark[0]) : setExpectedColor(originalColor)
  }, [themeConfig, colorsDark, originalColor])

  return [expectedColor];
};
