import { http } from '../http/axios.instance';
import { IProjectDeliverable , IProjectDeliverableStartEndDates } from 'models/project-deliverable';

const path = "project-deliverables"
const extendedPath = "project-deliverables-extended"

export const projectDeliverableRepository = {
  updateProjectDeliverable: async (id: string, entity: Partial<IProjectDeliverable>) => {

    const requestUrl = `${path}/${id}`;
    const brand = await http.patch<Partial<IProjectDeliverable>>(requestUrl, entity);
    return brand.data;
  },
  requestForApproval: async (id: string) => {
    const requestUrl = `${path}/${id}/request-for-approval`;
    const response = await http.post(requestUrl);
    return response.data;
  },
  removeActualOrStartEndDates: async (id: string , entity: IProjectDeliverableStartEndDates) => {
    const requestUrl = `${extendedPath}/${id}/undo-date`;
    const response = await http.put<IProjectDeliverableStartEndDates>(requestUrl, entity);
    return response.data;
  },
}
