import { useState } from 'react';
import { Button, Popover, theme, ConfigProvider } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { EditSvgIcon } from '@components/Icons/EditSvgIcon';

export interface componentProps {
    openEdition: () => void
}

export const PunchListItemMenu = (props: componentProps) => {

    const { openEdition } = props

    const [openned, setOpenned] = useState(false);
    
    const { token: { colorFill } } = theme.useToken();

    const onEdit = () => {
        setOpenned(!openned);
        openEdition();  
    }
    
    const content = (
        <div className="pb-5 pt-10" style={{ width: '180px' }}>
            <div className="flex items-center mb-5 cursor-pointer menu-item pt-3 pb-5 " onClick={ () => onEdit()  }>
                <EditSvgIcon className="ml-15" />
                <span className="ml-10">Edit</span>
            </div>
        </div>
    )

    return (
        <ConfigProvider theme={{ token: { borderRadius: 0 } }}>
            <Popover content={content} open={openned} onOpenChange={ () => setOpenned(!openned) } placement="bottomRight" trigger="click" overlayClassName="wrapper-popover-menu">
                <div className="pr-5">
                    <Button type="text" shape="circle" ghost={false} style={{ backgroundColor: colorFill }} className='flex flex-row justify-center items-center' icon={
                        <EllipsisOutlined className="font-extrabold cursor-pointer" style={{ fontSize: "1.2rem" }} />
                    } >  
                    </Button>
                </div>
            </Popover>
        </ConfigProvider>
    )

}

