import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip, theme } from 'antd';

export interface IInfoIconProps {
    tooltip?: string,
}

export const InfoIcon = (props: IInfoIconProps) => {

    const { tooltip } = props;

    const { token: { colorTextTertiary } } = theme.useToken();

    return (<Tooltip title={tooltip}><InfoCircleOutlined style={{ color: colorTextTertiary }} /></Tooltip>);
}