import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getPunchListCategories } from "@store/slices/punch-list-category";
import { Card, Switch, Tag, theme } from "antd";
import useVisible from "@HOOKs/UseVisible";
import { EditOutlined } from "@ant-design/icons";
import { NEUTRAL_7_COLOR } from "@providers/ThemeProvider";
import { CategoryEdit } from "./CategoryEdit";
import "@styles/components/admin-item-card.less";
import { useTranslation } from "react-i18next";
import { IPunchListItemCategory } from "@models/punch-list.model";

const ListItem = (punchListCategory: IPunchListItemCategory) => {
  const { t } = useTranslation();
  return <li style={{ color: NEUTRAL_7_COLOR }} className="pb-4">{punchListCategory.description} {punchListCategory.active && <Tag>{t("generic.active")}</Tag>}</li>
}

interface ListItemEditableProps {
  punchListItemCategory: IPunchListItemCategory;
  punchListItemCategorySelected: IPunchListItemCategory;
  editPunchListItemCategoryClicked: any;
}

const ListItemEditable = (props: ListItemEditableProps) => {

  const { t } = useTranslation();
  const { punchListItemCategory, punchListItemCategorySelected, editPunchListItemCategoryClicked } = props;

  return <div className={`list-item-editable ${(punchListItemCategorySelected?.id === punchListItemCategory.id ? 'selected' : '')}`} onClick={() => editPunchListItemCategoryClicked(punchListItemCategory)}>
    <div>
      <div className="handle-container">
        <div className="handle-dot"></div>
      </div>
    </div>
    <div className="description">
      {punchListItemCategory.description} {punchListItemCategory.active && <Tag>{t("generic.active")}</Tag>}
    </div>
    <span className="edit-container">
      <span className="edit-link">
        <EditOutlined /> {t("generic.edit")}
      </span>
    </span>
  </div>
}

export const CategoriesCard = (props: any) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { token: { colorPrimary, colorTextDisabled } } = theme.useToken();

  const loadPunchListCategories = () => {
    dispatch(
      getPunchListCategories()
    );
}

  useEffect(() => {
    loadPunchListCategories();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { entities: punchListCategories } = useAppSelector((state) => state.PunchListCategory);

  const [openEditPunchListCategory, toogleEditPunchListCategory] = useVisible();
  const [punchListCategorySelected, setPunchListCategorySelected] = useState<IPunchListItemCategory>({})
  const [isEditing, setIsEditing] = useState<boolean>(false)

  useEffect(() => {
    if (!openEditPunchListCategory) {
        setPunchListCategorySelected({})
    }
  }, [openEditPunchListCategory]);

  const onModifiedChanges = (role: IPunchListItemCategory) => {
    loadPunchListCategories();
  }

  const toggleEdit = () => {
    setIsEditing(!isEditing)
  };

  const editPunchListCategory = (punchListCategory : IPunchListItemCategory) => {
    setPunchListCategorySelected(punchListCategory)
    toogleEditPunchListCategory()
  }

  const createNewPunchListCategory = () => {
    setPunchListCategorySelected({})
    toogleEditPunchListCategory();
  }

  const categoriesSorted = useMemo(() => {
    return [...punchListCategories].sort((a, b) => {
      return (a.description || '').localeCompare((b.description || ''));
    });
  }, [punchListCategories]);

  return (
    <>
      <CategoryEdit open={openEditPunchListCategory} toogle={toogleEditPunchListCategory} punchListItemCategory={punchListCategorySelected} onModifiedChanges={onModifiedChanges} />
      <Card title={<>{t("admin.category")}<span className="item-count">{punchListCategories?.length}</span></>} bordered={false} className="admin-item-card" extra={<div><span className="extra-label" style={{ color: (isEditing ? colorPrimary : colorTextDisabled) }} onClick={toggleEdit}>{isEditing ? t('admin.managing') : t('admin.manage')}</span><Switch checked={isEditing} onClick={toggleEdit} /></div>}>
        <ul className={isEditing ? 'editing' : ''}>
            {categoriesSorted.map(category => 
            <div
                key={category.id}>
                <>
                {isEditing && <ListItemEditable punchListItemCategory={category} punchListItemCategorySelected={punchListCategorySelected} editPunchListItemCategoryClicked={editPunchListCategory} />}
                {!isEditing && <ListItem {...category} />}
                </>
            </div>
            )}
        </ul>
        {isEditing && <>
            <div className="spacer"></div>
            <span onClick={createNewPunchListCategory} className="add-new-btn">+ {t("admin.addNewCategory")}</span>
        </>}
        </Card>
    </>
  )
}