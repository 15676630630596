
import { isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, isRejectedAction, serializeAxiosError } from "../reducer.util";
import { createAsyncThunk } from "@reduxjs/toolkit"
import { companyContactService } from '../../services/company-contact.service';
import { IQueryParams } from "../../models/pagination";
import { cleanEntity } from '../../shared/util/entity-utils';
import { ICompanyContact, defaultValue } from '../../models/company.contact.model';

const nameOfEntity = 'companyContact';
const service = companyContactService;

export const getEntities = createAsyncThunk(
  `${nameOfEntity}/fetch_entity_list`,
  async (queryParams: IQueryParams) => {
    return service.getAll(queryParams);
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  `${nameOfEntity}/fetch_entity`,
  async (id: string | number) => {
    return service.getById(id);
  },
  { serializeError: serializeAxiosError }
);

export const getEntityByCompany = createAsyncThunk(
  `${nameOfEntity}/fetch_entity_by_company`,
  async (id: string | number) => {
    return service.getByCompanyId(id);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  `${nameOfEntity}/create_entity`,
  async (entity: ICompanyContact, thunkAPI) => {
    const result = await service.create(cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  `${nameOfEntity}/update_entity`,
  async (entity: ICompanyContact, thunkAPI) => {
    const result = entity.id && (await service.update(entity.id, cleanEntity(entity)));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntityPartial = createAsyncThunk(
  `${nameOfEntity}/update_entity_partial`,
  async (entity: ICompanyContact, thunkAPI) => {
    const result = entity.id && await service.updatePartial(entity.id, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  `${nameOfEntity}/delete_entity`,
  async (id: string | number, thunkAPI) => {
    const result = await service.delete(id);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

const initialState: EntityState<ICompanyContact> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export const CompanyContactSlice = createEntitySlice({
  name: nameOfEntity,
  initialState,
  reducers: {
    clearEntity: state => {
      state.entity = defaultValue;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntityByCompany.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data && Array.isArray(action?.payload?.data)) state.entities = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        const xTotalCount = headers['x-total-count'];

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: xTotalCount ? parseInt(xTotalCount, 10) : data.length,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, updateEntityPartial), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload as ICompanyContact;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.entity = {
        firstName: '-',
        lastName: '-',
      };
      state.loading = false;
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.error.message;
    });
  },
});

export const { reset, clearEntity } = CompanyContactSlice.actions;

// Reducer
export default CompanyContactSlice.reducer;

  