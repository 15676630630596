import { TabContainer } from "@components/Utils/TabContainer"
import { Badge, Button, Col, Row, theme } from "antd"
import { BackButton } from "@components/Utils/BackButton"
import { useNavigate } from "react-router-dom"
import { SearchTable } from "@components/Utils/SearchTable"
import { DragHandle } from "@components/Icons/DragHandle"
import { useAppDispatch, useAppSelector } from "@store/store";
import { ICostCategoryExtended } from "@models/cost-category.model";
import { ICostSubCategory, ICostSubCategoryExtended } from "@models/cost-sub-category.model";
import { costCategoryService } from "@services/cost-category.service";
import { costSubCategoryService } from "@services/cost-sub-category.service";
import { EditOutlined } from "@ant-design/icons"
import "@styles/components/admin-cost-groups.less";
import { getCostGroupingsExtended, reorderCostGroupings } from "@store/slices/cost-grouping";
import { ICostGrouping } from "@models/cost-grouping.model"
import { useEffect, useState } from "react"
import { useLayoutContex } from "@providers/LayoutProvider"
import { CostGroupEdit } from "./CostGroupEdit"
import useVisible from "@HOOKs/UseVisible"
import { CostCategoryEdit } from "./CostCategoryEdit"
import { CostSubCategoryEdit } from "./CostSubCategoryEdit"
import { asyncLaunchNotification } from "@store/slices/notification"
import { useTranslation } from "react-i18next";
import { sortByOrderProperty } from "@shared/util/array-util"
import { reorderCostCategories } from "@store/slices/cost-category"
import { reorderCostSubCategories } from "@store/slices/cost-sub-category"

const DraggableItem = ({id, name, count, selected, onSelect, onEdit, handleDragStart, handleDragEnter, handleDragEnd}) => {

    const { token: { colorPrimary, colorPrimaryBg, colorFillTertiary, colorFill, colorFillSecondary, colorPrimaryBorder, colorTextTertiary } } = theme.useToken();
    const { t } = useTranslation();

    return <div 
    draggable={true}
    onDragStart={(event) => handleDragStart(event, id)}
    onDragEnter={(event) => handleDragEnter(event, id)}
    onDragEnd={handleDragEnd} 
    className="mt-10 draggable-item" 
    style={{ background: selected ? colorPrimaryBg : colorFillTertiary, border: `1px solid ${(selected ? colorPrimaryBorder : colorFill)}` }} 
    onClick={() => { onSelect(id) }}>
        <div className="mr-8 drag-handle" style={{ marginTop: '2px' }}>
            <DragHandle fill={selected ? colorPrimary : undefined} />
        </div>
        <div style={{ color: (selected ? colorPrimary : colorTextTertiary), fontSize: '14px' }}>
            {name}
        </div>
        <div style={{ flex: 1 }}>
            <Badge
                count={count}
                className='ml-10'
                style={{ backgroundColor: colorFillSecondary, color: colorTextTertiary }} />
        </div>
        <div className="edit-link">
            <Button type="link" style={{ color: colorPrimary }} onClick={(e) => { e.stopPropagation(); onEdit(id) }}><EditOutlined /> {t("generic.edit")}</Button>
        </div>
        <div className="ml-8" style={{ marginTop: '2px' }}>
            <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.92334 10.58L5.50334 6L0.92334 1.41L2.33334 0L8.33334 6L2.33334 12L0.92334 10.58Z" fill="#292D2D" fill-opacity="0.15"/>
            </svg>
        </div>
    </div>
}

export const CostGroupsConfig = () => {

    const [filterValue, setFilterValue] = useState<string>('');
    const [categoryFilterValue, setCategoryFilterValue] = useState<string>('');
    const [subCategoryFilterValue, setSubCategoryFilterValue] = useState<string>('');
    const [selectedCostGroupingId, setSelectedCostGroupingId] = useState<number | undefined>(undefined)
    const [selectedCostCategoryId, setSelectedCostCategoryId] = useState<number | undefined>(undefined)
    const { entities: costGroupings } = useAppSelector((state) => state.CostGrouping);
    const [ costCategories, setCostCategories ] = useState<ICostCategoryExtended[]>([])
    const [ costSubCategories, setCostSubCategories ] = useState<ICostSubCategoryExtended[]>([])
    const dispatch = useAppDispatch();
    const { setHeaderLabelEntityState } = useLayoutContex()
    const [openEditCostGroup, toggleEditCostGroup] = useVisible();
    const [editingCostGrouping, setEditingCostGrouping] = useState<ICostGrouping | null>({})
    const [openEditCostCategory, toggleEditCostCategory] = useVisible();
    const [editingCostCategory, setEditingCostCategory] = useState<ICostCategoryExtended | null>({})
    const [openEditCostSubCategory, toggleEditCostSubCategory] = useVisible();
    const [editingCostSubCategory, setEditingCostSubCategory] = useState<ICostSubCategory | null>({})
    const [costGroupingsFiltered, setCostGroupingsFiltered] = useState<ICostGrouping[]>([])
    const [costCategoriesFiltered, setCostCategoriesFiltered] = useState<ICostCategoryExtended[]>([])
    const [costSubCategoriesFiltered, setCostSubCategoriesFiltered] = useState<ICostSubCategoryExtended[]>([])
    const { t } = useTranslation();

    useEffect(() => {
        setHeaderLabelEntityState(t("admin.costGroups"));
        dispatch(getCostGroupingsExtended())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!openEditCostGroup) {
          setEditingCostGrouping({})
        }
      }, [openEditCostGroup]);
    useEffect(() => {
        if (!openEditCostCategory) {
          setEditingCostCategory({})
        }
      }, [openEditCostCategory]);
    useEffect(() => {
        if (!openEditCostSubCategory) {
          setEditingCostSubCategory({})
        }
      }, [openEditCostSubCategory]);

    useEffect(() => {
        const sorted = [...costGroupings].sort(sortByOrderProperty);
        const lcFilterValue = filterValue.toLowerCase();
        const sortedAndFiltered = sorted.filter(x => x?.name?.toLowerCase().includes(lcFilterValue));
        setCostGroupingsFiltered(sortedAndFiltered)
    }, [costGroupings, filterValue])

    useEffect(() => {
        const sorted = [...costCategories].sort(sortByOrderProperty);
        const lcFilterValue = categoryFilterValue.toLowerCase();
        const sortedAndFiltered = sorted.filter(x => x?.name?.toLowerCase().includes(lcFilterValue))
        setCostCategoriesFiltered(sortedAndFiltered)
    }, [costCategories, categoryFilterValue])

    useEffect(() => {
        const sorted = [...costSubCategories].sort(sortByOrderProperty);
        const lcFilterValue = subCategoryFilterValue.toLowerCase();
        const sortedAndFiltered = sorted.filter(x => x?.name?.toLowerCase().includes(lcFilterValue))
        setCostSubCategoriesFiltered(sortedAndFiltered)
    }, [costSubCategories, subCategoryFilterValue])

    const { token: { colorBgContainer, colorBorderSecondary, colorPrimary, colorBorder, colorBgBase, colorFillTertiary, colorFill, colorTextSecondary, colorFillSecondary, colorTextTertiary } } = theme.useToken();

    const navigate = useNavigate();
    const navigateBack = () => {
        navigate('/admin/application-configuration/cost-management')
    }
    
    const onChangeValueToSearch = (e: any) => {
        setFilterValue(e.target.value);
    }

    const onChangeCategoryValueToSearch = (e: any) => {
        setCategoryFilterValue(e.target.value);
    }

    const onChangeSubCategoryValueToSearch = (e: any) => {
        setSubCategoryFilterValue(e.target.value)
    }

    const loadCostCategories = async (id: number) => {
        // @ts-ignore
        const costCategoriesResponse = await costCategoryService.getCostCategories(id)
        if (costCategoriesResponse.status === 200) {
            setCostCategories(costCategoriesResponse.data)
        }
    }

    const loadCostSubCategories = async (id: number) => {
        const costSubCategoriesResponse = await costSubCategoryService.getCostSubcategories(id)
        if (costSubCategoriesResponse.status === 200) {
            setCostSubCategories(costSubCategoriesResponse.data)
        }
    }

    const onSelect = async (id: number) => {
        setSelectedCostGroupingId(id)
        setSelectedCostCategoryId(undefined)

        setCostCategories([])
        setCostSubCategories([])
        await loadCostCategories(id)
    }

    const onSelectCategory = async (id: number) => {
        setSelectedCostCategoryId(id)
        setCostSubCategories([])
        await loadCostSubCategories(id);
    }

    const onEdit = (id: number) => {
        const cg = costGroupings.find(x => x.id === id);
        if (cg) {
            setEditingCostGrouping(cg)
            toggleEditCostGroup();
        }
    }

    const onAddCostGrouping = () => {
        const maxOrder = Math.max(...costGroupings.map(x => Number(x.order))) || 0
        setEditingCostGrouping({active: true, order: (isFinite(maxOrder) ? maxOrder : 0) + 1})
        toggleEditCostGroup();
    }

    const onEditCostCategory = (id: number) => {
        const cc = costCategories.find(x => x.id === id);
        if (cc) {
            setEditingCostCategory(cc)
            toggleEditCostCategory();
        }
    }

    const onAddCostCategory = () => {
        const maxOrder = Math.max(...costCategories.map(x => Number(x.order))) || 0
        setEditingCostCategory({costGroupingId: selectedCostGroupingId, active: true, order: (isFinite(maxOrder) ? maxOrder : 0) + 1})
        toggleEditCostCategory();
    }

    const onCostCategoryModified = async () => {
        dispatch(getCostGroupingsExtended())
        if (selectedCostGroupingId) {
            await loadCostCategories(selectedCostGroupingId)
        }
    }

    const onCostSubCategoryModified = async () => {
        if (selectedCostGroupingId) {
            await loadCostCategories(selectedCostGroupingId)
        }
        if (selectedCostCategoryId) {
            await loadCostSubCategories(selectedCostCategoryId)
        }
    }

    const onEditCostSubCategory = (id: number) => {
        const cc = costSubCategories.find(x => x.id === id);
        if (cc) {
            setEditingCostSubCategory(cc)
            toggleEditCostSubCategory();
        }
    }

    const onAddCostSubCategory = () => {
        const maxOrder = Math.max(...costSubCategories.map(x => Number(x.order))) || 0
        setEditingCostSubCategory({costCategory: { id: selectedCostCategoryId }, active: true, order: (isFinite(maxOrder) ? maxOrder : 0) + 1})
        toggleEditCostSubCategory();
    }

    const [draggedItem, setDraggedItem] = useState<ICostGrouping | null>(null);

    const handleDragStart = (event: React.DragEvent<HTMLDivElement>, id: number) => {
        const costGrouping = costGroupings.find(x => x.id === id)
        if (costGrouping) {
            setDraggedItem(costGrouping);
        }
    };

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>, id: number) => {
        event.preventDefault();
        const costGrouping = costGroupings.find(x => x.id === id)
        if (costGrouping) {
            setCostGroupingsFiltered((prevItems) => {
                const newItems = [...prevItems];
                const draggedItemIndex = prevItems.findIndex((i) => i.id === draggedItem?.id);
                const itemIndex = prevItems.findIndex((i) => i.id === costGrouping.id);
                newItems.splice(draggedItemIndex, 1);
                newItems.splice(itemIndex, 0, draggedItem!);
                return newItems;
            });
        }
    };

    const handleDragEnd = () => {
        setDraggedItem(null);
        const ids = costGroupingsFiltered.map(s => Number(s.id));
        if (costGroupingsFiltered.filter(s => Number(s.id)).length !== ids.length) {
            dispatch(asyncLaunchNotification({
                type: "error",
                config: {
                    message: `Invalid IDs`,
                    description: `IDs are invalid. Please refresh and try again.`
                }
            }));
            return false;
        }
        dispatch(reorderCostGroupings(ids));
    };

    const [draggedCategory, setDraggedCategory] = useState<ICostCategoryExtended | null>(null);

    const handleCategoryDragStart = (event: React.DragEvent<HTMLDivElement>, id: number) => {
        const costCategory = costCategories.find(x => x.id === id)
        if (costCategory) {
            setDraggedCategory(costCategory);
        }
    };

    const handleCategoryDragEnter = (event: React.DragEvent<HTMLDivElement>, id: number) => {
        event.preventDefault();
        const costCategory = costCategories.find(x => x.id === id)
        if (costCategory) {
            setCostCategoriesFiltered((prevItems) => {
                const newItems = [...prevItems];
                const draggedItemIndex = prevItems.findIndex((i) => i.id === draggedCategory?.id);
                const itemIndex = prevItems.findIndex((i) => i.id === costCategory.id);
                newItems.splice(draggedItemIndex, 1);
                newItems.splice(itemIndex, 0, draggedCategory!);
                return newItems;
            });
        }
    };

    const handleCategoryDragEnd = () => {
        setDraggedCategory(null);
        const ids = costCategoriesFiltered.map(s => Number(s.id));
        if (costCategoriesFiltered.filter(s => Number(s.id)).length !== ids.length) {
            dispatch(asyncLaunchNotification({
                type: "error",
                config: {
                    message: `Invalid IDs`,
                    description: `IDs are invalid. Please refresh and try again.`
                }
            }));
            return false;
        }
        dispatch(reorderCostCategories({ ids, costGroupingId: Number(selectedCostGroupingId) }));
    };

    const [draggedSubCategory, setDraggedSubCategory] = useState<ICostSubCategoryExtended | null>(null);

    const handleSubCategoryDragStart = (event: React.DragEvent<HTMLDivElement>, id: number) => {
        const costSubCategory = costSubCategories.find(x => x.id === id)
        if (costSubCategory) {
            setDraggedSubCategory(costSubCategory);
        }
    };

    const handleSubCategoryDragEnter = (event: React.DragEvent<HTMLDivElement>, id: number) => {
        event.preventDefault();
        const costSubCategory = costSubCategories.find(x => x.id === id)
        if (costSubCategory) {
            setCostSubCategoriesFiltered((prevItems) => {
                const newItems = [...prevItems];
                const draggedItemIndex = prevItems.findIndex((i) => i.id === draggedSubCategory?.id);
                const itemIndex = prevItems.findIndex((i) => i.id === costSubCategory.id);
                newItems.splice(draggedItemIndex, 1);
                newItems.splice(itemIndex, 0, draggedSubCategory!);
                return newItems;
            });
        }
    };

    const handleSubCategoryDragEnd = () => {
        setDraggedSubCategory(null);
        const ids = costSubCategoriesFiltered.map(s => Number(s.id));
        if (costSubCategoriesFiltered.filter(s => Number(s.id)).length !== ids.length) {
            dispatch(asyncLaunchNotification({
                type: "error",
                config: {
                    message: `Invalid IDs`,
                    description: `IDs are invalid. Please refresh and try again.`
                }
            }));
            return false;
        }
        dispatch(reorderCostSubCategories({ ids, costCategoryId: Number(selectedCostCategoryId) }));
    };

    return (
        <>
            <CostGroupEdit open={openEditCostGroup} toggle={toggleEditCostGroup} costGrouping={editingCostGrouping} onModifiedChanges={() => {}} />
            <CostCategoryEdit open={openEditCostCategory} toggle={toggleEditCostCategory} costCategory={editingCostCategory} onModifiedChanges={onCostCategoryModified} />
            <CostSubCategoryEdit open={openEditCostSubCategory} toggle={toggleEditCostSubCategory} costSubCategory={editingCostSubCategory} onModifiedChanges={onCostSubCategoryModified} />
            <TabContainer>
                <div className="flex flex-col overflow-auto">
                    <Row>
                        <Col span={24}>
                            <div className="flex flex-row items-center pl-10 pt-11 pb-11" style={{ borderTop: `1px solid ${colorBorderSecondary}`, borderBottom: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                                <BackButton onClick={navigateBack} >{t("admin.costManagement")}</BackButton>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="admin-cost-groups-panel" style={{ border: `2px solid ${colorBorder}`, background: colorBgBase }}>
                    <div className="cost-groups-column">
                        <div className="cost-groups-header" style={{ background: colorFillTertiary, border: `1px solid ${colorFill}` }}>
                            <div className="title-text" style={{ flex: 0, color: colorTextSecondary }}>{t("admin.costGrouping")}</div>
                            <div style={{ flex: 1 }}>
                                <Badge
                                    count={costGroupings.length}
                                    className='ml-10'
                                    style={{ backgroundColor: colorFillSecondary, color: colorTextTertiary }} />
                            </div>
                            <div style={{ flex: 0 }}>
                                <Button type="link" style={{ color: colorPrimary }} onClick={onAddCostGrouping}>+ {t("generic.addNew")}</Button>
                            </div>
                        </div>
                        <div style={{ borderBottom: `1px solid ${colorBorder}` }}>
                            <SearchTable 
                                className="mt-10 mb-10" 
                                style={{ width: '100%' }} 
                                placeholder="Quick Filter"
                                handleOnSearch={() => {}}
                                onChange={onChangeValueToSearch}
                                onClear={() => { setFilterValue('') }} />
                        </div>
                        <div>
                            {costGroupingsFiltered.map((cg) => 
                                <DraggableItem 
                                    key={cg.id} 
                                    id={cg.id} 
                                    name={cg.name} 
                                    count={cg.categoryCount}
                                    onEdit={onEdit}
                                    onSelect={onSelect}
                                    selected={(selectedCostGroupingId === cg.id)}
                                    handleDragStart={handleDragStart}
                                    handleDragEnd={handleDragEnd}
                                    handleDragEnter={handleDragEnter} />
                            )}
                        </div>
                    </div>
                    <div className="cost-groups-column" style={{ border: `2px solid ${colorBorder}`, borderTop: 0, borderBottom: 0 }}>
                        <div className="cost-groups-header" style={{ background: colorFillTertiary, border: `1px solid ${colorFill}` }}>
                            <div className="title-text" style={{ flex: 0, color: colorTextSecondary }}>{t("admin.costCategories")}</div>
                            {selectedCostGroupingId && <div style={{ flex: 1 }}>
                                <Badge
                                    count={costCategories.length}
                                    className='ml-10'
                                    style={{ backgroundColor: colorFillSecondary, color: colorTextTertiary }} />
                            </div>}
                            {selectedCostGroupingId && <div style={{ flex: 0 }}>
                                <Button type="link" style={{ color: colorPrimary }} onClick={onAddCostCategory}>+ {t("generic.addNew")}</Button>
                            </div>}
                        </div>
                        {selectedCostGroupingId && <div style={{ borderBottom: `1px solid ${colorBorder}` }}>
                            <SearchTable 
                                className="mt-10 mb-10" 
                                style={{ width: '100%' }} 
                                placeholder="Quick Filter"
                                handleOnSearch={() => {}}
                                onChange={onChangeCategoryValueToSearch}
                                onClear={() => { setCategoryFilterValue('') }} />
                        </div>}
                        {selectedCostGroupingId && <div>
                            {costCategoriesFiltered.map((cc) => 
                                <DraggableItem 
                                    key={cc.id} 
                                    id={cc.id} 
                                    name={cc.name} 
                                    count={cc.subCategoryCount}
                                    onEdit={onEditCostCategory}
                                    onSelect={onSelectCategory}
                                    selected={(selectedCostCategoryId === cc.id)}
                                    handleDragStart={handleCategoryDragStart}
                                    handleDragEnd={handleCategoryDragEnd}
                                    handleDragEnter={handleCategoryDragEnter} />
                            )}
                        </div>}
                    </div>
                    <div className="cost-groups-column">
                        <div className="cost-groups-header" style={{ background: colorFillTertiary, border: `1px solid ${colorFill}` }}>
                            <div className="title-text" style={{ flex: 0, color: colorTextSecondary }}>{t("admin.costSubcategories")}</div>
                            {selectedCostCategoryId && <div style={{ flex: 1 }}>
                                <Badge
                                    count={costSubCategories.length}
                                    className='ml-10'
                                    style={{ backgroundColor: colorFillSecondary, color: colorTextTertiary }} />
                            </div>}
                            {selectedCostCategoryId && <div style={{ flex: 0 }}>
                                <Button type="link" style={{ color: colorPrimary }} onClick={onAddCostSubCategory}>+ {t("generic.addNew")}</Button>
                            </div>}
                        </div>
                        {selectedCostCategoryId && <div style={{ borderBottom: `1px solid ${colorBorder}` }}>
                            <SearchTable 
                                className="mt-10 mb-10" 
                                style={{ width: '100%' }} 
                                placeholder="Quick Filter"
                                handleOnSearch={() => {}}
                                onChange={onChangeSubCategoryValueToSearch}
                                onClear={() => { setSubCategoryFilterValue('') }} />
                        </div>}
                        {selectedCostCategoryId && <div>
                            {costSubCategoriesFiltered.map((cc) => 
                                <DraggableItem 
                                    key={cc.id} 
                                    id={cc.id} 
                                    name={cc.name} 
                                    count={0}
                                    onEdit={onEditCostSubCategory}
                                    onSelect={() => {}}
                                    selected={false}
                                    handleDragStart={handleSubCategoryDragStart}
                                    handleDragEnd={handleSubCategoryDragEnd}
                                    handleDragEnter={handleSubCategoryDragEnter} />
                            )}
                        </div>}
                    </div>
                </div>
            </TabContainer>
        </>
    )
}