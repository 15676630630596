import { SVGProps } from "react";

type IProps = SVGProps<SVGSVGElement>

export const CommentSvgIcon = (props: IProps) => {

    const { ...restProps } = props;
    
    return (
        <svg data-testid="comment-svg-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...restProps}>
            <path d="M5.99992 14.6663C5.82311 14.6663 5.65354 14.5961 5.52851 14.4711C5.40349 14.3461 5.33325 14.1765 5.33325 13.9997V11.9997H2.66659C2.31296 11.9997 1.97382 11.8592 1.72378 11.6091C1.47373 11.3591 1.33325 11.02 1.33325 10.6663V2.66634C1.33325 1.92634 1.93325 1.33301 2.66659 1.33301H13.3333C13.6869 1.33301 14.026 1.47348 14.2761 1.72353C14.5261 1.97358 14.6666 2.31272 14.6666 2.66634V10.6663C14.6666 11.02 14.5261 11.3591 14.2761 11.6091C14.026 11.8592 13.6869 11.9997 13.3333 11.9997H9.26658L6.79992 14.473C6.66658 14.5997 6.49992 14.6663 6.33325 14.6663H5.99992ZM6.66658 10.6663V12.7197L8.71992 10.6663H13.3333V2.66634H2.66659V10.6663H6.66658Z" fill="currentColor" />
            <path d="M5.99992 14.6663C5.82311 14.6663 5.65354 14.5961 5.52851 14.4711C5.40349 14.3461 5.33325 14.1765 5.33325 13.9997V11.9997H2.66659C2.31296 11.9997 1.97382 11.8592 1.72378 11.6091C1.47373 11.3591 1.33325 11.02 1.33325 10.6663V2.66634C1.33325 1.92634 1.93325 1.33301 2.66659 1.33301H13.3333C13.6869 1.33301 14.026 1.47348 14.2761 1.72353C14.5261 1.97358 14.6666 2.31272 14.6666 2.66634V10.6663C14.6666 11.02 14.5261 11.3591 14.2761 11.6091C14.026 11.8592 13.6869 11.9997 13.3333 11.9997H9.26658L6.79992 14.473C6.66658 14.5997 6.49992 14.6663 6.33325 14.6663H5.99992ZM6.66658 10.6663V12.7197L8.71992 10.6663H13.3333V2.66634H2.66659V10.6663H6.66658Z" fill="currentColor" fillOpacity="0.10"/>
        </svg>
    )
}
