import React from 'react'
import { Col, Row } from 'antd';
import { CancelButtonModal, CommonFooterModal, ModalWrapper, OKButtonModal } from '@components/Utils/ModalWrapper'
import { useAppDispatch } from '@store/store';
import { getProjectDeliverablesData } from '@store/slices/project-deliverables';
import { approveDeliverable } from '@store/slices/project-deliverables-approval';
import { IApproveDeliverableRequest, IProjectDeliverableApproval } from '@models/project-deliverable-approval';
import { hasIntegerValue } from '@shared/util/number-util';
import { useParams } from 'react-router-dom';
import { IProjectDeliverableUI } from '@models/project-deliverable';

interface IApproveDeliverableModalProps {
    open: boolean;
    setOpen: (state: boolean) => void;
    deliverable: IProjectDeliverableUI;
    nextApproval?: IProjectDeliverableApproval;
}

export const ApproveDeliverableModal: React.FC<IApproveDeliverableModalProps> = ({ open, setOpen, nextApproval, deliverable}) => {

    const dispatch = useAppDispatch();
    const { projectId } = useParams<"projectId">();

    const sendRequestApproval = () => {   
        if (!hasIntegerValue(deliverable?.id)) return;

        const approveDeliverablePayload: IApproveDeliverableRequest = {
            deliverableId: String(deliverable.id),
            payload: { approved: true }
        }
        dispatch(approveDeliverable(approveDeliverablePayload)).then(() => {
            setOpen(false);
            projectId && dispatch(getProjectDeliverablesData(projectId));
        })
    }

    const footer = (
        <CommonFooterModal className='justify-between'>
            <CancelButtonModal onClick={() => setOpen(false) } >Cancel</CancelButtonModal>
            <OKButtonModal onClick={sendRequestApproval} >Approve</OKButtonModal>
        </CommonFooterModal>
    )

    return (
        <>
            <ModalWrapper  
                footer={footer}
                open={open}
            >
                <Row justify="space-evenly" className="pt-30 pb-10">
                    <Col span={24} flex={"auto"} className="pt-30 pb-15 ml-30 mr-30 flex items-center justify-center">
                        <div className="text-center font-bold tracking-normal" style={{
                            fontSize: "16px",
                            lineHeight: "26px",
                        }}
                        > You are about to approve this deliverable. This can’t be undone.</div>
                    </Col>
                    <Col span={24} flex={"auto"} className="pt-10 pb-20 pl-20 pr-20 ml-30 mr-30 flex items-center justify-center">
                        <div className="text-center tracking-normal" style={{ lineHeight: "26px" }}>
                            { 
                                nextApproval?.id 
                                ? (<>Once you approve this it will be sent to { nextApproval?.user?.fullName || "" } for approval.</>)
                                : (<>Once you approve this it will be marked as completed.</>)
                            }
                        </div>
                    </Col>
                </Row>
            </ModalWrapper>
        </>
    )
}