import { Alert, Col, Row } from 'antd';

export const ErrorUserPolicy = () => {
  return (
    <Row>
        <Col span={24}>
            <Alert
                    message={(
                        <span>
                            A user in the approval flow has wrong data, please contact the system administrator.
                        </span>
                    )}
                    rootClassName="pl-30"
                    type="error"
                    banner
                    action={<></>}
            />  
        </Col>
    </Row>    
  )
}
