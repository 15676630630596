import { useMemo } from "react";
import { IAttachment } from '@models/attachment.model';
import { DerivativesEnum } from "@models/enumerations/derivatives-enum.model";
import { isArrayWithValues } from "@shared/util/array-util";
import { If } from "@components/Utils/Structural";
import { isString } from "@shared/util/validations";
import { Skeleton, theme } from "antd";
import { PictureOutlined } from "@ant-design/icons";
import { BadgeCommentIndicator } from "./BadgeCommentIndicator";

interface IGalleryItemProps {
  attachment: IAttachment,
  loading?: "eager" | "lazy",
  onClickFile?: (file: IAttachment) => void
}

export const GalleryItem = (props: IGalleryItemProps) => {

    const { attachment = {}, onClickFile } = props;
    const { derivatives = [] } = attachment;

    const signedUrl = useMemo(() => {
        let url = "";
        if (isArrayWithValues(derivatives)) {
          const urlLowResolution = derivatives.find(derivative => derivative.derivativeType === DerivativesEnum.THUMBNAIL)?.signedUrl || "";
          if (urlLowResolution) {
            return urlLowResolution;
          } else {
            const urlImgPreview = derivatives.find(derivative => derivative.derivativeType === DerivativesEnum.LORES)?.signedUrl || ""
             if (urlImgPreview) {
              return urlImgPreview;
             } else {
                const urlThumbnail = derivatives.find(derivative => derivative.derivativeType === DerivativesEnum.PREVIEW)?.signedUrl || ""
                return urlThumbnail;
             }
          }
        } 
        return url ?? "";
    }, [derivatives])

    const { token: { colorBorder, colorBorderSecondary }} = theme.useToken();

    const onClickFileItemGallery = () => {
      onClickFile?.(attachment); 
    }
    
    return (
      <>
        <If condition={isString(signedUrl) && signedUrl !== ""}>
            <div className="relative w-full">
              <img
                key={attachment.id} 
                src={signedUrl} 
                onClick={onClickFileItemGallery}
                className="cursor-pointer"
                style={{
                    border: `1px solid ${colorBorder}`,
                    backgroundColor: colorBorderSecondary,
                    borderRadius: 4,
                    objectFit: "cover",
                    aspectRatio: 1 / 1,
                    overflow: "hidden",
                    width: '100%'
                }}
                { ...props.loading && { loading: props.loading }}
                alt=""
              />
              <If condition={!!(attachment.totalComments && attachment.totalComments > 0)}>
                <div 
                  className="absolute"
                  style={{ bottom: 8, right: 8 }}
                >
                  <BadgeCommentIndicator total={attachment.totalComments || 0} />
                </div>
              </If>
            </div>
        </If>
        <If condition={isString(signedUrl) && signedUrl === ""}>
          <LoadingCustomImageSkeleton onClickFileItemGallery={onClickFileItemGallery} />
        </If>
      </>
    )
}

export const LoadingCustomImageSkeleton = (props: { active?: boolean, onClickFileItemGallery }) => {
  const { active = false, onClickFileItemGallery } = props;
  const { token } = theme.useToken()
  return (
      <div className="w-full h-full cursor-pointer" onClick={onClickFileItemGallery}>
        <Skeleton.Node active={active} className="relative w-full flex items-center cursor-pointer" prefixCls="custom-skeleton" style={{
            borderRadius: 4,
            objectFit: "cover",
            aspectRatio: 1 / 1,
        }}>
            <div className="w-full flex items-center justify-center" >
                <PictureOutlined style={{ color: token.colorTextQuaternary, fontSize: '3rem' }}  />
            </div>
        </Skeleton.Node>
      </div>
  )
}