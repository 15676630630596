
import { Helmet } from 'react-helmet-async';
import { configuration } from '../environments/env';
import Code404NotFoundComponent from '@components/__codes__/Code404NotFoundComponent';

export default function NotFoundPage() {
  return (
    <>
      <Helmet>
        <title>404 | { configuration.APP_NAME }</title>
      </Helmet>
      <Code404NotFoundComponent />
    </>
  )
}
