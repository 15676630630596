import { ProtectedRoute } from "../components/ProtectedRoute/ProtectedRoute";
import { AuthProvider } from "../providers/AuthProvider";

export const AuthRequired = ({ toComponent }: { toComponent: JSX.Element }) => {
    return (
      <AuthProvider>
        <ProtectedRoute>{toComponent}</ProtectedRoute>
      </AuthProvider>
    );
};

// { TODO } Ask Some Desing To Build Error Template
export const handleError = { errorElement: <></> }