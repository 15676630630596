import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getLatestByProjectId } from '@store/slices/budget-extended';
import { BudgetSubtabsEnum } from './BudgetSubTabs';
import { BudgetSubTabs } from './BudgetSubTabs';
import { BudgetCreate } from './create/BudgetCreate';
import { Skeleton, theme } from 'antd';

interface IBudgetTabBaseRouteProps {
  tab: BudgetSubtabsEnum;
}

export const BudgetTabBaseRoute = (props: IBudgetTabBaseRouteProps) => {
  const { tab } = props;
  const { projectId } = useParams();
  const { entity: budget } = useAppSelector(state => state.BudgetExtended);
  const dispatch = useAppDispatch();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [processing, setProcessing] = useState<boolean>(true);
  const [existBudget, setExistBudget] = useState<undefined | boolean>(undefined);

  useEffect(() => {
    if (!projectId) return;
    dispatch(getLatestByProjectId(Number(projectId)))
      .unwrap()
      .then(() => {
        setProcessing(false);
        setExistBudget(true);
      })
      .catch((error: any) => {
        setProcessing(false);
        if (error?.response?.status === 404) {
          setExistBudget(false);
        } else {
          setExistBudget(true);
        }
      });
  }, [dispatch, projectId]);

  if (processing)
    return (
      <div className="mt-16 ml-40 mb-8 p-20 w-full" style={{ backgroundColor: colorBgContainer, borderRadius: '0.25rem' }}>
        <Skeleton />
      </div>
    );
  if (existBudget || budget?.id) return <BudgetSubTabs tab={tab} />;
  return <BudgetCreate />;
};
