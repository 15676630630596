/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useErrorAnimation } from "@HOOKs/UseErrorAnimation";
import { isFulfilled } from "@reduxjs/toolkit";
import { asyncLaunchNotification } from "@store/slices/notification";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getRoles } from "@store/slices/role";
import { Button, Col, Drawer, Form, InputNumber, Modal, Row, Select, Space, Tabs, theme } from "antd";
import { CloseOutlined, ExclamationCircleFilled, UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { IBudgetApprovalRangeSettingSave } from "@models/budget-approval-range-setting.model";
import TabPane from "antd/es/tabs/TabPane";
import { DragHandle } from "@components/Icons/DragHandle";
import { IBudgetApproverSettingExtended } from "@models/budget-approver-setting.model";
import { getUsers } from '../../../../store/slices/users-extended';
import { saveComplete } from "@store/slices/budget-approval-range-setting-extended";
import { getUniqueId } from "@infrastructure/repositories/utils.repository";
import { sortByOrderProperty } from "@shared/util/array-util";
import { ExclamationCircleOutlined } from "@ant-design/icons";

interface IThresholdEditProps {
  open: boolean,
  toogle: () => void,
  budgetApprovalRangeSetting: IBudgetApprovalRangeSettingSave
  allBudgetApprovalRangeSettings: readonly IBudgetApprovalRangeSettingSave[]
}

export const ThresholdEdit = (props: IThresholdEditProps) => {

    const { open, toogle, budgetApprovalRangeSetting, allBudgetApprovalRangeSettings } = props;

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { token: { 
        colorTextSecondary,
        colorTextQuaternary,
        colorFillTertiary,
        colorWarningTextActive,
        colorFillSecondary } } = theme.useToken();

    const [form] = Form.useForm();
    const minValue = Form.useWatch('minValue', form);
    const maxValue = Form.useWatch('maxValue', form);

    const { confirm } = Modal;

    const [animateError, playAnimationOfError] = useErrorAnimation();

    const onFinishFailed = (errorInfo: any) => playAnimationOfError();

    const onFinish = (entity: any): void => {

        if (entity.minValue >= entity.maxValue) {
            dispatch(asyncLaunchNotification({
                type: "error",
                config: {
                  message: t("admin.threshold.invalidMinMax"),
                  description: t("admin.threshold.minValueMustBeLess")
                }
            }))
            return;
        }

        const overlapped = allBudgetApprovalRangeSettings.filter(x => x.id !== budgetApprovalRangeSetting?.id && (x.maxValue || 0) > entity.minValue && (x.minValue || 0) < entity.maxValue)
        if (overlapped.length > 0) {
            dispatch(asyncLaunchNotification({
                type: "error",
                config: {
                  message: t("admin.threshold.thresholdValueOverlap"),
                  description: t("admin.threshold.thresholdValueOverlapDetail")
                }
            }))
            return;
        }

        if (approversSorted.length === 0) {
            dispatch(asyncLaunchNotification({
                type: "error",
                config: {
                  message: t("admin.threshold.atLeastOneApprover"),
                  description: t("admin.threshold.atLeastOneApproverDetail")
                }
            }))
            return;
        }

        dispatch(
            saveComplete({
                id: budgetApprovalRangeSetting?.id || 0,
                ...entity,
                budgetApprovers: approversSorted
            })  
        ).then(onSuccessCreateStatus);
    };

    const onSuccessCreateStatus = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: t("admin.threshold.thresholdSaved"),
                  description: t("admin.threshold.thresholdSavedSuccessfully")
                }
            }))
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onClose = () => { 
        form.resetFields();
        toogle(); 
    }

    const onSubmit = () => form.submit();

    useEffect(() => { 
        setFormFields() 
        dispatch(getUsers())
        dispatch(getRoles())
        setApprovers(budgetApprovalRangeSetting.budgetApprovers?.map(x => {
            return {
                id: x.id,
                order: x.order,
                role: x.role,
                user: x.user,
                guid: getUniqueId()
            }
        }) || [])
    }, [budgetApprovalRangeSetting])

    const rangedOverlappedIdx = useMemo(() => {
        return allBudgetApprovalRangeSettings.findIndex(x => x.id !== budgetApprovalRangeSetting?.id && (x.maxValue || 0) > minValue && (x.minValue || 0) < maxValue)
    }, [budgetApprovalRangeSetting, minValue, maxValue]);

    const setFormFields = () => {
        form.setFieldsValue({
            ...budgetApprovalRangeSetting
        });
    }

    const { usersList } = useAppSelector((state) => state.UsersExtended);
    const { entities: roles } = useAppSelector((state) => state.Roles);
    const [activeTab, setActiveTab] = useState<string>('users')
    const [approvers, setApprovers] = useState<IBudgetApproverSettingExtended[]>([])

    const userOptions = useMemo(() => {
        return usersList.map(u => {
            return {
                value: u.id,
                label: u.fullName
            }
        })
    }, [usersList]);

    const roleOptions = useMemo(() => {
        return roles.map(elem => {
            return { label: elem.name, value: elem.id }
        })
    }, [roles]);

    const getMaxOrder = (): number => {
        return Math.max(...approvers.map(x => x.order || 0))
    }

    const addUser = (userId: string | number) => {
        const user = usersList.find(x => `${x.id}` === `${userId}`);
        if (user) {
            const updatedApprovers = [...approvers];
            updatedApprovers.push({ 
                id: 0, 
                guid: getUniqueId(), 
                user: { id: userId, fullName: user.fullName },
                order: getMaxOrder() + 1
            });
            setApprovers(updatedApprovers)
        }
    }

    const addRole = (roleId: string | number) => {
        const role = roles.find(x => `${x.id}` === `${roleId}`);
        if (role) {
            const updatedApprovers = [...approvers];
            updatedApprovers.push({ 
                id: 0, 
                guid: getUniqueId(), 
                role: {...role},
                order: getMaxOrder() + 1
             });
            setApprovers(updatedApprovers)
        }
    }

    const onRemoveApprover = (guid: string | undefined) => {
        const idx = approvers.findIndex(x => x.guid === guid);
        if (idx !== -1) {
            const updatedApprovers = [...approvers];
            updatedApprovers.splice(idx, 1);
            setApprovers(updatedApprovers);
        }
    }

    const [draggedItem, setDraggedItem] = useState<IBudgetApproverSettingExtended | null>(null);

    const [approversSorted, setApproversSorted] = useState<IBudgetApproverSettingExtended[]>([])

    useEffect(() => {
        const sorted = [...approvers].sort(sortByOrderProperty)
        setApproversSorted(sorted);
    }, [approvers])

    const handleDragStart = (event: React.DragEvent<HTMLDivElement>, item: IBudgetApproverSettingExtended) => {
        setDraggedItem(item);
    };

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>, item: IBudgetApproverSettingExtended) => {
        event.preventDefault();
        setApproversSorted((prevItems) => {
            const newItems = [...prevItems];
            const draggedItemIndex = prevItems.findIndex((i) => i.id === draggedItem?.id);
            const itemIndex = prevItems.findIndex((i) => i.id === item.id);
            newItems.splice(draggedItemIndex, 1);
            newItems.splice(itemIndex, 0, draggedItem!);
            return newItems;
        });
    };

    const handleDragEnd = () => {
        setDraggedItem(null);
    };

    return (
        <>
            <Drawer 
                width={448} 
                title={`${budgetApprovalRangeSetting?.id ? t("admin.threshold.editThreshold") : t("admin.threshold.addThreshold")}`}
                placement="right" 
                onClose={() => {
                    confirm({
                        title: t("admin.threshold.ifYouClose"),
                        icon: <ExclamationCircleFilled />,
                        content: ``,
                        onOk() {
                            onClose();
                        }
                    });
                }} 
                open={open}
                closable={true}
                extra={
                    <Space>
                      <Button type="primary" ghost onClick={onClose}>
                        Cancel
                      </Button>
                      <Button onClick={onSubmit} type="primary">
                        {`${budgetApprovalRangeSetting?.id ? t("generic.save"): t("generic.create")}`}
                      </Button>
                    </Space>
                  }
            >
                <Form
                    layout="vertical"
                    form={form}
                    onSubmitCapture={(e) => { e.stopPropagation(); e.preventDefault(); }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className={`threshold-edit ${animateError && "error-animation"}`}
                >

                    <Row gutter={16}>
                        <Col span={24}>
                            <div className="threshold-section-title" style={{ backgroundColor: colorFillTertiary, border: `1px solid transparent` }}>
                                <div>
                                    <h5 style={{ color: colorTextSecondary }}>{t("admin.threshold.threshold")}:</h5>
                                </div>
                            </div>
                            <div style={{ display: 'flex', gap: '8px', marginTop: '8px' }}>
                                <div style={{ flex: 1 }}>
                                    <Form.Item
                                        name="minValue"
                                        label={t("admin.threshold.between")}
                                        rules={[
                                            { required: true, message: t("admin.threshold.minValueIsRequired") },
                                        ]}
                                    >
                                        <InputNumber
                                            className="w-full"
                                            placeholder={t("admin.threshold.minValue")}
                                            formatter={value => `$ ${value}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                                            parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : ""}
                                        />
                                    </Form.Item>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <Form.Item
                                        name="maxValue"
                                        label={t("admin.threshold.to")}
                                        rules={[
                                            { required: true, message: t("admin.threshold.maxValueIsRequired") },
                                        ]}
                                    >
                                        <InputNumber
                                            className="w-full"
                                            placeholder={t("admin.threshold.maxValue")}
                                            formatter={value => `$ ${value}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                                            parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : ""}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            {rangedOverlappedIdx !== -1 && <div style={{ marginTop: '-16px', marginBottom: '12px', color: colorWarningTextActive }}>
                                <div><ExclamationCircleOutlined /> {t("admin.threshold.minMaxIncludedInAnother")}.</div>
                                <div style={{ fontWeight: 'bold' }}>(T{rangedOverlappedIdx} Min: {allBudgetApprovalRangeSettings[rangedOverlappedIdx].minValue} - USD)</div>
                            </div>}
                            <div className="threshold-section-title" style={{ backgroundColor: colorFillTertiary, border: `1px solid transparent` }}>
                                <div>
                                    <h5 style={{ color: colorTextSecondary }}>{t("admin.threshold.addUsers")}:</h5>
                                </div>
                            </div>
                            <Tabs onChange={(key) => setActiveTab(key)} type="card" activeKey={activeTab} className="threshold-tabs">
                                <TabPane tab={t('admin.threshold.user')} key="users">
                                    <UserOutlined className="user-icon" />
                                    <Select
                                        style={{ width: '100%' }}
                                        showSearch
                                        defaultActiveFirstOption={false}
                                        suffixIcon={null}
                                        filterOption={false}
                                        value=''
                                        onSelect={(e) => addUser(e)}
                                        notFoundContent={null}
                                        options={userOptions}
                                    />
                                    <div style={{ color: '#00000073' }}>{t("admin.threshold.typeTheUserName")}</div>
                                </TabPane>
                                <TabPane tab={t('admin.threshold.role')} key="roles">
                                    <UserOutlined className="user-icon" />
                                    <Select
                                        style={{ width: '100%' }}
                                        showSearch
                                        defaultActiveFirstOption={false}
                                        suffixIcon={null}
                                        filterOption={false}
                                        value=''
                                        onSelect={(e) => addRole(e)}
                                        notFoundContent={null}
                                        options={roleOptions}
                                    />
                                    <div style={{ color: '#00000073' }}>{t("admin.threshold.typeTheRoleName")}</div>
                                </TabPane>
                            </Tabs>
                            <div className="threshold-approvers">
                                {approversSorted.map((a, idx) => {
                                    return <div 
                                        key={a.guid} 
                                        className="threshold-approver"
                                        draggable={true}
                                        onDragStart={(event) => handleDragStart(event, a)}
                                        onDragEnter={(event) => handleDragEnter(event, a)}
                                        onDragEnd={handleDragEnd}
                                        >
                                    <div>{idx + 1}.</div>
                                    <div className="data-block" style={{ backgroundColor: colorFillSecondary }}>
                                        <div style={{ paddingTop: '4px', marginRight: '8px', cursor: 'grab' }}><DragHandle /></div>
                                        <div style={{ color: colorTextSecondary }}>{a.user?.fullName || a.role?.name}{a.role && <span style={{ color: colorTextQuaternary, marginLeft: '8px' }}>(role)</span>}</div>
                                        <div style={{ color: colorTextSecondary, marginLeft: '8px', cursor: 'pointer' }} onClick={() => onRemoveApprover(a.guid)}><CloseOutlined /></div>
                                    </div>
                                </div>
                                })}
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
  );
}
