import React, { useState } from 'react'
import { Col, Divider, Layout, Menu, Row, theme } from 'antd';
import { ModalWrapper } from '@components/Utils/ModalWrapper'
import { BgColorsOutlined, CloseOutlined, SoundOutlined } from '@ant-design/icons';
import { usePreferencesProvider } from '@providers/PreferencesProvider';
import { MenuInfo } from '@components/Layout/SideMenu/Menu';
import { PreferencesContentModal } from './PreferencesContentModal';

const { Content, Sider } = Layout;

export const PREFERENCES_KEYS = {
    THEMES: "THEMES",
    LAYOUT: "LAYOUT",
    NOTIFICATIONS: "NOTIFICATIONS",
    FONT: "FONT",
    LANGUAGE: "LANGUAGE",
    ACCESIBILITY: "ACCESIBILITY",
    ADVANCED: "ADVANCED"
}

export interface SelectInfo extends MenuInfo {
    selectedKeys: string[];
}

interface IPreferencesModalProps {}

export const PreferencesModal: React.FC<IPreferencesModalProps> = () => {

    const { showModalPreferences, setVisibleModalPreferences } = usePreferencesProvider();

    const { token: { colorBgElevated } } = theme.useToken();

    const [preferenceKeySelected, setPreferenceKeySelected] = useState<string>(PREFERENCES_KEYS.THEMES)
    
    const footer = (
        <Row></Row>
    )

    const onClose = () => {
        setVisibleModalPreferences(false);
    }

    const header = (
        <>
          <Row justify="space-between" align={"middle"} className="pt-20 pb-10" >
            <Col flex="4rem" className="flex items-center justify-start pl-30">
             <h3>Preferences</h3>
            </Col>
            <Col flex="4rem" className="flex items-center justify-end">
                <CloseOutlined className="cursor-pointer mr-30" onClick={onClose} />
            </Col>
          </Row>
          <Divider className="mt-5 mb-0" />
        </>
    );

    const navLinks = [
        {
            key: PREFERENCES_KEYS.THEMES,
            icon: <BgColorsOutlined />,
            label: `Themes`
        },
        {
            key: PREFERENCES_KEYS.NOTIFICATIONS,
            icon: <SoundOutlined />,
            label: `Notifications`
        }    
    ]

    const onSelectPreference = (info: SelectInfo) => {
        setPreferenceKeySelected(info.key);
    }

    return (
        <>
            <ModalWrapper  
                title={header}
                footer={footer}
                open={showModalPreferences}
                width={750}
                centered={false}
                style={{ background: colorBgElevated }}
            >
                <Layout style={{ background: colorBgElevated }}>
                    <Sider
                        className='h-full mt-0 mb-0'
                        style={{ background: colorBgElevated }}
                        theme="light"
                        breakpoint="lg"
                        collapsedWidth="0"
                    >
                        <Menu
                            style={{ background: colorBgElevated }}
                            theme="light"
                            mode="inline"
                            defaultSelectedKeys={[PREFERENCES_KEYS.THEMES]}
                            onSelect={onSelectPreference}
                            items={navLinks}
                        />
                    </Sider>
                    <Layout>
                        <Content>
                            <div style={{ padding: 24, minHeight: 450, background: colorBgElevated }}>
                                <PreferencesContentModal keyComponentToSetVisible={preferenceKeySelected} />
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </ModalWrapper>
        </>
    )
}