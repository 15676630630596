import { IProjectDeliverableUI } from '@models/project-deliverable';
import { Button, ConfigProvider, theme, Tooltip } from 'antd';
import { useEffect, useLayoutEffect, useState } from 'react';
import { EmptyButton } from '@components/Utils/EmptyButton';
import { isArrayWithValues } from '@shared/util/array-util';

export interface StatusButtonProps extends React.ComponentPropsWithoutRef<typeof Button> {
    deliverable: IProjectDeliverableUI
}


const deliverableApprovalStatus = Object.freeze({
    NOT_STARTED: 'NOT_STARTED',
})

export const StatusApprovalProjectDeliverableButton: React.FC<StatusButtonProps> = ({ deliverable, style, ...restProps }) => {
    const [loading, setLoading] = useState(true);
    const [pendingApproval, setPendingApproval] = useState<string | null>("");

    useLayoutEffect(() => {
        setLoading(true)
        setTimeout(() => setLoading(false), 10)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliverable])

    useEffect(() => {
        const approvals = deliverable?.approvals;

        if (approvals && isArrayWithValues(deliverable?.approvals)) {
            const firstPendingApproval = approvals[0];

            if (firstPendingApproval.status === deliverableApprovalStatus.NOT_STARTED) {
                const user = Boolean(firstPendingApproval?.user?.firstName);
                const role = Boolean(firstPendingApproval?.role?.name);
                const approver = user
                    ? `${firstPendingApproval?.user?.firstName || ""} ${firstPendingApproval?.user?.lastName || ""}`
                    : `${role}`;

                setPendingApproval(approver);
            } else {
                setPendingApproval(null);
            }
        }
    }, [deliverable.approvals])


    const toolTipTextGenerator = () => {
        return `This deliverable needs to be approved by ${pendingApproval}`
    }

    return (
        <ConfigProvider theme={{ algorithm: theme.compactAlgorithm, token: { borderRadius: 3 } }}>
            {
                loading || !deliverable.statusLabelUI
                    ? <EmptyButton />
                    : (
                        < Tooltip title={toolTipTextGenerator()} overlayStyle={{ maxWidth: '350px' }}>
                            {pendingApproval &&
                                <span>
                                    <Button className='mr-4 cursor-default pointer-events-none' style={style} {...restProps} >
                                        {"Approval Required"}
                                    </Button>
                                </span>}
                        </Tooltip>
                    )
            }
        </ConfigProvider >
    )
}
