import * as d3 from "d3";
import { v4 } from "uuid";
import {
  AssetType,
  IAsset,
  IDimensionsConfig,
  IFloorplanCallbacks,
  IFloorplanConfig,
  IFloorplanControls,
  IGridPatternConfig,
  IStrokeConfig,
  IdAndColor,
  MeasurementUnits,
  ZoomEvent,
} from "../interfaces/interfaces";
import { Drawer } from "./drawer";
import { UUID } from "crypto";
import { Utils } from "./utils";
import { Minimap } from "./minimap";
import { Camera } from "./camera";

export class Controller {
  private placeAssetType: AssetType | null = null;
  private gridPixelsSize: number;
  private assets: IAsset[] = [];
  private selectedAssets: IAsset[] = [];
  private zoomBehavior: any;
  private isMeasuring: boolean = false;
  private measurementStartLoc: { x: number; y: number } | null = null;
  private measurementEndLoc: { x: number; y: number } | null = null;
  private measurementColor = "#00ff38";
  private pixelsPerMeter: number;
  private officeWidth: number;
  private officeHeight: number;
  private dimensionsConfig: IDimensionsConfig;
  private backgroundImage?: string;
  private gridPatternConfig?: IGridPatternConfig;
  private placeholderAsset: IAsset | null = null;

  constructor(
    private instanceId: string,
    private initialAssets: IAsset[] = [],
    private isEditMode: boolean,
    private setPlacingModeCallback: (type: AssetType | null) => void,
    private checkButtonsVisibility: () => void,
    private markerRadiusMeters: number,
    private floorplanConfig: IFloorplanConfig,
    private floorplanCallbacks: IFloorplanCallbacks
  ) {
    this.dimensionsConfig = floorplanConfig.dimensionsConfig!;
    this.pixelsPerMeter = floorplanConfig.dimensionsConfig!.pixelsPerMeter;
    this.officeHeight = floorplanConfig.dimensionsConfig!.officeHeight;
    this.officeWidth = floorplanConfig.dimensionsConfig!.officeWidth;
    this.backgroundImage = this.floorplanConfig.backgroundImage;
    this.gridPatternConfig = this.floorplanConfig.gridPatternConfig;

    this.gridPixelsSize = this.gridPatternConfig
      ? this.gridPatternConfig.size * this.pixelsPerMeter
      : 0;

    const zoomed = (e: ZoomEvent) => {
      if (
        !Utils.isNumber(e.transform.k) ||
        !Utils.isNumber(e.transform.x) ||
        !Utils.isNumber(e.transform.y)
      )
        return;
      const mainG = d3.select("#" + instanceId).select("g");
      if (!mainG) return;
      const zoomSlider = d3.select("#zoom-control").select("input").node();
      const zoomLabel = d3.select("#zoom-control").select("span").node();
      if (zoomSlider) {
        zoomSlider.value = (e.transform.k * 100).toString();
        zoomLabel.innerText = Math.floor(e.transform.k * 100).toString() + "%";
      }
      mainG.attr("transform", e.transform);
      this.refreshMinimap();
      this.refreshMeasurement();
      this.refreshControlsOnAssets();
    };

    const scaleMin = 0.1;
    const scaleMax = 2;
    this.zoomBehavior = d3
      .zoom()
      .scaleExtent([scaleMin, scaleMax])
      .on("zoom", zoomed);
    d3.select("#" + instanceId).call(this.zoomBehavior);

    const zoomSlider = d3.select("#zoom-slider-" + instanceId);
    zoomSlider.on("input", (e: Event) => {
      const inputValue = (e.target as HTMLInputElement).value;
      const zoomLevel = Number.parseFloat(inputValue) / 100;
      d3.select("#" + instanceId).call(this.zoomBehavior.scaleTo, zoomLevel);
    });

    this.assets = structuredClone(initialAssets);

    // Draws initial assets
    // onAssetAdded isn't called initially because it's called when new assets are added
    // onFloorplanCreated returns the array for the initial assets drawn
    this.draw(false);
    Drawer.highlightNodes([], this.instanceId);
    this.setBackgroundImage(this.backgroundImage);
    this.refreshMinimap();
    if (this.floorplanCallbacks.onFloorplanCreated) {
      this.floorplanCallbacks.onFloorplanCreated(initialAssets, {
        backgroundColor: this.floorplanConfig.backgroundColor,
        backgroundImage: this.backgroundImage,
        defaultAssetColor: this.floorplanConfig.defaultAssetColor,
        gridPatternConfig: this.gridPatternConfig,
        dimensionsConfig: this.dimensionsConfig,
        allowMultipleAdd: this.floorplanConfig.allowMultipleAdd,
        allowSingleSelection: this.floorplanConfig.allowSingleSelection,
        showFloorplanControls: this.floorplanConfig.showFloorplanControls,
        allowedAssetTypes: this.floorplanConfig.allowedAssetTypes,
        markerRadiusMeters: this.markerRadiusMeters,
      });
    }

    Drawer.createArrowMarkers(this.instanceId, this.measurementColor);
    const mainG = d3.select("#" + instanceId).select("g");
    mainG.on("click", (event: MouseEvent) => {
      if (!this.isMeasuring) {
        this.handleGClick(event);
      } else {
        const [x, y] = d3.pointer(event);
        const xMeters = x / this.pixelsPerMeter;

        //floorplanConfig
        const yMeters = y / this.pixelsPerMeter;

        if (!this.measurementStartLoc) {
          this.measurementStartLoc = { x: xMeters, y: yMeters };
        } else {
          this.isMeasuring = false;
          this.measurementEndLoc = { x: xMeters, y: yMeters };
          this.measurementStop();
        }
      }
    });

    mainG.on("mousemove", (event: MouseEvent) => {
      if (!this.isMeasuring || !this.measurementStartLoc) return;
      const [x, y] = d3.pointer(event);
      const xMeters = x / this.pixelsPerMeter;
      const yMeters = y / this.pixelsPerMeter;
      this.measurementEndLoc = { x: xMeters, y: yMeters };
      this.refreshMeasurement();
    });
  }

  zoomToFit(duration = 1000) {
    Camera.zoomToFit(
      duration,
      this.instanceId,
      this.pixelsPerMeter,
      this.officeWidth,
      this.officeHeight,
      this.zoomBehavior
    );
  }

  getAllAssets() {
    return this.assets;
  }

  getSelectedAssets() {
    return this.selectedAssets;
  }

  setAllAssets(assets: IAsset[]) {
    this.initialAssets = assets;
    this.assets = assets;
    //Sets initial layout selection to null and calls reset in case there is an old layout on the svg that might interfere
    this.setSelectedAsset([]);
    this.reset();
  }

  getBackgroundImage() {
    return this.backgroundImage;
  }

  setBackgroundImage(url: string | undefined) {
    Drawer.setBackgroundImage(this.instanceId, url, this.refreshMinimap);
    this.backgroundImage = url;
  }

  getDimensionsConfig() {
    return this.dimensionsConfig;
  }

  isMultipleAddAllowed() {
    return this.floorplanConfig.allowMultipleAdd;
  }

  setMultipleAddAllowed(allowMultipleAdd: boolean) {
    this.floorplanConfig.allowMultipleAdd = allowMultipleAdd;
  }

  reset() {
    if (!this.isEditMode) return;
    this.selectedAssets = [];
    Drawer.deleteAllNodes(this.instanceId);
    // Resets the layout to only the initial assets
    this.assets = structuredClone(this.initialAssets);
    this.draw(true);
    Drawer.highlightNodes([], this.instanceId);
    this.refreshControlsOnAssets();
    this.zoomToFit(750);
  }

  clear() {
    if (!this.isEditMode) return;
    Drawer.deleteAllNodes(this.instanceId);
    this.assets = [];
    this.setSelectedAsset([]);
    this.refreshControlsOnAssets();
    this.refreshMinimap();
  }

  setEditMode(isEditMode: boolean) {
    this.isEditMode = isEditMode;
    //Hides and shows edition buttons based on the value of isEditMode
    this.checkButtonsVisibility();
  }

  getIsEditMode() {
    return this.isEditMode;
  }

  setDimensionsConfig(dimensionsConfig: IDimensionsConfig) {
    this.dimensionsConfig = dimensionsConfig;
    this.pixelsPerMeter = dimensionsConfig.pixelsPerMeter;
    this.officeHeight = dimensionsConfig!.officeHeight;
    this.officeWidth = dimensionsConfig!.officeWidth;

    this.selectedAssets = [];

    Drawer.deleteAllNodes(this.instanceId);

    this.gridPixelsSize = this.gridPatternConfig
      ? this.gridPatternConfig.size * this.pixelsPerMeter
      : 0;

    const mainG = d3.select("#" + "mainG-" + this.instanceId);
    mainG
      .attr("width", this.officeWidth * this.pixelsPerMeter)
      .attr("height", this.officeHeight * this.pixelsPerMeter);
    const boardRect = mainG
      .select("rect")
      .attr("width", this.officeWidth * this.pixelsPerMeter)
      .attr("height", this.officeHeight * this.pixelsPerMeter);

    if (this.gridPatternConfig) {
      const patternId = "layout-grid-" + this.instanceId;
      const gridPattern = mainG.select("pattern");
      gridPattern
        .attr("width", this.gridPixelsSize)
        .attr("height", this.gridPixelsSize);

      d3.select("#grid-vertical-" + this.instanceId)
        .attr("x1", this.gridPixelsSize)
        .attr("y1", 0)
        .attr("x2", this.gridPixelsSize)
        .attr("y2", this.gridPixelsSize);

      d3.select("#grid-horizontal-" + this.instanceId)
        .attr("x1", 0)
        .attr("y1", this.gridPixelsSize)
        .attr("x2", this.gridPixelsSize)
        .attr("y2", this.gridPixelsSize);

      boardRect.attr("fill", `url(#${patternId})`);
    } else {
      boardRect.attr("fill", this.floorplanConfig.backgroundColor || "gray");
    }

    this.draw(true);
    Drawer.highlightNodes([], this.instanceId);
    this.refreshControlsOnAssets();
    this.refreshMinimap();
  }

  handleDelete = () => {
    if (this.selectedAssets.length === 0) return;
    this.assets = this.assets.filter(
      (asset) =>
        !this.selectedAssets.some(
          (selectedAsset) => asset.id == selectedAsset.id
        )
    );
    this.selectedAssets.map((selectedAsset) =>
      Drawer.deleteNode(
        selectedAsset.id,
        this.instanceId,
        this.floorplanCallbacks.onAssetDeleted
      )
    );
    this.setSelectedAsset([]);
    this.refreshControlsOnAssets();
    this.refreshMinimap();
  };

  handleDeleteAssets = (assetsIds: UUID[]) => {
    if (assetsIds.length === 0) return;
    this.assets = this.assets.filter(
      (asset) => !assetsIds.some((deletedAssetId) => asset.id == deletedAssetId)
    );
    assetsIds.map((deletedAssetId) =>
      Drawer.deleteNode(
        deletedAssetId,
        this.instanceId,
        this.floorplanCallbacks.onAssetDeleted
      )
    );
    this.setSelectedAsset([]);
  };

  handleDuplicate = () => {
    if (this.selectedAssets.length === 0) return;

    const mainG = d3.select("#" + this.instanceId).select("g");
    const mainGBox = mainG.node().getBoundingClientRect();
    const currentTransform = d3.zoomTransform(mainG.node());
    // Not sure why k factor needs to be applied only when >1, otherwise boundaries were not
    // limiting asset dragging all the time, specially on quick moves.
    const offset = this.gridPatternConfig ? this.gridPixelsSize : 10;

    const invertTransformOffset = Math.round(
      currentTransform.k < 1 ? offset : offset / currentTransform.k
    );

    let newAssets = [];

    let outOfBoundsX = false;
    let outOfBoundsY = false;

    for (const asset of this.selectedAssets) {
      const assetNode = d3
        .select("#" + this.instanceId)
        .select("#g" + asset.id);
      const boundingRect = assetNode.node().getBoundingClientRect();

      if (boundingRect.right + invertTransformOffset > mainGBox.right)
        outOfBoundsX = true;
      if (boundingRect.bottom + invertTransformOffset > mainGBox.bottom)
        outOfBoundsY = true;
    }

    for (const asset of this.selectedAssets) {
      newAssets.push({
        ...asset,
        id: v4(),
        x:
          (asset.x * this.pixelsPerMeter +
            invertTransformOffset * (outOfBoundsX ? -1 : 1)) /
          this.pixelsPerMeter,
        y:
          (asset.y * this.pixelsPerMeter +
            invertTransformOffset * (outOfBoundsY ? -1 : 1)) /
          this.pixelsPerMeter,
      });
    }

    //Adds new assets to the array of assets
    this.assets = this.assets.concat(newAssets);
    // Draws the new asset picked, which is placed on click inside the svg
    this.draw(true);

    this.setSelectedAsset(newAssets);
    this.refreshMinimap();
    this.refreshControlsOnAssets();
  };

  handleGClick = (event: MouseEvent) => {
    if (this.placeAssetType) {
      // We use 3d.pointer instead of event.offsetX due to zoom/pan
      let [posX, posY] = d3.pointer(event);
      if (this.gridPatternConfig) {
        posX = Utils.roundToNearestByStep(posX, this.gridPixelsSize);
        posY = Utils.roundToNearestByStep(posY, this.gridPixelsSize);
      }
      const newAsset = {
        id: v4(),
        label: "",
        type: this.placeAssetType,
        x: posX / this.pixelsPerMeter,
        y: posY / this.pixelsPerMeter,
        color: this.floorplanConfig.defaultAssetColor || "#A39B99",
        rotationAngle: 0,
      };

      //Adds new asset to the array of assets
      this.assets.push(newAsset);
      const mainG = d3.select("#" + this.instanceId).select("g");
      const mainGBox = mainG.node().getBoundingClientRect();

      // Draws the new asset picked, which is placed on click inside the svg
      this.draw(false);

      const assetNode = d3
        .select("#" + this.instanceId)
        .select("#g" + newAsset.id);

      const assetBox = assetNode.node().getBoundingClientRect();
      // Validate if newly added asset is in boundaries, if not undo the add
      if (
        assetBox.right <= mainGBox.right &&
        assetBox.left >= mainGBox.left &&
        assetBox.top >= mainGBox.top &&
        assetBox.bottom <= mainGBox.bottom
      ) {
        if (this.floorplanCallbacks.onAssetAdded)
          this.floorplanCallbacks.onAssetAdded(newAsset);
        this.setSelectedAsset([newAsset]);
        this.refreshMinimap();

        if (!this.floorplanConfig.allowMultipleAdd) {
          this.deletePlaceholder();
          this.setPlacingModeCallback(null);
        } else {
          this.setPlaceholder();
        }
      } else {
        this.assets = this.assets.filter((asset) => asset.id != newAsset.id);
        Drawer.deleteNode(newAsset.id, this.instanceId);
      }
    } else {
      //If it's not listening for an element to be placed inside the svg it sets the selection to null
      this.setSelectedAsset([]);
    }
    this.refreshControlsOnAssets();
  };

  refreshMinimap = () => {
    Minimap.refresh(this.instanceId, this.pixelsPerMeter, this.zoomBehavior);
  };

  private draw = (triggerAssetAdded: boolean, assetsToDraw?: IAsset[]) => {
    Drawer.draw(
      assetsToDraw ? assetsToDraw : this.assets,
      this.instanceId,
      this.onAssetClicked,
      this.onAssetDragged,
      this.pixelsPerMeter,
      this.markerRadiusMeters!,
      triggerAssetAdded ? this.floorplanCallbacks.onAssetAdded : undefined,
      this.floorplanCallbacks.onAssetSelectionChanged,
      this.floorplanCallbacks.onAssetDragEnd,
      this.floorplanCallbacks.onAssetSimpleClick,
      this.placeholderAsset ? [this.placeholderAsset.id] : []
    );
  };

  goTo(x: number, y: number, zoomLevel: number) {
    Camera.goTo(
      x,
      y,
      this.instanceId,
      this.pixelsPerMeter,
      this.zoomBehavior,
      zoomLevel
    );
  }

  private onAssetClicked = (
    asset: IAsset,
    onAssetSelectionChanged?: (assets: IAsset[] | null) => void,
    event?: MouseEvent
  ) => {
    let isNewSelectedAsset = !this.selectedAssets.some(
      (a) => a.id === asset.id
    );
    if (
      (event?.metaKey || event?.ctrlKey) &&
      !this.floorplanConfig.allowSingleSelection
    ) {
      // Multiple selection
      if (isNewSelectedAsset) {
        this.selectedAssets.push(asset);
      } else {
        this.selectedAssets = this.selectedAssets.filter(
          (selectedAsset) => selectedAsset.id !== asset.id
        );
      }
      // Invokes callback when the selection is changed
      if (onAssetSelectionChanged) {
        onAssetSelectionChanged(this.selectedAssets);
      }

      const assetIds = this.selectedAssets.map((asset) => asset.id);
      Drawer.highlightNodes(
        assetIds,
        this.instanceId,
        this.floorplanConfig.strokeConfig
      );
    } else {
      // Single selection
      Drawer.highlightNodes(
        [asset.id],
        this.instanceId,
        this.floorplanConfig.strokeConfig
      );

      // Invokes callback when the selection is changed
      if (onAssetSelectionChanged && isNewSelectedAsset) {
        onAssetSelectionChanged([asset]);
      }

      // Copying the asset by value to prevent modifying original data.
      this.selectedAssets = [asset];
    }

    this.checkButtonsVisibility();
    this.refreshControlsOnAssets();
    this.refreshMinimap();
  };

  private showRotationControls() {
    return (
      this.selectedAssets.length === 1 &&
      this.isEditMode &&
      [AssetType.DESK, AssetType.CHAIR].includes(this.selectedAssets[0].type)
    );
  }

  private showResizeControls() {
    return (
      this.selectedAssets.length === 1 &&
      this.isEditMode &&
      [AssetType.DESK, AssetType.CHAIR].includes(this.selectedAssets[0].type)
    );
  }

  private refreshControlsOnAssets() {
    const boardRect = d3
      .select("#" + this.instanceId)
      .select("g")
      .select("rect")
      .node();
    if (!boardRect) return;
    const currentTransform = d3.zoomTransform(boardRect);

    d3.select("#" + this.instanceId)
      .select("g")
      .selectAll(".asset-controls")
      .remove();

    if (this.selectedAssets.length != 1 || !this.isEditMode) return;

    const assetId = this.selectedAssets[0].id;
    const assetG = d3.select("#" + this.instanceId).select("#g" + assetId);

    const assetUIElement = d3
      .select("#" + this.instanceId)
      .select("#e" + assetId);

    const bbox = assetUIElement.node().getBBox();
    const transformAttr = assetUIElement.attr("transform");
    const scale = Utils.getScaleFromTransform(transformAttr);

    const bBoxW = bbox.width * scale.scaleX;
    const bBoxH = bbox.height * scale.scaleY;
    const bBoxX = bbox.x * scale.scaleX;
    const bBoxY = bbox.y * scale.scaleY;

    if (this.showRotationControls() || this.showResizeControls()) {
      assetG
        .append("rect")
        .attr("class", "asset-controls")
        .attr("x", bBoxX)
        .attr("y", bBoxY)
        .attr("width", bBoxW)
        .attr("height", bBoxH)
        .attr("stroke", "white")
        .attr("stroke-width", 2 / currentTransform.k)
        .attr(
          "stroke-dasharray",
          4 / currentTransform.k + "," + 4 / currentTransform.k
        )
        .attr("fill", "none");
      assetG
        .append("rect")
        .attr("class", "asset-controls")
        .attr("x", bBoxX)
        .attr("y", bBoxY)
        .attr("width", bBoxW)
        .attr("height", bBoxH)
        .attr("stroke", "black")
        .attr("stroke-width", 2 / currentTransform.k)
        .attr(
          "stroke-dasharray",
          4 / currentTransform.k + "," + 4 / currentTransform.k
        )
        .attr("stroke-dashoffset", 3 / currentTransform.k)
        .attr("fill", "none");
    }

    if (this.showRotationControls()) {
      const centerX = bBoxX + bBoxW / 2;
      const centerY = bBoxY;
      const lineLength = 20 / currentTransform.k;
      const endX = centerX;
      const endY = centerY - lineLength;
      assetG
        .append("line")
        .attr("class", "asset-controls")
        .attr("x1", centerX)
        .attr("y1", centerY)
        .attr("x2", endX)
        .attr("y2", endY)
        .attr("stroke", "black")
        .attr("stroke-width", 2 / currentTransform.k);

      assetG
        .append("circle")
        .attr("class", "asset-controls")
        .attr("cx", endX)
        .attr("cy", endY)
        .attr("r", 5 / currentTransform.k)
        .attr("cursor", "pointer")
        .attr("fill", "#00ff38")
        .attr("stroke", "black")
        .attr("stroke-width", 2 / currentTransform.k)
        .call(d3.drag().on("drag", this.dragRotate));
    }

    if (this.showResizeControls()) {
      const squareSize = 10 / currentTransform.k;
      const squaresData = [
        {
          x: bBoxX - squareSize / 2,
          y: bBoxY - squareSize / 2,
          drag: this.handleHeightAndWidthResize,
        }, // top left
        {
          x: bBoxX + bBoxW / 2 - squareSize / 2,
          y: bBoxY - squareSize / 2,
          drag: this.handleHeightResize,
        }, // top center
        {
          x: bBoxX + bBoxW - squareSize / 2,
          y: bBoxY - squareSize / 2,
          drag: this.handleHeightAndWidthResize,
        }, // top right
        {
          x: bBoxX - squareSize / 2,
          y: bBoxY + bBoxH / 2 - squareSize / 2,
          drag: this.handleWidthResize,
        }, // center left
        {
          x: bBoxX + bBoxW - squareSize / 2,
          y: bBoxY + bBoxH / 2 - squareSize / 2,
          drag: this.handleWidthResize,
        }, // center right
        {
          x: bBoxX - squareSize / 2,
          y: bBoxY + bBoxH - squareSize / 2,
          drag: this.handleHeightAndWidthResize,
        }, // bottom left
        {
          x: bBoxX + bBoxW / 2 - squareSize / 2,
          y: bBoxY + bBoxH - squareSize / 2,
          drag: this.handleHeightResize,
        }, // bottom center
        {
          x: bBoxX + bBoxW - squareSize / 2,
          y: bBoxY + bBoxH - squareSize / 2,
          drag: this.handleHeightAndWidthResize,
        }, // bottom right
      ];

      for (const data of squaresData) {
        assetG
          .append("rect")
          .attr("class", "asset-controls")
          .attr("x", data.x)
          .attr("y", data.y)
          .attr("cursor", "crosshair")
          .attr("width", squareSize)
          .attr("height", squareSize)
          .attr("stroke", "black")
          .attr("stroke-width", 1)
          .attr("fill", "white")
          .call(d3.drag().on("drag", data.drag));
      }
    }
  }

  private handleHeightAndWidthResize = (event: any, d: IAsset) => {
    const [x, y] = d3.pointer(
      event,
      d3
        .select("#" + this.instanceId)
        .select("g")
        .node()
    );
    // Calculate the asset's rotation angle (in degrees)
    const rotationInDegrees = d.rotationAngle || 0;

    // Convert degrees to radians
    const rotationInRadians = (rotationInDegrees * Math.PI) / 180;

    // Calculate the new width and position based on rotation
    const cosAngle = Math.cos(rotationInRadians);
    const sinAngle = Math.sin(rotationInRadians);

    const offsetX = x / this.pixelsPerMeter - d.x;
    const offsetY = y / this.pixelsPerMeter - d.y;

    // Adjust for the element's center
    const adjustedOffsetX = Math.abs(offsetX * cosAngle + offsetY * sinAngle);
    const adjustedOffsetY = Math.abs(-offsetX * sinAngle + offsetY * cosAngle);

    let tentativeWidthXOffset;
    let tentativeHeightXOffset;
    let tentativeWidthYOffset;
    let tentativeHeightYOffset;

    tentativeWidthXOffset = Math.abs(adjustedOffsetX * 2);
    const factor =
      Drawer.getAssetHeightMeters(d) / Drawer.getAssetWidthMeters(d);
    tentativeHeightXOffset = tentativeWidthXOffset * factor;
    tentativeHeightYOffset = Math.abs(adjustedOffsetY * 2);
    tentativeWidthYOffset = tentativeHeightYOffset / factor;
    this.checkBoundariesDetection(
      d,
      Math.max(tentativeWidthXOffset, tentativeWidthYOffset),
      Math.max(tentativeHeightXOffset, tentativeHeightYOffset)
    );
  };

  private handleHeightResize = (event: any, d: IAsset) => {
    const [x, y] = d3.pointer(
      event,
      d3
        .select("#" + this.instanceId)
        .select("g")
        .node()
    );
    // Calculate the asset's rotation angle (in degrees)
    const rotationInDegrees = d.rotationAngle || 0;

    // Convert degrees to radians
    const rotationInRadians = (rotationInDegrees * Math.PI) / 180;

    // Calculate the new width and position based on rotation
    const cosAngle = Math.cos(rotationInRadians);
    const sinAngle = Math.sin(rotationInRadians);

    // x and y are coordinates relative to the asset's center
    const offsetY =
      Math.abs((x / this.pixelsPerMeter - d.x) * sinAngle) +
      Math.abs((y / this.pixelsPerMeter - d.y) * cosAngle);

    const newHeight = offsetY * 2;
    this.checkBoundariesDetection(d, d.width!, newHeight);
  };

  private handleWidthResize = (event: any, d: IAsset) => {
    const [x, y] = d3.pointer(
      event,
      d3
        .select("#" + this.instanceId)
        .select("g")
        .node()
    );

    // Calculate the asset's rotation angle (in degrees)
    const rotationInDegrees = d.rotationAngle || 0;

    // Convert degrees to radians
    const rotationInRadians = (rotationInDegrees * Math.PI) / 180;

    // Calculate the new width and position based on rotation
    const cosAngle = Math.cos(rotationInRadians);
    const sinAngle = Math.sin(rotationInRadians);

    // x and y are coordinates relative to the asset's center
    const offsetX =
      Math.abs((x / this.pixelsPerMeter - d.x) * cosAngle) +
      Math.abs((y / this.pixelsPerMeter - d.y) * sinAngle);

    const newWidth = offsetX * 2;
    this.checkBoundariesDetection(d, newWidth, d.height!);
  };

  private checkBoundariesDetection = (
    d: IAsset,
    newWidth: number,
    newHeight: number
  ) => {
    if (this.selectedAssets.length != 1 || !this.isEditMode) return;

    const originalWidth = d.width;
    const originalHeight = d.height;

    const boardRect = d3
      .select("#" + this.instanceId)
      .select("g")
      .select("rect");

    d.width = newWidth;
    d.height = newHeight;

    this.updateAsset(d);

    d3.select("#" + this.instanceId)
      .select("g")
      .selectAll(".asset-controls")
      .remove();

    // Check boundaries after rotation and decide whether to keep it or not
    const assetNode = d3.select("#" + this.instanceId).select("#g" + d.id);
    const assetBox = assetNode.node().getBoundingClientRect();

    const boardRectBox = boardRect.node().getBoundingClientRect();

    if (
      !(
        assetBox.right < boardRectBox.right &&
        assetBox.left > boardRectBox.left &&
        assetBox.top > boardRectBox.top &&
        assetBox.bottom < boardRectBox.bottom
      )
    ) {
      d.width = originalWidth;
      d.height = originalHeight;

      this.updateAsset(d);
    } else {
      this.refreshControlsOnAssets();
    }
  };

  private dragRotate = (event: any, d: IAsset) => {
    if (this.selectedAssets.length != 1 || !this.isEditMode) return;

    const assetId = this.selectedAssets[0].id;
    const currentRotationAngle = this.selectedAssets[0].rotationAngle;

    const targetElement = d3
      .select("#" + this.instanceId)
      .select("g")
      .selectAll(".node")
      .filter(function (d: IAsset) {
        return d.id === assetId;
      });

    // We don't want controls UI to affect boundaries detection, so let's temporary hide them
    d3.select("#" + this.instanceId)
      .select("g")
      .selectAll(".asset-controls")
      .remove();

    const bBox = targetElement.node().getBoundingClientRect();
    const cx = (bBox.left + bBox.right) / 2; // center
    const cy = (bBox.top + bBox.bottom) / 2;
    const x = event.sourceEvent.clientX; //x and y of cursor
    const y = event.sourceEvent.clientY;

    const angleRadiansA = Math.atan2(y - cy, x - cx);
    const offset = 1; // Using a vertical offset from center to determine the axis
    const angleRadiansB = Math.atan2(cy - (cy - offset), cx - cx);

    // Calc final angle
    const angleDegrees =
      ((angleRadiansA - angleRadiansB) * 180) / Math.PI + 180;

    const angleOffset = Math.round(angleDegrees - currentRotationAngle);
    this.handleAssetsRotate(angleOffset);
    this.refreshControlsOnAssets();
  };

  private onAssetDragged = (
    draggedAsset: IAsset,
    event: MouseEvent,
    dx: number,
    dy: number
  ) => {
    //If the element selected is different from the dragged element, it sets the selected
    //element as the dragged element that comes from the Drawer
    if (this.placeholderAsset) return;
    if (
      !this.selectedAssets.some(
        (selectedAsset) => selectedAsset.id === draggedAsset.id
      )
    ) {
      this.onAssetClicked(
        draggedAsset,
        this.floorplanCallbacks.onAssetSelectionChanged,
        event
      );
    }

    if (this.isEditMode) {
      this.handleAssetsMove(dx, dy);
    }
  };

  handleAssetsMoveRequest = (dx: number, dy: number) => {
    if (this.selectedAssets.length === 0) return;
    this.handleAssetsMove(dx, dy);
    Drawer.updateNodesProperties(
      this.selectedAssets,
      this.instanceId,
      this.pixelsPerMeter,
      this.floorplanCallbacks.onAssetUpdated
    );
    this.updateAssetsData(this.selectedAssets);
  };

  private updateAssetsData(updatedAssets: IAsset[]) {
    //Updates all asset's data
    this.assets = this.assets.map((asset) => {
      const updatedAsset = updatedAssets.find(
        (updated) => updated.id === asset.id
      );

      if (updatedAsset) {
        return {
          ...asset,
          x: updatedAsset.x,
          y: updatedAsset.y,
          label: updatedAsset.label,
          color: updatedAsset.color,
          rotationAngle: updatedAsset.rotationAngle,
        };
      }

      // If there's no matching updatedAsset, return the original asset
      return asset;
    });
  }

  setSelectedAsset(assets: IAsset[], event?: MouseEvent) {
    if (assets.length > 0) {
      this.selectedAssets = assets;
      if (this.floorplanCallbacks.onAssetSelectionChanged) {
        this.floorplanCallbacks.onAssetSelectionChanged(this.selectedAssets);
      }

      const assetIds = this.selectedAssets.map((asset) => asset.id);

      Drawer.highlightNodes(
        assetIds,
        this.instanceId,
        this.floorplanConfig.strokeConfig
      );
    } else {
      this.onNoAssetSelected(this.floorplanCallbacks.onAssetSelectionChanged);
    }

    this.checkButtonsVisibility();
  }

  private onNoAssetSelected = (
    onAssetSelectionChanged?: (asset: IAsset[] | null) => void
  ) => {
    this.selectedAssets = [];
    Drawer.highlightNodes([], this.instanceId);
    this.refreshMinimap();
    this.refreshControlsOnAssets();
    this.checkButtonsVisibility();
    if (onAssetSelectionChanged) {
      onAssetSelectionChanged([]);
    }
  };

  handleAssetsMove = (dx: number, dy: number) => {
    if (this.selectedAssets.length === 0) return;
    let assetsBoxMinX;
    let assetsBoxMinY;
    let assetsBoxMaxX;
    let assetsBoxMaxY;

    // We don't want controls UI to affect boundaries detection, so let's temporary hide them
    d3.select("#" + this.instanceId)
      .select("g")
      .selectAll(".asset-controls")
      .style("display", "none");

    const boardRect = d3
      .select("#" + this.instanceId)
      .select("g")
      .select("rect");
    const boardRectBox = boardRect.node().getBoundingClientRect();
    const currentTransform = d3.zoomTransform(boardRect.node());
    let tentativeX = this.roundToNearestStepIfNeeded(
      this.selectedAssets[0].x * this.pixelsPerMeter + dx
    );

    let tentativeStepX =
      tentativeX - this.selectedAssets[0].x * this.pixelsPerMeter;

    let tentativeY = this.roundToNearestStepIfNeeded(
      this.selectedAssets[0].y * this.pixelsPerMeter + dy
    );

    let tentativeStepY =
      tentativeY - this.selectedAssets[0].y * this.pixelsPerMeter;

    let leftmostAsset: IAsset;
    let rightmostAsset: IAsset;
    let topAsset: IAsset;
    let bottomAsset: IAsset;

    let minXAllowed = 0;
    let maxXAllowed = 0;
    let minYAllowed = 0;
    let maxYAllowed = 0;

    for (const asset of this.selectedAssets) {
      const assetNode = d3
        .select("#" + this.instanceId)
        .select("#g" + asset.id);
      const boundingRect = assetNode.node().getBoundingClientRect();

      if (!assetsBoxMinX || boundingRect.left < assetsBoxMinX) {
        assetsBoxMinX = boundingRect.left;
        minXAllowed = this.roundToNearestStepIfNeeded(
          boundingRect.width / 2 / currentTransform.k,
          boundingRect.width / 2 / currentTransform.k,
          Number.POSITIVE_INFINITY
        );
        leftmostAsset = asset;
      }
      if (!assetsBoxMaxX || boundingRect.right > assetsBoxMaxX) {
        assetsBoxMaxX = boundingRect.right;
        maxXAllowed = this.roundToNearestStepIfNeeded(
          (boardRectBox.width - boundingRect.width / 2) / currentTransform.k,
          Number.NEGATIVE_INFINITY,
          (boardRectBox.width - boundingRect.width / 2) / currentTransform.k
        );
        rightmostAsset = asset;
      }

      if (!assetsBoxMinY || boundingRect.top < assetsBoxMinY) {
        assetsBoxMinY = boundingRect.top;
        minYAllowed = this.roundToNearestStepIfNeeded(
          boundingRect.height / 2 / currentTransform.k,
          boundingRect.height / 2 / currentTransform.k,
          Number.POSITIVE_INFINITY
        );
        topAsset = asset;
      }
      if (!assetsBoxMaxY || boundingRect.bottom > assetsBoxMaxY) {
        assetsBoxMaxY = boundingRect.bottom;
        maxYAllowed = this.roundToNearestStepIfNeeded(
          (boardRectBox.height - boundingRect.height / 2) / currentTransform.k,
          Number.NEGATIVE_INFINITY,
          (boardRectBox.height - boundingRect.height / 2) / currentTransform.k
        );
        bottomAsset = asset;
      }
    }

    let tentativeAssetMaxBoxX =
      assetsBoxMaxX + tentativeStepX * currentTransform.k;

    let tentativeAssetMinBoxX =
      assetsBoxMinX + tentativeStepX * currentTransform.k;

    //if the tentative step in the x direction surpasses boundaries, it's clamped to the max or min value
    if (tentativeAssetMaxBoxX > boardRectBox.right) {
      tentativeStepX = maxXAllowed - rightmostAsset!.x * this.pixelsPerMeter;
    }
    if (tentativeAssetMinBoxX < boardRectBox.left) {
      tentativeStepX = minXAllowed - leftmostAsset!.x * this.pixelsPerMeter;
    }

    for (const asset of this.selectedAssets) {
      asset.x =
        this.roundToNearestStepIfNeeded(
          asset.x * this.pixelsPerMeter + tentativeStepX
        ) / this.pixelsPerMeter;
    }

    let tentativeAssetMaxBoxY =
      assetsBoxMaxY + tentativeStepY * currentTransform.k;

    let tentativeAssetMinBoxY =
      assetsBoxMinY + tentativeStepY * currentTransform.k;

    //if the tentative step in the y direction surpasses boundaries, it's clamped to the max or min value
    if (tentativeAssetMaxBoxY > boardRectBox.bottom) {
      tentativeStepY = maxYAllowed - bottomAsset!.y * this.pixelsPerMeter;
    }
    if (tentativeAssetMinBoxY < boardRectBox.top) {
      tentativeStepY = minYAllowed - topAsset!.y * this.pixelsPerMeter;
    }

    for (const asset of this.selectedAssets) {
      asset.y =
        this.roundToNearestStepIfNeeded(
          asset.y * this.pixelsPerMeter + tentativeStepY
        ) / this.pixelsPerMeter;
    }

    Drawer.updateNodesProperties(
      this.selectedAssets,
      this.instanceId,
      this.pixelsPerMeter,
      this.floorplanCallbacks.onAssetUpdated
    );

    this.updateAssetsData(this.selectedAssets);
    this.refreshMinimap();
    d3.select("#" + this.instanceId)
      .select("g")
      .selectAll(".asset-controls")
      .style("display", null);
  };

  private roundToNearestStepIfNeeded(
    number: number,
    min: number = Number.NEGATIVE_INFINITY,
    max: number = Number.POSITIVE_INFINITY
  ) {
    if (this.gridPatternConfig) {
      return Utils.roundToNearestByStep(number, this.gridPixelsSize, min, max);
    } else {
      return number;
    }
  }

  getGridPixelsSize() {
    return this.gridPixelsSize;
  }

  getGridPatternConfig() {
    return this.gridPatternConfig;
  }

  blinkAssets(nodesToBlink: IdAndColor[]) {
    Drawer.blinkNodes(nodesToBlink, this.instanceId);
    this.refreshMinimap();
  }

  updateAsset(updatedAsset: IAsset) {
    if (!this.isEditMode) return;
    Drawer.updateNodesProperties(
      [updatedAsset],
      this.instanceId,
      this.pixelsPerMeter,
      this.placeholderAsset ? undefined : this.floorplanCallbacks.onAssetUpdated
    );
    this.updateAssetsData([updatedAsset]);
    this.refreshMinimap();
    this.refreshControlsOnAssets();

    // If the updated asset are included in selectedAssets then the update must be applied there too.
    this.selectedAssets = this.selectedAssets.map((selectedAsset) => {
      if (selectedAsset.id === updatedAsset.id) {
        return updatedAsset;
      } else {
        return selectedAsset;
      }
    });
  }

  setPlacingMode(type: AssetType | null) {
    this.placeAssetType = type;
    if (type && this.floorplanConfig.allowPlaceholder) {
      this.setPlaceholder();
    }
  }

  measurementStart = () => {
    this.measurementClear();
    this.isMeasuring = true;
    const mainG = d3.select("#" + this.instanceId).select("g");
    mainG.attr("cursor", "crosshair");
  };

  measurementStop = () => {
    if (this.isMeasuring) {
      this.isMeasuring = false;
      this.measurementClear();
      this.refreshMeasurement();
    }

    const mainG = d3.select("#" + this.instanceId).select("g");
    mainG.attr("cursor", null);
    if (this.floorplanCallbacks.onMeasurementStop)
      this.floorplanCallbacks.onMeasurementStop();
  };

  measurementClear = () => {
    d3.select("#" + this.instanceId)
      .select("g")
      .selectAll(".measurement")
      .remove();
    this.measurementStartLoc = null;
    this.measurementEndLoc = null;
  };

  private setPlaceholder = () => {
    const mainG = d3.select("#" + this.instanceId).select("g");

    mainG.on("mouseenter", (event: MouseEvent) => {
      if (this.placeAssetType) {
        this.createPlaceholder(event);
      } else {
        if (!this.placeholderAsset) return;
        this.deletePlaceholder();
      }
    });

    mainG.on("mousemove", (event: MouseEvent) => {
      if (!this.placeAssetType) return;

      if (!this.placeholderAsset) {
        this.createPlaceholder(event);
      } else {
        let [posX, posY] = d3.pointer(event);

        if (this.gridPatternConfig) {
          posX = Utils.roundToNearestByStep(posX, this.gridPixelsSize);
          posY = Utils.roundToNearestByStep(posY, this.gridPixelsSize);
        }

        const mainG = d3.select("#" + this.instanceId).select("g");
        const mainGBox = mainG.node().getBoundingClientRect();

        this.placeholderAsset.x = posX / this.dimensionsConfig.pixelsPerMeter;
        this.placeholderAsset.y = posY / this.dimensionsConfig.pixelsPerMeter;

        this.updateAsset(this.placeholderAsset);

        const assetNode = d3
          .select("#" + this.instanceId)
          .select("#g" + this.placeholderAsset.id);

        const assetBox = assetNode.node().getBoundingClientRect();
        if (
          !(
            assetBox.right < mainGBox.right &&
            assetBox.left > mainGBox.left &&
            assetBox.top > mainGBox.top &&
            assetBox.bottom < mainGBox.bottom
          )
        ) {
          this.deletePlaceholder();
        }
      }
    });

    mainG.on("mouseleave", (event: MouseEvent) => {
      this.deletePlaceholder();
    });
  };

  private createPlaceholder = (event: MouseEvent) => {
    if (!this.floorplanConfig.allowPlaceholder) return;
    let allAssets: IAsset[] = structuredClone(this.assets);

    let [posX, posY] = d3.pointer(event);

    if (this.gridPatternConfig) {
      posX = Utils.roundToNearestByStep(posX, this.gridPixelsSize);
      posY = Utils.roundToNearestByStep(posY, this.gridPixelsSize);
    }

    this.placeholderAsset = {
      id: v4(),
      label: "",
      type: this.placeAssetType!,
      x: posX / this.dimensionsConfig.pixelsPerMeter,
      y: posY / this.dimensionsConfig.pixelsPerMeter,
      color: this.floorplanConfig.defaultAssetColor || "#A39B99",
      rotationAngle: 0,
      opacity: 0.5,
    };

    allAssets.push(this.placeholderAsset);
    const mainG = d3.select("#" + this.instanceId).select("g");
    const mainGBox = mainG.node().getBoundingClientRect();

    this.draw(false, allAssets);

    const placeholderAssetNode = d3
      .select("#" + this.instanceId)
      .select("#g" + this.placeholderAsset.id);

    const assetBox = placeholderAssetNode.node().getBoundingClientRect();
    // Validate if newly added asset is in boundaries, if not undo the add
    if (
      !(
        assetBox.right <= mainGBox.right &&
        assetBox.left >= mainGBox.left &&
        assetBox.top >= mainGBox.top &&
        assetBox.bottom <= mainGBox.bottom
      )
    ) {
      allAssets = allAssets.filter(
        (asset) => asset.id != this.placeholderAsset?.id
      );
      this.deletePlaceholder();
    }
  };

  private deletePlaceholder = () => {
    if (!this.placeholderAsset) return;
    Drawer.deleteNode(this.placeholderAsset.id, this.instanceId);
    this.placeholderAsset = null;
    this.refreshMinimap();
  };

  private refreshMeasurement = () => {
    if (!this.measurementStartLoc || !this.measurementEndLoc) return;
    d3.select("#" + this.instanceId)
      .select("g")
      .selectAll(".measurement")
      .remove();

    const pxPerM = this.pixelsPerMeter;
    const x1 = this.measurementStartLoc.x * pxPerM;
    const y1 = this.measurementStartLoc.y * pxPerM;
    const x2 = this.measurementEndLoc.x * pxPerM;
    const y2 = this.measurementEndLoc.y * pxPerM;

    const lengthMeters = Math.sqrt(
      (this.measurementEndLoc.x - this.measurementStartLoc.x) ** 2 +
        (this.measurementEndLoc.y - this.measurementStartLoc.y) ** 2
    );

    let lengthLabel: string;
    if (this.floorplanConfig.measurementUnits === MeasurementUnits.METERS) {
      lengthLabel = lengthMeters.toFixed(2) + " m";
    } else {
      const { feet, inches } = Utils.convertMetersToFeetAndInches(lengthMeters);
      lengthLabel = `${feet}' ${inches}''`;
    }

    Drawer.drawMeasurement(
      this.instanceId,
      x1,
      y1,
      x2,
      y2,
      lengthLabel,
      this.measurementColor
    );
  };

  handleAssetsRotateRounded = (angle: number) => {
    if (this.selectedAssets.length > 1 || this.selectedAssets.length === 0)
      return;
    const asset = this.selectedAssets[0];
    const roundedNewAngle = Utils.roundToNearestByStep(
      asset.rotationAngle + angle,
      15
    );
    const angleOffset = roundedNewAngle - asset.rotationAngle;
    this.handleAssetsRotate(angleOffset);
  };

  handleAssetsRotate = (angle: number) => {
    if (this.selectedAssets.length > 1 || this.selectedAssets.length === 0)
      return;
    const asset = this.selectedAssets[0];
    asset.rotationAngle += angle;
    // Update UI without triggering callback onAssetUpdated
    Drawer.updateNodesProperties([asset], this.instanceId, this.pixelsPerMeter);

    // We don't want controls UI to affect boundaries detection, so let's temporary hide them
    d3.select("#" + this.instanceId)
      .select("g")
      .selectAll(".asset-controls")
      .style("display", "none");

    // Check boundaries after rotation and decide whether to keep it or not
    const assetNode = d3.select("#" + this.instanceId).select("#g" + asset.id);
    const assetBox = assetNode.node().getBoundingClientRect();
    const boardRect = d3
      .select("#" + this.instanceId)
      .select("g")
      .select("rect");
    const boardRectBox = boardRect.node().getBoundingClientRect();

    if (
      assetBox.right < boardRectBox.right &&
      assetBox.left > boardRectBox.left &&
      assetBox.top > boardRectBox.top &&
      assetBox.bottom < boardRectBox.bottom
    ) {
      // Keep rotation, update data and trigger callback onAssetUpdated if defined
      this.updateAssetsData([asset]);
      if (this.floorplanCallbacks.onAssetUpdated)
        this.floorplanCallbacks.onAssetUpdated(asset);
    } else {
      // Undo UI rotation without triggering callback onAssetUpdated
      asset.rotationAngle -= angle;
      Drawer.updateNodesProperties(
        [asset],
        this.instanceId,
        this.pixelsPerMeter
      );
    }
    d3.select("#" + this.instanceId)
      .select("g")
      .selectAll(".asset-controls")
      .style("display", null);
    this.refreshMinimap();
  };
}
