import { ITimeline, ITimelineEntry } from "@models/timeline.model";
import { dateSorter } from "@shared/util/table-utils";
import { Timeline as AntdTimeline, Badge, Col, Row, TimelineItemProps, TimelineProps, Typography, theme } from "antd";
import { getTimelineItemStrategy } from "./strategy-factory";
import { useTranslation } from 'react-i18next';


export interface ITimelineProps extends TimelineProps {
    timeline: ITimeline<ITimelineEntry> | null | undefined;    
}

export const Timeline = (props: ITimelineProps) => {
    const { timeline, ...restProps } = props;
    const { token: { colorText, colorFillQuaternary, colorPrimary, colorBorder } } = theme.useToken();
    const { t } = useTranslation();

    const timelineItems = (): TimelineItemProps[] => {
        const items: TimelineItemProps[] = [];
        if (timeline?.timeline) {
            const auxArray = [...timeline?.timeline];
            // sort by date descending
            auxArray.sort((c1: ITimelineEntry, c2: ITimelineEntry) => (c1.date && c2.date) ? dateSorter(c2.date, c1.date) : 0);
            auxArray.forEach(entry => {
                const itemRenderer = getTimelineItemStrategy(entry!.type!);
                items.push(itemRenderer.getItem(entry));
            });
        }
        return items;
    }

    return(<>
     <Row className="pb-10 w-full">
            <Col span={24} style={{ color: colorText }} >
                <div className='flex flex-row items-center pl-10 pt-8 pb-8'
                    style={{ border: `1px solid ${colorBorder}`, backgroundColor: colorFillQuaternary, borderRadius: 4 }}>
                    <Typography.Title level={5} style={{ margin: 0 }}>
                    {t("generic.timeline")}
                    </Typography.Title>
                    <Badge
                        count={timeline?.timeline?.length}
                        className='ml-10'
                        style={{ backgroundColor: colorPrimary, borderColor: colorFillQuaternary }} />
                </div>
            </Col>
        </Row>
        <Row className="pt-10 pl-20 pr-20 w-full">
            <Col span={24}>
                <AntdTimeline
                    items={timelineItems()}
                    {...restProps}
                />
            </Col>
        </Row>
    </>);
}