import { SVGProps } from "react";

type IProps = SVGProps<SVGSVGElement>

export const ChevronLeftSvgIcon = (props: IProps) => {  

    const { ...restProps } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...restProps}>
            <path d="M15 6L9 12L15 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
