import { BackButton } from "@components/Utils/BackButton";
import { TabContainer } from "@components/Utils/TabContainer";
import { useLayoutContex } from "@providers/LayoutProvider";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Badge, Button, Col, Row, theme, Tag } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CustomFieldGroupTypeEnum, CustomFieldGroupTypeListOptions } from "@models/enumerations/custom-field-group-type-enum.model";
import "@styles/components/admin-cost-groups.less";
import { getEntities as getCustomFieldGroups, reorderCustomFieldGroups } from "@store/slices/custom-field-groups";
import { ICustomFieldGroup, ICustomFieldGroupExtendedCount } from "@models/custom-field-group.model";
import { sortByOrderProperty } from "@shared/util/array-util";
import { DragHandle } from "@components/Icons/DragHandle";
import { EditOutlined } from "@ant-design/icons";
import { asyncLaunchNotification } from "@store/slices/notification";
import { customFieldsService } from "@services/custom-fields.service";
import { ICustomField } from "@models/custom-field.model";
import { CustomFieldGroupEdit } from "./CustomFieldGroupEdit";
import useVisible from "@HOOKs/UseVisible";
import { CustomFieldEdit } from "./CustomFieldEdit";

const DraggableItem = ({id, name, count, selected, onSelect, onEdit, handleDragStart, handleDragEnter, handleDragEnd, tag, config }) => {

    const { token: { colorPrimary, colorPrimaryBg, colorFillTertiary, colorFill, colorFillSecondary, colorPrimaryBorder, colorTextTertiary, colorBorder } } = theme.useToken();
    const { t } = useTranslation();

    const configParsed = config ? JSON.parse(config) : {};
    const options = configParsed?.options || [];

    return <div 
    draggable={true}
    onDragStart={(event) => handleDragStart(event, id)}
    onDragEnter={(event) => handleDragEnter(event, id)}
    onDragEnd={handleDragEnd} 
    className="mt-10 draggable-item" 
    style={{ display: 'block', background: selected ? colorPrimaryBg : colorFillTertiary, border: `1px solid ${(selected ? colorPrimaryBorder : colorFill)}` }} 
    onClick={() => { onSelect(id) }}>
        <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            <div className="mr-8 drag-handle" style={{ marginTop: '2px' }}>
                <DragHandle fill={selected ? colorPrimary : undefined} />
            </div>
            <div style={{ color: (selected ? colorPrimary : colorTextTertiary), fontSize: '14px' }}>
                {name}
            </div>
            <div style={{ flex: 1 }}>
                <Badge
                    count={count}
                    className='ml-10'
                    style={{ backgroundColor: colorFillSecondary, color: colorTextTertiary }} />
            </div>
            <div className="edit-link">
                <Button type="link" style={{ color: colorPrimary }} onClick={(e) => { e.stopPropagation(); onEdit(id) }}><EditOutlined /> {t("generic.edit")}</Button>
            </div>
            {tag === undefined && <div className="ml-8" style={{ marginTop: '2px' }}>
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.92334 10.58L5.50334 6L0.92334 1.41L2.33334 0L8.33334 6L2.33334 12L0.92334 10.58Z" fill="#292D2D" fill-opacity="0.15"/>
                </svg>
            </div>}
            {tag && <Tag>{tag}</Tag>}
        </div>
        {options && options.length > 0 && <div style={{ color: colorTextTertiary, borderTop: `1px solid ${colorBorder}` }}>
                <ul style={{ marginBottom: '8px', marginTop: '12px', paddingLeft: '35px' }}>
                    {options.map(o => 
                        <li>{o}</li>
                    )}
                </ul>
            </div>}
    </div>
}

interface IEntityItemProps {
    selected: boolean;
    entityType: CustomFieldGroupTypeListOptions;
    onClick: (type: CustomFieldGroupTypeListOptions) => void;
    groupCount: number;
}

const formatEntityType = (name: string) => {
    return name.charAt(0) + name.slice(1).toLowerCase();
}

const EntityItem = (props: IEntityItemProps) => {
    
    const { selected, entityType, onClick, groupCount } = props;

    const { token: { 
        colorFillTertiary,
        colorFill,
        colorPrimaryBg,
        colorPrimaryBorder,
        colorPrimary,
        colorTextTertiary,
        colorFillSecondary } } = theme.useToken();
    
    return <div className="mt-10 draggable-item" style={{ height: '42px', background: selected ? colorPrimaryBg : colorFillTertiary, border: `1px solid ${(selected ? colorPrimaryBorder : colorFill)}` }} onClick={() => { onClick(entityType) }}>
            <div style={{ color: (selected ? colorPrimary : colorTextTertiary), fontSize: '14px' }}>
                {formatEntityType(`${entityType}`)}
            </div>
            <div style={{ flex: 1 }}>
                <Badge
                    count={groupCount}
                    className='ml-10'
                    style={{ backgroundColor: colorFillSecondary, color: colorTextTertiary }} />
            </div>
            <div className="ml-8" style={{ marginTop: '2px' }}>
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.92334 10.58L5.50334 6L0.92334 1.41L2.33334 0L8.33334 6L2.33334 12L0.92334 10.58Z" fill="#292D2D" fill-opacity="0.15"/>
                </svg>
            </div>
        </div>
}

const entityTypes = [
    CustomFieldGroupTypeEnum.LOCATION,
    CustomFieldGroupTypeEnum.PROJECT,
    CustomFieldGroupTypeEnum.SITE
];

export const CustomFieldsTab = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { setHeaderLabelEntityState } = useLayoutContex();

    const [selectedEntityType, setSelectedEntityType] = useState<CustomFieldGroupTypeListOptions | null>(null)
    const { entities: customFieldGroups } = useAppSelector((state) => state.CustomFieldGroups);
    const [customFieldGroupsFiltered, setCustomFieldGroupsFiltered] = useState<ICustomFieldGroupExtendedCount[]>([])
    const [ customFields, setCustomFields ] = useState<ICustomField[]>([])
    const [ customFieldsSorted, setCustomFieldsSorted ] = useState<ICustomField[]>([])
    const [ selectedCustomFieldGroupId, setSelectedCustomFieldGroupId ] = useState<number | string>();
    const [openEditCustomFieldGroup, toggleEditCustomFieldGroup] = useVisible();
    const [editingCustomFieldGroup, setEditingCustomFieldGroup] = useState<ICustomFieldGroup | null>({})
    const [openEditCustomField, toggleEditCustomField] = useVisible();
    const [editingCustomField, setEditingCustomField] = useState<ICustomField | null>({})

    useEffect(() => {
        const sorted = [...customFieldGroups].sort(sortByOrderProperty);
        const sortedAndFiltered = sorted.filter(x => x?.referenceType === selectedEntityType);
        setCustomFieldGroupsFiltered(sortedAndFiltered)
    }, [customFieldGroups, selectedEntityType]);

    useEffect(() => {
        const sorted = [...customFields].sort(sortByOrderProperty);
        setCustomFieldsSorted(sorted);
    }, [customFields]);

    useEffect(() => {
        loadCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomFieldGroupId])

    useEffect(() => {
        if (!openEditCustomFieldGroup) {
          setEditingCustomFieldGroup({})
        }
      }, [openEditCustomFieldGroup]);

    useEffect(() => {
        if (!openEditCustomField) {
          setEditingCustomField({})
        }
      }, [openEditCustomField]);

    const loadCustomFields = async () => {
        if (selectedCustomFieldGroupId) {
            const response = await customFieldsService.getCustomFieldsForGroup(selectedCustomFieldGroupId);
            setCustomFields(response.data);
        } else {
            setCustomFields([]);
        }
    }

    const loadCustomFieldGroups = () => {
        dispatch(getCustomFieldGroups());
    }

    useEffect(() => {
        setHeaderLabelEntityState(t("admin.customFields"));
        loadCustomFieldGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const { token: { 
        colorPrimary, 
        colorBorderSecondary, 
        colorBgContainer, 
        colorBorder,
        colorFillTertiary,
        colorTextSecondary,
        colorBgBase,
        colorFill,
        colorFillSecondary,
        colorTextTertiary } } = theme.useToken();
    
    const navigateBack = () => {
        navigate('/admin/application-configuration')
    }

    const onAddGroup = () => {
        const maxOrder = Math.max(...customFieldGroups.map(x => Number(x.order))) || 0
        // @ts-ignore
        setEditingCustomFieldGroup({referenceType: selectedEntityType, order: (isFinite(maxOrder) ? maxOrder : 0) + 1})
        toggleEditCustomFieldGroup();
    }

    const onClickEntity = (type: CustomFieldGroupTypeListOptions) => {
        setSelectedCustomFieldGroupId(undefined);
        setSelectedEntityType(type);
    }

    const onEdit = (id: number) => {
        const cg = customFieldGroups.find(x => x.id === id);
        if (cg) {
            setEditingCustomFieldGroup(cg)
            toggleEditCustomFieldGroup();
        }
    }

    const onSelect = (id: any) => {
        setSelectedCustomFieldGroupId(id);
    }

    const [draggedItem, setDraggedItem] = useState<ICustomFieldGroupExtendedCount | null>(null);

    const handleDragStart = (event: React.DragEvent<HTMLDivElement>, id: number) => {
        const customFieldGroup = customFieldGroupsFiltered.find(x => x.id === id)
        if (customFieldGroup) {
            setDraggedItem(customFieldGroup);
        }
    };

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>, id: number) => {
        event.preventDefault();
        const customFieldGroup = customFieldGroupsFiltered.find(x => x.id === id)
        if (customFieldGroup) {
            setCustomFieldGroupsFiltered((prevItems) => {
                const newItems = [...prevItems];
                const draggedItemIndex = prevItems.findIndex((i) => i.id === draggedItem?.id);
                const itemIndex = prevItems.findIndex((i) => i.id === customFieldGroup.id);
                newItems.splice(draggedItemIndex, 1);
                newItems.splice(itemIndex, 0, draggedItem!);
                return newItems;
            });
        }
    };

    const handleDragEnd = () => {
        setDraggedItem(null);
        const ids = customFieldGroupsFiltered.map(s => Number(s.id));
        if (customFieldGroupsFiltered.filter(s => Number(s.id)).length !== ids.length) {
            dispatch(asyncLaunchNotification({
                type: "error",
                config: {
                    message: `Invalid IDs`,
                    description: `IDs are invalid. Please refresh and try again.`
                }
            }));
            return false;
        }
        // @ts-ignore
        dispatch(reorderCustomFieldGroups({referenceType: selectedEntityType, ids}));
    };

    const [draggedField, setDraggedField] = useState<ICustomField | null>(null);

    const handleDragStartFields = (event: React.DragEvent<HTMLDivElement>, id: number) => {
        const customFieldGroup = customFieldsSorted.find(x => x.id === id)
        if (customFieldGroup) {
            setDraggedField(customFieldGroup);
        }
    };

    const handleDragEnterFields = (event: React.DragEvent<HTMLDivElement>, id: number) => {
        event.preventDefault();
        const customField = customFieldsSorted.find(x => x.id === id)
        if (customField) {
            setCustomFieldsSorted((prevItems) => {
                const newItems = [...prevItems];
                const draggedItemIndex = prevItems.findIndex((i) => i.id === draggedField?.id);
                const itemIndex = prevItems.findIndex((i) => i.id === customField.id);
                newItems.splice(draggedItemIndex, 1);
                newItems.splice(itemIndex, 0, draggedField!);
                return newItems;
            });
        }
    };

    const handleDragEndFields = async () => {
        setDraggedField(null);
        const ids = customFieldsSorted.map(s => Number(s.id));
        if (customFieldsSorted.filter(s => Number(s.id)).length !== ids.length) {
            dispatch(asyncLaunchNotification({
                type: "error",
                config: {
                    message: `Invalid IDs`,
                    description: `IDs are invalid. Please refresh and try again.`
                }
            }));
            return false;
        }
        // @ts-ignore
        await customFieldsService.reorder(selectedCustomFieldGroupId, ids);
        loadCustomFields();
    };

    const onAddField = () => {
        const maxOrder = Math.max(...customFields.map(x => Number(x.order))) || 0
        setEditingCustomField({customFieldGroup: { id: Number(selectedCustomFieldGroupId) }, order: (isFinite(maxOrder) ? maxOrder : 0) + 1})
        toggleEditCustomField();
    }

    const onEditField = (id: number) => {
        const cf = customFields.find(x => x.id === id);
        if (cf) {
            setEditingCustomField(cf)
            toggleEditCustomField();
        }
    }

    return <>
        <CustomFieldGroupEdit open={openEditCustomFieldGroup} toggle={toggleEditCustomFieldGroup} customFieldGroup={editingCustomFieldGroup} onModifiedChanges={() => { loadCustomFieldGroups() }} />
        <CustomFieldEdit open={openEditCustomField} toggle={toggleEditCustomField} customField={editingCustomField} onModifiedChanges={() => { loadCustomFieldGroups(); loadCustomFields(); }} />
        <TabContainer>
            <div className="flex flex-col overflow-auto">
                <Row>
                    <Col span={24}>
                        <div className="flex flex-row items-center pl-10 pt-11 pb-11" style={{ borderTop: `1px solid ${colorBorderSecondary}`, borderBottom: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                            <BackButton onClick={navigateBack} >{t("admin.applicationConfiguration")}</BackButton>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="admin-cost-groups-panel" style={{ border: `2px solid ${colorBorder}`, background: colorBgBase }}>
                <div className="cost-groups-column">
                    <div className="cost-groups-header" style={{ background: colorFillTertiary, border: `1px solid ${colorFill}` }}>
                        <div className="title-text" style={{ flex: 0, color: colorTextSecondary }}>{t("admin.entity")}</div>
                    </div>
                    {entityTypes.map(t => {
                        return <EntityItem 
                                    key={t} 
                                    groupCount={customFieldGroups.filter(x => x.referenceType === t).length}
                                    entityType={t} 
                                    selected={selectedEntityType === t}
                                    onClick={onClickEntity} />
                    })}
                </div>
                <div className="cost-groups-column">
                    <div className="cost-groups-header" style={{ background: colorFillTertiary, border: `1px solid ${colorFill}` }}>
                        <div className="title-text" style={{ flex: 0, color: colorTextSecondary }}>{t("admin.groups")}</div>
                        <div style={{ flex: 1 }}>
                            <Badge
                                count={customFieldGroupsFiltered.length}
                                className='ml-10'
                                style={{ backgroundColor: colorFillSecondary, color: colorTextTertiary }} />
                        </div>
                        {selectedEntityType && <div style={{ flex: 0 }}>
                            <Button type="link" style={{ color: colorPrimary }} onClick={onAddGroup}>+ {t("generic.addNew")}</Button>
                        </div>}
                    </div>
                    <div>
                        {customFieldGroupsFiltered.map((cg) => 
                            <DraggableItem 
                                key={cg.id} 
                                id={cg.id} 
                                name={cg.name} 
                                tag={undefined} 
                                count={cg.fieldCount}
                                onEdit={onEdit}
                                onSelect={onSelect}
                                selected={selectedCustomFieldGroupId === cg.id}
                                handleDragStart={handleDragStart}
                                handleDragEnd={handleDragEnd}
                                handleDragEnter={handleDragEnter}
                                config={undefined} />
                        )}
                    </div>
                </div>
                <div className="cost-groups-column">
                    <div className="cost-groups-header" style={{ background: colorFillTertiary, border: `1px solid ${colorFill}` }}>
                        <div className="title-text" style={{ flex: 0, color: colorTextSecondary }}>{t("admin.fields")}</div>
                        <div style={{ flex: 1 }}>
                            <Badge
                                count={customFieldsSorted.length}
                                className='ml-10'
                                style={{ backgroundColor: colorFillSecondary, color: colorTextTertiary }} />
                        </div>
                        {selectedCustomFieldGroupId && <div style={{ flex: 0 }}>
                            <Button type="link" style={{ color: colorPrimary }} onClick={onAddField}>+ {t("generic.addNew")}</Button>
                        </div>}
                    </div>
                    <div>
                        {customFieldsSorted.map((cf) => 
                            <DraggableItem 
                                key={cf.id} 
                                id={cf.id} 
                                name={cf.label}
                                tag={cf.fieldType} 
                                count={0}
                                onEdit={onEditField}
                                onSelect={() => {}}
                                selected={false}
                                handleDragStart={handleDragStartFields}
                                handleDragEnd={handleDragEndFields}
                                handleDragEnter={handleDragEnterFields}
                                config={cf.config} />
                        )}
                    </div>
                </div>
            </div>
        </TabContainer>
    </>;
}