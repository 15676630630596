import { Can } from "@components/Permisions/Can";
import { useAppDispatch, useAppSelector } from "@store/store";
import { ConfigProvider, Select, theme } from "antd";
import { useParams } from "react-router-dom";
import { updateEntityStatus as updateProjectStatus } from '@store/slices/projects';
import { DownOutlined } from "@ant-design/icons";
import { getEntities as getProjectStatuses } from '@store/slices/project-statuses';
import { useEffect } from "react";

interface IOverviewProjectStatusSelectProps {
    defaultValue: string
}

export const OverviewProjectStatusSelect = (props: IOverviewProjectStatusSelectProps) => {

    const { defaultValue } = props

    const { token: { colorFillSecondary } } = theme.useToken();

    const dispatch = useAppDispatch();
    const { projectId } = useParams<"projectId">();
    const { entities: projectStatuses } = useAppSelector((state) => state.ProjectStatuses);
    const { entity: project } = useAppSelector((state) => state.Project);

    const onSelectProjectStatus = (valueSelected: string) => {
        const projectStatusSelected = projectStatuses.find(({ id }) => String(id) === String(valueSelected));
        if (project && projectStatusSelected) {
            dispatch(updateProjectStatus({ id: projectId, projectStatus: projectStatusSelected }))
        }
    }

    useEffect(() => {
        dispatch(getProjectStatuses({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Can I="EDIT" A="PROJECT" Entity={{ id: projectId }} >
            <ConfigProvider theme={{
                components: {
                    Select: { colorPrimaryHover: colorFillSecondary, fontSizeIcon: 9, borderRadius: 5 }
                }
            }}>
                <Select
                    className="w-full"
                    suffixIcon={<DownOutlined className="font-bold" />}
                    defaultValue={defaultValue}
                    options={projectStatuses.map((item) => ({ value: item.id, label: (<div className="block font-semibold w-full">{item?.name}</div>) }))}
                    onSelect={onSelectProjectStatus}
                    popupMatchSelectWidth={false}
                />
            </ConfigProvider>
        </Can>
    )
}
