import { http } from "../infrastructure/http/axios.instance";

export const path = 'utils';

export const retryFilePreviewGeneration = async (attachmentId: number) => {
  const requestUrl = `${path}/retry-file-preview-generation?attachmentId=${attachmentId}`;
  const promiseGetAll = await http.get<any>(requestUrl);
  return promiseGetAll;
};

export const filePreviewUtilsService = {
  retryFilePreviewGeneration: retryFilePreviewGeneration,
}

