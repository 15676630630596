
import { Button, Switch } from 'antd';
import image from './../../assets/img/under-construction.svg'
import cloud from './../../assets/img/cloud.svg'
import './UnderConstruction.less';
import PosterAnimated from './PosterAnimated';

export default function UnderConstruction() {
  return (
    <div className='under-construction'>
      <div className='under-construction--card'>
          <div className='under-construction--left-side'>
            <img src={cloud} alt="" className='under-construction--cloud-1' />
            <img src={cloud} alt="" className='under-construction--cloud-2' />
            <img src={cloud} alt="" className='under-construction--cloud-3' />
            <img src={cloud} alt="" className='under-construction--cloud-4' />
            <PosterAnimated />
            <span className='under-construction--title'>Website <b>-tog-Level5</b></span>
            <span className='under-construction--subtitle'>
              We are currently working on this site. <br/>
              Our website is coming soon.<br/>
              In the meantime, check our social networks.
            </span>
            <div className='under-construction--status'>
              <Button type="dashed" className='under-construction--status-button'>
                <span className='under-construction--status-button__content'>Maintenance mode</span>
              </Button>
              <Switch checkedChildren="ON" unCheckedChildren="OFF" defaultChecked className='under-construction--status-switch'/>
            </div>
          </div>
          <img src={image} alt="" className='under-construction--img' />
      </div>      
    </div>
  )
}
