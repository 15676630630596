import { CustomButtonText } from "@components/Utils/CustomButtonText";
import { TabContainer } from "@components/Utils/TabContainer";
import { FeatureFlagOptionEnum } from "@models/enumerations/feature-flag-option.enum";
import { useFeatureFlagsProvider } from "@providers/FeatureFlagsProvider";
import { useLayoutContex } from "@providers/LayoutProvider";
import { Button, Card } from "antd"
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const Overview = (props) => {

    const { setHeaderLabelEntityState } = useLayoutContex()
    const navigate = useNavigate();
    const { t } = useTranslation();
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { flags, isFlagEnabled } = useFeatureFlagsProvider();
    const isRFIEnabled = isFlagEnabled(FeatureFlagOptionEnum.RFI);
    const isBudgetEnabled = isFlagEnabled(FeatureFlagOptionEnum.BUDGET);
    const isOxBlueEnabled = isFlagEnabled(FeatureFlagOptionEnum.OX_BLUE_INTEGRATION);
    const isChangeOrdersEnabled = isFlagEnabled(FeatureFlagOptionEnum.CHANGE_ORDERS);
    const isPunchListEnabled = isFlagEnabled(FeatureFlagOptionEnum.PUNCHLIST);

    useEffect(() => {
        setHeaderLabelEntityState(t("admin.overview"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const navigateToUsers = () => {
        navigate('/admin/access-and-permissions/users');
    }

    const navigateToGroups = () => {
        navigate('/admin/access-and-permissions/groups');
    }

    const navigateToPermissions = () => {
        navigate('/admin/access-and-permissions/permissions');
    }

    const navigateToAccess = () => {
        navigate('/admin/access-and-permissions');
    }

    const navigateToModuleConfig = () => {
        navigate('/admin/module-configuration')
    }

    const navigateToProjects = () => {
        navigate('/admin/module-configuration/projects')
    }

    const navigateToProjectDeliverables = () => {
        navigate('/admin/module-configuration/project-deliverables')
    }

    const navigateToSites = () => {
        navigate('/admin/module-configuration/sites')
    }

    const navigateToRFI = () => {
        navigate('/admin/module-configuration/rfi')
    }

    const navigateToBudget = () => {
        navigate('/admin/module-configuration/budget')
      }

    const navigateToAppConfig = () => {
        navigate('/admin/application-configuration')
    }

    const navigateToCostManagement = () => {
        navigate('/admin/application-configuration/cost-management')
    }

    const navigateToOtherSettings = () => {
        navigate('/admin/application-configuration/other-settings')
    }

    const navigateToChangeOrder = () => {
        navigate('/admin/module-configuration/change-order')
    }

    const navigateToCustomFields = () => {
        navigate('/admin/application-configuration/custom-fields');
    }

    const navigateToIntegration = () => {
        navigate('/admin/application-configuration/integration');
    }

    const navigateToPunchList = () => {
        navigate('/admin/module-configuration/punch-list')
    }

    return ( 
        <TabContainer>
            <div style={{ padding: '16px 24px' }}>
                <Card title={t("admin.accessAndPermissions")} extra={<CustomButtonText type="default" onClick={navigateToAccess}>{t("generic.view")}</CustomButtonText>} className="admin-card">
                    <ul>
                        <li><Button type="link" onClick={navigateToUsers}>{t("admin.users")}</Button></li>
                        <li><Button type="link" onClick={navigateToGroups}>{t("admin.groups")}</Button></li>
                        <li><Button type="link" onClick={navigateToPermissions}>{t("admin.permissions")}</Button></li>
                    </ul>
                </Card>
                <Card title={t("admin.applicationConfiguration")} extra={<CustomButtonText type="default" onClick={navigateToAppConfig}>{t("generic.view")}</CustomButtonText>} className="admin-card">
                    <ul>
                        <li><Button type="link" onClick={navigateToCostManagement}>{t("admin.costManagement")}</Button></li>
                        <li><Button type="link" onClick={navigateToOtherSettings}>{t("admin.otherSettings")}</Button></li>
                        {isOxBlueEnabled && <li><Button type="link" onClick={navigateToIntegration}>{t("admin.integration")}</Button></li>}
                        <li><Button type="link" onClick={navigateToCustomFields}>{t("admin.customFields")}</Button></li>
                    </ul>
                </Card>
                <Card title={t("admin.moduleConfiguration")} extra={<CustomButtonText type="default" onClick={navigateToModuleConfig}>{t("generic.view")}</CustomButtonText>} className="admin-card">
                    <ul>
                        <li><Button type="link" onClick={navigateToSites}>{t("admin.sites")}</Button></li>
                        <li><Button type="link" onClick={navigateToProjects}>{t("admin.projects")}</Button></li>
                        <li><Button type="link" onClick={navigateToProjectDeliverables}>{t("admin.projectDeliverables")}</Button></li>
                        {isRFIEnabled && <li><Button type="link" onClick={navigateToRFI}>{t("admin.rfi")}</Button></li>}
                        {isBudgetEnabled && <li><Button type="link" onClick={navigateToBudget}>{t("budget.budget")}</Button></li>}
                        {isChangeOrdersEnabled && <li><Button type="link" onClick={navigateToChangeOrder}>{t("admin.changeOrder")}</Button></li>}
                        {isPunchListEnabled && <li><Button type="link" onClick={navigateToPunchList}>{t("punchList.punchList")}</Button></li>}
                    </ul>
                </Card>
            </div>
        </TabContainer>
    );
}