/* eslint-disable react-hooks/exhaustive-deps */
import { TabContainer } from "@components/Utils/TabContainer";
import { IChangeOrderItem } from "@models/change-order-item.model";
import { ICostCategory } from "@models/cost-category.model";
import { ICostSubCategory } from "@models/cost-sub-category.model";
import { ChangeOrderItemTypeEnum } from "@models/enumerations/change-order-item-type-enum.model";
import { costCategoryService } from "@services/cost-category.service";
import { costSubCategoryService } from "@services/cost-sub-category.service";
import { getEntities as getChangeOrderItems } from "@store/slices/change-order-items";
import { getEntity as getChangeOrder, submitDetail } from "@store/slices/change-orders";
import { asyncLaunchNotification } from "@store/slices/notification";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Form } from "antd";
import Big from "big.js";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BackHeader } from "../shared/BackHeader";
import { ChangeOrderCostsForm, IChangeOrderFormItem } from "./ChangeOrderCostsForm";

export const ChangeOrderCostsUpdate = () => {
    const { coId } = useParams<"coId">();
    const { entity: changeOrderEntity } = useAppSelector((state) => state.ChangeOrder);
    const { entities: changeOrderItems } = useAppSelector((state) => state.ChangeOrderItem);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    
    const [costCategories, setCostCategories] = useState<ICostCategory[]>([])
    const [subCategoriesByCategory, setSubCategoriesByCategory] = useState<Map<number, ICostSubCategory[]> | undefined>(undefined)
    const [materialsSubCategoriesByIndex, setMaterialsSubCategoriesByIndex] = useState<Map<number, ICostSubCategory[]> | undefined>(undefined)
    const [laborSubCategoriesByIndex, setLaborSubCategoriesByIndex] = useState<Map<number, ICostSubCategory[]> | undefined>(undefined)

    const [loading, setLoading] = useState<boolean>(true)
    const [loadingItems, setLoadingItems] = useState<boolean>(true)
    const [loadingSubCategoriesByCategory, setLoadingSubCategoriesByCategory] = useState<boolean>(true)
    const [loadingMaterialsSubCategoriesByIndex, setLoadingMaterialsSubCategoriesByIndex] = useState<boolean>(true)
    const [loadingLaborSubCategoriesByIndex, setLoadingLaborSubCategoriesByIndex] = useState<boolean>(true)

    const [materialsInitialItems, setMaterialsInitialItems] = useState<IChangeOrderFormItem[] | undefined>(undefined)
    const [laborInitialItems, setLaborInitialItems] = useState<IChangeOrderFormItem[] | undefined>(undefined)
    const [materialsSubtotal, setMaterialsSubtotal] = useState<Big>(Big(0))
    const [laborSubtotal, setLaborSubtotal] = useState<Big>(Big(0))

    const [form] = Form.useForm();

    useEffect(() => {
        if (coId) {
            dispatch(getChangeOrder(coId))
        }
    }, [coId])

    const getCostCategories = (costGroupingId: number) => {
        costCategoryService.getActiveCostCategories(costGroupingId).then((response) => {
            setCostCategories(response.data)
        }).catch((error) => {
            onErrorDefault();
        });
    }

    const handleGetChangeOrdersSucess = () => {
        setLoadingItems(false)
    }

    useEffect(() => {
        if (coId) {
            dispatch(getChangeOrderItems({ changeOrderId: Number(coId), onSuccess: handleGetChangeOrdersSucess }))
        }
    }, [coId])

    useEffect(() => {
        setLoading(loadingItems === true
            || loadingSubCategoriesByCategory === true
            || loadingMaterialsSubCategoriesByIndex === true
            || loadingLaborSubCategoriesByIndex === true);
    }, [loadingItems, loadingSubCategoriesByCategory, loadingMaterialsSubCategoriesByIndex, loadingLaborSubCategoriesByIndex])


    const mapToFromItems = (itemType: ChangeOrderItemTypeEnum): IChangeOrderFormItem[] => {
        return changeOrderItems?.filter(item => item.type === itemType)
            .map(item => {
                return {
                    id: item.id,
                    costCategory: item.costSubCategory?.costCategory?.id,
                    costSubCategory: item.costSubCategory?.id,
                    description: item.description,
                    unitsQty: Number(item.unitsQty),
                    unitCost: Number(item.unitCost)
                } as IChangeOrderFormItem
            });
    }

    const handleCategoriesFetched = (categoriesIds: number[], subcategories: ICostSubCategory[]) => {
        const map: Map<number, ICostSubCategory[]> = new Map();
        categoriesIds.forEach(id => {
            map.set(id, subcategories.filter(subCategory => subCategory.costCategory?.id === id));
        });
        setSubCategoriesByCategory(map);

    }

    useEffect(() => {
        if (subCategoriesByCategory) {
            setLoadingSubCategoriesByCategory(false);
        }
    }, [subCategoriesByCategory])

    useEffect(() => {
        if (changeOrderItems && loadingItems === false) {
            if (changeOrderItems[0]?.costSubCategory?.costCategory?.costGrouping?.id) {
                getCostCategories(changeOrderItems[0].costSubCategory?.costCategory?.costGrouping?.id)
            }
            var categoriesIds = changeOrderItems.map(item => item.costSubCategory?.costCategory?.id!).filter((value, index, array) => array.indexOf(value) === index);
            costSubCategoryService.getActiveCostSubcategoriesByCategoriesIds(categoriesIds)
                .then((result) => handleCategoriesFetched(categoriesIds, result.data))
                .catch((error) => {
                    onErrorDefault();
                });
            const material = mapToFromItems(ChangeOrderItemTypeEnum.MATERIALS);
            setMaterialsInitialItems(material);
            recalculateMaterialsSubtotal(material);

            const labor = mapToFromItems(ChangeOrderItemTypeEnum.LABOR);
            setLaborInitialItems(labor);
            recalculateLaborSubtotal(labor);
        }
    }, [changeOrderItems, loadingItems])

    useEffect(() => {
        if (materialsInitialItems && subCategoriesByCategory) {
            const map: Map<number, ICostSubCategory[]> = new Map();
            materialsInitialItems.forEach((value: IChangeOrderFormItem, index: number) => {
                map.set(index, subCategoriesByCategory.get(Number(value.costCategory)) ?? []);
            });
            setMaterialsSubCategoriesByIndex(map);
        }
    }, [materialsInitialItems, subCategoriesByCategory])

    useEffect(() => {
        if (materialsSubCategoriesByIndex) {
            setLoadingMaterialsSubCategoriesByIndex(false);
        }
    }, [materialsSubCategoriesByIndex])

    useEffect(() => {
        if (laborInitialItems && subCategoriesByCategory) {
            const map: Map<number, ICostSubCategory[]> = new Map();
            laborInitialItems.forEach((value: IChangeOrderFormItem, index: number) => {
                map.set(index, subCategoriesByCategory.get(Number(value.costCategory)) ?? []);
            });
            setLaborSubCategoriesByIndex(map);
        }
    }, [laborInitialItems, subCategoriesByCategory])

    useEffect(() => {
        if (laborSubCategoriesByIndex) {
            setLoadingLaborSubCategoriesByIndex(false);
        }
    }, [laborSubCategoriesByIndex])

    const onCreateItemsSuccess = () => {
        dispatch(asyncLaunchNotification({
            type: "success",
            config: {
                message: t("changeOrder.costsDetail.detailSentTitle"),
                description: t("changeOrder.costsDetail.detailSentSubtitle")
            }
        }));
        navigateBack();
    }

    const handleFinish = (items: IChangeOrderItem[]) => {
        dispatch(submitDetail({ id: Number(coId), items: items, onSuccess: onCreateItemsSuccess }));
    };

    const onErrorDefault = () => {
        dispatch(asyncLaunchNotification({
            type: "error",
            config: {
                message: t("changeOrder.changeOrder"),
                description: t("generic.defaultError")
            }
        }));
    }

    const navigateBack = () => {
        const newPath = pathname.substring(0, pathname.lastIndexOf('/'));
        navigate(newPath);
    }

    const calculateSubtotal = (itemValues: any[]): Big => {
        let cost = Big(0);
        itemValues?.forEach((item: any) => {
            if (item?.unitsQty && item?.unitCost && Number.isInteger(Number(item?.unitsQty))) {
                const qty = new Big(item.unitsQty)
                cost = Big(cost.add(qty.times(item.unitCost)))
            }
        })
        return cost;
    }

    const recalculateMaterialsSubtotal = (initialValues?: any[]) => {
        const materialsItemsValues = initialValues ?? form.getFieldValue("materialsItems")
        setMaterialsSubtotal(calculateSubtotal(materialsItemsValues));
    }

    const recalculateLaborSubtotal = (initialValues?: any[]) => {
        const laborItemsValues = initialValues ?? form.getFieldValue("laborItems")
        setLaborSubtotal(calculateSubtotal(laborItemsValues));
    }

    return (
        <TabContainer>
            <BackHeader label={t("changeOrder.changeOrder") + " #" + changeOrderEntity?.number} />
            <ChangeOrderCostsForm
                operation="UPDATE"
                changeOrder={changeOrderEntity}
                costCategories={costCategories}
                materialsInitialItems={materialsInitialItems}
                laborInitialItems={laborInitialItems}
                initialSubCategoriesByCategory={subCategoriesByCategory}
                initialMaterialsSubCategoriesByIndex={materialsSubCategoriesByIndex}
                initialLaborSubCategoriesByIndex={laborSubCategoriesByIndex}
                initialMaterialsSubtotal={materialsSubtotal}
                initialLaborSubtotal={laborSubtotal}
                onFinish={handleFinish}
                loading={loading}
            />
        </TabContainer>
    );
}