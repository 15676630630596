import { DownloadOutlined } from "@ant-design/icons";
import { FileCancelSvgIcon } from "@components/Icons/FileCancelSvgIcon";
import { IAttachment } from "@models/attachment.model";
import { useThemeProvider } from "@providers/ThemeProvider";
import { attachmentService } from "@services/attachment.service";
import { downloadUrl } from "@shared/util/download-util";
import { Button, theme } from "antd";
import { useState } from "react";

interface IPreviewNotAvailableProps {
  attachment: IAttachment
}

export const PreviewNotAvailable = (props: IPreviewNotAvailableProps) => {

    const { attachment } = props;

    const { token: { colorText, colorBgBase, colorFill, fontSizeLG }} = theme.useToken();

    const [loadingDownload, setLoadingDownload] = useState(false);

    const { themeConfig } = useThemeProvider();

    const onDownloadAction = (): Promise<void> => {
        if (!attachment) { return Promise.resolve(); }

        setLoadingDownload(true);
        const handleGetViewLink = (response) => {
            if (response?.data) { downloadUrl(`${response.data}`); }
        }
        return attachmentService.getViewLink(String(attachment?.id))
                                .then(handleGetViewLink)
                                .finally(() => setLoadingDownload(false));
    }
    
    return (
      <div
        data-testid="preview-not-available-container"
        className="flex flex-col items-center justify-center pt-15 pb-15 pl-15 pr-15"    
        style={{
            borderRadius: 8,
            background: colorBgBase
        }}
        >
            <div 
                className="flex flex-col items-center justify-center mt-12 mb-12 ml-7 mr-7"
            >
                <FileCancelSvgIcon 
                    data-testid="preview-not-available-icon"
                    className="mb-3"
                    style={{ color: colorFill }} 
                    fillOpacityContainer={`${Boolean(themeConfig?.darkMode) ? 0.85 : 0.60 }`}
                />
                <span 
                    className="mb-12"
                    style={{ color: colorText, fontSize: fontSizeLG }} >
                    Preview not available
                </span>
                <Button 
                    data-testid="preview-not-available-download-button"
                    type="primary" 
                    className="ml-20 mr-20" 
                    style={{ boxShadow: "none" }}
                    onClick={onDownloadAction} 
                    loading={loadingDownload} 
                >
                    <DownloadOutlined />
                    Download Attachment
                </Button>
            </div>
      </div>
    )
}