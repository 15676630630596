import { useState, useEffect, useLayoutEffect } from 'react'
import { useLayoutContex } from '@providers/LayoutProvider';
import CompanyDetailsHeader from './CompanyDetailsHeader';
import CompanyContacts from './CompanyContacts/CompanyContacts';
import { useParams } from 'react-router-dom';
import { getEntity } from '@store/slices/company';
import { AppDispatch, useAppSelector } from '@store/store';
import { useDispatch } from 'react-redux';
import { getOpenGraph, reset } from '@store/slices/open-graph';

export default function CompanyDetails() {
  const { id } = useParams<'id'>();

  const { entity: companyEntity, loading } = useAppSelector(state => state.Company);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(reset());
    if (id && companyEntity && String(companyEntity.id) !== id) {
      dispatch(getEntity(id)).then((response: any) => {
        if (response && response.payload && response.payload.data && response.payload.data.website) {
          dispatch(getOpenGraph({ url: response.payload.data.website }));
        }
      });
    }
  }, [dispatch, id, companyEntity]);

  const [heightContent, setHeightContent] = useState(0);

  const { headerLabelEntity, layoutSize, setHeaderLabelEntityState } = useLayoutContex();

  useLayoutEffect(() => {
    setHeightContent(layoutSize?.layoutHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutSize]);

  useLayoutEffect(() => {
    if (!loading && setHeaderLabelEntityState && companyEntity?.name && headerLabelEntity !== companyEntity?.name)
      setHeaderLabelEntityState(companyEntity?.name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyEntity]);

  return (
    <div className="h-full pr-20 pt-20 mr-10 pb-20 overflow-auto" style={{ height: `${heightContent}px` }}>
      <CompanyDetailsHeader />
      <CompanyContacts />
    </div>
  );
}



