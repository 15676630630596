import { IAuditHistorySetting, IHistorySlice, ILastFetchQueryParams } from "@models/audit-history";
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { auditHistoryService } from "@services/audit-history.service";
import { RootState } from "@store/store";

export interface IAuditHistoryState {
  loading: boolean,
  readyToShow: boolean,
  showHistory: boolean,
  history: IHistorySlice[],
  settings: IAuditHistorySetting
}

const initialState: IAuditHistoryState = {
  loading: false,
  readyToShow: false,
  showHistory: false,
  history: [],
  settings: {} as IAuditHistorySetting
};

export const asyncShowAuditHistory = createAsyncThunk(
  'auditHistory/asyncShowAuditHistory',
  async (request: IAuditHistorySetting, thunkApi) => {
    return request;
  }
)

export const getHistory = createAsyncThunk(
  'auditHistory/getHistory',
  async (_, thunkApi) => {
    return auditHistoryService.getEntityHistory();
  }
)

export const getProjectDeliverableHistory = createAsyncThunk(
  'auditHistory/getProjectDeliverableHistory',
  async (settings: IAuditHistorySetting, thunkApi) => {
    thunkApi.dispatch(setSettings(settings));
    return auditHistoryService.getProjectDeliverableHistory(settings);
  }
)

export const getPunchListItemHistory = createAsyncThunk(
  'auditHistory/getPunchListItemHistory',
  async (settings: IAuditHistorySetting, thunkApi) => {
    thunkApi.dispatch(setSettings(settings));
    return auditHistoryService.getPunchListItemHistory(settings);
  }
)

export const sortHistory = createAsyncThunk(
  'auditHistory/sortHistory',
  async (settings: ILastFetchQueryParams, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { settings: oldSettings } = state.AuditHistory;
    const newSettings = { ...oldSettings, lastFetchQueryParams: settings }
    thunkApi.dispatch(setSettings(newSettings));
    const serviceToGetHistory = auditHistoryService[newSettings.repositoryMethod as keyof typeof auditHistoryService];
    return serviceToGetHistory(newSettings);
  }
)

export const AuditHistorySlice = createSlice({
    name: 'auditHistory',
    initialState,
    reducers: {
        setSettings: (state, action) => {
          state.settings = action.payload
        },
        showAuditHistory: (state, action) => {
            state.showHistory = action.payload?.showHistory
        }
    },
    extraReducers(builder) {
      builder
        .addCase(asyncShowAuditHistory.pending, (state, action) => {
            if (action?.meta?.arg) {
              state.settings = {
                ...action?.meta?.arg
              }
            }
            state.readyToShow = true
        })
        .addCase(asyncShowAuditHistory.fulfilled, (state, action) => {
          state.readyToShow = false
        })
        .addMatcher(isFulfilled(getProjectDeliverableHistory, getPunchListItemHistory, getHistory, sortHistory), (state, action) => {
          const { data } = action.payload;
          state.history = data;
        })
        .addMatcher(isFulfilled(getProjectDeliverableHistory, getPunchListItemHistory, getHistory, sortHistory), (state, action) => {
          state.loading = false;
        })
        .addMatcher(isPending(getProjectDeliverableHistory, getPunchListItemHistory, getHistory, sortHistory), (state, action) => {
          state.loading = true;
        })
        .addMatcher(isRejected(getProjectDeliverableHistory, getPunchListItemHistory, getHistory, sortHistory), (state, action) => {
          state.loading = false;
        })
    }
});

// Action Reducers
export const { showAuditHistory, setSettings } = AuditHistorySlice.actions;
  
// Reducer
export default AuditHistorySlice.reducer;