import { Card } from "antd";
import { NEUTRAL_7_COLOR } from "@providers/ThemeProvider";
import "@styles/components/admin-item-card.less";
import { CustomButtonText } from "@components/Utils/CustomButtonText";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const CostGroupsCard = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigateToCostGrouping = () => {
        navigate('/admin/application-configuration/cost-management/cost-groups');
    }

    return (
    <>
        <Card title={t("admin.costGroups")} extra={<CustomButtonText type="default" onClick={navigateToCostGrouping}>{t("generic.view")}</CustomButtonText>} className="admin-card">
            <ul>
                <li style={{ color: NEUTRAL_7_COLOR }} className="pb-4">{t("admin.costGrouping")}</li>
                <li style={{ color: NEUTRAL_7_COLOR }} className="pb-4">{t("admin.costCategories")}</li>
                <li style={{ color: NEUTRAL_7_COLOR }} className="pb-4">{t("admin.costSubcategories")}</li>
            </ul>
        </Card>
    </>
    )
}