import { theme, Row, Col } from 'antd';
import { RfiListHeader } from './RfiListHeader';
import { RfiTable } from './RfiTable';
import { RfiListBar } from './RfiListBar';
import { useState } from 'react';
import { TabContainer } from '@components/Utils/TabContainer';

export const RfiList = () => {
    const { token: { padding, colorBgContainer } } = theme.useToken();

    const [showOpenFilter, setShowOpenFilter] = useState(false);

    const handleOpenRfiFilterChange = (checked: boolean) => {
        setShowOpenFilter(checked);
    }

    return (
        <TabContainer>
            <div className='overflow-auto'>
                <Row style={{ paddingLeft: `${padding}px`, paddingRight: `${padding}px`, backgroundColor: colorBgContainer}} className="pt-15 pb-15 w-full">
                    <Col span={24}>
                        <RfiListHeader />
                    </Col>
                </Row>
                <Row style={{ paddingLeft: `${padding}px`, paddingRight: `${padding}px` }} className="pt-20 w-full">
                    <Col span={24}>
                        <RfiListBar openFilterChange={handleOpenRfiFilterChange} />
                    </Col>
                </Row>
                <Row style={{ paddingLeft: `${padding}px`, paddingRight: `${padding}px` }} className="pt-20 w-full">
                    <Col span={24}>
                        <RfiTable showOnlyOpen={showOpenFilter} />
                    </Col>
                </Row>
            </div>
        </TabContainer>
    )

}