import { Button, theme } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "@store/store";
import { setNewItemMode } from "@store/slices/punch-list";

export const ManageBarContainer = () => {

    const { token: { colorBgBase } } = theme.useToken();

    const dispatch = useAppDispatch();

    const { pageSelected } = useAppSelector(store => store.PunchList);

    const addNewPunchListItem = () => {
      dispatch(setNewItemMode(true));
    }

    return (
      <div style={{ background: colorBgBase }} className="flex flex-row items-center justify-end pl-30 pr-15 w-full">
        {/* <div className="mt-10 mb-10">
            <CollapsibleButton />
        </div> */}
        <Button 
          onClick={addNewPunchListItem}
          type="primary" 
          className="mt-10 mb-10" 
          disabled={!Boolean(pageSelected?.id)}
        >
            <PlusOutlined />
            New Item
        </Button>
      </div>
    )
}
