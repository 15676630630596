import React, { useEffect, useState } from 'react';
import { Avatar, Badge, Button, ConfigProvider, Divider, Table, Tag, theme } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { PlusOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { useParams } from 'react-router-dom';
import ContactCreate from './CreateContact';
import { useAppDispatch, useAppSelector } from '@store/store';
import { ICompanyContact } from '@models/company.contact.model';
import { getEntitiesByCompany, getEntity } from '@store/slices/company-contacts-extended';
import { getEntityByCompany } from '@store/slices/company-contact';
import { EmptyCellTable } from '@components/Utils/EmptyCellTable';
import ViewOrUpdateContact from './ViewOrUpdateContact';
import { useTranslation } from 'react-i18next';
import { ChevronRightSvgIcon } from '@components/Icons/ChevronRightSvgIcon';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

const { Text } = Typography;

dayjs.extend(relativeTime);

const PrimaryContactLabel = (props: IPrimaryContactLabel) => {
  const { t } = useTranslation();

  const { record } = props;
  const {
    token: { colorPrimary, colorPrimaryBg, colorPrimaryBorder },
  } = theme.useToken();
  if (record.primary)
    return (
      <Tag
        className="cursor-default"
        style={{ color: colorPrimary, backgroundColor: colorPrimaryBg, border: `1px solid ${colorPrimaryBorder}` }}
      >
        {t(`generic.primaryContact`)}
      </Tag>
    );
  return <EmptyCellTable />;
};
interface IFirstNameLabelProps {
  text: string;
  record: ICompanyContact;
}
interface IPrimaryContactLabel {
  record: ICompanyContact;
}

const FirstNameLabel = ({ text, record }: IFirstNameLabelProps) => {
  return (
    <>
      <Avatar size={'small'} className="ml-5 mr-5">
        {record?.firstName?.charAt(0).toUpperCase()}
        {record?.lastName?.charAt(0).toUpperCase()}
      </Avatar>
      <span>{text}</span>
    </>
  );
};

const ViewMoreCell = () => {
  const {
    token: { colorPrimaryText },
  } = theme.useToken();

  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center select-none">
      <span className="ml-10" style={{ color: colorPrimaryText }}>
        {t(`generic.view`)}
      </span>
      <ChevronRightSvgIcon className="ml-10" style={{ color: colorPrimaryText }} />
    </div>
  );
};

const initialColumnsState: { [key: string]: boolean } = {
  button: true,
  firstName: true,
  lastName: true,
  email: true,
  phoneNumber: true,
  info: true,
  label: true,
};

export const ContactsTable = () => {
  const { entities } = useAppSelector(state => state.CompanyContactsExtended);

  const { t } = useTranslation();

  const {
    token: { colorTextTertiary },
  } = theme.useToken();

  const [columnsState] = useState(initialColumnsState);

  const columns: ColumnsType<ICompanyContact> = [
    {
      title: '',
      dataIndex: 'button',
      key: 'button',
      width: '10%',
      render: (text, record: ICompanyContact) => (
        <div
          onClick={() => {
            dispatch(getEntity(String(record.id)));
            dispatch(getEntityByCompany(String(record.company?.id)));
            setOpenEditContact(true);
          }}
          className="cursor-pointer"
        >
          <ViewMoreCell />
        </div>
      ),
    },
    {
      title: t('company.contact.firstName'),
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text, record) => <FirstNameLabel text={text} record={record} /> || <EmptyCellTable />,
    },
    {
      title: t('company.contact.lastName'),
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: t('generic.address.email'),
      dataIndex: 'email',
      render: (text, record: ICompanyContact) => record.address?.email || <EmptyCellTable />,
      key: 'email',
    },
    {
      title: t('generic.address.phoneNumber'),
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
      render: (text, record: ICompanyContact) => record.address?.phone || <EmptyCellTable />,
    },
    {
      title: t('company.contact.info'),
      dataIndex: 'info',
      key: 'info',
      render: (_, record: ICompanyContact) => {
        const hasUser = record.user?.id ? true : false;
        const isActiveUser = record.user?.activated ? record.user.activated : false;
        const lastLogin = record.user?.lastLoginDate ? record.user.lastLoginDate : false;
        return hasUser ? (
          isActiveUser ? (
            <span>
              {lastLogin
                ? `${t('company.contact.linkContact.lastLogin')}: ${dayjs(lastLogin).fromNow()}`
                : t('company.contact.linkContact.notLoggedInYet')}
            </span>
          ) : (
            <span style={{ color: colorTextTertiary }}>{t('company.contact.linkContact.inactive')}</span>
          )
        ) : (
          <span style={{ color: colorTextTertiary }}>{t('company.contact.linkContact.noUserAccount')}</span>
        );
      },
    },
    {
      title: '',
      dataIndex: 'label',
      key: 'label',
      render: (_, record) => <PrimaryContactLabel record={record} />,
    },
  ];

  const dispatch = useAppDispatch();

  const [openCreateContact, setOpenCreateContact] = useState(false);
  const [openEditContact, setOpenEditContact] = useState(false);

  const {
    token: { colorPrimary, colorBorderSecondary, colorBgElevated },
  } = theme.useToken();

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(
        getEntitiesByCompany({
          page: 0,
          size: 1000,
          sort: `firstName,asc`,
          query: `companyId.equals=${id}`,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [visibleColumns, setVisibleColumns] = React.useState(
    columns.filter(column => column && column.key && columnsState[String(column.key)])
  );
  const [tableData, setTableData] = React.useState(entities);

  useEffect(() => {
    prepareDataToRender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entities]);

  const prepareDataToRender = () => {
    setTableData(entities);
    const filteredVisibleData = columns.filter(column => column && column.key && columnsState[String(column.key)]);
    setVisibleColumns(filteredVisibleData);
  };

  return (
    <div
      style={{
        backgroundColor: colorBgElevated,
        border: `1px solid ${colorBorderSecondary}`,
        borderRadius: '5px',
      }}
    >
      <div
        style={{
          height: '48px',
          margin: '10px 0 10px 0',
          padding: '0.5rem 0.5rem 0.5rem 1rem',
        }}
        className="flex justify-between"
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text>{t(`company.companyContacts`)}</Text>
          <Badge
            count={tableData.length}
            size="small"
            className="ml-30 mr-30"
            style={{ backgroundColor: colorPrimary, outline: 'none', color: 'primary', width: 30 }}
          />
          <Divider type="vertical" className="ml-20" style={{ height: '24px' }} plain />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={() => {
              setOpenCreateContact(true);
              dispatch(getEntityByCompany(String(id)));
            }}
            style={{ backgroundColor: colorPrimary, color: 'white' }}
            icon={<PlusOutlined />}
          >
            {t(`company.contact.newContact`)}
          </Button>
          <ContactCreate open={openCreateContact} setOpen={setOpenCreateContact} />
          <ViewOrUpdateContact open={openEditContact} setOpen={setOpenEditContact} />
        </div>
      </div>
      <div className="pl-15 pr-15 pb-15">
        <ConfigProvider theme={{ token: { borderRadius: 5 } }}>
          <Table className="bordered-custom-table" columns={visibleColumns} size="small" dataSource={tableData} pagination={false} />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default ContactsTable;
