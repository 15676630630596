import { COLOR_TEXT_BASE, WHITE_ABSOLUTE } from "@providers/ThemeProvider";
import { Row, Typography } from "antd";
import { COLOR_ICON_HEADER_BAR_FULL_SCREEN, COLOR_TEXT_HEADER_BAR_FULL_SCREEN } from "../../FilePreviewModal";
import { CloseOutlined, FileOutlined } from "@ant-design/icons";
import { IAttachment } from "@models/attachment.model";
import { EmptyLabel } from "@components/Utils/EmptyLabel";

interface IToolBarMobileProps {
    attachment: IAttachment;
    onCloseModalPreview: () => void;
}

export const ToolBarMobile = (props: IToolBarMobileProps) => {

    const { attachment, onCloseModalPreview,  } = props;
    
    return (
        <Row className="flex flex-row items-center justify-between" style={{ backgroundColor: COLOR_TEXT_BASE, maxWidth: "100vw"}}>
            <div className="flex flex-row items-center">
                <div className="pt-15 pb-15 pl-16 pr-10" style={{ color: COLOR_ICON_HEADER_BAR_FULL_SCREEN }}>
                    <FileOutlined style={{ height: '34px', fontSize: "32px" }} /> 
                </div>
                <div>
                    <Typography.Title 
                        level={5} 
                        style={{ margin: 0, color: COLOR_TEXT_HEADER_BAR_FULL_SCREEN, width: `calc(100vw - 110px)`}}
                        ellipsis={{ rows: 1, expandable: false, suffix: '' }}
                        title={attachment?.fileName || ""}
                    >
                        { attachment?.fileName || <EmptyLabel/> }
                    </Typography.Title>
                </div>
            </div>
            <div className="flex flex-row items-center" style={{ fontSize: 20 }} > 
                <CloseOutlined title={"Close"} onClick={onCloseModalPreview} className="cursor-pointer select-none pr-16" style={{ color: WHITE_ABSOLUTE }} />
            </div>
        </Row>  
    )
}
