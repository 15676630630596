import { createAsyncThunk, createSlice, isFulfilled, isPending } from "@reduxjs/toolkit";
import { IUtil } from "@models/utils";
import { oxBlueProjectsService } from "@services/ox-blue-projects.service";
import { IOxBlueSession, IOxBlueProject, defaultValue, IOxBlueCamera, IOxBlueCameraDates, IOxBlueCameraImageFeed } from "@models/ox-blue.model";
import { serializeGenericHandleError } from "@store/thunk.util";

interface IStateEntity extends IUtil {
  data: {
    oxBlueProjects: IOxBlueProject[];
    oxBlueSession: IOxBlueSession;
    oxBlueProjectEntity: IOxBlueProject,
    oxBlueCameraEntity: IOxBlueCamera;
    oxBlueCameraDates: IOxBlueCameraDates;
    oxBlueCameraImageFeed: IOxBlueCameraImageFeed
  };
  loading: boolean;
  loadingOneByOne: boolean;
  errorMessage: any;
}

const initialState: IStateEntity = {
  data: {
    oxBlueProjects: [],
    oxBlueSession: { sessionId: "", validSession: false },
    oxBlueProjectEntity: defaultValue,
    oxBlueCameraEntity: {},
    oxBlueCameraDates: { dates: [] },
    oxBlueCameraImageFeed: {}
  },
  loading: false,
  loadingOneByOne: false,
  errorMessage: null
}

const sliceName: string = "oxBlueProjects";

export const getOxBlueSession = createAsyncThunk(
  `${sliceName}/getOxBlueSession`,
  async (_, thunkAPI) => {
    const data = await oxBlueProjectsService.getOxBlueSession();
    return data;
  },
  { serializeError: serializeGenericHandleError }
)

export const getOxBlueProjects = createAsyncThunk(
  `${sliceName}/getOxBlueProjects`,
  async (_, thunkAPI) => {
    const data = await oxBlueProjectsService.getOxBlueProjects();
    return data;
  },
  { serializeError: serializeGenericHandleError }
)

export const getOxBlueProjectById = createAsyncThunk(
  `${sliceName}/getOxBlueProjectById`,
  async (payload: any, thunkAPI) => {
    const { id } = payload;
    const data = await oxBlueProjectsService.getOxBlueProjectById({ id: id });
    return data;
  },
  { serializeError: serializeGenericHandleError }
)

export const getOxBlueCameraDetailsById = createAsyncThunk(
  `${sliceName}/getOxBlueCameraDetailsById`,
  async (payload: any, thunkAPI) => {
    const { id } = payload;
    const data = await oxBlueProjectsService.getOxBlueCameraDetailsById({ id: id });
    return data;
  },
  { serializeError: serializeGenericHandleError }
)

export const getOxBlueCameraDateTimes = createAsyncThunk(
  `${sliceName}/getOxBlueCameraDateTimes`,
  async (payload: any, thunkAPI) => {
    const { id } = payload;
    const data = await oxBlueProjectsService.getOxBlueCameraByTime({ id: id });
    return data;
  },
  { serializeError: serializeGenericHandleError }
)

export const getOxBlueCameraImagesByTimes = createAsyncThunk(
  `${sliceName}/getOxBlueCameraImagesByTimes`,
  async (payload: any, thunkAPI) => {
    const { id, date, time } = payload;
    const data = await oxBlueProjectsService.getOxBlueCameraImagesRenderByImageDateTime({ id, date, time });
    return data;
  },
  { serializeError: serializeGenericHandleError }
)

export const getOxBlueCameraImageOneAtATime = createAsyncThunk(
  `${sliceName}/getOxBlueCameraImageOneAtATime`,
  async (payload: any, thunkAPI) => {
    const { id, date, time } = payload;
    const data = await oxBlueProjectsService.getOxBlueCameraImagesRenderByImageDateTime({ id, date, time });
    return data;
  },
  { serializeError: serializeGenericHandleError }
)


export const oxBlueProjectsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getOxBlueSession), (state, action) => {
        state.data.oxBlueSession = action.payload;
        state.errorMessage = null;
        state.loading = false;
      })
      .addMatcher(isFulfilled(getOxBlueProjects), (state, action) => {
        if (Array.isArray(action.payload)) {
          state.data.oxBlueProjects = action.payload;
        }
        state.errorMessage = null;
        state.loading = false;
      })
      .addMatcher(isPending(getOxBlueProjects), (state, action) => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isFulfilled(getOxBlueProjectById), (state, action) => {
        state.data.oxBlueProjectEntity = action.payload;
        state.errorMessage = null;
        state.loading = false;
      })
      .addMatcher(isFulfilled(getOxBlueCameraDetailsById), (state, action) => {
        state.data.oxBlueCameraEntity = action.payload;
        state.errorMessage = null;
        state.loading = false;
      })
      .addMatcher(isFulfilled(getOxBlueCameraDateTimes), (state, action) => {
        state.data.oxBlueCameraDates = action.payload;
        state.errorMessage = null;
        state.loading = false;
      })
      .addMatcher(isFulfilled(getOxBlueCameraImagesByTimes), (state, action) => {
        state.data.oxBlueCameraImageFeed = action.payload;
        state.errorMessage = null;
        state.loading = false;
      })
      .addMatcher(isPending(getOxBlueCameraImagesByTimes), (state, action) => {
        state.data.oxBlueCameraImageFeed = {}
        state.errorMessage = null;
        state.loading = true;
      })
  }
});


export const { reset } = oxBlueProjectsSlice.actions;
export default oxBlueProjectsSlice.reducer;
