/* eslint-disable react-hooks/exhaustive-deps */
import { reset as resetAttachments } from '@store/slices/attachment';
import { reset as resetComments } from '@store/slices/comment-v2';
import { getEntities as getDistributions } from "@store/slices/rfi-distribution";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Col, Row } from "antd";
import { useEffect } from "react";
import { CloseRfi } from "../shared/CloseRfi";
import { Rfi } from "../shared/Rfi";
import { ICreateRfiStepProps } from "./RfiCreate";

export const RfiCreateStepThree = (props: ICreateRfiStepProps) => {

    const { entity } = useAppSelector((state) => state.Rfi);
    const { entities: distributionsList } = useAppSelector((state) => state.RfiDistribution);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(resetAttachments());
        dispatch(resetComments());
    }, []);

    useEffect(() => {
        if (entity?.id) {
            dispatch(
                getDistributions(entity?.id)
            );
        }
    }, [entity?.id])

    return (
      <>
        <Row className="mt-10">
          <Col span={24}>
            <Rfi
              id={entity?.id}
              number={entity?.number}
              categoryName={entity?.category?.name}
              questionPayload={entity?.questionPayload}
              scheduleImpact={entity?.scheduleImpact?.toString()}
              costImpact={entity?.costImpact?.toString()}
              dueDate={entity?.dueDate ? new Date(entity!.dueDate!) : undefined}
              creatorUserId={entity?.createdById}
              assignedTo={entity?.assignedTo}
              following={distributionsList.map(distribution => distribution.user!)}
              titleExtra={<CloseRfi status={entity?.status?.toString()} id={entity?.id} />}
              addFollowersEnabled={true}
            />
          </Col>
        </Row>
      </>
    );
}