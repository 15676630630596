
import { http } from '@infrastructure/http/axios.instance';
import { IPunchListItemStatus } from '@models/punch-list.model';

const path = 'punchlist-statuses';

export const punchListStatusRepository = {
  getPunchlistStatuses: async () => {
    const requestUrl = `${path}?_=${new Date().getTime()}`;
    const promiseGetAll = await http.get<IPunchListItemStatus[]>(requestUrl);
    return promiseGetAll;
  },
  create: async (entity: IPunchListItemStatus): Promise<IPunchListItemStatus> => {

    const requestUrl = `${path}`;
    const promiseCreate = await http.post<IPunchListItemStatus>(requestUrl, entity);
    return promiseCreate.data;
  },
  update: async (id: string | number, entity: IPunchListItemStatus): Promise<IPunchListItemStatus> => {

    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.put<IPunchListItemStatus>(requestUrl, entity);
    return promiseUpdate.data;
  },
  reorder: async (ids: number[]) => {
    const requestUrl = `${path}/reorder`;
    const promiseUpdate = await http.put<IPunchListItemStatus>(requestUrl, ids);
    return promiseUpdate;
  },
}