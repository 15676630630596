import { EmptyLabel } from "@components/Utils/EmptyLabel";
import { DATE_FORMAT_FULL_NAME_MONTH, formatDatePipe } from "@shared/util/date-utils";
import { getUserDisplayName } from "@shared/util/user-utils";
import { getChangeOrdersDefaultUsers } from "@store/slices/project-change-orders-default-users";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Card, Row, Col, theme } from "antd";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const ChangeOrdersListHeader = () => {
    const { token: { colorTextTertiary, colorBorder } } = theme.useToken();

    const { projectId } = useParams<"projectId">();
    const { entity: project } = useAppSelector((state) => state.Project);

    const { data: projectOverview } = useAppSelector((state) => state.ProjectOverview);
    const roleBindings = useAppSelector((state) => state.ProjectChangeOrderDefaultUsers.roleBindings);
    const dispatch = useAppDispatch();


    useEffect(() => {
        if (projectId) {
            dispatch(
                getChangeOrdersDefaultUsers(projectId)
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId])



    return (
        <Row gutter={8} className="h-full">
            <Col span={8} className="h-full">
                <Card
                    bodyStyle={{ paddingLeft: 12, paddingRight: 12, paddingTop: 8, paddingBottom: 8 }}
                    className='flex flex-col h-full'
                    style={{ borderColor: colorBorder, borderRadius: '4px' }}
                >
                    <Row className="font-medium mb-10" style={{ color: colorTextTertiary }}>
                        <Col>
                            Details
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} lg={12}>
                            <span className="font-bold">Status:</span>
                        </Col>
                        <Col xs={24} lg={12}>
                            <div className='flex flex-row'>
                                <span>{(project?.projectStatus?.name) || <EmptyLabel />}</span>
                            </div>
                        </Col>
                        <Col xs={24} lg={12}>
                            <span className="font-bold">Open date:</span>
                        </Col>
                        <Col xs={24} lg={12}>
                            <div className='flex flex-row'>
                                <span>{(formatDatePipe(projectOverview?.headerData?.targetOpening, DATE_FORMAT_FULL_NAME_MONTH)) || <EmptyLabel />}</span>
                            </div>
                        </Col>
                        <Col xs={24} lg={12}>
                            <span className="font-bold">Project type:</span>
                        </Col>
                        <Col xs={24} lg={12}>
                            <div className='flex flex-row'>
                                <span>{(project?.projectType?.name) || <EmptyLabel />}</span>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col span={16} className="h-full">
                <Card
                    bodyStyle={{ paddingLeft: 12, paddingRight: 12, paddingTop: 8, paddingBottom: 8 }}
                    className='flex flex-col h-full'
                    style={{ borderColor: colorBorder, borderRadius: '4px' }}>
                    <Row className="font-medium mb-10" style={{ color: colorTextTertiary }}>
                        <Col>
                            Core Project Team
                        </Col>
                    </Row>
                    <Row>
                        {roleBindings?.map((roleBinding) => (
                            <Col xs={24} lg={12} key={roleBinding.id}>
                                <Row className="w-full">
                                    <Col xs={24} xl={12}>
                                        <span className="font-bold">{roleBinding.role?.name}:</span>
                                    </Col>
                                    <Col xs={24} xl={12}>
                                        <div className='flex flex-row'>
                                            <span>{roleBinding?.user ? getUserDisplayName(roleBinding?.user) : <EmptyLabel />}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        ))}
                    </Row>
                </Card>
            </Col>
        </Row>
    );
}