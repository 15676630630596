import { IUser, defaultValue } from '../../models/user.model';
import { userService } from '../../services/user.service';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { EntityState, createEntitySlice, errorGenericMessage, serializeAxiosError } from "../reducer.util";
import { IQueryParams } from '@models/pagination';
import { cleanEntity } from '@shared/util/entity-utils';
import { asyncLaunchNotification } from './notification';
import { IQueryParamsGeneric } from '@models/utils';

export const getEntities = createAsyncThunk(
  'users/fetch_user_list',
  async (params: IQueryParamsGeneric, thunkAPI) => {
    return userService.getAll(params);
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  'users/fetch_user_by_id',
  async (userLogin: string, thunkAPI) => {
    const user = await userService.getByUserLogin(userLogin);
    return user;
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'users/create_entity',
  async (entity: IUser, thunkAPI) => {
    const result = await userService.create(cleanEntity(entity))
      .catch((error) => {
        const message = getMessageError({ error, entityAction: `create the user`, entity})
        thunkAPI.dispatch(asyncLaunchNotification({
          type: "error",
          config: {
            message: `Changes could not be saved`,
            description: message
          }
        }))
        return thunkAPI.rejectWithValue(error);
      });

    return result;
  }
);

export const updateEntity = createAsyncThunk(
  'users/update_entity',
  async (entity: IUser, thunkAPI) => {
    const user = await userService.update(entity.id, cleanEntity(entity))
      .catch((error) => {
        const message = getMessageError({ error, entityAction: `update the user`, entity}) 
        thunkAPI.dispatch(asyncLaunchNotification({
          type: "error",
          config: {
            message: `Changes could not be saved`,
            description: message
          }
        }))
        return thunkAPI.rejectWithValue(error);
      });

    return user;
  }
);

export const resetPassword = createAsyncThunk(
  'users/send_password_reset',
  async (email: string, thunkAPI) => {
    const result = await userService.resetPassword(email)
      .then(() => {
        thunkAPI.dispatch(asyncLaunchNotification({
          type: "success",
          config: {
            message: `User notified`,
            description: `Email sent successfully`
          }
        }))
      })
      .catch(() => {
        thunkAPI.dispatch(asyncLaunchNotification({
          type: "error",
          config: {
            message: `Error`,
            description: `We apologize, something happened in our servers trying to send the link to recovery the password.`
          }
        }))
      });

    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getMessageError = ({error, entityAction, entity }: {error: any, entityAction: string, entity: IUser}) => {
  if (!error) return errorGenericMessage({ entityAction }) ;
  const message = error.response?.data?.errorKey === "userexists" ? `The login "${entity?.login}" already exists, please set a different one.`: errorGenericMessage({ entityAction }) ;
  return message;
}

const initialState: EntityState<IUser> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  lastPagination: {} as IQueryParams,
  updateSuccess: false,
};

export const slice = createEntitySlice({
  name: 'users',
  initialState,
  reducers: {
    reset: (state) => {
      return initialState
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: data.length,
        };
      })
      .addMatcher(isFulfilled(getEntity), (state, action) => {
        state.loading = false;
        state.entity = action.payload.data as IUser;
      })
      .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload as IUser;
      })
      .addMatcher(isPending(getEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  }
});

export const { reset } = slice.actions;

// Reducer
export default slice.reducer;

