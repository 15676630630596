import { http } from '../http/axios.instance';
import { IAttachment, IOnUploadProgress } from './../../models/attachment.model';
import { IAttachmentCriteria } from './../../store/slices/attachment';
import { StringORNumber } from './utils.repository';

const path = 'attachments';

export const attachmentRepository = {

  getAll: async (queryParams: IAttachmentCriteria) => {

    const params = new URLSearchParams(queryParams as any)
    const requestUrl = `${path}?${params.toString()}`;

    const promiseGetAll = await http.get<IAttachment[]>(requestUrl);
    return promiseGetAll;
  },
  getById: async (id: StringORNumber) => {

    const requestUrl = `${path}/${id}`;
    const promiseGetByID = await http.get<IAttachment>(requestUrl);
    return promiseGetByID;
  },
  create: async (entity: IAttachment, onUploadProgress?: IOnUploadProgress): Promise<IAttachment> => {

    const requestUrl = `${path}`;
    const promiseCreate = await http.post<IAttachment>(requestUrl, entity, { ...onUploadProgress && { onUploadProgress }});
    return promiseCreate.data;
  },
  update: async (id: string | number, entity: IAttachment, onUploadProgress?: IOnUploadProgress ): Promise<IAttachment> => {

    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.put<IAttachment>(requestUrl, entity, { ...onUploadProgress && { onUploadProgress }});
    return promiseUpdate.data;
  },
  delete: async (id: string | number) => {

    const requestUrl = `${path}/${id}`;
    const promiseDelete = await http.delete<IAttachment>(requestUrl);
    return promiseDelete;
  },
  count: async ({ referenceType, referenceId }: { referenceType: string, referenceId: StringORNumber }) => {

    const requestUrl = `${path}/count?referenceType.equals=${referenceType}&referenceId.equals=${referenceId}`;
    const promiseGetCount = await http.get<number>(requestUrl);
    return promiseGetCount.data;
  }
}