import { formatFullDatetimeWhitoutDayName, formatFullNameMonth, fromNow } from "@shared/util/date-utils";
import { Tooltip, theme } from "antd";
import { useLayoutEffect, useState } from "react";
import dayjs from "dayjs";

export interface IEntryTimeProps {
    date: string | undefined,
    containerStyle?: React.CSSProperties
}

export const EntryTime = (props: IEntryTimeProps) => {
    const { date } = props;
    const { token: { colorTextTertiary, fontSizeSM } } = theme.useToken();
    const [formatedDate, setFormatedDate] = useState<string>('');

    useLayoutEffect(() => {
        if (date) {
            const isLessThan30Days = dayjs().diff(dayjs(date), 'days') < 30;
            if (isLessThan30Days) {
                setFormatedDate(fromNow(date));
            } else {
                setFormatedDate(formatFullNameMonth(date))
            }
        }
    }, [date])

    return (
        <span 
            style={{ color: colorTextTertiary, fontSize: fontSizeSM, ...props?.containerStyle }}>
            <Tooltip title={formatFullDatetimeWhitoutDayName(date)}>
                {formatedDate}
            </Tooltip>
        </span>
    );
}