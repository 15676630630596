import { SVGProps } from 'react';

type IProps = SVGProps<SVGSVGElement>;

interface ITextProps extends IProps {
  isActive: boolean;
}

export const TextShapeSvgIcon = (props: ITextProps) => {
  const { isActive, ...restProps } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <g clipPath="url(#clip0_25779_15099)">
        <path
          d="M0 2C0 0.9 0.9 0 2 0H6V2H2V6H0V2ZM20 18C20 19.11 19.11 20 18 20H14V18H18V14H20V18ZM2 20C0.9 20 0 19.11 0 18V14H2V18H6V20H2ZM18 0C19.11 0 20 0.9 20 2V6H18V2H14V0H18ZM7 5V7H9V15H11V7H13V5H7Z"
          fill={isActive ? '#1890FF' : '#292D2D'}
          fillOpacity={isActive ? '1' : '0.45'}
        />
      </g>
      <defs>
        <clipPath id="clip0_25779_15099">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
