import { RootState } from '@store/store';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { WrapperGoogleMap } from '@components/Google/Map/WrapperGoogleMap';
import { isArrayWithValues } from '../../../shared/util/array-util';
import { IMyProjects } from '@models/dashboard.model';

export const MyProjectsMap = () => {
    
    const { data: { myProjects: { list } } } = useSelector((state: RootState) => state.Dashboard);

    const [markersOptions, setMarkersOptions] = useState<google.maps.marker.AdvancedMarkerElementOptions[]>([])

    useEffect(() => {
        processMarkerOptions(list)
    }, [list])

    const processMarkerOptions = (list: IMyProjects[]) => {
        if (list && isArrayWithValues(list)) {
            const locationsWithCoordinates = list.filter((location) => (!!location?.longitude && !!location?.latitude))
            const markersPropsOptions = locationsWithCoordinates.map(({ longitude, latitude }) => {
                if (!latitude || !longitude) { return {} }
                return {
                    position: { lat: +latitude, lng: +longitude }
                }
            })
            markersPropsOptions && setMarkersOptions(markersPropsOptions);
        }
    }

    const containerStyle = {
        width: '450px',
        height: '337.5px'
    };

    return (
        <div style={containerStyle} >
            <WrapperGoogleMap 
                markers={markersOptions}
            />
        </div>
    )
}
