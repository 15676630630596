
import { ICostGrouping } from '@models/cost-grouping.model';
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { costGroupingService } from '@services/cost-grouping.service';
import { serializeGenericHandleError } from '@store/thunk.util';
import { cleanEntity } from '@shared/util/entity-utils';

export const getActiveEntities = createAsyncThunk(
    'costGrouping/fetch_active_entity_list',
    async () => {
        return costGroupingService.getActiveCostGroupings();
    },
    { serializeError: serializeGenericHandleError }
);

export const getCostGroupingsExtended = createAsyncThunk(
    'costGrouping/fetch_entity_list',
    async () => {
        return costGroupingService.getCostGroupingsExtended();
    },
    { serializeError: serializeGenericHandleError }
);

export const createEntity = createAsyncThunk(
    `costGrouping/create_entity`,
    async (entity: ICostGrouping, thunkAPI) => {
      const result = await costGroupingService.create(cleanEntity(entity));
      thunkAPI.dispatch(getCostGroupingsExtended());
      return result;
    },
    { serializeError: serializeGenericHandleError }
  );
  
  export const updateEntity = createAsyncThunk(
    `costGrouping/update_entity`,
    async (entity: ICostGrouping, thunkAPI) => {
      const result = entity.id && await costGroupingService.update(entity.id, cleanEntity(entity));
      thunkAPI.dispatch(getCostGroupingsExtended());
      return result;
    },
    { serializeError: serializeGenericHandleError }
  );

  export const reorderCostGroupings = createAsyncThunk(
    `costGrouping/reorder`,
    async (ids: number[], thunkAPI) => {
      const result = await costGroupingService.reorder(ids);
      thunkAPI.dispatch(getCostGroupingsExtended());
      return result;
    },
    { serializeError: serializeGenericHandleError }
  );

interface ICostGroupingState {
    loading: boolean;
    errorMessage: string | null;
    activeEntities: ReadonlyArray<ICostGrouping>;
    entities: ReadonlyArray<ICostGrouping>;
}

const initialState: ICostGroupingState = {
    loading: false,
    errorMessage: null,
    activeEntities: [],
    entities: []
};

export const CostGroupingSlice = createSlice({
    name: 'costGrouping',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(isFulfilled(getActiveEntities), (state, action) => {
                const { data } = action.payload;
                return {
                    ...state,
                    loading: false,
                    activeEntities: data
                };
            })
            .addMatcher(isFulfilled(getCostGroupingsExtended), (state, action) => {
                const { data } = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data
                };
            })
            .addMatcher(isPending(getActiveEntities), state => {
                state.errorMessage = null;
                state.loading = true;
            })
            .addMatcher(isPending(getCostGroupingsExtended), state => {
                state.errorMessage = null;
                state.loading = true;
            })
            .addMatcher(isFulfilled(createEntity, updateEntity), (state) => {
                state.loading = false;
                state.errorMessage = null;
            })
            .addMatcher(isPending(createEntity, updateEntity), state => {
                state.errorMessage = null;
                state.loading = true;
            });
    }
});

// Reducer
export default CostGroupingSlice.reducer;

