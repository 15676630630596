import { ICompany, defaultValue } from '../../models/company.model';
import { createEntitySlice, EntityState, serializeAxiosError } from '../reducer.util';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { companyExtendedService } from '../../services/company-extended.service';
import { cleanEntity } from '../../shared/util/entity-utils';
import { IQueryParams } from '@models/pagination';

export const getEntities = createAsyncThunk(
  'company/fetch_entity_list',
  async (queryParams: IQueryParams) => {
    return companyExtendedService.getCompanies(queryParams);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'company-extended/create_entity',
  async (entity: ICompany, thunkAPI) => {
    const result = await companyExtendedService.createCompany(cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  `companies/update_entity`,
  async (entity: ICompany, thunkAPI) => {
    const result = await companyExtendedService.updateEntity(cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

const initialState: EntityState<ICompany> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export const CompanySlice = createEntitySlice({
  name: 'company_extended',
  initialState,
  reducers: {
    clearEntity: state => {
      state.entity = defaultValue;
    },
  },
});

export const { reset, clearEntity } = CompanySlice.actions;

// Reducer
export default CompanySlice.reducer;
