import { EmptyLabel } from "@components/Utils/EmptyLabel";
import { capitalize } from "@shared/util/string-utils";
import { useAppSelector } from "@store/store";

export const UserNameCrumbLabel = () => {

    const { entity: user = {} } = useAppSelector((state) => state.Users);
    const { firstName = "", lastName = "" } = user;

    return (
        <span>
            { 
                `${firstName ? capitalize(firstName) : ""} ${lastName ? capitalize(lastName) : ""}` || <EmptyLabel /> 
            }
        </span>
    )
}
