import { useState } from "react";
import { useDispatch } from "react-redux";
import { blue } from "@ant-design/colors";
import { Button, Col, Drawer, Form, Input, Row, Space, theme } from 'antd';
import { AppDispatch, useAppSelector } from '@store/store';
import { useTranslation } from 'react-i18next';
import { useNotificationsProvider } from '@providers/NotificationsProvider';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { createEntity, getEntities } from '@store/slices/company-extended';
import { ICompany } from '@models/company.model';
import { isFulfilled } from '@reduxjs/toolkit';
import { AutoCompleteAddressCustom } from '@components/Locations/AutocompleteAddress/AutoCompleteAddressCustom';
import { IAddress } from '@models/address.model';
import { ADDRESS_TYPE_PRIMARY } from '@constants/core';
import { validateURL } from '@shared/util/validations';

const CompanyCreate = ({ pageNumber, itemsPerPage, sortingKey, open, setOpen, setCompaniesData }) => {
  const { openNotification } = useNotificationsProvider();

  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { updating } = useAppSelector(state => state.Location);

  const [animateError, setAnimateError] = useState(false);
  const [form] = Form.useForm();

  const onClose = (): void => {
    setOpen(false);
  };

  const [hiddenFields, setHiddenFields] = useState<Partial<IAddress>>({ addressType: ADDRESS_TYPE_PRIMARY });

  const onFinish = (entity: Partial<ICompany>): void => {
    const adjustedURL = entity?.website
      ? /^(http:\/\/|https:\/\/)/.test(entity?.website)
        ? entity?.website
        : `http://${entity?.website}`
      : '';
    const requestData = {
      name: entity.name,
      website: adjustedURL,
      address: {
        ...entity.address,
        streetAddress: addressInputValue,
        ...hiddenFields,
      },
    };

    const onSuccessCreateCompany = (response: any) => {
      if (isFulfilled(response)) {
        dispatch(
          getEntities({
            page: pageNumber - 1,
            size: itemsPerPage,
            sort: sortingKey,
          })
        );
        openNotification({
          type: 'info',
          config: {
            icon: <CheckCircleTwoTone style={{ fontSize: 24 }} twoToneColor={[blue[5], blue[0]]} />,
            message: `New company creation`,
            description: `Now you can add contacts`,
          },
        });
        form.resetFields();
        onClose();
      } else {
        playAnimationOfError();
      }
    };

    dispatch(createEntity({ ...requestData })).then(onSuccessCreateCompany);
  };

  const playAnimationOfError = () => {
    setAnimateError(true);
    setTimeout(() => {
      setAnimateError(false);
    }, 500);
  };

  const {
    token: { colorBorderSecondary, colorPrimary, fontSize },
  } = theme.useToken();

  const onFinishFailed = (errorInfo: any) => {
    playAnimationOfError();
  };

  const [addressInputValue, setAddressInputValue] = useState('');

  const onSelectItemInAutocomplete = (address: any) => {
    const { subAddress = '', city = '', stateProvince = '', postalCode = '', country, latitude, longitude } = address;
    form.setFieldsValue({
      address: {
        streetAddress2: subAddress,
        city: city,
        stateProvince: stateProvince,
        postalCode: postalCode,
        country: country,
      },
    });
    setHiddenFields({
      ...hiddenFields,
      latitude,
      longitude,
    });
  };

  const onChangeAddressValue = (text: string) => {
    setAddressInputValue(text);
    form.setFieldsValue({
      address: {
        streetAddress: text,
      },
    });
  };

  const onSubmit = () => {
    form.submit();
  };

  return (
    <>
      <Drawer
        title={`New ${t('generic.company')}`}
        width={580}
        onClose={onClose}
        open={open}
        styles={{ body: { paddingBottom: 80 } }}
        extra={
          <Space>
            <Button type="default" onClick={onClose}>
              {t('generic.cancel')}
            </Button>
            <Button onClick={onSubmit} type="primary" loading={updating}>
              {t('generic.save')}
            </Button>
          </Space>
        }
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className={`${animateError && 'error-animation'}`}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="name"
                label={`${t('generic.company')} Name`}
                rules={[{ required: true, message: `${t('company.enterCompanyName')}` }]}
              >
                <Input placeholder={`${t('company.enterCompanyName')}`} allowClear />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="website" label="Website" rules={[{ validator: validateURL }]}>
                <div style={{ display: 'flex' }}>
                  <Input style={{ marginLeft: '-3px' }} placeholder={`http://www.example.com`} allowClear />
                </div>
              </Form.Item>
            </Col>
          </Row>

          <div
            className="bg-color-neutral-4 font-medium"
            style={{
              height: '32px',
              border: `1px solid ${colorBorderSecondary}`,
              fontSize: fontSize + 2,
              margin: '10px 0 10px 0',
              color: colorPrimary,
              borderRadius: '5px',
              padding: '2px 0 0 10px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {t('generic.address.address')}
          </div>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name={['address', 'streetAddress']} label={t('generic.address.addressLine1')}>
                <AutoCompleteAddressCustom
                  style={{ width: '100%' }}
                  onChangeAutocomplete={onSelectItemInAutocomplete}
                  onChangeValueInput={onChangeAddressValue}
                  placeholder={t('generic.address.addressLine1Placeholder')}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name={['address', 'streetAddress2']} label={t('generic.address.addressLine2')}>
                <Input placeholder={t('generic.address.addressLine2Placeholder')} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name={['address', 'city']} label={t('generic.address.city')}>
                <Input placeholder={t('generic.address.city')} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={['address', 'stateProvince']} label={t('generic.address.state')}>
                <Input placeholder={t('generic.address.state')} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={['address', 'postalCode']} label={t('generic.address.postalCode')}>
                <Input type="number" placeholder={t('generic.address.postalCode')} allowClear />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name={['address', 'country']} label={t('generic.address.country')}>
                <Input placeholder={t('generic.address.country')} allowClear />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name={['address', 'phone']} label={t('generic.address.phoneNumber')}>
                <Input placeholder={t('generic.address.phoneNumber')} allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={['address', 'email']} label={t('generic.address.email')}>
                <Input placeholder={t('generic.address.email')} allowClear />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default CompanyCreate;
