import { TabContainer } from "@components/Utils/TabContainer";
import { BackHeader } from "../BackHeader";
import { UploadFloorPlan, UploadFloorPlanStepsEnum } from "./UploadFloorPlan";

export const UploadFloorPlanView = () => {

    return (
      <TabContainer>
       <BackHeader />
       <UploadFloorPlan step={UploadFloorPlanStepsEnum.UPLOAD_FILE} />
      </TabContainer>
    )
}
