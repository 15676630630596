import { IChangeOrderItem, defaultValue } from '@models/change-order-item.model';
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { changeOrderItemsService } from '@services/change-order-item.service';
import { EntityState, serializeAxiosError } from "../reducer.util";

export interface IGetChangeOrderItemsArgs {
    changeOrderId: number,
    onSuccess?: () => void
}

export const getEntities = createAsyncThunk(
    'changeOrderItems/fetch_entity_list',
    async ({ changeOrderId, onSuccess }: IGetChangeOrderItemsArgs) => {
        var result = changeOrderItemsService.getChangeOrderItems(changeOrderId);
        onSuccess?.();
        return result;
    },
    { serializeError: serializeAxiosError }
);

export interface ISaveChangeOrderItemsArgs {
    changeOrderId: number,
    items: IChangeOrderItem[],
    onSuccess?: () => void
}

const initialState: EntityState<IChangeOrderItem> = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

export const ChangeOrderItemsSlice = createSlice({
    name: 'changeOrderItems',
    initialState,
    reducers: {
        reset: (state,) => {
            return initialState;
        },
    },
    extraReducers(builder) {
        builder
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const { data } = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data,
                };
            })
            .addMatcher(isPending(getEntities), state => {
                state.errorMessage = null;
                state.loading = true;
            });
    }
});

export const { reset } = ChangeOrderItemsSlice.actions;

// Reducer
export default ChangeOrderItemsSlice.reducer;

