/* eslint-disable react-hooks/exhaustive-deps */
import { FilterOutlined } from "@ant-design/icons";
import { Badge, Button, Popover, theme } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AdvancedFiltersContainer, IInitalValuesForm } from "./AdvancedFiltersContainer";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getRoles } from "@store/slices/role";
import { getDisciplines } from "@store/slices/discipline";
import { getPhases } from "@store/slices/phase";

export const FloatDropdownFilters = () => {

    const dispatch = useAppDispatch();

    const { token : { colorBorder } } =  theme.useToken();

    const { t } = useTranslation();

    const [popupVisible, setPopupVisible] = useState<boolean>(false);

    const { advancedFiltersCount } = useAppSelector((store) => store.ProjectDeliverables);

    useEffect(() => {
        dispatch(getRoles());
        dispatch(getDisciplines());
        dispatch(getPhases());
    }, [])
    
    const { advancedFiters  } = useAppSelector((store) => store.ProjectDeliverables);

    const [initialValues, setInitialValues] = useState<IInitalValuesForm>({}); 

    useEffect(() => {
        if(advancedFiters) {
            setInitialValues({
                ...advancedFiters.status && { status: advancedFiters.status.split(",") },
                ...advancedFiters.disciplines && { disciplines: advancedFiters.disciplines.split(",") },
                ...advancedFiters.phases && { phases: advancedFiters.phases.split(",") },
                ...advancedFiters.roles && { roles: advancedFiters.roles.split(",") },
                ...advancedFiters.showOnlyKeyMilestone && { showOnlyKeyMilestone: Boolean(advancedFiters.showOnlyKeyMilestone) },
                ...advancedFiters.hideNotApplicable && { hideNotApplicable: Boolean(advancedFiters.hideNotApplicable) },
            });
        }
    }, [advancedFiters]);

    return (
        <Popover
            content={<AdvancedFiltersContainer setPopupVisible={setPopupVisible} initialValues={initialValues} />} 
            placement="bottomRight" 
            trigger={["click"]} 
            arrow={false} 
            zIndex={20}
            open={popupVisible}
            onOpenChange={(visible) => {
                setPopupVisible(visible)
            }}
            destroyTooltipOnHide={true}
            overlayInnerStyle={{ border: `1px solid ${colorBorder}`, padding: 0, marginTop: 8 }}
        >   
            <div onClick={() => setPopupVisible(!popupVisible)}>
                <Badge count={advancedFiltersCount} offset={[-1, 3]} >
                    <Button 
                        type="primary" 
                        { ...(typeof advancedFiltersCount !== "undefined" && advancedFiltersCount > 0) && { ghost: true }} 
                    >
                        <FilterOutlined />
                        {t("generic.filter")}
                    </Button>
                </Badge>
            </div>
        </Popover>
    )
}
