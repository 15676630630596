import { SVGProps } from "react";

type IProps = SVGProps<SVGSVGElement>

export const ChevronRightSvgIcon = (props: IProps) => {  

    const { ...restProps } = props;

    return (
        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <path d="M1.25 8.5L4.75 5L1.25 1.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        
    )
}
