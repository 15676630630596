import { ICostSubCategory } from "./cost-sub-category.model";
import { ChangeOrderItemTypeEnum } from "./enumerations/change-order-item-type-enum.model";

export interface IChangeOrderItem {
  id?: number;
  description?: string | null;
  unitsQty?: number;
  unitCost?: string;
  type?: ChangeOrderItemTypeEnum;
  changeOrderId?: number;
  costSubCategory?: ICostSubCategory;
}

export const defaultValue: Readonly<IChangeOrderItem> = {};
