import { ICustomFieldGroupExtended, ICustomFieldGroupExtendedUI } from "@models/custom-field-group.model";

export class CustomFieldExtendedMapper  {

  public static processCustomFieldsExtended (customFields: ICustomFieldGroupExtended[]): ICustomFieldGroupExtendedUI[] {

    const output = customFields.map((item) => {
        const customFieldsChildren = item.customFieldsChildren?.map((customField) => {
          
          let jsonValueProccessed = undefined; 
          
          if (customField?.value) {
            const jsonValue = JSON.parse(customField?.value);
            jsonValueProccessed = jsonValue.value
          }

          return {
            ...customField,
            jsonValueProccessed: jsonValueProccessed
          }
        })

        return {
           ...item,
           customFieldsChildren
        };
    })
    
    return output;
  }

  public static toDomain (response: ICustomFieldGroupExtended[]): ICustomFieldGroupExtendedUI[] {

    const dataTransformedToDomain = this.processCustomFieldsExtended(response)

    return dataTransformedToDomain || [];
  }

}