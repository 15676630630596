import useHover from "@HOOKs/UseHover";
import { CloseOutlined } from "@ant-design/icons";
import { Tag, theme } from "antd";
import { useRef } from "react";

interface ICustomFilterShipProps extends React.ComponentPropsWithoutRef<typeof Tag> {
    children: React.ReactNode
    filterActive?: boolean
}

export const CustomFilterShip = (props: ICustomFilterShipProps) => {

    const { children, filterActive, ...restProps } = props;

    const { token: { colorPrimaryBorder, colorBgElevated, colorPrimaryBg, colorPrimaryHover, colorPrimaryActive, colorTextTertiary, colorPrimaryBgHover, fontSize } } = theme.useToken();
    const fontIconClose = fontSize - 5;
    const fontTextShip = fontSize - 2;

    const shipRef = useRef(null);
    const isHoverInShip = useHover(shipRef);

    return (
        <Tag
            ref={shipRef}
            className='flex flex-row items-center justify-center h-full pl-15 pr-15 cursor-pointer' 
            style={{ 
                fontSize: fontTextShip, 
                borderRadius: 300, 
                borderColor: colorPrimaryBorder, 
                color: filterActive ? (isHoverInShip ? colorPrimaryActive : colorPrimaryHover) : colorTextTertiary,
                background: filterActive ? colorPrimaryBgHover : isHoverInShip ? colorPrimaryBg : colorBgElevated 
            }}
            { ...restProps }
        >
            { children }
            { filterActive && <CloseOutlined className='ml-5' style={{ fontSize: fontIconClose }} /> }
        </Tag>
    )
}
