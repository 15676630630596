/* eslint-disable react-hooks/exhaustive-deps */
import { DEFAULT_MAP_POSITION, useGoogleMapsProvider } from '@providers/GoogleMapsProvider';
import { MapMarkerSvgIcon } from '@components/Icons/MapMarkerSvgIcon';
import { Skeleton, theme } from 'antd';
import { useRef, useEffect, useState } from 'react';
import { hasMoreThanOne, hasOnlyOne } from '../../../shared/util/array-util';
import SiteRiseStyleMap from './SiteRiseStyleMap.json';
import "./WrapperGoogleMaps.less";
import { isBoolean } from '../../../shared/util/validations';
import { generateUniqueStringWithTimestamp } from '@shared/util/date-utils';
import ReactDOMServer from 'react-dom/server';
import { GoogleAdvancedMarker } from '@components/Icons/GoogleAdvancedMarker';

const parser = new DOMParser();

export interface MarkerOptions extends google.maps.marker.AdvancedMarkerElementOptions {
    extraData?: any
}
export interface IMarkerFullData  {
    marker: google.maps.marker.AdvancedMarkerElement;
    extraData?: any
}

export interface ICustomMapOptions {
    fitToBounds?: {
        top: number,
        right: number,
        bottom: number,
        left: number
    }
    center?: boolean
}

interface IWrapperGoogleMap {
    containerStyle?: React.CSSProperties;
    markers?: MarkerOptions[];
    options?: google.maps.MapOptions;
    center?: google.maps.LatLng | google.maps.LatLngLiteral;
    zoom?: number;
    customMapOptions?: ICustomMapOptions;
    handleClickMarker?: (map: google.maps.Map, marker: google.maps.marker.AdvancedMarkerElement, extraData: any) => void;
    handlerMarkerIsVisible?: (map: google.maps.Map, marker: google.maps.marker.AdvancedMarkerElement, extraData: any) => void;
    onMapGenerated?: (map: google.maps.Map) => void;
    onMarkersGenerated?: (markers: IMarkerFullData[]) => void
}

const DEFAULT_ZOOM = 3.2

const DEFAULT_MAP_OPTIONS = {
    controlSize: 24,
    panControl: true,
    zoomControl: true,
    mapTypeControl: false,
}

export const WrapperGoogleMap: React.FC<IWrapperGoogleMap> = (props) => {

    const {
        containerStyle = {},
        markers,
        options,
        center,
        zoom,
        customMapOptions = {},
        handleClickMarker,
        handlerMarkerIsVisible,
        onMapGenerated,
        onMarkersGenerated
    } = props;

    const ref = useRef<HTMLDivElement>(null);
    const { isLoaded, isLoading } = useGoogleMapsProvider();
    const [ mapGenerated, setMapGenerated ] = useState<google.maps.Map | undefined>();
    const [ markersList, setMarkerList ] = useState<google.maps.marker.AdvancedMarkerElement[]>([])

    useEffect(() => {
        if (ref && isLoaded) {
            generateMap();
        } 
    }, [isLoaded])

    useEffect(() => {
        if (mapGenerated) {
            setMarkersInMap(customMapOptions, mapGenerated);
            onMapGenerated && onMapGenerated(mapGenerated);
        }
    }, [markersList, customMapOptions])

    useEffect(() => {
        markers ? processMarkers(markers) : setMarkerList([]) 
    }, [markers, isLoaded])


    const resetCurrentMarkers = () => {
        markersList.forEach((marker) => { 
            marker.map = null;
            google.maps.event.clearInstanceListeners(marker);
        });
    }

    const processMarkers = (markersOptionsList: MarkerOptions[]) => {

        if (!isLoaded) return;
        
        resetCurrentMarkers();
        
        let markers: google.maps.marker.AdvancedMarkerElement[] = [];
        let markersFullDataList: IMarkerFullData[] = [];

        markersOptionsList && markersOptionsList?.forEach((options) => {
            const { position, extraData, ...restOptions} = options;
            if (position) {
                const pinSvg = parser.parseFromString(ReactDOMServer.renderToString(<GoogleAdvancedMarker />), 'image/svg+xml').documentElement;
                const newMarker = new google.maps.marker.AdvancedMarkerElement({ 
                    position: new google.maps.LatLng(position),
                    content: pinSvg,
                    ...restOptions 
                });

                if (options.gmpClickable && handleClickMarker) {

                    const handlerClick = (map: google.maps.Map) => {
                        handleClickMarker(map, newMarker, extraData);
                    }
                    google.maps.event.addListener(newMarker, 'click', handlerClick );
                }

                if (handlerMarkerIsVisible) {
                    const handlerVisibleMarker = () => {
                        mapGenerated &&  handlerMarkerIsVisible(mapGenerated, newMarker, extraData);
                        google?.maps?.event?.clearListeners?.(newMarker, 'visible_changed');
                    }
                    google.maps.event.addListener(newMarker, 'visible_changed', handlerVisibleMarker);
                }
                markers.push(newMarker);
                markersFullDataList.push({ marker: newMarker, extraData: extraData});
            }
        });

        setMarkerList(markers);
        onMarkersGenerated?.(markersFullDataList);
    }

    const generateMap = () => {
        const googleMapsOptions = {
            center: center || new google.maps.LatLng(DEFAULT_MAP_POSITION),
            zoom: zoom || DEFAULT_ZOOM,
            ...DEFAULT_MAP_OPTIONS,
            ...options,
            styles: SiteRiseStyleMap,
            mapId: `MAP_ID_${generateUniqueStringWithTimestamp()}`
        }

        const newMap = new window.google.maps.Map(ref.current as HTMLDivElement, googleMapsOptions);

        setMapGenerated(newMap);
    }

    const setMarkersInMap = (customMapOptions: ICustomMapOptions, mapGenerated?: google.maps.Map) => {
        if (mapGenerated && isLoaded) {

            const bounds = new google.maps.LatLngBounds();
            
            markersList.forEach((marker) => {
                marker.map = mapGenerated;
                marker.gmpClickable = true;
                const positionInMap = marker.position;
                positionInMap && bounds.extend(positionInMap);
            });

            if (markersList && hasMoreThanOne(markersList)) {
                mapGenerated.fitBounds(bounds, customMapOptions?.fitToBounds ? customMapOptions?.fitToBounds : { top: 30, right: 30, bottom: 30, left: 30 });
                if (!isBoolean(customMapOptions?.center) ) {
                    mapGenerated.setCenter(bounds.getCenter());
                }
                if (isBoolean(customMapOptions?.center) && customMapOptions?.center) {
                    mapGenerated.setCenter(bounds.getCenter());
                }
            }

            if (markersList && hasOnlyOne(markersList)) {
                const _position = markersList[0].position;
                if (_position) {
                    mapGenerated.setCenter(new google.maps.LatLng(_position))
                }
            }
        }
    }

    return (
        <>  
            { isLoading ? <LoadingCustomMapSkeleton /> : (<div ref={ref} className='w-full h-full' style={{ borderRadius: 4, ...containerStyle}} />) }
        </>
    )
}

const LoadingCustomMapSkeleton = () => {
    const { token } = theme.useToken()
    return (
        <Skeleton.Node active={true} className="relative w-full flex items-center" prefixCls="custom-skeleton">
            <div className="flex items-center justify-center">
                <MapMarkerSvgIcon width='3rem' height='3rem' iconStyles={{ color: token.colorTextQuaternary }}  />
            </div>
        </Skeleton.Node>
    )
}

