import { ExclamationCircleFilled } from '@ant-design/icons';
import { TrashSvgIcon } from '@components/Icons/TrashSvgIcon';
import { useAuth } from '@providers/AuthProvider';
import { deleteCommentRichText } from '@store/slices/comment-v2';
import { useAppDispatch } from '@store/store';
import { Avatar, Col, Modal, Row, Tooltip, theme } from 'antd';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import { useState } from 'react';
import { ICommentRichText } from '../../models/comment.model';
import { formatFullDatetime, fromNow } from '../../shared/util/date-utils';
import { ViewerRichText } from './ViewerRichText';

export interface ICommentProps {
    value: ICommentRichText;
    isPreview?: boolean;
    onDelete?: () => void;
}

export const Comment = (props: ICommentProps) => {

    const { value, isPreview } = props;

    const dispatch = useAppDispatch();
    const { token: { colorText, colorError, fontSize, colorTextTertiary } } = theme.useToken();
    const { user: userLogged } = useAuth();
    const [hoverComment, setHoverComment] = useState<number>(-1);
    const [modal, contextHolder] = Modal.useModal();

    const deleteCommentRichTextAction = (item: ICommentRichText) => {
        modal.confirm({
            title: 'Do you Want to delete the comment?',
            icon: <ExclamationCircleFilled />,
            onOk() {
                dispatch(deleteCommentRichText({ referenceType: value.referenceType!, referenceId: value.referenceId!, id: String(value.id), onSuccess: props.onDelete }));
            }
        })
    }

    const formatTime = (item: ICommentRichText, isLessThan24Hour: boolean) => {
        if (isLessThan24Hour) {
            return item.createdDate ? fromNow(item.createdDate) : "";
        }
        return formatFullDatetime(item.createdDate);
    }

    const emailTooltip = (email?: string) => {
        return (
            <span className='flex flex-row w-full'>{email || ""}</span>
        )
    }

    const isCreatedTimeLessThan10Minutes = dayjs().diff(dayjs(value.createdDate), 'minutes') < 10;
    const _24hoursInMinutes = 1440;
    const isCreatedTimeLessThan24Hour = dayjs().diff(dayjs(value.createdDate), 'minutes') < _24hoursInMinutes;

    return (
      <>
        {' '}
        <>{contextHolder}</>
        <Col span={24} key={value.id} onMouseOver={() => value.id && setHoverComment(value.id)} onMouseOut={() => setHoverComment(-1)}>
          <Row className="mt-5 pb-5 justify-between" justify={'center'} align={'middle'}>
            <Col>
              <Avatar className="cursor-pointer" gap={4}>
                {`${value.createdByFirstName?.[0]}${value.createdByLastName?.[0]}`.toUpperCase()}
              </Avatar>
              <Tooltip placement="top" title={emailTooltip(value.createdByEmail)} rootClassName="fullMaxWidth">
                <span className="font-bold ml-10" style={{ color: colorText }}>
                  {value.createdByFirstName && capitalize(value.createdByFirstName)}
                </span>
                <span className="font-bold ml-3" style={{ color: colorText }}>
                  {value.createdByLastName && capitalize(value.createdByLastName)}
                </span>
              </Tooltip>
            </Col>
            <Col className="flex flex-row items-center">
              {isPreview === true ? (
                <span style={{ color: colorTextTertiary }} className="font-normal mr-20">
                  {formatFullDatetime(new Date().toString())}
                </span>
              ) : (
                <>
                  {Number(value?.createdBy) === userLogged.id && hoverComment === value.id && isCreatedTimeLessThan10Minutes && (
                    <span
                      style={{ color: colorError, fontSize: fontSize - 2 }}
                      className="cursor-pointer flex flex-row items-center"
                      onClick={() => deleteCommentRichTextAction(value)}
                    >
                      Delete
                      <span className="ml-5 mr-10 flex flex-row items-center">
                        <TrashSvgIcon />
                      </span>
                    </span>
                  )}
                  {isCreatedTimeLessThan24Hour && (
                    <Tooltip placement={'top'} title={formatFullDatetime(value.createdDate)} rootClassName="fullMaxWidth">
                      <span style={{ color: colorTextTertiary }} className="font-normal mr-20">
                        {formatTime(value, isCreatedTimeLessThan24Hour)}
                      </span>
                    </Tooltip>
                  )}
                  {!isCreatedTimeLessThan24Hour && (
                    <span style={{ color: colorTextTertiary }} className="font-normal mr-20">
                      {formatTime(value, isCreatedTimeLessThan24Hour)}
                    </span>
                  )}
                </>
              )}
            </Col>
          </Row>

          {/* Viewer */}
          <Row wrap={false}>
            <Col flex={'none'}>
              <Avatar className="cursor-pointer" gap={4} style={{ opacity: 0 }}></Avatar>
            </Col>
            <Col flex={'auto'} style={{ color: colorText }}>
              {value.payload && <ViewerRichText payload={value.payload} />}
            </Col>
          </Row>
        </Col>
      </>
    );
}

