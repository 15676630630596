export type sortValue = string | number | null | undefined

export const defaultSorter = (a: sortValue, b: sortValue)  => {
    if (!!a && !!b) {
        const valueA = String(a).toLowerCase();
        const valueB = String(b).toLowerCase();
        if (valueA < valueB) {
          return -1;
        }
        if (valueA > valueB) {
          return 1;
        }
    }
    return 0;
}


export const dateSorter = (a: string, b: string) => {
    if (!!a && !!b) {
        const _a = new Date(a);
        const _b = new Date(b);
        if (_a && _b) {
            return _a.getTime() - _b.getTime() 
        }
    }
    return 0;
}

export const dateTypeSorter = (a: Date | undefined, b: Date | undefined) => {
    if (!a) {
        return -1;
    }
    if (!b) {
        return 1;
    }
    if (a < b) {
        return -1;
    } else {
        return 1;
    }
}
