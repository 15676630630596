import { Col, Drawer, Row, theme } from "antd";
import { InfoButon } from "../Toolbar/InfoButon";
import { CloseButtonModal } from "../CloseButtonModal";
import { BodyInfoSide, InfoSide } from "../../InfoSide";
import { borderRadiusFromBottomStyleDrawersInMobile } from "@providers/ThemeProvider";
import { IAttachment } from "@models/attachment.model";

interface IDrawerInfoAttachmentProps {
    open: boolean,
    onClose: () => void,
    attachment: IAttachment
}

export const DrawerInfoAttachment = (props: IDrawerInfoAttachmentProps) => {

    const { open, onClose, attachment } = props;

    const { token: { colorBorderSecondary, colorFillTertiary, colorTextSecondary } } = theme.useToken();
    
    return (
        <Drawer
            open={open} 
            closable={false}
            placement="bottom"
            width={"100%"}
            height={"50%"}
            onClose={onClose}
            styles={{
                wrapper: borderRadiusFromBottomStyleDrawersInMobile,
                content: borderRadiusFromBottomStyleDrawersInMobile,
                header: { padding: 0 },
                body: { padding: '0.5rem', height: '100%' },
            }}
            title={
                <div className="flex flex-row items-center justify-between pt-15 pb-15" style={{ background: colorFillTertiary }}>
                    <div className="flex flex-row items-center pl-20 ml-4">
                        <InfoButon isActive={true} />
                        <span className="ml-6" style={{ color: colorTextSecondary }}>Info</span>
                    </div>
                    <div className="pr-20 mr-4">
                        <CloseButtonModal 
                            onCloseModalPreview={onClose} 
                            containerStyles={{ width: "2rem", height: "2rem" }}
                        />
                    </div>
                </div>
            }    
        >   
            <Row className="h-full">
                <Col span={24} className="h-full">
                <InfoSide 
                    containerClassName="h-full"
                    body={
                        <BodyInfoSide 
                            attachment={attachment}
                            bodyContainerClassName="pl-15 pt-15 pr-15 h-full" 
                            bodyContainerStyle={{
                                border: `1px solid ${colorBorderSecondary}`,
                                borderRadius: '0.25rem', 
                                height: '100%'
                            }}
                        />
                    } 
                />
                </Col>
            </Row>
        </Drawer>
    )
}
