import { CommentSvgIcon } from "@components/Icons/CommentSvgIcon";
import { Tooltip, theme } from "antd";

interface ICommentButtonProps {
    handleOnClick: () => void;
    isActive: boolean;
}

export const CommentButton = (props: ICommentButtonProps) => {

    const { handleOnClick, isActive } = props;

    const { token: { colorPrimary, colorTextTertiary }} = theme.useToken();
    
    return (
        <Tooltip placement="bottom" title={"Comments"}>
            <CommentSvgIcon 
                className="cursor-pointer" 
                onClick={handleOnClick} 
                height="22" 
                width="22" 
                style={{ color: isActive ? colorPrimary : colorTextTertiary }}  
            />
        </Tooltip>
    )
}
