import { Col, Modal, Row, Select } from "antd";
import { useState } from "react";
import { getDefaultLangOfClient, getIdiomClient } from '../../environments/env';
import { CLIENT_IDIOMS, INTERNATIONALIZATION_LANGUAGES, defaultLangKey } from "@constants/languages";
import i18n from 'i18next';

interface ILanguageAndIdiomSelectorModalProps {
  visibleModal: boolean,
  toogleModal: () => void
}

export const LanguageAndIdiomSelectorModal = (props: ILanguageAndIdiomSelectorModalProps) => {

    const { visibleModal, toogleModal} = props;

    const [languageSelected, setLanguage] = useState<string>(getDefaultLangOfClient())
    const [idiomSelected, setIdiom] = useState(getIdiomClient())
  
    const onLanguageSelect = (id: string) => {
      const languageSelected = INTERNATIONALIZATION_LANGUAGES.find((item) => String(item.id) === String(id));
      if(languageSelected) {
        setLanguage(languageSelected.id);
        i18n.changeLanguage(languageSelected.id);
        setIdiom("__none__")
      }
    } 
  
    const onIdiomSelect = (id: string) => {
      const idiomSelect = CLIENT_IDIOMS.find((item) => String(item.clientId) === String(id));
      if (!idiomSelect) { return; }

      if (idiomSelect.clientId === "__none__") {
         i18n.changeLanguage(`${languageSelected ? languageSelected : defaultLangKey}`);
         return;
      }
  
      if(languageSelected) {
        setIdiom(idiomSelect.clientId);
        i18n.changeLanguage(`${languageSelected}-${idiomSelect.clientId}`);
      }
  
      if(!languageSelected) {
        setIdiom(idiomSelect.clientId);
        i18n.changeLanguage(`${defaultLangKey}-${idiomSelect.clientId}`).catch(() => { i18n.changeLanguage(`${defaultLangKey}`); });
      }
    } 
    
    return (
        <Modal
            title="Language and Idiom Selector"
            open={visibleModal}
            onCancel={toogleModal}
            width={450}
            centered
        >
            <Row className="pt-20">
                <Col span={24} className="mb-20">
                <span>Language</span>
                <Select
                    placeholder="Select a Language"
                    className='w-full mt-5'
                    options={INTERNATIONALIZATION_LANGUAGES.filter(lng => lng.translationsIncluded).map((item) => ({ id: item.id, value: String(item.id), label: `${item.languageLabelInEnglish} - ${item.language}` }))}
                    defaultValue={'en'}
                    onSelect={onLanguageSelect}
                />
                </Col>
            </Row>
            <Row>
                <Col span={24} className="mb-20">
                <span>Client Idiom</span>
                <Select
                    placeholder="Select a Client Idiom"
                    className='w-full mt-5'
                    options={CLIENT_IDIOMS.filter(idiom => idiom.translationsIncluded).map((item) => ({ value: item.clientId, label: item.clientDescription }))}
                    defaultValue={idiomSelected}
                    onSelect={onIdiomSelect}
                />
                </Col>
            </Row>
        </Modal>
    )
}