import { http } from "@infrastructure/http/axios.instance";
import { ISearchResult } from "@models/search";
import { getUniqueId } from "./utils.repository";

export const searchRepository = {
  search: async (queryParams: { q: string }) => {
    const { q } = queryParams;
    const url = `/search-controller/search?q=${q}`

    const promiseGetAll = await http.get<ISearchResult | null>(url).then((results) => {
      if (results && results.data?.locations) {
        results.data.locations = results.data.locations.map((location) => ({ resultId: getUniqueId() , ...location }) )
      }
      if (results && results.data?.sites) {
        results.data.sites = results.data.sites.map((site) => ({ resultId: getUniqueId(), ...site }) )
      }
      return results;
    });
    return promiseGetAll.data;
  }
}