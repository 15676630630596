import { useState, useEffect } from "react";
import { isDevelopment } from "../environments/env";

export const useIsDevelopment= () => {

    const [isDevelopmentActived, setIsDevelopmentActived] = useState(false)

    useEffect(() => {
        setIsDevelopmentActived(isDevelopment())
    }, []);
    
    return [isDevelopmentActived, setIsDevelopmentActived];
};
