import { Alert, Button, Col, Row, Space, Typography, message, Card, theme } from "antd";
import Title from "antd/es/typography/Title";
import { Popconfirm } from 'antd';

import { useState } from "react";
import { resetApiKey } from "../../services/apiaccess.service";
import { AxiosError, AxiosResponse } from "axios";
import { configuration } from "../../environments/env";
import { BackButton } from "@components/Utils/BackButton";
import { useLocation, useNavigate } from "react-router-dom";

const BaseAPI = configuration.API_URL;


const { Text } = Typography;
const { Paragraph } = Typography;

const ApiAccess: React.FC = () => {
    const initialAsterisks = "********************";
    const [apiKeyValue, setApiKeyValue] = useState<string>(initialAsterisks)
    const [messageApi, contextHolder] = message.useMessage();
    const { token: { colorBgContainerDisabled } } = theme.useToken();

    const error = (message: string) => {
        messageApi.open({
            type: 'error',
            content: `There was an error: "${message}"`,
        });
    };
    const regenerateApiKey = () => {
        resetApiKey().then((response: AxiosResponse) => {
            setApiKeyValue(response?.data);
        }).catch((e: AxiosError) => {
            error(e.message);
        });

    }

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const navigateBack = () => {
        const newPath = pathname.substring(0, pathname.lastIndexOf('/'));
        navigate(newPath);
    }

    const isCopyable = initialAsterisks !== apiKeyValue;
    return (
        <>
            {contextHolder}
            <BackButton onClick={navigateBack} >{'Back'}</BackButton>
            <Row justify="space-between" className="mb-20 mt-20">
                <Col span={12} >
                    <Space size={12}>
                        <Title level={4}   >API Access</Title>
                    </Space>
                </Col>
            </Row>

            <Row className="mb-15">
                <Col  >
                    <Alert message={<Space><Text>For security reasons Api-Keys will only appear once.</Text><Text strong>Copy them after creation.</Text></Space>} type="info" />
                </Col>
            </Row>
            <Row className="mb-15">
                <Col span={2}>
                    <Text >Api-Key: </Text>
                </Col>
                <Col span={22} >
                    <Row>
                        <Col>
                            <Paragraph code copyable={isCopyable}>{apiKeyValue}</Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Popconfirm
                                title={<>Are you sure to regenerate the Api-Key?,<br />previous value will be unusable</>}
                                okText="Yes"
                                onConfirm={regenerateApiKey}
                                cancelText="No"
                            >
                                <Button danger>Regenerate</Button>
                            </Popconfirm>
                        </Col>
                    </Row>
                </Col>

            </Row >
            <Row >
                <Col span={2}>
                    <Text >Example: </Text>
                </Col>
                <Col span={8} >
                    <Card style={{ backgroundColor: colorBgContainerDisabled }}>
                        <Paragraph style={{ fontFamily: 'monospace' }} copyable>{`curl --location --request GET '${BaseAPI}locations' \
--header 'Authorization: api-key ${apiKeyValue}'`}</Paragraph>
                    </Card></Col>
            </Row>


        </>
    );

}
export default ApiAccess;