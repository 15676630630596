import { TabContainer } from '@components/Utils/TabContainer'
import { OxBlueImageView } from './OxBlueImageView'

export const OxBlueTab = () => {
  return (
    <TabContainer>
      <OxBlueImageView />
    </TabContainer>
  )
}
