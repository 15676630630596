import { formatDatePipe } from "@shared/util/date-utils";
import { Tooltip } from "antd";
import { HTMLProps, useEffect, useState } from "react";

export interface IValidUntilProps extends HTMLProps<HTMLSpanElement> {
    date?: string | null,
}

export const ValidUntil = (props: IValidUntilProps) => {

    const { date: dateStr, style, ...restProps } = props;

    const [differenceInDays, setDifferenceInDays] = useState<number | undefined>(undefined)

    useEffect(() => {
        if (dateStr) {
            const date = new Date(dateStr!);
            date.setHours(0, 0, 0, 0);
            const now = new Date();
            now.setHours(0, 0, 0, 0);
            const difference = date.getTime() - now.getTime();
            setDifferenceInDays(difference / (1000 * 3600 * 24));
        }
    }, [dateStr])

    return (<>{differenceInDays &&
        <Tooltip title={formatDatePipe(dateStr)}>
            {differenceInDays >= 0 ?
                <span style={{ ...style }} {...restProps}>{differenceInDays} days left</span>
                : <span style={{ ...style, color: "#A8071A" }} {...restProps}>Past due</span>
            }
        </Tooltip>
    }</>);
}