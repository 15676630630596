/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { Card, Col, Layout, Row } from "antd";
import { Content } from "antd/es/layout/layout";

import { SITERISE_BG_WHITE_COLOR } from "@providers/ThemeProvider";
import { If } from '@components/Utils/Structural';
import { useAppDispatch, useAppSelector } from "@store/store";
import { clearState, validateToken } from "@store/slices/account";
import { useContainerErrorAnimation } from "@components/Utils/ContainerErrorAnimation";
import { AccountActionTypeEnum } from "@models/enumerations/account-action-type.enum";
import { BrandLogo } from "./BrandLogo";
import { FooterChangePassword } from './FooterChangePassword';
import { ContainerFormChangePassword } from "./ContainerFormChangePassword";
import { ContainerChangePasswordSuccessfully } from "./ContainerChangePasswordSuccessfully";
import { ErrorTokenMessage } from "./ErrorTokenMessage";

interface  IChangePasswordProps {
  typeAction: AccountActionTypeEnum
}

export const ChangePassword = (props: IChangePasswordProps) => {

  const { typeAction } = props;

  const [searchParams] = useSearchParams();  
  const key = searchParams.get('key');

  const dispatch = useAppDispatch();

  const { animateError } = useContainerErrorAnimation();

  const { resetPasswordSuccess, isTokenValid, loading } = useAppSelector(state => state.Account);

  useEffect(() => () => { dispatch(clearState()) }, [] );

  useEffect(() => {
    key && dispatch(validateToken({ actionType: typeAction, token: key}));
  }, [])

  return (
    <div className="flex items-center justify-center min-h-screen transition-all-1-ease">
      <Layout className="h-screen overflow-scroll" style={{ backgroundColor: SITERISE_BG_WHITE_COLOR }}>
        <Content style={{ backgroundColor: SITERISE_BG_WHITE_COLOR }} >

          <div className="flex flex-col justify-between h-full">

            <div className="flex flex-col">
                <div className={`flex items-center pt-40 pb-30 justify-center transition-all-1-ease ${animateError ? "error-animation" : ""}`} style={{ borderRadius: 4 }} >
                  <Card style={{ width: 336, borderRadius: 4 }} className="pr-10 pl-10 bg-color-neutral-2">
                    
                    {/* Logo */}
                    <Row justify="center">
                      <Col className="text-center">
                        <BrandLogo />
                      </Col>
                    </Row>

                    <If condition={!!(isTokenValid)}>
                      <>
                        {/* Container Form Component */}
                        <If condition={!resetPasswordSuccess}>
                          <ContainerFormChangePassword typeAction={typeAction} />
                        </If>
                        
                        {/* Reset Successfully Component  */}
                        <If condition={resetPasswordSuccess}>
                          <ContainerChangePasswordSuccessfully typeAction={typeAction} />
                        </If>
                      </>
                    </If>

                    {/* Error In token (Invalid, Expired, Malformed etc.) */}
                    <If condition={ !Boolean(isTokenValid) && !loading }>
                      <ErrorTokenMessage />
                    </If>                    

                  </Card>
                </div>
            </div>

            <FooterChangePassword />

          </div>

        </Content>
      </Layout>
    </div>
  );
}