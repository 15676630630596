import { TabContainer } from '@components/Utils/TabContainer';
import { Col, Row, theme } from 'antd';
import { BudgetDetailTable } from './BudgetDetailTable';
import ProjectInfo from './ProjectInfo';
import BudgetSummary from './BudgetSummary';
import BudgetApprovers from './BudgetApprovers';
import CreateRevision from './CreateRevision';
import BudgetAlertLatestVersion from './BudgetAlertLatestVersion';

export const BudgetDetailTab = () => {

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <TabContainer>
      <Row >
        <Col span={15}>
          <div
            className=" mt-16 ml-40 mb-8 p-20 "
            style={{ backgroundColor: colorBgContainer, borderRadius: '0.25rem', gap: '0.75rem' }}
          >
            <BudgetDetailTable />
          </div>
        </Col>
        <Col span={9}>
          <div
            className=" flex flex-col ml-40 "
            style={{ backgroundColor: colorBgContainer, borderRadius: '0.25rem' }}
          >
            <BudgetAlertLatestVersion />
            <ProjectInfo />
            <BudgetSummary />
            <BudgetApprovers />
            <CreateRevision />
          </div>
        </Col>
      </Row>
    </TabContainer >
  );
};
