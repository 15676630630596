import { IAuditHistorySetting, IHistorySlice } from '@models/audit-history';
import { http } from '../http/axios.instance';
import { MAX_RESULT_AUDIT_HISTORY_DEFAULT } from '@constants/core';

const projectDeliverablesHistoryPath = '/project-deliverables-extended';
const punchListItemHistoryPath = '/punch-list-item-extended';
const historyPath = '/history';

export const auditHistoryRepository = {
    // Custom Fetch for Project Deliverable 
    getProjectDeliverableHistory: async (params: IAuditHistorySetting) => {

        const { referenceId: projectDeliverableId, lastFetchQueryParams = {}} = params;
        const {
            maxResults = MAX_RESULT_AUDIT_HISTORY_DEFAULT,
            newestFirst = true,
        } = lastFetchQueryParams

        const requestUrl = `${projectDeliverablesHistoryPath}/${projectDeliverableId}/history?maxResults=${maxResults}&newestFirst=${newestFirst}`;

        const promiseGetHistory = await http.get(requestUrl);
        return promiseGetHistory;
    },
    // Custom Fetch for Project Punch List Item 
    getPunchListItemHistory: async (params: IAuditHistorySetting) => {

        const { referenceId: punchListItemId, lastFetchQueryParams = {}} = params;
        const {
            maxResults = MAX_RESULT_AUDIT_HISTORY_DEFAULT,
            newestFirst = true,
        } = lastFetchQueryParams

        const requestUrl = `${punchListItemHistoryPath}/${punchListItemId}/history?maxResults=${maxResults}&newestFirst=${newestFirst}`;

        const promiseGetHistory = await http.get(requestUrl);
        return promiseGetHistory;
    },
    // Generic
    getEntityHistory: async () => { 
        
        const requestUrl = `${historyPath}`;

        const promiseGetHistory = await http.get<IHistorySlice[]>(requestUrl);
        return promiseGetHistory;
    }
}