/* eslint-disable react-hooks/exhaustive-deps */
import { Timeline } from "@components/Timeline/Timeline";
import { getTimeline, reset } from "@store/slices/change-order-timeline";
import { useAppDispatch, useAppSelector } from "@store/store";
import { useEffect, useState } from "react";
import { ChangeOrderTimelineContext, IChangeOrderTimelineContext } from "./ChangeOrderTimelineContext";
import { ITimeline, ITimelineEntry } from "@models/timeline.model";
import { dateSorter } from "@shared/util/table-utils";
import { ChangeOrdersStatusIds } from "@models/enumerations/change-orders-status";
import { TimelineEntryTypeEnum } from "@models/enumerations/timeline-entry-type-enum.model";

export interface IChangeOrderTimelineProps {
    changeOrderId: number | undefined;
}

export const ChangeOrderTimeline = (props: IChangeOrderTimelineProps) => {

    const { changeOrderId } = props;

    const { timeline: timelineSource, loading } = useAppSelector((state) => state.ChangeOrderTimeline);
    const dispatch = useAppDispatch();
    const [contextValue, setContextValue] = useState<IChangeOrderTimelineContext | null | undefined>(null);
    const [timeline, setTimeline] = useState<ITimeline<ITimelineEntry> | undefined>(undefined)
    const { entity: changeOrder } = useAppSelector((state) => state.ChangeOrder);

    useEffect(() => {
        dispatch(reset());
    }, [])

    useEffect(() => {
        if (changeOrderId) {
            setContextValue({ changeOrderId: changeOrderId });
            dispatch(getTimeline(changeOrderId));
        }
    }, [changeOrderId])

    useEffect(() => {
        if (changeOrder && timelineSource?.timeline && loading === false) {
            // for some statuses we add a dynamic timeline entry at the top (most recent)
            if (changeOrder.lastStatusTransition?.status?.id === ChangeOrdersStatusIds.PENDING_PUBLISHING
                || changeOrder.lastStatusTransition?.status?.id === ChangeOrdersStatusIds.PENDING_ESTIMATE_RESUBMISSION
                || changeOrder.lastStatusTransition?.status?.id === ChangeOrdersStatusIds.PENDING_DETAIL_SUBMISSION
                || changeOrder.lastStatusTransition?.status?.id === ChangeOrdersStatusIds.PENDING_DETAIL_RESUBMISSION) {
                // copy the timeline object
                const timelineCopy: ITimeline<ITimelineEntry> = { ...timelineSource, timeline: [...timelineSource.timeline] };
                // sort the entries by date and find the most recent one
                const lastDateStr = timelineCopy.timeline?.map(entry => entry.date).sort((d1: string | undefined, d2: string | undefined) => (d1 && d2) ? dateSorter(d2, d1) : 0)[0] || "";
                const lastDate = lastDateStr ? new Date(lastDateStr) : undefined;
                // add 1 second to the most recent date so the new entry is at the top
                lastDate?.setSeconds(lastDate.getSeconds() + 1);

                let title = "";
                let type: TimelineEntryTypeEnum = TimelineEntryTypeEnum.CHANGE_ORDER_PENDING_PUBLISHING;

                if (changeOrder.lastStatusTransition?.status?.id === ChangeOrdersStatusIds.PENDING_PUBLISHING) {
                    title = "Pending Publishing";
                    type = TimelineEntryTypeEnum.CHANGE_ORDER_PENDING_PUBLISHING;
                }
                if (changeOrder.lastStatusTransition?.status?.id === ChangeOrdersStatusIds.PENDING_ESTIMATE_RESUBMISSION) {
                    title = "Pending Estimate Resubmission";
                    type = TimelineEntryTypeEnum.CHANGE_ORDER_PENDING_ESTIMATE_RESUBMISSION;
                }
                if (changeOrder.lastStatusTransition?.status?.id === ChangeOrdersStatusIds.PENDING_DETAIL_SUBMISSION) {
                    title = "Pending Detail Submission";
                    type = TimelineEntryTypeEnum.CHANGE_ORDER_PENDING_DETAIL_SUBMISSION;
                }
                if (changeOrder.lastStatusTransition?.status?.id === ChangeOrdersStatusIds.PENDING_DETAIL_RESUBMISSION) {
                    title = "Pending Detail Resubmission";
                    type = TimelineEntryTypeEnum.CHANGE_ORDER_PENDING_DETAIL_RESUBMISSION;
                }

                const newEntry: ITimelineEntry = {
                    title: title,
                    date: lastDate?.toISOString(),
                    type: type,
                }
                timelineCopy.timeline?.push(newEntry);

                setTimeline(timelineCopy)
            } else {
                setTimeline(timelineSource);
            }
        }
    }, [timelineSource, changeOrder, loading])

    return (<>
        {loading === false && timeline &&
            <ChangeOrderTimelineContext.Provider value={contextValue}>
                <Timeline timeline={timeline} />
            </ChangeOrderTimelineContext.Provider>
        }
    </>);
}