import { Helmet } from 'react-helmet-async';
import UnderConstruction from '../components/UnderConstruction/UnderConstruction';
import { configuration } from '../environments/env';

export default function UnderConstructionPage() {
  return (
    <>
      <Helmet>
        <title>Under Construction | { configuration.APP_NAME }</title>
      </Helmet>
      <UnderConstruction />
    </>
  )
}
