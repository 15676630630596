import { CommentReferenceEnum } from './enumerations/comment-reference-enum.model';

export interface IComment {
  id?: number;
  body?: string | null;
  referenceType?: CommentReferenceEnum | null;
  referenceId?: number | null;
  createdBy?: number;
  createdDate?: string;
  lastModifiedBy?: number;
  lastModifiedDate?: string;
}
export interface ICommentRichText {
  id?: number;
  payload?: string | null;
  referenceType?: CommentReferenceEnum | null;
  referenceId?: number | null;
  createdBy?: number;
  createdById?: number;
  createdByFirstName?: string;
  createdByLastName?: string;
  createdByEmail?: string;
  createdDate?: string;
  lastModifiedBy?: number;
  lastModifiedDate?: string;
}

export interface ICommentRichTextGetRequest { 
  referenceType: CommentReferenceEnum | null;
  referenceId: number | null;
}
export interface ICommentRichTextGetRequestParams extends ICommentRichTextGetRequest {
  page?: number;
  size?: number;
  sort?: string;
}

export interface ICommentRichTextCreateRequest  extends ICommentRichTextGetRequest {
  payload: string;
  onSuccess?: () => void;
}

export interface ICommentRichTextDeleteRequest extends ICommentRichTextGetRequest{
  id: string | number;
  onSuccess?: () => void;
}

export const defaultValue: Readonly<IComment> = {};
