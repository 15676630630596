import { ICompanyContact } from "./company.contact.model";
import { CompanyReferenceEnum } from "./enumerations/company-reference-enum.model";
import { ICompanyRole } from "./company-binding.model";
import { IAddress } from "./address.model";

export interface ICompany {
  id?: number;
  name?: string | null;
  website?: string | null;
  address?: IAddress | null;
  email?: string | null;
  phone?: string | null;
  contactCount?: Number | null;
  primaryContact?: ICompanyContact;
}

export interface CompanyExtended extends ICompany {
  primaryContact: ICompanyContact
}

export interface ICompanyItem {
  id?: number;
  referenceId?: number | string | null;
  referenceType?: CompanyReferenceEnum | null;
  company: CompanyExtended;
  companyRole: ICompanyRole;
}

export const defaultValue: Readonly<ICompany> = {};

