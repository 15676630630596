/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect } from 'react';
import { IAsset } from "../../FloorPlanEditor/interfaces/interfaces";
import { theme } from "antd";
import { IFloorplanCallbacks, IFloorplanConfig } from "floor-plan-editor-2d";
import { FloorPlanEditor } from "floor-plan-editor-2d";
import { DerivativesEnum } from '@models/enumerations/derivatives-enum.model';
import { IDerivatives } from '@models/attachment.model';
import "./FloorPlanViewerReadOnly.less";
import { isArrayWithValues } from '@shared/util/array-util';

export interface IFloorPlanViewerProps {
    height: number;
    containerStyle?: React.CSSProperties | undefined;
    initialAssets?: IAsset[] | [];
}

let floorPlanEditor: FloorPlanEditor;

export const FloorPlanViewerReadOnly = (props: IFloorPlanViewerProps) => {

    const  { initialAssets = [], height, containerStyle = {} } = props;
    
    let floorPlanContainer:HTMLElement;
    
    const { token: { colorPrimaryBorderHover, colorPrimary } } = theme.useToken();

    const initializeEditor = () => {

        floorPlanContainer = document.getElementById("floor-plan-container-read-only")!;
 
        const floorPlanInitialAssets: IAsset[] = [...initialAssets];
        const isEditMode = true;
        const floorplanCallbacks: IFloorplanCallbacks = {};

        const floorplanConfig: IFloorplanConfig = {
            backgroundColor: "transparent",
            gridPatternConfig: {
                size: 0.1,
                color: "transparent"
            },
            defaultAssetColor: colorPrimaryBorderHover,
            backgroundImage: "",
            allowSingleSelection: true,
            allowMultipleAdd: false,
            strokeConfig: {
                width: 1.2 
            },  
            dimensionsConfig: {
                pixelsPerMeter: 45,
                officeWidth: 100,
                officeHeight: 67,
            },
            markerRadiusMeters: 0.50,
            showFloorplanControls: {
                showMinimap: false,
                showDeleteButton: false,
                showResetButton: false,
                showClearButton: false,
                showZoomSlider: false,
                showMoveButtons: false,
                showRotateButtons: false,
                showDuplicateButton: false
            },
            allowedAssetTypes: []
        }

        floorPlanEditor = new FloorPlanEditor(
            floorPlanContainer,
            floorPlanInitialAssets,
            isEditMode,
            floorplanCallbacks,
            floorplanConfig
        );
        
    }

    useLayoutEffect(() => {

        initializeEditor();
        
        return () => {
            floorPlanEditor?.destroy();
        }
    }, []);

    useLayoutEffect(() => {
        floorPlanEditor?.setAllAssets?.(initialAssets);
        floorPlanEditor?.setSelectedAsset(initialAssets);
        
        // POC Go to Point (Zoom Animation Included)
        // initialAssets[0] && floorPlanEditor.goTo(initialAssets[0].x, initialAssets[0].y, 0.5);

    }, [initialAssets]);

    useLayoutEffect(() => {

        if (isArrayWithValues(initialAssets)) {
            const punchListItemData = initialAssets[0].data;
            
            const derivativeFounded = punchListItemData?.punchlistPage?.floorPlan?.derivatives?.find((derivative) => derivative.derivativeType === DerivativesEnum.PREVIEW) ?? {} as IDerivatives;
            
            if (derivativeFounded.signedUrl) {
                floorPlanEditor?.setBackgroundImage?.(derivativeFounded.signedUrl);
            }
        }
    }, [initialAssets])

    return (
        <>
            <div className="relative">
                <div 
                    id="floor-plan-container-read-only"
                    style={{ 
                        width: `100%`, 
                        height: height,
                        background: "transparent",
                        ...containerStyle
                    }}>
                </div>
                <div 
                    id="overlay" 
                    className="absolute w-full h-full top-0 left-0"
                    style={{
                        border: `1px solid var(--neutral-4, #F0F0F0)`,
                        borderRadius: 4,
                        backgroundColor: `${colorPrimary}19`
                    }}>    
                </div>
            </div>
        </>
    )
}
