/* eslint-disable react-hooks/exhaustive-deps */
import { ICurrency } from '@models/currency.model';
import { useAuth } from '@providers/AuthProvider';
import { DATE_FORMAT_DEFAULT, formatDatePipe } from '@shared/util/date-utils';
import { getUserDisplayName } from '@shared/util/user-utils';
import { countChangeOrders } from '@store/slices/change-orders';
import { getActiveEntities as getCurrencies } from '@store/slices/currency';
import { getEntities as getRfis } from "@store/slices/rfi";
import { useAppDispatch, useAppSelector } from '@store/store';
import { Avatar, Button, Col, DatePicker, Divider, Form, Input, Row, Select, theme } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DescriptionInput } from '../DescriptionInput';
import { useChangeOrderStepperContext } from './ChangeOrderStepperContext';
import { IChangeOrderStepProps } from './Stepper';

const { Option } = Select;

export const StepOne = (props: IChangeOrderStepProps) => {

    const { token: { colorTextTertiary } } = theme.useToken();

    const { user: currentUser } = useAuth();
    const [changeOrderNumber, setChangeOrderNumber] = useState<number>(1);
    const [form] = Form.useForm();
    const { projectId } = useParams<"projectId">();
    const { entity, entitiesQuantity: changeOrdersQuantity } = useAppSelector((state) => state.ChangeOrder);
    const { activeEntities: currenciesList } = useAppSelector((state) => state.Currency);
    const { entities: rfiList } = useAppSelector((state) => state.Rfi);
    const dispatch = useAppDispatch();
    const [impactDaysDisabled, setImpactDaysDisabled] = useState<boolean>(entity?.scheduleImpact === true ? false : true)
    const [impactDaysValue, setImpactDaysValue] = useState<number | undefined>(undefined)
    const [selectedCurrencyId, setSelectedCurrencyId] = useState<number | undefined>(840)
    const createChangeOrderContext = useChangeOrderStepperContext();
    const { t } = useTranslation();

    useEffect(() => {
        if (projectId) {
            dispatch(
                countChangeOrders(projectId)
            );
            dispatch(
                getCurrencies()
            );
            dispatch(
                getRfis({
                    page: 0,
                    size: 1000,
                    sort: `number,asc`,
                    query: `projectId.equals=${projectId}`
                })
            );
        }
    }, [projectId])

    useEffect(() => {
        if (projectId) {
            dispatch(
                countChangeOrders(projectId)
            );
            dispatch(
                getCurrencies()
            );
            dispatch(
                getRfis({
                    page: 0,
                    size: 1000,
                    sort: `number,asc`,
                    query: `projectId.equals=${projectId}`
                })
            );
        }
    }, [projectId])

    useEffect(() => {
        if (createChangeOrderContext && entity) {
            form.setFieldsValue({
                ...entity,
                dueDate: dayjs(entity.dueDate),
                estimatedCost: entity.estimatedCost ? parseFloat(entity.estimatedCost) : entity.estimatedCost,
                scheduleImpact: entity.scheduleImpact === true ? 'yes' : 'no',
                relatedRfiId: entity.relatedRfi?.id,
            });
        }
    }, [form, createChangeOrderContext, entity]);

    useEffect(() => {
        if (createChangeOrderContext && entity) {
            setChangeOrderNumber(entity.number!);
        } else if (changeOrdersQuantity) {
            setChangeOrderNumber(Number(changeOrdersQuantity + 1));
        }
    }, [changeOrdersQuantity, createChangeOrderContext, entity])

    const onFinish = (values: any): void => {
        props.complete?.({ ...values, currencyId: selectedCurrencyId });
    };

    const onFinishFailed = () => {
    };

    const disabledDate: RangePickerProps['disabledDate'] = (current: Dayjs) => {
        // Cannot select days before today and today
        return current && current < dayjs().endOf('day')
    };

    const getDefaultDueDate = () => {
        const date = new Date();
        date.setDate(date.getDate() + 30);
        return date;
    }

    const genericFilter = (input: string, option: any) => (
        option?.children?.toLowerCase!()?.indexOf!(input?.toLowerCase!()) >= 0
    )

    const createClick = () => {
        form.submit();
    }

    const handleScheduleImpactChange = (value: string) => {
        if (value !== "yes") {
            setImpactDaysValue(undefined)
            setImpactDaysDisabled(true)
        }
        else {
            setImpactDaysValue(0)
            setImpactDaysDisabled(false)
        }
    }

    useEffect(() => {
        if (createChangeOrderContext?.changeOrderCreated === true && entity?.currency?.id) {
            setSelectedCurrencyId(entity.currency.id);
        }
    }, [createChangeOrderContext, entity])

    const handleCurrencySelect = (value: number) => {
        setSelectedCurrencyId(value);
    }

    const currencySelect = (
        <Select
            style={{ width: '70px' }}
            optionLabelProp="label"
            popupMatchSelectWidth={250}
            onChange={handleCurrencySelect}
            defaultValue={840}
            value={selectedCurrencyId}
        >
            {[...currenciesList].sort((c1: ICurrency, c2: ICurrency) => {
                if (c1.name! > c2.name!) {
                    return 1;
                }

                if (c1.name! < c2.name!) {
                    return -1;
                }

                return 0;
            })
                .map((currency) => (
                    <Option value={currency.id} key={currency.id} label={currency.symbol}>
                        {`${currency.symbol} - ${currency.isoCode}: ${currency.name}`}
                    </Option>
                ))}
        </Select>
    );

    useEffect(() => {
        form.validateFields(['scheduleImpactDays']);
    }, [impactDaysDisabled, form]);

    return (
        <>
            <Row>
                <Col className="pl-30 pt-15">
                    <Row>
                        <Col>
                            <span className="font-bold">{t("changeOrder.changeOrder")}:</span>
                            <span className="ml-10" style={{ color: colorTextTertiary }}>#{changeOrderNumber}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span className="font-bold">Submitter:</span>
                            <span className="ml-10 flex-row items-center" style={{ color: colorTextTertiary }}>
                                <Avatar className="cursor-default mr-5" gap={4} style={{ backgroundColor: '#B2B2B2' }}>
                                    {`${currentUser.firstName?.slice(0, 2)}`.toUpperCase()}
                                </Avatar>
                                {getUserDisplayName(currentUser)}
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span className="font-bold">Date of creation:</span>
                            <span className="ml-10" style={{ color: colorTextTertiary }}>{formatDatePipe(entity?.createdDate || (new Date()).toDateString())}</span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Divider type="horizontal"></Divider>
            <Form
                layout="vertical"
                form={form}
                initialValues={{
                    dueDate: dayjs(getDefaultDueDate())
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Row>
                    <Col span={12} className='pl-10 pr-10'>
                        <Form.Item name="dueDate" label={t("changeOrder.requiredByLabel") + ":"} initialValue={dayjs(getDefaultDueDate())} rules={[
                            { required: true, message: t("changeOrder.requiredByRequired") }
                        ]}>
                            <DatePicker
                                name="dueDate"
                                className="w-full"
                                format={DATE_FORMAT_DEFAULT}
                                disabledDate={disabledDate}
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} className='pl-10 pr-10'></Col>
                    <Col span={12} className='pl-10 pr-10'>
                        <Form.Item name="scheduleImpact" label={t("changeOrder.scheduleImpactLabel") + ":"} rules={[
                            { required: true, message: t("changeOrder.scheduleImpactRequired") }
                        ]}>
                            <Select
                                placeholder={t("changeOrder.scheduleImpactPlaceholder")}
                                options={[{ value: "yes", label: "Yes" }, { value: "no", label: "No" }]}
                                onChange={handleScheduleImpactChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} className='pl-10 pr-10'>
                        <Form.Item name="scheduleImpactDays" label={t("changeOrder.numberOfDaysLabel") + ":"}
                            rules={[{ required: impactDaysDisabled === false, message: t("changeOrder.numberOfDaysRequired") },
                            { pattern: new RegExp(/^[1-9]{1}[0-9]{0,2}$/i), message: t("changeOrder.numberOfDaysCustomValidationMessage") }]}
                        >
                            <Input type='number' disabled={impactDaysDisabled} defaultValue={impactDaysValue} min={1} max={999} step={1} style={{ width: '80px' }} />
                        </Form.Item>
                    </Col>
                    <Col span={24} className='pl-10 pr-10'>
                        <Form.Item name="description" label={t("changeOrder.descriptionLabel") + ":"} rules={[
                            { required: true, message: t("changeOrder.descriptionRequired") }
                        ]}>
                            <DescriptionInput initialValue={entity?.description} />
                        </Form.Item>
                    </Col>
                    <Col span={24} className='pl-10 pr-10'>
                        <Form.Item name="drawingReference" label={t("changeOrder.drawingReferenceOptLabel") + ":"}>
                            <Input type='text' placeholder={t("changeOrder.drawingReferencePlaceholder")} maxLength={255} />
                        </Form.Item>
                    </Col>
                    <Col span={24} className='pl-10 pr-10'>
                        <Form.Item name="relatedRfiId" label={t("changeOrder.relatedToRfiLabel") + ":"}>
                            <Select
                                placeholder={t("changeOrder.relatedToRfiPlaceholder")}
                                allowClear
                                optionFilterProp="children"
                                showSearch
                                filterOption={genericFilter}
                            >
                                {rfiList?.map((rfi) => (
                                    <Option value={rfi.id} key={rfi.id}>
                                        {`#${rfi.number} - ${rfi.category?.name}`}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24} className='pl-10 pr-10'>
                        <Form.Item name="estimatedCost" label={t("changeOrder.estimatedCostLabel") + ":"} rules={[
                            { required: true, message: t("changeOrder.estimatedCostRequired") }
                        ]}>
                            <Input addonBefore={currencySelect} type='number' />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Row>
                <Col span={12} className='text-left pl-10 pr-10'>
                    <Button type="link" danger onClick={props.dismiss}>
                        {t("changeOrder.stepper.dismissButton")}
                    </Button>
                </Col>
                <Col span={12} className='text-right pl-10 pr-10'>
                    <Button type="primary" onClick={createClick}>
                        {t("changeOrder.stepper.saveAndContinueButton")}
                    </Button>
                </Col>
            </Row>
        </>
    );
}