import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getRoles } from "@store/slices/role";
import { Card, Switch, Tag, theme } from "antd";
import useVisible from "@HOOKs/UseVisible";
import { IRole } from "@models/role.model";
import { EditOutlined } from "@ant-design/icons";
import { NEUTRAL_7_COLOR } from "@providers/ThemeProvider";
import { RoleEdit } from "./RoleEdit";
import "@styles/components/admin-item-card.less";

const ListItem = (role: IRole) => {
  return <li style={{ color: NEUTRAL_7_COLOR }} className="pb-4">{role.name} {role.discipline && <Tag>{role.discipline.name}</Tag>}</li>
}

interface ListItemEditableProps {
  role: IRole;
  roleSelected: IRole;
  editRoleClicked: any;
}

const ListItemEditable = (props: ListItemEditableProps) => {

  const { role, roleSelected, editRoleClicked } = props;

  return <div className={`list-item-editable ${(roleSelected?.id === role.id ? 'selected' : '')}`} onClick={() => editRoleClicked(role)}>
    <div>
      <div className="handle-container">
        <div className="handle-dot"></div>
      </div>
    </div>
    <div className="description">
      {role.name} {role.discipline && <Tag>{role.discipline.name}</Tag>}
    </div>
    <span className="edit-container">
      <span className="edit-link">
        <EditOutlined /> Edit
      </span>
    </span>
  </div>
}

export const RolesCard = (props: any) => {

  const dispatch = useAppDispatch();

  const { token: { colorPrimary, colorTextDisabled } } = theme.useToken();

  const loadRoles = () => {
    dispatch(
      getRoles()
    );
}

  useEffect(() => {
    loadRoles();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { entities: roles } = useAppSelector((state) => state.Roles);

  const [openEditRole, toogleEditRole] = useVisible();
  const [roleSelected, setRoleSelected] = useState<IRole>({})
  const [isEditing, setIsEditing] = useState<boolean>(false)

  useEffect(() => {
    if (!openEditRole) {
      setRoleSelected({})
    }
  }, [openEditRole]);

  const onModifiedChanges = (role: IRole) => {
    loadRoles();
  }

  const toggleEdit = () => {
    setIsEditing(!isEditing)
  };

  const editRole = (role : IRole) => {
    setRoleSelected(role)
    toogleEditRole()
  }

  const createNewRole = () => {
    setRoleSelected({})
    toogleEditRole();
  }

  return (
    <>
      <RoleEdit open={openEditRole} toogle={toogleEditRole} role={roleSelected} onModifiedChanges={onModifiedChanges} />
      <Card title={<>Role<span className="item-count">{roles?.length}</span></>} bordered={false} className="admin-item-card" extra={<div><span className="extra-label" style={{ color: (isEditing ? colorPrimary : colorTextDisabled) }} onClick={toggleEdit}>{isEditing ? 'Managing' : 'Manage'}</span><Switch checked={isEditing} onClick={toggleEdit} /></div>}>
        <ul className={isEditing ? 'editing' : ''}>
            {roles.map(role => 
            <div
                key={role.id}>
                <>
                {isEditing && <ListItemEditable role={role} roleSelected={roleSelected} editRoleClicked={editRole} />}
                {!isEditing && <ListItem {...role} />}
                </>
            </div>
            )}
        </ul>
        {isEditing && <>
            <div className="spacer"></div>
            <span onClick={createNewRole} className="add-new-btn">+ Add New Role</span>
        </>}
        </Card>
    </>
  )
}