import { Helmet } from 'react-helmet-async';
import { Login } from '../components/Login/Login';
import { configuration } from '../environments/env';
import { AuthProvider } from '../providers/AuthProvider';

export default function LoginPage() {
  return (
    <AuthProvider>
      <Helmet>
        <title>Login | { configuration.APP_NAME }</title>
      </Helmet>
      <Login />
    </AuthProvider>
  )
}