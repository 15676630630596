export const EmptyCommentSvgIcon = () => {
    return (
        <svg 
            width="40" 
            height="37" 
            viewBox="0 0 40 37" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
                <path d="M22.6211 15.0898C21.6285 15.0898 20.8594 15.859 20.8594 16.8086C20.8594 17.7582 21.6285 18.5273 22.6211 18.5273C23.5277 18.5273 24.2969 17.7582 24.2969 16.8086C24.2969 15.859 23.5277 15.0898 22.6211 15.0898ZM10.5898 15.0898C9.59727 15.0898 8.82812 15.859 8.82812 16.8086C8.82812 17.7582 9.59727 18.5273 10.5898 18.5273C11.4965 18.5273 12.2656 17.7582 12.2656 16.8086C12.2656 15.859 11.4965 15.0898 10.5898 15.0898Z" fill="currentColor"/>
                <path d="M36.4135 11.8261C34.3467 8.9902 31.4592 7.09528 28.2924 6.2402V6.2445C27.5576 5.42809 26.7283 4.67614 25.8002 4.00583C18.7662 -1.10745 8.892 0.452313 3.75724 7.4863C-0.380652 13.2011 -0.204479 20.8883 4.01505 26.3496L4.04943 32.0472C4.04943 32.1847 4.07091 32.3222 4.11388 32.4511C4.34161 33.1773 5.11505 33.5769 5.83693 33.3492L11.2768 31.6347C12.7162 32.1461 14.2029 32.4382 15.6811 32.5199L15.6596 32.5371C19.4881 35.3258 24.5068 36.1636 29.1088 34.6425L34.5701 36.4215C34.7076 36.4644 34.8494 36.4902 34.9955 36.4902C35.7561 36.4902 36.3705 35.8758 36.3705 35.1152V29.3574C40.1561 24.2183 40.2549 17.1156 36.4135 11.8261ZM11.8783 28.584L11.3627 28.3691L7.1088 29.7011L7.06583 25.2324L6.72208 24.8457C3.08693 20.4113 2.8463 14.0218 6.24943 9.33395C10.3916 3.65349 18.3322 2.3988 23.9955 6.49802C29.676 10.6273 30.935 18.555 26.8315 24.2011C23.3897 28.9234 17.2279 30.6679 11.8783 28.584ZM33.5776 27.8535L33.2338 28.2832L33.2768 32.7519L29.0658 31.334L28.5502 31.5488C26.144 32.4425 23.5787 32.5156 21.2026 31.8496L21.194 31.8453C24.3693 30.8699 27.2483 28.8933 29.3236 26.0488C32.6065 21.5242 33.1393 15.8394 31.2315 10.9925L31.2572 11.0097C32.2455 11.7187 33.1522 12.6039 33.9213 13.6738C37.0408 17.9535 36.8647 23.78 33.5776 27.8535Z" fill="currentColor" />
                <path d="M16.6055 15.0898C15.6129 15.0898 14.8438 15.859 14.8438 16.8086C14.8438 17.7582 15.6129 18.5273 16.6055 18.5273C17.5121 18.5273 18.2812 17.7582 18.2812 16.8086C18.2812 15.859 17.5121 15.0898 16.6055 15.0898Z" fill="currentColor"/>
        </svg>
    )
}