/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import { Skeleton } from "antd";
import { AlbumDetails } from "./AlbumDetails";
import { useAppDispatch, useAppSelector } from "@store/store";
import { AlbumGallery } from "./AlbumGallery";
import { getPhotosByReferenceTypeAndReferenceId } from "@store/slices/generic-album-gallery-multimedia";
import { AttachmentReferenceEnum } from "@models/enumerations/attachment-reference-enum.model";
import { PAGINATION_SIZE_DEFAULT_IN_PHOTOS } from "@constants/core";
import { useUrlParamsUtils } from "@HOOKs/UseParamsUtils";
import { isNumber } from "@shared/util/number-util";

interface IAlbumDetailsContainerProps {};

export const AlbumDetailsContainer = (props: IAlbumDetailsContainerProps) => {
    
  const dispatch = useAppDispatch();
  const { getUrlParam } = useUrlParamsUtils();
  const loaderRef = useRef(null);
    
  const { loadingDetailsAlbumSelected, entity: album } = useAppSelector((store) => store.PhotoAlbum);
  const { currentPage: pageCounter, loading, firstLoaded } = useAppSelector((store) => store.GenericAlbumGalleryMultimedia);

  const shouldAddMorePhotos = ({ paginationDefault, totalItems, pageToLoad }) => {
    if (!pageToLoad || !paginationDefault || !totalItems || paginationDefault === 0 || pageToLoad < 0 || totalItems === 0 ) {
      return false;
    }
    return pageToLoad <  Math.ceil(totalItems / paginationDefault );
  }

  const observer = useRef<IntersectionObserver | null>(null);
  const [pagesLoaded, setPagesLoaded] = useState<number[]>([])

  useEffect(() => {
    setPagesLoaded([]);
  }, [album])

  useEffect(() => {
    if (!firstLoaded) {
      setPagesLoaded([]);
    }
  }, [firstLoaded])

  const loadAnotherPage = useCallback(() => {
    if (!isNumber(pageCounter)) { return; }

    const albumSelected = getUrlParam("albumSelected");
    const pageToLoad = pageCounter! + 1;

    if (pagesLoaded.includes(pageToLoad)) { return; };
    setPagesLoaded([...pagesLoaded, pageToLoad]);
    
    const totalItems = album?.totalPhotos || 0;
    const shouldLoadMore = shouldAddMorePhotos({ paginationDefault: PAGINATION_SIZE_DEFAULT_IN_PHOTOS, totalItems, pageToLoad: pageToLoad });

    if (albumSelected && shouldLoadMore && !loading) {
      dispatch(getPhotosByReferenceTypeAndReferenceId({ 
        referenceId: String(albumSelected), 
        referenceType: AttachmentReferenceEnum.PHOTO_ALBUM, 
        page: pageToLoad, 
        size: PAGINATION_SIZE_DEFAULT_IN_PHOTOS 
      }));
    }
  }, [pageCounter, album, loading]);

  useEffect(() => {
    
    const currentLoader = loaderRef.current;

    if (observer.current) {
      observer.current?.disconnect();
      currentLoader && observer.current.unobserve?.(currentLoader);
      observer.current = null;
    }

    observer.current = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        loadAnotherPage();
      }
    });

    if (currentLoader && !loading) {
      observer.current.observe(currentLoader);
    }

    return () => {
      if (currentLoader && observer?.current) {
        observer?.current.unobserve(currentLoader);
      }
    };
  }, [pageCounter, album, loading]);

  return (
    <div className="flex flex-col w-full overflow-y-scroll">
      <Skeleton loading={loadingDetailsAlbumSelected} paragraph={{ rows: 3 }} active={true} className="w-full pl-16 pr-16 pt-16">
        <AlbumDetails />
      </Skeleton>
      <AlbumGallery />
      <div ref={loaderRef} className="mb-30"></div>
    </div>
  )
}
