import { SVGProps } from 'react';

type IProps = SVGProps<SVGSVGElement>;

export const CloseSvgIcon = (props: IProps) => {
  const { ...restProps } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <g clipPath="url(#clip0_25779_15094)">
        <path
          d="M13.8702 6.47008C13.8702 6.37186 13.7899 6.2915 13.6917 6.2915L12.2184 6.2982L9.99969 8.94329L7.78317 6.30043L6.30772 6.29374C6.20951 6.29374 6.12915 6.37186 6.12915 6.47231C6.12915 6.51472 6.14478 6.5549 6.17156 6.58838L9.07558 10.0482L6.17156 13.5058C6.14459 13.5385 6.12962 13.5795 6.12915 13.6219C6.12915 13.7201 6.20951 13.8004 6.30772 13.8004L7.78317 13.7937L9.99969 11.1486L12.2162 13.7915L13.6894 13.7982C13.7876 13.7982 13.868 13.7201 13.868 13.6196C13.868 13.5772 13.8524 13.537 13.8256 13.5036L10.926 10.046L13.83 6.58615C13.8568 6.5549 13.8702 6.51249 13.8702 6.47008Z"
          fill="#292D2D"
          fillOpacity="0.25"
        />
        <path
          d="M10 0C4.47768 0 0 4.47768 0 10C0 15.5223 4.47768 20 10 20C15.5223 20 20 15.5223 20 10C20 4.47768 15.5223 0 10 0ZM10 18.3036C5.41518 18.3036 1.69643 14.5848 1.69643 10C1.69643 5.41518 5.41518 1.69643 10 1.69643C14.5848 1.69643 18.3036 5.41518 18.3036 10C18.3036 14.5848 14.5848 18.3036 10 18.3036Z"
          fill="#292D2D"
          fillOpacity="0.25"
        />
      </g>
      <defs>
        <clipPath id="clip0_25779_15094">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
