import { SVGProps } from 'react';

type IProps = SVGProps<SVGSVGElement>;

export const SetSquareSvgIcon = (props: IProps) => {
  const { ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" {...restProps}>
      <path
        d="M18.2 17.7L17.1 18.8L16.4 18L17.5 17L15.5 15L14.5 16.1L13.8 15.4L14.9 14.3L13 12.4L11.9 13.5L11.2 12.8L12.3 11.7L10.3 9.8L9.2 10.9L8.5 10.2L9.5 9L7.6 7.1L6.5 8.1L5.8 7.4L6.9 6.3L4.5 4V20H20.5L18.2 17.7ZM7.5 17V11.2L13.3 17H7.5Z"
        fill="#ADC6FF"
      />
    </svg>
  );
};
