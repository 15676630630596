import { RollbarTestComponentError } from "../RollbarTestComponentError";
import { Layout, Row } from "antd";
import { MainHeader } from "@components/Layout/HeaderLayout/MainHeader";
import { Content } from "antd/es/layout/layout";
import { usePageContentSize } from "@HOOKs/UsePageContentSize";

export const TestErrorPage = () => {

    const [layoutRef]= usePageContentSize()

    return (
        <Layout >
          <MainHeader />
          <Content ref={layoutRef} >
            <Row justify={"center"} className="mt-40 w-full">
                <RollbarTestComponentError />
            </Row>
          </Content>
      </Layout>
    )
}
