import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getDisciplines } from "@store/slices/discipline";
import { Card, Switch, theme } from "antd";
import useVisible from "@HOOKs/UseVisible";
import { IDiscipline } from "@models/discipline.model";
import { EditOutlined } from "@ant-design/icons";
import { NEUTRAL_7_COLOR } from "@providers/ThemeProvider";
import { DisciplineEdit } from "./DisciplineEdit";
import "@styles/components/admin-item-card.less";

const ListItem = (discipline: IDiscipline) => {
  return <li style={{ color: NEUTRAL_7_COLOR }} className="pb-4">{discipline.name}</li>
}

interface IListItemEditableProps {
  discipline: IDiscipline;
  disciplineSelected: IDiscipline;
  editDisciplineClicked: any;
}

const ListItemEditable = (props: IListItemEditableProps) => {

  const { discipline, disciplineSelected, editDisciplineClicked } = props;

  return <div className={`list-item-editable ${(disciplineSelected?.id === discipline.id ? 'selected' : '')}`} onClick={() => editDisciplineClicked(discipline)}>
    <div>
      <div className="handle-container">
        <div className="handle-dot"></div>
      </div>
    </div>
    <div className="description">
      {discipline.name}
    </div>
    <span className="edit-container">
      <span className="edit-link">
        <EditOutlined /> Edit
      </span>
    </span>
  </div>
}

export const DisciplineCard = (props: any) => {

  const dispatch = useAppDispatch();

  const { token: { colorPrimary, colorTextDisabled } } = theme.useToken();

  const loadDisciplines = () => {
    dispatch(
      getDisciplines()
    );
  }

  useEffect(() => {
    loadDisciplines();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { entities: disciplines } = useAppSelector((state) => state.Discipline);

  const [openEditDiscipline, toogleEditDiscipline] = useVisible();
  const [disciplineSelected, setDisciplineSelected] = useState<IDiscipline>({})
  const [isEditing, setIsEditing] = useState<boolean>(false)

  useEffect(() => {
    if (!openEditDiscipline) {
      setDisciplineSelected({})  
    }
  }, [openEditDiscipline]);

  const onModifiedChanges = (discipline: IDiscipline) => {
    loadDisciplines();
  }

  const toggleEdit = () => {
    setIsEditing(!isEditing)
  };

  const editDiscipline = (discipline : IDiscipline) => {
    setDisciplineSelected(discipline)
    toogleEditDiscipline()
  }

  const createNewDiscipline = () => {
    setDisciplineSelected({})
    toogleEditDiscipline();
  }
  
  return (
    <>
      <DisciplineEdit open={openEditDiscipline} toogle={toogleEditDiscipline} discipline={disciplineSelected} onModifiedChanges={onModifiedChanges} />
      <Card title={<>Discipline<span className="item-count">{disciplines?.length}</span></>} bordered={false} className="admin-item-card" extra={<div><span className="extra-label" style={{ color: (isEditing ? colorPrimary : colorTextDisabled) }} onClick={toggleEdit}>{isEditing ? 'Managing' : 'Manage'}</span><Switch checked={isEditing} onClick={toggleEdit} /></div>}>
        <ul className={isEditing ? 'editing' : ''}>
            {disciplines.map(discipline => 
            <div
                key={discipline.id}>
                <>
                {isEditing && <ListItemEditable discipline={discipline} disciplineSelected={disciplineSelected} editDisciplineClicked={editDiscipline} />}
                {!isEditing && <ListItem {...discipline} />}
                </>
            </div>
            )}
        </ul>
        {isEditing && <>
            <div className="spacer"></div>
            <span onClick={createNewDiscipline} className="add-new-btn">+ Add New Discipline</span>
        </>}
        </Card>
    </>
  )
}