import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import { Alert, Button, Card, Checkbox, Col, Divider, Form, Input, Layout, Row, theme } from "antd";
import { UserOutlined, LockOutlined, WindowsOutlined, GoogleOutlined } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";

import { Authenticate } from "../../services/auth.service";
import { Logo } from "../Logo/Logo";
import "./Login.less";
import { useAuth } from "../../providers/AuthProvider";
import {
  configuration,
  isEnabledSsoMicrosoft,
  isEnabledSsoGoogle,
  isEnabledSsoJumpcloud,
  isEnabledSsoOnelogin,
  isEnabledSsoOkta,
} from '../../environments/env';
import { SITERISE_BG_WHITE_COLOR } from '@providers/ThemeProvider';
import { JumpCloudSvgIcon } from '@components/Icons/JumpCloudSvgIcon';
import { OneLoginSvgIcon } from '@components/Icons/OneLoginSvgIcon';
import { OktaSvgIcon } from '@components/Icons/OktaSvgIcon';

const isSSODisabled = (): boolean => {
  return !isEnabledSsoMicrosoft() && !isEnabledSsoGoogle() && !isEnabledSsoJumpcloud() && !isEnabledSsoOnelogin() && !isEnabledSsoOkta();
};

export const Login: React.FC<any> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorSSODeactivated, setErrorSSODeactivated] = useState(false);
  const [errorSSONotPresent, setErrorSSONotPresent] = useState(false);

  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [animateError, setAnimateError] = useState(false);
  const { checkLoginLocally, getUserData } = useAuth();
  const {
    token: { colorText, fontSize },
  } = theme.useToken();
  const { t } = useTranslation();

  useEffect(() => {
    checkLoginLocally(); // if works, will redirect to home = /dashboard

    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has('notPresent')) {
      setErrorSSONotPresent(true);
    }
    if (searchParams.has('deactivated')) {
      setErrorSSODeactivated(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const playAnimationOfError = () => {
    setAnimateError(true);
    setTimeout(() => {
      setAnimateError(false);
    }, 500);
  };

  const onFinish = (_user: any): void => {
    setIsLoading(true);

    Authenticate(_user)
      .then(async data => {
        // data is not beign used because we're using cookies
        return await getUserData(); // if works will redirect to /location
      })
      .catch((error: any) => {
        setIsError(true);
        playAnimationOfError();
        setError(t('login.usernameOrPasswordIncorrect'));
      })
      .finally((): void => setIsLoading(false));
  };

  const onFinishFailed = (errorInfo: any) => {
    playAnimationOfError();
  };

  const getSsoUrl = (): string => {
    return configuration.API_URL.replace('api/', 'oauth2/authorization/oidc');
  };

  return (
    <div className="login">
      <Layout className="h-screen">
        <Content style={{ backgroundColor: SITERISE_BG_WHITE_COLOR }}>
          <div className={`login-container-card ${animateError ? 'error-animation' : ''}`} style={{ borderRadius: 4 }}>
            <Card style={{ width: 350, borderRadius: 4 }}>
              <Row justify="center">
                <Col className="text-center">
                  {configuration.LOGO_LOGIN_CARD ? (
                    <img className="login-logo" src={configuration.LOGO_LOGIN_CARD} alt="" />
                  ) : (
                    <Logo classStyle="login-logo" isCollapsed={true} />
                  )}
                </Col>
              </Row>

              <Row justify="center" hidden={isEnabledSsoMicrosoft() === false}>
                <a href={getSsoUrl()} className={'w-full mb-5'}>
                  <Button type="primary" className="w-full">
                    <WindowsOutlined /> {t('login.signInUsing')} Microsoft SSO
                  </Button>
                </a>
              </Row>

              <Row justify="center" hidden={isEnabledSsoGoogle() === false}>
                <a href={getSsoUrl()} className={'w-full mb-5'}>
                  <Button type="primary" className="w-full">
                    <GoogleOutlined /> {t('login.signInUsing')} Google SSO
                  </Button>
                </a>
              </Row>

              <Row justify="center" hidden={isEnabledSsoJumpcloud() === false}>
                <a href={getSsoUrl()} className={'w-full mb-5'}>
                  <Button type="primary" className="w-full flex flex-row items-center justify-center">
                    <JumpCloudSvgIcon className="mr-10" /> {t('login.signInUsing')} JumpCloud SSO
                  </Button>
                </a>
              </Row>

              <Row justify="center" hidden={isEnabledSsoOnelogin() === false}>
                <a href={getSsoUrl()} className={'w-full mb-5'}>
                  <Button type="primary" className="w-full flex flex-row items-center justify-center">
                    <OneLoginSvgIcon className="mr-10" /> {t('login.signInUsing')} Onelogin SSO
                  </Button>
                </a>
              </Row>

              <Row justify="center" hidden={isEnabledSsoOkta() === false}>
                <a href={getSsoUrl()} className={'w-full mb-5'}>
                  <Button type="primary" className="w-full flex flex-row items-center justify-center">
                    <OktaSvgIcon className="mr-10" /> {t('login.signInUsing')} Okta SSO
                  </Button>
                </a>
              </Row>

              <Row justify="center" hidden={isSSODisabled()}>
                <Divider className="divider">{t('login.or')}</Divider>
              </Row>

              <Form
                name="formLogin"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ rememberMe: false }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row justify="space-between">
                  <Col span={24}>
                    <Form.Item label="" name="username" rules={[{ required: true, message: t('login.enterUsername') }]}>
                      <Input
                        className="login-username"
                        placeholder={t('login.username')}
                        autoComplete="username"
                        prefix={<UserOutlined />}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify="space-between">
                  <Col span={24}>
                    <Form.Item label="" name="password" rules={[{ required: true, message: t('login.enterPassword') }]}>
                      <Input.Password placeholder={t('login.password')} autoComplete="current-password" prefix={<LockOutlined />} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify="space-between">
                  <Col span={12}>
                    <Form.Item name="rememberMe" valuePropName="checked">
                      <Checkbox>
                        <span className="save-login-label">{t('login.saveLoginInfo')}</span>
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={10} offset={2} flex={0} hidden={true}>
                    <Button type="link">
                      <span className="forgot-password-label">{t('login.forgotPassword')}</span>
                    </Button>
                  </Col>
                </Row>

                <Form.Item className="mb-5">
                  <Button
                    id="submit_login"
                    type={isSSODisabled() ? 'primary' : 'default'}
                    htmlType="submit"
                    className="button-login"
                    loading={isLoading}
                  >
                    {t('login.logIn')}
                  </Button>
                </Form.Item>
              </Form>

              <Row justify="center">
                <Col className={'w-full mb-5'}>
                  {errorSSONotPresent && <Alert message={t('login.userNotFound')} type="error" />}
                  {errorSSODeactivated && <Alert message={t('login.userDeactivated')} type="error" />}
                </Col>
              </Row>

              {isError && <ErrorMessage message={error} />}
            </Card>
          </div>
          <div style={{ fontSize: fontSize - 2, textAlign: 'center' }} className={`login-container-card mt-40 pt-30`}>
            <i style={{ color: colorText }}>{t('login.siteRiseMessage')}</i>
          </div>

          <div className={`login-container-card mt-40`}>
            <img src={'/media/client/siterise/powered_by_siterise_dark.svg'} alt="" className="block" />
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export const ErrorMessage: React.FC<{ message: string }> = ({ message }) => {
  const { token: { colorErrorText } } = theme.useToken();
  return (
    <Row justify="center">
      <Col className="text-center text-xs mb-15 mt-20" style={{ color: colorErrorText }}>
        {message}
      </Col>
    </Row>
  );
};