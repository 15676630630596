import { loadGtag } from "@infrastructure/analytics/gtag";


export class AnalyticsService {
    
    constructor(private readonly measurementId: string) {}
    
    load() {
        // If it doesn't have any Key loaded
        if (!this.measurementId) return 
        
        // Call the loadGtag() function to load the gtag.js library
        loadGtag(this.measurementId);
    }

    isLoaded() {
        return Boolean(window?.dataLayer)
    }

    trackEvent(eventName: string, eventCategory: string) {

        // If it doesn't have any Library loadded
        if(!window['gtag']) return 
        
        // Send a custom event to Google Analytics
        window['gtag']('event', eventName, {
            'event_category': eventCategory
        });
    }
    
  }