import { EmptyLabel } from '@components/Utils/EmptyLabel';
import { ICurrency } from '@models/currency.model';
import { Col, Row, Tooltip, theme } from 'antd';

export interface ICostCellProps {
    cost?: string | null,
    currency?: ICurrency | undefined
}

export const CostCell = (props: ICostCellProps) => {

    const { cost, currency } = props;

    const { token: { colorTextTertiary } } = theme.useToken();

    const costFloat = cost ? parseFloat(cost) : undefined;

    return (<>
        {cost ?
            <Tooltip title={`${costFloat} ${currency?.name ?? ''}`}>
                <Row>
                    <Col span={3} className="font-bold" style={{ color: colorTextTertiary }}>{currency?.symbol}</Col>
                    <Col span={21} style={{ fontFamily: "'Roboto Mono', monospace" }}>{costFloat!.toFixed(2)}</Col>
                </Row>
            </Tooltip>
            : <EmptyLabel />
        }
    </>
    );
}