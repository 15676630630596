/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import { IProjectTemplate } from "@models/project-template-model";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getTemplateDeliverables, duplicateProjectTemplate as duplicateTemplate } from "@store/slices/project-templates-extended";
import { Badge, Button, Card, Col, Drawer, Row, Space, Tag, theme } from "antd";
import { ITemplateDeliverable } from "@models/template-deliverable.model";
import { useNavigate } from "react-router-dom";
import { KeySvgIcon } from '@components/Icons/KeyIcon';
import SvgIconWrapper from '@shared/components/SvgIconWrapper';
interface IDeliverableEditProps {
    open: boolean,
    toogle: () => void,
    projectTemplate: IProjectTemplate
}

const IconKeyMilestoneInDeliverableCard = () => {
    const { token: { fontSizeSM, colorTextTertiary } } = theme.useToken();
    return (
        <SvgIconWrapper backgroundColor={colorTextTertiary}  >
            <KeySvgIcon fill="red" style={{ color: '#fff', fontSize: fontSizeSM }} />
        </SvgIconWrapper>
    )
}

export const TemplateDetailsPanel = (props: IDeliverableEditProps) => {

    const { open, toogle, projectTemplate } = props;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { token: {
        colorBorderSecondary,
        colorPrimary,
        colorFillQuaternary,
        colorText,
        colorTextSecondary,
        colorTextTertiary,
        colorFillTertiary } } = theme.useToken();

    const loadTemplateDeliverables = () => {
        if (projectTemplate?.id) {
            dispatch(getTemplateDeliverables(String(projectTemplate?.id)));
        }
    }

    useEffect(() => {
        loadTemplateDeliverables();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectTemplate]);

    const duplicateProjectTemplate = () => {
        if (projectTemplate?.id) {
            dispatch(duplicateTemplate(String(projectTemplate?.id)));
            onClose();
        }
    }

    const { deliverablesList } = useAppSelector((state) => state.ProjectTemplatesExtended);

    const deliverablesSorted = useMemo(() => {
        return [...deliverablesList].sort((a: ITemplateDeliverable, b: ITemplateDeliverable) => (a?.order || 0) - (b?.order || 0));
    }, [deliverablesList]);

    const onClose = () => {
        toogle();
    }

    const navigateToEdit = () => {
        if (projectTemplate) {
            navigate(`/admin/module-configuration/project-deliverables/templates/${projectTemplate?.id}`)
        }
    }

    return (
        <>
            <Drawer
                width={448}
                title={`${projectTemplate?.name}`}
                placement="right"
                onClose={onClose}
                open={open}
                closable={true}
                extra={
                    <Space>
                        <Button type="primary" ghost onClick={duplicateProjectTemplate}>
                            Duplicate
                        </Button>
                        <Button onClick={navigateToEdit} type="primary">
                            Edit
                        </Button>
                    </Space>
                }
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <div className="pr-8 pl-8 mb-16" style={{ color: colorTextSecondary, backgroundColor: colorFillTertiary, border: `1px solid ${colorBorderSecondary}`, borderRadius: '4px', lineHeight: '34px' }}>
                            <span style={{ fontWeight: 'bold' }}>Number Deliverables</span>
                            <Badge
                                count={deliverablesSorted.length}
                                className='ml-10'
                                style={{ backgroundColor: colorPrimary, borderColor: colorFillQuaternary }} />
                        </div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        {deliverablesSorted.map(deliverable => {
                            return <div className={'flex mb-12'}>
                                <div className={'flex pr-8'} style={{ flex: 0, justifyContent: 'center', alignItems: 'center' }}>
                                    <Badge count={deliverable?.order}
                                        style={{ backgroundColor: colorFillTertiary, color: colorTextTertiary, fontWeight: 500, fontSize: '14px', lineHeight: '22px' }} />
                                </div>
                                <Card style={{ borderRadius: '0.5rem', flex: 1, padding: 0 }}>
                                    <div style={{ lineHeight: '24px', fontWeight: 500, fontSize: '16px', color: colorText }}>
                                        {deliverable?.deliverable?.isKeyMilestone && <IconKeyMilestoneInDeliverableCard />}
                                        {deliverable?.deliverable?.name}
                                    </div>
                                    <div className={'flex mt-8'} style={{ gap: '0.25rem', color: colorTextTertiary, lineHeight: '20px', fontSize: '12px' }}><span style={{ fontWeight: 700 }}>Duration</span><span>{deliverable?.duration} days</span></div>
                                    {deliverable?.deliverable?.isOpeningMilestone && <Tag className={'mt-6'} color={'green'}>Opening Milestone</Tag>}
                                </Card>
                            </div>
                        })}
                    </Col>
                </Row>
            </Drawer>
        </>
    );
}
