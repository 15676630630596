import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getEntities, reorderProjectDeliverableReason } from "@store/slices/project-deliverable-reasons";
import { Card, Switch, Tag, theme } from "antd";
import useVisible from "@HOOKs/UseVisible";
import { EditOutlined } from "@ant-design/icons";
import { NEUTRAL_7_COLOR } from "@providers/ThemeProvider";
import { ProjectDeliverableReasonEdit } from "./ProjectDeliverableReasonEdit";
import "@styles/components/admin-item-card.less";
import { IProjectDeliverableReason } from "@models/project-deliverable-reason.model";
import { asyncLaunchNotification } from "@store/slices/notification";
import { DragHandle } from "@components/Icons/DragHandle";

export const ProjectDeliverableReasonsCard = (props: any) => {

  const dispatch = useAppDispatch();

  const { token: { colorPrimary, colorTextDisabled, colorBorderSecondary, colorBgBase, colorText } } = theme.useToken();

  const loadReasons = () => {
    dispatch(
      getEntities({})
    );
  }

  useEffect(() => {
    loadReasons();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [sortedProjectDeliverableReasons, setSortedProjectDeliverableReasons] = useState<IProjectDeliverableReason[]>([])

  const { entities: projectDeliverableReasons } = useAppSelector((state) => state.ProjectDeliverableReasons);

  useEffect(() => {
    setSortedProjectDeliverableReasons(JSON.parse(JSON.stringify(projectDeliverableReasons)));
  }, [projectDeliverableReasons]);

  const [openEditDiscipline, toogleEditDiscipline] = useVisible();
  const [projectDeliverableReasonSelected, setProjectDeliverableReasonSelected] = useState<IProjectDeliverableReason>({})
  const [isEditing, setIsEditing] = useState<boolean>(false)

  useEffect(() => {
    if (!openEditDiscipline) {
      setProjectDeliverableReasonSelected({})  
    }
  }, [openEditDiscipline]);

  const onModifiedChanges = (projectDeliverableReason: IProjectDeliverableReason) => {
    loadReasons();
  }

  const toggleEdit = () => {
    setIsEditing(!isEditing)
  };

  const editDiscipline = (projectDeliverableReason: IProjectDeliverableReason) => {
    setProjectDeliverableReasonSelected(projectDeliverableReason)
    toogleEditDiscipline()
  }

  const createNewDiscipline = () => {
    const orders = projectDeliverableReasons.map(x => Number(x.order))
    const maxOrder = Math.max(...orders)
    setProjectDeliverableReasonSelected({ active: true, order: maxOrder + 1, description: "" })
    toogleEditDiscipline();
  }

  const [draggedItem, setDraggedItem] = useState<IProjectDeliverableReason | null>(null);

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>, item: IProjectDeliverableReason) => {
    setDraggedItem(item);
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>, item: IProjectDeliverableReason) => {
    event.preventDefault();
    setSortedProjectDeliverableReasons((prevItems) => {
      const newItems = [...prevItems];
      const draggedItemIndex = prevItems.findIndex((i) => i.id === draggedItem?.id);
      const itemIndex = prevItems.findIndex((i) => i.id === item.id);
      newItems.splice(draggedItemIndex, 1);
      newItems.splice(itemIndex, 0, draggedItem!);
      return newItems;
    });
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
    const ids = sortedProjectDeliverableReasons.map(s => Number(s.id));
    if (sortedProjectDeliverableReasons.filter(s => Number(s.id)).length !== ids.length) {
      dispatch(asyncLaunchNotification({
        type: "error",
        config: {
            message: `Invalid IDs`,
            description: `IDs are invalid. Please refresh and try again.`
        }
      }));
      return false;
    }
    dispatch(reorderProjectDeliverableReason(ids));
  };

  const ListItem = (status: IProjectDeliverableReason) => {
    return <li style={{ color: NEUTRAL_7_COLOR, paddingBottom: '4px' }}>{status.description} {status.active && <Tag>Active</Tag>}</li>
  }
  
  const ListItemEditable = (status: IProjectDeliverableReason) => {
    return <div style={{ display: 'flex', backgroundColor: colorBgBase, border: `1px solid ${colorBorderSecondary}`, borderRadius: '4px', padding: '4px 8px', marginBottom: '4px' }}>
      <div>
        <div style={{ paddingTop: '4px', marginRight: '8px' }}><DragHandle /></div>
      </div>
      <div style={{ paddingTop: '3px', color: colorText }}>
        {status.description} {status.active && <Tag>Active</Tag>}
      </div>
      <span style={{ flex: '1', textAlign: 'right' }}>
        <span onClick={() => editDiscipline(status)} style={{ cursor: 'pointer', color: colorPrimary }}>
          <EditOutlined /> Edit
        </span>
      </span>
    </div>
  }
  
  return (
    <>
      <ProjectDeliverableReasonEdit 
        open={openEditDiscipline} 
        toogle={toogleEditDiscipline} 
        projectDeliverableReason={projectDeliverableReasonSelected} 
        onModifiedChanges={onModifiedChanges} />
      <Card title={<>Change Reason Options<span className="item-count">{projectDeliverableReasons?.length}</span></>} bordered={false} className="admin-item-card" extra={<div><span className="extra-label" style={{ color: (isEditing ? colorPrimary : colorTextDisabled) }} onClick={toggleEdit}>{isEditing ? 'Managing' : 'Manage'}</span><Switch checked={isEditing} onClick={toggleEdit} /></div>}>
        <ul style={{ paddingLeft: '15px' }}>
            {sortedProjectDeliverableReasons.map(status => 
            <div
                draggable={isEditing}
                onDragStart={(event) => handleDragStart(event, status)}
                onDragEnter={(event) => handleDragEnter(event, status)}
                onDragEnd={handleDragEnd} 
                key={status.id}>
                <>
                {isEditing && <ListItemEditable {...status} />}
                {!isEditing && <ListItem {...status} />}
                </>
            </div>
            )}
        </ul>
        {isEditing && <>
            <div className="spacer"></div>
            <span onClick={createNewDiscipline} className="add-new-btn">+ Add New Reason</span>
        </>}
        </Card>
    </>
  )
}