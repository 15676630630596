import { Button, ConfigProvider, theme } from "antd";
import React from "react";

interface ICustomButtonLinkProps extends React.ComponentPropsWithoutRef<typeof Button> {};

export const CustomButtonLink = ({ children, ...restProps }: ICustomButtonLinkProps) => {
    const { token: { colorPrimary, colorPrimaryActive, colorPrimaryTextHover } } = theme.useToken();
    return (
        <ConfigProvider 
            theme={{ 
                token: { 
                    colorLink: colorPrimary, 
                    colorLinkActive: colorPrimaryActive,
                    colorLinkHover: colorPrimaryTextHover
                }
            }} 
        >
            <Button 
                type="link" 
                {...restProps} 
            >
                {children}
            </Button>
        </ConfigProvider>
    )
}