import { IProjectCreationForm, IProjectTemplate, IRole, defaultTemplateValue, goToProject } from './../../models/project-template-model';
import { projectsService } from './../../services/projects.service';
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { serializeAxiosError } from "../reducer.util";
import { asyncLaunchNotification } from './notification';
import { reset } from './project-steps';
import { ITemplateDeliverable } from '@models/template-deliverable.model';
import { ITemplateDeliverableApproval } from '@models/template-deliverable-approval';


export interface IApplyTemplateToCreateAProject {
  siteId: string | number;
  body: IProjectCreationForm;
  goToProject: goToProject
}

export const getTemplates = createAsyncThunk(
  'project_templates_extended/fetch_templates',
  async (search: String, thunkAPI) => {
    return projectsService.getTemplates(search);
  },
  { serializeError: serializeAxiosError }
);

export const getTemplateById = createAsyncThunk(
  'groups/fetch_group_by_id',
  async (projectTemplateId: string, thunkAPI) => {
    const projectTemplate = await projectsService.getTemplateById(projectTemplateId);
    return projectTemplate;
  },
  { serializeError: serializeAxiosError }
);

export const getTemplatesRoles = createAsyncThunk(
  'project_templates_extended/fetch_template_roles',
  async (templateId: string | number, thunkAPI) => {
    return projectsService.getTemplatesRoles(templateId);
  },
  { serializeError: serializeAxiosError }
);

export const getTemplateDeliverables = createAsyncThunk(
  'project_templates_extended/fetch_template_deliverables',
  async (templateId: string | number, thunkAPI) => {
    return await projectsService.getTemplateDeliverables(templateId);
  },
  { serializeError: serializeAxiosError }
);

export const getTemplateDeliverableApprovals = createAsyncThunk(
  'project_templates_extended/fetch_template_deliverable_approvals',
  async (templateId: string | number, thunkAPI) => {
    return await projectsService.getTemplateDeliverableApprovals(templateId);
  },
  { serializeError: serializeAxiosError }
);

export const createProjectTemplate = createAsyncThunk(
  `project_templates_extended/create_entity`,
  async (goToProjectTemplate: any, thunkAPI) => {
    const result = await projectsService.createTemplate({ name: 'New Template' });
    thunkAPI.dispatch(asyncLaunchNotification({
      type: "success",
      config: {
        message: `Template`,
        description: `Template created successfully`
      }
    }));

    if (result?.data?.id) {
      goToProjectTemplate(result.data.id)
    }

    thunkAPI.dispatch(reset());
    
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const duplicateProjectTemplate = createAsyncThunk(
  'project_templates_extended/duplicate_template',
  async (templateId: string | number, thunkAPI) => {
    const response = await projectsService.duplicateTemplate(templateId);
    thunkAPI.dispatch(getTemplates(''));

    thunkAPI.dispatch(asyncLaunchNotification({
      type: "success",
      config: {
        message: `Project Template`,
        description: `Template duplicated successfully`
      }
    }));

    return response;
  },
  { serializeError: serializeAxiosError }
);

export const applyTemplateToCreateAProject = createAsyncThunk(
  'project_templates_extended/apply_template',
  async ({ siteId, body, goToProject }: IApplyTemplateToCreateAProject, thunkAPI) => {
    const result = await projectsService.applyTemplateToCreateAProject(siteId, body);

    thunkAPI.dispatch(asyncLaunchNotification({
      type: "success",
      config: {
        message: `Project Template`,
        description: `Template applied successfully`
      }
    }));

    if (result?.data?.id) {
      goToProject(result.data.id)
    }

    thunkAPI.dispatch(reset());
    
    return result;
  },
  { serializeError: serializeAxiosError }
);

interface IinitialState {
  templatesList: IProjectTemplate[],
  template: IProjectTemplate,
  rolesList: IRole[],
  deliverablesList: ITemplateDeliverable[],
  deliverableApprovalsList: ITemplateDeliverableApproval[],
  totalItems: number,
  loading: boolean
}
const initialState: IinitialState = {
  templatesList: [],
  template: defaultTemplateValue,
  rolesList: [],
  deliverablesList: [],
  deliverableApprovalsList: [],
  totalItems: 0,
  loading: false,
};

export const slice = createSlice({
  name: 'project_templates_extended',
  initialState,
  reducers: {
    clearCollections: (state,) => {
      return initialState
    }
  },
  extraReducers(builder) {
    builder
      //  getTemplates
      .addCase(getTemplates.fulfilled, (state, action) => {
        state.templatesList = action.payload.data;
      })

      //  getTemplatesRoles
      .addCase(getTemplatesRoles.fulfilled, (state, action) => {
        state.rolesList = action.payload.data;
      })

      //  getTemplatesRoles
      .addCase(applyTemplateToCreateAProject.fulfilled, (state, action) => {

      })

      //  getTemplatesDeliverables
      .addCase(getTemplateDeliverables.fulfilled, (state, action) => {
        state.deliverablesList = action.payload.data;
      })

      //  getTemplateDeliverableApprovals
      .addCase(getTemplateDeliverableApprovals.fulfilled, (state, action) => {
        state.deliverableApprovalsList = action.payload.data;
      })

      .addMatcher(isFulfilled(getTemplates), (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: data.length,
        };
      })

      .addMatcher(isPending(getTemplateById), state => {
        state.loading = true;
      })
      .addMatcher(isFulfilled(getTemplateById), (state, action) => {
        state.loading = false;
        state.template = action.payload.data as IProjectTemplate;
      });

  }
});

export const { clearCollections } = slice.actions;

// Reducer
export default slice.reducer;

