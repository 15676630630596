
import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { serializeAxiosError } from "../reducer.util";
import { createAsyncThunk } from "@reduxjs/toolkit"
import { projectDeliverableApprovalService } from '../../services/project-deliverable-approval.service';
import { IApproveDeliverableRequest, IProjectDeliverableApproval } from '@models/project-deliverable-approval';

const sliceName = "projectDeliverableApproval";
const service = projectDeliverableApprovalService;

export const partialUpdate = createAsyncThunk(
    `${sliceName}/partialUpdate`,
    async (partial: Partial<IProjectDeliverableApproval>, thunkAPI) => {
      const result = partial.id && await service.partialUpdate(partial.id, partial);
      return result;
    },
    { serializeError: serializeAxiosError }
);

export const approveDeliverable = createAsyncThunk(
  `${sliceName}/approveDeliverable`,
  async (request: IApproveDeliverableRequest , thunkAPI) => {
    const result = await service.approveDeliverable(request);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export interface IInitialState {
  loading: boolean,
  errorMessage: any
}

const initialState: IInitialState  = {
    loading: false,
    errorMessage: null
};

export const ProjectDeliverableApprovalSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
      reset: () => {
        return initialState;
      }
    },
    extraReducers(builder) {
      builder
        .addCase(partialUpdate.fulfilled, (state, action) => {
          state.errorMessage = null;
          state.loading = false;
          return state;
        })
        .addCase(approveDeliverable.fulfilled, (state, action) => {
          state.errorMessage = null;
          state.loading = false;
        })
        .addMatcher(isPending(partialUpdate, approveDeliverable), state => {
          state.errorMessage = null;
          state.loading = true;
        })
        .addMatcher(isRejected(partialUpdate, approveDeliverable), state => {
          state.errorMessage = null;
          state.loading = false;
        });
    }
  });
  
  export const { reset } = ProjectDeliverableApprovalSlice.actions;
  
  // Reducer
  export default ProjectDeliverableApprovalSlice.reducer;

  