import { If } from "@components/Utils/Structural";
import { Col, Row } from "antd";
import { DragZoneUploadMultiFiles } from "./DragZoneUploadMultiFiles";
import { AttachmentReferenceEnum } from "@models/enumerations/attachment-reference-enum.model";
import { useAppDispatch, useAppSelector } from "@store/store";
import { asyncLaunchNotification } from "@store/slices/notification";
import { updateTotalAttachmentOfAlbum } from "@store/slices/photo-album";
import { isNumber } from "@shared/util/number-util";
import { isStringOrNumber } from "@shared/util/validations";
import { countMultimediaFiles, getPhotosByReferenceTypeAndReferenceId, reset } from "@store/slices/generic-album-gallery-multimedia";
import { PAGINATION_SIZE_DEFAULT_IN_PHOTOS } from "@constants/core";

interface IDraggerZoneContainerProps {}

export const DraggerZoneContainer = (props: IDraggerZoneContainerProps) => {

    const dispatch = useAppDispatch();

    const { entity: album } = useAppSelector((store) => store.PhotoAlbum);
    
    const onMultiUploadProcessFinishCallback = () => {
        setTimeout(() => {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: `Photos uploaded successfully`,
                  description: `The thumbnails will become visible once our processing is complete.`
                }
            }));
        }, 500);

        if (isStringOrNumber(album?.id)) {
          dispatch(countMultimediaFiles({ referenceId: album.id!, referenceType: AttachmentReferenceEnum.PHOTO_ALBUM}))
            .unwrap()
            .then((totalItems) => {
              dispatch(reset())
              dispatch(getPhotosByReferenceTypeAndReferenceId({ referenceId: String(album?.id),
                referenceType: AttachmentReferenceEnum.PHOTO_ALBUM,
                page: 0,
                size: PAGINATION_SIZE_DEFAULT_IN_PHOTOS }
              ));
              dispatch(updateTotalAttachmentOfAlbum({ albumId: album.id, totalItems }));
            })
        } 
    }
    
    return (
      <>
       {/* Dragger Zone */}
       <Row>
          <Col span={24} style={{ paddingTop: 12, paddingRight: 16, paddingLeft: 16 }}>
            <If condition={isNumber(album?.id)}>
              <DragZoneUploadMultiFiles 
                entityType={AttachmentReferenceEnum.PHOTO_ALBUM}
                referenceId={album?.id!}
                onMultiUploadProcessFinishCallback={onMultiUploadProcessFinishCallback}
              />
            </If>
          </Col>
        </Row>
      </>
    )
}
