import { useChangeOrderTimelineContext } from "@components/Locations/Sites/Projects/ChangeOrdersTab/ChangeOrderTimelineContext";
import { IChangeOrderApprovalRequest } from "@models/change-order-approval-request";
import { getTimeline } from "@store/slices/change-order-timeline";
import { approve, getEntity as getChangeOrder } from '@store/slices/change-orders';
import { asyncLaunchNotification } from "@store/slices/notification";
import { useAppDispatch } from "@store/store";
import { Alert, Button, Col, Drawer, Form, Input, Row, Space, theme } from "antd";
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

interface IApproveProps {
    open: boolean
    toggle: () => void,
}

export const Approve = (props: IApproveProps) => {
    const { open, toggle } = props;
    const { token: { colorTextSecondary, colorBgLayout, colorBorder } } = theme.useToken();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [form] = Form.useForm();
    const changeOrderTimelineContext = useChangeOrderTimelineContext();

    const onDrawerClose = () => {
        toggle()
    }

    const onDrawerSubmit = () => {
        form.submit();
    }

    const handleApproveSuccess = () => {
        dispatch(asyncLaunchNotification({
            type: "success",
            config: {
                message: t("changeOrder.detailApprovedTitle"),
                description: t("changeOrder.detailApprovedDescription")
            }
        }));
        dispatch(getChangeOrder(changeOrderTimelineContext?.changeOrderId!));
        dispatch(getTimeline(changeOrderTimelineContext?.changeOrderId!));
        toggle();
    }

    const onFinish = (values: any): void => {
        if (changeOrderTimelineContext?.changeOrderId!) {
            const approvalRequest: IChangeOrderApprovalRequest = {
                comment: values.comment
            }
            dispatch(approve({ id: changeOrderTimelineContext?.changeOrderId!, approvalRequest: approvalRequest, onSuccess: handleApproveSuccess }));
        }
    };

    return (
        <Drawer
            title={t("changeOrder.detailEstimateTitle")}
            width={400}
            onClose={onDrawerClose}
            open={open}
            bodyStyle={{ paddingBottom: 80 }}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="comment"
                            label={t("changeOrder.commentLabel")}
                        >
                            <TextArea rows={3} maxLength={200} placeholder={t("changeOrder.commentPlaceholder")} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24} className="text-center">
                        <Alert
                            message={<div style={{ color: colorTextSecondary }}><div>{t("changeOrder.approveDetailAlertPart1")}</div><div>{t("changeOrder.approveDetailAlertPart2")}</div></div>}
                            type="info"
                            style={{ backgroundColor: colorBgLayout, borderColor: colorBorder }}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24} className="text-right pt-30">
                        <Space>
                            <Button type="default" onClick={onDrawerClose}>
                                {t("generic.cancel")}
                            </Button>
                            <Button onClick={onDrawerSubmit} type="primary">
                                {t("changeOrder.approveDetailButton")}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
}