/* eslint-disable react-hooks/exhaustive-deps */
import { TabContainer } from "@components/Utils/TabContainer";
import { IChangeOrderItem } from "@models/change-order-item.model";
import { ICostCategory } from "@models/cost-category.model";
import { changeOrderCostGroupKey } from "@models/setting.model";
import { costCategoryService } from "@services/cost-category.service";
import { settingsService } from "@services/settings.service";
import { getEntity as getChangeOrder, submitDetail } from "@store/slices/change-orders";
import { asyncLaunchNotification } from "@store/slices/notification";
import { useAppDispatch, useAppSelector } from "@store/store";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BackHeader } from "../shared/BackHeader";
import { ChangeOrderCostsForm } from "./ChangeOrderCostsForm";
import { CostGroupingNotSet } from "./CostGroupingNotSet";

export const ChangeOrderCostsCreate = () => {
    const { coId } = useParams<"coId">();
    const { entity: changeOrderEntity } = useAppSelector((state) => state.ChangeOrder);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [costCategories, setCostCategories] = useState<ICostCategory[]>([])
    const [costGroupingNotSet, setCostGroupingNotSet] = useState<boolean>(false)

    useEffect(() => {
        if (coId) {
            dispatch(getChangeOrder(coId))
        }
    }, [coId])

    const getCostCategories = (costGroupingId: number) => {
        costCategoryService.getActiveCostCategories(costGroupingId).then((response) => {
            setCostCategories(response.data)
        }).catch((error) => {
            onErrorDefault();
        });
    }

    useEffect(() => {
        if (coId) {
            settingsService.getOneSettingByKey(changeOrderCostGroupKey).then((settingResponse) => {
                if (settingResponse.data.value !== undefined && settingResponse.data.value !== null && settingResponse.data.value.trim().length > 0) {
                    getCostCategories(Number(settingResponse.data.value));
                } else {
                    setCostGroupingNotSet(true);
                }
            }).catch((error) => {
                onErrorDefault();
            });
        }
    }, [coId])

    const onCreateItemsSuccess = () => {
        dispatch(asyncLaunchNotification({
            type: "success",
            config: {
                message: t("changeOrder.costsDetail.detailSentTitle"),
                description: t("changeOrder.costsDetail.detailSentSubtitle")
            }
        }));
        navigateBack();
    }

    const handleFinish = (items: IChangeOrderItem[]) => {
        dispatch(submitDetail({ id: Number(coId), items: items, onSuccess: onCreateItemsSuccess }));
    };

    const onErrorDefault = () => {
        dispatch(asyncLaunchNotification({
            type: "error",
            config: {
                message: t("changeOrder.changeOrder"),
                description: t("generic.defaultError")
            }
        }));
    }

    const navigateBack = () => {
        const newPath = pathname.substring(0, pathname.lastIndexOf('/'));
        navigate(newPath);
    }

    return (
        <TabContainer>
            <BackHeader label={t("changeOrder.changeOrder") + " #" + changeOrderEntity?.number} />
            {costGroupingNotSet ?
                <CostGroupingNotSet />
                :
                <ChangeOrderCostsForm
                    operation="CREATE"
                    changeOrder={changeOrderEntity}
                    costCategories={costCategories}
                    onFinish={handleFinish}
                    loading={false}
                />
            }
        </TabContainer>
    );
}