import { theme } from "antd";

export enum THEME_BADGE_ENUM {
    THEME_SMALL = "THEME_SMALL",
    THEME_MEDIUM = "THEME_MEDIUM"
}
interface ICategoryBadgeProps {
    category: React.ReactElement | string,
    themeUI: keyof typeof THEME_BADGE_ENUM
}

export const CategoryBadge = (props: ICategoryBadgeProps) => {

    const { category, themeUI } = props;

    const { token: { colorPrimaryBg, colorPrimaryActive, colorPrimaryBorder, colorPrimary, fontSizeSM, fontSize } } = theme.useToken();

    const THEME_BADGE_SMALL = {
        borderColor: colorPrimaryBorder,
        background: colorPrimaryBg,
        color: colorPrimary
    }

    const THEME_BADGE_MEDIUM = {
        borderColor: "transparent",
        background: colorPrimaryBg,
        color: colorPrimaryActive
    }
    
    return (
        <div
            className={`
                ${themeUI === THEME_BADGE_ENUM.THEME_MEDIUM ? 'pt-4 pb-4 pl-10 pr-10' : 'pt-2 pb-2 pl-8 pr-8'}
            `}
            style={{
                border: themeUI === THEME_BADGE_ENUM.THEME_MEDIUM ? `transparent` : `2px solid ${THEME_BADGE_SMALL.borderColor}`,
                borderRadius: 4,
                background: colorPrimaryBg,
            }}
        >
            <span
                className="flex"
                style={{
                    color: themeUI === THEME_BADGE_ENUM.THEME_MEDIUM ? THEME_BADGE_MEDIUM.color : THEME_BADGE_SMALL.color,
                    fontSize: themeUI === THEME_BADGE_ENUM.THEME_MEDIUM ? fontSize : fontSizeSM,
                    fontWeight: themeUI === THEME_BADGE_ENUM.THEME_MEDIUM ? 500 : 400,
                    lineHeight: themeUI === THEME_BADGE_ENUM.THEME_MEDIUM ? "22px" : "20px",
                    verticalAlign: "middle"
                }}
            >
                {category}
            </span>
        </div>
    )
}
