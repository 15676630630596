import { FC } from "react";

import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { UniqueIdentifier, useDroppable } from "@dnd-kit/core";
import { theme } from "antd";

import { TemplateDeliverable } from './TemplateDeliverable';
import { ITemplateDeliverableEdit } from "./TemplateEdit";
import { IPhase } from "@models/phase.model";
import { IUser } from "@models/user.model";
import { IRole } from "@models/role.model";
import { ITemplateDeliverableApproval } from "@models/template-deliverable-approval";

export interface ItemType { id: UniqueIdentifier, color: string }

interface MilestoneTableProps {
    templateDeliverables: ITemplateDeliverableEdit[]
    phases: readonly IPhase[]
    width?: string;
    onRemove(id: any): any
    onChangeValue(id: any, field: string, value: any): any;
    allUsers: readonly IUser[];
    allRoles: readonly IRole[];
    updateApprovals(deliverableId: string, approvals: ITemplateDeliverableApproval[]): any;
}

export const MilestoneTable: FC<MilestoneTableProps> = ({ templateDeliverables, width, onRemove, phases, onChangeValue, allUsers, allRoles, updateApprovals }) => {

    const { token: {  colorBgContainer} } = theme.useToken();

  const { setNodeRef } = useDroppable({ id: "pallette", })

  return <SortableContext items={templateDeliverables.map((deliverable) => `${deliverable.id}`)} strategy={rectSortingStrategy}>
    <ul style={{ minWidth: '500px', minHeight: '200px', padding: '0px 16px 20px', listStyleType: 'none', background: colorBgContainer }} ref={setNodeRef}>
      {templateDeliverables.map((d) => <div key={d.id}>
        <TemplateDeliverable 
            templateDeliverableEdit={d} 
            id={`${d.id}`} 
            onRemove={onRemove}
            onChangeValue={onChangeValue}
            templateDeliverables={templateDeliverables}
            phases={phases}
            allUsers={allUsers}
            allRoles={allRoles}
            updateApprovals={updateApprovals}
            initiallyExpanded={d.initiallyExpanded} />
      </div>)}
    </ul>
  </SortableContext>
}