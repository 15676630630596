import { http } from '../http/axios.instance';
import { IAttachment, IAttachmentGetRequestParams } from '@models/attachment.model';
import { StringORNumber } from './utils.repository';
import { PAGINATION_SIZE_DEFAULT_IN_PHOTOS } from '@constants/core';

export const photoRepository = {
  
  getPhotosByReferenceTypeAndReferenceId: async (referenceType: string, referenceId: StringORNumber, queryParams: IAttachmentGetRequestParams) => {
    const { page = 0, size = PAGINATION_SIZE_DEFAULT_IN_PHOTOS } = queryParams;
    const requestUrl = `/attachments/${referenceType}/${referenceId}?page=${page}&size=${size}`;
    return await http.get<IAttachment[]>(requestUrl);
  },
  
  count: async ({ referenceType, referenceId }: { referenceType: string, referenceId: StringORNumber }) => {
    const requestUrl = `/attachments/count?referenceType.equals=${referenceType}&referenceId.equals=${referenceId}`;
    const promiseGetCount = await http.get<number>(requestUrl);
    return promiseGetCount.data;
  },
}