import { CustomButtonText } from "@components/Utils/CustomButtonText";
import { LOCATION_PATH } from "@constants/core";
import { useAppSelector } from "@store/store";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const LocationEditDetailsButton = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { entity: locationEntity } = useAppSelector((state) => state.Location);
   
    const openLocationEdit = () => {
        if (locationEntity?.id) {
            navigate(`${LOCATION_PATH}/${locationEntity.id}/edit`)
        }
    }

    return (
        <>
            <CustomButtonText onClick={openLocationEdit} className="w-full">
                {t("location.editLocationDetails")}
            </CustomButtonText>
        </>
    )
}
