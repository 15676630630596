// Collapse

import { RightOutlined } from "@ant-design/icons";
import { Collapse, theme } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface ICustomCollapseViewDetailsPros {
    contentExpanded: React.ReactNode
}

export const CustomCollapseViewDetails = (props: ICustomCollapseViewDetailsPros) => {
    const { contentExpanded } = props;
    const { token: { colorPrimary, colorPrimaryHover } } = theme.useToken();
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false)

    const collapseHeader = (
        <div style={{ color: isOpen ? colorPrimary : colorPrimaryHover }}>
            {t("audit.viewHistory")}
        </div>
    )

    const onChangeCollapse = (key: string | string[]) => {
        const isCollapseOpened = !key.includes('0');
        setIsOpen(isCollapseOpened);
    }

    return (
        <Collapse 
            defaultActiveKey={['0']} 
            ghost 
            size="small"
            onChange={onChangeCollapse}
            style={{ paddingLeft: 0, marginLeft: 0 }}
            expandIcon={({ style, isActive }) => <CustomIconArrowCollapse style={style} isActive={isActive} />}
            items={[{
                key: '0',
                label: collapseHeader,
                children: (contentExpanded)
            }]} 
        />
    )
}

const CustomIconArrowCollapse = (props: {isActive?: boolean, style?: React.CSSProperties }) => {
    const { token: { motionDurationMid, motionEaseInOut, colorPrimary } } = theme.useToken();
    return <RightOutlined 
        style={{
            color: colorPrimary,
            transform: props?.isActive ? "rotate(90deg)" : "rotate(0deg)", 
            transition: `all ${motionDurationMid} ${motionEaseInOut}` 
        }}/>
}

export const CustomCollapseConfigProps = {
    padding: 4,
    marginSM: 4,
    paddingLG: 4,
    paddingSM: 4,
    paddingXS: 4
}