import { isArrayWithValues } from "@shared/util/array-util";
import { useMatches } from "react-router-dom";

export const MobileNavigation = () => {

  let matches = useMatches();

  let crumbs = matches
    .filter((match: any) => Boolean(match.handle?.mobile));

    if (isArrayWithValues(crumbs)) {

      const lastMobileNavigation = crumbs.pop() || {};
      const { handle = {} } = lastMobileNavigation as { handle: any};
      const { mobile = () => <></> } = handle;
      const mobileHandler = mobile as () => React.ReactNode
      
      return <>{mobileHandler()}</>;
    }

    return null;
};
