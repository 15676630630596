import { http } from '@infrastructure/http/axios.instance';

import {
  IBudget,
  IBudgetApprovalAction,
  IBudgetApprover,
  IBudgetCreateRequest,
  IBudgetExtended,
  IBudgetRevision,
  IBudgetSummary,
} from '@models/budget.model';
import { DEFAULT_BUDGET_EXPORTED_FILENAME_EXCEL, DEFAULT_BUDGET_EXPORTED_FILENAME_PDF } from '@constants/core';
import { StringORNumber } from './utils.repository';

const path = 'budgets-extended';

export const budgetExtendedRepository = {
  getLatestByProjectId: async (projectId: StringORNumber): Promise<IBudgetExtended> => {
    const requestUrl = `${path}/by-project/${projectId}`;
    const response = await http.get<IBudgetExtended>(requestUrl);
    return response.data;
  },
  createByTemplate: async (data: IBudgetCreateRequest): Promise<IBudget> => {
    const requestUrl = `${path}/by-template`;
    const response = await http.post<IBudget>(requestUrl, data);
    return response.data;
  },
  getBudgetSummary: async (budgetId: StringORNumber): Promise<IBudgetSummary> => {
    const requestUrl = `${path}/${budgetId}/budget-summary`;
    const response = await http.get<IBudgetSummary>(requestUrl);
    return response.data;
  },
  submitForApproval: async (budgetId: StringORNumber): Promise<string> => {
    const requestUrl = `${path}/${budgetId}/submit-for-approval`;
    const response = await http.post(requestUrl);
    return response.data;
  },
  getBudgetApprovers: async (budgetId: StringORNumber): Promise<IBudgetApprover[]> => {
    const requestUrl = `${path}/${budgetId}/approvers`;
    const response = await http.get<IBudgetApprover[]>(requestUrl);
    return response.data;
  },
  handleApproval: async ({ budgetId, approved }: IBudgetApprovalAction): Promise<void> => {
    const requestUrl = `${path}/${budgetId}/approval`;
    const response = await http.post(requestUrl, { approved });
    return response.data;
  },
  exportToExcel: async (budgetId: StringORNumber): Promise<any> => {
    const requestUrl = `${path}/${budgetId}/export-excel`;
    const response = await http.get(requestUrl, {
      responseType: 'blob',
    });
  

    const contentDisposition = response.headers['content-disposition'];

    const fileName = contentDisposition
      ? getFileNameFromContentDisposition(contentDisposition, DEFAULT_BUDGET_EXPORTED_FILENAME_EXCEL)
      : DEFAULT_BUDGET_EXPORTED_FILENAME_EXCEL;

    return { blob: response.data, fileName };
  },
  exportToPdf: async (budgetId: StringORNumber): Promise<any> => {
    const requestUrl = `${path}/${budgetId}/export-pdf`;
    const response = await http.get(requestUrl, {
      responseType: 'blob',
    });

    const contentDisposition = response.headers['content-disposition'];

    const fileName = contentDisposition
      ? getFileNameFromContentDisposition(contentDisposition, DEFAULT_BUDGET_EXPORTED_FILENAME_PDF)
      : DEFAULT_BUDGET_EXPORTED_FILENAME_PDF;

    return { blob: response.data, fileName };
  },
  createRevision: async (budgetId: StringORNumber): Promise<IBudget> => {
    const requestUrl = `${path}/${budgetId}/revisions`;
    const response = await http.post<IBudget>(requestUrl);
    return response.data;
  },
  getAllByProjectId: async (projectId: StringORNumber): Promise<IBudgetRevision[]> => {
    const requestUrl = `${path}/all-by-project/${projectId}`;
    const response = await http.get<IBudgetRevision[]>(requestUrl);
    return response.data;
  },
  getOneByProjectId: async (projectId: StringORNumber, budgetId: StringORNumber): Promise<IBudgetExtended> => {
    const requestUrl = `${path}/by-project/${projectId}/${budgetId}`;
    const response = await http.get<IBudgetExtended>(requestUrl);
    return response.data;
  },
};

// Utility function to extract the file name from Content-Disposition header
export const getFileNameFromContentDisposition = (header: any, defaultName: string) => {
  const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = fileNameRegex.exec(header);
  return matches != null && matches[1] ? matches[1].replace(/['"]/g, '') : defaultName;
};
