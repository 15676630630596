import { useEffect, useState } from 'react';
import { useAppSelector } from '@store/store';
import { Button, Col, Drawer, Popconfirm, Row, Space, theme } from 'antd';
import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { CompanyGenericUpdate } from './CompanyBindingUpdate';
import { deleteEntity } from '@store/slices/company-binding';
import { CompanyReferenceEnumListOptions } from '@models/enumerations/company-reference-enum.model';
import useVisible from '@HOOKs/UseVisible';
import { CompanyGenericCreate } from './CompanyBindingCreate';
import { If } from '@components/Utils/Structural';
import { useTranslation } from 'react-i18next';
import { ICompanyBinding } from '@models/company-binding.model';
import { useAppDispatch } from '@store/store';
import { pascalCaseFirstWord } from '@shared/util/string-utils';
import { getCompanies, getCompaniesFullList } from '@store/slices/generic-company';
import { IQueryParams } from '@models/pagination';

export interface ICompanyManageProps extends IQueryParams {
  open: boolean;
  toggle: () => void;
  referenceId?: StringORNumber;
  referenceType: CompanyReferenceEnumListOptions;
}

type IAction = () => void;

export const CompanyGenericManageList = (props: ICompanyManageProps) => {
  const { open, toggle, referenceId, referenceType, page, size, sort } = props;
  const { t } = useTranslation();
  const {
    token: { colorText, colorPrimary, colorFillTertiary, colorFillQuaternary },
  } = theme.useToken();

  const dispatch = useAppDispatch();

  const [visibleCompanyCreateDrawer, toggleVisibleCompanyCreateDrawer] = useVisible();
  const [visibleCompanyEditDrawer, toggleVisibleCompanyEditDrawer] = useVisible();

  const [companySelected, setCompanySelected] = useState<ICompanyBinding | null>(null);

  const { dataFullList: companiesList } = useAppSelector(state => state.GenericCompany[referenceType]);

  const selectAction = (company: ICompanyBinding, action: IAction) => {
    setCompanySelected(company);
    action();
  };

  const deleteCompany = (company: ICompanyBinding) => {
    dispatch(deleteEntity(String(company?.id))).then(() => {
      if (referenceId && referenceType) {
        dispatch(getCompanies({ referenceId, referenceType, page, size, sort }));
        dispatch(getCompaniesFullList({ referenceId, referenceType }));
      }
    });
  };

  useEffect(() => {
    referenceId !== undefined && dispatch(getCompaniesFullList({ referenceId, referenceType }));
  }, [dispatch, referenceId, referenceType]);

  return (
    <>
      <Drawer
        title={`Manage Companies`}
        width={500}
        onClose={toggle}
        open={open}
        closable={false}
        styles={{
          body: { paddingBottom: 80 },
        }}
        extra={
          <Space>
            <Button type="primary" ghost onClick={toggle}>
              {t('generic.cancel')}
            </Button>
            <Button type="primary" onClick={toggleVisibleCompanyCreateDrawer}>
              {t('generic.add')}
            </Button>
          </Space>
        }
      >
        {companiesList.map((company: ICompanyBinding) => (
          <div
            key={company.id}
            className="pt-3 pb-3 mb-3"
            style={{
              border: `1px solid ${colorFillTertiary}`,
              backgroundColor: colorFillQuaternary,
              borderRadius: '4px',
              padding: '8px 16px 8px 16px',
              cursor: 'pointer',
              marginBottom: '8px',
            }}
          >
            <Row justify={'space-between'} align={'middle'} key={company?.company?.id} className="mb-10 mt-10" style={{ color: colorText }}>
              <Col flex="1">
                <div className="flex flex-col">
                  <span className="font-bold pb-5">{company?.company?.name}</span>
                  <span>{company?.companyRole?.description}</span>
                  <If condition={Boolean(company?.companyContact)}>
                    <span>{`${company?.companyContact?.firstName} ${company?.companyContact?.lastName}`}</span>
                  </If>
                </div>
              </Col>
              <Col flex={'none'}>
                <Row>
                  <Popconfirm
                    placement="bottomRight"
                    title={
                      <>
                        Are you sure to unlink this
                        <br />
                        company from this {pascalCaseFirstWord(referenceType)}
                      </>
                    }
                    okText="Accept and Unlink"
                    onConfirm={() => deleteCompany(company)}
                    cancelText="Cancel"
                    okButtonProps={{ danger: true }}
                    icon={false}
                  >
                    <Button type="text" danger>
                      <span>{'Unlink'}</span>
                    </Button>
                  </Popconfirm>
                  <Button type="link" style={{ color: colorPrimary }} onClick={() => selectAction(company, toggleVisibleCompanyEditDrawer)}>
                    <span>{t('generic.edit')}</span>
                  </Button>
                </Row>
              </Col>
            </Row>
          </div>
        ))}

        <If condition={!!companySelected}>
          <Row>
            <If condition={visibleCompanyEditDrawer}>
              <CompanyGenericUpdate
                open={visibleCompanyEditDrawer}
                toggle={toggleVisibleCompanyEditDrawer}
                company={companySelected}
                referenceType={referenceType}
                referenceId={referenceId}
                page={page}
                size={size}
                sort={sort}
              />
            </If>
          </Row>
        </If>
        <If condition={visibleCompanyCreateDrawer}>
          <Row>
            <CompanyGenericCreate
              open={visibleCompanyCreateDrawer}
              toggle={toggleVisibleCompanyCreateDrawer}
              referenceId={referenceId}
              referenceType={referenceType}
              page={page}
              size={size}
              sort={sort}
            />
          </Row>
        </If>
      </Drawer>
    </>
  );
};
