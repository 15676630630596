export enum CustomFieldTypeEnum {
  STRING = 'STRING',

  INTEGER = 'INTEGER',

  DECIMAL = 'DECIMAL',

  BOOLEAN = 'BOOLEAN',

  DATE = 'DATE',

  DATETIME = 'DATETIME',

  TIME = 'TIME',

  CURRENCY = 'CURRENCY',

  SELECTOR = 'SELECTOR'
}
