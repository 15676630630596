import { AnyAction, Middleware, isRejectedWithValue } from "@reduxjs/toolkit";
import { isRejectedAction } from "@store/reducer.util";
import { asyncLaunchNotification } from '@store/slices/notification';

// ErrroMiddleware
export const ErrroMiddleware: Middleware = (store) => (next) => (action) => {

    const { dispatch } = store;
  
    const { error, payload } = action;

    if (isRejectedAction(action) && error) {
      
        const message = isRejectedWithValue(action) ? payload?.message : `Error`;
        const description = isRejectedWithValue(action) ? payload?.description : error.message;

        const shouldLaunchNotification = Boolean(isRejectedWithValue(action)) || (error?.disableAutomaticErrorNotification === false);
        
        if (shouldLaunchNotification) {
            dispatch(
                asyncLaunchNotification({
                    type: "error",
                    config: {
                        message: message,
                        description: description
                    }
                }) as unknown as AnyAction
            )
        }
    }

    try {
        
        // Let other middleware or reducers handle the action first
        return next(action);

    } catch (error) {

        // Handle the error here
        console.error('An error occurred:', error);

        // Dispatch Unkwonw Error
        dispatch(
            asyncLaunchNotification({
                type: "error",
                config: {
                    message: `Error`,
                    description: `Oops! Something went wrong!`
                }
            }) as unknown as AnyAction
        )
    
        // Optionally, you can dispatch an error action to update the state with the error
        // const errorAction = { type: 'SOMETHING_WENT_WRONG', payload: error };
        // store.dispatch(errorAction);
    
        // Rethrow the error to ensure it propagates to the console or other error boundaries
        throw error;
    }
};
