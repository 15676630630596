/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';
import { If } from '@components/Utils/Structural';
import { Skeleton, theme, Typography } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { useAppSelector } from '@store/store';
import { isValidUrl } from '@shared/util/url-util';
import { reset } from '@store/slices/open-graph';
import { ICompany } from '@models/company.model';
import { NEUTRAL_5_COLOR, WHITE_ABSOLUTE } from '@providers/ThemeProvider';

const ASPECT_RATIO_CONTAINER_IMG = 1 / 0.83;

interface ICompanyKeyPhotoViewerProps {
  companyEntity: ICompany;
}

export const CompanyKeyPhotoViewer = (props: ICompanyKeyPhotoViewerProps) => {
  const { companyEntity } = props;

  const { entity: openGraphEntity, loading: openGraphLoading } = useAppSelector(state => state.OpenGraph);

  const {
    token: { colorBorder, colorBgContainer, fontSize, colorTextQuaternary, colorText },
  } = theme.useToken();

  useEffect(() => {
    reset();
  }, []);

  const CompanyNameWebsiteTextHolder = () => {
    return (
      <div
        className="pr-10 flex flex-col justify-flex-start w-full"
        style={{
          height: '20%',
          bottom: 0,
          boxShadow: '0px -8px 6px -3px rgba(0,0,0,0.13)',
          backgroundColor: WHITE_ABSOLUTE,
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
          paddingTop: '10px',
        }}
      >
        <div className="pl-10" style={{ lineHeight: '25px', height: '20px', fontSize: fontSize, color: colorText }}>
          <b>{companyEntity?.name}</b>
        </div>
        <If condition={!!openGraphEntity?.description}>
          <div className="pl-10" style={{ lineHeight: '25px', height: '20px', color: colorText }}>
            <Typography.Text title="" ellipsis>
              {openGraphEntity?.description}
            </Typography.Text>
          </div>
        </If>
        <div className="pl-10" style={{ lineHeight: '25px', height: '20px', color: NEUTRAL_5_COLOR }}>
          {companyEntity.website}
        </div>
      </div>
    );
  };

  const LoadingCustomImageSkeleton = (props: { srcKeyPhoto }) => {
    const { srcKeyPhoto } = props;
    return (
      <Skeleton.Node
        active={openGraphLoading}
        style={{ backgroundColor: colorBgContainer, borderRadius: 16 }}
        className="w-full flex items-center"
        prefixCls="custom-skeleton"
      >
        <div
          style={{ height: '100%', borderRadius: 16, border: `1px solid ${colorBorder}` }}
          className="w-full flex flex-col items-center justify-center"
        >
          <div className="cursor-pointer" style={{ height: '80%' }}>
            <If condition={!isValidUrl(openGraphEntity.image) && !openGraphLoading}>
              <div className="w-full flex items-center justify-center" style={{ height: '100%' }}>
                <LinkOutlined style={{ color: colorTextQuaternary, fontSize: '3rem' }} />
              </div>
            </If>
            <If condition={isValidUrl(openGraphEntity.image)}>
              <img
                className="w-full cursor-pointer"
                src={srcKeyPhoto}
                alt="company-website-og"
                style={{
                  height: '100%',
                  borderTopRightRadius: 16,
                  borderTopLeftRadius: 16,
                  objectFit: 'cover',
                }}
              />
            </If>
          </div>
          <CompanyNameWebsiteTextHolder />
        </div>
      </Skeleton.Node>
    );
  };

  const container = useMemo(() => {
    return (
      <div style={{ aspectRatio: ASPECT_RATIO_CONTAINER_IMG }}>
        <LoadingCustomImageSkeleton srcKeyPhoto={openGraphEntity.image} />
      </div>
    );
  }, [openGraphEntity.image, companyEntity]);

  return container;
};
