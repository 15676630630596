import { Avatar, Col, Divider, Popover, Tag } from "antd";
import {
    ImportOutlined,
    QuestionOutlined,
    UserOutlined,
    ApiOutlined,
    SettingOutlined
} from "@ant-design/icons";
import { Row } from "antd";
import "./UserMenu.less";
import { useAuth } from "../../providers/AuthProvider";
import { useContext } from 'react';

import ConfigProvider, { ConfigContext } from "antd/es/config-provider";
import { useNavigate } from "react-router-dom";
import { capitalize } from '../../shared/util/string-utils';
import { usePreferencesProvider } from '../../providers/PreferencesProvider';
import { PreferencesModal } from "@components/Preferences/PreferencesModal";

export const UserMenu = () => {

    const { theme } = useContext(ConfigContext);
    const { setVisibleModalPreferences } = usePreferencesProvider();
    const navigate = useNavigate();

    const { user: userLogged, logout } = useAuth();

    const handleLogout = () => {
        logout();
    }
    
    const handlePreferences = () => {
        setVisibleModalPreferences(true);
    }

    const content = (
        <div className="menu-user-content pb-5" >
            <Row align={"middle"} justify={"center"} className="header-avatar mb-10">
                <Col>
                    <Avatar className="mb-5 avatar" size={56} icon={<UserOutlined />} style={{ backgroundColor: '#87d068' }} />
                </Col>
            </Row>
            <Row align={"middle"} justify={"center"} className="mt-40">
                <Col className="mb-10 text-xs ml-15 mr-15">
                    Signed in as: <span>{ userLogged?.login && capitalize(userLogged?.login) }</span>
                </Col>
            </Row>
            <Row align={"middle"} justify={"center"} className="mb-20">
                <Col>
                    <Tag color="green" className="ml-0 pl-5 pr-5">
                        {" "}{userLogged?.email}{" "}
                    </Tag>
                </Col>
            </Row>
            <Divider style={{ display: 'none' }} className="hidden" />
            <div className="mb-5 cursor-pointer user-menu-item pt-5 pb-5 hidden">
                <UserOutlined className="ml-15" />
                <span className="ml-10">Profile</span>
            </div>
            <div className="mb-5 cursor-pointer user-menu-item pt-5 pb-5 hidden">
                <QuestionOutlined className="ml-15" />
                <span className="ml-10">Help</span>
            </div>
            <div className="cursor-pointer user-menu-item pt-5 pb-5" onClick={() => navigate("/settings/api-access")}>
                <ApiOutlined className="ml-15" />
                <span className="ml-10">API access</span>
            </div>
            <Divider />
            <div className="cursor-pointer user-menu-item pt-5 pb-5" onClick={handlePreferences}>
                <SettingOutlined className="ml-15" />
                <span className="ml-10">Preferences</span>
            </div>
            <Divider />
            <div className="cursor-pointer user-menu-item pt-5 pb-5" onClick={handleLogout}>
                <ImportOutlined className="ml-15" />
                <span className="ml-10">Sign out</span>
            </div>
        </div >
    )

    return (
      <ConfigProvider theme={{ ...theme, token: { borderRadius: 0 } }}>
        <Popover content={content} placement="bottomRight" className="mr-20" trigger="click" overlayClassName="wrapper-popover" zIndex={20}>
          <div className="pr-5">
            <Avatar className="cursor-pointer" gap={4}>
              {`${userLogged?.firstName?.[0]}${userLogged?.lastName?.[0]}`.toUpperCase()}
            </Avatar>
          </div>
        </Popover>
        <PreferencesModal />
      </ConfigProvider>
    );

}


