import { CustomButtonText } from "@components/Utils/CustomButtonText";
import { useLayoutContex } from "@providers/LayoutProvider";
import { Card } from "antd"
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const AccessAndPermissionsView = (props) => {

    const navigate = useNavigate();
    const { setHeaderLabelEntityState } = useLayoutContex()
    const { t } = useTranslation();

    const navigateToUsers = () => {
        navigate('/admin/access-and-permissions/users');
    }

    const navigateToGroups = () => {
        navigate('/admin/access-and-permissions/groups');
    }

    const navigateToPermissions = () => {
        navigate('/admin/access-and-permissions/permissions');
    }

    useEffect(() => {
        setHeaderLabelEntityState(t("admin.accessAndPermissions"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <div style={{ padding: '16px 24px' }}>
        <Card title="Users" extra={<CustomButtonText type="default" onClick={navigateToUsers}>View</CustomButtonText>} className="ant-card-no-body admin-card" />
        <Card title="Groups" extra={<CustomButtonText type="default" onClick={navigateToGroups}>View</CustomButtonText>} className="ant-card-no-body admin-card"  />
        <Card title="Permissions" extra={<CustomButtonText type="default" onClick={navigateToPermissions}>View</CustomButtonText>} className="ant-card-no-body admin-card" />
    </div>
}