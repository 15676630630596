import { http } from '../http/axios.instance';
import { IOpenGraph } from '@store/slices/open-graph';

const path = 'utils/url-preview';

export const openGraphRepository = {
  getOpenGraph: async payload => {
    const requestUrl = `${path}`;
    const promiseGet = await http.post<IOpenGraph>(requestUrl, payload);
    return promiseGet.data;
  },
};
