export interface ThunkRequest<P> {
  payload: P,
  onSuccess?: () => void, 
  onError?: (error?: unknown ) => void 
}

export const ErrorMap = {
  400: "Bad Request - The server could not understand the request due to invalid syntax or missing parameters.",
  401: "Unauthorized Access",
  403: "Forbidden - You don't have permission to access this resource on the server.",
  404: "Resource Not Found: The requested content could not be located.",
  500: "Oops! Something went wrong!",
  503: "We're sorry, but the service you're trying to access is currently unavailable."
}

export type ErrorMapType = keyof typeof ErrorMap;

export interface IErrorSerialized  {
  message: string;
  genericMessage: string;
  serverMessage: string;
}

export type IErrorCatched = IErrorSerialized | null;

export const GENERIC_BACKEND_MESSAGE = "Internal Server Error";
export const DEFAULT_FRONTED_MESSAGE = "Oops! Something went wrong!"

export const serializeGenericHandleError = (value: any) => {

  const defaultMessage = DEFAULT_FRONTED_MESSAGE;

  let errorMessage = `${defaultMessage}`;
  let errorGenericMessage = `${defaultMessage}`;
  let errorServerMessage = `${defaultMessage}`;

  if (typeof value === 'object' && value !== null) {

    // Capture the error based on the http error code
    if (value?.response?.status && Number.isInteger(value?.response?.status)) {
      const genericMessage = ErrorMap[value?.response?.status as ErrorMapType] || defaultMessage
      errorMessage = genericMessage;
      errorGenericMessage = genericMessage;
    }
    
    if (value?.response?.data?.title) {
      const serverMessage = value?.response?.data?.title;

      // Captures the error based on the message that the server responds to 
      // and if this message is different from the generic one this is shown
      const isAbackendErrorDifferentFromTheGenericOne = serverMessage !== GENERIC_BACKEND_MESSAGE
  
      if (isAbackendErrorDifferentFromTheGenericOne) {
        errorMessage = serverMessage;
        errorServerMessage = serverMessage
      }
    }
    
  }

  return {
    /**
     * third Level Error Message.
     *
     */
    message: errorMessage || defaultMessage,

    /**
     * Second Level Error Message.
     *
     */
    genericMessage: errorGenericMessage || defaultMessage,

    /**
     * First Level Error Message.
     *
     */
    serverMessage: String(errorServerMessage) || defaultMessage,

    /**
     * A flag to Disable Automatic Error Notification.
     *
     * @default `false`
     */
    disableAutomaticErrorNotification: false
  };
};

export const serializeCustomHandleError = (value: any) => {
  return {
    ...serializeGenericHandleError(value),
    disableAutomaticErrorNotification: false
  }
}
