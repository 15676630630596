import { createAsyncThunk, createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { IUtil } from "@models/utils";
import { IAddress, ICreateAddressRequest, IDeleteAddressRequest, IGetAddressRequest, IUpdateAddressRequest } from '@models/address.model';
import { asyncLaunchNotification } from '@store/slices/notification';
import { genericAddressService } from "@services/generic-address.service";

export interface IState extends IUtil {
  data: {
    addressesList: IAddress[]
  }
}

const initialState: IState = {
  data: {
    addressesList: []
  },
  loading: false,
  errorMessage: null
};

const sliceName: string = "siteAddress"

export const getAddressesList = createAsyncThunk(
  `${sliceName}/getSiteAddress`,
  async (payload: IGetAddressRequest) => {
    const data = await genericAddressService.getAddressesList(payload);
    return data;
  }
)

export const createAddress = createAsyncThunk(
  `${sliceName}/createAddress`,
  async (payload: ICreateAddressRequest , thunkAPI) => {
    const data = await genericAddressService.createAddress(payload);
    if (data && data.data) {
      thunkAPI.dispatch(asyncLaunchNotification({
        type: "success",
        config: {
          message: `Address`,
          description: `Address created successfully`
        }
      }));
      const payloadGetListAddresss = {
        referenceId: payload.referenceId,
        referenceType: payload.referenceType
      }
      thunkAPI.dispatch(getAddressesList(payloadGetListAddresss))
    }
    return data;
  }
)

export const updateAddress = createAsyncThunk(
  `${sliceName}/updateAddress`,
  async (payload: IUpdateAddressRequest , thunkAPI) => {
    const data = await genericAddressService.updateAddress(payload.address);
    if (data && data.data) {
      thunkAPI.dispatch(asyncLaunchNotification({
        type: "success",
        config: {
          message: `Address`,
          description: `Address updated successfully`
        }
      }));
      const payloadGetListAddresss = {
        referenceId: payload.referenceId,
        referenceType: payload.referenceType
      }
      thunkAPI.dispatch(getAddressesList(payloadGetListAddresss))
    }
    return data;
  }
)

export const deleteAddress = createAsyncThunk(
  `${sliceName}/deleteAddress`,
  async (deleteResquest: IDeleteAddressRequest , thunkAPI) => {
    const data = await genericAddressService.deleteAddress(deleteResquest);
    if (data && data) {
      thunkAPI.dispatch(asyncLaunchNotification({
        type: "success",
        config: {
          message: `Address`,
          description: `Address delete successfully`
        }
      }));
      const payloadGetListAddresss = {
        referenceId: deleteResquest.referenceId,
        referenceType: deleteResquest.referenceType
      }
      thunkAPI.dispatch(getAddressesList(payloadGetListAddresss))
    }
    return data;
  }
)

export const SiteAddressSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reset: (state,) => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getAddressesList.fulfilled, (state, action) => {
        state.data.addressesList = action.payload;
        state.errorMessage = null;
        state.loading = false;
      })
      .addMatcher(isPending(getAddressesList), state => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isRejected(getAddressesList), (state, action) => {
        state.errorMessage = action?.error?.message || null;
        state.loading = false;
      });
  }
});

export const { reset } = SiteAddressSlice.actions;

// Reducer
export default SiteAddressSlice.reducer;