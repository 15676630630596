import { CustomButtonText } from "@components/Utils/CustomButtonText";
import { TabContainer } from "@components/Utils/TabContainer"
import { FeatureFlagOptionEnum } from "@models/enumerations/feature-flag-option.enum";
import { useFeatureFlagsProvider } from "@providers/FeatureFlagsProvider";
import { useLayoutContex } from "@providers/LayoutProvider";
import { Card } from "antd"
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom"

export const ModuleConfigurationTab = (props: any) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setHeaderLabelEntityState } = useLayoutContex();
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const { flags, isFlagEnabled } = useFeatureFlagsProvider();
  const isRFIEnabled = isFlagEnabled(FeatureFlagOptionEnum.RFI);
  const isBudgetEnabled = isFlagEnabled(FeatureFlagOptionEnum.BUDGET);
  const isChangeOrdersEnabled = isFlagEnabled(FeatureFlagOptionEnum.CHANGE_ORDERS);
  const isPunchListEnabled = isFlagEnabled(FeatureFlagOptionEnum.PUNCHLIST);

  useEffect(() => {
    setHeaderLabelEntityState(t("admin.moduleConfiguration"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigateToProjects = () => {
    navigate('/admin/module-configuration/projects')
  }
  
  const navigateToSites = () => {
    navigate('/admin/module-configuration/sites')
  }

  const navigateToProjectDeliverables = () => {
    navigate('/admin/module-configuration/project-deliverables')
  }

  const navigateToRFI = () => {
    navigate('/admin/module-configuration/rfi')
  }

  const navigateToBudget = () => {
    navigate('/admin/module-configuration/budget')
  }

  const navigateToChangeOrder = () => {
    navigate('/admin/module-configuration/change-order')
  }

  const navigateToPunchList = () => {
    navigate('/admin/module-configuration/punch-list')
  }
  
  return (
    <>
      <TabContainer>
            <div style={{ padding: '16px 24px' }}>
              <Card title="Sites" extra={<CustomButtonText type="default" onClick={navigateToSites}>View</CustomButtonText>} className="ant-card-no-body admin-card" />
              <Card title="Projects" extra={<CustomButtonText type="default" onClick={navigateToProjects}>View</CustomButtonText>} className="ant-card-no-body admin-card" />
              <Card title="Project Deliverables" extra={<CustomButtonText type="default" onClick={navigateToProjectDeliverables}>View</CustomButtonText>} className="ant-card-no-body admin-card" />
              {isRFIEnabled && <Card title={t("admin.rfi")} extra={<CustomButtonText type="default" onClick={navigateToRFI}>View</CustomButtonText>} className="ant-card-no-body admin-card" />}
              {isBudgetEnabled && <Card title={t("budget.budget")} extra={<CustomButtonText type="default" onClick={navigateToBudget}>View</CustomButtonText>} className="ant-card-no-body admin-card" />}
              {isChangeOrdersEnabled && <Card title={t("admin.changeOrder")} extra={<CustomButtonText type="default" onClick={navigateToChangeOrder}>View</CustomButtonText>} className="ant-card-no-body admin-card" />}
              {isPunchListEnabled && <Card title={t("punchList.punchList")} extra={<CustomButtonText type="default" onClick={navigateToPunchList}>View</CustomButtonText>} className="ant-card-no-body admin-card" />}
            </div>
        </TabContainer>
    </>
  )
}