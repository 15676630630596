/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { useSearchParams } from "react-router-dom";
import { Skeleton, Spin, theme } from "antd";
import { useAppDispatch, useAppSelector } from "@store/store";
import { GalleryItem } from "./GalleryItem";
import { isArrayWithValues } from "@shared/util/array-util";
import { ContainerFileProvider } from "@components/Attachment/FilePreview/ContainerFileProvider";
import { FilePreviewModal, StrategyLoadAttachments } from "@components/Attachment/FilePreviewExtended/FilePreviewModal";
import { IAttachment } from "@models/attachment.model";
import { getExtensionOfFileName, isAnValidImage } from "@shared/util/document-utils";
import { DerivativesEnum } from "@models/enumerations/derivatives-enum.model";
import { updateTotalAttachmentOfAlbum } from "@store/slices/photo-album";
import { AttachmentReferenceEnum } from "@models/enumerations/attachment-reference-enum.model";
import { countMultimediaFiles, getPhotosByReferenceTypeAndReferenceId, removePhotoFromGallery } from "@store/slices/generic-album-gallery-multimedia";
import { PAGINATION_SIZE_DEFAULT_IN_PHOTOS } from "@constants/core";
import { FilePaginator } from "@components/Attachment/FilePreviewExtended/FilePaginator";
import useVisible from "@HOOKs/UseVisible";
import useSelectFileByRouteParameter from "./UseSelectFileByRouteParameter";
import { useUrlParamsUtils } from "@HOOKs/UseParamsUtils";
import { URL_PARAM_ATTACHMENT_SELECTED_KEY } from "@constants/photoAlbum";
import { FooterMobile } from "@components/Attachment/FilePreviewExtended/FilePreviewModal/Footer/FooterMobile";
import { FilePaginatorMobile } from "@components/Attachment/FilePreviewExtended/FilePreviewModal/Footer/FilePaginatorMobile";
import { isStringOrNumber } from "@shared/util/validations";

const UseOnWatchParam = ({ setAlbumSelected }) => {
    let [searchURLParams] = useSearchParams();

    useMemo(() => {
        setAlbumSelected(searchURLParams.get("albumSelected"));
    }, [searchURLParams]);
}

export const GalleryContainer = () => {

    const [albumSelected, setAlbumSelected] = useState<String|null>("");

    UseOnWatchParam({ setAlbumSelected });

    // UI
    const { token: { motionDurationMid, motionEaseInOut }} = theme.useToken();

    const { width: gridWidth = 0,  ref: gridRef } = useResizeDetector({refreshMode: 'debounce', refreshRate: 150 });

    const dispatch = useAppDispatch();

    const [gridTemplateColumns, setGridTemplateColumns] = useState("repeat(4, 1fr)");
    
    useLayoutEffect(() => {
        if (gridWidth > 0) {
            setGridTemplateColumns("1fr")
        }
        if (gridWidth > 100) {
            setGridTemplateColumns("repeat(2, 1fr)")
        }
        if (gridWidth > 380) {
            setGridTemplateColumns("repeat(3, 1fr)")
        }
        if (gridWidth > 560) {
            setGridTemplateColumns("repeat(4, 1fr)")
        }
        if (gridWidth > 740) {
            setGridTemplateColumns("repeat(5, 1fr)")
        }
        if (gridWidth > 920) {
            setGridTemplateColumns("repeat(5, 1fr)")
        }
        if (gridWidth > 1100) {
            setGridTemplateColumns("repeat(6, 1fr)")
        }
        if (gridWidth > 1500) {
            setGridTemplateColumns("repeat(7, 1fr)")
        }
        if (gridWidth > 1700) {
            setGridTemplateColumns("repeat(8, 1fr)")
        }
        if (gridWidth > 1900) {
            setGridTemplateColumns("repeat(9, 1fr)")
        }
    }, [gridWidth])

    const [fileSelected, setFileSelected] = useState<IAttachment | null>({});

    const [openFilePreview, toogleOpenFilePreview, setVisible] = useVisible();

    // Data Albums
    const { entity: album } = useAppSelector((store) => store.PhotoAlbum);
    
    // Data Gallery
    const { entities, firstLoaded, currentPage: pageCounter = 0, loading, totalItems } = useAppSelector((store) => store.GenericAlbumGalleryMultimedia);
    
    useSelectFileByRouteParameter({ setFileSelected, setVisible, attachments: entities, firstLoaded });
    
    const { setOrReplaceUrlParam, deleteUrlParam  } = useUrlParamsUtils();

    useEffect(() => {
        if (openFilePreview && fileSelected) {
            fileSelected?.id && setOrReplaceUrlParam(URL_PARAM_ATTACHMENT_SELECTED_KEY, String(fileSelected.id!));
        }
    }, [openFilePreview, fileSelected]);

    const loadAnotherPage = () => {
        dispatch(getPhotosByReferenceTypeAndReferenceId({ 
            referenceId: String(albumSelected), 
            referenceType: AttachmentReferenceEnum.PHOTO_ALBUM, 
            page: pageCounter + 1, 
            size: PAGINATION_SIZE_DEFAULT_IN_PHOTOS 
        }));
    }
    
    const listAttachments = useMemo(() => {
        return isArrayWithValues(entities) 
            ? entities
            : []
    }, [entities]);

    const onClickGalleryItem = (file: IAttachment) => {
        setFileSelected(file);
        toogleOpenFilePreview();
        onViewAction(file)
    }

    const onViewAction = (record: IAttachment) => {

        const dataFile = getDataExtension(record);
    
        if (dataFile.isAnValidImage) {
            setFileSelected({ ...record, ...dataFile });
            setVisible(true);
            return;
        }
    
        if (!dataFile.isAnValidImage && record.derivatives?.some((item) => item.derivativeType === DerivativesEnum.PREVIEW)) {
            setFileSelected({ ...record, ...dataFile });
            return;
        }
    }

    const onSuccessRemove = () => {
        if (fileSelected && fileSelected.id) {
            deleteUrlParam(URL_PARAM_ATTACHMENT_SELECTED_KEY);
            dispatch(removePhotoFromGallery({ attachment: fileSelected}));
            setTimeout(() => {
                if (isStringOrNumber(album?.id)) {
                    dispatch(countMultimediaFiles({ referenceId: album.id!, referenceType: AttachmentReferenceEnum.PHOTO_ALBUM}))
                    .unwrap()
                    .then((totalItems) => {
                        dispatch(updateTotalAttachmentOfAlbum({ albumId: album.id, totalItems }));
                    });
                }
            }, 10);
        }
    }
    
    return (
        <div>
            <div 
                ref={gridRef} 
                className="container grid pr-16 pl-16 pt-16 pb-30 w-full" 
                style={{ 
                    transition: `all ${motionDurationMid} ${motionEaseInOut}`,
                    gridTemplateColumns: gridTemplateColumns, gridGap: '1rem', gridAutoFlow: 'row' 
                }}>
                
                <Skeleton 
                    loading={loading && pageCounter === 0} 
                    paragraph={{ rows: 14 }} 
                    active={true} 
                    className="w-full pl-16 pr-16 pt-16" 
                    style={{ gridColumnStart: 1, gridColumnEnd: -1 }}
                    >
                    {
                        isArrayWithValues(listAttachments) && listAttachments.map((attachment, index) => {
                            return (
                                <div key={attachment?.id} style={{ aspectRatio: 1 / 1 }}>
                                    <GalleryItem 
                                        attachment={attachment} 
                                        onClickFile={() => onClickGalleryItem(attachment)} 
                                        loading={index > 10 ? "lazy" : "eager"} 
                                    />
                                </div>
                            )
                        })
                    }
                </Skeleton>
                
                {
                    openFilePreview && fileSelected && (
                        <ContainerFileProvider>
                            <FilePreviewModal
                                open={openFilePreview}
                                toggle={toogleOpenFilePreview}
                                fileData={{ ...fileSelected }}
                                filePaginator={
                                    <FilePaginator
                                        totalAttachments={totalItems}
                                        strategyLoadAttachments={StrategyLoadAttachments.ON_DEMAND}
                                        loadMoreAttachments={loadAnotherPage}
                                        fullFilesCollection={[...listAttachments]}
                                        fileSelected={fileSelected}
                                        setFileSelected={setFileSelected} 
                                    />
                                }
                                footer={
                                    <FooterMobile
                                        filePaginator={
                                            <FilePaginatorMobile
                                                totalAttachments={totalItems}
                                                strategyLoadAttachments={StrategyLoadAttachments.ON_DEMAND}
                                                loadMoreAttachments={loadAnotherPage}
                                                fullFilesCollection={[...listAttachments]}
                                                fileSelected={fileSelected}
                                                setFileSelected={setFileSelected}
                                            />
                                        }
                                        attachment={fileSelected}
                                        closeFilePreview={toogleOpenFilePreview} 
                                        onSuccessDeleteAttachment={onSuccessRemove}
                                    />
                                }
                                onSuccessDeleteAttachment={onSuccessRemove}
                            />
                        </ContainerFileProvider>
                    )
                }

            </div>

            {
                loading && pageCounter > 0 && 
                    (<div className='flex flex-row items-center justify-center w-full pl-10 pt-8 pb-8 mt-10'>
                        <Spin />
                    </div>)
            }

        </div>
        
    )
}

export const getDataExtension = (documentSelected: IAttachment) => {
    const extension = documentSelected.fileName ? getExtensionOfFileName(documentSelected.fileName) : "";
    const IsImageValid = isAnValidImage(extension);
    return {
        extension,
        isAnValidImage: IsImageValid
    }
}
