import { IBudgetApprovalRangeSetting, IBudgetApprovalRangeSettingSave } from '@models/budget-approval-range-setting.model';
import { IQueryParams } from '../../models/pagination';
import { http } from '../http/axios.instance';
import { StringORNumber } from './utils.repository';

const path = 'budget-approval-range-settings';

export const budgetApprovalRangeSettingRepository = {
  getBudgetApprovalRangeSettings: async (queryParams: IQueryParams) => {
    const { sort, page, size } = queryParams;
    const requestUrl = `${path}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
    const settings = await http.get<IBudgetApprovalRangeSetting[]>(requestUrl);
    return settings;
  },
  create: async (entity: IBudgetApprovalRangeSetting) => {
    const requestUrl = `${path}`;
    const setting = await http.post<IBudgetApprovalRangeSetting>(requestUrl, entity);
    return setting;
  },
  update: async (id: StringORNumber, entity: IBudgetApprovalRangeSetting) => {
    const requestUrl = `${path}/${id}`;
    const setting = await http.put<IBudgetApprovalRangeSetting>(requestUrl, entity);
    return setting;
  },
  saveComplete: async (setting: IBudgetApprovalRangeSettingSave) => {
    const promise = await http.put<IBudgetApprovalRangeSetting>(`budget-approval-range-settings-extended/${setting?.id}/complete`, setting);
    return promise;
  },
  getBudgetApprovalRangeSettingsWithApprovers: async () => {
    const requestUrl = `budget-approval-range-settings-extended?cacheBuster=${new Date().getTime()}`;
    const settings = await http.get<IBudgetApprovalRangeSettingSave[]>(requestUrl);
    return settings;
  },
};