import { Breadcrumb, theme } from "antd";
import { Link, useMatches } from "react-router-dom";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb.d"
import { ChevronRightSvgIcon } from "@components/Icons/ChevronRightSvgIcon";

export interface IBreadcrumbNavigationProps {
  removeLastBreadcrumbAutomatically?: boolean
}

export const BreadcrumbNavigation = (props: IBreadcrumbNavigationProps) => {

  const { token: { colorBorder }} = theme.useToken();

  const { removeLastBreadcrumbAutomatically = true } = props

  const extraBreadcrumbItems: ItemType[] = [] as ItemType[];

  let matches = useMatches();

  let crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match: any) => Boolean(match.handle?.crumb));

    // sometimes you don't want to show last Crumb, in that case set removeLastBreadcrumbAutomatically in true.
    removeLastBreadcrumbAutomatically && crumbs.pop();

    // now map them into an array of elements, passing the loader
    // data to each one

    crumbs.forEach((match: any, index) => {
    
        const isLastItem = index === (crumbs.length - 1);

        const linkLabelRender = match?.handle?.crumb!({ ...match.data, isLastItem });
        const navigateTo: string = (match?.handle?.crumbNavigateTo && typeof match?.handle?.crumbNavigateTo === "function" && match?.handle?.crumbNavigateTo!({ ...match.data, isLastItem })) || "";

        extraBreadcrumbItems.push({
          key: match.pathname,
          title: (
            <span className={`${ isLastItem ? "font-semibold" : "" }`}>
              { 
                navigateTo 
                  ? (<Link to={navigateTo}>{linkLabelRender}</Link>)
                  : (<Link to={match.pathname}>{linkLabelRender}</Link>)
              }
            </span>
          )
        });
        
    });
    
  return <Breadcrumb separator={<ChevronRightSvgIcon color={colorBorder} />} items={extraBreadcrumbItems} />;
};
