import { RightOutlined } from "@ant-design/icons";
import { theme } from "antd";
import { useState } from "react";

interface ICollapsibleButtonProps {
    isActive?: boolean, 
    style?: React.CSSProperties 
    onClick?: () => void
}

export const CollapsibleButton = (props: ICollapsibleButtonProps) => {

    const { token: { colorPrimary, colorPrimaryBg } } = theme.useToken();

    const [collapsed, setCollapsed] = useState(false)

    const toogleNav = () => {
        setCollapsed(!collapsed);
    }

    return (
        <div
            onClick={toogleNav} 
            className={`generic-transition cursor-pointer pt-5 pr-8 pb-5 pl-8 w-min`} 
            style={{ backgroundColor: colorPrimaryBg, borderRadius: "5px" }}
        >
            <RightOutlined 
                className="generic-transition"
                style={{
                    color: colorPrimary,
                    transform: collapsed ? "rotateY(0deg)" : "rotateY(180deg)",  
                }}
            />
        </div>
    )

}