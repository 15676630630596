import { IQueryParamsGeneric } from '@models/utils';
import { http } from '../http/axios.instance';
import { StringORNumber } from './utils.repository';
import { IPhotoAlbum } from '@models/photo-album.model';

export const path = 'photo-albums';

export const siteAlbumPhotosRepository = {
  getSiteAlbumPhotos: async (queryParams: IQueryParamsGeneric) => {
    
    const { page = 0, size = 5, siteId } = queryParams;
    const requestUrl = `${path}?referenceId.equals=${siteId}&referenceType.equals=SITE&page=${page}&size=${size}`;
  
    const promiseGetAll = await http.get<IPhotoAlbum[]>(requestUrl);

    return promiseGetAll;
  },
  getSiteAlbumPhotoByLocationId: async (id: StringORNumber) => {

    const requestUrl = `${path}/${id}`;
    const promiseGetByID = await http.get<IPhotoAlbum>(requestUrl);
    return promiseGetByID;
  },
  getSiteAlbumPhotosById: async (id: StringORNumber) => {

    const requestUrl = `${path}/${id}`;
    const promiseGetByID = await http.get<IPhotoAlbum>(requestUrl);
    return promiseGetByID.data;
  },
  createSiteAlbumPhotos: async (entity: Omit<IPhotoAlbum, "id">): Promise<IPhotoAlbum> => {
    
    const requestUrl = `${path}`;
    const promiseCreate = await http.post<IPhotoAlbum>(requestUrl, entity);
    return promiseCreate.data;
  },
  updateSiteAlbumPhotos: async (id: string | number, entity: IPhotoAlbum): Promise<IPhotoAlbum> => {

    console.log(entity);

    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.put<IPhotoAlbum>(requestUrl, entity);
    return promiseUpdate.data;
  },
  deleteSiteAlbumPhotos: async (id: string | number) => {

    const requestUrl = `${path}/${id}`;
    const promiseDelete = await http.delete<IPhotoAlbum>(requestUrl);
    return promiseDelete;
  },
}