import { IChangeOrderItem } from "@models/change-order-item.model";
import { Col, Row, Tooltip, Typography, theme } from "antd";
import Big from "big.js";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

export interface ICostDetailTableProps {
    title: string,
    items: IChangeOrderItem[],
    currencySymbol: string | undefined,
}

export const CostDetailTable = (props: ICostDetailTableProps) => {

    const { title, items, currencySymbol } = props;

    const { token: { colorFillQuaternary, colorTextTertiary } } = theme.useToken();
    const { t } = useTranslation();

    const getItemSubtotal = (unitsQty?: number, unitCost?: string): Big => {
        let cost = Big(0);
        if (unitsQty && unitCost) {
            const qty = new Big(unitsQty)
            cost = Big(cost.add(qty.times(unitCost)))
        }
        return cost;
    }

    return (items?.length > 0 ? <>
        <Row className="mt-10" style={{ backgroundColor: colorFillQuaternary, lineHeight: "28px" }}>
            <Col span={24} className="pl-10 font-bold">
                {title}
            </Col>
        </Row>
        <Row className="pl-10 pr-10 font-bold">
            <Col span={4}>
                {t("changeOrder.costsDetail.codeTitle")}
            </Col>
            <Col span={8}>
                {t("changeOrder.costsDetail.descriptionTitle")}
            </Col>
            <Col span={2}>
                {t("changeOrder.costsDetail.qtyTitle")}
            </Col>
            <Col span={5} className="text-center">
                {t("changeOrder.costsDetail.costTitle")}
            </Col>
            <Col span={5} className="text-center">
                {t("changeOrder.costsDetail.subtotalTitle")}
            </Col>
        </Row>
        {items.map(item =>
        (<Row className="pl-10 pr-10" key={item.id}>
            <Col span={4}>
                {item.costSubCategory?.costCategory?.costGrouping?.code}-{item.costSubCategory?.costCategory?.code}-{item.costSubCategory?.code}
            </Col>
            <Col span={8} className="pr-10">
                <Text
                    style={{ width: '100%' }}
                    ellipsis={{ tooltip: item.description }}
                >
                    {item.description}
                </Text>
            </Col>
            <Col span={2}>
                {Number(item.unitsQty)}
            </Col>
            <Col span={5} className="text-right">
                <Row wrap={false}>
                    <Col flex="none">
                        <div className="pl-20 pr-5" style={{ color: colorTextTertiary, fontWeight: 500 }}>{currencySymbol}</div>
                    </Col>
                    <Col flex="auto" style={{ fontFamily: "'Roboto Mono', monospace" }}>
                        {item.unitCost &&
                            <Tooltip title={parseFloat(item.unitCost)}>
                                {parseFloat(item.unitCost).toFixed(2)}
                            </Tooltip>
                        }
                    </Col>
                </Row>
            </Col>
            <Col span={5} className="text-right" >
                <Row wrap={false}>
                    <Col flex="none">
                        <div className="pl-20 pr-5" style={{ color: colorTextTertiary, fontWeight: 500 }}>{currencySymbol}</div>
                    </Col>
                    <Col flex="auto" style={{ fontFamily: "'Roboto Mono', monospace" }}>
                        <Tooltip title={getItemSubtotal(item.unitsQty, item.unitCost).toNumber()}>
                            {getItemSubtotal(item.unitsQty, item.unitCost).toFixed(2)}
                        </Tooltip>
                    </Col>
                </Row>
            </Col>
        </Row>
        ))}
    </> : <></>
    );
}