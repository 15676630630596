/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react';
import { ArrayToObject } from "@shared/util/object-utils";
import { useAppSelector } from "@store/store";
import { useFeatureFlagsProvider } from '@providers/FeatureFlagsProvider';
import { FeatureFlagOptionEnum } from '@models/enumerations/feature-flag-option.enum';
import { isArray } from '@shared/util/array-util';

export const CRUD_ACTIONS = {
    CREATE: "CREATE",
    READ: "READ",
    EDIT: "EDIT",
    DELETE: "DELETE"
}

export const ACTIONS_PERMISIONS = {
    // LIST: "LIST",
    ...CRUD_ACTIONS
}

export const ENTITIES = {
    "group.oak.siterise.service.dto.RichCommentDTO": "group.oak.siterise.service.dto.RichCommentDTO",
    "group.oak.siterise.service.dto.LocationDTO": "group.oak.siterise.service.dto.LocationDTO",
    "group.oak.siterise.service.dto.SiteDTO": "group.oak.siterise.service.dto.SiteDTO",
    PROJECT: "group.oak.siterise.service.dto.ProjectDTO",
    ADDRESS: "ADDRESS",
    COMPANY: "COMPANY",
    CUSTOM_FIELDS: "CUSTOM_FIELDS",
    PROJECT_DELIVERABLES: "group.oak.siterise.service.dto.ProjectDeliverableDTO",
    ATTACHMENTS: "ATTACHMENTS",
    BI_DASHBOARD: "BIDashboardReports"
}

export const ROLES = {
    ADMIN: 'ADMIN'
}

export type IActionsPermisions = keyof typeof ACTIONS_PERMISIONS;
export type ICrudActions = keyof typeof CRUD_ACTIONS;
export type IEntities = keyof typeof ENTITIES;

export interface ICan {
    I: IActionsPermisions,
    A: IEntities,
    Entity?: { id: string | number | undefined | null }
}

const usePermisions = () => {

    const { data: { permisionsList, permisionsLoaded } } = useAppSelector((state) => state.Permisions);    
    const [permisions, setPermisions] = useState(null);
    const { isFlagEnabled } = useFeatureFlagsProvider();

    useEffect(() => {
        if (isArray(permisionsList) && permisionsLoaded) {
            const permisionsIndexed = ArrayToObject(permisionsList, (item) => item)
            permisionsIndexed && setPermisions(permisionsIndexed);
        }
    }, [permisionsList, permisionsLoaded])

    const can = useCallback(({ I: canI, A: nameEntity, Entity }: ICan): boolean => {

        if (isFlagEnabled(FeatureFlagOptionEnum.ACL_EVALUATOR)) {

            // Admin Permissions
            if (permisions && permisions[ROLES.ADMIN]) {
                return true
            }

            // List Permisions
            if (canI === ACTIONS_PERMISIONS.READ && ENTITIES[nameEntity]) {
                return Boolean(permisions && permisions[`${ENTITIES[nameEntity]}_${ACTIONS_PERMISIONS.READ}`])
            }

            // Create Permisions
            if (canI === ACTIONS_PERMISIONS.CREATE && ENTITIES[nameEntity]) {
                return Boolean(permisions && permisions[`${ENTITIES[nameEntity]}_${ACTIONS_PERMISIONS.CREATE}`])
            }

            // Create Permisions
            if (canI === ACTIONS_PERMISIONS.EDIT && ENTITIES[nameEntity]) {
                return Boolean(permisions && permisions[`${ENTITIES[nameEntity]}_${ACTIONS_PERMISIONS.EDIT}`])
            }

            // /**
            //  *  READ, UPDATE, DELETE Permisions
            //  *  @dependency Entity
            //  *  */

            // if ((canI !== ACTIONS_PERMISIONS.LIST && canI !== ACTIONS_PERMISIONS.CREATE && canI === CRUD_ACTIONS[canI as ICrudActions]) && ENTITIES[nameEntity] && Entity?.id) {
            //     const action = `${nameEntity}_${Entity?.id}_${ACTIONS_PERMISIONS[canI]}`;
            //     const isAllowed = Boolean(permisions && permisions[action])
            //     return isAllowed;
            // }

            return false;
        } else {
            return true;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permisions]);


    return { can, permisions }
}


export default usePermisions;
