import { Comment } from "@components/Comment/Comment";
import { EmptyLabel } from "@components/Utils/EmptyLabel";
import { IChangeOrderResume } from "@models/change-order.model";
import { ICommentRichText } from "@models/comment.model";
import { AttachmentReferenceEnum } from "@models/enumerations/attachment-reference-enum.model";
import { formatDatePipe } from "@shared/util/date-utils";
import { getByReferenceTypeAndReferenceId } from '@store/slices/attachment';
import { useAppDispatch, useAppSelector } from "@store/store";
import { Avatar, Button, Card, Col, Divider, Row, Space, Tag, Tooltip, theme } from "antd";
import { useLayoutEffect } from "react";
import { useTranslation } from 'react-i18next';
import { AttachmentItem } from "./AttachmentItem";
import { IChangeOrderStepProps } from "./Stepper";
import { ChangeOrdersStatusIds } from "@models/enumerations/change-orders-status";

export const StepThree = (props: IChangeOrderStepProps) => {

    const { token: { colorPrimary, colorTextSecondary, colorBgLayout, fontSize, colorInfoBg, colorTextTertiary } } = theme.useToken();

    const { entity } = useAppSelector((state) => state.ChangeOrder);
    const { entities: attachmentList } = useAppSelector((state) => state.Attachment);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const getAllAttachments = () => {
        dispatch(getByReferenceTypeAndReferenceId({ referenceType: AttachmentReferenceEnum.CHANGE_ORDER_REVISION, referenceId: entity.currentRevisionId! }))
    }

    useLayoutEffect(() => {
        entity?.id && getAllAttachments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity?.id])

    const getCreatedByLabel = (changeOrder: IChangeOrderResume): String => {
        if (changeOrder?.createdByFirstName && changeOrder?.createdByLastName) {
            return changeOrder.createdByFirstName + ' ' + changeOrder.createdByLastName;
        } else if (changeOrder?.createdByFirstName) {
            return changeOrder.createdByFirstName;
        } else if (changeOrder?.createdByLastName) {
            return changeOrder.createdByLastName;
        } else {
            return changeOrder?.createdBy?.toString() || '';
        }
    }

    const descriptionView = () => {
        const buildComment = () => {
            const comment: ICommentRichText = {
                payload: entity?.description,
                createdDate: entity?.createdDate,
                createdBy: entity?.createdBy,
                createdById: entity?.createdBy,
                createdByFirstName: entity?.createdByFirstName,
                createdByLastName: entity?.createdByLastName,
                createdByEmail: entity?.createdByEmail
            }

            return comment;
        }

        return (<>
            {(entity?.description && <Comment value={buildComment()} isPreview={false} />)}
        </>);
    }

    const publishChangeOrder = () => {
        props.complete?.();
    }

    return (<>
        <Row>
            <Col span={24} className='pt-15 mb-20 flex flex-row items-center'>
                <span style={{ color: colorPrimary, fontSize: '20px', lineHeight: '28px' }}>{t("changeOrder.co")} #{entity?.number}</span>
            </Col>
        </Row>
        <Row>
            <Col className="pl-30">
                <Row>
                    <Col>
                        <span className="font-bold">{t("changeOrder.submitterLabel")}:</span>
                        <span className="ml-10 flex-row items-center" style={{ color: colorTextTertiary }}>
                            <Avatar className="cursor-default mr-5" gap={4} style={{ backgroundColor: '#B2B2B2' }}>
                                {`${entity?.createdByFirstName?.slice(0, 2)}`.toUpperCase()}
                            </Avatar>
                            {getCreatedByLabel(entity)}
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span className="font-bold">{t("changeOrder.creationDateLabel")}:</span>
                        <span className="ml-10" style={{ color: colorTextTertiary }}>{formatDatePipe(entity?.createdDate)}</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span className="font-bold">{t("changeOrder.requiredByLabel")}:</span>
                        <span className="ml-10" style={{ color: colorTextTertiary }}>{formatDatePipe(entity?.dueDate)}</span>
                    </Col>
                </Row>
            </Col>
            <Divider type="horizontal"></Divider>
        </Row>
        <Row>
            <Col span={24} className='mb-10'>
                {descriptionView()}
            </Col>
        </Row>
        {
            attachmentList.map(attachment =>
                <Row key={attachment.id}>
                    <Col span={24} className='pl-30 pb-5'>
                        <AttachmentItem value={attachment} />
                    </Col>
                </Row>)
        }
        <Row>
            <Col className="pl-40 mb-10">
                <Col>
                    <span className="font-bold">{t("changeOrder.estimatedCostLabel")}:</span>
                    <span className="ml-10">
                        <Tooltip title={`${entity?.estimatedCost ? parseFloat(entity?.estimatedCost) : ''} ${entity?.currency?.name || ''}`}>
                            {entity?.currency?.symbol} {entity?.estimatedCost ? parseFloat(entity?.estimatedCost!).toFixed(2) : ''}
                        </Tooltip>
                    </span>
                </Col>
            </Col>
        </Row>
        <Row>
            <Col span={24} className='pl-30 mb-15'>
                <Card
                    bodyStyle={{ paddingLeft: 12, paddingRight: 12, paddingTop: 8, paddingBottom: 8 }}
                    style={{ border: 0, borderRadius: '4px', backgroundColor: colorBgLayout }}>
                    <Row className="w-full">
                        <Space>
                            <span className="font-bold">{t("changeOrder.scheduleImpactLabel")}:</span>
                        </Space>
                        <Space>
                            <div className='flex flex-row ml-10'>
                                <span style={{ color: colorTextSecondary }}>{entity?.scheduleImpact === true ? "Yes" : "No"}</span>
                            </div>
                        </Space>
                        {entity?.scheduleImpactDays && <>
                            <Divider type="vertical" className='ml-20' style={{ height: '22px' }} />
                            <Space>

                                <div className='flex flex-row  ml-10'>
                                    <span style={{ color: colorTextSecondary }}>+ {entity?.scheduleImpactDays} {t("changeOrder.workDays")}</span>
                                </div>
                            </Space>
                        </>
                        }
                    </Row>
                </Card>
            </Col>
        </Row>
        <Row>
            <Col span={24} className='pl-30 mb-15'>
                <Card
                    bodyStyle={{ paddingLeft: 12, paddingRight: 12, paddingTop: 8, paddingBottom: 8 }}
                    style={{ border: 0, borderRadius: '4px', backgroundColor: colorBgLayout }}>
                    <Row className="w-full">
                        <Col>
                            <span className="font-bold">{t("changeOrder.drawingReferenceLabel")}:</span>
                            <span className="ml-10" style={{ color: colorTextSecondary }}>{entity?.drawingReference || <EmptyLabel />}</span>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
        <Row>
            <Col className="pl-30 mb-10">
                <Col>
                    <span>{t("changeOrder.rfiRelatedLabel")}:</span>
                    {entity?.relatedRfi ?
                        <Tag className="ml-10 pt-3 pb-3 pl-10 pr-10"
                            color="processing"
                            style={{ fontSize: fontSize, borderColor: colorInfoBg, borderRadius: '2px' }}>
                            {t("rfi.rfi")} #{entity?.relatedRfi?.number} - {entity?.relatedRfi?.category?.name}
                        </Tag>
                        : <EmptyLabel className="ml-10" />
                    }
                </Col>
            </Col>
        </Row>
        <Row className="pt-20">
            <Col span={6} className='text-left pl-10 pr-10'>
                <Button type="link" danger onClick={props.dismiss}>
                    {t("changeOrder.stepper.dismissButton")}
                </Button>
            </Col>
            <Col span={18} className='text-right pl-10 pr-10'>
                {entity?.lastStatusTransition?.status?.id !== ChangeOrdersStatusIds.PENDING_ESTIMATE_RESUBMISSION &&
                    <Button type="primary" ghost onClick={props.dismiss}>
                        {t("changeOrder.stepper.keepDraftButton")}
                    </Button>
                }
                <Button type="primary" className='ml-10' onClick={publishChangeOrder}>
                    {entity?.lastStatusTransition?.status?.id === ChangeOrdersStatusIds.PENDING_ESTIMATE_RESUBMISSION ? t("changeOrder.stepper.resubmitEstimateButton")
                        : t("changeOrder.stepper.publishButton")}
                </Button>
            </Col>
        </Row>
    </>);
}