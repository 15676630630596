/* eslint-disable react-hooks/exhaustive-deps */
import { If } from '@components/Utils/Structural';
import { Row, Empty, theme } from 'antd';
import { useAppSelector } from "@store/store";
import { ProjectDeliverableCard } from "./ProjectDeliverableCard";
import { useEffect } from 'react';

interface IProjectDeliverableListProps {}

export const ProjectDeliverableListFiltered = (props: IProjectDeliverableListProps) => {

    const { token: { colorTextQuaternary }} = theme.useToken();
    const { data: { deliverablesFiltered = [] }, allFilters, loadingList, lastScrollPosition } = useAppSelector((state) => state.ProjectDeliverables);

    useEffect(() => {
        if(lastScrollPosition !== null && !loadingList) {
            document.getElementById("containterDeliverableList")?.scroll?.(0,lastScrollPosition);
        }
    }, [lastScrollPosition, loadingList])
    
    return (
        <>
            <If condition={Boolean(deliverablesFiltered?.length < 1)}>
                <Row align={"middle"} className="flex flex-col pt-40 mt-40 w-full items-center justify-center">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    <If condition={(Object.keys(allFilters).length > 0)}>
                        <span className='mt-0 pt-0' style={{ color: colorTextQuaternary }}>
                            There are no matches for your search.
                        </span>
                    </If>
                </Row>
            </If>
            <If condition={Boolean(deliverablesFiltered?.length > 0)}>
                <div className="flex flex-col">
                    {/* Deliverable list */}
                    <div className='h-full pl-8'>
                        <div className='pb-8'>
                            {
                                deliverablesFiltered?.map((item: any, i: number) => (
                                    <div key={item.id}>
                                        <ProjectDeliverableCard 
                                            keyProp={item.id} 
                                            deliverable={item}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </If>
        </>
    )
}
