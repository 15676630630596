import { sortHistory } from "@store/slices/audit-history";
import { useAppDispatch, useAppSelector } from "@store/store";
import { HTMLProps, useState } from "react";
import { useTranslation } from "react-i18next";
import { theme } from "antd";
import { ArrowDownOutlined } from "@ant-design/icons";

export const SortingHistory = (props: HTMLProps<HTMLDivElement>) => {
    const { onClick, style, ...restProps } = props;

    const dispatch = useAppDispatch();
    const { settings } = useAppSelector((store) => store.AuditHistory);
    const { token: { colorBorder, fontSizeSM, colorTextTertiary, colorPrimaryTextHover, motionDurationMid, motionEaseInOut } } = theme.useToken();
    const { t } = useTranslation();
    const [isNewestFirstSelected, setIsNewestFirstSelected] = useState(true);

    const onSorting = () => {
        setIsNewestFirstSelected(!isNewestFirstSelected)
        const currentSort = settings?.lastFetchQueryParams?.newestFirst;
        dispatch(sortHistory({ newestFirst: !currentSort }));
    }

    return (
        <div
            className="flex flex-row items-center justify-center relative" 
        >   
            <div className="flex flex-row items-center mb-4 pb-4 mt-4 pt-4 pl-10 pr-10 font-light cursor-pointer"
                 style={{ color: colorBorder, ...style }}
                 onClick={onSorting}
                 {...restProps}
            >
                <span 
                    className="mr-5" 
                    style={{ fontSize: fontSizeSM, color: colorTextTertiary }} 
                >
                    { isNewestFirstSelected ? t("audit.newestToOldest") : t("audit.oldestToNewest") }
                </span>
                <ArrowDownOutlined 
                    style={{ 
                        color: colorPrimaryTextHover,
                        transform: isNewestFirstSelected ? "rotate(0)" : "rotate(180deg)", 
                        transition: `all ${motionDurationMid} ${motionEaseInOut}` 
                    }} />
            </div>
        </div>
    )
}
