import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { genericAddressService } from '@services/generic-address.service';
import { IUtil } from '@models/utils';
import { IAddress, ICreateAddressRequest, IDeleteAddressRequest, IGetAddressRequest, IUpdateAddressRequest } from '@models/address.model';
import { asyncLaunchNotification } from '@store/slices/notification';
import { AddressReferenceEnumListOptions } from '@models/enumerations/address-reference-enum.model';
import { AddressReferenceEnum } from '@models/enumerations/address-reference-enum.model';

interface IStateEntity extends IUtil {
  data: IAddress[];
  loading: boolean;
  errorMessage: any;
}

interface IState {
  [AddressReferenceEnum.COMPANY]: IStateEntity;
  [AddressReferenceEnum.CONTACT]: IStateEntity;
  [AddressReferenceEnum.LOCATION]: IStateEntity;
  [AddressReferenceEnum.SITE]: IStateEntity;
}

const getInitialState: IStateEntity = {
  data: [],
  loading: false,
  errorMessage: null,
};

const initialState: IState = {
  [AddressReferenceEnum.COMPANY]: { ...getInitialState },
  [AddressReferenceEnum.CONTACT]: { ...getInitialState },
  [AddressReferenceEnum.LOCATION]: { ...getInitialState },
  [AddressReferenceEnum.SITE]: { ...getInitialState },
};
const sliceName: string = 'genericAddress';

const actionGetAddressList = async (payload: IGetAddressRequest) => {
  const data = await genericAddressService.getAddressesList(payload);
  return data;
};

const actionCreateAddress = async (payload: ICreateAddressRequest, thunkAPI: any) => {
  const data = await genericAddressService.createAddress(payload);
  if (data && data.data) {
    thunkAPI.dispatch(
      asyncLaunchNotification({
        type: 'success',
        config: {
          message: `Address`,
          description: `Address created successfully`,
        },
      })
    );
    const payloadGetListAddresss = {
      referenceId: payload.referenceId,
      referenceType: payload.referenceType,
    };
    thunkAPI.dispatch(getAddressesList(payloadGetListAddresss));
  }
  return data;
};

const actionUpdateAddress = async (payload: IUpdateAddressRequest, thunkAPI: any) => {
  const data = await genericAddressService.updateAddress(payload.address);
  if (data && data.data) {
    thunkAPI.dispatch(
      asyncLaunchNotification({
        type: 'success',
        config: {
          message: `Address`,
          description: `Address updated successfully`,
        },
      })
    );
    const payloadGetListAddresss = {
      referenceId: payload.referenceId,
      referenceType: payload.referenceType,
    };
    thunkAPI.dispatch(getAddressesList(payloadGetListAddresss));
  }
  return data;
};

const actionDeleteAddress = async (deleteResquest: IDeleteAddressRequest, thunkAPI: any) => {
  const data = await genericAddressService.deleteAddress(deleteResquest);
  if (data && data) {
    thunkAPI.dispatch(
      asyncLaunchNotification({
        type: 'success',
        config: {
          message: `Address`,
          description: `Address delete successfully`,
        },
      })
    );
    const payloadGetListAddresss = {
      referenceId: deleteResquest.referenceId,
      referenceType: deleteResquest.referenceType,
    };
    thunkAPI.dispatch(getAddressesList(payloadGetListAddresss));
  }
  return data;
};

export const getAddressesCompanyList = createAsyncThunk(`${sliceName}/${AddressReferenceEnum.COMPANY}/getAddressList`, actionGetAddressList)
export const createAddressCompany = createAsyncThunk(`${sliceName}/${AddressReferenceEnum.COMPANY}/createAddress`, actionCreateAddress)
export const updateAddressCompany = createAsyncThunk(`${sliceName}/${AddressReferenceEnum.COMPANY}/updateAddress`, actionUpdateAddress)
export const deleteAddressCompany = createAsyncThunk(`${sliceName}/${AddressReferenceEnum.COMPANY}/deleteAddress`, actionDeleteAddress)

export const createAddressContact = createAsyncThunk(`${sliceName}/${AddressReferenceEnum.CONTACT}/createAddress`, actionCreateAddress);
export const updateAddressContact = createAsyncThunk(`${sliceName}/${AddressReferenceEnum.LOCATION}/updateAddress`, actionUpdateAddress);
export const deleteAddressContact = createAsyncThunk(`${sliceName}/${AddressReferenceEnum.CONTACT}/deleteAddress`, actionDeleteAddress);
export const getAddressesContactList = createAsyncThunk(
  `${sliceName}/${AddressReferenceEnum.CONTACT}/getAddressList`,
  actionGetAddressList
);

export const getAddressesLocationList = createAsyncThunk(
  `${sliceName}/${AddressReferenceEnum.LOCATION}/getAddressList`,
  actionGetAddressList
);
export const createAddressLocation = createAsyncThunk(`${sliceName}/${AddressReferenceEnum.LOCATION}/createAddress`, actionCreateAddress);
export const updateAddressLocation = createAsyncThunk(`${sliceName}/${AddressReferenceEnum.LOCATION}/updateAddress`, actionUpdateAddress);
export const deleteAddressLocation = createAsyncThunk(`${sliceName}/${AddressReferenceEnum.LOCATION}/deleteAddress`, actionDeleteAddress);

export const getAddressesSiteList = createAsyncThunk(`${sliceName}/${AddressReferenceEnum.SITE}/getAddressList`, actionGetAddressList);
export const createAddressSite = createAsyncThunk(`${sliceName}/${AddressReferenceEnum.SITE}/createAddress`, actionCreateAddress);
export const updateAddressSite = createAsyncThunk(`${sliceName}/${AddressReferenceEnum.SITE}/updateAddress`, actionUpdateAddress);
export const deleteAddressSite = createAsyncThunk(`${sliceName}/${AddressReferenceEnum.SITE}/deleteAddress`, actionDeleteAddress);

export const getAddressesList = createAsyncThunk(`${sliceName}/getData`, async (payload: IGetAddressRequest, thunkAPI) => {
  const thunkToApply = genericAddressThunkMap(`LIST-${payload.referenceType}`);
  thunkAPI.dispatch(thunkToApply(payload));
});

export const createAddress = createAsyncThunk(`${sliceName}/createData`, async (payload: ICreateAddressRequest, thunkAPI) => {
  const thunkToApply = genericAddressThunkMap(`CREATE-${payload.referenceType}`);
  thunkAPI.dispatch(thunkToApply(payload));
});

export const updateAddress = createAsyncThunk(`${sliceName}/updateData`, async (payload: IUpdateAddressRequest, thunkAPI) => {
  const thunkToApply = genericAddressThunkMap(`UPDATE-${payload.referenceType}`);
  thunkAPI.dispatch(thunkToApply(payload));
});

export const deleteAddress = createAsyncThunk(`${sliceName}/deleteData`, async (payload: IDeleteAddressRequest, thunkAPI) => {
  const thunkToApply = genericAddressThunkMap(`DELETE-${payload.referenceType}`);
  thunkAPI.dispatch(thunkToApply(payload));
});

export const GenericAddressSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reset: state => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        isFulfilled(getAddressesContactList, getAddressesLocationList, getAddressesCompanyList, getAddressesSiteList),
        (state, action) => {
          const entityIndex = getAddressReferenceType(action.type);
          state[entityIndex].data = action.payload;
          state[entityIndex].errorMessage = null;
          state[entityIndex].loading = false;
        }
      )
      .addMatcher(
        isPending(getAddressesContactList, getAddressesLocationList, getAddressesCompanyList, getAddressesSiteList),
        (state, action) => {
          const entityIndex = getAddressReferenceType(action.type);
          state[entityIndex].errorMessage = null;
          state[entityIndex].loading = true;
        }
      )
      .addMatcher(
        isRejected(getAddressesContactList, getAddressesLocationList, getAddressesCompanyList, getAddressesSiteList),
        (state, action) => {
          const entityIndex = getAddressReferenceType(action.type);
          state[entityIndex].errorMessage = action?.error?.message || null;
          state[entityIndex].loading = false;
        }
      );
  },
});

export const { reset } = GenericAddressSlice.actions;

// Helpers
const getAddressReferenceType = (actionString: string) => actionString.split('/')[1] as AddressReferenceEnumListOptions;

const genericAddressThunkMap = (addressReference: string) => {
  const mapThunksEnabled = {
    [`LIST-${AddressReferenceEnum.COMPANY}`]: getAddressesCompanyList,
    [`LIST-${AddressReferenceEnum.LOCATION}`]: getAddressesLocationList,
    [`LIST-${AddressReferenceEnum.CONTACT}`]: getAddressesContactList,
    [`LIST-${AddressReferenceEnum.SITE}`]: getAddressesSiteList,
    [`CREATE-${AddressReferenceEnum.LOCATION}`]: createAddressLocation,
    [`CREATE-${AddressReferenceEnum.SITE}`]: createAddressSite,
    [`CREATE-${AddressReferenceEnum.COMPANY}`]: createAddressCompany,
    [`CREATE-${AddressReferenceEnum.CONTACT}`]: createAddressContact,
    [`UPDATE-${AddressReferenceEnum.LOCATION}`]: updateAddressLocation,
    [`UPDATE-${AddressReferenceEnum.CONTACT}`]: updateAddressContact,
    [`UPDATE-${AddressReferenceEnum.SITE}`]: updateAddressSite,
    [`UPDATE-${AddressReferenceEnum.COMPANY}`]: updateAddressCompany,
    [`DELETE-${AddressReferenceEnum.LOCATION}`]: deleteAddressLocation,
    [`DELETE-${AddressReferenceEnum.CONTACT}`]: deleteAddressContact,
    [`DELETE-${AddressReferenceEnum.SITE}`]: deleteAddressSite,
    [`DELETE-${AddressReferenceEnum.COMPANY}`]: deleteAddressCompany
  }
  return mapThunksEnabled[addressReference as keyof typeof mapThunksEnabled] as any;
};

// Reducer
export default GenericAddressSlice.reducer;
