import { AuditHistoryDrawer } from "./AuditHistoryDrawer";
import { AuditHistoryDrawerContent } from "./AuditHistoryDrawerContent";
import { AuditHistoryContexProvider } from "./AuditHistoryProvider";

export const AuditHistory = () => {
    
    return (
        <AuditHistoryContexProvider>
            <AuditHistoryDrawer>
                <AuditHistoryDrawerContent />
            </AuditHistoryDrawer>
        </AuditHistoryContexProvider>
      );
}
