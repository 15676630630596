import { IQueryParams } from '../../models/pagination';
import { http } from '../http/axios.instance';
import { StringORNumber } from './utils.repository';
import { ISiteStatus } from '../../models/site-status.model';

const path = 'site-statuses';

export const siteStatusesRepository = {
  getAll: async (queryParams: IQueryParams) => {

    const { sort, page, size } = queryParams;
    const params = new URLSearchParams({
      ...sort && { sort: `${sort}` },
      ...page && { page: `${page}` },
      ...size && { size: `${size}` },
      cacheBuster: `${new Date().getTime()}`
    })
    const requestUrl = `${path}?${params.toString()}`;
  
    const promiseGetAll = await http.get<ISiteStatus[]>(requestUrl);
    return promiseGetAll;
  },
  getById: async (id: StringORNumber) => {

    const requestUrl = `${path}/${id}`;
    const promiseGetByID = await http.get<ISiteStatus>(requestUrl);
    return promiseGetByID;
  },
  create: async (entity: ISiteStatus): Promise<ISiteStatus> => {

    const requestUrl = `${path}`;
    const promiseCreate = await http.post<ISiteStatus>(requestUrl, entity);
    return promiseCreate.data;
  },
  update: async (id: string | number, entity: ISiteStatus): Promise<ISiteStatus> => {

    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.put<ISiteStatus>(requestUrl, entity);
    return promiseUpdate.data;
  },
  delete: async (id: string | number) => {

    const requestUrl = `${path}/${id}`;
    const promiseDelete = await http.delete<ISiteStatus>(requestUrl);
    return promiseDelete;
  },
  reorder: async (siteStatusIds: number[]) => {
    const requestUrl = `${path}/reorder`;
    const promiseUpdate = await http.put<ISiteStatus>(requestUrl, siteStatusIds);
    return promiseUpdate;
  },
}