/* eslint-disable react-hooks/exhaustive-deps */
import { IProjectDeliverableUI } from "@models/project-deliverable";
import { Col, Row, Checkbox, Divider } from "antd";
import React, { useEffect } from "react";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { t } from "i18next";
import { IDeliverableRemoveActualDatesDisabledState, IDeliverableRemoveActualDatesCheckedState } from './DeliverableDetailsDependencyModal'

interface IDeliverableHandleTimelineModalContentProps {
    deliverable: IProjectDeliverableUI;
    allowRemoveDates: any,
    setCanRemoveStatus: React.Dispatch<React.SetStateAction<string>>,
    checked: IDeliverableRemoveActualDatesCheckedState,
    setChecked: React.Dispatch<React.SetStateAction<IDeliverableRemoveActualDatesCheckedState>>,
    disabledStatus: IDeliverableRemoveActualDatesDisabledState,
    setDisabledStatus: React.Dispatch<React.SetStateAction<IDeliverableRemoveActualDatesDisabledState>>
}

export const NOT_AVAILABLE_TO_REMOVE = "NOT_AVAILABLE_TO_REMOVE"
export const BOTH_ARE_NEEDED = "BOTH_ARE_NEEDED"
export const YES = "YES"
export const ONLY_START_DATE = "ONLY_START_DATE"
export const NO_DATES_FOUND = "NO_DATES_FOUND"
export const APPROVED = "APPROVED"
export const PENDING_APPROVAL = "PENDING_APPROVAL"
export const NOT_LOADED = "NOT_LOADED"

export const RenderMessageStates = Object.freeze({
    [NOT_AVAILABLE_TO_REMOVE]: "NOT_AVAILABLE_TO_REMOVE",
    [APPROVED]: "APPROVED",
    [PENDING_APPROVAL]: "PENDING_APPROVAL",
    [BOTH_ARE_NEEDED]: "BOTH_ARE_NEEDED",
    [YES]: "YES",
    [ONLY_START_DATE]: "ONLY_START_DATE",

})

export interface IDeliverableHandleTimelineModalContentRef {
    getValues: () => {
        dependency: number,
        offset: number
    }
}

export const DeliverableHandleTimelineModalContent = React.forwardRef((props: IDeliverableHandleTimelineModalContentProps, ref) => {

    const { allowRemoveDates,
        setCanRemoveStatus,
        checked,
        setChecked,
        disabledStatus,
        setDisabledStatus, deliverable } = props

    useEffect(() => {
        // Handle logic based on deliverable for setting canRemoveStatus
        if (deliverable.firstApproval?.status === APPROVED)
            setCanRemoveStatus(RenderMessageStates[APPROVED]);
        else if (deliverable.firstApproval?.status === PENDING_APPROVAL)
            setCanRemoveStatus(RenderMessageStates[PENDING_APPROVAL])
        else if (!deliverable.actualFinishDate && !deliverable.actualStartDate)
            setCanRemoveStatus(RenderMessageStates[NOT_AVAILABLE_TO_REMOVE]);
        else if (deliverable.actualFinishDate && deliverable.actualStartDate)
            setCanRemoveStatus(RenderMessageStates[YES]);
        else if (deliverable.actualStartDate && !deliverable.actualFinishDate)
            setCanRemoveStatus(RenderMessageStates[ONLY_START_DATE]);


        // Handle logic based on allowRemoveDates for setting disabledStatus
        switch (allowRemoveDates) {
            case RenderMessageStates[YES]:
                setDisabledStatus({ ...disabledStatus, endDate: false });
                if (checked.startDate) {
                    setCanRemoveStatus(RenderMessageStates[BOTH_ARE_NEEDED]);
                    setDisabledStatus({ ...disabledStatus, endDate: true });
                }
                break;
            case RenderMessageStates[BOTH_ARE_NEEDED]:
                if (checked.startDate) {
                    setCanRemoveStatus(RenderMessageStates[BOTH_ARE_NEEDED]);
                    setDisabledStatus({ ...disabledStatus, endDate: true });
                } else {
                    setDisabledStatus({ ...disabledStatus, endDate: false });
                }
                break;
            case RenderMessageStates[ONLY_START_DATE]:
                setDisabledStatus({ ...disabledStatus, endDate: true });
                break;
            case RenderMessageStates[NOT_AVAILABLE_TO_REMOVE]:
            case RenderMessageStates[APPROVED]:
                setDisabledStatus({ startDate: true, endDate: true });
                break;
            default:
                setDisabledStatus({ ...disabledStatus, endDate: false });
        }
    }, [deliverable, checked.startDate, allowRemoveDates]);



    const onChange = (e: CheckboxChangeEvent) => {
        setChecked({ ...checked, [`${e.target.name}`]: e.target.checked });
    };


    const renderAlertMessage = () => {
        switch (allowRemoveDates) {
            case RenderMessageStates[APPROVED]:
                return (t(`projectDeliverable.deliverablesAlreadyApprovedInfo`))
            case RenderMessageStates[PENDING_APPROVAL]:
                return (t(`projectDeliverable.deliverablePendingApprovalInfo`))
            case RenderMessageStates[ONLY_START_DATE]:
                return (t(`projectDeliverable.endDateNotSetInfo`))
            case RenderMessageStates[BOTH_ARE_NEEDED]:
                return (t(`projectDeliverable.startDateEndDateBothNeededRemovalInfo`))
            case RenderMessageStates[NOT_AVAILABLE_TO_REMOVE]:
                return (t(`projectDeliverable.noDateIsAvailableToRemoveInfo`))
        }
    }


    return (
        <Col>
            <Row className="mb-10">
                <Checkbox name="startDate" checked={checked.startDate} disabled={[NOT_AVAILABLE_TO_REMOVE, PENDING_APPROVAL, NOT_LOADED].includes(allowRemoveDates) || disabledStatus.startDate} onChange={onChange}>
                    {t(`projectDeliverable.removeActualStartDate`)}
                </Checkbox>
            </Row>
            <Row>
                <Checkbox name="endDate" checked={checked.endDate} disabled={[NOT_AVAILABLE_TO_REMOVE, PENDING_APPROVAL, NOT_LOADED].includes(allowRemoveDates) || disabledStatus.endDate} onChange={onChange}>
                    {t(`projectDeliverable.removeActualEndDate`)}
                </Checkbox>
            </Row>
            <Divider />
            {!(allowRemoveDates === "YES") && <Row style={{ flexWrap: "nowrap" }}>
                <Row className="pt-6 pr-12">
                    <InfoCircleTwoTone twoToneColor="#2fa9eb" style={{ alignSelf: "flex-start" }} />
                </Row>
                <span>
                    {renderAlertMessage()}
                </span>
            </Row>}
        </Col>

    )
})