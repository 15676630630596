import { Row, theme } from 'antd';
import { CompaniesGenericTable } from '@components/CompaniesGeneric/CompaniesGenericTable';
import { useParams } from 'react-router-dom';

export const ListCompanies = () => {
    const {
        token: { marginLG },
    } = theme.useToken();

    const { projectId } = useParams<'projectId'>();

    return (
      <div className="flex flex-row mt-30" style={{ paddingLeft: `${marginLG}px`, paddingRight: `${marginLG}px` }}>
        <div className="flex flex-col w-full">
          <Row className="overflow-auto relative">
            <CompaniesGenericTable referenceType="PROJECT" referenceId={projectId} tableCustomizedProps={{ scroll: { y: 320 } }} />
          </Row>
        </div>
      </div>
    );
};
