export const getKeyByValue = (value: string, object: Object) => {
    const indexOfS = Object.values(object).indexOf(value);
  
    const key = Object.keys(object)[indexOfS];
  
    return key;
}

export const ArrayToObject = (list: any[], index?: (item: any, internalIndex: number) => string) => {
    return list.reduce((acc, item, internalIndex) => {
        acc[index ? index(item, internalIndex) : internalIndex ] = item
        return acc;
    }, {})
}

export const allPropsOfObjectAreUndefined = (object: Object) => {
    return Object.values(object).every((item) => item === undefined)
}

export const sortObjectByKey = (obj: any) => {

    // Step 1: Extract keys
    const keys = Object.keys(obj);
  
    // Step 2: Sort keys
    keys.sort();
  
    // Step 3: Create a new object with sorted keys
    const sortedObject = {};
    for (const key of keys) {
      sortedObject[key] = obj[key];
    }
  
    return sortedObject;
  }



