/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Skeleton, Typography, theme } from "antd";
import { useProviderColor } from "@HOOKs/UseProviderColor";
import { EmptyLabel } from "@components/Utils/EmptyLabel";
import { DEFAULT_LABEL_PROJECT_OPENING } from "@constants/core";
import { SECONDARY_TEXT_COLOR, TERTIARY_TEXT_COLOR } from "@providers/ThemeProvider";
import { formatDateTargetOpening, formatDatetimePipe } from "@shared/util/date-utils";
import { pascalCaseFirstWord } from "@shared/util/string-utils";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getProjectOverviewData } from "@store/slices/project-overview";
import { OverviewProjectStatusSelect } from './OverviewProjectStatusSelect';

export const OverviewHeader = () => {
  const {
    token: { marginLG, fontSize, colorText, fontSizeHeading2 },
  } = theme.useToken();
  const [colorGreyStrong] = useProviderColor(SECONDARY_TEXT_COLOR);
  const [colorGreyLight] = useProviderColor(TERTIARY_TEXT_COLOR);

  const dispatch = useAppDispatch();
  const { projectId } = useParams<'projectId'>();
  const {
    data: { headerData },
    loading,
  } = useAppSelector(state => state.ProjectOverview);

  useEffect(() => {
    dispatchGetProjectOverviewData();
  }, [projectId]);

  const dispatchGetProjectOverviewData = () => projectId && dispatch(getProjectOverviewData(projectId));

  return (
    <div className="flex flex-row justify-between items-center mt-40 mb-40" style={{ paddingLeft: marginLG, paddingRight: marginLG }}>
      <Skeleton loading={loading} paragraph={{ rows: 2 }} active={true}>
        <Row justify={'space-between'} className="w-full">
          <Col flex={'none'} className="flex flex-col">
            <span className="mb-5" style={{ fontSize: fontSize + 1, color: colorGreyStrong }}>
              <span className="pr-5 font-bold" style={{ color: colorText }}>
                {headerData?.targetOpeningType ? pascalCaseFirstWord(`${headerData?.targetOpeningType}`) : DEFAULT_LABEL_PROJECT_OPENING}:
              </span>
              {headerData?.lastMilestoneName || <EmptyLabel />}
            </span>

            <Typography.Title level={2} className="font-bold" style={{ fontSize: fontSizeHeading2 + 2 }}>
              {(headerData.targetOpening && formatDateTargetOpening(headerData?.targetOpening)) || <EmptyLabel />}
            </Typography.Title>
          </Col>

          <Col flex={'none'} className="flex flex-row items-center">
            <Row className="flex flex-row items-center w-full">
              <Col flex={'none'} className="flex flex-col pr-40">
                <span className="font-bold" style={{ fontSize: fontSize + 1, color: colorGreyLight }}>
                  Weekly Status
                </span>
                <span className="mt-10 font-normal" style={{ fontSize: fontSize - 1, color: colorGreyStrong }}>
                  Last Updated:{' '}
                  {(headerData?.weeklyStatusLastUpdated &&
                    headerData?.weeklyStatusLastUpdated !== null &&
                    formatDatetimePipe(headerData?.weeklyStatusLastUpdated)) || <EmptyLabel />}
                </span>
              </Col>
              <Col flex={'none'} style={{ minWidth: '7rem', maxWidth: 200 }}>
                <OverviewProjectStatusSelect defaultValue={headerData?.weeklyStatus?.name} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Skeleton>
    </div>
  );
};
