import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

interface ISpinnerProps extends  React.ComponentPropsWithRef<typeof Spin> {
  iconFontSize?: number;
}

export const Spinner = (props: ISpinnerProps) => {

    const { indicator, iconFontSize = 36, ...restProps  } = props;
    
    return (
      <>
        <Spin indicator={<LoadingOutlined style={{ fontSize: iconFontSize }} spin={true} />} {...restProps} />
      </>
    )
}
