import { TabContainer } from "@components/Utils/TabContainer";
import { BodySearch } from "./BodySearch";

export const SearchPageBodyContent = () => {

    return (
        <TabContainer>
          <BodySearch />
        </TabContainer>
    )
}
