import { AxiosProgressEvent } from 'axios';
import { AttachmentReferenceEnum } from './enumerations/attachment-reference-enum.model';
import { DerivativesEnum } from './enumerations/derivatives-enum.model';
import { ProgressAriaProps } from 'antd/es/progress';
import { RcFile, UploadFileStatus } from 'antd/es/upload/interface';
export interface IAttachment {
  id?: number;
  fileName?: string | null;
  referenceType?: AttachmentReferenceEnum | null;
  referenceId?: number | string | null;
  createdBy?: string;
  createdByFirstName?: string;
  createdByLastName?: string;
  createdByEmail?: string;
  fileSize?: number | null;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  versions?: IAttachment[];
  versionReferenceId?: string;
  attachment?: IAttachment | null;
  derivatives?: IDerivatives[];
  totalComments?: number;
}
export interface IDerivatives {
  id?: number,
  signedUrl?: string,
  pageNumber?: number,
  derivativeType?: DerivativesEnum,
  attachment: unknown
}

export interface FilePreviewData extends IAttachment {
  urlImage?: string,
  extension?: string,
  isAnValidImage?: boolean,
  isPdf?: boolean
}

export type IOnUploadProgress = (config: AxiosProgressEvent) => void | undefined

export const defaultValue: Readonly<IAttachment> = {};

export interface IAttachmentGetRequestParams {
  page?: number;
  size?: number;
}
export interface UploadFileCustom<T = any> extends ProgressAriaProps {
  uid: string;
  size?: number;
  name: string;
  fileName?: string;
  lastModified?: number;
  lastModifiedDate?: Date;
  url?: string;
  status?: UploadFileStatus;
  percent?: AxiosProgressEvent;
  thumbUrl?: string;
  crossOrigin?: React.ImgHTMLAttributes<HTMLImageElement>['crossOrigin'];
  originFileObj?: RcFile;
  response?: T;
  error?: any;
  linkProps?: any;
  type?: string;
  xhr?: T;
  preview?: string;
}

export type onProgressEvent = unknown | any;