/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, Tabs, TabsProps, theme } from 'antd'
import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { AdminSearch } from './AdminSearch';
import { useTranslation } from "react-i18next";

export const AdminTabs = () => {

    const { token: { colorBgLayout, colorBgContainer, marginLG, colorBorderSecondary } } = theme.useToken();

    const navigate = useNavigate();
    const { t } = useTranslation();

    const [modalOpen, setModalOpen] = useState(false);

    const items: TabsProps['items'] = [
      {
          key: '1',
          label: t("admin.overview"),
          className: "h-full",
          children: null,
      },
      {
          key: '2',
          label: t("admin.accessAndPermissions"),
          className: 'h-full',
          children: null
      },
      {
          key: '3',
          label: t("admin.applicationConfiguration"),
          className: 'h-full',
          children: null
      },
      {
          key: '4',
          label: t("admin.moduleConfiguration"),
          className: "h-full"
      },
      {
        key: '5',
        label: t("admin.filePreviewGeneration"),
        className: "h-full",
        children: null
      },
    ];

    const getUrlFromKey = (key: string): string => {
      if (key === '1') {
          return '/admin';
      } else if (key === '2') {
          return '/admin/access-and-permissions';
      } else if (key === '3') {
          return '/admin/application-configuration';
      } else if (key === '4') {
        return '/admin/module-configuration';
      } else if (key === '5') {
        return '/admin/file-preview-utils';
      }

      return '/admin';
    }

    const getDefaultActiveKey = (): string => {
      const url = window.location.pathname;
      if (url.includes(`/access-and-permissions`)) {
          return '2';
      } else if (url.includes(`/application-configuration`)) {
          return '3';
      } else if (url.includes(`/module-configuration`)) {
          return '4';
      } else if (url.includes(`/file-preview-utils`)) {
          return '5';
      }
      return '1';
    }

    const defaultActiveKey = getDefaultActiveKey();

    return (
        <>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                    <Tabs
                        defaultActiveKey={defaultActiveKey}
                        items={items}
                        className='mb-0'
                        style={{ backgroundColor: colorBgLayout, borderTop: `1px solid ${colorBorderSecondary}`, height: 'auto'}}
                        tabBarStyle={{ backgroundColor: colorBgContainer, paddingLeft: `${marginLG}px`, marginBottom: 0 }}
                        onTabClick={(key: string, event: any) => { event.stopPropagation(); navigate(getUrlFromKey(key)); }}
                    />
                </div>
                <div style={{ paddingRight: '20px', alignContent: 'center', flex: 0, backgroundColor: colorBgContainer, borderTop: `1px solid ${colorBorderSecondary}`, borderBottom: `1px solid ${colorBorderSecondary}` }}>
                    <Button type="primary" ghost onClick={() => { setModalOpen(true) }}>Search</Button>
                </div>
            </div>
            <Outlet />
            {modalOpen && <Modal
                title={t("admin.searchAdminOptions")}
                style={{ top: 20 }}
                open={modalOpen}
                onCancel={() => setModalOpen(false)}
                footer={null}
                width={600}
            >
                <AdminSearch />
            </Modal>}
        </>
    )
}
