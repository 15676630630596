import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "@store/store";
import { Button, Col, Drawer, Form, Input, Row, Select, Space } from "antd";
import { IAddress, ICreateAddressRequest } from '@models/address.model';
import { AutoCompleteAddressCustom } from "@components/Locations/AutocompleteAddress/AutoCompleteAddressCustom";
import { getEntities as getAddressTypes } from '@store/slices/address-types';
import { createAddress } from '@store/slices/generic-address';
import { AddressReferenceEnumListOptions } from '@models/enumerations/address-reference-enum.model';
import { AddressMap } from './AddressMap';
import { isArrayWithValues } from "@shared/util/array-util";
import { StringORNumber } from "@infrastructure/repositories/utils.repository";
import { IOptionMap } from "./AddressGenericUpdate";
import { useTranslation } from 'react-i18next';

export interface IAddressCreateProps {
  open: boolean;
  toggle: () => void;
  referenceId?: StringORNumber;
  referenceType: AddressReferenceEnumListOptions;
}

export const AddresGenericCreate = (props: IAddressCreateProps) => {
  const { open, toggle, referenceId, referenceType } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { entities: addressTypes } = useAppSelector(state => state.AddressTypes);

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getAddressTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (open && isArrayWithValues(addressTypes) && form) {
      form.setFieldValue('addressType', addressTypes[0].id);
    }
  }, [open, form, addressTypes]);

  const onClose = (): void => {
    toggle();
  };

  const onAfterClose = (open: boolean) => {
    if (open) return;

    if (form) {
      form.resetFields();
    }
    setHiddenFields({});
    setAddressInputValue('');
    setOptionMap({ markerOptions: [], placeResult: null });
  };

  const [hiddenFields, setHiddenFields] = useState<Partial<IAddress>>();
  const [optionMap, setOptionMap] = useState<IOptionMap>({ markerOptions: [], placeResult: null });

  const onFinishFailed = (errorInfo: any) => {
    //
  };

  const onSubmit = () => {
    form.submit();
  };

  const [addressInputValue, setAddressInputValue] = useState('');

  const onFinish = (entity: any): void => {
    if (!referenceId) return;

    const payload: ICreateAddressRequest = {
      referenceId,
      referenceType: referenceType,
      address: {
        ...entity.address,
        streetAddress: addressInputValue,
        ...hiddenFields,
        addressType: {
          id: entity.addressType,
        },
      },
    };

    dispatch(createAddress(payload)).then(() => onClose());
  };

  const onSelectItemInAutocomplete = (address: any) => {
    const { subAddress = '', city = '', stateProvince = '', postalCode = '', country, latitude, longitude, googleMapPlace } = address;
    form.setFieldsValue({
      address: {
        streetAddress2: subAddress,
        city: city,
        stateProvince: stateProvince,
        postalCode: postalCode,
        country: country,
      },
    });
    setHiddenFields({
      latitude,
      longitude,
    });

    const marker = [
      {
        position: { lat: +latitude, lng: +longitude },
        gmpClickable: true,
        extraData: address,
      },
    ];
    setOptionMap({ markerOptions: marker, placeResult: googleMapPlace });
  };

  const onChangeAddressValue = (text: string) => {
    setAddressInputValue(text);
    form.setFieldsValue({
      address: {
        streetAddress: text,
      },
    });
  };

  return (
    <>
      <Drawer
        title={t('generic.address.addAddress')}
        width={500}
        onClose={onClose}
        afterOpenChange={onAfterClose}
        open={open}
        closable={false}
        styles={{
          body: { paddingBottom: 80 },
        }}
        extra={
          <Space>
            <Button type="default" onClick={onClose}>
              {t('generic.cancel')}
            </Button>
            <Button onClick={onSubmit} type="primary">
              {t('generic.save')}
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="addressType" label={t('generic.address.addressType')} rules={[{ required: true, message: '' }]}>
                <Select
                  placeholder={t('generic.address.addressType')}
                  options={addressTypes.map(item => ({ value: item.id, label: item?.description }))}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name={['address', 'streetAddress']} label={t('generic.address.addressLine1')}>
                <AutoCompleteAddressCustom
                  onChangeAutocomplete={onSelectItemInAutocomplete}
                  onChangeValueInput={onChangeAddressValue}
                  placeholder={t('generic.address.addressLine1Placeholder')}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name={['address', 'streetAddress2']} label={t('generic.address.addressLine2')}>
                <Input placeholder={t('generic.address.addressLine2Placeholder')} allowClear />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={8}>
              <Form.Item name={['address', 'city']} label={t('generic.address.city')}>
                <Input placeholder={t('generic.address.city')} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={['address', 'stateProvince']} label={t('generic.address.state')}>
                <Input placeholder={t('generic.address.state')} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={['address', 'postalCode']} label={t('generic.address.postalCode')}>
                <Input placeholder={t('generic.address.postalCode')} allowClear />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name={['address', 'country']} label={t('generic.address.country')}>
                <Input placeholder={t('generic.address.country')} allowClear />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name={['address', 'phone']} label={t('generic.address.phoneNumber')}>
                <Input placeholder={t('generic.address.phoneNumber')} allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={['address', 'email']} label={t('generic.address.email')}>
                <Input placeholder={t('generic.address.email')} allowClear />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>{open && <AddressMap optionMap={optionMap} />}</Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};
