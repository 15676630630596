import { CustomButtonText } from "@components/Utils/CustomButtonText";
import { FeatureFlagOptionEnum } from "@models/enumerations/feature-flag-option.enum";
import { useFeatureFlagsProvider } from "@providers/FeatureFlagsProvider";
import { Button, Card } from "antd"
import { useLayoutContex } from "@providers/LayoutProvider";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TabContainer } from "@components/Utils/TabContainer";

export const ApplicationConfigurationView = (props) => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { flags, isFlagEnabled } = useFeatureFlagsProvider();
    const isOxBlueEnabled = isFlagEnabled(FeatureFlagOptionEnum.OX_BLUE_INTEGRATION);
    const { setHeaderLabelEntityState } = useLayoutContex();

    useEffect(() => {
        setHeaderLabelEntityState(t("admin.applicationConfiguration"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const navigateToCostManagement = () => {
        navigate('/admin/application-configuration/cost-management');
    }

    const navigateToOtherSettings = () => {
        navigate('/admin/application-configuration/other-settings');
    }

    const navigateToIntegration = () => {
        navigate('/admin/application-configuration/integration');
    }

    const navigateToCustomFields = () => {
        navigate('/admin/application-configuration/custom-fields');
    }

    return <TabContainer>
            <div style={{ padding: '16px 24px' }}>
            <Card title="Cost Management" extra={<CustomButtonText type="default" onClick={navigateToCostManagement}>View</CustomButtonText>} className="ant-card-no-body admin-card" />
            <Card title="Other Settings" extra={<CustomButtonText type="default" onClick={navigateToOtherSettings}>View</CustomButtonText>} className="admin-card">
                <ul>
                    <li><Button type="link" onClick={navigateToOtherSettings}>Disciplines</Button></li>
                    <li><Button type="link" onClick={navigateToOtherSettings}>Roles</Button></li>
                    <li><Button type="link" onClick={navigateToOtherSettings}>Company Roles</Button></li>
                    <li><Button type="link" onClick={navigateToOtherSettings}>{t("admin.addressTypes")}</Button></li>
                    <li><Button type="link" onClick={navigateToOtherSettings}>{t("admin.markets")}</Button></li>
                    <li><Button type="link" onClick={navigateToOtherSettings}>{t("admin.brands")}</Button></li>
                </ul>
            </Card>
            {isOxBlueEnabled && <Card title={t("admin.integration")} extra={<CustomButtonText type="default" onClick={navigateToIntegration}>View</CustomButtonText>} className="admin-card">
                <ul>
                    <li><Button type="link" onClick={navigateToIntegration}>OxBlue</Button></li>
                </ul>
            </Card>}
            <Card title={t("admin.customFields")} extra={<CustomButtonText type="default" onClick={navigateToCustomFields}>{t("generic.view")}</CustomButtonText>} className="ant-card-no-body admin-card" />
        </div>
    </TabContainer>
}