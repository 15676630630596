import { IQueryParams } from '@models/pagination';
import { CompanyExtended, ICompany } from '../../models/company.model';
import { http } from '../http/axios.instance';

const pathExtended = 'companies-extended';
const path = 'companies';

export const companyExtendedRepository = {
  createCompany: async (entity: ICompany): Promise<ICompany> => {
    const requestUrl = `${pathExtended}`;
    const promiseCreate = await http.post<ICompany>(requestUrl, entity);
    return promiseCreate.data;
  },

  updateEntity: async (entity: ICompany): Promise<ICompany> => {
    const requestUrl = `${path}/${entity.id}`;
    const promiseCreate = await http.patch<ICompany>(requestUrl, entity);
    return promiseCreate.data;
  },

  getCompanies: async (queryParams: IQueryParams) => {
    const { sort, page, size, nameFilter = '' } = queryParams;
    const requestUrl = `${pathExtended}/sort-by-params${
      size ? `?page=${page}&size=${size}&sort=${sort}&name.contains=${nameFilter}&` : '?'
    }cacheBuster=${new Date().getTime()}`;

    const promiseGetAll = await http.get<CompanyExtended[]>(requestUrl);
    return promiseGetAll;
  },
};
