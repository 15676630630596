
import { http } from '@infrastructure/http/axios.instance';
import { IPunchListReason } from '@models/punch-list.model';

const path = 'punchlist-reasons';

export const punchListReasonRepository = {
  getPunchlistReasons: async () => {
    const requestUrl = `${path}?_=${new Date().getTime()}`;
    const promiseGetAll = await http.get<IPunchListReason[]>(requestUrl);
    return promiseGetAll;
  },
  create: async (entity: IPunchListReason): Promise<IPunchListReason> => {

    const requestUrl = `${path}`;
    const promiseCreate = await http.post<IPunchListReason>(requestUrl, entity);
    return promiseCreate.data;
  },
  update: async (id: string | number, entity: IPunchListReason): Promise<IPunchListReason> => {

    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.put<IPunchListReason>(requestUrl, entity);
    return promiseUpdate.data;
  },
  reorder: async (ids: number[]) => {
    const requestUrl = `${path}/reorder`;
    const promiseUpdate = await http.put<IPunchListReason>(requestUrl, ids);
    return promiseUpdate;
  },
}