import { ICommentRichText } from "./comment.model";
import { RfiImpactEnum } from "./enumerations/rfi-impact-enum.model";
import { RfiStatusEnum } from "./enumerations/rfi-status-enum.model";
import { IRfiReason } from "./rfi-reason.model";
import { IRfiCategory } from "./rfi-category.model";
import { IUser } from "./user.model";
import { IRfiDistribution } from "./rfi-distribution.model";

export interface IRfi {
    id?: number;
    number?: number;
    questionPayload?: string;
    status?: RfiStatusEnum;
    scheduleImpact?: RfiImpactEnum | null;
    costImpact?: RfiImpactEnum | null;
    projectId?: number;
    assignedTo?: IUser;
    category?: IRfiCategory;
    reason?: IRfiReason | null;
    officialAnswer?: ICommentRichText | null;
    createdBy?: string;
    createdById?: number;
    createdByFirstName?: string;
    createdByLastName?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    responsesQty?: number;
    dueDate?: string | null;
}

export const defaultValue: Readonly<IRfi> = {};

export interface IRfiWithDistributions {
    rfi: IRfi;
    distributions: IRfiDistribution[];
}
