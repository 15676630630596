import { Typography } from 'antd';

const { Text } = Typography;

export const EmptyLabelText = (props: React.ComponentPropsWithoutRef<typeof Text>) => {
  return (
    <Text {...props}>--</Text>
  )
}

export const EmptyLabel = (props: React.HTMLAttributes<HTMLSpanElement>) => {
    return (<span {...props}>--</span>)
}
