import { DEFAULT_BORDER_RADIUS } from "@providers/ThemeProvider";
import { useEffect } from "react";

interface ICustomBoxProps {
  children?: React.ReactNode;
  style: React.CSSProperties;
  className?: string;
}

export const CustomBox = (props: ICustomBoxProps) => {
  const { children = null, style, className = '' } = props;

  useEffect(() => {}, []);

  return (
    <div
      className={`flex flex-row items-center ${className}`}
      style={{
        borderRadius: DEFAULT_BORDER_RADIUS,
        ...style,
      }}
    >
      {children}
    </div>
  );
};
