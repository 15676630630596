import { CloseOutlined, DownloadOutlined, FileImageOutlined, FilePdfOutlined, InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { TrashSvgIcon } from "@components/Icons/TrashSvgIcon";
import { Col, Divider, Row, Tooltip, Typography, theme } from "antd";
import { orange, gold } from '@ant-design/colors';
import { EmptyLabel } from "@components/Utils/EmptyLabel";
import { capitalize } from "@shared/util/string-utils";
import { formatDatePipe } from '@shared/util/date-utils';
import { FilePreviewData } from "@models/attachment.model";
import { TextEllipsis } from "@components/Utils/TextEllipsisContent";
import { If } from '@components/Utils/Structural';
import { useState } from "react";
import { attachmentService } from "@services/attachment.service";
import { convertBytes } from "@shared/util/document-utils";

interface IInfoSideProps {
    close: () => void,
    fileData: FilePreviewData,
    previewLatestVersion: () => void,
    onDeleteAction: () => void,
    showModalForNewRevision: (attachmentId: number) => void,
    showRevisionPanel?: boolean
}

export const InfoSide = (props: IInfoSideProps) => {

    const { close, fileData, previewLatestVersion, onDeleteAction, showRevisionPanel = true } = props;

    const { versions = [] } = fileData;
    const latestVersion = versions[0] || null;

    const { token: { colorText, colorBorder, colorBorderSecondary, colorTextTertiary, colorBgElevated, colorBgLayout, colorErrorHover, colorPrimary } } = theme.useToken();

    const [loadingDownload, setLoadingDownload] = useState(false);

    const onClicDownload = () => {
        fileData.id && fileData.fileName && onDownloadAction(String(fileData.id), fileData.fileName)
    }

    const onDownloadAction = (attachmentId: string, fileName: string): Promise<void> => {
        setLoadingDownload(true);
        return attachmentService.getViewLink(attachmentId).then((response) => {
            if (response) window.open(`${response.data}`, "_blank");
        })
            .finally(() => setLoadingDownload(false));
    }

    return (
        <>
            <div className="relative pl-20 pr-20 pb-40 h-full">

                {/* Header */}
                <div className="pr-5 pb-10">
                    <Typography.Title level={5} className="mt-0 mb-0 mr-0 ml-0 pt-20 pr-40" style={{ color: colorText, minHeight: 60 }}>
                        {fileData.fileName || <EmptyLabel />}
                    </Typography.Title>
                </div>
                <div
                    title={"Close"}
                    className="flex flex-row items-center justify-center absolute cursor-pointer"
                    onClick={() => close()}
                    style={{
                        width: "2.5rem",
                        height: "2.5rem",
                        backgroundColor: colorBgLayout,
                        border: `1px solid ${colorBorderSecondary}`,
                        top: '1.4rem',
                        right: '1rem',
                        borderRadius: '50%'
                    }}>
                    <CloseOutlined />
                </div>

                {/* Metadata */}
                <div className="mt-5 pt-20 pb-20 pl-20 pr-20" style={{ border: `1px solid ${colorBorder}`, color: colorText, backgroundColor: colorBgLayout, borderRadius: 4 }}>
                    <Row className="pb-4">
                        <Col span={12} className="font-bold">Format:</Col>
                        <Col span={12} style={{ color: colorTextTertiary }} className="flex flex-row items-center">
                            {fileData?.isAnValidImage ? <FileImageOutlined className="mr-5" /> : <FilePdfOutlined className="mr-5" />}
                            {fileData?.isAnValidImage ? `${fileData.extension?.toUpperCase()} Image ` : `${fileData.extension?.toUpperCase()} File`}
                        </Col>
                    </Row>
                    <Row className="pb-4">
                        <Col span={12} className="font-bold">Size:</Col>
                        <Col span={12} style={{ color: colorTextTertiary }} >
                            {(fileData.fileSize && convertBytes(fileData.fileSize)) || <EmptyLabel />}
                        </Col>
                    </Row>
                    <Row className="pb-4">
                        <Col span={12} className="font-bold">Creator:</Col>
                        <Col span={12} style={{ color: colorTextTertiary }} >
                            <If condition={!!(fileData.createdBy)}>
                                <TextEllipsis
                                    text={capitalize(`${fileData.createdByFirstName} ${fileData.createdByLastName}`)}
                                    ellipsis={{
                                        tooltip: {
                                            title: fileData.createdByEmail,
                                            rootClassName: "fullMaxWidth"
                                        }
                                    }}
                                    style={{ color: colorTextTertiary }}
                                />
                            </If>
                        </Col>
                    </Row>
                    <Row className="pb-4">
                        <Col span={12} className="font-bold">Upload Date:</Col>
                        <Col span={12} style={{ color: colorTextTertiary }} >
                            {(fileData.createdDate && formatDatePipe(fileData.createdDate)) || <EmptyLabel />}
                        </Col>
                    </Row>
                </div>
                                    
                <If condition={Boolean(showRevisionPanel)}>
                    <If condition={(latestVersion?.id !== fileData.id)}>
                        <div className="mt-20 pt-20 pb-20 pl-20 pr-20" style={{ border: `1px solid ${orange[2]}`, color: gold[9], backgroundColor: gold[0], borderRadius: 4 }}>
                            <Row className="pb-4 text-center">
                                <Col span={24} className="font-bold">
                                    <InfoCircleOutlined className="" style={{ color: gold[5] }} />
                                </Col>
                            </Row>
                            <Row className="pb-4 text-center pt-5">
                                <Col span={24} className="font-normal pr-20 pl-20">This is not the latest existing version of this file.
                                    <span className="pl-4 underline cursor-pointer" onClick={() => previewLatestVersion()}>Click here to preview the latest version.</span>
                                </Col>
                            </Row>
                        </div>
                    </If>
                </If>                    

                {/* Buttons Panel */}
                <div className="absolute bottom-0 mt-15 right-0 left-0" style={{ backgroundColor: colorBgElevated }} >
                    <div className="sticky bottom-0 right-0 left-0 pl-20 pr-20">
                        <Divider className="mt-0 mb-10 pt-0 pb-0 w-full" />
                        <div className="flex flex-row items-center justify-center w-full pb-15">
                            <div className="flex flex-row items-center pl-20 pr-20 pt-7 pb-7" style={{ border: `1px solid ${colorBorder}`, color: colorText, backgroundColor: colorBgLayout, borderRadius: "2rem" }}>

                                <Tooltip placement="top" title={"Delete"}>
                                    <If condition={!!(fileData)}>
                                        <TrashSvgIcon onClick={() => onDeleteAction()} height="18" style={{ color: colorErrorHover }} className="ml-5 mr-5 cursor-pointer" />
                                    </If>
                                </Tooltip>

                                <Divider type={"vertical"} className="mt-0 mb-0 pl-0 pr-0 self-end" style={{ height: 16 }} />

                                <Tooltip placement="top" title={"Download"}>
                                    {
                                        loadingDownload
                                            ? <LoadingOutlined style={{ color: colorPrimary }} height="20" className="ml-5 mr-5 cursor-pointer" />
                                            : <DownloadOutlined onClick={() => onClicDownload()} height="20" style={{ color: colorPrimary }} className="ml-5 mr-5 cursor-pointer" />
                                    }
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
