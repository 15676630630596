/* eslint-disable react-hooks/exhaustive-deps */
import useVisible from "@HOOKs/UseVisible";
import { EntryTime } from "@components/Timeline/EntryTime";
import { ITimelineEntry } from "@models/timeline.model";
import { Button, Col, Divider, Row, Tag, theme } from "antd";
import { useEffect, useLayoutEffect, useState } from "react";
import { Reject } from "./Reject";
import { Approve } from "./Approve";
import { Resubmission } from "./Resubmission";
import { LockOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';
import { useAppSelector } from "@store/store";
import { ValidUntil } from "@components/Locations/Sites/Projects/ChangeOrdersTab/shared/ChangeOrderValidUntil";

export interface IEntryBodyProps {
    value: ITimelineEntry
}

export const EntryBody = (props: IEntryBodyProps) => {
    const { value } = props;
    const { token: { colorBgContainer, colorTextTertiary } } = theme.useToken();
    const [jsonDetail, setJsonDetail] = useState<any>(undefined)
    const { t } = useTranslation();
    const pendingStatus = "Pending";
    const approvedStatus = "Approved";
    const rejectedStatus = "Rejected";
    const [reviewStatus, setReviewStatus] = useState<string>(pendingStatus)
    const [closed, setClosed] = useState<boolean>(false)
    const [approved, setApproved] = useState<boolean>(false)
    const [primaryColor, setPrimaryColor] = useState<string | undefined>(undefined)
    const [secondaryColor, setSecondaryColor] = useState<string | undefined>(undefined)
    const [tertiaryColor, setTertiaryColor] = useState<string | undefined>(undefined)
    const [bgColor, setBgColor] = useState<string | undefined>(undefined)
    const [rejectDrawerOpen, toggleRejectDrawerOpen, setRejectDrawerOpen] = useVisible();
    const [askResubmissionDrawerOpen, toggleAskResubmissionDrawerOpen, setAskResubmissionDrawerOpen] = useVisible();
    const [approveDrawerOpen, toggleApproveDrawerOpen, setApproveDrawerOpen] = useVisible();
    const { entity: changeOrder } = useAppSelector((state) => state.ChangeOrder);

    useEffect(() => {
        setJsonDetail(value.detail ? JSON.parse(value.detail) : undefined);
    }, [value])
    
    useLayoutEffect(() => {
        if (jsonDetail) {
            setReviewStatus(jsonDetail.reviewCompletedStatus ? jsonDetail.reviewCompletedStatus : pendingStatus)
            setClosed(jsonDetail.reviewCompletedStatus === rejectedStatus && !jsonDetail.resubmissionRequested ? true : false)
            setApproved(jsonDetail.reviewCompletedStatus === approvedStatus && !jsonDetail.resubmissionRequested ? true : false)
        }
    }, [jsonDetail])

    useLayoutEffect(() => {
        let pColor = "#FA8C16";
        let sColor = "#FFE7BA";
        let tColor = "#FFF7E6";
        switch (reviewStatus) {
            case pendingStatus:
                pColor = "#FA8C16";
                sColor = "#FFE7BA";
                tColor = "#FFF7E6";
                break;
            case approvedStatus:
                pColor = "#237804";
                sColor = "#B7EB8F";
                tColor = approved ? "#D9F7BE" : "#F6FFED"
                break;
            case rejectedStatus:
                pColor = "#A8071A";
                sColor = "#FFCCC7";
                tColor = closed ? "#FFCCC7" : "#FFF1F0";
                break;
        }
        setPrimaryColor(pColor);
        setSecondaryColor(sColor);
        setTertiaryColor(tColor);
    }, [reviewStatus, closed, approved])

    useLayoutEffect(() => {
        if (closed === true) {
            setBgColor("#FFF1F0");
        } else {
            setBgColor(colorBgContainer);
        }
    }, [closed])

    useLayoutEffect(() => {
        if (approved === true) {
            setBgColor("#F6FFED");
        } else {
            setBgColor(colorBgContainer);
        }
    }, [approved])

    const rejectClick = () => {
        setRejectDrawerOpen(true);
    }

    const resubmissionClick = () => {
        setAskResubmissionDrawerOpen(true);
    }

    const approveClick = () => {
        setApproveDrawerOpen(true);
    }

    return (<>
        <Row className="w-full pt-10 pr-10 pl-10 pb-10" style={{ backgroundColor: bgColor, border: '1px solid', borderColor: primaryColor, borderRadius: '4px' }}>
            <Col span={24}>
                <Row>
                    <Col span={17} className="font-bold">
                        {closed && <span><LockOutlined style={{ color: primaryColor }} /> {t("changeOrder.closed")}</span>}
                        {approved && <span><LockOutlined style={{ color: primaryColor }} /> {t("changeOrder.approved")}</span>}
                        {!closed && !approved && t("changeOrder.finalReview")}
                    </Col>
                    <Col span={7} className="text-right">{reviewStatus === pendingStatus ?
                        <ValidUntil date={changeOrder?.dueDate} style={{ color: colorTextTertiary, fontSize: 12 }} />
                        :
                        <EntryTime date={value.date} />}</Col>
                </Row>
            </Col>
            <Col>
                <Tag style={{ color: primaryColor, backgroundColor: tertiaryColor, borderColor: secondaryColor }}>{reviewStatus}</Tag>
            </Col>
            {reviewStatus === pendingStatus && <Col span={24}>
                <Row>
                    <Divider style={{ margin: '10px 0px 10px 0px' }} />
                    <Col span={6}><Button type="link" danger onClick={rejectClick}>{t("changeOrder.rejectButton")}</Button></Col>
                    <Col span={18} className="text-right">
                        <Button className="mr-10" onClick={resubmissionClick}>{t("changeOrder.requestResubmissionButton")}</Button>
                        <Button type="primary" onClick={approveClick}>{t("changeOrder.approveButton")}</Button>
                    </Col>
                </Row>
            </Col>}
        </Row>
        {(jsonDetail?.reviewCompletedStatus === undefined || jsonDetail.reviewCompletedStatus === pendingStatus) &&
            <>
                <Reject open={rejectDrawerOpen} toggle={toggleRejectDrawerOpen} />
                <Resubmission open={askResubmissionDrawerOpen} toggle={toggleAskResubmissionDrawerOpen} />
                <Approve open={approveDrawerOpen} toggle={toggleApproveDrawerOpen} />
            </>}
    </>);
}
