/* eslint-disable react-hooks/exhaustive-deps */
import { Tabs, TabsProps, theme } from 'antd'
import { useEffect, useLayoutEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { useLayoutContex } from '@providers/LayoutProvider';
import { getEntity } from '@store/slices/location';
import { useAppDispatch, useAppSelector } from '@store/store';
import { LocationOverviewTab } from './OverviewTab/LocationOverviewTab';
import { LocationPhotoAlbumTab } from './PhotoAlbum/LocationPhotoAlbumTab';
import { useBaseLocationsTabUrl } from './RouteBaseLocationTabs';
import { ILOCATION_STATUS_MAP_KEYS, StatusCell } from '../LocationStatusTag';
import { useTranslation } from 'react-i18next';

const LocationTabsWrapper = ({ children }: any) => {
  const dispatch = useAppDispatch();

  const { setNewStylePropertiesInContentLayout, setHeaderLabelEntityState } = useLayoutContex();

  const { entity: locationEntity } = useAppSelector(state => state.Location);

  const { id } = useParams<'id'>();

  useLayoutEffect(() => {
    setNewStylePropertiesInContentLayout && setNewStylePropertiesInContentLayout();
  }, []);

  useEffect(() => {
    if (id && locationEntity && locationEntity.id && String(locationEntity.id) !== id) {
      dispatch(getEntity(id));
    }
  }, [id]);

  useLayoutEffect(() => {
    if (setHeaderLabelEntityState && locationEntity?.name) {
      setHeaderLabelEntityState?.(locationEntity?.name);
    }

    const headerTags = document.getElementById('header-tags');
    const tagsContainer = document.createElement('div');
    tagsContainer.classList.add('ml-10');

    const root = createRoot(tagsContainer!);

    if (locationEntity?.locationStatus) {
      const statusKey = locationEntity?.locationStatus as ILOCATION_STATUS_MAP_KEYS;
      root.render(<StatusCell status={statusKey} />);
    }
    headerTags?.appendChild(tagsContainer);

    return () => {
      setHeaderLabelEntityState?.('');
      headerTags?.removeChild(tagsContainer);
    };
  }, [locationEntity]);

  return <>{children}</>;
};

export interface ILocationsTabsProps {
  tab: LocationsTabsMap;
}

export const LocationTabs = (props: ILocationsTabsProps) => {
  const { tab } = props;
  return (
    <>
      <LocationTabsWrapper>
        <LocationsTabsContainer tab={tab} />
      </LocationTabsWrapper>
      <Outlet />
    </>
  );
};

export enum LocationsTabsMap {
  OVERVIEW = 'overview',
  PHOTO_ALBUM = 'photos',
}

export interface ILocationsTabsContainerProps {
  tab: LocationsTabsMap;
}

export const PhotoAlbumLocationItemTab = defaultActiveKey => {
  return {
    key: LocationsTabsMap.PHOTO_ALBUM,
    label: `Photos`,
    className: 'h-full',
    children: defaultActiveKey === LocationsTabsMap.PHOTO_ALBUM ? <LocationPhotoAlbumTab /> : null,
  };
};

const LocationsTabsContainer = (props: ILocationsTabsContainerProps) => {
  const { tab } = props;

  const { t } = useTranslation();

  const [defaultActiveKey, setDefaultActiveKey] = useState<`${LocationsTabsMap}` | undefined>(LocationsTabsMap.OVERVIEW);

  const {
    token: { colorBgLayout, colorBgContainer, marginLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const items: TabsProps['items'] = [
    {
      key: LocationsTabsMap.OVERVIEW,
      label: `${t('location.navigation.overview')}`,
      className: 'h-full',
      children: <LocationOverviewTab />,
    },
    // Active for Location Tab Photo Album
    // PhotoAlbumLocationItemTab(defaultActiveKey)
  ];

  useEffect(() => {
    if (tab && Object.values<string>(LocationsTabsMap).includes(tab)) {
      setDefaultActiveKey(tab as `${LocationsTabsMap}`);
    }
    return () => {
      setDefaultActiveKey(undefined);
    };
  }, [tab]);

  const { validBaseUrl } = useBaseLocationsTabUrl();

  const onTabClick = (activeKey: string, e: React.KeyboardEvent | React.MouseEvent) => {
    setTimeout(() => {
      if (activeKey === LocationsTabsMap.OVERVIEW) {
        navigate(`${validBaseUrl}`, { relative: 'route' });
      } else {
        navigate(`${validBaseUrl}/${activeKey}`, { relative: 'route', replace: true });
      }

      setDefaultActiveKey(activeKey as `${LocationsTabsMap}`);
    }, 50);
  };

  return (
    <Tabs
      defaultActiveKey={LocationsTabsMap.OVERVIEW}
      activeKey={defaultActiveKey}
      items={items}
      className="customTabs imb-0 overflow-hidden"
      style={{ backgroundColor: colorBgLayout }}
      onTabClick={onTabClick}
      destroyInactiveTabPane={true}
      tabBarStyle={{ backgroundColor: colorBgContainer, paddingLeft: `${marginLG}px`, marginBottom: 0 }}
    />
  );
};
