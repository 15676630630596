import { ICurrency } from './currency.model';
import { IDiscipline } from './discipline.model';
import { IProjectDeliverableUI } from './project-deliverable';
import { IProjectStatus } from "./project-status.model";
import { IProjectTemplate } from "./project-template-model";
import { IProjectType } from "./project-type.model";
import { ISite } from "./site.model";
import { PrimaryKey } from "./utils";

export interface IProject {
  id?: number | string;
  site?: ISite;
  procoreId?: number | null;
  oxblueProjectId?: string | null ;
  procoreLink?: string
  projectType?: IProjectType | null;
  projectStatus?: IProjectStatus | null;
  template?: IProjectTemplate | null;
  createdDate?: string,
  archive?: boolean
  nickname? : string | null
  currency?: ICurrency,
  squareFootage? : number | null
}
export interface IProjectStatusOverview {
  id: number | string;
  projectStatus: IProjectStatus | null;
  projectStatusLastModified?: string;
}

export interface IProjectTypeOverview {
  id: number | string;
  projectType: IProjectType | null;
  projectTypeLastModified?: string;
}

export interface IProjectOverview {
  headerData?: any;
  keyMilestoneList?: any;
  teamMemberList?: ITeamMemberItemCustom[];
  companiesList?: any
}

export interface ITeamMemberItemCustom {
  roleBindingId?: number;
  role: Role;
  user?: User;
  primary: boolean;
}

export interface ITeamMembersByGroup {
  coreMembers: ITeamMemberItemCustom[];
  dynamicMembers: ITeamMemberItemCustom[];
}

export interface Role {
  id: number;
  name: string;
  discipline: IDiscipline;
}

export interface User {
  id: number;
  fullName: string;
  firstName: string;
  lastName: string;
}


export interface IProjectDeliverables {
  deliverablesList?: IProjectDeliverableUI[];
  deliverablesFiltered: IProjectDeliverableUI[];
  deliverableSelected?: IProjectDeliverableUI;
}

export interface IRequestProjectDeliverableDetailsById {
  projectId?: string | number,
  projectDeliverableId: string | number
}
export interface IFormStep1 {
  type?: PrimaryKey | null;
  status?: PrimaryKey | null;
}

export const defaultValue: Readonly<IProject> = {};
