/* eslint-disable react-hooks/exhaustive-deps */
import { PinMapSvgIcon } from "@components/Icons/PinMapSvgIcon"
import { DividerVertical } from "./ToolbarComponents"
import { theme } from "antd"
import { TableOutlined } from "@ant-design/icons"
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { isIncluded } from "@shared/util/enum-util";

export enum VISUALIZATIONS {
    TABLE = 'TABLE',
    MAP = 'MAP'
}

export type IVISUALIZATIONS_TYPE = keyof typeof VISUALIZATIONS;

export interface IViewOptionsProps {
    setVisualization: React.Dispatch<React.SetStateAction<IVISUALIZATIONS_TYPE>>
    visualization: string
}

export const ViewOptions = (props: IViewOptionsProps) => {

    const { setVisualization, visualization } = props;

    const { token: { colorPrimaryBg, colorPrimary, colorTextSecondary, colorTextQuaternary } } = theme.useToken();
    
    let [searchURLParams, setURLSearchParams] = useSearchParams();

    useEffect(() => {
        const view = getViewUrlParam();
        const hasViewInOptions = isIncluded(VISUALIZATIONS, view);
        if (hasViewInOptions) {
            if (view === VISUALIZATIONS.MAP) {
                setVisualization(VISUALIZATIONS.MAP)
            } else {
                setVisualization(VISUALIZATIONS.TABLE)
            }
        } else {
            setUrlViewParamIfNotExists(visualization);
        }
    }, []);

    const getViewUrlParam = (): string => {
        const view = searchURLParams?.get?.("view")?.toUpperCase?.();
        return view ?? "";
    }

    const setUrlViewParamIfNotExists = (view: string) => {
        if (!searchURLParams.get("view")) {
            setUrlViewParam(view)
        }
    }

    const setUrlViewParam = (view: string) => {
        setURLSearchParams((prev) => {
            prev.set("view", String(view?.toLocaleLowerCase?.()))
            return prev;
        });
    }

    const onSelectViewMapMode = () => { 
        setVisualization(VISUALIZATIONS.MAP);
        setUrlViewParam(VISUALIZATIONS.MAP);
    }
    const onSelectViewTableMode = () => {
        setVisualization(VISUALIZATIONS.TABLE);
        setUrlViewParam(VISUALIZATIONS.TABLE);
    }

    return (
        <> 
            <DividerVertical />
            <span style={{ color: colorTextSecondary }} className='pr-5 mr-8'>View By:</span>
            <div 
                onClick={onSelectViewTableMode}
                className='cursor-pointer flex flex-row items-center pt-5 pb-5 pl-5 pr-5' 
                style={{
                    borderRadius: 4, 
                    color: visualization === VISUALIZATIONS.TABLE ? colorPrimary : colorTextQuaternary, 
                    background: visualization === VISUALIZATIONS.TABLE ? colorPrimaryBg : "transparent"
                }}>
                <TableOutlined />
            </div>
            
            <div 
                onClick={onSelectViewMapMode}
                className='cursor-pointer flex flex-row items-center pt-5 pb-5 pl-5 pr-5 ml-4' 
                style={{ 
                    borderRadius: 4,
                    color: visualization === VISUALIZATIONS.MAP ? colorPrimary : colorTextQuaternary,
                    background: visualization === VISUALIZATIONS.MAP ? colorPrimaryBg : "transparent", 
                }}
            >
                <PinMapSvgIcon width="14" height="14" />
            </div>

            {/* TODO -> Active when we have the filters */}
            {/* 
                <DividerVertical />
                <div className='flex flex-row items-center opacity-30 cursor-not-allowed'>
                    <span className="mr-8" style={{ color: primaryColor }}>Display Options</span>
                    <TuneSvgIcon style={{ color: colorPrimaryBorder }} />
                </div> 
            */}
        </>
    )
}