import { ArrowLeftOutlined } from "@ant-design/icons";
import { theme } from "antd";
import { useRef } from "react";
import useHover from "../../HOOKs/UseHover";

export const ArrowBack = ({ className, style, ...restProps }: React.ComponentPropsWithoutRef<typeof ArrowLeftOutlined>) => {
    const arrowhoverRef = useRef(null);
    const isHoverInArrow = useHover(arrowhoverRef);
    const { token: { colorPrimaryTextHover, colorText } } = theme.useToken();
    
    const defaultStyle: React.CSSProperties = {
      color: isHoverInArrow
        ? colorPrimaryTextHover
        : colorText
    }

    return (
      <ArrowLeftOutlined
        ref={arrowhoverRef}
        className={`animation-grow-in-hover cursor-pointer ${className || ""}`}
        style={{ ...defaultStyle, ...style}}
        {...restProps}
      />
    );
  };