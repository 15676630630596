/* eslint-disable react-hooks/exhaustive-deps */
import { RfiImpactEnum } from '@models/enumerations/rfi-impact-enum.model';
import { IUser } from '@models/user.model';
import { useAuth } from '@providers/AuthProvider';
import { DATE_FORMAT_DEFAULT, formatDatePipe } from '@shared/util/date-utils';
import { capitalize } from '@shared/util/string-utils';
import { getUserDisplayName } from '@shared/util/user-utils';
import { getRfiDefaultUsers } from '@store/slices/project-rfi-default-users';
import { countRfi } from '@store/slices/rfi';
import { getEntities as getRfiCategories } from '@store/slices/rfi-category';
import { getUsers } from '@store/slices/users-extended';
import { useAppDispatch, useAppSelector } from '@store/store';
import { Avatar, Button, Col, DatePicker, Divider, Form, Row, Select, Tag, theme } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCreateRfiContext } from './CreateRfiContext';
import { QuestionInput } from './QuestionInput';
import { ICreateRfiStepProps } from './RfiCreate';

const { Option } = Select;

export const RfiCreateStepOne = (props: ICreateRfiStepProps) => {

    const { token: { colorTextTertiary, fontSizeSM } } = theme.useToken();

    const { user: currentUser } = useAuth();
    const [rfiNumber, setRfiNumber] = useState<number>(1);
    const [form] = Form.useForm();
    const rfiImpactEnumValues = Object.keys(RfiImpactEnum);
    const { projectId } = useParams<"projectId">();
    const roleBindings = useAppSelector((state) => state.ProjectRfiDefaultUsers.roleBindings);
    const { entitiesQuantity: rfiQty } = useAppSelector((state) => state.Rfi);
    const { entities: rfiCategories } = useAppSelector((state) => state.RfiCategory);
    const { usersList } = useAppSelector((state) => state.UsersExtended);
    const [rfiDefaultUsers, setRfiDefaultUsers] = useState<IUser[]>([]);
    const [usersForAssignedToSelect, setUsersForAssignedToSelect] = useState<IUser[]>([]);
    const dispatch = useAppDispatch();
    const [usersForFollowingSelect, setUsersForFollowingSelect] = useState<IUser[]>([]);
    const [followers, setFollowers] = useState<IUser[]>([]);
    const createRfiContext = useCreateRfiContext()

    useEffect(() => {
        if (createRfiContext?.following)
            setFollowers(createRfiContext.following.map(value => JSON.parse(value as string)));
    }, [])


    useEffect(() => {
        if (projectId) {
            dispatch(
                countRfi(projectId)
            );
            dispatch(
                getRfiCategories()
            );
            dispatch(
                getRfiDefaultUsers(projectId)
            );
            dispatch(
                getUsers()
            );
        }
    }, [projectId])

    useEffect(() => {
        if (createRfiContext) {
            form.setFieldsValue({
                ...createRfiContext,
            });
        }
    }, [form, createRfiContext]);

    useEffect(() => {
        if (rfiQty) {
            setRfiNumber(Number(rfiQty + 1));
        }
    }, [rfiQty])

    useEffect(() => {
        if (roleBindings) {
            const users = roleBindings.map(roleBinding => roleBinding.user!)
            const distinctUsers = users.filter(
                (user, i, arr) => arr.findIndex(t => t.id === user.id) === i
            );
            setRfiDefaultUsers(distinctUsers);
            setUsersForAssignedToSelect(distinctUsers.filter(u => u.id !== currentUser.id));
        }

    }, [roleBindings])

    useEffect(() => {
        if (usersList && rfiDefaultUsers) {
            setUsersForFollowingSelect(usersList.filter(u => rfiDefaultUsers.some(defUser => u.id === defUser.id) === false));
        }

    }, [usersList, rfiDefaultUsers])

    useEffect(() => {
        if (usersForFollowingSelect.some(u => u.id === currentUser.id) === true) {
            setFollowers([currentUser]);
        } else {
            setFollowers([]);
        }
    }, [usersForFollowingSelect])

    const onFinish = (values: any): void => {
        let allFollowers: IUser[] = rfiDefaultUsers;
        allFollowers = allFollowers?.concat(followers);
        props.nextStep?.({ ...values, number: rfiNumber, allFollowers: allFollowers });
    };

    const onFinishFailed = () => {
    };

    const disabledDate: RangePickerProps['disabledDate'] = (current: Dayjs) => {
        // Cannot select days before today and today
        return current && current < dayjs().endOf('day')
    };

    const getDefaultDueDate = () => {
        const date = new Date();
        date.setDate(date.getDate() + 7);
        return date;
    }

    const genericFilter = (input: string, option: any) => (
        option?.children?.toLowerCase!()?.indexOf!(input?.toLowerCase!()) >= 0
    )

    const createClick = () => {
        form.submit();
    }

    const followingChange = (values: number[]) => {
        const users = usersForFollowingSelect.filter(u => values.includes(u.id));
        setFollowers(users);
    }

    const followingSelectDefaultValue = (): number[] => {
        if (usersForFollowingSelect.some(u => u.id === currentUser.id)) {
            return [currentUser.id];
        } else {
            return [];
        }
    }

    return (
      <>
        <Row>
          <Col className="pl-30">
            <Row>
              <Col>
                <span className="font-bold">RFI:</span>
                <span className="ml-10" style={{ color: colorTextTertiary }}>
                  #{rfiNumber}
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="font-bold">Submitter:</span>
                <span className="ml-10 flex-row items-center" style={{ color: colorTextTertiary }}>
                  <Avatar className="cursor-default mr-5" gap={4} style={{ backgroundColor: '#B2B2B2' }}>
                    {`${currentUser.firstName?.[0]}${currentUser.lastName?.[0]}`.toUpperCase()}
                  </Avatar>
                  {getUserDisplayName(currentUser)}
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="font-bold">Date of creation:</span>
                <span className="ml-10" style={{ color: colorTextTertiary }}>
                  {formatDatePipe(new Date().toDateString())}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider type="horizontal"></Divider>
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            dueDate: dayjs(getDefaultDueDate()),
            following: followingSelectDefaultValue(),
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row>
            <Col xs={24} md={12} className="pl-10 pr-10">
              <Form.Item name="category" label="Category:" rules={[{ required: true, message: 'Please enter a Category' }]}>
                <Select
                  placeholder="Please select a Category"
                  allowClear
                  optionFilterProp="children"
                  showSearch
                  filterOption={genericFilter}
                >
                  {rfiCategories?.map(category => (
                    <Option value={JSON.stringify(category)} key={category.id}>
                      {category.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} className="pl-10 pr-10">
              <Form.Item
                name="dueDate"
                label="Required By Date:"
                initialValue={dayjs(getDefaultDueDate())}
                rules={[{ required: true, message: 'Please select the Required By Date' }]}
              >
                <DatePicker name="dueDate" className="w-full" format={DATE_FORMAT_DEFAULT} disabledDate={disabledDate} allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} className="pl-10 pr-10">
              <Form.Item name="scheduleImpact" label="Schedule Impact:">
                <Select
                  placeholder="Please select the Schedule Impact"
                  options={rfiImpactEnumValues.map(rfiImpactEnum => ({
                    value: rfiImpactEnum,
                    label: capitalize(rfiImpactEnum.toLowerCase()),
                  }))}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} className="pl-10 pr-10">
              <Form.Item name="costImpact" label="Cost Impact:">
                <Select
                  placeholder="Please select the Cost Impact"
                  options={rfiImpactEnumValues.map(rfiImpactEnum => ({
                    value: rfiImpactEnum,
                    label: capitalize(rfiImpactEnum.toLowerCase()),
                  }))}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={24} className="pl-10 pr-10">
              <Form.Item name="questionPayload" label="Question:" rules={[{ required: true, message: 'Please write a Question' }]}>
                <QuestionInput initialValue={createRfiContext?.questionPayload} />
              </Form.Item>
            </Col>
            <Col span={24} className="pl-10 pr-10">
              <Form.Item name="assignedTo" label="Assigned To:" rules={[{ required: true, message: 'Please select the Assigned To user' }]}>
                <Select placeholder="Please select a user" allowClear optionFilterProp="children" showSearch filterOption={genericFilter}>
                  {usersForAssignedToSelect?.map(user => (
                    <Option value={JSON.stringify(user)} key={user.id}>
                      {getUserDisplayName(user)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} className="pl-10 pr-10">
              <Form.Item name="following" label="Following Discussion:">
                <Select
                  mode="multiple"
                  onChange={value => {
                    followingChange(value);
                  }}
                  placeholder="Please select users"
                  allowClear
                  optionFilterProp="children"
                  showSearch
                  filterOption={genericFilter}
                >
                  {usersForFollowingSelect?.map(user => (
                    <Option value={user.id} key={user.id} disabled={user.id === currentUser.id}>
                      {getUserDisplayName(user)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} className="pl-10 pr-10">
              <div style={{ fontSize: fontSizeSM }}>Default Users</div>
              <div className="ml-5">
                {rfiDefaultUsers?.map(user => (
                  <Tag style={{ color: colorTextTertiary, fontSize: fontSizeSM, borderRadius: '2px' }} key={user.id}>
                    {getUserDisplayName(user)}
                  </Tag>
                ))}
              </div>
            </Col>
            <Col span={24} className="pl-10 pr-10 pt-10">
              <div style={{ fontSize: fontSizeSM }}>Additional followers</div>
              <div className="ml-5">
                {followers?.map(user => (
                  <Tag style={{ fontSize: fontSizeSM, borderRadius: '2px' }} key={user.id}>
                    {getUserDisplayName(user)}
                  </Tag>
                ))}
              </div>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col span={24} className="text-right pl-10 pr-10">
            <Button type="primary" onClick={createClick}>
              Create RFI
            </Button>
          </Col>
        </Row>
      </>
    );
}