import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from "../reducer.util";
import { customFieldGroupsService } from '@services/custom-field-groups.service';
import { ICustomFieldGroup, defaultValue } from '@models/custom-field-group.model';
import { serializeGenericHandleError } from '@store/thunk.util';
import { CustomFieldGroupTypeListOptions } from '@models/enumerations/custom-field-group-type-enum.model';

const initialState: EntityState<ICustomFieldGroup> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type reorderCustomFieldGroupsProps = {
  referenceType: CustomFieldGroupTypeListOptions;
  ids: number[]
}


// Actions

export const getEntities = createAsyncThunk(
    'customFieldGroup/fetch_entity_list', 
    async () => {
        return customFieldGroupsService.getEntities();
  });
  
export const createEntity = createAsyncThunk(
  'customFieldGroup/create_entity',
  async (entity: ICustomFieldGroup, thunkAPI) => {
    return await customFieldGroupsService.create(entity);
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'customFieldGroup/update_entity',
  async (entity: ICustomFieldGroup, thunkAPI) => {
    // @ts-ignore
    return await customFieldGroupsService.update(entity.id, entity);
  },
  { serializeError: serializeAxiosError }
);

export const reorderCustomFieldGroups = createAsyncThunk(
  `customFieldGroup/reorder`,
  async (props: reorderCustomFieldGroupsProps, thunkAPI) => {
    const { referenceType, ids } = props;

    const result = await customFieldGroupsService.reorder(referenceType, ids);
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeGenericHandleError }
);

// slice

export const CustomFieldGroupSlice = createEntitySlice({
  name: 'customFieldGroup',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = CustomFieldGroupSlice.actions;

// Reducer
export default CustomFieldGroupSlice.reducer;
