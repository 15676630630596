import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { IBudgetApproverSetting, defaultValue } from '@models/budget-approver-setting.model';
import { EntityState, createEntitySlice, serializeAxiosError } from '@store/reducer.util';
import { budgetApproverSettingRepository } from '@infrastructure/repositories/budget-approver-setting.repository';
import { IQueryParams } from '@models/pagination';

const initialState: EntityState<IBudgetApproverSetting> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

// Actions

export const getEntities = createAsyncThunk('budgetApproverSetting/fetch_entity_list', async (params: IQueryParams) => {
  return await budgetApproverSettingRepository.getBudgetApproverSettings(params);
});

export const createEntity = createAsyncThunk(
  'budgetApproverSetting/create_entity',
  async (entity: IBudgetApproverSetting, thunkAPI) => {
    const result = await budgetApproverSettingRepository.create(entity);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'budgetApproverSetting/update_entity',
  async (entity: IBudgetApproverSetting, thunkAPI) => {
    // @ts-ignore
    const result = await budgetApproverSettingRepository.update(entity.id, entity);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const BudgetApproverSettingSlice = createEntitySlice({
  name: 'budgetApproverSetting',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = BudgetApproverSettingSlice.actions;

// Reducer
export default BudgetApproverSettingSlice.reducer;
