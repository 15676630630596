import { ITimeline, ITimelineEntry } from '@models/timeline.model';
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { changeOrdersService } from '@services/change-orders.service';
import { serializeAxiosError } from "../reducer.util";


export const getTimeline = createAsyncThunk(
    'changeOrderTimeline/fetch_entity_list',
    async (id: string | number) => {
        return changeOrdersService.getChangeOrderTimeline(id);
    },
    { serializeError: serializeAxiosError }
);

interface ChangeOrderTimelineState {
    loading: boolean;
    timeline: ITimeline<ITimelineEntry> | null;
}

const initialState: ChangeOrderTimelineState = {
    loading: false,
    timeline: null,
};

export const ChangeOrderTimelineSlice = createSlice({
    name: 'changeOrderTimeline',
    initialState,
    reducers: {
        reset: (state,) => {
            return initialState;
        },
    },
    extraReducers(builder) {
        builder

            .addMatcher(isFulfilled(getTimeline), (state, action) => {
                state.loading = false;
                state.timeline = action.payload.data;
            })
            .addMatcher(isPending(getTimeline), state => {
                state.loading = true;
            });
    }
});

export const { reset } = ChangeOrderTimelineSlice.actions;

// Reducer
export default ChangeOrderTimelineSlice.reducer;

