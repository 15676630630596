
import { isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from "../reducer.util";
import { createAsyncThunk } from "@reduxjs/toolkit"
import { IQueryParams } from "../../models/pagination";
import { cleanEntity } from '../../shared/util/entity-utils';
import { IProjectStatus, defaultValue } from '../../models/project-status.model';
import { projectStatusesService } from '../../services/project-statuses.service';

const nameOfEntity = "projectStatuses";
const service = projectStatusesService;

export const getEntities = createAsyncThunk(
  `${nameOfEntity}/fetch_entity_list`,
  async (queryParams: IQueryParams) => {
      return service.getAll(queryParams);
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  `${nameOfEntity}/fetch_entity`,
  async (id: string | number) => {
    return service.getById(id);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  `${nameOfEntity}/create_entity`,
  async (entity: IProjectStatus, thunkAPI) => {
    const result = await service.create(cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const reorderProjectStatuses = createAsyncThunk(
  `${nameOfEntity}/reorder`,
  async (projectStatusIds: number[], thunkAPI) => {
    const result = await service.reorder(projectStatusIds);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  `${nameOfEntity}/update_entity`,
  async (entity: IProjectStatus, thunkAPI) => {
    const result = entity.id && await service.update(entity.id, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  `${nameOfEntity}/delete_entity`,
  async (id: string | number, thunkAPI) => {
    const result = await service.delete(id);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

const initialState: EntityState<IProjectStatus> = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

export const ProjectStatusesSlice = createEntitySlice({
    name: nameOfEntity,
    initialState,
    reducers: {
      clearEntity: (state,) => {
        state.entity = defaultValue;
      },
    },
    extraReducers(builder) {
        builder
          .addCase(getEntity.fulfilled, (state, action) => {
            state.loading = false;
            state.entity = action.payload.data;
          })
          .addMatcher(isFulfilled(getEntities), (state, action) => {
            const { data } = action.payload;

            return {
              ...state,
              loading: false,
              entities: data,
              totalItems: data.length,
            };
          })
          .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
            state.updating = false;
            state.loading = false;
            state.updateSuccess = true;
            state.entity = action.payload as IProjectStatus;
          })
          .addMatcher(isPending(getEntities, getEntity), state => {
            state.errorMessage = null;
            state.updateSuccess = false;
            state.loading = true;
          })
          .addMatcher(isPending(createEntity, updateEntity), state => {
            state.errorMessage = null;
            state.updateSuccess = false;
            state.updating = true;
          });
      }
  });
  
  export const { reset, clearEntity } = ProjectStatusesSlice.actions;
  
  // Reducer
  export default ProjectStatusesSlice.reducer;

  