
import { http } from '@infrastructure/http/axios.instance';
import { IRole } from '@models/role.model';

const path = 'roles';

export const rolesRepository = {
  getRoles: async (queryParams: any) => {
    const { sort = "asc" } = queryParams;

    const params = new URLSearchParams({
      ...sort && { sort },
      page: 0,
      size: 1000
    } as any)
    const requestUrl = `${path}?${params.toString()}`;

    const promiseGetAll = await http.get<IRole[]>(requestUrl);
    return promiseGetAll;
  },
  create: async (entity: IRole): Promise<IRole> => {

    const requestUrl = `${path}`;
    const promiseCreate = await http.post<IRole>(requestUrl, entity);
    return promiseCreate.data;
  },
  update: async (id: string | number, entity: IRole): Promise<IRole> => {

    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.put<IRole>(requestUrl, entity);
    return promiseUpdate.data;
  },
}