import React, { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Col, Row, theme, Tooltip } from 'antd';
import { CalendarOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { blue, green, red } from '@ant-design/colors';
import { isNumber } from 'lodash';

import { Can } from '@components/Permisions/Can';
import { EmptyLabel } from '@components/Utils/EmptyLabel';
import { EmptyButton } from '@components/Utils/EmptyButton';
import { CustomButtonText } from '@components/Utils/CustomButtonText';
import { If } from '@components/Utils/Structural';
import { IProjectDeliverableUI } from '@models/project-deliverable';
import { EditTimeLine } from './EditTimeLine';
import { HandlerActualDate } from './HandlerActualDate';

interface IDeliverableActiveProp {
  deliverableActive: IProjectDeliverableUI
}

interface ITimeLineContainerProps extends IDeliverableActiveProp { }

export const TimeLineContainer: React.FC<ITimeLineContainerProps> = ({ deliverableActive }) => {

  const { token: { colorTextTertiary } } = theme.useToken();

  const [isUnfinish, setIsUnfinish] = useState(false);

  useLayoutEffect(() => {
    const hasCorrectDates = deliverableActive?.actualStartDate && deliverableActive?.actualFinishDate;
    setIsUnfinish(!hasCorrectDates);
  }, [deliverableActive])

  const HandlerEditTimelineButton = ({ deliverableActive }: IDeliverableActiveProp) => {
    const { RouteParamDeliverableId } = useParams<"RouteParamDeliverableId">();
    return (
      <Can I="EDIT" A="PROJECT_DELIVERABLES" Entity={{ id: RouteParamDeliverableId }}>
        <EditTimelineButton deliverableActive={deliverableActive} />
      </Can>
    )
  }

  const shouldMergeStartAndEndDate = () => {
    const { actualStartDate, actualFinishDate, plannedStartDate, plannedFinishDate, duration, approvals } = deliverableActive;

    const areDatesSame: boolean = !!((actualStartDate && actualFinishDate && actualStartDate === actualFinishDate)
    || (plannedStartDate && plannedFinishDate && plannedStartDate === plannedFinishDate && !actualStartDate));

    return duration === 1 && !approvals?.length && areDatesSame
  }

  return (
    <>
      <Row>
        <Col span={12}><span className="font-bold" style={{ color: colorTextTertiary }}>Timeline</span></Col>
        <Col span={12}>
          <div className='flex flex-row justify-end'>
            {
              (isUnfinish && !deliverableActive.isStatusPendingApproval)
                ? (<HandlerEditTimelineButton deliverableActive={deliverableActive} />)
                : (<EmptyButton />)
            }
          </div>
        </Col>
      </Row>

      {shouldMergeStartAndEndDate() ?
        <RenderMostRecentStarEndtDate deliverableActive={deliverableActive} /> :
        <>
          <RenderMostRecentStartDate deliverableActive={deliverableActive} />
          <RenderMostRecentEndDate deliverableActive={deliverableActive} />
        </>
      }

      <Row className='mt-20'>
        <Col span={12}>
          <span className="font-bold">Duration:</span>
        </Col>
        <Col span={12}>
          <div className='flex flex-row justify-end pr-0'>
            <span>{
              `${!!deliverableActive?.duration ? `${deliverableActive?.duration > 0 ? deliverableActive?.duration : 0} days` : ""}` || <EmptyLabel />}
            </span>
          </div>
        </Col>
      </Row>

      {
        isUnfinish && (
          <Row className='mt-40'>
            <Col span={12}>
              <div className='flex flex-row'>
                {deliverableActive && <HandlerActualDate deliverable={deliverableActive} />}
              </div>
            </Col>
          </Row>
        )
      }

    </>
  )
}

export const EditTimelineButton = ({ deliverableActive }: IDeliverableActiveProp) => {
  const [editTimeLine, setEditTimeLine] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <CustomButtonText onClick={() => setEditTimeLine(true)} >
        <CalendarOutlined />
        {t("generic.manageSchedule")}
      </CustomButtonText>
      {deliverableActive && (<EditTimeLine openEdition={editTimeLine} setOpenEdition={setEditTimeLine} deliverable={deliverableActive} />)}
    </>
  )
}

export const RenderMostRecentStartDate = ({ deliverableActive }: { deliverableActive: IProjectDeliverableUI }) => {
  const { actualStartDate, plannedStartDate, actualStartDateFormatted, plannedStartDateFormatted } = deliverableActive;
  const dateLabel = !!actualStartDate ? `Actual Start Date` : `Planned Start Date`;
  const lastDateLabel = `Planned Start Date : ${deliverableActive.plannedStartDateFormatted}`;
  const dateValue = !!actualStartDate ? actualStartDateFormatted : plannedStartDateFormatted

  return (<RenderMostRecentDate
    dateLabel={dateLabel}
    dateValue={dateValue}
    lastDateLabel={lastDateLabel}
    lastDateValue={plannedStartDate}
    diff={deliverableActive.diffPlannedAndActualStartDate}
    diffAbsolute={deliverableActive.diffAbsolutePlannedAndActualStartDate}
  />
  )
}

export const RenderMostRecentStarEndtDate = ({ deliverableActive }: { deliverableActive: IProjectDeliverableUI }) => {
  const { actualStartDate, plannedStartDate, actualStartDateFormatted, plannedStartDateFormatted } = deliverableActive;
  const dateLabel = !!actualStartDate ? `Actual Start and End Date` : `Planned Start and End Date`;
  const lastDateLabel = `Planned Start Date : ${deliverableActive.plannedStartDateFormatted}`;
  const dateValue = !!actualStartDate ? actualStartDateFormatted : plannedStartDateFormatted

  return (<RenderMostRecentDate
    dateLabel={dateLabel}
    dateValue={dateValue}
    lastDateLabel={lastDateLabel}
    lastDateValue={plannedStartDate}
    diff={deliverableActive.diffPlannedAndActualStartDate}
    diffAbsolute={deliverableActive.diffAbsolutePlannedAndActualStartDate}
  />
  )
}

export const RenderMostRecentEndDate = ({ deliverableActive }: { deliverableActive: IProjectDeliverableUI }) => {
  const { actualFinishDate, plannedFinishDate, actualFinishDateFormatted, plannedFinishDateFormatted } = deliverableActive;
  const dateLabel = !!actualFinishDate ? `Actual End Date` : `Planned End Date`;
  const lastDateLabel = `Planned Finish Date : ${deliverableActive.plannedFinishDateFormatted}`;
  const dateValue = !!actualFinishDate ? actualFinishDateFormatted : plannedFinishDateFormatted

  return (
    <RenderMostRecentDate
      dateLabel={dateLabel}
      dateValue={dateValue}
      lastDateValue={plannedFinishDate}
      lastDateLabel={lastDateLabel}
      diff={deliverableActive.diffPlannedAndActualEndDate}
      diffAbsolute={deliverableActive.diffAbsolutePlannedAndActualEndDate}
    />
  )
}
export interface IRenderMostRecentDateProps {
  dateLabel: string;
  dateValue?: string | null;
  lastDateLabel?: string | null;
  lastDateValue?: string | null;
  diff: number | null;
  diffAbsolute: number | null;
}
export const RenderMostRecentDate = (props: IRenderMostRecentDateProps) => {
  const { dateLabel, diffAbsolute, lastDateLabel } = props;
  const { t } = useTranslation();
  return (
    <Row className='mt-20' justify={"space-between"}>
      <Col flex={"none"}>
        <span className="font-bold">{dateLabel}:</span>
      </Col>
      <Col flex={"none"}>
        <div className='flex flex-row justify-end pr-0'>
          <If condition={!!(diffAbsolute)}>
            <Tooltip title={`${lastDateLabel}`}>
              <span className='pr-5' style={{ color: props.diff! > 0 ? red[6] : green[6] }}>
                ({props.diff} {diffAbsolute! > 1 ? t("generic.days") : t("generic.day")})
              </span>
              <InfoCircleOutlined className="cursor-pointer mr-10" style={{ color: props.diff! > 0 ? red[3] : green[3] }} />
            </Tooltip>
          </If>
          <If condition={(isNumber(diffAbsolute) && diffAbsolute === 0)}>
            <Tooltip title={`${lastDateLabel}`}>
              <InfoCircleOutlined className="cursor-pointer mr-10" style={{ color: blue[5] }} />
            </Tooltip>
          </If>
          <span>{(props.dateValue) || <EmptyLabel />}</span>
        </div>
      </Col>
    </Row>
  )
}
