import { FileOutlined } from "@ant-design/icons";
import { IAttachment } from "@models/attachment.model";
import { convertBytes } from "@shared/util/document-utils";
import { Col, Row, Typography, theme } from "antd";

const { Text } = Typography;

export interface IAttachmentItemProps {
    value: IAttachment
}

export const AttachmentItem = (props: IAttachmentItemProps) => {
    const { value } = props;

    const { token: { colorTextTertiary } } = theme.useToken();


    return (<Row>
        <Col span={1}>
            <FileOutlined style={{ fontSize: 20, color: colorTextTertiary }} />
        </Col>
        <Col span={18}>
            <Text className="font-bold" ellipsis={{ tooltip: value.fileName }}>
                {value.fileName}
            </Text>
        </Col>
        {
            value.fileSize !== undefined && value.fileSize !== null && value.fileSize > 0 &&
            <Col span={5}>
                <span className="pl-10" style={{ fontSize: 12, color: colorTextTertiary }}>{convertBytes(value.fileSize)}</span>
            </Col>
        }
    </Row>);
}