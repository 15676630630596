import { SVGProps } from 'react';

type IProps = SVGProps<SVGSVGElement>;

interface IColorPickerProps extends IProps {
  selectedColor: string;
}

export const ColorPickerSvgIcon = (props: IColorPickerProps) => {
  const { selectedColor, ...restProps } = props;

  return (
    <svg width="60" height="40" viewBox="0 0 60 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path
        d="M0.5 16C0.5 7.43959 7.43959 0.5 16 0.5H44C52.5604 0.5 59.5 7.43959 59.5 16V24C59.5 32.5604 52.5604 39.5 44 39.5H16C7.43959 39.5 0.5 32.5604 0.5 24V16Z"
        stroke="#292D2D"
        strokeOpacity="0.15"
      />
      <circle cx="16" cy="20" r="8" fill={selectedColor} />
      <path d="M35.41 16.58L40 21.17L44.59 16.58L46 18L40 24L34 18L35.41 16.58Z" fill="#292D2D" fillOpacity="0.45" />
    </svg>
  );
};
