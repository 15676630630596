/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTimeout } from "@HOOKs/UseTimeOut";
import { punchListCheckGenerationPreviews } from "@store/slices/punch-list";
import { useAppDispatch } from "@store/store";
import { Card, Col, Progress, Row, Typography, theme } from "antd";
import { If } from '@components/Utils/Structural';
import { useNavigate } from "react-router-dom";
import { UploadFloorPlanStepsEnum } from "./UploadFloorPlan";
import { UsePunchListRouteSegment } from "../UsePunchListRouteSegment";
import { MAX_LIMIT_SIZE_FILE_TO_UPLOAD_IN_MB, VALID_EXTENSIONS_UPLOAD_FILE } from "./UploadFileStep";
import { IErrorUploading } from '@models/alert-error-uploading';

const { Title } = Typography;

interface IProcessingFilesCardStepProps {
    punchListGenerationId: string | number | null;
    setCurrentStep?: (step: number) => void;
    setError?: (error: IErrorUploading) => void;
}

let checkInterval;
let progressInterval;

const TIME_COUNTDOWN_IN_SECONDS = 1800;
const VALID_EXTENSIONS_UPLOAD_FILE_UI_FORMATTED = VALID_EXTENSIONS_UPLOAD_FILE.join(", ".toString().toLowerCase())

export const PROCESSING_FILES_STATUS = Object.freeze({ 
    PENDING: "PENDING", 
    FINISHED: "FINISHED", 
    ERROR: "ERROR", 
    CANCELLED: "CANCELLED"
});

export const PROCESSING_FILES_STATUS_ERRORS = Object.freeze({ 
    ERROR_EXCEEDS_MAX_FILE_SIZE: "ERROR_EXCEEDS_MAX_FILE_SIZE", 
    ERROR_FILE_DOES_NOT_EXIST: "ERROR_FILE_DOES_NOT_EXIST",
    ERROR_GROUP_DOCS: "ERROR_GROUP_DOCS",
    ERROR_GROUP_DOCS_INDIVIDUALLY: "ERROR_GROUP_DOCS_INDIVIDUALLY",
    ERROR_UNSUPPORTED_SOURCE_EXTENSION: "ERROR_UNSUPPORTED_SOURCE_EXTENSION",
    ERROR_FILE_UPLOAD_FAILED: "ERROR_FILE_UPLOAD_FAILED"
});

const SERVER_ERROR_MESSAGES = [
    PROCESSING_FILES_STATUS_ERRORS.ERROR_FILE_DOES_NOT_EXIST,
    PROCESSING_FILES_STATUS_ERRORS.ERROR_GROUP_DOCS,
    PROCESSING_FILES_STATUS_ERRORS.ERROR_GROUP_DOCS_INDIVIDUALLY,
    PROCESSING_FILES_STATUS_ERRORS.ERROR_FILE_UPLOAD_FAILED,
]

export const ProcessingFilesCardStep = (props: IProcessingFilesCardStepProps) => {

    const { punchListGenerationId, setCurrentStep, setError } = props;

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const { token: { colorPrimaryHover, colorBgContainer, padding, colorPrimary }} = theme.useToken();

    const [progress, setProgress] = useState(0);

    const [countdown, setCountdown] = useState(TIME_COUNTDOWN_IN_SECONDS);

    useEffect(() => {
        return () => {
            clearInterval(checkInterval)
            clearInterval(progressInterval)
        };
    }, []);

    // Set top 100 Percent
    useEffect(() => {
        if (progress > 100) { 
            setProgress(100);
        }
    }, [progress]);

    useEffect(() => {
        console.log(countdown);
        if (countdown === 0) { 
            clearInterval(checkInterval);
            clearInterval(progressInterval);
        }
    }, [countdown]);

    const launchVerification = () => {
        
        setProgress(progress + 5);

        checkInterval =  setInterval(() => {

            if (punchListGenerationId && punchListGenerationId !== null) {
                dispatch(punchListCheckGenerationPreviews({ id: punchListGenerationId }))
                    .unwrap()
                    .then(processCheckGenerationPreviews)
                    .catch(() => {
                        clearInterval(checkInterval);
                        clearInterval(progressInterval);
                    })
            }
        }, 8000);
    }

    const processCheckGenerationPreviews = (response) => {
        console.log(`launchVerification -> time: ${new Date()}`, response);

        if (response.status === PROCESSING_FILES_STATUS.PENDING) {
            // Set Real Progress
            if (response.completionPercentage > 0) {
                setProgress(response.completionPercentage);
            }

            // Mock (Increase 3% in the progress bar to make feel user Hope until next attemp)
            if (response.completionPercentage < progress) {
                setProgress((currentProgress) => { 
                    if (currentProgress < 75) {
                        return currentProgress + 3
                    }
                    return currentProgress
                });
            }
        }

        if (response.status === PROCESSING_FILES_STATUS.FINISHED) {
            // Set Full Progress
            setProgress(100);

            // Remove Interval
            clearInterval(checkInterval);
            clearInterval(progressInterval);

            // Navigate to select page Step
            onFinishProcess();
        }

        // Show Error Notification
        if (response.status === PROCESSING_FILES_STATUS.ERROR) {

            // Default Error Message
            let errorMessage: string = "Something went wrong.";
            let suggestionMessage: string = "Please try again later.";
            
            if (response?.previewGenerationStatus === PROCESSING_FILES_STATUS_ERRORS.ERROR_EXCEEDS_MAX_FILE_SIZE) {
                errorMessage = "File too large";
                suggestionMessage = `Max size: ${MAX_LIMIT_SIZE_FILE_TO_UPLOAD_IN_MB}MB`;
            }

            if (SERVER_ERROR_MESSAGES.includes(response?.previewGenerationStatus)) {
                errorMessage = "We had trouble uploading your file";
                errorMessage = "You can try again; if the issue persists, please attempt it later or contact our support team";
            }

            if (response?.previewGenerationStatus === PROCESSING_FILES_STATUS_ERRORS.ERROR_UNSUPPORTED_SOURCE_EXTENSION) {
                errorMessage = `Unsupported extension`;
                suggestionMessage = `Supported formats: ${VALID_EXTENSIONS_UPLOAD_FILE_UI_FORMATTED}`;
            }

            // Remove Intervals
            clearInterval(checkInterval);
            clearInterval(progressInterval);

            setError?.({
                textError: errorMessage,
                textSuggestion: suggestionMessage
            });

            // Come back to Upload File Step
            setCurrentStep?.(UploadFloorPlanStepsEnum.UPLOAD_FILE);
        }
    }

    // 6 Seconds Before Starting to See if the 
    useTimeout(launchVerification, 6000);

    const startFakeProgressBar = () => {
        progressInterval = setInterval(() => {
            setProgress((value) => {
                let newProgressPercent = value;
                let percentage = 0.1;
                if (newProgressPercent > 0) {
                    percentage = 0.3;
                }
                if (newProgressPercent > 25) {
                    percentage = 0.2;
                }
                if (newProgressPercent > 50) {
                    percentage = 0.15;
                }
                if (newProgressPercent > 75) {
                    percentage = 0.075;
                }
                if (newProgressPercent > 90) {
                    percentage = 0;
                }
                return newProgressPercent + percentage;
            });
            setCountdown((value) => { return value - 1 });
        }, 1000);
    }

    useTimeout(startFakeProgressBar, 1000);

    const basePunchListUrlSegment = UsePunchListRouteSegment();

    const onFinishProcess = () => {
        
        setTimeout(() => {
            navigate(`${basePunchListUrlSegment}punch-list/process/${punchListGenerationId}/select-pages`);
        }, 1200);
    }
    
    return (
        <Card
            className="w-full"
            style={{ 
                border: `1px solid ${colorPrimaryHover}`, 
                borderRadius: 8,
                background: colorBgContainer, 
                outline: `0px solid transparent`, 
            }}
            bodyStyle={{ padding }}
        >
            <Row justify={"center"} className="mt-10">
                <Col span={24} className="flex flex-row justify-center pl-15 pr-15">
                    <Progress percent={progress} showInfo={false} style={{ marginBottom: 0 }}/>
                </Col>   
            </Row>
            
            <Row justify={"center"} className="mt-5">
                <Col span={24} className="flex flex-row justify-center">
                    <Title className="mt-5" level={5} style={{ color: colorPrimary, fontWeight: 500 }} >Processing file</Title>
                </Col>   
            </Row>
            <Row justify={"center"} className="mt-5">
                <Col span={24} className="flex flex-row justify-center">
                    <span className="text-color-neutral-8">
                        <If condition={(progress > 0)} >
                            <>
                                File uploaded successfully. We are currently processing it for preview
                            </>
                        </If>
                    </span>
                </Col>   
            </Row>
            <Row justify={"center"} className="mt-5 mb-15">
                <Col span={24} className="flex flex-row justify-center ">
                    <span className="text-color-neutral-6">Please don't close this window.</span>
                </Col>   
            </Row>
    </Card>
    )
}
