import { createAsyncThunk, createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { projectsService } from '../../services/projects.service';
import { StringORNumber } from '../../infrastructure/repositories/utils.repository';
import { ITeamMemberItemCustom } from '../../models/project-model';
import { IUtil } from '@models/utils';
import { IQueryParams } from '@models/pagination';

export interface IState extends IUtil {
  data: ITeamMemberItemCustom[];
  totalItems: number;
  loading: boolean;
  errorMessage: any;
}

const initialState: IState = {
  data: [],
  totalItems: 0,
  loading: false,
  errorMessage: null,
};

const sliceName: string = 'project-overview-team';

export const getProjectOverviewTeam = createAsyncThunk(
  `${sliceName}/getProjectOverviewTeam`,
  async (payload: { id: StringORNumber; queryParams: IQueryParams }, thunkApi) => {
    return projectsService.getProjectOverviewTeam(payload);
  }
);

export const ProjectOverviewTeamSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reset: state => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getProjectOverviewTeam.fulfilled, (state, action) => {
        const { data, headers } = action.payload;
        if (data) {
          state.data = data;
        }
        state.totalItems = headers['x-total-count'] && parseInt(headers['x-total-count'], 10);
        state.errorMessage = null;
        state.loading = false;
      })
      .addMatcher(isPending(getProjectOverviewTeam), state => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isRejected(getProjectOverviewTeam), state => {
        state.errorMessage = null;
        state.loading = false;
      });
  },
});

export const { reset } = ProjectOverviewTeamSlice.actions;

// Reducer
export default ProjectOverviewTeamSlice.reducer;
