/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useLayoutEffect, useMemo, createContext } from 'react';
import { useLayoutContex } from '@providers/LayoutProvider';
import useElementSize from '@HOOKs/UseElementSize';
import Locations from '../Locations';
import { useTranslation } from 'react-i18next';

interface ILocationContainerProps {
    children: React.ReactNode;
}

export const LocationsContainerContext = createContext({} as {
    heightContainer: number;
});

export const LocationsView = ({ children }: ILocationContainerProps) => {

    const [, { height: heightHeaderRow }] = useElementSize();
    
    const { t } = useTranslation();

    const { layoutSize, setNewStylePropertiesInContentLayout, setHeaderLabelEntityState } = useLayoutContex();
    
    useEffect(() => {
        setNewStylePropertiesInContentLayout && setNewStylePropertiesInContentLayout();
    }, []);

    useLayoutEffect(() => {
        setHeaderLabelEntityState && setHeaderLabelEntityState(`${t("generic.locations")}`);
        return () => setHeaderLabelEntityState && setHeaderLabelEntityState("");
    }, []);
    
    const layoutHeightCalculated = useMemo(() => {
        return layoutSize ? (layoutSize.layoutHeight - heightHeaderRow) : 0;
    }, [layoutSize])

    const heightContainer = (layoutSize?.layoutHeight || 0) - heightHeaderRow - 0;

    const provider = {
        heightContainer
    }

    return (
        <LocationsContainerContext.Provider value={provider}>
            <div 
                className='pr-15 pl-15 pb-15 pt-15 overflow-auto' 
                style={{ height: layoutHeightCalculated > 0 ? layoutHeightCalculated: 'auto' }}
            >
                {children}
            </div>
        </LocationsContainerContext.Provider>
    )
}

export const useLocationContainerContex = () => {
    return useContext(LocationsContainerContext);
};

export const LocationsContainer = () => {
    return (
        <LocationsView>
            <Locations />
        </LocationsView>
    )
}