/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch, useAppSelector } from "@store/store";
import { Avatar, Card, Col, Divider, Row, Select, Typography, theme } from "antd";
import { useEffect, useState } from "react";
import { PunchListItemLastModified } from "./PunchListItemLastModified";
import { EditPunchListItemButton } from "./EditPunchListItemButtom";
import { FloorPlanViewerReadOnly } from "../FloorPlanViewer/FloorPlanViewerReadOnly";
import { getUniqueId } from "@infrastructure/repositories/utils.repository";
import { AssetType } from "floor-plan-editor-2d";
import { IAsset } from "../../FloorPlanEditor/interfaces/interfaces";
import { CategoryBadge } from "../CategoryBadge";
import { formatDatePipe, formatFullDatetime, generateUniqueStringWithTimestamp } from "@shared/util/date-utils";
import { PunchListItemRichText } from "../SidePanel/PunchListContainer/ViewerRichText/DescriptionPunchListItemRichText";
import { editPunchListItemStatus } from "@store/slices/punch-list";
import { isNumber } from "@shared/util/number-util";
import { If } from "@components/Utils/Structural";
import { isArrayWithValues } from '../../../../../../shared/util/array-util';
import { isStringOrNumber } from "@shared/util/validations";
import { asyncLaunchNotification } from "@store/slices/notification";
import { THEME_BADGE_ENUM } from "../CategoryBadge";

const { Title } = Typography;

interface ICardDetailContainerProps {}

export const CardDetailContainer = (props: ICardDetailContainerProps) => {

    const dispatch = useAppDispatch();
    
    const { token: { colorPrimaryHover, colorPrimaryBorderHover, colorTextTertiary, colorBgContainer, paddingLG }} = theme.useToken();

    const { punchListItemSelected, punchListStatuses } = useAppSelector((store) => store.PunchList);

    const [assetSelected, setAssetSelected] = useState<IAsset | undefined>(undefined);

    const [defaultStatusSelected, setDefaultStatusSelected] = useState<number | undefined>(undefined);

    useEffect(() => {
        punchListItemSelected && setAssetSelected({ 
            id: getUniqueId(),
            data: punchListItemSelected, 
            color: colorPrimaryBorderHover,
            label: "",
            type: AssetType.MARKER,
            rotationAngle: 0,
            x: punchListItemSelected.markerLocationX,
            y: punchListItemSelected.markerLocationY
        });
    }, [punchListItemSelected]);

    useEffect(() => {
        if(isNumber(punchListItemSelected?.punchlistStatus?.id)) {
            setDefaultStatusSelected(punchListItemSelected?.punchlistStatus?.id);
        } else {
            const status = isArrayWithValues(punchListStatuses) && punchListStatuses.find((status) => status?.defaultOption === true);
            status && setDefaultStatusSelected(status.id);
        }
    }, [punchListItemSelected, punchListStatuses]);

    const onSelectStatus = (statusId: number) => {
        if (isStringOrNumber(punchListItemSelected?.id)) {
            dispatch(editPunchListItemStatus({ id: +punchListItemSelected?.id!, punchlistStatus: { id: statusId }}))
                .unwrap()
                .then(() => {
                    // Show Notifications
                    dispatch(asyncLaunchNotification({
                        type: "success",
                        config: {
                        message: `Punch list Item`,
                        description: `Punch list Item Status successfully updated`
                        }
                    }));
                });
        }
    }
    
    return (
        <Card
            style={{ 
                border: `1px solid ${colorPrimaryBorderHover}`, 
                borderRadius: 8,
                background: colorBgContainer, 
                outline: `0px solid transparent`, 
            }}
            bodyStyle={{ padding: paddingLG }}
            hoverable={true}
        >       
            {/* Header */}
            <Row justify={"space-between"} className="items-center">
                <Col>
                    {/* Number Title */}
                    <Title 
                        className="text-color-neutral-8 mt-0 imb-0 pr-12" 
                        level={5} 
                        style={{ fontWeight: 400, color: colorPrimaryHover, marginBottom: 0 }} 
                        ellipsis={true}
                    >
                        Item #{punchListItemSelected?.punchlistNumber}
                    </Title>
                </Col>
                <Col className="flex items-center">
                    <div className="pr-15">
                        <EditPunchListItemButton assetSelected={assetSelected} />
                    </div>
                    <PunchListItemLastModified punchListItem={punchListItemSelected} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Divider className="mt-12 mb-16"/>
                </Col>
            </Row>
            <Row>
                <Col span={12} className="pr-16">

                    <Row justify={"space-between"}>
                        <Col>
                            <CategoryBadge 
                                category={`${punchListItemSelected?.punchlistCategory?.description || ""}`} 
                                themeUI={THEME_BADGE_ENUM.THEME_MEDIUM}
                            />
                        </Col>
                        <Col>
                            <div>
                                <span className="mr-9" style={{ color: colorTextTertiary }}>Status</span>
                                <Select
                                    key={generateUniqueStringWithTimestamp()}
                                    defaultValue={defaultStatusSelected}
                                    options={[...punchListStatuses].map((item) => ({ value: item.id, label: (<div className="block font-semibold w-full">{item?.status}</div>) }))}
                                    onSelect={onSelectStatus}
                                    popupMatchSelectWidth={false}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-12 flex flex-row items-center">
                        <Col span={12}>
                            <div className="flex flex-row items-center">
                                <If condition={Boolean(punchListItemSelected?.assignedTo?.fullName)}>
                                    <Avatar className="cursor-default bg-color-neutral-4" gap={4}>
                                        <span className="text-color-neutral-8">
                                            {`${punchListItemSelected?.assignedTo?.fullName?.slice(0, 2)}`?.toUpperCase!()}
                                        </span>
                                    </Avatar>
                                </If>
                                <span className="flex font-bold ml-10 text-color-neutral-10">
                                    {punchListItemSelected?.assignedTo?.fullName}
                                </span>
                            </div>
                        </Col>
                        <Col span={12} className="flex flex-row items-center justify-end">
                            <span className="text-color-neutral-7">
                                {formatFullDatetime(punchListItemSelected?.createdDate)}
                            </span>
                        </Col>
                    </Row>

                    <Row className="mt-8 pl-30 mb-16">
                        <Col className="pl-10">
                            <div className="text-color-neutral-7 mr-10" key={generateUniqueStringWithTimestamp()}>
                                { punchListItemSelected?.description && <PunchListItemRichText payload={punchListItemSelected?.description} />}
                            </div>
                        </Col>
                    </Row>

                    {/* Floor Plan */}
                    <Row>
                        <Col span={24} className="flex flex-row items-center w-full pt-8 pb-8 pr-16 pl-16">
                            <div className="flex flex-row w-full items-center bg-color-neutral-3 pt-8 pb-8 pr-16 pl-16" style={{ borderRadius: 4 }}>
                                <span className="font-medium text-color-neutral-9">
                                    Floor Plan #
                                </span>
                                <span className="pl-5">
                                    {punchListItemSelected?.punchlistPage?.id}
                                </span>
                            </div>
                        </Col>
                    </Row>

                    {/* Assigned to */}
                    <Row>
                        <Col span={24} className="flex flex-row items-center w-full pt-8 pb-8 pr-16 pl-16">
                            <div className="flex flex-row w-full items-center bg-color-neutral-3 pt-8 pb-8 pr-16 pl-16" style={{ borderRadius: 4 }}>
                                <span className="font-medium text-color-neutral-9">
                                    Assigned to:
                                </span>
                                <span className="text-color-neutral-10 pl-5">
                                    {punchListItemSelected?.assignedTo?.fullName}
                                </span>
                            </div>
                        </Col>
                    </Row>

                    {/* Due Date: */}
                    <Row>
                        <Col span={24} className="flex flex-row items-center w-full pt-8 pb-8 pr-16 pl-16">
                            <div className="flex flex-row w-full items-center bg-color-neutral-3 pt-8 pb-8 pr-16 pl-16" style={{ borderRadius: 4 }}>
                                <span className="font-medium text-color-neutral-9">
                                    Due Date:
                                </span>
                                <span className="text-color-neutral-7 pl-5">
                                    {formatDatePipe(punchListItemSelected?.targetCompletionDate)}
                                </span>
                            </div>
                        </Col>
                    </Row>

                </Col>
                <Col span={12} className="pl-16">
                    {/* Floorplan Viewer */}
                    <FloorPlanViewerReadOnly 
                        height={362}
                        initialAssets={assetSelected ? [assetSelected] : []} 
                    />
                </Col>
                
            </Row>
      </Card>
    )
}
