import { RfiImpactEnum } from "@models/enumerations/rfi-impact-enum.model";
import { IUser } from "@models/user.model";
import dayjs from "dayjs";
import { createContext, useContext } from "react";

export interface ICreateRfiContext {
    number?: number;
    questionPayload?: string;
    scheduleImpact?: RfiImpactEnum | null;
    costImpact?: RfiImpactEnum | null;
    projectId?: number;
    assignedTo?: string;
    category?: string;
    dueDate?: dayjs.Dayjs | null;
    following?: string[] | null;
    allFollowers?: IUser[];
};

export const CreateRfiContext = createContext<ICreateRfiContext | null | undefined>(undefined);

export const useCreateRfiContext = () => {
    return useContext(CreateRfiContext);
};