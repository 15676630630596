import { RootState } from '@store/store';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { WrapperGoogleMap } from '@components/Google/Map/WrapperGoogleMap';
import { isArrayWithValues } from '../../../shared/util/array-util';
import { IMyPastDeliverable } from '@models/dashboard.model';

export const MyPastDeliverablesMap = () => {
  const {
    data: {
      myPastDeliverables: { list },
    },
  } = useSelector((state: RootState) => state.Dashboard);

  const [markersOptions, setMarkersOptions] = useState<google.maps.marker.AdvancedMarkerElementOptions[]>([]);

  useEffect(() => {
    processMarkerOptions(list);
  }, [list]);

  const processMarkerOptions = (list: IMyPastDeliverable[]) => {
    if (list && isArrayWithValues(list)) {
      const locationsWithCoordinates = list.filter(location => location?.longitude && location?.latitude);
      const markersPropsOptions = locationsWithCoordinates.map(({ longitude, latitude }) => ({
        position: { lat: +latitude, lng: +longitude },
      }));
      markersPropsOptions && setMarkersOptions(markersPropsOptions);
    }
  };

  const containerStyle = {
    width: '450px',
    height: '337.5px',
  };

  return (
    <div style={containerStyle}>
      <WrapperGoogleMap markers={markersOptions} />
    </div>
  );
};
