
interface ISvgIconWrapperProps {
    children: React.ReactNode;
    backgroundColor: string;
    className?: string;
    left?: string;
    width?: number;
    height?: number;
    borderRadius?: number;
    marginLeft?: string;
    top?: string;
}

const SvgIconWrapper = ({ children, className, backgroundColor, left, width, height, borderRadius, top }: ISvgIconWrapperProps) => {
    return (
        <div className={className || "absolute flex flex-row items-center justify-center"} style={{
            backgroundColor,
            left: left || "-10px",
            width: width || 20,
            height: height || 20,
            borderRadius: borderRadius || 4,
            top,
        }}>
            {children}
        </div>
    )
}

export default SvgIconWrapper 