import { UserOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@store/store';
import { Button, Drawer, Popconfirm, Select, Space, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import { getUsers } from '@store/slices/users-extended';
import { useEffect } from 'react';

interface ILinkContact {
  open: boolean;
  setOpen: (value: boolean) => void;
  setSelectedUser: (value: number | null) => void;
  selectedUser: number | null;
  onSubmit: () => void;
}

const LinkContact = (props: ILinkContact) => {
  const { open, setOpen, setSelectedUser, onSubmit, selectedUser } = props;

  const { t } = useTranslation();

  const {
    token: { colorTextSecondary },
  } = theme.useToken();

  const dispatch = useAppDispatch();

  const onChangeUser = (value: number) => {
    setSelectedUser(value);
  };

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const onClose = (): void => {
    setOpen(false);
  };

  const { usersList } = useAppSelector(state => state.UsersExtended);

  const userOptions = usersList.map(elem => {
    return { label: elem.fullName, value: elem.id };
  });

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <Drawer
      title={`${t(`company.contact.linkContact.linkProfile`)}`}
      width={580}
      onClose={onClose}
      open={open}
      styles={{ body: { paddingBottom: 80 } }}
      extra={
        <Space>
          {selectedUser ? (
            <Popconfirm
              placement="bottomRight"
              title={
                <>
                  {t('dashboard.teamManage.ifYouClose')}
                  <br />
                  {t('dashboard.teamManage.wontSave')}
                </>
              }
              okText={t('generic.accept')}
              onConfirm={() => {
                setSelectedUser(null);
                toggle();
              }}
              cancelText={t('generic.cancel')}
            >
              <Button type="default">{t('generic.cancel')}</Button>
            </Popconfirm>
          ) : (
            <Button
              type="default"
              onClick={() => {
                toggle();
              }}
            >
              {t('generic.cancel')}
            </Button>
          )}
          <Button onClick={onSubmit} type="primary" loading={false}>
            {`${t(`company.contact.linkContact.linkProfile`)}`}
          </Button>
        </Space>
      }
    >
      <div className="flex flex-col h-full">
        <div className="mb-10" style={{ color: colorTextSecondary }}>
          {t('company.contact.linkContact.selectProfile')}
        </div>
        <Select
          placeholder={`${t('generic.select')} ${t('dashboard.teamManage.user')}`}
          options={userOptions}
          showSearch
          onChange={onChangeUser}
          suffixIcon={<UserOutlined />}
          defaultValue={null}
          value={selectedUser}
          allowClear
          filterOption={(inputValue, option) => option!.label!.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
        />
        <div className="mt-5" style={{ color: colorTextSecondary }}>
          {t('company.contact.linkContact.linkContactInputPlaceholderText')}
        </div>
      </div>
    </Drawer>
  );
};

export default LinkContact;
