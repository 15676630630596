import { IChangeOrderResume } from '@models/change-order.model';
import { ICreateChangeOrderRequest } from '@models/create-change-order-request';
import { ITimeline } from '@models/timeline.model';
import { http } from '../http/axios.instance';
import { StringORNumber } from './utils.repository';
import { IChangeOrderTimelineEntry } from '@models/change-order-timeline-entry.model';
import { IChangeOrderRejectionRequest } from '@models/change-order-rejection-request';
import { IChangeOrderApprovalRequest } from '@models/change-order-approval-request';
import { IChangeOrderItem } from '@models/change-order-item.model';
import { IChangeOrderItemsRequest } from '@models/order-items-request.model';
import { IUpdateChangeOrderReasonRequest } from '@models/update-change-order-reason-request.model';

const path = 'change-orders';
const pathExtended = 'change-orders-extended';

export const changeOrdersRepository = {
    getChangeOrders:  async (projectId: string | number, page: number, size: number, sort?: string, statuses?: number[])=> {
    const requestUrl = `${pathExtended}?projectId=${projectId}&statusIds=${statuses?.join(",")}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}&cacheBuster=${new Date().getTime()}`;
  
    const promiseGetAll = await http.get<IChangeOrderResume[]>(requestUrl);
    return promiseGetAll;
  },
  getChangeOrderById: async (id: StringORNumber) => {
    const requestUrl = `${pathExtended}/${id}?cacheBuster=${new Date().getTime()}`;
    const promiseGetByID = await http.get<IChangeOrderResume>(requestUrl);
    return promiseGetByID;
  },
  createChangeOrder: async (entity: ICreateChangeOrderRequest): Promise<IChangeOrderResume> => {
    const requestUrl = `${pathExtended}`;
    const promiseCreate = await http.post<IChangeOrderResume>(requestUrl, entity);
    return promiseCreate.data;
  },
  countChangeOrders: async (projectId: StringORNumber) => {
    const requestUrl = `${path}/quantity/project/${projectId}?cacheBuster=${new Date().getTime()}`;
    const promiseCountOpen = await http.get<number>(requestUrl);
    return promiseCountOpen;
  },
  publish: async (id: StringORNumber): Promise<IChangeOrderResume> => {
    const requestUrl = `${pathExtended}/${id}/publish`;
    const promise = await http.post<IChangeOrderResume>(requestUrl);
    return promise.data;
  },
  reject: async (id: StringORNumber, rejectionReason: IChangeOrderRejectionRequest): Promise<IChangeOrderResume> => {
    const requestUrl = `${pathExtended}/${id}/reject`;
    const promise = await http.post<IChangeOrderResume>(requestUrl, rejectionReason);
    return promise.data;
  },
  requestResubmission: async (id: StringORNumber, rejectionReason: IChangeOrderRejectionRequest): Promise<IChangeOrderResume> => {
    const requestUrl = `${pathExtended}/${id}/request-resubmission`;
    const promise = await http.post<IChangeOrderResume>(requestUrl, rejectionReason);
    return promise.data;
  },
  approve: async (id: StringORNumber, approvalRequest: IChangeOrderApprovalRequest): Promise<IChangeOrderResume> => {
    const requestUrl = `${pathExtended}/${id}/approve`;
    const promise = await http.post<IChangeOrderResume>(requestUrl, approvalRequest);
    return promise.data;
  },
  getChangeOrderTimeline: async (id: StringORNumber) => {
    const requestUrl = `${pathExtended}/${id}/timeline?cacheBuster=${new Date().getTime()}`;
    const promiseGetTimeline = await http.get<ITimeline<IChangeOrderTimelineEntry>>(requestUrl);
    return promiseGetTimeline;
  },
  submitDetail: async (id: StringORNumber, items: IChangeOrderItem[]): Promise<IChangeOrderResume> => {
    const requestUrl = `${pathExtended}/${id}/submit-detail`;
    const request: IChangeOrderItemsRequest = {
      items: items
  }
    const promise = await http.post<IChangeOrderResume>(requestUrl, request);
    return promise.data;
  },
  updateReason: async (id: StringORNumber, reasonId: number | null): Promise<IChangeOrderResume> => {
    const requestUrl = `${pathExtended}/${id}/reason`;
    const request: IUpdateChangeOrderReasonRequest = {
      reasonId: reasonId
  }
    const promise = await http.put<IChangeOrderResume>(requestUrl, request);
    return promise.data;
  },
}