import { getProjectOverviewData } from './project-overview';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../reducer.util';
import { asyncLaunchNotification } from './notification';
import { IUser } from '@models/user.model';
import { IRole } from '@models/role.model';
import { CompanyReferenceEnumListOptions } from '@models/enumerations/company-reference-enum.model';
import { projectsService } from '@services/projects.service';
import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { ITeamMembersByGroup } from '@models/project-model';

export interface IProjectRoleAndUserAssigned {
  id: string;
  referenceId: number;
  referenceType: CompanyReferenceEnumListOptions;
  role: IRole;
  user: IUser;
  primary: boolean;
}

export interface IUpdateTeamMemberRequest {
  payload: IProjectRoleAndUserAssigned;
  projectId: string;
  roleBindingId: string;
}
export interface ICreateTeamMember {
  referenceId: number;
  referenceType: CompanyReferenceEnumListOptions;
  role: IRole;
  user: IUser;
  primary: boolean;
}

export interface ICreateTeamMemberRequest {
  payload: ICreateTeamMember;
  projectId: string;
}

export interface IDeleteTeamMemberRequest {
  projectId: string;
  roleBindingId: string;
}
export const getTeamMembers = createAsyncThunk('project_teams_extended/get_team_members', async (projectId: StringORNumber) => {
  return projectsService.getTeamMembers(projectId);
});

export const updateTeamMember = createAsyncThunk(
  'project_teams_extended/update_team_member',
  async ({ payload, projectId, roleBindingId }: IUpdateTeamMemberRequest, thunkAPI) => {
    const result = await projectsService.updateTeamMember(payload, projectId, roleBindingId);

    thunkAPI.dispatch(
      asyncLaunchNotification({
        type: 'success',
        config: {
          message: `Project Team Updated`,
          description: `Project Team Updated successfully`,
        },
      })
    );

    thunkAPI.dispatch(getProjectOverviewData(projectId));

    thunkAPI.dispatch(reset());

    return result;
  },
  { serializeError: serializeAxiosError }
);

export const createTeamMember = createAsyncThunk(
  'project_teams_extended/create_team_member',
  async ({ payload, projectId }: ICreateTeamMemberRequest, thunkAPI) => {
    const result = await projectsService.createTeamMember(payload, projectId);

    thunkAPI.dispatch(
      asyncLaunchNotification({
        type: 'success',
        config: {
          message: `Project Team Member Created`,
          description: `Project Team Member Created Successfully`,
        },
      })
    );

    thunkAPI.dispatch(getProjectOverviewData(projectId));

    thunkAPI.dispatch(reset());

    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteTeamMember = createAsyncThunk(
  'project_teams_extended/delete_team_member',
  async ({ projectId, roleBindingId }: IDeleteTeamMemberRequest, thunkAPI) => {
    const result = await projectsService.deleteTeamMember(projectId, roleBindingId);

    thunkAPI.dispatch(
      asyncLaunchNotification({
        type: 'success',
        config: {
          message: `Project Team Member Deleted`,
          description: `Project Team Member Deleted Successfully`,
        },
      })
    );

    thunkAPI.dispatch(getProjectOverviewData(projectId));

    thunkAPI.dispatch(reset());

    return result;
  },
  { serializeError: serializeAxiosError }
);

interface InitialState {
  data: ITeamMembersByGroup;
  isloading: boolean;
  isError: boolean;
}
const initialState: InitialState = {
  data: { coreMembers: [], dynamicMembers: [] },
  isloading: false,
  isError: false,
};

export const slice = createSlice({
  name: 'project_teams_extended',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getTeamMembers.fulfilled, (state, action) => {
        const { data } = action.payload;
        if (data) {
          state.data = data;
        }
        state.isloading = false;
        state.isError = false;
      })
      .addCase(updateTeamMember.fulfilled, (state, action) => {
        state.isloading = false;
        state.isError = false;
      })
      .addCase(updateTeamMember.pending, (state, action) => {
        state.isloading = true;
        state.isError = false;
      })
      .addCase(updateTeamMember.rejected, (state, action) => {
        state.isloading = false;
        state.isError = true;
      });
  },
});

export const { reset } = slice.actions;

// Reducer
export default slice.reducer;
