import { useAppDispatch, useAppSelector } from "@store/store";
import { Card, Form, Select, Switch, theme } from "antd"
import { useEffect, useMemo, useState } from "react";
import { IUpdateSettingArgs, getEntities as getSettings } from "@store/slices/setting";
import { getActiveEntities as getActiveCurrencies } from "@store/slices/currency";
import { updateEntity } from "@store/slices/setting";
import { ISetting, defaultCurrencyKey } from "@models/setting.model";
import { ICurrency } from "@models/currency.model";
import { useTranslation } from "react-i18next";

const { Option } = Select;

export const DefaultCurrencyCard = (props: any) => {

    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const { activeEntities: activeCurrencies } = useAppSelector((state) => state.Currency);
    const { entities: settings } = useAppSelector((state) => state.Setting);
    const [defaultCurrencySetting, setDefaultCurrencySetting] = useState<ISetting | undefined>(undefined)
    const { t } = useTranslation();
    const [selectedCurrencyText, setSelectedCurrencyText] = useState<string>("")

    useEffect(() => {
        const array = settings?.filter(setting => setting.key === defaultCurrencyKey);
        if (array?.length > 0) {
            setDefaultCurrencySetting(array[0])
            if (array[0].value) {
                const currency = activeCurrencies.find(c => c.id?.toString() === array[0].value?.toString());
                if (currency) {
                    const currencyLabel = `${currency.symbol} - ${currency.isoCode} - ${currency.name}`
                    if (currencyLabel !== selectedCurrencyText) {
                        setSelectedCurrencyText(currencyLabel)
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [settings, activeCurrencies])

    const loadSettings = () => {
        dispatch(getSettings());
        dispatch(getActiveCurrencies());
    }
    
      useEffect(() => {
        loadSettings();
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    const sortedCurrencies = useMemo(() => {
        return [...activeCurrencies].sort((c1: ICurrency, c2: ICurrency) => {
            if (c1.name! > c2.name!) { return 1; }
            if (c1.name! < c2.name!) { return -1; }
            return 0;
        });
    }, [activeCurrencies]);

    const { token: { colorPrimary, colorTextDisabled, colorBorderSecondary, colorBgBase } } = theme.useToken();

    const [isEditing, setIsEditing] = useState<boolean>(false)

    const toggleEdit = () => {
        setIsEditing(!isEditing)
    };

    const handleChange = (value: String) => {
        const array = settings?.filter(setting => setting.key === defaultCurrencyKey);
        if (array?.length > 0) {
            let settingToSave = JSON.parse(JSON.stringify(array[0]));
            settingToSave.value = `${value}`;
            const args: IUpdateSettingArgs = { 
                entity: settingToSave,
                onSuccess: () => {
                    loadSettings()
                }
            }
            dispatch(updateEntity(args))
        }
    }

    return (defaultCurrencySetting ?
        <Card title="Default Currency" style={{ width: 400, marginBottom: '16px' }} extra={<div><span style={{ color: (isEditing ? colorPrimary : colorTextDisabled), marginRight: '8px', cursor: 'pointer' }} onClick={toggleEdit}>{isEditing ? 'Managing' : 'Manage'}</span><Switch checked={isEditing} onClick={toggleEdit} /></div>}>
            <div style={{ backgroundColor: colorBgBase, border: '1px solid ' + colorBorderSecondary, borderRadius: '4px', padding: '4px 8px', marginBottom: '4px' }}>
                {!isEditing && selectedCurrencyText}
                {isEditing && <Form
                    layout="horizontal"
                    form={form}
                    initialValues={{
                        defaultCurrency: defaultCurrencySetting?.value ? Number(defaultCurrencySetting?.value) : null
                    }}
                    style={{ display: 'inline-block' }}
                >
                    <Form.Item name="defaultCurrency" rules={[{ required: true, message: t("settings.defaultCurrencyRequired") }]} style={{ marginBottom: '0px' }}>
                        <Select
                            onChange={handleChange}
                            style={{ width: 270 }}
                        >
                            {sortedCurrencies.map((currency) => (
                                <Option value={currency.id} key={currency.id}>
                                    {`${currency.symbol} - ${currency.isoCode}: ${currency.name}`}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>}
            </div>
        </Card>
        :
        <></>
    );
}