import { IGroup, defaultValue } from "@models/group.model";
import { IQueryParamsGeneric } from "@models/utils";
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { groupService } from "@services/group.service";
import { cleanEntity } from "@shared/util/entity-utils";
import { EntityState, createEntitySlice, serializeAxiosError } from "@store/reducer.util";
import { getMessageError } from "./users";
import { asyncLaunchNotification } from "./notification";
import { IQueryParams } from "@models/pagination";

export const getEntities = createAsyncThunk(
    'groups/fetch_group_list',
    async (params: IQueryParamsGeneric, thunkAPI) => {
      return groupService.getAll(params);
    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
    'groups/fetch_group_by_id',
    async (groupId: string, thunkAPI) => {
      const group = await groupService.getGroupById(groupId);
      return group;
    },
    { serializeError: serializeAxiosError }
);

  export const createEntity = createAsyncThunk(
    'groups/create_entity',
    async (entity: IGroup, thunkAPI) => {
      const result = await groupService.create(cleanEntity(entity))
        .catch((error) => {
          const message = getMessageError({ error, entityAction: `create the group`, entity})
          thunkAPI.dispatch(asyncLaunchNotification({
            type: "error",
            config: {
              message: `Changes could not be saved`,
              description: message
            }
          }))
          return thunkAPI.rejectWithValue(error);
        });
  
      return result;
    }
  );
  
  export const updateEntity = createAsyncThunk(
    'groups/update_entity',
    async (entity: IGroup, thunkAPI) => {
      const group = await groupService.update(entity.id, cleanEntity(entity))
        .catch((error) => {
          const message = getMessageError({ error, entityAction: `update the group`, entity}) 
          thunkAPI.dispatch(asyncLaunchNotification({
            type: "error",
            config: {
              message: `Changes could not be saved`,
              description: message
            }
          }))
          return thunkAPI.rejectWithValue(error);
        });
  
      return group;
    }
  );

  const initialState: EntityState<IGroup> = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    lastPagination: {} as IQueryParams,
    updateSuccess: false,
  };
  
  export const slice = createEntitySlice({
    name: 'groups',
    initialState,
    reducers: {
      reset: (state) => {
        return initialState
      },
    },
    extraReducers(builder) {
      builder
        .addMatcher(isFulfilled(getEntities), (state, action) => {
          const { data } = action.payload;
          return {
            ...state,
            loading: false,
            entities: data,
            totalItems: data.length,
          };
        })
        .addMatcher(isFulfilled(getEntity), (state, action) => {
          state.loading = false;
          state.entity = action.payload.data as IGroup;
        })
        .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entity = action.payload as IGroup;
        })
        .addMatcher(isPending(getEntities), state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.loading = true;
        })
        .addMatcher(isPending(createEntity, updateEntity), state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        });
    }
  });
  
  export const { reset } = slice.actions;
  
  // Reducer
  export default slice.reducer;