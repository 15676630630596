import { useState } from 'react';
import { Button, Popover, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { EllipsisOutlined } from "@ant-design/icons";
import { useAppDispatch } from "@store/store";
import { asyncLaunchNotification } from "@store/slices/notification";
import { EditSvgIcon } from "../../Icons/EditSvgIcon";
import { ISite } from '@models/site.model';
import ConfigProvider from "antd/es/config-provider";
import "./Menu.less";

export interface componentProps {
    site: ISite 
}

export const SiteMenu = (props: componentProps) => {

    const { site } = props
    
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [openned, setOpenned] = useState(false);
    
    const { token: { colorFill } } = theme.useToken();

    const onEdit = () => {
        setOpenned(!openned);
        setTimeout(() => {
            if (site?.id) {
                navigate(`edit`)
            } else {
                dispatch(asyncLaunchNotification({
                    type: "error",
                    config: {
                      message: `Site`,
                      description: `Site ID not found`
                    }
                }));
            }
        }, 50)   
    }
    
    const content = (
        <div className="menu-content pb-5 pt-10" style={{ width: '180px' }}>
            <div className="flex items-center mb-5 cursor-pointer menu-item pt-3 pb-5 " onClick={ () => onEdit()  }>
                <EditSvgIcon className="ml-15" />
                <span className="ml-10">Edit</span>
            </div>
        </div>
    )

    return (
        <ConfigProvider theme={{ token: { borderRadius: 0 } }}>
            <Popover content={content} open={openned} onOpenChange={ () => setOpenned(!openned) } placement="bottomLeft" trigger="click" overlayClassName="wrapper-popover-menu">
                <div className="pr-5">
                    <Button type="text" shape="circle" ghost={false} style={{ backgroundColor: colorFill }} className='flex flex-row justify-center items-center' icon={
                        <EllipsisOutlined className="font-extrabold cursor-pointer" style={{ fontSize: "1.2rem" }} />
                    } >  
                    </Button>
                </div>
            </Popover>
        </ConfigProvider>
    )

}


