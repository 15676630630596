import { IAsset } from "@components/Locations/Sites/Projects/FloorPlanEditor/interfaces/interfaces";
import { IPhotoAlbum } from "./photo-album.model";
import { StringORNumber } from "@infrastructure/repositories/utils.repository";
import { IProject } from '@models/project-model';
import { IUser } from "./user.model";
import { IAttachment } from "./attachment.model";

export interface IPunchListItem {
    id?: number | string;
    itemNumber?: number | string;
    category?: { id: number, name?: string };
    assignedTo?: IUser;
    requiredBy?: string;
    description?: string;
    asset?: IAsset;
    album?: IPhotoAlbum;
    totalAttachments?: number;
    totalComments?: number;

    punchlistCategory?: IPunchListItemCategory;
    punchlistStatus?: IPunchListItemStatus;
    punchlistNumber?: number;
    actualCompletionDate?: string;
    targetCompletionDate?: string;
    markerLocationX: number;
    markerLocationY: number;

    punchlistPage?: Partial<IPunchListItemPage>

    //Auditory
    lastModifiedByFullName?: string
    createdByEmail?: string;
    createdByFirstName?: string;
    createdByLastName?: string;
    createdDate?: string;
    lastModifiedByEmail?: string;
    lastModifiedByFirstName?: string;
    lastModifiedByLastName?: string;
    lastModifiedDate?: string;
}

export interface IPunchListItemCategory {
    id?: number;
    description?: string;
    active?: boolean;
}

export interface IPunchListItemStatus {
    id?: number;
    status?: string;
    order?: boolean | number | null;
    defaultOption?: boolean | null;
    finalState?: boolean | null;
}

export interface IPunchListReason {
    id?: number;
    description?: string;
    order?: boolean | number | null;
    active?: boolean;
}

export interface IEditPunchListItemStatusRequest {
    id: number | string;
    punchlistStatus: Pick<IPunchListItemStatus, "id">
}

export interface IPunchListCheckGenerationPreviewsRequest {
    id: number | string;
}
export interface ICreatePunchListPagesWithPreviewsPagesSelectedRequest  {
    id: number | string;
    selectedPages: number[]
}

export interface IPunchListItemPage {
    id: StringORNumber;
    order: number;
    project: IProject;
    floorPlan: IAttachment
}

export interface IPunchListItemPagePagination extends IPunchListItemPage {
    index: number;
}
export interface IPunchListItemPageUI extends  IPunchListItemPage {
    nextPage?: IPunchListItemPagePagination;
    prevPage?: IPunchListItemPagePagination;
}

export interface IPunchListPageFilters { 
    projectId: StringORNumber 
}

export interface IPunchListItemsFilters { 
    pageId: StringORNumber 
}
  
export const defaultValue: Readonly<IPunchListItem> = {} as IPunchListItem;
export const defaultCategoryValue: Readonly<IPunchListItemCategory> = {} as IPunchListItemCategory;
export const defaultStatusValue: Readonly<IPunchListItemStatus> = {} as IPunchListItemStatus;
export const defaultReasonValue: Readonly<IPunchListReason> = {} as IPunchListReason;