import { IGroup, defaultValue } from "@models/group.model";
import { IGroupBinding } from "@models/group-binding.model";
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { groupBindingService } from "@services/group-binding.service";
import { cleanEntity } from "@shared/util/entity-utils";
import { EntityState, createEntitySlice, serializeAxiosError } from "@store/reducer.util";
import { asyncLaunchNotification } from "./notification";
import { IQueryParams } from "@models/pagination";

export const getEntities = createAsyncThunk(
    'group_bindings/fetch_list_by_group_id',
    async (groupId: any, thunkAPI) => {
      const groupBindings = await groupBindingService.getAllByGroupId(groupId);
      return groupBindings;
    },
    { serializeError: serializeAxiosError }
);

export const getEntitiesByUserId = createAsyncThunk(
  'group_bindings/fetch_list_by_user_id',
  async (userId: any, thunkAPI) => {
    const groupBindings = await groupBindingService.getAllByUserId(userId);
    return groupBindings;
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
    'group_bindings/create_entity',
    async (entity: IGroupBinding, thunkAPI) => {
      const result = await groupBindingService.create(cleanEntity(entity))
        .catch((error) => {
          const message = `Error adding user to group`;
          thunkAPI.dispatch(asyncLaunchNotification({
            type: "error",
            config: {
              message: `Changes could not be saved`,
              description: message
            }
          }))
          return thunkAPI.rejectWithValue(error);
        });
  
      return result;
    }
);

export const deleteEntity = createAsyncThunk(
    `group_bindings/delete_entity`,
    async (id: string, thunkAPI) => {
        console.log(id);
      const result = await groupBindingService.delete(id);
      return result;
    },
    { serializeError: serializeAxiosError }
);

  const initialState: EntityState<IGroupBinding> = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    lastPagination: {} as IQueryParams,
    updateSuccess: false,
  };
  
  export const slice = createEntitySlice({
    name: 'group_bindings',
    initialState,
    reducers: {
      reset: (state) => {
        return initialState
      },
    },
    extraReducers(builder) {
      builder
        .addMatcher(isFulfilled(getEntities), (state, action) => {
          const { data } = action.payload;
          return {
            ...state,
            loading: false,
            entities: data,
            totalItems: data.length,
          };
        })
        .addMatcher(isFulfilled(getEntitiesByUserId), (state, action) => {
          const { data } = action.payload;
          return {
            ...state,
            loading: false,
            entities: data,
            totalItems: data.length,
          };
        })
        .addMatcher(isFulfilled(createEntity), (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entity = action.payload as IGroup;
        })
        .addMatcher(isPending(getEntities), state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.loading = true;
        })
        .addMatcher(isPending(getEntitiesByUserId), state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.loading = true;
        })
        .addMatcher(isPending(createEntity), state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        });
    }
  });
  
  export const { reset } = slice.actions;
  
  // Reducer
  export default slice.reducer;