import React, { useRef, useState, useEffect } from 'react';
import { Row } from "antd";
import { useContainerFileContex } from './ContainerFileProvider';

interface IImageContainerBoxProps {
  width: string,
  height: string,
  imageUrl: string
}

interface Position {
  x: number;
  y: number;
}


export const ImageContainerBox = (props: IImageContainerBoxProps) => {

    const { width, height, imageUrl } = props;

    useEffect(() => {
    }, [])

    return (
        <Row>
            <ZoomableImage imageUrl={imageUrl} width={width} height={height}  />
        </Row>
    )
}

export const ZoomableImage = (props: IImageContainerBoxProps) => {

  const {imageUrl, width, height  } = props;
  
  const imageRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  
  const { scale, setScale } = useContainerFileContex();

  const [position, setPosition] = useState<Position>({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);

  const [dragStart, setDragStart] = useState<Position | null>(null);

  const [pinchStart, setPinchStart] = useState<number>(0);
  const [pinchScale, setPinchScale] = useState<number>(1);

  useEffect(() => {
    const containerElement = containerRef.current;
    if (containerElement) {
      const handleWheel = (event: WheelEvent) => {

        event.preventDefault();
        const scaleFactor = 1.1;
        const wheelScale = event.deltaY < 0 ? scaleFactor : 1 / scaleFactor;
        const newScale = Math.min(5, Math.max(1, scale * wheelScale));

        setScale(newScale);

        if (newScale < 1.01) {
            setPosition(() => ({ x: 0, y: 0 }))
        }
      };

      containerElement.addEventListener('wheel', handleWheel);

      return () => {
        containerElement.removeEventListener('wheel', handleWheel);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale]);

  const handleTouchStart = (event: React.TouchEvent<HTMLImageElement>) => {
    if (event.touches.length === 1) {
      setDragging(true);
    } else if (event.touches.length === 2) {
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];
      const pinchDistance = Math.hypot(
        touch2.clientX - touch1.clientX,
        touch2.clientY - touch1.clientY
      );
      setPinchStart(pinchDistance);
    }
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLImageElement>) => {
    if (event.touches.length === 1 && dragging) {
      const touch = event.touches[0];
      setPosition((prevPosition) => ({
        x: prevPosition.x + touch.clientX - touch.pageX,
        y: prevPosition.y + touch.clientY - touch.pageY,
      }));
    } else if (event.touches.length === 2) {
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];
      const pinchDistance = Math.hypot(
        touch2.clientX - touch1.clientX,
        touch2.clientY - touch1.clientY
      );
      const deltaPinch = pinchDistance - pinchStart;
      const newScale = Math.max(1, pinchScale + deltaPinch * 0.01);
      setScale(newScale);
    }
  };

  const handleTouchEnd = () => {
    setDragging(false);
    setPinchScale(scale);   
  };


  const handleMouseDown = (event: React.MouseEvent<HTMLImageElement>) => {
    event.preventDefault();
    setDragging(true);
    const { clientX, clientY } = event;
    setDragStart({ x: clientX, y: clientY });
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!dragging || !dragStart) return;

    const { clientX, clientY } = event;
    const deltaX = clientX - dragStart.x;
    const deltaY = clientY - dragStart.y;

    if (scale && scale > 1) { 
        if (containerRef.current?.clientWidth && scale) {
    
            const leftX = (scale * containerRef.current?.clientWidth) - containerRef.current?.clientWidth;
    
            setPosition(prevPosition => {
                let xPosition = prevPosition.x + deltaX;
                if (leftX === 0) {
                    xPosition = 0;
                } else {
                    xPosition = prevPosition.x + deltaX
                }
                return {
                    x: xPosition,
                    y: prevPosition.y + deltaY
                }
            });
    
            setDragStart({ x: clientX, y: clientY });
        }
    } else {
        setPosition(() => ({ x: 0, y: 0 }))
    }
  };

  const handleMouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
    setDragging(false);
    setDragStart(null);
  };

  return (
    <div
      className="zoomable-image-container select-none"
      ref={containerRef}
      style={{
        width: `${width}`,
        height: `${height}`,
        maxWidth: "100%",
        maxHeight: "100%",
        overflow: 'hidden',
        touchAction: 'none',
      }}
    >
      <img
        className="zoomable-image"
        src={imageUrl}
        alt="file preview"
        ref={imageRef}
        loading='lazy'
        style={{
          transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
          touchAction: 'none',
          width: "100%",
          minWidth: '100%',
          minHeight: '100%',
          objectFit: 'contain',
          maxWidth: "100%",
          maxHeight: "100%"
        }}


        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}

        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}


      />
    </div>
  );
}


