import { Col, ConfigProvider, Divider, Row, theme, Typography, Button, Tooltip } from 'antd';
import { IProjectDeliverableUI } from '@models/project-deliverable';
import { EmptyLabel } from '@components/Utils/EmptyLabel';
import AttachmentsList from '@components/Attachment/AttachmentsList';
import CommentsList from '@components/Comment/CommentsList';
import { AttachmentReferenceEnum } from '@models/enumerations/attachment-reference-enum.model';
import { CommentReferenceEnum } from '@models/enumerations/comment-reference-enum.model';
import { StatusProjectDeliverableButton } from './StatusProjectDeliverableButton';
import { StatusApprovalProjectDeliverableButton } from './StatusApprovalProjectDeliverableButton';
import { ApprovalControlBar } from './ApprovalControlBar';
import { TimeLineContainer } from './TimeLineContainer';
import { DependencyLabel } from './DependencyLabel';
import { COLOR_TEXT_BASE, useThemeProvider, WHITE } from '@providers/ThemeProvider';
import { Can } from '@components/Permisions/Can';
import { DeliverableDetailsButtonEditDetails } from './DeliverableDetails/DeliverableDetailsButtonEditDetails';
import { DeliverableLastModified } from './DeliverableDetails/DeliverableLastModified';
import { BadgeTypeButton } from '@components/Utils/BadgeTypeButton';
import { InfoCircleOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { If } from '@components/Utils/Structural';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@store/store';
import { useParams } from 'react-router-dom';
import { getProjectDeliverablesData } from '@store/slices/project-deliverables';
import { richCommentJsonToPlainText } from '@shared/util/rich-comment-utils';
import { useState, useEffect } from 'react';
import { ViewerRichText } from '@components/Comment/ViewerRichText';
import { KeySvgIcon } from '@components/Icons/KeyIcon';

const { Text } = Typography;

interface IDeliverableDetailsProps {
  deliverableActive: IProjectDeliverableUI;
  referenceId: number | null;
}

export const DeliverableDetails: React.FC<IDeliverableDetailsProps> = ({ deliverableActive, referenceId }) => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams<'projectId'>();
  const { themeConfig } = useThemeProvider();
  const {
    token: {
      marginLG,
      colorTextTertiary,
      colorBorder,
      colorText,
      colorFillQuaternary,
      colorBorderSecondary,
      fontSizeHeading4,
      colorPrimary,
      colorFillSecondary,
    },
  } = theme.useToken();
  const { t } = useTranslation();
  const paddingLeftAndRightCalculated = { paddingLeft: `${marginLG}px`, paddingRight: `${marginLG}px` };

  const onSuccessDelete = () => {
    dispatch(getProjectDeliverablesData(String(projectId)));
  };

  const [infoGuideShow, setInfoGuideShow] = useState<boolean>(false);
  const [infoGuideExpandable, setInfoGuideExpandable] = useState<boolean>(false);

  const infoGuideTxt = (
    (deliverableActive.deliverable?.infoGuide && richCommentJsonToPlainText(deliverableActive.deliverable?.infoGuide)) ||
    ''
  ).substring(0, 45);

  useEffect(() => {
    if (deliverableActive.deliverable?.infoGuide && richCommentJsonToPlainText(deliverableActive.deliverable?.infoGuide).length > 45) {
      setInfoGuideExpandable(true);
    }
  }, [deliverableActive.deliverable?.infoGuide]);

  const toggleInfoGuide = () => {
    setInfoGuideShow(!infoGuideShow);
  };

  return (
    <>
      <Row className="w-full pt-20 pb-20" justify={'center'} align={'middle'} style={paddingLeftAndRightCalculated}>
        <Col className="w-full">
          <Row justify={'space-between'}>
            <Col className="w-max flex flex-row items-center">
              <div className="flex flex-row items-center w-min">
                <Text
                  className="font-bold"
                  style={{ fontSize: fontSizeHeading4 }}
                  ellipsis={true ? { tooltip: `deliverable?.name` || <EmptyLabel /> } : false}
                >
                  {deliverableActive?.deliverable?.name || <EmptyLabel />}
                </Text>
                <If condition={Boolean(deliverableActive?.phase)}>
                  <div
                    className="w-max ml-10"
                    style={{
                      border: `1px solid ${colorBorder}`,
                      color: colorText,
                      backgroundColor: colorFillQuaternary,
                      borderRadius: 20,
                    }}
                  >
                    <span className="pr-8 pl-8 font-bold text-color-neutral-7">{deliverableActive?.phase?.label}</span>
                  </div>
                </If>
                <If condition={Boolean(deliverableActive?.deliverable?.isKeyMilestone)}>
                  <BadgeTypeButton className="w-max ml-10">
                    <KeySvgIcon className="ml-8 mr-4 text-color-neutral-7" style={{ fontSize: 12 }} />
                    <span className="mr-8 font-bold text-color-neutral-7">{t('projectDeliverable.keyMilestone')}</span>
                  </BadgeTypeButton>
                </If>
              </div>
            </Col>
            <Col flex={'none'}>
              <StatusApprovalProjectDeliverableButton deliverable={deliverableActive} />
              <StatusProjectDeliverableButton deliverable={deliverableActive} />
            </Col>
          </Row>
          <Row>
            <Col flex={'auto'} className="pt-11">
              <div>
                <span className="font-bold mr-5">Owner:</span>
                <span>
                  {deliverableActive.deliverable?.role?.name ? `${deliverableActive.deliverable?.role?.name} - ` : ''}
                  {deliverableActive?.owner || <EmptyLabel />}
                </span>
              </div>
            </Col>
            <Col flex={'none'} className="pt-11">
              <DeliverableLastModified deliverable={deliverableActive} />
            </Col>
          </Row>
          {infoGuideTxt && deliverableActive.deliverable?.infoGuide && (
            <Row>
              <Col flex={'auto'} className="mt-10 pt-5" style={{ borderTop: '1px solid ' + colorBorderSecondary }}>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <div className="pr-5">
                    <Tooltip title={t('projectDeliverable.infoGuide')}>
                      <InfoCircleOutlined style={{ color: colorTextTertiary }} />
                    </Tooltip>
                  </div>
                  <div style={{ width: '327px' }}>
                    {infoGuideShow ? (
                      <ViewerRichText payload={deliverableActive.deliverable?.infoGuide} />
                    ) : (
                      `${infoGuideTxt}${infoGuideExpandable ? '...' : ''}`
                    )}
                  </div>
                  <div className="pl-5">
                    {infoGuideExpandable && infoGuideShow ? (
                      <Tooltip title={t('generic.collapse')}>
                        <Button
                          type="text"
                          onClick={toggleInfoGuide}
                          icon={<CaretUpOutlined />}
                          style={{
                            backgroundColor: colorFillSecondary,
                            color: colorBorderSecondary,
                            width: '24px',
                            height: '24px',
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={t('generic.expand')}>
                        <Button
                          type="text"
                          onClick={toggleInfoGuide}
                          icon={<CaretDownOutlined />}
                          style={{ color: colorPrimary, width: '24px', height: '24px' }}
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <Divider className="mt-5 mb-0" />

      <ApprovalControlBar deliverable={deliverableActive} />

      <Row className="w-full mt-30" style={paddingLeftAndRightCalculated}>
        <Col span={12} className="pr-20">
          <TimeLineContainer deliverableActive={deliverableActive} />
        </Col>

        <Col span={12} className="w-full pl-20">
          <Row>
            <Col span={12}>
              <span className="font-bold" style={{ color: colorTextTertiary }}>
                Details
              </span>
            </Col>
            <Col span={12}>
              <div className="flex flex-row justify-end">
                <DeliverableDetailsButtonEditDetails deliverable={deliverableActive} />
              </div>
            </Col>
          </Row>
          <Row className="mt-20" justify={'space-between'}>
            <Col flex={'none'}>
              <span className="font-bold">Discipline:</span>
            </Col>
            <Col flex={'none'}>
              <div className="flex flex-row justify-end">
                <span className="font-bold">{deliverableActive?.deliverable?.discipline?.name || <EmptyLabel />}</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-20" justify={'space-between'}>
            <Col flex={'none'}>
              <span className="font-bold">Dependent on:</span>
            </Col>
            <Col flex={'none'}>
              <div className="flex flex-row justify-end">
                <DependencyLabel deliverable={deliverableActive} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="w-full mt-20" justify={'center'} align={'middle'} style={paddingLeftAndRightCalculated}>
        <Col span={24}>
          {referenceId && (
            <AttachmentsList
              entityType={AttachmentReferenceEnum.PROJ_DELIVERABLE}
              referenceId={referenceId}
              deliverable={deliverableActive}
              onSuccessRemove={onSuccessDelete}
            />
          )}
        </Col>
      </Row>
      <Can I="READ" A="group.oak.siterise.service.dto.RichCommentDTO" effect="HIDE">
        <Row className="w-full mt-30" justify={'center'} align={'middle'} style={paddingLeftAndRightCalculated}>
          <ConfigProvider theme={{ token: { colorTextBase: themeConfig.darkMode ? WHITE : COLOR_TEXT_BASE } }}>
            <Col span={24}>
              {referenceId && <CommentsList entityType={CommentReferenceEnum.PROJ_DELIVERABLE} referenceId={referenceId} />}
            </Col>
          </ConfigProvider>
        </Row>
      </Can>
    </>
  );
};
