import { IProjectDeliverableUI } from '@models/project-deliverable';
import React from 'react'
import { ErrorUserPolicy } from './ErrorUserPolicy';
import { WaitingApprovalWarning } from './WaitingApprovalWarning';
import { ApprovalCompleteInfo } from './ApprovalCompleteInfo';
import { isArrayWithValues } from '@shared/util/array-util';

interface IApprovalControlBarProps {
    deliverable: IProjectDeliverableUI;
}

export const ApprovalControlBar: React.FC<IApprovalControlBarProps> = ({ deliverable }) => {

    const renderContent = () => {

        if (deliverable.isStatusCompleted && isArrayWithValues(deliverable.approvals)) {
            return (<ApprovalCompleteInfo deliverable={deliverable} />) 
        }
        
        if (!deliverable.isStatusPendingApproval) return (<React.Fragment />)

        if (deliverable.hasUserErrorPolicy) {
            return <ErrorUserPolicy />
        }

        if (!deliverable.hasUserErrorPolicy) {
            return (<WaitingApprovalWarning deliverable={deliverable} />)
        } 
    }

    return (
        <>{ renderContent() }</>
    )
}
