import { IChangeOrderReason } from "./change-order-reason.model";
import { IChangeOrderStatusTransition } from "./change-order-status-transition.model";
import { ICurrency } from "./currency.model";
import { IRfi } from "./rfi.model";

export interface IChangeOrderResume {
    id?: number;
    number?: number;
    projectId?: number;
    createdDate?: string;
    createdBy?: number;
    createdByFirstName?: string;
    createdByLastName?: string;
    createdByEmail?: string;
    description?: string;
    drawingReference?: string | null;
    scheduleImpact?: boolean | null;
    scheduleImpactDays?: number | null;
    estimatedCost?: string | null;
    totalCost?: string | null;
    currency?: ICurrency;
    dueDate?: string | null;
    lastStatusTransition?: IChangeOrderStatusTransition;
    relatedRfi?: IRfi;
    currentRevisionId?: number;
    reason?: IChangeOrderReason;
}

export const defaultChangeOrderResume: Readonly<IChangeOrderResume> = {};


