export enum AddressReferenceEnum {
  COMPANY = 'COMPANY',

  CONTACT = 'CONTACT',

  LOCATION = 'LOCATION',

  SITE = 'SITE',
}

export type AddressReferenceEnumListOptions = keyof typeof AddressReferenceEnum