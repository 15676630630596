/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useErrorAnimation } from "@HOOKs/UseErrorAnimation";
import { IGroup } from "@models/group.model";
import { isFulfilled } from "@reduxjs/toolkit";
import { asyncLaunchNotification } from "@store/slices/notification";
import { createEntity, updateEntity } from "@store/slices/groups";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Button, Col, Drawer, Form, Input, Row, Space } from "antd";
interface IGroupEditProps {
  open: boolean,
  toogle: () => void,
  onModifiedChanges?: (group: IGroup) => void,
  group: IGroup
}

export const GroupEdit = (props: IGroupEditProps) => {

    const { open, toogle, group, onModifiedChanges } = props;

    const dispatch = useAppDispatch();

    const [form] = Form.useForm();

    const { loading, updating } = useAppSelector((state) => state.Groups);

    const [animateError, playAnimationOfError] = useErrorAnimation();

    const onFinishFailed = (errorInfo: any) => playAnimationOfError();

    const onFinish = (entity: any): void => {
        const hasGroup = group?.id;

        if (hasGroup) {
            dispatch(
                updateEntity({
                    ...group,
                    ...entity
                })  
            ).then(onSuccessUpdatedGroup);
            
        } else {
            dispatch(createEntity({
                id: "",
                ...entity
            })).then(onSuccessCreateGroup);
        }
    };

    const onSuccessUpdatedGroup = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: `Changes saved`,
                  description: `Your changes have been saved successfully`
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onSuccessCreateGroup = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: `Changes saved`,
                  description: `Group created successfully`
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onClose = () => { 
        form.resetFields();
        toogle(); 
    }

    const onSubmit = () => form.submit();

    useEffect(() => { setGroupFormFields() }, [group])

    const setGroupFormFields = () => {
        form.setFieldsValue({
            ...group
        });
    }

    return (
        <>
            <Drawer 
                width={448} 
                title={`${group?.id ? "Edit": "Add"} group`}
                placement="right" 
                onClose={onClose} 
                open={open}
                closable={false}
                extra={
                    <Space>
                      <Button type="primary" ghost onClick={onClose}>
                        Cancel
                      </Button>
                      <Button loading={loading} onClick={onSubmit} type="primary" disabled={updating}>
                        {`${group?.id ? "Save": "Create"}`}
                      </Button>
                    </Space>
                  }
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className={`${animateError && "error-animation"}`}
                >

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[
                                    { required: true, message: "Name field is Required" },
                                ]}
                            >
                                <Input placeholder="Name" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Drawer>
        </>
  );
}
