
import { featureFlagEvaluationRepository } from '@infrastructure/repositories/feature-flag-evaluation.repository';
import { IFeatureFlagEvalResult } from '@models/feature-flag-eval-result.model';
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { serializeAxiosError } from "../reducer.util";

interface IStateEntity {
    loading: boolean,
    errorMessage: any,
    flags: IFeatureFlagEvalResult[] | undefined,
}

export const getEntities = createAsyncThunk(
    'featureFlagEvaluation',
    async () => {
        return featureFlagEvaluationRepository.getAll();
    },
    { serializeError: serializeAxiosError }
);

const initialState: IStateEntity = {
    loading: false,
    errorMessage: null,
    flags: undefined,
};

export const FeatureFlagEvaluationSlice = createSlice({
    name: 'featureFlagEvaluation',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const { data } = action.payload;

                return {
                    ...state,
                    loading: false,
                    flags: data,
                };
            })
            .addMatcher(isPending(getEntities), state => {
                state.errorMessage = null;
                state.loading = true;
            });
    }
});

// Reducer
export default FeatureFlagEvaluationSlice.reducer;
