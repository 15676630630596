import { useLayoutEffect } from 'react'
import { Breadcrumb, theme, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { useResizeDetector } from 'react-resize-detector';
import { UserMenu } from '@components/UserMenu/UserMenu';
import { useLayoutContex } from '@providers/LayoutProvider';
import { formatDatePipe, DATE_FORMAT_FULL_NAME_MONTH } from '@shared/util/date-utils';
import { configuration } from '../../environments/env';
import dayjs from 'dayjs';
import { Search } from '@components/Layout/HeaderLayout/Search';
import { Support } from '@components/Layout/HeaderLayout/Support';

export const DashBoardHeader = () => {
  const {
    token: { colorBgLayout, marginLG, margin },
  } = theme.useToken();

  const { height: headerHeight, ref: headerRef } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 50,
  });

  const { setHeaderSizeState } = useLayoutContex();

  useLayoutEffect(() => {
    setHeaderSizeState && setHeaderSizeState(headerHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerHeight]);

  return (
    <Header ref={headerRef} className="flex flex-row justify-between" style={{ padding: 0, background: colorBgLayout, height: 'auto' }}>
      <div className="flex flex-col">
        <div className="flex flex-col" style={{ marginLeft: `${marginLG}px`, marginTop: `${margin}px` }}>
          <div>
            <Breadcrumb className="pointer-events-none">
              <Breadcrumb.Item>{formatDatePipe(dayjs().toString(), DATE_FORMAT_FULL_NAME_MONTH)}</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="flex flex-row items-center">
            <Typography.Title level={4} style={{ marginTop: `${margin}px`, marginBottom: `${margin}px`, height: 'auto' }}>
              Welcome to {configuration.APP_NAME}!
            </Typography.Title>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-center" style={{ height: 'auto' }}>
        <div style={{ height: '4rem', position: 'relative', float: 'right', textAlign: 'end' }} className="mr-15">
          <Search />
        </div>
        <div className="realtive h-auto mt-5 mr-20">
          <Support />
        </div>
        <div style={{ height: 'auto' }}>
          <UserMenu />
        </div>
      </div>
    </Header>
  );
};

