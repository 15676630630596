import { http } from '../http/axios.instance';

const path = '/account';

export const accountRepository = {
  validateToken: async (data: {token: string, actionType: string}) => {

    const _params = new URLSearchParams({
      actionType: data.actionType,
      token: data.token
    })

    const requestUrl = `${path}/validate-token?${_params.toString()}`;

    const promiseSetPassword = await http.get(requestUrl);
    return promiseSetPassword;
  },
  initialSetPassword: async (data: { key: string, newPassword: string}) => {
    const requestUrl = `${path}/set-initial-password`;

    const promiseSetPassword = await http.post(requestUrl, data).catch((err) => { throw err; });
    return promiseSetPassword;
  },
  passwordReset: async (data: { key: string, newPassword: string}) => {
    const requestUrl = `${path}/reset-password/finish`;

    const promisePasswordReset = await http.post(requestUrl, data);
    return promisePasswordReset;
  }
}