import { Button, Modal, theme } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getLatestByProjectId, handleApproval } from '@store/slices/budget-extended';
import { WarningOutlined } from '@ant-design/icons';
import { asyncLaunchNotification } from '@store/slices/notification';
import { useParams } from 'react-router-dom';

export const BudgetApprovalAction = () => {
  const { t } = useTranslation();
  const [approve, setApprove] = useState<boolean | null>(null);

  return approve === null ? (
    <div className="flex justify-between items-center">
      <Button type="link" danger onClick={() => setApprove(false)}>
        {t('budget.approval.reject')}
      </Button>
      <Button type="primary" onClick={() => setApprove(true)}>
        {t('budget.approval.approve')}
      </Button>
    </div>
  ) : (
    <ApproveDialog approve={approve} setApprove={setApprove} />
  );
};

const ApproveDialog = ({ approve, setApprove }: { approve: boolean; setApprove: Dispatch<SetStateAction<boolean | null>> }) => {
  const { id: budgetId } = useAppSelector(state => state.BudgetExtended.entity);
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const {
    token: {
      colorPrimaryBorder,
      colorPrimaryTextActive,
      colorPrimaryBg,
      colorPrimaryBgHover,
      colorTextSecondary,
      colorError,
      colorErrorBorder,
      colorErrorTextActive,
      colorErrorBg,
    },
  } = theme.useToken();

  const onSubmit = (approved: boolean) => {
    if (!budgetId) return;
    setLoading(true);
    dispatch(handleApproval({ budgetId, approved }))
      .unwrap()
      .then(() => {
        if (!projectId) return;
        dispatch(getLatestByProjectId(projectId));
      })
      .catch(() => dispatch(asyncLaunchNotification({ type: 'error', config: { message: t('generic.defaultError') } })))
      .finally(() => {
        setLoading(false);
        setApprove(null);
      });
  };

  const onClickApprove = () => {
    onSubmit(true);
  };

  const onClickReject = () => {
    setIsRejectModalOpen(true);
  };

  const onClickCancel = () => {
    setApprove(null);
  };

  const onModalCancel = () => {
    setIsRejectModalOpen(false);
  };

  return (
    <>
      <div
        className="flex flex-col p-12"
        style={{
          border: `1px solid ${approve ? colorPrimaryBorder : colorErrorBorder}`,
          borderRadius: '4px',
          backgroundColor: approve ? colorPrimaryBg : colorErrorBg,
          gap: '10px',
        }}
      >
        <span
          className="pb-4"
          style={{
            color: approve ? colorPrimaryTextActive : colorErrorTextActive,
            fontWeight: '500',
            fontSize: '16px',
            borderBottom: `1px solid ${approve ? colorPrimaryBgHover : colorErrorBorder}`,
          }}
        >
          {t(`budget.approval.${approve ? 'approveBudget' : 'rejection'}`)}
        </span>
        {/**Comment box for future iteration */}
        {/* <span>
        {t('budget.approval.comment')} <span style={{ color: colorTextSecondary }}>({t('budget.approval.optional')})</span>
      </span>
      <ConfigProvider theme={{ token: { colorTextBase: themeConfig.darkMode ? WHITE : COLOR_TEXT_BASE } }}>
        <RichTextEditor
          showSend={false}
          entityType={CommentReferenceEnum.BUDGET_APPROVAL}
          placeholderText={t('budget.approval.leaveComment')}
        />
      </ConfigProvider> */}
        <div
          className="flex justify-between items-center pt-12"
          // style={{ borderTop: `1px solid ${approve ? colorPrimaryBgHover : colorErrorBorder}` }}
        >
          {approve ? (
            <>
              <Button onClick={onClickCancel}>{t('generic.cancel')}</Button>
              <Button type="primary" onClick={onClickApprove}>
                {t('budget.approval.approve')}
              </Button>
            </>
          ) : (
            <>
              <Button type="primary" danger onClick={onClickReject}>
                {t('budget.approval.rejectBudget')}
              </Button>
              <Button danger onClick={onClickCancel}>
                {t('generic.cancel')}
              </Button>
            </>
          )}
        </div>
      </div>
      <Modal
        title={
          <>
            <WarningOutlined className="mr-8" style={{ color: colorError }} />
            <span>{t('budget.approval.rejection')}</span>
          </>
        }
        open={isRejectModalOpen}
        onOk={() => {
          onSubmit(false);
        }}
        confirmLoading={loading}
        onCancel={onModalCancel}
        okText={t('budget.approval.confirmAndReject')}
        okButtonProps={{ type: 'primary', danger: true }}
        centered
      >
        <p style={{ color: colorTextSecondary }}>{t('budget.approval.rejectDescription')}</p>
      </Modal>
    </>
  );
};
