import { http } from '../http/axios.instance';
import { IGroupBinding } from '../../models/group-binding.model';
import { StringORNumber } from './utils.repository';

const path = 'group-bindings';

export const groupBindingsRepository = {
  getAllByGroupId: async (groupId: StringORNumber) => {

    const _params = new URLSearchParams({
      groupId: `${groupId}`,
      sort: 'user.firstName',
      cacheBuster: `${new Date().getTime()}`
    })
    const requestUrl = `${path}?${_params.toString()}`;

    const promiseGetAll = await http.get<IGroupBinding[]>(requestUrl);
    return promiseGetAll;
  },
  getAllByUserId: async (userId: StringORNumber) => {

    const _params = new URLSearchParams({
      userId: `${userId}`,
      cacheBuster: `${new Date().getTime()}`
    })
    const requestUrl = `${path}?${_params.toString()}`;

    const promiseGetAll = await http.get<IGroupBinding[]>(requestUrl);
    return promiseGetAll;
  }, 
  create: async (entity: IGroupBinding) => {

    const requestUrl = `${path}`;
    const groupBinding = await http.post<IGroupBinding>(requestUrl, entity);
    return groupBinding;
  },
  delete: async (id: string | number) => {

    const requestUrl = `${path}/${id}`;
    const groupBinding = await http.delete<IGroupBinding>(requestUrl);
    return groupBinding;
  }
}