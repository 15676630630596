import { IProjectDeliverable } from './project-deliverable';
import { IRole } from './role.model';
import { IUser } from './user.model';

export interface IProjectDeliverableApproval {
  id?: number;
  requestDate?: string | null;
  approvedDate?: string | null;
  order?: number | null;
  projectDeliverable?: IProjectDeliverable | null;
  role?: IRole | null;
  user?: IUser | null;
  policy?: string;
  status?: string;
  key?: string | number;
}

export interface IApproveDeliverableRequest {
  deliverableId: string
  payload: IApproveDeliverablePayload
}
export interface IApproveDeliverablePayload {
  approved: boolean
}

export const POLICY = {
  INVALID: "INVALID"
}

export const defaultValue: Readonly<IProjectDeliverableApproval> = {};