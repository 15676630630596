/* eslint-disable react-hooks/exhaustive-deps */
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  LexicalTypeaheadMenuPlugin,
  MenuOption,
  MenuTextMatch,
  useBasicTypeaheadTriggerMatch
} from "@lexical/react/LexicalTypeaheadMenuPlugin";

import { TextNode } from "lexical";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as ReactDOM from "react-dom";

import { $createMentionNode } from "../Nodes/MentionNode";
import { IUser } from "@models/user.model";
import { theme } from "antd";
import { useAppDispatch } from "@store/store";
import { getUsers } from "@store/slices/users-extended";
import { useAppSelector } from "@store/store";
import { isArrayWithValues } from "@shared/util/array-util";

const PUNCTUATION = "\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%'\"~=<>_:;";

const NAME = "\\b[A-Z][^\\s" + PUNCTUATION + "]";

const DocumentMentionsRegex = {
  NAME,
  PUNCTUATION
};

const CapitalizedNameMentionsRegex = new RegExp(
  "(^|[^#])((?:" + DocumentMentionsRegex.NAME + "{" + 1 + ",})$)"
);

const PUNC = DocumentMentionsRegex.PUNCTUATION;

const TRIGGERS = ["@"].join("");

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = "[^" + TRIGGERS + PUNC + "\\s]";

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  "(?:" +
  "\\.[ |$]|" + // E.g. "r. " in "Mr. Smith"
  " |" + // E.g. " " in "Josh Duck"
  "[" +
  PUNC +
  "]|" + // E.g. "-' in "Salier-Hellendag"
  ")";

const LENGTH_LIMIT = 75;

const AtSignMentionsRegex = new RegExp(
  "(^|\\s|\\()(" +
    "[" +
    TRIGGERS +
    "]" +
    "((?:" +
    VALID_CHARS +
    VALID_JOINS +
    "){0," +
    LENGTH_LIMIT +
    "})" +
    ")$"
);

// At most, 5 suggestions are shown in the popup.
const SUGGESTION_LIST_LENGTH_LIMIT = 5;

const mentionsCache = new Map();

function useMentionLookupService(mentionString: string | null) {
  const [results, setResults] = useState<Array<IUser>>([]);

  const dispatch = useAppDispatch();

  const { usersList } = useAppSelector(state => state.UsersExtended) 

  const usersService = {
    search(string: string, callback: (results: Array<IUser>) => void): void {
      setTimeout(() => {
        const results = usersList.filter((mention: IUser) =>
          mention && mention?.fullName && mention?.fullName?.toLowerCase().includes(string.toLowerCase())
        );
        callback(results);
      }, 500);
    }
  };

  useEffect(() => {
    if (!isArrayWithValues(usersList)) {
      dispatch(getUsers());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const cachedResults = mentionsCache.get(mentionString);

    if (mentionString == null) {
      setResults([]);
      return;
    }

    if (cachedResults === null) {
      return;
    } else if (cachedResults !== undefined) {
      setResults(cachedResults);
      return;
    }

    mentionsCache.set(mentionString, null);
    usersService.search(mentionString, (newResults) => {
      mentionsCache.set(mentionString, newResults);
      setResults(newResults);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentionString]);

  return results;
}

function checkForCapitalizedNameMentions(
  text: string,
  minMatchLength: number
): MenuTextMatch | null {
  const match = CapitalizedNameMentionsRegex.exec(text);
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[2];
    if (matchingString != null && matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: matchingString
      };
    }
  }
  return null;
}

function checkForAtSignMentions(
  text: string,
  minMatchLength: number
): MenuTextMatch | null {
  let match = AtSignMentionsRegex.exec(text);

  // TO CHECK / TODO / BUG
  // if (match === null) {
  //   match = AtSignMentionsRegexAliasRegex.exec(text);
  // }

  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2]
      };
    }
  }
  return null;
}

function getPossibleQueryMatch(text: string): MenuTextMatch | null {
  const match = checkForAtSignMentions(text, 1);
  return match === null ? checkForCapitalizedNameMentions(text, 3) : match;
}
interface MentionsTypeaheadMenuItemProps {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: IOption;
  queryString: string | null;
  results: IUser[];
}

function MentionsTypeaheadMenuItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
  queryString,
  results
}: MentionsTypeaheadMenuItemProps) {

  const indexInResults = option.key;
  const userFounded = results[Number(indexInResults)];

  let re = new RegExp([queryString].join("|"),"gi");
  const fullName = userFounded.fullName || "";

  const fullNameMatch = fullName.replace(re, (matched) => {
      return `<b class="bold-mention-match">${matched}</b>`;
  });

  const fisrtSegmentOfEmail= userFounded.email || ""
  const fisrtSegmentOfEmailMatch = fisrtSegmentOfEmail.replace(re, (matched) => {
    return `<span class="first-segment-mention-match">${matched}</span>`;
  });

  let className = "item";
  if (isSelected) {
    className += " selected";
  }
  return (
    <li
      key={option.user?.id | 0}
      tabIndex={-1}
      className={`${className} flex flex-row items-center`}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={"typeahead-item-" + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      <div className="flex flex-row items-center h-max">
        <span className="text ml-10 h-max min-w-max" >
          <span dangerouslySetInnerHTML={{ __html: fullNameMatch }} className="h-max"></span>
        </span>
        <span className="ml-15 mr-10 h-max min-w-max">
            <span dangerouslySetInnerHTML={{ __html: fisrtSegmentOfEmailMatch }} className="h-max"></span>
          </span>
      </div>
    </li>
  );
}


interface IOption extends MenuOption {
  user?: IUser;
}

export default function MentionsPlugin(): JSX.Element | null {

  const [editor] = useLexicalComposerContext();

  const [queryString, setQueryString] = useState<string | null>(null);

  const results = useMentionLookupService(queryString);

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch("/", {
    minLength: 0
  });

  const options: Array<IOption> = useMemo(
    () => {
      return results
        .reduce((result, user, index) => { 
          const newTOption = new MenuOption(String(index));
          result.push(newTOption);
          return result;
        }, [] as IOption[])
        .slice(0, SUGGESTION_LIST_LENGTH_LIMIT) as IOption[];
    },
    [results]
  );

  const onSelectOption = (
    selectedOption: IOption,
    nodeToReplace: TextNode | null,
    closeMenu: () => void
  ) => {
    editor.update(() => {
      const user = results[Number(selectedOption.key)];
      const mentionNode = $createMentionNode(user);
      if (nodeToReplace) {
        nodeToReplace.replace(mentionNode);
      }
      mentionNode.select();
      closeMenu();
    });
  }

  const checkForMentionMatch = useCallback(
    (text: string) => {
      const mentionMatch = getPossibleQueryMatch(text);
      const slashMatch = checkForSlashTriggerMatch(text, editor);
      return !slashMatch && mentionMatch ? mentionMatch : null;
    },
    [checkForSlashTriggerMatch, editor]
  );

  const { token } = theme.useToken();

  return (
    <LexicalTypeaheadMenuPlugin
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForMentionMatch}
      options={options}
      menuRenderFn={(
        anchorElementRef,
        { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }
      ) =>
        anchorElementRef && anchorElementRef !== null &&  results.length
          ? ReactDOM.createPortal(
              <div className="typeahead-popover mentions-menu" style={{ backgroundColor: token.colorBgElevated, color: token.colorText }}>
                <ul style={{ outline: `1px solid ${token.colorBorder}` }}>
                  {options.map((option, i: number) => (
                    <MentionsTypeaheadMenuItem
                      index={Number(option.key) || 0}
                      isSelected={selectedIndex === i}
                      onClick={() => {
                        setHighlightedIndex(i);
                        selectOptionAndCleanUp(option);
                      }}
                      onMouseEnter={() => {
                        setHighlightedIndex(i);
                      }}
                      queryString={queryString}
                      option={option}
                      key={option.key}
                      results={results}
                    />
                  ))}
                </ul>
              </div>,
              anchorElementRef.current as any
            )
          : null
      }
    />
  );
}