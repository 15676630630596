/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { If } from '@components/Utils/Structural';
import { useAppSelector } from "@store/store";
import { isNumber } from "lodash";
import { isString } from "@shared/util/validations";
import { useBreakpoints } from "@HOOKs/UseBreakpoints";
import { AlbumListContainer } from "./AlbumListContainer";
import { AlbumDetailsContainer } from "./AlbumDetail/AlbumDetailsContainer";
import { NAVIGATION_KEY_URL_PARAM } from "./AlbumCard";

interface IPhotoTabLayoutProps {};

export type IViews = "list" | "details";

export const useMobileNavigation = () => {

    let [, setURLSearchParams] = useSearchParams();

    const { isMobile: isMobileNavigation, isTablet, isDesktop } = useBreakpoints();

    useEffect(() => {
        if (isMobileNavigation) {
            setURLSearchParams((prev) => {
                prev.set(NAVIGATION_KEY_URL_PARAM, "mobile");
                return prev;
            });
        } 
    }, [isMobileNavigation])

    useEffect(() => {
        if (isTablet || isDesktop) {
            setURLSearchParams((prev) => {
                prev.delete(NAVIGATION_KEY_URL_PARAM);
                prev.delete("view");
                return prev;
            });
        } 
    }, [isTablet, isDesktop])

    return [isMobileNavigation] as const;
};


export const PhotoTabLayout = (props: IPhotoTabLayoutProps) => {

    const [isMobileNavigation] = useMobileNavigation();

    const { isTablet, isDesktop } = useBreakpoints();

    const { totalItems } = useAppSelector((store) => store.PhotoAlbum);

    let [searchURLParams, setURLSearchParams] = useSearchParams();

    const [viewToShow, setViewToShow] = useState<IViews | null>(null);

    useEffect(() => {
        const view = searchURLParams.get("view");
        if (isTablet || isDesktop) {
            setURLSearchParams((prev) => {
                prev.delete("view");
                return prev;
            });
        } else {
            setURLSearchParams((prev) => {
                prev.set("view", isString(view) && ["list","details"].includes(view!) ? view! : "list");
                return prev;
            });
        }
    }, [])

    useEffect(() => {
        const view = searchURLParams.get("view");
        if (isString(view) && ["list","details"].includes(view!)) {
            setViewToShow(view as IViews)
        } else {
            setViewToShow(null);
        }
    }, [searchURLParams])

    return (
        <div className="flex flex-row h-full" >

            {/* Mobile */}
            <If condition={isMobileNavigation && viewToShow === "list"} >
                <AlbumListContainer />
            </If>
            <If condition={isMobileNavigation && viewToShow === "details"} >
                <div className="flex flex-col w-full h-full">
                    <AlbumDetailsContainer />
                </div>
            </If>

            {/* Desktop */}
            <If condition={!isMobileNavigation}>
                <>
                    <AlbumListContainer />
                    <If condition={Boolean(isNumber(totalItems) && (totalItems! > 0))}>
                        <div className="flex flex-col w-full h-full">
                            <AlbumDetailsContainer />
                        </div>
                    </If>
                </>
            </If>
        </div>
    )
}
