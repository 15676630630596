/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { configuration } from '../../environments/env'
import { clarity } from 'clarity-js';
import { useAuth } from '@providers/AuthProvider';
import { useFeatureFlagsProvider } from '@providers/FeatureFlagsProvider';
import { FeatureFlagOptionEnum } from '@models/enumerations/feature-flag-option.enum';

export const MicrosoftClarity = () => {

    const { user } = useAuth();

    const { flags, isFlagEnabled } = useFeatureFlagsProvider();

    useEffect(() => {
        
        if (user?.email) {

            const isDisabledThisFeatureFlag = !(Boolean(isFlagEnabled && isFlagEnabled(FeatureFlagOptionEnum.MICROSOFT_CLARITY))) 
            const isInvalidProjectID = !Boolean(configuration.MICROSOFT_CLARITY_PROJECT_ID);

            if (isInvalidProjectID || isDisabledThisFeatureFlag) { return; }

            clarity.consent();
            
            clarity.set('user_id', user.id)
            clarity.set('user_email', user.id)
            clarity.set('user_name', `${user.fullName}`)

            clarity.start({
                projectId: `${configuration.MICROSOFT_CLARITY_PROJECT_ID}`,
                upload: 'https://m.clarity.ms/collect',
                track: true,
                content: true,
            })
        }

        return () => {
            clarity.stop();
        }
    }, [user, flags])

    return null;
}
