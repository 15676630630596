import { ICustomFieldBinding } from './../../models/custom-field-binding.model';
import { ICustomFieldGroup } from '../../models/custom-field-group.model';

import { createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { serializeAxiosError } from "../reducer.util";
import { createAsyncThunk } from "@reduxjs/toolkit"
import { customFieldsService } from "../../services/custom-fields.service";
import { ICustomField } from '@models/custom-field.model';

export const getCustomFieldGroupListOfLocation = createAsyncThunk(
    'custom_fields_groups/fetch_custom_fields_groups_list_by_location_id',
    async (locationId: string, thunkAPI) => {
        return customFieldsService.getCustomFieldsGroupsByLocationId(locationId);
    },
    { serializeError: serializeAxiosError }
);

export const getCustomFieldBindingListOfLocation = createAsyncThunk(
    'custom_fields_groups/fetch_custom_fields_list_by_location_id',
    async (locationId: string, thunkAPI) => {
        return customFieldsService.getCustomFieldsBindingsByLocationId(locationId);
    },
    { serializeError: serializeAxiosError }
);

export const createCustomFieldBinding = createAsyncThunk(
    'custom_fields_groups/create_custom_field_binding',
    async (values: ICustomFieldBinding, thunkAPI) => {
        return customFieldsService.createCustomFieldBinding(values);
    },
    { serializeError: serializeAxiosError }
);

export const updateCustomFieldBinding = createAsyncThunk(
    'custom_fields_groups/update_custom_field_binding',
    async (values: ICustomFieldBinding, thunkAPI) => {
        return customFieldsService.updateCustomFieldBinding(values);
    },
    { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
    'custom_fields/create_entity',
    async (entity: ICustomField, thunkAPI) => {
      return await customFieldsService.create(entity);
    },
    { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
    'custom_fields/update_entity',
    async (entity: ICustomField, thunkAPI) => {
        // @ts-ignore
        return await customFieldsService.update(entity.id, entity);
    },
    { serializeError: serializeAxiosError }
);

interface IinitialState {
    errorMessageGroups: string | null,
    entitiesGroups: ICustomFieldGroup[],
    loadingGroups: boolean,

    errorMessageFieldsBindings: string | null,
    entitiesFieldsBindings: ICustomFieldBinding[],
    loadingFieldBindings: boolean,

    saving: boolean
}
const initialState: IinitialState = {
    errorMessageGroups: "",
    entitiesGroups: [],
    loadingGroups: false,

    errorMessageFieldsBindings: "",
    entitiesFieldsBindings: [],
    loadingFieldBindings: false,
    saving: false
};

export const CustomFiledsSlice = createSlice({
    name: 'custom_fields_groups',
    initialState,
    reducers: {
        clearCollections: (state,) => {
            return initialState
        },
    },
    extraReducers(builder) {
        builder
            //  groups
            .addCase(getCustomFieldGroupListOfLocation.fulfilled, (state, action) => {
                state.loadingGroups = false;
                state.entitiesGroups = action.payload.data;
            })
            .addCase(getCustomFieldGroupListOfLocation.pending, (state, action) => {
                state.loadingGroups = true;
            }).addCase(getCustomFieldGroupListOfLocation.rejected, (state, action) => {
                state.loadingGroups = false;
                state.errorMessageGroups = "Error"
            })
            //  custom fields bindings
            .addCase(getCustomFieldBindingListOfLocation.fulfilled, (state, action) => {
                state.loadingFieldBindings = false;
                state.entitiesFieldsBindings = action.payload.data;
            })
            .addCase(getCustomFieldBindingListOfLocation.pending, (state, action) => {
                state.loadingFieldBindings = true;
            }).addCase(getCustomFieldBindingListOfLocation.rejected, (state, action) => {
                state.loadingFieldBindings = false;
                state.errorMessageFieldsBindings = "Error"
            })

            //  custom fields bindings created
            .addCase(createCustomFieldBinding.fulfilled, (state, action) => {
                state.entitiesFieldsBindings.push(action.payload.data);
            })

            .addCase(updateCustomFieldBinding.fulfilled, (state, action) => {
                const createdEntity = action.payload.data;
                const indexOldEntity = state.entitiesFieldsBindings.findIndex(item => item.id!! === createdEntity.id!!)
                if (indexOldEntity) {
                    state.entitiesFieldsBindings.splice(indexOldEntity, 0, createdEntity)
                }
            })
            .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
                state.saving = false;
            })
            .addMatcher(isPending(createEntity, updateEntity), state => {
                state.saving = true;
            });

    }
});

export const { clearCollections } = CustomFiledsSlice.actions;

// Reducer
export default CustomFiledsSlice.reducer;

