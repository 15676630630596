import { DerivativesEnum } from "@models/enumerations/derivatives-enum.model";
import { isArrayWithValues } from "./array-util";
import { IDerivatives } from "@models/attachment.model";

export const getSignedUrlFromThumbnailToPreview = (derivatives: IDerivatives[]) => {
    let url = "";
    if (isArrayWithValues(derivatives)) {
      const urlLowResolution = derivatives.find(derivative => derivative?.derivativeType === DerivativesEnum.THUMBNAIL)?.signedUrl || "";
      if (urlLowResolution) {
        return urlLowResolution;
      } else {
        const urlImgPreview = derivatives.find(derivative => derivative?.derivativeType === DerivativesEnum.LORES)?.signedUrl || ""
         if (urlImgPreview) {
          return urlImgPreview;
         } else {
            const urlThumbnail = derivatives.find(derivative => derivative?.derivativeType === DerivativesEnum.PREVIEW)?.signedUrl || ""
            return urlThumbnail;
         }
      }
    } 
    const foundUrl = url ?? "";
    return foundUrl;
}