
import { http } from '../http/axios.instance';
import { IAddress, ICreateAddressRequest, IDeleteAddressRequest, IGetAddressRequest } from '@models/address.model';

const path = 'addresses-extended';
const pathUpdate = 'addresses'

export const genericAddressRepository = {
  getAddressesList: async (payload: IGetAddressRequest) => {
    const { referenceType, referenceId } = payload;
    const requestUrl = `${path}?referenceType=${referenceType}&referenceId=${referenceId}`;

    const promiseGetAll = await http.get<IAddress[]>(requestUrl);
    return promiseGetAll.data;
  },
  createAddress: async (payload: ICreateAddressRequest) => {
    const requestUrl = `${path}`;

    const promiseCreate = await http.post(requestUrl, payload);
    return promiseCreate;
  },
  updateAddress: async (payload: IAddress) => {
    const requestUrl = `${pathUpdate}/${payload.id}`;

    const promiseUpdate = await http.put(requestUrl, payload);
    return promiseUpdate;
  },
  deleteAddress: async ({ referenceId, referenceType, addressId }: IDeleteAddressRequest) => {
    
    const requestUrl = `${path}/${referenceId}/${referenceType}/${addressId}`;

    const promiseDelete = await http.delete(requestUrl);
    return promiseDelete;
  }
}

