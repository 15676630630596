/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useErrorAnimation } from "@HOOKs/UseErrorAnimation";
import { ISiteStatus } from "@models/site-status.model";
import { isFulfilled } from "@reduxjs/toolkit";
import { asyncLaunchNotification } from "@store/slices/notification";
import { createEntity, updateEntity } from "@store/slices/sites-statuses";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Button, Col, Drawer, Form, Input, Row, Space, Switch, theme } from "antd";
interface ISiteStatusEditProps {
  open: boolean,
  toogle: () => void,
  onModifiedChanges?: (group: ISiteStatus) => void,
  siteStatus: ISiteStatus
}

export const SiteStatusEdit = (props: ISiteStatusEditProps) => {

    const { open, toogle, siteStatus, onModifiedChanges } = props;

    const dispatch = useAppDispatch();

    const { token: { colorBorderSecondary, paddingSM } } = theme.useToken();

    const [form] = Form.useForm();

    const activeStatus = Form.useWatch('activeStatus', form);
    const openStatus = Form.useWatch('openStatus', form);

    const { loading, updating } = useAppSelector((state) => state.SiteStatuses);

    const [animateError, playAnimationOfError] = useErrorAnimation();

    const onFinishFailed = (errorInfo: any) => playAnimationOfError();

    const onFinish = (entity: any): void => {
        const hasStatus = siteStatus?.id;
        if (hasStatus) {
            dispatch(
                updateEntity({
                    ...siteStatus,
                    ...entity,
                    activeStatus: activeStatus,
                    openStatus: openStatus
                })  
            ).then(onSuccessUpdatedStatus);
            
        } else {
            dispatch(createEntity({
                id: "",
                ...entity,
                activeStatus: activeStatus,
                openStatus: openStatus
            })).then(onSuccessCreateStatus);
        }
    };

    const onSuccessUpdatedStatus = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: `Changes saved`,
                  description: `Your changes have been saved successfully`
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onSuccessCreateStatus = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: `Changes saved`,
                  description: `Status created successfully`
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onClose = () => { 
        form.resetFields();
        toogle(); 
    }

    const onSubmit = () => form.submit();

    useEffect(() => { setStatusFormFields() }, [siteStatus])

    const setStatusFormFields = () => {
        form.setFieldsValue({
            ...siteStatus,
            activeStatus: Boolean(siteStatus.activeStatus),
            openStatus: Boolean(siteStatus.openStatus),
        });
    }

    const onActiveStatusChanged = (value: boolean) => {
        if (!value) {
            form.setFieldValue('openStatus', false);
        }
    }

    return (
        <>
            <Drawer 
                width={448} 
                title={`${siteStatus?.id ? "Edit": "Add"} status`}
                placement="right" 
                onClose={onClose} 
                open={open}
                closable={false}
                extra={
                    <Space>
                      <Button type="primary" ghost onClick={onClose}>
                        Cancel
                      </Button>
                      <Button loading={loading} onClick={onSubmit} type="primary" disabled={updating}>
                        {`${siteStatus?.id ? "Save": "Create"}`}
                      </Button>
                    </Space>
                  }
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className={`${animateError && "error-animation"}`}
                >

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="description"
                                label="Description"
                                rules={[
                                    { required: true, message: "Description field is Required" },
                                ]}
                            >
                                <Input placeholder="Description" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16} style={{ borderTop: `1px solid ${colorBorderSecondary}`, paddingTop: paddingSM }}>
                        <Col span={"none"} className="pr-0" style={{  }}>
                            Active Status
                        </Col>
                        <Col span={"none"} className="pr-0" style={{ flex: '1', textAlign: 'right' }}>
                            <Form.Item name="activeStatus" valuePropName="checked">
                                <Switch onChange={onActiveStatusChanged} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16} style={{ borderBottom: `1px solid ${colorBorderSecondary}`, paddingTop: paddingSM }}>
                        <Col span={"none"} className="pr-0" style={{  }}>
                            Open Status
                        </Col>
                        <Col span={"none"} className="pr-0" style={{ flex: '1', textAlign: 'right' }}>
                            <Form.Item name="openStatus" valuePropName="checked">
                                <Switch disabled={!activeStatus} />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Drawer>
        </>
  );
}
