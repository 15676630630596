import { createContext, useContext } from "react";

export interface IChangeOrderStepperContext {
    changeOrderCreated?: boolean;
};

export const ChangeOrderStepperContext = createContext<IChangeOrderStepperContext | null | undefined>(undefined);

export const useChangeOrderStepperContext = () => {
    return useContext(ChangeOrderStepperContext);
};