import { TabContainer } from '@components/Utils/TabContainer';
import { Col, Row, Tabs, TabsProps, theme } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
//import { BudgetSummaryTab } from './SummaryTab/BudgetSummaryTab';
import { BudgetDetailTab } from './DetailTab/BudgetDetailTab';

export enum BudgetSubtabsEnum {
  SUMMARY = 'summary',
  DETAIL = 'detail',
}

export interface IBudgetSubtabsProps {
  tab: BudgetSubtabsEnum;
}

export const BudgetSubTabs = (props: IBudgetSubtabsProps) => {
  const { tab } = props;
  const { id: locationId } = useParams<'id'>();
  const { siteId } = useParams<'siteId'>();
  const { projectId } = useParams<'projectId'>();
  const [defaultActiveKey, setDefaultActiveKey] = useState<`${BudgetSubtabsEnum}` | undefined>(BudgetSubtabsEnum.SUMMARY);

  const {
    token: { colorBgLayout, colorBgContainer, marginLG },
  } = theme.useToken();

  const items: TabsProps['items'] = [
    // {
    //   key: 'summary',
    //   label: 'Summary',
    //   children: defaultActiveKey === BudgetSubtabsEnum.SUMMARY ? <BudgetSummaryTab /> : null,
    // },
    {
      key: 'detail',
      label: 'Detail',
      children: defaultActiveKey === BudgetSubtabsEnum.DETAIL ? <BudgetDetailTab /> : null,
    },
  ];

  useEffect(() => {
    if (tab && Object.values<string>(BudgetSubtabsEnum).includes(tab)) {
      setDefaultActiveKey(tab as `${BudgetSubtabsEnum}`);
    }
    return () => {
      setDefaultActiveKey(undefined);
    };
  }, [tab]);

  const navigate = useNavigate();

  const onTabClick = (activeKey: string) => {
    navigate(`/location/${locationId}/sites/${siteId}/project/${projectId}/budget/${activeKey}`, {
      relative: 'route',
      replace: true,
    });
    setDefaultActiveKey(activeKey as `${BudgetSubtabsEnum}`);
  };

  return (
    <TabContainer>
      <Row>
        <Col span={24}>
          <Tabs
            items={items}
            activeKey={defaultActiveKey}
            defaultActiveKey={defaultActiveKey}
            size="small"
            className="custom-tabs imb-0 overflow-hidden"
            style={{ backgroundColor: colorBgLayout }}
            onTabClick={onTabClick}
            destroyInactiveTabPane={true}
            tabBarStyle={{
              backgroundColor: colorBgContainer,
              paddingLeft: `${2 * marginLG}px`,
              marginBottom: 0,
            }}
          />
        </Col>
      </Row>
    </TabContainer>
  );
};
