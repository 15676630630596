import { IProjectDeliverableUI } from "@models/project-deliverable";
import { formatDatetimeWithAmPm, fromNow } from "@shared/util/date-utils";
import { Tooltip, theme } from "antd";
import dayjs from "dayjs";
import { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ViewHistoryChangesLink } from "./ViewHistoryChangesLink";

interface IDeliverableLastModifiedProps {
    deliverable: IProjectDeliverableUI;
}

export const DeliverableLastModified = (props: IDeliverableLastModifiedProps) => {

    const { deliverable } = props;
    const { token: { colorTextTertiary, colorFillTertiary } } = theme.useToken();
    const { t } = useTranslation();
    const [formatedDate, setFormatedDate] = useState<string>('');

    useLayoutEffect(() => {
        if (deliverable.lastModifiedDate) {
            const isLessThan30Days = dayjs().diff(dayjs(deliverable.lastModifiedDate), 'days') < 30;
            if (isLessThan30Days) {
                setFormatedDate(fromNow(deliverable.lastModifiedDate));
            } else {
                setFormatedDate(formatDatetimeWithAmPm(deliverable.lastModifiedDate))
            }
        }
    }, [deliverable])

    return (
        <div className="flex flex-row text-xs" style={{ padding: '2px 8px', lineHeight: "20px", color: colorTextTertiary, backgroundColor: colorFillTertiary, borderRadius: 4 }}>
            <span className="pr-5">
                {t("projectDeliverable.lastModifiedBy")}:
            </span>
            <span className='font-bold pr-5'>{deliverable.lastModifiedByFullName}</span> 
            <span className="">
                <Tooltip title={formatDatetimeWithAmPm(deliverable.lastModifiedDate)}>{formatedDate}</Tooltip>
            </span>
            <ViewHistoryChangesLink deliverableActive={deliverable} />
        </div>
    )
}
