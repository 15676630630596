import { ITimelineEntry } from "@models/timeline.model";
import { TimelineItemProps } from "antd";
import { ITimelineItemStrategy } from "../../strategy-factory";
import { EntryBody } from "./EntryBody";

export class ChangeOrderFileItemStrategy implements ITimelineItemStrategy {
    public getItem(timelineEntry: ITimelineEntry): TimelineItemProps {
        const response: TimelineItemProps =
        {
            color: '#1890FF',
            children: <EntryBody value={timelineEntry} />,
        };
        return response;
    }
}
