/* eslint-disable react-hooks/exhaustive-deps */
import { IChangeOrderItem } from "@models/change-order-item.model";
import { IChangeOrderResume } from "@models/change-order.model";
import { ICostCategory } from "@models/cost-category.model";
import { ICostSubCategory } from "@models/cost-sub-category.model";
import { ChangeOrderItemTypeEnum } from "@models/enumerations/change-order-item-type-enum.model";
import { costSubCategoryService } from "@services/cost-sub-category.service";
import { asyncLaunchNotification } from "@store/slices/notification";
import { useAppDispatch } from "@store/store";
import { Alert, Button, Card, Col, Divider, Form, Row, Skeleton, Typography, theme } from "antd";
import Big from "big.js";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import { CostItemsFormList } from "./CostItemsFormList";
import { CostSummary } from "./CostSummary";
import { ListHeader } from "./ListHeader";

export interface IChangeOrderCostsFormProps {
    operation: "CREATE" | "UPDATE";
    changeOrder: IChangeOrderResume;
    costCategories: ICostCategory[];
    materialsInitialItems?: IChangeOrderFormItem[];
    laborInitialItems?: IChangeOrderFormItem[];
    initialSubCategoriesByCategory?: Map<number, ICostSubCategory[]>;
    initialMaterialsSubCategoriesByIndex?: Map<number, ICostSubCategory[]>;
    initialLaborSubCategoriesByIndex?: Map<number, ICostSubCategory[]>;
    initialMaterialsSubtotal?: Big;
    initialLaborSubtotal?: Big;
    onFinish: (items: IChangeOrderItem[]) => void;
    loading: boolean;
}

export const ChangeOrderCostsForm = (props: IChangeOrderCostsFormProps) => {
    const { operation, changeOrder, costCategories, materialsInitialItems, laborInitialItems, initialSubCategoriesByCategory, initialMaterialsSubCategoriesByIndex,
        initialLaborSubCategoriesByIndex, initialMaterialsSubtotal, initialLaborSubtotal, onFinish, loading } = props;
    const isUpdate = operation === "UPDATE";
    const { token: { padding, colorPrimary, colorFillQuaternary, colorBorderSecondary, colorError } } = theme.useToken();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [subCategoriesByCategory, setSubCategoriesByCategory] = useState<Map<number, ICostSubCategory[]> | undefined>(undefined)
    const [materialsSubCategoriesByIndex, setMaterialsSubCategoriesByIndex] = useState<Map<number, ICostSubCategory[]> | undefined>(undefined)
    const [laborSubCategoriesByIndex, setLaborSubCategoriesByIndex] = useState<Map<number, ICostSubCategory[]> | undefined>(undefined)
    const [materialsSubtotal, setMaterialsSubtotal] = useState<Big>(Big(0))
    const [laborSubtotal, setLaborSubtotal] = useState<Big>(Big(0))
    const [form] = Form.useForm();
    const [costDetailsRequiredMessageVisible, setCostDetailsRequiredMessageVisible] = useState<boolean>(false)
    const [initialValues, setInitialValues] = useState<any>({ materialsItems: [{ costCategory: null, costSubCategory: null, description: null, unitsQty: null, unitCost: null }] });

    useEffect(() => {
        let values = {};
        if (isUpdate) {
            if (materialsInitialItems) {
                values = { materialsItems: materialsInitialItems }
            }

            if (laborInitialItems) {
                values = { ...values, laborItems: laborInitialItems }
            }
        }
        setInitialValues(values)

    }, [materialsInitialItems, laborInitialItems])

    useEffect(() => {
        if (initialSubCategoriesByCategory) {
            setSubCategoriesByCategory(initialSubCategoriesByCategory)
        }
    }, [initialSubCategoriesByCategory])

    useEffect(() => {
        if (initialMaterialsSubCategoriesByIndex) {
            setMaterialsSubCategoriesByIndex(initialMaterialsSubCategoriesByIndex)
        }
    }, [initialMaterialsSubCategoriesByIndex])

    useEffect(() => {
        if (initialLaborSubCategoriesByIndex) {
            setLaborSubCategoriesByIndex(initialLaborSubCategoriesByIndex)
        }
    }, [initialLaborSubCategoriesByIndex])

    useEffect(() => {
        if (initialMaterialsSubtotal) {
            setMaterialsSubtotal(initialMaterialsSubtotal)
        }
    }, [initialMaterialsSubtotal])

    useEffect(() => {
        if (initialLaborSubtotal) {
            setLaborSubtotal(initialLaborSubtotal)
        }
    }, [initialLaborSubtotal])

    const mapToItems = (formItems: any, itemType: ChangeOrderItemTypeEnum): IChangeOrderItem[] => {
        return formItems?.map((value: any) => {
            return {
                id: value.id ?? null,
                description: value.description,
                unitsQty: Number(value.unitsQty),
                unitCost: value.unitCost,
                type: itemType,
                changeOrderId: changeOrder.id,
                costSubCategory: { id: Number(value.costSubCategory) } as ICostSubCategory
            } as IChangeOrderItem
        }) ?? [];
    }

    const onFormFinish = (values: any) => {
        const materialItems: IChangeOrderItem[] = mapToItems(values.materialsItems, ChangeOrderItemTypeEnum.MATERIALS);
        const laborItems: IChangeOrderItem[] = mapToItems(values.laborItems, ChangeOrderItemTypeEnum.LABOR);
        if (materialItems.length === 0 && laborItems.length === 0) {
            setCostDetailsRequiredMessageVisible(true);
        } else {
            onFinish(materialItems.concat(laborItems));
        }
    };

    const onErrorDefault = () => {
        dispatch(asyncLaunchNotification({
            type: "error",
            config: {
                message: t("changeOrder.changeOrder"),
                description: t("generic.defaultError")
            }
        }));
    }

    const handleCategoryChange = (index: number, fieldName: string, categoryIdStr: string, subCategoriesByIndex: Map<number, ICostSubCategory[]> | undefined, setSubCategoriesByIndexCallback: (map: Map<number, ICostSubCategory[]>) => void) => {
        if (categoryIdStr) {
            const categoryId = Number(categoryIdStr)

            const itemsValue = form.getFieldValue(fieldName);
            if (itemsValue[index] && itemsValue[index].costSubCategory) {
                itemsValue[index].costSubCategory = null;
                form.setFieldsValue({ fieldName: itemsValue });
            }

            let subCategories: ICostSubCategory[] | undefined;
            if (subCategoriesByCategory?.has(categoryId)) {
                subCategories = subCategoriesByCategory.get(categoryId);
                const newByIndexMap = subCategoriesByIndex ? new Map(subCategoriesByIndex.set(index, subCategories!)) : (new Map()).set(index, subCategories!);
                setSubCategoriesByIndexCallback(newByIndexMap);
            } else {
                costSubCategoryService.getActiveCostSubcategories(categoryId).then((response) => {
                    subCategories = response.data;
                    const newByCategoryMap = new Map(subCategoriesByCategory?.set(categoryId, subCategories));
                    setSubCategoriesByCategory(newByCategoryMap)
                    const newByIndexMap = subCategoriesByIndex ? new Map(subCategoriesByIndex.set(index, subCategories)) : (new Map()).set(index, subCategories);
                    setSubCategoriesByIndexCallback(newByIndexMap);
                }).catch((error) => {
                    onErrorDefault();
                });
            }
        }
    }

    const handleCatgoryChangeMaterials = (index: number, categoryIdStr: string) => {
        handleCategoryChange(index, "materialsItems", categoryIdStr, materialsSubCategoriesByIndex, setMaterialsSubCategoriesByIndex);
    }

    const handleCatgoryChangeLabor = (index: number, categoryIdStr: string) => {
        handleCategoryChange(index, "laborItems", categoryIdStr, laborSubCategoriesByIndex, setLaborSubCategoriesByIndex);
    }

    const navigateBack = () => {
        const newPath = pathname.substring(0, pathname.lastIndexOf('/'));
        navigate(newPath);
    }

    const submit = () => {
        form.submit();
    }

    const calculateSubtotal = (itemValues: any[]): Big => {
        let cost = Big(0);
        itemValues?.forEach((item: any) => {
            if (item?.unitsQty && item?.unitCost && Number.isInteger(Number(item?.unitsQty))) {
                const qty = new Big(item.unitsQty)
                cost = Big(cost.add(qty.times(item.unitCost)))
            }
        })
        return cost;
    }

    const recalculateMaterialsSubtotal = (initialValues?: any[]) => {
        const materialsItemsValues = initialValues ?? form.getFieldValue("materialsItems")
        setMaterialsSubtotal(calculateSubtotal(materialsItemsValues));
    }

    const recalculateLaborSubtotal = (initialValues?: any[]) => {
        const laborItemsValues = initialValues ?? form.getFieldValue("laborItems")
        setLaborSubtotal(calculateSubtotal(laborItemsValues));
    }

    const handleMaterialItemRemoved = (index: number) => {
        const auxMap = new Map(materialsSubCategoriesByIndex);
        auxMap.delete(index)
        setMaterialsSubCategoriesByIndex(auxMap)
    }

    const handleLaborItemRemoved = (index: number) => {
        const auxMap = new Map(laborSubCategoriesByIndex);
        auxMap.delete(index)
        setLaborSubCategoriesByIndex(auxMap)
    }

    return (
        <Skeleton loading={loading} paragraph={{ rows: 10 }} active={true} >
            <Row style={{ paddingLeft: `${padding}px`, paddingRight: `${padding}px` }} className="pt-20 w-full">
                <Col flex="none" style={{ width: '100%' }}>
                    <Card
                        style={{ borderRadius: '4px' }}
                        bodyStyle={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                        <Row>
                            <Col span={24} className='flex flex-row items-center pl-15'
                                style={{ border: `1px solid ${colorBorderSecondary}`, backgroundColor: colorFillQuaternary, borderRadius: 4 }}
                            >
                                <span style={{ color: colorPrimary, fontSize: '20px', lineHeight: '32px' }}>
                                    {isUpdate ? t("changeOrder.costsDetail.updateTitle") : t("changeOrder.costsDetail.addTitle")}
                                </span>
                            </Col>
                        </Row>
                        {costDetailsRequiredMessageVisible &&
                            <Row>
                                <Col span={24} className='mt-15'>
                                    <Alert style={{ color: colorError }} message={t("changeOrder.costsDetail.costRequiredMessage")} type="error" />
                                </Col>
                            </Row>
                        }
                        <Form
                            form={form}
                            name="itemsForm"
                            onFinish={onFormFinish}
                            autoComplete="off"
                            initialValues={initialValues}
                        >
                            <Row>
                                <Col span={24} className='pl-15 mt-15'>
                                    <Typography.Title level={5} style={{ margin: 0, fontWeight: 500, color: colorPrimary }}>
                                        {t("changeOrder.costsDetail.materials")}
                                    </Typography.Title>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className='pl-25 mt-15'>
                                    <ListHeader currency={changeOrder?.currency} />
                                    <div className="mt-5" style={{ maxHeight: 200, overflowY: 'auto' }}>
                                        <CostItemsFormList
                                            name="materialsItems"
                                            costCategories={costCategories}
                                            subCategoriesByIndex={materialsSubCategoriesByIndex}
                                            addButtonText={t("changeOrder.costsDetail.addMaterialButton")}
                                            onCostCategoryChange={handleCatgoryChangeMaterials}
                                            onCostChange={() => recalculateMaterialsSubtotal()}
                                            onItemAdded={() => setCostDetailsRequiredMessageVisible(false)}
                                            onItemRemoved={handleMaterialItemRemoved}
                                        />
                                    </div>
                                    <Row>
                                        <Col span={24}>
                                            <Divider style={{ margin: "10px 0px 0px 0px" }} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className='pl-15 mt-15'>
                                    <Typography.Title level={5} style={{ margin: 0, fontWeight: 500, color: colorPrimary }}>
                                        {t("changeOrder.costsDetail.labor")}
                                    </Typography.Title>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className='pl-25 mt-15'>
                                    <ListHeader currency={changeOrder?.currency} />
                                    <div className="mt-5" style={{ maxHeight: 200, overflowY: 'auto' }}>
                                        <CostItemsFormList
                                            name="laborItems"
                                            costCategories={costCategories}
                                            subCategoriesByIndex={laborSubCategoriesByIndex}
                                            addButtonText={t("changeOrder.costsDetail.addLaborButton")}
                                            onCostCategoryChange={handleCatgoryChangeLabor}
                                            onCostChange={() => recalculateLaborSubtotal()}
                                            onItemAdded={() => setCostDetailsRequiredMessageVisible(false)}
                                            onItemRemoved={handleLaborItemRemoved}
                                        />
                                    </div>
                                    <Row>
                                        <Col span={24}>
                                            <Divider style={{ margin: "10px 0px 0px 0px" }} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                        <Row className='mt-20'>
                            <Col span={24} className='pl-10 pr-20 flex justify-end'>
                                <CostSummary info materialsSubtotal={materialsSubtotal} laborSubtotal={laborSubtotal} currencySymbol={changeOrder.currency?.symbol} />
                            </Col>
                        </Row>
                        <Row className='pl-25'>
                            <Col span={24}>
                                <Divider style={{ margin: "10px 0px 0px 0px" }} />
                            </Col>
                        </Row>
                        <Row className='mt-20'>
                            <Col span={12} className='pl-10'>
                                <Button type="link" danger onClick={navigateBack}>
                                    {t("changeOrder.costsDetail.dismissButton")}
                                </Button>
                            </Col>
                            <Col span={12} className='text-right pr-20'>
                                <Button type="primary" onClick={submit}>
                                    {t("changeOrder.costsDetail.submitDetailButton")}
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Skeleton>
    );
}

export interface IChangeOrderFormItem {
    id?: number,
    costCategory?: number | string,
    costSubCategory?: number | string,
    description?: string,
    unitsQty?: number | string,
    unitCost?: number | string,
}