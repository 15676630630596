import { DEFAULT_COLOR, LIGHT_GREY_1 } from '@providers/ThemeProvider';
import { theme } from 'antd';
import { useState } from 'react';
import { useProviderColor } from './UseProviderColor';
import { useLayoutEffect } from 'react';

const DEFAULT_NOT_STARTED_COLOR = DEFAULT_COLOR;

const useColorStatus = () => {

    const { token: { colorPrimary } } = theme.useToken();

    const [lastStatus, setLastStatus] = useState("")

    const [defaultColor] = useProviderColor(DEFAULT_NOT_STARTED_COLOR)

    const getStatusColor = (status: string) => {
        const statusColorMap = {
            'NOT_STARTED': defaultColor,
            'ON_TRACK': '#22BC72',
            'PAST_DUE': '#E43711',
            'COMPLETED': colorPrimary,
            'NOT_APPLICABLE': LIGHT_GREY_1,
            'PENDING_APPROVAL': "#EC9546"
        }
        return statusColorMap.hasOwnProperty(status) ? statusColorMap[status as keyof typeof statusColorMap] : undefined;
    }

    useLayoutEffect(() => {
        if (lastStatus) {
            setExpectedColor(getStatusColor(lastStatus));

            // setTimeout(() => setExpectedColor(getStatusColor(lastStatus)), DEFAULT_TIMING_IN_LOADING_EFFECT)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultColor])

    const [expectedColor, setExpectedColor] = useState<string | undefined>();

    function setStatus(status: string) {
        const statusColor = getStatusColor(status)
        statusColor && setLastStatus(status)
        setExpectedColor(statusColor)
    }

    return [expectedColor, setStatus] as const;
};

export default useColorStatus;