import { MAX_RESULT_AUDIT_HISTORY_DEFAULT } from "@constants/core";
import { AuditHistoryEntities } from "@models/enumerations/audit-history-entities-enum.model";
import { IProjectDeliverableUI } from "@models/project-deliverable";
import { getProjectDeliverableHistory, showAuditHistory } from "@store/slices/audit-history";
import { asyncLaunchNotification } from "@store/slices/notification";
import { useAppDispatch } from "@store/store";
import { theme } from "antd";

interface IViewHistoryChangesLinkProps {
  deliverableActive: IProjectDeliverableUI
}

export const ViewHistoryChangesLink = (props: IViewHistoryChangesLinkProps) => {

    const { 
        deliverableActive
    } = props;

    const { token: { colorPrimaryText } } = theme.useToken();

    const dispatch = useAppDispatch();

    const onShowHistory = () => {
        dispatch(getProjectDeliverableHistory({
            referenceId: String(deliverableActive.id),
            referenceType: AuditHistoryEntities.PROJECT_DELIVERABLE,
            entityLabel: 'Deliverable',
            entityTranslation: 'projectDeliverable',
            entityNameToObserve: String(deliverableActive.deliverable?.name),
            lastFetchQueryParams: {
                maxResults: MAX_RESULT_AUDIT_HISTORY_DEFAULT,
                newestFirst: true
            },
            repositoryMethod: "getProjectDeliverableHistory"
        }))
            .unwrap()
            .then(() => dispatch(showAuditHistory({ showHistory: true })) )
            .catch(() => {
                dispatch(asyncLaunchNotification({
                    type: "error",
                    config: {
                        message: `Audit History`,
                        description: `There was an error trying to get the Audit History, please try again later.`
                    }
                }));
            })
        
    }
    
    return (
        <div className="pr-8 pl-8" style={{ color: colorPrimaryText }}>
            <span className="underline cursor-pointer" onClick={onShowHistory}>
                View History
            </span>
        </div>
    )
}
