import { useState, useEffect } from 'react';
import { theme, Badge, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import useIntercom from '@HOOKs/UseIntercom';
import { useTranslation } from 'react-i18next';

export const Support = () => {
  const {
    token: { colorTextQuaternary },
  } = theme.useToken();

  const { t } = useTranslation();

  const { bootIntercom, updateIntercomBadge } = useIntercom();

  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    bootIntercom();
  }, [bootIntercom]);

  useEffect(() => {
    updateIntercomBadge(setUnreadCount);
  }, [updateIntercomBadge]);

  return (
    <Badge size="small" count={unreadCount}>
      <Tooltip placement="top" title={t('generic.support')}>
        <QuestionCircleOutlined
          id="launch_support"
          style={{ fontSize: '24px', width: '24px', height: '24px', color: colorTextQuaternary }}
          className={`cursor-pointer`}
        />
      </Tooltip>
    </Badge>
  );
};
