import { EmptyLabel } from "@components/Utils/EmptyLabel";
import { IAttachment } from "@models/attachment.model";
import { getExtensionOfFileName, getNameOfFileName } from "@shared/util/document-utils";
import { Typography, theme } from "antd";

interface IFileNameTitleProps {
    fileData: IAttachment
}

export const FileNameTitle = (props: IFileNameTitleProps) => {

    const { fileData } = props;

    const { token: { colorTextSecondary, colorTextQuaternary }} = theme.useToken();
    
    return (
        <div className="flex flex-row items-center select-none">
            <Typography.Title level={5} className="mt-0 mb-0 mr-0 ml-8 pt-0 pb-0 pr-40" style={{ marginBottom: 0, marginTop: 0, padding: 0 }}>
                <span style={{ color: colorTextSecondary }}>{ getNameOfFileName(`${fileData?.fileName}`) || <EmptyLabel /> }</span> 
                <span style={{ color: colorTextQuaternary }}>.{ getExtensionOfFileName(`${fileData?.fileName}`)}</span>
            </Typography.Title>
         </div>
    )
}
