import { ICostSubCategory } from "@models/cost-sub-category.model";
import { createAsyncThunk, createSlice, isFulfilled, isPending } from "@reduxjs/toolkit";
import { cleanEntity } from "@shared/util/entity-utils";
import { serializeGenericHandleError } from "@store/thunk.util";
import { costSubCategoryService } from '@services/cost-sub-category.service';

interface IReorderParams {
    costCategoryId: string | number
    ids: number[]
}

export const createEntity = createAsyncThunk(
    'costSubCategory/create_entity',
    async (entity: ICostSubCategory, thunkAPI) => {
        const result = await costSubCategoryService.create(cleanEntity(entity));
      return result;
    },
    { serializeError: serializeGenericHandleError }
  );
  
  export const updateEntity = createAsyncThunk(
    'costSubCategory/update_entity',
    async (entity: ICostSubCategory, thunkAPI) => {
        const result = entity.id && await costSubCategoryService.update(entity.id, cleanEntity(entity));
      return result;
    },
    { serializeError: serializeGenericHandleError }
  );

  export const reorderCostSubCategories = createAsyncThunk(
    `costSubCategory/reorder`,
    async (params: IReorderParams, thunkAPI) => {
      const result = await costSubCategoryService.reorder(params.costCategoryId, params.ids);
      return result;
    },
    { serializeError: serializeGenericHandleError }
  );

  export const getAllCostSubCategories = createAsyncThunk(
    `costSubCategory/getAll`,
    async (thunkAPI) => {
      const result = await costSubCategoryService.getAllCostSubcategories()
      return result;
    },
    { serializeError: serializeGenericHandleError }
  );

interface ICostSubCategoryState {
    loading: boolean;
    errorMessage: string | null;
    entities: ReadonlyArray<ICostSubCategory>;
}

const initialState: ICostSubCategoryState = {
    loading: false,
    errorMessage: null,
    entities: []
};

export const slice = createSlice({
    name: 'costSubCategory',
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
        .addMatcher(isFulfilled(getAllCostSubCategories), (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                loading: false,
                entities: data
            };
        })
        .addMatcher(isPending(getAllCostSubCategories), state => {
            state.errorMessage = null;
            state.loading = true;
        });
    }
});

// Reducer
export default slice.reducer;