
import { IUserSettings } from '@models/user.model';
import { http } from '../http/axios.instance';
import { StringORNumber } from './utils.repository';
import { isArrayWithValues } from '../../shared/util/array-util';

const path = "user-settings";

export const UserSettingsRepository = {
  getUserSettings: async (id: StringORNumber) => {
    const requestUrl = `${path}?userId.equals=${id}`;
    const preferences = await (await http.get<IUserSettings[]>(requestUrl))?.data;
    const userSettigns = isArrayWithValues(preferences) ? preferences[0]: null
    return userSettigns;
  },
  createUserSettings: async (entity: Partial<IUserSettings>) => {
    
    const requestUrl = `${path}`;
    const promiseCreate = await http.post<IUserSettings>(requestUrl, entity);
    return promiseCreate.data;
  },
  updateUserSettings: async (entity: IUserSettings) => {
    const id = entity.id;
    if (!id) return;

    const requestUrl = `${path}/${id}`;
    const promiseCreate = await http.put<IUserSettings>(requestUrl, entity);
    return promiseCreate.data;
  }
}
