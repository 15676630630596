import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getEntities as getMarkets } from "@store/slices/market";
import { Card, Switch, theme } from "antd";
import useVisible from "@HOOKs/UseVisible";
import { EditOutlined } from "@ant-design/icons";
import { NEUTRAL_7_COLOR } from "@providers/ThemeProvider";
import { MarketEdit } from "./MarketEdit";
import "@styles/components/admin-item-card.less";
import { useTranslation } from "react-i18next";
import { IMarket } from "@models/market.model";

const ListItem = (market: IMarket) => {
  return <li style={{ color: NEUTRAL_7_COLOR }} className="pb-4">{market.description}</li>
}

interface ListItemEditableProps {
  market: IMarket;
  marketSelected: IMarket;
  editMarketClicked: any;
}

const ListItemEditable = (props: ListItemEditableProps) => {

  const { t } = useTranslation();
  const { market, marketSelected, editMarketClicked } = props;

  return <div className={`list-item-editable ${(marketSelected?.id === market.id ? 'selected' : '')}`} onClick={() => editMarketClicked(market)}>
    <div>
      <div className="handle-container">
        <div className="handle-dot"></div>
      </div>
    </div>
    <div className="description">
      {market.description}
    </div>
    <span className="edit-container">
      <span className="edit-link">
        <EditOutlined /> {t("generic.edit")}
      </span>
    </span>
  </div>
}

export const MarketsCard = (props: any) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { token: { colorPrimary, colorTextDisabled } } = theme.useToken();

  const loadMarkets = () => {
    dispatch(
      getMarkets({})
    );
}

  useEffect(() => {
    loadMarkets();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { entities: markets } = useAppSelector((state) => state.Market);

  const [openEditMarket, toogleEditMarket] = useVisible();
  const [marketSelected, setMarketSelected] = useState<IMarket>({})
  const [isEditing, setIsEditing] = useState<boolean>(false)

  useEffect(() => {
    if (!openEditMarket) {
      setMarketSelected({})
    }
  }, [openEditMarket]);

  const onModifiedChanges = (market: IMarket) => {
    loadMarkets();
  }

  const toggleEdit = () => {
    setIsEditing(!isEditing)
  };

  const editMarket = (market : IMarket) => {
    setMarketSelected(market)
    toogleEditMarket()
  }

  const createNewMarket = () => {
    setMarketSelected({})
    toogleEditMarket();
  }

  const marketsSorted = useMemo(() => {
    return [...markets].sort((a, b) => {
      return (a.description || '').localeCompare((b.description || ''));
    });
  }, [markets]);

  return (
    <>
      <MarketEdit open={openEditMarket} toogle={toogleEditMarket} market={marketSelected} onModifiedChanges={onModifiedChanges} />
      <Card title={<>{t("admin.market")}<span className="item-count">{markets?.length}</span></>} bordered={false} className="admin-item-card" extra={<div><span className="extra-label" style={{ color: (isEditing ? colorPrimary : colorTextDisabled) }} onClick={toggleEdit}>{isEditing ? t('admin.managing') : t('admin.manage')}</span><Switch checked={isEditing} onClick={toggleEdit} /></div>}>
        <ul className={isEditing ? 'editing' : ''}>
            {marketsSorted.map(market => 
            <div
                key={market.id}>
                <>
                {isEditing && <ListItemEditable market={market} marketSelected={marketSelected} editMarketClicked={editMarket} />}
                {!isEditing && <ListItem {...market} />}
                </>
            </div>
            )}
        </ul>
        {isEditing && <>
            <div className="spacer"></div>
            <span onClick={createNewMarket} className="add-new-btn">+ {t("admin.addNewMarket")}</span>
        </>}
        </Card>
    </>
  )
}