import { Card } from "antd";
import { useEffect } from "react";

interface ICustomCardLayoutProps extends React.ComponentPropsWithRef<typeof Card> {};

export const CustomCardLayout = (props: ICustomCardLayoutProps) => {

    const { children, style, bodyStyle, ...restProps } = props;

    useEffect(() => {
    }, [])
    
    return (
        <Card
            title={""} 
            bordered={false}
            style={{ ...{ borderRadius: 8 }, ...style }} 
            bodyStyle={{ ...{ padding: 16 }, ...bodyStyle }}
            {...restProps}
        >
            {children}
        </Card>
    )
}
