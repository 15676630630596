import React from 'react'
import { Typography } from 'antd';
import { EmptyLabelText } from './EmptyLabel';

const { Text } = Typography;

interface ITextEllipsisProps extends React.ComponentPropsWithoutRef<typeof Text> {
  text?: string | null;
  emptyLabelText?: boolean;
  emptyComponent?: React.ReactNode;
}

export const TextEllipsis: React.FC<ITextEllipsisProps> = (props) => {

  const { text, emptyComponent = null, emptyLabelText = true, ...restProps } = props;

  const _emptyComponent = emptyLabelText ? <EmptyLabelText /> : emptyComponent;

  return (
    <Text ellipsis={ !!text ? { tooltip: text }: false } {...restProps} >
      {text || _emptyComponent }
    </Text>
  )
}