
import { IRfiCategory } from '@models/rfi-category.model';
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { rfiCategoryService } from '@services/rfi-category.service';
import { serializeAxiosError } from "../reducer.util";
import { cleanEntity } from '@shared/util/entity-utils';

export const getEntities = createAsyncThunk(
    'rfiCategory/fetch_entity_list',
    async () => {
        return rfiCategoryService.getRfiCategories();
    },
    { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
    `rfiCategory/create_entity`,
    async (entity: IRfiCategory, thunkAPI) => {
      const result = await rfiCategoryService.create(cleanEntity(entity));
      thunkAPI.dispatch(getEntities());
      return result;
    },
    { serializeError: serializeAxiosError }
  );
  
  export const updateEntity = createAsyncThunk(
    `rfiCategory/update_entity`,
    async (entity: IRfiCategory, thunkAPI) => {
      const result = entity.id && await rfiCategoryService.update(entity.id, cleanEntity(entity));
      thunkAPI.dispatch(getEntities());
      return result;
    },
    { serializeError: serializeAxiosError }
  );

interface IRfiCategoryState {
    loading: boolean;
    errorMessage: string | null;
    entities: ReadonlyArray<IRfiCategory>;
}

const initialState: IRfiCategoryState = {
    loading: false,
    errorMessage: null,
    entities: []
};

export const RfiCategorySlice = createSlice({
    name: 'rfiCategories',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const { data } = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data
                };
            })
            .addMatcher(isPending(getEntities), state => {
                state.errorMessage = null;
                state.loading = true;
            })
            .addMatcher(isFulfilled(createEntity, updateEntity), (state) => {
                state.loading = false;
              })
              .addMatcher(isPending(createEntity, updateEntity), state => {
                state.errorMessage = null;
              });
    }
});

// Reducer
export default RfiCategorySlice.reducer;

