import { message } from 'antd';
import axios, { AxiosResponseHeaders, RawAxiosResponseHeaders } from "axios";

import { configuration } from "../../environments/env";
import { STORAGE_LOGIN_REDIRECT_KEY } from '@constants/core';
import { setLocalStorageValue } from '@HOOKs/UseLocalStorage';

const BaseAPI = configuration.API_URL;

export const http = axios.create({
  baseURL: BaseAPI,
  timeout: 60000,
  withCredentials: true
});

export type IHeaders = RawAxiosResponseHeaders | AxiosResponseHeaders;

const setupAxiosInterceptors = (onUnauthenticated: () => void) => {

  let showMessage: boolean = true;

  const onResponseSuccess = (response: any) => response;
  const onResponseError = (err: { status: any; response: { status: any; }; }) => {
    const status = err.status || (err.response ? err.response.status : 0);
    console.warn('Axios request failed: ', status);

    if (status === 0 && BaseAPI === 'http://localhost:8080/api/' && showMessage) {
      message.error("Backend is Offline");
      showMessage = false;
      setTimeout(() => showMessage = true, 3000);
    }

    if (status === 401) {
      onUnauthenticated();

      if (!window.location.pathname.includes('/login')) {
        const currentLocation = window.location.pathname + window.location.search;
        const redirectUrl = `/login`;
        setLocalStorageValue(STORAGE_LOGIN_REDIRECT_KEY, encodeURIComponent(currentLocation));
        window.location.assign(redirectUrl);
      }
    }
    return Promise.reject(err);
  };
  http.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
