import { BackButton } from "@components/Utils/BackButton"
import { TabContainer } from "@components/Utils/TabContainer"
import { useAppDispatch, useAppSelector } from "@store/store";
import { Button, Col, Row, theme } from "antd"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "@styles/components/admin-thresholds.less";
import { useEffect, useState } from "react";
import { IBudgetApprovalRangeSettingSave } from "@models/budget-approval-range-setting.model";
import { getEntities as getBudgetApprovalRangeSettings } from "@store/slices/budget-approval-range-setting-extended";
import { Threshold } from './Thresholds/Threshold';
import { ThresholdEdit } from "./Thresholds/ThresholdEdit";
import useVisible from "@HOOKs/UseVisible";
import { useLayoutContex } from "@providers/LayoutProvider";

export const ApprovalRouting = () => {

    const { token: { 
        colorBgBase, 
        colorBgContainer, 
        colorBorderSecondary,
        colorBorder } } = theme.useToken();
    
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { setHeaderLabelEntityState } = useLayoutContex();

    const [thresholdSelected, setThresholdSelected] = useState<IBudgetApprovalRangeSettingSave>({})

    const loadBudgetApprovalRangeSettings = () => {
        dispatch(
            getBudgetApprovalRangeSettings()
        );
      }
    
      useEffect(() => {
        loadBudgetApprovalRangeSettings();
        setHeaderLabelEntityState(t("admin.approvalRouting"));
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
    const { entities: thresholds } = useAppSelector((state) => state.BudgetApprovalRangeSettingExtended);

    const navigateBack = () => {
        navigate('/admin/module-configuration/budget')
    }

    const [openEditThreshold, toogleEditThreshold] = useVisible();

    const editThreshold = (threshold: IBudgetApprovalRangeSettingSave) => {
        setThresholdSelected({...threshold});
        toogleEditThreshold()
    }

    const addThreshold = () => {
        setThresholdSelected({ id: 0, minValue: 0, maxValue: 0, budgetApprovers: [] });
        toogleEditThreshold()
    }

    const addThresholdMapped = (bottom: number, top: number) => {
        setThresholdSelected({ id: 0, minValue: bottom, maxValue: top, budgetApprovers: [] });
        toogleEditThreshold()
    }
    
    return <TabContainer>
        <ThresholdEdit 
            open={openEditThreshold} 
            toogle={toogleEditThreshold} 
            budgetApprovalRangeSetting={thresholdSelected}
            allBudgetApprovalRangeSettings={thresholds} />
        <div>
            <div className="flex flex-col overflow-auto">
                <Row>
                    <Col span={24}>
                        <div className="flex flex-row items-center pl-10 pt-11 pb-11" style={{ borderTop: `1px solid ${colorBorderSecondary}`, borderBottom: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                            <BackButton onClick={navigateBack} >{t("budget.budget")}</BackButton>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="thresholds-container">
                <div style={{ background: `${colorBgBase}`, border: `1px solid ${colorBorder}` }}>
                    {[...thresholds].sort((a, b) => (a?.minValue || 0) - (b?.minValue || 0)).map((t, idx, arr) => {
                        return <Threshold threshold={t} thresholds={arr} index={idx+1} onClickEdit={editThreshold} onClickAdd={addThresholdMapped} />
                    })}
                    <div>
                        <Button onClick={addThreshold}>{t("admin.threshold.addNewThreshold")}</Button>
                    </div>
                </div>
            </div>
        </div>
    </TabContainer>
}