/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { IUser } from "@models/user.model";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Avatar, Button, Card, Col, Divider, Modal, Row, Typography, theme } from "antd";
import { UserEdit } from "./UserEdit";
import { TabContainer } from "@components/Utils/TabContainer";
import { BackButton } from "@components/Utils/BackButton";
import { COLOR_TEXT_TERTIARY, NEUTRAL_6_COLOR, NEUTRAL_7_COLOR, NEUTRAL_8_COLOR, WHITE, useThemeProvider } from "@providers/ThemeProvider";
import { StatusUserIndicator } from "./StatusUserIndicator";
import { green, red } from "@ant-design/colors";
import { EditOutlined, ExclamationCircleFilled, SendOutlined } from "@ant-design/icons";
import { useProviderColor } from "@HOOKs/UseProviderColor";
import { useNavigate, useParams } from 'react-router-dom';
import { getEntity, resetPassword } from "@store/slices/users";
import { EmptyLabel } from "@components/Utils/EmptyLabel";
import { LANGUAGES } from "@constants/languages";
import { isArrayWithValues } from "@shared/util/array-util";
import { DATETIME_FORMAT_AM_PM, formatDatePipe } from "@shared/util/date-utils";
import { If } from "@components/Utils/Structural";
import useVisible from "@HOOKs/UseVisible";
import { deleteEntity, getEntitiesByUserId } from "@store/slices/group-bindings";
import { asyncLaunchNotification } from "@store/slices/notification";

export const UserDetailTab = () => {

    const { themeConfig } = useThemeProvider();

    const [modal, contextHolder] = Modal.useModal();

    const { token: { marginLG, colorBgContainer, colorBorderSecondary, colorPrimary, colorErrorText } } = theme.useToken();

    const [colorNeutral8Color] = useProviderColor(NEUTRAL_8_COLOR);
    const [colorNeutral7Color] = useProviderColor(NEUTRAL_7_COLOR);

    const [openEditUser, toogleEditUser] = useVisible();

    const navigate = useNavigate();

    const { entity: user, loading } = useAppSelector((state) => state.Users);

    const dispatch = useAppDispatch();

    const { userLogin } = useParams<"userLogin">();

    const { entities: groupBindings } = useAppSelector((state) => state.GroupBindings);

    const filterGroups = () => {
        dispatch(
            getEntitiesByUserId(user?.id)
        );
    }

    useEffect(() => {
        userLogin && dispatch(getEntity(userLogin))
        user && filterGroups()
    }, [])

    const [userSelected, setUserSelected] = useState<IUser>({})

    const editUser = () => {
        setUserSelected({...user});
        toogleEditUser()
    }

    const removeUserFromGroup = (groupBinding: any) => {
        modal.confirm({
            title: `Are you sure you want to remove this user from ${groupBinding?.group?.name} group?`,
            icon: <ExclamationCircleFilled />,
            content: `If you click 'Confirm', ${userLogin} will be removed from this group`,
            onOk: () => {
                dispatch(
                    deleteEntity(`${groupBinding?.id}`)
                ).then(() => {
                    filterGroups();
                    dispatch(asyncLaunchNotification({
                        type: "success",
                        config: {
                            message: "Successfully removed",
                            description: `You have removed ${userLogin} from this group`
                        }
                      }));
                });
            }
        });
    }

    const navigateBack = () => {
        navigate(`/admin/access-and-permissions/users`);
    }

    const sendPasswordReset = (email: string) => {
        dispatch(resetPassword(email));
    }

    const onModifiedChanges = (user: IUser) => {
        user?.login && navigate(`/admin/access-and-permissions/users/${user?.login}`);
        user?.login && dispatch(getEntity(user?.login));
    }

    const navigateToGroup = (groupId: any) => {
        navigate(`/admin/access-and-permissions/groups/${groupId}`);
    }

    return (
      <>
        <>{contextHolder}</>
        <UserEdit open={openEditUser} toogle={toogleEditUser} user={userSelected} onModifiedChanges={onModifiedChanges} />
        <TabContainer>
          <div className="flex flex-col overflow-auto">
            <Row>
              <Col span={24}>
                <div
                  className="flex flex-row items-center pl-10 pt-11 pb-11"
                  style={{
                    borderTop: `1px solid ${colorBorderSecondary}`,
                    borderBottom: `1px solid ${colorBorderSecondary}`,
                    backgroundColor: colorBgContainer,
                  }}
                >
                  <BackButton onClick={navigateBack}>Back</BackButton>
                </div>
              </Col>
            </Row>
            <Row style={{ paddingLeft: marginLG, paddingRight: marginLG }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Card loading={loading} bodyStyle={{ padding: 0 }} className="mt-20 mb-20" style={{ borderRadius: 4, minWidth: '100%' }}>
                  <Row className="flex flex-row items-center justify-between mt-12">
                    <Row className="flex flex-row items-center">
                      <Avatar className="cursor-pointer ml-12 mr-10" gap={4}>
                        {`${user?.firstName?.[0]}${user?.lastName?.[0]}`.toUpperCase()}
                      </Avatar>
                      <Typography.Title
                        level={5}
                        className="inline pr-10 pb-0 imb-0"
                        style={{ color: themeConfig.darkMode ? WHITE : COLOR_TEXT_TERTIARY, fontWeight: 500 }}
                      >
                        {`${user?.firstName || ''} ${user?.lastName || ''}`}
                      </Typography.Title>
                      <Typography.Title
                        level={5}
                        className="inline pr-10 pb-0 imt-0 imb-0"
                        style={{ color: themeConfig.darkMode ? WHITE : NEUTRAL_6_COLOR, fontWeight: 500 }}
                      >
                        #{user?.id || ''}
                      </Typography.Title>
                      <Row className="flex flex-row items-center pl-10">
                        <StatusUserIndicator style={{ backgroundColor: user?.activated ? green[4] : red[4] }} />
                        <span className="ml-10">{user?.activated ? 'Activated' : 'Deactivated'}</span>
                      </Row>
                    </Row>
                    <Row className="flex flex-row items-center cursor-pointer pr-20" style={{ color: colorPrimary }} onClick={editUser}>
                      <EditOutlined className="mr-8 scale-90" />
                      <span>Edit</span>
                    </Row>
                  </Row>
                  <Row className="pr-10 pl-10 pt-10 pb-10">
                    <Divider className="mt-0 mb-0 mr-0 ml-0" />
                  </Row>
                  <Row className="pl-40">
                    <Col span={24} className="flex flex-col">
                      <Typography.Title
                        level={5}
                        className="inline pr-10 pb-0 imt-0 imb-0 pl-5"
                        style={{ color: colorNeutral7Color, fontWeight: 500 }}
                      >
                        Personal Info
                      </Typography.Title>
                      <Row className="pl-10 pt-15 pb-8">
                        <Col span={9} className="flex flex-row">
                          <span className="inline font-bold">Last Name</span>
                        </Col>
                        <Col span={15} className="flex flex-row">
                          <span style={{ color: colorNeutral8Color }}>{user?.lastName || <EmptyLabel />}</span>
                        </Col>
                      </Row>
                      <Row className="pl-10 pb-8">
                        <Col span={9}>
                          <span className="font-bold">First Name</span>
                        </Col>
                        <Col span={15}>
                          <span style={{ color: colorNeutral8Color }}>{user?.firstName || <EmptyLabel />}</span>
                        </Col>
                      </Row>
                      <Row className="pl-10 pb-8">
                        <Col span={9}>
                          <span className="font-bold">Email</span>
                        </Col>
                        <Col span={15}>
                          <span style={{ color: colorNeutral8Color }}>{user?.email || <EmptyLabel />}</span>
                        </Col>
                      </Row>
                      <Row className="pl-10 pb-8">
                        <Col span={9}>
                          <span className="font-bold">Language</span>
                        </Col>
                        <Col span={15}>
                          <span style={{ color: colorNeutral8Color }}>
                            {(user?.langKey && LANGUAGES.find(lang => String(lang.id) === String(user?.langKey))?.description) || (
                              <EmptyLabel />
                            )}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="pr-10 pl-10 pt-15 pb-10">
                    <Divider className="mt-0 mb-0 mr-0 ml-0" />
                  </Row>
                  <Row className="pl-40 pb-30">
                    <Col span={24} className="flex flex-col">
                      <Typography.Title
                        level={5}
                        className="inline pr-10 pb-15 imt-0 imb-0 pl-5"
                        style={{ color: colorNeutral7Color, fontWeight: 500 }}
                      >
                        Activity
                      </Typography.Title>
                      <If condition={!!user?.lastLogin}>
                        <Row className="pl-10 pb-8">
                          <Col span={9} className="flex flex-row">
                            <span className="inline font-bold">Last login</span>
                          </Col>
                          <Col span={15} className="flex flex-row">
                            <span style={{ color: colorNeutral8Color }}>
                              {(user?.lastLogin && formatDatePipe(`${user?.createdDate || ''}`, DATETIME_FORMAT_AM_PM)) || <EmptyLabel />}
                            </span>
                          </Col>
                        </Row>
                      </If>
                      <Row className="pl-10 pb-8">
                        <Col span={9}>
                          <span className="font-bold">Created by</span>
                        </Col>
                        <Col span={15}>
                          <span style={{ color: colorNeutral8Color }}>{user?.createdBy || <EmptyLabel />}</span>
                        </Col>
                      </Row>
                      <Row className="pl-10 pb-8">
                        <Col span={9}>
                          <span className="font-bold">Created date</span>
                        </Col>
                        <Col span={15}>
                          <span style={{ color: colorNeutral8Color }}>
                            {(user?.createdDate && formatDatePipe(`${user?.createdDate || ''}`, DATETIME_FORMAT_AM_PM)) || <EmptyLabel />}
                          </span>
                        </Col>
                      </Row>
                      <Row className="pl-10 pb-8">
                        <Col span={9}>
                          <span className="font-bold">Modified By</span>
                        </Col>
                        <Col span={15}>
                          <span style={{ color: colorNeutral8Color }}>{user?.lastModifiedBy || <EmptyLabel />}</span>
                        </Col>
                      </Row>
                      <Row className="pl-10 pb-8">
                        <Col span={9}>
                          <span className="font-bold">Modified date</span>
                        </Col>
                        <Col span={15}>
                          <span style={{ color: colorNeutral8Color }}>
                            {(user?.lastModifiedDate && formatDatePipe(`${user?.lastModifiedDate || ''}`, DATETIME_FORMAT_AM_PM)) || (
                              <EmptyLabel />
                            )}
                          </span>
                        </Col>
                      </Row>
                      <Row className="pl-10 pb-8">
                        <Col span={9}>
                          <span className="font-bold">Profiles</span>
                        </Col>
                        <Col span={15} className="flex flex-col" style={{ color: colorNeutral8Color }}>
                          <>
                            {user &&
                              user?.authorities &&
                              isArrayWithValues(user?.authorities) &&
                              user?.authorities.map((item, i) => {
                                return (
                                  <span key={item} style={{ color: colorNeutral8Color }}>
                                    {item}
                                  </span>
                                );
                              })}
                          </>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="pr-10 pl-10 pt-5 pb-5">
                    <Divider className="mt-0 mb-0 mr-0 ml-0" />
                  </Row>
                  <Row justify={'end'}>
                    <Button
                      type="text"
                      onClick={() => user.email && sendPasswordReset(user.email)}
                      className="flex flex-row items-center mr-20 mt-5 mb-10 cursor-pointer"
                      style={{ color: colorPrimary, backgroundColor: 'transparent' }}
                    >
                      <SendOutlined />
                      <span className="ml-10">Send Password reset</span>
                    </Button>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Card
                  bodyStyle={{ padding: 0 }}
                  className="ml-20 mt-20 mb-20 mr-20 pr-10 pl-10 pb-10 pt-10"
                  style={{ borderRadius: 4, minWidth: '100%' }}
                >
                  <Row>
                    <Typography.Title
                      level={5}
                      className="inline pr-10 pb-0 imb-0"
                      style={{ color: themeConfig.darkMode ? WHITE : COLOR_TEXT_TERTIARY, fontWeight: 500 }}
                    >
                      Groups of {user.firstName} {user.lastName}
                    </Typography.Title>
                  </Row>
                  <Row className="pr-10 pl-10 pt-10 pb-10">
                    <Divider className="mt-0 mb-0 mr-0 ml-0" />
                  </Row>
                  {groupBindings.map((groupBinding, index) => {
                    return (
                      <Row
                        className="flex flex-row items-center justify-between pl-20"
                        style={{ borderColor: '#ccc', borderRadius: '4px' }}
                        key={groupBinding.id}
                      >
                        <Col className="flex flex-col">{groupBinding?.group?.name}</Col>
                        <Col className="flex flex-col" style={{ width: '400px' }}>
                          <Row className="flex flex-row items-center pt-10 pb-10 pl-20 pr-20 font-medium">
                            <span
                              className="cursor-pointer"
                              style={{ color: colorErrorText }}
                              onClick={() => removeUserFromGroup(groupBinding)}
                            >
                              Remove
                            </span>
                            <span
                              className="cursor-pointer ml-20"
                              style={{ color: colorPrimary }}
                              onClick={() => navigateToGroup(groupBinding?.group?.id)}
                            >
                              View detail
                            </span>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                </Card>
              </Col>
            </Row>
          </div>
        </TabContainer>
      </>
    );
}
