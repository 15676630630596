import { IAddress } from "./address.model";
import { ICompany } from "./company.model";
import { IUser } from "./user.model";

export interface ICompanyContact {
  id?: number;
  firstName?: string | null;
  lastName?: string | null;
  primary?: boolean | null;
  company?: ICompany | null;
  email?: ICompany | null;
  address?: IAddress,
  user?: IUser
}


export const defaultValue: Readonly<ICompanyContact> = {};