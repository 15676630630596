import { TabContainer } from '@components/Utils/TabContainer';
import ArchiveProjectSetting from './ArchiveProjectSetting';
import OxBlueSetting from './OxBlueSetting';
import { useFeatureFlagsProvider } from '@providers/FeatureFlagsProvider';
import { FeatureFlagOptionEnum } from '@models/enumerations/feature-flag-option.enum';
import ProcoreProjects from './ProcoreProjects';
import ProjectTypeSettings from './ProjectTypeSettings';
import ProjectInformation from './ProjectInformation';
import ProjectNickname from "./ProjectNickname";
import { Col, Row } from 'antd';
import DefaultCurrency from './CurrencySetting';
import SquarefeetSetting from './SquareFeetSetting';

const ProjectDeliverableSettings: React.FC = () => {
  const { isFlagEnabled } = useFeatureFlagsProvider();


  return (
    <TabContainer>
      <div className="pt-20 pl-20">
          <div className="grid h-full" style={{ gridTemplateColumns: '1fr', gridTemplateRows: 'min-content 1fr' }}>
              <Row gutter={[16, 24]} className='overflow-auto'>
                <Col className="gutter-row" span={8}>
                  <ProjectNickname/>
                  <ProjectTypeSettings />
                  {isFlagEnabled(FeatureFlagOptionEnum.OX_BLUE_INTEGRATION) && <OxBlueSetting />}
                  {isFlagEnabled(FeatureFlagOptionEnum.PROCORE) && <ProcoreProjects />}
                  <DefaultCurrency/>
                  <SquarefeetSetting/>
                </Col>
                <Col className="gutter-row" span={8}>
                  <ProjectInformation />
                  <ArchiveProjectSetting />
                </Col>
              </Row>
          </div>
      </div>
    </TabContainer>
  );
};

export default ProjectDeliverableSettings;
