import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { ICostSubCategory } from './cost-sub-category.model';
import { ICurrency } from './currency.model';
import { IProject } from './project-model';
import { ICostGroupingExtended, ICostGroupingUI } from './cost-grouping.model';
import { IRole } from './role.model';
import { IUser } from './user.model';

export enum BudgetStatusEnum {
  APPROVED = 'APPROVED',
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REJECTED = 'REJECTED',
}

export interface IBudget {
  id?: number;
  status?: BudgetStatusEnum;
  project?: IProject;
  currency?: ICurrency;
  parent?: IBudget;
  budgetLineItemList?: IBudgetLineItem[];
}

export interface IBudgetExtended extends IBudget {
  id?: number;
  groups?: ICostGroupingExtended[];
  approvers?: IBudgetApprover[];
}

export interface IBudgetRevision {
  id?: number;
  status?: BudgetStatusEnum;
  project?: IProject;  
  revisionNumber?: number;
  approvalDate?: string;
}

export interface IBudgetUI extends IBudget {
  groups?: ICostGroupingUI[];
}

export interface IBudgetLineItem {
  id?: StringORNumber;
  cost?: string;
  costPerSF?: string;
  note?: string;
  budget?: IBudget;
  costSubCategory?: ICostSubCategory;
}

export interface IBudgetCreateRequest {
  projectId: StringORNumber;
  budgetTemplateId: StringORNumber;
  currencyId: number;
  squareFootage: number;
}

export interface IBudgetLineItemCostUpdateRequest {
  id: StringORNumber;
  cost: string;
}

export interface IBudgetLineItemCostUpdateResponse {
  id: StringORNumber;
  cost: string;
  costPerSF: string;
  categoryId: StringORNumber;
  categoryCost: string;
  categoryCostPerSF: string;
  groupingId: StringORNumber;
  groupingCost: string;
  groupingCostPerSF: string;
}

export interface IBudgetSummary {
  totalEstimatedCost: number;
  totalCostPerSF: number;
}

export interface IBudgetApprover {
  budgetApprovalPolicy: BudgetApprovalPolicy;
  role?: IRole | null;
  user?: IUser | null;
  status: BudgetApprovalStatus | null;
}

export interface IBudgetApprovalAction {
  budgetId: StringORNumber;
  approved: boolean;
}

export enum BudgetApprovalPolicy {
  ROLE = 'ROLE',
  USER = 'USER',
  INVALID = 'INVALID',
}
export enum BudgetApprovalStatus {
  QUEUED = 'QUEUED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export const defaultValue: Readonly<IBudget> = {};
