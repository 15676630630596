import { Modal, Tooltip, theme } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { TrashSvgIcon } from "@components/Icons/TrashSvgIcon";
import { IAttachment } from "@models/attachment.model";
import { useAppDispatch } from "@store/store";
import { deleteEntity as deleteAttachment } from '@store/slices/attachment';

interface IDeleteButtonProps {
    attachment: IAttachment;
    onSuccessDeleteAttachment: () => void;
    onFinishProcessDelete: () => void;
}

export const DeleteButton = (props: IDeleteButtonProps) => {

    const { attachment, onFinishProcessDelete, onSuccessDeleteAttachment } = props;

    const { token: { colorErrorBorderHover }} = theme.useToken();

    const dispatch = useAppDispatch();

    const onSuccessDelete = () => {
        onSuccessDeleteAttachment?.();
    };

    const [modal, contextHolder] = Modal.useModal();

    const onDeleteAction = () => {
        if (!attachment.id) return;

        modal.confirm({
            styles: { content: { padding: 16, borderRadius: 4  } },
            title: 'Do you Want to delete this file?',
            icon: <ExclamationCircleFilled />,
            onOk() {
                attachment.id && dispatch(deleteAttachment({id: attachment.id, onSuccess: onSuccessDelete})).then(() => {
                    onFinishProcessDelete?.();
                })
            }
        })
    }

    return (
        <>
            <>{contextHolder}</>
            <Tooltip placement="bottom" title={"Delete"}>
                <TrashSvgIcon className="cursor-pointer" onClick={onDeleteAction} height="20" width="20" style={{ color: colorErrorBorderHover }}  />
            </Tooltip>
        </>
    )
}
