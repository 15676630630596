import { IApproveDeliverableRequest, IProjectDeliverableApproval } from '@models/project-deliverable-approval';
import { http } from '../http/axios.instance';

const approvalsPath = 'project-deliverable-approvals';
const deliverablesPath = 'project-deliverables';

export const projectDeliverableApprovalRepository = {
  partialUpdate: async (id: string | number, entity: Partial<IProjectDeliverableApproval>): Promise<IProjectDeliverableApproval> => {

    const requestUrl = `${approvalsPath}/${id}`;
    const promiseUpdate = await http.patch<IProjectDeliverableApproval>(requestUrl, entity);
    return promiseUpdate.data;
  },
  approveDeliverable: async ({ deliverableId, payload }: IApproveDeliverableRequest) => {

    const requestUrl = `${deliverablesPath}/${deliverableId}/approval`;
    const promiseUpdate = await http.post(requestUrl, payload);
    return promiseUpdate.data;
  },
}