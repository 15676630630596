import { AclTargetEnum } from "./enumerations/acl-target-enum.model";

export interface IAclTarget {
  id?: number;
  name?: string | null;
  label?: string | null;
  action?: AclTargetEnum | null;
  parent?: number | null;
}

export const defaultValue: Readonly<IAclTarget> = {};
