import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { EntityState, createEntitySlice, serializeAxiosError } from '@store/reducer.util';
import { IBudgetApprovalRangeSetting } from '@models/budget-approval-range-setting.model';
import { defaultValue } from '@models/budget-approver-setting.model';
import { cleanEntity } from '@shared/util/entity-utils';
import { IQueryParams } from '@models/pagination';
import { budgetApprovalRangeSettingService } from '@services/budget-approval-range-setting.service';


const initialState: EntityState<IBudgetApprovalRangeSetting> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/budget-approval-range-settings';

// Actions

export const getEntities = createAsyncThunk('budgetApprovalRangeSetting/fetch_entity_list', async (params: IQueryParams) => {
  return await budgetApprovalRangeSettingService.getBudgetApprovalRangeSettings(params);
});

export const createEntity = createAsyncThunk(
  'budgetApprovalRangeSetting/create_entity',
  async (entity: IBudgetApprovalRangeSetting, thunkAPI) => {
    const result = await budgetApprovalRangeSettingService.create(cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'budgetApprovalRangeSetting/update_entity',
  async (entity: IBudgetApprovalRangeSetting, thunkAPI) => {
    // @ts-ignore
    const result = await budgetApprovalRangeSettingService.update(entity.id, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'budgetApprovalRangeSetting/partial_update_entity',
  async (entity: IBudgetApprovalRangeSetting, thunkAPI) => {
    const result = await axios.patch<IBudgetApprovalRangeSetting>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'budgetApprovalRangeSetting/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IBudgetApprovalRangeSetting>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const BudgetApprovalRangeSettingSlice = createEntitySlice({
  name: 'budgetApprovalRangeSetting',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = BudgetApprovalRangeSettingSlice.actions;

// Reducer
export default BudgetApprovalRangeSettingSlice.reducer;
