import { useEffect, useState } from 'react';
import { Col, ConfigProvider, Row, theme } from 'antd';
import { Card } from 'antd';
import { useThemeProvider } from '@providers/ThemeProvider';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '@store/store';
import { MyPendingApprovalsTable } from './MyPendingApprovalTable';
import { MyPendingApprovalsMap } from './MyPendingApprovalMap';
import { useTranslation } from 'react-i18next';
import { getMyPendingApprovals } from '@store/slices/dashboard';

export const MyPendingApprovalCard = () => {
  const { themeConfig } = useThemeProvider();
  const algorithmSelected = themeConfig?.darkMode ? [theme.compactAlgorithm, theme.darkAlgorithm] : theme.compactAlgorithm;
  const {
    data: {
      myPendingApprovals: { totalItems },
    },
  } = useSelector((state: RootState) => state.Dashboard);

  const { t } = useTranslation();

  const HeaderCard = (
    <Row justify="space-between">
      <div className="flex flex-row items-baseline">
        <span className="font-bold mr-5 ">{t('dashboard.myPendingApprovals')}</span>
        {totalItems ? <span className="font-normal ml-5">({totalItems} Total)</span> : <></>}
      </div>
    </Row>
  );

  //check if there are pending approvals to show the card
  const dispatch = useDispatch<AppDispatch>();
  const [currentTotalItems, setCurrentTotalItems] = useState(totalItems);
  useEffect(() => {
    const fetchPendingApprovals = async () => {
      await dispatch(getMyPendingApprovals({}));
    };
    fetchPendingApprovals();
  }, [dispatch]);

  useEffect(() => {
    setCurrentTotalItems(totalItems);
  }, [totalItems]);
  //If there are no pending approvals we will hide this card
  if (currentTotalItems === 0) {
    return null;
  }

  return (
    <ConfigProvider theme={{ token: { borderRadius: 5 }, algorithm: algorithmSelected }}>
      <Card className="mt-15" title={HeaderCard} bordered={false}>
        <Row gutter={8} wrap={false} className="w-full">
          <Col>
            <MyPendingApprovalsTable />
          </Col>
          <Col>
            <MyPendingApprovalsMap />
          </Col>
        </Row>
      </Card>
    </ConfigProvider>
  );
};
