import React from 'react';
import { useProviderColor } from '@HOOKs/UseProviderColor';
import { ConfigProvider, Empty, Row, Table, theme } from 'antd';
import { SECONDARY_TEXT_COLOR } from '@providers/ThemeProvider';
export interface ITableCustomizedProps extends React.ComponentPropsWithRef<typeof Table> {
    header?: React.ReactNode;
    titleOnEmptyTable?: React.ReactNode;
}

export const TableCustomized: React.FC<ITableCustomizedProps> = (props) => {
    const { titleOnEmptyTable, dataSource, showHeader, header, ...restProps } = props;

    const {
        token: { colorBgBase, colorFillQuaternary },
    } = theme.useToken();

    const CustomizeRenderEmptyRowsInTable = ({ text, componentName }: { text: React.ReactNode; componentName?: string }) => {
        const [colorText] = useProviderColor(SECONDARY_TEXT_COLOR);

        return (
            <div className="text-center pt-10 pb-5" aria-label={`${componentName}`}>
                <div style={{ color: colorText }}>{text}</div>
            </div>
        );
    };

    const renderEmptyHandler = (componentName?: string) => {
        return titleOnEmptyTable ? <CustomizeRenderEmptyRowsInTable text={titleOnEmptyTable} componentName={componentName} /> : <Empty />;
    };

    const isEmptyTable = (dataSource?: readonly unknown[]) => !Boolean(dataSource && dataSource?.length > 0);

    return (
        <Row>
            <div>
                {header ?? null}
                <ConfigProvider theme={{ token: { borderRadius: 8 } }} renderEmpty={renderEmptyHandler}>
                    <Table
                        className="border-radius-8-custom-table"
                        size="small"
                        pagination={false}
                        dataSource={dataSource}
                        showHeader={!isEmptyTable(dataSource)}
                        onRow={(record, rowIndex) => ({
                            style: {
                                background: Boolean(rowIndex ? rowIndex % 2 !== 0 : false) ? colorFillQuaternary : colorBgBase,
                            },
                        })}
                        {...restProps}
                    />
                </ConfigProvider>
            </div>
        </Row>
    );
};
