import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { blue } from '@ant-design/colors';
import { Button, Checkbox, Col, Drawer, Form, Input, Row, Space, theme } from 'antd';
import { AppDispatch, useAppSelector } from '@store/store';
import { isFulfilled } from '@reduxjs/toolkit';
import { useNotificationsProvider } from '@providers/NotificationsProvider';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { updateEntityPartial } from '@store/slices/company-contact';
import { getEntitiesByCompany } from '@store/slices/company-contacts-extended';
import { ICompanyContact } from '@models/company.contact.model';
import { useParams } from 'react-router-dom';
import { useErrorAnimation } from '@HOOKs/UseErrorAnimation';
import { AddressGenericCardHandler } from '@components/AddressGeneric/AddressGenericCardHandler';
import { If } from '@components/Utils/Structural';
import { t } from 'i18next';
import LinkContact from './LinkContactDrawer';
import { LinkOffIcon } from '@components/Icons/LinkOffSvgIcon';
import { NEUTRAL_6_COLOR, NEUTRAL_8_COLOR } from '@providers/ThemeProvider';
import { LinkOnSvgIcon } from '@components/Icons/LinkOnSvgIcon';

interface IViewOrUpdateContactProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const ViewOrUpdateContact = (props: IViewOrUpdateContactProps) => {
  const { open, setOpen } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { updating } = useAppSelector(state => state.Location);

  const { entities: companyContacts } = useAppSelector(state => state.CompanyContact);
  const { entity: selectedContact } = useAppSelector(state => state.CompanyContactsExtended);

  const {
    token: { colorError, colorTextTertiary, fontSize, colorPrimary, colorWarningActive },
  } = theme.useToken();

  const [openLinkContactDrawer, setOpenLinkContactDrawer] = useState(false);

  const [selectedUser, setSelectedUser] = useState<number | null>(null);

  useEffect(() => {
    if (selectedContact && selectedContact.id) {
      form.setFieldsValue({ ...selectedContact });
      setIsPrimaryAlreadyExist(false);
      selectedContact.user?.id ? setSelectedUser(selectedContact.user.id) : setSelectedUser(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContact, open]);

  const [form] = Form.useForm();

  const { openNotification } = useNotificationsProvider();
  const { id } = useParams<'id'>();

  const getCompanyContacts = () => {
    if (id) {
      dispatch(
        getEntitiesByCompany({
          page: 0,
          size: 1000,
          sort: `firstName,asc`,
          query: `companyId.equals=${id}`,
        })
      );
    }
  };

  const onClose = (): void => {
    getCompanyContacts();
    setSelectedUser(null);
    setOpen(false);
  };

  const [animateError, playAnimationOfError] = useErrorAnimation();

  const onFinish = (entity: Partial<ICompanyContact>): void => {
    if (!selectedContact || !selectedContact.id) return;

    const requestData = {
      company: { id: Number(id) },
      firstName: entity.firstName,
      lastName: entity.lastName,
      primary: Boolean(entity.primary),
      email: entity.email,
      id: selectedContact.id,
      user: { id: selectedUser ? selectedUser : 0 },
    };

    const onSuccessUpdateCompanyContact = (response: any) => {
      if (isFulfilled(response)) {
        openNotification({
          type: 'info',
          config: {
            icon: <CheckCircleTwoTone style={{ fontSize: 24 }} twoToneColor={[blue[5], blue[0]]} />,
            message: `${t('company.contact.updatedContact')}`,
            description: `${t('company.contact.contactUpdatedSuccessfully')}`,
          },
        });
        form.resetFields();
        setSelectedUser(null);
        onClose();
      } else {
        playAnimationOfError();
      }
    };
    dispatch(updateEntityPartial({ ...requestData })).then(onSuccessUpdateCompanyContact);
  };

  const onFinishFailed = (errorInfo: any) => {
    playAnimationOfError();
  };

  const onSubmit = () => {
    form.submit();
  };

  const [isPrimaryAlreadyExist, setIsPrimaryAlreadyExist] = useState(false);

  const checkExistingPrimary = (_: any, value: string) => {
    if (selectedContact && !selectedContact.primary) {
      let doesPrimaryExists = companyContacts.find(contact => contact.primary === true);
      if (doesPrimaryExists && Boolean(form.getFieldValue('primary'))) setIsPrimaryAlreadyExist(Boolean(doesPrimaryExists));
      else setIsPrimaryAlreadyExist(false);
    }
    return Promise.resolve();
  };

  return (
    <>
      <Drawer
        title={`Edit Contact`}
        width={580}
        onClose={onClose}
        open={open}
        styles={{ body: { paddingBottom: 80 } }}
        extra={
          <Space>
            <Button type="default" onClick={onClose}>
              {t('generic.cancel')}
            </Button>
            <Button onClick={onSubmit} type="primary" loading={updating}>
              {t('generic.save')}
            </Button>
          </Space>
        }
      >
        <div className="flex flex-col h-full">
          <div className="flex flex-col" style={{ flexGrow: 1 }}>
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className={`${animateError && 'error-animation'}`}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="firstName" label={`First Name`} rules={[{ required: true, message: `Please enter First Name` }]}>
                    <Input placeholder={`Please enter Last Name`} allowClear />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="lastName" label={`Last Name`} rules={[{ required: true, message: `Please enter Last Name` }]}>
                    <Input placeholder={`Please enter Last Name`} allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item rules={[{ validator: checkExistingPrimary }]} name="primary" valuePropName="checked">
                    <Checkbox>{t('company.contact.setAsPrimaryContact')}</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <If condition={isPrimaryAlreadyExist}>
                    <div className="mb-20" style={{ color: colorError }}>
                      {t(`company.contact.primaryContactAlreadyExistsWarning`)}
                    </div>
                  </If>
                </Col>
              </Row>
              <AddressGenericCardHandler
                referenceType="CONTACT"
                referenceId={selectedContact && selectedContact.id ? String(selectedContact.id) : undefined}
              />
            </Form>
            <LinkContact
              selectedUser={selectedUser}
              onSubmit={() => {
                setOpenLinkContactDrawer(false);
              }}
              setSelectedUser={setSelectedUser}
              open={openLinkContactDrawer}
              setOpen={setOpenLinkContactDrawer}
            />
            {selectedUser ? (
              <div className="mt-20 mb-20 pb-20 pt-20">
                <Row justify={'space-between'} align={'middle'} className="mb-10 mt-10">
                  <Col>
                    <div className="pr-20">
                      <LinkOnSvgIcon />
                    </div>
                  </Col>
                  <Col flex="1">
                    <div className="flex flex-col">
                      <div className="font-bold" style={{ color: NEUTRAL_8_COLOR, fontSize: fontSize + 2 }}>{`${t(
                        'company.contact.linkContact.linkedToUserAccount'
                      )}`}</div>
                    </div>
                  </Col>
                  <Col>
                    <Button onClick={() => setSelectedUser(null)} type="text" danger>
                      <span>{`${t('generic.unlink')}`}</span>
                    </Button>
                  </Col>
                </Row>
                <Row className="ml-40">
                  <Col>
                    <span style={{ color: NEUTRAL_6_COLOR }}>
                      {selectedContact.user?.login && selectedContact.user.id === selectedUser
                        ? selectedContact.user.login
                        : `${t('company.contact.linkContact.userAccount')} #${selectedUser}`}
                    </span>{' '}
                  </Col>
                </Row>
              </div>
            ) : (
              <div className="flex mt-20 mb-20 pb-20 pt-20">
                <div className="pr-20">
                  <LinkOffIcon />
                </div>
                <div className="flex flex-col">
                  <div className="font-bold" style={{ color: colorTextTertiary, fontSize: fontSize + 2 }}>{`${t(
                    'company.contact.linkContact.linkToUserAccount'
                  )}`}</div>
                  <div className="mt-5" style={{ color: colorWarningActive }}>{`${t(
                    'company.contact.linkContact.noUserAccountLinked'
                  )}`}</div>
                  <div
                    className="mt-15 cursor-pointer"
                    onClick={() => setOpenLinkContactDrawer(true)}
                    style={{ color: colorPrimary }}
                  >{`${t('company.contact.linkContact.linkToExistingProfile')}`}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ViewOrUpdateContact;
