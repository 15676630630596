import { http } from '../http/axios.instance';
import { IUser } from '../../models/user.model';

const path = 'users-extended';

export const usersExtendedRepository = {
  getAll: async () => {

    const params = new URLSearchParams({
      cacheBuster: `${new Date().getTime()}`
    })
    const requestUrl = `${path}?${params.toString()}`;

    const promiseGetAll = await http.get<IUser[]>(requestUrl);
    return promiseGetAll;
  },

}