import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from '@store/reducer.util';
import { IAclPermission, defaultValue } from '@models/acl-permission.model';
import { IQueryParams } from '@models/pagination';
import { aclPermissionsService } from '@services/acl-permissions.service';
import { getTotalItems } from '@shared/util/entity-utils';

const initialState: EntityState<IAclPermission> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

interface IGetEntitiesParams {
  queryParams: IQueryParams,
  aclTargetId: any | undefined | null
}

// Actions
export const getEntities = createAsyncThunk(
  'aclPermission/fetch_entity_list', 
  async (params: IGetEntitiesParams, thunkAPI) => {
  return await aclPermissionsService.getAclPermissions(params.queryParams, params.aclTargetId);
});

export const createEntity = createAsyncThunk(
  'aclPermission/create_entity',
  async (entity: IAclPermission, thunkAPI) => {
    const result = await aclPermissionsService.createAclPermission(entity);
    thunkAPI.dispatch(getEntities({ queryParams: {}, aclTargetId: entity.aclTargetId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'aclPermission/delete_entity',
  async (entity: IAclPermission, thunkAPI) => {
    if (typeof entity.id !== 'undefined') {
      const result = await aclPermissionsService.deleteAclPermission(entity.id);
      thunkAPI.dispatch(getEntities({ queryParams: {}, aclTargetId: entity.aclTargetId }));
      return result;
    }
  },
  { serializeError: serializeAxiosError }
);

// slice

export const AclPermissionSlice = createEntitySlice({
  name: 'aclPermission',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: getTotalItems(headers)
        };
      })
      .addMatcher(isFulfilled(createEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload;
      })
      .addMatcher(isPending(getEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = AclPermissionSlice.actions;

// Reducer
export default AclPermissionSlice.reducer;
