/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useErrorAnimation } from "@HOOKs/UseErrorAnimation";
import { isFulfilled } from "@reduxjs/toolkit";
import { asyncLaunchNotification } from "@store/slices/notification";
import { createEntity, updateEntity, getEntities as getBrands } from "@store/slices/brand";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Button, Col, Drawer, Form, Input, Row, Space } from "antd";
import { useTranslation } from "react-i18next";
import { IBrand } from "@models/brand.model";
interface IBrandEditProps {
  open: boolean,
  toogle: () => void,
  onModifiedChanges?: (brand: IBrand) => void,
  brand: IBrand
}

export const BrandEdit = (props: IBrandEditProps) => {

    const { open, toogle, brand, onModifiedChanges } = props;

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [form] = Form.useForm();

    const { loading, updating } = useAppSelector((state) => state.Brand);

    const [animateError, playAnimationOfError] = useErrorAnimation();

    const onFinishFailed = (errorInfo: any) => playAnimationOfError();

    const onFinish = (entity: any): void => {
        const hasBrand = brand?.id;
        if (hasBrand) {
            dispatch(
                updateEntity({
                    ...brand,
                    ...entity,
                    description: entity?.description?.trim() || ''
                })  
            ).then(onSuccessUpdatedStatus);
            
        } else {
            dispatch(createEntity({
                id: "",
                ...entity,
                description: entity?.description?.trim() || ''
            })).then(onSuccessCreateStatus);
        }
    };

    const onSuccessUpdatedStatus = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: t("admin.changesSaved"),
                  description: t("admin.yourChangesHaveBeenSavedSuccessfully")
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onSuccessCreateStatus = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: t("admin.changesSaved"),
                  description: t("admin.brandCreatedSuccessfully")
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onClose = () => { 
        form.resetFields();
        toogle(); 
    }

    const onSubmit = () => form.submit();

    const loadBrands = () => {
        dispatch(
          getBrands({})
        );
      }
    
      useEffect(() => {
        loadBrands();
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    useEffect(() => { setRoleFormFields() }, [brand])

    const setRoleFormFields = () => {
        form.setFieldsValue({
            ...brand
        });
    }

    return (
        <>
            <Drawer 
                width={448} 
                title={`${brand?.id ? t("admin.editBrand"): t("admin.createBrand")}`}
                placement="right" 
                onClose={onClose} 
                open={open}
                closable={false}
                extra={
                    <Space>
                      <Button type="primary" ghost onClick={onClose}>
                        {t("generic.cancel")}
                      </Button>
                      <Button loading={loading} onClick={onSubmit} type="primary" disabled={updating}>
                        {`${brand?.id ? t("generic.save"): t("generic.create")}`}
                      </Button>
                    </Space>
                  }
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className={`${animateError && "error-animation"}`}
                >

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="description"
                                label={t("admin.description")}
                                rules={[
                                    { required: true, message: t("admin.descriptionFieldRequired") },
                                ]}
                            >
                                <Input placeholder={t("admin.description")} allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    
                </Form>
            </Drawer>
        </>
  );
}
