import { BudgetStatusEnum } from '@models/budget.model';
import { Divider, Tag, theme } from 'antd';
import {
  // Trans,
  useTranslation,
} from 'react-i18next';
import { BudgetApprovalAction } from './BudgetApprovalAction';

const statusToTagColorMap = {
  [BudgetStatusEnum.PENDING_APPROVAL]: 'warning',
  [BudgetStatusEnum.APPROVED]: 'success',
  [BudgetStatusEnum.REJECTED]: 'error',
};

interface IBudgetStatusBoxProps {
  status: BudgetStatusEnum.APPROVED | BudgetStatusEnum.PENDING_APPROVAL | BudgetStatusEnum.REJECTED;
  isApproverUser: boolean;
}

export const BudgetApprovalStatusBox = ({ status, isApproverUser }: IBudgetStatusBoxProps) => {
  const {
    token: {
      colorText,
      colorWarningBorderHover,
      colorSuccessBorderHover,
      colorErrorBorderHover,
      // colorTextTertiary, colorTextQuaternary
    },
  } = theme.useToken();
  const { t } = useTranslation();
  const statusToBorderColorMap = {
    [BudgetStatusEnum.PENDING_APPROVAL]: colorWarningBorderHover,
    [BudgetStatusEnum.APPROVED]: colorSuccessBorderHover,
    [BudgetStatusEnum.REJECTED]: colorErrorBorderHover,
  };
  const statusToTranslationMap = {
    [BudgetStatusEnum.PENDING_APPROVAL]: t('budget.status.pending'),
    [BudgetStatusEnum.APPROVED]: t('budget.status.approved'),
    [BudgetStatusEnum.REJECTED]: t('budget.status.rejected'),
  };
  return (
    <div
      className="mb-15 mt-15 mr-15 p-16"
      style={{
        gridColumn: '1/-1',
        border: `1px solid ${statusToBorderColorMap[status]}`,
        borderRadius: '0.25rem',
      }}
    >
      <div className="flex justify-between items-center mb-12">
        <span style={{ fontSize: '16px', fontWeight: 500, color: colorText }}>{t('budget.approval.sent')}</span>
      </div>
      <div className="flex justify-between items-center">
        <Tag color={statusToTagColorMap[status]} className="px-8 py-4" style={{ borderRadius: '4px', fontSize: '14px' }}>
          {statusToTranslationMap[status]}
        </Tag>
        {/*To do: Read current pending approver dynamically */}
        {/* {status === BudgetStatusEnum.PENDING_APPROVAL && (
          <span style={{ color: colorTextQuaternary }}>
            <Trans
              i18nKey="budget.approval.needFeedback"
              values={{ approver: 'Don Drapper' }}
              components={{ strong: <span style={{ color: colorTextTertiary }}></span> }}
            />
          </span>
        )} */}
      </div>
      {/**TO DO: Check if logged user is pending approver */}
      {status === BudgetStatusEnum.PENDING_APPROVAL && isApproverUser && (
        <>
          <Divider />
          <BudgetApprovalAction />
        </>
      )}
    </div>
  );
};
