import { If } from "@components/Utils/Structural";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getEntity } from '@store/slices/projects';
import { Button, Col, Divider, Row, theme, Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { updateProjectEntity } from "@store/slices/project-deliverable-timeline";
import { getActiveEntities as getCurrencies } from '@store/slices/currency';
import { asyncLaunchNotification } from "@store/slices/notification";




const DefaultCurrency = () => {

    const { token: { fontSizeHeading3, colorBorderSecondary, colorTextSecondary, colorTextTertiary, colorFillSecondary, colorBgContainer, fontSize } } = theme.useToken();

    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const [isManaging, setIsManaging] = useState<boolean>(false);

    const { entity: projectEntity } = useAppSelector((state) => state.Project);

    const [selectedProjectCurrency, setSelectedProjectCurrency] = useState<number | null>(null)
     

    const toggleManage = () => {
        setIsManaging(!isManaging);
    }

    const { activeEntities: currencies } = useAppSelector(state => state.Currency);
 

    useEffect(() => {
        dispatch(getCurrencies());
    }, [dispatch]);


    useEffect(() => {
        if(projectEntity && projectEntity.currency && projectEntity.currency.id){
            setSelectedProjectCurrency(projectEntity.currency.id)
        }
        dispatch(getCurrencies());
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectEntity]);
    

    const handleSelectChange = (value: number) => {
        setSelectedProjectCurrency(value)
    }

    const currencyOptions = currencies.map(currency => ({
        value: currency.id,
        label: `${currency.symbol} - ${currency.isoCode} - ${currency.name}`,
      }));
    
    const getCurrencyLabel = () => {

        let currencyLabel = ''
        if(projectEntity.currency && projectEntity.currency.symbol)
            currencyLabel += `${projectEntity.currency.symbol} - `;
        if(projectEntity.currency && projectEntity.currency.isoCode)
            currencyLabel += `${projectEntity.currency.isoCode} - `;
        if(projectEntity.currency && projectEntity.currency.name)
            currencyLabel += `${projectEntity.currency.name}`
        return currencyLabel
    }

    
    const confirmUpdateProjectType = () => {
        const requestData = {
          ...(selectedProjectCurrency ? { currency: { id: selectedProjectCurrency } } : {})
        };
      
        if (projectEntity && projectEntity.id) {
          dispatch(updateProjectEntity({
            ...requestData,
            id: String(projectEntity.id)
          })).then(() => {
            setIsManaging(false);
            dispatch(getEntity(String(projectEntity.id)));
            setSelectedProjectCurrency(null);
          }).catch(error => {
            if (onError) onError(error);
          });
        }
      };

    const onError = (error: unknown) => {
        dispatch(asyncLaunchNotification({
          type: "error",
          config: {
              message:  `${t('generic.project')}`,
              description: `${t('projectSettings.messages.currencyUpdateFailed')}`
          }
      }));
      };

    return (
        <div className={`block sticky top-0 mb-20`} style={{ border: `1.2px solid ${colorBorderSecondary}`, borderRadius: 10, background: colorBgContainer, zIndex: 10, padding: 10 }}>
            <Row justify={"space-between"} align="middle" style={{ paddingTop: 10 }}>
                <Col className='pt-3 pb-3'>
                    <span className='font-semibold pl-15' style={{ fontSize: fontSizeHeading3, color: colorTextSecondary }}>
                        {`${t('budget.currency')}`}
                    </span>
                </Col>
            </Row>
            <Divider className='mt-8 mb-8' style={{ borderColor: colorFillSecondary }} />
            <Row className='flex flex-row items-center justify-between'>
                <If condition={!isManaging && Boolean(projectEntity.currency?.name)}>
                    <Row className="mb-10 mt-10 flex-col align-end ml-5">
                        <div className='flex flex-col pl-10' style={{ color: colorTextTertiary, fontSize: fontSize + 3 }}>
                            {getCurrencyLabel()}
                        </div>
                    </Row>
                </If>
                <If condition={!isManaging && !Boolean(projectEntity.currency?.name)}>
                    <Row className="mb-10 mt-10 flex-col align-end ml-5">
                        <div className='flex flex-col pl-10' style={{ color: colorTextTertiary }}>
                            {`${t('projectSettings.messages.noCurrencySelected')}`}
                        </div>
                    </Row>
                </If>
                <If condition={isManaging}>
                    <Row className="mt-3">
                        <div className="pt-3 mb-3 mr-5 mb-10 mt-10 cursor-pointer" style={{ flex: 1, borderRadius: "4px", padding: "5px" }}>
                            <Select
                                style={{ width: 300 }}
                                value={selectedProjectCurrency}
                                onChange={handleSelectChange}
                                options={currencyOptions}
                                placeholder="Select Project Currency"
                            />
                        </div>
                    </Row>
                </If>
                <Row className='flex items-center'>
                    <If condition={!isManaging}>
                        <Button type='primary' onClick={toggleManage}>{t(`generic.manage`)}</Button>
                    </If>
                    <If condition={isManaging}>
                        <>
                            <Button className='mr-5' onClick={toggleManage}>{t(`generic.cancel`)}</Button>
                            <Button type='primary' onClick={confirmUpdateProjectType}>{t(`generic.save`)}</Button>
                        </>
                    </If>
                </Row>
            </Row>
        </div>

    )
}

export default DefaultCurrency;