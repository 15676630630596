/* eslint-disable react-hooks/exhaustive-deps */
import Code403NotAllowedComponent from '@components/__codes__/Code403NotAllowedComponent';
import { Helmet } from 'react-helmet-async';
import { configuration } from '../environments/env';
import { useLayoutContex } from '@providers/LayoutProvider';
import { Layout, theme } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { MainHeader } from '@components/Layout/HeaderLayout/MainHeader';
import { usePageContentSize } from '@HOOKs/UsePageContentSize';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useAuth } from '@providers/AuthProvider';
import { ADMIN_ROLE_KEY } from '@constants/core';
import { isArrayWithValues } from '@shared/util/array-util';
import { AdminTabs } from '@components/Admin/AdminTabs';

export default function AdminPage() {

  const PageContent = () => {

    const { setHeaderLabelEntityState } = useLayoutContex();
    const [layoutRef]= usePageContentSize()
    const { token: { colorBgLayout} } = theme.useToken();

    useLayoutEffect(() => { 
        setHeaderLabelEntityState && setHeaderLabelEntityState("Admin Section")
        
        return () => setHeaderLabelEntityState && setHeaderLabelEntityState("");
    },[]);

    return (
      <Layout >
          <MainHeader 
            headerProps={{ style: { background: colorBgLayout }}} 
            breadcrumbProps={{ removeLastBreadcrumbAutomatically: false }} 
          />
          <Content ref={layoutRef}>
            <AdminTabs />
          </Content>
      </Layout>
    )
  }

  const { user } = useAuth();
  const [blockAccessPage, setBlockAccessPage] = useState(false)

  useEffect(() => {
    (isArrayWithValues(user?.authorities) && !user?.authorities?.includes(ADMIN_ROLE_KEY)) && setBlockAccessPage(true)
  }, [])

  const Page = () => (
    <>
      <Helmet>
        <title>Admin | { configuration.APP_NAME }</title>
      </Helmet>
      { blockAccessPage ? <Code403NotAllowedComponent /> : <PageContent /> }
    </>
  )
  return (<Page />)
}