/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useErrorAnimation } from "@HOOKs/UseErrorAnimation";
import { isFulfilled } from "@reduxjs/toolkit";
import { asyncLaunchNotification } from "@store/slices/notification";
import { createEntity, updateEntity } from "@store/slices/cost-sub-category";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Button, Col, Drawer, Form, Input, Row, Space, Switch, theme } from "antd";
import { ICostSubCategoryExtended, ICostSubCategory } from "@models/cost-sub-category.model";
import { useTranslation } from "react-i18next";

interface ICostSubCategoryEditProps {
  open: boolean,
  toggle: () => void,
  onModifiedChanges?: (costSubCategory: ICostSubCategoryExtended) => void,
  costSubCategory: ICostSubCategory | null
}

export const CostSubCategoryEdit = (props: ICostSubCategoryEditProps) => {

    const { open, toggle, costSubCategory, onModifiedChanges } = props;

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { token: { colorTextDisabled, colorSuccessText } } = theme.useToken();
    
    const [form] = Form.useForm();
    const active = Form.useWatch('active', form);

    const { loading, updating } = useAppSelector((state) => state.Discipline);

    const [animateError, playAnimationOfError] = useErrorAnimation();

    const onFinishFailed = (errorInfo: any) => playAnimationOfError();

    const onFinish = (entity: any): void => {
        const hasEntity = costSubCategory?.id;
        if (hasEntity) {
            dispatch(
                updateEntity({
                    ...costSubCategory,
                    ...entity,
                    name: entity?.name?.trim() || '',
                    code: entity?.code?.trim() || '',
                    glCode: entity?.glCode?.trim() || '',
                    active: active
                })  
            ).then(onSuccessUpdatedStatus);
            
        } else {
            dispatch(createEntity({
                id: "",
                ...entity,
                name: entity?.name?.trim() || '',
                code: entity?.code?.trim() || '',
                glCode: entity?.glCode?.trim() || '',
                active: active,
                order: costSubCategory?.order || 99,
                costCategory: { id: costSubCategory?.costCategory?.id }
            })).then(onSuccessCreateStatus);
        }
    };

    const onSuccessUpdatedStatus = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: `Changes saved`,
                  description: `Your changes have been saved successfully`
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onSuccessCreateStatus = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: `Changes saved`,
                  description: `Cost Subcategory created successfully`
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onClose = () => { 
        form.resetFields();
        toggle(); 
    }

    const onSubmit = () => form.submit();

    useEffect(() => { setRoleFormFields() }, [costSubCategory])

    const setRoleFormFields = () => {
        form.setFieldsValue({
            ...costSubCategory,
            active: Boolean(costSubCategory?.active)
        });
    }

    return (
        <>
            <Drawer 
                width={448} 
                title={`${costSubCategory?.id ? "Edit": "Create new"} subcategory`}
                placement="right" 
                onClose={onClose} 
                open={open}
                closable={false}
                extra={
                    <Space>
                      <Button type="primary" ghost onClick={onClose}>
                        {t("generic.cancel")}
                      </Button>
                      <Button loading={loading} onClick={onSubmit} type="primary" disabled={updating}>
                        {`${costSubCategory?.id ? t("generic.save"): t("generic.create")}`}
                      </Button>
                    </Space>
                  }
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className={`${animateError && "error-animation"}`}
                >

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[
                                    { required: true, message: "Name field is Required" },
                                ]}
                            >
                                <Input placeholder="Name" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="code"
                                label="Code"
                                rules={[
                                    { required: true, message: "Code field is Required" },
                                ]}
                            >
                                <Input placeholder="Code" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="glCode"
                                label="GL Code"
                                rules={[
                                    { required: false },
                                ]}
                            >
                                <Input placeholder="GL Code" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={"none"} className="pr-0" style={{ flex: '0' }}>
                            Status
                        </Col>
                        <Col span={"none"} className="pl-0" style={{ flex: '1', textAlign: 'right' }} >
                            {/* For css purposes */}
                            <Form.Item className="ml-0 pl-0" valuePropName="">
                                <span style={{ color: (Boolean(active) ? colorSuccessText : colorTextDisabled) }}>{Boolean(active) ? 'Active' : 'Inactive'}</span>
                                <Switch style={{ display: "none" }}/>
                            </Form.Item>
                        </Col>
                        <Col span={"none"} className="pr-0" style={{ flex: '0', textAlign: 'right' }}>
                            <Form.Item name="active" valuePropName="checked">
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Drawer>
        </>
  );
}
