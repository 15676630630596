import { UIEvent, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Col, ConfigProvider, Modal, Row, Typography, theme } from "antd";
import { MODAL_THEME_SECONDARY, WHITE_ABSOLUTE } from "@providers/ThemeProvider";
import { useResizeDetector } from "react-resize-detector";
import { COLOR_TEXT_BASE } from "@providers/ThemeProvider";
import { useThemeProvider } from "@providers/ThemeProvider";
import { InfoSide } from "./InfoSide";
import { FullScreenSvgIcon } from "@components/Icons/FullScreenSvgIcon";
import { If } from "@components/Utils/Structural";
import { CloseOutlined, ExclamationCircleFilled, FileOutlined, FilePdfOutlined, ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { FilePreviewData } from "@models/attachment.model";
import { EmptyLabel } from "@components/Utils/EmptyLabel";
import { isArrayWithValues } from "@shared/util/array-util";
import { getExtensionOfFileName, isAnValidImage } from "@shared/util/document-utils";
import { attachmentService } from "@services/attachment.service";
import { DerivativesEnum } from "@models/enumerations/derivatives-enum.model";
import { deleteEntity as deleteAttachment } from '@store/slices/attachment';
import { ImageContainerBox } from "./ImageContainerBox";
import { useAppDispatch } from "@store/store";
import { useContainerFileContex } from "./ContainerFileProvider";
interface IFilePreviewModalProps {
    open: boolean
    toggle: () => void,
    fileData: FilePreviewData,
    previewLatestVersion: () => void,
    getAllAttachments: () => void,
    showModalForNewRevision: (attachmentId: number) => void
    onSuccessDeleteAttachment?: () => void
    showRevisionPanel?: boolean
}

const BACKGROUND_FULLSCREEN = '#262626';
const LIGHT_BACKGROUND = "#434343";
const DARK_BACKGROUND = "#525252";
const COLOR_BORDER_INSIDE = "#8C8C8C";
const COLOR_ICON_HEADER_BAR_FULL_SCREEN = "#BFBFBF";
const COLOR_TEXT_HEADER_BAR_FULL_SCREEN = "#D9D9D9";
const ZINDEX_MODAL = 2;
const ZINDEX_LAYER_OVER_MODAL = 3;

const FixedPaginationCss: React.CSSProperties = {
    position: "sticky",
    top: "10px" 
}

export const FilePreviewModal = (props: IFilePreviewModalProps) => {

    const { 
        open, 
        toggle, 
        fileData, 
        previewLatestVersion, 
        getAllAttachments,
        showModalForNewRevision, 
        showRevisionPanel = true,
        onSuccessDeleteAttachment
    } = props;

    const { derivatives = [] } = fileData;

    const dispatch = useAppDispatch();
    const { token: { colorBorder, fontSize, colorPrimaryHover } } = theme.useToken();
    const { themeConfig } = useThemeProvider();
    const [modal, contextHolder] = Modal.useModal();
    const [documentMode, setDocumentMode] = useState(false);
    const [indexImageSelected, setIndexImageSelected] = useState(0);
    const [urlImageActive, setUrlImageActive] = useState("");
    
    const { width: widthContainerDocument = 0, height: heightContainerDocument = 0, ref: containerDocumentRef } = useResizeDetector({
        refreshMode: 'debounce',
        refreshRate: 50,
    });
    
    const [styleContainerFullScreen, setStyleContainerFullScreen] = useState<React.CSSProperties>({});
    const [headerBarHeight, setHeaderBarHeight] = useState(0);
    const headerBarFullScreenRef = useRef<HTMLDivElement | null>(null);
    const divFullScreenRef = useRef<HTMLDivElement | null>(null);
    const sidePanelListImages = useRef<HTMLDivElement | null>(null);
    const { scaleInString, zoomIn, zoomOut, resetZoom } = useContainerFileContex();

    const [isFullScreenMode, setIsFullScreenMode] = useState(false);
    
    useEffect(() => {
        const isAnImageValid = fileData.fileName && isAnValidImage(getExtensionOfFileName(fileData.fileName));
        if (isAnImageValid) {
            setDocumentMode(false);
            setIndexImageSelected(0);
            const linkUrl = (fileData.derivatives && isArrayWithValues(fileData.derivatives) && fileData.derivatives[0]?.signedUrl) || "";
            linkUrl ? setUrlImageActive(linkUrl): setImageUrl();
        } 
        
        if (!isAnImageValid && fileData.derivatives && isArrayWithValues(fileData.derivatives)) {
            setDocumentMode(true);
            setIndexImageSelected(0);
            fileData.derivatives && derivatives[0]?.signedUrl && setUrlImageActive(derivatives[0].signedUrl); 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileData])

    const setImageUrl = () => {
        attachmentService.getViewLink(String(fileData.id)).then((response) => {
          if (response.data) {
            setUrlImageActive(response.data)
          }
        });
    }

    const onCloseModalPreview = () => {
        toggle()
        setDocumentMode(false);
        setTimeout(() => {
            setUrlImageActive("");
        }, 500)
    }
    const selectImage = (url: string, index: number) => {
        setUrlImageActive(url);
        setIndexImageSelected(index);
    }

    const fullscreen = () => {
        resetZoom();
        if (!isFullScreenMode) {
            setIsFullScreenMode(true);
            setStyleContainerFullScreen({ 
                background: BACKGROUND_FULLSCREEN,
                height: '100vh', 
                width: '100vw', 
                position: "fixed",
                zIndex: ZINDEX_MODAL, 
                top: 0, 
                right: 0,
                left: 0, 
                bottom: 0
            });
        } else {
            setIsFullScreenMode(false);
            setStyleContainerFullScreen({})
        }
    }

    const [isFixed, setIsFixed] = useState(false);

    const handleScroll = (event: UIEvent<HTMLElement>) => {
        if (event.target && event?.currentTarget?.scrollTop && event?.currentTarget?.scrollTop > 25 ) {
            setIsFixed(true);
        } else {
            setIsFixed(false);
        }
    };

    useLayoutEffect(() => {
        setHeaderBarHeight(headerBarFullScreenRef?.current?.clientHeight || 0);
    }, [isFullScreenMode])

    const footerModal = (<></>)

    const onSuccessDelete = () => {
        onSuccessDeleteAttachment?.();
    };

    const onDeleteAction = () => {
        if (!fileData.id) return;

        modal.confirm({
            title: 'Do you Want to delete this document?',
            icon: <ExclamationCircleFilled />,
            onOk() {
                fileData.id && dispatch(deleteAttachment({id: fileData.id, onSuccess: onSuccessDelete})).then(() => {
                    getAllAttachments?.();
                    toggle();
                })
            }
        })
    }
    
    return (
        <>
            <>{contextHolder}</>
            <ConfigProvider
                    theme={{
                        components: {
                            Modal: {
                                ...MODAL_THEME_SECONDARY,
                                borderRadius: 4,
                                borderRadiusLG: 4,
                                borderRadiusSM: 4,
                                borderRadiusXS: 4,
                            },
                        },
                    }}
            >
            <Modal 
                open={open} 
                closable={false}
                forceRender={true} 
                destroyOnClose={true} 
                centered={true} 
                footer={footerModal}
                width={"75%"}
                keyboard={false}
                styles={{ body: { border: `1px solid ${colorBorder}`, borderRadius: 4, overflow: "hidden"} }} >
                    <div ref={containerDocumentRef} className="w-full h-full" style={{ borderRadius: 4, height: "80vh" }} >
                        <div ref={divFullScreenRef} style={styleContainerFullScreen} className="h-full">                        
                            <If condition={(isFullScreenMode)}>    
                                <Row ref={headerBarFullScreenRef} className="flex flex-row items-center justify-between" style={{ backgroundColor: COLOR_TEXT_BASE, maxWidth: "100vw"}}>
                                    <div className="flex flex-row items-center">
                                        <div className="pt-15 pb-15 pl-30 pr-10" style={{ color: COLOR_ICON_HEADER_BAR_FULL_SCREEN }}>
                                            { 
                                                documentMode 
                                                    ? <FilePdfOutlined style={{ height: '34px', fontSize: "32px" }}/> 
                                                    : <FileOutlined style={{ height: '34px', fontSize: "32px" }} /> 
                                            }
                                        </div>
                                        <div>
                                            <Typography.Title 
                                                level={5} 
                                                style={{ margin: 0, color: COLOR_TEXT_HEADER_BAR_FULL_SCREEN, width: "60vw" }}
                                                ellipsis={{ rows: 1, expandable: false, suffix: '' }}
                                                title={fileData?.fileName || ""}
                                            >
                                                { fileData?.fileName || <EmptyLabel/> }
                                            </Typography.Title>
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-center" style={{ fontSize: 20 }} > 
                                        <span className="mr-20" style={{ color: WHITE_ABSOLUTE, fontSize: fontSize }} >{scaleInString}</span>
                                        <ZoomInOutlined onClick={zoomIn} title={"Zoom in"} className="cursor-pointer select-none pr-20" style={{ color: WHITE_ABSOLUTE }} />
                                        <ZoomOutOutlined onClick={zoomOut} title={"Zoom out"} className="cursor-pointer select-none pr-30" style={{ color: WHITE_ABSOLUTE }} />
                                        <CloseOutlined title={"Close"} onClick={fullscreen} className="cursor-pointer select-none pr-30" style={{ color: WHITE_ABSOLUTE }} />
                                    </div>
                                </Row>   
                            </If>
                            <Row className={`${isFullScreenMode ? "": "h-full"}`}>
                                
                                {/* Left Panel Images list / Paginator */}
                                <If condition={(documentMode)}>
                                    <Col 
                                        onScroll={handleScroll}
                                        ref={sidePanelListImages} 
                                        span={isFullScreenMode ? 3 : 4} 
                                        className="relative overflow-scroll" 
                                        style={{ backgroundColor: LIGHT_BACKGROUND, maxHeight: isFullScreenMode ? "100vh" : heightContainerDocument, borderRight: `1px solid ${COLOR_BORDER_INSIDE}` }} >

                                        {/* Pagination Info */}
                                        <div
                                            className="flex flex-row items-center justify-center mt-10 " 
                                            style={{ ...isFixed && {...FixedPaginationCss } }}
                                        >
                                            <span 
                                                className="pl-10 pr-10 pt-5 pb-5" 
                                                style={{ 
                                                    color: WHITE_ABSOLUTE, 
                                                    backgroundColor: LIGHT_BACKGROUND, 
                                                    fontSize: fontSize + 2, 
                                                    borderRadius: "4px", 
                                                    ...isFixed && { border: `1px solid ${COLOR_BORDER_INSIDE}` }
                                                }}
                                            >
                                                Page {indexImageSelected + 1} of { isArrayWithValues(fileData?.derivatives) ? fileData?.derivatives?.filter((img) => img?.derivativeType === DerivativesEnum.PREVIEW ).length : 1 }
                                            </span>
                                        </div>
                                        
                                        {/* List of Images */}
                                        <div className="pr-25 pl-25 pt-20">
                                            {
                                                derivatives && isArrayWithValues(derivatives) && derivatives.filter((img) => img?.derivativeType === DerivativesEnum.PREVIEW ).map((img, index) => {
                                                    return (
                                                        <div key={img.id} className="flex flex-col items-center mb-10">
                                                            { 
                                                                img?.signedUrl && (
                                                                    <img 
                                                                        src={img.signedUrl} 
                                                                        onClick={() => selectImage(img.signedUrl!, index) }
                                                                        className={`w-full cursor-pointer h-full ${ (indexImageSelected === index) ? "mb-7" : "mb-4"}`}
                                                                        style={(indexImageSelected === index) ? { outline: `4px solid ${colorPrimaryHover}`, borderRadius: 4 } : {} }
                                                                        alt=""
                                                                    ></img>
                                                                ) 
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                    </Col>
                                </If>

                                {/* Viewer */}
                                <Col 
                                    span={isFullScreenMode ? (documentMode ? 21 : 24) : (documentMode ? 14 : 18)} 
                                    className="items-center relative overflow-hidden"
                                    style={{ backgroundColor: DARK_BACKGROUND }}
                                >       
                                        <If condition={(!isFullScreenMode)}>
                                            <div 
                                                title={"FullScreen"} 
                                                className="absolute flex flex-row pt-11 pb-11 pr-11 pl-11 rounded-full cursor-pointer" 
                                                style={{ backgroundColor: LIGHT_BACKGROUND, zIndex: ZINDEX_LAYER_OVER_MODAL, right: "1.8rem", top: "1.8rem" }} >
                                                <FullScreenSvgIcon 
                                                    onClick={() => fullscreen() } 
                                                    style={{ color: WHITE_ABSOLUTE }} 
                                                />
                                            </div>
                                        </If>
                                        <If condition={!!(urlImageActive)}>
                                            <ImageContainerBox
                                                    imageUrl={urlImageActive} 
                                                    width={ isFullScreenMode ? "100%": `${widthContainerDocument}px` } 
                                                    height={ isFullScreenMode ? `calc(100vh - ${headerBarHeight}px)`: `${heightContainerDocument}px` }
                                            />
                                        </If>
                                </Col>

                                {/* InfoSide */}
                                <If condition={(!isFullScreenMode)}>
                                    <Col span={6} className="overflow-scroll generic-shadow-left" style={{ maxHeight: heightContainerDocument }} >
                                        <ConfigProvider theme={{ token: { colorTextBase: themeConfig.darkMode ? WHITE_ABSOLUTE : COLOR_TEXT_BASE  } }}>
                                            <If condition={open} >
                                                <InfoSide 
                                                    close={() => onCloseModalPreview() } 
                                                    fileData={fileData} 
                                                    previewLatestVersion={previewLatestVersion}
                                                    onDeleteAction={onDeleteAction}
                                                    showModalForNewRevision={showModalForNewRevision}
                                                    showRevisionPanel={showRevisionPanel} />
                                            </If>
                                        </ConfigProvider>
                                    </Col>
                                </If>
                            </Row>
                        </div>
                    </div>
            </Modal>
            </ConfigProvider>
        </>
    )
}
