type Detector = {
    name: string;
    detect: (userAgent: string) => boolean;
};
  
const detectBrowser = (): string => {
    let userAgent = navigator.userAgent;         
    let browserName = "";

    if(userAgent.match(/OPR\//i)){
    browserName = "Opera";
    } else if(userAgent.match(/Edg/i)){
    browserName = "Edge";
    } else if(userAgent.match(/Chrome|chromium|crios/i)){
    browserName = "Chrome";
    } else if(userAgent.match(/firefox|fxios/i)){
    browserName = "Firefox";
    }  else if(userAgent.match(/Safari/i)){
    browserName = "Safari";
    } else {
    browserName="Unknown browser";
    }
    
    return browserName;
}
  
const operatingSystems: Detector[] = [
    {
        name: "Windows",
        detect: (userAgent) => /win/i.test(userAgent),
    },
    {
        name: "Mac OS",
        detect: (userAgent) => /mac/i.test(userAgent),
    },
    {
        name: "Linux",
        detect: (userAgent) => /linux/i.test(userAgent),
    },
    {
        name: "iOS",
        detect: (userAgent) => /iphone|ipad|ipod/i.test(userAgent),
    },
    {
        name: "Android",
        detect: (userAgent) => /android/i.test(userAgent),
    },
];
  
export const detectBrowserAndOS = (userAgent: string) => {
    const detectedBrowser = detectBrowser();
    const detectedOS = operatingSystems.find((os) => os.detect(userAgent));

    const browserName = detectedBrowser;
    const osName = detectedOS ? detectedOS.name : "Unknown OS";

    return {
    browser: browserName,
    os: osName,
    };
}
  
  
  
  