import { Button, Drawer, Row, Space, theme } from 'antd'
import { IAddress } from '@models/address.model';
import { useAppDispatch } from '@store/store';
import { deleteAddress } from '@store/slices/generic-address';
import { AddressReferenceEnumListOptions } from '@models/enumerations/address-reference-enum.model';
import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { If } from '@components/Utils/Structural';

export interface IAddressGenericDeleteProps {
    open: boolean
    toggle: () => void,
    address: IAddress,
    referenceId?: StringORNumber,
    referenceType: AddressReferenceEnumListOptions
}

export const AddressGenericDelete = (props: IAddressGenericDeleteProps) => {
    const { open, toggle, address, referenceId, referenceType } = props;

    const dispatch = useAppDispatch();
    const { token: { colorText } } = theme.useToken();
    
    const onDelete = () => {
        if (referenceId && referenceType && address.id) {
            dispatch(deleteAddress({
                referenceId,
                referenceType,
                addressId: address.id
            })).then(() => {
                toggle();
            })
        }
    }

    return (
        <Drawer
          title="Delete Address"
          width={500}
          closable={false}
          onClose={toggle}
          open={open}
          extra={
            <Space>
              <Button type="primary" ghost onClick={toggle}>
                Go Back
              </Button>
            </Space>
          }
        >
          <Row justify={"center"}>
            <div className='flex flex-col justify-center items-center pr-20 pl-20' style={{ color: colorText }}>
                <span className='mt-40 font-bold'>
                    Are you sure you want to delete this address?
                </span>
                <div className='flex flex-col justify-center items-center mt-30'>
                  <span className='font-bold pb-5 text-center'>
                    { address?.addressType?.description }
                  </span>
                  <span className='text-center'>
                    { address?.streetAddress }
                  </span>
                  <If condition={!!address.streetAddress2}>
                    <span className='text-center'>
                      { address?.streetAddress2 }
                    </span>
                  </If>
                  <span className='text-center'>
                    { `${ address?.city ? `${address?.city},` : "" } ${ address?.stateProvince || "" } ${ address?.postalCode || "" }` }
                  </span>
                </div>
                <div className='w-min mt-30'>
                    <Button type="primary" danger onClick={onDelete} >
                        Delete
                    </Button>
                </div>
            </div>
          </Row>
        </Drawer>
    )
}
