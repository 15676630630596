/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Outlet, useOutletContext, useParams } from "react-router-dom";

type ContextType = {validBaseUrl: string };

export const RouteBaseLocationsTabs = () => {

    const { id: locationId } = useParams<"id">();

    useEffect(() => {
        setValidBaseUrl(`/location/${locationId}`)
    }, []);

    const [validBaseUrl, setValidBaseUrl] = React.useState<string>('');
    
    return (<Outlet context={{ validBaseUrl: validBaseUrl }} />)
}

export function useBaseLocationsTabUrl() {
    return useOutletContext<ContextType>();
}