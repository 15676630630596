
import { http } from '@infrastructure/http/axios.instance';
import { IDiscipline } from '@models/discipline.model';

const path = 'disciplines';

export const disciplinesRepository = {
  getDisciplines: async (queryParams: any) => {
    const { sort = "name" } = queryParams;

    const params = new URLSearchParams({
      ...sort && { sort },
      page: 0,
      size: 1000
    } as any)
    const requestUrl = `${path}?${params.toString()}`;

    const promiseGetAll = await http.get<IDiscipline[]>(requestUrl);
    return promiseGetAll;
  },
  create: async (entity: IDiscipline): Promise<IDiscipline> => {

    const requestUrl = `${path}`;
    const promiseCreate = await http.post<IDiscipline>(requestUrl, entity);
    return promiseCreate.data;
  },
  update: async (id: string | number, entity: IDiscipline): Promise<IDiscipline> => {

    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.put<IDiscipline>(requestUrl, entity);
    return promiseUpdate.data;
  },
}