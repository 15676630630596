import { 
    Row, 
    Col, 
    Form, 
    Divider, 
    Button, 
    InputNumber,
    Select, theme, Switch, Modal } from "antd";
import { useMemo, useState, useEffect } from "react";
import "@styles/components/deliverable-edit-form.less";
import { ITemplateDeliverableEdit } from "./TemplateEdit";
import { IPhase } from "@models/phase.model";
import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import { IUser } from "@models/user.model";
import { IRole } from "@models/role.model";
import { ITemplateDeliverableApproval } from "@models/template-deliverable-approval";
import { TrashSvgIcon } from "@components/Icons/TrashSvgIcon";

interface ITemplateDeliverableEditProps {
    templateDeliverableEdit: ITemplateDeliverableEdit;
    templateDeliverables: ITemplateDeliverableEdit[];
    phases: readonly IPhase[];
    onClose: any;
    onRemove(id: any): any
    onChangeValue(id: any, field: string, value: any): any;
    allUsers: readonly IUser[];
    allRoles: readonly IRole[];
    updateApprovals(deliverableId: string, approvals: ITemplateDeliverableApproval[]): any;
}

export const TemplateDeliverableEdit = (props: ITemplateDeliverableEditProps) => {

    const { templateDeliverableEdit, templateDeliverables, phases, allUsers, allRoles } = props;
    const { templateDeliverable, id } = templateDeliverableEdit;
    const { token: { fontSizeSM, colorTextQuaternary, colorWarningBg, colorWarningTextActive, colorWarningBorder, colorPrimary, colorTextTertiary } } = theme.useToken();

    const [form] = Form.useForm();
    const [modal, contextHolder] = Modal.useModal();

    let tempId = 50000000;
    const genTempId = () => {
        return tempId++;
    }

    const pendingIssues: string[] = [];
    if (!templateDeliverable.duration) {
        pendingIssues.push('duration');
    }
    if (!templateDeliverable.phase) {
        pendingIssues.push('phase');
    }

    const [approversEnabled, setApproversEnabled] = useState<boolean>(((templateDeliverable.approvals && templateDeliverable.approvals?.length > 0) ? true : false))

    const userOptions = useMemo(() => {
        return allUsers.map(u => {
            return { value: u.id, label: u.fullName }
        })
    }, [allUsers]);

    const roleOptions = useMemo(() => {
        return allRoles.map(r => {
            return { value: r.id, label: r.name }
        })
    }, [allRoles]);

    useEffect(() => {
        const dependencyId = form.getFieldValue('dependency');
        if (dependencyId) {
            if (!templateDeliverables.some(x => `${x.id}` === `${dependencyId}`)) {
                form.setFieldValue('dependency', undefined);
            }
        }
    // eslint-disable-next-line
    }, [templateDeliverables])

    const addApproval = () => {
        const approvals = templateDeliverable.approvals ? templateDeliverable.approvals.map(x => x) : [];
        approvals.push({
            key: genTempId(),
            order: approvals.length,
            role: allRoles[0],
            user: null,
            dependency: templateDeliverable
       })
       props.updateApprovals(id, approvals)
    }

    const onChangeApprovalType = (index: number, value: any) => {
        if (!templateDeliverable.approvals) return;
        const approvals = templateDeliverable.approvals.map(x => { return {...x} });
        if (approvals[index].role && value === "role") return;
        if (approvals[index].user && value === "user") return;
        if (value === "role") {
            approvals[index].role = allRoles[0];
            approvals[index].user = null;
        } else {
            approvals[index].role = null;
            approvals[index].user = allUsers[0];
        }
        props.updateApprovals(id, approvals);
    }

    const onChangeApprovalRole = (index: number, value: any) => {
        if (!templateDeliverable.approvals) return;
        const approvals = templateDeliverable.approvals.map(x => { return {...x} });
        const role = allRoles.find(x => x.id === value);
        if (role) {
            approvals[index].role = {...role};
            props.updateApprovals(id, approvals);
        }
    }

    const onChangeApprovalUser = (index: number, value: any) => {
        if (!templateDeliverable.approvals) return;
        const approvals = templateDeliverable.approvals.map(x => { return {...x} });
        const user = allUsers.find(x => x.id === value);
        if (user) {
            approvals[index].user = {...user};
            props.updateApprovals(id, approvals);
        }
    }

    const deleteApprover = (index: number) => {
        if (!templateDeliverable.approvals) return;
        const approvals = templateDeliverable.approvals.map(x => { return {...x} });
        approvals.splice(index, 1);
        props.updateApprovals(id, approvals);
    }

    const toggleApprovers = () => {
        if (approversEnabled) {
            props.updateApprovals(id, []);
        }
        setApproversEnabled(!approversEnabled)
    }

    return <>
    <>{contextHolder}</>
    <Form layout={'vertical'} className="deliverable-edit-form" form={form}>
        {pendingIssues.length > 0 && <div style={{ background: colorWarningBg, color: colorWarningTextActive, border: `1px solid ${colorWarningBorder}`, padding: '0.75rem', borderRadius: '4px', marginBottom: '1rem' }}>
            Pending {pendingIssues.join(', ')}
        </div>}
        <Row gutter={16}>
            <Col span={12} flex={0}>
                <Form.Item
                    name="duration"
                    label="Duration"
                    rules={[
                        { required: true, message: "Duration is Required" },
                    ]}
                >
                    <InputNumber 
                        defaultValue={templateDeliverable?.duration || undefined}
                        placeholder="Duration in days" 
                        style={{ width: '100%' }}
                        onChange={(e) => props.onChangeValue(id, 'duration', e)} />
                </Form.Item>
            </Col>
            <Col span={12} flex={0}>
                <Form.Item
                    name="phase"
                    label="Phase"
                    rules={[
                        { required: true, message: "Phase is Required" },
                    ]}
                >
                    <Select
                        placeholder="Select"
                        className='w-full mt-5'
                        defaultValue={templateDeliverable?.phase?.id}
                        options={phases.map(d => { return { value: d.id, label: d.label } })}
                        onChange={(e) => props.onChangeValue(id, 'phase', e)}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={16}>
            <Col span={12} flex={0}>
                <Form.Item
                    name="dependency"
                    label="Dependency"
                >
                    <Select
                        allowClear
                        placeholder="Select"
                        className='w-full mt-5'
                        defaultValue={(templateDeliverable?.dependency?.deliverable?.id ? `${templateDeliverable?.dependency?.deliverable?.id}` : undefined)}
                        options={templateDeliverables.filter(x => x.templateDeliverable?.deliverable?.id !== templateDeliverable?.deliverable?.id).map(d => { return { value: d.id, label: d.templateDeliverable?.deliverable?.name } })}
                        onChange={(e) => {
                            if (!e) {
                                form.setFieldValue('dependencyOffset', '')
                            }
                            props.onChangeValue(id, 'dependency', e);
                        }}
                    />
                </Form.Item>
            </Col>
            <Col span={12} flex={0}>
                <Form.Item
                    name="dependencyOffset"
                    label="Offset"
                >
                    <InputNumber 
                        disabled={(templateDeliverable?.dependency?.deliverable?.id ? false : true)}
                        placeholder="example" 
                        style={{ width: '100%' }}
                        defaultValue={templateDeliverable?.dependencyOffset || ''}
                        onChange={(e) => props.onChangeValue(id, 'dependencyOffset', e)} />
                </Form.Item>
                <div style={{ marginTop: '-1.25rem', fontSize: fontSizeSM, color: colorTextQuaternary }}>By default 0</div>
            </Col>
        </Row>
        <Divider />
        <div style={{ display: 'flex' }}>
            <div style={{ color: (approversEnabled ? colorPrimary : colorTextTertiary), marginRight: '0.5rem' }}>Approvers</div>
            <Switch defaultChecked={approversEnabled} style={{ marginBottom: '10px' }} onChange={toggleApprovers} title="Approvers" />
        </div>
        <div>
            {templateDeliverable.approvals?.map((approval, i) => {
                return <Row gutter={16} key={approval.key}>
                    <Col span={11} flex={0}>
                        <Form.Item
                            label="Type"
                        >
                            <Select
                                placeholder="Select"
                                className='w-full mt-5'
                                value={(approval.role ? "role" : "user")}
                                options={[{ value: "role", label: "Role" }, { value: "user", label: "User" }]}
                                onChange={(e) => onChangeApprovalType(i, e)}
                                disabled={!approversEnabled}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={11} flex={0}>
                        {approval.role ? <Form.Item
                            label="Value"
                        >
                            <Select
                                value={approval.role?.id}
                                placeholder="Select"
                                className='w-full mt-5'
                                disabled={!approversEnabled}
                                options={roleOptions}
                                onChange={(e) => onChangeApprovalRole(i, e)}
                            />
                        </Form.Item> : <Form.Item
                            label="Value"
                        >
                            <Select
                                value={approval.user?.id}
                                placeholder="Select"
                                className='w-full mt-5'
                                disabled={!approversEnabled}
                                options={userOptions}
                                onChange={(e) => onChangeApprovalUser(i, e)}
                            />
                        </Form.Item>}
                    </Col>
                    <Col span={2} flex={0}>
                        <Button type="link" style={{ marginTop: '30px' }} onClick={() => deleteApprover(i)}>
                            <TrashSvgIcon />
                        </Button>
                    </Col>
                </Row>
            })}
        </div>
        {approversEnabled && <div>
            <Button type="link" onClick={addApproval}>
                <PlusOutlined />
                Add Approver
            </Button>
        </div>}
        <Divider />
        <Row>
            <Col flex={1}>
            </Col>
            <Col flex={0} style={{ whiteSpace: 'nowrap' }} className="mr-20">
                <Button type="link" danger onClick={() => { 
                    if (templateDeliverables.some(x => `${x.templateDeliverable.dependency?.deliverable?.id}` === `${id}`)) {
                        modal.confirm({
                            title: 'There are other deliverables dependant upon this deliverable. Removing this deliverable will break those dependencies. Do you wish to proceed?',
                            icon: <ExclamationCircleFilled />,
                            onOk() {
                                props.onRemove(id);
                            }
                        })
                    } else {
                        props.onRemove(id);
                    }
                }}>Remove Deliverable</Button>
            </Col>
            <Col flex={0} className="mr-20">
                <Button onClick={props.onClose}>Close</Button>
            </Col>
        </Row>
    </Form>
</>
}