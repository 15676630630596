import { ShapeTypeEnum } from '@models/enumerations/shape-type.enum';
import { IShapeResponse } from '@models/shape-response.model';
import { IShape } from '@models/shape.model';

export class ShapeMapper {
  public static processShapeList(shapeResponseList: IShapeResponse[]): IShape[] {
    const output = shapeResponseList.map(item => {
      return this.processShape(item);
    });
    return output;
  }

  public static processShape(shapeResponse: IShapeResponse): IShape {
    // Destructure relevant fields from the response
    const { id, type, markerLocationX, markerLocationY, propertyValues } = shapeResponse;

    // Parse additional properties if they exist
    const additionalProperties = propertyValues ? JSON.parse(propertyValues) : {};

    // Return the transformed shape
    return {
      id: id?.toString() || null, // Convert id to string if it's a number
      left: markerLocationX || 0,
      top: markerLocationY || 0,
      width: additionalProperties.width || 0,
      height: additionalProperties.height || 0,
      type: type || ShapeTypeEnum.RECTANGLE, // Rectangle as default
      color: additionalProperties.color || '#000000',
      rotate: additionalProperties.rotate || 0,
      text: additionalProperties.text || '',
      ref: undefined,
    } as IShape;
  }

  public static toDomainSingle(response: IShapeResponse): IShape {
    return this.processShape(response);
  }

  public static toDomain(response: IShapeResponse[]): IShape[] {
    const dataTransformedToDomain = this.processShapeList(response);
    return dataTransformedToDomain || [];
  }

  public static convertToShapeResponseList(shapeList: IShape[]): IShapeResponse[] {
    const output = shapeList.map(item => {
      return this.convertToShapeResponseSingle(item);
    });
    return output;
  }

  public static convertToShapeResponseSingle(shape: IShape): IShapeResponse {
    // Extract relevant fields from the IShape
    const { id, left, top, width, height, type, color, rotate, text, ref } = shape;

    // Construct the additional properties
    const additionalProperties = JSON.stringify({
      width,
      height,
      color,
      rotate,
      text,
      ref,
    });

    // Return the transformed shape response
    return {
      id: id ? parseInt(id, 10) : null, // Convert id to number if possible
      type,
      markerLocationX: left,
      markerLocationY: top,
      propertyValues: additionalProperties,
      // Optionally add pageNumber or attachment if needed
    } as IShapeResponse;
  }
}
