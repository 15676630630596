/* eslint-disable react-hooks/exhaustive-deps */
import { IRfiDistribution } from "@models/rfi-distribution.model";
import { IUser } from "@models/user.model";
import { getUserDisplayName } from "@shared/util/user-utils";
import { asyncLaunchNotification } from "@store/slices/notification";
import { getRfiDefaultUsers } from "@store/slices/project-rfi-default-users";
import { createDistributions, getEntities as getDistributions } from "@store/slices/rfi-distribution";
import { getUsers } from "@store/slices/users-extended";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Avatar, Button, Col, Divider, Form, Modal, Row, Select, Typography, theme } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const { Option } = Select;

export interface IFollowersModalProps {
    rfiId: number,
    open: boolean,
    toggle: () => void,
    following: IUser[] | undefined,
    currentUserIsFollowing: boolean | undefined,
    followRfi: () => void,
}

export const FollowersModal = (props: IFollowersModalProps) => {

    const { rfiId, open, toggle, following, currentUserIsFollowing, followRfi } = props;
    const { token: { colorBgLayout, colorTextTertiary } } = theme.useToken();
    const { projectId } = useParams<"projectId">();
    const dispatch = useAppDispatch();
    const roleBindings = useAppSelector((state) => state.ProjectRfiDefaultUsers.roleBindings);
    const { usersList } = useAppSelector((state) => state.UsersExtended);

    const [rfiDefaultUsers, setRfiDefaultUsers] = useState<IUser[]>([]);
    const [additionalFollowers, setAdditionalFollowers] = useState<IUser[]>([])
    const [form] = Form.useForm();
    const [usersForFollowingSelect, setUsersForFollowingSelect] = useState<IUser[]>([]);

    useEffect(() => {
        if (projectId) {
            dispatch(
                getRfiDefaultUsers(projectId)
            );
            dispatch(
                getUsers()
            );
        }
    }, [projectId])

    useEffect(() => {
        if (roleBindings) {
            const users = roleBindings.map(roleBinding => roleBinding.user!)
            const distinctUsers = users.filter(
                (user, i, arr) => arr.findIndex(t => t.id === user.id) === i
            );
            setRfiDefaultUsers(distinctUsers);
        }

    }, [roleBindings])

    useEffect(() => {
        if (following && rfiDefaultUsers) {
            setAdditionalFollowers(following.filter(u => rfiDefaultUsers.find(d => d.id === u.id) === undefined))
        }
    }, [following, rfiDefaultUsers])

    useEffect(() => {
        if (usersList && rfiDefaultUsers && additionalFollowers) {
            setUsersForFollowingSelect(usersList.filter(user => rfiDefaultUsers.find(defUser => user.id === defUser.id) === undefined && additionalFollowers.find(adUser => user.id === adUser.id) === undefined));
        }

    }, [usersList, rfiDefaultUsers, additionalFollowers])

    const genericFilter = (input: string, option: any) => (
        option?.children?.toLowerCase!()?.indexOf!(input?.toLowerCase!()) >= 0
    )

    const createDistributionsSuccess = () => {
        dispatch(
            asyncLaunchNotification({
                type: "success",
                config: {
                    message: `Users successfully added`,
                    description: `A notification has been sent to them.`
                }
            })
        );
        dispatch(getDistributions(rfiId!));
        toggle();
    }

    const onFinish = (values: any): void => {
        if (values.newFollowers) {
            const distributions: IRfiDistribution[] = values.newFollowers.map((u: string) => {
                const folllowerUser = JSON.parse(u) as IUser;
                return {
                    rfiId: rfiId,
                    user: folllowerUser
                }
            });
            dispatch(
                createDistributions({ rfiId: rfiId!, distributions, onSuccess: createDistributionsSuccess })
            )
        }
    };

    useEffect(() => {
        if (open === true) {
            form.resetFields();
        }
    }, [open])

    const getUserRole = (usr: IUser): string => {
        return roleBindings?.filter(binding => binding.user?.id === usr.id).map(binding => binding.role?.name).join(", ") || "";
    }

    return (
      <Modal
        title={<Typography.Title level={4}>Following discussion</Typography.Title>}
        open={open}
        closable={true}
        centered={true}
        keyboard={false}
        onCancel={toggle}
        footer={null}
        width={'500px'}
      >
        <div style={{ maxHeight: '350px', overflowY: 'auto' }}>
          <Row>
            <Col
              span={24}
              className="mt-10 pl-10 pr-10 pt-5 pb-5 font-bold"
              style={{ border: 0, borderRadius: '4px', backgroundColor: colorBgLayout }}
            >
              Core Project Team
            </Col>
          </Row>
          <Row className="pl-10 pr-10">
            {rfiDefaultUsers?.map(user => (
              <Col xs={24} md={12} key={user.id}>
                <div className="flex flex-row items-center pt-10">
                  <div style={{ width: '32px' }}>
                    <Avatar className="cursor-default" gap={4} style={{ backgroundColor: '#B2B2B2' }}>
                      {`${user.firstName?.[0]}${user.lastName?.[0]}`.toUpperCase()}
                    </Avatar>
                  </div>
                  <div className="pl-20">
                    <div className="font-bold">{getUserDisplayName(user)}</div>
                    <div>{getUserRole(user)}</div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Row>
            <Col
              span={24}
              className="mt-20 pl-10 pr-10 pt-5 pb-5 font-bold"
              style={{ border: 0, borderRadius: '4px', backgroundColor: colorBgLayout }}
            >
              <Row className="w-full">
                <Col span={12}>Additional followers</Col>
                <Col span={12} className="text-right">
                  {currentUserIsFollowing === false && (
                    <Button type="link" onClick={followRfi} style={{ height: '22px', padding: 0, border: 0 }}>
                      + Follow this RFI
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="pl-10 pr-10">
            {additionalFollowers?.map(user => (
              <Col xs={24} md={12} key={user.id}>
                <div className="flex flex-row items-center pt-10">
                  <div style={{ width: '32px' }}>
                    <Avatar className="cursor-default" gap={4} style={{ backgroundColor: '#B2B2B2' }}>
                      {`${user.firstName?.[0]}${user.lastName?.[0]}`.toUpperCase()}
                    </Avatar>
                  </div>
                  <div className="pl-20">
                    <div className="font-bold">{getUserDisplayName(user)}</div>
                  </div>
                </div>
              </Col>
            ))}
            {additionalFollowers.length === 0 && (
              <Row className="mt-10 w-full">
                <Col span={24} className="text-center" style={{ color: colorTextTertiary }}>
                  Thre are still no additional followers for this RFI
                </Col>
              </Row>
            )}
          </Row>
        </div>
        {currentUserIsFollowing === true && (
          <>
            <Divider type="horizontal" />
            <Form layout="vertical" form={form} onFinish={onFinish}>
              <Row>
                <Col span={24} className="pl-10 pr-10">
                  <Form.Item name="newFollowers" label="Add new follower:" rules={[{ required: true, message: 'Please select a user' }]}>
                    <Select mode="multiple" allowClear optionFilterProp="children" showSearch filterOption={genericFilter}>
                      {usersForFollowingSelect?.map(user => (
                        <Option value={JSON.stringify(user)} key={user.id}>
                          {getUserDisplayName(user)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} className="text-right pl-10 pr-10">
                  <Button type="primary" onClick={() => form.submit()}>
                    Add to RFI
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </Modal>
    );
}