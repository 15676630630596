import { FC, useState, useEffect } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragHandle } from '@components/Icons/DragHandle';
import { theme } from "antd";
import "@styles/components/template-edit.less";
import { ChevronRightSvgIcon } from '@components/Icons/ChevronRightSvgIcon';
import { ChevronDownSvgIcon } from '@components/Icons/ChevronDownSvgIcon';
import { TemplateDeliverableEdit } from './TemplateDeliverableEdit';
import { ITemplateDeliverableEdit } from './TemplateEdit';
import { IPhase } from '@models/phase.model';
import { IUser } from '@models/user.model';
import { IRole } from '@models/role.model';
import { ITemplateDeliverableApproval } from '@models/template-deliverable-approval';
import SvgIconWrapper from '@shared/components/SvgIconWrapper';
import { KeySvgIcon } from '@components/Icons/KeyIcon';

interface Props {
  id: string | number;
  templateDeliverableEdit: ITemplateDeliverableEdit;
  templateDeliverables: ITemplateDeliverableEdit[];
  phases: readonly IPhase[];
  onRemove(id: any): any
  onChangeValue(id: any, field: string, value: any): any;
  allUsers: readonly IUser[];
  allRoles: readonly IRole[];
  updateApprovals(deliverableId: string, approvals: ITemplateDeliverableApproval[]): any;
  initiallyExpanded: boolean;
}

const IconKeyMilestoneInDeliverableTable = () => {
  const { token: { fontSizeSM, colorTextTertiary } } = theme.useToken();
  return (
    <SvgIconWrapper className="ml-10 flex flex-row items-center justify-center" backgroundColor={colorTextTertiary} >
      <KeySvgIcon style={{ color: '#fff', fontSize: fontSizeSM }} />
    </SvgIconWrapper>
  )
}

export const TemplateDeliverable: FC<Props> = ({ id, templateDeliverableEdit, onRemove, templateDeliverables, phases, onChangeValue, allUsers, allRoles, updateApprovals, initiallyExpanded }) => {

  const { templateDeliverable } = templateDeliverableEdit;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const [expanded, setExpanded] = useState<boolean>(initiallyExpanded)

  useEffect(() => {
    if (initiallyExpanded && !expanded) {
      setExpanded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiallyExpanded])

  const { token: {
    colorBgContainer,
    colorBorderSecondary,
    colorText,
    colorTextTertiary,
    colorWarningText,
    colorFillQuaternary } } = theme.useToken();

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  const phaseName = templateDeliverable?.phase?.id ? phases.find(x => x.id === templateDeliverable?.phase?.id)?.label : '';

  return (
    <li ref={setNodeRef} {...attributes} style={{ ...style }}>
      <div className="deliverable-in-list" style={{ border: `1px solid ${colorBorderSecondary}`, borderTop: 0 }}>
        <div className="deliverable-data-row" style={{ backgroundColor: colorFillQuaternary }}>
          <div className="deliverable-cell cell-handle" {...listeners}>
            <DragHandle />
          </div>
          <div className="deliverable-cell cell-title" onClick={toggleExpand} style={{ flex: 1, color: colorText }}>
            {expanded && <ChevronDownSvgIcon />}{!expanded && <ChevronRightSvgIcon />}<span style={{ marginLeft: '0.5rem' }}>{templateDeliverable?.deliverable?.name}</span>{templateDeliverable?.deliverable?.isKeyMilestone && <IconKeyMilestoneInDeliverableTable />}
          </div>
          <div className="deliverable-cell cell-order" style={{ color: colorText }}>
            {templateDeliverable?.order}
          </div>
          <div className="deliverable-cell cell-duration" style={{ color: colorText }}>
            {(typeof templateDeliverable.duration !== "undefined" && templateDeliverable.duration !== null && templateDeliverable.duration > 0) && `${templateDeliverable?.duration} days`}
            {(!templateDeliverable.duration) && <span><span style={{ color: colorWarningText }}>Pending</span> <span className="dot" style={{ background: colorWarningText }}></span></span>}
          </div>
          <div className="deliverable-cell cell-dependency" style={{ flex: 1, color: colorText }}>
            {templateDeliverable?.dependency?.deliverable?.name} {(templateDeliverable?.dependencyOffset ? true : false) && <span style={{ color: colorTextTertiary }}>({templateDeliverable?.dependencyOffset && templateDeliverable?.dependencyOffset > 0 ? '+' : ''}{templateDeliverable?.dependencyOffset}d offset)</span>}
          </div>
          <div className="deliverable-cell cell-phase" style={{ color: colorText }}>
            {phaseName}
            {!phaseName && <span><span style={{ color: colorWarningText }}>Pending</span> <span className="dot" style={{ background: colorWarningText }}></span></span>}
          </div>
          <div className="deliverable-cell cell-approval" style={{ color: colorText }}>
            {((templateDeliverable.approvals && templateDeliverable.approvals?.length > 0) ? 'Yes' : 'No')}
          </div>
        </div>
        {expanded && <div className="deliverable-edit-content" style={{ background: colorBgContainer, padding: '1rem' }}>
          <TemplateDeliverableEdit
            onClose={toggleExpand}
            onRemove={onRemove}
            onChangeValue={onChangeValue}
            templateDeliverableEdit={templateDeliverableEdit}
            templateDeliverables={templateDeliverables}
            phases={phases}
            allUsers={allUsers}
            allRoles={allRoles}
            updateApprovals={updateApprovals} />
        </div>}
      </div>
    </li>
  );
}
