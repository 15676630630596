import { IChangeOrderItem } from '@models/change-order-item.model';
import { http } from '../http/axios.instance';
import { StringORNumber } from './utils.repository';

export const changeOrderItemRepository = {
    getChangeOrderItems: async (changeOrderId: StringORNumber) => {
        const requestUrl = `change-orders/${changeOrderId}/items?cacheBuster=${new Date().getTime()}`;
        const promiseGetAll = await http.get<IChangeOrderItem[]>(requestUrl);
        return promiseGetAll;
    }
}