import { Badge, Col, Divider, Row, Typography, theme } from "antd";

import { CreateAlbumButton } from "./CreateAlbumButton";
import { useAppSelector } from "@store/store";

const { Title } = Typography;

interface IHeaderAlbumListProps {}

export const HeaderAlbumList = (props: IHeaderAlbumListProps) => {

    const { totalItems } = useAppSelector((store) => store.PhotoAlbum);
    const { token: { colorFillQuaternary, colorPrimary } } = theme.useToken();

    return (
        <>
            <Row className="pr-30 pl-30 pb-8 flex flex-row">        
                <Col span={24} className="flex flex-row items-center">
                    <Title className="text-color-neutral-9 mt-0 imb-0" level={4} style={{ fontWeight: 500 }}>Albums</Title>
                    <Badge
                        count={totalItems}
                        className='ml-10'
                        style={{ backgroundColor: colorPrimary, borderColor: colorFillQuaternary, paddingLeft: 5, paddingRight: 5 }} />
                    <div className="flex flex-row" style={{ position: "absolute", top: 0, right: 0 }}>
                        <CreateAlbumButton />
                    </div>
                </Col>
            </Row>
            <Row className="pr-30 pl-30">
                <Col span={24}>
                    <Divider className="mb-0 mt-9"/>
                </Col>
            </Row>
        </>
    )
}