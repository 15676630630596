import { Col, Form, Row, Select } from 'antd'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setProjectToCreate, setValidStep, Steps } from '../../../../store/slices/project-steps';
import { getEntities as getProjectStatuses } from '../../../../store/slices/project-statuses';
import { getEntities as getProjectTypes } from '../../../../store/slices/project-types';
import { AppDispatch, useAppSelector } from '../../../../store/store';
import { contentStyleSteps } from '../AddProject';
import { IFormStep1 } from '../../../../models/project-model';

export const Step1 = () => {

  const dispatch = useDispatch<AppDispatch>();

  const { entities: projectTypes } = useAppSelector((state) => state.ProjectTypes);
  const { entities: projectStatuses } = useAppSelector((state) => state.ProjectStatuses);

  useEffect(() => {
    dispatch(getProjectTypes({}));
    dispatch(getProjectStatuses({}));
  }, [dispatch])

  const [form] = Form.useForm();

  const onFinish = (entity: IFormStep1): void => {
    dispatch(setValidStep({ step: Steps.STEP1, modification: { isValid: true } }));
    dispatch(setProjectToCreate({
      projectTypeId: entity.type,
      projectStatusId: entity.status
    }))
  };

  const onFinishFailed = () => {
    dispatch(setValidStep({ step: Steps.STEP1, modification: { isValid: false } }))
  };

  // https://github.com/ant-design/ant-design/issues/26747
  // Ant desing has some issues when we want to validate in onchange form directly 
  // in this case is better to use form.submit() for validatins and manage steps validations in onFinish
  const checkValidations = () => form.submit()

  return (
    <div className='ml-30 mr-30' style={contentStyleSteps}>
      <span className="flex text-bold pr-30 pt-20 pb-30" style={{ fontSize: '16px' }}>Add Project Details</span>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row>
          <Col span={24}>
            <Form.Item name="type" label="Type" rules={[
              { required: true, message: "" }
            ]}>
              <Select
                onChange={checkValidations}
                placeholder="Please select an Item"
                options={projectTypes?.map!((item) => ({ value: item.id, label: item?.name }))}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name="status" label="Status" rules={[
              { required: true, message: "" }
            ]}>
              <Select
                onChange={checkValidations}
                placeholder="Please select an Item"
                options={projectStatuses.map((item) => ({ value: item.id, label: item?.name }))}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </div>
  )
}
