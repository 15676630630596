import { geekblue } from '@ant-design/colors';
import { CustomBox } from '@components/Utils/CustomBox';
import { theme } from 'antd';
import { useTranslation } from 'react-i18next';

interface ILocationStatus {
  label: string;
  backgroundColor: string;
  borderColor: string;
  textColor: string;
}

type ILOCATION_STATUS_MAP = {
  PLANNING: ILocationStatus;
  ACTIVE: ILocationStatus;
  OPEN: ILocationStatus;
  CLOSED: ILocationStatus;
};

export type ILOCATION_STATUS_MAP_KEYS = keyof ILOCATION_STATUS_MAP;

export const StatusCell = ({ status }: { status: ILOCATION_STATUS_MAP_KEYS }) => {
  const {
    token: {
      colorWarningBorder,
      colorWarningTextActive,
      colorWarningBg,
      colorSuccessTextActive,
      colorSuccessBorder,
      colorSuccessBg,
      colorTextTertiary,
      colorFillSecondary,
      colorFillTertiary,
    },
  } = theme.useToken();

  const { t } = useTranslation();

  const LOCATION_STATUS_MAP = {
    PLANNING: {
      label: t('location.statuses.planning'),
      backgroundColor: colorWarningBg,
      borderColor: colorWarningBorder,
      textColor: colorWarningTextActive,
    },
    ACTIVE: {
      label: t('location.statuses.active'),
      backgroundColor: geekblue[0],
      borderColor: geekblue[2],
      textColor: geekblue[6],
    },
    OPEN: {
      label: t('location.statuses.open'),
      backgroundColor: colorSuccessBg,
      borderColor: colorSuccessBorder,
      textColor: colorSuccessTextActive,
    },
    CLOSED: {
      label: t('location.statuses.closed'),
      backgroundColor: colorFillTertiary,
      borderColor: colorFillSecondary,
      textColor: colorTextTertiary,
    },
  };

  return (
    <CustomBox
      style={{
        maxHeight: '1.75rem',
        backgroundColor: LOCATION_STATUS_MAP[status].backgroundColor,
        border: `1px solid ${LOCATION_STATUS_MAP[status].borderColor}`,
      }}
    >
      <span className="pt-4 pb-4 pl-8 pr-8 select-none" style={{ color: LOCATION_STATUS_MAP[status].textColor }}>
        {LOCATION_STATUS_MAP[status].label}
      </span>
    </CustomBox>
  );
};
