import { IBrand } from '../../models/brand.model';
import { IQueryParams } from '../../models/pagination';
import { http } from '../http/axios.instance';
import { StringORNumber } from './utils.repository';

const path = 'brands';

export const brandRepository = {
  getBrands: async (queryParams: IQueryParams) => {
    
    const { sort, page, size } = queryParams;
    const requestUrl = `${path}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  
    const brands = await http.get<IBrand[]>(requestUrl);
    return brands;
  },
  getBrandById: async (id: StringORNumber) => {

    const requestUrl = `${path}/${id}`;
    const brand = await http.get<IBrand>(requestUrl);
    return brand;
  },
  createBrand: async (entity: IBrand): Promise<IBrand> => {

    const requestUrl = `${path}`;
    const brand = await http.post<IBrand>(requestUrl, entity);
    return brand.data;
  },
  updateBrand: async (id: string | number, entity: IBrand): Promise<IBrand> => {

    const requestUrl = `${path}/${id}`;
    const brand = await http.put<IBrand>(requestUrl, entity);
    return brand.data;
  },
  deleteBrand: async (id: string | number) => {

    const requestUrl = `${path}/${id}`;
    const brand = await http.delete<IBrand>(requestUrl);
    return brand;
  },
}