import { Helmet } from 'react-helmet-async';
import { configuration } from '../environments/env';
import { ChangePassword } from '@components/Admin/PasswordReset/ChangePassword';
import { ContainerErrorAnimation } from '@components/Utils/ContainerErrorAnimation';
import { AccountActionTypeEnum } from '@models/enumerations/account-action-type.enum';

export default function ResetPasswordPage() {
  return (
    <>
      <Helmet>
        <title>Reset Password | { configuration.APP_NAME }</title>
      </Helmet>
      <ContainerErrorAnimation>
        <ChangePassword typeAction={AccountActionTypeEnum.RESET_PASSWORD} />
      </ContainerErrorAnimation>
    </>
  )
}