import { createAsyncThunk, createSlice, isPending } from '@reduxjs/toolkit';
import { IQueryParamsGeneric } from '@models/utils';
import { locationService } from '@services/locations.service';
import { ILocation } from '@models/location.model';
import { serializeGenericHandleError } from '@store/thunk.util';

export const getLocationsExtended = createAsyncThunk(
    `locations/getLocationsExtended`,
    async (queryParams: IQueryParamsGeneric, thunkAPI) => {
      const result = await locationService.getLocations(queryParams);
      return result;
    },
    { serializeError: serializeGenericHandleError }
)

interface IinitialState {
  loading: boolean,
  locationsList: ILocation[]
}
const initialState: IinitialState = {
  loading: false,
  locationsList: []
};

export const slice = createSlice({
  name: 'locations_extended',
  initialState,
  reducers: {
    clearCollections: (state,) => {
      return initialState
    },
  },
  extraReducers(builder) {
    builder
      //  getLocations
      .addCase(getLocationsExtended.fulfilled, (state, action) => {
        state.loading = false;
        state.locationsList = action.payload.data;
      })
      .addMatcher(isPending(getLocationsExtended), state => {
        state.loading = true;
      });
  }
});

export const { clearCollections } = slice.actions;

// Reducer
export default slice.reducer;

