import { useLayoutEffect } from "react";
import { Button, theme, Typography } from "antd";
import { Header } from "antd/es/layout/layout";
import { useResizeDetector } from "react-resize-detector";
import { UserMenu } from "../../UserMenu/UserMenu";
import { BreadcrumbNavigation, IBreadcrumbNavigationProps } from '../BreadcrumbNavigation';
import { useLayoutContex } from "../../../providers/LayoutProvider";
import { MoreOutlined } from "@ant-design/icons";
import { EmptyLabel } from "../../Utils/EmptyLabel";
import { Search } from "./Search";
import { Support } from './Support';

export interface HeaderProps extends React.ComponentPropsWithoutRef<typeof Header> {}

export interface MainHeaderProps {
  headerProps?: HeaderProps;
  breadcrumbProps?: IBreadcrumbNavigationProps;
}

export const MainHeader = (props: MainHeaderProps) => {
  const { headerProps = {}, breadcrumbProps } = props;

  const {
    token: { colorBgContainer, marginLG, margin, colorTextSecondary },
  } = theme.useToken();

  const { height: headerHeight, ref: headerRef } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 50,
  });

  const { headerLabelEntity, setHeaderSizeState } = useLayoutContex();

  useLayoutEffect(() => {
    setHeaderSizeState && setHeaderSizeState(headerHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerHeight]);

  return (
    <Header
      ref={headerRef}
      className="flex flex-col justify-between pt-0 pb-0 pl-0 pr-0 h-auto"
      style={{ background: colorBgContainer }}
      {...headerProps}
    >
      <div className="flex flex-row justify-between" style={{ width: '100%' }}>
        <div className="flex flex-col" style={{ marginLeft: `${marginLG}px`, marginTop: `${margin}px` }}>
          <div>
            <BreadcrumbNavigation {...breadcrumbProps} />
          </div>
        </div>
        <div className="flex flex-row">
          <div className="realtive mr-20" style={{ height: '4rem', float: 'right', textAlign: 'end' }}>
            <Search />
          </div>
          <div className="realtive h-auto mt-5 mr-20">
            <Support />
          </div>
          <div className="h-auto">
            <UserMenu />
          </div>
        </div>
      </div>
      <div className="flex flex-row" style={{ marginLeft: `${marginLG}px` }}>
        <Typography.Title
          level={3}
          className="h-auto"
          style={{
            marginBottom: `${margin}px`,
            color: colorTextSecondary,
          }}
        >
          {headerLabelEntity || <EmptyLabel style={{ opacity: 0 }} />}
        </Typography.Title>
        <div id="header-tags"></div>
        {headerLabelEntity && <Button icon={<MoreOutlined />} hidden={true} className="ml-20" size="small" />}
      </div>
    </Header>
  );
};
