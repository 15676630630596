/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch, useAppSelector } from "@store/store";
import { PaginationDefaultState } from "./PaginationDefaultState"
import { useEffect, useState } from "react";
import { isArrayWithValues } from "@shared/util/array-util";
import { If } from "@components/Utils/Structural";
import { PunchListPagination } from "./PunchListPagination";
import { IPunchListItemPage } from "@models/punch-list.model";
import { getPuchListItems, selectPage, setPageById } from "@store/slices/punch-list";
import { useParams } from "react-router-dom";

export const HeaderPunchListSidePanel = () => {

    const dispatch = useAppDispatch();

    const { punchListPages } = useAppSelector(store => store.PunchList);

    const [pages, setPages] = useState<IPunchListItemPage[] | []>([]);

    const { pageId } = useParams<"pageId">();

    useEffect(() => {
      if (isArrayWithValues(punchListPages)) {
        setPages(punchListPages);
        if (pageId) {
          pageId && dispatch(setPageById(pageId));
          pageId && dispatch(getPuchListItems({ pageId: pageId }));
        } else {
          dispatch(selectPage(punchListPages[0]));
        }
      }
    
    }, [punchListPages])
    
    return (
      <>
        <If condition={Boolean(isArrayWithValues(pages))}>
          <PunchListPagination />
        </If>
        <If condition={!isArrayWithValues(pages)}>
          <PaginationDefaultState />
        </If>
      </>
    )
}
