
import { Helmet } from 'react-helmet-async';
import { configuration } from '../environments/env';
import { Badge, Card, Col, Row, Tooltip, theme } from 'antd';
import { SITERISE_BG_WHITE_COLOR, TERTIARY_TEXT_COLOR, WHITE_ABSOLUTE } from '@providers/ThemeProvider';
import { InfoCircleOutlined } from '@ant-design/icons';
import { detectBrowserAndOS } from '@shared/util/platform';
import { OFFICIAL_WEBSITE_SITERISE_URL } from '@constants/core';

export default function AboutSiteRiseApp() {

  const userAgent = window.navigator.userAgent;
  const { browser, os } = detectBrowserAndOS(userAgent);

  const { token } = theme.useToken()

  const title = (
    <div className='flex flex-row items-center justify-between' style={{ height: 130 }}>
        <div>
          <div className='flex flex-row items-center '>
            <img style={{ width: 25, height: 25 }} src={"/media/client/siterise/dark_icon_site_rise.png"} alt="" />
            <span className='ml-10 mr-15'>SiteRise App</span>
            <Tooltip title="Go to the official website of SiteRise">
              <a href={OFFICIAL_WEBSITE_SITERISE_URL} target="_blank" rel="noreferrer" style={{ color: "#fff"}}> 
                <InfoCircleOutlined className="cursor-pointer" />
              </a>
            </Tooltip>
          </div>
        </div>
        <div>
          <img alt='' src={"/media/client/siterise/SiteriseCityLocation.svg"} className="absolute mt-15 mr-30 pt-3 top-0 right-0" style={{ width: 180, zIndex: 2 }} />
        </div>
    </div>
  )

  const BadgeRibbonTitle = (
    <div className='pt-2 pb-2 pl-5 pr-10'>
      <span className="flex flex-row items-center" >
        <span className="flex rounded-full mr-5" style={{ width: 5, height: 5, backgroundColor: "green" }}></span>
        <span style={{ fontSize: 11 }}>Running</span>
      </span>
    </div>
  )

  return (
    <>
      <Helmet>
        <title>About SiteRise App | { configuration.APP_NAME }</title>
      </Helmet>
      <Row className='flex flex-row items-center relative w-screen h-screen' style={{ background: SITERISE_BG_WHITE_COLOR }} >
          <Col span={24} className='flex flex-row items-center justify-center w-full'>

            <Badge.Ribbon text={BadgeRibbonTitle} color="green">
              <Card 
                title={title} 
                bordered={false}
                style={{ width: 430, borderRadius: 4 }}
                styles={{
                  header: { backgroundColor: TERTIARY_TEXT_COLOR, color: WHITE_ABSOLUTE, borderTopLeftRadius: 4, borderTopRightRadius: 4 }
                }}
              >
                <p className='mt-10 pb-0 pt-0 mb-5'>
                  <span className='font-medium mr-5'>Client:</span>
                  <span className='font-light' style={{ color: token.colorTextSecondary, fontSize: token.fontSizeSM}}> { configuration.APP_NAME } </span> 
                </p>
                <p className='pb-0 pt-0 mb-5'>
                  <span className='font-medium mr-5' >Version:</span>
                  <span id="about_version_number" data-testId="aboutNumberVersion" className='mr-3 font-semibold' style={{ color: token.colorTextSecondary, fontSize: token.fontSizeSM}} >{ configuration.VERSION }</span>
                  <span className='font-light' style={{ color: token.colorTextSecondary, fontSize: token.fontSizeSM}}>(Official Build)</span>
                </p>
                <p className='pb-5 pt-0 mb-20'>
                  <span className='font-medium mr-5'>Running on:</span> 
                  <span className='font-light' style={{ color: token.colorTextSecondary, fontSize: token.fontSizeSM}}>{os} - { browser }</span> 
                </p>
                <p className='font-light text-center pb-0 pt-0 mb-15' style={{ fontSize: 12, color: token.colorBgSpotlight }}>Copyright (C) { new Date()?.getFullYear() }, The SiteRise Development Team</p>
              </Card>
            </Badge.Ribbon>

          </Col>
      </Row>
    </>
  )
}
