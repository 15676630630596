import useVisible from "@HOOKs/UseVisible";
import { CustomButtonText } from "@components/Utils/CustomButtonText";
import { useTranslation } from "react-i18next";
import { IPunchListItem } from "@models/punch-list.model";
import { UploadFilePunchListItemModal } from "./UploadFilePunchListItemModal";
import { FileAddOutlined } from "@ant-design/icons";
import { theme } from "antd";

interface IUploadFilePunchListItemProps {
    punchListItem: IPunchListItem
}

export const UploadFilePunchListItem = (props: IUploadFilePunchListItemProps) => {

    const { punchListItem } = props;

    const [ openFileUploadModal, toogleFileUploadModal ] = useVisible();

    const { token: { colorPrimaryActive } } = theme.useToken();
    
    const { t } = useTranslation();

    const onOpenModalUpload = () => {
        toogleFileUploadModal();
    }

    return (
        <>
            <CustomButtonText onClick={onOpenModalUpload} className="w-full">
                <FileAddOutlined style={{ color: colorPrimaryActive }} />
                {t("attachment.uploadFile")}
            </CustomButtonText>
            <UploadFilePunchListItemModal 
                punchListItem={punchListItem} 
                openModal={openFileUploadModal} 
                toogleModal={toogleFileUploadModal} 
            />
        </>
    )
}
