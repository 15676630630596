import useVisible from "@HOOKs/UseVisible";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { WHITE, useThemeProvider } from "@providers/ThemeProvider";
import { Col, Drawer, Row } from "antd";
import { useEffect } from "react";

import { MobileNavigation } from "./MobileNavigation";
import { MainMenu } from "../SideMenu/Menu";

export const MobileHeaderMenu = () => {

    const { themeConfig } = useThemeProvider();

    const [visibleDrawerMobileMenu, setVisibleDrawerMobileMenu] = useVisible()

    useEffect(() => {
    }, [])
    
    return (
        <div className="flex flex-row items-center justify-between" style={{ backgroundColor: themeConfig.secondaryColor, color: WHITE }}>
            <div className="pl-15">
                <MobileNavigation />
            </div>
            <div className="pt-15 pb-15 pr-15">
                <MenuOutlined className="cursor-pointer" onClick={setVisibleDrawerMobileMenu} style={{ fontSize: 24 }} />

                <Drawer 
                    open={visibleDrawerMobileMenu} 
                    closable={false}
                    width={"75%"}
                    onClose={setVisibleDrawerMobileMenu}
                    styles={{
                        header: { padding: 0 }
                    }}
                    title={
                        <div className="flex flex-row items-center justify-between" style={{ backgroundColor: themeConfig.secondaryColor, color: WHITE }}>
                            <div className="pl-15">
                            </div>
                            <div className="pt-15 pb-15 pr-15">
                                <CloseOutlined className="cursor-pointer" onClick={setVisibleDrawerMobileMenu} style={{ fontSize: 24 }} />
                            </div>
                        </div>
                    }
                    bodyStyle={{ padding: 0 }}
                    style={{ backgroundColor: themeConfig.secondaryColor, color: WHITE }}
                    
                >   
                    <Row className="mt-16">
                        <Col span={24}>
                            <MainMenu menuItemSelectedPlacement="left" />
                        </Col>
                    </Row>
                </Drawer>
            </div>
        </div>
    )
}
