export const isNumberGreaterThanZero = (value: unknown) => {
  return typeof value === 'number' && value >= 0;
};

export const hasIntegerValue = (value: unknown) => {
  return Number.isInteger(value);
};

export const isNumber = (value: any) => {
  return !isNaN(value) && typeof value === 'number';
};

export const hasNotIntegerValue = (value: unknown) => {
  return !Number.isInteger(value);
};

export const isEven = (value: number) => {
  return value % 2 === 0;
};

export const isOdd = (value: number) => {
  return value % 2 !== 0;
};

export const isIDValid = (value: any) => {
  if (typeof value === 'string') {
    return !!value;
  }
  if (typeof value === 'number') {
    return hasIntegerValue(value);
  }
  return !!value;
};

export const isJsonValid = (value: string) => {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
};

/**Formats a number string with comma as thousands divider and two digits after fractional dot */
export const normalizeNumberString = (value: string): string => {
  const number = parseFloat(value);

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(number);
};

/**Removes commas from normalized number string so it can be sent as valid payload to the backend */
export const denormalizeNumberString = (value: string): string => {
  return value.replace(/,/g, '');
};

export const isValidNumberString = (value: string) => {
  const cleanedString = value.replace(/,/g, '');

  const number = parseFloat(cleanedString);

  return !isNaN(number) && isFinite(number);
};
