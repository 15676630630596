import { Image } from 'antd'
import React from 'react'
import { configuration } from '../../environments/env';

const LOGO = configuration.LOGO_PATH_URL;
const LOGO_DARK = configuration.LOGO_PATH_URL_DARK;
const BACKGROUND_COLOR_IN_DARK_LOGO = configuration.BACKGROUND_COLOR_IN_DARK_LOGO;

interface IProps {
  classStyle?: string;
  isCollapsed?: boolean;
}


export const Logo: React.FC<IProps> = ({ classStyle, isCollapsed = false }) => {
  return (
    <Image
      src={isCollapsed ? LOGO : LOGO_DARK}
      className={classStyle}
      style={{ backgroundColor: isCollapsed ? BACKGROUND_COLOR_IN_DARK_LOGO : 'transparent', maxWidth: '8rem', maxHeight: '5rem', objectFit: 'contain' }}
      preview={false}
    />
  )
}
