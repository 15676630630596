// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.floor-plan-editor-board {
  height: 100%;
  width: 100%;
  /* CSS to prevent text selection */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, supported by modern browsers */
}

.floor-plan-editor-container {
  height: 100%;
  position: relative;
  color: currentColor;
}

.floor-plan-editor-control-button {
  position: absolute;
  cursor: pointer;
  color: currentColor;
  min-width: 90px;
  right: 10px;
}
.floor-plan-editor-asset-button {
  position: absolute;
  cursor: pointer;
  color: currentColor;
  min-width: 90px;
  right: 110px;
}
.selected-button {
  background-color: #d7d7d7;
}

.floor-plan-editor-container text,
.floor-plan-editor-container button {
  font-family: Arial;
}

svg > g:first-child:focus {
  outline: none;
  border: none;
}

.minimap {
  position: absolute;
  width: 130px;
  height: 130px;
  border: 1px solid currentColor;
  right: 0;
  bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,kCAAkC;EAClC,2BAA2B,EAAE,eAAe;EAC5C,yBAAyB,EAAE,WAAW;EACtC,wBAAwB,EAAE,mBAAmB;EAC7C,sBAAsB,EAAE,4BAA4B;EACpD,qBAAqB,EAAE,2BAA2B;EAClD,iBAAiB,EAAE,uDAAuD;AAC5E;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,eAAe;EACf,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,eAAe;EACf,YAAY;AACd;AACA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,QAAQ;EACR,SAAS;AACX","sourcesContent":[".floor-plan-editor-board {\n  height: 100%;\n  width: 100%;\n  /* CSS to prevent text selection */\n  -webkit-touch-callout: none; /* iOS Safari */\n  -webkit-user-select: none; /* Safari */\n  -khtml-user-select: none; /* Konqueror HTML */\n  -moz-user-select: none; /* Old versions of Firefox */\n  -ms-user-select: none; /* Internet Explorer/Edge */\n  user-select: none; /* Non-prefixed version, supported by modern browsers */\n}\n\n.floor-plan-editor-container {\n  height: 100%;\n  position: relative;\n  color: currentColor;\n}\n\n.floor-plan-editor-control-button {\n  position: absolute;\n  cursor: pointer;\n  color: currentColor;\n  min-width: 90px;\n  right: 10px;\n}\n.floor-plan-editor-asset-button {\n  position: absolute;\n  cursor: pointer;\n  color: currentColor;\n  min-width: 90px;\n  right: 110px;\n}\n.selected-button {\n  background-color: #d7d7d7;\n}\n\n.floor-plan-editor-container text,\n.floor-plan-editor-container button {\n  font-family: Arial;\n}\n\nsvg > g:first-child:focus {\n  outline: none;\n  border: none;\n}\n\n.minimap {\n  position: absolute;\n  width: 130px;\n  height: 130px;\n  border: 1px solid currentColor;\n  right: 0;\n  bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
