/* eslint-disable react-hooks/exhaustive-deps */
import { ProjectDeliverablesTabLayout } from './ProjectDeliverablesTabLayout';
import { ProjectDeliverableListContainer } from './ProjectDeliverableList/ProjectDeliverableListContainer';
import { DeliverableDetailsContainer } from './DeliverableDetails/DeliverableDetailsContainer';
import { ProjectDeliverableFilterContainer } from './ProjectDeliverableFilters/ProjectDeliverableFilterContainer';

export const ProjectDeliverablesTab = () => {
    return (
        <>
            <ProjectDeliverablesTabLayout 
                filterArea={<ProjectDeliverableFilterContainer />}
                listArea={<ProjectDeliverableListContainer />}
                detailsArea={<DeliverableDetailsContainer />}
            />
        </>
    )
}