import { IProject } from './project-model';
import { ITemplateDeliverable } from './template-deliverable.model';

export interface IProjectTemplate {
  id?: number;
  name?: string;
  active?: boolean;
}
export interface IProjectTemplateExtended {
  id?: number;
  name?: string;
  active?: boolean;
  deliverableCount?: number;
}
export interface IProjectTemplateSave {
  id?: number;
  name?: string;
  active?: boolean;
  templateDeliverables?: ITemplateDeliverable[]
}

export interface IProjectCreationForm {
  projectTypeId: number | null;
  projectStatusId: number | null;
  templateId: number | null;
  roleBindingList: RoleBinding[];
}

export type goToProject = (id: number | string) => void;

export interface RoleBinding {
  user: {
    id: number;
  };
  role: {
    id: number;
  };
}

export interface IRole {
  id: number;
  name: string;
}


export const defaultValue: Readonly<IProject> = {};
export const defaultTemplateValue: Readonly<IProjectTemplate> = {};
