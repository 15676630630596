import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '@store/store';
import { Button, Col, Divider, Drawer, Form, Popconfirm, Row, Select, Space, Switch, Tag, Typography, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@store/store';
import { IQueryParams } from '@models/pagination';
import { ITeamMemberItemCustom } from '@models/project-model';
import { CompanyReferenceEnumListOptions } from '@models/enumerations/company-reference-enum.model';
import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { DeleteOutlined } from '@ant-design/icons';
import { getRoles } from '@store/slices/role';
import { ICreateTeamMember, createTeamMember, deleteTeamMember, updateTeamMember } from '@store/slices/project-teams-extended';
import { isFulfilled } from '@reduxjs/toolkit';
import { getPermisions } from '@store/slices/permisions';
const { Text } = Typography;

interface ITeamManageProps extends IQueryParams {
  open: boolean;
  toggle: () => void;
  referenceType: CompanyReferenceEnumListOptions;
  getTeam: () => void;
  referenceId?: StringORNumber;
  memberSelected?: ITeamMemberItemCustom;
  setMemberSelected?: (member: ITeamMemberItemCustom | null) => void;
}
interface ITeamMemberFormData {
  role?: number;
  user?: number;
}

export const AddOrEditTeamMember = (props: ITeamManageProps) => {
  const { open, toggle, memberSelected, setMemberSelected, referenceType, referenceId, getTeam } = props;

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  /** Reference initial values to observe changes and enable save button accordingly */
  const initialRoleId = useRef(memberSelected?.role?.id ?? null);
  const initialUserId = useRef(memberSelected?.user?.id ?? null);
  const initialPrimary = useRef(memberSelected?.primary ?? false);

  const [form] = Form.useForm();

  const [selectedRoleId, setSelectedRoleId] = useState<number | null>(memberSelected?.role?.id ?? null);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(memberSelected?.user?.id ?? null);
  const [primary, setPrimary] = useState<boolean>(memberSelected?.primary || false);

  const changesExist =
    selectedRoleId !== initialRoleId.current || selectedUserId !== initialUserId.current || primary !== initialPrimary.current;

  const { entity: Project } = useAppSelector(state => state.Project);
  const { usersList } = useAppSelector(state => state.UsersExtended);
  const { entities: rolesList } = useAppSelector(state => state.Roles);
  const { data: projectTeamMembers } = useAppSelector(state => state.ProjectTeamsExtended);

  const projectTeam = projectTeamMembers.coreMembers.concat(projectTeamMembers.dynamicMembers);

  const existingPrimary =
    selectedRoleId !== null && projectTeam.find(member => member.role.id === selectedRoleId && member.primary && member.user?.id);

  const {
    token: { colorWarningText, colorFillSecondary, colorFillTertiary, colorPrimaryText, colorTextSecondary, colorTextQuaternary },
  } = theme.useToken();

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  /** Filter options according to user selection*/
  const userOptions = usersList
    .filter(user =>
      selectedRoleId ? !projectTeam.some(member => member.role?.id === selectedRoleId && member.user?.id === user.id) : true
    )
    .map(elem => {
      return { label: elem.fullName, value: elem.id };
    })
    .concat(memberSelected?.user?.id ? [{ label: memberSelected.user?.fullName, value: memberSelected.user?.id }] : []);

  /** Filter options according to user selection*/
  const roleOptions = memberSelected
    ? [{ label: memberSelected.role.name, value: memberSelected.role.id }]
    : rolesList
        .filter(role =>
          selectedUserId ? !projectTeam.some(member => member.user?.id === selectedUserId && member.role?.id === role.id) : true
        )
        .map(elem => {
          return { label: elem.name, value: elem.id };
        });

  const updateTeamMemberCall = (data: ITeamMemberFormData) => {
    const payload = {
      id: `${memberSelected!.roleBindingId}`,
      referenceId: Number(referenceId!),
      referenceType,
      primary,
      user: {
        id: data.user || memberSelected?.user?.id,
      },
      role: {
        id: data.role,
      },
    };
    dispatch(updateTeamMember({ payload: payload, projectId: `${Project.id}`, roleBindingId: `${memberSelected!.roleBindingId}` })).then(
      onSuccess
    );
  };
  const createMemberCall = (data: ITeamMemberFormData) => {
    const payload: ICreateTeamMember = {
      referenceId: Number(referenceId),
      referenceType,
      primary,
      user: { id: data.user },
      role: { id: data.role },
    };
    dispatch(createTeamMember({ payload, projectId: `${Project.id}` })).then(onSuccess);
  };
  const deleteMemberCall = () => {
    dispatch(deleteTeamMember({ projectId: `${Project.id}`, roleBindingId: `${memberSelected!.roleBindingId}` })).then(onSuccess);
  };

  const onFinish = (data: ITeamMemberFormData): void => {
    if (memberSelected) {
      if (!memberSelected.user?.id) {
        createMemberCall(data);
      } else {
        updateTeamMemberCall(data);
      }
    } else {
      createMemberCall(data);
    }
  };

  const onSave = () => {
    form.submit();
  };

  const onSuccess = (response: any) => {
    if (isFulfilled(response)) {
      clearForm();
      toggle();
      dispatch(getPermisions());
      getTeam();
    }
  };
  const onChangeRole = (value: number | undefined) => {
    form.setFieldsValue({ role: value });

    if (!value) {
      setSelectedRoleId(null);
    } else {
      setSelectedRoleId(value);
    }
  };

  const onChangeUser = (value: number | undefined) => {
    form.setFieldsValue({ user: value });

    if (!value) {
      setSelectedUserId(null);
    } else {
      setSelectedUserId(value);
    }
  };

  const deleteRoleBinding = () => {
    if (memberSelected) deleteMemberCall();
  };

  const clearForm = () => {
    setMemberSelected && setMemberSelected(null);
    setSelectedRoleId(null);
    setSelectedUserId(null);
    form.resetFields();
  };

  const RoleOccupiedMessage = () => {
    if (existingPrimary && ((memberSelected && memberSelected.user?.id !== existingPrimary.user?.id) || !memberSelected))
      return <Text style={{ color: colorWarningText, fontSize: '14px' }}>{t('dashboard.teamManage.roleOccupied')}</Text>;
    return null;
  };

  const PrimaryContactMessage = () => {
    if (existingPrimary && !primary && (!memberSelected || memberSelected.user?.id !== existingPrimary.user?.id))
      return (
        <Text style={{ color: colorTextQuaternary, fontSize: '14px' }}>
          {t('dashboard.teamManage.primaryContact')}: {existingPrimary.user?.fullName}
        </Text>
      );
    return null;
  };

  const UserWillBeRemovedMessage = () => {
    if (existingPrimary && primary && (!memberSelected || (memberSelected && !memberSelected.primary)))
      return (
        <Text style={{ color: colorTextQuaternary, fontSize: '14px' }}>
          {existingPrimary.user?.fullName} {t('dashboard.teamManage.willBeRemovedAsPrimary')}
        </Text>
      );
    if (memberSelected?.user?.id && memberSelected?.primary && (!primary || selectedUserId !== memberSelected.user?.id))
      return (
        <Text style={{ color: colorTextQuaternary, fontSize: '14px' }}>
          {memberSelected.user?.fullName} {t('dashboard.teamManage.willBeRemovedAsPrimary')}
        </Text>
      );
    return null;
  };

  const NewContactMessage = () => {
    if (
      (!memberSelected && primary && !existingPrimary && selectedUserId && selectedRoleId) ||
      (memberSelected && !memberSelected.user?.id && selectedUserId)
    )
      return (
        <Text style={{ color: colorTextQuaternary, fontSize: '14px' }}>
          {usersList.find(u => u.id === selectedUserId)?.fullName} {t('dashboard.teamManage.willBeNewPrimary')}
        </Text>
      );
    return null;
  };

  return (
    <>
      <Drawer
        title={
          memberSelected && memberSelected.user?.id
            ? `${t('generic.edit')} ${t('dashboard.teamManage.teamMember')}`
            : `New ${t('dashboard.teamManage.teamMember')}`
        }
        width={500}
        onClose={() => {
          clearForm();
          toggle();
        }}
        open={open}
        closable={false}
        styles={{
          body: { paddingBottom: 80 },
        }}
        extra={
          <Space>
            {selectedRoleId && selectedUserId ? (
              <Popconfirm
                placement="bottomRight"
                title={
                  <>
                    {t('dashboard.teamManage.ifYouClose')}
                    <br />
                    {t('dashboard.teamManage.wontSave')}
                  </>
                }
                okText={t('generic.accept')}
                onConfirm={() => {
                  clearForm();
                  toggle();
                }}
                cancelText={t('generic.cancel')}
              >
                <Button type="primary" ghost>
                  {t('generic.cancel')}
                </Button>
              </Popconfirm>
            ) : (
              <Button
                type="primary"
                ghost
                onClick={() => {
                  clearForm();
                  toggle();
                }}
              >
                {t('generic.cancel')}
              </Button>
            )}
            <Button type="primary" onClick={onSave} disabled={!changesExist || !form.getFieldValue('role') || !form.getFieldValue('user')}>
              {memberSelected ? t('generic.save') : t('generic.add')}
            </Button>
          </Space>
        }
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          initialValues={
            memberSelected
              ? {
                  role: selectedRoleId,
                  user: selectedUserId,
                }
              : {}
          }
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item shouldUpdate name="role" label={t('dashboard.teamManage.role')} rules={[{ required: true }]}>
                <Select
                  disabled={Boolean(memberSelected)}
                  placeholder={`${t('generic.select')} ${t('dashboard.teamManage.role')}`}
                  options={roleOptions}
                  showSearch
                  onChange={onChangeRole}
                  allowClear
                  filterOption={(inputValue, option) => option!.label!.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                />
              </Form.Item>
              <Form.Item
                shouldUpdate
                name="user"
                label={`${t('generic.select')} ${t('dashboard.teamManage.user')}`}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={`${t('generic.select')} ${t('dashboard.teamManage.user')}`}
                  options={userOptions}
                  showSearch
                  onChange={onChangeUser}
                  allowClear
                  filterOption={(inputValue, option) => option!.label!.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Space direction="vertical" className="w-full">
            <RoleOccupiedMessage />
            <div
              className="flex justify-between p-12"
              style={{ border: `1px solid ${colorFillSecondary}`, borderRadius: '4px', backgroundColor: colorFillTertiary }}
            >
              <Text style={{ color: primary ? colorPrimaryText : colorTextSecondary, fontSize: '14px' }}>
                {primary ? t('dashboard.teamManage.primaryMember') : t('dashboard.teamManage.setAsPrimary')}
              </Text>
              <Switch
                value={memberSelected && !memberSelected.user?.id ? true : primary}
                onChange={setPrimary}
                disabled={memberSelected && !memberSelected.user?.id}
              />
            </div>
            <PrimaryContactMessage />
            <UserWillBeRemovedMessage />
            <NewContactMessage />
          </Space>
        </Form>
        {memberSelected?.user && (
          <>
            <Divider />
            <Popconfirm
              placement="bottomRight"
              title={
                <>
                  {t('dashboard.teamManage.areYouSure')}
                  <br />
                  {t('dashboard.teamManage.forThisProject')}
                </>
              }
              okText={t('dashboard.teamManage.confirmAndRemove')}
              onConfirm={() => {
                deleteRoleBinding();
              }}
              cancelText={t('generic.cancel')}
              okButtonProps={{ danger: true }}
              icon={false}
            >
              <Tag
                className="flex justify-center cursor-pointer"
                style={{ width: '100%', borderRadius: '5px', fontSize: '0.9rem' }}
                color="error"
              >
                <div className="mt-5 mb-5">
                  <DeleteOutlined className="mr-8 scale-120" />
                  {t('dashboard.teamManage.removeThis')}
                </div>
              </Tag>
            </Popconfirm>
          </>
        )}
      </Drawer>
    </>
  );
};
