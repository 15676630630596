import { ICompany } from './company.model';
import { CompanyReferenceEnum, CompanyReferenceEnumListOptions } from './enumerations/company-reference-enum.model';
import { ICompanyContact } from './company.contact.model';
import { IQueryParams } from './pagination';

export interface ICompanyRole {
  id?: number;
  description?: string | null;
}

export interface ICompanyBinding {
  id?: number;
  referenceId?: number | string | null;
  referenceType?: CompanyReferenceEnum | null;
  company?: ICompany | null;
  companyRole?: ICompanyRole | null;
  companyContact?: ICompanyContact | null;
}

export interface ICompanyExtended {
  id?: number;
  name?: string | null;
  contacts?: ICompanyContact[] | null;
}
export interface ICompanyBindingExtended {
  id?: number;
  referenceId: number | string | null;
  referenceType: CompanyReferenceEnum;
  company?: ICompanyExtended | null;
  companyRole?: ICompanyRole | null;
  companyContact?: ICompanyContact | null;
}

export interface IGetCompaniesRequest extends IQueryParams {
  referenceId: number | string | null;
  referenceType: CompanyReferenceEnumListOptions;
}

export const defaultValue: Readonly<ICompanyBinding> = {};
