import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { ErrroMiddleware } from './middleware/error-middleware';

import AuditHistory from './slices/audit-history';
import Account from './slices/account';
import BudgetExtended from './slices/budget-extended';
import BudgetTemplate from './slices/budget-template';
import BudgetTemplateExtended from './slices/budget-template-extended';
import AddressTypes from './slices/address-types';
import Attachment from './slices/attachment';
import Brand from './slices/brand';
import ChangeOrderReason from './slices/change-order-reasons';
import ChangeOrderRejectionReason from './slices/change-order-rejection-reasons';
import ChangeOrderRevision from './slices/change-order-revisions';
import ChangeOrderStatus from './slices/change-order-status';
import ChangeOrder from './slices/change-orders';
import CommentVersion2Slice from './slices/comment-v2';
import Company from './slices/company';
import CompanyBinding from './slices/company-binding';
import CompanyContact from './slices/company-contact';
import CompanyContactsExtended from './slices/company-contacts-extended';
import CompanyRoles from './slices/company-roles';
import CustomFields from './slices/customfields';
import Dashboard from './slices/dashboard';
import DisabledAccountNotification from './slices/disabled-account-notification';
import Discipline from './slices/discipline';
import FeatureFlagEvaluation from './slices/feature-flag-evaluation';
import GenericAddress from './slices/generic-address';
import GenericAlbumGalleryMultimedia from './slices/generic-album-gallery-multimedia';
import GenericCompany from './slices/generic-company';
import GenericCustomFields from './slices/generic-custom-fields';
import Location from './slices/location';
import LocationExtended from './slices/locations-extended';
import Market from './slices/market';
import Notification from './slices/notification';
import OpenGraph from './slices/open-graph';
import OxBlueProjects from './slices/ox-blue-projects';
import Permisions from './slices/permisions';
import Phase from './slices/phase';
import ProcoreProjects from './slices/procore-projects';
import ProjectChangeOrderDefaultUsers from './slices/project-change-orders-default-users';
import ProjectDeliverableTimeLine from './slices/project-deliverable-timeline';
import projectDeliverableReasonCodesSlice from './slices/project-deliverable-reason-codes';
import ProjectDeliverables from './slices/project-deliverables';
import ProjectDeliverablesGantt from './slices/project-deliverables-gantt';
import ProjectOverview from './slices/project-overview';
import ProjectOverviewTeam from './slices/project-overview-team';
import ProjectRfiDefaultUsers from './slices/project-rfi-default-users';
import ProjectStatuses from './slices/project-statuses';
import ProjectSteps from './slices/project-steps';
import ProjectTeamsExtended from './slices/project-teams-extended';
import ProjectTemplatesExtended from './slices/project-templates-extended';
import ProjectTypes from './slices/project-types';
import Project from './slices/projects';
import PunchList from './slices/punch-list';
import PunchListCategory from './slices/punch-list-category';
import PunchListStatus from './slices/punch-list-status';
import PunchListReason from './slices/punch-list-reason';
import Rfi from './slices/rfi';
import RfiCategory from './slices/rfi-category';
import RfiReason from './slices/rfi-reason';
import RfiDistribution from './slices/rfi-distribution';
import Roles from './slices/role';
import Search from './slices/search';
import Sites from './slices/site';
import SiteAddress from './slices/site-address';
import PhotoAlbum from './slices/photo-album';
import SiteAlbumPhotos from './slices/site-album-photos';
import SiteStatuses from './slices/sites-statuses';
import Testing from './slices/testing';
import Users from './slices/users';
import UsersExtended from './slices/users-extended';
import Currency from './slices/currency';
import ChangeOrderTimeline from './slices/change-order-timeline';
import Setting from './slices/setting';
import ChangeOrderItem from './slices/change-order-items';
import CostGrouping from './slices/cost-grouping';
import CostCategory from './slices/cost-category';
import CostSubCategory from './slices/cost-sub-category';
import Groups from './slices/groups';
import GroupBindings from './slices/group-bindings';
import AclTargets from './slices/acl-target';
import AclPermissions from './slices/acl-permission';
import Deliverables from './slices/deliverable';
import ProjectDeliverableReasons from './slices/project-deliverable-reasons';
import BIDashboard from './slices/bi-dashboard';
import BudgetSummary from './slices/budget-summary';
import BudgetApprover from './slices/budget-approvers';
import BudgetRevision from './slices/budget-revision';
import BudgetApprovalRangeSetting from './slices/budget-approval-range-setting';
import BudgetApproverSetting from './slices/budget-approver-setting';
import BudgetApprovalRangeSettingExtended from './slices/budget-approval-range-setting-extended';
import CustomFieldGroups from './slices/custom-field-groups';
import Shape from './slices/shape';

export const store = configureStore({
  reducer: {
    Account,
    AclPermissions,
    AclTargets,
    AddressTypes,
    Attachment,
    AuditHistory,
    BIDashboard,
    Brand,
    BudgetApprovalRangeSetting,
    BudgetApprovalRangeSettingExtended,
    BudgetApprover,
    BudgetApproverSetting,
    BudgetExtended,
    BudgetRevision,
    BudgetSummary,
    BudgetTemplate,
    BudgetTemplateExtended,
    ChangeOrder,
    ChangeOrderItem,
    ChangeOrderReason,
    ChangeOrderRejectionReason,
    ChangeOrderRevision,
    ChangeOrderStatus,
    ChangeOrderTimeline,
    CommentVersion2Slice,
    Company,
    CompanyBinding,
    CompanyContact,
    CompanyContactsExtended,
    CompanyRoles,
    CostCategory,
    CostGrouping,
    CostSubCategory,
    Currency,
    CustomFieldGroups,
    CustomFields,
    Dashboard,
    Deliverables,
    DisabledAccountNotification,
    Discipline,
    FeatureFlagEvaluation,
    GenericAddress,
    GenericAlbumGalleryMultimedia,
    GenericCompany,
    GenericCustomFields,
    GroupBindings,
    Groups,
    Location,
    LocationExtended,
    Market,
    Notification,
    OpenGraph,
    OxBlueProjects,
    Permisions,
    Phase,
    PhotoAlbum,
    Project,
    ProjectChangeOrderDefaultUsers,
    projectDeliverableReasonCodesSlice,
    ProjectDeliverableReasons,
    ProjectDeliverableTimeLine,
    ProjectDeliverables,
    ProjectDeliverablesGantt,
    ProjectOverview,
    ProjectOverviewTeam,
    ProjectRfiDefaultUsers,
    ProjectStatuses,
    ProjectSteps,
    ProjectTeamsExtended,
    ProjectTemplatesExtended,
    ProjectTypes,
    ProcoreProjects,
    PunchList,
    PunchListCategory,
    PunchListStatus,
    PunchListReason,
    Rfi,
    RfiCategory,
    RfiDistribution,
    RfiReason,
    Roles,
    Search,
    Setting,
    Shape,
    SiteAddress,
    SiteAlbumPhotos,
    SiteStatuses,
    Sites,
    Testing,
    Users,
    UsersExtended,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      ErrroMiddleware
      // LoggerMiddleware
    ),
});

const getStore = () => store;

export type IRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, IRootState, unknown, AnyAction>;
export type useAppDispatchType = typeof useAppDispatch;

export default getStore;
