import { Avatar, Card, Col, ConfigProvider, Radio, Row, theme } from 'antd'
import { useLayoutEffect, useState } from 'react'
import { formatDatePipe } from '@shared/util/date-utils';
import { MoonSvgIcon } from '@components/Icons/MoonSvgIcon'
import { SunSvgIcon } from '@components/Icons/SunSvgIcon';
import { isAppeareanceDarkMode, usePreferencesProvider, APPEARANCES } from '@providers/PreferencesProvider';
import { Tag } from 'antd';
import dayjs from 'dayjs';
import { userSettingsService } from '@services/user-settings.service';
import { useAuth } from '@providers/AuthProvider';

const today = dayjs().endOf('day').toString()

export const PreferencesTheme = () => {

  const { token: { colorText, fontSize, colorWhite, colorPrimary, colorBgContainer, colorBorderSecondary } } = theme.useToken();

  const [isDarkModeSelected, setIsDarkModeSelected] = useState(false);

  const { appearance, setDarkMode, setLightMode, userSettings, setPreferences } = usePreferencesProvider()
  
  const { user } = useAuth()

  useLayoutEffect(() => {
    if (appearance) {
      setIsDarkModeSelected(isAppeareanceDarkMode(appearance));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appearance])


  const setSettings = async (appearance: string) => {
    if (userSettings && userSettings.id) {
      const userPreferences = await userSettingsService.updateUserPreferences({
        ...userSettings,
        settings: JSON.stringify({ ...userSettings.settings, appearance: appearance })
      })
      setPreferences(userPreferences);
    } 
    
    if (!userSettings) {
      const userPreferences = await userSettingsService.createUserPreferences({
        settings: JSON.stringify({ appearance: appearance }),
        user: { id: user?.id }
      })
      setPreferences(userPreferences);
    }
  }

  const onSetLightMode = () => {
    setLightMode();
    setSettings(APPEARANCES.LIGHT);
  }

  const onSetDarkMode = () => {
    setDarkMode();
    setSettings(APPEARANCES.DARK);
  }

  
  const titleCardLight = (
    <>
      <ConfigProvider theme={{ algorithm: theme.defaultAlgorithm }}>  
        <Row justify={"start"}>
          <Col flex={"none"}>
            <Avatar icon={<SunSvgIcon />} gap={4} className="mt-15 mb-15 mr-10 flex flex-row justify-center items-center" />
          </Col>
          <Col flex="auto" style={{ color: isDarkModeSelected ? colorBgContainer: colorText }}>
          <div className='mt-10'>
            <p className='mt-0 mb-0 text-left font-normal flex flex-row justify-between'> 
                Theme preference
                <Tag>
                  <span className='ml-5 mr-5 mt-0 font-normal' style={{ fontSize: fontSize - 4, color: isDarkModeSelected ? colorBgContainer: colorText }}> 
                      { today && formatDatePipe(today) } 
                  </span>
                </Tag>
            </p>
            <p className='mt-0 mb-0 text-left font-normal' style={{ fontSize: fontSize - 2 }}> 
              light as day
            </p>
          </div>
        </Col>
        </Row>
      </ConfigProvider>
    </>
  )

  const titleCardDark = (
    <>
      <Row justify={"start"}>
        <Col flex={"none"}>
          <Avatar icon={<MoonSvgIcon />} gap={4} className="mt-15 mb-15 mr-10 flex flex-row justify-center items-center" />
        </Col>
        <Col flex="auto" style={{ color: isDarkModeSelected ? colorText : colorBgContainer }}>
          <div className='mt-10'>
            <p className='mt-0 mb-0 text-left font-normal flex flex-row justify-between'> 
                Theme preference
                <Tag>
                  <span className='ml-5 mr-5 mt-0 font-normal' style={{ fontSize: fontSize - 4, color: isDarkModeSelected ? colorText : colorBgContainer  }}> 
                      { today && formatDatePipe(today) } 
                  </span>
                </Tag>
            </p>
            <p className='mt-0 mb-0 text-left font-normal' style={{ fontSize: fontSize - 2 }}> 
              dark as night
            </p>
          </div>
        </Col>
      </Row>
    </>
  )

  return (
    <div>
      <Row>
        <Col span={24}>
          <ConfigProvider theme={{ algorithm: theme.defaultAlgorithm, components: { Card: { colorBorderSecondary: isDarkModeSelected ? colorBorderSecondary: colorPrimary } } }}>   
            <Card 
              size="small" 
              className='w-full cursor-pointer' 
              title={titleCardLight} 
              onClick={onSetLightMode}
              styles={{
                body: (isDarkModeSelected) ? { backgroundColor: colorBgContainer } : {}
              }}
            > 
            <ConfigProvider theme={{ algorithm: isDarkModeSelected ? theme.darkAlgorithm: theme.defaultAlgorithm }}>
              <Radio className="ml-10" checked={!isDarkModeSelected} >
                <span className={ !isDarkModeSelected ? "font-semibold": "font-medium " }>Light Mode</span>
              </Radio>
            </ConfigProvider>
            </Card>
          </ConfigProvider>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="mt-20">
          <ConfigProvider theme={{ algorithm: theme.darkAlgorithm, components: { Card: { colorBorderSecondary: isDarkModeSelected ? colorPrimary: colorBorderSecondary } } }}>
            
              <Card 
                size="small" 
                className='w-full cursor-pointer' 
                title={titleCardDark} 
                onClick={onSetDarkMode}
                styles={{
                  body: (!isDarkModeSelected) ? { backgroundColor: colorWhite } : {}
                }}
              >
                <ConfigProvider theme={{ algorithm: isDarkModeSelected ? theme.darkAlgorithm: theme.defaultAlgorithm }}>
                    <Radio className="ml-10" checked={isDarkModeSelected}>
                      <span className={ isDarkModeSelected ? "font-semibold": "font-medium" }>Dark Mode</span>
                    </Radio>
                </ConfigProvider>
              </Card>
          </ConfigProvider>
        </Col>
      </Row>
    </div>
  )
}
