import { configuration } from '../../../environments/env';

interface IStaticImageMapProps {
    width: number,
    height: number,
    zoom?: number,
    center?: string,
    markers: { latitude: string, longitude: string }[]
}

const DEFAULT_ZOOM = 11;

export const StaticImageMap = (props: IStaticImageMapProps) => {

    const { width, height, center, zoom = DEFAULT_ZOOM, markers } = props;
    
    const generateStaticMapURL = () => {
        const baseURL = 'https://maps.googleapis.com/maps/api/staticmap';
        const zoomMap = zoom || DEFAULT_ZOOM;
        const size = `${width}x${height}`;
        
        const markersURL = markers
            .map((marker, index) => `markers=size:normal%7Ccolor:red%7C|${marker.latitude},${marker.longitude}`)
            .join('&');

        return `${baseURL}?zoom=${zoomMap}${center ? `center=${center}` : ""}&size=${size}&${markersURL}&key=${configuration.GOOGLE_MAPS_API_KEY}`;
    };
    
    return (
        <img style={{ width, height, borderRadius: 4 }} src={`${generateStaticMapURL()}`} alt="" />
    )
}
