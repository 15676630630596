
import { isFulfilled, isPending } from '@reduxjs/toolkit';
import { ICompany, defaultValue } from "../../models/company.model";
import { createEntitySlice, EntityState, serializeAxiosError } from "../reducer.util";
import { createAsyncThunk } from "@reduxjs/toolkit"
import { companyService } from "../../services/company.service";
import { IQueryParams } from "../../models/pagination";
import { cleanEntity } from '../../shared/util/entity-utils';


export const getEntities = createAsyncThunk(
  'company/fetch_entity_list', 
  async (queryParams: IQueryParams) => {
      return companyService.getCompanies(queryParams);
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  'company/fetch_entity',
  async (id: string | number) => {
    return companyService.getCompanyById(id);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'company/create_entity',
  async (entity: ICompany, thunkAPI) => {
    const result = await companyService.createCompany(cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'company/update_entity',
  async (entity: ICompany, thunkAPI) => {
    const result = entity.id && await companyService.updateCompany(entity.id, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'company/delete_entity',
  async (id: string | number, thunkAPI) => {
    const result = await companyService.deleteCompany(id);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

const initialState: EntityState<ICompany> = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

export const CompanySlice = createEntitySlice({
    name: 'company',
    initialState,
    reducers: {
      clearEntity: (state,) => {
        state.entity = defaultValue;
      },
    },
    extraReducers(builder) {
        builder
          .addCase(getEntity.fulfilled, (state, action) => {
            state.loading = false;
            state.entity = action.payload.data;
          })
          .addMatcher(isFulfilled(getEntities), (state, action) => {
            const { data, headers } = action.payload;
            const xTotalCount = headers['x-total-count'];

            return {
              ...state,
              loading: false,
              entities: data,
              totalItems: xTotalCount ? parseInt(xTotalCount, 10) : data.length,
            };
          })
          .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
            state.updating = false;
            state.loading = false;
            state.updateSuccess = true;
            state.entity = action.payload as ICompany;
          })
          .addMatcher(isPending(getEntities, getEntity), state => {
            state.errorMessage = null;
            state.updateSuccess = false;
            state.loading = true;
          })
          .addMatcher(isPending(createEntity, updateEntity), state => {
            state.errorMessage = null;
            state.updateSuccess = false;
            state.updating = true;
          });
      }
  });
  
  export const { reset, clearEntity } = CompanySlice.actions;
  
  // Reducer
  export default CompanySlice.reducer;

  