import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import { RouterProvider } from "react-router-dom";
import { BrowserRouterApp } from "./routes/routes";
import { HelmetProvider } from "react-helmet-async";
import { ThemeContextProvider } from "./providers/ThemeProvider";
import { AntDesignProvider } from "./providers/AntDesignProvider";
import { Provider } from 'react-redux'
import { store } from "./store/store";

import 'antd/dist/reset.css';
import "./App.less";
import { Head } from "./components/Head/Head";
import { DisabledAccountNotification } from './components/Notification/DisabledAccountNotification';
import { LayoutProvider } from "@providers/LayoutProvider";
import { GoogleMapsProvider } from "@providers/GoogleMapsProvider";
import { AnalyticsProvider } from "@providers/AnalyticsProvider";
import { PreferencesProvider } from "@providers/PreferencesProvider";
import { errorTrackingConf } from '@shared/error/error-tracking';
import  './i18n';
import { Suspense } from 'react';
import { NotificationsProvider } from '@providers/NotificationsProvider';
import { AuditHistory } from '@components/AuditHistory/AuditHistory';

// import { useStomp } from "@HOOKs/UseStomp";
// import { configuration } from "./environments/env";
// import { message } from "antd";
// import { useEffect } from "react";
// import { parseCRUDNotification } from "@shared/util/notfication-messages";

function App() {

  // I'm commenting this to refactor it on https://www.pivotaltracker.com/story/show/184768657

  // const { disconnect, subscribe, unsubscribe, subscriptions, send, isConnected } =
  // const { subscribe, isConnected } = useStomp({
  //   wsUrl: configuration.API_URL.replace("/api/", "/websocket/") + "notifications"
  // }, () => {
  //   subscribe('/topic/notifications', (msg: any) => {
  //     const { operation, refId, refType, label, username } = msg;
  //     message.info(parseCRUDNotification(operation, refId, refType, label, username), 10);
  //   })
  // });

  // useEffect(() => {
  //   console.log('isConnected?:', isConnected);
  // }, [isConnected]);


  return (
    <Suspense>
      <RollbarProvider config={errorTrackingConf}>
        <ErrorBoundary>
          <Provider store={store}>
            <PreferencesProvider>
              <ThemeContextProvider>
                <AntDesignProvider>
                  <AnalyticsProvider>
                    <GoogleMapsProvider>
                      <LayoutProvider>
                        <HelmetProvider>
                            <Head />
                            <NotificationsProvider>
                              <RouterProvider router={BrowserRouterApp} />
                            </NotificationsProvider>
                            <DisabledAccountNotification />
                            <AuditHistory />
                        </HelmetProvider>
                      </LayoutProvider>
                    </GoogleMapsProvider>
                  </AnalyticsProvider>
                </AntDesignProvider>
              </ThemeContextProvider>
            </PreferencesProvider>
          </Provider>
        </ErrorBoundary>
      </RollbarProvider>
    </Suspense>
  );
}

export default App;
