import { useEffect, useState } from "react";
import { Col, Drawer, Row, Select, Table, Tag, Form, theme, Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { TabContainer } from "@components/Utils/TabContainer";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getEntities } from "@store/slices/acl-target";
import { createEntity }  from "@store/slices/acl-permission";
import { IAclTarget } from "@models/acl-target.model";
import FormItem from "antd/es/form/FormItem";
import { getUsers } from "@store/slices/users-extended";
import { getRoles } from "@store/slices/role";
import { getEntities as getGroups } from "@store/slices/groups";
import { useErrorAnimation } from "@HOOKs/UseErrorAnimation";
import { PermissionsList } from "./PermissionsList";
import { getEntities as getCompanyRoles } from "@store/slices/company-roles";
import { BackButton } from "@components/Utils/BackButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLayoutContex } from "@providers/LayoutProvider";

interface DataType {
  key: string;
  name: string;
  permissionName: string;
}

export const PermissionsTab = () => {

  const { token: { marginLG, colorBgContainer, colorTextLabel, colorBorderSecondary } } = theme.useToken();

    const { entities } = useAppSelector((state) => state.AclTargets);

    const dispatch = useAppDispatch();

    const selectPermission = (permissionName: string, action: string) => {
        const aclTarget = entities.find(x => x.action === action && x.name === permissionName);
        if (typeof aclTarget !== 'undefined') {
          setSelectedAclTarget(aclTarget);
        }
    }

    const columns: ColumnsType<DataType> = [
        {
          title: 'Target Name',
          dataIndex: 'name',
          key: 'name',
          render: (text) => 
          <>
            {/*<span style={{ width: '24px', display: 'inline-flex' }}><ChevronDownSvgIcon /></span>*/}
            <span style={{ fontSize: '14px', fontWeight: 500, maxWidth: '200px', textOverflow: 'ellipsis', display: 'block', maxHeight: '24px', overflow: 'hidden', whiteSpace: 'nowrap' }}>{text}</span>
          </>,
        },
        {
          title: 'Create',
          dataIndex: 'create',
          key: 'create',
          render: (_, { permissionName }) => (
            <>
              <Tag color={'green'} className="cursor-pointer"  key={'1'} onClick={() => selectPermission(permissionName, 'CREATE')}>
                Restricted
              </Tag>
            </>
          ),
        },
        {
          title: 'Edit',
          dataIndex: 'edit',
          key: 'edit',
          render: (_, { permissionName }) => (
            <>
              <Tag color={'green'} className="cursor-pointer"  key={'1'} onClick={() => selectPermission(permissionName, 'EDIT')}>
                Restricted
              </Tag>
            </>
          ),
        },
        {
          title: 'View',
          key: 'view',
          dataIndex: 'view',
          render: (_, { permissionName }) => (
            <>
              <Tag color={'green'} className="cursor-pointer"  key={'1'} onClick={() => selectPermission(permissionName, 'READ')}>
                Restricted
              </Tag>
            </>
          ),
        },
        {
          title: 'Delete',
          key: 'delete',
          render: (_, { permissionName }) => (
            <>
              <Tag color={'green'} className="cursor-pointer" key={'1'} onClick={() => selectPermission(permissionName, 'DELETE')}>
                Restricted
              </Tag>
            </>
          ),
        },
    ];
    
    const [selectedAclTarget, setSelectedAclTarget] = useState<IAclTarget | null>(null);
    const [selectedPermissionType, setSelectedPermissionType] = useState<string>('USER');

    const loadPermissions = () => {
        dispatch(
          getEntities()
        );
    }

    const { t } = useTranslation();
    const { setHeaderLabelEntityState } = useLayoutContex()
    
    useEffect(() => {
        loadPermissions();
        dispatch(getUsers());
        dispatch(getRoles());
        dispatch(getCompanyRoles({}));
        dispatch(getGroups({}));
        setHeaderLabelEntityState(t("admin.permissions"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [data, setData] = useState<DataType[]>([]);

    useEffect(() => {
      const permissionsData: DataType[] = [];
      entities.forEach((permission, idx) => {
        if (permissionsData.filter(x => x.name === permission.label && x.permissionName === permission.name).length === 0) {
          permissionsData.push({
            key: `${idx}`,
            name: `${permission.label}`,
            permissionName: `${permission.name}`
          });
        }
      });
      setData(permissionsData);
    }, [entities]);

    const getDrawerTitle = (): string => {
      if (selectedAclTarget) {
        return `Manage ${selectedAclTarget.action} permission for ${selectedAclTarget.label}`;
      }

      return 'Manage permissions';
    }

    const { usersList } = useAppSelector((state) => state.UsersExtended);
    const { entities: roles } = useAppSelector((state) => state.Roles);
    const { entities: companies } = useAppSelector((state) => state.CompanyRoles);
    const { entities: groups } = useAppSelector((state) => state.Groups);

    const [form] = Form.useForm();

    const options = usersList.map(elem => {
      return { label: elem.fullName, value: elem.id }
    })
    const roleOptions = roles.map(elem => {
      return { label: elem.name, value: elem.id }
    })
    const companyOptions = companies.map(elem => {
      return { label: elem.description, value: elem.id }
    })
    const groupOptions = groups.map(elem => {
      return { label: elem.name, value: elem.id }
    })

    const permissionTypeOptions = [
      { label: 'User', value: 'USER' },
      { label: 'Role', value: 'ROLE' },
      { label: 'Company', value: 'COMPANY_ROLE' },
      { label: 'Group', value: 'GROUP' }
    ];

    const [animateError, playAnimationOfError] = useErrorAnimation();

    const onFinishFailed = (errorInfo: any) => playAnimationOfError();

    const onFinish = (data: any): void => {
        
      const newPermissionType = data.newPermissionType ?? 'USER';
      let refId = null;
      if (newPermissionType === 'USER') {
        refId = data.user;
      } else if (newPermissionType === 'ROLE') {
        refId = data.role;
      } else if (newPermissionType === 'COMPANY_ROLE') {
        refId = data.company;
      } else if (newPermissionType === 'GROUP') {
        refId = data.group;
      }
      if (selectedAclTarget && refId) {
        dispatch(createEntity({ referenceType: newPermissionType, referenceId: refId, aclTargetId: selectedAclTarget.id }))
      }
    };

    const navigate = useNavigate();

    const navigateBack = () => {
        navigate('/admin/access-and-permissions')
    }

    const sidebarTitleStyles = { backgroundColor: colorBgContainer, borderRadius: '4px', padding: '4px 12px', marginBottom: '8px' };
    const sidebarTitleTextStyles = { color: colorTextLabel, lineHeight: '24px', fontSize: '16px', fontWeight: 500 };

    return (
        <>
            <TabContainer>
                <div className="flex flex-col overflow-auto">
                    <Row>
                        <Col span={24}>
                            <div className="flex flex-row items-center pl-10 pt-11 pb-11" style={{ borderTop: `1px solid ${colorBorderSecondary}`, borderBottom: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                                <BackButton onClick={navigateBack} >Access & Permissions</BackButton>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className="h-full">
                    <Col span={14}>
                        <div className="pt-20 overflow-auto" style={{ paddingLeft: marginLG, paddingRight: marginLG }}>
                            <Table columns={columns} dataSource={data} pagination={false} />
                        </div>
                    </Col>
                    <Col span={10}>
                        <div style={{ position: 'relative', height: '100%' }}>
                            <Drawer
                                title={getDrawerTitle()}
                                placement="right"
                                closable={false}
                                open={true}
                                getContainer={false}
                                mask={false}
                                width={500}
                            >
                                {!selectedAclTarget && <div style={{ marginTop: '40px', textAlign: 'center' }}>
                                    <div style={{ color: '#91D5FF' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                                            <path d="M4.5 16H8.5M16.5 4V8M10.9 10.4L7.96667 7.46667M22.1 10.4L25.0333 7.46667M10.9 21.6L7.96667 24.5333M16.5 16L28.5 20L23.1667 22.6667L20.5 28L16.5 16Z" stroke="#91D5FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                    <div style={{ color: '#8C8C8C', lineHeight: '24px' }}>
                                        Select an action to view its permissions
                                    </div>
                                </div>}
                                {selectedAclTarget && <>
                                  <div style={{ ...sidebarTitleStyles }}>
                                    <div style={{ ...sidebarTitleTextStyles }}>Add new permission</div>
                                  </div>
                                  <Form
                                      layout="vertical"
                                      form={form}
                                      onFinish={onFinish}
                                      onFinishFailed={onFinishFailed}
                                      className={`${animateError && "error-animation"}`} >
                                    <Row className="flex flex-items items-center w-full space-between" gutter={12}>
                                      <Col span={12}>
                                        <FormItem name="newPermissionType" label="Type">
                                          <Select
                                              defaultValue={'USER'}
                                              value={selectedPermissionType}
                                              onChange={(value: string) => { setSelectedPermissionType(value); }}
                                              options={permissionTypeOptions}
                                          />
                                        </FormItem>
                                      </Col>
                                      <Col span={12}>
                                        {selectedPermissionType === 'USER' && <FormItem name="user" label="Select from list">
                                          <Select
                                              placeholder="Select User"
                                              options={options}
                                              showSearch
                                              allowClear
                                              filterOption={(inputValue, option) =>
                                                  option!.label!.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                              }
                                          />
                                        </FormItem>}
                                        {selectedPermissionType === 'ROLE' && <FormItem name="role" label="Select from list">
                                            <Select
                                                placeholder="Select Role"
                                                options={roleOptions}
                                                showSearch
                                                allowClear
                                                filterOption={(inputValue, option) =>
                                                    option!.label!.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                            />
                                          </FormItem>}
                                          {selectedPermissionType === 'COMPANY_ROLE' && <FormItem name="company" label="Select from list">
                                            <Select
                                                placeholder="Select Company Role"
                                                options={companyOptions}
                                                showSearch
                                                allowClear
                                                filterOption={(inputValue, option) =>
                                                    option!.label!.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                            />
                                          </FormItem>}
                                          {selectedPermissionType === 'GROUP' && <FormItem name="group" label="Select from list">
                                            <Select
                                                placeholder="Select Group"
                                                options={groupOptions}
                                                showSearch
                                                allowClear
                                                filterOption={(inputValue, option) =>
                                                    option!.label!.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                            />
                                          </FormItem>}
                                        </Col>
                                      </Row>
                                      <Row className="w-full">
                                        <Col span={24} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                          <Button type="primary" htmlType="submit">
                                            Create new permission
                                          </Button>
                                        </Col>
                                      </Row>
                                  </Form>
                                  <div style={{ ...sidebarTitleStyles }} className="mt-10">
                                    <div style={{ ...sidebarTitleTextStyles }}>List of existing permissions</div>
                                  </div>
                                  <PermissionsList permissionId={selectedAclTarget.id} />
                                </>}
                            </Drawer>
                        </div>
                    </Col>
                </Row>
            </TabContainer>
        </>
    )
}
