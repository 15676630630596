import AttachmentsList from "@components/Attachment/AttachmentsList";
import { AttachmentReferenceEnum } from "@models/enumerations/attachment-reference-enum.model";
import { isArrayWithValues } from '@shared/util/array-util';
import { VALID_IMAGES_EXTENSIONS } from "@constants/fileImagesValidExtentions";
import { UploadFile } from "antd";
import { generateUniqueStringWithTimestamp } from "@shared/util/date-utils";
import { useState } from "react";

interface IDragZoneUploadMultiFilesProps {
    referenceId: number;
    entityType: AttachmentReferenceEnum
    onMultiUploadProcessFinishCallback?: () => void
}

const MAX_LIMIT_FILES_TO_UPLOAD_IN_ALBUMS = 50;

export const DragZoneUploadMultiFiles = (props: IDragZoneUploadMultiFilesProps) => {

    const { referenceId, entityType, onMultiUploadProcessFinishCallback } = props;

    const [keyDragZoneUploadMultiFiles, setKeyDragZoneUploadMultiFiles] = useState<string>("")
    
    const onMultiUploadProcessFinish = ({filelist}: { filelist: UploadFile[] }) => {
        if (isArrayWithValues(filelist)) {
            if (filelist.some((file) => Boolean(['success', 'done'].includes(String(file.status))))) {
                onMultiUploadProcessFinishCallback?.();
                setKeyDragZoneUploadMultiFiles(generateUniqueStringWithTimestamp());
            }
            if (filelist.every(file => Boolean(['error'].includes(String(file.status))))) {
              setKeyDragZoneUploadMultiFiles(generateUniqueStringWithTimestamp());
            }
        }
    }
    
    return (
        <div key={keyDragZoneUploadMultiFiles}>
            <AttachmentsList
                entityType={entityType}
                referenceId={referenceId} 
                showHeader={false}
                showlistOfAttachments={false}
                maxLimitFilesToUpload={MAX_LIMIT_FILES_TO_UPLOAD_IN_ALBUMS}
                validExtensions={VALID_IMAGES_EXTENSIONS}
                onMultiUploadProcessFinish={onMultiUploadProcessFinish}
            />
        </div>
    )
}