import { generate } from '@ant-design/colors';
import { configuration as SecrectAWSKeys } from "../environments/env";
import { useContext, useLayoutEffect, useState } from 'react';
import { createContext } from 'react';
import { APPEARANCES, usePreferencesProvider } from '@providers/PreferencesProvider';

export type paletteColorSettings = {
    primaryColor: string;
    secondaryColor: string;
    tertiaryColor: string
}

export interface themeSettings extends paletteColorSettings {
    darkMode: boolean
}

export const primaryColor: string = SecrectAWSKeys.PRIMARY_COLOR;
export const secondaryColor: string = SecrectAWSKeys.SECONDARY_COLOR;
export const tertiaryColor: string = SecrectAWSKeys.TERTIARY_COLOR;

const initialThemeSettingsState: themeSettings = {
    primaryColor: primaryColor,
    secondaryColor: secondaryColor,
    tertiaryColor: tertiaryColor,
    darkMode: false
}

export type ThemeContextProviderType = {
    themeConfig: themeSettings;
    setThemeConfig: (config: themeSettings) => void;
};

export const ThemeContext = createContext<ThemeContextProviderType | {}>({});

export const ThemeContextProvider = ({ children }: any) => {  

    const [themeConfig, setThemeConfig] = useState(initialThemeSettingsState);
    const { appearance } = usePreferencesProvider();

    useLayoutEffect(() => {
        setGlobalPalete(themeConfig.primaryColor, PRIMARY_COLOR_NAME, themeConfig.darkMode);
        setGlobalPalete(themeConfig.secondaryColor, SECONDARY_COLOR_NAME, themeConfig.darkMode);
        setGlobalPalete(themeConfig.tertiaryColor, TERTIARY_COLOR_NAME, themeConfig.darkMode);
        setZoom(1);
        setNeutralColors(themeConfig.darkMode)
    }, [themeConfig])

    useLayoutEffect(() => {
        if (appearance) {
            setThemeConfig({ ...themeConfig, darkMode: appearance === APPEARANCES.DARK })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appearance]);

    return (
        <ThemeContext.Provider value={{ setThemeConfig, themeConfig }}>
            {children}
        </ThemeContext.Provider>
    )
};

export const useThemeProvider = () => {
    return useContext(ThemeContext) as ThemeContextProviderType;
};

export const setGlobalPalete = (colorToSet: string, name: string, isDarkMode: boolean = false): void => {
    if (colorToSet) {
        const palette = generate(colorToSet, { theme: isDarkMode ? 'dark': 'default' });  
        palette.forEach((color, index) => {
            document.documentElement.style.setProperty(`--${name}-${index}`, `${color}`);
        });
    }
}

export const setZoom = (zoom: number ): void => {
    document.body.style.setProperty("zoom", `${zoom}`);
    document.documentElement.style.setProperty(`--zoom`, `${zoom}`);
}

export type paletteColor = { [key: string]: string }

export const paletteGenerator = (color: string): paletteColor => {
    const palette = generate(color);   
    const paletteGenerated = palette.reduce(function(target, key, index) {
        target[`color-${index}`] = `${key}`;
        return target;
    }, {} as paletteColor);
    return paletteGenerated as paletteColor; 
}

// #########################
// Settings Theme Variables.
// #########################
export const PRIMARY_COLOR_NAME = 'primary-color';
export const SECONDARY_COLOR_NAME = 'secondary-color';
export const TERTIARY_COLOR_NAME = 'tertiary-color';
export const mainFont: string = "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,\n  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',\n  'Noto Color Emoji'";
export const DEFAULT_WIDTH_MODAL_COMPONENT = 451;
export const DEFAULT_BORDER_RADIUS = 4;
export const MODAL_THEME_SECONDARY = {
    borderRadius: 8,
    borderRadiusLG: 8,
    borderRadiusSM: 8,
    borderRadiusXS: 8,
    paddingContentHorizontalLG: 0,
    paddingMD: 0,
    paddingLG: 0,
    paddingXS: 0,
    padding: 0,
}

export const WHITE_ABSOLUTE = "#FFFFFF";
export const MAIN_BOX_SHADOW = "box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.15)";
export const SITERISE_BG_WHITE_COLOR = "#EBEBEB";
export const COLOR_TEXT_BASE = "#262626";
export const WHITE = "#F5CFCF";
export const COLOR_TEXT_TERTIARY= "#434343";
export const DEFAULT_COLOR = "#000000";
export const WHITE_LIGHT = "#fbfbfb";
export const SITE_RISE_OFF_WHITE = "#FBFDF8"
export const SECONDARY_TEXT_COLOR = "#545454";
export const TERTIARY_TEXT_COLOR = "#333333";
export const SECONDARY_COLOR_ALERT = "#EC9546";
export const LIGHT_GREY = "#EFEFEF";
export const LIGHT_GREY_1 = "#AEAEAE";
export const NEUTRAL_2_COLOR = "#FAFAFA";
export const NEUTRAL_4_COLOR = "#F0F0F0";
export const NEUTRAL_5_COLOR = "#d9d9d9";
export const NEUTRAL_6_COLOR = "#BFBFBF";
export const NEUTRAL_7_COLOR = "#8C8C8C";
export const NEUTRAL_8_COLOR = "#595959";
export const NEUTRAL_11_COLOR= "#1F1F1F";

export const themeLight = {
    colorPrimary: "#1890ff",
    colorInfo: "#1890ff",
    colorLink: "#13c2c2",
    colorTextBase: "#292d2d",
    colorBgBase: "#fcfdfa"
}

export const themeDark = {  
    colorPrimary: "#1890FF",
    colorInfo: "#1890FF",
    colorLink: "#13C2C2",
    colorTextBase: "#FCFDFA",
    colorBgBase: "#292D2D",
    colorErrorBg: "#463939",
    colorErrorBorder: "#714444",
    colorWarningBg: "#564B39",
    colorWarningBorder: "#8B754C",
    colorSuccessBg: "#2D3B29",
    colorSuccessBorder: "#315320",
    colorPrimaryBg: "#2E3949",
}


export const setNeutralColors = (isDarkMode: boolean = false): void => {
    document.documentElement.style.setProperty(`--neutral-1`, isDarkMode ? DEFAULT_COLOR: WHITE_ABSOLUTE);
    document.documentElement.style.setProperty(`--neutral-2`, isDarkMode ? "#141414": "#fafafa");
    document.documentElement.style.setProperty(`--neutral-3`, isDarkMode ? NEUTRAL_11_COLOR: "#f5f5f5");
    document.documentElement.style.setProperty(`--neutral-4`, isDarkMode ? COLOR_TEXT_BASE: "#f0f0f0");
    document.documentElement.style.setProperty(`--neutral-5`, isDarkMode ? COLOR_TEXT_TERTIARY: NEUTRAL_5_COLOR);
    document.documentElement.style.setProperty(`--neutral-6`, isDarkMode ? NEUTRAL_8_COLOR: NEUTRAL_6_COLOR);
    document.documentElement.style.setProperty(`--neutral-7`, isDarkMode ? NEUTRAL_7_COLOR: NEUTRAL_7_COLOR);
    document.documentElement.style.setProperty(`--neutral-8`, isDarkMode ? NEUTRAL_6_COLOR: NEUTRAL_8_COLOR);
    document.documentElement.style.setProperty(`--neutral-9`, isDarkMode ? NEUTRAL_5_COLOR: COLOR_TEXT_TERTIARY);
    document.documentElement.style.setProperty(`--neutral-10`, isDarkMode ? "#f0f0f0": COLOR_TEXT_BASE);
    document.documentElement.style.setProperty(`--neutral-11`, isDarkMode ? "#f5f5f5": NEUTRAL_11_COLOR);
}

export const borderRadiusFromBottomStyleDrawersInMobile: React.CSSProperties = {
    borderTopLeftRadius: '0.25rem',
    borderTopRightRadius: '0.25rem',
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0'
}
