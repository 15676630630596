import { isFulfilled } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from '../reducer.util';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { budgetExtendedService } from '@services/budget-extended.service';
import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { IBudgetApprover } from '@models/budget.model';

export const getBudgetApprovers = createAsyncThunk(
  'budget-extended/get_budget_approvers',
  async (budgetId: StringORNumber) => {
    return await budgetExtendedService.getBudgetApprovers(budgetId);
  },
  { serializeError: serializeAxiosError }
);

const initialState: EntityState<IBudgetApprover[]> = {
  loading: false,
  errorMessage: null,
  entities: [],
  updating: false,
  updateSuccess: false,
  entity: []
};

export const BudgetApproverSlice = createEntitySlice({
  name: 'budget_approver',
  initialState,
  extraReducers(builder) {
    builder.addMatcher(isFulfilled(getBudgetApprovers), (state, action) => {
      return {
        ...state,
        loading: false,
        entity: action.payload,
      };
    });
  },
});


// Reducer
export default BudgetApproverSlice.reducer;
