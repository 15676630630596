import { Col, ConfigProvider, Row, theme } from 'antd'
import { UpcomingOpeningsTable } from './UpcomingOpeningsTable'
import { Card } from 'antd';
import { useThemeProvider } from '@providers/ThemeProvider';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { UpcomingOpeningsMap } from './UpcomingOpeningsMap';
import { useTranslation } from 'react-i18next';

export const UpcomingOpeningsCard = () => {

  const { themeConfig } = useThemeProvider();
  const algorithmSelected = themeConfig?.darkMode ? [theme.compactAlgorithm, theme.darkAlgorithm] : theme.compactAlgorithm;
  const { data: { upcomingOpenings: { totalItems} } } = useSelector((state: RootState) => state.Dashboard);
  const { t } = useTranslation();

  const HeaderCard = (  
    <Row justify="space-between">
        <div className='flex flex-row items-baseline'>
            <span className='font-bold mr-5'>
              {t("dashboard.upcomingOpenings")}
            </span>
            { totalItems && (<span className='font-normal ml-5'>({totalItems} Total)</span>) }
        </div>
    </Row>
  )

  return (
    <ConfigProvider theme={{ token: { borderRadius: 5 }, algorithm: algorithmSelected }}>
      <Card className='mt-15' title={HeaderCard} bordered={false}>
        <Row gutter={8} wrap={false} className='w-full'>
          <Col>
            <UpcomingOpeningsTable />
          </Col>
          <Col>
            <UpcomingOpeningsMap />
          </Col>
        </Row>
      </Card>
    </ConfigProvider>
  )
}
