import { If } from "@components/Utils/Structural";
import { isArrayWithValues } from "@shared/util/array-util";
import { theme } from "antd";
import { useMemo } from "react";
import { useResizeDetector } from "react-resize-detector";
import { IAttachment } from "@models/attachment.model";
import { ThumbailAttachmentPunchListItem } from "./ThumbailAttachmentPunchListItem";
import { Spinner } from "@components/Utils/Spinner";
import { ThumbnailCustomBadge } from "@components/PhotoAlbum/ThumbnailCustomBadge";

interface IThumbnailContainerListProps {
    attachmentList: IAttachment[];
    loading: boolean
}

export const ThumbnailContainerList = (props: IThumbnailContainerListProps) => {

    const { attachmentList, loading } = props;

    const { token:  {colorBgLayout, colorBorderSecondary, lineHeight, marginSM, paddingSM }} = theme.useToken();

    const { width: thumbnailContainerListWidth = 0,  ref: thumbnailContainerRef } = useResizeDetector({refreshMode: 'debounce', refreshRate: 50 });

    const maxNumberOfThumbnailsPossible: number = useMemo(() => {
        const fullWidhtOfImage = 52;
        const halfWidhtOfBadge = 10;
        return Math.floor((thumbnailContainerListWidth - halfWidhtOfBadge) / fullWidhtOfImage);
    }, [thumbnailContainerListWidth])

    const attachmentsListProcessed = useMemo(() => {
        const attachmentListCopy = [...attachmentList];
        return isArrayWithValues(attachmentListCopy) ? attachmentListCopy!.slice(0, maxNumberOfThumbnailsPossible): []
    }, [attachmentList, maxNumberOfThumbnailsPossible])
    
    return (
      <div 
        ref={thumbnailContainerRef}
        className="flex flex-row items-center justify-center"
        style={{ 
            border: `1px solid ${colorBorderSecondary}`, 
            background: colorBgLayout, 
            borderRadius: 4,
            paddingLeft: 2,
            paddingTop: 4,
            paddingBottom: 4,
            marginTop: marginSM,
            marginBottom: marginSM,
        }}> 
            <If condition={!loading && isArrayWithValues(attachmentsListProcessed)}>
                <div className="flex flex-row w-full overflow-hidden justify-start" style={{ paddingRight: 15 }}>
                    {
                        attachmentsListProcessed.map((attachment, index) => {
                            const isLastItem = index === (maxNumberOfThumbnailsPossible - 1);
                            return (
                                <div key={attachment.id}>
                                    <ThumbailAttachmentPunchListItem 
                                        attachment={attachment}
                                        {...(isLastItem) && { counterNode: <ThumbnailCustomBadge count={attachmentsListProcessed.length} />} } 
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </If>
            <If condition={Boolean(loading)}>
                <div style={{ lineHeight, paddingTop: paddingSM, paddingBottom: paddingSM }}>
                    <Spinner iconFontSize={24} />
                </div>
            </If>
            <If condition={!loading && !isArrayWithValues(attachmentsListProcessed)}>
                <div className="font-normal text-color-neutral-6" style={{ lineHeight, paddingTop: paddingSM, paddingBottom: paddingSM }}>
                    No Attachments Yet
                </div>
            </If>
      </div>
    )
}
