import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getEntities as getAddressTypes } from "@store/slices/address-types";
import { Card, Switch, Tag, theme } from "antd";
import useVisible from "@HOOKs/UseVisible";
import { EditOutlined } from "@ant-design/icons";
import { NEUTRAL_7_COLOR } from "@providers/ThemeProvider";
import { AddressTypeEdit } from "./AddressTypeEdit";
import "@styles/components/admin-item-card.less";
import { IAddressType } from "@models/address-type.model";
import { useTranslation } from "react-i18next";

const ListItem = (addressType: IAddressType) => {
  const { t } = useTranslation();
  return <li style={{ color: NEUTRAL_7_COLOR }} className="pb-4">{addressType.description} {addressType.main && <Tag>{t("admin.main")}</Tag>} {addressType.onePerEntity && <Tag>{t("admin.1entity")}</Tag>}</li>
}

interface ListItemEditableProps {
  addressType: IAddressType;
  addressTypeSelected: IAddressType;
  editAddressTypeClicked: any;
}

const ListItemEditable = (props: ListItemEditableProps) => {

  const { t } = useTranslation();
  const { addressType, addressTypeSelected, editAddressTypeClicked } = props;

  return <div className={`list-item-editable ${(addressTypeSelected?.id === addressType.id ? 'selected' : '')}`} onClick={() => editAddressTypeClicked(addressType)}>
    <div>
      <div className="handle-container">
        <div className="handle-dot"></div>
      </div>
    </div>
    <div className="description">
      {addressType.description} {addressType.main && <Tag>{t("admin.main")}</Tag>} {addressType.onePerEntity && <Tag>{t("admin.1entity")}</Tag>}
    </div>
    <span className="edit-container">
      <span className="edit-link">
        <EditOutlined /> {t("generic.edit")}
      </span>
    </span>
  </div>
}

export const AddressTypesCard = (props: any) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { token: { colorPrimary, colorTextDisabled } } = theme.useToken();

  const loadAddressTypes = () => {
    dispatch(
      getAddressTypes()
    );
}

  useEffect(() => {
    loadAddressTypes();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { entities: addressTypes } = useAppSelector((state) => state.AddressTypes);

  const [openEditAddressType, toogleEditAddressType] = useVisible();
  const [addressTypeSelected, setAddressTypeSelected] = useState<IAddressType>({})
  const [isEditing, setIsEditing] = useState<boolean>(false)

  useEffect(() => {
    if (!openEditAddressType) {
      setAddressTypeSelected({})
    }
  }, [openEditAddressType]);

  const onModifiedChanges = (role: IAddressType) => {
    loadAddressTypes();
  }

  const toggleEdit = () => {
    setIsEditing(!isEditing)
  };

  const editAddressType = (addressType : IAddressType) => {
    setAddressTypeSelected(addressType)
    toogleEditAddressType()
  }

  const createNewAddressType = () => {
    setAddressTypeSelected({})
    toogleEditAddressType();
  }

  const addressTypesSorted = useMemo(() => {
    return [...addressTypes].sort((a, b) => {
      if (a.main !== b.main) {
        return (a?.main ? 0 : 1) - (b?.main ? 0 : 1);
      } else {
        return (a.description || '').localeCompare((b.description || '')); 
      }
    });
  }, [addressTypes]);

  return (
    <>
      <AddressTypeEdit open={openEditAddressType} toogle={toogleEditAddressType} addressType={addressTypeSelected} onModifiedChanges={onModifiedChanges} />
      <Card title={<>{t("admin.addressType")}<span className="item-count">{addressTypes?.length}</span></>} bordered={false} className="admin-item-card" extra={<div><span className="extra-label" style={{ color: (isEditing ? colorPrimary : colorTextDisabled) }} onClick={toggleEdit}>{isEditing ? t('admin.managing') : t('admin.manage')}</span><Switch checked={isEditing} onClick={toggleEdit} /></div>}>
        <ul className={isEditing ? 'editing' : ''}>
            {addressTypesSorted.map(addressType => 
            <div
                key={addressType.id}>
                <>
                {isEditing && <ListItemEditable addressType={addressType} addressTypeSelected={addressTypeSelected} editAddressTypeClicked={editAddressType} />}
                {!isEditing && <ListItem {...addressType} />}
                </>
            </div>
            )}
        </ul>
        {isEditing && <>
            <div className="spacer"></div>
            <span onClick={createNewAddressType} className="add-new-btn">+ {t("admin.addNewAddressType")}</span>
        </>}
        </Card>
    </>
  )
}