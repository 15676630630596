import { IRfi, IRfiWithDistributions } from '@models/rfi.model';
import { IQueryParams } from '../../models/pagination';
import { http } from '../http/axios.instance';
import { StringORNumber } from './utils.repository';

const path = 'rfis';
const pathExtended = 'rfis-extended';

export const rfiRepository = {
  getRfis: async (queryParams: IQueryParams) => {
    const { sort, page, size, query } = queryParams;
    const requestUrl = `${path}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}${
      query ? `${query}&` : ''
    }cacheBuster=${new Date().getTime()}`;

    const promiseGetAll = await http.get<IRfi[]>(requestUrl);
    return promiseGetAll;
  },
  getRfiById: async (id: StringORNumber) => {
    const requestUrl = `${path}/${id}?cacheBuster=${new Date().getTime()}`;
    const promiseGetByID = await http.get<IRfi>(requestUrl);
    return promiseGetByID;
  },
  createRfi: async (entity: IRfi): Promise<IRfi> => {
    const requestUrl = `${path}`;
    const promiseCreate = await http.post<IRfi>(requestUrl, entity);
    return promiseCreate.data;
  },
  updateRfi: async (id: string | number, entity: IRfi): Promise<IRfi> => {
    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.put<IRfi>(requestUrl, entity);
    return promiseUpdate.data;
  },
  patchRfi: async (id: string | number, entity: IRfi): Promise<IRfi> => {
    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.patch<IRfi>(requestUrl, entity);
    return promiseUpdate.data;
  },
  countOpenRfi: async (projectId: StringORNumber) => {
    const requestUrl = `${path}/open-quantity/project/${projectId}?cacheBuster=${new Date().getTime()}`;
    const promiseCountOpen = await http.get<number>(requestUrl);
    return promiseCountOpen;
  },
  countRfi: async (projectId: StringORNumber) => {
    const requestUrl = `${path}/quantity/project/${projectId}?cacheBuster=${new Date().getTime()}`;
    const promiseCountOpen = await http.get<number>(requestUrl);
    return promiseCountOpen;
  },
  createRfiWithDistributions: async (entity: IRfiWithDistributions): Promise<IRfi> => {
    const requestUrl = `${pathExtended}`;
    const promiseCreate = await http.post<IRfi>(requestUrl, entity);
    return promiseCreate.data;
  },
};