
import { http } from '../http/axios.instance';

const path = 'permissions-controller';

export const permisionsRepository = {
  getPermisions: async () => {
    const requestUrl = `${path}/get-permissions`;

    const promiseGetAll = await http.get<string[]>(requestUrl);
    return promiseGetAll;
  },
}