import { TableCustomized } from '@components/TableCustomized/TableCustomized'
import { Col, Row, Space, theme, Badge } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useProviderColor } from '@HOOKs/UseProviderColor';
import { TERTIARY_TEXT_COLOR } from '@providers/ThemeProvider';
import { useEffect } from 'react';
import { getAddressesList } from '@store/slices/generic-address';
import { useAppDispatch, useAppSelector } from '@store/store';
import { IAddress, IGetAddressRequest } from '@models/address.model';
import { EmptyCellTable } from '@components/Utils/EmptyCellTable';
import { AddressGenericManageList } from './AddressGenericManageList';
import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { AddressReferenceEnum, AddressReferenceEnumListOptions } from '@models/enumerations/address-reference-enum.model';
import { AddresGenericCreate } from './AddressGenericCreate';
import useVisible from '@HOOKs/UseVisible';
import { CustomButtonText } from '@components/Utils/CustomButtonText';
import { useTranslation } from 'react-i18next';

const columns: ColumnsType<IAddress | object> = [
  {
    title: 'Address Type',
    render: (address: IAddress) => <>{address?.addressType?.description || <EmptyCellTable />}</>,
  },
  {
    title: 'Street Name',
    render: (address: IAddress) => {
      const renderText = `${address?.streetAddress ? `${address?.streetAddress},` : ''} ${
        address?.streetAddress2 ? `${address?.streetAddress2},` : ''
      }`;
      return renderText.trimStart() ? renderText : <EmptyCellTable />;
    },
  },
  {
    title: 'City, State and Zip',
    render: (address: IAddress) => {
      const renderText = `${address?.city ? `${address?.city},` : ''} ${address?.stateProvince || ''} ${address?.postalCode || ''}`;
      return renderText.trimStart() ? renderText : <EmptyCellTable />;
    },
  },
];

export interface IAddressGenericUpdateProps {
  referenceId?: StringORNumber;
  referenceType: AddressReferenceEnumListOptions;
}

export const AddressGenericTable = (props: IAddressGenericUpdateProps) => {
  const { referenceId, referenceType } = props;

  const [visibleAddressCreateDrawer, toggleVisibleAddressCreateDrawer] = useVisible();
  const [visibleAddressManageDrawer, toggleVisibleAddressManageDrawer] = useVisible();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const addressesList = useAppSelector(state => state.GenericAddress[referenceType]);

  useEffect(() => {
    if (referenceId) {
      const payload: IGetAddressRequest = {
        referenceId: referenceId,
        referenceType: AddressReferenceEnum[referenceType],
      };
      dispatch(getAddressesList(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Header Table
  const {
    token: { colorPrimaryBg, colorPrimary, fontSizeHeading5 },
  } = theme.useToken();
  const [colorTextTertiary] = useProviderColor(TERTIARY_TEXT_COLOR);

  const header = (
    <>
      <Row justify={'space-between'} align="middle" className="mb-12">
        <Col>
          <Space>
            <span className="font-semibold pl-10" style={{ fontSize: fontSizeHeading5, color: colorTextTertiary }}>
              {t('location.address')}
            </span>

            <Badge
              count={addressesList.data.length}
              size="small"
              className="ml-50 mr-30"
              style={{
                backgroundColor: colorPrimaryBg,
                outline: 'none',
                color: colorPrimary,
              }}
            />
          </Space>
        </Col>
        <Col>
          <Space>
            <CustomButtonText onClick={toggleVisibleAddressManageDrawer}>{t('generic.manage')}</CustomButtonText>
            <CustomButtonText onClick={toggleVisibleAddressCreateDrawer}>{t('generic.add')}</CustomButtonText>
          </Space>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      {header}
      <TableCustomized
        rowKey="id"
        columns={columns}
        dataSource={addressesList.data}
        scroll={{ y: 110 }}
        titleOnEmptyTable={'There are no Address added'}
      />
      <Row>
        <AddresGenericCreate
          open={visibleAddressCreateDrawer}
          toggle={toggleVisibleAddressCreateDrawer}
          referenceId={referenceId}
          referenceType={referenceType}
        />
        <AddressGenericManageList
          open={visibleAddressManageDrawer}
          toggle={toggleVisibleAddressManageDrawer}
          referenceId={referenceId}
          referenceType={referenceType}
        />
      </Row>
    </>
  );
};
