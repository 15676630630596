/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { TabContainer } from '@components/Utils/TabContainer';
import { BryntumGantt, BryntumProjectModel } from '@bryntum/gantt-react';
import { ganttConfig } from './GanttConfig';
import { useResizeDetector } from 'react-resize-detector';
import { useParams } from 'react-router-dom';
import { getProjectDeliverablesDataToGanttChart } from '@store/slices/project-deliverables-gantt';
import { useAppDispatch, useAppSelector } from '@store/store';
import { isArrayWithValues } from '@shared/util/array-util';
import { DomHelper, ProjectModel, ProjectModelConfig } from '@bryntum/gantt';
import { If } from '@components/Utils/Structural';
import { getEntity } from '../../../../../store/slices/projects';
import { useThemeProvider } from '@providers/ThemeProvider';
import './Gannt.scss';
import { ProjectDeliverableFilterContainer } from '../ProjectDeliverableTab/ProjectDeliverableFilters/ProjectDeliverableFilterContainer';
import { getProjectDeliverablesData } from '@store/slices/project-deliverables';

type BryntumGanttProject = ProjectModel | Partial<ProjectModelConfig> | undefined;

// Gantt is doing some calculations internally, copying to make Redux data changeable
export const copy = (data: any[]) => {
  return data && isArrayWithValues(data) ? data?.map(item => ({ ...item })) : [];
};

const calculateOffsetDate = inputDate => {
  const offsetMilliseconds = 8 * 24 * 60 * 60 * 1000 + 6 * 60 * 60 * 1000;
  const inputDateWithOffset = new Date(inputDate.getTime() + offsetMilliseconds);
  return inputDateWithOffset;
};

export const ScheduleTab = () => {
  const ganttRef = useRef<BryntumGantt>(null);

  const projectRef = useRef();

  const dispatch = useAppDispatch();

  const { height: heightContainerDocument = 0, ref: containerDocumentRef } = useResizeDetector();

  const {
    data: { deliverablesFiltered = [] },
  } = useAppSelector(state => state.ProjectDeliverables);

  const {
    data: { projectGanttData },
    loading,
  } = useAppSelector(root => root.ProjectDeliverablesGantt);

  const [filteredProjectGanttData, setFilteredProjectGanttData] = useState(projectGanttData);

  useEffect(() => {
    setFilteredProjectGanttData(projectGanttData);
  }, [projectGanttData]);

  useEffect(() => {
    if (projectGanttData && projectGanttData.tasks) {
      const filteredIds = deliverablesFiltered.map(deliverable => deliverable.id);
      const tasks = projectGanttData.tasks;
      const newFilteredTasks = tasks.filter(item => filteredIds.includes(item.id));
      const newFilteredGanttData = { ...filteredProjectGanttData, tasks: newFilteredTasks };
      setFilteredProjectGanttData(newFilteredGanttData);
    }
  }, [deliverablesFiltered]);

  const { projectId } = useParams<'projectId'>();

  const { entity: projectEntity } = useAppSelector(state => state.Project);

  useEffect(() => {
    projectId && dispatch(getProjectDeliverablesDataToGanttChart(projectId));
  }, [projectId]);

  useEffect(() => {
    calculateVisibleDate();
  }, [projectGanttData]);

  useEffect(() => {
    if (projectId && projectEntity && String(projectEntity.id) !== projectId) {
      dispatch(getEntity(projectId));
    }
  }, [dispatch, projectId, projectEntity]);

  const { themeConfig } = useThemeProvider();

  const [visibleDate, setVisibleDate] = useState(null);

  const calculateVisibleDate = () => {
    if (!projectGanttData || !projectGanttData.tasks || projectGanttData.tasks.length === 0) {
      return; // Early return if no project data is available
    }

    let earliestStartDate = new Date(projectGanttData.tasks[0].startDate);
    let latestEndDate = new Date(projectGanttData.tasks[0].endDate);

    projectGanttData.tasks.forEach(task => {
      const taskStartDate = new Date(task.startDate);
      const taskEndDate = new Date(task.endDate);

      if (taskStartDate < earliestStartDate) {
        earliestStartDate = taskStartDate;
      }

      if (taskEndDate > latestEndDate) {
        latestEndDate = taskEndDate;
      }
    });

    const today = new Date();
    let focusDate;

    if (today >= earliestStartDate && today <= latestEndDate) {
      focusDate = calculateOffsetDate(new Date());
    } else if (today < earliestStartDate) {
      focusDate = earliestStartDate;
    } else if (today > latestEndDate) {
      focusDate = calculateOffsetDate(latestEndDate);
    }

    if (focusDate) {
      setVisibleDate(focusDate);
    }
  };

  useEffect(() => {
    themeConfig?.darkMode ? DomHelper.setTheme('classic-dark') : DomHelper.setTheme('stockholm');
  }, [themeConfig]);

  useEffect(() => {
    projectId && dispatch(getProjectDeliverablesData(projectId));
  }, []);

  return (
    <TabContainer>
      <div className="overflow-scroll h-full" ref={containerDocumentRef}>
        <div style={{ height: heightContainerDocument }}>
          <If condition={!loading && filteredProjectGanttData && filteredProjectGanttData?.project && projectRef && ganttRef}>
            <>
              <ProjectDeliverableFilterContainer />
              <BryntumProjectModel
                ref={projectRef}
                {...filteredProjectGanttData?.project}
                tasks={copy(filteredProjectGanttData?.tasks)}
                dependencies={copy(filteredProjectGanttData?.dependencies)}
              />
              <BryntumGantt ref={ganttRef} {...ganttConfig} visibleDate={visibleDate} project={projectRef as BryntumGanttProject} />
            </>
          </If>
        </div>
      </div>
    </TabContainer>
  );
};
