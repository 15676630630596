import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { IUtil } from "@models/utils";
import { ICustomFieldGroupExtendedUI } from "@models/custom-field-group.model";
import { customFieldsService } from "@services/custom-fields.service";
import { ICustomFieldsGetRequest } from "@models/custom-field.model";
import { CustomFieldGroupTypeEnum, CustomFieldGroupTypeListOptions } from "@models/enumerations/custom-field-group-type-enum.model";
import { asyncLaunchNotification } from "./notification";

interface IStateEntity extends IUtil {
  data: ICustomFieldGroupExtendedUI[] ,
  loading: boolean,
  errorMessage: any
}

interface IState {
  [CustomFieldGroupTypeEnum.LOCATION]: IStateEntity,
  [CustomFieldGroupTypeEnum.SITE]: IStateEntity,
  [CustomFieldGroupTypeEnum.PROJECT]: IStateEntity
}

const getInitialState: IStateEntity = {
  data: [],
  loading: false,
  errorMessage: null
}

const initialState: IState = {
  [CustomFieldGroupTypeEnum.LOCATION]: {...getInitialState },
  [CustomFieldGroupTypeEnum.SITE]: {...getInitialState },
  [CustomFieldGroupTypeEnum.PROJECT]: {...getInitialState }
}

const sliceName: string = "genericCustomFields"

export const getLocationsCustomFiels = createAsyncThunk(
  `${sliceName}/${CustomFieldGroupTypeEnum.LOCATION}/getCustomFields`,
  async (payload: ICustomFieldsGetRequest, thunkAPI) => {
    const data = await customFieldsService.getCustomFieldsData(payload);
    return data;
  }
)

export const getSiteCustomFiels = createAsyncThunk(
  `${sliceName}/${CustomFieldGroupTypeEnum.SITE}/getCustomFields`,
  async (payload: ICustomFieldsGetRequest, thunkAPI) => {
    const data = await customFieldsService.getCustomFieldsData(payload);
    return data;
  }
)

export const getProjectCustomFiels = createAsyncThunk(
  `${sliceName}/${CustomFieldGroupTypeEnum.PROJECT}/getCustomFields`,
  async (payload: ICustomFieldsGetRequest, thunkAPI) => {
    const data = await customFieldsService.getCustomFieldsData(payload);
    return data;
  }
)

export const getCustomFields = createAsyncThunk(
  `${sliceName}/getData`,
  async (payload: ICustomFieldsGetRequest, thunkAPI) => {
    const thunkToApply = selectThunk(payload.referenceType)
    thunkAPI.dispatch(thunkToApply(payload));
  }
)

export const updateCustomFiels = createAsyncThunk(
  `${sliceName}/updateCustomFields`,
  async (payload: ICustomFieldGroupExtendedUI, thunkAPI) => {
    const data = await customFieldsService.saveOrUpdateCustomFieldsGroup(payload);
    if (data) {
      thunkAPI.dispatch(asyncLaunchNotification({
        type: "success",
        config: {
          message: payload?.name || "Success",
          description: "Fields have been successfully updated."
        }
      }));
    }
    return data;
  }
)

export const genericCustomFieldsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reset: (state,) => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getLocationsCustomFiels, getSiteCustomFiels, getProjectCustomFiels), (state, action) => {
        const entityIndex = getCustomFieldGroupType(action.type);
        state[entityIndex].data = action.payload;
        state[entityIndex].errorMessage = null;
        state[entityIndex].loading = false;
      })
      .addMatcher(isPending(getLocationsCustomFiels, getSiteCustomFiels, getProjectCustomFiels), (state, action) => {
        const entityIndex = getCustomFieldGroupType(action.type);
        state[entityIndex].errorMessage = null;
        state[entityIndex].loading = true;
      })
      .addMatcher(isRejected(getLocationsCustomFiels, getSiteCustomFiels, getProjectCustomFiels), (state, action) => {
        const entityIndex = getCustomFieldGroupType(action.type);
        state[entityIndex].errorMessage = action?.error?.message || null;
        state[entityIndex].loading = false;
      });
  }
});

// Helpers
const getCustomFieldGroupType = (actionString: string) => actionString.split("/")[1] as CustomFieldGroupTypeListOptions;

export const selectThunk = (customFieldGroupType: CustomFieldGroupTypeListOptions) => {
  const mapThunksEnabled = {
    [CustomFieldGroupTypeEnum.LOCATION]: getLocationsCustomFiels,
    [CustomFieldGroupTypeEnum.SITE]: getSiteCustomFiels,
    [CustomFieldGroupTypeEnum.PROJECT]: getProjectCustomFiels,
  }
  return mapThunksEnabled[customFieldGroupType as keyof typeof mapThunksEnabled];
}

// Action Reducers
export const { reset } = genericCustomFieldsSlice.actions;

// Reducer
export default genericCustomFieldsSlice.reducer;