import { http } from "../infrastructure/http/axios.instance";
import { UserRepository } from '../infrastructure/repositories/user.repository';
import { STORAGE_USER_KEY } from "../constants";

export interface IUserRequestAuthenticate {
  username: string;
  password: string;
  rememberMe?: boolean;
}

export interface ITokenResponse {
  id_token: string;
}

export const Authenticate = (user: IUserRequestAuthenticate) => {
  const data = `username=${encodeURIComponent(user.username)}&password=${encodeURIComponent(user.password)}&remember-me=${user.rememberMe}&submit=Login`
  return http.post('/authentication', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
}

export const logout = () => {
  localStorage.removeItem(STORAGE_USER_KEY);

  http.post<any>('/logout', {}).then(() => {
    http.get<any>('/authenticate');
  }
  );
};

export const authService = {
  getUser: UserRepository.getuser,
  logout: logout,
}

export const isLoggedIn = () => {
  const userData = localStorage.getItem(STORAGE_USER_KEY);
  return !!userData;
}
