import { createContext, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ADMIN_ID, FORBIDDEN_CODE, STORAGE_LOGIN_REDIRECT_KEY, STORAGE_USER_KEY } from "../constants";
import { redirectIfExistParam, setLocalStorageValue, useLocalStorage } from "../HOOKs/UseLocalStorage";
import { authService, isLoggedIn } from "../services/auth.service";
import { asyncLaunchDisabledAccountNotification, close as closeNotification} from "../store/slices/disabled-account-notification";
import { AppDispatch, useAppSelector } from "../store/store";
import { AxiosError } from 'axios';
import { getPermisions } from '../store/slices/permisions';
import { userSettingsService } from "@services/user-settings.service";
import { usePreferencesProvider } from '@providers/PreferencesProvider';
import { IUser } from '@models/user.model';

interface IUserContext {
  user: IUser,
  isAdmin: () => boolean,
  checkLoginLocally(): void,
  getUserData(): Promise<unknown>,
  getUserDataWithoutRedirect(): Promise<unknown>,
  logout: () => Promise<unknown>
}

const AuthContext = createContext({} as IUserContext);

export const AuthProvider = ({ children }: any) => {

  const { setPreferences, setAppearanceDefault } = usePreferencesProvider();

  const [user, setUser] = useLocalStorage(STORAGE_USER_KEY, null) as [IUser, (user: IUser | null) => void];
  
  const { open: openDisabledAccountNotification} = useAppSelector((state) => state.DisabledAccountNotification);

  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const isAdmin = () => Boolean(`${user.id}` === ADMIN_ID && user?.authorities?.includes("ROLE_ADMIN"));

  const logout = () => {
    return new Promise((res) => {
      setUser(null);
      setLocalStorageValue(STORAGE_LOGIN_REDIRECT_KEY, null);
      authService.logout();
      if (openDisabledAccountNotification) {
        dispatch(closeNotification())
      }
      setAppearanceDefault();
      navigate("/login", { replace: true });

      // Logout Intercom Helpscout Integration
      window && window?.Intercom &&  window?.Intercom!('shutdown')

      res(true);
    });
  }

  const requestGetPermisions = (userLogged: IUser) => {
    dispatch(getPermisions());
    return userLogged
  }

  const requestGetPreferences = async (userLogged: IUser) => {
    if (userLogged && userLogged.id) {
      const userPreferences = await userSettingsService.getUserSettings(userLogged?.id);
      setPreferences(userPreferences) 
    };

    return userLogged
  }

  const catchErrorForbidden = (error: AxiosError | any, reject: any) => {
    if (error?.response?.status === FORBIDDEN_CODE) {
      dispatch(asyncLaunchDisabledAccountNotification({ onOk: logout }));
      reject();
    }
  }

  const value = {
    user,
    isAdmin: isAdmin,
    checkLoginLocally() {
      if (isLoggedIn()) redirectIfExistParam(navigate, true);
    },
    getUserData() {
      return new Promise(async (resolve, reject) => {
        await authService.getUser().then((userLogged) => {
          setUser(userLogged);
          return userLogged;
        })
        .then(requestGetPreferences)
        .then(requestGetPermisions)
        .then((userLogged) => userLogged ? redirectIfExistParam(navigate, true) : navigate("/login") )
        .catch((error) => catchErrorForbidden(error, reject) );
        resolve(true);
      });
    },
    getUserDataWithoutRedirect() {
      return new Promise(async (resolve, reject) => {
        await authService.getUser().then((userLogged) => {  
          setUser(userLogged);
          return userLogged;
        })
        .then(requestGetPreferences)
        .then(requestGetPermisions)
        .catch((error) => catchErrorForbidden(error, reject) );
        resolve(true);
      });
    },
    logout: logout
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};