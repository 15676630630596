import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Button, Col, Row, Form, Input, theme, Switch } from "antd";
import { useAppDispatch, useAppSelector } from "@store/store";
import { TabContainer } from "@components/Utils/TabContainer"
import { SearchTable } from "@components/Utils/SearchTable";
import { BackButton } from "@components/Utils/BackButton";
import { getTemplateById, getTemplateDeliverableApprovals } from "@store/slices/project-templates-extended";
import { getEntities as getDeliverables } from "@store/slices/deliverable"; 
import { getTemplateDeliverables } from "@store/slices/project-templates-extended";
import { projectsService } from '@services/projects.service';

import "@styles/components/template-edit.less";
// dnd-kit
import { DndContext, DragEndEvent, DragOverEvent, DragOverlay } from '@dnd-kit/core';
import { rectIntersection, } from '@dnd-kit/core';
import { arrayMove, } from '@dnd-kit/sortable';

// dnd-kit helpers
import { DeliverableCard, SystemDeliverable } from './SystemDeliverable';
import { DropZone } from './DropZone';
import { MilestoneTable } from './MilestoneTable';
import { MilestoneTableHeader } from "./MilestoneTableHeader";
import { ITemplateDeliverable } from "@models/template-deliverable.model";
import { getPhases } from "@store/slices/phase";
import { IProjectTemplateSave } from "@models/project-template-model";
import { getUsers } from "@store/slices/users-extended";
import { getRoles } from "@store/slices/role";
import { ITemplateDeliverableApproval } from "@models/template-deliverable-approval";
import { asyncLaunchNotification } from "@store/slices/notification";

export interface ITemplateDeliverableEdit {
    id: string;
    templateDeliverable: ITemplateDeliverable;
    initiallyExpanded: boolean;
}

export const TemplateEdit = () => {

    const { token: { 
        colorPrimary, 
        colorBgContainer, 
        colorBorderSecondary, 
        colorText, 
        colorPrimaryBg, 
        colorWarningText,
        colorFillTertiary,
        colorFillSecondary,
        colorTextSecondary,
        colorTextTertiary,
        colorSuccessText,
        colorWarningBg,
        colorWarningBorder,
        fontSize,
        colorFillQuaternary } } = theme.useToken();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { template } = useAppSelector((state) => state.ProjectTemplatesExtended);
    const { entities: deliverables } = useAppSelector((state) => state.Deliverables);
    const { deliverablesList, deliverableApprovalsList } = useAppSelector((state) => state.ProjectTemplatesExtended);
    const { entities: phases } = useAppSelector((state) => state.Phase);
    const { usersList: allUsers } = useAppSelector((state) => state.UsersExtended);
    const { entities: allRoles } = useAppSelector((state) => state.Roles);

    // DnD
    const [activeItem, setActiveItem] = useState<ITemplateDeliverableEdit | null>(null);
    const [activeItemOrigin, setActiveItemOrigin] = useState<string | null>(null)

    const [systemDeliverables, setSystemDeliverables] = useState<ITemplateDeliverableEdit[]>([])
    const [systemDeliverablesFiltered, setSystemDeliverablesFiltered] = useState<ITemplateDeliverableEdit[]>([])
    const [projectDeliverables, setProjectDeliverables] = useState<ITemplateDeliverableEdit[]>([])
    const [filterValue, setFilterValue] = useState<string>('')
    const [unsavedChangesCount, setUnsavedChangesCount] = useState<number>(0)
    const [templateId, setTemplateId] = useState<number>(0)
    const [templateName, setTemplateName] = useState<string>('')
    const [templateActive, setTemplateActive] = useState<boolean>(false)
    const [templateLoaded, setTemplateLoaded] = useState<boolean>(false)
    const [draggingFromSystem, setDraggingFromSystem] = useState<boolean>(false)

    useEffect(() => {
        setTemplateId(template?.id || 0)
        setTemplateName(template?.name || '')
        setTemplateActive(template?.active || false)
        setTemplateLoaded(true)
    }, [template])

    const onChangeValueToSearch = (e: any) => {
        setFilterValue(e.target.value);
    }

    const handleOnSearch = () => {
        
    }

    useEffect(() => {
        const deliverablesForEdit = deliverables.map(d => {
            return {
                id: `${d.id}`,
                templateDeliverable: {
                    id: -1,
                    deliverable: d,
                    template: { id: Number(params.templateId) },
                    approvals: []
                },
                initiallyExpanded: false
            }
        })
        setSystemDeliverables(deliverablesForEdit)
    }, [deliverables, params.templateId]);

    useEffect(() => {
        const lcFilterValue = filterValue.toLowerCase();
        const systemDeliverablesF = systemDeliverables.filter(x => x.templateDeliverable?.deliverable?.name?.toLowerCase().includes(lcFilterValue))
        setSystemDeliverablesFiltered(systemDeliverablesF);
    }, [systemDeliverables, filterValue]);

    useEffect(() => {
        const deliverablesForEdit = deliverablesList.map(d => {
            return {
                id: `${d.deliverable?.id}`,
                templateDeliverable: {
                    ...d,
                    approvals: deliverableApprovalsList.filter(x => x.dependency?.id === d.id).map(a => { return {...a, key: a.id} })
                },
                initiallyExpanded: false
            }
        })
        setProjectDeliverables(deliverablesForEdit);
    }, [deliverablesList, deliverableApprovalsList]);

    const setDeliverableOrders = (newDeliverableId: string | number | undefined) => {
        const orderedProjectDeliverables = projectDeliverables.map((d, i) => {
            return {
                id: d.id,
                templateDeliverable: {
                    ...d.templateDeliverable,
                    order: i + 1
                },
                initiallyExpanded: (newDeliverableId === d.id ? true : d.initiallyExpanded)
            }
        });
        setProjectDeliverables(orderedProjectDeliverables);
    }

    const updateApprovals = (deliverableId: string, approvals: ITemplateDeliverableApproval[]) => {
        const updateProjectDeliverables = projectDeliverables.map((d, i) => {
            return {
                id: d.id,
                templateDeliverable: {
                    ...d.templateDeliverable,
                    approvals: deliverableId === d.id ? approvals : d.templateDeliverable.approvals
                },
                initiallyExpanded: d.initiallyExpanded
            }
        });
        setProjectDeliverables(updateProjectDeliverables);
    }

    const handleDragEnd = ({ active, over }: DragEndEvent) => {
        if (!over) {
          setActiveItem(null);
          setActiveItemOrigin(null)
          return;
        }
    
        if (over.id === "current") {
          //getItem(active.id)?.color;
        } else if (over.id === "favorite") {
          //getItem(active.id)?.color;
        }
        setActiveItem(null);
        setActiveItemOrigin(null)

        setDeliverableOrders(draggingFromSystem ? active?.id : undefined);
      };
    
      const onDragOver = ({ active, over }: DragOverEvent) => {
        if (!over) {
          if (activeItemOrigin === null) return;
          const indx = projectDeliverables.findIndex(x => `${x.id}` === `${active.id}`);
          if (indx === -1) return
          setProjectDeliverables(projectDeliverables.filter(x => `${x.id}` !== `${active.id}`))
          return;
        }
    
        if ((over.id === "favorite" || over.id === "current") && activeItemOrigin !== null) {
          // we're not dragging over the pallette, so we may need to remove the item from the pallette
          const active_indx = projectDeliverables.findIndex(x => `${x.id}` === `${active.id}`);
          if (active_indx === -1) return;
          setProjectDeliverables(projectDeliverables.filter(x => `${x.id}` !== `${active.id}`))
    
          return
    
        }
    
        const active_indx = projectDeliverables.findIndex(x => `${x.id}` === `${active.id}`);
        const over_indx = projectDeliverables.findIndex(x => `${x.id}` === `${over.id}`);
    
        if ((active_indx !== -1) && (over_indx !== -1)) {
          if (active_indx === over_indx) return;
          setProjectDeliverables(arrayMove(projectDeliverables, active_indx, over_indx))
        }
        else if (over.id === "pallette" || (over_indx !== -1 && active_indx === -1)) {
            addDeliverableToTemplate(`${active.id}`)
        }
      }

    const navigateBack = () => {
        navigate('/admin/module-configuration/project-deliverables/templates')
    }

    useEffect(() => {
        if (params.templateId) {
            dispatch(getTemplateById(params.templateId))
            dispatch(getTemplateDeliverables(params.templateId))
            dispatch(getTemplateDeliverableApprovals(`${params.templateId}`))
        }
        dispatch(getDeliverables({}));
        dispatch(getPhases());
        dispatch(getUsers());
        dispatch(getRoles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.templateId]);

    const hasPendingIssues = projectDeliverables.filter(x => !x.templateDeliverable.phase || !x.templateDeliverable.duration).length > 0;

    const removeDeliverable = (id: any) => {
        const idx = projectDeliverables.findIndex(x => x.id === id);
        if (idx !== -1) {
            const newList = [...projectDeliverables];
            newList.splice(idx, 1);
            newList.forEach((d) => {
                if (d.templateDeliverable.dependency?.deliverable?.id &&
                    `${d.templateDeliverable.dependency?.deliverable?.id}` === `${id}`) {
                    d.templateDeliverable.dependency = null;
                }
            })
            setProjectDeliverables(newList);
        }
    }

    const onChangeDeliverableValue = (id: any, field: string, value: any): any => {
        const idx = projectDeliverables.findIndex(x => x.id === id);
        if (idx !== -1) {
            setUnsavedChangesCount(unsavedChangesCount+1)
            const newList = [...projectDeliverables].map((d, i) => {
                const dependency = (i === idx && field === 'dependency' ? projectDeliverables.find(x => x.id === value)?.templateDeliverable : d.templateDeliverable.dependency);
                const dependencyOffset = (i === idx && field === 'dependencyOffset' ? Number(value) : d.templateDeliverable.dependencyOffset);
                return {
                    id: d.id,
                    templateDeliverable: {
                        ...d.templateDeliverable,
                        duration: (i === idx && field === 'duration' ? Number(value) : d.templateDeliverable.duration),
                        phase: (i === idx && field === 'phase' ? { id: value, name: phases.find(x => x.id === value)?.name } : d.templateDeliverable.phase),
                        dependencyOffset: dependency ? dependencyOffset : null,
                        dependency: dependency,
                    },
                    initiallyExpanded: d.initiallyExpanded
                }
            });
            setProjectDeliverables(newList);
        }
    }

    const addDeliverableToTemplate = (id: string) => {
        if (projectDeliverables.findIndex(x => `${x.id}` === `${id}`) === -1) {
            const systemDeliverableIdx = systemDeliverables.findIndex(x => `${x.id}` === `${id}`);
            if (systemDeliverableIdx !== -1) {
                const isSystemDeliverableOpening = systemDeliverables[systemDeliverableIdx].templateDeliverable.deliverable?.isOpeningMilestone;
                const openingExits = projectDeliverables.some(x => x.templateDeliverable?.deliverable?.isOpeningMilestone);
                if (isSystemDeliverableOpening && openingExits) {
                    dispatch(asyncLaunchNotification({
                        type: "error",
                        config: {
                          message: `Deliverable could not be added`,
                          description: `Only one opening milestone allowed per template.`
                        }
                    }))
                    return;
                }
                const newDeliverable = {
                    ...systemDeliverables[systemDeliverableIdx],
                    initiallyExpanded: false
                }
                setProjectDeliverables([...projectDeliverables, newDeliverable]);
            }
        }
    }

    const addDeliverableToTemplateAndSetOrder = (id: string) => {
        if (projectDeliverables.findIndex(x => `${x.id}` === `${id}`) === -1) {
            const systemDeliverableIdx = systemDeliverables.findIndex(x => `${x.id}` === `${id}`);
            if (systemDeliverableIdx !== -1) {
                const isSystemDeliverableOpening = systemDeliverables[systemDeliverableIdx].templateDeliverable.deliverable?.isOpeningMilestone;
                const openingExits = projectDeliverables.some(x => x.templateDeliverable?.deliverable?.isOpeningMilestone);
                if (isSystemDeliverableOpening && openingExits) {
                    dispatch(asyncLaunchNotification({
                        type: "error",
                        config: {
                          message: `Deliverable could not be added`,
                          description: `Only one opening milestone allowed per template.`
                        }
                    }))
                    return;
                }
                const newDeliverable = {
                    ...systemDeliverables[systemDeliverableIdx],
                    initiallyExpanded: true
                };
                const orderedProjectDeliverables = [...projectDeliverables, newDeliverable].map((d, i) => {
                    return {
                        id: d.id,
                        templateDeliverable: {
                            ...d.templateDeliverable,
                            order: i + 1
                        },
                        initiallyExpanded: d.initiallyExpanded
                    }
                });
                setProjectDeliverables(orderedProjectDeliverables);
            }
        }
    }

    const saveTemplate = async () => {
        // Check basic errors
        let error = '';
        if (!projectDeliverables || projectDeliverables.length === 0) {
            error = `Please add at least one deliverable to save.`;
        } else if (hasPendingIssues) {
            error = `Please resolve pending issues before saving.`;
        } else if (!templateName || templateName.length === 0) {
            error = `Please enter a name for the template.`;
        } else {
            // Check for circular dependencies
            for (let j = 0; j < projectDeliverables.length; j++) {
                let dependency = projectDeliverables[j].templateDeliverable.dependency
                let dependencyCount = 0;
                let errorFound = false;
                while (dependency) {
                    // eslint-disable-next-line no-loop-func
                    const deliverable = projectDeliverables.find(x => x.templateDeliverable.deliverable?.id === dependency?.deliverable?.id)
                    dependency = deliverable?.templateDeliverable?.dependency
                    dependencyCount++;
                    if (dependencyCount > projectDeliverables.length) {
                        error = `Circular dependency detected. Please adjust dependencies.`;
                        errorFound = true;
                        break;
                    }
                }
                if (errorFound) {
                    break;
                }
            }
        }

        if (error) {
            dispatch(asyncLaunchNotification({
                type: "error",
                config: {
                  message: `Changes could not be saved`,
                  description: error
                }
            }))
            return;
        }
        const templateSaveData: IProjectTemplateSave = {
            id: templateId,
            name: templateName,
            active: templateActive,
            templateDeliverables: projectDeliverables.map(x => x.templateDeliverable)
        };
        const response = await projectsService.saveTemplateComplete(templateSaveData);
        if (response?.data?.id) {
            dispatch(
                asyncLaunchNotification({
                    type: "success",
                    config: {
                        message: `Project Template Saved`,
                        description: `Template saved successfully`
                    }
                    })
            );
            setUnsavedChangesCount(0);
            setTemplateId(response?.data?.id);
        }
    }

    const clearFilter = () => {
        setFilterValue('');
    }

    return (
        <>
            <TabContainer>
                {(templateLoaded === true) && <div className="template-edit">
                    <div className="flex flex-col overflow-auto">
                        <Row>
                            <Col span={24}>
                                <div className="flex flex-row items-center pl-10 pt-11 pb-11" style={{ borderTop: `1px solid ${colorBorderSecondary}`, borderBottom: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                                    <BackButton onClick={navigateBack} >Templates</BackButton>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <DndContext
                        onDragStart={({ active }) => {
                            const dfs = projectDeliverables.findIndex(x => `${x.id}` === `${active.id}`) === -1;
                            setDraggingFromSystem(dfs)
                            setActiveItem(systemDeliverables.find(x => `${x.id}` === `${active.id}`) || null)
                        }}
                        onDragOver={onDragOver}
                        onDragCancel={() => {
                            setActiveItem(null)
                        }}
                        onDragEnd={handleDragEnd}
                        collisionDetection={rectIntersection}>
                        <div className="flex flex-row overflow-auto h-full">
                            <div style={{ width: '346px', height: '100%', borderRight: `1px solid ${colorBorderSecondary}`, background: `${colorBgContainer}` }}>
                                <div className="flex flex-row" style={{ gap: '0.25rem', borderBottom: `1px solid ${colorBorderSecondary}`, padding: '1rem' }}>
                                    <div className="deliverables-list-title" style={{ color: colorText }}>
                                        List of Deliverables
                                    </div>
                                    <div>
                                        <Badge 
                                            count={<span style={{ color: colorPrimary }}>{systemDeliverables.length}</span>} 
                                            size="small" 
                                            rootClassName='ml-15' 
                                            style={{ 
                                                display: "flex", 
                                                alignItems: "center",
                                                backgroundColor: colorPrimaryBg, 
                                                paddingLeft: 8, 
                                                paddingRight: 8, 
                                                height: 20, 
                                                borderRadius: 100 
                                            }} 
                                        />
                                    </div>
                                </div>
                                <div style={{ paddingTop: '1rem' }}>
                                    <SearchTable 
                                        className="ml-20 mr-20" 
                                        style={{ width: 'calc(100% - 40px)' }} 
                                        placeholder="Quick Filter"
                                        handleOnSearch={handleOnSearch}
                                        onChange={onChangeValueToSearch}
                                        onClear={clearFilter} />
                                </div>
                                <div className="deliverables-list-container">
                                    <DropZone id="current">
                                        {systemDeliverablesFiltered.map(d => 
                                            {
                                                const exists = projectDeliverables.findIndex(x => `${x.id}` === `${d.id}`) !== -1;
                                                const existsKey = exists ? 'ext-' : '';
                                                return <SystemDeliverable 
                                                    deliverable={d.templateDeliverable.deliverable || {}} 
                                                    key={`${existsKey}${d.id}`} 
                                                    id={`${existsKey}${d.id}`}
                                                    existsInTemplate={exists}
                                                    addDeliverableToTemplate={addDeliverableToTemplateAndSetOrder} />
                                            }
                                        )}
                                    </DropZone>
                                </div>
                            </div>
                            <div style={{ flex: 1, height: 'calc(100% - 57px)', overflow: 'scroll' }}>
                                <div style={{ background: `${colorBgContainer}`, padding: '1rem', paddingRight: '1.5rem'  }}>
                                    <div style={{ borderBottom: `1px solid ${colorBorderSecondary}` }}>
                                        <div className="flex flex-row" style={{ gap: '0.25rem' }}>
                                            <div style={{ lineHeight: '28px', fontSize: '20px', fontWeight: 500, color: colorText, flex: 1, paddingBottom: '12px' }}>
                                                {template.name}
                                            </div>
                                            <div>
                                                <Button type='default' style={{ marginRight: '10px' }} onClick={navigateBack}>
                                                    Cancel
                                                </Button>
                                                <Button type='primary' onClick={saveTemplate}>
                                                    Save
                                                </Button>
                                                {(unsavedChangesCount > 0) && <Badge color='red' count={unsavedChangesCount} style={{ position: 'absolute', marginTop: '-25px', marginRight: '-8px' }} />}
                                            </div>
                                        </div>
                                        {(unsavedChangesCount > 0) && <div style={{ color: colorWarningText, textAlign: 'right', marginBottom: '8px' }}>There are unsaved changes</div>}
                                    </div>
                                    <Row gutter={16} style={{ marginTop: '1rem' }}>
                                        <Col span={14}>
                                            <Form layout={'vertical'}>
                                                <Form.Item
                                                    label="Template Name"
                                                    rules={[
                                                        { required: true, message: "Name field is Required" },
                                                    ]}
                                                >
                                                    <Input
                                                        value={templateName} 
                                                        placeholder="Name" 
                                                        allowClear 
                                                        onChange={(e) => { setTemplateName(e.target.value) }}
                                                        style={{ width: '100%' }} />
                                                </Form.Item>
                                            </Form>
                                            <div>
                                                <Row gutter={16}>
                                                    <Col span={"none"} className="pr-0" style={{ color: colorTextSecondary, opacity: (!hasPendingIssues ? 1 : 0.4) }}>
                                                        Template Status:
                                                    </Col>
                                                    <Col style={{ color: (templateActive ? colorSuccessText : colorTextTertiary), opacity: (!hasPendingIssues ? 1 : 0.4) }}>
                                                        {(templateActive ? 'Active' : 'Unpublished')}
                                                    </Col>
                                                    <Col span={"none"} className="pr-0" style={{ flex: '1', textAlign: 'right' }}>
                                                        <Form.Item valuePropName="checked">
                                                            <Switch 
                                                                checked={templateActive} disabled={hasPendingIssues} style={{ marginBottom: '10px' }} onChange={(e) => { setTemplateActive(e) }}/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            {(hasPendingIssues === true) && <div style={{ border: `1px solid ${colorWarningBorder}`, backgroundColor: colorWarningBg, borderRadius: '0.5rem', padding: '1rem', color: colorWarningText }}>
                                                You can't activate this template until you've set all the pending values in the deliverables
                                            </div>}
                                        </Col>
                                        <Col span={10} style={{ border: `1px solid ${colorFillSecondary}`, backgroundColor: colorFillTertiary, borderRadius: '0.5rem', padding: '1rem', color: colorTextSecondary }}>
                                            <div className="flex flex-col" style={{ height: '100%' }}>
                                                <div style={{ flex: 1 }}>
                                                    <ol style={{ paddingLeft: '20px' }}>
                                                        <li>Add your deliverables from the list to include them in this template.</li>
                                                        <li>Choose the duration for each one.</li>
                                                        <li>Make sure it's active so you can use it</li>
                                                    </ol>
                                                </div>
                                                <div style={{ flex: 0, color: colorTextTertiary, borderTop: `1px solid ${colorFillSecondary}`, paddingTop: '1rem', fontSize: '12px', lineHeight: '20px' }}>
                                                    Note: Any changes to this template will <strong>not</strong> be applied retroactively.
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div style={{ padding: '1rem' }}>
                                    <div className="flex flex-row" style={{ borderRadius: 4, border: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                                        <span className="pt-10 pb-10 pl-20 font-medium" style={{ color: colorText, fontSize: fontSize + 2 }} >Deliverables for this template</span>
                                        <Badge
                                            count={projectDeliverables.length}
                                            className='ml-10 mt-10'
                                            style={{ backgroundColor: colorPrimary, borderColor: colorFillQuaternary }} />
                                    </div>
                                </div>
                                <div>
                                    <MilestoneTableHeader />
                                    <MilestoneTable 
                                        templateDeliverables={projectDeliverables} 
                                        onRemove={removeDeliverable}
                                        onChangeValue={onChangeDeliverableValue}
                                        phases={phases}
                                        allUsers={allUsers}
                                        allRoles={allRoles}
                                        updateApprovals={updateApprovals} />
                                    <DragOverlay>
                                        {activeItem ? <DeliverableCard
                                                        id={`${activeItem.id}`} 
                                                        deliverable={activeItem.templateDeliverable.deliverable || {}} 
                                                        existsInTemplate={projectDeliverables.findIndex(x => `${x.id}` === `${activeItem.id}`) !== -1} 
                                                        /> : null}
                                    </DragOverlay>
                                </div>
                            </div>
                        </div>
                    </DndContext>
                </div>}
            </TabContainer>
        </>
    )
}