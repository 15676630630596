import { budgetExtendedRepository } from '@infrastructure/repositories/budget-extended.repository';

export const budgetExtendedService = {
  getLatestByProjectId: budgetExtendedRepository.getLatestByProjectId,
  createByTemplate: budgetExtendedRepository.createByTemplate,
  getBudgetSummary: budgetExtendedRepository.getBudgetSummary,
  submitForApproval: budgetExtendedRepository.submitForApproval,
  getBudgetApprovers: budgetExtendedRepository.getBudgetApprovers,
  handleApproval: budgetExtendedRepository.handleApproval,
  exportToExcel: budgetExtendedRepository.exportToExcel,
  exportToPdf: budgetExtendedRepository.exportToPdf,
  createRevision: budgetExtendedRepository.createRevision,
  getAllByProjectId: budgetExtendedRepository.getAllByProjectId,
  getOneByProjectId: budgetExtendedRepository.getOneByProjectId
};
