import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getPhases, reorderPhases } from "@store/slices/phase";
import { Card, Switch, theme } from "antd";
import useVisible from "@HOOKs/UseVisible";
import { EditOutlined } from "@ant-design/icons";
import { NEUTRAL_7_COLOR } from "@providers/ThemeProvider";
import "@styles/components/admin-item-card.less";
import { asyncLaunchNotification } from "@store/slices/notification";
import { DragHandle } from "@components/Icons/DragHandle";
import { IPhase } from "@models/phase.model";
import { PhaseEdit } from "./PhaseEdit";
import { useTranslation } from "react-i18next";

export const PhaseCard = (props: any) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { token: { colorPrimary, colorTextDisabled, colorBorderSecondary, colorBgBase, colorText } } = theme.useToken();

  const loadPhases = () => {
    dispatch(
      getPhases()
    );
  }

  useEffect(() => {
    loadPhases();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [sortedPhases, setSortedPhases] = useState<IPhase[]>([])

  const { entities: phases } = useAppSelector((state) => state.Phase);

  useEffect(() => {
    setSortedPhases(JSON.parse(JSON.stringify(phases)));
  }, [phases]);

  const [openEditPhase, toogleEditPhase] = useVisible();
  const [phaseSelected, setPhaseSelected] = useState<IPhase>({})
  const [isEditing, setIsEditing] = useState<boolean>(false)

  useEffect(() => {
    if (!openEditPhase) {
      setPhaseSelected({})  
    }
  }, [openEditPhase]);

  const onModifiedChanges = (phase: IPhase) => {
    loadPhases();
  }

  const toggleEdit = () => {
    setIsEditing(!isEditing)
  };

  const editPhase = (phase: IPhase) => {
    setPhaseSelected(phase)
    toogleEditPhase()
  }

  const createNewPhase = () => {
    const orders = phases.map(x => Number(x.order))
    const maxOrder = Math.max(...orders)
    setPhaseSelected({ order: maxOrder + 1 })
    toogleEditPhase();
  }

  const [draggedItem, setDraggedItem] = useState<IPhase | null>(null);

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>, item: IPhase) => {
    setDraggedItem(item);
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>, item: IPhase) => {
    event.preventDefault();
    setSortedPhases((prevItems) => {
      const newItems = [...prevItems];
      const draggedItemIndex = prevItems.findIndex((i) => i.id === draggedItem?.id);
      const itemIndex = prevItems.findIndex((i) => i.id === item.id);
      newItems.splice(draggedItemIndex, 1);
      newItems.splice(itemIndex, 0, draggedItem!);
      return newItems;
    });
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
    const ids = sortedPhases.map(s => Number(s.id));
    if (sortedPhases.filter(s => Number(s.id)).length !== ids.length) {
      dispatch(asyncLaunchNotification({
        type: "error",
        config: {
            message: `Invalid IDs`,
            description: `IDs are invalid. Please refresh and try again.`
        }
      }));
      return false;
    }
    dispatch(reorderPhases(ids));
  };

  const ListItem = (phase: IPhase) => {
    return <li style={{ color: NEUTRAL_7_COLOR, paddingBottom: '4px' }}>{phase.name}</li>
  }
  
  const ListItemEditable = (phase: IPhase) => {
    return <div style={{ display: 'flex', backgroundColor: colorBgBase, border: `1px solid ${colorBorderSecondary}`, borderRadius: '4px', padding: '4px 8px', marginBottom: '4px' }}>
      <div>
        <div style={{ paddingTop: '4px', marginRight: '8px' }}><DragHandle /></div>
      </div>
      <div style={{ paddingTop: '3px', color: colorText }}>
        {phase.name}
      </div>
      <span style={{ flex: '1', textAlign: 'right' }}>
        <span onClick={() => editPhase(phase)} style={{ cursor: 'pointer', color: colorPrimary }}>
          <EditOutlined /> {t('generic.edit')}
        </span>
      </span>
    </div>
  }
  
  return (
    <>
      <PhaseEdit 
        open={openEditPhase} 
        toogle={toogleEditPhase} 
        phase={phaseSelected} 
        onModifiedChanges={onModifiedChanges} />
      <Card title={<>{t('generic.phase')}<span className="item-count">{phases?.length}</span></>} bordered={false} className="admin-item-card" extra={<div><span className="extra-label" style={{ color: (isEditing ? colorPrimary : colorTextDisabled) }} onClick={toggleEdit}>{isEditing ? 'Managing' : 'Manage'}</span><Switch checked={isEditing} onClick={toggleEdit} /></div>}>
        <ul style={{ paddingLeft: '15px' }}>
            {sortedPhases.map(phase => 
            <div
                draggable={isEditing}
                onDragStart={(event) => handleDragStart(event, phase)}
                onDragEnter={(event) => handleDragEnter(event, phase)}
                onDragEnd={handleDragEnd} 
                key={phase.id}>
                <>
                {isEditing && <ListItemEditable {...phase} />}
                {!isEditing && <ListItem {...phase} />}
                </>
            </div>
            )}
        </ul>
        {isEditing && <>
            <div className="spacer"></div>
            <span onClick={createNewPhase} className="add-new-btn">+ {t('admin.addNew')}</span>
        </>}
        </Card>
    </>
  )
}