/* eslint-disable react-hooks/exhaustive-deps */
import { useStomp } from '@HOOKs/UseStomp';
import { EmptyCommentSvgIcon } from '@components/Icons/EmptyCommentSvgIcon';
import { PAGINATION_SIZE_DEFAULT_IN_COMMENTS } from '@constants/core';
import { COLOR_TEXT_BASE, WHITE, useThemeProvider } from '@providers/ThemeProvider';
import { isArrayWithValues } from '@shared/util/array-util';
import { getEntities as getCommentsRichText, reset as resetComments } from '@store/slices/comment-v2';
import { useAppDispatch, useAppSelector } from '@store/store';
import { Avatar, Badge, Button, Col, ConfigProvider, Divider, Row, Spin, Typography, theme } from 'antd';
import { useEffect, useState } from 'react';
import { configuration } from '../../environments/env';
import { ICommentRichText } from '../../models/comment.model';
import { CommentReferenceEnum } from '../../models/enumerations/comment-reference-enum.model';
import { useAuth } from "../../providers/AuthProvider";
import { Comment } from './Comment';
import { CommentGenerator } from './CommentGenerator';
import { useParams } from 'react-router-dom';
import { getProjectDeliverablesData } from '@store/slices/project-deliverables';


export interface ICommentProps {
  entityType: CommentReferenceEnum
  referenceId: number,
  title?: string
}

export default function CommentsList(props: ICommentProps) {

  const { token: { colorText, colorFillQuaternary, colorPrimary, colorBorder, colorTextQuaternary } } = theme.useToken();

  const dispatch = useAppDispatch();

  const { themeConfig } = useThemeProvider();

  const { referenceId, entityType, title } = props;

  const { entities: commentListRichText, totalItems, loading } = useAppSelector((state) => state.CommentVersion2Slice)

  const { user: userLogged } = useAuth();

  const [pageCounter, setPageCounter] = useState<number>(0);

  // suscribe to notifications
  const { subscribe, isConnected } = useStomp({
    wsUrl: configuration.API_URL.replace("/api/", "/websocket/") + "notifications"
  }, () => {
    subscribe('/topic/notifications', (msg: any) => {
      const { refId, refType, username } = msg;

      if (refType === 'group.oak.siterise.domain.Comment'
        && parseInt(refId) === referenceId
        && username !== userLogged.login) {
        dispatch(getCommentsRichText({ referenceType: entityType, referenceId, size: PAGINATION_SIZE_DEFAULT_IN_COMMENTS, sort: "createdDate,desc", page: pageCounter }));
      }
    })
  });

  // reset page on unmount
  useEffect(() => {
    dispatch(getCommentsRichText({ referenceType: entityType, referenceId, size: PAGINATION_SIZE_DEFAULT_IN_COMMENTS, sort: "createdDate,desc", page: 0 }));

    return () => {
      dispatch(resetComments());
    }
  }, []);

  useEffect(() => {
    console.log('isConnected?:', isConnected);
  }, [isConnected]);

  const CustomizedRenderEmpty = () => (
    <div style={{ textAlign: 'center', color: colorTextQuaternary }} className='mt-20'>
      <EmptyCommentSvgIcon />
      <p className='mt-5'>There are no comments yet</p>
    </div>
  );

  const loadAnotherPage = () => {
    dispatch(getCommentsRichText({ referenceType: entityType, referenceId, size: PAGINATION_SIZE_DEFAULT_IN_COMMENTS, sort: "createdDate,desc", page: pageCounter + 1 }));
    setPageCounter(pageCounter + 1);
  }

  const { projectId } = useParams<"projectId">();
  const onSuccessDeleteComment = () => {
    dispatch(getProjectDeliverablesData(String(projectId)));
    dispatch(getCommentsRichText({ referenceType: entityType, referenceId, size: PAGINATION_SIZE_DEFAULT_IN_COMMENTS, sort: "createdDate,desc", page: 0 }));
    setPageCounter(0);
  };

  return (
    <>
      <Row className="pb-10 w-full">
        <Col span={24} style={{ color: colorText }}>
          <div
            className="flex flex-row items-center pl-10 pt-8 pb-8"
            style={{ border: `1px solid ${colorBorder}`, backgroundColor: colorFillQuaternary, borderRadius: 4 }}
          >
            <Typography.Title level={5} style={{ margin: 0 }}>
              {title || 'Comments'}
            </Typography.Title>
            <Badge count={totalItems} className="ml-10" style={{ backgroundColor: colorPrimary, borderColor: colorFillQuaternary }} />
          </div>
        </Col>
      </Row>
      {commentListRichText?.length < totalItems! && (
        <Row className="pt-10 pb-10 w-full">
          <Col span={24} style={{ color: colorText }}>
            <div className="flex flex-row items-center pl-10 pt-8 pb-8 mt-10">
              <span>There are {totalItems} comments in total,</span> &nbsp;{' '}
              <Button style={{ padding: '0px' }} onClick={() => loadAnotherPage()} type="link">
                load older comments.
              </Button>
            </div>
          </Col>
        </Row>
      )}
      {loading && (
        <div className="flex flex-row items-center justify-center w-full pl-10 pt-8 pb-8 mt-10">
          {' '}
          <Spin />
        </div>
      )}
      {commentListRichText?.length === 0 && loading === false && <CustomizedRenderEmpty />}

      <Row className="mt-10" justify={'center'}>
        {commentListRichText &&
          isArrayWithValues(commentListRichText) &&
          commentListRichText.map((item: ICommentRichText) => (
            <div key={item.id} className="w-full">
              <Comment value={item} onDelete={onSuccessDeleteComment} />
              <Divider className="mt-15 mb-5" />
            </div>
          ))}
      </Row>

      <Row className="mt-20" wrap={false}>
        <Col flex={'none'}>
          <Avatar className="cursor-pointer" gap={4}>
            {`${userLogged?.firstName?.[0]}${userLogged?.lastName?.[0]}`.toUpperCase()}
          </Avatar>
        </Col>
        <Col flex={'auto'} style={{ marginBottom: '12rem' }} className="pl-10">
          <ConfigProvider theme={{ token: { colorTextBase: themeConfig.darkMode ? WHITE : COLOR_TEXT_BASE } }}>
            <CommentGenerator entityType={entityType} referenceId={referenceId} />
          </ConfigProvider>
        </Col>
      </Row>
    </>
  );
}