import AttachmentsList from '@components/Attachment/AttachmentsList';
import CommentsList from '@components/Comment/CommentsList';
import { TabContainer } from '@components/Utils/TabContainer';
import { AttachmentReferenceEnum } from '@models/enumerations/attachment-reference-enum.model';
import { CommentReferenceEnum } from '@models/enumerations/comment-reference-enum.model';
import { RfiImpactEnum } from '@models/enumerations/rfi-impact-enum.model';
import { IRfiCategory } from '@models/rfi-category.model';
import { IRfiDistribution } from '@models/rfi-distribution.model';
import { IRfiWithDistributions } from '@models/rfi.model';
import { IUser } from '@models/user.model';
import { createRfiWithDistributions, reset as resetRfi } from '@store/slices/rfi';
import { useAppDispatch, useAppSelector } from '@store/store';
import { Card, Col, Row, Steps, theme } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CreateRfiContext, ICreateRfiContext } from './CreateRfiContext';
import { RfiCreateStepOne } from './RfiCreateStepOne';
import { RfiCreateStepThree } from './RfiCreateStepThree';
import { RfiCreateStepTwo } from './RfiCreateStepTwo';
import { BackHeader } from '../shared/BackHeader';

export const RfiCreate = () => {
    const { token: { padding, colorPrimary } } = theme.useToken();

    const { projectId } = useParams<"projectId">();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const dispatch = useAppDispatch();
    const [contextValue, setContextValue] = useState<ICreateRfiContext | null | undefined>(null);
    const { updateSuccess, entity } = useAppSelector((state) => state.Rfi);
    const STEP_2_INDEX = 1;
    const STEP_3_INDEX = 2;

    useEffect(() => {
        dispatch(resetRfi());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const items = [
        {
            title: 'Add Information',
        },
        {
            title: 'Confirm',
        },
        {
            title: 'Attach File',
            description: '(Optional)',
        },
    ];

    const stepOneComplete = (stepValue: any) => {
        setContextValue(stepValue as ICreateRfiContext);
        setCurrentStep(STEP_2_INDEX);
    }

    const stepTwoComplete = (stepValue: any) => {
        const request: IRfiWithDistributions = {
            rfi: {
                questionPayload: contextValue?.questionPayload,
                scheduleImpact: contextValue?.scheduleImpact ? RfiImpactEnum[contextValue.scheduleImpact] : null,
                costImpact: contextValue?.costImpact ? RfiImpactEnum[contextValue.costImpact] : null,
                projectId: Number(projectId),
                assignedTo: JSON.parse(contextValue?.assignedTo!) as IUser,
                category: JSON.parse(contextValue?.category!) as IRfiCategory,
                dueDate: contextValue?.dueDate?.toDate().toISOString()
            },
            distributions: contextValue?.allFollowers ? contextValue.allFollowers.map(u => {
                return {
                    user: u
                } as IRfiDistribution
            }) : []
        }
        dispatch(createRfiWithDistributions(request));
    }

    useEffect(() => {
        if (updateSuccess === true) {
            setCurrentStep(STEP_3_INDEX);
        }
    }, [updateSuccess]);

    const stepBack = () => {
        setCurrentStep(currentStep - 1);
    }

    const renderStep = () => {
        switch (currentStep) {
            case 0:
                return (<RfiCreateStepOne nextStep={stepOneComplete} />);
            case 1:
                return (<RfiCreateStepTwo nextStep={stepTwoComplete} previousStep={stepBack} />);
            case 2:
                return (<RfiCreateStepThree/>);
            default:
                return (<></>);
        }
    }

    const isStepThree = () => {
        return currentStep === STEP_3_INDEX;
    }

    const stepper = () => {
        return (
            <>
                <Row>
                    <Col span={24} className='text-center mb-15' style={{ color: colorPrimary, fontSize: '20px', lineHeight: '28px' }}>
                        New RFI
                    </Col>
                </Row>
                <Row>
                    <Col span={24} className='text-center' style={{ maxWidth: '450px', margin: 'auto' }}>
                        <Steps
                            progressDot
                            current={currentStep}
                            items={items}
                        />
                    </Col>
                </Row>
            </>
        );
    }

    return (
        <CreateRfiContext.Provider value={contextValue}>
            <TabContainer>
                <BackHeader />
                {isStepThree() && <div className='pt-20'>{stepper()}</div>}
                <Row style={{ paddingLeft: `${padding}px`, paddingRight: `${padding}px` }} className="pt-20 w-full">
                    <Col flex="none" style={{ width: '100%', maxWidth: '648px' }}>
                        <Card
                            style={{ borderRadius: '4px' }}
                            bodyStyle={{ paddingLeft: 10, paddingRight: 10 }}
                        >
                            {isStepThree() !== true && stepper()}
                            <Row>
                                <Col span={24}>
                                    {renderStep()}
                                </Col>
                            </Row>
                        </Card>
                        {isStepThree() && <>
                            <Card
                                className='mt-20'
                                style={{ borderRadius: '4px' }}
                                bodyStyle={{ paddingLeft: 10, paddingRight: 10, paddingTop: 0 }}
                            >   
                                <Row className='mt-20'>
                                    <Col span={24}>
                                        {entity && <CommentsList entityType={CommentReferenceEnum.RFI} referenceId={entity.id!} title={'Activity'} />}
                                    </Col>
                                </Row>
                            </Card>
                        </>
                        }
                    </Col>
                    {isStepThree() &&
                        <Col flex="auto" style={{ maxWidth: '550px' }} className="pl-20">
                            {
                                entity && <AttachmentsList entityType={AttachmentReferenceEnum.RFI} referenceId={entity.id!} />
                            }
                        </Col>
                    }
                </Row>
            </TabContainer>
        </CreateRfiContext.Provider>
    );
}

export interface ICreateRfiStepProps {
    nextStep?: (stepValues?: any) => void,
    previousStep?: () => void,
}   