
import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit"
import { cleanEntity, getTotalItems } from '@shared/util/entity-utils';
import { IQueryParamsGeneric } from '@models/utils';
import { IPhotoAlbum } from '@models/photo-album.model';
import { serializeGenericHandleError } from '@store/thunk.util';
import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { isArrayWithValues } from '@shared/util/array-util';
import { isNumber } from '@shared/util/number-util';
import { IAttachment } from '@models/attachment.model';
import { RootState } from '@store/store';
import { photosAlbumService } from '@services/photo-album.service';

const nameOfEntity = "photoAlbums";
const service = photosAlbumService;

export const getPhotoAlbumEntities = createAsyncThunk(
  `${nameOfEntity}/fetch_entity_list`,
  async (queryParams: IQueryParamsGeneric) => {
      return service.getPhotoAlbums(queryParams);
  },
  { serializeError: serializeGenericHandleError }
);

export const createEntity = createAsyncThunk(
    `${nameOfEntity}/create_entity`,
    async (entity: IPhotoAlbum, thunkAPI) => {
      return service.createPhotoAlbum(entity);
    },
    { serializeError: serializeGenericHandleError }
);

export const getEntityById = createAsyncThunk(
  `${nameOfEntity}/getEntityById`,
  async (albumId: StringORNumber, thunkAPI) => {
    const result = await service.getPhotoAlbumById(albumId);
    return result;
  },
  { serializeError: serializeGenericHandleError }
);

export const updateEntity = createAsyncThunk(
    `${nameOfEntity}/update_entity`,
    async ({entity, onSuccess}: {entity: IPhotoAlbum, onSuccess?: () => void }, thunkAPI) => {
      const resultUpdate = entity.id && await service.updatePhotoAlbum(entity.id, cleanEntity(entity) as IPhotoAlbum);
      const resultGetById = resultUpdate && entity.id && thunkAPI.dispatch(getEntityById(entity?.id));
      resultGetById && onSuccess?.();
      return resultGetById;
    },
    { serializeError: serializeGenericHandleError }
);

export const updateAttachmentsOfAlbumSelected = createAsyncThunk(
  `${nameOfEntity}/updateAttachmentsOfAlbumSelected`,
  async ({entity}: {entity: IPhotoAlbum }, thunkAPI) => {
    const albumId = entity.id || null;
    const state  = thunkAPI.getState() as RootState;
    const photoAlbumList = state.PhotoAlbum.entities;

    // Get Index in List
    const albumInListIndex: number = getIndexOfAlbum(photoAlbumList, String(albumId));
    const isInAlbumList: boolean = isIndexNumberInAlbumList(albumInListIndex);

    // If there is an Index, set new Photo Album
    let attachmentsSliced: IAttachment[] = [];
    if (isInAlbumList) {
      attachmentsSliced = state.GenericAlbumGalleryMultimedia.entities.slice(0, 5) || [];
    }

    return { albumInListIndex, attachments: attachmentsSliced }
  }
);

interface IState {
  errorMessage: null,
  entities: IPhotoAlbum[],
  entity: IPhotoAlbum,
  updating: boolean,
  totalItems: number,
  updateSuccess: boolean,
  loadingListAlbums: boolean,
  loadingDetailsAlbumSelected: boolean,
  currentPage: number
}

const initialState: IState = {
  errorMessage: null,
  entities: [],
  entity: {} as IPhotoAlbum,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  loadingListAlbums: false,
  loadingDetailsAlbumSelected: false,
  currentPage: 0
}

export const PhotoAlbumSlice = createSlice({
    name: nameOfEntity,
    initialState,
    reducers: {
      setAlbum: (state, action: { payload: IPhotoAlbum}) => {
        state.entity = { ...action.payload };
        return state;
      },
      updateTotalAttachmentOfAlbum: (state, action) => {
        const { album , totalItems} =  action.payload;

        // Get Index in List
        const albumInListIndex = getIndexOfAlbum(state.entities, String(album?.id));

        // If there is an Index, set new Photo Album
        if (isIndexNumberInAlbumList(albumInListIndex)) {
          state.entities[albumInListIndex as number] = { ...state.entities[albumInListIndex as number], totalPhotos: totalItems};
          state.entity.totalPhotos = totalItems;
        }

        return state;
      },
      removeAttachmentFromGallery: (state, action: { payload: {album: IPhotoAlbum, attachment: IAttachment }}) => {
        const { album, attachment } = action.payload;

        // Get Index in List
        const albumInListIndex = getIndexOfAlbum(state.entities, String(album?.id));

        // If there is an Index, set new Photo Album
        if (isIndexNumberInAlbumList(albumInListIndex)) { 
          state.entities[albumInListIndex].attachments = state.entities[albumInListIndex].attachments?.filter((item) => item.id !== attachment.id);
        }
        
        return state;
      },
      reset: (state) => {
        return initialState;
      }
    },
    extraReducers(builder) {
        builder
          .addMatcher(isFulfilled(getPhotoAlbumEntities), (state, action) => {
            
            const { data, headers } = action.payload;
            const { page } = action.meta.arg;

            let currentPageLocal;
    
            let newEntities: IPhotoAlbum[] = [];
    
            if (!page || page === 0) {
              newEntities = data;
              currentPageLocal = 0;
            } else {
              newEntities = [...state.entities, ...data];
              currentPageLocal = Number(page);
            }

            return {
              ...state,
              loadingListAlbums: false,
              entities: newEntities,
              totalItems: getTotalItems(headers),
              updating: false,
              currentPage : currentPageLocal
            };
          })
          .addMatcher(isFulfilled(getEntityById), (state, action) => {
            const newPhotoAlbum = action.payload as IPhotoAlbum;
            
            // Get Index in List
            const albumInListIndex = getIndexOfAlbum(state.entities, String(newPhotoAlbum.id));
    
            // If there is an Index, set new Photo Album
            if (isIndexNumberInAlbumList(albumInListIndex)) {
              const { attachments, totalPhotos, ...rest} = newPhotoAlbum;
              state.entities[albumInListIndex as number] = { ...state.entities[albumInListIndex as number], ...rest };
            }

            state.loadingDetailsAlbumSelected = false;
            state.updating = false;
            state.entity = newPhotoAlbum;

            return state;
          })
          .addMatcher(isPending(getEntityById), state => {
            state.loadingDetailsAlbumSelected = true;
            state.errorMessage = null;
          })
          .addMatcher(isPending(updateEntity), state => {
            state.loadingDetailsAlbumSelected = true;
            state.errorMessage = null;
            state.updateSuccess = false;
            state.updating = true;
          })
          .addMatcher(isFulfilled(updateEntity), (state, action) => {
            state.updating = false;
            state.loadingDetailsAlbumSelected = false;
            state.updateSuccess = true;
          })
          .addMatcher(isFulfilled(createEntity), (state, action) => {
            state.updating = false;
            state.loadingListAlbums = false;
            state.updateSuccess = true;
          })
          .addMatcher(isFulfilled(updateAttachmentsOfAlbumSelected), (state, action) => {
            const { albumInListIndex, attachments = undefined } = action.payload;

            if (isIndexNumberInAlbumList(albumInListIndex)) { 
              state.entities[albumInListIndex].attachments = attachments;
            }
          })
          .addMatcher(isPending(getPhotoAlbumEntities, createEntity), state => {
            state.loadingListAlbums = true;
            state.errorMessage = null;
            state.updateSuccess = false;
            state.updating = true;
          })
          .addMatcher(isRejected(getPhotoAlbumEntities, createEntity, updateEntity, getEntityById) , (state) => {
            state.loadingListAlbums = false;
            state.loadingDetailsAlbumSelected = false;
            state.updating = false;
            state.updateSuccess = false;
          })
    }
});
  
export const { 
    reset, 
    setAlbum,
    removeAttachmentFromGallery,
    updateTotalAttachmentOfAlbum 
} = PhotoAlbumSlice.actions;

// Utils
const getIndexOfAlbum = (albumList: IPhotoAlbum[], albumId: string) => { 
  return isArrayWithValues(albumList) ? albumList.findIndex((albumStored) => String(albumStored.id) ===  String(albumId)) : -1;
}

const isIndexNumberInAlbumList = (index: number) => {
  return isNumber(index) && Boolean(index! > -1);
}
  
// Reducer
export default PhotoAlbumSlice.reducer;

  