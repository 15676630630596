import { createContext, useContext, useState } from "react";

export type AuditHistoryContextProviderType = {
    headerHeight: number;
    setHeaderHeight: (height: number) => void;
};

export const AuditHistoryContex = createContext<AuditHistoryContextProviderType | {}>({});

export const AuditHistoryContexProvider = ({ children }: any) => {  

    const [headerHeight, setHeaderHeight] = useState<number>(0);

    return (
        <AuditHistoryContex.Provider value={{ headerHeight, setHeaderHeight }}>
            {children}
        </AuditHistoryContex.Provider>
    )
};

export const useAuditHistoryProvider = () => {
    return useContext(AuditHistoryContex) as AuditHistoryContextProviderType;
};
