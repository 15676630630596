import React, { useState } from 'react'
import { Alert, Button, Col, ConfigProvider, Row, Space } from 'antd';
import { IProjectDeliverableUI } from '@models/project-deliverable';
import { ViewDetailsApprovals, approvalTimeLineLabelMap, getApprovalLabel } from './ViewDetailsApprovals';
import { isArrayWithValues } from '@shared/util/array-util';
import { useAuth } from '@providers/AuthProvider';
import { IUser } from '@models/user.model';
import { SECONDARY_COLOR_ALERT } from '@providers/ThemeProvider';
import { IProjectDeliverableApproval } from '@models/project-deliverable-approval';
import { formatDatePipe } from '@shared/util/date-utils';
import { useLayoutEffect } from 'react';
import { ApproveDeliverableModal } from './ApproveDeliverableModal';
import { RejectDeliverableModal } from './RejectDeliverableModal';
import useModal from '@HOOKs/UseModal';

interface WaitingApprovalWarningProps {
    deliverable: IProjectDeliverableUI
}

const INITIAL_APPROVALS_INFO = {
    isApproverSameTheUserLogged: false,
    approvalApprovedByUserLogged: {} as IProjectDeliverableApproval | undefined,
}

export const WaitingApprovalWarning: React.FC<WaitingApprovalWarningProps> = ({ deliverable}) => {
    
    const { user: userLogged } = useAuth();
    const [openViewDetailsApprovals, setOpenViewDetailsApprovals] = useState(false);
    const [approvalsInfo, setApprovalsInfo] = useState(INITIAL_APPROVALS_INFO)

    const getApprovalsInfo = (deliverable: IProjectDeliverableUI) => {
        if ( !(deliverable?.approvals && isArrayWithValues(deliverable.approvals))) { return };

        const { approvals = [] } = deliverable;

        const isUserSameTheUserLogged = (user: IUser | null | undefined) => String(user?.id) === String(userLogged?.id)
        const isApproverSameTheUserLogged = isUserSameTheUserLogged(deliverable.approverUser);
        const isApprovalApproved = (status: string | undefined) => status && (getApprovalLabel(status) === approvalTimeLineLabelMap.APPROVED)
        const approvalApprovedByUserLogged = approvals.find((approval) => (isUserSameTheUserLogged(approval?.user)) && isApprovalApproved(approval.status))
        
        if (!deliverable?.approverUser?.id) { return (<></>) }

        setApprovalsInfo({
            isApproverSameTheUserLogged,
            approvalApprovedByUserLogged
        })
    }

    useLayoutEffect(() => {
        getApprovalsInfo(deliverable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliverable])

    const ApprovalStatus: React.FC<{ approvalsInfo: typeof INITIAL_APPROVALS_INFO }> = ({approvalsInfo}) => {
        
        const {visible: visibleApproveDeliverableModal, setVisible: setVisibleApproveDeliverableModal} = useModal()
        const {visible: visibleRejectDeliverableModal, setVisible: setVisibleRejectDeliverableModal} = useModal()

        const approverMessageContent = (
            <span>
                This deliverable is pending your approval.
                <span className="ml-10 cursor-pointer font-semibold" onClick={() => setOpenViewDetailsApprovals(true)}>View Details</span>
            </span>
        )

        const approvedOn = (<span>You approved this deliverable on { formatDatePipe(approvalsInfo?.approvalApprovedByUserLogged?.approvedDate) }.</span>)

        const userMessage = (
            <span>
                <span className="mr-10 font-semibold">Approval Status:</span>
                Awaiting approval from {deliverable?.awaitingApprovalFrom} (Approver {deliverable.approverNumber} of {deliverable?.totalApprovers})
            </span>
        );

        const viewDetailsButton = (
            <Button size="small" type="text" className='mr-20' onClick={() => setOpenViewDetailsApprovals(true)}>
                <span className='font-medium' style={{ color: SECONDARY_COLOR_ALERT }}>
                    View Details
                </span>  
            </Button>
        )

        const handleDeliverableButton = (
            <>
                <Button size="small" type="text" className='mr-5' onClick={() => setVisibleApproveDeliverableModal(true) }>
                    <span className='font-medium' style={{ color: SECONDARY_COLOR_ALERT }}>
                        Approve
                    </span>  
                </Button>
                <span className='font-medium' style={{ color: SECONDARY_COLOR_ALERT }}>|</span> 
                <Button size="small" type="text" className='ml-5 mr-15' onClick={() => setVisibleRejectDeliverableModal(true) }>
                    <span className='font-medium' style={{ color: SECONDARY_COLOR_ALERT }}>
                        Reject
                    </span>  
                </Button>
            </>
        )

        const RenderMessageContent = () => {
            let messsage = userMessage;
            const { approvedDate } = deliverable?.approval

            if (approvalsInfo?.approvalApprovedByUserLogged?.approvedDate) {
                messsage = approvedOn;
            }
            if (approvalsInfo.isApproverSameTheUserLogged && !approvedDate) {
                messsage = approverMessageContent;
            }
            return (<div className='pt-10 pb-10 pl-15'>{messsage}</div>)
        }

        return (
            <>
                <Row className='w-full pb-20'>
                    <Col span={24}>
                        <Alert
                            message={<RenderMessageContent />}
                            type="warning"
                            banner
                            icon={<></>}
                            action={
                                <Space>
                                    <ConfigProvider theme={{ token: { colorPrimary: SECONDARY_COLOR_ALERT } }}>
                                        { approvalsInfo.isApproverSameTheUserLogged ? handleDeliverableButton : viewDetailsButton }
                                    </ConfigProvider>
                                </Space>
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <ViewDetailsApprovals 
                        deliverable={deliverable} 
                        open={openViewDetailsApprovals} 
                        setOpen={setOpenViewDetailsApprovals} />
                    <ApproveDeliverableModal 
                        open={visibleApproveDeliverableModal} 
                        setOpen={setVisibleApproveDeliverableModal} 
                        deliverable={deliverable}
                        nextApproval={deliverable.nextApproval} />
                    <RejectDeliverableModal 
                        open={visibleRejectDeliverableModal} 
                        setOpen={setVisibleRejectDeliverableModal} 
                        deliverable={deliverable}
                        nextApproval={deliverable.firstApproval} />
                </Row>
            </>
        )
    }

    return (deliverable.isWaitingForApprovals && !(deliverable?.isStatusCompleted)) ? <ApprovalStatus approvalsInfo={approvalsInfo} /> : null
}