/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BackHeader } from "../BackHeader";
import { CardDetailContainer } from "./CardDetailContainer";
import { Col, Row } from "antd";
import { useAppDispatch } from "@store/store";
import { getPunchListCategories, getPuchListItemById, getPuchListStatuses } from "@store/slices/punch-list";
import { TabContainer } from "@components/Utils/TabContainer";
import { AttachmentReferenceEnum } from "@models/enumerations/attachment-reference-enum.model";
import { CommentReferenceEnum } from "@models/enumerations/comment-reference-enum.model";
import { getUsers } from "@store/slices/users-extended";
import AttachmentsList from "@components/Attachment/AttachmentsList";
import CommentsList from "@components/Comment/CommentsList";

interface IPunchListItemDetailsViewProps {}

export const PunchListItemDetailsView = (props: IPunchListItemDetailsViewProps) => {

    const { punchListItem } = useParams<"punchListItem">();

    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(getPuchListStatuses())
    }, [])

    useEffect(() => {
        punchListItem && dispatch(getPuchListItemById(punchListItem))
        dispatch(getUsers());
        dispatch(getPunchListCategories());
    }, [punchListItem]);
    
    return (
      <>
        <BackHeader />
        <TabContainer>
          <Row>
            <Col span={24} className="pl-30 pr-16 pt-16">
              <CardDetailContainer />
            </Col>
          </Row>
          <Row className="pl-30 pr-16 mt-16">
              <Col span={12} className="pr-12">
                <CommentsList entityType={CommentReferenceEnum.PUNCH_LIST_ITEM} referenceId={Number(punchListItem)} title={'Comments'} />
              </Col>
              <Col span="12" className="pl-12">
                <AttachmentsList entityType={AttachmentReferenceEnum.PUNCH_LIST_ITEM} referenceId={Number(punchListItem)} />
              </Col>
          </Row>
        </TabContainer>
      </>
    )
}
