import { ICommentRichText, ICommentRichTextGetRequest } from './../../models/comment.model';
import { defaultValue, ICommentRichTextCreateRequest, ICommentRichTextDeleteRequest, ICommentRichTextGetRequestParams } from '../../models/comment.model';

import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { commentVersion2Service } from '../../services/comment.service';
import { createEntitySlice, EntityState, serializeAxiosError } from "../reducer.util";
import { asyncLaunchNotification } from '@store/slices/notification';
import { PAGINATION_SIZE_DEFAULT_IN_COMMENTS } from '@constants/core';
import { serializeGenericHandleError } from '@store/thunk.util';
import { getTotalItems } from '@shared/util/entity-utils';

const nameOfEntity = "comment-version-2";
const service = commentVersion2Service;

export const getEntities = createAsyncThunk(
  `${nameOfEntity}/fetch_entity_list`,
  async (queryParams: ICommentRichTextGetRequestParams) => {
    return service.getAll(queryParams);
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  `${nameOfEntity}/fetch_entity`,
  async (id: string | number) => {
    return service.getById(id);
  },
  { serializeError: serializeAxiosError }
);

export const createCommentRichText = createAsyncThunk(
  `${nameOfEntity}/create_entity`,
  async (payload: ICommentRichTextCreateRequest, thunkAPI) => {
    const resultEntity = await service.create(payload);

    if (resultEntity) {
      const { referenceType, referenceId } = payload;
      thunkAPI.dispatch(getEntities({ referenceType, referenceId, page: 0, sort: "createdDate,desc", size: PAGINATION_SIZE_DEFAULT_IN_COMMENTS }));
      thunkAPI.dispatch(
        asyncLaunchNotification({
          type: "success",
          config: {
            message: `Comment`,
            description: `Comment has been created.`
          }
        })
      )
      payload?.onSuccess && payload.onSuccess();
    }
    return resultEntity;
  },
  { serializeError: serializeAxiosError }
);

export const deleteCommentRichText = createAsyncThunk(
  `${nameOfEntity}/delete_entity`,
  async (payload: ICommentRichTextDeleteRequest, thunkAPI) => {
    const result = await service.delete(payload)
      .catch((error) => {
        thunkAPI.dispatch(
          asyncLaunchNotification({
            type: "error",
            config: {
              message: `Error`,
              description: `We apologize, something happened in our servers trying to delete the comment.`
            }
          })
        )
      })

    if (result) {
      thunkAPI.dispatch(
        asyncLaunchNotification({
          type: "success",
          config: {
            message: `Comment`,
            description: `Comment has been deleted.`
          }
        })
      )
      payload?.onSuccess && payload.onSuccess();
    }
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getTotalCommmentsCount = createAsyncThunk(
  `${nameOfEntity}/getTotalItems`,
  async (payload: ICommentRichTextGetRequest) => {
    return service.getTotalItems(payload);
  },
  { serializeError: serializeGenericHandleError }
);

const initialState: EntityState<ICommentRichText> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export const CommentVersion2Slice = createEntitySlice({
  name: nameOfEntity,
  initialState,
  reducers: {
    clearEntity: (state,) => {
      state.entity = defaultValue;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        const { page } = action.meta.arg;

        data.reverse(); // as we're fetchting the latest comments, we nee to reserve them to be in order


        let newEntities: ICommentRichText[] = [];

        if (page === 0) {
          newEntities = data;
        } else {
          newEntities = newEntities.concat(data);
          newEntities = newEntities.concat(state.entities);
        }

        return {
          ...state,
          loading: false,
          entities: newEntities,
          totalItems: getTotalItems(headers),
        };
      })
      .addMatcher(isFulfilled(createCommentRichText), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isFulfilled(getTotalCommmentsCount), (state, action) => {
        const { headers } = action.payload;
        return {
          ...state,
          totalItems: getTotalItems(headers),
        };
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
  }
});

export const { reset, clearEntity } = CommentVersion2Slice.actions;

// Reducer
export default CommentVersion2Slice.reducer;

