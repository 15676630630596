import { ISetting } from '@models/setting.model';
import { http } from '../http/axios.instance';

const path = 'settings';

export const settingRepository = {
    getAllSettings: async () => {
        const requestUrl = `${path}?cacheBuster=${new Date().getTime()}`;

        const promiseGetAll = await http.get<ISetting[]>(requestUrl);
        return promiseGetAll;
    },
    getOneSettingByKey: async (key: string) => {
        const requestUrl = `${path}/one-by-key/${key}?cacheBuster=${new Date().getTime()}`;

        const promiseGetOne = await http.get<ISetting>(requestUrl);
        return promiseGetOne;
    },
    updateSetting: async (id: string | number, entity: ISetting): Promise<ISetting> => {
        const requestUrl = `${path}/${id}`;
        const promiseUpdate = await http.put<ISetting>(requestUrl, entity);
        return promiseUpdate.data;
    },
}