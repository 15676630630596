import { RightOutlined } from "@ant-design/icons";
import type { ResizeCallbackData } from 'react-resizable';
import { Resizable } from 'react-resizable';
import "./TableUtils.less";

interface ILastCellTableExtendedProp {
    children:  React.ReactNode
}
export const LastCellHoverSelectionTableExtended = (props: ILastCellTableExtendedProp) => {
    return (
        <div className='flex flex-row justify-between relative'>
            {props.children}
            <RightOutlined className='icon-show-in-hover'/>
        </div>
    )
}

export const ResizableCell = (
  props: React.HTMLAttributes<any> & {
    onResize: (e: React.SyntheticEvent<Element>, data: ResizeCallbackData) => void;
    width: number;
  },
) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};
