import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Button, Col, Divider, Drawer, Row, Space, theme, Card } from 'antd';
import { getAddressesList } from '@store/slices/generic-address';
import { IAddress, IGetAddressRequest } from '@models/address.model';
import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { AddressGenericUpdate } from './AddressGenericUpdate';
import { AddressReferenceEnum, AddressReferenceEnumListOptions } from '@models/enumerations/address-reference-enum.model';
import { AddressGenericDelete } from './AddressGenericDelete';
import useVisible from '@HOOKs/UseVisible';
import { AddresGenericCreate } from './AddressGenericCreate';
import { If } from '@components/Utils/Structural';
import { useTranslation } from 'react-i18next';
import { PhoneOutlined, MailOutlined } from '@ant-design/icons';

export interface IAddressManageProps {
  open: boolean;
  toggle: () => void;
  referenceId?: StringORNumber;
  referenceType: AddressReferenceEnumListOptions;
}

type IAction = () => void;

export const AddressGenericManageList = (props: IAddressManageProps) => {
  const { open, toggle, referenceId, referenceType } = props;
  const { t } = useTranslation();
  const {
    token: { colorBorderSecondary, colorFill, colorFillQuaternary, colorFillSecondary, colorTextTertiary },
  } = theme.useToken();

  const [visibleAddressCreateDrawer, toggleVisibleAddressCreateDrawer] = useVisible();
  const [visibleAddressEditDrawer, toggleVisibleAddressEditDrawer] = useVisible();
  const [visibleAddressDeleteDrawer, toggleVisibleAddressDeleteDrawer] = useVisible();

  const [addresSelected, setAddresSelected] = useState<IAddress | null>(null);

  const onClose = (): void => {
    toggle();
  };

  const dispatch = useAppDispatch();

  const addressesList = useAppSelector(state => state.GenericAddress[referenceType]);

  useEffect(() => {
    if (referenceId) {
      const payload: IGetAddressRequest = {
        referenceId: referenceId,
        referenceType: AddressReferenceEnum[referenceType],
      };
      dispatch(getAddressesList(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectAction = (address: IAddress, action: IAction) => {
    setAddresSelected(address);
    action();
  };

  const TitleCard = ({ address }: { address: IAddress }) => {
    const {
      token: { colorTextSecondary },
    } = theme.useToken();
    return (
      <Row justify={'space-between'} align="middle">
        <Col flex="1">
          <span style={{ color: colorTextSecondary, fontSize: '1rem', fontWeight: '500' }}>{address?.addressType?.description}</span>
        </Col>
        <Col>
          <Button type="text" danger onClick={() => selectAction(address, toggleVisibleAddressDeleteDrawer)}>
            {t('generic.delete')}
          </Button>
        </Col>
        <Col>
          <Button type="default" onClick={() => selectAction(address, toggleVisibleAddressEditDrawer)}>
            {t('generic.edit')}
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Drawer
        title={t('generic.address.manageAddresses')}
        width={500}
        onClose={onClose}
        open={open}
        styles={{
          body: { paddingBottom: 80 },
        }}
        extra={
          <Space>
            <Button
              type="primary"
              onClick={() => {
                toggleVisibleAddressCreateDrawer();
              }}
            >
              {t('generic.add')}
            </Button>
          </Space>
        }
      >
        {addressesList.data.map((address: IAddress) => (
          <Row className="pt-20 w-full">
            <Col span={24}>
              <Card
                title={<TitleCard address={address} />}
                styles={{
                  header: {
                    paddingLeft: 0,
                    paddingRight: 0,
                    borderColor: colorBorderSecondary,
                  },
                  body: { padding: 0, paddingTop: 5 },
                }}
                style={{
                  borderColor: colorBorderSecondary,
                  backgroundColor: colorFillQuaternary,
                  borderRadius: 8,
                  overflow: 'hidden',
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                <div
                  className="flex flex-col"
                  style={{
                    color: colorTextTertiary,
                  }}
                >
                  <span>{address?.streetAddress}</span>
                  <If condition={!!address?.streetAddress2}>
                    <span>{address?.streetAddress2}</span>
                  </If>
                  <span>{`${address?.city ? `${address?.city},` : ''} ${address?.stateProvince || ''} ${address?.postalCode || ''}`}</span>
                  <span>{address?.country}</span>
                  <If
                    condition={
                      (!!address?.phone || !!address?.email) &&
                      (!!address?.streetAddress ||
                        !!address?.streetAddress2 ||
                        !!address?.city ||
                        !!address?.stateProvince ||
                        !!address?.postalCode ||
                        !!address?.country)
                    }
                  >
                    <Divider type="horizontal" className="ml-0 mr-0 mt-5 mb-10" style={{ borderColor: colorFillSecondary }} />
                  </If>
                  <If condition={!!address?.phone}>
                    <span>
                      <PhoneOutlined style={{ fontSize: '15px', color: colorFill, transform: 'scaleX(-1)' }} /> {address?.phone}
                    </span>
                  </If>
                  <If condition={!!address?.email}>
                    <span>
                      <MailOutlined style={{ fontSize: '15px', color: colorFill }} /> {address?.email}
                    </span>
                  </If>
                </div>
              </Card>
            </Col>
          </Row>
        ))}

        <If condition={!!addresSelected}>
          <Row>
            <If condition={visibleAddressEditDrawer}>
              <AddressGenericUpdate
                open={visibleAddressEditDrawer}
                toggle={toggleVisibleAddressEditDrawer}
                address={addresSelected!}
                referenceId={referenceId}
                referenceType={referenceType}
              />
            </If>
            <If condition={visibleAddressDeleteDrawer}>
              <AddressGenericDelete
                open={visibleAddressDeleteDrawer}
                toggle={toggleVisibleAddressDeleteDrawer}
                address={addresSelected!}
                referenceId={referenceId}
                referenceType={referenceType}
              />
            </If>
          </Row>
        </If>
        <If condition={visibleAddressCreateDrawer}>
          <Row>
            <AddresGenericCreate
              open={visibleAddressCreateDrawer}
              toggle={toggleVisibleAddressCreateDrawer}
              referenceId={referenceId}
              referenceType={referenceType}
            />
          </Row>
        </If>
      </Drawer>
    </>
  );
};
