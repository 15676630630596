import { Avatar, Col, Row, theme, Button } from 'antd';
import { CompanyKeyPhotoViewer } from './CompanyPhotoViewer';
import { useResizeDetector } from 'react-resize-detector';
import { ContentVertical } from '@components/Locations/Tabs/OverviewTab/LocationCard';
import { EmptyLabelText } from '@components/Utils/EmptyLabel';
import { TextEllipsis } from '@components/Utils/TextEllipsisContent';
import { t } from 'i18next';
import { useAppSelector } from '@store/store';
import { useMemo, useState } from 'react';
import EditCompany from './EditCompany';
import { AddressGenericCardHandler } from '@components/AddressGeneric/AddressGenericCardHandler';
import { useParams } from 'react-router-dom';
import { If } from '@components/Utils/Structural';

export default function CompanyDetailsHeader() {
  const {
    token: { marginLG, colorTextTertiary, colorBorder, colorBgContainer },
  } = theme.useToken();

  const { id } = useParams<'id'>();

  const [openEditDrawer, setOpenEditDrawer] = useState<Boolean>(false);

  const { entity: companyEntity } = useAppSelector(state => state.Company);

  const { entities } = useAppSelector(state => state.CompanyContactsExtended);

  const getFirstPrimaryContact = useMemo(() => {
    return entities.find(contact => contact.primary === true);
  }, [entities]);

  const { ref: fullLayoutRef } = useResizeDetector({ refreshMode: 'debounce', refreshRate: 50 });

  return (
    <div
      className="pr-20 pt-20 mr-10 pb-20 shadow-md"
      style={{
        borderRadius: '8px',
        backgroundColor: colorBgContainer,
        marginLeft: `${marginLG}px`,
        boxShadow: ' -8px 3px 28px -12px rgba(0,0,0,0.27)',
      }}
    >
      <div className="flex w-full">
        <div
          style={{ width: '40%' }}
          ref={fullLayoutRef}
          className={`w-full pl-20 pr-30`}
          onClick={() => (companyEntity.website ? window.open(`${companyEntity.website}`) : '')}
        >
          <CompanyKeyPhotoViewer companyEntity={companyEntity} />
        </div>
        <div className="flex flex-col" style={{ flex: 1, borderRadius: '5px' }}>
          <Row className="justify-between pl-15">
            <Col span={12}>
              <AddressGenericCardHandler referenceType="COMPANY" referenceId={id} />
            </Col>

            <EditCompany open={openEditDrawer} setOpen={setOpenEditDrawer} />
            <Col span={3} style={{ textAlign: 'right' }}>
              <Button type="default" onClick={() => setOpenEditDrawer(true)}>
                {t('generic.edit')}
              </Button>
            </Col>
          </Row>
          {/* Below 2 Fields will be added in Iteration 2 , Keeping the code  */}
          {false && (
            <Row className="mt-20 pl-15">
              <Col span={24}>
                <ContentVertical>
                  <CompanyTitleLabel>Number of Projects</CompanyTitleLabel>
                  <div style={{ color: colorTextTertiary }} className="w-full">
                    <EmptyLabelText />
                  </div>
                </ContentVertical>
              </Col>
            </Row>
          )}

          {false && (
            <Row className="mt-20 pl-15">
              <Col span={24}>
                <ContentVertical>
                  <CompanyTitleLabel>{t('generic.company')} Category</CompanyTitleLabel>
                  <EmptyLabelText />
                </ContentVertical>
              </Col>
            </Row>
          )}
          <If condition={!!getFirstPrimaryContact}>
            <Row className="mt-20 pl-15 mb-20">
              <Col span={12}>
                <ContentVertical>
                  <CompanyTitleLabel>Primary {`${t('generic.contact')}`}</CompanyTitleLabel>
                  <div
                    style={{
                      color: colorTextTertiary,
                      border: `1px solid ${colorBorder}`,
                      borderRadius: '5px',
                      padding: 12,
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: 8,
                    }}
                  >
                    <Avatar className="mb-5 avatar" size={40} style={{ marginLeft: 8 }}>{`${getFirstPrimaryContact?.firstName
                      ?.charAt(0)
                      .toUpperCase()}${getFirstPrimaryContact?.lastName?.charAt(0)?.toUpperCase()}`}</Avatar>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                      <TextEllipsis
                        text={`${getFirstPrimaryContact?.firstName} ${getFirstPrimaryContact?.lastName}`}
                        style={{ color: colorTextTertiary, fontWeight: 600 }}
                      />
                    </div>
                  </div>
                </ContentVertical>
              </Col>
            </Row>
          </If>
        </div>
      </div>
    </div>
  );
}

export const CompanyTitleLabel = ({ children }: { children: React.ReactNode }) => {
  const {
    token: { colorTextSecondary },
  } = theme.useToken();
  return (
    <div className="font-bold w-full mb-4" style={{ color: colorTextSecondary }}>
      {children}
    </div>
  );
};
