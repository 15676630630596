import { Card, Col, Radio, ConfigProvider, Row, Select, theme } from 'antd';

import { ReactComponent as NotificationLeft } from './../../assets/img/notification-left.svg'
import { ReactComponent as NotificationRight } from './../../assets/img/notification-right.svg'
import { useState } from 'react';

import { SoundOutlined } from '@ant-design/icons';
import { SoundMusicOutlinedIcon } from '@components/Icons/SoundMusicOutlinedIcon';
import { SoundMusicOutlinedOffIcon } from '@components/Icons/SoundMusicOffOutlinedIcon';
import { INotificationSoundItem, NOTIFICATION_PLACEMENT, NOTIFICATION_SOUNDS, NOTIFICATION_SOUNDS_MAP, usePreferencesProvider } from '@providers/PreferencesProvider';
import { userSettingsService } from '@services/user-settings.service';
import { useAuth } from '@providers/AuthProvider';

export const PreferencesNotifications = () => {

  const { token: { fontSize, colorPrimary, colorBorderSecondary } } = theme.useToken();

  const { userSettings, setSoundNotification, placementNotification, setPlacementNotification, setPreferences } = usePreferencesProvider();

  const setPlacementNotificationPreference = async (placement: string) => {
    if (userSettings && userSettings.id && userSettings.settings) {
      userSettings.settings = {
        ...userSettings.settings,
        notification_placement: placement
      }
      const payload = {
        ...userSettings,
        settings: JSON.stringify({
          ...userSettings.settings
        })
      };
      const userPreferences = await userSettingsService.updateUserPreferences(payload);
      setPreferences(userPreferences);
    } 
    
    if (!userSettings) {
      const userPreferences = await userSettingsService.createUserPreferences({
        settings: JSON.stringify({ notification_placement: placement }),
        user: { id: user?.id }
      })
      setPreferences(userPreferences);
    }
  }

  const onSelectLeft = () => {
    setPlacementNotification(NOTIFICATION_PLACEMENT.TOP_LEFT);
    setPlacementNotificationPreference(NOTIFICATION_PLACEMENT.TOP_LEFT);

    const element = document.getElementById('notificationLeftSVGBox'); 
    if (element) {
      element.classList.add('slide-in-left');
      setTimeout(() => {
        element.classList.remove('slide-in-left');
      }, 500)
    }
  }

  const onSelectRight = () => {
    setPlacementNotification(NOTIFICATION_PLACEMENT.TOP_RIGHT);
    setPlacementNotificationPreference(NOTIFICATION_PLACEMENT.TOP_RIGHT);

    const element = document.getElementById('notificationRightSVGBox'); 
    if (element) {
      element.classList.add('slide-in-right');
      setTimeout(() => {
        element.classList.remove('slide-in-right');
      }, 500)
    }
  }
 
  const INotificationSoundItem = ({ item }: { item: INotificationSoundItem }) => {
    const styleIcon = { transform: 'scale(0.90)' };
    return (
      <div className="flex flex-row items-center item-selected-make-bolder">
        { 
          item.hasSound 
            ? (<SoundMusicOutlinedIcon iconStyles={styleIcon} className='mr-10 item-selected-make-bolder'/>)
            : (<SoundMusicOutlinedOffIcon iconStyles={{ transform: 'scale(0.75)' }} className='mr-10' />)
        }
        <span>{ item && item?.name }</span>
      </div>
    )
  }

  const [isPlayingSound, setIsPlayingSound] = useState(false)
  const { user } = useAuth()

  const setNotificationSoundId =  async (soundId: number) => {
    if (userSettings && userSettings.id && userSettings.settings) {
      userSettings.settings = {
        ...userSettings.settings,
        notification_soundId: soundId
      }
      const payload = {
        ...userSettings,
        settings: JSON.stringify({
          ...userSettings.settings
        })
      };
      const userPreferences = await userSettingsService.updateUserPreferences(payload);
      setPreferences(userPreferences);
    } 
    
    if (!userSettings) {
      const userPreferences = await userSettingsService.createUserPreferences({
        settings: JSON.stringify({ notification_soundId: soundId }),
        user: { id: user?.id }
      })
      setPreferences(userPreferences);
    }
  }

  const onNotificationSoundSelect = (id: number) => {
    const sound = NOTIFICATION_SOUNDS_MAP[id as keyof typeof NOTIFICATION_SOUNDS_MAP];
    if (sound?.src) {
      const audio = new Audio(sound?.src);
      if (audio) {
        audio.play()
        .then(() => setIsPlayingSound(true))
        .finally(() => {
          setTimeout(() => {
            setIsPlayingSound(false);
          }, 800)
        })

        setSoundNotification(sound?.src);
      }
    }
    setNotificationSoundId(sound?.id)
  } 

  return (
    <div>
      <Row>
        <Col span={24} className="mb-20">
          <span>Notification sound (notifications) { isPlayingSound &&  <SoundOutlined className='ml-10'/> } </span>
          <Select
              placeholder="Select a Sound of Notifications"
              className='w-full mt-5'
              options={NOTIFICATION_SOUNDS.map((item) => ({ value: item.id, label: (<INotificationSoundItem item={item} />) }))}
              defaultValue={userSettings?.settings?.notification_soundId}
              onSelect={onNotificationSoundSelect}
          />
        </Col>
      </Row>
      <Row>
        <span className='mb-5'>Notification Placement</span>
      </Row>
      <Row className='pb-20'>
        <Col span={12} className="pr-15">
        <ConfigProvider theme={{ components: { Card: { colorBorderSecondary: placementNotification === NOTIFICATION_PLACEMENT.TOP_LEFT ? colorPrimary: colorBorderSecondary } } }}>
          <Card 
            bordered
            className='cursor-pointer ant-card-cover-reset' 
            bodyStyle={{ padding: 0 }}
            onClick={onSelectLeft}
            cover={<NotificationLeft />}>
            <Radio 
              className='pt-10 pl-10 pb-10'
              checked={placementNotification === NOTIFICATION_PLACEMENT.TOP_LEFT} >
              <span style={{ fontSize: fontSize - 2 }}>Left</span>
            </Radio>
          </Card>
        </ConfigProvider>  
        </Col>
        <Col className="pl-15" span={12}>
        <ConfigProvider theme={{ components: { Card: { colorBorderSecondary: placementNotification === NOTIFICATION_PLACEMENT.TOP_RIGHT ? colorPrimary : colorBorderSecondary } } }}>
          <Card
            bordered
            className='cursor-pointer ant-card-cover-reset' 
            bodyStyle={{ padding: 0}}
            cover={<NotificationRight />}
            onClick={onSelectRight}
          >
            <Radio 
              className='pt-10 pl-10 pb-10'
              checked={placementNotification === NOTIFICATION_PLACEMENT.TOP_RIGHT } >
              <span style={{ fontSize: fontSize - 2 }}>Right</span>
            </Radio>
          </Card>
        </ConfigProvider >
        </Col>
      </Row>
    </div>
  )
}
