/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useErrorAnimation } from "@HOOKs/UseErrorAnimation";
import { isFulfilled } from "@reduxjs/toolkit";
import { asyncLaunchNotification } from "@store/slices/notification";
import { createEntity, updateEntity } from "@store/slices/rfi-reason";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Button, Col, Drawer, Form, Input, Row, Space, Switch } from "antd";
import { IRfiReason } from "@models/rfi-reason.model";
import { useTranslation } from "react-i18next";
interface IRFIReasonEditProps {
  open: boolean,
  toogle: () => void,
  onModifiedChanges?: (rfiReason: IRfiReason) => void,
  rfiReason: IRfiReason
}

export const RFIReasonEdit = (props: IRFIReasonEditProps) => {

    const { open, toogle, rfiReason, onModifiedChanges } = props;

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [form] = Form.useForm();

    const disabled = Form.useWatch('disabled', form);

    const { loading } = useAppSelector((state) => state.RfiReason);

    const [animateError, playAnimationOfError] = useErrorAnimation();

    const onFinishFailed = (errorInfo: any) => playAnimationOfError();

    const onFinish = (entity: any): void => {
        const hasRfiReason = rfiReason?.id;
        if (hasRfiReason) {
            dispatch(
                updateEntity({
                    ...rfiReason,
                    ...entity,
                    name: entity?.name?.trim() || '',
                    disabled
                })  
            ).then(onSuccessUpdatedStatus);
            
        } else {
            dispatch(createEntity({
                id: "",
                ...entity,
                name: entity?.name?.trim() || '',
                disabled
            })).then(onSuccessCreateStatus);
        }
    };

    const onSuccessUpdatedStatus = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                    message: t("admin.changesSaved"),
                    description: t("admin.yourChangesHaveBeenSavedSuccessfully")
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onSuccessCreateStatus = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: t("admin.changesSaved"),
                  description: t("admin.rfiReasonCreatedSuccessfully")
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onClose = () => { 
        form.resetFields();
        toogle(); 
    }

    const onSubmit = () => form.submit();

    useEffect(() => { setRoleFormFields() }, [rfiReason])

    const setRoleFormFields = () => {
        form.setFieldsValue({
            ...rfiReason,
            disabled: Boolean(rfiReason.disabled),
        });
    }

    return (
        <>
            <Drawer 
                width={448} 
                title={`${rfiReason?.id ? t("generic.edit"): t("generic.create")} ${t("admin.rfiReason")}`}
                placement="right" 
                onClose={onClose} 
                open={open}
                closable={false}
                extra={
                    <Space>
                      <Button type="primary" ghost onClick={onClose}>
                        Cancel
                      </Button>
                      <Button loading={loading} onClick={onSubmit} type="primary" disabled={loading}>
                        {`${rfiReason?.id ? t("generic.save"): t("generic.create")}`}
                      </Button>
                    </Space>
                  }
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className={`${animateError && "error-animation"}`}
                >

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label={t("admin.rfiReasonName")}
                                rules={[
                                    { required: true, message: t("admin.rfiReasonNameFieldIsRequired") },
                                ]}
                            >
                                <Input placeholder={t("admin.rfiReasonName")} allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={"none"} className="pr-0" style={{  }}>
                            {t("generic.disabled")}
                        </Col>
                        <Col span={"none"} className="pr-0" style={{ flex: '1', textAlign: 'right' }}>
                            <Form.Item name="disabled" valuePropName="checked">
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Drawer>
        </>
  );
}
