import { IChangeOrderReason } from "@models/change-order-reason.model";
import { getEnabledEntities as getReasons } from '@store/slices/change-order-reasons';
import { getEntity as getChangeOrder, updateReason } from '@store/slices/change-orders';
import { asyncLaunchNotification } from "@store/slices/notification";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Alert, Button, Col, Drawer, Form, Row, Select, Space, theme } from "antd";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

const { Option } = Select;

interface IReasonDrawerProps {
    open: boolean,
    toggle: () => void,
    currentReasonId: number | undefined
}

export const ReasonDrawer = (props: IReasonDrawerProps) => {
    const { open, toggle, currentReasonId } = props;
    const { token: { colorTextSecondary, colorBgLayout, colorBorder } } = theme.useToken();
    const { coId } = useParams<"coId">();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { entities: reasons } = useAppSelector((state) => state.ChangeOrderReason);

    const [form] = Form.useForm();

    useEffect(() => {
        if (reasons.length === 0) {
            dispatch(getReasons());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onDrawerClose = () => {
        toggle()
    }

    const onDrawerSubmit = () => {
        form.submit();
    }

    const handleUpdateSuccess = () => {
        dispatch(asyncLaunchNotification({
            type: "success",
            config: {
                message: t("changeOrder.reasonChangedTitle"),
                description: t("changeOrder.reasonChangedDescription")
            }
        }));
        dispatch(getChangeOrder(coId!));
        toggle();
    }

    const onFinish = (values: any): void => {
        if (coId) {
            dispatch(updateReason({ id: coId, reasonId: values.reasonId, onSuccess: handleUpdateSuccess }));
        }
    };

    const genericFilter = (input: string, option: any) => (
        option?.children?.toLowerCase!()?.indexOf!(input?.toLowerCase!()) >= 0
    )

    return (
        <Drawer
            title={t("changeOrder.reasonLabel")}
            width={400}
            onClose={onDrawerClose}
            open={open}
            bodyStyle={{ paddingBottom: 80 }}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                initialValues={{
                    reasonId: currentReasonId ?? null
                }}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="reasonId"
                            label={t("changeOrder.coReasonLabel")}
                        >
                            <Select
                                allowClear
                                optionFilterProp="children"
                                showSearch
                                filterOption={genericFilter}
                            >
                                {reasons?.map((reason: IChangeOrderReason) => (
                                    <Option value={reason.id!} key={reason.id!}>
                                        {reason.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24} className="text-center">
                        <Alert
                            message={<div style={{ color: colorTextSecondary }}><div>{t("changeOrder.reasonAlertPart1")}</div><br /><div>{t("changeOrder.reasonAlertPart2")}</div></div>}
                            type="info"
                            style={{ backgroundColor: colorBgLayout, borderColor: colorBorder }}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24} className="text-right pt-30">
                        <Space>
                            <Button type="default" onClick={onDrawerClose}>
                                {t("generic.cancel")}
                            </Button>
                            <Button onClick={onDrawerSubmit} type="primary">
                                {t("generic.save")}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
}