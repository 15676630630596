import { projectsRepository } from "../infrastructure/repositories/projects.repository";

export const projectsService = {
  getAll: projectsRepository.getAll,
  getById: projectsRepository.getById,
  getProjectOverview: projectsRepository.getProjectOverview,
  getProjectOverviewTeam: projectsRepository.getProjectOverviewTeam,
  getProjectDeliverables: projectsRepository.getProjectDeliverables,
  getProjectDeliverableDetailById: projectsRepository.getProjectDeliverableDetailById,
  getProjectDeliverablesDataGantt: projectsRepository.getProjectDeliverablesDataGantt,
  evaluateTimelineDeliverable: projectsRepository.evaluateTimelineDeliverable,
  applyChangesTimelineDeliverable: projectsRepository.applyChangesTimelineDeliverable,
  create: projectsRepository.create,
  update: projectsRepository.update,
  linkOxBlueIdToProject: projectsRepository.linkOxBlueIdToProject,
  unlinkOxBlueIdToProject: projectsRepository.unlinkOxBlueIdToProject,
  updateNickNameForProject: projectsRepository.updateNickNameForProject,
  updateArchiveStatus: projectsRepository.updateArchiveStatus,
  updateProjectEntity: projectsRepository.updateProjectEntity,
  updateStatus: projectsRepository.updateStatus,
  updateType: projectsRepository.updateType,
  delete: projectsRepository.delete,
  getTemplates: projectsRepository.getTemplates,
  getTemplateById: projectsRepository.getTemplateById,
  getTemplatesRoles: projectsRepository.getTemplatesRoles,
  getTemplateDeliverables: projectsRepository.getTemplateDeliverables,
  getTemplateDeliverableApprovals: projectsRepository.getTemplateDeliverableApprovals,
  applyTemplateToCreateAProject: projectsRepository.applyTemplateToCreateAProject,
  getTeamMembers: projectsRepository.getTeamMembers,
  updateTeamMember: projectsRepository.updateTeamMember,
  createTeamMember: projectsRepository.createTeamMember,
  deleteTeamMember: projectsRepository.deleteTeamMember,
  getRfiDefaultUsers: projectsRepository.getRfiDefaultUsers,
  getChangeOrdersDefaultUsers: projectsRepository.getChangeOrdersDefaultUsers,
  duplicateTemplate: projectsRepository.duplicateTemplate,
  createTemplate: projectsRepository.createTemplate,
  saveTemplateComplete: projectsRepository.saveTemplateComplete,
  getReasonCodes: projectsRepository.getReasonCodes,
  createProjectDeliverable: projectsRepository.createProjectDeliverable,
};