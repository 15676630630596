// WhiteList File Upload Extentions - (Valid Extentions)
export const VALID_EXTENSIONS = [
  // Documents
  '.doc',
  '.docx',
  '.docm', // Word Macro-Enabled Document
  '.dot',
  '.dotx',
  '.dotm',
  '.rtf',
  '.txt',
  '.odt',
  '.ott',

  // Spreadsheets
  '.xls',
  '.xlsx',
  '.xlsm', // Excel Macro-Enabled Workbook
  '.xlsb',
  '.csv',
  '.xls2003',
  '.ods',
  '.tsv',
  '.xlt',
  '.xltx',
  '.xltm',
  '.xlam',
  '.fods',
  '.sxc',

  // Presentations
  '.ppt',
  '.pptx',
  '.pps',
  '.ppsx',
  '.odp',
  '.pot',
  '.potx',
  '.potm',
  '.pptm', // PowerPoint Macro-Enabled Presentation
  '.ppsm',
  '.fodp',

  // Images
  '.tif',
  '.tiff',
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.bmp',
  '.ico',
  '.dib',
  '.jpc',
  '.jpeg-ls',
  '.jpeg2000',
  '.webp',
  '.heic',
  '.heif',

  // Portable
  '.pdf',
  '.xps',
  '.oxps',
  '.epub',

  // html
  '.htm',
  '.html',
  '.mhtml',

  // Metafiles
  '.emz',
  '.wmz',

  // Photoshop
  '.psd',

  // Project
  '.mpp', // Microsoft Project
  '.mpt',
  '.mpx',

  // Outlook: pst, ost
  '.pst',
  '.ost',

  // Email:
  '.msg',
  '.eml',
  '.emlx',

  // Diagrams
  '.vsd',
  '.vsdx',
  '.vsdm',
  '.vss',
  '.vssm',
  '.vst',
  '.vstm',
  '.vsx',
  '.vtx',
  '.vdw',
  '.vdx',
  '.svg',
  '.svgz',

  // Autocad:
  '.dxf', // Drawing Exchange Format
  '.dwg', // AutoCAD drawing
  '.dwf',
  '.stl',
  '.ifc', // Industry Foundation Classes
  '.dwt',

  // Postscript
  '.eps', // Encapsulated PostScript
  '.ps',
  '.psl',
  '.cgm',

  // Coreldraw
  '.dr',
  '.cmx',

  // Other
  '.vcf',
  '.plt',
  '.lgs',
  '.otg',
  '.md',
  '.ai',
  '.log',

  '.zip',
  '.rvt', // Revit project
  '.skp', // SketchUp project
  '.ifc', // Industry Foundation Classes
  '.stp', // STEP 3D CAD file
  '.igs', // IGES 3D CAD file
  '.stl', // Stereolithography 3D file
  '.3ds', // 3D Studio Max file
  '.obj', // Wavefront OBJ file
  '.ai', // Adobe Illustrator
  '.psd', // Adobe Photoshop
  '.indd', // Adobe InDesign
  '.xd', // Adobe XD
  '.svg', // Scalable Vector Graphics
  '.sketch', // Sketch file
  '.fig', // Figma design file
  '.key', // Apple Keynote
  '.numbers', // Apple Numbers
  '.pages', // Apple Pages

  '.hevc', // Video

  '.mp4',
  '.mpg',
  '.mpeg',
  '.avi',
  '.mwv',
  '.mov',

  '.mp3',
  '.wav',
  '.wma',
];