import { CommentSvgIcon } from "@components/Icons/CommentSvgIcon";
import { WHITE_ABSOLUTE } from "@providers/ThemeProvider";
import { theme } from "antd";
import { isNumber } from '@shared/util/number-util';

interface IBadgeCommentIndicatorProps {
  total: number;
}

export const BadgeCommentIndicator = (props: IBadgeCommentIndicatorProps) => {

    const { total } = props;

    const { token: { colorBgSpotlight }} = theme.useToken();
    
    return (
      <>
       <div 
            className="flex flex-row items-center pt-3 pb-3 pr-10 pl-10"
            style={{
                borderRadius: 4,
                border: `1px solid ${colorBgSpotlight}`,
                background: colorBgSpotlight,
                backdropFilter: `blur(2px)`
            }}
            >
            <CommentSvgIcon 
                className="mr-7"
                style={{ color: `var(--neutral-7)` }}
            />
            <span 
              data-testid="container-total-value"
              style={{ color: WHITE_ABSOLUTE }}
            >
                { isNumber(total) ? total : "" }
            </span>
        </div>
      </>
    )
}
