import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { blue } from '@ant-design/colors';
import { Button, Checkbox, Col, Drawer, Form, Input, Row, Space, theme } from 'antd';
import { AppDispatch, useAppSelector } from '@store/store';
import { isFulfilled } from '@reduxjs/toolkit';
import { useNotificationsProvider } from '@providers/NotificationsProvider';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { createEntity } from '@store/slices/company-contacts-extended';
import { getEntitiesByCompany } from '@store/slices/company-contacts-extended';
import { ICompanyContact } from '@models/company.contact.model';
import { useParams } from 'react-router-dom';
import { useErrorAnimation } from '@HOOKs/UseErrorAnimation';
import { If } from '@components/Utils/Structural';
import { useTranslation } from 'react-i18next';
import { AddressMap } from '@components/AddressGeneric/AddressMap';
import { IOptionMap } from '@components/AddressGeneric/AddressGenericUpdate';
import { AutoCompleteAddressCustom } from '@components/Locations/AutocompleteAddress/AutoCompleteAddressCustom';
import { IAddress } from '@models/address.model';
import { ADDRESS_TYPE_PRIMARY } from '@constants/core';
import { LinkOffIcon } from '@components/Icons/LinkOffSvgIcon';
import LinkContact from './LinkContactDrawer';
import { LinkOnSvgIcon } from '@components/Icons/LinkOnSvgIcon';
import { NEUTRAL_8_COLOR, NEUTRAL_6_COLOR } from '@providers/ThemeProvider';

const ContactCreate = ({ open, setOpen }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { updating } = useAppSelector(state => state.Location);
  const { entities: contacts } = useAppSelector(state => state.CompanyContact);

  const [optionMap, setOptionMap] = useState<IOptionMap>({ markerOptions: [], placeResult: null });

  const { t } = useTranslation();

  const [openLinkContactDrawer, setOpenLinkContactDrawer] = useState(false);

  const {
    token: { colorError, colorTextTertiary, fontSize, colorPrimary, colorWarningActive },
  } = theme.useToken();

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue('primary', !Boolean(contacts.length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  const { openNotification } = useNotificationsProvider();
  const { id } = useParams<'id'>();

  const getCompanyContacts = () => {
    if (id) {
      dispatch(
        getEntitiesByCompany({
          page: 0,
          size: 1000,
          sort: `firstName,asc`,
          query: `companyId.equals=${id}`,
        })
      );
    }
  };

  const onClose = (): void => {
    setOpen(false);
    setSelectedUser(null);
  };

  const [hiddenFields, setHiddenFields] = useState<Partial<IAddress>>();

  const [animateError, playAnimationOfError] = useErrorAnimation();

  const [selectedUser, setSelectedUser] = useState<number | null>(null);

  const onFinish = (entity: Partial<ICompanyContact>): void => {
    let requestData = {
      company: { id: Number(id) },
      firstName: entity.firstName,
      lastName: entity.lastName,
      primary: Boolean(entity.primary),
      email: entity.email,
      address: {
        ...entity.address,
        addressType: ADDRESS_TYPE_PRIMARY,
        streetAddress: addressInputValue,
        ...hiddenFields,
      },
    };

    if (selectedUser) {
      requestData['user'] = { id: selectedUser };
    }

    const onSuccessCreateCompanyContact = (response: any) => {
      if (isFulfilled(response)) {
        openNotification({
          type: 'info',
          config: {
            icon: <CheckCircleTwoTone style={{ fontSize: 24 }} twoToneColor={[blue[5], blue[0]]} />,
            message: `${t('company.contact.newContactCreation')}`,
            description: `${t('company.contact.contactCreatedForCompany')}`,
          },
        });
        form.resetFields();
        setSelectedUser(null);
        setIsPrimaryAlreadyExist(false);
        getCompanyContacts();
        onClose();
      } else {
        playAnimationOfError();
      }
    };
    dispatch(createEntity({ ...requestData })).then(onSuccessCreateCompanyContact);
  };

  const onFinishFailed = (errorInfo: any) => {
    playAnimationOfError();
  };

  const onSubmit = () => {
    form.submit();
  };

  const { entities } = useAppSelector(state => state.CompanyContact);

  const [isPrimaryAlreadyExist, setIsPrimaryAlreadyExist] = useState(false);

  const [addressInputValue, setAddressInputValue] = useState('');

  const checkExistingPrimary = (_: any, value: string) => {
    if (value) {
      let doesPrimaryExists = entities.find(contact => contact.primary === true);
      if (doesPrimaryExists && Boolean(form.getFieldValue('primary'))) setIsPrimaryAlreadyExist(Boolean(doesPrimaryExists));
      else setIsPrimaryAlreadyExist(false);
    } else setIsPrimaryAlreadyExist(false);
    return Promise.resolve();
  };

  const onSelectItemInAutocomplete = (address: any) => {
    const { subAddress = '', city = '', stateProvince = '', postalCode = '', country, latitude, longitude, googleMapPlace } = address;
    form.setFieldsValue({
      address: {
        streetAddress2: subAddress,
        city: city,
        stateProvince: stateProvince,
        postalCode: postalCode,
        country: country,
      },
    });
    setHiddenFields({
      latitude,
      longitude,
    });

    const marker = [
      {
        position: { lat: +latitude, lng: +longitude },
        gmpClickable: true,
        extraData: address,
      },
    ];
    setOptionMap({ markerOptions: marker, placeResult: googleMapPlace });
  };

  const onChangeAddressValue = (text: string) => {
    setAddressInputValue(text);
    form.setFieldsValue({
      address: {
        streetAddress: text,
      },
    });
  };

  return (
    <>
      <Drawer
        title={`${t(`company.contact.createContact`)}`}
        width={580}
        onClose={onClose}
        open={open}
        styles={{ body: { paddingBottom: 80 } }}
        extra={
          <Space>
            <Button type="default" onClick={onClose}>
              {t('generic.cancel')}
            </Button>
            <Button onClick={onSubmit} type="primary" loading={updating}>
              {t('generic.save')}
            </Button>
          </Space>
        }
      >
        <div className="flex flex-col h-full">
          <div className="flex flex-col" style={{ flexGrow: 1 }}>
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className={`${animateError && 'error-animation'}`}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="firstName"
                    label={`${t('company.contact.firstName')}`}
                    rules={[{ required: true, message: `${t('company.contact.enterFirstName')}` }]}
                  >
                    <Input placeholder={`${t('company.contact.enterFirstName')}`} allowClear />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="lastName"
                    label={`${t('company.contact.lastName')}`}
                    rules={[{ required: true, message: `${t('company.contact.enterLastName')}` }]}
                  >
                    <Input placeholder={`${t('company.contact.enterLastName')}`} allowClear />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item rules={[{ validator: checkExistingPrimary }]} name="primary" valuePropName="checked">
                    <Checkbox>{t('company.contact.setAsPrimaryContact')}</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <If condition={isPrimaryAlreadyExist}>
                    <div className="mb-20" style={{ color: colorError }}>
                      {t(`company.contact.primaryContactAlreadyExistsWarning`)}
                    </div>
                  </If>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name={['address', 'streetAddress']} label={t('generic.address.addressLine1')}>
                    <AutoCompleteAddressCustom
                      onChangeAutocomplete={onSelectItemInAutocomplete}
                      onChangeValueInput={onChangeAddressValue}
                      placeholder={t('generic.address.addressLine1Placeholder')}
                      allowClear
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name={['address', 'streetAddress2']} label={t('generic.address.addressLine2')}>
                    <Input placeholder={t('generic.address.addressLine2Placeholder')} allowClear />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={8}>
                <Col span={8}>
                  <Form.Item name={['address', 'city']} label={t('generic.address.city')}>
                    <Input placeholder={t('generic.address.city')} allowClear />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={['address', 'stateProvince']} label={t('generic.address.state')}>
                    <Input placeholder={t('generic.address.state')} allowClear />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={['address', 'postalCode']} label={t('generic.address.postalCode')}>
                    <Input placeholder={t('generic.address.postalCode')} allowClear />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name={['address', 'country']} label={t('generic.address.country')}>
                    <Input placeholder={t('generic.address.country')} allowClear />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name={['address', 'phone']} label={t('generic.address.phoneNumber')}>
                    <Input placeholder={t('generic.address.phoneNumber')} allowClear />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={['address', 'email']} label={t('generic.address.email')}>
                    <Input placeholder={t('generic.address.email')} allowClear />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>{open && <AddressMap optionMap={optionMap} />}</Col>
              </Row>
            </Form>
            <LinkContact
              selectedUser={selectedUser}
              onSubmit={() => {
                setOpenLinkContactDrawer(false);
              }}
              setSelectedUser={setSelectedUser}
              open={openLinkContactDrawer}
              setOpen={setOpenLinkContactDrawer}
            />

            {selectedUser ? (
              <div className="mt-20 mb-20 pb-20 pt-20">
                <Row justify={'space-between'} align={'middle'} className="mb-10 mt-10">
                  <Col>
                    <div className="pr-20">
                      <LinkOnSvgIcon />
                    </div>
                  </Col>
                  <Col flex="1">
                    <div className="flex flex-col">
                      <div className="font-bold" style={{ color: NEUTRAL_8_COLOR, fontSize: fontSize + 2 }}>{`${t(
                        'company.contact.linkContact.linkedToUserAccount'
                      )}`}</div>
                    </div>
                  </Col>
                  <Col>
                    <Button onClick={() => setSelectedUser(null)} type="text" danger>
                      <span>{`${t('generic.unlink')}`}</span>
                    </Button>
                  </Col>
                </Row>
                <Row className="ml-40">
                  <Col>
                    <span style={{ color: NEUTRAL_6_COLOR }}>
                      {`${t('company.contact.linkContact.userAccount')}`} #{selectedUser}
                    </span>{' '}
                  </Col>
                </Row>
              </div>
            ) : (
              <div className="flex mt-20 mb-20 pb-20 pt-20">
                <div className="pr-20">
                  <LinkOffIcon />
                </div>
                <div className="flex flex-col">
                  <div className="font-bold" style={{ color: colorTextTertiary, fontSize: fontSize + 2 }}>{`${t(
                    'company.contact.linkContact.linkToUserAccount'
                  )}`}</div>
                  <div className="mt-5" style={{ color: colorWarningActive }}>{`${t(
                    'company.contact.linkContact.noUserAccountLinked'
                  )}`}</div>
                  <div
                    className="mt-15 cursor-pointer"
                    onClick={() => setOpenLinkContactDrawer(true)}
                    style={{ color: colorPrimary }}
                  >{`${t('company.contact.linkContact.linkToExistingProfile')}`}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ContactCreate;
