import React from 'react';
import { CommonFooterModal, ModalWrapper, OKButtonModal } from '@components/Utils/ModalWrapper'
import { Col, Row } from 'antd';

interface IRequestApprovalSentAlertModalProps {
    open: boolean,
    setOpen: (state: boolean) => void
    userNotified: string
}

export const RequestApprovalSentAlertModal: React.FC<IRequestApprovalSentAlertModalProps> = ({ open, setOpen, userNotified}) => {

    const footerAlertSuccess = (
        <CommonFooterModal className='justify-center items-center'>
            <OKButtonModal onClick={() => setOpen(false) } >Okay</OKButtonModal>
        </CommonFooterModal>
    )

    return (
        <>
            <ModalWrapper  
                footer={footerAlertSuccess}
                open={open}
            >
                <Row justify="space-evenly" className="pt-30 pb-10">
                    <Col span={24} flex={"auto"} className="pt-30 pb-20 ml-30 mr-30 flex items-center justify-center">
                        <div className="tracking-normal font-bold text-center" style={{ fontSize: "16px", lineHeight: "26px" }}>
                            Submitted!
                        </div>
                    </Col>
                    <Col span={24} flex={"auto"} className="pt-10 pb-20  ml-30 mr-30 flex items-center justify-center">
                        <div className="tracking-normal text-center" style={{ lineHeight: "26px" }}>
                            { userNotified || "" } has been notified. Please check back later to review the status of the approval.
                        </div>
                    </Col>
                </Row>
            </ModalWrapper>
        </>
    )
}