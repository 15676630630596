import { CloseButtonModal } from "../CloseButtonModal";
import { borderRadiusFromBottomStyleDrawersInMobile } from "@providers/ThemeProvider";
import { Badge, Col, Drawer, Row, theme } from "antd";
import { IAttachment } from "@models/attachment.model";
import { BodyCommentsPanel } from "../../CommentsPanel";
import { useResizeDetector } from "react-resize-detector";
import { CommentSvgIcon } from "@components/Icons/CommentSvgIcon";
import { useAppSelector } from "@store/store";
import { If } from "@components/Utils/Structural";

interface IDrawerCommentsAttachmentProps {
    open: boolean,
    onClose: () => void,
    attachment: IAttachment
}

export const DrawerCommentsAttachment = (props: IDrawerCommentsAttachmentProps) => {

    const { open, onClose, attachment } = props;

    const { token: { colorFillTertiary, colorTextSecondary, colorPrimary, colorInfoBg, colorPrimaryText } } = theme.useToken();

    const { height: heightContainerPanel = 0, ref: containerPanelRef } = useResizeDetector({
        refreshMode: 'debounce',
        refreshRate: 50,
    });

    const { totalItems } = useAppSelector((state) => state.CommentVersion2Slice);
    
    return (
        <Drawer
            open={open} 
            closable={false}
            placement="bottom"
            width={"100%"}
            height={"80%"}
            onClose={onClose}
            styles={{
                wrapper: borderRadiusFromBottomStyleDrawersInMobile,
                content: borderRadiusFromBottomStyleDrawersInMobile,
                header: { padding: 0 },
                body: { padding: '0.5rem', height: '100%' },
            }}
            title={
                <div className="flex flex-row items-center justify-between pt-15 pb-15" style={{ background: colorFillTertiary }}>
                    <div className="flex flex-row items-center pl-20 ml-4">
                        <CommentSvgIcon 
                            className="cursor-pointer" 
                            height="22" 
                            width="22" 
                            style={{ color: colorPrimary }}  
                        />
                        <span className="ml-6" style={{ color: colorTextSecondary }}>
                            Comments
                        </span>
                        <If condition={Boolean(totalItems)}>
                            <Badge 
                                count={<span style={{ color: colorPrimaryText }}>{totalItems}</span>} 
                                size="small" 
                                rootClassName='ml-10' 
                                style={{ 
                                    display: "flex", 
                                    alignItems: "center",
                                    backgroundColor: colorInfoBg, 
                                    paddingLeft: 8, 
                                    paddingRight: 8, 
                                    height: 20, 
                                    borderRadius: 100 
                                }} 
                            />
                        </If>
                    </div>
                    <div className="pr-20 mr-4">
                        <CloseButtonModal
                            onCloseModalPreview={onClose} 
                            containerStyles={{ width: "2rem", height: "2rem" }}
                        />
                    </div>
                </div>
            }    
        >   
            <Row className="h-full">
                <Col span={24} className="h-full" ref={containerPanelRef}>
                    <BodyCommentsPanel 
                        attachment={attachment} 
                        heightContainer={heightContainerPanel} 
                    />
                </Col>
            </Row>
        </Drawer>
    )
}
