import { Card, Col, Row, theme } from "antd";
import Big from "big.js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface ICostSummaryProps {
    materialsSubtotal: Big,
    laborSubtotal: Big,
    currencySymbol: string | undefined,
    info?: boolean,
}

export const CostSummary = (props: ICostSummaryProps) => {

    const { materialsSubtotal, laborSubtotal, currencySymbol, info } = props;
    const { token: { colorTextTertiary, colorBgLayout, colorInfoBg, colorBorderSecondary, colorInfoBorder } } = theme.useToken();
    const { t } = useTranslation();
    const [total, setTotal] = useState<Big>(Big(0))
    const [fractionDigits, setFractionDigits] = useState<number>(2)
    const minDecimals = 2;
    const maxDecimals = 8;

    useEffect(() => {
        const tot = materialsSubtotal.add(laborSubtotal);
        setTotal(tot);
        const decimals = Math.max(countDecimals(materialsSubtotal.toNumber()), countDecimals(laborSubtotal.toNumber()), countDecimals(tot.toNumber()));
        setFractionDigits(decimals < minDecimals ? minDecimals : (decimals > maxDecimals ? maxDecimals : decimals));
    }, [materialsSubtotal, laborSubtotal])

    const countDecimals = (value: number): number => {
        if (Math.floor(value) === value) return 0;
        return value.toString().split(".")[1].length || 0;
    }

    return (
        <Card style={{ width: 350, borderRadius: '4px', borderColor: info ? colorInfoBorder : colorBorderSecondary, backgroundColor: info ? colorInfoBg : colorBgLayout }}
            bodyStyle={{ paddingTop: 15, paddingBottom: 15, paddingLeft: 20, paddingRight: 20 }}>
            <Row>
                <Col span="14">{t("changeOrder.costsDetail.materialsSubtotal")}:</Col>
                <Col span="1" style={{ color: colorTextTertiary, fontWeight: 500 }}>{currencySymbol}</Col>
                <Col span="9" className="text-right" style={{ fontFamily: "'Roboto Mono', monospace" }}>{materialsSubtotal.toFixed(fractionDigits)}</Col>
            </Row>
            <Row>
                <Col span="14">{t("changeOrder.costsDetail.laborSubtotal")}:</Col>
                <Col span="1" style={{ color: colorTextTertiary, fontWeight: 500 }}>{currencySymbol}</Col>
                <Col span="9" className="text-right" style={{ fontFamily: "'Roboto Mono', monospace" }}>{laborSubtotal.toFixed(fractionDigits)}</Col>
            </Row>
            <Row>
                <Col span="14">{t("changeOrder.costsDetail.total")}:</Col>
                <Col span="1" style={{ color: colorTextTertiary, fontWeight: 500 }}>{currencySymbol}</Col>
                <Col span="9" className="text-right" style={{ fontFamily: "'Roboto Mono', monospace" }}>{total.toFixed(fractionDigits)}</Col>
            </Row>
        </Card>
    );
}