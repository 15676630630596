import { createContext, useContext } from "react";
import { useErrorAnimation } from "@HOOKs/UseErrorAnimation";

export const PreferencesContext = createContext({} as {
    animateError: boolean,
    playAnimationOnError: () => void
});

export const ContainerErrorAnimation = ({ children }: any) => {

    const [animateError, playAnimationOnError] = useErrorAnimation();

    const value = {
        animateError,
        playAnimationOnError
    }
    
    return (
        <PreferencesContext.Provider value={value}>{children}</PreferencesContext.Provider>
    )
}

export const useContainerErrorAnimation = () => {
    return useContext(PreferencesContext);
};