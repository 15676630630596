
import { IRoleBinding } from '@models/role-binding.model';
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { projectsService } from '@services/projects.service';
import { serializeAxiosError } from "../reducer.util";
import { StringORNumber } from '@infrastructure/repositories/utils.repository';

interface IStateEntity {
    loading: boolean,
    errorMessage: any,
    roleBindings: IRoleBinding[] | undefined,
}

export const getChangeOrdersDefaultUsers = createAsyncThunk(
    'projectChangeOrdersDefaultUsers',
    async (projectId: StringORNumber) => {
        return projectsService.getChangeOrdersDefaultUsers(projectId);
    },
    { serializeError: serializeAxiosError }
);

const initialState: IStateEntity = {
    loading: false,
    errorMessage: null,
    roleBindings: undefined,
};

export const ProjectChangeOrdersDefaultUsersSlice = createSlice({
    name: 'projectChangeOrdersDefaultUsers',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(isFulfilled(getChangeOrdersDefaultUsers), (state, action) => {
                const { data } = action.payload;

                return {
                    ...state,
                    loading: false,
                    roleBindings: data,
                };
            })
            .addMatcher(isPending(getChangeOrdersDefaultUsers), state => {
                state.errorMessage = null;
                state.loading = true;
            });
    }
});

// Reducer
export default ProjectChangeOrdersDefaultUsersSlice.reducer;
