import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
} from "antd";

import { AppDispatch, useAppSelector } from "@store/store";
import { getEntity } from "@store/slices/location";
import { getEntities as getSiteStatuses } from "@store/slices/sites-statuses";
import { createEntity } from "@store/slices/site";
import { useNavigate } from "react-router-dom";
import { DATE_FORMAT_DEFAULT } from '@shared/util/date-utils';
import { ISite } from '../../../models/site.model';
import { useTranslation } from "react-i18next";

const SitesCreate = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { entity: locationEntity, updating } = useAppSelector((state) => state.Location);
  const { entities: siteStatuses, loading: loadingSiteStatuses } = useAppSelector((state) => state.SiteStatuses);

  const [open, setOpen] = useState(true);
  const [animateError, setAnimateError] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [form] = Form.useForm();

  const { id } = useParams<"id">();

  useEffect(() => {
    dispatch(getSiteStatuses({ sort: "order,asc" }));
  }, [dispatch]);

  const onClose = (): void => {
    const locationId = `${locationEntity.id}`;
    setOpen(false);
    dispatch(getEntity(locationId));
    navigate(`/location/${locationId}`);
  };

  const onFinish = (entity: Partial<ISite>): void => {
    if (formSubmitting) return;

    setFormSubmitting(true);
    const { name, openDate, status, closeDate } = entity;
    const entityToCreate: Partial<ISite> = {
      ...name && { name },
      ...openDate && { openDate },
      ...closeDate && { closeDate },
      ...status && { status: [...siteStatuses]?.find!((item) => item.id === status) },
      location: {
        id
      }
    };
    dispatch(createEntity({ ...entityToCreate })).then(() => onClose()).finally(() => {
      setFormSubmitting(false);
    });
  };

  const playAnimationOfError = () => {
    setAnimateError(true);
    setTimeout(() => {
      setAnimateError(false);
    }, 500);
  };

  const onFinishFailed = (errorInfo: any) => {
    playAnimationOfError();
  };

  const onSubmit = () => {
    form.submit();
  };

  return (
    <>
      <Drawer
        title={`Add ${t('generic.site')}`}
        width={580}
        onClose={onClose}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button type="primary" ghost onClick={onClose}>
              Cancel
            </Button>
            <Button
              data-testid="submitCreateSite"
              onClick={onSubmit}
              type="primary"
              loading={updating}
            >
              Save
            </Button>
          </Space>
        }
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className={`${animateError && "error-animation"}`}
        >

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                data-testid="siteName"
                name="name"
                label={`${t('generic.site')} Name`}
                rules={[
                  { required: true, message: `Please enter ${t('generic.site')} Name` },
                ]}
              >
                <Input placeholder={`Please enter ${t('generic.site')} Name`} allowClear />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="openDate" label="Open Date">
                <DatePicker
                  data-testid="siteOpenDate"
                  name="openDate"
                  className="w-full"
                  format={DATE_FORMAT_DEFAULT}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="status"
                label="Status"
                rules={[
                  { required: true, message: "Please select Status" },
                ]}
              >
                <Select
                  data-testid="siteStatus"
                  loading={loadingSiteStatuses}
                  placeholder="Please select a Status"
                  options={siteStatuses?.map((item) => ({ value: item.id, label: item?.description }))}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>

        </Form>

      </Drawer>
    </>
  );
};

export default SitesCreate;
