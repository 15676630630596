import { createAsyncThunk, createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { projectsService } from '../../services/projects.service';
import { StringORNumber } from "../../infrastructure/repositories/utils.repository";
import { IProjectDeliverable } from '../../models/project-deliverable';

export interface IUtil {
  data: any,
  loading: boolean,
  updateSuccess: boolean,
  errorMessage: any
}

export interface IState extends IUtil {
  data: {
    projectGanttData: any
  }
}

export interface RequestChangeStartDate {
  projectId: string;
  deliverableId: string;
  bodyRequest: Partial<IProjectDeliverable>;
}

export interface RequestForApproval {
  projectId: string;
  projectDeliverableId: string;
}

const initialState: IState = {
  data: {
    projectGanttData: {},
  },
  loading: false,
  updateSuccess: false,
  errorMessage: null
};

const sliceName: string = "project-deliverables-gannt-chart"

export const getProjectDeliverablesDataToGanttChart = createAsyncThunk(
  `${sliceName}/getProjectDeliverablesDataToGanttChart`,
  async (projectId: StringORNumber, thunkAPI) => {
    return projectsService.getProjectDeliverablesDataGantt(projectId)
                          .catch(() => thunkAPI.dispatch(reset()));
  }
)

export const ProjectDeliverablesGanttSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getProjectDeliverablesDataToGanttChart.fulfilled, (state, action) => {
        const data = action.payload;
        if (data) { state.data.projectGanttData = data }
        state.errorMessage = null;
        state.loading = false;
      })
      .addMatcher(isPending(getProjectDeliverablesDataToGanttChart), state => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isRejected(getProjectDeliverablesDataToGanttChart), (state, action) => {
        state.errorMessage = action?.error?.message;
        state.loading = false;
      });
  }
});

export const { reset } = ProjectDeliverablesGanttSlice.actions;

// Reducer
export default ProjectDeliverablesGanttSlice.reducer;