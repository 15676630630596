import { getOxBlueCameraDetailsById, getOxBlueCameraDateTimes, getOxBlueProjectById, getOxBlueCameraImagesByTimes } from '@store/slices/ox-blue-projects';
import { useAppDispatch, useAppSelector } from '@store/store';
import { Col, Row, theme, Button, Spin, Typography, Radio, RadioChangeEvent, DatePicker } from 'antd'
import { FilePaginator } from './FilePaginator';
import { useEffect, useRef, useState } from 'react';
import { getFirstItem, isArrayWithValues } from '@shared/util/array-util';
import { secondaryColor } from '@providers/ThemeProvider';
import { LoadingOutlined } from '@ant-design/icons';
import { If } from '@components/Utils/Structural';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import LazyImg from './LazyLoader';



interface IMappedTimes {
    time: string,
    isLoaded: boolean,
    url: string | null
}

type IDateSelectorTabs = "DEFAULT" | "CALENDER"

dayjs.extend(relativeTime);

const { Title } = Typography;

export const OxBlueImageView = () => {
    const dispatch = useAppDispatch();

    const CUSTOM_BORDER_CAMERA_VIEW = "#424646"

    const { token: { colorBgBase, colorBorderSecondary } } = theme.useToken();

    const { data: { oxBlueProjectEntity } } = useAppSelector((state) => state.OxBlueProjects);
    const { data: { oxBlueCameraDates } } = useAppSelector((state) => state.OxBlueProjects);
    const { data: { oxBlueCameraEntity } } = useAppSelector((state) => state.OxBlueProjects);
    const { data: { oxBlueCameraImageFeed }, loading: loadingCameraFeed } = useAppSelector((state) => state.OxBlueProjects);
    const { entity: projectEntity } = useAppSelector((state) => state.Project);


    const [srcForHeroImage, setSrcForHeroImage] = useState<string | null>(null)
    const [selectedDate, setSelectedDate] = useState<string | null>(null);
    const [selectedDateFormatted, setSelectedDateFormatted] = useState<string | null>(dayjs().format('YYYY-MM-DD'));
    const [selectedTimes, setSelectedTimes] = useState<string | null>(null);
    const [selectedCameraId, setSelectedCameraId] = useState(null);
    const [enabledDates, setEnabledDates] = useState<string[]>([]);
    const [mappedLatestTimes, setMappedLatestTimes] = useState<IMappedTimes[]>([]);

    useEffect(() => {
        if (projectEntity.oxblueProjectId) {
            dispatch(getOxBlueProjectById({ id: projectEntity.oxblueProjectId }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectEntity])

    useEffect(() => {
        if (!oxBlueCameraDates || !oxBlueCameraDates.dates) return;
        const dates = Object.keys(oxBlueCameraDates.dates);
        const today = new Date().toISOString().split('T')[0];
        const latestDate = dates[dates.length - 1];
        const selectedDateForTimeStamps = selectedDate ? selectedDate : dates.includes(today) ? today : latestDate;
        if (!selectedDateForTimeStamps) return;

        setEnabledDates(dates)

        setSelectedDate(selectedDateForTimeStamps);
        const year = parseInt(selectedDateForTimeStamps.substring(0, 4));
        const month = parseInt(selectedDateForTimeStamps.substring(4, 6));
        const day = parseInt(selectedDateForTimeStamps.substring(6, 8));
        setSelectedDateFormatted(`${year}-${month}-${day}`)

        if (selectedDateForTimeStamps && dates && oxBlueCameraDates.dates[selectedDateForTimeStamps]) {
            const timesArray = oxBlueCameraDates.dates[selectedDateForTimeStamps];
            if (isArrayWithValues(timesArray)) {
                getImagesForCameraByTimes(timesArray[timesArray.length - 1])
            }
        }


        if (selectedDateForTimeStamps && oxBlueCameraDates.dates[selectedDateForTimeStamps]) {
            const mappedTimes = oxBlueCameraDates.dates[selectedDateForTimeStamps].map(time => ({
                time: time,
                url: null,
                isLoaded: false
            }))
            const sortedMappedTimes = mappedTimes.sort((a, b) => Number(a.time) - Number(b.time));
            setMappedLatestTimes(sortedMappedTimes);
        }


        setTimeout(() => {
            scrollTimelineBackToNow()
        }, 1500)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oxBlueCameraDates, selectedDate]);

    const scrollTimelineBackToNow = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft = containerRef.current.scrollWidth - containerRef.current.clientWidth;
        }
    }


    useEffect(() => {
        if (selectedCameraId) {
            dispatch(getOxBlueCameraDetailsById({ id: selectedCameraId }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCameraId])

    useEffect(() => {
        if (oxBlueCameraImageFeed && oxBlueCameraImageFeed.path) {
            setSrcForHeroImage(oxBlueCameraImageFeed.path)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oxBlueCameraImageFeed])

    const containerRef = useRef<HTMLDivElement>(null);


    const getImagesForCameraByTimes = (selectedTime) => {
        if (selectedTime) {
            setSelectedTimes(selectedTime)
            dispatch(getOxBlueCameraImagesByTimes({ id: selectedCameraId, date: selectedDate, time: selectedTimes }))
        }
    }



    /** 
    * Function used to disable some dates in DatePicker when condition it's true, example: PlannedStartDate can't be less than today.
    * */
    const disabledPlannedStartDate = (current) => {
        const isDisabled = !enabledDates.includes(new Date(current).toISOString().slice(0, 10).replace(/-/g, ""))
        return !!isDisabled;
    };



    useEffect(() => {
        if (isArrayWithValues(oxBlueProjectEntity.cameras)) {
            setSelectedCameraId(getFirstItem(oxBlueProjectEntity.cameras))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oxBlueProjectEntity])

    useEffect(() => {
        if (selectedCameraId) {
            dispatch(getOxBlueCameraDateTimes({ id: selectedCameraId }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCameraId])






    function formatDateTime(date: string, time: string): string {
        if (!date || !time) {
            return '';
        }

        const year = parseInt(date.substring(0, 4));
        const month = parseInt(date.substring(4, 6));
        const day = parseInt(date.substring(6, 8));

        const hours = parseInt(time.substring(0, 2));
        const minutes = parseInt(time.substring(2, 4));

        const formattedDate = dayjs(`${year}-${month}-${day} ${hours}:${minutes}:00`).format('ddd, MMM Do, YYYY (h:mm A)')
        return formattedDate;
    }


    function calculateTimeAgo(date: string, time: string): string {
        let timeZone = ""
        if (oxBlueCameraEntity?.offsetFromUTC) {
            timeZone = oxBlueCameraEntity.offsetFromUTC
        }
        if (!date || !time) {
            return '';
        }
        var lastDate = date;
        var year = lastDate.substring(0, 4);
        var month = lastDate.substring(4, 6);
        var day = lastDate.substring(6, 8);
        var hour = time.substring(0, 2);
        var minute = time.substring(2, 4);
        var seconds = time.substring(4, 6);
        var finalDate = new Date(year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + seconds + " " + timeZone);
        const timeAgo = dayjs(finalDate).fromNow()

        return timeAgo
    }

    const containerStyle = {
        backgroundColor: colorBgBase,
        border: `1.2px solid ${colorBorderSecondary}`,
        borderRadius: 7,
        maxWidth: "1100px",
        minWidth: "1100px",
        overflow: "hidden",
        margin: 10,
    }


    const updateSelectedImage = (passedId) => {
        if (passedId) {
            setSelectedTimes(passedId)
        }
    }


    function formatTime(inputTime) {
        return dayjs(inputTime, "HHmmss").format("hh:mm A");
    }

    const [mode, setMode] = useState<IDateSelectorTabs>('DEFAULT');
    const [isCalenderPopupVisible, setIsCalenderPopupVisible] = useState<boolean>(false);

    const handleModeChange = (e: RadioChangeEvent) => {
        setMode(e.target.value);
    };


    const selectDateFromPicker = (date) => {
        const numericDate = new Date(date).getDate();
        const numericMonth = new Date(date).getMonth();
        const numericYear = new Date(date).getFullYear();
        setSelectedDate(`${numericYear}${numericMonth < 10 ? "0" : ""}${numericMonth + 1}${numericDate < 10 ? "0" : ""}${numericDate}`)
    }

    const setDateToToday = () => {
        if (!oxBlueCameraDates || !oxBlueCameraDates.dates) return;

        const dates = Object.keys(oxBlueCameraDates.dates);
        const today = new Date().toISOString().split('T')[0];
        const latestDate = dates[dates.length - 1];
        const selectedDateForTimeStamps = dates.includes(today) ? today : latestDate;
        setSelectedDate(selectedDateForTimeStamps)

        if (selectedDateForTimeStamps && dates && oxBlueCameraDates.dates[selectedDateForTimeStamps]) {
            const timesArray = oxBlueCameraDates.dates[selectedDateForTimeStamps];
            if (isArrayWithValues(timesArray)) {
                getImagesForCameraByTimes(timesArray[timesArray.length - 1])
            }
        }


        if (selectedDateForTimeStamps && oxBlueCameraDates.dates[selectedDateForTimeStamps]) {
            const mappedTimes = oxBlueCameraDates.dates[selectedDateForTimeStamps].map(time => ({
                time: time,
                url: null,
                isLoaded: false
            }))
            const sortedMappedTimes = mappedTimes.sort((a, b) => Number(a.time) - Number(b.time));
            setMappedLatestTimes(sortedMappedTimes);
        }
    }



    return (
        <div className={`block sticky top-0`}
            style={containerStyle}>
            <div className='mb-10 mt-10' style={{ padding: 10 }}>
                <Row justify={"space-between"}>
                    <Col className='font-bold' style={{
                        alignContent: "center",
                    }}>
                        <Title level={4}>
                            {oxBlueProjectEntity.name}
                        </Title>
                    </Col>
                    <Row className='flex flex-row items-center'>
                        <Row className='mr-15' align={"middle"}>
                            <FilePaginator selectedCameraId={selectedCameraId} setSelectedCameraId={setSelectedCameraId} />
                        </Row>
                        <Radio.Group onChange={handleModeChange} value={mode} >
                            <Radio.Button
                                onClick={() => {
                                    setDateToToday()
                                }}
                                style={{
                                    borderTopLeftRadius: 20,
                                    borderBottomLeftRadius: 20,
                                }} value="DEFAULT">Today</Radio.Button>
                            <Radio.Button
                                onClick={() => {
                                    setIsCalenderPopupVisible(!isCalenderPopupVisible)
                                }}
                                style={{
                                    borderTopRightRadius: 20,
                                    borderBottomRightRadius: 20
                                }} value="CALENDER">Explore</Radio.Button>
                            <DatePicker
                                disabledDate={disabledPlannedStartDate}
                                open={isCalenderPopupVisible}
                                value={dayjs(selectedDateFormatted)}
                                onChange={selectDateFromPicker}
                                style={{ visibility: "hidden", width: 0 }}
                                placement='bottomLeft'
                                onOpenChange={open => setIsCalenderPopupVisible(open)}
                            />

                        </Radio.Group>
                    </Row>
                </Row>
            </div>
            <div className='flex flex-col align-center justify-center' style={{ overflowX: 'auto', whiteSpace: 'nowrap', minHeight: "825px", maxHeight: "825px" }}>
                <If condition={!Boolean(srcForHeroImage) || loadingCameraFeed}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </If>
                <If condition={Boolean(srcForHeroImage) && !loadingCameraFeed}>
                    <img style={{ flex: '1 0 auto' }} alt='site' width={"100%"} src={srcForHeroImage || ""} />
                </If>
            </div>
            <div style={{ backgroundColor: secondaryColor }} >
                <div style={{ padding: "10px" }}>
                    <div
                        className='flex justify-between pb-10'
                        style={{ borderBottom: `2px solid ${CUSTOM_BORDER_CAMERA_VIEW}` }}>
                        <div className='flex'>
                            <div className='flex items-center'>
                                <h1 style={{ color: "white", fontSize: "17px", padding: "4px", marginBottom: 0 }}>
                                    {formatDateTime(selectedDate || "", selectedTimes || "")}
                                </h1>
                            </div>
                            <If condition={Boolean(selectedTimes)}>
                                <Button style={{ color: "white", marginLeft: "5px" }} type="primary" disabled>
                                    {calculateTimeAgo(selectedDate || "", selectedTimes || "")}
                                </Button>
                            </If>
                        </div>
                        <Button onClick={scrollTimelineBackToNow} type='primary' style={{ marginLeft: "5px" }}>
                            Now
                        </Button>
                    </div>
                    <div>
                        <div className='flex flex-row mt-10' style={{ overflow: "auto" }} ref={containerRef}>
                            {mappedLatestTimes.map((item) => (
                                <div className='m-10 flex flex-col' style={{ margin: 10, minWidth: "210px", width: "210px" }}>
                                    <div
                                        className='cursor-pointer'
                                        onClick={() => updateSelectedImage(item.time)}
                                        style={{
                                            minHeight: "170px",
                                        }}>
                                        <LazyImg setSrcForHeroImage={setSrcForHeroImage} selectedCameraId={selectedCameraId} selectedDate={selectedDate} mappedLatestTimes={mappedLatestTimes} selectedTimes={selectedTimes} item={item} />
                                    </div>
                                    <div className='flex flex-row justify-center mt-5' style={{ color: "white" }}>
                                        {formatTime(item.time)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </div>
        </div >
    )
}
