import { SVGProps } from "react";

type IProps = SVGProps<SVGSVGElement>

export const KeySvgIcon = (props: IProps) => {

    const { ...restProps } = props;

    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <g id="key">
                <path id="Vector" d="M3.5 7C2.95 7 2.5 6.55 2.5 6C2.5 5.45 2.95 5 3.5 5C4.05 5 4.5 5.45 4.5 6C4.5 6.55 4.05 7 3.5 7ZM6.3 5C5.9 3.85 4.8 3 3.5 3C1.85 3 0.5 4.35 0.5 6C0.5 7.65 1.85 9 3.5 9C4.8 9 5.9 8.15 6.3 7H8V9H10V7H11.5V5H6.3Z" fill="currentColor" />
            </g>
        </svg>

    )
}
