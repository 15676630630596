import { HeaderPunchListSidePanel } from "./HeaderPunchListSidePanel/HeaderPunchListSidePanel";
import { PunchListContainer } from "./PunchListContainer/PunchListContainer";
import { ManageBarContainer } from "./ManageBar/ManageBarContainer";
import { CollapsibleButton } from "./ManageBar/CollapsibleButton";
import { theme } from "antd";

export const SidePanel = () => {

    const { token: { colorBorder } } = theme.useToken();
    
    return (
      <div className="relative h-full w-full overflow-hidden" style={{ borderRight: `1.2px solid ${colorBorder}` }}>
        
        <div className="absolute overflow-y-auto h-full w-full">
          <div className="overflow-auto h-full">
            <HeaderPunchListSidePanel />
            <PunchListContainer />
          </div>
        </div>
        
        <div 
          className="absolute w-full" 
          style={{ bottom: 0, left: 0, borderTop: `1.2px solid ${colorBorder}` }}
        >
          <ManageBarContainer />
        </div>

        <div className="absolute inline w-min" style={{ bottom: 10, left: 25 }}>
          <CollapsibleButton />
        </div>
      </div>
    )
}
