import { PictureOutlined } from '@ant-design/icons';
import { If } from '@components/Utils/Structural';
import { IAttachment } from '@models/attachment.model';
import { DerivativesEnum } from '@models/enumerations/derivatives-enum.model';
import { isString } from '@shared/util/validations';
import { Skeleton, theme } from 'antd';
import { useLayoutEffect, useState } from 'react';

interface IThumbnailCardProps {
  attachment: IAttachment,
  counterNode?: React.ReactNode
}

export const ThumbnailCard = (props: IThumbnailCardProps) => {

    const { attachment, counterNode } = props;
    const { derivatives = [] } = attachment;

    const { token: { colorBorderSecondary }} = theme.useToken();

    const [signedUrl, setSignedUrl] = useState("");

    useLayoutEffect(() => {
        const signedUrl = derivatives.find((item) => item.derivativeType === DerivativesEnum.THUMBNAIL)?.signedUrl || "";
        setSignedUrl(signedUrl);
    }, [derivatives])

    return (
      <div 
        className="flex flex-row items-center relative"
        style={{
            marginLeft: 2,
            marginRight: 2
        }}
      >
        <If condition={Boolean(isString(signedUrl) && signedUrl !== "")} >
          <img 
              src={signedUrl} 
              style={{
                  borderRadius: 4, 
                  backgroundColor: colorBorderSecondary,
                  width: 48,
                  height: 48,
                  maxHeight: 48,
                  maxWidth: 48,
                  objectFit: "cover"
              }}
              loading={"lazy"}
              alt=""
          />
        </If>

        <If condition={Boolean(isString(signedUrl) && signedUrl === "")} >
          <LoadingCustomImageSkeleton />
        </If>

        <div style={{ transform: 'translateX(-50%)' }}>
            {counterNode}
        </div>
      </div>
    )
}

export const LoadingCustomImageSkeleton = (props: { active?: boolean }) => {
  const { active = false } = props;
  const { token } = theme.useToken()
  return (
      <Skeleton.Node active={active} className="relative w-full flex items-center" prefixCls="custom-skeleton" >
          <div className="w-full flex items-center justify-center" style={{
              borderRadius: 4,
              backgroundColor: token.colorBorderSecondary,
              width: 48,
              height: 48,
              maxHeight: 48,
              maxWidth: 48,
              objectFit: "cover"
          }}>
              <PictureOutlined style={{ color: token.colorTextQuaternary, fontSize: '1rem' }}  />
          </div>
      </Skeleton.Node>
  )
}
