/* eslint-disable react-hooks/exhaustive-deps */
import { IPunchListItem } from "@models/punch-list.model";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Badge, theme } from "antd";
import { useEffect } from "react";
import { getEntities as getCommentsRichText, reset as resetComments } from '@store/slices/comment-v2';
import { CommentReferenceEnum } from "@models/enumerations/comment-reference-enum.model";
import { isNumber } from "@shared/util/number-util";
import { useTranslation } from "react-i18next";
import { CommentPunchListItemViewer } from "./CommentPunchListItemViewer";
import { UsePunchListRouteSegment } from "../UsePunchListRouteSegment";
import { useNavigate } from "react-router-dom";
import { If } from "@components/Utils/Structural";
import { isArrayWithValues } from "@shared/util/array-util";

interface ICommentListOfPunchListItemContainerProps {
  punchListItem: IPunchListItem;
}

const LATEST_COMMENTS_SIZE  = 5;

export const CommentListOfPunchListItemContainer = (props: ICommentListOfPunchListItemContainerProps) => {

    const { punchListItem } = props;

    const { entities: commentList, totalItems } = useAppSelector((state) => state.CommentVersion2Slice);

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const { t } = useTranslation();

    const { token: { colorPrimary, colorFillQuaternary, lineHeight } } = theme.useToken();

    useEffect(() => {
      getComments();
      return () => {
        dispatch(resetComments());
      }
    }, []);

    const getComments = () => { 
      const punchListItemId = Number(punchListItem?.id);
      if (isNumber(Number(punchListItemId))) { 
        dispatch(getCommentsRichText({ 
          referenceType: CommentReferenceEnum.PUNCH_LIST_ITEM, 
          referenceId: punchListItemId,
          size: LATEST_COMMENTS_SIZE, 
          sort: "createdDate,desc", 
          page: 0 
        }));
      }
    }

    const basePunchListUrlSegment = UsePunchListRouteSegment();

    const onGoDetailsView = () => {
      const punchListPageId = punchListItem?.punchlistPage?.id || "";
      const punchListItemId = punchListItem?.id || "";
      navigate(`${basePunchListUrlSegment}punch-list/page/${punchListPageId}/punch-list-item/${punchListItemId}`);
    }

    return (
      <>
        <div>
          <div className="flex flex-row items-center justify-between mt-5 mb-5">
            <div className="flex flex-row items-center">
              <span className="text-color-original">{ t("generic.comments")}</span>
              <Badge
                  count={totalItems}
                  className='ml-10'
                  style={{ backgroundColor: colorPrimary, borderColor: colorFillQuaternary, paddingLeft: 5, paddingRight: 5 }} 
              />
            </div>
          </div>
          <div 
            className="bg-color-neutral-2 pt-12 pb-12 pl-12 pr-12 text-center text-color-neutral-7 font-14-line-h-22 mt-15 border border-solid border-neutral-3"
            style={{
              borderRadius: 4
            }}
          >    
            <If condition={!isArrayWithValues(commentList)}>
                  <div className="font-normal text-color-neutral-6 pt-5 pr-5 pr-5 pb-5" style={{ lineHeight }}>
                      No Comments Yet
                  </div>
            </If>
            <If condition={isArrayWithValues(commentList)}>
                <>
                  Displaying the last five comments.
                  To see the entire discussion, go to <span onClick={onGoDetailsView} className="text-color-primary cursor-pointer">detailed view.</span>
                </>
            </If>
          </div>
        </div>
        <div className="mt-16">
          { 
            commentList?.map((comment) => (
              <div key={comment.id}>
                <CommentPunchListItemViewer comment={comment} />
              </div>
            ))
          }
        </div>
      </>
    )
}
