import { EntryTime } from "@components/Timeline/EntryTime";
import { ChangeOrdersStatusIds } from "@models/enumerations/change-orders-status";
import { ITimelineEntry } from "@models/timeline.model";
import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

export interface IEntryBodyProps {
    value: ITimelineEntry
}

export const EntryBody = (props: IEntryBodyProps) => {
    const { value } = props;
    const [jsonDetail, setJsonDetail] = useState<any>(undefined)
    const { t } = useTranslation();

    useEffect(() => {
        setJsonDetail(value.detail ? JSON.parse(value.detail) : undefined);
    }, [value])

    const statusNamesMap = new Map([
        [ChangeOrdersStatusIds.PENDING_PUBLISHING, t("changeOrder.created")],
        [ChangeOrdersStatusIds.PENDING_INITIAL_REVIEW, t("changeOrder.published")]
    ]);

    return (
        <Row className="w-full pr-10">
            <Col span={17} className="font-bold">{statusNamesMap.get(jsonDetail?.status)}</Col>
            <Col span={7} className="text-right"><EntryTime date={value.date} /></Col>
        </Row>
    );
}
