import { createAsyncThunk, createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { projectsService } from '../../services/projects.service';
import { projectDeliverableService } from '../../services/project-deliverable.service';
import { StringORNumber } from "../../infrastructure/repositories/utils.repository";
import { IProjectDeliverables, IRequestProjectDeliverableDetailsById } from "../../models/project-model";
import { IProjectDeliverable, IProjectDeliverableStartEndDates, IProjectDeliverableUI } from '../../models/project-deliverable';
import { serializeAxiosError } from '../reducer.util';
import { serializeGenericHandleError } from '@store/thunk.util';
import { isArrayWithValues } from '@shared/util/array-util';

export interface IUtil {
  data: any,
  loadingList: boolean,
  loadingDetails: boolean,
  updateSuccess: boolean,
  errorMessage: any
}

export interface IBasicFilters {
  deliverableName?: string
}
export interface IAdvancedFilters {
  status?: string;
  disciplines?: string;
  phases?: string;
  roles?: string;
  showOnlyKeyMilestone?: boolean;
  hideNotApplicable?: boolean;
}
export interface IState extends IUtil {
  data: IProjectDeliverables,
  lastScrollPosition: number | null,
  allFilters: IBasicFilters & IAdvancedFilters;
  basicfilters: IBasicFilters
  advancedFiters: IAdvancedFilters
  advancedFiltersCount?: number
}

export interface RequestChangeStartDate {
  projectId: string;
  deliverableId: string;
  bodyRequest: Partial<IProjectDeliverable>;
  onSuccess?: () => void
}

export interface RequestChangeActualDates {
  projectId: string;
  deliverableId: string;
  bodyRequest: Partial<IProjectDeliverableStartEndDates>;
  onSuccess?: () => void
}

export interface RequestForApproval {
  projectId: string;
  projectDeliverableId: string;
}

const initialState: IState = {
  data: {
    deliverablesList: [] as IProjectDeliverableUI[],
    deliverablesFiltered: [] as IProjectDeliverableUI[],
    deliverableSelected: {} as IProjectDeliverableUI,
  },
  lastScrollPosition: null,
  allFilters: {},
  basicfilters: {},
  advancedFiters: {},
  advancedFiltersCount: 0,
  loadingList: false,
  loadingDetails: false,
  updateSuccess: false,
  errorMessage: null
};

const sliceName: string = "project-deliverables"

export const createProjectDeliverable = createAsyncThunk(
  `${sliceName}/createProjectDeliverable`,
  async (data: IProjectDeliverable, thunkAPI) => {
    return projectsService.createProjectDeliverable(data);
  }
);

export const getProjectDeliverablesData = createAsyncThunk(
  `${sliceName}/getProjectDeliverablesData`,
  async (id: StringORNumber, thunkAPI) => {
    return projectsService.getProjectDeliverables(id);
  }
)


export const getProjectDeliverableDetailById = createAsyncThunk(
  `${sliceName}/getProjectDeliverableDetailById`,
  async (request: IRequestProjectDeliverableDetailsById, thunkAPI) => {
    return projectsService.getProjectDeliverableDetailById({
      projectId: request.projectId ,
      projectDeliverableId: request.projectDeliverableId
    });
  }
)

export const getProjectDeliverablesDataToGanntChart = createAsyncThunk(
  `${sliceName}/getProjectDeliverablesDataToGanntChart`,
  async (projectId: StringORNumber, thunkAPI) => {
    return projectsService.getProjectDeliverables(projectId);
  }
)

export const updateProjectDeliverable = createAsyncThunk(
  `${sliceName}/updateProjectDeliverable`,
  async ({ projectId, deliverableId, bodyRequest, onSuccess }: RequestChangeStartDate, thunkAPI) => {
    const result = await projectDeliverableService.updateProjectDeliverable(deliverableId, bodyRequest);
    if (result) {
      onSuccess?.();
    }
    return result;
  },
  { serializeError: serializeGenericHandleError }
)

export const removeProjectDeliverableActualDates = createAsyncThunk(
  `${sliceName}/updateProjectDeliverable`,
  async ({ projectId, deliverableId, bodyRequest, onSuccess }: RequestChangeActualDates, thunkAPI) => {
    const result = await projectDeliverableService.removeActualOrStartEndDates(deliverableId, bodyRequest);
    if (result) {
      onSuccess?.();
    }
    return result;
  },
  { serializeError: serializeGenericHandleError }
)

export const requestForApproval = createAsyncThunk(
  `${sliceName}/requestForApproval`,
  async ({ projectId, projectDeliverableId }: RequestForApproval, thunkAPI) => {
    return projectDeliverableService.requestForApproval(projectDeliverableId);
  },
  { serializeError: serializeAxiosError }
)

export const searchDeliverableByAllFilters = (projectDeliverablesList: IProjectDeliverableUI[], filters: IBasicFilters & IAdvancedFilters) => {
  let result: IProjectDeliverableUI[] = projectDeliverablesList;
  
  // Filter: Name of Deliverable 
  if (typeof filters?.deliverableName === "string") {
    if (filters?.deliverableName === "") {
      result = [...projectDeliverablesList];
    } else {
      result = [...projectDeliverablesList.filter((projectDeliverable) => String(projectDeliverable.deliverable?.name).toLowerCase().includes(String(filters?.deliverableName).toLowerCase()))]
    }
  }

  // Filter: Status 
  if (typeof filters?.status === "string") {
    const filtersOfStatus = filters?.status.split(",");
    if (isArrayWithValues(filtersOfStatus)) {
      result = [...result.filter(projectDeliverable => Boolean(projectDeliverable?.status && filtersOfStatus?.includes(projectDeliverable?.status)) )]
    }
  }

  // Filter: Roles 
  if (typeof filters?.roles === "string") {
    const filtersOfRoles = filters?.roles.split(",");
    if (isArrayWithValues(filtersOfRoles)) {
      result = [...result.filter(projectDeliverable => Boolean(filtersOfRoles?.includes(String(projectDeliverable?.deliverable?.role?.id))) )]
    }
  }

  // Filter: Disciplines 
  if (typeof filters?.disciplines === "string") {
    const filtersOfDisciplines = filters?.disciplines.split(",");
    if (isArrayWithValues(filtersOfDisciplines)) {
      result = [...result.filter(projectDeliverable => Boolean(filtersOfDisciplines?.includes(String(projectDeliverable?.deliverable?.discipline?.id))) )]
    }
  }

  // Filter: Phases 
  if (typeof filters?.phases === "string") {
    const filtersOfPhases = filters?.phases.split(",");
    if (isArrayWithValues(filtersOfPhases)) {
      result = [...result.filter(projectDeliverable => Boolean(filtersOfPhases?.includes(String(projectDeliverable?.phase?.id))) )]
    }
  }

  // Filter: Show Only Key Milestone 
  if (filters.showOnlyKeyMilestone) {
    result = [...result.filter(projectDeliverable => Boolean(projectDeliverable?.deliverable?.isKeyMilestone) )]
  }

  // Filter: Hide Not Applicable
  if (filters.hideNotApplicable) {
    result = [...result.filter(projectDeliverable => !Boolean(projectDeliverable?.notApplicableFlag) )]
  }

  return result;
}

export const ProjectDeliverablesSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    filterDeliverablesByBasicFilters: (state, action) => {
      state.basicfilters = {
        ...action.payload
      }
      state.allFilters = { ...state.basicfilters, ...state.advancedFiters };
      if (state.data.deliverablesList) {
        state.data.deliverablesFiltered = searchDeliverableByAllFilters(state.data.deliverablesList, state.allFilters)
      }
      return state;
    },
    filterDeliverablesByAdvancedFilters: (state, action) => {
      state.advancedFiters = {
        ...action.payload
      }
      state.advancedFiltersCount = Object.keys(state.advancedFiters).length;
      state.allFilters = { ...state.basicfilters, ...state.advancedFiters };
      if (state.data.deliverablesList) {
        state.data.deliverablesFiltered = searchDeliverableByAllFilters(state.data.deliverablesList, state.allFilters)
      }
      return state;
    },
    setLastScrollPosition: (state, action) => {
      state.lastScrollPosition = action.payload;
      return state;
    },
    reset: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getProjectDeliverablesData.fulfilled, (state, action) => {
        const data = action.payload;
        if (data) { state.data.deliverablesList = data }
        if (data) { state.data.deliverablesFiltered = searchDeliverableByAllFilters(data, state.allFilters) }
        state.errorMessage = null;
        state.loadingList = false;
      })
      .addCase(updateProjectDeliverable.fulfilled, (state, action) => {
        state.updateSuccess = true;
        state.errorMessage = null;
        state.loadingDetails = false;
      })
      .addCase(requestForApproval.fulfilled, (state, action) => {
        state.updateSuccess = true;
        state.errorMessage = null;
        state.loadingDetails = false;
      })
      .addCase(getProjectDeliverableDetailById.fulfilled, (state, action) => {
        const data = action.payload;
        if (data) { state.data.deliverableSelected = data[0] }
        state.errorMessage = null;
        state.loadingDetails = false;
      })
      .addCase(getProjectDeliverableDetailById.pending, (state, action) => {
        state.errorMessage = null;
        state.loadingDetails = true;
      })
      .addCase(getProjectDeliverableDetailById.rejected, (state, action) => {
        state.errorMessage = action?.error?.message;
        state.loadingDetails = false;
      })
      .addMatcher(isPending(updateProjectDeliverable, requestForApproval), state => {
        state.updateSuccess = false;
      })
      .addMatcher(isRejected(updateProjectDeliverable, requestForApproval), state => {
        state.updateSuccess = false;
      })
      .addMatcher(isPending(getProjectDeliverablesData), state => {
        state.errorMessage = null;
        state.loadingList = true;
      })
      .addMatcher(isRejected(getProjectDeliverablesData), (state, action) => {
        state.errorMessage = action?.error?.message;
        state.loadingList = false;
      })
      .addMatcher(isPending(updateProjectDeliverable, requestForApproval), state => {
        state.errorMessage = null;
        state.loadingDetails = true;
      })
      .addMatcher(isRejected(updateProjectDeliverable, requestForApproval), (state, action) => {
        state.errorMessage = action?.error?.message;
        state.loadingDetails = false;
      });
  }
});

export const { 
  reset,
  filterDeliverablesByBasicFilters,
  filterDeliverablesByAdvancedFilters,
  setLastScrollPosition
} = ProjectDeliverablesSlice.actions;

// Reducer
export default ProjectDeliverablesSlice.reducer;