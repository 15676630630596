export interface IGroup {
  id?: any;
  name?: string;
}

export const defaultValue: Readonly<IGroup> = {
  id: '',
  name: ''
};

export interface IGroupExtended {
  id?: any;
  name?: string;
  userCount?: any;
}