import { ICompanyRole } from '../../models/company-binding.model';
import { IQueryParams } from '../../models/pagination';
import { http } from '../http/axios.instance';
import { StringORNumber } from './utils.repository';

const path = 'company-roles';


export const companyRoleRepository = {
  getAll: async (queryParams: IQueryParams) => {
    
    const { sort, page, size } = queryParams;
    const requestUrl = `${path}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  
    const promiseGetAll = await http.get<ICompanyRole[]>(requestUrl);
    return promiseGetAll;
  },
  getById: async (id: StringORNumber) => {

    const requestUrl = `${path}/${id}`;
    const promiseGetByID = await http.get<ICompanyRole>(requestUrl);
    return promiseGetByID;
  },
  create: async (entity: ICompanyRole): Promise<ICompanyRole> => {

    const requestUrl = `${path}`;
    const promiseCreate = await http.post<ICompanyRole>(requestUrl, entity);
    return promiseCreate.data;
  },
  update: async (id: string | number, entity: ICompanyRole): Promise<ICompanyRole> => {

    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.put<ICompanyRole>(requestUrl, entity);
    return promiseUpdate.data;
  },
  delete: async (id: string | number) => {

    const requestUrl = `${path}/${id}`;
    const promiseDelete = await http.delete<ICompanyRole>(requestUrl);
    return promiseDelete;
  },
}