import React, { useState, useEffect } from 'react';
import { ConfigProvider, Table, theme, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import { AppDispatch, RootState, useAppSelector } from '@store/store';
import { getUpcomingOpenings } from '@store/slices/dashboard';
import { ILocation } from '@models/location.model';
import { useNavigate } from 'react-router-dom';
import { IMarket } from '@models/market.model';
import { IBrand } from '@models/brand.model';
import { OnRowEvent } from '@models/utils/table.utils.model';
import { EmptyCellTable } from '@components/Utils/EmptyCellTable';
import { useThemeProvider } from '@providers/ThemeProvider';
import { removeTimeZone } from '@shared/util/date-utils';
import type { ColumnsType, TableProps } from 'antd/es/table';
import "@styles/components/table.less";
import { dateSorter, defaultSorter } from '@shared/util/table-utils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { getEntities as getBrands } from '@store/slices/brand';


const TitleBrand = () => {
    const { t } = useTranslation();
    return <span>{ `${t("location.brand")}`}</span>
}

const TitleOpenDate = () => {
    const { t } = useTranslation();
    return <span>{ `${t("location.openDate")}`}</span>
}

const columns: ColumnsType<ILocation> = [
  {
    title: 'Location#',
    dataIndex: 'locationNumber',
    key: 'locationNumber',
    className: 'border-left-in-hover',
    ellipsis: true,
    sorter: (a, b) => defaultSorter(a?.locationNumber, b?.locationNumber),
    render: value => {
      return value || <EmptyCellTable />;
    },
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => defaultSorter(a?.name, b?.name),
    render: value => (
      <Tooltip placement="top" title={value}>
        {value || <EmptyCellTable />}
      </Tooltip>
    ),
  },
  {
    title: <TitleBrand />,
    dataIndex: 'brand',
    key: 'brand',
    sorter: (a, b) => defaultSorter(a?.brand?.description, b?.brand?.description),
    render: (value: IBrand) => {
      return value?.description || <EmptyCellTable />;
    },
    ellipsis: true,
  },
  {
    title: 'Market',
    dataIndex: 'market',
    key: 'market',
    sorter: (a, b) => defaultSorter(a?.market?.description, b?.market?.description),
    render: (value: IMarket) => {
      return value?.description || <EmptyCellTable />;
    },
    ellipsis: true,
  },
  {
    title: <TitleOpenDate />,
    dataIndex: 'openDate',
    key: 'openDate',
    ellipsis: true,
    sorter: (a, b) => (a?.openDate && b?.openDate ? dateSorter(a?.openDate, b?.openDate) : 0),
    render: value => {
      return value || <EmptyCellTable />;
    },
  },
  {
    title: 'City, State',
    dataIndex: '',
    key: 'cityState',
    ellipsis: true,
    sorter: (a, b) => {
      const valueA = a.city && a.stateProvince && `${a.city}, ${a.stateProvince}`;
      const valueB = b.city && b.stateProvince && `${b.city}, ${b.stateProvince}`;
      return defaultSorter(valueA, valueB);
    },
    render: (_: ILocation) => {
      const valueFomatted = _.city && _.stateProvince && `${_.city}, ${_.stateProvince}`;
      return valueFomatted ? valueFomatted : <EmptyCellTable />;
    },
  },
];

export const UpcomingOpeningsTable: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { themeConfig } = useThemeProvider();
  const algorithmSelected = themeConfig?.darkMode ? [theme.compactAlgorithm, theme.darkAlgorithm] : theme.compactAlgorithm;
  const {
    data: {
      upcomingOpenings: { list, loading, totalItems },
    },
  } = useSelector((state: RootState) => state.Dashboard);

  const [tableParams, setTableParams] = useState({
    size: 20,
    current: 0,
    sort: `openDate,desc`,
  });

  //autohide columns
  const brands = useAppSelector(state => state.Brand.entities);
  useEffect(() => {
    if (brands.length === 0) {
      dispatch(getBrands({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [filteredColumns, setFilteredColumns] = useState<ColumnsType<ILocation>>([]);

  useEffect(() => {
    const tempColumns = brands.length > 1 ? columns : columns.filter(column => column.key !== 'brand');
    setFilteredColumns(tempColumns);
  }, [brands, filteredColumns]);

  const filterLocations = () => {
    const { current, size, sort } = tableParams;
    dispatch(
      getUpcomingOpenings({
        page: current,
        size: size,
        'openDate.greaterThan': removeTimeZone(dayjs()),
        'openDate.lessThanOrEqual': removeTimeZone(dayjs().add(30, 'days')),
        sort: sort,
      })
    );
  };

  useEffect(() => {
    filterLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableParams]);

  const onChange: TableProps<ILocation>['onChange'] = pagination => {
    setTableParams(oldValue => {
      return {
        ...oldValue,
        ...(pagination?.pageSize && { size: pagination.pageSize }),
        current: pagination.current !== undefined ? pagination.current - 1 : 0,
      };
    });
  };

  const onRowClick = (eventOnClick: OnRowEvent<ILocation>) => {
    const { event, record } = eventOnClick;

    const urlToNavigate = `/location/${record.id}`;
    event?.metaKey ? window.open(`${urlToNavigate}`) : navigate(`${urlToNavigate}`);
  };

  return (
    <div className="overflow-y-auto">
      <div>
        <ConfigProvider theme={{ token: { borderRadius: 0 }, algorithm: algorithmSelected }}>
          <Table
            loading={loading}
            rowKey="id"
            size="small"
            columns={filteredColumns}
            dataSource={list}
            onChange={onChange}
            onRow={(record, rowIndex) => ({ onClick: event => onRowClick({ event, record, rowIndex }) })}
            className={list?.length > 0 ? 'app-table' : ''}
            rowClassName={'table-with-selector'}
            pagination={{ pageSize: tableParams.size, total: totalItems }}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};