/* eslint-disable react-hooks/exhaustive-deps */
import { AttachmentReferenceEnum } from "@models/enumerations/attachment-reference-enum.model";
import { isArrayWithValues } from "@shared/util/array-util";
import { getByReferenceTypeAndReferenceId } from "@store/slices/attachment";
import { useAppDispatch, useAppSelector } from "@store/store";
import { useEffect, useState } from "react";
import { PageGeneratedPreviewItem } from "./PageGeneratedPreviewItem";
import { IDerivatives } from '@models/attachment.model';
import { sortObjectByKey } from "@shared/util/object-utils";
import { Button, Checkbox, Col, Row, theme } from "antd";
import { createPunchListPagesWithPreviewsPagesSelected } from "@store/slices/punch-list";
import { asyncLaunchNotification } from "@store/slices/notification";
import { useNavigate } from "react-router-dom";
import { UsePunchListRouteSegment } from "../UsePunchListRouteSegment";

export interface IPageSelector {
  pageNumber: number;
  isSelected: boolean;
  position: number
}
interface ISelectPagesStepProps {
  punchListGenerationId: string | number | null;
}

export const SelectPagesStep = (props: ISelectPagesStepProps) => {

    const { punchListGenerationId } = props;

    const dispatch = useAppDispatch();

    useEffect(() => {
      if (punchListGenerationId !== null) {
        dispatch(
          getByReferenceTypeAndReferenceId({ 
            referenceType: AttachmentReferenceEnum.PUNCH_LIST_GENERATE,
            referenceId: punchListGenerationId!
          })
        )
      }
    }, [punchListGenerationId]);

    const [derivativesPages, setDerivativesPages] = useState<IDerivatives[][]>();

    const [checkPagesList, setCheckPagesList] = useState<boolean[]>([]);
    
    const { entities: attachmentList } = useAppSelector((state) => state.Attachment);

    const { token: { colorBorder, colorPrimary }} = theme.useToken();
    
    const basePunchListUrlSegment = UsePunchListRouteSegment();

    useEffect(() => {
      processDerivatives();
    }, [attachmentList])

    const processDerivatives = () => {
      let derivativesCollection = {}
      if (attachmentList && isArrayWithValues(attachmentList) && isArrayWithValues(attachmentList[0].derivatives)) {
        attachmentList[0].derivatives?.forEach((derivative: IDerivatives) => { 
          if (derivative?.pageNumber) {
            derivativesCollection[derivative?.pageNumber] = Array.isArray(derivativesCollection[derivative?.pageNumber]) 
              ?  [...derivativesCollection[derivative?.pageNumber], derivative] 
              : [derivative];
          }
        })
        const derivativesSorted = Object.values(sortObjectByKey(sortObjectByKey(derivativesCollection))) as IDerivatives[][];
        derivativesSorted && setDerivativesPages(derivativesSorted);
        derivativesSorted && setCheckPagesList(Array(derivativesSorted.length).fill(false));
        derivativesSorted && setTotalOfPages(derivativesSorted.length);
      }
    }

    const [pagesSelected, setPagesSelected] = useState<number[]>([]);
    const [numberOfPagesSelected, setNumberOfPagesSelected] = useState(0);
    const [totalOfPages, setTotalOfPages] = useState(0);
    const [loadingConfirmFloorPlans, setLoadingConfirmFloorPlans] = useState(false);
    const [selectAllPages, setSelectAllPages] = useState(false);

    const onCLickPageSelection = (pageSelector: IPageSelector) => {
      const newPagesSelected = pagesSelected;
      const pageNumberIndexOfpagesSelected = pagesSelected.indexOf(pageSelector.pageNumber);
      const pageNumberIsInPagesSelected = pageNumberIndexOfpagesSelected > -1;

      if (pageSelector.isSelected && !pageNumberIsInPagesSelected) {
        newPagesSelected.push(pageSelector.pageNumber)
      }
      if (!pageSelector.isSelected && pageNumberIsInPagesSelected) {
          newPagesSelected.splice(pageNumberIndexOfpagesSelected,1);
      }

      setPagesSelected(newPagesSelected);
      setNumberOfPagesSelected(newPagesSelected.length);
      checkPagesList[pageSelector.position] = pageSelector.isSelected
      setCheckPagesList(checkPagesList);

      const isAllPagesSelected = checkPagesList.every(page => (page === true));
      setSelectAllPages(isAllPagesSelected);
    }

    const navigate = useNavigate();

    const confirmFloorPlans = () => {

      if (!punchListGenerationId) return;
        
        setLoadingConfirmFloorPlans(true);

        dispatch(createPunchListPagesWithPreviewsPagesSelected({
          id: punchListGenerationId,
          selectedPages: [...pagesSelected]
        }))
        .unwrap()
        .then(() => {
          // Show Notification
          dispatch(asyncLaunchNotification({
            type: "success",
            config: {
              message: `Punch list Pages`,
              description: `Punch list Pages successfully created`
            }
          }));

          // Navigate to Punch list 
          setTimeout(() => {
            navigate(`${basePunchListUrlSegment}punch-list`);
          }, 600);
        })
        .finally(() => setLoadingConfirmFloorPlans(false));

    }

    const onSelectAll = () => {
      const isSelectedAll = !selectAllPages;

      const newStateCheckPagesList = checkPagesList.map(() => isSelectedAll);
      setCheckPagesList(newStateCheckPagesList);
      setSelectAllPages(isSelectedAll);

      if (isSelectedAll) {
        const newSelection = derivativesPages && [...derivativesPages]?.reduce?.((collection, derivatives, index) => {
          collection.push({
            pageNumber: derivatives[0].pageNumber || 0,
            isSelected: true,
            position: index
          });
          return collection;
        }, [] as IPageSelector[]);

        const newPageNumbersSelected = isArrayWithValues(newSelection) ?  newSelection?.map((page) => page.pageNumber) : [];
        newPageNumbersSelected && setPagesSelected(newPageNumbersSelected);
      } else {
        setPagesSelected([]); 
      }

      isSelectedAll ? setNumberOfPagesSelected(totalOfPages) : setNumberOfPagesSelected(0);
    }

    return (
      <div className="flex flex-col">
        <div className="flex flex-row justify-center items-center mt-20 mb-15">
          <span className="text-color-neutral-7" style={{ fontSize: 16 }}>
            Select pages to use as Punch List’s Floor Plan
          </span>
        </div>
        <div className="flex flex-row justify-center items-center mt-8 mb-8 pr-40 pl-40">
          <Row className="w-full bg-color-neutral-4 pt-16 pb-16 pl-16 pr-16" style={{  borderRadius: 16, border: `1px solid ${colorBorder}` }}>
            <Col flex="none" className="flex flex-row justify-center items-center">
              <Checkbox onChange={onSelectAll} checked={selectAllPages} className="pl-40 pr-40">
                  <span>Select All</span>
              </Checkbox>
            </Col>
            <Col flex="auto" className="flex flex-row justify-center items-center">
              <span style={{ color: colorPrimary }}>
                  { numberOfPagesSelected }
              </span>
              <span className="text-color-neutral-6 ml-6">
                  of { totalOfPages ? totalOfPages : "0" }{ " " }{ totalOfPages > 1 ? 'pages': 'page'}
              </span>
              <span className="text-color-neutral-6 ml-6">
                  selected
              </span>
            </Col>
            <Col flex="none">
                <Button type="primary" className="ml-20 mr-7" onClick={confirmFloorPlans} loading={loadingConfirmFloorPlans} disabled={numberOfPagesSelected < 1}>
                    Confirm Floor Plans
                </Button>
            </Col>
          </Row>
        </div>
        <div
          className="container grid pr-40 pl-40 pt-16 pb-30 w-full" 
          style={{ 
              gridTemplateColumns: "repeat(4, 1fr)", gridGap: '1rem', gridAutoFlow: 'row' 
          }}
        >
          {
            derivativesPages && isArrayWithValues(derivativesPages) && derivativesPages?.map((derivatives, index) => {
              return (
                  <div 
                    key={index} 
                    style={{
                      width: "100%" 
                    }}>
                      <PageGeneratedPreviewItem 
                        derivatives={derivatives} 
                        loading={index > 10 ? "lazy" : "eager"}
                        onCLickPageSelection={onCLickPageSelection}
                        isPageChecked={checkPagesList[index]}
                        position={index}
                      />
                  </div>
              )
            })
          }
        </div>
      </div>
    )
}
