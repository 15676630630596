import * as d3 from "d3";
import { Utils } from "./utils";

export class Camera {
  static goTo = (
    x: number,
    y: number,
    instanceId: string,
    pixelsPerMeter: number,
    zoomBehavior: any,
    zoomLevel: number = 2,
    duration = 1000
  ) => {
    const svg = d3.select("#" + instanceId);

    const svgHeight = svg.node().getBoundingClientRect().height;
    const svgWidth = svg.node().getBoundingClientRect().width;

    const targetXPixels = x * pixelsPerMeter;
    const targetYPixels = y * pixelsPerMeter;

    // Centering the desired point
    const translateX = svgWidth / 2 - targetXPixels * zoomLevel;
    const translateY = svgHeight / 2 - targetYPixels * zoomLevel;

    const newTransform = d3.zoomIdentity
      .translate(translateX, translateY)
      .scale(zoomLevel);
      
    console.log("🚀 ~ file: camera.ts:29 ~ Camera ~ newTransform:", newTransform)

    svg
      .transition()
      .duration(duration)
      .call(zoomBehavior.transform, newTransform);
  };

  static zoomToFit = (
    duration: number,
    instanceId: string,
    pixelsPerMeter: number,
    officeWidth: number,
    officeHeight: number,
    zoomBehavior: any
  ) => {
    const svg = d3.select("#" + instanceId);

    const svgWidth = svg?.node()?.getBoundingClientRect()?.width;
    const svgHeight = svg?.node()?.getBoundingClientRect()?.height;

    const officeWidthPixels = officeWidth * pixelsPerMeter;
    const officeHeightPixels = officeHeight * pixelsPerMeter;

    const xCenter = officeWidthPixels / pixelsPerMeter / 2;
    const yCenter = officeHeightPixels / pixelsPerMeter / 2;

    let zoomLevel = Utils.clamp(
      Math.min(svgWidth / officeWidthPixels, svgHeight / officeHeightPixels),
      0.1,
      2
    );

    this.goTo(
      xCenter,
      yCenter,
      instanceId,
      pixelsPerMeter,
      zoomBehavior,
      zoomLevel,
      duration
    );
  };
}
