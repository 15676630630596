import { IBrand } from "./brand.model";
import { IMarket } from "./market.model";
import { IAddress } from "./address.model";
import { ICompanyItem } from "./company.model";
import { ISite } from "./site.model";
import { IAttachment } from "./attachment.model";
import { StringORNumber } from "@infrastructure/repositories/utils.repository";

export interface ILocation {
    id?: StringORNumber;
    name?: string | null;
    city?: string | null;
    stateProvince?: string | null;
    openDate?: string | null;
    closeDate?: string | null;
    locationNumber?: string | null;
    brand?: IBrand | null;
    market?: IMarket | null;
    address?: IAddress;
    addressList?: IAddress[];
    companyBindingList?: ICompanyItem[];
    siteList?: ISite[];
    locationKeyPhoto?: IAttachment
    locationStatus?: string | null;
    
}

export interface ILocationUI extends ILocation {
  locationKeyPhotoSrc?: string;
}
  
export const defaultValue: Readonly<ILocation> = {
  openDate: null,
  closeDate: null
};