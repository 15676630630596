import useVisible from "@HOOKs/UseVisible";
import { UploadKeyPhoto } from "@components/Locations/Menu/UploadKeyPhoto";
import { CustomButtonText } from "@components/Utils/CustomButtonText";
import { useAppSelector } from "@store/store";
import { useTranslation } from "react-i18next";

interface IUploadKeyPhotoButtonProps {}

export const UploadKeyPhotoButton = (props: IUploadKeyPhotoButtonProps) => {

    const { entity: locationEntity } = useAppSelector((state) => state.Location);
    const [ openFileUploadModal, toogleFileUploadModal ] = useVisible();
    const { t } = useTranslation();

    const onOpenModalUpload = () => {
        toogleFileUploadModal();
    }

    return (
        <>
            <CustomButtonText onClick={onOpenModalUpload} className="w-full">
                {t("location.uploadKeyPhoto")}
            </CustomButtonText>
            <UploadKeyPhoto location={locationEntity} openModal={openFileUploadModal} toogleModal={toogleFileUploadModal} />
        </>
    )
}
