/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useErrorAnimation } from "@HOOKs/UseErrorAnimation";
import { ICompanyRole } from "@models/company-binding.model";
import { isFulfilled } from "@reduxjs/toolkit";
import { asyncLaunchNotification } from "@store/slices/notification";
import { createEntity, updateEntity } from "@store/slices/company-roles";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Button, Col, Drawer, Form, Input, Row, Space } from "antd";
interface ICompanyRoleEditProps {
  open: boolean,
  toogle: () => void,
  onModifiedChanges?: (role: ICompanyRole) => void,
  companyRole: ICompanyRole
}

export const CompanyRoleEdit = (props: ICompanyRoleEditProps) => {

    const { open, toogle, companyRole, onModifiedChanges } = props;

    const dispatch = useAppDispatch();

    const [form] = Form.useForm();

    const { loading, updating } = useAppSelector((state) => state.CompanyRoles);

    const [animateError, playAnimationOfError] = useErrorAnimation();

    const onFinishFailed = (errorInfo: any) => playAnimationOfError();

    const onFinish = (entity: any): void => {
        const hasRole = companyRole?.id;
        if (hasRole) {
            dispatch(
                updateEntity({
                    ...companyRole,
                    ...entity
                })  
            ).then(onSuccessUpdatedStatus);
            
        } else {
            dispatch(createEntity({
                id: "",
                ...entity
            })).then(onSuccessCreateStatus);
        }
    };

    const onSuccessUpdatedStatus = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: `Changes saved`,
                  description: `Your changes have been saved successfully`
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onSuccessCreateStatus = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: `Changes saved`,
                  description: `Status created successfully`
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onClose = () => { 
        form.resetFields();
        toogle(); 
    }

    const onSubmit = () => form.submit();

    useEffect(() => { setRoleFormFields() }, [companyRole])

    const setRoleFormFields = () => {
        form.setFieldsValue({
            ...companyRole
        });
    }

    return (
        <>
            <Drawer 
                width={448} 
                title={`${companyRole?.id ? "Edit": "Create new"} company role`}
                placement="right" 
                onClose={onClose} 
                open={open}
                closable={false}
                extra={
                    <Space>
                      <Button type="primary" ghost onClick={onClose}>
                        Cancel
                      </Button>
                      <Button loading={loading} onClick={onSubmit} type="primary" disabled={updating}>
                        {`${companyRole?.id ? "Save": "Create"}`}
                      </Button>
                    </Space>
                  }
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className={`${animateError && "error-animation"}`}
                >

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="description"
                                label="Company Role Name"
                                rules={[
                                    { required: true, message: "Company Role Name field is Required" },
                                ]}
                            >
                                <Input placeholder="Company Role Name" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Drawer>
        </>
  );
}
