import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ICostCategory } from "@models/cost-category.model";
import { ICostSubCategory } from "@models/cost-sub-category.model";
import { Button, Col, Form, Input, Row, Select, theme } from "antd";
import { useRef } from "react";
import { useTranslation } from 'react-i18next';

const { Option } = Select;

export interface ICostItemsFormListProps {
    name: string,
    costCategories: ICostCategory[],
    subCategoriesByIndex: Map<number, ICostSubCategory[]> | undefined,
    addButtonText: string,
    onCostCategoryChange: (index: number, categoryId: string) => void,
    onCostChange: () => void,
    onItemAdded: () => void,
    onItemRemoved: (index: number) => void
}

export const CostItemsFormList = (props: ICostItemsFormListProps) => {

    const { name, addButtonText, costCategories, subCategoriesByIndex, onCostCategoryChange, onCostChange, onItemAdded, onItemRemoved } = props;
    const { token: { colorError } } = theme.useToken();
    const { t } = useTranslation();
    const bottomEl = useRef<null | HTMLDivElement>(null);

    const scrollToBottom = () => {
        setTimeout(
            () => bottomEl?.current?.scrollIntoView({ behavior: 'smooth' })
            , 50);
    };

    return (
        <Form.List name={name}>
            {(fields, { add, remove }) => (
                <>
                    {fields.map(({ key, name, ...restField }) => (
                        <Row key={key} className='ml-25 pl-15'>
                            <Col span={5} style={{ padding: "5px 15px 5px 0px" }}>
                                <Form.Item {...restField} className="mb-5" name={[name, "costCategory"]} rules={[{ required: true, message: t("changeOrder.costsDetail.csiDivisionRequired") }]}>
                                    <Select
                                        placeholder={t("changeOrder.costsDetail.csiDivisionPlaceholder")}
                                        onChange={(value) => onCostCategoryChange(key, value)}  >
                                        {costCategories?.map((category: ICostCategory) => (
                                            <Option value={category.id} key={category.id}>
                                                {category.code} - {category.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={5} style={{ padding: "5px 15px 5px 0px" }}>
                                <Form.Item {...restField} className="mb-5" name={[name, "costSubCategory"]} rules={[{ required: true, message: t("changeOrder.costsDetail.csiSubivisionRequired") }]}>
                                    <Select
                                        placeholder={t("changeOrder.costsDetail.csiSubivisionPlaceholder")}
                                        disabled={subCategoriesByIndex?.get(key) === undefined}
                                    >
                                        {subCategoriesByIndex?.get(key)?.map((category: ICostCategory) => (
                                            <Option value={category.id} key={category.id}>
                                                {category.code} - {category.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={7} style={{ padding: "5px 15px 5px 0px" }}>
                                <Form.Item {...restField} className="mb-5" name={[name, "description"]} rules={[{ required: true, message: t("changeOrder.costsDetail.descriptionRequired") }]}>
                                    <Input placeholder={t("changeOrder.costsDetail.descriptionPlaceholder")} />
                                </Form.Item>
                            </Col>
                            <Col span={3} style={{ padding: "5px 15px 5px 0px" }}>
                                <Form.Item {...restField} className="mb-5" name={[name, "unitsQty"]}
                                    rules={[{ required: true, message: t("changeOrder.costsDetail.unitsQtyRequired") },
                                    { pattern: new RegExp(/^([0-9]|-){1}[0-9]*$/i), message: t("changeOrder.costsDetail.unitsQtyCustomValidationMessage") }]}>
                                    <Input type="number" onChange={onCostChange} />
                                </Form.Item>
                            </Col>
                            <Col span={3} style={{ padding: "5px 15px 5px 0px" }}>
                                <Form.Item {...restField} className="mb-5" name={[name, "unitCost"]} rules={[{ required: true, message: t("changeOrder.costsDetail.unitCostRequired") }]}>
                                    <Input type="number" onChange={onCostChange} />
                                </Form.Item>
                            </Col>
                            <Col span={1} style={{ padding: "5px 15px 5px 0px" }}>
                                <DeleteOutlined style={{ height: 32, color: colorError }} onClick={() => { remove(name); onItemRemoved(key); onCostChange(); }} />
                                <Form.Item {...restField} name={[name, "id"]} style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    ))}
                    <Form.Item className="mb-0">
                        <div ref={bottomEl}>
                            <Button className="ml-20" type="link" onClick={() => { add(); scrollToBottom(); onItemAdded(); }} icon={<PlusOutlined />} >
                                {addButtonText}
                            </Button>
                        </div>
                    </Form.Item>
                </>
            )}
        </Form.List>
    );
}