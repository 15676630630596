/* eslint-disable react-hooks/exhaustive-deps */
import { red } from "@ant-design/colors";
import { CloseCircleTwoTone } from "@ant-design/icons";
import { Col, Row, theme, Button } from "antd";
import { useNavigate } from "react-router-dom";

export const ErrorTokenMessage = () => {
    
    const { token: { fontSizeXL } } = theme.useToken();

    const navigate = useNavigate();

    const goToLogin = () => {
        navigate("/login")
    }

    return (
        <>
            <Row justify={"center"} >
                <Col span={24} className="flex justify-center pt-40 pb-5" > 
                    <CloseCircleTwoTone style={{ fontSize: 30 }} twoToneColor={[red[5],red[0]]} />
                </Col>
            </Row>

            <Row justify={"center"} >
                <Col span={24} className="flex justify-center pt-8" > 
                    <span className="text-color-neutral-8 font-medium" style={{ fontSize: fontSizeXL }}>Invalid token</span>
                </Col>
            </Row>

            <Row justify={"center"} >
                <Col span={24} className="flex justify-center pt-8 pb-15" > 
                    <p className="flex justify-center text-center text-color-neutral-7 font-normal">
                        Invalid or already used token. Please make sure the link is correct.
                    </p>
                </Col>
            </Row>

            <Button type="primary" htmlType="submit" className="w-full" onClick={goToLogin}>
                Go to Login
            </Button>
        </>
    )
}