
import { http } from '../http/axios.instance';
import { IAddressType } from '@models/address-type.model';

const path = 'address-types';

export const addressTypesRepository = {
    getAddressTypes: async () => {
        const requestUrl = `${path}`;

        const promiseGetAll = await http.get<IAddressType[]>(requestUrl);
        return promiseGetAll;
    },
    createAddressType: async (entity: IAddressType): Promise<IAddressType> => {

        const requestUrl = `${path}`;
        const response = await http.post<IAddressType>(requestUrl, entity);
        return response.data;
    },
    updateAddressType: async (id: string | number, entity: IAddressType): Promise<IAddressType> => {

        const requestUrl = `${path}/${id}`;
        const response = await http.put<IAddressType>(requestUrl, entity);
        return response.data;
    },
}