import { IBudgetExtended, IBudgetUI } from '@models/budget.model';
import { ICostCategoryExtended, ICostCategoryUI } from '@models/cost-category.model';
import { ICostGroupingExtended, ICostGroupingUI } from '@models/cost-grouping.model';
import { ICostSubCategoryExtended, ICostSubCategoryUI } from '@models/cost-sub-category.model';
import { normalizeNumberString } from '@shared/util/number-util';

export class BudgetMapper {
  public static toUI(budget: IBudgetExtended): IBudgetUI {
    const budgetUI: IBudgetUI = {
      ...budget,
      groups: budget.groups?.map(this.groupingToGroupingUI.bind(this)) || [],
    };
    // Calculate the totals locally, we leave this here in case it's needed in the future
    // budgetUI.groups?.forEach(group => {
    //   group.children?.forEach(category => {
    //     category.cost = this.sumCosts(category.children)
    //   })
    //   group.cost = this.sumCosts(group.children)
    // });
    return budgetUI;
  }

  private static groupingToGroupingUI(grouping: ICostGroupingExtended): ICostGroupingUI {
    return {
      ...grouping,
      key: `costGrouping-${grouping.id}`,
      cost: normalizeNumberString(grouping.totalCostCategories || '0.00'),
      costPerSF: normalizeNumberString(grouping.totalCostPerSFCategories || '0.00'),
      children: grouping.categories?.map(this.categoryToCategoryUI.bind(this)) || [],
    };
  }

  private static categoryToCategoryUI(category: ICostCategoryExtended): ICostCategoryUI {
    return {
      ...category,
      key: `costCategory-${category.id}`,
      cost: normalizeNumberString(category.totalCostSubCategories || '0.00'),
      costPerSF: normalizeNumberString(category.totalCostPerSFSubCategories || '0.00'),
      children: category.subCategories?.map(this.subCategoryToSubCategoryUI.bind(this)) || [],
    };
  }

  private static subCategoryToSubCategoryUI(subCategory: ICostSubCategoryExtended): ICostSubCategoryUI {
    return {
      ...subCategory,
      key: `costSubCategory-${subCategory.id}`,
      lineItemId: subCategory.budgetLineItem?.id,
      cost: normalizeNumberString(subCategory.budgetLineItem?.cost || '0.00'),
      costPerSF: normalizeNumberString(subCategory.budgetLineItem?.costPerSF || '0.00'),
    };
  }

  private static sumCosts(children: any[] | undefined): string {
    if (!children) return '0.00';
    const sum = children.reduce<number>((acc, current) => {
      return acc + Number(current.cost);
    }, 0);
    return sum.toFixed(2);
  }
}
