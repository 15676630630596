import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getEntities } from "@store/slices/change-order-reasons";
import { Card, Switch, Tag, theme } from "antd";
import useVisible from "@HOOKs/UseVisible";
import { EditOutlined } from "@ant-design/icons";
import { NEUTRAL_7_COLOR } from "@providers/ThemeProvider";
import "@styles/components/admin-item-card.less";
import { ChangeOrderReasonEdit } from "./ChangeOrderReasonEdit";
import { IChangeOrderReason } from "@models/change-order-reason.model";
import { useTranslation } from "react-i18next";

const ListItem = (changeOrderReason: IChangeOrderReason) => {
  const { t } = useTranslation();
  return <li style={{ color: NEUTRAL_7_COLOR }} className="pb-4">{changeOrderReason.name} {changeOrderReason.disabled && <Tag>{t("generic.disabled")}</Tag>}</li>
}

interface IListItemEditableProps {
  changeOrderReason: IChangeOrderReason;
  changeOrderReasonSelected: IChangeOrderReason;
  editChangeOrderReasonClicked: any;
}

const ListItemEditable = (props: IListItemEditableProps) => {

  const { changeOrderReason, changeOrderReasonSelected, editChangeOrderReasonClicked } = props;
  const { t } = useTranslation();

  return <div className={`list-item-editable ${(changeOrderReasonSelected?.id === changeOrderReason.id ? 'selected' : '')}`} onClick={() => editChangeOrderReasonClicked(changeOrderReason)}>
    <div>
      <div className="handle-container">
        <div className="handle-dot"></div>
      </div>
    </div>
    <div className="description">
      {changeOrderReason.name} {changeOrderReason.disabled && <Tag>{t("generic.disabled")}</Tag>}
    </div>
    <span className="edit-container">
      <span className="edit-link">
        <EditOutlined /> {t("generic.edit")}
      </span>
    </span>
  </div>
}

export const ChangeOrderReasonCard = (props: any) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { token: { colorPrimary, colorTextDisabled } } = theme.useToken();

  const loadChangeOrderReasons = () => {
    dispatch(
      getEntities()
    );
  }

  useEffect(() => {
    loadChangeOrderReasons();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const { allEntities: changeOrderReasons } = useAppSelector((state) => state.ChangeOrderReason);

  const changeOrderReasonsSorted = useMemo(() => {
    const copied = JSON.parse(JSON.stringify(changeOrderReasons))
    return copied.sort((a: IChangeOrderReason, b: IChangeOrderReason) => {
      const nameA = a?.name || ''; 
      const nameB = b?.name || '';
    
      return String(nameA).localeCompare(String(nameB));
    });
  }, [changeOrderReasons])

  const [openEditChangeOrderReason, toogleEditChangeOrderReason] = useVisible();
  const [changeOrderReasonSelected, setChangeOrderReasonSelected] = useState<IChangeOrderReason>({})
  const [isEditing, setIsEditing] = useState<boolean>(false)

  useEffect(() => {
    if (!openEditChangeOrderReason) {
      setChangeOrderReasonSelected({})  
    }
  }, [openEditChangeOrderReason]);

  const onModifiedChanges = (changeOrderReason: IChangeOrderReason) => {
    loadChangeOrderReasons();
  }

  const toggleEdit = () => {
    setIsEditing(!isEditing)
  };

  const editChangeOrderReason = (changeOrderReason : IChangeOrderReason) => {
    setChangeOrderReasonSelected(changeOrderReason)
    toogleEditChangeOrderReason()
  }

  const createNewChangeOrderReason = () => {
    setChangeOrderReasonSelected({})
    toogleEditChangeOrderReason();
  }
  
  return (
    <>
      <ChangeOrderReasonEdit open={openEditChangeOrderReason} toogle={toogleEditChangeOrderReason} changeOrderReason={changeOrderReasonSelected} onModifiedChanges={onModifiedChanges} />
      <Card title={<>{t("admin.changeOrderReason")}<span className="item-count">{changeOrderReasons?.length}</span></>} bordered={false} className="admin-item-card" extra={<div><span className="extra-label" style={{ color: (isEditing ? colorPrimary : colorTextDisabled) }} onClick={toggleEdit}>{isEditing ? t("admin.managing") : t("admin.manage")}</span><Switch checked={isEditing} onClick={toggleEdit} /></div>}>
        <ul className={isEditing ? 'editing' : ''}>
            {changeOrderReasonsSorted.map(changeOrderReason => 
            <div
                key={changeOrderReason.id}>
                <>
                {isEditing && <ListItemEditable changeOrderReason={changeOrderReason} changeOrderReasonSelected={changeOrderReasonSelected} editChangeOrderReasonClicked={editChangeOrderReason} />}
                {!isEditing && <ListItem {...changeOrderReason} />}
                </>
            </div>
            )}
        </ul>
        {isEditing && <>
            <div className="spacer"></div>
            <span onClick={createNewChangeOrderReason} className="add-new-btn">+ {t("admin.addNewChangeOrderReason")}</span>
        </>}
        </Card>
    </>
  )
}