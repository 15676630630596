
import { http } from '../http/axios.instance';
import { STORAGE_USER_KEY } from '../../constants';

export const UserRepository = {
  getuser: async () => {
    const pathAccountUrl = "account";
    const user = http.get(pathAccountUrl).then(({ data }) => {
      localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(data));
      return data;
    })
    return user;
  }
}
