/* eslint-disable react-hooks/exhaustive-deps */
import { UIEvent, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Col, ConfigProvider, Modal, Row, Typography, theme } from "antd";
import { MODAL_THEME_SECONDARY, WHITE_ABSOLUTE } from "@providers/ThemeProvider";
import { useResizeDetector } from "react-resize-detector";
import { COLOR_TEXT_BASE } from "@providers/ThemeProvider";
import { FullScreenSvgIcon } from "@components/Icons/FullScreenSvgIcon";
import { If } from "@components/Utils/Structural";
import { CloseOutlined, FileOutlined, FilePdfOutlined, ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { EmptyLabel } from "@components/Utils/EmptyLabel";
import { isArrayWithValues } from "@shared/util/array-util";
import { getExtensionOfFileName, isAnValidImage } from "@shared/util/document-utils";
import { attachmentService } from "@services/attachment.service";
import { DerivativesEnum } from "@models/enumerations/derivatives-enum.model";
import { useContainerFileContex } from "../FilePreview/ContainerFileProvider";
import { ImageContainerBox } from "../FilePreview/ImageContainerBox";
import { IAttachment, IDerivatives } from "@models/attachment.model";
import { useThemeProvider } from "@providers/ThemeProvider";
import { BodyInfoSide, HeaderInfoSide, InfoSide } from './InfoSide';
import { useEffect } from 'react';
import { isNumber } from '@shared/util/number-util';
import { CommentsPanel } from "./CommentsPanel";
import { PreviewNotAvailable } from "./PreviewNotAvailable";
import { useUrlParamsUtils } from "@HOOKs/UseParamsUtils";
import { URL_PARAM_ATTACHMENT_SELECTED_KEY } from "@constants/photoAlbum";
import { FileNameTitle } from "./FilePreviewModal/FileNameTitle";
import { CloseButtonModal } from "./FilePreviewModal/CloseButtonModal";
import { useMobileNavigation } from "@components/PhotoAlbum/PhotoTabLayout";
import { PaginatorInfo } from "./FilePreviewModal/LeftPanel/PaginatorInfo";
import { ToolBarMobile } from "./FilePreviewModal/Toolbar/ToolBarMobile";
import { DownloadButton } from "./FilePreviewModal/Toolbar/DownloadButton";
import { DeleteButton } from "./FilePreviewModal/Toolbar/DeleteButton";
import { CommentButton } from "./FilePreviewModal/Toolbar/CommentButton";
import { InfoButon } from "./FilePreviewModal/Toolbar/InfoButon";
import useIntercom from "@HOOKs/UseIntercom";

interface IFilePreviewModalProps {
    open: boolean
    toggle: () => void,
    filePaginator: React.ReactNode,
    footer?: React.ReactNode,
    fileData: IAttachment,
    onSuccessDeleteAttachment?: () => void,
}

export const BACKGROUND_FULLSCREEN = COLOR_TEXT_BASE;
export const LIGHT_BACKGROUND = "#434343";
export const DARK_BACKGROUND = "#525252";
export const COLOR_BORDER_INSIDE = "#8C8C8C";
export const COLOR_ICON_HEADER_BAR_FULL_SCREEN = "#BFBFBF";
export const COLOR_TEXT_HEADER_BAR_FULL_SCREEN = "#D9D9D9";
export const ZINDEX_MODAL = 2;
export const ZINDEX_LAYER_OVER_MODAL = 3;

export enum PANELS {
    INFO = "INFO",
    COMMENTS = "COMMENTS",
}

export enum StrategyLoadAttachments {
    ON_DEMAND = "ON_DEMAND",
    CURRENT_ATTACHMENTS = "CURRENT_ATTACHMENTS"
}

export const FilePreviewModal = (props: IFilePreviewModalProps) => {

    const { 
        open, 
        toggle,
        fileData,
        filePaginator,
        footer,
        onSuccessDeleteAttachment
    } = props;

    const [documentMode, setDocumentMode] = useState(false);
    const [indexImageSelected, setIndexImageSelected] = useState(0);
    const [urlImageActive, setUrlImageActive] = useState("");
    
    const { height: heightContainerToolbar = 0, ref: containerToolBarRef } = useResizeDetector({ refreshMode: 'debounce', refreshRate: 50 });
    const { width: widthContainerDocument = 0, height: heightContainerDocument = 0, ref: containerDocumentRef } = useResizeDetector({ refreshMode: 'debounce', refreshRate: 50 });
    const { height: heightContainerFooter = 0, ref: footerContainerRef } = useResizeDetector({ refreshMode: 'debounce', refreshRate: 50 });
    
    const [styleContainerFullScreen, setStyleContainerFullScreen] = useState<React.CSSProperties>({});
    const [headerBarHeight, setHeaderBarHeight] = useState(0);
    const headerBarFullScreenRef = useRef<HTMLDivElement | null>(null);
    const divFullScreenRef = useRef<HTMLDivElement | null>(null);
    const sidePanelListImages = useRef<HTMLDivElement | null>(null);
    const { scaleInString, zoomIn, zoomOut, resetZoom } = useContainerFileContex();
    const [isFullScreenMode, setIsFullScreenMode] = useState(false);

    const [derivatives, setDerivatives] = useState<IDerivatives[] | []>([]);
    const [hasPreview, setHasPreview] = useState<boolean>(false);

    useEffect(() => {
        const { derivatives = [] } = fileData;
        const hasDerivatives = isArrayWithValues(fileData?.derivatives);
        setHasPreview(hasDerivatives);
        setDerivatives(derivatives);
        resetZoom();
        const isAnImageValid = fileData.fileName && isAnValidImage(`${getExtensionOfFileName(fileData.fileName)}`.toLocaleLowerCase());
        if (isAnImageValid) {
            setIndexImageSelected(0);
            const linkUrl = (hasDerivatives && fileData.derivatives![0]?.signedUrl) || "";
            linkUrl ? setUrlImageActive(linkUrl): setImageUrl();
        } 

        const isDocument = hasDerivatives && fileData.derivatives?.some(({ pageNumber }) => isNumber(pageNumber) && pageNumber! > 1)
        setDocumentMode(Boolean(isDocument));
        
        if (!isAnImageValid && hasDerivatives) {
            setIndexImageSelected(0);
            fileData.derivatives && derivatives[0]?.signedUrl && setUrlImageActive(derivatives[0].signedUrl); 
        }
    }, [fileData])

    const setImageUrl = () => {
        attachmentService.getViewLink(String(fileData.id)).then((response) => {
          if (response.data) {
            setUrlImageActive(response.data)
          }
        });
    }

    const { bootIntercom, shutdownIntercom } = useIntercom();

    useEffect(() => {
        showIntercom();
    }, [open]); 

    const showIntercom = () => {
        if (open) { shutdownIntercom(); }
    }

    const hideIntercom = () => {
        if (isMobileNavigation) { bootIntercom(); }
    }

    const { deleteUrlParam } = useUrlParamsUtils();

    const onCloseModalPreview = () => {
        toggle();
        setDocumentMode(false);
        setTimeout(() => {
            setUrlImageActive("");
        }, 500);
        deleteUrlParam(URL_PARAM_ATTACHMENT_SELECTED_KEY);
        hideIntercom();
    }
    const selectImage = (url: string, index: number) => {
        setUrlImageActive(url);
        setIndexImageSelected(index);
    }

    const fullscreen = () => {
        resetZoom();
        if (!isFullScreenMode) {
            setIsFullScreenMode(true);
            setStyleContainerFullScreen({ 
                background: BACKGROUND_FULLSCREEN,
                height: '100vh', 
                width: '100vw', 
                position: "fixed",
                zIndex: ZINDEX_MODAL, 
                top: 0, 
                right: 0,
                left: 0, 
                bottom: 0
            });
        } else {
            setIsFullScreenMode(false);
            setStyleContainerFullScreen({})
        }
    }

    const [isFixed, setIsFixed] = useState(false);

    const handleScroll = (event: UIEvent<HTMLElement>) => {
        if (event.target && event?.currentTarget?.scrollTop && event?.currentTarget?.scrollTop > 25 ) {
            setIsFixed(true);
        } else {
            setIsFixed(false);
        }
    };

    useLayoutEffect(() => {
        setHeaderBarHeight(headerBarFullScreenRef?.current?.clientHeight || 0);
    }, [isFullScreenMode])

    const [heightContainerBox, setHeightContainerBox] = useState(0);

    useEffect(() => {
        setHeightContainerBox(heightContainerDocument - heightContainerToolbar - heightContainerFooter )
    }, [heightContainerDocument, heightContainerToolbar, heightContainerFooter]);

    const onSuccessDelete = () => {
        onSuccessDeleteAttachment?.();
    };

    const [shouldShowRightPanel, setShouldShowRightPanel] = useState(false);
    const [panelActive, setPanelActive] = useState<keyof typeof PANELS | undefined >();


    const { token: {fontSize, colorPrimaryHover, colorBgBase, colorBorderSecondary } } = theme.useToken();
    const { themeConfig } = useThemeProvider();

    const activeInfoPanel = () => {
        // Close if the panel is already open 
        if (panelActive === PANELS.INFO) {
            setShouldShowRightPanel(false);
            setPanelActive(undefined);
        } else {
            // Open if the panel is closed
            setPanelActive(PANELS.INFO);
            setShouldShowRightPanel(true);
        }
    }

    const activeCommentPanel = () => {
        // Close if the panel is already open 
        if (panelActive === PANELS.COMMENTS) {
            setShouldShowRightPanel(false);
            setPanelActive(undefined);
        } else {
            // Open if the panel is closed
            setPanelActive(PANELS.COMMENTS);
            setShouldShowRightPanel(true);
        }
    }

    const centerPanelSpanWidth = useMemo(() => {
        if (isFullScreenMode) {
            return documentMode ? (shouldShowRightPanel ? 21 : 24) : 24;
        } else {
            return documentMode ? (shouldShowRightPanel ? 14 : 20) : shouldShowRightPanel ? 18 : 24;
        }
    }, [isFullScreenMode, documentMode, shouldShowRightPanel]);

    const [isMobileNavigation] = useMobileNavigation();

    const widthModal = isMobileNavigation ? window.innerWidth : "75%";
    const heightModal = isMobileNavigation ? window.innerHeight : "80vh";
    const borderRadius = isMobileNavigation ? 0 : 8;

    return (
        <>
            <ConfigProvider
                    theme={{
                        components: {
                            Modal: {
                                ...MODAL_THEME_SECONDARY,
                                borderRadius: borderRadius,
                                borderRadiusLG: borderRadius,
                                borderRadiusSM: borderRadius,
                                borderRadiusXS: borderRadius,
                            },
                        },
                    }}
            >
                <Modal 
                    open={open} 
                    closable={false}
                    forceRender={true} 
                    destroyOnClose={true} 
                    centered={isMobileNavigation ? false: true} 
                    footer={<></>}
                    maskClosable={true}
                    mask={isMobileNavigation ? false : true}
                    width={widthModal}
                    onCancel={onCloseModalPreview}
                    keyboard={false}
                    styles={{ 
                        body: { outline: `1px solid ${isMobileNavigation ? "transparent" : colorBorderSecondary }`, borderRadius: borderRadius, overflow: "hidden", height: heightModal, width: isMobileNavigation ? window.innerWidth: '100%' },
                        content: isMobileNavigation ? { position: "fixed", top: 0, left: 0, right: 0, bottom: 0 } : {}
                    }} >
                        <div ref={containerDocumentRef} className="w-full h-full" style={{ borderRadius: borderRadius, height: heightModal, width: isMobileNavigation ? window.innerWidth: '100%' }} >

                            {/* ToolBar */}
                            <div ref={containerToolBarRef}>
                                <If condition={isMobileNavigation}>
                                    <ToolBarMobile attachment={fileData} onCloseModalPreview={onCloseModalPreview} />
                                </If>
                                <If condition={!isMobileNavigation}>
                                    <div>
                                        <div className="flex flex-row items-center justify-between pt-16 pb-16 pl-16 pr-16" style={{ background: colorBgBase, borderBottom: `1px solid ${colorBorderSecondary}` }}>
                                            <div className="flex flex-row items-center">
                                                {/* Panel Collapsible */}
                                                {/* // TODO - Implement the collapsible panel */}
                                                {/* <div 
                                                    onClick={() => setDocumentMode((state) => !state)}
                                                    className="flex flex-row items-center ml-5 pt-4 pb-4 pr-4 pl-4 mr-8"
                                                    style={{
                                                        borderRadius: 4, 
                                                        backgroundColor: colorPrimaryBg,
                                                        border: `1px solid ${colorPrimaryBgHover}` 
                                                    }}>
                                                    <ChevronLeftSvgIcon style={{ color: colorPrimaryBorder }} />
                                                    <div className="flex flex-row items-center ml-5 mr-4">
                                                        <PanelLeftSvgIcon style={{ color: colorPrimaryBorder }} />
                                                    </div>
                                                </div> */}
                                                <FileNameTitle fileData={fileData} />
                                            </div>
                                            
                                            <div className="flex flex-row items-center justify-end">
                                                <div className="flex flex-row items-center pr-20">
                                                    {filePaginator}
                                                </div>
                                                <div className="flex flex-row items-center">
                                                    <div className="ml-5 mr-16 ">
                                                        <InfoButon handleOnClick={activeInfoPanel} isActive={panelActive === PANELS.INFO} />
                                                    </div>
                                                    <div className="ml-5 mr-16 ">
                                                        <CommentButton handleOnClick={activeCommentPanel} isActive={panelActive === PANELS.COMMENTS} />
                                                    </div>
                                                    <div className="ml-5 mr-16">
                                                        <DownloadButton attachment={fileData} />
                                                    </div>
                                                    <div className="ml-5 mr-30">
                                                        <DeleteButton attachment={fileData} onSuccessDeleteAttachment={onSuccessDelete} onFinishProcessDelete={toggle} />
                                                    </div>
                                                </div>
                                                <CloseButtonModal onCloseModalPreview={onCloseModalPreview} />
                                            </div>
                                        </div>                      
                                    </div>
                                </If>
                            </div>

                            
                            {/* File Preview */}
                            <div ref={divFullScreenRef} style={styleContainerFullScreen} className="h-full">  

                                <If condition={(isFullScreenMode)}>    
                                    <Row ref={headerBarFullScreenRef} className="flex flex-row items-center justify-between" style={{ backgroundColor: COLOR_TEXT_BASE, maxWidth: "100vw"}}>
                                        <div className="flex flex-row items-center">
                                            <div className="pt-15 pb-15 pl-30 pr-10" style={{ color: COLOR_ICON_HEADER_BAR_FULL_SCREEN }}>
                                                { 
                                                    documentMode 
                                                        ? <FilePdfOutlined style={{ height: '34px', fontSize: "32px" }}/> 
                                                        : <FileOutlined style={{ height: '34px', fontSize: "32px" }} /> 
                                                }
                                            </div>
                                            <div>
                                                <Typography.Title 
                                                    level={5} 
                                                    style={{ margin: 0, color: COLOR_TEXT_HEADER_BAR_FULL_SCREEN, width: "60vw" }}
                                                    ellipsis={{ rows: 1, expandable: false, suffix: '' }}
                                                    title={fileData?.fileName || ""}
                                                >
                                                    { fileData?.fileName || <EmptyLabel/> }
                                                </Typography.Title>
                                            </div>
                                        </div>
                                        <div className="flex flex-row items-center" style={{ fontSize: 20 }} > 
                                            <div className="flex flex-row items-center">
                                                <span className="mr-20" style={{ color: WHITE_ABSOLUTE, fontSize: fontSize }} >{scaleInString}</span>
                                                <ZoomInOutlined onClick={zoomIn} title={"Zoom in"} className="cursor-pointer select-none pr-20" style={{ color: WHITE_ABSOLUTE }} />
                                                <ZoomOutOutlined onClick={zoomOut} title={"Zoom out"} className="cursor-pointer select-none pr-30" style={{ color: WHITE_ABSOLUTE }} />
                                            </div>
                                            <CloseOutlined title={"Close"} onClick={fullscreen} className="cursor-pointer select-none pr-30" style={{ color: WHITE_ABSOLUTE }} />
                                        </div>
                                    </Row>   
                                </If>
                                
                                <Row style={{ height: isFullScreenMode ? "100%" : heightContainerBox }}>
                                    
                                    {/* Left Panel Images list / Paginator */}
                                    <If condition={(documentMode)}>
                                        <Col 
                                            onScroll={handleScroll}
                                            ref={sidePanelListImages} 
                                            span={isFullScreenMode ? 3 : 4} 
                                            className="relative overflow-scroll" 
                                            style={{ backgroundColor: LIGHT_BACKGROUND, maxHeight: isFullScreenMode ? "100vh" : heightContainerBox, borderRight: `1px solid ${COLOR_BORDER_INSIDE}` }} >

                                            {/* Pagination Info */}
                                            <PaginatorInfo derivatives={derivatives} indexImageSelected={indexImageSelected} isFixed={isFixed} />
                                            
                                            {/* List of Images */}
                                            <div className="pr-25 pl-25 pt-20">
                                                {
                                                    derivatives && isArrayWithValues(derivatives) && derivatives.filter((img) => img?.derivativeType === DerivativesEnum.PREVIEW ).map((img, index) => {
                                                        return (
                                                            <div key={img.id} className="flex flex-col items-center mb-10">
                                                                { 
                                                                    img?.signedUrl && (
                                                                        <img 
                                                                            src={img.signedUrl} 
                                                                            onClick={() => selectImage(img.signedUrl!, index) }
                                                                            className={`w-full cursor-pointer h-full ${ (indexImageSelected === index) ? "mb-7" : "mb-4"}`}
                                                                            style={(indexImageSelected === index) ? { outline: `4px solid ${colorPrimaryHover}`, borderRadius: 4 } : {} }
                                                                            alt=""
                                                                        />
                                                                    ) 
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                        </Col>
                                    </If>

                                    {/* Viewer Central Container */}
                                    <Col 
                                        span={centerPanelSpanWidth}
                                        className="items-center relative overflow-hidden"
                                        style={{ backgroundColor: isMobileNavigation ? COLOR_TEXT_BASE : DARK_BACKGROUND }}
                                    >       
                                            <If condition={Boolean(hasPreview)}>
                                                <>
                                                    <If condition={(!isFullScreenMode && !isMobileNavigation)}>
                                                        <div 
                                                            title={"FullScreen"} 
                                                            className="absolute flex flex-row pt-11 pb-11 pr-11 pl-11 rounded-full cursor-pointer" 
                                                            style={{ backgroundColor: LIGHT_BACKGROUND, zIndex: ZINDEX_LAYER_OVER_MODAL, right: "1.8rem", top: "1.8rem" }} >
                                                            <FullScreenSvgIcon 
                                                                onClick={fullscreen} 
                                                                style={{ color: WHITE_ABSOLUTE }} 
                                                            />
                                                        </div>
                                                    </If>
                                                    <If condition={!!(urlImageActive)}>
                                                        <ImageContainerBox
                                                                imageUrl={urlImageActive} 
                                                                width={ isFullScreenMode ? "100%": `${widthContainerDocument}px` } 
                                                                height={ isFullScreenMode ? `calc(100vh - ${headerBarHeight}px)`: `${heightContainerBox}px` }
                                                        />
                                                    </If>
                                                </>
                                            </If>

                                            <If condition={!Boolean(hasPreview)}>
                                                <div className="flex flex-row h-full w-full items-center justify-center" style={{ height: `${heightContainerBox}px` }}>
                                                    <PreviewNotAvailable attachment={fileData} />
                                                </div> 
                                            </If>

                                    </Col>

                                    {/* Panel Side Right */}
                                    <If condition={(!isFullScreenMode)}>
                                        <If condition={(shouldShowRightPanel)}>
                                            <Col span={6} className="overflow-scroll generic-shadow-left" style={{ maxHeight: heightContainerBox }} >
                                                <ConfigProvider theme={{ token: { colorTextBase: themeConfig.darkMode ? WHITE_ABSOLUTE : COLOR_TEXT_BASE  } }}>
                                                    <If condition={Boolean(panelActive === PANELS.INFO)} >
                                                        <InfoSide
                                                            header={<HeaderInfoSide />}
                                                            body={<BodyInfoSide attachment={fileData} bodyContainerClassName="pl-30 pt-16 pr-16" />}
                                                        />
                                                    </If>
                                                    <If condition={Boolean(panelActive === PANELS.COMMENTS)} >
                                                        <CommentsPanel fileData={fileData} />
                                                    </If>
                                                </ConfigProvider>
                                            </Col>
                                        </If>
                                    </If>

                                </Row>
                                
                                {/* footer */}
                                <Row>
                                    <div ref={footerContainerRef} className="w-full">
                                        <If condition={isMobileNavigation}>
                                            <>{footer}</>
                                        </If>
                                    </div>
                                </Row>
                            </div>

                        </div>
                </Modal>
            </ConfigProvider>
        </>
    )
}
