import { ICostSubCategory } from "@models/cost-sub-category.model";
import { http } from '../http/axios.instance';
import { StringORNumber } from "./utils.repository";

export const costSubcategoryRepository = {
    getAll: async () => {
        const requestUrl = `cost-sub-categories?cacheBuster=${new Date().getTime()}`;
        const promiseGetAll = await http.get<ICostSubCategory[]>(requestUrl);
        return promiseGetAll;
    },
    getCostSubcategories: async (categoryId: StringORNumber) => {
        const requestUrl = `cost-categories/${categoryId}/sub-categories?cacheBuster=${new Date().getTime()}`;

        const promiseGetAll = await http.get<ICostSubCategory[]>(requestUrl);
        return promiseGetAll;
    },
    getActiveCostSubcategories: async (categoryId: StringORNumber) => {
        const requestUrl = `cost-categories/${categoryId}/sub-categories/active?cacheBuster=${new Date().getTime()}`;

        const promiseGetAll = await http.get<ICostSubCategory[]>(requestUrl);
        return promiseGetAll;
    },
    getActiveCostSubcategoriesByCategoriesIds: async (categoriesIds: StringORNumber[]) => {
        const requestUrl = `cost-sub-categories/active?categoryIds=${categoriesIds.join(',')}&cacheBuster=${new Date().getTime()}`;

        const promiseGetAll = await http.get<ICostSubCategory[]>(requestUrl);
        return promiseGetAll;
    },
    create: async (entity: ICostSubCategory): Promise<ICostSubCategory> => {
        const requestUrl = `cost-sub-categories`;
        const promiseCreate = await http.post<ICostSubCategory>(requestUrl, entity);
        return promiseCreate.data;
    },
    update: async (id: string | number, entity: ICostSubCategory): Promise<ICostSubCategory> => {
        const requestUrl = `cost-sub-categories/${id}`;
        const promiseUpdate = await http.put<ICostSubCategory>(requestUrl, entity);
        return promiseUpdate.data;
    },
    reorder: async (categoryId: StringORNumber, ids: number[]) => {
        const requestUrl = `cost-categories/${categoryId}/cost-sub-categories/reorder`;
        const promiseUpdate = await http.put<ICostSubCategory>(requestUrl, ids);
        return promiseUpdate;
    },
}