import { IProjectDeliverableUI } from "@models/project-deliverable";
import { DeliverableHandleDependencyModal } from "./DeliverableDetailsDependencyModal";
import useVisible from "@HOOKs/UseVisible";
import { CustomButtonText } from '@components/Utils/CustomButtonText';
import { useTranslation } from 'react-i18next';

interface IDeliverableDetailsButtonEditDetailsProps {
  deliverable: IProjectDeliverableUI;
}

export const DeliverableDetailsButtonEditDetails = (props: IDeliverableDetailsButtonEditDetailsProps) => {

    const { deliverable } = props;

    const { t } = useTranslation();

    const [openModal, toogleModal] = useVisible()
    
    const onEdit = () => { toogleModal() }

    return (
        <>  
            <CustomButtonText onClick={onEdit} >
                {t("generic.edit")}
            </CustomButtonText>
            { openModal && <DeliverableHandleDependencyModal open={openModal} toogle={toogleModal}  deliverable={deliverable} /> }
        </>
    )
}



