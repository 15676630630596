import { Helmet } from 'react-helmet-async'
import { Outlet } from 'react-router-dom'
import { configuration } from '../environments/env'
import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { DashBoardHeader } from '@components/Dashboard/DashboardHeader';
import { usePageContentSize } from '@HOOKs/UsePageContentSize';
import { useAuth } from '@providers/AuthProvider';
import { useTranslation } from 'react-i18next';

export default function DashboardPage() {
  const PageContent = () => {
    const [layoutRef] = usePageContentSize();

    const { user: userLogged } = useAuth();

    return (
      <Layout className={`${userLogged && userLogged.id ? 'opacity-100' : 'opacity-0'}`}>
        <DashBoardHeader />
        <Content ref={layoutRef}>
          <Outlet />
        </Content>
      </Layout>
    );
  };

  const { t } = useTranslation();
  const Page = () => (
    <>
      <Helmet>
        <title>
          {t('generic.home')} | {configuration.APP_NAME}
        </title>
      </Helmet>
      <PageContent />
    </>
  );
  return <Page />;
}