import { IProjectDeliverable, IProjectDeliverableUI } from "@models/project-deliverable";
import { POLICY } from "@models/project-deliverable-approval";
import { sortCollectionByFieldString } from "@shared/util/array-util";
import { formatDatePipe } from "@shared/util/date-utils";
import { hasIntegerValue, isNumber } from "@shared/util/number-util";
import dayjs from "dayjs";

export const statusMap = Object.freeze({
    'NOT_STARTED': 'Not Started',
    'COMPLETED': 'Completed',
    'ON_TRACK': 'On Track',
    'PAST_DUE': 'Past Due',
    'NOT_APPLICABLE': 'Not Applicable',
    'PENDING_APPROVAL': 'Pending Approval'
})

export const statusCollection = Object.keys(statusMap).map(function (key) {
  let newObject = {} as any;
  newObject["value"] = key;
  newObject["label"] = `${statusMap[key as keyof typeof statusMap]}`;
  return newObject;
})

export const statusCollectionAscSorted = sortCollectionByFieldString(statusCollection, 'label');

export const getStatusLabel = (status: string) => {
  return statusMap.hasOwnProperty(status) && statusMap[status as keyof typeof statusMap];
}

const isNotStarted = (status?: string) => Boolean(status && (getStatusLabel(status) === statusMap.NOT_STARTED))
const isPendingApproval = (status?: string) => Boolean(status && (getStatusLabel(status) === statusMap.PENDING_APPROVAL))
const isCompleted = (status?: string) => Boolean(status && (getStatusLabel(status) === statusMap.COMPLETED))

export const getMostAccurateDate = (deliverable: IProjectDeliverable): string => {
  let date: string | null | undefined = null;
  if (deliverable.actualFinishDate != null) {
    date = deliverable.actualFinishDate;
  } else if (deliverable.actualStartDate != null) {
    date = deliverable.plannedFinishDate;
  } else {
    date = deliverable.plannedFinishDate;
  }
  return formatDatePipe(date);
}

export const getMostAccurateStartDate = (deliverable: IProjectDeliverable): string => {
  let date: string | null | undefined = null;
  if (deliverable.actualStartDate != null) {
    date = deliverable.actualStartDate;
  } else if (deliverable.actualStartDate != null) {
    date = deliverable.plannedStartDate;
  } else {
    date = deliverable.plannedStartDate;
  }
  return formatDatePipe(date);
}

export class ProjectDeliverableMapper {

  public static toDomain(response: IProjectDeliverable[]): Promise<IProjectDeliverableUI[]> {
    const result = new Promise((resolve, reject) => {
      try {
        const dataTransformedToDomain = response.map((deliverable, index) => {

          const { approvals = [], plannedStartDate, actualStartDate, plannedFinishDate, actualFinishDate } = deliverable;
          const statusLabelUI = deliverable?.status ? getStatusLabel(deliverable?.status) : "";
          const approvalPendingForApprovalIndex = approvals?.findIndex((item) => (item.status && isPendingApproval(item?.status) && item.approvedDate === null));
          const approval = approvals![approvalPendingForApprovalIndex];
          const approverUser = approvals![approvalPendingForApprovalIndex]?.user;
          const approverUserFullName = (approverUser && approverUser.fullName) || "";
          const hasApprovalWaiting = approvalPendingForApprovalIndex > -1;
          const approverNumber = hasApprovalWaiting ? (approvalPendingForApprovalIndex + 1) : 0
          const awaitingApprovalFrom = hasApprovalWaiting ? approverUserFullName : "";
          const firstApproval = approvals[0];
          const lastApproval = [...approvals].pop();
          const requestedOn = (firstApproval && firstApproval?.requestDate) || '';
          const hasDuration = hasIntegerValue(deliverable.duration);
          const durationHasAtLeast1day = hasDuration && deliverable?.duration! > 0;
          const diffPlannedAndActualStartDate = (actualStartDate && plannedStartDate) ? dayjs(actualStartDate).diff(plannedStartDate, 'day') : null;
          const diffPlannedAndActualEndDate = (actualFinishDate && plannedFinishDate) ? dayjs(actualFinishDate).diff(plannedFinishDate, 'day') : null;
          const plannedStartDateFormatted = formatDatePipe(plannedStartDate);
          const plannedFinishDateFormatted = formatDatePipe(plannedFinishDate);
          const actualStartDateFormatted = formatDatePipe(actualStartDate);
          const actualFinishDateFormatted = formatDatePipe(actualFinishDate);
          const diffAbsolutePlannedAndActualStartDate = isNumber(diffPlannedAndActualStartDate) ? Math.abs(diffPlannedAndActualStartDate!) : null;
          const diffAbsolutePlannedAndActualEndDate = isNumber(diffPlannedAndActualEndDate) ? Math.abs(diffPlannedAndActualEndDate!) : null;
          const mostAccurateDate = getMostAccurateDate(deliverable);
          const mostAccurateStartDate = getMostAccurateStartDate(deliverable);

          let nextApproval = {}
          if (hasApprovalWaiting) {
            const nextApproverFoundedIndex = approvals?.findIndex((item, index) => ((index > approvalPendingForApprovalIndex) && item.status && isNotStarted(item?.status) && item.approvedDate === null));
            nextApproval = approvals![nextApproverFoundedIndex]
          }

          return {
            ...deliverable,
            isStatusPendingApproval: deliverable?.status && isPendingApproval(deliverable?.status),
            isStatusCompleted: deliverable?.status && isCompleted(deliverable?.status),
            hasUserErrorPolicy: approvals.some!((item) => String(item.policy) === POLICY.INVALID),
            isWaitingForApprovals: hasApprovalWaiting,
            totalApprovers: approvals.length,
            firstApproval,
            lastApproval,
            approval,
            nextApproval,
            approverUser,
            approverUserFullName,
            approverNumber,
            awaitingApprovalFrom,
            requestedOn,
            statusLabelUI,
            hasDuration,
            durationHasAtLeast1day,
            diffPlannedAndActualStartDate,
            diffPlannedAndActualEndDate,
            plannedStartDateFormatted,
            plannedFinishDateFormatted,
            actualStartDateFormatted,
            actualFinishDateFormatted,
            diffAbsolutePlannedAndActualStartDate,
            diffAbsolutePlannedAndActualEndDate,
            mostAccurateDate,
            mostAccurateStartDate
          }
        });
        resolve(dataTransformedToDomain);
      } catch (error: any) {
        reject(error);
      }
    })
    return result as Promise<IProjectDeliverableUI[]>;
  }

}