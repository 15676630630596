export interface ISetting {
    id?: number;
    key?: string;
    value?: string;
    lastModifiedById?: string;
    lastModifiedByFirstName?: string;
    lastModifiedByLastName?: string;
    lastModifiedDate?: string;
}

export const defaultValue: Readonly<ISetting> = {};

export const defaultCurrencyKey = 'DEFAULT_CURRENCY';
export const changeOrderCostGroupKey = 'CHANGE_ORDER_COST_GROUP';
export const projectDeliverableReasonRequiredAlwaysKey = 'PROJECT_DELIVERABLE_REASON_REQUIRED_ALWAYS';
export const projectDeliverableReasonRequiredOpeningKey = 'PROJECT_DELIVERABLE_REASON_REQUIRED_OPENING';
export const oxblueAppId = 'INTEGRATION_OXBLUE_X-APP-ID'
export const oxblueUsername = 'INTEGRATION_OXBLUE_USERNAME'
export const oxbluePassword = 'INTEGRATION_OXBLUE_PASSWORD'