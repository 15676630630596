/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useErrorAnimation } from "@HOOKs/UseErrorAnimation";
import { isFulfilled } from "@reduxjs/toolkit";
import { asyncLaunchNotification } from "@store/slices/notification";
import { createEntity, updateEntity } from "@store/slices/custom-field-groups";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Button, Col, Drawer, Form, Input, Row, Space } from "antd";
import { ICustomFieldGroup } from "@models/custom-field-group.model";
import { useTranslation } from "react-i18next";

interface ICustomFieldGroupEditProps {
  open: boolean,
  toggle: () => void,
  onModifiedChanges?: (customFieldGroup: ICustomFieldGroup) => void,
  customFieldGroup: ICustomFieldGroup | null
}

export const CustomFieldGroupEdit = (props: ICustomFieldGroupEditProps) => {

    const { open, toggle, customFieldGroup, onModifiedChanges } = props;

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    
    const [form] = Form.useForm();

    const { loading, updating } = useAppSelector((state) => state.CustomFieldGroups);

    const [animateError, playAnimationOfError] = useErrorAnimation();

    const onFinishFailed = (errorInfo: any) => playAnimationOfError();

    const onFinish = (entity: any): void => {
        const hasEntity = customFieldGroup?.id;
        if (hasEntity) {
            dispatch(
                updateEntity({
                    ...customFieldGroup,
                    ...entity,
                    name: entity?.name?.trim() || ''
                })  
            ).then(onSuccessUpdatedStatus);
            
        } else {
            dispatch(createEntity({
                id: "",
                ...entity,
                name: entity?.name?.trim() || '',
                order: customFieldGroup?.order || 99,
                referenceType: customFieldGroup?.referenceType || ''
            })).then(onSuccessCreateStatus);
        }
    };

    const onSuccessUpdatedStatus = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: t("admin.changesSaved"),
                  description: t("admin.yourChangesHaveBeenSavedSuccessfully")
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onSuccessCreateStatus = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: t("admin.changesSaved"),
                  description: t("admin.customFieldGroupCreatedSuccessfully")
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onClose = () => { 
        form.resetFields();
        toggle(); 
    }

    const onSubmit = () => form.submit();

    useEffect(() => { setRoleFormFields() }, [customFieldGroup])

    const setRoleFormFields = () => {
        form.setFieldsValue({
            ...customFieldGroup
        });
    }

    return (
        <>
            <Drawer 
                width={448} 
                title={`${customFieldGroup?.id ? t("generic.edit"): t("generic.create")} ${t("admin.customFieldGroup")}`}
                placement="right" 
                onClose={onClose} 
                open={open}
                closable={false}
                extra={
                    <Space>
                      <Button type="primary" ghost onClick={onClose}>
                        {t("generic.cancel")}
                      </Button>
                      <Button loading={loading} onClick={onSubmit} type="primary" disabled={updating}>
                        {`${customFieldGroup?.id ? t("generic.save"): t("generic.create")}`}
                      </Button>
                    </Space>
                  }
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className={`${animateError && "error-animation"}`}
                >

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[
                                    { required: true, message: t("admin.nameFieldIsRequired") },
                                ]}
                            >
                                <Input placeholder={t("generic.name")} allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
  );
}
