import ReactDOMServer from 'react-dom/server';
import { WrapperGoogleMap } from '@components/Google/Map/WrapperGoogleMap';
import { useAppDispatch, useAppSelector } from '@store/store';
import { useEffect, useState } from 'react';
import { isArrayWithValues } from '@shared/util/array-util';
import { getUniqueId } from '@infrastructure/repositories/utils.repository';
import { asyncLaunchNotification } from '@store/slices/notification';
import { DEFAULT_COLOR } from '@providers/ThemeProvider';
import type { IAddress } from '@models/address.model';
import type { AddressReferenceEnumListOptions } from '@models/enumerations/address-reference-enum.model';
import type { StringORNumber } from '@infrastructure/repositories/utils.repository';

export interface IAddressListMapViewProps {
    referenceId?: StringORNumber,
    referenceType: AddressReferenceEnumListOptions
}

export const AddressGenericCardMap = (props: IAddressListMapViewProps) => {

    const { referenceType } = props;

    const dispatch = useAppDispatch();

    const { data: addressesList } = useAppSelector((state) => state.GenericAddress[referenceType]);

    const [markersOptions, setMarkersOptions] = useState<google.maps.marker.AdvancedMarkerElementOptions[]>([])

    useEffect(() => {
        processMarkerOptions(addressesList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressesList]);

    const handleClickMarker = (map: google.maps.Map, marker: google.maps.marker.AdvancedMarkerElement, extraData: IAddress) => {

        const idZoomToSteet = getUniqueId();
        const idViewStreet = getUniqueId();

        const InfoPlacePopover = (map: google.maps.Map, marker: google.maps.marker.AdvancedMarkerElement, address: IAddress,) => {
            return (<div id="infowindow-content" style={{ color: DEFAULT_COLOR }}>

                {/* Address Type Ej: Primary */}
                <span id="place-name" className="font-bold text-tiny-075"> {address?.addressType?.description}</span>
                <br />

                {/* Address Description */}
                <span className='text-tiny-070'>{address?.streetAddress}</span><br />
                <span className='text-tiny-070'>{`${address?.city ? `${address?.city},` : ""} ${address?.stateProvince || ""} ${address?.postalCode || ""}`}</span>
                <br />

                {/* Extra Help */}
                <div className='flex flex-row items-center justify-end pt-10'>
                    <button id={idZoomToSteet} className='ghost-button-windowinfo text-tiny-070 flex flex-row items-center'>Smart Zoom</button>
                    <button id={idViewStreet} className='button-windowinfo ml-7 text-tiny-070 flex flex-row items-center'>
                        Street View
                    </button>
                </div>

            </div>)
        }

        const infowindow = new google.maps.InfoWindow({
            content: ReactDOMServer.renderToString(InfoPlacePopover(map, marker, extraData))
        });

        infowindow.open(map, marker as google.maps.marker.AdvancedMarkerElement);

        infowindow.addListener("closeclick", () => {
            marker.gmpClickable = true;
            document.getElementById(idZoomToSteet)?.removeEventListener("click", () => zoomToStret(marker));
            document.getElementById(idViewStreet)?.removeEventListener("click", () => setViewStreet(marker));
        });

        const zoomToStret = (marker: google.maps.marker.AdvancedMarkerElement) => {
            const DEFAULT_MIN_FIT_ZOOM = 16
            if (marker?.map) {
                try {
                    const position = marker.position;
                    const markerMap = marker.map as google.maps.Map;
                    markerMap.setZoom!(DEFAULT_MIN_FIT_ZOOM);
                    markerMap.setCenter!(position!);
                } catch (e) {
                    dispatch(asyncLaunchNotification({
                        type: "error",
                        config: {
                            message: `Map`,
                            description: `Something happened trying to fit/Zoom you Marker in the Map.`
                        }
                    }));
                    console.error(e);
                }
            }
        }

        const setViewStreet = (marker: google.maps.marker.AdvancedMarkerElement) => {
            if (extraData.latitude && extraData.longitude) {
                try {
                    const markerMap = marker?.map as google.maps.Map;
                    const streetView = markerMap.getStreetView();
                    streetView?.setPosition(new google.maps.LatLng(+extraData.latitude!, +extraData.longitude!));
                    streetView?.setVisible(true);
                } catch (e) {
                    dispatch(asyncLaunchNotification({
                        type: "error",
                        config: {
                            message: `Map`,
                            description: `Something happened trying to set the Street View Mode.`
                        }
                    }));
                    console.error(e);
                }

            }
        }

        infowindow.addListener("visible", () => {
            document.getElementById(idZoomToSteet)?.addEventListener("click", () => zoomToStret(marker));
            document.getElementById(idViewStreet)?.addEventListener("click", () => setViewStreet(marker));
        });

        marker.gmpDraggable = false;
    }

    const processMarkerOptions = (list: IAddress[]) => {
        if (list && isArrayWithValues(list)) {
            const locationsWithCoordinates = list.filter((location) => (!!location?.longitude && !!location?.latitude))
            const markersPropsOptions = locationsWithCoordinates.map((entity) => {
                const { longitude, latitude } = entity;
                if (!latitude || !longitude) { return {} }
                return {
                    position: { lat: +latitude, lng: +longitude },
                    gmpClickable: true,
                    extraData: entity
                }
            })
            markersPropsOptions && setMarkersOptions(markersPropsOptions);
        } else {
            setMarkersOptions([]);
        }
    }

    const handleOnMapGenerated = (map?: google.maps.Map) => {
        setOpenStreeViewIfExistPrimaryAddress(map);
    }

    const setOpenStreeViewIfExistPrimaryAddress = (map?: google.maps.Map) => {
        if (!map) return;

        const primaryAddressValid = addressesList.find((address) => address?.addressType?.main && address.latitude && address?.longitude);

        if (primaryAddressValid?.latitude && primaryAddressValid?.longitude) {
            map?.setCenter?.(new google.maps.LatLng(+primaryAddressValid.latitude!, +primaryAddressValid.longitude!));
            map?.setZoom?.(16);
        }

    }

    return (
        <WrapperGoogleMap
            containerStyle={{
                // aspectRatio: 1 / 0.829
                height: '100%'
            }}
            markers={markersOptions}
            handleClickMarker={handleClickMarker}
            onMapGenerated={handleOnMapGenerated}
        />
    )
}