import { Col, Row, theme, Typography } from "antd";
import { CreateAlbumButton } from "./CreateAlbumButton";
import { usePhotoAlbumContextProvider } from "./PhotoAlbumProvider";

const { Title } = Typography;

interface IAlbumListContainerEmptyProps {}

export const AlbumListContainerEmpty = (props: IAlbumListContainerEmptyProps) => {

    const { token: { colorTextSecondary }} = theme.useToken();

    const { entityName } = usePhotoAlbumContextProvider();
    
    return (
        <>
            <Row className="pt-20 h-full pr-30 pl-30 pb-40 w-full h-full">
                <Col span={24}>
                    <div className="flex flex-col items-center justify-center">
                        <div className="flex flex-col items-center">
                            <Title className="text-color-neutral-9 mt-0 imb-0" level={4} style={{ fontWeight: 500 }}>No Albums Yet</Title>
                        </div>
                        <div className="pt-8" style={{ color: colorTextSecondary }}>
                            Create your first album for this {entityName}
                        </div>
                        <div className="flex flex-row items-center pt-15">
                            <CreateAlbumButton />
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}
