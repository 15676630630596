/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useErrorAnimation } from "@HOOKs/UseErrorAnimation";
import { LANGUAGES } from "@constants/languages";
import { IUser } from "@models/user.model";
import { isFulfilled } from "@reduxjs/toolkit";
import { asyncLaunchNotification } from "@store/slices/notification";
import { createEntity, updateEntity } from "@store/slices/users";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Button, Col, Drawer, Form, Input, Row, Select, Space, Switch, theme } from "antd";
interface IUserEditProps {
  open: boolean,
  toogle: () => void,
  onModifiedChanges?: (user: IUser) => void,
  user: IUser
}

export const UserEdit = (props: IUserEditProps) => {

    const { open, toogle, user, onModifiedChanges } = props;

    const { token: { colorPrimary, colorTextQuaternary } } = theme.useToken();

    const dispatch = useAppDispatch();

    const [form] = Form.useForm();

    const activated = Form.useWatch('activated', form);

    const { loading, updating } = useAppSelector((state) => state.Users);

    const [animateError, playAnimationOfError] = useErrorAnimation();

    const onFinishFailed = (errorInfo: any) => playAnimationOfError();

    const onFinish = (entity: any): void => {
        const hasUser = user?.id;
        const permisions = { authorities: ["ROLE_USER", "ROLE_ADMIN"] };

        if (hasUser) {
            dispatch(
                updateEntity({
                    ...user,
                    ...permisions,
                    ...entity,
                    activated: activated
                })  
            ).then(onSuccessUpdatedUser);
            
        } else {
            dispatch(createEntity({
                id: "",
                ...entity,
                ...permisions,
                langKey: "en",
                createdDate: null,
                createdBy: "",
                lastModifiedBy: "",
                lastModifiedDate: null,
                activated: activated,
                password: ""
            })).then(onSuccessCreateUser);
        }
    };

    const onSuccessUpdatedUser = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: `Changes saved`,
                  description: `Your changes have been saved successfully`
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onSuccessCreateUser = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: `Changes saved`,
                  description: `User created successfully`
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onClose = () => { 
        form.resetFields();
        toogle(); 
    }

    const onSubmit = () => form.submit();

    useEffect(() => { setUserFormFields() }, [user])

    const setUserFormFields = () => {
        form.setFieldsValue({
            ...user,
            activated: Boolean(user.activated)
        });
    }

    const validateLogin = (_: any, value: string) => {
        const pattern = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$|^[a-zA-Z0-9._-]{3,}$/);
        if (value && !pattern.test(value)) {
          return Promise.reject('Invalid Login text');
        }
        return Promise.resolve();
    };

    return (
        <>
            <Drawer 
                width={448} 
                title={`${user?.id ? "Edit": "Add"} user`}
                placement="right" 
                onClose={onClose} 
                open={open}
                closable={false}
                extra={
                    <Space>
                      <Button type="primary" ghost onClick={onClose}>
                        Cancel
                      </Button>
                      <Button loading={loading} onClick={onSubmit} type="primary" disabled={updating}>
                        {`${user?.id ? "Save": "Create"}`}
                      </Button>
                    </Space>
                  }
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className={`${animateError && "error-animation"}`}
                >

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="firstName"
                                label="First Name"
                                rules={[
                                    { required: true, message: "First Name field is Required" },
                                ]}
                            >
                                <Input placeholder="First Name" allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="lastName"
                                label="Last Name"
                                rules={[
                                    { required: true, message: "Last Name field is Required" },
                                ]}
                            >
                                <Input placeholder="Last Name" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="login"
                                label="Login"
                                rules={[
                                    { required: true, message: "Login field is Required" },
                                    { validator: validateLogin }
                                ]}
                            >
                                <Input placeholder="Login" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    { required: true, message: "Email field is Required" },
                                    { max: 50, message: "This field cannot be longer than 50 characters"}
                                ]}
                            >
                                <Input placeholder="Email" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                        <Form.Item 
                            name="langKey" 
                            label="Language" 
                            rules={[
                                { required: true, message: "Language is Required" }
                            ]}
                        >
                            <Select
                                placeholder="Language"
                                options={LANGUAGES.map((item: any) => ({ value: item.id, label: item?.description }))}
                                allowClear={false}
                            />
                        </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16} >
                        <Col span={"none"} className="pr-0">
                            <Form.Item name="activated" label="Status" valuePropName="checked">
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col span={"none"} className="pl-0" >
                            {/* For css purposes */}
                            <Form.Item className="ml-0 pl-0" label={<span style={{ opacity: 0 }}>Label</span>} valuePropName="">
                                <Switch style={{ display: "none" }}/>
                                <span className="ml-15" style={{ color: Boolean(activated) ? colorPrimary : colorTextQuaternary }}>{ Boolean(activated) ? "Activated": "Deactivated"}</span>
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Drawer>
        </>
  );
}
