import { SVGProps } from "react";

type IProps = SVGProps<SVGSVGElement>

export const JumpCloudSvgIcon = (props: IProps) => {  

    const { ...restProps } = props;

    return (
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 393.09 190.3" width="14" height="14" {...restProps}>
            <defs></defs>
            <path fill="currentColor" d="M393.09,137.05c0,29.41-23.84,53.24-53.24,53.24-.22,0-.44-.01-.66-.02v.02s-14,0-14,0c-1.53-17.72-19.81-32.81-45.68-39.81,9.74-9.63,15.23-23.56,13.36-38.73-2.58-20.89-19.6-37.68-40.51-40.01-27.9-3.12-51.52,18.63-51.52,45.9,0,12.85,5.25,24.47,13.72,32.84-13.73,3.71-25.32,9.7-33.42,17.16-5.58-3.29-12.14-5.99-19.39-7.95,8.31-8.22,12.99-20.1,11.39-33.04-2.21-17.81-16.72-32.13-34.56-34.11-23.78-2.65-43.92,15.9-43.92,39.14,0,10.96,4.48,20.86,11.7,28-20.41,5.52-35.3,16.95-38.44,30.6h-14v-.02c-.22,0-.43,.02-.66,.02-29.41,0-53.24-23.84-53.24-53.24s23.84-53.24,53.24-53.24c7,0,13.68,1.36,19.8,3.82,8.33-18.52,26.94-31.43,48.57-31.43,1.3,0,2.59,.06,3.86,.15C131.55,24.27,159.73,0,193.58,0c25.25,0,47.34,13.5,59.47,33.66,5.21-1.58,10.73-2.44,16.45-2.44,30.51,0,55.36,24.18,56.48,54.42,4.42-1.19,9.06-1.83,13.86-1.83,29.41,0,53.24,23.84,53.24,53.24Z"/>
        </svg>
    )
}
