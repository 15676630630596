import { ReactNode } from "react";
import { COLOR_TEXT_BASE, useThemeProvider } from "@providers/ThemeProvider";
import { IconMoreMenuMobile } from "./IconMoreMenuMobile";
import { IAttachment } from "@models/attachment.model";
import { ConfigProvider, theme } from "antd";
import { THEME_LIGHT } from '../../../../../providers/AntDesignProvider';

interface IFooterMobileProps {
  filePaginator: ReactNode;
  attachment: IAttachment;
  closeFilePreview: () => void;
  onSuccessDeleteAttachment: () => void;
}

export const FooterMobile = (props: IFooterMobileProps) => {

    const { filePaginator, attachment, closeFilePreview, onSuccessDeleteAttachment } = props;

    const { themeConfig } = useThemeProvider();

    const themeLight = {
        colorPrimary: themeConfig.primaryColor,
        colorInfo: themeConfig.primaryColor,
        ...THEME_LIGHT
    }

    return (
        <ConfigProvider theme={{ algorithm: theme.defaultAlgorithm, token: { ...themeLight  }}}>
            <div 
                className="flex flex-row w-full items-center justify-between pt-8 pb-16 pr-20 pl-20"
                style={{
                    backgroundColor: COLOR_TEXT_BASE,
                }}
            >
                {filePaginator}
                <div className="flex flex-row ml-16">
                    <IconMoreMenuMobile 
                        attachment={attachment}  
                        closeFilePreview={closeFilePreview}
                        onSuccessDeleteAttachment={onSuccessDeleteAttachment}
                    />
                </div>
            </div>
        </ConfigProvider>
    )
}
