import { useEffect, useState } from 'react';

const breakpoints = {
  mobile: 576,
  tablet: 768,
  desktop: 992,
};

type Breakpoint = 'mobile' | 'tablet' | 'desktop';

function getCurrentBreakpoint(width: number): Breakpoint {
  if (width < breakpoints.mobile) return 'mobile';
  if (width < breakpoints.tablet) return 'tablet';
  return 'desktop';
}

export function useBreakpoints() {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<Breakpoint>(
    getCurrentBreakpoint(window.innerWidth)
  );

  useEffect(() => {
    function handleResize() {
      const newBreakpoint = getCurrentBreakpoint(window.innerWidth);
      if (newBreakpoint !== currentBreakpoint) {
        setCurrentBreakpoint(newBreakpoint);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [currentBreakpoint]);

  const isMobile = currentBreakpoint === 'mobile';
  const isTablet = currentBreakpoint === 'tablet';
  const isDesktop = currentBreakpoint === 'desktop';

  return { isMobile, isTablet, isDesktop };
}