import { theme, Row, Col, ConfigProvider } from 'antd';
import { Card } from 'antd';
import { useParams } from 'react-router-dom';
import { AddressGenericListMapView } from '@components/AddressGeneric/AddressGenericListMapView';
import { AddressGenericTable } from '@components/AddressGeneric/AddressGenericTable';
import { CompaniesGenericTable } from '@components/CompaniesGeneric/CompaniesGenericTable';

export const SiteOverviewCardEntities = () => {

    const { token: { marginLG } } = theme.useToken();
    const { siteId } = useParams<"siteId">();

    return (
        <Row style={{ paddingLeft: `${marginLG}px`, paddingRight: `${marginLG}px` }} className="pb-15 w-full">
            <Col span={24}>
                <ConfigProvider theme={{ components: { Card: { borderRadiusLG: 8 } } }} >
                    <Card>
                        <Row>
                            <Col span={8} className="pr-20">
                                <AddressGenericListMapView referenceType="SITE" referenceId={siteId} />
                            </Col>
                            
                            <Col span={16}>
                                <Row className='pb-10'> 
                                    <Col span={24}> 
                                        <AddressGenericTable referenceType="SITE" referenceId={siteId} />
                                    </Col>   
                                </Row>

                                <Row className='pt-10'> 
                                    <Col span={24}> 
                                        <CompaniesGenericTable 
                                            referenceType="SITE" 
                                            referenceId={siteId} 
                                            tableCustomizedProps={{  scroll: { y: 110 } }} 
                                        />
                                    </Col>   
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </ConfigProvider>
            </Col>
        </Row>
    )
}