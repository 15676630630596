import { Skeleton } from 'antd';
import { useAppSelector } from "@store/store";
import { ProjectDeliverableList } from './ProjectDeliverableList';
import { ProjectDeliverableListFiltered } from './ProjectDeliverableListFiltered';


export const ProjectDeliverableListContainer = () => {

    return (
        <>  
            <ProjectDeliverableList />
            <ProjectDeliverableListContainerFiltered />
        </>
    )
}

export const ProjectDeliverableListContainerFiltered = () => {

    const { loadingList } = useAppSelector((state) => state.ProjectDeliverables);

    return (
        <>
            <Skeleton loading={loadingList} paragraph={{ rows: 24 }} active={true}>
                <ProjectDeliverableListFiltered />
            </Skeleton>
        </>
    )
}
