import React from 'react'
import { Helmet } from 'react-helmet-async';
import { configuration } from '../../environments/env'

const FAVICON_URL = configuration.FAVICON_PATH_URL;
const APP_NAME = configuration.APP_NAME;
const APP_DESCRIPTION = configuration.APP_DESCRIPTION;

export const Head = () => {
  return (
    <>
        <Helmet>
            <link rel="icon" type="image/png" href={FAVICON_URL} sizes="16x16" />
            <meta name='description' content={APP_DESCRIPTION} />
            <title>{APP_NAME}</title>
        </Helmet>
    </>
  )
}
