import { isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState } from "../reducer.util";
import { createAsyncThunk } from "@reduxjs/toolkit"
import { IPhase, defaultValue } from '@models/phase.model';
import { cleanEntity, getTotalItems } from '@shared/util/entity-utils';
import { phasesService } from '@services/phases.service';
import { serializeGenericHandleError } from '@store/thunk.util';

const nameOfEntity = "phases";
const service = phasesService;

export const getPhases = createAsyncThunk(
  `${nameOfEntity}/fetch_entity_list`,
  async () => {
      return service.getPhases({});
  },
  { serializeError: serializeGenericHandleError }
);

export const reorderPhases = createAsyncThunk(
  `${nameOfEntity}/reorder`,
  async (ids: number[], thunkAPI) => {
    const result = await service.reorder(ids);
    thunkAPI.dispatch(getPhases());
    return result;
  },
  { serializeError: serializeGenericHandleError }
);

export const createEntity = createAsyncThunk(
  `${nameOfEntity}/create_entity`,
  async (entity: IPhase, thunkAPI) => {
    const result = await service.create(cleanEntity(entity));
    thunkAPI.dispatch(getPhases());
    return result;
  },
  { serializeError: serializeGenericHandleError }
);

export const updateEntity = createAsyncThunk(
  `${nameOfEntity}/update_entity`,
  async (entity: IPhase, thunkAPI) => {
    const result = entity.id && await service.update(entity.id, cleanEntity(entity));
    thunkAPI.dispatch(getPhases());
    return result;
  },
  { serializeError: serializeGenericHandleError }
);

const initialState: EntityState<IPhase> = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

export const PhasesSlice = createEntitySlice({
    name: nameOfEntity,
    initialState,
    reducers: {
      clearEntity: (state,) => {
        state.entity = defaultValue;
      },
    },
    extraReducers(builder) {
        builder
          .addMatcher(isFulfilled(getPhases), (state, action) => {
            const { data, headers } = action.payload;
            return {
              ...state,
              loading: false,
              entities: data,
              totalItems: getTotalItems(headers),
            };
          })
          .addMatcher(isPending(getPhases), state => {
            state.errorMessage = null;
            state.updateSuccess = false;
            state.loading = true;
          })
          .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
            state.updating = false;
            state.loading = false;
            state.updateSuccess = true;
            state.entity = action.payload as IPhase;
          })
          .addMatcher(isPending(createEntity, updateEntity), state => {
            state.errorMessage = null;
            state.updateSuccess = false;
            state.updating = true;
          });
      }
  });
  
  export const { reset, clearEntity } = PhasesSlice.actions;
  
  // Reducer
  export default PhasesSlice.reducer;

  