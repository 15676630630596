// projectDeliverableReasonCodesSlice.ts

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { projectsService } from '../../services/projects.service';

interface ProjectDeliverableReasonCode {
  id: number;
  description: string;
  order: number;
  active: boolean;
  createdBy: number;
  createdDate: string;
  lastModifiedBy: number;
  lastModifiedDate: string;
}

interface ProjectDeliverableReasonCodesState {
  data: ProjectDeliverableReasonCode[];
  loading: boolean;
  error: string | null;
}

const initialState: ProjectDeliverableReasonCodesState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchReasonCodes = createAsyncThunk(
  'projectDeliverableReasonCodes/fetchReasonCodes',
  async () => {
    try {
      const response = await projectsService.getReasonCodes();
      return response.data as ProjectDeliverableReasonCode[];
    } catch (error) {
      throw new Error('Failed to fetch reason codes');
    }
  }
);

const projectDeliverableReasonCodesSlice = createSlice({
  name: "projectDeliverableReasonCodes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReasonCodes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReasonCodes.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchReasonCodes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Failed to fetch reason codes';
      });
  },
});

export default projectDeliverableReasonCodesSlice.reducer;