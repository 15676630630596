import { useEffect, useState } from 'react';
import { TableCustomized } from '@components/TableCustomized/TableCustomized';
import { Col, Row, Space, Badge, theme, Button, Pagination, ConfigProvider } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useProviderColor } from '@HOOKs/UseProviderColor';
import { useAppDispatch, useAppSelector } from '@store/store';
import { EmptyCellTable } from '@components/Utils/EmptyCellTable';
import { getCompanies } from '@store/slices/generic-company';
import { CompanyReferenceEnumListOptions } from '@models/enumerations/company-reference-enum.model';
import { ICompanyBindingExtended } from '@models/company-binding.model';
import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { CompanyGenericCreate } from './CompanyBinding/CompanyBindingCreate';
import { TERTIARY_TEXT_COLOR } from '@providers/ThemeProvider';
import useVisible from '@HOOKs/UseVisible';
import { useTranslation } from 'react-i18next';
import { CustomButtonText } from '@components/Utils/CustomButtonText';
import { CompanyGenericManageList } from './CompanyBinding/CompanyBindingManage';
import { useNavigate } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import { PAGINATION_SIZE_GENERIC_TABLE_SMALL, GENERIC_COMPANIES_SORTING_KEY } from '@constants/core';

const getColumns: (navigateToCompanyDetails: any) => ColumnsType<ICompanyBindingExtended | object> = navigateToCompanyDetails => [
  {
    title: '',
    width: 100,
    render: (company: ICompanyBindingExtended) => (
      <Button onClick={() => navigateToCompanyDetails(company)} type="primary" style={{ border: 'none' }} ghost>
        {
          <>
            View <RightOutlined style={{ fontSize: '10px' }} />
          </>
        }
      </Button>
    ),
  },
  {
    title: 'Name',
    render: (company: ICompanyBindingExtended) => <>{company?.company?.name || <EmptyCellTable />}</>,
  },
  {
    title: 'Role',
    render: (company: ICompanyBindingExtended) => <>{company?.companyRole?.description || <EmptyCellTable />}</>,
  },
  {
    title: 'Main Point of Contact',
    render: (company: ICompanyBindingExtended) => {
      const companyContact = company.companyContact;
      const renderText = `${companyContact ? `${companyContact?.firstName || ''} ${companyContact?.lastName || ''}` : ''}`;
      return !!renderText.trimStart() ? renderText : <EmptyCellTable />;
    },
  },
];

export interface ICompaniesTableProps {
  referenceId?: StringORNumber;
  referenceType: CompanyReferenceEnumListOptions;
  tableCustomizedProps?: React.ComponentPropsWithoutRef<typeof TableCustomized>;
}

export const CompaniesGenericTable = (props: ICompaniesTableProps) => {
  const { referenceId, referenceType, tableCustomizedProps } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: siteCompanies, loading, totalItems } = useAppSelector(state => state.GenericCompany[referenceType]);
  const [visibleCompanyCreateDrawer, toggleVisibleCompanyCreateDrawer] = useVisible();
  const [visibleCompanyManageDrawer, toggleVisibleCompanyManageDrawer] = useVisible();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const itemsPerPage = PAGINATION_SIZE_GENERIC_TABLE_SMALL;

  const navigateToCompanyDetails = (company: ICompanyBindingExtended) => {
    const urlToNavigate = `/companies/${company?.company?.id}`;
    navigate(`${urlToNavigate}`);
  };

  useEffect(() => {
    if (referenceId && referenceType) {
      dispatch(getCompanies({ referenceId, referenceType, page: pageNumber - 1, size: itemsPerPage, sort: GENERIC_COMPANIES_SORTING_KEY }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const {
    token: { colorPrimary, colorPrimaryBg, colorTextSecondary, fontSizeHeading5 },
  } = theme.useToken();
  const [colorTextTertiary] = useProviderColor(TERTIARY_TEXT_COLOR);

  // Header Table
  const Header = () => (
    <Row justify={'space-between'} align="middle" className="mb-12">
      <Col>
        <Space>
          <span
            className="font-semibold pl-10"
            style={{
              fontSize: fontSizeHeading5,
              color: colorTextTertiary,
            }}
          >
            {t('generic.companies')}
          </span>
          <Badge
            count={totalItems}
            size="small"
            className="ml-30 mr-30"
            style={{
              backgroundColor: colorPrimaryBg,
              outline: 'none',
              color: colorPrimary,
            }}
          />
        </Space>
      </Col>
      <Col>
        <Space>
          {totalItems > 0 && (
            <ConfigProvider theme={{ token: { borderRadius: 8, colorText: colorTextSecondary } }}>
              <Pagination
                simple
                className="mr-10"
                current={pageNumber}
                total={totalItems}
                pageSize={itemsPerPage}
                onChange={setPageNumber}
              />
            </ConfigProvider>
          )}
          <CustomButtonText onClick={toggleVisibleCompanyManageDrawer}>{t('generic.manage')}</CustomButtonText>
          <CustomButtonText onClick={toggleVisibleCompanyCreateDrawer}>{t('generic.add')}</CustomButtonText>
        </Space>
      </Col>
    </Row>
  );

  return (
    <>
      <TableCustomized
        loading={loading}
        rowKey="id"
        columns={getColumns(navigateToCompanyDetails)}
        dataSource={siteCompanies}
        titleOnEmptyTable={`There are no ${t('generic.companies')} added`}
        {...tableCustomizedProps}
        header={<Header />}
      />
      <Row>
        <CompanyGenericCreate
          open={visibleCompanyCreateDrawer}
          toggle={toggleVisibleCompanyCreateDrawer}
          referenceId={referenceId}
          referenceType={referenceType}
          page={pageNumber - 1}
          size={itemsPerPage}
          sort={GENERIC_COMPANIES_SORTING_KEY}
        />
        <CompanyGenericManageList
          open={visibleCompanyManageDrawer}
          toggle={toggleVisibleCompanyManageDrawer}
          referenceId={referenceId}
          referenceType={referenceType}
          page={pageNumber - 1}
          size={itemsPerPage}
          sort={GENERIC_COMPANIES_SORTING_KEY}
        />
      </Row>
    </>
  );
};
