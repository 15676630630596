import { getFirstItem } from "@shared/util/array-util";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Button, Col, Row, Select, theme } from "antd"
import { ConfigProvider } from 'antd';
import FormItem from "antd/es/form/FormItem";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { fetchReasonCodes } from '@store/slices/project-deliverable-reason-codes';
import { useResizeDetector } from "react-resize-detector";
import { applyChangesTimelineDeliverable, setLastRequested } from "@store/slices/project-deliverable-timeline";
import { getProjectDeliverableDetailById, getProjectDeliverablesData } from '@store/slices/project-deliverables';
import { useParams } from "react-router-dom";
import { IProjectDeliverable } from "@models/project-deliverable";



interface IReasonCodes {
    value: number;
    label: string;
}

const REASON_CODE_REQUIRED_STATUSES = Object.freeze({
    NOT_SET: "NOT_SET",
    REQUIRED: "REQUIRED",
    NOT_REQUIRED: "NOT_REQUIRED"
})


interface IEditTimeLineControlsProps {
    onClose: () => void,
    deliverable: IProjectDeliverable
}

export const EditTimeLineControls = ({ onClose, deliverable }: IEditTimeLineControlsProps) => {
    const dispatch = useAppDispatch()

    const [reasonRequired, setReasonRequired] = useState<"NOT_SET" | "REQUIRED" | "NOT_REQUIRED">("NOT_SET");
    const [selectedReason, setSelectedReason] = useState(null)
    const [reasonCodes, setReasonCodes] = useState<IReasonCodes[]>([])

    const { data: ReasonCodes } = useAppSelector((state) => state.projectDeliverableReasonCodesSlice);
    const { data: Timeline, lastRequested } = useAppSelector((state) => state.ProjectDeliverableTimeLine);

    const { projectId } = useParams<"projectId">();

    const { ref: footerTableRef } = useResizeDetector();
    const { token: { colorSuccess, colorWarning, colorBgContainer } } = theme.useToken();


    useEffect(() => {
        if (isEmpty(reasonCodes) && ReasonCodes.length) {
            let reasonCodesMapped = ReasonCodes.map((reason) => ({
                value: reason.id,
                label: reason.description
            }))
            setReasonCodes(reasonCodesMapped)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ReasonCodes])

    useEffect(() => {
        if (getFirstItem(Timeline.evaluation.timeline) && reasonRequired === REASON_CODE_REQUIRED_STATUSES.NOT_SET) {
            if (Timeline.evaluation.reasonRequired) {
                setReasonRequired(REASON_CODE_REQUIRED_STATUSES.REQUIRED)
                getReasonsForEvalution();
            }
            else setReasonRequired(REASON_CODE_REQUIRED_STATUSES.NOT_REQUIRED)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Timeline])


    const onReasonChange = (value) => {
        setSelectedReason(value);
    };

    const getReasonsForEvalution = async () => {
        if (isEmpty(reasonCodes) && isEmpty(ReasonCodes))
            dispatch(fetchReasonCodes())
    }


    const applyChanges = () => {
        const changedReason = ReasonCodes.find((reason) => reason.id === selectedReason)
        dispatch(setLastRequested({ ...lastRequested, requestBody: { ...lastRequested.requestBody, projectDeliverableReason: changedReason } }))
        dispatch(applyChangesTimelineDeliverable()).unwrap().then(() => {
            dispatch(getProjectDeliverablesData(String(projectId)));
            dispatch(getProjectDeliverableDetailById({ projectDeliverableId: String(deliverable.id) }));
        });
    }

    return (
        <Row ref={footerTableRef} style={{
            opacity: Timeline.evaluation?.timeline > 0 ? 0 : 1,
            boxShadow: `rgba(0, 0, 0, 0.1) 0px -3px 2px 1px`,
            backgroundColor: colorBgContainer,
            alignItems: "center",
            paddingLeft: "30px",
            paddingRight: "30px",
            width: "100%",
            position: 'absolute',
            bottom: 0,
            marginLeft: "-24px",
            marginRight: "-22px",
            paddingBottom: "30px"
        }}>
            <Col span={12} className='pt-30'>
                {reasonRequired === REASON_CODE_REQUIRED_STATUSES.REQUIRED &&
                    <>
                        <FormItem label="Reason For Change" rules={[{ required: true, message: "" }]} className="mb-0" />
                        <Select
                            placeholder="Select a Reason"
                            className='w-full'
                            options={reasonCodes}
                            value={selectedReason}
                            onSelect={onReasonChange}
                        />
                    </>}
            </Col>
            <Col span={12}>
                <div className='flex flex-col justify-end items-end pt-30'>
                    <ConfigProvider theme={{
                        token: {
                            colorPrimary: colorSuccess
                        }
                    }}>
                        {(!selectedReason && reasonRequired === REASON_CODE_REQUIRED_STATUSES.REQUIRED) && <Row justify="center">
                            <Col className="text-center text-xs mb-15 mt-5" style={{ color: colorWarning }}>
                                A reason is required to apply these changes
                            </Col>
                        </Row>}
                        <div className='flex'>
                            <Button className='mr-5' type="default" onClick={() => onClose()}>
                                Cancel
                            </Button>
                            <Button
                                disabled={reasonRequired === REASON_CODE_REQUIRED_STATUSES.REQUIRED ? !selectedReason : false}
                                type="primary" onClick={() => applyChanges()}>
                                Apply Changes
                            </Button>
                        </div>

                    </ConfigProvider>
                </div>
            </Col >
        </Row >
    )
}