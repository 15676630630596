
import { http } from '@infrastructure/http/axios.instance';
import { IPunchListItemCategory } from '@models/punch-list.model';

const path = 'punchlist-categories';

export const punchListCategoryRepository = {
  getPunchlistCategories: async (queryParams: any) => {
    const { sort = "asc" } = queryParams;

    const params = new URLSearchParams({
      ...sort && { sort },
      page: 0,
      size: 1000
    } as any)
    const requestUrl = `${path}?${params.toString()}`;

    const promiseGetAll = await http.get<IPunchListItemCategory[]>(requestUrl);
    return promiseGetAll;
  },
  create: async (entity: IPunchListItemCategory): Promise<IPunchListItemCategory> => {

    const requestUrl = `${path}`;
    const promiseCreate = await http.post<IPunchListItemCategory>(requestUrl, entity);
    return promiseCreate.data;
  },
  update: async (id: string | number, entity: IPunchListItemCategory): Promise<IPunchListItemCategory> => {

    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.put<IPunchListItemCategory>(requestUrl, entity);
    return promiseUpdate.data;
  },
}