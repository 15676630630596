/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { If } from "@components/Utils/Structural";
import { Image, Skeleton, theme } from "antd";
import { ShopOutlined } from "@ant-design/icons";
import { useAppSelector } from "@store/store";
import { isValidUrl } from "@shared/util/url-util";
import { configuration } from "@environments/env";
import { isStringEmpty } from "@shared/util/validations";

const ASPECT_RATIO_CONTAINER_IMG = 1 / 0.83;

const hasDefaultImage = Boolean(configuration?.DEFAULT_IMAGE_KEY_PHOTO && !isStringEmpty(configuration?.DEFAULT_IMAGE_KEY_PHOTO));

export const LocationKeyPhotoViewer = () => {   

    const { entity: locationEntity, loading } = useAppSelector((state) => state.Location);

    const [srcKeyPhoto, setSrcKeyPhoto] = useState<string>('');
    const [isKeyUrlImageWithError, setIsKeyUrlImageWithError] = useState<boolean>(false);
    const [isDefaultImageWithError, setIsDefaultImageWithError] = useState<boolean>(false);

    useLayoutEffect(() => {
        setSrcKeyPhoto('');
    }, []);

    useEffect(() => {
        setSrcKeyPhoto(locationEntity.locationKeyPhotoSrc || "");
    }, [locationEntity]);

    const container = useMemo(() => {
        const hasKeyPhotoActive = isValidUrl(srcKeyPhoto) && !isKeyUrlImageWithError;
        const hasDefaultImageActive = !hasKeyPhotoActive && hasDefaultImage && !isDefaultImageWithError;

        return (
            <div style={{ aspectRatio: ASPECT_RATIO_CONTAINER_IMG }} className="w-full">
                <If condition={loading}>
                    <LoadingCustomImageSkeleton active={loading} />
                </If>

                <If condition={!loading && hasKeyPhotoActive}>
                    <ImageKeyLocation srcKeyPhoto={srcKeyPhoto} onError={setIsKeyUrlImageWithError} />
                </If>

                <If condition={!loading && hasDefaultImageActive}>
                    <ImageKeyLocation srcKeyPhoto={configuration?.DEFAULT_IMAGE_KEY_PHOTO} onError={setIsDefaultImageWithError} />
                </If>  

                <If condition={!loading && isKeyUrlImageWithError && (!hasDefaultImage || isDefaultImageWithError) }>
                    <LoadingCustomImageSkeleton active={false} />
                </If>  
            </div>
        );
    }, [srcKeyPhoto, loading, isKeyUrlImageWithError, isDefaultImageWithError]);

    return container;
};

interface IImageKeyLocationProps {  
    srcKeyPhoto: string,
    onError?: (hasError: boolean) => void
}

export const ImageKeyLocation = (props : IImageKeyLocationProps) => {
    const { srcKeyPhoto = "", onError } = props;
    const { token: { colorBorder } } = theme.useToken();

    const onErrorImage = () => {
        onError?.(true);
    }

    return <Image
        className="w-full"
        src={srcKeyPhoto}
        onError={onErrorImage}
        style={{ 
            border: `1px solid ${colorBorder}`,
            borderRadius: 4,
            objectFit: "cover",
            maxWidth: "100%",
            aspectRatio: ASPECT_RATIO_CONTAINER_IMG
        }}
        preview={false}
    />
}

export const LoadingCustomImageSkeleton = (props: { active?: boolean }) => {
    const { active = false } = props;
    const { token } = theme.useToken()
    return (
        <Skeleton.Node active={active} className="relative w-full flex items-center" prefixCls="custom-skeleton" style={{ 
            border: `1px solid ${token.colorBorder}`,
            borderRadius: 4,
            objectFit: "cover",
            aspectRatio: ASPECT_RATIO_CONTAINER_IMG
        }}>
            <div className="w-full flex items-center justify-center">
                <ShopOutlined style={{ color: token.colorTextQuaternary, fontSize: '3.2rem' }}  />
            </div>
        </Skeleton.Node>
    )
}
