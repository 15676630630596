import axios from "axios";

interface ICleanDownloadFile {
  url: string;
  fileName: string;
}

export const cleanDownloadFile = (props: ICleanDownloadFile): Promise<void> => {
  const { url, fileName } = props;

  return axios
    .get(url, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      responseType: 'blob',
    })
    .then(response => {
      openBinaryFile(response.data, fileName, false);
    });
};

export const downloadUrl = (url: string) => {
  const newUrl = new URL(url);
  if (newUrl) {
    window.open(`${url}`, '_blank');
  }
};

export const openBinaryFile = (blob: Blob, fileName: string, openNewTab: boolean) => {
  const a = document.createElement('a');
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  if (openNewTab) {
    window.open(`${url}`, '_blank');
  }
};
