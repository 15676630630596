import React, { useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { Badge, Button, Checkbox, Col, ConfigProvider, Divider, Pagination, Row, Table, theme } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IProject } from '@models/project-model';
import { IProjectStatus } from '@models/project-status.model';
import { IProjectType } from '@models/project-type.model';
import { TERTIARY_TEXT_COLOR } from '@providers/ThemeProvider';
import { getEntities } from '@store/slices/projects';
import { AppDispatch, RootState } from '@store/store';
import { EmptyCellTable } from '@components/Utils/EmptyCellTable';
import { Can } from '@components/Permisions/Can';
import { useProviderColor } from '@HOOKs/UseProviderColor';
import { formatDatePipe } from '@shared/util/date-utils';
import { dateSorter, defaultSorter } from '@shared/util/table-utils';
import "@styles/components/table.less";
import { PlusOutlined, RightOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { LastCellHoverSelectionTableExtended } from '@components/Utils/Table/TableUtils';
import { useTranslation } from 'react-i18next';



const ProjectList: React.FC = () => {

    // Data
    const dispatch = useDispatch<AppDispatch>();
    const { entities: entityList, loading, totalItems } = useSelector((state: RootState) => state.Project);

    const { siteId } = useParams<"siteId">();

    // Actions
    const navigate = useNavigate();
    const openFormNewLocation = () => navigate("add-project")

    // Header Table, Theme
    const {
      token: {
        marginLG,
        colorPrimary,
        colorBgBase,
        colorBorderSecondary,
        colorFillSecondary,
        colorTextQuaternary,
        colorTextSecondary,
        fontSizeHeading5,
      },
    } = theme.useToken();
    const padding = marginLG * 1;
    const paddingBg = marginLG * 1;
    const [colorTextTertiary] = useProviderColor(TERTIARY_TEXT_COLOR);
    const paddingLeftAndRightCalculated = { paddingLeft: `${padding}px`, paddingRight: `${padding}px` };
    const paddingLeftAndRightCalculatedBg = { padding: `${padding}px ${paddingBg}px` };

    const PAGINATION_SIZE_PROJECTS_TABLE = 5;

    const [showArchived, setShowArchived] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(1);

    const { t } = useTranslation();

    const columns: ColumnsType<IProject> = [
      {
        title: '',
        dataIndex: 'id',
        width: '100px',
        className: 'border-left-in-hover',
        render: (text, record: IProject) => (
          <Button type="primary" size="small" style={{ border: 'none' }} ghost>
            {
              <>
                {t('generic.view')} <RightOutlined style={{ fontSize: '10px' }} />
              </>
            }
          </Button>
        ),
      },
      {
        title: t('project.projectNameNumber'),
        dataIndex: 'id',
        className: 'border-left-in-hover',
        sorter: ({ id: a }, { id: b }) => defaultSorter(a, b),
        render: (value, record) => {
          return (
            <>
              {record.nickname ? (
                <>
                  {record.nickname} <span style={{ color: colorTextQuaternary }}>{`(#${value})`}</span>
                </>
              ) : (
                `${t('generic.project')} #${value}`
              )}
            </>
          );
        },
      },
      {
        title: t('project.projectType'),
        dataIndex: 'projectType',
        render: (value: IProjectType) => {
          return (value && value?.name) || <EmptyCellTable />;
        },
        ellipsis: true,
      },
      {
        title: t('project.projectStatus'),
        dataIndex: 'projectStatus',
        render: (value: IProjectStatus) => {
          return (value && value?.name) || <EmptyCellTable />;
        },
        ellipsis: true,
      },
      {
        title: t('generic.createdDate'),
        dataIndex: 'createdDate',
        sorter: (a, b) => (a?.createdDate && b?.createdDate ? dateSorter(a.createdDate, b.createdDate) : 0),
        render: date => {
          return (
            <LastCellHoverSelectionTableExtended>
              {(date && formatDatePipe(date)) || <EmptyCellTable />}
            </LastCellHoverSelectionTableExtended>
          );
        },
        ellipsis: true,
      },
    ];

    useEffect(() => {
      const requestData = {
        'siteId.equals': `${siteId}`,
        sort: `createdDate,desc`,
        page: pageNumber - 1,
        size: PAGINATION_SIZE_PROJECTS_TABLE,
      };
      if (!showArchived) requestData['archive.equals'] = `${false}`;
      getTableData(requestData);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);

    const toggleArchiveProjectsVisibility = (e: CheckboxChangeEvent) => {
      const requestData = {
        'siteId.equals': `${siteId}`,
        sort: `createdDate,desc`,
        sortingKey: 'createdDate',
        page: pageNumber - 1,
        size: PAGINATION_SIZE_PROJECTS_TABLE,
      };
      if (!e.target.checked) requestData['archive.equals'] = false;
      getTableData(requestData);
      setShowArchived(Boolean(e.target.checked));
    };

    const onRowClick = (eventOnCLick: { event: React.MouseEvent<any, MouseEvent>; project: IProject }) => {
      const { event, project } = eventOnCLick;
      const urlToNavigate = project.id ? `project/${project.id}` : '';
      const urlToNavigateNewTab = project.id && window.location.href ? `${window.location.href}/project/${project.id}` : '';
      event.metaKey ? window.open(`${urlToNavigateNewTab}`) : navigate(`${urlToNavigate}`);
    };

    const getTableData = requestData => {
      dispatch(getEntities(requestData));
    };

    const Header = () => (
      <Row justify={'space-between'} align="middle" className="mb-30">
        <Col className="pt-3 pb-3">
          <Row style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            <span className="font-semibold pl-10" style={{ fontSize: fontSizeHeading5, color: colorTextTertiary }}>
              {t('generic.projects')}
            </span>
            <Badge
              count={totalItems}
              size="small"
              className="ml-20 mr-20"
              style={{ backgroundColor: colorPrimary, outline: 'none', color: 'primary', width: '30px' }}
            />
            <Divider type="vertical" className="ml-20" style={{ height: '24px' }} plain />
            <div className="ml-8">
              <Checkbox checked={showArchived} onChange={toggleArchiveProjectsVisibility}>
                {t('site.showArchivedProjects')}
              </Checkbox>
            </div>
          </Row>
        </Col>
        <Col>
          <Row align={'middle'}>
            {entityList.length ? (
              <ConfigProvider theme={{ token: { borderRadius: 8, colorText: colorTextSecondary } }}>
                <Pagination
                  simple
                  className="mr-10"
                  current={pageNumber}
                  total={totalItems}
                  pageSize={PAGINATION_SIZE_PROJECTS_TABLE}
                  onChange={setPageNumber}
                />
              </ConfigProvider>
            ) : (
              ''
            )}
            <Can I="CREATE" A="PROJECT">
              <Button onClick={openFormNewLocation} style={{ backgroundColor: colorPrimary, color: 'white' }} icon={<PlusOutlined />}>
                {t('site.newProject')}
              </Button>
            </Can>
          </Row>
        </Col>
      </Row>
    );

    return (
        <Row style={{ ...paddingLeftAndRightCalculated }} className='mb-30'>
            <div style={{ ...paddingLeftAndRightCalculatedBg, border: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgBase, width: "100%", borderRadius: 8 }}
            >
                <Header />
                <ConfigProvider theme={{ token: { borderRadius: 4 } }}>
                    <Table
                        className='mt-20 bordered-custom-table'
                        loading={loading}
                        rowClassName={"hover-in-row"}
                        pagination={false}
                        rowKey="id"
                        columns={columns as ColumnsType<IProject | object>}
                        dataSource={entityList}
                        onRow={(project: IProject, index) => ({
                            style: {
                                backgroundColor: project.archive ? colorFillSecondary : 'default',
                                color: project.archive ? colorTextSecondary : 'default',
                            },
                            onClick: (event) => onRowClick({ event, project })
                        })}
                    />
                </ConfigProvider>
            </div>

        </Row>
    )
};

export default ProjectList;