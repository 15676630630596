
import { isFulfilled, isPending } from '@reduxjs/toolkit';
import { IBrand, defaultValue } from "../../models/brand.model";
import { createEntitySlice, EntityState, serializeAxiosError } from "../reducer.util";
import { createAsyncThunk } from "@reduxjs/toolkit"
import { brandService } from "../../services/brands.service";
import { IQueryParams } from "../../models/pagination";
import { cleanEntity, entitiesSortedByStrings } from '../../shared/util/entity-utils';

export const getEntities = createAsyncThunk(
  'brand/fetch_entity_list', 
  async (queryParams: IQueryParams) => {
      return brandService.getBrands(queryParams);
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  'brand/fetch_entity',
  async (id: string | number) => {
    return brandService.getBrandById(id);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'brand/create_entity',
  async (entity: IBrand, thunkAPI) => {
    const result = await brandService.createBrand(cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'brand/update_entity',
  async (entity: IBrand, thunkAPI) => {
    const result = entity.id && await brandService.updateBrand(entity.id, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'brand/delete_entity',
  async (id: string | number, thunkAPI) => {
    const result = await brandService.deleteBrand(id);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

const initialState: EntityState<IBrand> = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

export const BrandSlice = createEntitySlice({
    name: 'brand',
    initialState,
    reducers: {
      clearEntity: (state) => {
        state.entity = defaultValue;
      },
    },
    extraReducers(builder) {
        builder
          .addCase(getEntity.fulfilled, (state, action) => {
            state.loading = false;
            state.entity = action.payload.data;
          })
          .addMatcher(isFulfilled(getEntities), (state, action) => {
            const { data, headers } = action.payload;
            const xTotalCount = headers['x-total-count'];
            const rawEntities = data.map((entity) => ({ 
              id: entity.id,
              description: entity?.description
            }))
            const sortedEntities = entitiesSortedByStrings(rawEntities, "description");

            return {
              ...state,
              loading: false,
              entities: sortedEntities,
              totalItems: xTotalCount ? parseInt(xTotalCount, 10) : data.length,
            };
          })
          .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
            state.updating = false;
            state.loading = false;
            state.updateSuccess = true;
            state.entity = action.payload as IBrand;
          })
          .addMatcher(isPending(getEntities, getEntity), state => {
            state.errorMessage = null;
            state.updateSuccess = false;
            state.loading = true;
          })
          .addMatcher(isPending(createEntity, updateEntity), state => {
            state.errorMessage = null;
            state.updateSuccess = false;
            state.updating = true;
          });
      }
  });
  
  export const { reset, clearEntity } = BrandSlice.actions;
  
  // Reducer
  export default BrandSlice.reducer;

  