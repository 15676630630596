/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useErrorAnimation } from "@HOOKs/UseErrorAnimation";
import { isFulfilled } from "@reduxjs/toolkit";
import { asyncLaunchNotification } from "@store/slices/notification";
import { createEntity } from "@store/slices/group-bindings";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Button, Drawer, Form, Select, Space } from "antd";
import { getUsers } from '@store/slices/users-extended';
import FormItem from "antd/es/form/FormItem";
interface IGropuUserAddProps {
  open: boolean,
  toogle: () => void,
  onModifiedChanges?: () => void,
  groupId: any
}

export const GroupUserAdd = (props: IGropuUserAddProps) => {

    const { open, toogle, onModifiedChanges, groupId } = props;

    const dispatch = useAppDispatch();

    const [form] = Form.useForm();

    const { usersList, loading } = useAppSelector((state) => state.UsersExtended);

    const [animateError, playAnimationOfError] = useErrorAnimation();

    const onFinishFailed = (errorInfo: any) => playAnimationOfError();

    const onFinish = (data: any): void => {
        if (data?.user) {
            dispatch(createEntity({ user: { id: data.user }, group: { id: groupId } }))
            .then(onSuccessAddUser)
        }
    };

    useEffect(() => {
        dispatch(getUsers());
    }, [dispatch])

    const onSuccessAddUser = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: `Changes saved`,
                  description: `User added successfully`
                }
            }))
            onModifiedChanges && onModifiedChanges();
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onClose = () => { 
        form.resetFields();
        toogle(); 
    }

    const onSubmit = () => form.submit();

    const options = usersList.map(elem => {
        return { label: elem.fullName, value: elem.id }
    })

    return (
        <>
            <Drawer 
                width={448} 
                title={`Add User`}
                placement="right" 
                onClose={onClose} 
                open={open}
                closable={false}
                extra={
                    <Space>
                      <Button type="primary" ghost onClick={onClose}>
                        Cancel
                      </Button>
                      <Button loading={loading} onClick={onSubmit} type="primary">
                        Add User
                      </Button>
                    </Space>
                  }
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className={`${animateError && "error-animation"}`}
                >

                  <FormItem name="user">
                    <Select
                        placeholder="Select User"
                        options={options}
                        showSearch
                        allowClear
                        filterOption={(inputValue, option) =>
                            option!.label!.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                    />
                    </FormItem>

                </Form>
            </Drawer>
        </>
  );
}
