export const LinkOffIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path d="M17 7H13V8.9H17C18.71 8.9 20.1 10.29 20.1 12C20.1 13.43 19.12 14.63 17.79 15L19.25 16.44C20.88 15.61 22 13.95 22 12C22 10.6739 21.4732 9.40215 20.5355 8.46447C19.5979 7.52678 18.3261 7 17 7ZM16 11H13.81L15.81 13H16V11ZM2 4.27L5.11 7.38C3.29 8.12 2 9.91 2 12C2 13.3261 2.52678 14.5979 3.46447 15.5355C4.40215 16.4732 5.67392 17 7 17H11V15.1H7C5.29 15.1 3.9 13.71 3.9 12C3.9 10.41 5.11 9.1 6.66 8.93L8.73 11H8V13H10.73L13 15.27V17H14.73L18.74 21L20 19.74L3.27 3L2 4.27Z" fill="#FFD591" />
    </svg>
  );
};
