import { Col, Form, Row, Select } from 'antd'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setProjectToCreate, setValidStep, Steps } from '../../../../store/slices/project-steps';
import { AppDispatch, useAppSelector } from '../../../../store/store';
import { contentStyleSteps } from '../AddProject';
import { getTemplates } from '../../../../store/slices/project-templates-extended';
import { IProjectTemplate } from '@models/project-template-model';

export const Step2 = () => {


  const dispatch = useDispatch<AppDispatch>();

  const [form] = Form.useForm();

  const [isAutoCompleteOpen, setIsAutoCompleteOpen] = useState(false);

  useEffect(() => {
    dispatch(getTemplates(''))
  }, [dispatch])

  const onFinish = (entity: any): void => {
    dispatch(setProjectToCreate({ templateId: entity.template }))
    dispatch(setValidStep({ step: Steps.STEP2, modification: { isValid: true } }))
  };

  const onFinishFailed = () => {
    dispatch(setValidStep({ step: Steps.STEP2, modification: { isValid: false } }))
  };

  const onSelectSubmit = (val: number, option: string) => {
    form.submit()
  }

  const { templatesList = [] } = useAppSelector((state) => state.ProjectTemplatesExtended);
  const { currentStep, projectToCreate } = useAppSelector((state) => state.ProjectSteps);

  const [options, setOptions] = useState<{ value?: number; label?: string; }[]>([])

  useEffect(() => {
    const templatesListCopied = [...templatesList];
    const sortedTemplatesList = templatesListCopied.sort((a: IProjectTemplate, b: IProjectTemplate) => {
      const nameA = a?.name || ''; 
      const nameB = b?.name || '';
    
      return String(nameA).localeCompare(String(nameB));
    });

    const options = sortedTemplatesList.filter(x => x.active).map(el => {
      return { value: el.id, label: el?.name };
    })

    setOptions(options);
  }, [templatesList]);

  useEffect(() => {
    if (currentStep === Steps.STEP2) {
      setTimeout(() => {
        setIsAutoCompleteOpen(true);
      }, 600);
    } else {
      setIsAutoCompleteOpen(false);
    }
  }, [currentStep])

  const clearOptionCallback = () => {
    dispatch(setProjectToCreate({ templateId: null }))
    dispatch(setValidStep({ step: Steps.STEP2, modification: { isValid: false } }))
  }

  return (
    <div className='ml-30 mr-30' style={contentStyleSteps}>
      <span className="flex text-bold pr-30 pt-20 pb-30" style={{ fontSize: '16px' }}>Choose your Template</span>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="template" rules={[
              { required: true, message: "" }
            ]}>
              <Select
                showSearch
                open={isAutoCompleteOpen && projectToCreate.templateId === null}
                allowClear={true}
                options={options}
                placeholder="Select Template"
                filterOption={(inputValue: string, option) =>
                  option!.label!.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                onClear={clearOptionCallback}
                onSelect={(val: any, option: any) => onSelectSubmit(val, option)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div>
              {/* TODO */}
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}


