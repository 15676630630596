import { SVGProps } from 'react';

type IProps = SVGProps<SVGSVGElement>;

export const ShapeGroupSvgIcon = (props: IProps) => {
  const { ...restProps } = props;

  return (
    <svg width="60" height="36" viewBox="0 0 60 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <rect x="0.5" y="0.5" width="59" height="35" rx="3.5" fill="#E6F7FF" />
      <rect x="0.5" y="0.5" width="59" height="35" rx="3.5" stroke="#BAE7FF" />
      <path
        d="M17 17V8H8V17M10 15V10H15V15M26 12.5C26 13.9 24.9 15 23.5 15C22.1 15 21 13.9 21 12.5C21 11.1 22.11 10 23.5 10C24.89 10 26 11.11 26 12.5ZM12.5 20L8 28H17M13.58 26H11.42L12.5 24.08M28 12.5C28 10 26 8 23.5 8C21 8 19 10 19 12.5C19 15 21 17 23.5 17C26 17 28 15 28 12.5ZM25 23V20H23V23H20V25H23V28H25V25H28V23H25Z"
        fill="#91D5FF"
      />
      <path d="M43.0107 20.6717L45.6824 18L43.0107 15.3225L43.8332 14.5L47.3332 18L43.8332 21.5L43.0107 20.6717Z" fill="#91D5FF" />
    </svg>
  );
};
