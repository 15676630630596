import { customFieldsRepository } from "../infrastructure/repositories/custom-fields.repository"

export const customFieldsService = {
  getCustomFieldsGroupsByLocationId: customFieldsRepository.getCustomFieldsGroupsByLocationId,
  getCustomFieldsBindingsByLocationId: customFieldsRepository.getCustomFieldBindingListOfLocation,
  createCustomFieldBinding: customFieldsRepository.createCustomFieldBinding,
  updateCustomFieldBinding: customFieldsRepository.updateCustomFieldBinding,
  getCustomFieldsData: customFieldsRepository.getCustomFieldsData,
  saveOrUpdateCustomFieldsGroup: customFieldsRepository.saveOrUpdateCustomFieldsGroup,
  reorder: customFieldsRepository.reorder,
  update: customFieldsRepository.update,
  create: customFieldsRepository.create,
  getCustomFieldsForGroup: customFieldsRepository.getCustomFieldsForGroup
}