
import { isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from "../reducer.util";
import { createAsyncThunk } from "@reduxjs/toolkit"
import { cleanEntity, getTotalItems } from '@shared/util/entity-utils';
import { punchListReasonService } from '@services/punch-list-reasons.service';
import { serializeGenericHandleError } from '@store/thunk.util';
import { IPunchListReason, defaultReasonValue } from '@models/punch-list.model';

const nameOfEntity = "punch-list-reason";
const service = punchListReasonService;

export const getPunchListReasons = createAsyncThunk(
  `${nameOfEntity}/fetch_entity_list`,
  async () => {
      return service.getPunchlistReasons()
  },
  { serializeError: serializeGenericHandleError }
);

export const createEntity = createAsyncThunk(
  `${nameOfEntity}/create_entity`,
  async (entity: IPunchListReason, thunkAPI) => {
    const result = await service.create(cleanEntity(entity) as IPunchListReason);
    thunkAPI.dispatch(getPunchListReasons());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  `${nameOfEntity}/update_entity`,
  async (entity: IPunchListReason, thunkAPI) => {
    const result = entity.id && await service.update(entity.id, cleanEntity(entity) as IPunchListReason);
    thunkAPI.dispatch(getPunchListReasons());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const reorderReasons = createAsyncThunk(
  `${nameOfEntity}/reorder`,
  async (ids: number[], thunkAPI) => {
    const result = await service.reorder(ids);
    thunkAPI.dispatch(getPunchListReasons());
    return result;
  },
  { serializeError: serializeGenericHandleError }
);

const initialState: EntityState<IPunchListReason> = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultReasonValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

export const PunchListReasonSlice = createEntitySlice({
    name: nameOfEntity,
    initialState,
    reducers: {
      clearEntity: (state,) => {
        state.entity = defaultReasonValue;
      },
    },
    extraReducers(builder) {
        builder
          .addMatcher(isFulfilled(getPunchListReasons), (state, action) => {
            const { data, headers } = action.payload;
            return {
              ...state,
              loading: false,
              entities: data,
              totalItems: getTotalItems(headers),
            };
          })
          .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
            state.updating = false;
            state.loading = false;
            state.updateSuccess = true;
            state.entity = action.payload as IPunchListReason;
          })
          .addMatcher(isPending(createEntity, updateEntity), state => {
            state.errorMessage = null;
            state.updateSuccess = false;
            state.updating = true;
          })
          .addMatcher(isPending(getPunchListReasons), state => {
            state.errorMessage = null;
            state.updateSuccess = false;
            state.loading = true;
          })
      }
  });
  
  // Reducer
  export default PunchListReasonSlice.reducer;
  