import { useProviderColor } from "@HOOKs/UseProviderColor";
import { FolderOpenOutlined, MessageOutlined, SelectOutlined } from "@ant-design/icons";
import { EmptyLabel } from "@components/Utils/EmptyLabel";
import { Divider, theme } from "antd";
import { Link } from "react-router-dom";
import { If } from '@components/Utils/Structural';
import { DeliverableStatusBadge } from "./DeliverableStatusBadge";
import { useBaseProjectTabUrl } from "../RouteBaseProjectTabs";
import { LIGHT_GREY_1 } from "@providers/ThemeProvider";

interface ITaskTooltipProps {
    taskRecord: any
}

export const TaskTooltip = (props: ITaskTooltipProps) => {

    const { token: { fontSize, colorText, colorPrimaryTextActive, colorPrimaryText } } = theme.useToken();

    const [colorGreyLight] = useProviderColor(LIGHT_GREY_1);

    const { taskRecord } = props;

    const { data } = taskRecord;

    const { validBaseUrl } = useBaseProjectTabUrl();
    
    return (
        <div className="flex flex-col pt-15 pb-15 pl-15 pr-15" style={{ backgroundColor: "var(--neutral-2)"}}>
            <div className="flex flex-row justify-between items-center w-full">
                <span
                    className="font-semibold" 
                    style={{
                    color: colorText,
                    fontSize: fontSize + 4
                }}>{taskRecord?.data?.name}</span>
                <DeliverableStatusBadge
                    className="ml-5"
                    statusLabel={taskRecord?.data?.extraData?.statusLabel}
                    status={taskRecord?.data?.extraData?.status} />
            </div>
            <Divider className="mt-8 mb-8" />
            <div className="mt-8 flex flex-row justify-between">
                <div>
                    <span className="font-semibold text-color-neutral-9">Owner:</span>
                    <span className="pl-5 font-normal">
                        {  data?.extraData?.owner || <EmptyLabel /> }
                    </span>
                </div>
            </div>
            <div className="mt-8 flex flex-row justify-between">
                <div>
                    <span className="font-semibold text-color-neutral-9">Discipline:</span>
                    <span className="pl-5">{ data?.extraData?.deliverable?.discipline?.name || <EmptyLabel /> }</span>
                </div>
            </div>
            <div className="flex flex-row justify-between items-center mt-20 pt-5">
                <div>
                    <SelectOutlined className="mr-5" style={{ color: colorPrimaryText }} />
                    <If condition={validBaseUrl && data?.extraData?.id}>
                        <Link to={`${validBaseUrl}/deliverables/${data?.extraData?.id}`} target="_blank" className="no-underline" style={{ color: colorPrimaryTextActive }}>Open in new tab</Link>
                    </If>
                </div>
                <div className="flex flex-row items-center justify-end">
                    <If condition={!!data?.extraData?.totalAttachments} >
                        <div>
                            <span className='mr-5'>{ data?.extraData?.totalAttachments }</span>
                            <FolderOpenOutlined style={{ color: colorGreyLight }} />
                        </div>
                    </If>
                    <If condition={!!data?.extraData?.totalComments} >
                        <div className='ml-20'>
                            <span className="mr-5">{ data?.extraData?.totalComments }</span>
                            <MessageOutlined style={{ color: colorGreyLight }} />
                        </div>
                    </If>
                </div>
            </div>
        </div>
    )
}