import { RootState } from '@store/store';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { isArrayWithValues } from '../../../shared/util/array-util';
import { WrapperGoogleMap } from '@components/Google/Map/WrapperGoogleMap';
import { ILocation } from '@models/location.model';

export const RecentOpeningsMap = () => {

    const { data: { recentOpenings: { list } } } = useSelector((state: RootState) => state.Dashboard);

    const [markersOptions, setMarkersOptions] = useState<google.maps.marker.AdvancedMarkerElementOptions[]>([])

    useEffect(() => {
        processMarkerOptions(list)
    }, [list])

    const processMarkerOptions = (list: ILocation[]) => {
        if (list && isArrayWithValues(list)) {
            const locationsWithCoordinates = list.filter((location) => (location?.address?.longitude && location?.address?.latitude))
            const markersPropsOptions = locationsWithCoordinates.map((location) => {
                return {
                    position: { lat: +location?.address?.latitude!, lng: +location.address?.longitude! }
                }
            })
            markersPropsOptions && setMarkersOptions(markersPropsOptions);
        }
    }

    const containerStyle = {
        width: '450px',
        height: '337.5px'
    };

    return (
        <div style={containerStyle} >
            <WrapperGoogleMap 
                markers={markersOptions}
            />
        </div>
    )
}
