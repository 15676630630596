import { ChevronRightSvgIcon } from "@components/Icons/ChevronRightSvgIcon";
import { EmptyCellTable } from "@components/Utils/EmptyCellTable";
import { ILocation } from '@models/location.model';
import { TableColumnsType, Tooltip, theme } from "antd";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";
import { ILOCATION_STATUS_MAP_KEYS, StatusCell } from '../LocationStatusTag';
import { IBrand } from "@models/brand.model";
import { IMarket } from "@models/market.model";
import { useState, useEffect } from 'react';
import { typeResizeCallbackData } from 'react-resizable';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelector } from '@store/store';
import { getEntities as getBrands } from '@store/slices/brand';

const LocationHashSymbollabel = () => {
  const { t } = useTranslation();
  return <span className="select-none">{t('generic.number')}</span>;
};

const LocationNameLabel = () => {
  const { t } = useTranslation();
  return <span className="select-none">{t('location.locationName')}</span>;
};

const StatusLabel = () => {
  const { t } = useTranslation();
  return <span className="select-none">{t('location.status')}</span>;
};

const BrandLabel = () => {
  const { t } = useTranslation();
  return <span className="select-none">{t('location.brand')}</span>;
};

const OpenDateLabel = () => {
  const { t } = useTranslation();
  return <span className="select-none">{t('location.openDate')}</span>;
};

const MarketLabel = () => {
  const { t } = useTranslation();
  return <span className="select-none">{t('location.market')}</span>;
};

const CityStateLabel = () => {
  const { t } = useTranslation();
  return <span className="select-none">{t('location.cityState')}</span>;
};

const ViewMoreCell = () => {
  const {
    token: { colorPrimaryText },
  } = theme.useToken();
  return (
    <div className="flex flex-row items-center select-none">
      <span className="ml-10" style={{ color: colorPrimaryText }}>
        View
      </span>
      <ChevronRightSvgIcon className="ml-10" style={{ color: colorPrimaryText }} />
    </div>
  );
};

export const initialColumns: ColumnsType<ILocation> = [
  {
    title: '',
    fixed: 'left',
    key: 'view',
    render: value => <ViewMoreCell />,
    width: 100,
  },
  {
    title: <LocationHashSymbollabel />,
    dataIndex: 'locationNumber',
    key: 'locationNumber',
    fixed: 'left',
    width: 158,
    render: value => <span className="select-none">{value || <EmptyCellTable />}</span>,
    sorter: true,
  },
  {
    title: <LocationNameLabel />,
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    ellipsis: {
      showTitle: false,
    },
    width: 300,
    render: value => (
      <Tooltip placement="top" title={value}>
        <span className="select-none">{value || <EmptyCellTable />}</span>
      </Tooltip>
    ),
    sorter: true,
  },
  {
    title: <StatusLabel />,
    width: 140,
    dataIndex: 'locationStatus',
    key: 'locationStatus',
    render: (value: ILOCATION_STATUS_MAP_KEYS) => {
      return (
        <div className="flex flex-row items-center">
          <StatusCell status={value} />
        </div>
      );
    },
  },
  {
    title: <BrandLabel />,
    dataIndex: 'brand',
    key: 'brand',
    render: (value: IBrand) => <span className="select-none">{value?.description || <EmptyCellTable />}</span>,
    ellipsis: true,
  },
  {
    title: <MarketLabel />,
    dataIndex: 'market',
    key: 'market',
    render: (value: IMarket) => <span className="select-none">{value?.description || <EmptyCellTable />}</span>,
    ellipsis: true,
  },
  {
    title: <OpenDateLabel />,
    dataIndex: 'openDate',
    key: 'openDate',
    ellipsis: true,
    render: value => <span className="select-none">{value || <EmptyCellTable />}</span>,
  },
  {
    title: <CityStateLabel />,
    dataIndex: '',
    key: 'cityState',
    ellipsis: true,
    render: (_: ILocation) => {
      const valueFomatted = _.city && _.stateProvince && `${_.city}, ${_.stateProvince}`;
      const returnedValue = valueFomatted ? valueFomatted : <EmptyCellTable />;
      return <span className="select-none">{returnedValue}</span>;
    },
  },
];

export const useLocationsColumns = () => {
  //for instances with singular brands we will not show the brand column
  const dispatch = useDispatch<AppDispatch>();
  const brands = useAppSelector(state => state.Brand.entities);
  useEffect(() => {
    if (brands.length === 0) {
      dispatch(getBrands({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [columns, setColumns] = useState<ColumnsType<ILocation>>([]);

  useEffect(() => {
    const filteredColumns = brands.length > 1 ? initialColumns : initialColumns.filter(column => column.key !== 'brand');
    setColumns(filteredColumns);
  }, [brands]);

  const handleResize =
    (index: number) =>
    (_: React.SyntheticEvent<Element>, { size }: typeResizeCallbackData) => {
      const newColumns = [...columns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width,
      };
      setColumns(newColumns);
    };

  const mergeColumns: TableColumnsType<ILocation> = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column: TableColumnsType<ILocation>[number]) => ({
      width: column.width,
      onResize: handleResize(index) as React.ReactEventHandler<any>,
    }),
  }));

  return { columns: mergeColumns };
};