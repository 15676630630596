import { SVGProps } from 'react';

type IProps = SVGProps<SVGSVGElement>;

export const ArrowUpRightSvgIcon = (props: IProps) => {
  const { ...restProps } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path d="M5 17.59L15.59 7H9V5H19V15H17V8.41L6.41 19L5 17.59Z" fill="#292D2D" fillOpacity="0.45" />
    </svg>
  );
};
