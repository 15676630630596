import { theme } from "antd";
import { useLayoutEffect, useState } from "react";
import { RecentOpeningsCard } from "./RecentOpenings/RecentOpeningsCard";
import { UpcomingOpeningsCard } from "./UpcomingOpenings/UpcomingOpeningsCard";
import { useLayoutContex } from '@providers/LayoutProvider';
import { MyUpcomingDeliverablesCard } from "./MyUpcommingDeliverables/MyUpcomingDeliverablesCard";
import { MyProjectsCard } from "./MyProjects/MyProjectsCard";
import { MyPendingApprovalCard } from './MyPendingApprovals/MyPendingApprovalCard';
import { MyPastDeliverablesCard } from './MyPastDeliverables/MyPastDeliverablesCard';

export const Dashboard = () => {

  const { token: { marginLG } } = theme.useToken();
  const [ heightContent, setHeightContent ] = useState(0);
  const { layoutSize } = useLayoutContex();

  useLayoutEffect(() => {
    setHeightContent(layoutSize?.layoutHeight)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutSize])
  
  return (
    <div className="overflow-y-auto pr-20 mr-10 pb-20" style={{ marginLeft: `${marginLG}px`, height: `${heightContent}px` }}>
      <MyProjectsCard />
      <MyPastDeliverablesCard />
      <MyUpcomingDeliverablesCard />
      <MyPendingApprovalCard />
      <UpcomingOpeningsCard />
      <RecentOpeningsCard />
    </div>
  );
}
