import { TabContainer } from "@components/Utils/TabContainer";
import { useParams } from "react-router-dom";
import { Stepper } from "./shared/Stepper/Stepper";

export const ChangeOrderPublish = () => {
    const { projectId } = useParams<"projectId">();

    return (
        <TabContainer>
            <Stepper projectId={Number(projectId)} operation="UPDATE_PUBLISH" />
        </TabContainer>
    );
}