import { ISetting, changeOrderCostGroupKey } from "@models/setting.model";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Card, Form, Select, Switch, theme } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getEntities as getSettings, updateEntity } from "@store/slices/setting";
import { getActiveEntities as getActiveCostGroupings } from "@store/slices/cost-grouping";
import { formatDatetimeWithAmPm } from "@shared/util/date-utils";

const { Option } = Select;

export const ChangeOrdersCostGroupCard = () => {

    const { token: { colorTextTertiary } } = theme.useToken();
    const { activeEntities: activeCostGroupings } = useAppSelector((state) => state.CostGrouping);
    const [changeOrderCostGroupSetting, setChangeOrderCostGroupSetting] = useState<ISetting | undefined>(undefined)
    const { entities: settings } = useAppSelector((state) => state.Setting);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [selectedOptionText, setSelectedOptionText] = useState<string>("")

    const { token: { colorPrimary, colorTextDisabled, colorBorderSecondary, colorBgBase } } = theme.useToken();

    const toggleEdit = () => {
        setIsEditing(!isEditing)
    };

    const loadData = () => {
        dispatch(getSettings());
        dispatch(getActiveCostGroupings())
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const array = settings?.filter(setting => setting.key === changeOrderCostGroupKey);
        if (array?.length > 0) {
            setChangeOrderCostGroupSetting(array[0])
            if (array[0].value) {
                const cg = activeCostGroupings.find(c => c.id?.toString() === array[0].value?.toString());
                if (cg) {
                    const cgLabel = `${cg.code} - ${cg.name}`
                    if (cgLabel !== selectedOptionText) {
                        setSelectedOptionText(cgLabel)
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [settings, activeCostGroupings])

    const onFinish = (value: any): void => {
        dispatch(updateEntity({ entity: { ...changeOrderCostGroupSetting, value }, onSuccess: loadData }))
    };

    if (!changeOrderCostGroupSetting) {
        return <></>
    }

    return <Card title={t("settings.changeOrderCostGroupLabel")} style={{ width: 400, marginBottom: '16px' }} extra={<div><span style={{ color: (isEditing ? colorPrimary : colorTextDisabled), marginRight: '8px', cursor: 'pointer' }} onClick={toggleEdit}>{isEditing ? 'Managing' : 'Manage'}</span><Switch checked={isEditing} onClick={toggleEdit} /></div>}>
            <div style={{ backgroundColor: colorBgBase, border: '1px solid ' + colorBorderSecondary, borderRadius: '4px', padding: '4px 8px', marginBottom: '4px' }}>
                {!isEditing && selectedOptionText}
                {isEditing && <Form
                    layout="horizontal"
                    form={form}
                    initialValues={{
                        costGroup: changeOrderCostGroupSetting?.value ? Number(changeOrderCostGroupSetting?.value) : null
                    }}
                    style={{ display: 'inline-block' }}
                >
                    <Form.Item name="costGroup" rules={[{ required: true, message: t("settings.changeOrderCostGroupRequired") }]}>
                        <Select
                            onChange={onFinish}
                            style={{ width: 270 }}
                        >
                            {[...activeCostGroupings].map((costGrouping) => (
                                <Option value={costGrouping.id} key={costGrouping.id}>
                                    {costGrouping.code} - {costGrouping.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>}
                {changeOrderCostGroupSetting.lastModifiedDate &&
                    <div className="text-xs" style={{ fontStyle: 'italic', color: colorTextTertiary }}>{t("settings.lastSaved")}: {formatDatetimeWithAmPm(changeOrderCostGroupSetting.lastModifiedDate)}</div>
                }
            </div>
        </Card>;
}