import { attachmentService } from "@services/attachment.service";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { useState } from "react";

interface IDownloadButtonProps extends React.ComponentPropsWithoutRef<typeof Button> {
    attachmentId: string,
    fileName: string
}

export const DownloadAttachmentButton = (props: IDownloadButtonProps) => {

    const { attachmentId, fileName, ...restProps } = props;

    const [loading, setLoading] = useState(false);

    const onClicDownload = () => {
        onDownloadAction(attachmentId, fileName)
    }

    const onDownloadAction = (attachmentId: string, fileName: string): Promise<void> => {
        setLoading(true);

        return attachmentService.getViewLink(attachmentId).then((response) => {
            if (response) window.open(`${response.data}`, "_blank");
        })
        .finally(() => setLoading(false));
    }

    return (
        <Tooltip placement="top" title={"Download"} >
            <Button
                size='small'
                className="h-full"
                type='link'
                onClick={onClicDownload}
                {...restProps}
            >
                {loading ? <LoadingOutlined /> : <DownloadOutlined />}
            </Button>
        </Tooltip>
    )
}
