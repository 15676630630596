/* eslint-disable react-hooks/exhaustive-deps */
import useVisible from "@HOOKs/UseVisible";
import { LockOutlined } from "@ant-design/icons";
import { ViewerRichText } from "@components/Comment/ViewerRichText";
import { ValidUntil } from "@components/Locations/Sites/Projects/ChangeOrdersTab/shared/ChangeOrderValidUntil";
import { EntryTime } from "@components/Timeline/EntryTime";
import { IChangeOrderRevision } from "@models/change-order-revision.model";
import { ITimelineEntry } from "@models/timeline.model";
import { changeOrderRevisionsService } from "@services/change-order-revisions.service";
import { formatFullNameMonth } from "@shared/util/date-utils";
import { asyncLaunchNotification } from "@store/slices/notification";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Button, Card, Col, Divider, Modal, Row, Tag, Tooltip, theme } from "antd";
import { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Approve } from "./Approve";
import { Reject } from "./Reject";
import { Resubmission } from "./Resubmission";

export interface IEntryBodyProps {
    value: ITimelineEntry
}

export const EntryBody = (props: IEntryBodyProps) => {
    const { value } = props;
    const { token: { colorBgContainer, colorTextTertiary, fontSizeSM, colorInfoBg } } = theme.useToken();
    const [jsonDetail, setJsonDetail] = useState<any>(undefined)
    const { t } = useTranslation();
    const pendingStatus = "Pending";
    const approvedStatus = "Approved";
    const rejectedStatus = "Rejected";
    const [reviewStatus, setReviewStatus] = useState<string>(pendingStatus)
    const [closed, setClosed] = useState<boolean>(false)
    const [primaryColor, setPrimaryColor] = useState<string | undefined>(undefined)
    const [secondaryColor, setSecondaryColor] = useState<string | undefined>(undefined)
    const [tertiaryColor, setTertiaryColor] = useState<string | undefined>(undefined)
    const [bgColor, setBgColor] = useState<string | undefined>(undefined)
    const [rejectDrawerOpen, toggleRejectDrawerOpen, setRejectDrawerOpen] = useVisible();
    const [askResubmissionDrawerOpen, toggleAskResubmissionDrawerOpen, setAskResubmissionDrawerOpen] = useVisible();
    const [approveDrawerOpen, toggleApproveDrawerOpen, setApproveDrawerOpen] = useVisible();
    const { entity: changeOrder } = useAppSelector((state) => state.ChangeOrder);
    const [revision, setRevision] = useState<IChangeOrderRevision | undefined>();
    const dispatch = useAppDispatch();
    const [detailOpen, toggleDetailOpen, setDetailOpen] = useVisible();


    useEffect(() => {
        setRevision(undefined);
    }, [])

    useEffect(() => {
        setJsonDetail(value.detail ? JSON.parse(value.detail) : undefined);
    }, [value])

    useLayoutEffect(() => {
        if (jsonDetail) {
            setReviewStatus(jsonDetail.reviewCompletedStatus ? jsonDetail.reviewCompletedStatus : pendingStatus)
            setClosed(jsonDetail.reviewCompletedStatus === rejectedStatus && !jsonDetail.resubmissionRequested ? true : false)
            changeOrderRevisionsService.getById(jsonDetail.changeOrderRevisionId).then((response) => {
                setRevision(response.data)
            }).catch((error) => {
                onErrorDefault();
            });
        }
    }, [jsonDetail])

    const onErrorDefault = () => {
        dispatch(asyncLaunchNotification({
            type: "error",
            config: {
                message: t("changeOrder.changeOrder"),
                description: t("generic.defaultError")
            }
        }));
    }

    useLayoutEffect(() => {
        let pColor = "#FA8C16";
        let sColor = "#FFE7BA";
        let tColor = "#FFF7E6";
        switch (reviewStatus) {
            case pendingStatus:
                pColor = "#FA8C16";
                sColor = "#FFE7BA";
                tColor = "#FFF7E6";
                break;
            case approvedStatus:
                pColor = "#237804";
                sColor = "#B7EB8F";
                tColor = "#F6FFED";
                break;
            case rejectedStatus:
                pColor = "#A8071A";
                sColor = "#FFCCC7";
                tColor = closed ? "#FFCCC7" : "#FFF1F0";
                break;
        }
        setPrimaryColor(pColor);
        setSecondaryColor(sColor);
        setTertiaryColor(tColor);
    }, [reviewStatus, closed])

    useLayoutEffect(() => {
        if (closed === true) {
            setBgColor("#FFF1F0");
        } else {
            setBgColor(colorBgContainer);
        }
    }, [closed])

    const rejectClick = () => {
        setRejectDrawerOpen(true);
    }

    const resubmissionClick = () => {
        setAskResubmissionDrawerOpen(true);
    }

    const approveClick = () => {
        setApproveDrawerOpen(true);
    }

    const descriptionView = () => {
        return (<>
            {(revision?.description && <ViewerRichText payload={revision?.description} />)}
        </>);
    }

    const detailHeader = (
        <Row className="flex flex-row items-center pb-10">
            <Col span={18}>
                <div>{t("changeOrder.estimate")}</div>
                <div style={{ color: colorTextTertiary, fontSize: fontSizeSM }}>{jsonDetail?.reviewRequestedDate ? formatFullNameMonth(jsonDetail.reviewRequestedDate) : null}</div>
            </Col>
            <Col span={6}>
                <Tag style={{ color: primaryColor, backgroundColor: tertiaryColor, borderColor: secondaryColor }}>{reviewStatus}</Tag>
            </Col>
        </Row>
    )

    return (<>
        <Row className="w-full pt-10 pr-10 pl-10 pb-10" style={{ backgroundColor: bgColor, border: '1px solid', borderColor: primaryColor, borderRadius: '4px' }}>
            <Col span={24}>
                <Row>
                    <Col span={17} className="font-bold">{closed ? <span><LockOutlined style={{ color: primaryColor }} /> {t("changeOrder.closed")}</span> : t("changeOrder.initialReview")}</Col>
                    <Col span={7} className="text-right">{reviewStatus === pendingStatus ?
                        <ValidUntil date={changeOrder?.dueDate} style={{ color: colorTextTertiary, fontSize: 12 }} />
                        :
                        <EntryTime date={value.date} />}</Col>
                </Row>
            </Col>
            <Col span={12}>
                <Tag style={{ color: primaryColor, backgroundColor: tertiaryColor, borderColor: secondaryColor }}>{reviewStatus}</Tag>
            </Col>
            {revision &&
                <Col span={12} className="text-right">
                    <Button type="link" style={{ paddingBottom: 0, paddingTop: 0 }} onClick={() => setDetailOpen(true)}>{t("changeOrder.viewEstimate")}</Button>
                </Col>
            }
            {reviewStatus === pendingStatus && <Col span={24}>
                <Row>
                    <Divider style={{ margin: '10px 0px 10px 0px' }} />
                    <Col span={6}><Button type="link" danger onClick={rejectClick}>{t("changeOrder.rejectButton")}</Button></Col>
                    <Col span={18} className="text-right">
                        <Button className="mr-10" onClick={resubmissionClick}>{t("changeOrder.requestResubmissionButton")}</Button>
                        <Button type="primary" onClick={approveClick}>{t("changeOrder.approveButton")}</Button>
                    </Col>
                </Row>
            </Col>}
        </Row>
        <Modal
            title={detailHeader}
            footer={null}
            centered
            open={detailOpen}
            closable={true}
            forceRender
            onCancel={toggleDetailOpen}
        >
            <Row>
                <Col>
                    {descriptionView()}
                </Col>
            </Row>
            <Row className="mt-10 ml-5 mr-5">
                <Col span={24}>
                    <Card
                        bodyStyle={{ paddingLeft: 12, paddingRight: 12, paddingTop: 8, paddingBottom: 8 }}
                        style={{ border: 0, borderRadius: '4px', backgroundColor: colorInfoBg }}>
                        <Row className="w-full">
                            <Col>
                                <span className="font-bold">{t("changeOrder.estimatedCostLabel")}:</span>
                                <span className="ml-10">
                                    <Tooltip title={`${revision?.estimatedCost ? parseFloat(revision?.estimatedCost) : ''} ${revision?.currency?.name || ''}`}>
                                        {revision?.currency?.symbol} {revision?.estimatedCost ? parseFloat(revision?.estimatedCost!).toFixed(2) : ''}
                                    </Tooltip>
                                </span>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Modal>
        {(jsonDetail?.reviewCompletedStatus === undefined || jsonDetail.reviewCompletedStatus === pendingStatus) &&
            <>
                <Reject open={rejectDrawerOpen} toggle={toggleRejectDrawerOpen} />
                <Resubmission open={askResubmissionDrawerOpen} toggle={toggleAskResubmissionDrawerOpen} />
                <Approve open={approveDrawerOpen} toggle={toggleApproveDrawerOpen} />
            </>
        }
    </>
    );
}
