import ProjectList from '../Projects/ProjectList';
import { SiteOverviewHeaderTab } from './SiteOverviewHeaderTab';
import { TabContainer } from '@components/Utils/TabContainer';
import { SiteOverviewCardEntities } from './SiteOverviewCardEntities';
import { CustomFieldsGenericList } from '@components/CustomFieldsGeneric/CustomFieldsGenericList';
import { useParams } from 'react-router-dom';

export const SiteOverviewTab = () => {  

    const { siteId } = useParams<"siteId">();
    
    return (
        <TabContainer>
            <SiteOverviewHeaderTab />
            <ProjectList />
            <SiteOverviewCardEntities />
            <CustomFieldsGenericList referenceId={siteId} referenceType={"SITE"} />
        </TabContainer>
    )
}
