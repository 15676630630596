import { PhotoAlbumTymeEnum } from "@models/enumerations/photo-album-type.enum";
import { createContext, useContext } from "react";

interface IPhotoAlbumContext {
    referenceId: string | number;
    referenceType: PhotoAlbumTymeEnum, 
    entityName: string | React.ReactNode;
}

export const PhotoAlbumContext = createContext({} as IPhotoAlbumContext);

interface IPhotoAlbumContextProviderProps {
    referenceId: string | number;
    entityName: string | React.ReactNode;
    referenceType: PhotoAlbumTymeEnum, 
    children: React.ReactNode
}

export const PhotoAlbumContextProvider = (props: IPhotoAlbumContextProviderProps) => {

    const { children, referenceId, entityName, referenceType } = props

    const value = {
        referenceId,
        entityName,
        referenceType
    }

    return (
        <PhotoAlbumContext.Provider value={value}>{children}</PhotoAlbumContext.Provider>
    )
};

export const usePhotoAlbumContextProvider = () => {
    return useContext(PhotoAlbumContext);
};