import { If } from '@components/Utils/Structural';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getEntity } from '@store/slices/projects';
import { Button, Col, Divider, Row, theme, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateNickNameForProject } from '@store/slices/project-deliverable-timeline';
import { EmptyCellTable } from '@components/Utils/EmptyCellTable';
import { asyncLaunchNotification } from '@store/slices/notification';

const ProjectNickname = () => {
  const {
    token: {
      fontSizeHeading3,
      colorBorderSecondary,
      colorTextSecondary,
      colorTextTertiary,
      colorFillSecondary,
      colorBgContainer,
      fontSize,
    },
  } = theme.useToken();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [isManaging, setIsManaging] = useState<boolean>(false);

  const { entity: projectEntity } = useAppSelector(state => state.Project);

  const [nickName, setNickName] = useState<string>('');

  useEffect(() => {
    if (projectEntity && projectEntity.nickname) setNickName(projectEntity.nickname);
  }, [projectEntity]);

  const toggleManage = () => {
    setIsManaging(!isManaging);
  };

  const confirmUpdateNickname = () => {
    dispatch(updateNickNameForProject({ payload: { id: projectEntity.id, nickname: nickName ? nickName : null }, onError })).then(() => {
      setIsManaging(false);
      dispatch(getEntity(String(projectEntity.id)));
      setNickName('');
    });
  };

  const onError = (error: unknown) => {
    dispatch(asyncLaunchNotification({
      type: "error",
      config: {
          message: `Project Nickname`,
          description: `${t('projectSettings.nicknameUpdateFailed')}`
      }
  }));
  };

  return (
    <div
      className={`block sticky top-0 mb-20`}
      style={{ border: `1.2px solid ${colorBorderSecondary}`, borderRadius: 10, background: colorBgContainer, zIndex: 10, padding: 10 }}
    >
      <Row justify={'space-between'} align="middle" style={{ paddingTop: 10 }}>
        <Col className="pt-3 pb-3">
          <span className="font-semibold pl-15" style={{ fontSize: fontSizeHeading3, color: colorTextSecondary }}>
            {`${t('projectSettings.projectNickName')}`}
          </span>
        </Col>
      </Row>
      <Divider className="mt-8 mb-8" style={{ borderColor: colorFillSecondary }} />
      <Row className="flex flex-row items-center justify-between">
        <If condition={!isManaging && Boolean(projectEntity.projectType?.name)}>
          <>
            <If condition={Boolean(projectEntity?.projectType?.name)}>
              <Row className="mb-10 mt-10 flex-col align-end ml-5">
                <div className="flex flex-col pl-10" style={{ color: colorTextTertiary, fontSize: fontSize + 3 }}>
                  <If condition={Boolean(projectEntity.nickname)}>
                    <>{projectEntity?.nickname ? <>{projectEntity?.nickname}</> : ''}</>
                  </If>
                  <If condition={!Boolean(projectEntity.nickname)}>
                    <EmptyCellTable />
                  </If>
                </div>
              </Row>
            </If>
          </>
        </If>
        <If condition={isManaging}>
          <Row className="mt-3">
            <div className="pt-3 mb-3 mr-5 mb-10 mt-10 cursor-pointer" style={{ flex: 1, borderRadius: '4px', padding: '5px' }}>
              <Input
                style={{ width: 300 }}
                onChange={e => {
                  setNickName(e.target.value);
                }}
                placeholder={`${t(`projectSettings.nickname`)}`}
                value={nickName}
              />
            </div>
          </Row>
        </If>
        <Row className="flex items-center">
          <If condition={!isManaging}>
            <Button type="primary" onClick={toggleManage}>
              {`${t(`generic.manage`)}`}
            </Button>
          </If>
          <If condition={isManaging}>
            <>
              <Button className="mr-5" onClick={toggleManage}>
                {`${t(`generic.cancel`)}`}
              </Button>
              <Button type="primary" onClick={confirmUpdateNickname}>
              {`${t(`generic.save`)}`}
              </Button>
            </>
          </If>
        </Row>
      </Row>
    </div>
  );
};

export default ProjectNickname;
