import { ICurrency } from '@models/currency.model';
import { http } from '../http/axios.instance';

const path = 'currencies';

export const currencyRepository = {
    getActiveCurrencies: async () => {
        const requestUrl = `${path}/active?cacheBuster=${new Date().getTime()}`;

        const promiseGetAll = await http.get<ICurrency[]>(requestUrl);
        return promiseGetAll;
    }
}