import { BackButton } from "@components/Utils/BackButton";
import { TabContainer } from "@components/Utils/TabContainer";
import { FeatureFlagOptionEnum } from "@models/enumerations/feature-flag-option.enum";
import { useFeatureFlagsProvider } from "@providers/FeatureFlagsProvider";
import { useLayoutContex } from "@providers/LayoutProvider";
import { Row, Col, theme } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CategoriesCard } from "./PunchList/CategoriesCard";
import { StatusCard } from "./PunchList/StatusCard";
import { ReasonCard } from "./PunchList/ReasonCard";

export const PunchListAdminTab = (props: any) => {

    const { setHeaderLabelEntityState } = useLayoutContex()
    const { t } = useTranslation();
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { flags, isFlagEnabled } = useFeatureFlagsProvider();
    const isPunchListEnabled = isFlagEnabled(FeatureFlagOptionEnum.PUNCHLIST);
    
    useEffect(() => {
        setHeaderLabelEntityState(t("punchList.punchList"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const navigate = useNavigate();
    const { token: { colorBorderSecondary, colorBgContainer } } = theme.useToken();

    const navigateBack = () => {
        navigate('/admin/module-configuration')
    }

    if (!isPunchListEnabled) {
        return <></>
    }

    return (
        <>
          <TabContainer>
                <div className="flex flex-col overflow-auto">
                    <Row>
                        <Col span={24}>
                            <div className="flex flex-row items-center pl-10 pt-11 pb-11" style={{ borderTop: `1px solid ${colorBorderSecondary}`, borderBottom: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                                <BackButton onClick={navigateBack} >{t("admin.moduleConfiguration")}</BackButton>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div style={{ padding: '16px 24px' }}>
                    <CategoriesCard />
                    <StatusCard />
                    <ReasonCard />
                </div>
            </TabContainer>
        </>
    );
}