import { useEffect, useRef, useState } from "react";
import { Button, Carousel, Col, Descriptions, Divider, Row, Tag, Modal, Popconfirm } from "antd";
import ConfigProvider from "antd/es/config-provider";
import { CarouselRef } from "antd/es/carousel";
import { AppDispatch, useAppSelector } from "../../../store/store";
import { Steps as StepsProject, nextStep, prevStep, setSiteId, asyncFinishProcessAddProject, reset } from "../../../store/slices/project-steps";
import { useDispatch } from 'react-redux';
import { Step1 } from "./Steps/Step-1";
import { Step2 } from "./Steps/Step-2";
import { ArrowBack } from "../../Icons/ArrowBack";
import { CloseOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { Step3 } from "./Steps/Step-3";
import { getEntities } from '@store/slices/projects';
import { getPermisions } from '../../../store/slices/permisions';

export const contentStyleSteps: React.CSSProperties = {
  minHeight: "510px",
};

const AddProject = () => {

  const dispatch = useDispatch<AppDispatch>();

  const projectSteps = useAppSelector(state => state.ProjectSteps);

  const [openModal, setOpenModal] = useState(true);

  const navigate = useNavigate();

  const { id: locationId } = useParams<"id">();
  const { siteId } = useParams<"siteId">();

  const [formSubmitting, setFormSubmitting] = useState(false);


  useEffect(() => {
    dispatch(setSiteId(siteId))
  }, [dispatch, siteId])

  const goToProject = (id: number | string) => {
    navigate(`/location/${locationId}/sites/${siteId}/project/${id}`);
    dispatch(getPermisions())
    refreshProjectList();
  }

  const onNextStep = () => {
    if (projectSteps.isLastStep) {
      if (formSubmitting) return;

      setFormSubmitting(true);
      dispatch(asyncFinishProcessAddProject({ goToProject })).finally(() => {
        setFormSubmitting(false);
      });
    } else {
      dispatch(nextStep())
    }
  };

  const refreshProjectList = () => {
    dispatch(
      getEntities({
        "siteId.equals": `${siteId}`
      })
    );
  }

  const goPrevStep = () => {
    if (projectSteps.isInProcess) {
      dispatch(prevStep());
    }
  };

  const onCloseModal = () => {
    navigate(-1);
    setOpenModal(false);
    dispatch(reset());
  }

  const header = (
    <>
      <Row justify="space-evenly" className="pt-20 pb-10" >
        <Col flex="4rem" className="flex items-center justify-start">
          {projectSteps.isInProcess && <ArrowBack onClick={goPrevStep} className="ml-30" />}
        </Col>
        <Col flex={"auto"} className="flex items-center justify-center">
          <span style={{ fontSize: '16px', fontWeight: 400 }}>New Project</span>
        </Col>
        <Col flex="4rem" className="flex items-center justify-end">
          <Popconfirm
            title={<>Are you sure to cancel,<br />input data will be lost</>}
            okText="Yes"
            onConfirm={onCloseModal}
            cancelText="No"
          >
            <CloseOutlined className="mr-30" />
          </Popconfirm>
        </Col>
      </Row>
      <Divider className="mt-10 mb-10" />
    </>
  );

  const [disabledButtonContinue, setDisabledButtonContinue] = useState(true)

  useEffect(() => {
    if (projectSteps.currentStep === 1) {
      setDisabledButtonContinue(!projectSteps?.steps[StepsProject.STEP1].isValid)
    }
    if (projectSteps.currentStep === 2) {
      setDisabledButtonContinue(!projectSteps?.steps[StepsProject.STEP2].isValid)
    }
    carrouselSteps?.current?.goTo!(projectSteps.currentStep - 1);
  }, [projectSteps])

  const footer = (
    <>
      <Divider className="mt-0 mb-0" />
      <div className="flex flex-row items-center justify-between pt-15 pb-15 pl-30 pr-30">
        <div>
          {(projectSteps.lastStep > 1) && `Step ${projectSteps.currentStep} of ${projectSteps.lastStep}`}
        </div>
        <Button onClick={onNextStep} type="primary" className="pl-30 pr-30" disabled={disabledButtonContinue}>
          {projectSteps.isLastStep ? `Create Project` : `Continue`}
        </Button>
      </div>
    </>
  );

  const carrouselSteps = useRef({} as CarouselRef);

  const { projectToCreate } = useAppSelector((state) => state.ProjectSteps);
  const { entities: projectTypes } = useAppSelector((state) => state.ProjectTypes);
  const { entities: projectStatuses } = useAppSelector((state) => state.ProjectStatuses);
  const { templatesList } = useAppSelector((state) => state.ProjectTemplatesExtended);
  const { rolesList } = useAppSelector((state) => state.ProjectTemplatesExtended);
  const { usersList } = useAppSelector((state) => state.UsersExtended);

  return (
    <ConfigProvider
      theme={{
        // token: {
        //   fontFamily: 'Nunito Sans'
        // },
        components: {
          Modal: {
            borderRadius: 8,
            borderRadiusLG: 8,
            borderRadiusSM: 8,
            borderRadiusXS: 8,
            paddingContentHorizontalLG: 0,
            paddingMD: 0,
            paddingLG: 0,
            paddingXS: 0,
            padding: 0,
          },
        },
      }}
    >
      <Modal
        title={header}
        footer={footer}
        centered
        open={openModal}
        closable={false}
        destroyOnClose={true}
      >
        <Carousel
          ref={(c) => {
            //@ts-ignore
            if (c) { carrouselSteps.current = c; }
          }}
          dots={false}
          adaptiveHeight={true}
          effect="scrollx"
          touchMove={false}
          pauseOnFocus={true}
          infinite={false}
          lazyLoad={"progressive"}
        >
          <div>
            <Step1 />
          </div>
          <div>
            <Step2 />
          </div>
          <div>
            <Step3 />
          </div>
          <div>
            <div style={contentStyleSteps}>

              <Row gutter={16} className="pl-30 pr-30">
                <Col span={24}>
                  <Descriptions column={1} title="Review Project Settings">
                    <Descriptions.Item label="Status"><Tag >{projectStatuses.find(it => it.id === projectToCreate.projectStatusId)?.name}</Tag></Descriptions.Item>
                    <Descriptions.Item label="Type"><Tag >{projectTypes.find(it => it.id === projectToCreate.projectTypeId)?.name}</Tag></Descriptions.Item>
                    <Descriptions.Item label="Template"><Tag >{templatesList.find(it => it.id === projectToCreate.templateId)?.name}</Tag></Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
              <Row gutter={16} className="pl-30 pr-30">
                <Col span={24}>
                  <Descriptions column={1} title="Review Team">
                    {
                      projectToCreate.roleBindingList.map((item, key) =>
                        <Descriptions.Item key={key} label={rolesList.find(it => it.id === item.role.id)?.name}>
                          <Tag >{usersList.find(it => it.id === item.user.id)?.fullName}</Tag>
                        </Descriptions.Item>)
                    }
                  </Descriptions>
                </Col>
              </Row>
            </div>
          </div>
        </Carousel>
      </Modal>
    </ConfigProvider>
  );
};

export default AddProject;
