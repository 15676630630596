import { STORAGE_LOGIN_REDIRECT_KEY } from "@constants/core";
import { useState } from "react";

export const useLocalStorage = (keyName: string, defaultValue: any) => {
    
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        return JSON.parse(value);
      } else {
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue: any) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {}
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};

export const setLocalStorageValue = (keyName: string, newValue: any) => {
  try {
    window.localStorage.setItem(keyName, JSON.stringify(newValue));
  } catch (err) {}
};

export const getLocalStorageKey = (keyName: string, defaultValue: any) => {
  try {
    const value = window.localStorage.getItem(keyName);
    if (value) {
      return JSON.parse(value);
    } else {
      return defaultValue;
    }
  } catch (err) {
    return defaultValue;
  }
};

export const redirectIfExistParam = (navigate: any, shouldRedirectHome: boolean) => {
  const loginRedirectUrl = getLocalStorageKey(STORAGE_LOGIN_REDIRECT_KEY, null);
  setLocalStorageValue(STORAGE_LOGIN_REDIRECT_KEY, null);
  if (!shouldRedirectHome) {
    if (!loginRedirectUrl) {
      return;
    }
    const redirectUrl = decodeURIComponent(loginRedirectUrl);
    navigate(redirectUrl);
  } else {
    const redirectUrl = loginRedirectUrl ? decodeURIComponent(loginRedirectUrl) : null;
    redirectUrl ? navigate(redirectUrl) : navigate("/");
  }
}
