import { UUID } from "crypto";
export interface IAsset {
  id: UUID;
  label: string;
  type: AssetType;
  x: number;
  y: number;
  color?: string;
  rotationAngle: number;
  width?: number;
  height?: number;
  imageUrl?: string;
  opacity?: number;
}

export interface IFloorplanConfig {
  backgroundColor?: string;
  backgroundImage?: string;
  defaultAssetColor?: string;
  gridPatternConfig?: IGridPatternConfig;
  dimensionsConfig?: IDimensionsConfig;
  allowMultipleAdd?: boolean;
  allowSingleSelection?: boolean;
  showFloorplanControls?: IFloorplanControls;
  allowedAssetTypes?: AssetType[];
  markerRadiusMeters?: number;
  strokeConfig?: IStrokeConfig;
  measurementUnits?: MeasurementUnits;
  allowPlaceholder?: boolean;
}
export interface IFloorplanCallbacks {
  onAssetAdded?: (asset: IAsset) => void;
  onFloorplanCreated?: (
    initialAssets: IAsset[],
    floorplanConfig?: IFloorplanConfig
  ) => void;
  onAssetUpdated?: (asset: IAsset) => void;
  onAssetDeleted?: (asset: IAsset) => void;
  onAssetSelectionChanged?: (assets: IAsset[] | null) => void;
  onAssetSimpleClick?: (asset: IAsset) => void;
  onAssetDragEnd?: (asset: IAsset) => void;
  onMeasurementStop?: () => void;
}
export interface IGridPatternConfig {
  size: number;
  color?: string;
}
export interface IDimensionsConfig {
  pixelsPerMeter: number;
  officeWidth: number;
  officeHeight: number;
}
export interface IStrokeConfig {
  width?: number;
  color?: string;
}
export interface IFloorplanControls {
  showMinimap?: boolean;
  showZoomSlider?: boolean;
  showResetButton?: boolean;
  showClearButton?: boolean;
  showDeleteButton?: boolean;
  showDuplicateButton?: boolean;
  showRotateButtons?: boolean;
  showMoveButtons?: boolean;
}

export enum AssetType {
  DESK = "DESK",
  CHAIR = "CHAIR",
  MARKER = "MARKER",
}

export enum Direction {
  UP = "UP",
  DOWN = "DOWN",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum MeasurementUnits {
  METERS = "METERS",
  FEETSANDINCHES = "FEETS_AND_INCHES",
}

export interface ZoomEvent {
  transform: ZoomEventTransform;
}
export interface ZoomEventTransform {
  k: number;
  x: number;
  y: number;
}

export interface IdAndColor {
  id: UUID;
  color: string;
}
