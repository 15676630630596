import { GalleryContainer } from "../GalleryGrid/GalleryContainer";
import { DraggerZoneContainer } from "./DraggerZoneContainer";

interface IAlbumGalleryProps {}

export const AlbumGallery = (props: IAlbumGalleryProps) => {

    return (
      <>
        <DraggerZoneContainer />
        <GalleryContainer />
      </>
    )
}
