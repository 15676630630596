import { ArrowLeftOutlined, RightOutlined } from "@ant-design/icons";
import { theme } from "antd";
import { useRef } from "react";
import useHover from "../../HOOKs/UseHover";

export const RightOutlinedIcon = (props: React.ComponentPropsWithoutRef<typeof ArrowLeftOutlined>) => {
    const arrowhoverRef = useRef(null);
    const isHoverInArrow = useHover(arrowhoverRef);
    const { token: { colorPrimaryTextHover, colorTextBase } } = theme.useToken();
    
    return (
      <RightOutlined
        ref={arrowhoverRef}
        className="font-bold cursor-pointer animation-grow-in-hover"
        style={{
          color: isHoverInArrow
            ? colorPrimaryTextHover
            : colorTextBase,
        }}
        {...props}
      />
    );
};