import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getPunchListReasons, reorderReasons } from "@store/slices/punch-list-reason";
import { Card, Switch, Tag, theme } from "antd";
import useVisible from "@HOOKs/UseVisible";
import { EditOutlined } from "@ant-design/icons";
import { NEUTRAL_7_COLOR } from "@providers/ThemeProvider";
import "@styles/components/admin-item-card.less";
import { asyncLaunchNotification } from "@store/slices/notification";
import { DragHandle } from "@components/Icons/DragHandle";
import { IPunchListReason } from "@models/punch-list.model";
import { ReasonEdit } from "./ReasonEdit";
import { useTranslation } from "react-i18next";

export const ReasonCard = (props: any) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { token: { colorPrimary, colorTextDisabled, colorBorderSecondary, colorBgBase, colorText } } = theme.useToken();

  const loadReasons = () => {
    dispatch(
      getPunchListReasons()
    );
  }

  useEffect(() => {
    loadReasons();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [sortedReasons, setSortedReasons] = useState<IPunchListReason[]>([])

  const { entities: reasons } = useAppSelector((state) => state.PunchListReason);

  useEffect(() => {
    setSortedReasons(JSON.parse(JSON.stringify(reasons)));
  }, [reasons]);

  const [openEditReason, toogleEditReason] = useVisible();
  const [reasonSelected, setReasonSelected] = useState<IPunchListReason>({})
  const [isEditing, setIsEditing] = useState<boolean>(false)

  useEffect(() => {
    if (!openEditReason) {
      setReasonSelected({})  
    }
  }, [openEditReason]);

  const onModifiedChanges = (reason: IPunchListReason) => {
    loadReasons();
  }

  const toggleEdit = () => {
    setIsEditing(!isEditing)
  };

  const editReason = (reason: IPunchListReason) => {
    setReasonSelected(reason)
    toogleEditReason()
  }

  const createNewReason = () => {
    const orders = reasons.map(x => Number(x.order))
    const maxOrder = Math.max(...orders)
    setReasonSelected({ order: maxOrder + 1 })
    toogleEditReason();
  }

  const [draggedItem, setDraggedItem] = useState<IPunchListReason | null>(null);

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>, item: IPunchListReason) => {
    setDraggedItem(item);
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>, item: IPunchListReason) => {
    event.preventDefault();
    setSortedReasons((prevItems) => {
      const newItems = [...prevItems];
      const draggedItemIndex = prevItems.findIndex((i) => i.id === draggedItem?.id);
      const itemIndex = prevItems.findIndex((i) => i.id === item.id);
      newItems.splice(draggedItemIndex, 1);
      newItems.splice(itemIndex, 0, draggedItem!);
      return newItems;
    });
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
    const ids = sortedReasons.map(s => Number(s.id));
    if (sortedReasons.filter(s => Number(s.id)).length !== ids.length) {
      dispatch(asyncLaunchNotification({
        type: "error",
        config: {
            message: `Invalid IDs`,
            description: `IDs are invalid. Please refresh and try again.`
        }
      }));
      return false;
    }
    dispatch(reorderReasons(ids));
  };

  const ListItem = (reason: IPunchListReason) => {
    return <li style={{ color: NEUTRAL_7_COLOR, paddingBottom: '4px' }}>{reason.description} {reason.active && <Tag>{t("generic.active")}</Tag>}</li>
  }
  
  const ListItemEditable = (reason: IPunchListReason) => {
    return <div style={{ display: 'flex', backgroundColor: colorBgBase, border: `1px solid ${colorBorderSecondary}`, borderRadius: '4px', padding: '4px 8px', marginBottom: '4px' }}>
      <div>
        <div style={{ paddingTop: '4px', marginRight: '8px' }}><DragHandle /></div>
      </div>
      <div style={{ paddingTop: '3px', color: colorText }}>
        {reason.description} {reason.active && <Tag>{t("generic.active")}</Tag>}
      </div>
      <span style={{ flex: '1', textAlign: 'right' }}>
        <span onClick={() => editReason(reason)} style={{ cursor: 'pointer', color: colorPrimary }}>
          <EditOutlined /> {t('generic.edit')}
        </span>
      </span>
    </div>
  }
  
  return (
    <>
      <ReasonEdit 
        open={openEditReason} 
        toogle={toogleEditReason} 
        punchListReason={reasonSelected} 
        onModifiedChanges={onModifiedChanges} />
      <Card title={<>{t('admin.reason')}<span className="item-count">{reasons?.length}</span></>} bordered={false} className="admin-item-card" extra={<div><span className="extra-label" style={{ color: (isEditing ? colorPrimary : colorTextDisabled) }} onClick={toggleEdit}>{isEditing ? 'Managing' : 'Manage'}</span><Switch checked={isEditing} onClick={toggleEdit} /></div>}>
        <ul style={{ paddingLeft: '15px' }}>
            {sortedReasons.map(reason => 
            <div
                draggable={isEditing}
                onDragStart={(event) => handleDragStart(event, reason)}
                onDragEnter={(event) => handleDragEnter(event, reason)}
                onDragEnd={handleDragEnd} 
                key={reason.id}>
                <>
                {isEditing && <ListItemEditable {...reason} />}
                {!isEditing && <ListItem {...reason} />}
                </>
            </div>
            )}
        </ul>
        {isEditing && <>
            <div className="spacer"></div>
            <span onClick={createNewReason} className="add-new-btn">+ {t('admin.addNew')}</span>
        </>}
        </Card>
    </>
  )
}