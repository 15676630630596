import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getEntities as getRfiCategories } from "@store/slices/rfi-category";
import { Card, Switch, Tag, theme } from "antd";
import useVisible from "@HOOKs/UseVisible";
import { EditOutlined } from "@ant-design/icons";
import { NEUTRAL_7_COLOR } from "@providers/ThemeProvider";
import "@styles/components/admin-item-card.less";
import { RFICategoryEdit } from "./RFICategoryEdit";
import { IRfiCategory } from "@models/rfi-category.model";
import { useTranslation } from "react-i18next";

const ListItem = (rfiCategory: IRfiCategory) => {
  const { t } = useTranslation();
  return <li style={{ color: NEUTRAL_7_COLOR }} className="pb-4">{rfiCategory.name} {rfiCategory.disabled && <Tag>{t("generic.disabled")}</Tag>}</li>
}

interface IListItemEditableProps {
  rfiCategory: IRfiCategory;
  rfiCategorySelected: IRfiCategory;
  editRfiCategoryClicked: any;
}

const ListItemEditable = (props: IListItemEditableProps) => {

  const { rfiCategory, rfiCategorySelected, editRfiCategoryClicked } = props;
  const { t } = useTranslation();

  return <div className={`list-item-editable ${(rfiCategorySelected?.id === rfiCategory.id ? 'selected' : '')}`} onClick={() => editRfiCategoryClicked(rfiCategory)}>
    <div>
      <div className="handle-container">
        <div className="handle-dot"></div>
      </div>
    </div>
    <div className="description">
      {rfiCategory.name} {rfiCategory.disabled && <Tag>{t("generic.disabled")}</Tag>}
    </div>
    <span className="edit-container">
      <span className="edit-link">
        <EditOutlined /> {t("generic.edit")}
      </span>
    </span>
  </div>
}

export const RFICategoryCard = (props: any) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { token: { colorPrimary, colorTextDisabled } } = theme.useToken();

  const loadRfiCategories = () => {
    dispatch(
      getRfiCategories()
    );
  }

  useEffect(() => {
    loadRfiCategories();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { entities: rfiCategories } = useAppSelector((state) => state.RfiCategory);

  const rfiCategoriesSorted = useMemo(() => {
    const copied = JSON.parse(JSON.stringify(rfiCategories))
    return copied.sort((a: IRfiCategory, b: IRfiCategory) => {
      const nameA = a?.name || ''; 
      const nameB = b?.name || '';
    
      return String(nameA).localeCompare(String(nameB));
    });
  }, [rfiCategories])

  const [openEditRfiCategory, toogleEditRfiCategory] = useVisible();
  const [rfiCategorySelected, setRfiCategorySelected] = useState<IRfiCategory>({})
  const [isEditing, setIsEditing] = useState<boolean>(false)

  useEffect(() => {
    if (!openEditRfiCategory) {
      setRfiCategorySelected({})  
    }
  }, [openEditRfiCategory]);

  const onModifiedChanges = (rfiCategory: IRfiCategory) => {
    loadRfiCategories();
  }

  const toggleEdit = () => {
    setIsEditing(!isEditing)
  };

  const editRfiCategory = (rfiCategory : IRfiCategory) => {
    setRfiCategorySelected(rfiCategory)
    toogleEditRfiCategory()
  }

  const createNewRfiCategory = () => {
    setRfiCategorySelected({})
    toogleEditRfiCategory();
  }
  
  return (
    <>
      <RFICategoryEdit open={openEditRfiCategory} toogle={toogleEditRfiCategory} rfiCategory={rfiCategorySelected} onModifiedChanges={onModifiedChanges} />
      <Card title={<>{t("admin.rfiCategory")}<span className="item-count">{rfiCategories?.length}</span></>} bordered={false} className="admin-item-card" extra={<div><span className="extra-label" style={{ color: (isEditing ? colorPrimary : colorTextDisabled) }} onClick={toggleEdit}>{isEditing ? t("admin.managing") : t("admin.manage")}</span><Switch checked={isEditing} onClick={toggleEdit} /></div>}>
        <ul className={isEditing ? 'editing' : ''}>
            {rfiCategoriesSorted.map(rfiCategory => 
            <div
                key={rfiCategory.id}>
                <>
                {isEditing && <ListItemEditable rfiCategory={rfiCategory} rfiCategorySelected={rfiCategorySelected} editRfiCategoryClicked={editRfiCategory} />}
                {!isEditing && <ListItem {...rfiCategory} />}
                </>
            </div>
            )}
        </ul>
        {isEditing && <>
            <div className="spacer"></div>
            <span onClick={createNewRfiCategory} className="add-new-btn">+ {t("admin.addNewRFICategory")}</span>
        </>}
        </Card>
    </>
  )
}