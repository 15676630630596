import { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { AppDispatch, useAppSelector } from '@store/store';
import { useDispatch } from 'react-redux';
import { getEntity } from "@store/slices/site";
import { EmptyLabel } from '../../Utils/EmptyLabel';

export const SiteNameLabel = () => {

    const dispatch = useDispatch<AppDispatch>();
    const { entity: siteEntity } = useAppSelector((state) => state.Sites);

    const { siteId } = useParams<"siteId">();

    useEffect(() => {
        if (siteId && siteEntity && (String(siteEntity.id) !== siteId)) {
            dispatch(getEntity(siteId));
        }
    }, [dispatch, siteId, siteEntity]);

    return (
        <div>{siteEntity?.name || <EmptyLabel />}</div>
    )
}