import { ICostGrouping } from "@models/cost-grouping.model";
import { http } from '../http/axios.instance';


const path = 'cost-groupings';

export const costGroupingRepository = {
    getCostGroupings: async () => {
        const requestUrl = `${path}?cacheBuster=${new Date().getTime()}`;
        const promiseGetAll = await http.get<ICostGrouping[]>(requestUrl);
        return promiseGetAll;
    },
    getCostGroupingsExtended: async () => {
        const requestUrl = `cost-groupings-extended?cacheBuster=${new Date().getTime()}`;
        const promiseGetAll = await http.get<ICostGrouping[]>(requestUrl);
        return promiseGetAll;
    },
    getActiveCostGroupings: async () => {
        const requestUrl = `${path}/active?cacheBuster=${new Date().getTime()}`;
        const promiseGetAll = await http.get<ICostGrouping[]>(requestUrl);
        return promiseGetAll;
    },
    create: async (entity: ICostGrouping): Promise<ICostGrouping> => {
        const requestUrl = `${path}`;
        const promiseCreate = await http.post<ICostGrouping>(requestUrl, entity);
        return promiseCreate.data;
    },
    update: async (id: string | number, entity: ICostGrouping): Promise<ICostGrouping> => {
        const requestUrl = `${path}/${id}`;
        const promiseUpdate = await http.put<ICostGrouping>(requestUrl, entity);
        return promiseUpdate.data;
    },
    reorder: async (costGroupingIds: number[]) => {
        const requestUrl = `${path}/reorder`;
        const promiseUpdate = await http.put<ICostGrouping>(requestUrl, costGroupingIds);
        return promiseUpdate;
    },
}