type DebounceCallback = (...args: any[]) => void;

export const debounce = <T extends DebounceCallback>(
  func: T,
  delay: number
): ((...args: Parameters<T>) => void) => {
  let timerId: NodeJS.Timeout;

  return (...args: Parameters<T>): void => {
    clearTimeout(timerId);

    timerId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};