import { SVGProps } from 'react';

type IProps = SVGProps<SVGSVGElement>;

export const ShieldStarSvgIcon = (props: IProps) => {
  const { ...restProps } = props;

  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <g id="shield-star">
        <path
          id="Vector"
          d="M7 0.583496L1.75 2.91683V6.41683C1.75 9.65433 3.99 12.6818 7 13.4168C10.01 12.6818 12.25 9.65433 12.25 6.41683V2.91683L7 0.583496ZM8.79667 9.3335L7 8.25433L5.20917 9.3335L5.68167 7.29183L4.10083 5.92683L6.18917 5.746L7 3.821L7.81083 5.74016L9.89917 5.921L8.31833 7.29183L8.79667 9.3335Z"
          fillOpacity="0.15"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
