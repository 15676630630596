import { Button, Tag, theme } from "antd";
import { capitalize } from "lodash";

import { useAppDispatch } from '@store/store';
import { patchEntity, getEntity } from '@store/slices/rfi';
import { asyncLaunchNotification } from '@store/slices/notification';
import { IRfi } from '@models/rfi.model';
import { RfiStatusEnum } from '@models/enumerations/rfi-status-enum.model';
export interface ICloseRfiProps {
  id: number | undefined;
  status: string | undefined;
}

export const CloseRfi = (props: ICloseRfiProps) => {
  const {
    token: { colorFillQuaternary },
  } = theme.useToken();
  const dispatch = useAppDispatch();

  const changeRfiStatusSuccess = () => {
    dispatch(
      asyncLaunchNotification({
        type: 'success',
        config: {
          message: `RFI closed successfully`,
          description: `Status changed successfully.`,
        },
      })
    );
    dispatch(getEntity(props.id!));
  };

  const closeRfi = () => {
    const rfiModel: IRfi = { id: props.id, status: RfiStatusEnum.CLOSED };
    dispatch(patchEntity({ entity: rfiModel, onSuccess: changeRfiStatusSuccess }));
  };

  return (
    <>
      <Tag className="pt-3 pb-3 pl-10 pr-10" style={{ borderColor: colorFillQuaternary, borderRadius: '2px' }}>
        <strong>Status:</strong> {props.status ? capitalize(props.status.toLocaleLowerCase()) : ''}
      </Tag>
      <Button type="primary" onClick={closeRfi}>
        Close RFI
      </Button>
    </>
  );
};