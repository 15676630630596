import { IChangeOrderRevision } from '@models/change-order-revision.model';
import { ICreateChangeOrderRequest } from '@models/create-change-order-request';
import { partialUpdateEntity as partialUpdateChangeOrderRevision } from '@store/slices/change-order-revisions';
import { createEntity as createChangeOrder, getEntity, publish, reset } from '@store/slices/change-orders';
import { asyncLaunchNotification } from "@store/slices/notification";
import { useAppDispatch, useAppSelector } from '@store/store';
import { Button, Card, Col, Row, Steps, theme } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackHeader } from '../BackHeader';
import { ChangeOrderStepperContext, IChangeOrderStepperContext } from './ChangeOrderStepperContext';
import { StepOne } from './StepOne';
import { StepThree } from './StepThree';
import { StepTwo } from './StepTwo';
import { ChangeOrdersStatusIds } from '@models/enumerations/change-orders-status';

export interface IStepperProps {
    projectId: number | undefined;
    operation: "CREATION" | "UPDATE_PUBLISH";
}

export const Stepper = (props: IStepperProps) => {
    const { projectId, operation } = props;
    const { token: { padding, colorPrimary } } = theme.useToken();
    const CREATION = "CREATION";
    const UPDATE_PUBLISH = "UPDATE_PUBLISH";
    const [currentStep, setCurrentStep] = useState<number>(0);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const STEP_1_INDEX = 0;
    const STEP_2_INDEX = 1;
    const STEP_3_INDEX = 2;
    const [contextValue, setContextValue] = useState<IChangeOrderStepperContext | null | undefined>(null);
    const { entity: changeOrderEntity } = useAppSelector((state) => state.ChangeOrder);
    const { t } = useTranslation();

    useEffect(() => {
        if (operation && isUpdateAndPublishOperation()) {
            setContextValue({ changeOrderCreated: true });
        }

        if (operation && isCreationOperation()) {
            dispatch(reset());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operation]);

    const isCreationOperation = () => {
        return operation === CREATION
    }

    const isUpdateAndPublishOperation = () => {
        return operation === UPDATE_PUBLISH
    }

    const items = [
        {
            title: isCreationOperation() ? t("changeOrder.stepper.addInfo") : t("changeOrder.stepper.updateInfo"),
        },
        {
            title: t("changeOrder.stepper.attachFiles"),
            description: '(' + t("changeOrder.stepper.optional") + ')',
        },
        {
            title: t("changeOrder.stepper.publish"),
        },
    ];

    const handleCreateSuccess = () => {
        setContextValue({ changeOrderCreated: true });
        setCurrentStep(STEP_2_INDEX);
    }

    const handleUpdateSuccess = () => {
        dispatch(getEntity(changeOrderEntity.id!))
        setCurrentStep(STEP_2_INDEX);
    }

    const stepOneComplete = (stepValue: any) => {
        if (isCreationOperation() && contextValue?.changeOrderCreated !== true) {
            const request: ICreateChangeOrderRequest = {
                ...stepValue,
                projectId: Number(projectId),
                scheduleImpact: stepValue.scheduleImpact ? (stepValue.scheduleImpact === "yes" ? true : false) : null,
                scheduleImpactDays: stepValue.scheduleImpact === "yes" ? (stepValue.scheduleImpactDays ? Number(stepValue.scheduleImpactDays) : null) : null,
                estimatedCost: stepValue.estimatedCost ? Number(stepValue.estimatedCost) : null
            }
            dispatch(createChangeOrder({ entity: request, onSuccess: handleCreateSuccess }));
        } else {
            const revision: IChangeOrderRevision = {
                id: changeOrderEntity?.currentRevisionId,
                changeOrderId: changeOrderEntity.id,
                description: stepValue.description,
                drawingReference: stepValue.drawingReference,
                scheduleImpact: stepValue.scheduleImpact ? (stepValue.scheduleImpact === "yes" ? true : false) : null,
                scheduleImpactDays: stepValue.scheduleImpact === "yes" ? (stepValue.scheduleImpactDays ? Number(stepValue.scheduleImpactDays) : null) : null,
                estimatedCost: stepValue.estimatedCost ? Number(stepValue.estimatedCost).toString() : null,
                relatedRfi: stepValue.relatedRfiId ? { id: stepValue.relatedRfiId } : null,
                currency: { id: stepValue.currencyId },
                dueDate: stepValue.dueDate
            }
            dispatch(partialUpdateChangeOrderRevision({ entity: revision, onSuccess: handleUpdateSuccess }));
        }
    }

    const stepTwoComplete = (stepValue: any) => {
        setCurrentStep(STEP_3_INDEX);
    }

    const handleTransitionSuccess = () => {
        dispatch(asyncLaunchNotification({
            type: "success",
            config: {
                message: t("changeOrder.stepper.coCreatedTitle"),
                description: t("changeOrder.stepper.coCreatedDescription")
            }
        }));
        if (isCreationOperation()) {
            const newPath = pathname.substring(0, pathname.lastIndexOf('/'));
            navigate(`${newPath}/${changeOrderEntity?.id}`);
        } else {
            dismiss();
        }
    }

    const stepThreeComplete = () => {
        if (changeOrderEntity?.id) {
            dispatch(publish({ id: changeOrderEntity?.id!, onSuccess: handleTransitionSuccess }));
        }
    }

    const renderStep = () => {
        switch (currentStep) {
            case 0:
                return (<StepOne complete={stepOneComplete} dismiss={dismiss} />);
            case 1:
                return (<StepTwo complete={stepTwoComplete} dismiss={dismiss} />);
            case 2:
                return (<StepThree complete={stepThreeComplete} dismiss={dismiss} />);
            default:
                return (<></>);
        }
    }

    const dismiss = () => {
        const newPath = pathname.substring(0, pathname.lastIndexOf('/'));
        navigate(newPath);
    }

    const isStepOne = (): boolean => {
        return currentStep === STEP_1_INDEX;
    }

    const isStepTwo = (): boolean => {
        return currentStep === STEP_2_INDEX;
    }

    const isStepThree = (): boolean => {
        return currentStep === STEP_3_INDEX;
    }

    const stepButtonsVisible = (): boolean => {
        return isStepTwo() || isStepThree() || isUpdateAndPublishOperation();
    }

    const onStepChange = (value: number) => {
        setCurrentStep(value);
    };

    const stepsClickEnabled = () => {
        return isUpdateAndPublishOperation() || contextValue?.changeOrderCreated === true;
    }

    return (
        <ChangeOrderStepperContext.Provider value={contextValue}>
            <BackHeader label={isUpdateAndPublishOperation() ? t("changeOrder.changeOrder") + " #" + changeOrderEntity?.number : undefined} />
            <Row style={{ paddingLeft: `${padding}px`, paddingRight: `${padding}px` }} className="pt-20 w-full">
                <Col flex="none" style={{ width: '100%', maxWidth: '648px' }}>
                    <Card
                        style={{ borderRadius: '4px' }}
                        bodyStyle={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                        <Row>
                            <Col span={24} className='text-center mb-15' style={{ color: colorPrimary, fontSize: '20px', lineHeight: '28px' }}>
                                {isCreationOperation() && t("changeOrder.stepper.newCOTitle")}
                                {isUpdateAndPublishOperation() && <>{
                                    changeOrderEntity?.lastStatusTransition?.status?.id === ChangeOrdersStatusIds.PENDING_ESTIMATE_RESUBMISSION ? t("changeOrder.stepper.estimateResubmissionTitle")
                                        : t("changeOrder.stepper.publishTitle")
                                }</>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className='text-center flex'>
                                {stepButtonsVisible() &&
                                    <div style={{ width: 90 }}>
                                        {(isStepTwo() || isStepThree()) && <Button type="link" onClick={() => setCurrentStep(currentStep - 1)}>
                                            {'< ' + t("generic.back")}
                                        </Button>}
                                    </div>
                                }
                                <Steps
                                    progressDot
                                    current={currentStep}
                                    {...(stepsClickEnabled() && { onChange: onStepChange })}
                                    items={items}
                                    style={{ margin: 'auto', maxWidth: '450px' }}
                                />
                                {stepButtonsVisible() &&
                                    <div style={{ width: 90 }}>
                                        {(isStepTwo() || (isUpdateAndPublishOperation() && isStepOne())) && <Button type="link" onClick={() => setCurrentStep(currentStep + 1)}>
                                            {t("generic.skip") + ' >'}
                                        </Button>}
                                    </div>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                {renderStep()}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </ChangeOrderStepperContext.Provider>
    );
}

export interface IChangeOrderStepProps {
    complete?: (stepValues?: any) => void,
    dismiss?: () => void,
}   