/* eslint-disable react-hooks/exhaustive-deps */
import { If } from "@components/Utils/Structural";
import { IAttachment } from "@models/attachment.model";
import { CommentReferenceEnum } from "@models/enumerations/comment-reference-enum.model";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Badge, Col, Divider, Row, theme } from "antd";
import { useEffect, useMemo } from "react";
import { getEntities as getCommentsRichText, reset as resetComments } from '@store/slices/comment-v2';
import { PAGINATION_SIZE_DEFAULT_IN_COMMENTS } from "@constants/core";
import { ICommentRichText } from "@models/comment.model";
import { isArrayWithValues } from "@shared/util/array-util";
import { useResizeDetector } from "react-resize-detector";
import { CommentDetail } from "./CommentDetail";
import { CommentGenerator } from "@components/Comment/CommentGenerator";

interface ICommentsPanelProps {
    fileData: IAttachment;
}

export const CommentsPanel = (props: ICommentsPanelProps) => {

    const { fileData } = props;

    const { token: { fontSizeLG, colorTextSecondary, colorFillTertiary, colorFillSecondary, colorPrimaryText, colorPrimaryBg } } = theme.useToken();

    const { totalItems } = useAppSelector((state) => state.CommentVersion2Slice);

    const totalItemsRender = useMemo(() => {
        return (
            <If condition={Boolean(totalItems)}>
                <Badge 
                    count={<span style={{ color: colorPrimaryText }}>{totalItems}</span>} 
                    size="small" 
                    rootClassName='ml-10' 
                    style={{ 
                        display: "flex", 
                        alignItems: "center",
                        backgroundColor: colorPrimaryBg, 
                        paddingLeft: 8, 
                        paddingRight: 8, 
                        height: 20, 
                        borderRadius: 100 
                    }} 
                />
            </If>
        )
    }, [totalItems, colorPrimaryBg, colorPrimaryText]);

    const { height: heightContainerPanel = 0, ref: containerPanelRef } = useResizeDetector({
        refreshMode: 'debounce',
        refreshRate: 50,
    });

    const { height: heightContainerHeader = 0, ref: containerHeaderRef } = useResizeDetector({
        refreshMode: 'debounce',
        refreshRate: 50,
    });
    
    return (
      <>
        <Row ref={containerPanelRef} className="w-full h-full">
            <Col className="w-full relative h-full">
                <div 
                    ref={containerHeaderRef}
                    className="flex flex-row items-center w-full"
                    style={{ 
                        background: colorFillTertiary,
                        borderBottom: `1px solid ${colorFillSecondary}`, 
                    }}
                >
                    <span className="pl-30 pt-10 pb-10 font-medium" style={{ fontSize: fontSizeLG, color: colorTextSecondary }}>
                        Comments
                    </span>
                    {totalItemsRender}
                </div>
                <BodyCommentsPanel 
                    attachment={fileData} 
                    heightContainer={heightContainerPanel - heightContainerHeader} 
                />
            </Col>
        </Row>
      </>
    )
}

interface IBodyCommentsPanelProps {
    attachment: IAttachment;
    heightContainer: number;
}

export const BodyCommentsPanel = (props: IBodyCommentsPanelProps) => {

    const { attachment, heightContainer = 0 } = props;

    const dispatch = useAppDispatch();

    const { entities: commentListRichText } = useAppSelector((state) => state.CommentVersion2Slice);

    const { token: { colorFillTertiary, colorFillSecondary, colorTextQuaternary } } = theme.useToken();

    const { height: heightContainerEditor = 0, ref: containerEditorRef } = useResizeDetector({
        refreshMode: 'debounce',
        refreshRate: 50,
    });

    const heightContainerList = useMemo(() => {
        return (heightContainer - heightContainerEditor - 8)
    }, [heightContainer, heightContainerEditor]);

    useEffect(() => {
        dispatch(getCommentsRichText({ 
            referenceType: CommentReferenceEnum.PHOTO_ALBUM_ATTACHMENT, 
            referenceId: Number(attachment.id), 
            size: PAGINATION_SIZE_DEFAULT_IN_COMMENTS, 
            sort: "createdDate,desc", 
            page: 0 
        }));

        return () => {
            dispatch(resetComments());
        }
    }, [attachment]);
      
    const handlerOnDeleteComment = () => {
        dispatch(getCommentsRichText({ 
            referenceType: CommentReferenceEnum.PHOTO_ALBUM_ATTACHMENT, 
            referenceId: Number(attachment.id), 
            size: PAGINATION_SIZE_DEFAULT_IN_COMMENTS, 
            sort: "createdDate,desc", 
            page: 0 
        }));
    }

    const commentEditor = useMemo(() => {
        const onSuccessCreateComment = () => { };
        const commentGenerator = attachment.id && (<CommentGenerator 
                    entityType={CommentReferenceEnum.PHOTO_ALBUM_ATTACHMENT} 
                    referenceId={Number(attachment.id)} 
                    onSuccessCreateComment={onSuccessCreateComment}
                />);
        return commentGenerator;
    }, [attachment]);

    return (
        <>
            <div className="relative overflow-scroll" style={{ height: heightContainerList}}>
                <div className="absolute left-0 top-0 right-0 h-full">
                    <Row className='pr-12 pl-12 pb-8 pt-8 h-full' justify={"center"}>
                        {   
                            isArrayWithValues(commentListRichText) && (
                                <div className="mt-10 w-full">
                                    {
                                        commentListRichText.map((item: ICommentRichText) => (
                                            <div key={item.id} className='w-full'>
                                                <CommentDetail value={item} onDelete={handlerOnDeleteComment} />
                                                <Divider className='mt-10 mb-10' style={{ borderColor: colorFillSecondary }}/>
                                            </div>
                                        ))
                                    }
                                </div>
                            )
                        }
                        {
                            !isArrayWithValues(commentListRichText) && (
                                <div className='h-full w-full flex flex-row items-center justify-center' style={{ background: colorFillTertiary,  border: `1px solid ${colorFillTertiary}`, borderRadius: 8 }} >
                                    <span style={{ color: colorTextQuaternary }}>
                                        No Comments yet
                                    </span>
                                </div>
                            )
                        }
                    </Row>
                </div>
            </div>
            <div ref={containerEditorRef} className="absolute pr-8 pl-8 pb-8 left-0 bottom-0 right-0">
                {commentEditor}
            </div>
        </>
    )
}
