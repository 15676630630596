import { DragHandle } from '@components/Icons/DragHandle';
import { theme } from "antd";
import "@styles/components/template-edit.less";
import { ChevronRightSvgIcon } from '@components/Icons/ChevronRightSvgIcon';

export const MilestoneTableHeader = () => {

  const { token: {
    colorBgContainer,  
    colorText, 
    colorFillTertiary } } = theme.useToken();

  return (
    <div style={{ backgroundColor: colorBgContainer, margin: '0 1rem' }}>
        <div className="deliverable-in-list deliverables-header" style={{ backgroundColor: colorFillTertiary, border: '1px solid #ccc', borderRadius: '4px 4px 0px 0px' }}>
            <div className="deliverable-data-row">
                <div className="deliverable-cell" style={{ visibility: 'hidden' }}>
                    <DragHandle />
                </div>
                <div className="deliverable-cell" style={{ flex: 1, color: colorText }}>
                    <span style={{ visibility: 'hidden' }}><ChevronRightSvgIcon /></span><span style={{ marginLeft: '0.5rem' }}>Deliverable Name</span>
                </div>
                <div className="deliverable-cell cell-order" style={{ color: colorText }}>
                    Order
                </div>
                <div className="deliverable-cell cell-duration" style={{ color: colorText }}>
                    Duration
                </div>
                <div className="deliverable-cell cell-dependency" style={{ flex: 1, color: colorText }}>
                    Dependency
                </div>
                <div className="deliverable-cell cell-phase" style={{ color: colorText }}>
                    Phase
                </div>
                <div className="deliverable-cell cell-approval" style={{ color: colorText }}>
                    Approval
                </div>
            </div>
        </div>
    </div>
  );
}
