import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

export const useTableHeight = (): [number, Dispatch<SetStateAction<number>>] => {
  const [tableHeight, setTableHeight] = useState<number>(0);

  const handleSize = useCallback(() => {
    const viewportHeight = window.innerHeight;
    setTableHeight(viewportHeight - 370);
  }, []);

  useEffect(() => {
    handleSize();
    window.addEventListener('resize', handleSize);
    return () => window.removeEventListener('resize', handleSize);
  }, [handleSize]);

  return [tableHeight, setTableHeight];
};
