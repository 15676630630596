import { formatDatePipe } from "@shared/util/date-utils";
import { useMemo } from "react";
import { t } from "i18next";
import { ViewerRichText } from "@components/Comment/ViewerRichText";

interface IChangeItemHistroryRenderProps {
    type: string,
    value: any
}

export const ChangeItemHistoryRender = (props: IChangeItemHistroryRenderProps) => {

    const { type, value = null } = props;

    const processedValue = useMemo(() => {
        const returnValue = FIELDS[type as keyof typeof FIELDS]?.(value);
        return returnValue;
    }, [type, value])

    return (
        (<>{processedValue}</>) ?? null
    )
}

const getStringValue = (rawValue: string) => {
    if (rawValue === null) return t("generic.none");
    return String(rawValue);
}

const getIntegerValue = (rawValue: number) => {
    if (rawValue === null) return t("generic.none");
    return rawValue
}

const getDecimalValue = (rawValue: number) => {
    if (rawValue === null) return t("generic.none");
    return rawValue
}

const getBooleanValue = (rawValue: string): string => {
    if (rawValue === null) return t("generic.none");
    console.log(rawValue, typeof rawValue)
    return rawValue === 'true' ? 'True' : 'False'
}

const getDateValue = (rawValue: string): string => {
    if (rawValue === null) return t("generic.none");
    return formatDatePipe(rawValue);
}

const FIELDS = {
    'STRING': (rawValue: any) => getStringValue(rawValue),
    'INTEGER': (rawValue: any) => getIntegerValue(rawValue),
    'DECIMAL': (rawValue: any) => getDecimalValue(rawValue),
    'BOOLEAN': (rawValue: any) => getBooleanValue(rawValue),
    'DATE': (rawValue: any) => getDateValue(rawValue),
    'RICH_TEXT': (rawValue: any) => (<ViewerRichText payload={rawValue} />),
}
