import { Table } from "antd";

export default function TableHeader() {

  return (
    <>
      <Table
        size='small'
        className='w-full'
        pagination={false}
        showHeader={false}
        dataSource={[{ id: 1, a: "b", key: 0 }]}
        columns={[{
          title: 'Name',
          dataIndex: 'a',
          key: 'a',
          render: () => <span className='font-bold'>Name</span>
        },
        {
          title: 'Version',
          dataIndex: 'a',
          key: 'b',
          width: 60,
          render: () => <span className='font-bold'>Version</span>
        },
        {
          title: 'Creator',
          dataIndex: 'a',
          key: 'c',
          width: 100,
          render: () => <span className='font-bold'>Creator</span>
        },
        {
          title: 'Upload Date',
          dataIndex: 'a',
          key: 'd',
          width: 110,
          render: () => <span className='font-bold'>Upload Date</span>
        },
        {
          title: 'Actions',
          dataIndex: 'a',
          key: 'e',
          width: 150,
          render: () => <></>

        }
        ]} />
    </>
  )
}