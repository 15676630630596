import { createAsyncThunk, createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { projectsService } from '../../services/projects.service';
import { StringORNumber } from "../../infrastructure/repositories/utils.repository";
import { IProjectOverview } from "../../models/project-model";

export interface IUtil {
  data: any,
  loading: boolean,
  errorMessage: any
}

export interface IState extends IUtil {
  data: IProjectOverview
}

const initialState: IState = {
  data: {
    headerData: {},
    keyMilestoneList: [],
    teamMemberList: [],
    companiesList: [],
  },
  loading: false,
  errorMessage: null
};

const sliceName: string = "project-overview"

export const getProjectOverviewData = createAsyncThunk(
  `${sliceName}/getProjectOverviewData`,
  async (id: StringORNumber, thunkApi) => {
    return projectsService.getProjectOverview(id);
  }
)

export const ProjectOverviewSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reset: (state,) => {
      return initialState;
    },
    updateProjectStatusLastModified: (state, action) => {
      state.data.headerData['weeklyStatusLastUpdated'] = action.payload;
      return state;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getProjectOverviewData.fulfilled, (state, action) => {
        const { data } = action.payload;
        if (data) { state.data = data as IProjectOverview; }
        state.errorMessage = null;
        state.loading = false;
      })
      .addMatcher(isPending(getProjectOverviewData), state => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isRejected(getProjectOverviewData), state => {
        state.errorMessage = null;
        state.loading = false;
      });
  }
});

export const { reset, updateProjectStatusLastModified } = ProjectOverviewSlice.actions;

// Reducer
export default ProjectOverviewSlice.reducer;