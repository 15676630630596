import { DASHBOARD_BI_API_URL } from "@constants/core";
import { configuration } from "@environments/env";
import dayjs from "dayjs";

interface ILoaderLibrary {
    onLoadLibraryCallback: () => void;
    onErrorLibraryCallback?: () => void;
}

export const loadReavealGlobally = ({ onLoadLibraryCallback, onErrorLibraryCallback }: ILoaderLibrary) => { 
    // Load Dayjs library
    setDayJsGlobally();
    
    try {
        loadJQueryGlobally({ 
            // Once the jQuery library is loaded, the RevealBI library will be loaded
            onLoadLibraryCallback: () => { 
                // Once the RevealBI library is loaded, the callback function will be executed
                loadRevealBI({ 
                    onLoadLibraryCallback,
                    onErrorLibraryCallback
                });
            }
        });
    } catch (error) { 
        onErrorLibraryCallback?.();
    }
}

export const getRevealApiUrl = (): string => {
    return configuration.API_URL.replace("api/", DASHBOARD_BI_API_URL);
}

const setDayJsGlobally = () => {
    if(window) {
        window['dayjs'] = dayjs;
    }
}

export const loadJQueryGlobally = ({ onLoadLibraryCallback, onErrorLibraryCallback  }: ILoaderLibrary) => { 
    const scriptId = "jquery-scriptId";
    
    const isScriptIncrusted = document?.getElementById?.(scriptId);

    if (isScriptIncrusted) { 
        onLoadLibraryCallback?.();
        return; 
    }

    // Create a new script element
    const script = document?.createElement?.('script');
    
    // Set the src attribute to the revealbi library URL
    script.src = `https://cdn.jsdelivr.net/npm/jquery@3.6.0/dist/jquery.min.js`;

    script.id = scriptId;

    script.onload = () => {
        onLoadLibraryCallback?.();
    }

    script.onerror = (error) => { 
        onErrorLibraryCallback?.();
    }
    
    // Append the script element to the document's head
    document.head.appendChild(script);
}

export const loadRevealBI = ({ onLoadLibraryCallback, onErrorLibraryCallback }: ILoaderLibrary) => {

    const scriptId = "revealbi-scriptId";        

    const isScriptIncrusted = document?.getElementById?.(scriptId);

    if (isScriptIncrusted) { 
        onLoadLibraryCallback?.();
        return; 
    }

    // Create a new script element
    const script = document?.createElement?.('script');
    
    // Set the src attribute to the revealbi library URL
    script.src = `https://dl.revealbi.io/reveal/libs/1.6.4/infragistics.reveal.js`;

    script.id = scriptId;

    script.onload = () => {
        onLoadLibraryCallback?.();
    }

    script.onerror = () => {
        onErrorLibraryCallback?.();
    }
    
    // Append the script element to the document's head
    document.head.appendChild(script);
}

export const getLib = () => {
    const $ = window['$'] as any;
    if ($) {
        return $.ig;
    }
    return null;
}