import { getProjectOverviewData } from '@store/slices/project-overview';
import { createAsyncThunk, createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { projectsService } from '../../services/projects.service';
import { StringORNumber } from "../../infrastructure/repositories/utils.repository";
import { RootState } from '@store/store';
import { asyncLaunchNotification } from "./notification";
import { IProject } from '@models/project-model';
import { serializeAxiosError } from "../reducer.util";
import { ThunkRequest } from '@store/thunk.util';
import i18next from 'i18next';


export interface IUtil {
  data: any,
  lastRequested: IEvaluationParams;
  loading: boolean,
  applySuccess: boolean,
  errorMessage: any
}

export interface IState extends IUtil {
  data: {
    evaluation: any
  }
}

const initialState: IState = {
  data: {
    evaluation: []
  },
  lastRequested: {} as IEvaluationParams,
  loading: false,
  applySuccess: false,
  errorMessage: null
};

interface IRequestBody {
  projectDeliverableId: StringORNumber;
  plannedStartDate?: string,
  plannedFinishDate?: string
}

export interface IEvaluationParams {
  projectId: StringORNumber
  projectDeliverableId: StringORNumber;
  requestBody: IRequestBody
}

export interface IApplyChangesParams {
  projectId: StringORNumber
  projectDeliverableId: StringORNumber;
  requestBody: IRequestBody
}

const sliceName: string = "project-deliverable-timeline"

export const evaluateTimelineDeliverable = createAsyncThunk(
  `${sliceName}/evaluateTimelineDeliverable`,
  async (requestParams: IEvaluationParams, thunkAPI) => {
    const result = await projectsService.evaluateTimelineDeliverable(requestParams);
    if (result) {
      thunkAPI.dispatch(setLastRequested(requestParams));
    }
    return result;
  }
)

export const applyChangesTimelineDeliverable = createAsyncThunk(
  `${sliceName}/applyChangesTimelineDeliverable`,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { lastRequested } = state.ProjectDeliverableTimeLine;
    const result = await projectsService.applyChangesTimelineDeliverable(lastRequested);
    if (result) {
      thunkAPI.dispatch(getProjectOverviewData(lastRequested.projectId));
      thunkAPI.dispatch(asyncLaunchNotification({
        type: "success",
        config: {
          message: `${i18next.t('projectDeliverable.projectDeliverable')}`,
          description: `${i18next.t('projectDeliverable.messages.timelineAppliedSuccessfully')}`
        }
      }))
    }
    return result;
  }
)

export const linkOxBlueIdToProject = createAsyncThunk(
  `${sliceName}/linkOxBlueProjectToApp`,
  async (payload: IProject, thunkAPI) => {
    const { id } = payload;
    if (!id) return
    const data = await projectsService.linkOxBlueIdToProject(id, payload);
    if (data) {
      thunkAPI.dispatch(asyncLaunchNotification({
        type: "success",
        config: {
          message: `${i18next.t('projectSettings.oxBlue.oxBlueIntegration')}`,
          description: `${i18next.t('projectSettings.oxBlue.projectLinkedSuccessfully')}`
        }
      }))
    }
    return data;
  }
)

export const updateProjectEntity = createAsyncThunk(
  `${sliceName}/updateProjectEntity`,
  async (payload: IProject, thunkAPI) => {
    const { id } = payload;
    if (!id) return
    const data = await projectsService.updateProjectEntity(id, payload);
    return data;
  }
)

export const unlinkOxBlueIdToProject = createAsyncThunk(
  `${sliceName}/unLinkOxBlueProjectToApp`,
  async (payload: IProject, thunkAPI) => {
    const { id } = payload;
    if (!id) return
    const data = await projectsService.unlinkOxBlueIdToProject(id);
    if (data) {
      thunkAPI.dispatch(asyncLaunchNotification({
        type: "success",
        config: {
          message: `${i18next.t('projectSettings.oxBlue.oxBlueIntegration')}`,
          description: `${i18next.t('projectSettings.oxBlue.projectUnlinkedSuccessfully')}`
        }
      }))
    }
    return data;
  }
)
export const updateNickNameForProject = createAsyncThunk(
  `${sliceName}/updateNickNameForProject`,
  async (request: ThunkRequest<IProject>, thunkAPI) => {
    const { payload, onError } = request;
    const { id } = payload;
    if (!id) return
    const data = await projectsService.updateNickNameForProject(id, payload).catch(onError);;
    if (data) {
      thunkAPI.dispatch(asyncLaunchNotification({
        type: "success",
        config: {
          message: `${i18next.t('projectSettings.projectNickName')}`,
          description: `${i18next.t('projectSettings.nicknameUpdatedSuccessfully')}`
        }
      }))
    }
    return data;
  },
  { serializeError: serializeAxiosError }
)

export const ProjectDeliverableTimeLineSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setLastRequested: (state, action) => {
      state.lastRequested = action.payload
      return state;
    },
    reset: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(evaluateTimelineDeliverable.fulfilled, (state, action) => {
        state.data.evaluation = action.payload;
        state.errorMessage = null;
        state.loading = false;
        return state;
      })
      .addCase(applyChangesTimelineDeliverable.fulfilled, (state, action) => {
        state.applySuccess = true;
        state.errorMessage = null;
        state.loading = false;
      })
      .addMatcher(isPending(evaluateTimelineDeliverable, applyChangesTimelineDeliverable), state => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isRejected(evaluateTimelineDeliverable, applyChangesTimelineDeliverable), state => {
        state.errorMessage = null;
        state.loading = false;
      });
  }
});

export const { setLastRequested, reset } = ProjectDeliverableTimeLineSlice.actions;

// Reducer
export default ProjectDeliverableTimeLineSlice.reducer;