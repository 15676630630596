import { ICompanyBindingExtended, IGetCompaniesRequest } from '../../models/company-binding.model';
import { http } from '../http/axios.instance';

const path = 'company-bindings-extended';

export const companyBindingsExtendedRepository = {
  getCompanies: async (payload: IGetCompaniesRequest) => {
    const { referenceId, referenceType, page, size, sort } = payload;

    const requestUrl = `${path}?referenceType=${referenceType}&referenceId=${referenceId}${
      sort ? `&page=${page}&size=${size}&sort=${sort}` : ''
    }`;
    return await http.get<ICompanyBindingExtended[]>(requestUrl);
  },
  createCompany: async (entity: ICompanyBindingExtended): Promise<ICompanyBindingExtended> => {
    const requestUrl = `${path}`;
    const promiseCreate = await http.post<ICompanyBindingExtended>(requestUrl, entity);
    return promiseCreate.data;
  },
};
