import { PhotosTab } from "@components/PhotoAlbum/PhotosTab";
import { If } from "@components/Utils/Structural";
import { PhotoAlbumTymeEnum } from "@models/enumerations/photo-album-type.enum";
import { isStringOrNumber } from "@shared/util/validations";
import { useParams } from "react-router-dom";

export const ProjectPhotoAlbumTab = () => {

    const { projectId } = useParams<"projectId">();
    
    return (
        <If condition={isStringOrNumber(projectId)}>
            <PhotosTab 
                referenceId={projectId!}
                referenceType={PhotoAlbumTymeEnum.PROJECT}
                entityName={"Project"} 
            />
        </If>
    )
}
