import { DerivativesEnum } from "@models/enumerations/derivatives-enum.model";
import { ILocation, ILocationUI, defaultValue } from "@models/location.model";
import { attachmentService } from "@services/attachment.service";

export class LocationMapper  {

    public static async getKeyPhoto (location: ILocation)  {
        const derivatives = location?.locationKeyPhoto?.derivatives || [];
        const findPhotoLowResolution  = derivatives.find(file => file.derivativeType === DerivativesEnum.LORES);
        const hasAnLowResolutionImage = Boolean(findPhotoLowResolution && findPhotoLowResolution?.signedUrl);

        if (hasAnLowResolutionImage) {
            return findPhotoLowResolution?.signedUrl;
        }

        if (!hasAnLowResolutionImage) {
            const findPhotoPreview  = derivatives.find(file => file.derivativeType === DerivativesEnum.PREVIEW);
            const hasDerivativeTypePreview = Boolean(findPhotoPreview && findPhotoPreview?.signedUrl);

            if (hasDerivativeTypePreview) {
                return findPhotoPreview?.signedUrl
            }

            if (!hasDerivativeTypePreview && location?.locationKeyPhoto?.id) {
                const signedUrl = await attachmentService.getViewLink(String(location?.locationKeyPhoto?.id));
                return signedUrl.data;
            }
            
        }

        return "";
    }

  public static async toDomain (location?: ILocation) {

    if (!location) return defaultValue;

    const dataTransformedToDomain = {
        ...location,
        locationKeyPhotoSrc: await this.getKeyPhoto(location)
    } as ILocationUI;

    return dataTransformedToDomain || defaultValue;
  }

}