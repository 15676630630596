import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, List, Row, Tag, theme } from "antd";
import { useAppDispatch, useAppSelector } from "@store/store";
import { TabContainer } from "@components/Utils/TabContainer"
import { SearchTable } from "@components/Utils/SearchTable";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import { getEntitiesExtended } from "@store/slices/budget-template-extended";
import { BackButton } from "@components/Utils/BackButton";
import { IBudgetTemplateExtended } from "@models/budget-template.model";
import useVisible from "@HOOKs/UseVisible";
import { useLayoutContex } from "@providers/LayoutProvider";
import { useTranslation } from "react-i18next";
import { BudgetTemplateDetailsPanel } from "./BudgetTemplateDetailsPanel";

export const BudgetTemplateListTab = () => {

    const [budgetTemplateSelected, setBudgetTemplateSelected] = useState<IBudgetTemplateExtended>({})

    const { token: { marginLG, colorPrimary, colorTextTertiary, fontSize, colorBgContainer, colorBorderSecondary, colorText } } = theme.useToken();
    
    const { entities, totalItems } = useAppSelector((state) => state.BudgetTemplateExtended);

    const [ templatesListSorted, setTemplatesListSorted ] = useState<IBudgetTemplateExtended[]>([])

    const [openTemplateView, toogleTemplateView] = useVisible();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { setHeaderLabelEntityState } = useLayoutContex()
    const { t } = useTranslation();

    const filterTemplates = () => {
        dispatch(
            getEntitiesExtended(filterValue)
        );
    }

    const [filterValue, setFilterValue] = useState<string>("");

    useEffect(() => {
        filterTemplates();
        setHeaderLabelEntityState(t("admin.templateList"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const sortedTemplates = [...entities].sort((a: IBudgetTemplateExtended, b: IBudgetTemplateExtended) => {
            const nameA = a?.name || ''; 
            const nameB = b?.name || '';
          
            return String(nameA).localeCompare(String(nameB));
          });
          setTemplatesListSorted(sortedTemplates)
    }, [entities]);

    const onChangeValueToSearch = (e: any) => {
        setFilterValue(e.target.value);
    }

    const handleOnSearch = () => {
        filterTemplates();
    }

    const navigateBack = () => {
        navigate('/admin/module-configuration/budget')
    }

    const onClear = () => {
        setFilterValue('');
        dispatch(
            getEntitiesExtended('')
        );
    }

    const viewTemplate = (template: IBudgetTemplateExtended) => {
        setBudgetTemplateSelected({...template});
        toogleTemplateView();
    }

    const goToBudgetTemplate = (id: string | number) => {
        navigate(`/admin/module-configuration/budget/templates/${id}`);
    }

    const createNewTemplate = () => {
        goToBudgetTemplate(0);
    }

    return (
        <>
            <BudgetTemplateDetailsPanel open={openTemplateView} toogle={toogleTemplateView} budgetTemplate={budgetTemplateSelected} />
            <TabContainer>
                <div className="flex flex-col overflow-auto">
                    <Row>
                        <Col span={24}>
                            <div className="flex flex-row items-center pl-10 pt-11 pb-11" style={{ borderTop: `1px solid ${colorBorderSecondary}`, borderBottom: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                                <BackButton onClick={navigateBack}>{t("budget.budget")}</BackButton>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="pt-20 overflow-auto" style={{ paddingLeft: marginLG, paddingRight: marginLG }}>
                    <Row>
                        <Col xs={18} sm={18} md={18} lg={18} xl={18} xxl={18}>
                            <div className="flex flex-row items-center justify-between" style={{ borderRadius: 4, border: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                                <span className="pt-10 pb-10 pl-20 font-medium" style={{ color: colorText, fontSize: fontSize + 2 }} >{t("admin.templateList")}</span>
                                <div className="flex flex-row items-center">
                                    <span style={{ color: colorTextTertiary }}>{ totalItems ? `${totalItems}` : "0" } {t("admin.templates")}</span>
                                    <SearchTable className="ml-20" handleOnSearch={handleOnSearch} onChange={onChangeValueToSearch} placeholder="Quick Filter" onClear={onClear} />
                                    <Button type="primary" className="ml-20 mr-7" onClick={createNewTemplate}>
                                        <PlusOutlined />
                                        {t("admin.newTemplate")}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-10">
                        <Col xs={18} sm={18} md={18} lg={18} xl={18} xxl={18} >
                        <List
                            itemLayout="vertical"
                            pagination={{
                                pageSize: 10,
                            }}
                            dataSource={templatesListSorted.slice()}
                            renderItem={(template: IBudgetTemplateExtended) => (
                                <List.Item key={template.id} style={{ borderBlockEnd: 0, padding: 0, paddingBottom: '8px' }}>
                                    <div className="flex flex-row items-center justify-between" style={{ borderRadius: 4, border: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                                        <span className="pt-10 pb-10 pl-20 font-medium" style={{ fontSize: fontSize }} >{template.name}</span>
                                        <div className="flex flex-row items-center pt-10 pb-10 pl-20 pr-20 font-medium" style={{ textAlign: 'right'}}>
                                            {template.active && <Tag color={'green'}>{t("generic.active")}</Tag>}
                                            {!template.active && <Tag>{t("generic.inactive")}</Tag>}
                                            <span className="ml-20" style={{ color: colorTextTertiary }}>{ template.costLineCount } {t("admin.costLines")}</span>
                                            <span className="ml-20" style={{ color: colorPrimary, cursor: 'pointer' }} onClick={() => viewTemplate(template)} role="button">{t("admin.viewDetail")} &gt;</span>
                                        </div>
                                    </div>
                                </List.Item>
                            )}
                        />
                        </Col>
                    </Row>
                </div>
            </TabContainer>
        </>
    )
}