/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useErrorAnimation } from "@HOOKs/UseErrorAnimation";
import { isFulfilled } from "@reduxjs/toolkit";
import { asyncLaunchNotification } from "@store/slices/notification";
import { createEntity, updateEntity } from "@store/slices/customfields";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Button, Col, Drawer, Form, Input, Row, Select, Space, theme } from "antd";
import { ICustomField } from "@models/custom-field.model";
import { useTranslation } from "react-i18next";
import { DragHandle } from "@components/Icons/DragHandle";
import { TrashSvgIcon } from "@components/Icons/TrashSvgIcon";
import { getUniqueId } from "@infrastructure/repositories/utils.repository";

interface ISelectorOption {
    value: string;
    guid: string;
}

interface ICustomFieldEditProps {
  open: boolean,
  toggle: () => void,
  onModifiedChanges?: (customField: ICustomField) => void,
  customField: ICustomField | null
}

const DraggableItem = ({id, idx, name, onDelete, handleDragStart, handleDragEnter, handleDragEnd }) => {

    const { token: { colorFillTertiary, colorFill, colorTextTertiary } } = theme.useToken();

    return <div 
    draggable={true}
    onDragStart={(event) => handleDragStart(event, id)}
    onDragEnter={(event) => handleDragEnter(event, id)}
    onDragEnd={handleDragEnd} 
    className="mb-10 ml-10 selector-option"
    style={{ display: 'flex', alignItems: 'center' }} >
        <div style={{ color: colorTextTertiary, width: '30px', minWidth: '30px', flex: 0 }}>{idx + 1}.</div>
        <div className="draggable-item" style={{ background: colorFillTertiary, border: `1px solid ${colorFill}`, flex: 1 }}>
            <div className="mr-8 drag-handle" style={{ marginTop: '2px' }}>
                <DragHandle fill={undefined} />
            </div>
            <div style={{ color: colorTextTertiary, fontSize: '14px' }}>
                {name}
            </div>
        </div>
        <div style={{ flex: 0 }}>
            <Button type="link" style={{ color: colorTextTertiary }} onClick={(e) => { e.stopPropagation(); onDelete(id) }}><TrashSvgIcon /></Button>
        </div>
    </div>
}

const fieldTypeOptions = [
    { label: 'BOOLEAN', value: 'BOOLEAN' },
    { label: 'DATE', value: 'DATE' },
    { label: 'DATETIME', value: 'DATETIME' },
    { label: 'DECIMAL', value: 'DECIMAL' },
    { label: 'INTEGER', value: 'INTEGER' },
    { label: 'SELECTOR', value: 'SELECTOR' },
    { label: 'STRING', value: 'STRING' },
    { label: 'TIME', value: 'TIME' },
];

export const CustomFieldEdit = (props: ICustomFieldEditProps) => {

    const { open, toggle, customField, onModifiedChanges } = props;

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [animateError, playAnimationOfError] = useErrorAnimation();
    const { saving } = useAppSelector((state) => state.CustomFields);

    const labelValue = Form.useWatch('label', form);
    const fieldTypeValue = Form.useWatch('fieldType', form);
    const generatedName = useMemo(() => {
        return `${labelValue}`.trim().replace(/[\W-]+/g," ").replace(/ /g,"-").toLowerCase() || ''
    }, [labelValue]);

    const onFinishFailed = (errorInfo: any) => playAnimationOfError();
    const [ selectorOptions, setSelectorOptions ] = useState<ISelectorOption[]>([]);

    const [selectorOptionText, setSelectorOptionText] = useState<string>("")

    const onDeleteSelectorOption = (guid: string) => {
        const idx = selectorOptions.findIndex(x => x.guid === guid);
        if (idx !== -1) {
            const options = [...selectorOptions];
            options.splice(idx, 1);
            setSelectorOptions(options);
        }
    }

    const onAddSelectorOption = (e: any) => {
        if (e.keyCode === 13) {
            const v = e.target.value.trim();
            if (v.length > 0) {
                const options = [...selectorOptions];
                options.push({ value: v, guid: getUniqueId() });
                setSelectorOptions(options);
                setSelectorOptionText("")
            }
        }
    }

    const onFinish = (entity: any): void => {
        const hasEntity = customField?.id;

        const config = fieldTypeValue !== 'SELECTOR' ? {} : 
            { options: selectorOptions.map(x => x.value) };

        if (hasEntity) {
            dispatch(
                updateEntity({
                    ...customField,
                    ...entity,
                    label: entity?.label?.trim() || '',
                    name: generatedName,
                    config: JSON.stringify(config)
                })  
            ).then(onSuccessUpdatedStatus);
            
        } else {
            dispatch(createEntity({
                id: "",
                ...entity,
                label: entity?.label?.trim() || '',
                name: generatedName,
                order: customField?.order || 99,
                customFieldGroup: customField?.customFieldGroup,
                config: JSON.stringify(config)
            })).then(onSuccessCreateStatus);
        }
    };

    const onSuccessUpdatedStatus = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: t("admin.changesSaved"),
                  description: t("admin.yourChangesHaveBeenSavedSuccessfully")
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onSuccessCreateStatus = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: t("admin.changesSaved"),
                  description: t("admin.customFieldCreatedSuccessfully")
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onClose = () => { 
        form.resetFields();
        toggle(); 
    }

    const onSubmit = () => form.submit();

    useEffect(() => { setRoleFormFields() }, [customField])

    const setRoleFormFields = () => {
        form.setFieldsValue({
            ...customField
        });
        if (customField?.fieldType === 'SELECTOR') {
            try
            {
                const config = customField?.config ? JSON.parse(customField?.config) : {};
                const options = config?.options || [];
                setSelectorOptions(options.map(x => {
                    return {
                        value: x,
                        guid: getUniqueId()
                    }
                }));
            }
            catch (e) {
                console.log(e);
            }
        } else {
            setSelectorOptions([])
        }
    }

    const [draggedItem, setDraggedItem] = useState<ISelectorOption | null>(null);

    const handleDragStart = (event: React.DragEvent<HTMLDivElement>, guid: string) => {
        const option = selectorOptions.find(x => x.guid === guid)
        if (option) {
            setDraggedItem(option);
        }
    };

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>, guid: string) => {
        event.preventDefault();
        const option = selectorOptions.find(x => x.guid === guid)
        if (option) {
            setSelectorOptions((prevItems) => {
                const newItems = [...prevItems];
                const draggedItemIndex = prevItems.findIndex((i) => i.guid === draggedItem?.guid);
                const itemIndex = prevItems.findIndex((i) => i.guid === option.guid);
                newItems.splice(draggedItemIndex, 1);
                newItems.splice(itemIndex, 0, draggedItem!);
                return newItems;
            });
        }
    };

    const handleDragEnd = () => {
        setDraggedItem(null);
    };

    return (
        <>
            <Drawer 
                width={448} 
                title={`${customField?.id ? t("generic.edit"): t("generic.create")} ${t("admin.customField")}`}
                placement="right" 
                onClose={onClose} 
                open={open}
                closable={false}
                extra={
                    <Space>
                      <Button type="primary" ghost onClick={onClose}>
                        {t("generic.cancel")}
                      </Button>
                      <Button loading={saving} onClick={onSubmit} type="primary" disabled={saving}>
                        {`${customField?.id ? t("generic.save"): t("generic.create")}`}
                      </Button>
                    </Space>
                  }
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className={`${animateError && "error-animation"}`}
                >

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={t("generic.name")}
                            >
                                <Input value={generatedName} readOnly disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="label"
                                label={t("generic.label")}
                                rules={[
                                    { required: true, message: t("admin.labelFieldIsRequired") },
                                ]}
                            >
                                <Input placeholder={t("generic.label")} allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="fieldType"
                                label={t("admin.fieldType")}
                                rules={[
                                    { required: true, message: t("admin.fieldTypeIsRequired") },
                                ]}
                            >
                                <Select
                                    placeholder={t("admin.fieldType")}
                                    options={fieldTypeOptions}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {fieldTypeValue === 'SELECTOR' && <div><Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={t("admin.dropdownOptions")}
                                extra={t("admin.pressReturnToCreateNewOption")}
                            >
                                <Input
                                    value={selectorOptionText}
                                    onChange={(v) => { setSelectorOptionText(v.target.value) }}
                                    placeholder={t("admin.typeSomething")} 
                                    onKeyDown={onAddSelectorOption} 
                                    allowClear />
                                <span></span>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div>
                        {selectorOptions.map((v, i) => 
                            <DraggableItem 
                                key={v.guid}
                                id={v.guid} 
                                idx={i}
                                name={v.value} 
                                onDelete={() => { onDeleteSelectorOption(v.guid) }}
                                handleDragStart={handleDragStart}
                                handleDragEnd={handleDragEnd}
                                handleDragEnter={handleDragEnter} />
                        )}
                    </div>
                    </div>}
                </Form>
            </Drawer>
        </>
  );
}
