import { ICostCategory } from "@models/cost-category.model";
import { http } from '../http/axios.instance';
import { StringORNumber } from "./utils.repository";

export const costCategoryRepository = {
    getCostCategories: async (groupingId: StringORNumber) => {
        const requestUrl = `cost-groupings/${groupingId}/categories-extended?cacheBuster=${new Date().getTime()}`;

        const promiseGetAll = await http.get<ICostCategory[]>(requestUrl);
        return promiseGetAll;
    },
    getActiveCostCategories: async (groupingId: StringORNumber) => {
        const requestUrl = `cost-groupings/${groupingId}/categories/active?cacheBuster=${new Date().getTime()}`;

        const promiseGetAll = await http.get<ICostCategory[]>(requestUrl);
        return promiseGetAll;
    },
    create: async (entity: ICostCategory): Promise<ICostCategory> => {
        const requestUrl = `cost-categories`;
        const promiseCreate = await http.post<ICostCategory>(requestUrl, entity);
        return promiseCreate.data;
    },
    update: async (id: string | number, entity: ICostCategory): Promise<ICostCategory> => {
        const requestUrl = `cost-categories/${id}`;
        const promiseUpdate = await http.put<ICostCategory>(requestUrl, entity);
        return promiseUpdate.data;
    },
    reorder: async (costGroupingId: StringORNumber, ids: number[]) => {
        const requestUrl = `cost-groupings/${costGroupingId}/cost-categories/reorder`;
        const promiseUpdate = await http.put<ICostCategory>(requestUrl, ids);
        return promiseUpdate;
    },
    getAll: async () => {
        const requestUrl = `cost-categories?cacheBuster=${new Date().getTime()}`;
        const promiseUpdate = await http.get<ICostCategory[]>(requestUrl);
        return promiseUpdate;
    }, 
}