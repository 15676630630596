import React, { forwardRef, useImperativeHandle, useRef } from "react";

import { Col, ConfigProvider, Row, Spin, Tooltip, theme, Upload } from 'antd';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  FileAddOutlined,
  FileOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { RcFile, UploadChangeParam, UploadFile, UploadProps } from 'antd/es/upload';
import { useRollbar } from '@rollbar/react';

import {
  MAX_LIMIT_FILES_TO_UPLOAD,
  MAX_LIMIT_SIZE_FILE_TO_UPLOAD_IN_MB,
  MAX_SUM_FILE_SIZES_TO_UPLOAD_IN_ALBUMS_IN_MB,
} from '@constants/core';
import { VALID_EXTENSIONS } from "@constants/fileExtensions";
import { isArrayWithValues } from '@shared/util/array-util';
import { useAppDispatch } from '@store/store';
import { asyncLaunchNotification } from '@store/slices/notification';
import { useErrorAnimation } from '@HOOKs/UseErrorAnimation';
import { green, red } from '@ant-design/colors';
import { AttachmentReferenceEnum } from '@models/enumerations/attachment-reference-enum.model';
import { IAttachment } from '@models/attachment.model';
import { attachmentService } from '@services/attachment.service';
import { If } from '@components/Utils/Structural';
import { convertBytes, sumFileSizes } from '@shared/util/document-utils';
import { useAuth } from '@providers/AuthProvider';
import { configuration } from '../../../environments/env';
import { hasIntegerValue } from '@shared/util/number-util';
import { useTranslation } from 'react-i18next';
const { Dragger } = Upload;

interface IDragZoneProps {
  entityType: AttachmentReferenceEnum;
  referenceId: number;
  attachmentId: number | null;
  getAllAttachments: () => void;
  onUploaded: (props: { fileName?: string }) => void;
  projectId: string;
  disabled?: boolean;
  validExtensions?: string[];
  maxLimitFilesToUpload?: number;
  maxLimitSizeFileToUploadInMB?: number;
  onMultiUploadProcessFinish?: (props: { filelist: UploadFile[] }) => void;
}

type BeforeUploadValueType = void | boolean | string | Blob | File;
export type UploadFileStatus = 'error' | 'success' | 'done' | 'uploading' | 'removed';

export interface DragZoneComponentRef {
  openFileUploader: () => void;
}

export const DragZone = forwardRef<DragZoneComponentRef, IDragZoneProps>((props, ref) => {
  const {
    entityType,
    referenceId,
    attachmentId,
    getAllAttachments,
    onUploaded,
    onMultiUploadProcessFinish,
    projectId,
    disabled,
    maxLimitFilesToUpload = MAX_LIMIT_FILES_TO_UPLOAD,
    maxLimitSizeFileToUploadInMB = MAX_LIMIT_SIZE_FILE_TO_UPLOAD_IN_MB,
    validExtensions = VALID_EXTENSIONS,
  } = props;

  const dispatch = useAppDispatch();
  const {
    token: { colorPrimaryText },
  } = theme.useToken();
  const [animateError, playAnimationOfError] = useErrorAnimation();
  const rollbar = useRollbar();
  const { user: userLogged } = useAuth();
  const uploadSingleFileRef = useRef<HTMLSpanElement>(null);
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    openFileUploader: openDialogFileUploader,
  }));

  const openDialogFileUploader = () => {
    if (uploadSingleFileRef && uploadSingleFileRef?.current) {
      uploadSingleFileRef?.current.click();
    }
  };

  const onErrorUpload = () => {
    dispatch(
      asyncLaunchNotification({
        type: 'error',
        config: {
          message: t('attachment.errorUploading'),
          description: t('attachment.errorTryAgain'),
        },
      })
    );
  };

  const beforeUploadFiles = (file: RcFile, FileList: RcFile[]): BeforeUploadValueType | Promise<BeforeUploadValueType> => {
    const indexInList = FileList.findIndex(item => item.uid === file.uid);

    if (isArrayWithValues(FileList)) {
      if (hasIntegerValue(maxLimitFilesToUpload) && FileList.length > maxLimitFilesToUpload) {
        dispatch(
          asyncLaunchNotification({
            type: 'error',
            config: {
              message: t('attachment.errorUploading'),
              description: t('attachment.errorMaxFilesToUpload', { max: maxLimitFilesToUpload }),
            },
          })
        );
        playAnimationOfError();
        return false;
      }
      const sumFileSizesToUpload = sumFileSizes(FileList);
      if (sumFileSizesToUpload > MAX_SUM_FILE_SIZES_TO_UPLOAD_IN_ALBUMS_IN_MB) {
        // In the scenario of multiupload we need this to cancel the upload of each file
        return false;
      }
    }
    if (indexInList > maxLimitFilesToUpload - 1) {
      return false;
    }
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    console.log(event);
  };

  let showMessage: boolean = true;
  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info?.fileList.every(file => file.status !== 'uploading')) {
      onMultiUploadProcessFinish?.({ filelist: info.fileList });
      info.file.originFileObj = undefined;
    }

    const sumFileSizesToUpload = sumFileSizes(info.fileList as RcFile[]);
    if (sumFileSizesToUpload > MAX_SUM_FILE_SIZES_TO_UPLOAD_IN_ALBUMS_IN_MB) {
      const errorMessage = t('attachment.errorMaxSumOfFileSizes', { max: MAX_SUM_FILE_SIZES_TO_UPLOAD_IN_ALBUMS_IN_MB });
      info.fileList.map(item => {
        item.status = 'error';
        return item;
      });
      if (showMessage) {
        console.error(errorMessage);
        dispatch(
          asyncLaunchNotification({
            type: 'error',
            config: {
              message: t('attachment.errorUploading'),
              description: errorMessage,
            },
          })
        );
        showMessage = false;
        setTimeout(() => (showMessage = true), 2000);
      }
      playAnimationOfError();
      return false;
    }

    const finishedFiles = info?.fileList.filter(file => file.status !== 'uploading');
    finishedFiles.map(item => {
      item.originFileObj = undefined;
      return item;
    });
  };

  const isNewFileVersion = (attachmentId: number | null) => Boolean(attachmentId === null);

  const isSmallerThanMaxLimitSizeAllowed = (file: string | RcFile | Blob) => {
    if (Boolean(typeof file === 'string')) {
      return false;
    }
    try {
      const size = (file as RcFile).size as number;
      return size / 1024 / 1024 < maxLimitSizeFileToUploadInMB;
    } catch (error) {
      return false;
    }
  };

  const getExtension = (file: RcFile) => {
    const splitName = String((file as RcFile)?.name).split('.');
    const extension = splitName.length > 1 ? splitName.pop() : 'unknown';
    return String(extension).toLowerCase();
  };

  const isValidExtension = (file: string | RcFile | Blob) => {
    if (Boolean(typeof file === 'string')) {
      return false;
    }
    try {
      return validExtensions.includes(`.${getExtension(file as RcFile)}`);
    } catch (error) {
      return false;
    }
  };

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: true,
    maxCount: maxLimitFilesToUpload,
    type: 'select',
    listType: 'picture',
    disabled: disabled,
    beforeUpload: beforeUploadFiles,
    onDrop: onDrop,
    onChange: handleChange,
    itemRender: CustomItemRender,
    customRequest: ({ file, onSuccess, onError, onProgress }) => {
      const fileName = (file as RcFile).name;
      const extension = getExtension(file as RcFile);

      const isValidSize = isSmallerThanMaxLimitSizeAllowed(file);
      // https://lagarsoft.atlassian.net/browse/SR-459
      if (!isValidSize) {
        dispatch(
          asyncLaunchNotification({
            type: 'error',
            config: {
              message: t('attachment.errorUploading'),
              description: t('attachment.errorMaxFileSize', { max: maxLimitSizeFileToUploadInMB }),
            },
          })
        );

        playAnimationOfError();

        onError &&
          onError({
            status: 0,
            cause: t('attachment.errorExceedsMaxFileSize', { max: maxLimitSizeFileToUploadInMB }),
          } as any);
        return false;
      }

      // https://lagarsoft.atlassian.net/browse/SR-458
      const isValidExtention = isValidExtension(file);

      if (!isValidExtention) {
        dispatch(
          asyncLaunchNotification({
            type: 'error',
            config: {
              message: t('attachment.errorUploading'),
              description: t('attachment.errorUnsupportExtension'),
            },
          })
        );

        playAnimationOfError();

        onError &&
          onError({
            status: 0,
            cause: t('attachment.errorUnsupportExtension'),
          } as any);

        rollbar.info(`File Name [${fileName}] with invalid extension [${extension}] `, `File extension: [${extension}]`, {
          fileInvalid: { file },
          fileType: (file as RcFile).type,
          href: window?.location?.href,
          projectId: projectId,
          entityType,
          referenceId,
          attachmentId,
          user: { user: userLogged },
          appVersion: configuration?.VERSION,
        });

        return false;
      }

      const attachment: IAttachment = {};
      attachment.fileName = fileName;
      attachment.referenceType = entityType;
      attachment.referenceId = referenceId;
      attachment.fileSize = (file as RcFile).size;

      // Upload a new File
      if (isNewFileVersion(attachmentId)) {
        attachmentService
          .create({ attachment, file, onProgress })
          .then(response => {
            onSuccess && onSuccess({});
            onUploaded({ fileName });
            getAllAttachments();
          })
          .catch(error => {
            onErrorUpload();
            onError && onError({ status: 0, reason: t('attachment.errorTryAgain') } as any);
          });
      }

      // Upload a Revision
      if (attachmentId !== null) {
        const parentAttachment: IAttachment = { id: +attachmentId };
        attachment.attachment = parentAttachment;

        attachmentService
          .create({ attachment, file })
          .then(response => {
            onSuccess && onSuccess({});
            onUploaded({ fileName });
            getAllAttachments();
          })
          .catch(error => {
            onErrorUpload();
            onError && onError({ status: 0 } as any);
          });
      }
    },
  };

  return (
    <ConfigProvider theme={{ components: { Upload: { borderRadiusLG: 4 } } }}>
      <Dragger
        {...uploadProps}
        style={{ borderRadius: 4, marginBottom: 10 }}
        className={`flex flex-col w-full h-full ${animateError && 'error-animation'}`}
      >
        <div className="flex flex-col items-center justify-center w-full pt-8 pb-10">
          <p className="text-color-neutral-6 mb-0" style={{ fontSize: 36 }}>
            <FileAddOutlined />
          </p>
          <div className="flex flex-row items-center justify-center w-full pt-5">
            <span ref={uploadSingleFileRef} className="font-semibold pb-0 mb-0" style={{ color: colorPrimaryText }}>
              {t('attachment.clickToUpload')}
            </span>
            <span className="pl-5 pb-0 mb-0"> {t('attachment.orDragAndDrop')} </span>
          </div>
        </div>
      </Dragger>
    </ConfigProvider>
  );
});

const spinIcon = <LoadingOutlined style={{ fontSize: 17 }} spin />;

export const CustomItemRender = (
  originNode: React.ReactNode,
  file: UploadFile<any>,
  fileList: Array<UploadFile<any>>,
  actions: {
    download: () => void;
    preview: () => void;
    remove: () => void;
  }
): React.ReactNode => {
  return <ItemRender file={file} />;
};

export const ItemRender = ({ file }: { file: UploadFile<any> }) => {
  const {
    token: { colorBorder, colorText, colorTextSecondary },
  } = theme.useToken();

  const mapColors = Object.freeze({
    uploading: colorBorder,
    success: green[5],
    done: green[5],
    error: red[2],
  });

  const getColor = (status?: string) => mapColors[status as keyof typeof mapColors] || colorBorder;

  return (
    <Row
      className="mb-5 pt-5 pb-5 flex flex-row items-center justify-between"
      style={{ border: `1px solid ${getColor(file.status)}`, borderRadius: 4 }}
    >
      <Col className="flex flex-row items-center justify-center">
        <FileOutlined className="pl-10 pr-10" />
        <span data-testid="file_name_container" style={{ color: colorText, fontSize: 12 }}>
          {file?.name}
        </span>
        <span className="pl-10 pr-4" style={{ fontSize: 10, color: colorTextSecondary }}>{`${
          file?.size ? convertBytes(file?.size) : ''
        }`}</span>
        <If condition={file.status === 'error'}>
          <Tooltip placement="top" color={red[5]} title={<CauseErrorTooltip file={file} />}>
            <InfoCircleOutlined className="ml-4 cursor-default" style={{ fontSize: 11, color: red[5] }} />
          </Tooltip>
        </If>
      </Col>
      <Col className="flex flex-row items-center justify-center pr-10">
        <span style={{ fontSize: 11, color: colorTextSecondary }} className="pr-10">
          {`${file.percent}%`}
        </span>
        <If condition={`${file?.status}` === 'uploading'}>
          <Spin data-testid="loading_indicator" indicator={spinIcon} />
        </If>
        <If condition={`${file?.status}` === 'done'}>
          <CheckCircleTwoTone data-testid="success_indicator" style={{ fontSize: 18 }} twoToneColor={[green[5], green[0]]} />
        </If>
        <If condition={`${file?.status}` === 'error'}>
          <CloseCircleTwoTone data-testid="error_indicator" style={{ fontSize: 18 }} twoToneColor={[red[5], red[0]]} />
        </If>
      </Col>
    </Row>
  );
};

export const CauseErrorTooltip = ({ file }: { file: UploadFile<any> }) => {
  if (!file?.error?.cause) {
    return null;
  }
  return (
    <div className="flex flex-row items-center text-center" style={{ fontSize: 10 }}>
      {file?.error?.cause}
    </div>
  );
};
