import { createAsyncThunk, createSlice, isPending } from '@reduxjs/toolkit';
import { userExtendedService } from '../../services/user-extended.service';
import { serializeAxiosError } from "../reducer.util";
import { IUserExtended } from './../../models/user.model';

export const getUsers = createAsyncThunk(
  'users_extended/fetch_user_list',
  async (_, thunkAPI) => {
    return userExtendedService.getAll();
  },
  { serializeError: serializeAxiosError }
);

interface IinitialState {
  loading: boolean,
  usersList: IUserExtended[]
}
const initialState: IinitialState = {
  loading: false,
  usersList: []
};

export const slice = createSlice({
  name: 'users_extended',
  initialState,
  reducers: {
    clearCollections: (state,) => {
      return initialState
    },
  },
  extraReducers(builder) {
    builder
      //  getUsers
      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.usersList = action.payload.data;
      })
      .addMatcher(isPending(getUsers), state => {
        state.loading = true;
      });
  }
});

export const { clearCollections } = slice.actions;

// Reducer
export default slice.reducer;

