import { IOxBlueSession, IOxBlueProject, IOxBlueCamera, IOxBlueCameraDates, IOxBlueCameraImageFeed } from '@models/ox-blue.model';
import { http } from '../http/axios.instance';

const path = "3pi/oxblue"

export const oxBlueProjectRepository = {
  getOxBlueSessionId: async () => {
    const requestUrl = `${path}/valid-session`;
    const promiseGet = await http.get<IOxBlueSession>(requestUrl);
    return promiseGet.data;
  },
  getOxBlueProjects: async () => {
    const requestUrl = `${path}/projects`;
    const promiseGet = await http.get<IOxBlueProject[]>(requestUrl);
    return promiseGet.data;
  },
  getOxBlueProjectById: async (queryParams: any) => {
    const requestUrl = `${path}/projects/${queryParams.id}`;
    const promiseGet = await http.get<IOxBlueProject>(requestUrl);
    return promiseGet.data;
  },
  getOxBlueCameraDetailsById: async (queryParams: any) => {
    const requestUrl = `${path}/cameras/${queryParams.id}`;
    const promiseGet = await http.get<IOxBlueCamera>(requestUrl);
    return promiseGet.data;
  },
  getOxBlueCameraByTime: async (queryParams: any) => {
    const requestUrl = `${path}/cameras/${queryParams.id}/times`;
    const promiseGet = await http.get<IOxBlueCameraDates>(requestUrl);
    return promiseGet.data;
  },
  getOxBlueCameraImagesRenderByImageDateTime: async (queryParams: any) => {
    const requestUrl = `${path}/cameras/${queryParams.id}/date/${queryParams.date}/time/${queryParams.time}`;
    const promiseGet = await http.get<IOxBlueCameraImageFeed>(requestUrl);
    return promiseGet.data;
  }
}

