import { ILocation } from "./location.model";
import { ISiteStatus } from "./site-status.model";

export interface ISite {
  id?: number;
  name?: string | null;
  openDate?: string | null;
  closeDate?: string | null;
  status?: ISiteStatus | null;
  location?: ILocation | null;
}

export const defaultValue: Readonly<ISite> = {};
