/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { Badge, Col, Divider, Row, theme } from 'antd';
import { useAppDispatch, useAppSelector } from '@store/store';
import { EmptyCellTable } from '@components/Utils/EmptyCellTable';
import { useParams } from 'react-router-dom';
import { getCompanies } from '@store/slices/generic-company';
import { useTranslation } from 'react-i18next';
import { CustomButtonText } from '@components/Utils/CustomButtonText';
import { ChevronRightSvgIcon } from '@components/Icons/ChevronRightSvgIcon';
import { ICompanyBinding, ICompanyBindingExtended } from '@models/company-binding.model';
import { CompanyGenericCreate } from '@components/CompaniesGeneric/CompanyBinding/CompanyBindingCreate';
import useVisible from '@HOOKs/UseVisible';
import { If } from '@components/Utils/Structural';
import { isArrayWithValues } from '@shared/util/array-util';
import { primaryColor } from '@providers/ThemeProvider';
import { PlusOutlined } from '@ant-design/icons';
import { CompanyGenericManageList } from '@components/CompaniesGeneric/CompanyBinding/CompanyBindingManage';

export interface ILocationCompaniesListProps { }

export const LocationCompaniesList = (props: ILocationCompaniesListProps) => {

    const dispatch = useAppDispatch();

    const { id } = useParams<"id">();

    const [visibleCompanyCreateDrawer, toggleVisibleCompanyCreateDrawer] = useVisible();
    const [visibleCompanyManageDrawer, toggleVisibleCompanyManageDrawer] = useVisible();

    useEffect(() => {
        if (id) {
            dispatch(getCompanies({ referenceId: id, referenceType: "LOCATION" }))
        }
    }, [id]);

    const { data: siteCompanies } = useAppSelector((state) => state.GenericCompany["LOCATION"]);

    return (
        <>
            <If condition={(isArrayWithValues(siteCompanies))}>
                <div className='relative'>
                    <HeaderCard openDrawerNewCompany={toggleVisibleCompanyCreateDrawer} />
                    <BodyList openManageDrawer={toggleVisibleCompanyManageDrawer} />
                    <BottomTriggerManage openManageDrawer={toggleVisibleCompanyManageDrawer} />
                </div>
            </If>
            <If condition={!(isArrayWithValues(siteCompanies))}>
                <EmptyState openDrawerNewCompany={toggleVisibleCompanyCreateDrawer} />
            </If>
            <Row>
                <CompanyGenericCreate
                    open={visibleCompanyCreateDrawer}
                    toggle={toggleVisibleCompanyCreateDrawer}
                    referenceId={id}
                    referenceType={"LOCATION"}
                />
                <CompanyGenericManageList
                    open={visibleCompanyManageDrawer}
                    toggle={toggleVisibleCompanyManageDrawer}
                    referenceId={id}
                    referenceType={"LOCATION"} />
            </Row>
        </>
    )
}

const EmptyState = ({ openDrawerNewCompany }: { openDrawerNewCompany: () => void }) => {
    const { token: { colorFillTertiary, colorTextQuaternary } } = theme.useToken();
    const { t } = useTranslation();
    return (
        <div className='grid h-full' style={{ gridTemplateColumns: "1fr", gridTemplateRows: "min-content 1fr" }}>
            <HeaderCard openDrawerNewCompany={openDrawerNewCompany} />
            <div className='h-full w-full flex flex-row items-center justify-center' style={{ background: colorFillTertiary, border: `1px solid ${colorFillTertiary}`, borderRadius: 8 }} >
                <span style={{ color: colorTextQuaternary }}>
                    No {t("generic.companies")} yet
                </span>
            </div>
        </div>
    )
}


const HeaderCard = ({ openDrawerNewCompany }: { openDrawerNewCompany: () => void }) => {

    const { t } = useTranslation();

    const {
      token: { fontSizeHeading5, colorTextSecondary, colorFillSecondary, colorBgContainer },
    } = theme.useToken();
    const { data: siteCompanies } = useAppSelector((state) => state.GenericCompany["LOCATION"]);


    return (
      <div className="block sticky top-0" style={{ background: colorBgContainer, zIndex: 10 }}>
        <Row justify={'space-between'} align="middle" style={{ paddingTop: 6 }}>
          <Col className="pt-10 pb-5">
            <span className="font-semibold pl-15" style={{ fontSize: fontSizeHeading5, color: colorTextSecondary }}>
              {t('generic.companies')}
            </span>
          </Col>
          <Col>
            <Badge
              count={siteCompanies.length}
              className="ml-30 mr-30"
              style={{
                backgroundColor: colorFillSecondary,
                color: colorTextSecondary,
              }}
            />
            <CustomButtonText icon={<PlusOutlined />} onClick={() => openDrawerNewCompany()}>
              {t('generic.add')}
            </CustomButtonText>
          </Col>
        </Row>
        <Divider className="mt-6 mb-4" style={{ borderColor: colorFillSecondary }} />
      </div>
    );

}

const BodyList = ({ openManageDrawer }) => {
    const { data: siteCompanies } = useAppSelector((state) => state.GenericCompany["LOCATION"]);
    const limitedCompanies = siteCompanies?.slice(0, 3);
    return (
        <>
            <div>
                {
                    limitedCompanies?.map((company: ICompanyBinding) => {
                        return (
                            <BodyListItem openManageDrawer={openManageDrawer} company={company} />
                        )
                    })
                }
            </div>
            {/* <Divider /> */}
        </>
    )
}


const BottomTriggerManage = ({ openManageDrawer }) => {
    const { token: { colorFillTertiary, colorFillQuaternary } } = theme.useToken();

    return (
        <div
            className="pt-3 pb-3 mb-3 cursor-pointer"
            style={{
                border: `1px solid ${colorFillTertiary}`,
                backgroundColor: colorFillQuaternary,
                borderRadius: "4px",
                color: primaryColor
            }}
        >
            <Row className='items-center' onClick={openManageDrawer}>
                <Col flex="auto">
                    <div className='flex flex-col pt-2 pb-2 pr-15 pl-15'>
                        See More and Manage
                    </div>
                </Col>
            </Row>
        </div>
    )
}

const BodyListItem = ({ company: companyBinding, openManageDrawer }: { company: ICompanyBinding, openManageDrawer: any }) => {

    const { token: { colorFillTertiary, colorFillQuaternary, colorPrimaryBorder, colorPrimary, fontSizeLG, colorTextTertiary } } = theme.useToken();

    const [onActive, setOnActive] = useState<boolean>(false);

    const companyContact = useMemo(() => {
        const company = companyBinding as ICompanyBindingExtended
        const companyContact = company.companyContact
        const renderText = `${companyContact ? `${companyContact?.firstName || ""} ${companyContact?.lastName || ""}` : ""}`;
        return (!!(renderText.trimStart()) ? renderText : <EmptyCellTable />)
    }, [companyBinding]);

    return (
        <>
            <div
                className="pt-3 pb-3 mb-3"
                onMouseEnter={() => setOnActive(true)}
                onMouseLeave={() => setOnActive(false)}
                onClick={() => openManageDrawer()}
                style={{
                    border: `1px solid ${onActive ? colorFillTertiary : "transparent"}`,
                    backgroundColor: onActive ? colorFillQuaternary : "transparent",
                    borderRadius: "8px",
                    cursor: onActive ? "pointer" : "default"
                }}
            >
                <Row className='items-center'>
                    <Col flex="auto">
                        <div className='flex flex-col pt-2 pb-2 pr-15 pl-15'>
                            <div className="font-semibold" style={{
                                color: onActive ? colorPrimary : colorTextTertiary,
                                fontSize: fontSizeLG
                            }}>
                                {companyBinding?.company?.name || <EmptyCellTable />}
                            </div>
                            <div
                                className='pr-5'
                                style={{
                                    color: onActive ? colorPrimary : colorTextTertiary
                                }}
                            >
                                {(companyBinding?.companyRole?.description) || <EmptyCellTable />}
                            </div>
                            <div style={{
                                color: onActive ? colorPrimary : colorTextTertiary
                            }}>
                                {companyContact}
                            </div>
                        </div>
                    </Col>
                    <Col flex="none" style={{ width: 40 }}>
                        <If condition={Boolean(onActive)}>
                            <ChevronRightSvgIcon className="ml-15 mr-15" style={{ color: onActive ? colorPrimaryBorder : colorPrimary }} />
                        </If>
                    </Col>
                </Row>
            </div>
        </>
    )
}