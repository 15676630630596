import { AxiosProgressEvent } from 'axios';
import { attachmentExtendedRepository } from '../infrastructure/repositories/attachment-extended.repository';
import { attachmentRepository } from '../infrastructure/repositories/attachment.repository';
import { ICreateAttachmentAndUpload } from '../store/slices/attachment';

export const punchListUploadFileService = {
  upload: async (createAttachmentAndUpload: ICreateAttachmentAndUpload) => {
    const { attachment, file, onProgress } = createAttachmentAndUpload

    // Progress
    let percentageOfProgress = 0.01;

    const processProgress = (props: { progress: number, startFrom: number, weightOfLoad: number, originalConf?: AxiosProgressEvent }) => {
        const { progress, startFrom, weightOfLoad, originalConf } = props;
        const newProgress = startFrom + ((progress * weightOfLoad) / 1);
        percentageOfProgress = newProgress;
        
        onProgress && onProgress({ 
          percent: {
            loaded: 0, 
            ...originalConf,
            percentageOfProgress
          }
        });
    }

    // Create entity in our DB (2% of Progress Max)
    const onUploadProgressStep1 = (config: AxiosProgressEvent) => {
      config.progress && processProgress({ progress: config.progress, startFrom: 0, weightOfLoad: 2});  
    }
    // link of AWS (4% of Progress Max)
    const onUploadProgressStep2 = (config: AxiosProgressEvent) => {
      config.progress && processProgress({ progress: config.progress, startFrom: 2, weightOfLoad: 4});
    }
    // Aws Biggest Load Progress (86% of Progress Max)
    const onUploadProgressStep3 = (config: AxiosProgressEvent) => {
      config.progress && processProgress({ progress: config.progress, startFrom: 4, weightOfLoad: 86, originalConf: config }); 
    }
    // Record in DB [BE] (100% Complete Progess / Process)
    const onUploadProgressStep4 = (config: AxiosProgressEvent) => {
        config.progress && processProgress({ progress: config.progress, startFrom: 90, weightOfLoad: 10 });
    }

    // 1 - Create Attachment Entity (BE / DB)
    const resultEntity = await attachmentRepository.create(attachment, onUploadProgressStep1);
    let resultEntityConfirmed;

    // 2 - Request Signed url to Upload (AWS)
    if (!resultEntity.id) return;
    // if it is a new file
    const uploadURL = await (await attachmentExtendedRepository.getUploadSignedLink(`${resultEntity.id}`, onUploadProgressStep2 )).data;

    // 3 - Put file to s3 signed url
    try {
      const resultUlploadS3 = await attachmentExtendedRepository.putFileToS3(file, uploadURL, onUploadProgressStep3);
      const versionId = resultUlploadS3.headers["x-amz-version-id"];

      // 4 - confirm the file was uploaded
      resultEntity.versionReferenceId = versionId;
      resultEntity.attachment = attachment.attachment; // <- because backend don't hydrate it 
      resultEntityConfirmed = await attachmentRepository.update(resultEntity.id, resultEntity, onUploadProgressStep4);

    } catch (error: any) {
      // if something goes wrong, clean up 
      await attachmentRepository.delete(resultEntity.id, );

      console.error("There was a problem upload the file, cleaning up.")

      return Promise.reject(error);
    }

    return resultEntityConfirmed;

  }
}



