import React, { useState, useContext } from "react";
import { Col, ConfigProvider, FloatButton, Modal, notification, Row, Tag, theme, Tooltip } from "antd";
import Icon, { BgColorsOutlined, CopyOutlined, ToolOutlined, TranslationOutlined } from "@ant-design/icons";
import { SketchPicker } from "react-color";
import { ThemeContext, ThemeContextProviderType } from "../../providers/ThemeProvider";
import { NotificationPlacement } from "antd/es/notification/interface";
import { MoonSvgIcon } from '../Icons/MoonSvgIcon';
import { SunSvgIcon } from "../Icons/SunSvgIcon";
import { generate } from "@ant-design/colors";
import useCopyToClipboard from "../../HOOKs/UseCopyToClipboard";
import "./AppDevToolSettings.less"
import { LanguageAndIdiomSelectorModal } from "./LanguageAndIdiomSelectorModal";
import useVisible from "@HOOKs/UseVisible";

const AppDevToolSettings = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { setThemeConfig, themeConfig } = useContext(ThemeContext) as ThemeContextProviderType;
  
  const { token: { borderRadius, colorBgContainer } } = theme.useToken();

  const [primaryColorSelected, setPrimaryColorSelected] = useState(themeConfig.primaryColor);
  const [secondaryColorSelected, setSecondaryColorSelected] = useState(themeConfig.secondaryColor);
  const [tertiaryColorSelected, setTertiaryColorSelected] = useState(themeConfig.tertiaryColor);
  const [colorListGeneratedDark, setColorListGeneratedDark] = useState<string[]>([]);
  const [colorListGeneratedLight, setColorListGeneratedLight] = useState<string[]>([]);

const [visibleLanguageModal, toogleVisibleLanguageModal] = useVisible();

  const [valueCopied, copyToClipboard] = useCopyToClipboard()

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement: NotificationPlacement) => {
    api.info({
      message: <span>Color copied <Tag className="ml-5 mr-10">{valueCopied} </Tag></span>,
      placement,
      duration: 4.5,
      className: "notification-clipboard",
      icon: <BgColorsOutlined style={{ color: themeConfig.primaryColor }} className="mr-10" />,
    });
  };

  const handleToCopyToClipBoard = (value: string) => {
    copyToClipboard(value).then(() => {
        if (valueCopied) {
            openNotification('top');
        }
    });
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const primaryColorChangeComplete = (color: any) => {
    setPrimaryColorSelected(color.hex);
    setThemeConfig({...themeConfig, primaryColor: color.hex })
  };
  
  const secondaryColorChangeComplete = (color: any) => {
    setSecondaryColorSelected(color.hex);
    setThemeConfig({...themeConfig, secondaryColor: color.hex })
  };

  const tertiaryColorChangeComplete = (color: any) => {
    setTertiaryColorSelected(color.hex);
    setThemeConfig({...themeConfig, tertiaryColor: color.hex });

    const colors = generate(color.hex, {
      theme: "default",
      backgroundColor: '#000000',
    });
    setColorListGeneratedLight(colors);

    const colorsDark = generate(color.hex, {
      theme: "dark",
      backgroundColor: "#ffffff",
    });
    setColorListGeneratedDark(colorsDark);
  };

  const setThemeMode = (mode: boolean) => {
    setThemeConfig({...themeConfig, darkMode: mode })
  }

  return (
    <ConfigProvider theme={{ algorithm: themeConfig.darkMode ? theme.darkAlgorithm: theme.defaultAlgorithm, token: { colorPrimary: primaryColorSelected } }} >
    {contextHolder}
     <FloatButton.Group
        icon={<ToolOutlined />}
        type="primary"
        trigger="click"
        className="mb-30 pb-20"
      >
        <Tooltip placement="right" title={"Language & Client Idiom Selector"}>
            <FloatButton icon={<TranslationOutlined />} onClick={toogleVisibleLanguageModal} />
        </Tooltip>
        <Tooltip placement="right" title={"Dark Mode"}>
            <FloatButton icon={<Icon component={MoonSvgIcon} />} onClick={ () => setThemeMode(true)} type={ themeConfig.darkMode ? "primary": "default"} />
        </Tooltip>
        <Tooltip placement="right" title={"Light Mode"}>
            <FloatButton icon={<Icon component={SunSvgIcon} />} onClick={ () => setThemeMode(false)} type={ themeConfig.darkMode ? "default": "primary"} />
        </Tooltip>
        <Tooltip placement="right" title={"Palette Selector"}>
            <FloatButton icon={<BgColorsOutlined />} onClick={showModal} />
        </Tooltip>
      </FloatButton.Group>
      
      <Modal
        title="Palette Selector"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        styles={{
          mask: { backgroundColor: "rgba(0, 0, 0, 0.10)" },
        }}
        centered
      >
        <Row justify={"center"} className="mb-20">
            <Col className="text-center ml-15 mr-15">
                <SketchPicker
                    color={primaryColorSelected}
                    onChangeComplete={primaryColorChangeComplete}
                />
                <span className="block mt-15 cursor-pointer" onClick={() => handleToCopyToClipBoard(themeConfig.primaryColor) }>
                    <Tag >{primaryColorSelected} <CopyOutlined /></Tag>
                </span>
                <div className="mt-10 w-full" style={{ height: '80px', borderRadius: borderRadius, backgroundColor: primaryColorSelected }}></div>
            </Col>
            <Col className="text-center ml-15 mr-15">
                <SketchPicker
                    color={secondaryColorSelected}
                    onChangeComplete={secondaryColorChangeComplete}
                />
                <span className="block mt-15 cursor-pointer" onClick={() => handleToCopyToClipBoard(themeConfig.secondaryColor) }>
                    <Tag >{secondaryColorSelected} <CopyOutlined /></Tag>
                </span>
                <div className="mt-10 w-full" style={{ height: '80px', borderRadius: borderRadius, backgroundColor: secondaryColorSelected }}></div>
            </Col>
            <Col className="text-center ml-15 mr-15">
                <SketchPicker
                    color={tertiaryColorSelected}
                    onChangeComplete={tertiaryColorChangeComplete}
                />
                <span className="block mt-15 cursor-pointer" onClick={() => handleToCopyToClipBoard(themeConfig.tertiaryColor) }>
                    <Tag >{tertiaryColorSelected} <CopyOutlined /></Tag>
                </span>
                <div className="mt-10 w-full" style={{ height: '80px', borderRadius: borderRadius, backgroundColor: tertiaryColorSelected }}></div>
            </Col>
        </Row>
        <Row>
          <Col span={24} >
              <span className="mb-10">
                Color Generator
              </span>
              <div className="flex flex-row mb-5">
                {
                  colorListGeneratedLight.map((color, key) => (
                    <div key={key} style={{ height: "140px", width: "100px", backgroundColor: color }}>
                      <span style={{ backgroundColor: colorBgContainer }}>{color}</span>
                    </div>
                  ))
                }
              </div> 
              <div className="flex flex-row">
                {
                  colorListGeneratedDark.map((color, key) => (
                    <div key={key} style={{ height: "140px", width: "100px", backgroundColor: color }}>
                      <span style={{ backgroundColor: colorBgContainer }}>{color}</span>
                    </div>
                  ))
                }
              </div>  
          </Col>
        </Row>
      </Modal>

      <LanguageAndIdiomSelectorModal visibleModal={visibleLanguageModal} toogleModal={toogleVisibleLanguageModal} />
    </ConfigProvider>
  );
};

export default AppDevToolSettings;
