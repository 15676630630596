import { EmptyLabel } from "@components/Utils/EmptyLabel";
import { capitalize } from "@shared/util/string-utils";
import { useAppSelector } from "@store/store";

export const GroupNameCrumbLabel = () => {

    const { entity: group = {} } = useAppSelector((state) => state.Groups);
    const { name = "" } = group;

    return (
        <span>
            { 
                `${name ? capitalize(name) : ""}` || <EmptyLabel /> 
            }
        </span>
    )
}
