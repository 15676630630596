import { Button, Divider, Layout, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import AlertOctagone from '../../assets/images/icons/alert-octagon.svg'
import Title from 'antd/es/typography/Title';
import { MainHeader } from '@components/Layout/HeaderLayout/MainHeader';

export default function Code404NotFoundComponent() {
  const navigate = useNavigate();
  const { Text } = Typography;
  return (
    <Layout >
      <MainHeader />
      <div className="flex flex-col w-full h-screen bg-gray-100 px-4">
        <div className="text-center mb-20 flex justify-center items-center flex-col" style={{ height: "50%" }}>
          <img className="mx-auto" src={AlertOctagone} alt="Alert" style={{ width: '25px' }} />
          <Title level={2} className="mt-5">404</Title>
          <Title level={4}>Page Not Found</Title>
          <Text className="block mb-5" style={{ width: "20rem" }}>The page you're trying to access doesn't exist or has been modified.</Text>
          <Button className='mt-15' type="primary" onClick={() => navigate('/dashboard')}>Go To Dashboard</Button>
        </div>
        <div className="w-full text-center pb-4 flex justify-center items-end" style={{ height: "50%" }}>
          <div>
            <Divider />
            <div className="flex justify-center mb-15">
              <a href="mailto:support@siterise.app" className="ml-10 mr-10 text-sm">support@siterise.app</a><br />
              <a href="https://www.siterise.app" className="ml-10 mr-10 text-sm">www.siterise.app</a><br />
              <a href="https://www.theoakgroup.llc" className="ml-10 mr-10 text-sm">www.theoakgroup.llc</a>
            </div>
          </div>
        </div>
      </div>
    </Layout >

  )
}
