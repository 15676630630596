import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { IUtil } from "@models/utils";
import { ISearchResult } from "@models/search";
import { RootState } from "@store/store";
import { searchService } from '../../services/search.service';
import { asyncLaunchNotification } from '@store/slices/notification';

interface IStateEntity extends IUtil {
  q: string,
  filterBy: string,
  data: {
    results: ISearchResult
  },
  loading: boolean,
  errorMessage: any
}

const initialState: IStateEntity = {
  q: "",
  filterBy: "",
  data: {
    results: {}
  },
  loading: false,
  errorMessage: null
}

const sliceName: string = "searchResult"

export const getResults = createAsyncThunk(
  `${sliceName}/getResults`,
  async (_, thunkAPI) => {
    const state  = thunkAPI.getState() as RootState;
    const q = state.Search.q;
    
    const data = await searchService.search({ q }).catch((error) => {
      console.log(error);
      thunkAPI.dispatch(
        asyncLaunchNotification({
          type: "error",
          config: {
            message: `Search Error`,
            description: `Oops, it looks like we’re having trouble with our server while you’re performing a search. Please try again in a moment.`
          }
        })
      )
      return { locations: [], sites: [] }
    });
    return data;
  }
)

export const entitySlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reset: (state,) => {
      return initialState;
    },
    setQueryString: (state, action) => {
      state.q = action.payload;
      return state;
    },
    setFilterByString: (state, action) => {
      state.filterBy = action.payload;
      return state;
    }
  },
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getResults), (state, action) => {
        state.data.results = action.payload as ISearchResult;
        state.errorMessage = null;
        state.loading = false;
      })
      .addMatcher(isPending(getResults), (state) => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isRejected(getResults), (state, action) => {
        state.errorMessage = action?.error?.message || null;
        state.loading = false;
      });
  }
});

// Action Reducers
export const { reset, setQueryString, setFilterByString } = entitySlice.actions;

// Reducer
export default entitySlice.reducer;