import { changeOrdersRepository } from "@infrastructure/repositories/change-orders.repository";

export const changeOrdersService = {
  getChangeOrders: changeOrdersRepository.getChangeOrders,
  createChangeOrder: changeOrdersRepository.createChangeOrder,
  countChangeOrders: changeOrdersRepository.countChangeOrders,
  publish: changeOrdersRepository.publish,
  reject: changeOrdersRepository.reject,
  requestResubmission: changeOrdersRepository.requestResubmission,
  approve: changeOrdersRepository.approve,
  getChangeOrderById: changeOrdersRepository.getChangeOrderById,
  getChangeOrderTimeline: changeOrdersRepository.getChangeOrderTimeline,
  submitDetail: changeOrdersRepository.submitDetail,
  updateReason: changeOrdersRepository.updateReason,
}