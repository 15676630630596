import { updateEntityArchiveStatus } from '@store/slices/projects';
import { useAppDispatch, useAppSelector } from "@store/store";
import { Col, ConfigProvider, Divider, Popconfirm, Row, Switch, theme } from "antd";
import { useEffect, useState } from "react";


const ArchiveProjectSetting = () => {

    const { token: { fontSizeHeading3, fontSizeHeading5, colorBorderSecondary, colorTextSecondary, colorFillSecondary, colorBgContainer, colorError } } = theme.useToken();

    const { entity: projectEntity } = useAppSelector((state) => state.Project);
    const [projectArchiveValue, setProjectAchiveValue] = useState<boolean>(false);

    const [isConfirmArchive, setIsConfirmArchive] = useState<boolean>(false)

    useEffect(() => {
        setProjectAchiveValue(Boolean(projectEntity.archive))
    }, [projectEntity])

    const onChange = (e: Boolean) => {
        setIsConfirmArchive(Boolean(e));
    }
    const dispatch = useAppDispatch();

    const confirmationFromPopup = () => {
        dispatch(updateEntityArchiveStatus({ id: projectEntity.id, archive: isConfirmArchive }));
    }

    const unArchiveProject = (isArchived: Boolean) => {
        dispatch(updateEntityArchiveStatus({ id: projectEntity.id, archive: Boolean(isArchived) }));

    }

    return (
        <div className={`block sticky top-0 mb-20`}
            style={{
                border: `1.2px solid ${colorBorderSecondary}`,
                borderRadius: 10,
                background: colorBgContainer,
                zIndex: 10, padding: 10
            }}>
            <Row justify={"space-between"} align="middle" style={{ paddingTop: 10 }}>
                <Col className='pt-3 pb-3'>
                    <span className='font-semibold pl-15' style={{ fontSize: fontSizeHeading3, color: colorTextSecondary }}>
                        Archive Project
                    </span>
                </Col>
            </Row>
            <Divider className='mt-8 mb-8' style={{ borderColor: colorFillSecondary }} />
            <Row justify={"space-between"} align="middle" style={{ paddingTop: 10 }}>
                <Col className='pt-3 pb-3'>
                    <span className='font-semibold pl-15' style={{ fontSize: fontSizeHeading5, color: projectArchiveValue ? colorError : colorTextSecondary }}>
                        {projectArchiveValue ? `Archived project` : `Archive this project`}
                    </span>
                    <div className='mt-10 pl-15' style={{ fontSize: fontSizeHeading5, color: colorTextSecondary }}>
                        Archive projects will not be visible in the <br />reports but the data will be preserved
                    </div>
                </Col>
                <Col className='pt-3 pb-3'>
                    <ConfigProvider
                        theme={{
                            components: {
                                Switch: {
                                    colorPrimary: "red",
                                },
                            },
                        }}
                    >
                        {projectArchiveValue ?
                            <Switch value={projectArchiveValue} checked={projectArchiveValue} className="mb-2" onChange={unArchiveProject} />
                            :
                            <Popconfirm
                                title={<>This project will be removed <br />from all reports, but the data<br /> will be preserved</>}
                                okText="Accept and Archive"
                                onConfirm={confirmationFromPopup}
                                cancelText="Cancel"
                                placement="right"
                                okButtonProps={{ type: 'primary', danger: true }}
                            >
                                <Switch value={projectArchiveValue} checked={projectArchiveValue} className="mb-2" onChange={onChange} />
                            </Popconfirm>}
                    </ConfigProvider>
                </Col>
            </Row>
        </div >
    )
}

export default ArchiveProjectSetting;