import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { IUtil } from "@models/utils";
import { procoreProjectsService } from "@services/procore-projects.service";
import { ILinkProjectRequestPayload, IUnLinkProjectRequestPayload } from "@models/procore-project.model";
import { asyncLaunchNotification } from '@store/slices/notification';

interface IStateEntity extends IUtil {
  data: {
    procoreProjects: any[]
  },
  loading: boolean,
  errorMessage: any
}

const initialState: IStateEntity = {
  data: {
    procoreProjects: []
  },
  loading: false,
  errorMessage: null
}

const sliceName: string = "procoreProjects"

export const getProcoreProjects = createAsyncThunk(
  `${sliceName}/getProcoreProjects`,
  async (_, thunkAPI) => {
    const data = await procoreProjectsService.getProcoreProjects();
    return data;
  }
)

export const linkProcoreProjectToAPP = createAsyncThunk(
  `${sliceName}/linkProcoreProjectToAPP`,
  async (payload: ILinkProjectRequestPayload, thunkAPI) => {
    const data = await procoreProjectsService.linkProcoreProjectToAPP(payload);
    if (data) {
      thunkAPI.dispatch(asyncLaunchNotification({
        type: "success",
        config: {
          message: "Procore Integration",
          description: "Project linked successfully"
        }
      }))
    }
    return data;
  }
)

export const unlinkProcoreProjectToAPP = createAsyncThunk(
  `${sliceName}/unlinkProcoreProjectToAPP`,
  async (payload: IUnLinkProjectRequestPayload, thunkAPI) => {
    const data = await procoreProjectsService.unlinkProcoreProjectToAPP(payload);
    if (data) {
      thunkAPI.dispatch(asyncLaunchNotification({
        type: "success",
        config: {
          message: "Procore Integration",
          description: "Project unlinked successfully"
        }
      }))
    }
    return data;
  }
)

export const procoreProjectsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reset: (state,) => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getProcoreProjects), (state, action) => {
        state.data.procoreProjects = action.payload;
        state.errorMessage = null;
        state.loading = false;
      })
      .addMatcher(isPending(getProcoreProjects, linkProcoreProjectToAPP, unlinkProcoreProjectToAPP), (state) => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isRejected(getProcoreProjects, linkProcoreProjectToAPP, unlinkProcoreProjectToAPP), (state, action) => {
        state.errorMessage = action?.error?.message || null;
        state.loading = false;
      });
  }
});

// Action Reducers
export const { reset } = procoreProjectsSlice.actions;

// Reducer
export default procoreProjectsSlice.reducer;