import { VisualizationProvider } from "./VisualizationProvider";

interface IItemsVisualizationProps {
    children: React.ReactNode;
}

export const ItemsVisualization = ({ children }: IItemsVisualizationProps) => {
    
    return (
      <>
        <VisualizationProvider>
            {children}
        </VisualizationProvider>
      </>
    )
}
