import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { If } from '@components/Utils/Structural';
import { getResults, setQueryString } from '@store/slices/search';
import { useAppDispatch } from '@store/store';
import { ConfigProvider, Divider, Input, InputRef, theme } from 'antd'
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { useOnClickOutside } from '@HOOKs/UseOnClickOutSide';
import { IHandleOnSearchProps } from '@components/Utils/SearchTable';
import { debounce } from '@shared/util/performance';

const SUFIX_TYPE_EMPTY = "";
const SUFIX_TYPE_SEARCH = "search";
const SUFIX_TYPE_CLEAR = "clear";

const SUFIX_TYPE = {
  Empty: SUFIX_TYPE_EMPTY,
  search: SUFIX_TYPE_SEARCH,
  clear: SUFIX_TYPE_CLEAR
}

const debouncedHandleInputChange = debounce(({ handleSearchOnInput, value }: { handleSearchOnInput: (props: IHandleOnSearchProps) => void, value: string }) => {
  handleSearchOnInput({ valueToSearch: value });
}, 600);

export interface SearchProps extends React.ComponentPropsWithRef<typeof Input> {
  enabledAutoExpand?: boolean,
  suffixType?: keyof typeof SUFIX_TYPE;
  handleOnSearch?: (props?: IHandleOnSearchProps) => void;
  handleSearchOnInput?: (props?: IHandleOnSearchProps) => void;
  handleOnClearSearch?: () => void;
}

export const Search = (props : SearchProps ) => {

  const { 
    handleOnSearch, 
    handleOnClearSearch,
    handleSearchOnInput,
    suffixType, 
    enabledAutoExpand = true,
    defaultValue, 
    ...restProps
  } = props;

  const { token: { colorPrimary, colorPrimaryActive, colorPrimaryHover, fontSize, colorTextTertiary  } } = theme.useToken();

  const fontSizeTextCalculated = fontSize - 2;

  const [suffix, setSuffix] = useState<keyof typeof SUFIX_TYPE>("Empty");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  
  const inputRef = useRef<InputRef>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [valueToSearch, setValueToSearch] = useState('');
  const [isExpandSearch, setIsExpandSearch] = useState(false);
  const [isFirstChangeInSearch, setIsFirstChangeInSearch] = useState(false);

  useOnClickOutside(containerRef, () => {
    if (valueToSearch.length === 0 && enabledAutoExpand) {
      setIsExpandSearch(false);
    }

    if (valueToSearch.length === 0 && suffix === SUFIX_TYPE.search) {
      setSuffix("Empty")
    }
  })

  const onClear = () => {
    if (inputRef.current && inputRef.current.input) {
      inputRef.current.input.value = '';
    }
    setValueToSearch("");
    setSuffix("Empty");
    handleOnClearSearch?.();
  }

  useEffect(() => {
    if (suffixType === SUFIX_TYPE.Empty) {
      setSuffix(SUFIX_TYPE.Empty)
    }
    if (suffixType === SUFIX_TYPE.search) {
      setSuffix(SUFIX_TYPE.search)
    }
    if (suffixType === SUFIX_TYPE.clear) {
      setSuffix(SUFIX_TYPE.clear)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suffixType])

  useEffect(() => {
    typeof defaultValue === "string" && setValueToSearch(defaultValue)
    if (typeof defaultValue === "string" && defaultValue === "") {
      setSuffix("Empty");
    }
    if (typeof defaultValue === "string" && defaultValue !== "" && suffixType === SUFIX_TYPE.clear) {
      setSuffix(SUFIX_TYPE.clear)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  const handleClickSearch = () => {
    const hasHandleOnSearch = Boolean(handleOnSearch);

    if (hasHandleOnSearch) {
      handleOnSearch!({
        valueToSearch
      }) 
    } 
    
    if(!hasHandleOnSearch){ 
      const urlParams = new URLSearchParams();
      const sanitizedQuerySearch = valueToSearch ? DOMPurify.sanitize(valueToSearch): ""
      dispatch(setQueryString(sanitizedQuerySearch));
      urlParams.set('q', sanitizedQuerySearch);
      navigate(`/search?${urlParams.toString()}`, { replace: true });
      dispatch(getResults());
    }
  };

  const handleOnKeyDownSearch = (e: React.KeyboardEvent<Element>) => { 
    if (e.key === 'Enter') handleClickSearch();
    
    (!suffixType || suffixType === SUFIX_TYPE.search) && setSuffix(SUFIX_TYPE_SEARCH);
  }

  const suffixSearchClear = (
    <div className='cursor-pointer'>
      <span className='mr-5' onClick={onClear} style={{ color: colorTextTertiary }} >Clear</span>
      <CloseCircleOutlined onClick={onClear}  style={{ color: colorTextTertiary }} />
      <If condition={(!!isFirstChangeInSearch || valueToSearch.length > 0)}> 
        <>
          <Divider className='mt-0 mb-0 mr-5' type={"vertical"} />
          <span className='mr-5' onClick={() => handleClickSearch() } style={{ color: colorPrimaryActive }} >Search</span>
        </>
      </If>
    </div>
  )

  const suffixSearchSearch = (
    <div className='cursor-pointer'>
      <span className='pl-5 mr-5' style={{ color: colorPrimaryActive }} onClick={() => handleClickSearch() }>Search</span>
    </div>
  )

  const onChangeSearch = (e: any) => {
    const value = e && e.target.value

    if(handleSearchOnInput){
      debouncedHandleInputChange({
        handleSearchOnInput,
        value
      });
    }
    
    if (enabledAutoExpand) {
      setIsExpandSearch(true);
    }

    if (typeof value === "string")  {
      setValueToSearch(e.target.value);

      if (suffixType === SUFIX_TYPE.clear) {
        (value === "") ? setSuffix("Empty") : setSuffix(SUFIX_TYPE_CLEAR);
        setIsFirstChangeInSearch(true)
      }
      if (suffixType === SUFIX_TYPE.search) {
        setSuffix(SUFIX_TYPE_SEARCH)
      }
    }
  }

  const onFocus = () => {
    if (!suffixType || suffixType === SUFIX_TYPE.search) {
      setSuffix(SUFIX_TYPE_SEARCH);
      
      if (enabledAutoExpand) {
        setIsExpandSearch(true);
      }
    }
  }

  const onCLick = () => {
    if (enabledAutoExpand) {
      !isExpandSearch && setIsExpandSearch(true)
    }
    if (suffixType === SUFIX_TYPE.search) {
      setSuffix(SUFIX_TYPE_SEARCH)
    }
  }

  return (
    <>
      <ConfigProvider
        theme={{ ...theme, token: { borderRadius: 300, fontSize: fontSizeTextCalculated }, components: { Input: { borderRadius: 300 } } }}
      >
        <div ref={containerRef}>
          <Input
            id="generalSearchFieldId"
            ref={inputRef}
            size="middle"
            placeholder="Search"
            onClick={onCLick}
            onKeyDown={handleOnKeyDownSearch}
            prefix={<SearchOutlined onClick={() => handleClickSearch()} style={{ color: colorPrimary }} onFocus={onFocus} />}
            suffix={suffix === 'search' ? suffixSearchSearch : suffix === 'clear' ? suffixSearchClear : ''}
            style={{
              height: 'auto',
              width: isExpandSearch ? '100%' : '100px',
              borderColor: colorPrimaryHover,
              transition: 'width 0.35s ease-out',
            }}
            onInput={onChangeSearch}
            onFocus={onFocus}
            value={valueToSearch}
            {...restProps}
          />
        </div>
      </ConfigProvider>
    </>
  );
}
