import { isFulfilled } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from '../reducer.util';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { budgetExtendedService } from '@services/budget-extended.service';
import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { IBudgetRevision } from '@models/budget.model';

export const getAllByProjectId = createAsyncThunk(
  'budget-extended/get_all_by_project_id',
  async (projectId: StringORNumber) => {
    return await budgetExtendedService.getAllByProjectId(projectId);
  },
  { serializeError: serializeAxiosError },
);

const initialState: EntityState<IBudgetRevision> = {
  loading: false,
  errorMessage: null,
  entity: {},
  entities: [],
  updating: false,
  updateSuccess: false,
};

export const BudgetRevisionSlice = createEntitySlice({
  name: 'budget_revision',
  initialState,
  extraReducers(builder) {
    builder.addMatcher(isFulfilled(getAllByProjectId), (state, action) => {
      return {
        ...state,
        loading: false,
        entities: action.payload,
      };
    });
  },
});


// Reducer
export default BudgetRevisionSlice.reducer;
