import useVisible from '@HOOKs/UseVisible';
import { Comment } from '@components/Comment/Comment';
import { EmptyLabel } from "@components/Utils/EmptyLabel";
import { ICommentRichText } from "@models/comment.model";
import { IUser } from "@models/user.model";
import { useAuth } from "@providers/AuthProvider";
import { formatDatePipe } from "@shared/util/date-utils";
import { getUserDisplayName } from "@shared/util/user-utils";
import { asyncLaunchNotification } from '@store/slices/notification';
import { createDistributions, getEntities as getDistributions } from '@store/slices/rfi-distribution';
import { useAppDispatch } from '@store/store';
import { Avatar, Button, Card, Col, Divider, Row, Tag, Tooltip, theme } from "antd";
import { useEffect, useState } from 'react';
import { FollowersModal } from './FollowersModal';

export interface IRfiProps {
    id?: number,
    number: number | undefined,
    categoryName: string | undefined,
    questionPayload: string | undefined,
    scheduleImpact: string | undefined,
    costImpact: string | undefined,
    dueDate: Date | undefined,
    creatorUserId: number | undefined,
    assignedTo: IUser | undefined,
    following: IUser[] | undefined,
    titleExtra?: React.ReactNode,
    addFollowersEnabled?: boolean,
}

export const Rfi = (props: IRfiProps) => {
    const { id, number, categoryName, questionPayload, scheduleImpact, costImpact, dueDate, creatorUserId, assignedTo, following, titleExtra, addFollowersEnabled } = props;
    const { token: { colorPrimary, fontSize, colorInfoBg, colorTextSecondary, colorBgLayout, colorFillQuaternary } } = theme.useToken();

    const { user: currentUser } = useAuth();
    const [currentUserIsFollowing, setCurrentUserIsFollowing] = useState<boolean | undefined>(undefined);
    const [followersModalVisible, toogleFollowersModalVisible, setFollowersModalVisible] = useVisible();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (following && currentUser) {
            setCurrentUserIsFollowing(
                currentUser.id === creatorUserId // the current user is the RFI creator
                || currentUser.id === assignedTo?.id // or the current user is the RFI asignee
                || following.map(u => { return u.id! }).includes(currentUser.id) // or the current user is following the RFI
            );
        }
    }, [following, currentUser, creatorUserId, assignedTo])


    const questionPreview = () => {
        const buildComment = () => {
            const comment: ICommentRichText = {
                payload: questionPayload,
                createdBy: currentUser.id,
                createdById: currentUser.id,
                createdByFirstName: currentUser.firstName,
                createdByLastName: currentUser.lastName,
                createdByEmail: currentUser.email
            }

            return comment;
        }

        return (<>
            {(questionPayload && <Comment value={buildComment()} isPreview={true} />)}
        </>);
    }

    const createDistributionsSuccess = () => {
        if (followersModalVisible === true) {
            setFollowersModalVisible(false);
        }
        dispatch(
            asyncLaunchNotification({
                type: "success",
                config: {
                    message: `You are now following this RFI`,
                    description: `Moving forward, you will receive notifications regarding any updates.`
                }
            })
        );
        dispatch(getDistributions(id!));
    }

    const followRfi = () => {
        dispatch(
            createDistributions({ rfiId: id!, distributions: [{ rfiId: id, user: currentUser }], onSuccess: createDistributionsSuccess })
        )
    }

    return (
      <>
        <Row>
          <Col span={titleExtra ? 15 : 24} className="flex flex-row items-center">
            <span style={{ color: colorPrimary, fontSize: '20px', lineHeight: '28px' }}>RFI #{number}</span>
            <Tag
              className="ml-10 pt-3 pb-3 pl-10 pr-10"
              color="processing"
              style={{ fontSize: fontSize, borderColor: colorInfoBg, borderRadius: '2px' }}
            >
              {categoryName}
            </Tag>
          </Col>
          {titleExtra && <Col span={9}>{titleExtra}</Col>}
        </Row>
        <Divider className="mt-8 mb-10" type="horizontal"></Divider>
        <Row>
          <Col span={24} className="mb-10">
            {questionPreview()}
          </Col>
        </Row>
        <Row>
          <Col span={24} className="mb-20">
            <Card
              bodyStyle={{ paddingLeft: 12, paddingRight: 12, paddingTop: 8, paddingBottom: 8 }}
              style={{ border: 0, borderRadius: '4px', backgroundColor: colorBgLayout }}
            >
              <Row className="w-full">
                <Col xs={24} sm={11}>
                  <Row>
                    <Col xs={24} md={12}>
                      <span className="font-bold">Schedule Impact:</span>
                    </Col>
                    <Col xs={24} md={12}>
                      <div className="flex flex-row capitalized">
                        <span style={{ color: colorTextSecondary }}>{scheduleImpact?.toLocaleLowerCase() || <EmptyLabel />}</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={0} sm={2}>
                  <Divider type="vertical" style={{ height: '70%' }}></Divider>
                </Col>
                <Col xs={24} sm={11}>
                  <Row>
                    <Col xs={24} md={12}>
                      <span className="font-bold">Cost Impact:</span>
                    </Col>
                    <Col xs={24} md={12}>
                      <div className="flex flex-row capitalized">
                        <span style={{ color: colorTextSecondary }}>{costImpact?.toLocaleLowerCase() || <EmptyLabel />}</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={11} className="flex flex-row items-center">
            <span className="font-bold">Assigned To:</span>
            <span className="ml-10 flex flex-row items-center">
              {assignedTo && (
                <>
                  <Avatar className="cursor-default mr-5" gap={4} style={{ backgroundColor: '#B2B2B2' }}>
                    {`${assignedTo.firstName?.[0]}${assignedTo.lastName?.[0]}`.toUpperCase()}
                  </Avatar>
                  {getUserDisplayName(assignedTo)}
                </>
              )}
            </span>
          </Col>
          <Col xs={0} sm={2} className="flex flex-row items-center">
            <Divider type="vertical" style={{ height: '70%' }}></Divider>
          </Col>
          <Col xs={24} sm={11} className="flex flex-row items-center">
            <span className="font-bold">Required By:</span>
            <span className="ml-10 flex flex-row items-center">
              {dueDate && (
                <>
                  <div className="flex flex-row items-center capitalized">
                    <span style={{ color: colorTextSecondary }}>{formatDatePipe(dueDate.toString())}</span>
                  </div>
                </>
              )}
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={addFollowersEnabled ? 12 : 24} className="flex flex-row items-center">
            <span className="font-bold">Following discussion:</span>
            <span className="ml-10 flex flex-row items-center">
              {following && following.length > 0 && (
                <>
                  <Avatar.Group maxCount={3} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                    {following?.map(user => (
                      <Tooltip title={getUserDisplayName(user)} placement="top" key={user.id}>
                        <Avatar
                          className="cursor-pointer"
                          gap={4}
                          style={{ backgroundColor: '#B2B2B2' }}
                          onClick={() => setFollowersModalVisible(true)}
                        >
                          {`${user.firstName?.[0]}${user.lastName?.[0]}`.toUpperCase()}
                        </Avatar>
                      </Tooltip>
                    ))}
                  </Avatar.Group>
                </>
              )}
            </span>
          </Col>
          {addFollowersEnabled && (
            <>
              <Col xs={24} sm={12} className="flex flex-row items-center justify-end">
                {currentUserIsFollowing === true && (
                  <>
                    <Tag className="pt-4 pb-4 pl-10 pr-10" style={{ borderColor: colorFillQuaternary, borderRadius: '2px' }}>
                      &#x2713; Following
                    </Tag>
                    <Button type="link" onClick={() => setFollowersModalVisible(true)}>
                      + Add a Follower
                    </Button>
                  </>
                )}
                {currentUserIsFollowing === false && (
                  <Button type="link" onClick={followRfi}>
                    + Follow this RFI
                  </Button>
                )}
              </Col>
            </>
          )}
        </Row>
        {addFollowersEnabled && (
          <FollowersModal
            rfiId={id!}
            open={followersModalVisible}
            toggle={() => toogleFollowersModalVisible()}
            following={following}
            currentUserIsFollowing={currentUserIsFollowing}
            followRfi={followRfi}
          />
        )}
      </>
    );
}