import { formatDatetimeWithAmPm, fromNow } from "@shared/util/date-utils";
import { Tooltip, theme } from "antd";
import { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IPunchListItem } from "@models/punch-list.model";
import dayjs from "dayjs";
import { ViewHistoryChangesLinkPunchListItem } from "./ViewHistoryChangesLinkPunchList";

interface IPunchListItemLastModifiedProps {
    punchListItem?: IPunchListItem;
}

export const PunchListItemLastModified = (props: IPunchListItemLastModifiedProps) => {

    const { punchListItem } = props;
    const { token: { colorTextTertiary, colorFillTertiary } } = theme.useToken();
    const { t } = useTranslation();
    const [formatedDate, setFormatedDate] = useState<string>('');

    useLayoutEffect(() => {
        if (punchListItem?.lastModifiedDate) {
            const isLessThan30Days = dayjs().diff(dayjs(punchListItem?.lastModifiedDate), 'days') < 30;
            if (isLessThan30Days) {
                setFormatedDate(fromNow(punchListItem?.lastModifiedDate));
            } else {
                setFormatedDate(formatDatetimeWithAmPm(punchListItem?.lastModifiedDate))
            }
        }
    }, [punchListItem])

    return (
        <div className="flex flex-row text-xs" style={{ padding: '2px 8px', lineHeight: "20px", color: colorTextTertiary, backgroundColor: colorFillTertiary, borderRadius: 4 }}>
            <span className="pr-5">
                {t("projectDeliverable.lastModifiedBy")}:
            </span>
            <span className='font-bold pr-5'>{ `${punchListItem?.lastModifiedByFirstName || ""} ${punchListItem?.lastModifiedByLastName}` }</span> 
            <span className="">
                <Tooltip title={formatDatetimeWithAmPm(punchListItem?.lastModifiedDate)}>{formatedDate}</Tooltip>
            </span>
            { punchListItem && <ViewHistoryChangesLinkPunchListItem punchListItem={punchListItem} /> }
        </div>
    )
}
