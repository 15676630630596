export interface IProjectDeliverableReason {
  id?: number;
  description?: string;
  order?: number | null;
  active?: boolean;
}

export const defaultValue: Readonly<IProjectDeliverableReason> = {
  active: false,
};
