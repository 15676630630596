import { IRfi } from '@models/rfi.model';
import { http } from '../http/axios.instance';
import { IRfiReason } from '@models/rfi-reason.model';

const path = 'rfi-reasons';

export const rfiReasonRepository = {
    getRfiReasons: async () => {
        const requestUrl = `${path}?cacheBuster=${new Date().getTime()}`;

        const promiseGetAll = await http.get<IRfi[]>(requestUrl);
        return promiseGetAll;
    },
    create: async (entity: IRfiReason): Promise<IRfiReason> => {

        const requestUrl = `${path}`;
        const promiseCreate = await http.post<IRfiReason>(requestUrl, entity);
        return promiseCreate.data;
    },
    update: async (id: string | number, entity: IRfiReason): Promise<IRfiReason> => {

        const requestUrl = `${path}/${id}`;
        const promiseUpdate = await http.put<IRfiReason>(requestUrl, entity);
        return promiseUpdate.data;
    },
}