import { AppDispatch, useAppSelector } from '@store/store';
import { useDispatch } from 'react-redux';
import { Col, Row, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import { LocationKeyPhotoViewer } from './LocationKeyPhotoViewer';
import { EmptyLabelText } from '@components/Utils/EmptyLabel';
import { TextEllipsis } from '@components/Utils/TextEllipsisContent';
import { formatDatePipe } from '@shared/util/date-utils';
import { UploadKeyPhotoButton } from './UploadKeyPhotoButton';
import { LocationEditDetailsButton } from './LocationEditDetailsButton';
import { AddressGenericCardHandler } from '@components/AddressGeneric/AddressGenericCardHandler';
import { useParams } from 'react-router-dom';
import { CustomCardLayout } from '@components/Utils/CustomCardLayout';
import { useEffect, useState } from 'react';
import { MAIN_BOX_SHADOW } from '@providers/ThemeProvider';
import { If } from '@components/Utils/Structural';
import { getEntities as getBrands } from '@store/slices/brand';

interface ILocationCardProps {
  widthLayoutParent: number;
}

const SUGGEST_BREAKPOINT_DESKTOP = 1024;

export const LocationCard = (props: ILocationCardProps) => {
  const { widthLayoutParent } = props;

  const {
    token: { colorTextTertiary, colorBorder },
  } = theme.useToken();
  const { id } = useParams<'id'>();
  const { t } = useTranslation();

  const { entity: locationEntity } = useAppSelector(state => state.Location);
  const { locationNumber, market = {}, brand = {}, openDate, closeDate } = locationEntity;

  const [isBreakpointDesktop, setIsBreakpointDesktop] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();
  const brands = useAppSelector(state => state.Brand.entities);
  const [singularBrand, setSingularBrand] = useState<boolean>(false);
  useEffect(() => {
    if (brands.length === 0) {
      dispatch(getBrands({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSingularBrand(brands.length === 1);
  }, [brands]);

  useEffect(() => {
    setIsBreakpointDesktop(widthLayoutParent > SUGGEST_BREAKPOINT_DESKTOP);
  }, [widthLayoutParent]);

  return (
    <CustomCardLayout bordered={true} style={{ boxShadow: MAIN_BOX_SHADOW, borderColor: colorBorder, maxWidth: '100%' }}>
      <div className={`flex ${isBreakpointDesktop ? 'flex-col' : 'flex-row'} h-full`}>
        <div className={`flex flex-col ${isBreakpointDesktop ? 'pr-0' : 'pr-15'}`}>
          <div>
            <LocationKeyPhotoViewer />
          </div>
          <Row className="mt-15">
            <Col span={12} className="pr-5">
              <LocationEditDetailsButton />
            </Col>
            <Col span={12} className="pl-5">
              <UploadKeyPhotoButton />
            </Col>
          </Row>
        </div>
        <div className="flex flex-col">
          <Row className="mt-15 pl-15">
            <Col span={24}>
              <ContentVertical>
                <LocationTitleLabel>{t('location.locationNumber')}</LocationTitleLabel>
                <div style={{ color: colorTextTertiary }} className="w-full">
                  {locationNumber || <EmptyLabelText />}
                </div>
              </ContentVertical>
            </Col>
          </Row>

          <Row className="mt-15 pl-15">
            <Col span={24}>
              <ContentVertical>
                <LocationTitleLabel>{t('location.market')}</LocationTitleLabel>
                <div style={{ color: colorTextTertiary }} className="w-full">
                  <TextEllipsis text={market?.description} style={{ color: colorTextTertiary }} />
                </div>
              </ContentVertical>
            </Col>
          </Row>

          <Row className="mt-15 pl-15">
            <Col span={12}>
              <ContentVertical>
                <LocationTitleLabel>{t('location.openDate')}</LocationTitleLabel>
                <div style={{ color: colorTextTertiary }} className="w-full">
                  {formatDatePipe(openDate) || <EmptyLabelText />}
                </div>
              </ContentVertical>
            </Col>
            <If condition={Boolean(closeDate)}>
              <Col span={12}>
                <ContentVertical>
                  <LocationTitleLabel>{t('location.closeDate')}</LocationTitleLabel>
                  <div style={{ color: colorTextTertiary }} className="w-full">
                    {formatDatePipe(closeDate) || <EmptyLabelText />}
                  </div>
                </ContentVertical>
              </Col>
            </If>
          </Row>
          <If condition={Boolean(!singularBrand)}>
            <Row className="mt-15 pl-15">
              <Col span={24}>
                <ContentVertical>
                  <LocationTitleLabel>{t('location.brand')}</LocationTitleLabel>
                  <div style={{ color: colorTextTertiary }} className="w-full">
                    <TextEllipsis text={brand?.description} style={{ color: colorTextTertiary }} />
                  </div>
                </ContentVertical>
              </Col>
            </Row>
          </If>
          <Row className="mt-15">
            <Col span={24}>
              <AddressGenericCardHandler referenceType="LOCATION" referenceId={id} />
            </Col>
          </Row>
        </div>
      </div>
    </CustomCardLayout>
  );
};

export const LocationTitleLabel = ({ children }: { children: React.ReactNode }) => {
  const {
    token: { colorTextSecondary },
  } = theme.useToken();
  return (
    <div className="font-bold w-full" style={{ color: colorTextSecondary }}>
      {children}
    </div>
  );
};

export const ContentVertical = ({ children }: { children: React.ReactElement<any, any>[] }) => {
  return <div className="flex flex-col w-full h-full">{children.map(child => child)}</div>;
};
