import * as d3 from "d3";
import {
  AssetType,
  IAsset,
  IStrokeConfig,
  IdAndColor,
  ZoomEvent,
} from "../interfaces/interfaces";
import { UUID } from "crypto";
/**
 * Draw the nodes.
 * Each time this is called we only draw the added nodes since we are using "enter" only
 */

export class Drawer {
  static deskWidthMeters = 1.2;
  static deskHeightMeters = 0.7;
  static chairRadiusMeters = 0.2;
  static markerRadiusMeters: number;
  static initialAssetDimensions: Map<UUID, { width: number; height: number }> =
    new Map();

  static draw(
    nodesData: IAsset[],
    instanceId: string,
    onAssetClicked: (
      asset: IAsset,
      onAssetSelectionChanged?: (asset: IAsset[] | null) => void,
      event?: MouseEvent
    ) => void,
    onAssetDragged: (
      asset: IAsset,
      event: MouseEvent,
      dx: number,
      dy: number
    ) => void,
    pixelsPerMeter: number,
    markerRadius: number,
    onAssetAdded?: (asset: IAsset) => void,
    onAssetSelectionChanged?: (asset: IAsset[] | null) => void,
    onAssetDragEnd?: (asset: IAsset) => void,
    onAssetSimpleClick?: (asset: IAsset) => void,
    nonSelectableAssetsIds?: any[]
  ) {
    const typePriority = {
      [AssetType.CHAIR]: 1,
      [AssetType.DESK]: 2,
      [AssetType.MARKER]: 3,
    };
    this.markerRadiusMeters = markerRadius;

    // Save the original asset dimensions for resize handling later
    nodesData.forEach((asset) => {
      if (asset.type === AssetType.DESK) {
        this.initialAssetDimensions.set(asset.id, {
          width: this.getAssetWidthMeters(asset),
          height: this.getAssetHeightMeters(asset),
        });
      }
    });
    // Create a new array with a custom order, ensuring markers are drawn last, desks in the middle and chairs in the beginning
    const customOrderedNodesData = [...nodesData];

    customOrderedNodesData.sort((a, b) => {
      const priorityA = typePriority[a.type] || 0;
      const priorityB = typePriority[b.type] || 0;
      return priorityA - priorityB;
    });

    d3.select("#" + instanceId)
      .select("g")
      .selectAll(".node")
      .data(customOrderedNodesData, (d: IAsset) => {
        return d.id;
      })
      // This means that if there are more data elements than existing DOM elements, the enter selection will capture the additional data
      //items and allow you to perform operations on them.
      .join((enter: any) => {
        // Draw a group node that will contain the square and the text
        const node = enter.append("g");
        const drag = () => {
          let initialOffsetX = 0;
          let initialOffsetY = 0;
          let mouseMoved = false;

          const dragStart = (event: any, d: IAsset) => {
            const [x, y] = d3.pointer(
              event,
              d3
                .select("#" + instanceId)
                .select("g")
                .node()
            );
            initialOffsetX = x - d.x * pixelsPerMeter;
            initialOffsetY = y - d.y * pixelsPerMeter;
          };

          const dragMove = (event: any, d: IAsset) => {
            const [x, y] = d3.pointer(
              event,
              d3
                .select("#" + instanceId)
                .select("g")
                .node()
            );
            const offsetX = x - d.x * pixelsPerMeter - initialOffsetX;
            const offsetY = y - d.y * pixelsPerMeter - initialOffsetY;
            // If there is no notable movement then the asset position should not be updated.
            // There was an issue on Mac use Ventura where when using a mouse. Instead of clicking it was considering the click as a drag action.
            // Using Monterey or Windows wasn't causing this issue.
            if (Math.abs(offsetX) > 0 || Math.abs(offsetY) > 0) {
              onAssetDragged(d, event.sourceEvent, offsetX, offsetY);
              mouseMoved = true;
            }
          };

          const dragEnd = (event: any, d: IAsset) => {
            if (onAssetDragEnd && mouseMoved) onAssetDragEnd(d);
            mouseMoved = false;
          };

          return d3
            .drag()
            .on("start", dragStart)
            .on("drag", dragMove)
            .on("end", dragEnd);
        };
        node.style("opacity", (d: IAsset) => d.opacity || 1);
        node
          .classed("node", true)
          .classed("node-with-opacity", (d: IAsset) =>
            d.opacity ? true : false
          )
          .classed("blinking-highlight", true)
          //If needed, it attaches a unique id to the <g>
          .attr("id", (d: IAsset) => "g" + d.id)
          .attr("fill", (d: IAsset) => d.color)
          .attr("cursor", "grab")
          .call(drag())
          .on("click", (event: MouseEvent, d: IAsset) => {
            if (event.defaultPrevented) return;
            if (nonSelectableAssetsIds && nonSelectableAssetsIds.length > 0) {
              nonSelectableAssetsIds?.map((id) => {
                if (id === d.id) {
                  return;
                } else {
                  //Invoke  onAssetSelectionChanged callback when the asset is clicked
                  onAssetClicked(d, onAssetSelectionChanged, event);
                  if (onAssetSimpleClick) onAssetSimpleClick(d);
                  event.stopPropagation();
                }
              });
            } else {
              onAssetClicked(d, onAssetSelectionChanged, event);
              if (onAssetSimpleClick) onAssetSimpleClick(d);
              event.stopPropagation();
            }
          })
          .attr("transform", (d: IAsset) => {
            return this.getTransform(d, pixelsPerMeter);
          });

        node
          .append((d: IAsset) => {
            // Calls function to add the correspondent shape inside the <g> tag created above
            return this.getElement(d, pixelsPerMeter, instanceId);
          })

          // transition and end.all waits for the element to be effectively drawn before executing the callback
          .transition()
          .on("end.all", (d: IAsset) => {
            // Invoke callback function when the element is first drawn
            if (onAssetAdded) {
              onAssetAdded(d);
            }
          });

        // Asset text and styles
        node
          .append("text")
          .attr("x", (d: IAsset) =>
            d.type === AssetType.CHAIR || d.type === AssetType.MARKER
              ? 0
              : (this.getAssetWidthMeters(d) * pixelsPerMeter) / 2
          )
          .attr("y", (d: IAsset) =>
            d.type === AssetType.CHAIR || d.type === AssetType.MARKER
              ? 0
              : (this.getAssetHeightMeters(d) * pixelsPerMeter) / 2
          )
          .attr("text-anchor", "middle")
          .attr("alignment-baseline", "middle")
          .attr("font-size", (pixelsPerMeter / 100) * 12)
          .attr("fill", "black")
          .attr("stroke", "none")
          .attr("transform", (d: IAsset) => {
            return this.getAntiRotation(d, pixelsPerMeter);
          })
          .style("user-select", "none")
          .style("color", "currentColor")
          .text((d: IAsset) => d.label);
      });
  }

  private static updateGridSize(instanceId: string) {
    const grid = d3.select("#grid-" + instanceId);
    if (grid.empty()) return;
    const mainG = d3.select("#" + instanceId).select("g");
    const mainGBoundingBox = mainG.node().getBBox();

    grid
      .attr("x", mainGBoundingBox.x)
      .attr("y", mainGBoundingBox.y)
      .attr("width", mainGBoundingBox.width)
      .attr("height", mainGBoundingBox.height);
  }

  private static calcScale(
    currentWidthMeters: number,
    currentHeightMeters: number,
    targetWidthMeters: number,
    targetHeightMeters: number
  ) {
    const scaleWidth = targetWidthMeters / currentWidthMeters;
    const scaleHeight = targetHeightMeters / currentHeightMeters;
    return `scale(${scaleWidth}, ${scaleHeight})`;
  }

  private static getTransform(asset: IAsset, pixelsPerMeter: number) {
    let rotationString: string;
    let translationString: string;
    if (asset.type === AssetType.CHAIR || asset.type === AssetType.MARKER) {
      rotationString = `rotate(${asset.rotationAngle} )`;
      translationString = `translate(${asset.x * pixelsPerMeter} , ${
        asset.y * pixelsPerMeter
      } )`;
    } else {
      const offsetX = (this.getAssetWidthMeters(asset) * pixelsPerMeter) / 2;
      const offsetY = (this.getAssetHeightMeters(asset) * pixelsPerMeter) / 2;
      rotationString = `rotate(${asset.rotationAngle} ${offsetX} ${offsetY})`;
      translationString = `translate(${asset.x * pixelsPerMeter - offsetX} , ${
        asset.y * pixelsPerMeter - offsetY
      } )`;
    }
    return `${translationString}  ${rotationString}`;
  }

  private static getAntiRotation(asset: IAsset, pixelsPerMeter: number) {
    let antiRotationString: string;
    if (asset.type === AssetType.CHAIR) {
      antiRotationString = `rotate(${-asset.rotationAngle} )`;
    } else {
      const offsetX = (this.getAssetWidthMeters(asset) * pixelsPerMeter) / 2;
      const offsetY = (this.getAssetHeightMeters(asset) * pixelsPerMeter) / 2;

      antiRotationString = `rotate(${-asset.rotationAngle} ${offsetX} ${offsetY})`;
    }
    return antiRotationString;
  }

  private static dropShadowFilter(nodeColor: IdAndColor, svg: any) {
    // Create the drop shadow filter element
    const filter = svg
      .append("defs")
      .attr("class", "blink-filter")
      .append("filter")
      .attr("id", "drop-shadow" + nodeColor.id);
    filter
      .append("feDropShadow")
      .attr("dx", 0)
      .attr("dy", 0)
      .attr("stdDeviation", 2)
      .attr("flood-color", nodeColor.color)
      .attr("flood-opacity", 1);

    // Start a timer to repeat the transition every 1 second
    let deviation = true;
    setInterval(() => {
      filter
        .select("feDropShadow")
        .transition()
        .attr("stdDeviation", deviation ? 4 : 2)
        .duration(300);
      deviation = !deviation;
    }, 300);
  }

  private static getElement(
    asset: IAsset,
    pixelsPerMeter: number,
    instanceId: string
  ) {
    const chairRadius: number = this.chairRadiusMeters * pixelsPerMeter;
    const markerRadius: number = this.markerRadiusMeters * pixelsPerMeter;
    let element: any;
    let scale: string;

    switch (asset.type) {
      case AssetType.CHAIR:
        element = document.createElementNS(d3.namespaces.svg, "circle");
        element.setAttribute("r", chairRadius);
        scale = this.calcScale(
          this.chairRadiusMeters * 2,
          this.chairRadiusMeters * 2,
          this.getAssetWidthMeters(asset),
          this.getAssetHeightMeters(asset)
        );
        element.setAttribute("transform", scale);
        break;
      case AssetType.DESK:
        element = document.createElementNS(d3.namespaces.svg, "rect");
        // We use the original dimensions instead of scaling the default desk width/height, because the initial scaling deforms the background image for some reason.
        element.setAttribute(
          "width",
          this.getAssetWidthMeters(asset) * pixelsPerMeter
        );
        element.setAttribute(
          "height",
          this.getAssetHeightMeters(asset) * pixelsPerMeter
        );
        break;
      case AssetType.MARKER:
        element = document.createElementNS(d3.namespaces.svg, "circle");
        element.setAttribute("r", markerRadius);
        break;
      default:
        break;
    }
    this.appendBackgroundImage(asset, pixelsPerMeter, instanceId) &&
      element.setAttribute("fill", `url(#bg-${asset.id})`);

    element.setAttribute("vector-effect", "non-scaling-stroke");
    //If needed, it attaches a unique id to the element inside the <g>
    element.setAttribute("id", "e" + asset.id);
    return element;
  }

  private static appendBackgroundImage(
    asset: IAsset,
    pixelsPerMeter: number,
    instanceId: string
  ) {
    if (asset.imageUrl) {
      const svg = d3.select("#" + instanceId);
      d3.select(`#asset-background-defs-${asset.id}`).remove();
      const defs = svg
        .append("defs")
        .attr("class", "asset-background")
        .attr("id", `asset-background-defs-${asset.id}`);

      let x, y;
      const width = this.getAssetWidthMeters(asset) * pixelsPerMeter;
      const height = this.getAssetHeightMeters(asset) * pixelsPerMeter;
      if (asset.type === AssetType.CHAIR || asset.type === AssetType.MARKER) {
        x = width / 2;
        y = height / 2;
      } else if (asset.type === AssetType.DESK) {
        x = y = 0;
      }
      const pattern = defs
        .append("pattern")
        .attr("id", "bg-" + asset.id)
        .attr("patternUnits", "userSpaceOnUse")
        .attr("x", x)
        .attr("y", y)
        .attr("width", width)
        .attr("height", height);
      pattern
        .append("image")
        .attr("xlink:href", asset.imageUrl)
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", width)
        .attr("height", height);
      return true;
    }
    return false;
  }

  static getAssetWidthMeters(asset: IAsset) {
    let width: number = 0;
    switch (asset.type) {
      case AssetType.CHAIR:
        width = asset.width ?? this.chairRadiusMeters * 2;
        break;
      case AssetType.DESK:
        width = asset.width ?? this.deskWidthMeters;
        break;
      case AssetType.MARKER:
        width = asset.width ?? this.markerRadiusMeters * 2;
        break;
      default:
        break;
    }

    return width;
  }

  static getAssetHeightMeters(asset: IAsset) {
    let height: number = 0;
    switch (asset.type) {
      case AssetType.CHAIR:
        height = asset.height ?? this.chairRadiusMeters * 2;
        break;
      case AssetType.DESK:
        height = asset.height ?? this.deskHeightMeters;
        break;
      case AssetType.MARKER:
        height = asset.height ?? this.markerRadiusMeters * 2;
        break;
      default:
        break;
    }

    return height;
  }

  static setBackgroundImage(
    instanceId: string,
    url: string | undefined,
    onImageLoaded?: () => void
  ) {
    let mainG = d3.select("#" + instanceId).select("g");

    const gWidth = +mainG.attr("width"); // Get the width attribute as a number
    const gHeight = +mainG.attr("height"); // Get the height attribute as a number

    const image = mainG.select("image");

    if (!image.empty()) {
      image.remove();
    }
    if (url) {
      // Create a new image element
      const newImage = mainG.insert("image", ":first-child");

      // Set the image source
      newImage.attr("xlink:href", url).attr("display", "none");

      // Load the image to get its natural width and height
      const tempImage = new Image();
      tempImage.src = url;
      tempImage.onload = () => {
        const aspectRatio = tempImage.width / tempImage.height;

        // Calculate the dimensions to fit the image within the SVG without stretching
        let imageWidth = gWidth;
        let imageHeight = gWidth / aspectRatio;

        if (imageHeight > gHeight) {
          imageHeight = gHeight;
          imageWidth = gHeight * aspectRatio;
        }
        // Calculate the positioning to center the image
        const x = (gWidth - imageWidth) / 2;
        const y = (gHeight - imageHeight) / 2;
        newImage
          .attr("x", x)
          .attr("y", y)
          .attr("width", imageWidth)
          .attr("height", imageHeight);

        // Update the grid size if needed
        this.updateGridSize(instanceId);
        newImage.attr("display", null);
        if (onImageLoaded) onImageLoaded();
      };
    }
  }

  static highlightNodes(
    nodeIds: UUID[],
    instanceId: string,
    strokeConfig?: IStrokeConfig
  ) {
    d3.select("#" + instanceId)
      .select("g")
      .selectAll(".node")
      // Adds a black line on the outside lines of the shape/asset to highlight it
      .attr("stroke", (d: IAsset) => {
        // Check if the array includes the UUID
        return nodeIds.some((uuid) => uuid === d.id)
          ? strokeConfig?.color
            ? strokeConfig?.color
            : "black"
          : "none";
      })
      .attr("stroke-width", (d: IAsset) => {
        return nodeIds.some((uuid) => uuid === d.id)
          ? strokeConfig?.width
            ? strokeConfig?.width + "px"
            : "2px"
          : "0px";
      });
  }

  static blinkNodes(nodesToBlink: IdAndColor[], instanceId: string) {
    const svg = d3.select("#" + instanceId);
    svg.selectAll("defs.blink-filter").selectAll("filter").remove();
    for (let i = 0; i < nodesToBlink.length; i++) {
      this.dropShadowFilter(nodesToBlink[i], svg);
      svg
        .select("#e" + nodesToBlink[i].id)
        .style("filter", `url(#drop-shadow${nodesToBlink[i].id})`);
    }
  }

  static deleteNode(
    nodeId: UUID,
    instanceId: string,
    onAssetDeleted?: (asset: IAsset) => void
  ) {
    //Selects all nodes from the instance of the svg and deletes the element that is found using its id
    d3.select("#" + instanceId)
      .select("g")
      .selectAll(".node")
      .filter(function (d: IAsset) {
        // Invoke callback function when an element is deleted
        if (onAssetDeleted && d.id === nodeId) onAssetDeleted(d);
        return d.id === nodeId;
      })
      .remove();
    // remove background image defs
    d3.select(`#asset-background-defs-${nodeId}`).remove();
  }

  static deleteAllNodes(instanceId: string) {
    //Selects all nodes from the instance and deletes them all
    d3.select("#" + instanceId)
      .select("g")
      .selectAll(".node")
      .remove();

    d3.selectAll(".asset-background").remove();
  }

  static updateNodesProperties(
    nodes: IAsset[],
    instanceId: string,
    pixelsPerMeter: number,
    onAssetUpdated?: (asset: IAsset) => void
  ) {
    // Invoke callback function when the element is updated
    for (const node of nodes) {
      if (onAssetUpdated) onAssetUpdated(node);

      //Selects the <g> element using the id that was attached to the asset when it was created
      const g = d3
        .select("#" + instanceId)
        .select("g")
        .select("#g" + node.id);

      const transform = this.getTransform(node, pixelsPerMeter);
      const antiRotation = this.getAntiRotation(node, pixelsPerMeter);

      let labelX, labelY;
      if (node.type === AssetType.CHAIR || node.type === AssetType.MARKER) {
        labelX = 0;
        labelY = 0;
      } else {
        labelX = (this.getAssetWidthMeters(node) * pixelsPerMeter) / 2;
        labelY = (this.getAssetHeightMeters(node) * pixelsPerMeter) / 2;
      }

      g.datum(node)
        .attr("transform", transform)
        .attr("fill", (d: IAsset) => {
          return d.color;
        });

      g.datum(node)
        .attr("transform", transform)
        .style("opacity", (d: IAsset) => {
          return d.opacity;
        });

      g.select("text")
        .text((d: IAsset) => d.label)
        .style("color", "currentColor")
        .attr("transform", antiRotation)
        .attr("x", labelX)
        .attr("y", labelY);

      const element = d3.select("#" + instanceId).select("#e" + node.id);
      let scale: string;

      switch (node.type) {
        case AssetType.CHAIR:
          scale = this.calcScale(
            this.chairRadiusMeters * 2,
            this.chairRadiusMeters * 2,
            this.getAssetWidthMeters(node),
            this.getAssetHeightMeters(node)
          );
          element.attr("transform", scale);
          break;
        case AssetType.DESK:
          // Asset is scaled using the initial dimensions as a reference. For other asset types the default chair/marker dimensions are used since the initial size is set by scaling those dimensions. However for desks this initial scaling deforms the background image, so we work around that by setting the asset's custom dimensions directly, and storing them as reference to use here.
          scale = this.calcScale(
            this.initialAssetDimensions.get(node.id)!.width,
            this.initialAssetDimensions.get(node.id)!.height,
            this.getAssetWidthMeters(node),
            this.getAssetHeightMeters(node)
          );
          element.attr("transform", scale);
          break;
        case AssetType.MARKER:
          // Marker is not resizable
          break;
        default:
          break;
      }
    }
  }

  static createArrowMarkers = (instanceId: string, color: string) => {
    const svg = d3.select("#" + instanceId);
    svg
      .append("marker")
      .attr("id", "arrowEnd")
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", 8)
      .attr("markerWidth", 6)
      .attr("markerHeight", 6)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M0,-5L10,0L0,5")
      .attr("fill", color);

    svg
      .append("marker")
      .attr("id", "arrowStart")
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", 2)
      .attr("markerWidth", 6)
      .attr("markerHeight", 6)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M10,-5L0,0L10,5")
      .attr("fill", color);
  };

  static drawMeasurement = (
    instanceId: string,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    label: string,
    color: string
  ) => {
    const mainG = d3.select("#" + instanceId).select("g");
    const k = d3.zoomTransform(mainG.node()).k;
    mainG
      .append("line")
      .attr("class", "measurement")
      .attr("x1", x1)
      .attr("y1", y1)
      .attr("x2", x2)
      .attr("y2", y2)
      .attr("marker-end", "url(#arrowEnd)")
      .attr("marker-start", "url(#arrowStart)")
      .attr("stroke", color)
      .attr("stroke-width", 2 / k);

    const textX = (x1 + x2) / 2;
    const textY = (y1 + y2) / 2;
    const fontSize = 16;
    mainG
      .append("text")
      .attr("class", "measurement")
      .style("font", "sans-serif")
      .style("font-size", fontSize / k)
      .style("fill", "black")
      .style("font-family", "monospace")
      .attr("dy", fontSize / 2 / k)
      .attr("text-anchor", "middle")
      .attr("stroke", color)
      .attr("stroke-width", 2 / k)
      .attr("paint-order", "stroke")
      .attr("x", textX)
      .attr("y", textY)
      .text(label);
  };
}
