import { ViewerRichText } from "@components/Comment/ViewerRichText";
import { EntryTime } from "@components/Timeline/EntryTime";
import { If } from "@components/Utils/Structural";
import { ICommentRichText } from "@models/comment.model";
import { isString } from "@shared/util/validations";
import { Avatar, Divider, theme } from "antd";
import { useEffect } from "react";

interface ICommentPunchListItemViewerProps {
  comment: ICommentRichText
}

export const CommentPunchListItemViewer = (props: ICommentPunchListItemViewerProps) => {

    const { comment } = props;

    const { token: { fontSizeSM } } = theme.useToken();

    useEffect(() => {
    }, [])
    
    return (
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          <div className="flex flex-row items-center mr-5">
            <If condition={isString(comment?.createdByFirstName)}>
              <Avatar className="cursor-default bg-color-neutral-4" gap={4}>
                <span className="text-color-neutral-8">
                  {`${comment?.createdByFirstName?.[0]}${comment?.createdByLastName?.[0]}`?.toUpperCase!()}
                </span>
              </Avatar>
            </If>
          </div>
          <div className="flex flex-row justify-between w-full items-center">
            <div className="flex flex-row flex-wrap">
              <span className="flex font-bold ml-5 text-color-original">
                {`${comment.createdByFirstName} ${comment.createdByLastName}`}
              </span>
            </div>
            <span className="text-color-neutral-6 w-max min-w-max" style={{ fontSize: fontSizeSM }}>
              <EntryTime date={comment.lastModifiedDate} />
            </span>
          </div>
        </div>
        <div className="w-full text-color-neutral-8 mt-15">{comment.payload && <ViewerRichText payload={comment.payload} />}</div>
        <Divider className="mt-15 mb-15" />
      </div>
    );
}
