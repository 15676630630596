import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { useAuth } from '../../providers/AuthProvider';
import { isLoggedIn } from "../../services/auth.service";
import { redirectIfExistParam } from '@HOOKs/UseLocalStorage';

export const ProtectedRoute = ({ children }: any) => {

  const navigate = useNavigate();
  
  const { user, getUserDataWithoutRedirect } = useAuth();

  // Itercom Helpscout Integration
  useEffect(() => {
    if (user && window) {      
      window.Intercom && window.Intercom('update', {
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        Instance: window.location.origin,
      })
    }
  }, [user])

  useEffect(() => {
    new Promise(async (res) => {
      await getUserDataWithoutRedirect().then(() => {
        if (!isLoggedIn()) {
          navigate("/login");
        } else {
          redirectIfExistParam(navigate, false);
        }
      });
      res(true);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return children;
};
