
import { DeleteOutlined, EyeOutlined, MinusCircleOutlined, PlusCircleOutlined, RetweetOutlined } from '@ant-design/icons';
import { Button, Divider, Table, Tag, Tooltip, Typography } from 'antd';
import { Callable } from '@faker-js/faker/utils/types';
import { DATE_FORMAT_DEFAULT } from '@shared/util/date-utils';
import { IAttachment } from 'models/attachment.model';
import { DownloadAttachmentButton } from './DownloadAttachmentButton';
import { getFirstItem, isArrayWithValues } from '@shared/util/array-util';
import { EmptyCellTable } from '@components/Utils/EmptyCellTable';
import { getExtensionOfFileName, isAnValidImage } from '@shared/util/document-utils';
import { DerivativesEnum } from '@models/enumerations/derivatives-enum.model';
import dayjs from 'dayjs';
import NestedTable from './NestedTable';

export interface IMainTableProps {
  loading: boolean
  onViewAction: Callable,
  onDeleteAction: Callable,
  showModalForNewRevision: Callable,
  attachmentListWithKeys: IAttachment[]
}

export default function MainTable(props: IMainTableProps) {

  const { loading, onViewAction, onDeleteAction, attachmentListWithKeys, showModalForNewRevision } = props;

  const isAnImage = (record: IAttachment) => {
    const filename = record?.fileName
    const extension = filename ? getExtensionOfFileName(filename) : "";
    return isAnValidImage(extension);
  }

  const hasPreviewOfImagen = (record: IAttachment) => {
    if (isAnImage(record)) { return true }
    const derivatives = isArrayWithValues(record?.derivatives) ? record?.derivatives : [];
    return derivatives?.some((img) => img.derivativeType === DerivativesEnum.PREVIEW);
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'fileName',
      key: 'fileName',
      ellipsis: {
        showTitle: false,
      },
      render: (value: string, record: IAttachment) => {
        if (record.versions && record.versions.length > 0) {
          const versionToShow = getFirstItem(record.versions);
          return versionToShow
            ? (<Tooltip placement="top" title={versionToShow.fileName}> <Typography.Text >{versionToShow.fileName}</Typography.Text> </Tooltip>)
            : <EmptyCellTable />
        } else {
          return record?.fileName
            ? (<Tooltip placement="top" title={record?.fileName}> <Typography.Text >{record?.fileName}</Typography.Text> </Tooltip>)
            : <EmptyCellTable />
        }
      }
    },
    {
      title: 'Version',
      dataIndex: 'createdBy',
      width: 60,
      key: 'Version',
      render: (value: string, record: IAttachment) => record.versions && <Tag>{`v${record.versions?.length}`}</Tag>
    },
    {
      title: 'Creator',
      dataIndex: 'createdBy',
      width: 100,
      key: 'createdBy',
      ellipsis: {
        showTitle: false,
      },
      render: (value: string, record: any) => {
        const versionToShow = getFirstItem(record.versions);
        return versionToShow ? <Tooltip placement="top" title={versionToShow?.createdByFirstName + " " + versionToShow?.createdByLastName}><Typography.Text style={{ width: '100px' }} ellipsis={true}>{versionToShow?.createdByFirstName + " " + versionToShow?.createdByLastName}</Typography.Text> </Tooltip> : <EmptyCellTable />
      }
    },
    {
      title: 'Upload Date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      ellipsis: {
        showTitle: false,
      },
      width: 110,
      render: (value: string, record: any) => {
        const versionToShow = getFirstItem(record.versions);
        return (versionToShow && versionToShow.createdDate) ? dayjs(versionToShow.createdDate)?.format(DATE_FORMAT_DEFAULT) : <EmptyCellTable />
      }
    },
    {
      key: 'action',
      width: 150,
      render: (_: any, attachmentRecord: IAttachment) => {

        const firstChildrenRecord = attachmentRecord.versions![0]!;

        return (
          <>
            <Tooltip placement="top" title={"Upload revision"}>
              <Button size='small' type='link' onClick={() => showModalForNewRevision(attachmentRecord.id)} ><RetweetOutlined /></Button >
            </Tooltip>
            <Divider type="vertical" className="ml-0 mr-0" />
            <Tooltip placement="top" title={"Preview"}>
              <Button size='small' disabled={!hasPreviewOfImagen(firstChildrenRecord)} type='link' onClick={() => onViewAction(firstChildrenRecord, attachmentRecord.versions)} ><EyeOutlined /></Button >
            </Tooltip>
            <Divider type="vertical" className="ml-0 mr-0" />
            {firstChildrenRecord.fileName && <DownloadAttachmentButton attachmentId={String(firstChildrenRecord.id)} fileName={firstChildrenRecord.fileName} />}
            <Divider type="vertical" className="ml-0 mr-0" />
            <Tooltip placement="top" title={"Delete"}>
              <Button size='small' type='link' onClick={() => onDeleteAction(firstChildrenRecord)} ><DeleteOutlined /></Button>
            </Tooltip>
          </>
        )
      },
    },
  ]



  return (
    <>
      <Table
        size='small'
        loading={loading}
        className='w-full'
        pagination={{ pageSize: 5 }}
        showHeader={false}
        dataSource={attachmentListWithKeys}
        columns={columns}
        expandable={{
          expandedRowRender: (record: IAttachment) => <NestedTable
            attachmentRecord={record} onViewAction={onViewAction}
            onDeleteAction={onDeleteAction}
            showModalForNewRevision={showModalForNewRevision} />,
          rowExpandable: (record) => true,
          expandIcon: ({ expanded, onExpand, record }) => {
            if (((record as IAttachment).versions?.length || 0) === 1) {
              return <PlusCircleOutlined disabled style={{ opacity: 0.2 }} />
            }

            return expanded ? (
              <MinusCircleOutlined onClick={e => onExpand(record, e)} />
            ) : (
              <PlusCircleOutlined onClick={e => onExpand(record, e)} />
            )
          }
        }}
      />
    </>
  )
}