import { useEffect, useState } from "react";
import { Button, Col, DatePicker, Drawer, Form, Input, InputNumber, Row, Select, Space, Switch } from "antd";
import { useAppDispatch } from "@store/store";
import { ICustomField } from "@models/custom-field.model";
import { CustomFieldTypeEnum } from "@models/enumerations/custom-field-type-enum.model";
import { DATE_FORMAT_DEFAULT } from '@shared/util/date-utils';
import { useErrorAnimation } from "@HOOKs/UseErrorAnimation";
import { StringORNumber } from "@infrastructure/repositories/utils.repository";
import { CustomFieldGroupTypeEnum } from "@models/enumerations/custom-field-group-type-enum.model";
import { ICustomFieldGroupExtendedUI } from "@models/custom-field-group.model";
import { If } from "@components/Utils/Structural";
import { allPropsOfObjectAreUndefined } from "@shared/util/object-utils";
import { getCustomFields, updateCustomFiels } from "@store/slices/generic-custom-fields";
import dayjs from "dayjs";

type InitialValues = {
    [key: number | symbol]: any
};

interface ICustomFieldGenericGroupUpdateProps {
    open: boolean
    toggle: () => void,
    referenceId: StringORNumber,
    referenceType: keyof typeof CustomFieldGroupTypeEnum
    customFieldGroupUI: ICustomFieldGroupExtendedUI
}

export const CustomFieldGenericGroupUpdate = (props: ICustomFieldGenericGroupUpdateProps) => {

    const { open, toggle, referenceType, referenceId, customFieldGroupUI } = props;

    const dispatch = useAppDispatch();
    const [animateError, playAnimationOfError] = useErrorAnimation();
    const [form] = Form.useForm();
    const [initialValues, setInitialValues] = useState<any>(null)
    const [customFieldGroup, setCustomFieldGroup] = useState<ICustomFieldGroupExtendedUI | null>(customFieldGroupUI)

    useEffect(() => {
        setCustomFieldGroup(customFieldGroupUI);
    }, [customFieldGroupUI]);

    useEffect(() => {
        
        if (customFieldGroup && open) {
            setInitialValues(null) 
            
            const initValuesProcessed = customFieldGroup && customFieldGroup.customFieldsChildren?.map!((customField) => {
                const customFieldValue = JSON.parse(customField.value)?.value;
    
                if (customField?.fieldType === CustomFieldTypeEnum.DATE) {
                    return { id: customField.id, value: dayjs(customFieldValue) }
                }
                return { id: customField.id, value: customFieldValue }
            })
            .reduce((accumulator, item) => {
                return { ...accumulator, [`${item.id}`]: item?.value }
            }, {})
    
            initValuesProcessed && setInitialValues(initValuesProcessed);
        }

    }, [customFieldGroup, open]);

    const onClose = (): void => {
        setInitialValues(null);
        setCustomFieldGroup(null);
        form.resetFields();
        toggle();
    };

    const onFinish = (values: InitialValues): void => {

        if (allPropsOfObjectAreUndefined((values))) {
            onClose();
            return;
        }

        const customFieldGroup = { ...customFieldGroupUI };

        if (customFieldGroup?.customFieldsChildren) {
            customFieldGroup.customFieldsChildren = customFieldGroup?.customFieldsChildren?.map!((item) => {
                const constValueChanged = item?.id && values[item?.id];
                return { ...item, value: JSON.stringify({ value: constValueChanged }) }
            });
        }

        dispatch(updateCustomFiels({
            ...customFieldGroup,
            referenceId: referenceId
        })).then(() => {
            onClose();
            dispatch(getCustomFields({ referenceType: CustomFieldGroupTypeEnum[referenceType], referenceId }));
        });

    }

    const onFinishFailed = () => playAnimationOfError();

    const onSubmit = () => form.submit();

    // Render Edit Components
    const RenderTypeOfField = ({ customField }: {customField: ICustomField }) => {
        const { fieldType, label, id, config } = customField;

        switch (fieldType as CustomFieldTypeEnum) {
        case 'STRING':
        case 'INTEGER':
        case 'DECIMAL':
            return (
                <Form.Item name={`${id}`} label={`${label}`}>
                    <Input
                        className="w-full"
                        placeholder={`Please enter ${label}`}
                        onInput={() => false}
                    />
                </Form.Item>
            );

        case 'CURRENCY':
            return (
                <Form.Item name={`${id}`} label={`${label}`}>
                    <InputNumber
                        className="w-full"
                        placeholder={`Please enter ${label}`}
                        formatter={value => `$ ${value}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                        parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : ""}
                        onInput={() => false}
                    />
                </Form.Item>
            );

        case 'BOOLEAN':
            return (
                <Form.Item name={`${id}`} label={label} valuePropName="checked">
                    <Switch />
                </Form.Item>
            );

        case 'DATE':
            return (
                <Form.Item name={`${id}`} label={`${label}`} valuePropName='date'>
                    <DatePicker
                        name={`${id}`}
                        className="w-full"
                        placeholder={`Please select a date`}
                        format={DATE_FORMAT_DEFAULT}
                        defaultValue={initialValues[id as any] ? initialValues[id as any] : undefined} // this is a hack because datepicker is not picking initial values from form item
                    />
                </Form.Item>
            );

        case 'SELECTOR':
            {
                const pasedOptions = JSON.parse(config).options;
                let antFormattedOptions: any
                if (Array.isArray(pasedOptions)) {
                    antFormattedOptions = pasedOptions.map((it, index) => {
                    return { value: it, label: it }
                    })
                }

                return (
                    <Form.Item name={`${id}`} label={`${label}`}>
                        <Select
                            placeholder={`Please select an option`}
                            options={antFormattedOptions}
                        />
                    </Form.Item>
                )
            }
        default:
            return (
                <div style={{ margin: '20px 0' }}>
                    <p> {label}</p>
                    <Input
                        className="w-full"
                        placeholder={`Not Implemented for type ${fieldType}`}
                        disabled={true}
                    />
                </div>
            );
        }
    } 

    return (
        <>
            <Drawer
                title={`Edit ${customFieldGroupUI?.name}`}
                width={620}
                onClose={onClose}
                open={open}
                closable={false}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button type="primary" ghost onClick={onClose}>
                            Cancel
                        </Button>
                        <Button onClick={onSubmit} type="primary">
                            Save
                        </Button>
                    </Space>
                }
            >
                <If condition={initialValues !== null}>
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={initialValues}
                        className={`${animateError && "error-animation"}`}
                    >
                    {
                        customFieldGroup?.customFieldsChildren?.map((customfield, i) => (
                            <Row gutter={16} key={customfield.id}>
                                <Col span={24}>
                                    {(referenceId) && <RenderTypeOfField customField={customfield} />}
                                </Col>
                            </Row>
                        ))
                    }
                    </Form>
                </If>
            </Drawer>
        </>
    )
}
