import { defaultValue, IAttachment, onProgressEvent } from './../../models/attachment.model';

import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { attachmentService } from '../../services/attachment.service';
import { cleanEntity } from '../../shared/util/entity-utils';
import { createEntitySlice, EntityState, serializeAxiosError } from "../reducer.util";
import { RcFile } from 'antd/es/upload';
import { asyncLaunchNotification } from './notification';
import { StringORNumber } from '@infrastructure/repositories/utils.repository';

const nameOfEntity = "attachment";
const service = attachmentService;

export interface IAttachmentCriteria {
  "referenceType.equals"?: string
  "referenceId.equals"?: string
}

export interface UploadProgressEvent extends Partial<ProgressEvent> {
  percent?: number;
}

export interface ICreateAttachmentAndUpload {
  attachment: IAttachment;
  file: RcFile | Blob | string;
  onSuccess?: () => void;
  onProgress?: (event: onProgressEvent) => void;
}

export interface IDeleteAttachment {
  id: string | number;
  onSuccess?: () => void;
}

export interface IUploadNewRevision {
  attachmentId: number;
  file: RcFile | Blob | string;
}

export const getAttachmentsOfProjectDeliverable = createAsyncThunk(
  `${nameOfEntity}/fetch_entity_list_related_to_deliverable`,
  async (projectDeliverableId: string) => {
    return service.getAttachmentsOfProjectDeliverable(projectDeliverableId);
  },
  { serializeError: serializeAxiosError }
);

export const getByReferenceTypeAndReferenceId = createAsyncThunk(
  `${nameOfEntity}/fetch_entity_list_by_reference`,
  async ({ referenceType, referenceId }: { referenceType: string, referenceId: StringORNumber }, thunkAPI) => {
    return service.getByReferenceTypeAndReferenceId(referenceType, referenceId);
  },
  { serializeError: serializeAxiosError }
);

export const getEntities = createAsyncThunk(
  `${nameOfEntity}/fetch_entity_list`,
  async (queryParams: IAttachmentCriteria) => {
    return service.getAll(queryParams);
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  `${nameOfEntity}/fetch_entity`,
  async (id: string | number) => {
    return service.getById(id);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  `${nameOfEntity}/create_entity`,
  async (createAttachmentAndUpload: ICreateAttachmentAndUpload, thunkAPI) => {

    const resultEntity = await service.create(createAttachmentAndUpload);

    createAttachmentAndUpload?.onSuccess && createAttachmentAndUpload.onSuccess();
    return resultEntity;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  `${nameOfEntity}/update_entity`,
  async (entity: IAttachment, thunkAPI) => {
    const result = entity.id && await service.update(entity.id, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  `${nameOfEntity}/delete_entity`,
  async (payload: IDeleteAttachment, thunkAPI) => {
    const result = await service.delete(payload.id).then(() => {
      thunkAPI.dispatch(asyncLaunchNotification({
        type: "success",
        config: {
          message: `Attachments`,
          description: `File deleted successfully`
        }
      }));
      payload?.onSuccess && payload.onSuccess();
    });
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const countAttachments = createAsyncThunk(
  `${nameOfEntity}/countAttachment`,
  async ({ referenceType, referenceId }: { referenceType: string, referenceId: StringORNumber }) => {
    return service.getCount({ referenceType, referenceId });
  },
  { serializeError: serializeAxiosError }
)

const initialState: EntityState<IAttachment> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export const AttachmentSlice = createEntitySlice({
  name: nameOfEntity,
  initialState,
  reducers: {
    clearEntity: (state,) => {
      state.entity = defaultValue;
    },
    removeAttachmentFromListOfAttachments: (state, action: { payload: { attachment: IAttachment }}) => {
      const { attachment } = action.payload;

      // Get Index in List
      // const attachmentInListIndex = isArrayWithValues(state.entities) ? state.entities.findIndex((attachmentStored) => String(attachmentStored.id) ===  String(attachment?.id)) : -1;

      // If there is an Index, set new Photo Album
      if (attachment.id) { // isNumber(attachmentInListIndex) && Boolean(attachmentInListIndex! > -1)) { 
        state.entities = state.entities.filter((item) => item.id !== attachment.id);
      }
      
      return state;
    },
    setLoading: (state, action) => {  
      state.loading = action.payload;
      return state;
    },
    reset: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: data.length,
        };
      })
      .addMatcher(isFulfilled(getAttachmentsOfProjectDeliverable, getByReferenceTypeAndReferenceId), (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: data.length,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload as IAttachment;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(getAttachmentsOfProjectDeliverable, getByReferenceTypeAndReferenceId, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isFulfilled(countAttachments), (state, action) => { 
        state.totalItems = action.payload as number
      })
      .addMatcher(isRejected(getByReferenceTypeAndReferenceId), (state, action) => { 
        state.loading = false
      })
  }
});

export const { reset, clearEntity, removeAttachmentFromListOfAttachments, setLoading } = AttachmentSlice.actions;

// Reducer
export default AttachmentSlice.reducer;

