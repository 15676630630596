/* eslint-disable react-hooks/exhaustive-deps */
import { FeatureFlagOptionEnum } from "@models/enumerations/feature-flag-option.enum";
import { useFeatureFlagsProvider } from "@providers/FeatureFlagsProvider";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const DEFAULT_REDIRECT_TO = '/403';

interface IUseRedirectIfFeatureIsNotEnabledProps {
    redirectTo?: string;
    feature: FeatureFlagOptionEnum
}

export const useRedirectIfFeatureIsNotEnabled = (props: IUseRedirectIfFeatureIsNotEnabledProps) => {

    const { 
        redirectTo = DEFAULT_REDIRECT_TO, 
        feature 
    } = props;

    const navigate = useNavigate();
    const { isFlagEnabled } = useFeatureFlagsProvider();

    useEffect(() => {
        if ( !isFlagEnabled(feature) ) {
            navigate(redirectTo);
        }
    }, [feature, redirectTo]);
}
