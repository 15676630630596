import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { projectDeliverableReasonsService } from '../../services/project-deliverable-reasons.service';
import { cleanEntity } from '../../shared/util/entity-utils';
import { IQueryParams } from '../../models/pagination';
import { createEntitySlice, EntityState } from "../reducer.util";
import { serializeGenericHandleError } from '@store/thunk.util';
import { IProjectDeliverableReason, defaultValue } from '../../models/project-deliverable-reason.model';

const initialState: EntityState<IProjectDeliverableReason> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const service = projectDeliverableReasonsService;
const nameOfEntity = 'projectDeliverableReason';

// Actions

export const getEntities = createAsyncThunk('projectDeliverableReason/fetch_entity_list', 
  async ({ page, size, sort }: IQueryParams) => {
    return service.getAll({});
  },
  { serializeError: serializeGenericHandleError }
);

export const getEntity = createAsyncThunk(
  `${nameOfEntity}/fetch_entity`,
  async (id: string | number) => {
    return service.getById(id);
  },
  { serializeError: serializeGenericHandleError }
);

export const createEntity = createAsyncThunk(
  `${nameOfEntity}/create_entity`,
  async (entity: IProjectDeliverableReason, thunkAPI) => {
    const result = await service.create(cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeGenericHandleError }
);

export const reorderProjectDeliverableReason = createAsyncThunk(
  `${nameOfEntity}/reorder`,
  async (ids: number[], thunkAPI) => {
    const result = await service.reorder(ids);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeGenericHandleError }
);

export const updateEntity = createAsyncThunk(
  `${nameOfEntity}/update_entity`,
  async (entity: IProjectDeliverableReason, thunkAPI) => {
    const result = entity.id && await service.update(entity.id, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeGenericHandleError }
);

export const deleteEntity = createAsyncThunk(
  `${nameOfEntity}/delete_entity`,
  async (id: string | number, thunkAPI) => {
    const result = await service.delete(id);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeGenericHandleError }
);

// slice

export const ProjectDeliverableReasonSlice = createEntitySlice({
  name: 'projectDeliverableReason',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload as IProjectDeliverableReason;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = ProjectDeliverableReasonSlice.actions;

// Reducer
export default ProjectDeliverableReasonSlice.reducer;
