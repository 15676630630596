import { ICostCategory } from "@models/cost-category.model";
import { createAsyncThunk, createSlice, isFulfilled, isPending } from "@reduxjs/toolkit";
import { cleanEntity } from "@shared/util/entity-utils";
import { serializeGenericHandleError } from "@store/thunk.util";
import { costCategoryService } from '@services/cost-category.service';

interface IReorderParams {
  costGroupingId: string | number
  ids: number[]
}

export const createEntity = createAsyncThunk(
    'costCategory/create_entity',
    async (entity: ICostCategory, thunkAPI) => {
        const result = await costCategoryService.create(cleanEntity(entity));
      return result;
    },
    { serializeError: serializeGenericHandleError }
  );
  
  export const updateEntity = createAsyncThunk(
    'costCategory/update_entity',
    async (entity: ICostCategory, thunkAPI) => {
        const result = entity.id && await costCategoryService.update(entity.id, cleanEntity(entity));
      return result;
    },
    { serializeError: serializeGenericHandleError }
  );

  export const reorderCostCategories = createAsyncThunk(
    `costCategory/reorder`,
    async (params: IReorderParams, thunkAPI) => {
      const result = await costCategoryService.reorder(params.costGroupingId, params.ids);
      return result;
    },
    { serializeError: serializeGenericHandleError }
  );

  export const getAllCostCategories = createAsyncThunk(
    `costCategory/getAll`,
    async (thunkAPI) => {
      const result = await costCategoryService.getAllCostCategories();
      return result;
    },
    { serializeError: serializeGenericHandleError }
  );

interface ICostCategoryState {
    loading: boolean;
    errorMessage: string | null;
    entities: ReadonlyArray<ICostCategory>;
}

const initialState: ICostCategoryState = {
    loading: false,
    errorMessage: null,
    entities: []
};

export const slice = createSlice({
    name: 'costCategory',
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
        .addMatcher(isFulfilled(getAllCostCategories), (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                loading: false,
                entities: data
            };
        })
        .addMatcher(isPending(getAllCostCategories), state => {
            state.errorMessage = null;
            state.loading = true;
        });
    }
});

// Reducer
export default slice.reducer;