import { BackButton } from "@components/Utils/BackButton";
import { resetPunchListItemSelected } from "@store/slices/punch-list";
import { useAppDispatch } from "@store/store";
import { Col, Row, theme } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

export const BackHeader = () => {

    const { token: { padding, colorBgContainer } } = theme.useToken();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();

    const navigateToList = () => {
        const baseUrl = pathname?.split?.("punch-list")?.[0] || "";
        const newPath = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
        navigate(`${newPath}/punch-list`);
        
        dispatch(resetPunchListItemSelected())
    }

    return (
        <Row
            style={{ 
                paddingLeft: `${padding}px`,
                paddingRight: `${padding}px`,
                backgroundColor: colorBgContainer 
            }}
            className="pt-10 pb-10 w-full"
        >
            <Col span={24}>
                <BackButton onClick={navigateToList} >Punch List</BackButton>
            </Col>
        </Row>
    );
}