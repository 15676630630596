/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { InfoButon } from "../Toolbar/InfoButon";
import { CommentButton } from "../Toolbar/CommentButton";
import { DownloadButton } from "../Toolbar/DownloadButton";
import { DeleteButton } from "../Toolbar/DeleteButton";
import { IAttachment } from "@models/attachment.model";
import { PANELS } from "../../FilePreviewModal";
import { DrawerInfoAttachment } from "./DrawerInfoAttachment";
import { DrawerCommentsAttachment } from "./DrawerCommentsAttachment";
import { Badge, theme } from 'antd';
import { useAppDispatch, useAppSelector } from "@store/store";
import { WHITE_ABSOLUTE } from "@providers/ThemeProvider";
import { getTotalCommmentsCount } from "@store/slices/comment-v2";
import { CommentReferenceEnum } from "@models/enumerations/comment-reference-enum.model";
import { isStringOrNumber } from '@shared/util/validations';
import useVisible from "@HOOKs/UseVisible";

interface IMenuOptionsMobileProps {
    isVisible: boolean;
    attachment: IAttachment;
    setIsVisiblePopover: (isVisible: boolean) => void;
    closeFilePreview: () => void;
    onSuccessDeleteAttachment: () => void;
}

export const MenuOptionsMobile = (props: IMenuOptionsMobileProps) => {

    const { isVisible, attachment, setIsVisiblePopover, closeFilePreview, onSuccessDeleteAttachment } = props;
    const { token: { colorPrimary, fontSizeSM } } = theme.useToken();
    
    const [panelActive, setPanelActive] = useState<PANELS | null>(null);
    const [visibleDrawerInfoPanel, setVisibleDrawerInfoPanel] = useVisible();
    const [visibleDrawerCommentsPanel, setVisibleDrawerCommentsPanel] = useVisible();
    
    const { totalItems = 0 } = useAppSelector((state) => state.CommentVersion2Slice);

    const dispatch = useAppDispatch();

    useEffect(() => {
      if (isStringOrNumber(attachment?.id)) {
        dispatch(getTotalCommmentsCount({ 
          referenceId: Number(attachment.id), 
          referenceType: CommentReferenceEnum.PHOTO_ALBUM_ATTACHMENT 
        }));
      }
    }, []);

    useEffect(() => {
      if (!isVisible) {
        setPanelActive(null);
      }
      return () => {
        setPanelActive(null);
      }
    }, [isVisible]);

    const activeInfoPanel = () => {
      setPanelActive(PANELS.INFO);
      setVisibleDrawerInfoPanel();
    }

    const activeCommentPanel = () => {
      setPanelActive(PANELS.COMMENTS);
      setVisibleDrawerCommentsPanel();
    }
    
    const onSuccessDeleteAttachmentHandler = () => {
      setPanelActive(null);
      onSuccessDeleteAttachment();
    };

    const onFinishProcessDelete = () => {
      setIsVisiblePopover(false);
      closeFilePreview();
    };

    return (
      <>
          <div className="flex flex-col items-center justify-center pr-8 pl-8 pt-16 pb-16">
            <div className="flex flex-row items-center justify-center mb-16">
              <InfoButon 
                handleOnClick={activeInfoPanel}
                isActive={panelActive === PANELS.INFO} 
              />
            </div>
            <div className="flex flex-row items-center justify-center mb-16">
              <Badge 
                count={totalItems && <span style={{ color: WHITE_ABSOLUTE, fontSize: fontSizeSM }}>{totalItems}</span>}
                size="small"
                style={{ 
                    display: "flex", 
                    alignItems: "center",
                    backgroundColor: colorPrimary, 
                    paddingLeft: 8, 
                    paddingRight: 8, 
                    height: 20, 
                    borderRadius: 100 
                }} 
              >
                <CommentButton 
                  handleOnClick={activeCommentPanel} 
                  isActive={panelActive === PANELS.COMMENTS} 
                />
              </Badge>
            </div>
            <div className="flex flex-row items-center justify-center mb-16">
              <DownloadButton attachment={attachment} />
            </div>
            <div className="flex flex-row items-center justify-center">
              <DeleteButton 
                attachment={attachment} 
                onSuccessDeleteAttachment={onSuccessDeleteAttachmentHandler} 
                onFinishProcessDelete={onFinishProcessDelete} 
              />
            </div>
          </div>
          <div>
            <DrawerInfoAttachment 
              open={visibleDrawerInfoPanel}
              onClose={setVisibleDrawerInfoPanel}
              attachment={attachment}
            />
            <DrawerCommentsAttachment
              open={visibleDrawerCommentsPanel}
              onClose={setVisibleDrawerCommentsPanel}
              attachment={attachment} 
            />
          </div>
      </>
    )
}
