import { ICompanyRole } from './company-binding.model';
import { IDiscipline } from './discipline.model';
import { IPhase } from './phase.model';
import { IRole } from './role.model';

export interface IDeliverable {
  id?: number;
  name?: string | null;
  infoGuide?: string;
  isKeyMilestone?: boolean | null;
  isOpeningMilestone?: boolean | null;
  discipline?: IDiscipline | null;
  phase?: IPhase | null;
  role?: IRole | null;
  companyRole?: ICompanyRole | null;
}

export const defaultValue: Readonly<IDeliverable> = {
  isKeyMilestone: false,
};
