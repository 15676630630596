import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AppDispatch, useAppSelector } from '@store/store';
import { useDispatch } from 'react-redux';
import { getEntity } from '@store/slices/location';
import { getEntities as getBrands } from '@store/slices/brand';
import { EmptyLabel } from '../Utils/EmptyLabel';
import { ILocation } from '@models/location.model';

export const LocationNameLabel = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { entity: locationEntity } = useAppSelector(state => state.Location);
  const brands = useAppSelector(state => state.Brand.entities);

  const [singularBrand, setSingularBrand] = useState<boolean>(false);

  const { id } = useParams<'id'>();

  useEffect(() => {
    if (id && locationEntity && String(locationEntity.id) !== id) {
      dispatch(getEntity(id));
    }
    if (brands.length === 0) {
      dispatch(getBrands({}));
    }
  }, [dispatch, id, locationEntity, brands]);

  useEffect(() => {
    setSingularBrand(brands.length === 1);
  }, [brands]);

  const getSiteLabel = (locationEntity: ILocation) => {
    return locationEntity && locationEntity?.brand?.description && !singularBrand ? `(${locationEntity.brand?.description})` : '';
  };

  return <div>{`${locationEntity?.name || ''} ${getSiteLabel(locationEntity) || ''}` || <EmptyLabel />}</div>;
};
