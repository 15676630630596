import { Helmet } from 'react-helmet-async'
import { Outlet } from 'react-router-dom'
import { configuration } from '../environments/env'
import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { usePageContentSize } from '@HOOKs/UsePageContentSize';
import { SearchHeader } from '@components/Search/SearchHeader';

export default function SearchPage() {

  const PageContent = () => {

    const [layoutRef]= usePageContentSize()

    return (
      <Layout >
          <SearchHeader />
          <Content ref={layoutRef} >
            <Outlet />
          </Content>
      </Layout>
    )
  }
  
  const Page = () => (
    <>
      <Helmet>
        <title>Search | { configuration.APP_NAME }</title>
      </Helmet>
      <PageContent />
    </>
  )
  return (<Page />)
}