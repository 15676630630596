
import { isFulfilled } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, isRejectedAction, serializeAxiosError } from "../reducer.util";
import { createAsyncThunk } from "@reduxjs/toolkit"
import { companyContactService } from '../../services/company-contacts-extended.service';
import { cleanEntity } from '../../shared/util/entity-utils';
import { ICompanyContact, defaultValue } from '../../models/company.contact.model';
import { IQueryParams } from '@models/pagination';

const nameOfEntity = 'companyContactExtended';
const service = companyContactService;

export const getEntitiesByCompany = createAsyncThunk(
  `${nameOfEntity}/fetch_entity_list`,
  async (queryParams: IQueryParams) => {
    return service.getAll(queryParams);
  },
  { serializeError: serializeAxiosError }
);


export const createEntity = createAsyncThunk(
  `${nameOfEntity}/create_entity`,
  async (entity: ICompanyContact, thunkAPI) => {
    const result = await service.create(cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  `${nameOfEntity}/get_entity`,
  async (id: string | number, thunkAPI) => {
    const result = await service.get(id);
    return result;
  },
  { serializeError: serializeAxiosError }
);

const initialState: EntityState<ICompanyContact> = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

export const CompanyContactSlice = createEntitySlice({
  name: nameOfEntity,
  initialState,
  reducers: {
    clearEntity: state => {
      state.entity = defaultValue;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getEntitiesByCompany), (state, action) => {
        const { data, headers } = action.payload;
        const xTotalCount = headers['x-total-count'];

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: xTotalCount ? parseInt(xTotalCount, 10) : data.length,
        };
      })
      .addMatcher(isFulfilled(createEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload as ICompanyContact;
      })
      .addMatcher(isFulfilled(getEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload as ICompanyContact;
      });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.entity = {
        firstName: '-',
        lastName: '-',
      };
      state.loading = false;
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.error.message;
    });
  },
});

export const { reset, clearEntity } = CompanyContactSlice.actions;

// Reducer
export default CompanyContactSlice.reducer;

  