import { IQueryParams } from '../../models/pagination';
import { http } from '../http/axios.instance';
import { StringORNumber } from './utils.repository';
import { IDeliverable } from '../../models/deliverable.model';

const path = 'deliverables';

export const deliverablesRepository = {
  getAll: async (queryParams: IQueryParams) => {

    const { nameFilter } = queryParams;
    const params = new URLSearchParams({
      cacheBuster: `${new Date().getTime()}`,
    })
    const nameFilterParam = (nameFilter ? '&name.contains=' + nameFilter : '');
    const requestUrl = `${path}?${params.toString()}${nameFilterParam}`;
  
    const promiseGetAll = await http.get<IDeliverable[]>(requestUrl);
    return promiseGetAll;
  },
  getById: async (id: StringORNumber) => {

    const requestUrl = `${path}/${id}`;
    const promiseGetByID = await http.get<IDeliverable>(requestUrl);
    return promiseGetByID;
  },
  create: async (entity: IDeliverable): Promise<IDeliverable> => {

    const requestUrl = `${path}`;
    const promiseCreate = await http.post<IDeliverable>(requestUrl, entity);
    return promiseCreate.data;
  },
  update: async (id: string | number, entity: IDeliverable): Promise<IDeliverable> => {

    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.put<IDeliverable>(requestUrl, entity);
    return promiseUpdate.data;
  },
  delete: async (id: string | number) => {

    const requestUrl = `${path}/${id}`;
    const promiseDelete = await http.delete<IDeliverable>(requestUrl);
    return promiseDelete;
  },
}