import { Col, Row, Steps, Typography, theme } from "antd";
import { useEffect, useState } from "react";
import { If } from '@components/Utils/Structural';
import { ProcessingFilesCardStep } from "./ProcessingFilesCardStep";
import { SelectPagesStep } from "./SelectPagesStep";
import { UploadFileStep } from "./UploadFileStep";
import { useParams } from "react-router-dom";
import { IErrorUploading, defaultErrorUploading } from "@models/alert-error-uploading";

const { Title } = Typography;

interface IUploadFloorPlanProps {
    step?: UploadFloorPlanStepsEnum
}

export enum UploadFloorPlanStepsEnum {
   UPLOAD_FILE,
   PROCESSING_FILE,
   SELECT_PAGE
}
  

const items = [
    {
        title: 'Upload File',
    },
    {
        title: 'Processing Files',
    },
    {
        title: 'Select Pages',
    }
];

export const UploadFloorPlan = (props: IUploadFloorPlanProps) => {

    const { step = UploadFloorPlanStepsEnum.UPLOAD_FILE } = props;

    const { token: { colorPrimary }} = theme.useToken();

    const { generationId } = useParams<"generationId">()

    const [currentStep, setCurrentStep] = useState<UploadFloorPlanStepsEnum>(step);
    
    const [error, setError] = useState<IErrorUploading>(defaultErrorUploading);

    const [punchListGenerationId, setPunchListGenerationId] = useState<string | number | null>(null);

    useEffect(() => {
        generationId && setPunchListGenerationId(generationId)
    }, [generationId]);
    
    return (
        <>    
            <Row>
                <Col 
                    span={24}
                    className='text-center mb-15 mt-15'
                >
                    <Title 
                        className="mt-9" 
                        level={4} 
                        style={{ color: colorPrimary, fontWeight: 500 }} 
                    >
                        Upload Floor Plan
                    </Title>
                </Col>
            </Row>
            <Row justify={"center"}>
                <Col span={12}>
                        <Steps 
                            progressDot
                            current={currentStep}
                            items={items}
                            style={{ fontWeight: 500 }}
                        />
                </Col>
            </Row>
            <If condition={currentStep === UploadFloorPlanStepsEnum.UPLOAD_FILE}>
                <Row className="mt-40" justify={"center"}>
                    <Col span={10}>
                        <UploadFileStep 
                            error={error}
                            setCurrentStep={setCurrentStep}
                            setPunchListGenerationId={setPunchListGenerationId}
                        />
                    </Col>
                </Row>
            </If>
            <If condition={currentStep === UploadFloorPlanStepsEnum.PROCESSING_FILE}>
                <Row className="mt-40" justify={"center"}>
                    <Col span={10}>
                        <ProcessingFilesCardStep
                            setError={setError}
                            punchListGenerationId={punchListGenerationId}
                            setCurrentStep={setCurrentStep}
                        />
                    </Col>
                </Row>
            </If>
            <If condition={currentStep === UploadFloorPlanStepsEnum.SELECT_PAGE}>
                <Row className="mt-40" justify={"center"}>
                    <Col span={24}>
                        <SelectPagesStep 
                            punchListGenerationId={punchListGenerationId}
                        />
                    </Col>
                </Row>
            </If>
        </>
    )
}
