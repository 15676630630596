/* eslint-disable react-hooks/exhaustive-deps */
import { HeaderAlbumList } from "./HeaderAlbumList";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Button, Col, Row, Spin, theme } from "antd";

import { useAppDispatch, useAppSelector } from "@store/store";
import { AlbumCard } from "./AlbumCard";
import { isString, isStringOrNumber } from "@shared/util/validations";
import { isArrayWithValues } from "@shared/util/array-util";
import { getEntityById, getPhotoAlbumEntities } from "@store/slices/photo-album";
import { AttachmentReferenceEnum } from "@models/enumerations/attachment-reference-enum.model";
import { PAGINATION_SIZE_DEFAULT_IN_PHOTOS } from "@constants/core";
import { getPhotosByReferenceTypeAndReferenceId } from "@store/slices/generic-album-gallery-multimedia";
import { usePhotoAlbumContextProvider } from "./PhotoAlbumProvider";
import { URL_PARAM_ALBUM_SELECTED_KEY } from "@constants/photoAlbum";

interface IAlbumListCardsProps {}

export const AlbumListCards = (props: IAlbumListCardsProps) => {

    const { entities: albumList, totalItems, loadingListAlbums, currentPage } = useAppSelector((store) => store.PhotoAlbum);

    const { token: { colorText } } = theme.useToken();

    const dispatch = useAppDispatch()

    let [searchURLParams, setURLSearchParams] = useSearchParams();

    const [pageCounter, setPageCounter] = useState<number>(0);

    const { referenceId, referenceType } = usePhotoAlbumContextProvider()

    const loadAnotherPage = () => {
        dispatch(getPhotoAlbumEntities({ referenceId, referenceType , page: pageCounter + 1 }));
        setPageCounter(pageCounter + 1);
    }

    useEffect(() => {
        setPageCounter(currentPage);
    }, [currentPage]);

    const setAlbumSelected = (albumId: string) => {
        dispatch(getEntityById(String(albumId)));
        
        dispatch(getPhotosByReferenceTypeAndReferenceId({ referenceId: albumId,
            referenceType: AttachmentReferenceEnum.PHOTO_ALBUM,
            page: 0,
            size: PAGINATION_SIZE_DEFAULT_IN_PHOTOS 
        }));
    }

    // Get Basic Filter Params and Set the First Time in Store.
    useEffect(() => {
        const albumIdSelected = searchURLParams?.get?.(URL_PARAM_ALBUM_SELECTED_KEY);
        if (albumIdSelected) {
            setAlbumSelected(String(albumIdSelected));
        }
    }, []);

    useEffect(() => {
        const albumSelected = searchURLParams?.get?.(URL_PARAM_ALBUM_SELECTED_KEY);
        const isAnValidAlbumSelected = isString(albumSelected) && (+albumSelected! > 0);
        if (isAnValidAlbumSelected) {
            setTimeout(() => {
                setURLSearchParams((prev) => {
                    prev.delete(URL_PARAM_ALBUM_SELECTED_KEY);
                    prev.append(URL_PARAM_ALBUM_SELECTED_KEY, isString(albumSelected) ? albumSelected! : String(albumSelected));
                    return prev;
                });
            }, 50);
        }
        else if (!isAnValidAlbumSelected && isArrayWithValues(albumList)) {
            const albumId = albumList[0].id;
            if (isStringOrNumber(albumId)) {
                setURLSearchParams((prev) => {
                    prev.set(URL_PARAM_ALBUM_SELECTED_KEY, String(albumId));
                    return prev;
                });
                setAlbumSelected(String(albumId));
            }
        }
    }, [albumList])

    return (
      <div className="pb-16">
        <HeaderAlbumList />
        <Row className="h-full pr-30 pl-30 flex flex-col">
            <Col span={24}>
                {
                    albumList.map((album) => {
                        return (
                            <div key={album.id}>
                                <AlbumCard album={album} />
                            </div>
                        )
                    })
                }
            </Col>
        </Row>
        {
            loadingListAlbums && 
                <div className='flex flex-row items-center justify-center w-full pl-10 pt-8 pb-8 mt-10'>
                    <Spin />
                </div>
        }
        {(albumList?.length < totalItems!) && 
            <Row className="pt-5 pb-10 pl-30 w-full" >
                <Col span={24} style={{ color: colorText }} >
                <div className='flex flex-row items-center pl-30 pt-5 pb-8 mt-5'>
                    <span>There are {totalItems} albums in total,</span> &nbsp; <Button style={{ padding: '0px' }} onClick={() => loadAnotherPage()} type="link">load more albums.</Button>
                </div>
                </Col>
            </Row >
        }
        
      </div>
    )
}

