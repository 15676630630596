/* eslint-disable react-hooks/exhaustive-deps */
import { useThemeProvider } from "@providers/ThemeProvider";
import { useEffect } from "react";
import { getLib } from "./LoaderRevealLibrary";

export const useRevealContainer = (revealViewRef) => {

    const { themeConfig } = useThemeProvider();

    useEffect(() => { 
        themeConfig?.darkMode ? setDarkMode() : setLightMode();
    }, [themeConfig]);

    const setDarkMode = () => {
        const instance = getLib();
        if (instance && instance.RevealSdkSettings) {
            //set theme after reveal view has been loaded
            instance.RevealSdkSettings.theme = new instance.MountainDarkTheme(); 

            //refresh reveal view theme
            revealViewRef?.current?.refreshTheme?.();
        }
    }

    const setLightMode = () => {
        const instance = getLib();
        if (instance && instance.RevealSdkSettings) {
            //set theme after reveal view has been loaded
            instance.RevealSdkSettings.theme = new instance.MountainLightTheme();

            //refresh reveal view theme
            revealViewRef?.current?.refreshTheme?.();
        }
    }

    return { themeConfig, setDarkMode, setLightMode }

}