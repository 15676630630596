import { companyContactRepository } from '../infrastructure/repositories/company-contact.repository';

export const companyContactService = {
  getAll: companyContactRepository.getAll,
  getById: companyContactRepository.getById,
  getByCompanyId: companyContactRepository.getByCompanyId,
  create: companyContactRepository.create,
  update: companyContactRepository.update,
  updatePartial: companyContactRepository.updatePartial,
  delete: companyContactRepository.delete
}