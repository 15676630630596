import React, { SVGProps } from 'react' 

type PinMapSvgIconProps = SVGProps<SVGSVGElement>

export const PinMapSvgIcon = (props: PinMapSvgIconProps) => {

    const { ...restProps } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 10 14" fill="none" {...restProps}>
            <path d="M4.99998 3.15C5.4641 3.15 5.90922 3.33437 6.23741 3.66256C6.5656 3.99075 6.74998 4.43587 6.74998 4.9C6.74998 5.12981 6.70471 5.35738 6.61677 5.5697C6.52882 5.78202 6.39991 5.97493 6.23741 6.13744C6.07491 6.29994 5.88199 6.42884 5.66967 6.51679C5.45735 6.60474 5.22979 6.65 4.99998 6.65C4.53585 6.65 4.09073 6.46563 3.76254 6.13744C3.43435 5.80925 3.24998 5.36413 3.24998 4.9C3.24998 4.43587 3.43435 3.99075 3.76254 3.66256C4.09073 3.33437 4.53585 3.15 4.99998 3.15ZM4.99998 0C6.29954 0 7.54587 0.516249 8.4648 1.43518C9.38373 2.3541 9.89998 3.60044 9.89998 4.9C9.89998 8.575 4.99998 14 4.99998 14C4.99998 14 0.0999756 8.575 0.0999756 4.9C0.0999756 3.60044 0.616224 2.3541 1.53515 1.43518C2.45408 0.516249 3.70041 0 4.99998 0ZM4.99998 1.4C4.07172 1.4 3.18148 1.76875 2.5251 2.42513C1.86872 3.0815 1.49998 3.97174 1.49998 4.9C1.49998 5.6 1.49998 7 4.99998 11.697C8.49998 7 8.49998 5.6 8.49998 4.9C8.49998 3.97174 8.13123 3.0815 7.47485 2.42513C6.81847 1.76875 5.92823 1.4 4.99998 1.4Z" fill="currentColor"/>
        </svg>
    );
};
  