import React, { useEffect, useMemo, useState } from 'react';
import { flushSync } from 'react-dom';

import { ColorPicker, ColorPickerProps, Divider, GetProp, theme } from 'antd';
import Moveable from 'react-moveable';

import { IShape } from '../../models/shape.model';
import { ShapeTypeEnum } from '../../models/enumerations/shape-type.enum';
import { RectangleSvgIcon } from '@components/Icons/RectangleSvgIcon';
import { ShapeGroupSvgIcon } from '@components/Icons/ShapeGroupSvgIcon';
import { CircleSvgIcon } from '@components/Icons/CircleSvgIcon';
import { ArrowUpRightSvgIcon } from '@components/Icons/ArrowUpRightSvgIcon';
import { CloseSvgIcon } from '@components/Icons/CloseSvgIcon';
import { TextShapeSvgIcon } from '@components/Icons/TextShapeSvgIcon';
import { ColorPickerSvgIcon } from '@components/Icons/ColorPickerSvgIcon';
import { useAppDispatch, useAppSelector } from '@store/store';
import { createEntity, deleteEntity, getEntities as getShapes, updateEntity } from '@store/slices/shape';
import { isFulfilled } from '@reduxjs/toolkit';

type Color = Extract<GetProp<ColorPickerProps, 'value'>, string | { cleared: any }>;

interface IMarkupEditorProps {
  fileUrl: string;
  attachmentId?: number;
}

export const MarkupEditor = (props: IMarkupEditorProps) => {
  const { fileUrl } = props;
  const dispatch = useAppDispatch();

  const [targets, setTargets] = useState<IShape[]>([]);
  const containerRef = React.useRef(null);

  const [selectedShape, setSelectedShape] = useState<IShape | undefined>(undefined);
  const [isEditing, setIsEditing] = useState(false);

  const [panelActive, setPanelActive] = useState<keyof typeof ShapeTypeEnum | undefined>();

  const [color, setColor] = useState<Color>('#FF7A45');
  const selectedColor = useMemo<string>(() => (typeof color === 'string' ? color : color!.toHexString()), [color]);

  const {
    token: { colorPrimaryBg },
  } = theme.useToken();

  const { entities: shapeList } = useAppSelector(state => state.Shape);

  useEffect(() => {
    dispatch(getShapes({}));
  }, [dispatch]);

  useEffect(() => {
    const mutableShapeList = shapeList.map(shape => ({ ...shape }));
    setTargets(mutableShapeList);
  }, [shapeList]);

  const addShape = (type: ShapeTypeEnum, text?: string) => {
    setPanelActive(type);
    let newShape: IShape = {
      id: '',
      left: 200,
      top: 160,
      width: 100,
      height: 100,
      type: type,
      text: text,
      color: selectedColor,
      rotate: undefined,
      ref: undefined,
    };

    dispatch(createEntity(newShape)).then(data => {
      if (isFulfilled(data)) {
        newShape.id = data.payload.id;
      }
    });

    setTargets([...targets, newShape]);
  };

  const handleTextChange = e => {
    const updatedTargets = targets.map(shape => {
      if (shape.id === selectedShape?.id) {
        const updatedShape = { ...shape, text: e.target.value };
        dispatch(updateEntity(updatedShape));
        return updatedShape;
      } else {
        return shape;
      }
    });
    setTargets(updatedTargets);
  };

  const handleTextClick = shape => {
    setSelectedShape(shape);
    if (shape.type === ShapeTypeEnum.TEXT) {
      setIsEditing(true);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const onDelete = () => {
    if (selectedShape) {
      const updatedTargets = targets.filter(target => target.id !== selectedShape.id);
      setTargets(updatedTargets);
      setSelectedShape(undefined);
      if (selectedShape.id) {
        dispatch(deleteEntity(selectedShape.id));
      }
    }
  };

  const handleOnDrag = (shape, index, target, left, top) => {
    const updatedTargets = targets.slice();
    updatedTargets[index] = { ...shape, left, top };
    setTargets(updatedTargets);
    target.style.left = `${left}px`;
    target.style.top = `${top}px`;
    dispatch(updateEntity(updatedTargets[index]));
  };

  const handleOnResize = (shape, index, target, width, height) => {
    const updatedTargets = targets.slice();
    updatedTargets[index] = { ...shape, width, height };
    setTargets(updatedTargets);
    target.style.width = `${width}px`;
    target.style.height = `${height}px`;
    dispatch(updateEntity(updatedTargets[index]));
  };

  const handleOnRotate = (shape, index, target, beforeRotation) => {
    const updatedTargets = targets.slice();
    updatedTargets[index] = { ...shape, rotate: beforeRotation };
    setTargets(updatedTargets);
    target.style.transform = `rotate(${beforeRotation}deg)`;
    dispatch(updateEntity(updatedTargets[index]));
  };

  return (
    <div>
      <div className="flex flex-row items-center justify-start">
        <div className="flex flex-row items-center">
          <Divider type="vertical" style={{ height: '40px' }} />
          <div className="ml-5 mr-16">
            <ShapeGroupSvgIcon />
          </div>
          <div className="mr-16">
            <CircleSvgIcon onClick={() => addShape(ShapeTypeEnum.CIRCLE)} isActive={panelActive === ShapeTypeEnum.CIRCLE} />
          </div>
          <div className="mr-16">
            <ArrowUpRightSvgIcon />
          </div>
          <div className="mr-16">
            <RectangleSvgIcon onClick={() => addShape(ShapeTypeEnum.RECTANGLE)} isActive={panelActive === ShapeTypeEnum.RECTANGLE} />
          </div>
          <div className="mr-16">
            <ColorPicker value={color} onChange={setColor}>
              <ColorPickerSvgIcon selectedColor={selectedColor} />
            </ColorPicker>
          </div>
          <div className="mr-16">
            <CloseSvgIcon onClick={() => onDelete()} />
          </div>
          <Divider type="vertical" style={{ height: '40px' }} />
          <div className="mr-16 ml-16">
            <TextShapeSvgIcon onClick={() => addShape(ShapeTypeEnum.TEXT, 'Sample Text')} isActive={panelActive === ShapeTypeEnum.TEXT} />
          </div>
          <Divider type="vertical" style={{ height: '40px' }} />
        </div>
      </div>

      <div
        ref={containerRef}
        style={{
          position: 'relative',
        }}
      >
        <img src={fileUrl} alt="Selected File" />
        {targets.map((shape, index) => (
          <div
            key={shape.id}
            style={{
              position: 'absolute',
              left: shape.left,
              top: shape.top,
              width: shape.width,
              height: shape.height,
              backgroundColor: shape.type === ShapeTypeEnum.TEXT ? 'transparent' : 'rgba(255, 255, 255, 0.5)',
              border: shape.type === ShapeTypeEnum.TEXT ? 'none' : `2px solid ${shape.color}`,
              borderRadius: shape.type === ShapeTypeEnum.CIRCLE ? '50%' : '0%',
              transform: `rotate(${shape.rotate}deg)`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
              wordBreak: 'break-word',
              textAlign: 'center',
              color: shape.color,
            }}
            ref={el => (targets[index].ref = el)}
            onClick={() => handleTextClick(shape)}
          >
            {shape.type === ShapeTypeEnum.TEXT && isEditing && selectedShape && selectedShape.id === shape.id ? (
              <input
                type="text"
                value={shape.text}
                onChange={handleTextChange}
                onBlur={handleBlur}
                autoFocus
                style={{
                  width: '150px',
                  height: '30px',
                  textAlign: 'center',
                  border: '1px',
                  outline: 'none',
                  background: 'transparent',
                  backgroundColor: colorPrimaryBg,
                }}
              />
            ) : (
              shape.text
            )}
          </div>
        ))}
        {targets.map((shape, index) => (
          <Moveable
            flushSync={flushSync}
            key={shape.id}
            target={shape.ref}
            draggable={selectedShape?.id === shape.id}
            throttleDrag={1}
            edgeDraggable={false}
            startDragRotate={0}
            throttleDragRotate={0}
            resizable={selectedShape?.id === shape.id}
            keepRatio={false}
            throttleResize={1}
            renderDirections={['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se']}
            rotatable={selectedShape?.id === shape.id}
            throttleRotate={0}
            rotationPosition={'top'}
            onDrag={({ target, left, top }) => {
              handleOnDrag(shape, index, target, left, top);
            }}
            onResize={({ target, width, height }) => {
              handleOnResize(shape, index, target, width, height);
            }}
            onRotate={({ target, beforeRotation }) => {
              handleOnRotate(shape, index, target, beforeRotation);
            }}
            origin={false}
            hideDefaultLines={selectedShape?.id !== shape.id}
          />
        ))}
      </div>
    </div>
  );
};
