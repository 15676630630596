import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { defaultValue, IAttachment } from '../../models/attachment.model';
import { EntityState, serializeAxiosError } from "../reducer.util";
import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { photoService } from '@services/photo.service';
import { isNumber } from '@shared/util/number-util';

const nameOfEntity = "genericAlbumGalleryMultimedia";
const service = photoService;

export const getPhotosByReferenceTypeAndReferenceId = createAsyncThunk(
  `${nameOfEntity}/fetch_entity_list_by_reference`,
  async ({ referenceType, referenceId, page, size }: { referenceType: string, referenceId: StringORNumber, page: number, size: number }) => {
    return service.getPhotosByReferenceTypeAndReferenceId(referenceType, referenceId, {page, size});
  },
  { serializeError: serializeAxiosError }
);

export const countMultimediaFiles = createAsyncThunk(
  `${nameOfEntity}/countMultimediaFiles`,
  async ({ referenceType, referenceId }: { referenceType: string, referenceId: StringORNumber }) => {
    return service.getCount({ referenceType, referenceId });
  },
  { serializeError: serializeAxiosError }
)

interface IGenericAlbumGalleryMultimediaStore extends EntityState<IAttachment> {
  firstLoaded: boolean;
}

const initialState: IGenericAlbumGalleryMultimediaStore = {
  loading: false,
  firstLoaded: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  currentPage: 0,
};

export const GenericAlbumGalleryMultimediaSlice = createSlice({
  name: nameOfEntity,
  initialState,
  reducers: {
    clearEntity: (state,) => {
      state.entity = defaultValue;
    },
    removePhotoFromGallery: (state, action: { payload: { attachment: IAttachment }}) => {
      const { attachment } = action.payload;

      // If there is an Index, set new Photo Album
      if (attachment.id) { // isNumber(attachmentInListIndex) && Boolean(attachmentInListIndex! > -1)) { 
        state.entities = state.entities.filter((item) => item.id !== attachment.id);
      }
      
      return state;
    },
    reset: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getPhotosByReferenceTypeAndReferenceId), (state, action) => {
        const { data, headers } = action.payload;
        const { page } = action.meta.arg;
        let currentPageLocal;

        let newEntities: IAttachment[] = [];

        if (isNumber(page) && page === 0) {
          newEntities = data;
          currentPageLocal = 0;
        } else {
          newEntities = newEntities.concat(state.entities);
          newEntities = newEntities.concat(data);
          currentPageLocal = Number(page);
        }

        const totalItemsCount = headers['x-total-count'] ? parseInt(headers['x-total-count'], 10) : 0;

        return {
          ...state,
          loading: false,
          entities: newEntities,
          totalItems: totalItemsCount,
          currentPage : currentPageLocal,
          firstLoaded: true
        };
      })
      .addMatcher(isPending(getPhotosByReferenceTypeAndReferenceId), (state, action) => {
        const { page } = action.meta.arg;
        
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
        state.currentPage = page;
      })
      .addMatcher(isRejected(getPhotosByReferenceTypeAndReferenceId), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = false;
      })
      .addMatcher(isFulfilled(countMultimediaFiles), (state, action) => { 
        state.totalItems = action.payload as number
      })
      builder.addMatcher(isRejected(countMultimediaFiles), (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = String(action.error.message);
      });
  }
});

export const { reset, clearEntity, removePhotoFromGallery } = GenericAlbumGalleryMultimediaSlice.actions;

// Reducer
export default GenericAlbumGalleryMultimediaSlice.reducer;

