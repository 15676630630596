import { IRfiDistribution } from '@models/rfi-distribution.model';
import { http } from '../http/axios.instance';
import { StringORNumber } from './utils.repository';

const path = 'rfi-distributions-extended';

export const rfiDistributionRepository = {
    getRfiDistributions: async (rfiId: StringORNumber) => {
        const requestUrl = `${path}/rfi/${rfiId}?cacheBuster=${new Date().getTime()}`;

        const promiseGet = await http.get<IRfiDistribution[]>(requestUrl);
        return promiseGet;
    },
    createDistributions: async (rfiId: StringORNumber, distributions: IRfiDistribution[]) => {
        const requestUrl = `${path}/rfi/${rfiId}`;
        const promiseCreate = await http.post(requestUrl, distributions);
        return promiseCreate;
    }
}