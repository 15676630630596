import { IBudgetApproverSetting } from '../../models/budget-approver-setting.model';
import { IQueryParams } from '../../models/pagination';
import { http } from '../http/axios.instance';
import { StringORNumber } from './utils.repository';

const path = 'budget-approver-settings';

export const budgetApproverSettingRepository = {
  getBudgetApproverSettings: async (queryParams: IQueryParams) => {
    const { sort, page, size } = queryParams;
    const requestUrl = `${path}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
    const settings = await http.get<IBudgetApproverSetting[]>(requestUrl);
    return settings;
  },
  create: async (entity: IBudgetApproverSetting) => {
    const requestUrl = `${path}`;
    const setting = await http.post<IBudgetApproverSetting>(requestUrl, entity);
    return setting;
  },
  update: async (id: StringORNumber, entity: IBudgetApproverSetting) => {
    const requestUrl = `${path}/${id}`;
    const setting = await http.put<IBudgetApproverSetting>(requestUrl, entity);
    return setting;
  }
};