/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Divider, Row, Typography, theme } from "antd";
import { EmptyLabelText } from "@components/Utils/EmptyLabel";
import { AlbumLastModifiedBy } from "./AlbumLastModifiedBy";
import { If } from "@components/Utils/Structural";
import { FileImageOutlined } from "@ant-design/icons";
import { formatDatePipe, generateUniqueStringWithTimestamp } from "@shared/util/date-utils";
import { DescriptionAlbumRichText } from "../ViewerRichText/DescriptionAlbumRichText";
import { EditAlbumButton } from "./EditAlbumButton";
import { useMobileNavigation } from "../PhotoTabLayout";
import { useAppSelector } from "@store/store";
import { isStringOrNumber } from "@shared/util/validations";

const { Title } = Typography;

export const AlbumDetails = () => {

    const { entity: album } = useAppSelector((store) => store.PhotoAlbum);

    const { token: { colorBgBase, colorBorderSecondary, colorBgLayout, fontSizeSM, lineHeight }} = theme.useToken();

    const [isMobileNavigation] = useMobileNavigation();
    
    return (
      <div className="h-full flex flex-col w-full" style={{ background: colorBgLayout }}>
        <div 
          className="h-min flex flex-col w-full pt-16 pr-16 pb-16 pl-16" 
          style={{
            background: colorBgBase,
            borderBottom: `1px solid ${colorBorderSecondary}`
          }}
        >
          <Row wrap={false}>
              <Col flex="auto">
                  <Title 
                    className="text-color-neutral-9 mt-0 imb-0 pr-12" 
                    level={4} 
                    style={{ fontWeight: 500 }} 
                    ellipsis={true}
                  >
                      {album?.name || <EmptyLabelText />}
                  </Title>
              </Col>
              <If condition={!isMobileNavigation}>
                <Col flex="none">
                  <AlbumLastModifiedBy album={album} />
                </Col>
              </If>
          </Row>

          <Row wrap={false} className="mt-8">
              <Col flex="auto">
                  <div>
                    <span className="font-bold text-color-neutral-8 mr-4" style={{ lineHeight }}>Created by:</span>
                    <span className="font-normal text-color-neutral-8 mr-15">{album?.createdByFullName}</span>
                    <If condition={isStringOrNumber(album.totalPhotos)}>
                      <span className="mr-15">
                        <FileImageOutlined className="text-color-neutral-6 mr-4"/>
                        <span className="text-color-neutral-8" style={{ fontSize: fontSizeSM }}>
                          {album.totalPhotos}
                        </span>
                      </span>
                    </If>
                    <span className="text-color-neutral-6 mr-10">
                      { formatDatePipe(album?.albumDate) }
                    </span>
                  </div>
              </Col>
              <Col flex="none">
                <EditAlbumButton />
              </Col>
          </Row>

          <Row>
            <Divider className="mt-10 mb-10"/> 
          </Row>
          
          {/* Description of Album */}
          <Row wrap={false} className="items-center">
              <Col flex={"auto"} key={generateUniqueStringWithTimestamp()}>
                { album?.description && <DescriptionAlbumRichText payload={album?.description} />}
              </Col>
          </Row>   
        </div>
      </div>
    )
}
