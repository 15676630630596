import { http } from '@infrastructure/http/axios.instance';
import { IPhase } from '@models/phase.model';

const path = 'phases';

export const phasesRepository = {
  getPhases: async (queryParams: any) => {
    const { sort = "order" } = queryParams;

    const params = new URLSearchParams({
      ...sort && { sort },
      page: 0,
      size: 1000
    } as any)
    const requestUrl = `${path}?${params.toString()}`;

    const promiseGetAll = await http.get<IPhase[]>(requestUrl);
    return promiseGetAll;
  },
  create: async (entity: IPhase): Promise<IPhase> => {

    const requestUrl = `${path}`;
    const promiseCreate = await http.post<IPhase>(requestUrl, entity);
    return promiseCreate.data;
  },
  update: async (id: string | number, entity: IPhase): Promise<IPhase> => {

    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.put<IPhase>(requestUrl, entity);
    return promiseUpdate.data;
  },
  reorder: async (ids: number[]) => {
    const requestUrl = `${path}/reorder`;
    const promiseUpdate = await http.put<IPhase>(requestUrl, ids);
    return promiseUpdate;
  },
}