/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Col, Row, Typography, theme } from "antd";
import { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getEntities as getChangeOrderItems, reset as resetChangeOrderItems } from "@store/slices/change-order-items";
import { useAppDispatch, useAppSelector } from "@store/store";
import { ChangeOrderItemTypeEnum } from "@models/enumerations/change-order-item-type-enum.model";
import Big from "big.js";
import { CostDetailTable } from "./CostDetailTable";
import { CostSummary } from "./CostSummary";


export interface ICostDetailProps {
    changeOrderId: number,
    currencySymbol: string | undefined,
}

export const CostDetail = (props: ICostDetailProps) => {

    const { changeOrderId, currencySymbol } = props

    const { token: { colorBorder, colorText, colorFillQuaternary } } = theme.useToken();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { entities: changeOrderItems } = useAppSelector((state) => state.ChangeOrderItem);
    const [materialsSubtotal, setMaterialsSubtotal] = useState<Big>(Big(0))
    const [laborSubtotal, setLaborSubtotal] = useState<Big>(Big(0))

    useEffect(() => {
        dispatch(resetChangeOrderItems())
    }, [])

    useEffect(() => {
        dispatch(getChangeOrderItems({ changeOrderId: changeOrderId }))
    }, [changeOrderId])

    const calculateSubtotal = (itemValues: any[]): Big => {
        let cost = Big(0);
        itemValues?.forEach((item: any) => {
            if (item?.unitsQty && item?.unitCost) {
                const qty = new Big(item.unitsQty)
                cost = Big(cost.add(qty.times(item.unitCost)))
            }
        })
        return cost;
    }

    useLayoutEffect(() => {
        if (changeOrderItems) {
            const materialsItemsValues = changeOrderItems.filter(item => item.type === ChangeOrderItemTypeEnum.MATERIALS);
            setMaterialsSubtotal(calculateSubtotal(materialsItemsValues));
            const laborItemsValues = changeOrderItems.filter(item => item.type === ChangeOrderItemTypeEnum.LABOR);
            setLaborSubtotal(calculateSubtotal(laborItemsValues));
        }
    }, [changeOrderItems])

    return (changeOrderItems?.length > 0 ?
        <Card
            className='mt-20 pt-10'
            style={{ borderRadius: '4px' }}
            bodyStyle={{ paddingLeft: 10, paddingRight: 10, paddingTop: 0 }}
        >
            <Row className="w-full" >
                <Col span={24}
                    className='flex flex-row justify-between w-full pl-10 pt-8 pb-8'
                    style={{ border: `1px solid ${colorBorder}`, color: colorText, backgroundColor: colorFillQuaternary, borderRadius: 4 }}
                >
                    <Typography.Title level={5} style={{ margin: 0 }}>
                        {t("changeOrder.costsDetail.costDetailTitle")}
                    </Typography.Title>
                </Col>
            </Row>
            <CostDetailTable
                title={t("changeOrder.costsDetail.materials")}
                items={changeOrderItems.filter(item => item.type === ChangeOrderItemTypeEnum.MATERIALS)}
                currencySymbol={currencySymbol}
            />
            <CostDetailTable
                title={t("changeOrder.costsDetail.labor")}
                items={changeOrderItems.filter(item => item.type === ChangeOrderItemTypeEnum.LABOR)}
                currencySymbol={currencySymbol}
            />
            <Row className='mt-20'>
                <Col span={24} className='pl-10 pr-20 flex justify-end'>
                    <CostSummary materialsSubtotal={materialsSubtotal} laborSubtotal={laborSubtotal} currencySymbol={currencySymbol} />
                </Col>
            </Row>
        </Card>
        : <></>
    );
}