import { useState } from 'react';
import { BudgetKickoffDialog } from './BudgetKickoffDialog';
import { BudgetPlanningForm } from './BudgetPlanningForm';

enum BudgetCreateStepEnum {
  KICKOFF = 'kickoff',
  FORM = 'form',
}

export const BudgetCreate = () => {
  const [step, setStep] = useState<BudgetCreateStepEnum>(BudgetCreateStepEnum.KICKOFF);

  if (step === BudgetCreateStepEnum.KICKOFF) return <BudgetKickoffDialog next={() => setStep(BudgetCreateStepEnum.FORM)} />;
  else return <BudgetPlanningForm previous={() => setStep(BudgetCreateStepEnum.KICKOFF)} />;
};
