import { IQueryParams } from '../../models/pagination';
import { http } from '../http/axios.instance';
import { StringORNumber } from './utils.repository';
import { IProjectType } from '../../models/project-type.model';

const path = 'project-types';

export const projectTypesRepository = {
  getAll: async (queryParams: IQueryParams) => {

    const { sort, page, size } = queryParams;
    const params = new URLSearchParams({
      ...sort && { sort: `${sort}` },
      ...page && { page: `${page}` },
      ...size && { size: `${size}` },
      cacheBuster: `${new Date().getTime()}`
    })
    const requestUrl = `${path}?${params.toString()}`;
  
    const promiseGetAll = await http.get<IProjectType[]>(requestUrl);
    return promiseGetAll;
  },
  getById: async (id: StringORNumber) => {

    const requestUrl = `${path}/${id}`;
    const promiseGetByID = await http.get<IProjectType>(requestUrl);
    return promiseGetByID;
  },
  create: async (entity: IProjectType): Promise<IProjectType> => {

    const requestUrl = `${path}`;
    const promiseCreate = await http.post<IProjectType>(requestUrl, entity);
    return promiseCreate.data;
  },
  update: async (id: string | number, entity: IProjectType): Promise<IProjectType> => {

    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.put<IProjectType>(requestUrl, entity);
    return promiseUpdate.data;
  },
  delete: async (id: string | number) => {

    const requestUrl = `${path}/${id}`;
    const promiseDelete = await http.delete<IProjectType>(requestUrl);
    return promiseDelete;
  },
  reorder: async (projectTypeIds: number[]) => {
    const requestUrl = `${path}/reorder`;
    const promiseUpdate = await http.put<IProjectType>(requestUrl, projectTypeIds);
    return promiseUpdate;
  },
}