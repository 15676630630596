import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch
} from "antd";

import { RootState, useAppDispatch, useAppSelector } from "@store/store";
import { getEntity } from "@store/slices/location";
import { createCustomFieldBinding, updateCustomFieldBinding } from "@store/slices/customfields";
import { useNavigate } from "react-router-dom";
import { getCustomFieldBindingListOfLocation, getCustomFieldGroupListOfLocation } from "@store/slices/customfields";
import { sortBy } from "lodash";
import { ICustomField } from "../../../models/custom-field.model";
import { ICustomFieldBinding } from "../../../models/custom-field-binding.model";

import { CustomFieldBindingTypeEnum } from "../../../models/enumerations/custom-field-binding-type-enum.model";
import { CustomFieldTypeEnum } from "../../../models/enumerations/custom-field-type-enum.model";
import { DATE_FORMAT_DEFAULT } from '@shared/util/date-utils';
import dayjs from "dayjs";

const CustomFieldGroupUpdate = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { entity: locationEntity, updating } = useAppSelector((state) => state.Location);

  const [customFieldGroupLabel] = useState('')
  const [open, setOpen] = useState(true);
  const [animateError, setAnimateError] = useState(false);
  const [form] = Form.useForm();

  const { id: locationId } = useParams<"id">();
  const { customFieldGroupId } = useParams<"customFieldGroupId">();
  const referenceId = locationId ? parseInt(locationId) : null;
  const groupId = customFieldGroupId ? parseInt(customFieldGroupId) : null;
  const [initialValues, setInitialValues] = useState<any>(null)

  useEffect(() => {
    if (locationId) {
      dispatch(getCustomFieldGroupListOfLocation(locationId))
      dispatch(getCustomFieldBindingListOfLocation(locationId))
    }
  }, [customFieldGroupId, dispatch, locationId]);

  const { entitiesGroups: customFieldGroupList, entitiesFieldsBindings: customFieldBingingValueList, loadingFieldBindings } = useSelector((state: RootState) => state.CustomFields);

  const onClose = (): void => {
    const locationId = `${locationEntity.id}`;
    setOpen(false);
    dispatch(getEntity(locationId));
    dispatch(getCustomFieldGroupListOfLocation(locationId));
    dispatch(getCustomFieldBindingListOfLocation(locationId));

    navigate(`/location/${locationId}`);
  };

  const onFinish = (values: InitialValues): void => {
    if (Object.values(values).filter(it => it !== undefined).length === 0) {
      // console.log("No changes, closing");

      onClose();
      return;
    }

    for (const [key, val] of Object.entries(values)) {
      if (val === undefined) continue;
      const found = customFieldBingingValueList
        .find(item => {
          return item.referenceId === referenceId &&
            item.customFieldId?.id === parseInt(key) &&
            item.customFieldId?.customFieldGroup?.id === groupId
        })

      const allPromises: any[] = [];

      if (found) {
        // console.log("Update customFieldBinding: ", found);
        const newvalue = JSON.stringify({ value: val });

        if (found.value !== newvalue) {
          allPromises.push(dispatch(updateCustomFieldBinding({ ...found, value: newvalue })))
        } else {
          // console.log("values are the same. skipping ", found.value, newvalue);
        }

      } else {
        // console.log("Create customFieldBinding ", key);

        const newObejct: ICustomFieldBinding = {
          referenceId: referenceId!!,
          referenceType: CustomFieldBindingTypeEnum.LOCATION,
          customFieldId: { id: parseInt(key) },
          value: JSON.stringify({ value: val })
        };
        allPromises.push(dispatch(createCustomFieldBinding(newObejct)))

      }
      Promise.all(allPromises).then(() => onClose());
    }


  };

  const playAnimationOfError = () => {
    setAnimateError(true);
    setTimeout(() => {
      setAnimateError(false);
    }, 500);
  };

  const onFinishFailed = () => {
    playAnimationOfError();
  };

  const onSubmit = () => {
    form.submit();
  };

  // Render Edit Components
  const renderTypeOfField = (customField: ICustomField) => {
    const { fieldType, label, id, config } = customField;

    switch (fieldType as CustomFieldTypeEnum) {
      case 'STRING':
      case 'INTEGER':
      case 'DECIMAL':
        return (
          <Form.Item name={`${id}`} label={`${label}`}>
            <Input
              className="w-full"
              placeholder={`Please enter ${label}`}
              onInput={() => false}
            />
          </Form.Item>
        );

      case 'CURRENCY':
        return (
          <Form.Item name={`${id}`} label={`${label}`}>
            <InputNumber
              className="w-full"
              placeholder={`Please enter ${label}`}
              formatter={value => `$ ${value}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
              parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : ""}
              onInput={() => false}
            />
          </Form.Item>
        );

      case 'BOOLEAN':
        return (
          <Form.Item name={`${id}`} label={label} valuePropName="checked">
            <Switch ></Switch>
          </Form.Item>
        );

      case 'DATE':
        return (
          <Form.Item name={`${id}`} label={`${label}`} valuePropName='date'>
            <DatePicker
              name={`${id}`}
              className="w-full"
              placeholder={`Please select a date`}
              format={DATE_FORMAT_DEFAULT}
              defaultValue={initialValues[id as any] ? initialValues[id as any] : undefined} // this is a hack because datepicker is not picking initial values from form item
            />
          </Form.Item>
        );

      case 'SELECTOR':
        {

          const pasedOptions = JSON.parse(config).options;
          let antFormattedOptions: any
          if (Array.isArray(pasedOptions)) {
            antFormattedOptions = pasedOptions.map((it, index) => {
              return { value: it, label: it }
            })
          }

          return (
            <Form.Item name={`${id}`} label={`${label}`}>
              <Select
                placeholder={`Please select an option`}
                options={antFormattedOptions}
              />
            </Form.Item>
          )
        }
      default:
        return <div style={{ margin: '20px 0' }}><p> {label}</p><Input
          className="w-full"
          placeholder={`Not Implemented for type ${fieldType}`}
          disabled={true}
        /></div>;
    }
  }


  const customFields = sortBy(customFieldGroupList.find(item => `${item.id}` === `${customFieldGroupId}`)?.customFieldsChildren, "order");

  type InitialValues = {
    [key: number | symbol]: any
  };

  useEffect(() => {
    if (loadingFieldBindings) return;
    // load Form Initial values
    const initvals = customFieldBingingValueList.map((cfb: ICustomFieldBinding) => {
      const rawValue = cfb.value;
      const jsonValue = JSON.parse(rawValue);
      const jsonValueValue = jsonValue.value;


      if (cfb.customFieldId?.fieldType === CustomFieldTypeEnum.DATE) {
        return { id: cfb.customFieldId!!.id!!, value: dayjs(jsonValueValue) }

      }

      return { id: cfb.customFieldId!!.id!!, value: jsonValueValue }
    })
      .reduce((accumulator: InitialValues, currentValue) => { // this is just formatting the values a one big object, dua Ant design needs it 
        const returns = accumulator;
        returns[currentValue.id] = currentValue.value;
        return returns;
      }, {});
    setInitialValues(initvals);

  }, [customFieldBingingValueList, loadingFieldBindings]);



  return (
    <>
      <Drawer
        title={`Edit ${customFieldGroupLabel}`}
        width={620}
        onClose={onClose}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose={true}
        extra={
          <Space>
            <Button type="primary" ghost onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={onSubmit} type="primary" loading={updating}>
              Save
            </Button>
          </Space>
        }
      >
        {initialValues && <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={initialValues}
          className={`${animateError && "error-animation"}`}
        >

          {
            customFields?.map((customfield, i) => (
              <Row gutter={16} key={i}>
                <Col span={24}>
                  {(referenceId && groupId) && renderTypeOfField(customfield)}
                </Col>
              </Row>
            ))
          }

        </Form>}

      </Drawer>
    </>
  );
};

export default CustomFieldGroupUpdate;
