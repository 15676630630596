export interface IBudgetTemplate {
  id?: number;
  name?: string;
  active?: boolean;
}

export interface IBudgetTemplateExtended {
  id?: number;
  name?: string;
  active?: boolean;
  costLineCount?: number;
}

export interface IBudgetTemplateSave {
  id?: number;
  name?: string;
  active?: boolean;
  costSubCategoryIds?: number[]
}

export const defaultValue: Readonly<IBudgetTemplate> = {};
