import { useState } from 'react';
import { useAppSelector } from '@store/store';
import { Avatar, Badge, Button, Col, Drawer, Row, Space, theme } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import useVisible from '@HOOKs/UseVisible';
import { If } from '@components/Utils/Structural';
import { useTranslation } from 'react-i18next';
import { IQueryParams } from '@models/pagination';
import { ITeamMemberItemCustom } from '@models/project-model';
import { AddOrEditTeamMember } from './AddOrEditTeamMember';
import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { CompanyReferenceEnumListOptions } from '@models/enumerations/company-reference-enum.model';
import { ShieldStarSvgIcon } from '@components/Icons/ShieldStarSvgIcon';

export interface ITeamManageProps extends IQueryParams {
  open: boolean;
  toggle: () => void;
  referenceType: CompanyReferenceEnumListOptions;
  getTeam: () => void;
  referenceId?: StringORNumber;
}

type IAction = () => void;

export const TeamManage = (props: ITeamManageProps) => {
  const { open, toggle, referenceId, referenceType, getTeam } = props;
  const { t } = useTranslation();

  const {
    token: { colorPrimaryText, colorFillSecondary, colorFillTertiary, colorTextTertiary },
  } = theme.useToken();

  const [visibleTeamCreateOrEditDrawer, toggleVisibleTeamCreateOrEditDrawer] = useVisible();

  const [memberSelected, setMemberSelected] = useState<ITeamMemberItemCustom | null>(null);

  const { data: members } = useAppSelector(state => state.ProjectTeamsExtended);

  const selectAction = (member: ITeamMemberItemCustom, action: IAction) => {
    setMemberSelected(member);
    action();
  };

  // Sort the array by first: empty core roles. Second: Alphabetically by Role. Third: Alphabetically by User
  const customSort = (a, b) => {
    if (!a.user && b.user) {
      return -1;
    } else if (a.user && !b.user) {
      return 1;
    } else {
      // sort alphabetically by role name
      const roleComparison = a.role.name.toLowerCase().localeCompare(b.role.name.toLowerCase());
      if (roleComparison !== 0) {
        return roleComparison;
      } else {
        // If roles are the same, sort alphabetically by user name
        if (a.user && b.user) {
          return (a.user.firstName.toLowerCase() + ' ' + a.user.lastName.toLowerCase()).localeCompare(
            b.user.firstName.toLowerCase() + ' ' + b.user.lastName.toLowerCase()
          );
        } else {
          return 0; // No user to compare, consider them equal
        }
      }
    }
  };

  const sortedCoreMemberList = [...members.coreMembers].sort(customSort);
  const sortedDynamicMemberList = [...members.dynamicMembers].sort(customSort);

  return (
    <Drawer
      title={`${t('generic.manage')} ${t('dashboard.teamManage.teamMember')}`}
      width={500}
      onClose={toggle}
      open={open}
      closable={false}
      styles={{
        body: { paddingBottom: 80 },
      }}
      extra={
        <Space>
          <Button type="primary" ghost onClick={toggle}>
            {t('generic.cancel')}
          </Button>
          <Button
            type="primary"
            onClick={() => {
              toggleVisibleTeamCreateOrEditDrawer();
            }}
          >
            {t('generic.add')}
          </Button>
        </Space>
      }
    >
      <div
        className="flex justify-between items-center w-full py-4 px-8 mb-12"
        style={{ backgroundColor: colorFillTertiary, borderRadius: '0.24rem', border: `1px solid ${colorFillSecondary}` }}
      >
        <span style={{ color: colorPrimaryText, fontSize: '1rem' }}>Core team members</span>
        <Badge
          count={sortedCoreMemberList.length}
          size="small"
          style={{
            backgroundColor: colorFillSecondary,
            outline: 'none',
            color: colorTextTertiary,
          }}
        />
      </div>
      {sortedCoreMemberList.map((member: ITeamMemberItemCustom, index) => (
        <MemberCard
          key={index}
          member={member}
          selectAction={selectAction}
          toggleVisibleTeamCreateOrEditDrawer={toggleVisibleTeamCreateOrEditDrawer}
        />
      ))}
      {sortedDynamicMemberList.length > 0 && (
        <div
          className="flex justify-between items-center w-full py-4 px-8 my-12"
          style={{ backgroundColor: colorFillTertiary, borderRadius: '0.24rem', border: `1px solid ${colorFillSecondary}` }}
        >
          <span style={{ color: colorPrimaryText, fontSize: '1rem' }}>Additional team members</span>
          <Badge
            count={sortedDynamicMemberList.length}
            size="small"
            style={{
              backgroundColor: colorFillSecondary,
              outline: 'none',
              color: colorTextTertiary,
            }}
          />
        </div>
      )}
      {sortedDynamicMemberList.map((member: ITeamMemberItemCustom, index) => (
        <MemberCard
          key={index}
          member={member}
          selectAction={selectAction}
          toggleVisibleTeamCreateOrEditDrawer={toggleVisibleTeamCreateOrEditDrawer}
        />
      ))}
      <If condition={visibleTeamCreateOrEditDrawer}>
        <Row>
          <AddOrEditTeamMember
            open={visibleTeamCreateOrEditDrawer}
            toggle={toggleVisibleTeamCreateOrEditDrawer}
            referenceId={referenceId}
            referenceType={referenceType}
            getTeam={getTeam}
            memberSelected={memberSelected || undefined}
            setMemberSelected={setMemberSelected}
          />
        </Row>
      </If>
    </Drawer>
  );
};

const MemberCard = ({
  member,
  selectAction,
  toggleVisibleTeamCreateOrEditDrawer,
}: {
  member?: ITeamMemberItemCustom;
  selectAction: (m: any, t: any) => void;
  toggleVisibleTeamCreateOrEditDrawer: () => void;
}) => {
  const {
    token: {
      colorText,
      colorPrimary,
      colorFillTertiary,
      colorFillQuaternary,
      colorWarningBg,
      colorWarningBorder,
      colorWarningText,
      colorTextTertiary,
      colorTextSecondary,
      colorWarningBgHover,
    },
  } = theme.useToken();
  const { t } = useTranslation();

  return (
    <div
      className="pt-3 pb-3 mb-3"
      style={{
        border: `1px solid ${!member?.user?.id ? colorWarningBorder : colorFillTertiary}`,
        backgroundColor: !member?.user?.id ? colorWarningBg : colorFillQuaternary,
        borderRadius: '4px',
        padding: '8px 16px 8px 16px',
        cursor: 'pointer',
        marginBottom: '8px',
      }}
    >
      <Row justify={'space-between'} align={'middle'} className="mb-10 mt-10" style={{ color: colorText }}>
        <Col flex="1">
          <div className="flex">
            <div className="flex items-center">
              {member?.user?.id ? (
                <Avatar className="mr-12 flex align-center justify-center">
                  {member.user?.firstName[0].toUpperCase()}
                  {member.user?.lastName[0].toUpperCase()}
                </Avatar>
              ) : (
                <Avatar
                  className="mr-12 flex align-center justify-center"
                  style={{ backgroundColor: colorWarningBgHover, color: colorWarningText }}
                >
                  <WarningOutlined />
                </Avatar>
              )}
            </div>
            <div className="flex flex-col">
              {member?.user?.id ? (
                <span className="font-bold pb-5">
                  {member.user.fullName} {member?.primary && <ShieldStarSvgIcon className="ml-8" />}
                </span>
              ) : (
                <span className="font-bold pb-5" style={{ color: colorWarningText }}>
                  {t('generic.notDefined')}
                  {member?.primary && <ShieldStarSvgIcon className="ml-8" />}
                </span>
              )}

              <span className="pb-5" style={{ color: colorTextSecondary }}>
                {member?.role.name}
              </span>
              <span style={{ color: colorTextTertiary }}>{member?.role?.discipline?.name}</span>
            </div>
          </div>
        </Col>
        <Col flex={'none'}>
          <Row>
            <Button
              type="link"
              style={{ color: colorPrimary }}
              onClick={() => {
                selectAction(member, toggleVisibleTeamCreateOrEditDrawer);
              }}
            >
              <span>{member?.user?.id ? t('generic.edit') : t('generic.add')}</span>
            </Button>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
