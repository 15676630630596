/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const CostGroupingNotSet = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigateToSettings = () => {
        navigate('/settings');
    }

    return (
        <Row>
            <Col className="pl-20 pt-20">
                <Alert message={
                    <span>
                        {t("changeOrder.costGroupNotSetErrorMessagePart1")}
                        <Button type="link" style={{ padding: 0 }} onClick={navigateToSettings}>
                            {t("changeOrder.costGroupNotSetErrorMessagePart2")}
                        </Button>
                        {t("changeOrder.costGroupNotSetErrorMessagePart3")}
                    </span>
                }
                    type="warning"
                    className="pl-30 pr-30" />
            </Col>
        </Row>
    );
}