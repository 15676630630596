/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Badge, Button, Col, DatePicker, Form, Input, Popover, Row, Select, Space, theme } from "antd";
import { CustomDrawer } from "@components/Utils/CustomDrawer";
import { useErrorAnimation } from "@HOOKs/UseErrorAnimation";
import { DATE_FORMAT_DEFAULT, getDate, getISODate, removeTimeZone } from "@shared/util/date-utils";
import { getUserDisplayName } from "@shared/util/user-utils";
import { DescriptionMetadataMarkerInput } from "./DescriptionMetadataMarkerInput";
import { useAppDispatch, useAppSelector } from "@store/store";
import { IAsset } from '../../FloorPlanEditor/interfaces/interfaces';
import { setNewItemMode, addPunchListItem, editPunchListItem, getPuchListItems } from "@store/slices/punch-list";
import { isJsonValid, isNumber } from "@shared/util/number-util";
import { asyncLaunchNotification } from "@store/slices/notification";
import { isEqual, isStringOrNumber } from "@shared/util/validations";
import { IPunchListItem } from "@models/punch-list.model";
import { isArrayWithValues } from '@shared/util/array-util';
import { CloseOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { If } from "@components/Utils/Structural";
import { gold } from "@ant-design/colors";
interface IMetadataMarkerDrawerProps {
    open: boolean;
    toogle: () => void;
    destroyAssets?: (assets: IAsset[]) => void;
    asset: IAsset;
    onEditSuccess?: (punchListItemModified: IPunchListItem) => void;
}

export const MetadataMarkerDrawer = (props: IMetadataMarkerDrawerProps) => {

    const { 
        open, 
        toogle,
        destroyAssets,
        asset,
        onEditSuccess
    } = props;

    const { selectPunchListItem: punchListItem } = useAppSelector(store => store.PunchList);

    const dispatch = useAppDispatch();
    
    const [form] = Form.useForm();

    const [animateError, playAnimationOfError] = useErrorAnimation();

    const { usersList } = useAppSelector((state) => state.UsersExtended);

    const { punchListCategories } = useAppSelector(store => store.PunchList);

    const { pageSelected } = useAppSelector(store => store.PunchList);

    const initialValuesRef = React.useRef<any>();

    useEffect(() => {
        if (punchListItem) {
            const { punchlistNumber, punchlistCategory, assignedTo, targetCompletionDate, description } = punchListItem;
            form.setFieldValue("punchlistNumber",  punchlistNumber);
            punchlistCategory && form.setFieldValue("punchlistCategory",  punchlistCategory.id);
            form.setFieldValue("assignedTo", assignedTo?.id);
            getDate(targetCompletionDate) && form.setFieldValue("targetCompletionDate", getDate(targetCompletionDate));
            form.setFieldValue("description",  description);   
        }

        initialValuesRef.current = {
            ...form.getFieldsValue(["punchlistCategory", "assignedTo", "targetCompletionDate", "description"]),
            description: asset?.data?.description 
        }
    }, [punchListItem])

    const onFinishFailed = () => { playAnimationOfError(); }

    const onSubmit = () => {
        form.validateFields([
            "punchlistNumber", 
            "punchlistCategory",
            "assignedTo",
        ])
        .then(() => {
            onFinish(form.getFieldsValue([
                "punchlistNumber", 
                "punchlistCategory",
                "assignedTo",
                "description"
            ]));
        })
        .catch(onFinishFailed);
    };

    const onFinish = (dataForm: any) => {

        const { 
            targetCompletionDate, 
            description = form.getFieldValue("description"), 
            punchlistCategory, 
            assignedTo,
            ...rest
        } = dataForm

        // Editing Punchlist Item
        if (isNumber(asset?.data?.id)) {
            dispatch(editPunchListItem({
                punchListItem: {
                    ...asset.data,
                    description,
                    assignedTo: { id: assignedTo },
                    punchlistCategory: { id: punchlistCategory },
                    targetCompletionDate: removeTimeZone(form.getFieldValue("targetCompletionDate")),
                    ...rest,
                }
            }))
            .unwrap()
            .then((punchListItemModified) => {
                // Show Notification
                dispatch(asyncLaunchNotification({
                    type: "success",
                    config: {
                      message: `Punchlist  Item successfully updated`,
                      description: `Click on ‘View more’ to edit info and see details`
                    }
                }));

                onEditSuccess && punchListItemModified && onEditSuccess?.(punchListItemModified);
                // Close Drawer
                toogle(); 
            })
        }

        // Creating Punchlist Item
        if (!isNumber(asset?.data?.id)) {
            dispatch(addPunchListItem({
                punchListItem: {
                    punchlistPage: { id: String(pageSelected?.id) },
                    punchlistNumber: dataForm.punchlistNumber,
                    markerLocationX: asset.x,
                    markerLocationY: asset.y,
                    description,
                    assignedTo: { id: assignedTo },
                    punchlistCategory: { id: punchlistCategory },
                    targetCompletionDate: getISODate(targetCompletionDate),
                    actualCompletionDate: ""
                }
            }))
            .unwrap()
            .then(() => { 
                // Show Notifications
                dispatch(asyncLaunchNotification({
                    type: "success",
                    config: {
                      message: `Punchlist  Item successfully created`,
                      description: `Click on ‘View more’ to edit info and see details`
                    }
                }));

                dispatch(getPuchListItems({ pageId: String(pageSelected?.id) }))

                // Close Drawer
                toogle(); 
            })
        }
    }

    const options = useMemo(() => {
        return usersList.map(elem => {
            return { label: getUserDisplayName(elem), value: elem.id }
        })  
    }, [usersList]) 

    const onCloseDrawer = () => {
        if (!asset.data?.punchlistNumber) {
            destroyAssets?.([
                asset
            ]);
        }

        setTimeout(() => {
            form.resetFields();
            toogle();
            dispatch(setNewItemMode(false));
        }, 250);
    }

    const genericFilter = (input: string, option: any) => (
        option?.label?.toLowerCase!()?.indexOf!(input?.toLowerCase!()) >= 0
    );

    const [changes, setChanges] = useState<number>(0);

    const onFieldsChange = (changedFields: any, allFields: any) => {
        if (!isStringOrNumber(asset?.data?.id)) { return; }

        const changes = allFields.reduce((result: number, field) => {
            if (Boolean(field.touched) && isArrayWithValues(field?.name)) {
                const initialValue = initialValuesRef.current[field?.name];
                if(!isEqual(field.value, initialValue )) {
                    result += 1;
                }
                return result;
            }
            return result
        }, 0);
        setChanges(changes);
    }

    return (
        <CustomDrawer
            title={<TitleDrawer punchListItem={punchListItem} changes={changes} onCloseDrawer={onCloseDrawer} />}
            width={480}
            open={open}
            closable={false}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
                <Space>
                    <If condition={!isStringOrNumber(punchListItem?.id)}>
                        <Button type="primary" onClick={onSubmit}>
                            Save and Create
                        </Button>
                    </If>
                    <If condition={isStringOrNumber(punchListItem?.id)}>
                        <>  
                            <Button type="default" onClick={onCloseDrawer} className="mr-10">
                                Cancel
                            </Button>
                            <Badge count={changes} offset={[-1, 3]} >
                                <Button type="primary" onClick={onSubmit} disabled={changes < 1}>
                                    Save
                                </Button>
                            </Badge>
                        </>
                    </If>
                </Space>
            }
        >

            <Form
                layout="vertical"
                form={form}
                onFinishFailed={onFinishFailed}
                onFieldsChange={onFieldsChange}
                onFinish={() => console.log("OnFinish")}
                className={`${animateError && "error-animation"}`}
            >
                <If condition={isStringOrNumber(asset?.data?.id)}>
                    <Row gutter={16} className="mt-8">
                        <Col span={24}>
                            <Form.Item
                                name="punchlistNumber" 
                                label={`Item Number`}
                                rules={[
                                    { required: true, message: `Please enter an Item Number` },
                                ]}
                            >
                                <Input 
                                    type="number" 
                                    placeholder={`Please enter Item Number`} 
                                    disabled={isNumber(asset.data?.punchlistNumber)}
                                    allowClear 
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </If>

                <Row gutter={16} className="mt-8">
                    <Col span={24}>
                        <Form.Item 
                            name="punchlistCategory" 
                            label="Category:"
                            rules={[
                                { required: true, message: "Please select the Category" }
                            ]}
                        >
                            <Select
                                placeholder="Please select a Category"
                                allowClear
                                showSearch
                                filterOption={genericFilter}
                                options={
                                    punchListCategories.map((item) => ({ value: item.id, label: item.description }))
                                }
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                
                <Row gutter={16} className="mt-8">
                    <Col span={24}>
                        <Form.Item 
                            name="assignedTo" 
                            label="Assigned To:" 
                            rules={[
                                { required: true, message: "Please select the Assigned To User" }
                            ]}
                        >
                            <Select
                                placeholder="Please select a User"
                                allowClear
                                showSearch
                                filterOption={genericFilter}
                                options={options}
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16} className="mt-8">
                    <Col span={24}>
                        <Form.Item 
                            name="targetCompletionDate" 
                            label={"Required By:"}
                        >
                            <DatePicker
                                className="w-full"
                                format={DATE_FORMAT_DEFAULT}
                                allowClear={true}
                                placeholder={`Please select a Date`}
                            />
                        </Form.Item>
                    </Col>
                </Row>
     
                <Row gutter={16} className="mt-8">
                    <Col span={24}>
                        <Form.Item
                            name="description" 
                            label={`Description`}
                            required={false}
                            shouldUpdate={false}
                            validateTrigger={false}
                        >   
                            {
                                Boolean(asset?.data?.description && isJsonValid(asset?.data?.description)) 
                                    ? <DescriptionMetadataMarkerInput initialValue={asset?.data?.description} />
                                    : <DescriptionMetadataMarkerInput />
                            }
                        </Form.Item>
                    </Col>
                </Row>
                
            </Form>
            
        </CustomDrawer>
    )
}

const TitleDrawer = (props: { onCloseDrawer: () => void, punchListItem?: IPunchListItem | null, changes: number }) => {
    
    const { onCloseDrawer, punchListItem, changes } = props;
    
    const [ openPopover, setOpenPopover ] = useState(false);
    
    const onConfirm = () => {
        setOpenPopover(false);
        onCloseDrawer();
    }
    
    const onCancel = () => {
        setOpenPopover(false);
    }
    
    const onClose = () => {
        if (changes > 0) {
            setOpenPopover(true);
        } else {
            onCloseDrawer();
        }
    }

    return (
        <Space align="center">
         <Popover 
             placement="left"
             open={openPopover} 
             content={<PopoverContent onCancel={onCancel} onConfirm={onConfirm}/>} 
             trigger="click"
         >
             <CloseOutlined onClick={onClose} />
         </Popover>
          {`${isNumber(punchListItem?.punchlistNumber) ? `Edit Item #${punchListItem?.punchlistNumber}` : `Create New Item`}`}
        </Space>
    )
} 

const PopoverContent = (props: { onCancel: () => void, onConfirm: () => void}) => {
    
    const {
        onCancel,
        onConfirm
    } = props;

    const { token: { margin, marginSM, paddingXS } } = theme.useToken();

    return (
        <Row style={{ padding: paddingXS }}>
            <Col span={24}>
                <Row>
                    <Col flex={"none"} style={{ marginRight: marginSM }}>
                        <ExclamationCircleFilled style={{ color: gold[5] }} />
                    </Col>
                    <Col flex={"auto"}>
                        <p>If you close this drawer, your <br/> changes won't be saved</p>
                    </Col>
                </Row>
                <Row justify={"end"}>
                    <Col flex={"none"} >
                        <Space >
                            <Button type="default" onClick={onCancel} style={{ marginLeft: margin }}>
                                Cancel
                            </Button>
                            <Button type="primary" onClick={onConfirm}>
                                Accept
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
