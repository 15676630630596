import { EmptyLabel } from "@components/Utils/EmptyLabel";
import { IProjectDeliverable } from "@models/project-deliverable";
import { getProjectDeliverableDetailById } from "@store/slices/project-deliverables";
import { useAppDispatch } from "@store/store";
import { theme } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface IDependencyLabelProps {
    deliverable: IProjectDeliverable
}

export const DependencyLabel = (props: IDependencyLabelProps) => {

    const { deliverable: deliverableActive } = props;

    const [nameDependency, setNameDependency] = useState("");

    const [offsetDays, setOffsetDays] = useState(0);

    useEffect(() => {
        setNameDependency(deliverableActive?.dependency?.deliverable?.name || "");
        setOffsetDays(deliverableActive?.dependencyOffset || 0);
    }, [deliverableActive])

    const link = (nameDependency && deliverableActive?.dependency?.id) ? <CustomLinkToDeliverable dependencyId={deliverableActive?.dependency?.id}  dependencyName={nameDependency} /> : <EmptyLabel />

    const offset = offsetDays ? (offsetDays > 0 ? `(+ ${offsetDays} days)` : `( ${offsetDays} days)`) : "";
    
    const labelText = <span>{link}{` ${offset}`}</span>;
    
    const dependencyLabel = (<span>{labelText}</span>)

    return dependencyLabel;
}

export interface ILinkToDeliverableDependencyProps {
    dependencyId: number | string;
    dependencyName: string;
}

export const CustomLinkToDeliverable = (props: ILinkToDeliverableDependencyProps) => {
    const dispatch = useAppDispatch();
    const { token: { colorText } } = theme.useToken();
    const onClicLink = () => {
        dispatch(getProjectDeliverableDetailById({projectDeliverableId: String(props.dependencyId) }));
    }
    return <Link onClick={onClicLink} to={`../${props.dependencyId}`} relative={"path"} style={{ color: colorText, textDecoration: "underline" }} >{props.dependencyName}</Link>
}