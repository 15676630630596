/* eslint-disable react-hooks/exhaustive-deps */
import { RightOutlined } from "@ant-design/icons";
import { If } from "@components/Utils/Structural";
import { isArrayWithValues } from "@shared/util/array-util";
import { isNumber } from "@shared/util/number-util";
import { getPuchListItems, setPageByIndex } from "@store/slices/punch-list";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Col, Row, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { UsePunchListRouteSegment } from "../../UsePunchListRouteSegment";

interface IPunchListPaginationProps {  }

export const PunchListPagination = (props: IPunchListPaginationProps) => {

    const { token: { colorPrimary, colorBorder, colorBgContainer, colorPrimaryHover, fontSizeLG, fontSizeSM }} = theme.useToken();

    const { punchList } = useAppSelector(store => store.PunchList);

    const { punchListPages, pageSelected } = useAppSelector(store => store.PunchList);

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const onNextPage = () => {
        if(isNumber(pageSelected?.nextPage?.id)) {
            pageSelected?.nextPage && dispatch(setPageByIndex(pageSelected.nextPage.index));
            dispatch(getPuchListItems({ pageId: pageSelected?.nextPage?.id! }));
            
            navigateToPage(pageSelected?.nextPage?.id!);
        }
    }

    const onBackPage = () => {
        if(isNumber(pageSelected?.prevPage?.id)) {
            pageSelected?.prevPage && dispatch(setPageByIndex(pageSelected.prevPage.index));
            dispatch(getPuchListItems({ pageId: pageSelected?.prevPage?.id! }));
            
            navigateToPage(pageSelected?.prevPage?.id!);
        }
    }

    const basePunchListUrlSegment = UsePunchListRouteSegment();

    const navigateToPage = (pageId: string | number) => {
        navigate(`${basePunchListUrlSegment}punch-list/page/${pageId}`, { replace: true });
    }

    return (
      <>
        <Row 
            className="items-center justify-center pt-16 pb-16" 
            style={{ background: colorBgContainer, borderBottom: `1.2px solid ${colorBorder}` }}
        >
            <Col>
                <div 
                    onClick={onBackPage}
                    className="flex flex-center justify-center rounded-full bg-color-neutral-2 cursor-pointer"
                    style={{
                        width: 36,
                        height: 36
                    }}
                >
                    <RightOutlined 
                        className="generic-transition"
                        style={{
                            color: isNumber(pageSelected?.prevPage?.id) ? colorPrimary : colorBorder,
                            transform: "rotateY(180deg)",  
                        }}
                    />
                </div>
            </Col>
            <Col>
                <div className="flex flex-col items-center ml-10 mr-10 pr-30 pl-30">
                    <div style={{ fontSize: fontSizeLG }}>
                        <span className="font-medium mr-5" style={{ color: colorPrimary }}>Floor plan</span>
                        <span className="font-medium mr-5" style={{ color: colorPrimary }} >{pageSelected ? pageSelected?.order : ""}</span>
                        <If condition={Boolean(isArrayWithValues(punchListPages) && punchListPages?.length > 0)}>
                            <span className="text-color-neutral-7">of {punchListPages?.length}</span>
                        </If>
                    </div>
                    <div style={{ color: colorPrimaryHover, fontSize: fontSizeSM }}>
                        {punchList?.length || "0" } item{ punchList?.length > 1 ? "s" : ""}
                    </div>
                </div>
            </Col>
            <Col>
                <div
                    onClick={onNextPage} 
                    className="flex flex-center justify-center rounded-full bg-color-neutral-2 cursor-pointer"
                    style={{
                        width: 36,
                        height: 36
                    }}
                >    
                    <RightOutlined 
                        className="generic-transition"
                        style={{
                            color: isNumber(pageSelected?.nextPage?.id) ? colorPrimary : colorBorder,
                            transform: "rotateY(0deg)",  
                        }}
                    />
                </div>
            </Col>
        </Row>
      </>
    )
}
