import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Avatar, Button, Col, ConfigProvider, Row, Table, theme } from "antd";
import { red, green } from '@ant-design/colors';
import { ColumnsType, TableProps } from "antd/es/table";
import { useAppDispatch, useAppSelector } from "@store/store";

/* eslint-disable react-hooks/exhaustive-deps */
import { EmptyCellTable } from "@components/Utils/EmptyCellTable";
import { IUser } from "@models/user.model";
import { getEntities, resetPassword } from "@store/slices/users";
import { StatusUserIndicator } from "./StatusUserIndicator";
import { EditOutlined, PlusOutlined, SendOutlined } from "@ant-design/icons";
import { SearchTable } from "@components/Utils/SearchTable";
import { UserEdit } from "./UserEdit";
import { TabContainer } from "@components/Utils/TabContainer";
import useVisible from "@HOOKs/UseVisible";
import { formatDatetimePipe } from "@shared/util/date-utils";
import { dateTypeSorter, defaultSorter } from "@shared/util/table-utils";
import { BackButton } from "@components/Utils/BackButton";
import { useTranslation } from "react-i18next";
import { useLayoutContex } from "@providers/LayoutProvider";

export const UserListTab = () => {
    
    const { token: { marginLG, colorPrimary, colorTextTertiary, fontSize, colorBgContainer, colorBorderSecondary, colorText } } = theme.useToken();

    const [openEditUser, toogleEditUser] = useVisible();

    const columns: ColumnsType<IUser> = [
        {
            title: (
                <div className="TableCustomizedflex flex-row items-center pl-10">
                    <Avatar className="cursor-pointe h-0" gap={4} style={{ opacity: 0 }} >--</Avatar>
                    <span className="ml-15">First Name</span>
                </div>
            ),
            sorter: ({ firstName: a }, {firstName: b}) => defaultSorter(a, b),
            onCell: (user: IUser)  => ({ onClick: () => goToDetailUser(user) }),
            render: (user: IUser) => {
                return (
                  (
                    <div className="flex flex-row items-center pl-10 ">
                      <Avatar className="cursor-pointer" gap={4}>
                        {user?.firstName && user?.lastName ? `${user?.firstName?.[0]}${user?.lastName?.[0]}`.toUpperCase() : ''}
                      </Avatar>
                      <span className="ml-15 font-medium">{user?.firstName}</span>
                    </div>
                  ) || <EmptyCellTable />
                ); 
            }
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            sorter: ({ lastName: a }, {lastName: b}) => defaultSorter(a, b),
            onCell: (user: IUser, index?: number)  => ({ onClick: () => goToDetailUser(user) }),
            render: (value) => { return <span className="font-medium">{value}</span> || <EmptyCellTable/> }
        },
        {
            title: 'Login',
            dataIndex: 'login',
            sorter: ({ login: a }, {login: b}) => defaultSorter(a, b),
            onCell: (user: IUser)  => ({ onClick: () => goToDetailUser(user) }),
            render: (value) => { return <span style={{ color: colorTextTertiary }}>{value}</span> || <EmptyCellTable/> },
            ellipsis: true,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: ({ email: a }, {email: b}) => defaultSorter(a, b),
            onCell: (user: IUser, index?: number)  => ({ onClick: () => goToDetailUser(user) }),
            render: (value) => { return <span style={{ color: colorTextTertiary }}>{value}</span> || <EmptyCellTable/> },
            ellipsis: true,
        },
        {
            title: 'Status',
            dataIndex: 'activated',
            width: "120px",
            onCell: (user: IUser, index?: number)  => ({ onClick: () => goToDetailUser(user) }),
            render: (activated: boolean) => { return (
                activated !== null 
                    ? (
                        <div className="flex flex-row items-center">
                            <StatusUserIndicator style={{ backgroundColor: activated ? green[4] : red[4] }}/> 
                            <span className="ml-10">{ activated ? "Activated": "Deactivated" }</span>
                        </div>
                    )
                    : <EmptyCellTable/> 
                ) 
            },
            filters: [
                {
                    text: 'Activated',
                    value: true,
                },
                {
                    text: 'Deactivated',
                    value: false,
                },
            ]
        },
        {
            title: 'Last Login',
            dataIndex: 'lastLoginDate',
            sorter: (a, b) => dateTypeSorter(a.lastLoginDate, b.lastLoginDate),
            onCell: (user: IUser, index?: number)  => ({ onClick: () => goToDetailUser(user) }),
            render: (value) => { return <span className="font-medium">{formatDatetimePipe(value)}</span> || <EmptyCellTable/> }
        },
        {
            title: 'Password reset',
            width: "128px",
            render: (_: IUser) => { return (
                <div
                    test-element={`button-send-reset-password`}   
                    onClick={() => _.email && sendPasswordReset(_.email)} 
                    className="flex flex-row items-center ml-10 cursor-pointer" 
                    style={{ color: colorPrimary }}>
                    <SendOutlined />
                    <span className="ml-10">Send</span>
                </div>
            )},
        },
        {
            title: '',
            width: "85px",
            render: (user: IUser) => { return (
                <div 
                    onClick={() => editUser(user)}
                    className="flex flex-row items-center cursor-pointer" 
                    style={{ color: colorPrimary }}
                >
                    <EditOutlined className="mr-8 scale-90" />
                    <span>Edit</span>
                </div>
            ) },
        },
    ];

    const { entities: userList, totalItems, loading } = useAppSelector((state) => state.Users);

    const dispatch = useAppDispatch();
    
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { setHeaderLabelEntityState } = useLayoutContex()

    useEffect(() => {
        setHeaderLabelEntityState(t("admin.users"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [userSelected, setUserSelected] = useState<IUser>({})

    const [tableParams, setTableParams] = useState({
        size: 10,
        current: 0,
        sort: `lastName,asc`,
        activated: [true, false]
    });

    const filterUsers = () => {
        const { activated = [] } = tableParams;

        dispatch(
            getEntities({
                ...filterValue && { search: filterValue },
                ...activated && activated.length === 1 && { activated: activated[0] } 
            })
        );
    }

    useEffect(() => {
        filterUsers();
    },[tableParams]);

    const onChange: TableProps<IUser>['onChange'] = (pagination, filters, sorter, extra) => {
        setTableParams((oldValue) => { 
            return {
                ...oldValue, 
                ...pagination?.pageSize && { size: pagination.pageSize },
                ...filters,
                current: pagination.current !== undefined ? pagination.current - 1 : 0
            }
        })
    };

    const editUser = (user: IUser) => {
        setUserSelected({...user});
        toogleEditUser()
    }

    const addUser = () => {
        setUserSelected({});
        toogleEditUser()
    }

    const sendPasswordReset = (email: string) => {
        dispatch(resetPassword(email));
    }

    const [filterValue, setFilterValue] = useState<string>("");

    const onChangeValueToSearch = (e: any) => {
        setFilterValue(e.target.value);
    }

    const handleOnSearch = () => {
        filterUsers();
    }

    const onModifiedChanges = (user: IUser) => {
        filterUsers();
    }

    const goToDetailUser = (user: IUser) => {
        navigate(`/admin/access-and-permissions/users/${user?.login}`)
    }

    const TableComponentWrapper = useCallback((node: any) => {
        const style = { border: `1px solid ${colorBorderSecondary}`, borderRadius: 4 }
        return (
            <table style={{ ...node.style, ...style }}>
                { node.children.map((child: any) => child) }
            </table>
        ); 
    }, [colorBorderSecondary]);

    const navigateBack = () => {
        navigate('/admin/access-and-permissions')
    }

    return (
        <>
            <UserEdit open={openEditUser} toogle={toogleEditUser} user={userSelected} onModifiedChanges={onModifiedChanges} />
            <TabContainer>
                <div className="flex flex-col overflow-auto">
                    <Row>
                        <Col span={24}>
                            <div className="flex flex-row items-center pl-10 pt-11 pb-11" style={{ borderTop: `1px solid ${colorBorderSecondary}`, borderBottom: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                                <BackButton onClick={navigateBack} >Access & Permissions</BackButton>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="pt-20 overflow-auto" style={{ paddingLeft: marginLG, paddingRight: marginLG }}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={20}>
                            <div className="flex flex-row items-center justify-between" style={{ borderRadius: 4, border: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                                <span className="pt-10 pb-10 pl-20 font-medium" style={{ color: colorText, fontSize: fontSize + 2 }} >List of Users</span>
                                <div className="flex flex-row items-center">
                                    <span style={{ color: colorTextTertiary }}>{ totalItems ? `${totalItems}` : "0" } Members</span>
                                    <SearchTable className="ml-20" handleOnSearch={handleOnSearch} onChange={onChangeValueToSearch} />
                                    <Button type="primary" className="ml-20 mr-7" onClick={addUser}>
                                        <PlusOutlined />
                                        Add user
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-10">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={20} >
                            <ConfigProvider theme={{ token: { borderRadius: 2 } }}>
                                <Table 
                                    loading={loading}
                                    rowKey="id" 
                                    size="small" 
                                    columns={columns} 
                                    dataSource={userList} 
                                    onChange={onChange}
                                    rowClassName={"hover-in-row"}
                                    components={{
                                        table: TableComponentWrapper
                                    }}
                                    pagination={ { current: tableParams.current + 1, pageSize: tableParams.size, total: totalItems }}
                                />
                            </ConfigProvider>
                        </Col>
                    </Row>
                </div>
            </TabContainer>
        </>
    )
}