/* eslint-disable react-hooks/exhaustive-deps */
import { IProjectDeliverableUI } from "@models/project-deliverable";
import { Col, Row, Select, Form, Input } from "antd";
import React, { useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useAppSelector } from "@store/store";
import { useTranslation } from "react-i18next";

interface IDeliverableHandleDependencyModalContentProps {
    deliverable: IProjectDeliverableUI;
}

export interface IDeliverableHandleDependencyModalContentRef {
    getValues: () => {
        dependency: number,
        offset: number
    }
}

export const DeliverableHandleDependencyModalContent = React.forwardRef((props: IDeliverableHandleDependencyModalContentProps, ref) => {

    const { deliverable } = props;

    const { data: ProjectDeliverables } = useAppSelector((state) => state.ProjectDeliverables);

    const [form] = Form.useForm();

    const { t } = useTranslation();

    const [disableOffset, setDisableOffset] = useState(false);

    useImperativeHandle(ref, () => ({
        getValues: getValues
    }));

    const deliverableOptions: IProjectDeliverableUI[] = useMemo(() => {
        const deliverablesList = ProjectDeliverables?.deliverablesList ? ProjectDeliverables?.deliverablesList.filter((item: IProjectDeliverableUI) => item.id !== deliverable.id) : [];
        return deliverablesList;
    }, [ProjectDeliverables]);

    const deliverableOptionsOfSelect = useMemo(() => {
        const list = deliverableOptions.map((item: any) => ({ value: item?.id, label: item?.deliverable?.name }));
        return list;
    }, [deliverableOptions])

    useEffect(() => {
        const initValue = {
            dependency: deliverable?.dependency?.id ?? null,
            offset: deliverable?.dependencyOffset ?? null
        };
        form.setFieldsValue({
            ...initValue
        });
    }, [deliverableOptions, deliverable])

    const genericFilter = (input: string, option: any) => {
        return option?.label?.toLowerCase!()?.indexOf!(input?.toLowerCase!()) >= 0;
    }

    const onChange = (dependencyId: number) => {
        if (!dependencyId) {
            setDisableOffset(true);
            form.setFieldValue("offset", null);
        }

        if (dependencyId) {
            setDisableOffset(false);
            const newDeliverableDependencySelected = ProjectDeliverables?.deliverablesList && ProjectDeliverables?.deliverablesList.find((item: IProjectDeliverableUI) => String(item?.id) === String(dependencyId))
            const dependencyOffset = newDeliverableDependencySelected?.dependencyOffset ?? null;
            form.setFieldValue("offset", dependencyOffset);
        }
    }

    const getValues = () => {
        return {
            dependency: form.getFieldValue("dependency") ?? "0",
            offset: parseInt(form.getFieldValue("offset") ?? 0)
        };
    }

    return (
        <>
            <Form
                layout="vertical"
                form={form}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="dependency"
                            label={t("projectDeliverable.dependency")}
                        >
                            <Select
                                allowClear
                                optionFilterProp="children"
                                showSearch
                                filterOption={genericFilter}
                                options={deliverableOptionsOfSelect}
                                onChange={onChange}
                                placeholder={t("projectDeliverable.dependency")}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="offset"
                            label={t("projectDeliverable.offset")}
                        >
                            <Input 
                                type="number" 
                                placeholder={t("projectDeliverable.offset")} 
                                disabled={disableOffset}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </> 
    )
    
})




