/* eslint-disable react-hooks/exhaustive-deps */
import { green } from "@ant-design/colors";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { AccountActionTypeEnum } from "@models/enumerations/account-action-type.enum";
import { Col, Row, theme, Button } from "antd";
import { useNavigate } from "react-router-dom";

interface IContainerChangePasswordSuccessfullyProps {
    typeAction: AccountActionTypeEnum
}

export const ContainerChangePasswordSuccessfully = (props: IContainerChangePasswordSuccessfullyProps) => {
    
    const { token: { fontSizeXL } } = theme.useToken();

    const navigate = useNavigate();

    const goToLogin = () => {
        navigate("/login")
    }

    return (
        <>
            <Row justify={"center"} >
                <Col span={24} className="flex justify-center pt-40 pb-5" > 
                    <CheckCircleTwoTone style={{ fontSize: 30 }} twoToneColor={[green[5],green[0]]} />
                </Col>
            </Row>

            <Row justify={"center"} >
                <Col span={24} className="flex justify-center pt-8" > 
                    <span className="text-color-neutral-8 font-medium" style={{ fontSize: fontSizeXL }}>Password successfully reset</span>
                </Col>
            </Row>

            <Row justify={"center"} >
                <Col span={24} className="flex justify-center pt-8 pb-15" > 
                    <p className="flex justify-center text-center text-color-neutral-7 font-normal">
                        Your password has been successfully reset. Click below to log in.
                    </p>
                </Col>
            </Row>

            <Button id="buttonChangePasswordSuccesfullyGoToLogin" type="primary" htmlType="submit" className="w-full" onClick={goToLogin}>
                Go to Login
            </Button>
        </>
    )
}