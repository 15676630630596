import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, List, Row, theme } from "antd";
import { useAppDispatch, useAppSelector } from "@store/store";
import { TabContainer } from "@components/Utils/TabContainer"
import { SearchTable } from "@components/Utils/SearchTable";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import { IGroupExtended } from "@models/group.model";
import { getEntities } from "@store/slices/groups";
import useVisible from "@HOOKs/UseVisible";
import { GroupEdit } from "./GroupEdit";
import { BackButton } from "@components/Utils/BackButton";
import { useTranslation } from "react-i18next";
import { useLayoutContex } from "@providers/LayoutProvider";

export const GroupsTab = () => {

    const [openEditGroup, toogleEditGroup] = useVisible();

    const [groupSelected, setGroupSelected] = useState<IGroupExtended>({})

    const { token: { marginLG, colorPrimary, colorTextTertiary, fontSize, colorBgContainer, colorBorderSecondary, colorText } } = theme.useToken();
    
    const { entities: groupList, totalItems } = useAppSelector((state) => state.Groups);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { t } = useTranslation();
    const { setHeaderLabelEntityState } = useLayoutContex()

    const filterGroups = () => {
        dispatch(
            getEntities({
                ...filterValue && { search: filterValue }
            })
        );
    }

    const [filterValue, setFilterValue] = useState<string>("");

    useEffect(() => {
        filterGroups();
        setHeaderLabelEntityState(t("admin.groups"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeValueToSearch = (e: any) => {
        setFilterValue(e.target.value);
    }

    const handleOnSearch = () => {
        filterGroups();
    }

    const addGroup = () => {
        setGroupSelected({});
        toogleEditGroup()
    }

    const goToDetailGroup = (group: IGroupExtended) => {
        navigate(`/admin/access-and-permissions/groups/${group?.id}`)
    }

    const onModifiedChanges = (group: IGroupExtended) => {
        filterGroups();
    }

    const navigateBack = () => {
        navigate('/admin/access-and-permissions')
    }

    return (
        <>
            <GroupEdit open={openEditGroup} toogle={toogleEditGroup} group={groupSelected} onModifiedChanges={onModifiedChanges} />
            <TabContainer>
                <div className="flex flex-col overflow-auto">
                    <Row>
                        <Col span={24}>
                            <div className="flex flex-row items-center pl-10 pt-11 pb-11" style={{ borderTop: `1px solid ${colorBorderSecondary}`, borderBottom: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                                <BackButton onClick={navigateBack} >Access & Permissions</BackButton>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="pt-20 overflow-auto" style={{ paddingLeft: marginLG, paddingRight: marginLG }}>
                    <Row>
                        <Col xs={18} sm={18} md={18} lg={18} xl={18} xxl={18}>
                            <div className="flex flex-row items-center justify-between" style={{ borderRadius: 4, border: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                                <span className="pt-10 pb-10 pl-20 font-medium" style={{ color: colorText, fontSize: fontSize + 2 }} >List of Groups</span>
                                <div className="flex flex-row items-center">
                                    <span style={{ color: colorTextTertiary }}>{ totalItems ? `${totalItems}` : "0" } Groups</span>
                                    <SearchTable className="ml-20" handleOnSearch={handleOnSearch} onChange={onChangeValueToSearch} />
                                    <Button type="primary" className="ml-20 mr-7" onClick={addGroup}>
                                        <PlusOutlined />
                                        Add Group
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-10">
                        <Col xs={18} sm={18} md={18} lg={18} xl={18} xxl={18} >
                        <List
                            itemLayout="vertical"
                            pagination={{
                                pageSize: 10,
                            }}
                            dataSource={groupList.slice()}
                            renderItem={(group: IGroupExtended) => (
                                <List.Item key={group.id} style={{ borderBlockEnd: 0, padding: 0, paddingBottom: '8px' }}>
                                    <div className="flex flex-row items-center justify-between" style={{ borderRadius: 4, border: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                                        <span className="pt-10 pb-10 pl-20 font-medium" style={{ fontSize: fontSize }} >{group.name}</span>
                                        <div className="flex flex-row items-center pt-10 pb-10 pl-20 pr-20 font-medium" style={{ textAlign: 'right'}}>
                                            <span style={{ color: colorTextTertiary }}>{ group.userCount } Members</span>
                                            <span className="ml-20" style={{ color: colorPrimary, cursor: 'pointer' }} onClick={() => goToDetailGroup(group)} role="button">View detail &gt;</span>
                                        </div>
                                    </div>
                                </List.Item>
                            )}
                        />
                        </Col>
                    </Row>
                </div>
            </TabContainer>
        </>
    )
}