import { IChangeOrderReason } from '@models/change-order-reason.model';
import { http } from '../http/axios.instance';

const path = 'change-order-reasons';

export const changeOrderReasonsRepository = {
    getChangeOrderReasons: async () => {
        const requestUrl = `${path}?cacheBuster=${new Date().getTime()}`;
        const promiseGetAll = await http.get<IChangeOrderReason[]>(requestUrl);
        return promiseGetAll;
    },
    getEnabledChangeOrderReasons: async () => {
        const requestUrl = `${path}/enabled?cacheBuster=${new Date().getTime()}`;
        const promiseGetAll = await http.get<IChangeOrderReason[]>(requestUrl);
        return promiseGetAll;
    },
    create: async (entity: IChangeOrderReason): Promise<IChangeOrderReason> => {

        const requestUrl = `${path}`;
        const promiseCreate = await http.post<IChangeOrderReason>(requestUrl, entity);
        return promiseCreate.data;
    },
    update: async (id: string | number, entity: IChangeOrderReason): Promise<IChangeOrderReason> => {

        const requestUrl = `${path}/${id}`;
        const promiseUpdate = await http.put<IChangeOrderReason>(requestUrl, entity);
        return promiseUpdate.data;
    },
}