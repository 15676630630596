
import { IRfi, IRfiWithDistributions, defaultValue } from '@models/rfi.model';
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { rfiService } from '@services/rfi.service';
import { IQueryParams } from "../../models/pagination";
import { cleanEntity } from '../../shared/util/entity-utils';
import { EntityState, serializeAxiosError } from "../reducer.util";
import { asyncLaunchNotification } from './notification';

export interface IRfiPatchArgs {
  entity: IRfi;
  onSuccess: () => void;
}

export const getEntities = createAsyncThunk(
  'rfi/fetch_entity_list',
  async (queryParams: IQueryParams) => {
    return rfiService.getRfis(queryParams);
  },
  { serializeError: serializeAxiosError }
);

export const countOpenRfi = createAsyncThunk(
  'rfi/fetch_open_quantity',
  async (projectId: string | number) => {
    return rfiService.countOpenRfi(projectId);
  },
  { serializeError: serializeAxiosError }
);

export const countRfi = createAsyncThunk(
  'rfi/fetch_quantity',
  async (projectId: string | number) => {
    return rfiService.countRfi(projectId);
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  'rfi/fetch_entity',
  async (id: string | number) => {
    return rfiService.getRfiById(id);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'rfi/create_entity',
  async (entity: IRfi, thunkAPI) => {
    const result = await rfiService.createRfi(cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const createRfiWithDistributions = createAsyncThunk(
  'rfi/create_entity_extended',
  async (entity: IRfiWithDistributions, thunkAPI) => {
    const result = await rfiService.createRfiWithDistributions(entity);
    thunkAPI.dispatch(
      asyncLaunchNotification({
        type: 'success',
        config: {
          message: `RFI created successfully`,
          description: `Notifications have been sent to the involved users.`,
        },
      })
    );
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'rfi/update_entity',
  async (entity: IRfi, thunkAPI) => {
    const result = entity.id && (await rfiService.updateRfi(entity.id, cleanEntity(entity)));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const patchEntity = createAsyncThunk(
  'rfi/patch_entity',
  //   async (entity: IRfi, thunkAPI) => {
  async ({ entity, onSuccess }: IRfiPatchArgs) => {
    const result = entity.id && (await rfiService.patchRfi(entity.id, cleanEntity(entity)));
    // thunkAPI.dispatch(getEntities({}));
    onSuccess();
    return result;
  },
  { serializeError: serializeAxiosError }
);

interface RfiState extends EntityState<IRfi> {
    entitiesQuantity: number | null;
    openEntitiesQuantity: number | null;
}

const initialState: RfiState = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
    entitiesQuantity: null,
    openEntitiesQuantity: null,
};

export const RfiSlice = createSlice({
    name: 'rfi',
    initialState,
    reducers: {
        clearEntity: (state,) => {
            state.entity = defaultValue;
        },
        reset: (state,) => {
            return initialState;
          },
    },
    extraReducers(builder) {
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data;
            })
            .addCase(countRfi.fulfilled, (state, action) => {
                state.loading = false;
                state.entitiesQuantity = action.payload.data;
            })
            .addCase(countOpenRfi.fulfilled, (state, action) => {
                state.loading = false;
                state.openEntitiesQuantity = action.payload.data;
            })
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const { data, headers } = action.payload;
                const xTotalCount: string = headers['x-total-count'] || "0";
                return {
                    ...state,
                    loading: false,
                    entities: data,
                    totalItems: parseInt(xTotalCount, 10),
                };
            })
            .addMatcher(isFulfilled(createEntity, createRfiWithDistributions, updateEntity), (state, action) => {
                state.updating = false;
                state.loading = false;
                state.updateSuccess = true;
                state.entity = action.payload as IRfi;
            })
            .addMatcher(isPending(getEntities, getEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
            .addMatcher(isPending(createEntity, createRfiWithDistributions, updateEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.updating = true;
            });
    }
});

export const { reset, clearEntity } = RfiSlice.actions;

// Reducer
export default RfiSlice.reducer;

