import { IAclTarget } from '@models/acl-target.model';
import { http } from '../http/axios.instance';

const path = 'acl-targets';

export const aclTargetsRepository = {
  getAclTargets: async () => {
    const requestUrl = `${path}?cacheBuster=${new Date().getTime()}`;
    const promiseGetAll = await http.get<IAclTarget[]>(requestUrl);
    return promiseGetAll;
  }
}