import { useEffect, useRef, useState } from 'react';
import OxBlueImagePlaceholder from '../../../../../assets/images/icons/ox-blue-image-placeholder.svg'
import { If } from '@components/Utils/Structural';
import { theme } from 'antd';
import { useAppDispatch } from '@store/store';
import { getOxBlueCameraImageOneAtATime } from '@store/slices/ox-blue-projects';
import { IOxBlueCameraImageFeed } from '@models/ox-blue.model';
import { NEUTRAL_5_COLOR } from '@providers/ThemeProvider';


const LazyImg = ({ item, selectedTimes, mappedLatestTimes, selectedCameraId, selectedDate, setSrcForHeroImage }) => {
    const placeholderRef = useRef(null);
    const [imageSrc, setImageSrc] = useState("")

    useEffect(() => {
        if (selectedTimes && item && selectedTimes === item.time) {
            setSrcForHeroImage(imageSrc)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTimes])


    const dispatch = useAppDispatch();

    const { token: { colorPrimary } } = theme.useToken();


    useEffect(() => {
        const observer = new IntersectionObserver((entries, obs) => {
            entries.forEach(async entry => {
                if (entry.isIntersecting) {
                    loadRemainingUnloadedImages(entry.target.id);
                    obs.disconnect();
                }
            });
        }, { threshold: 0.1 });

        if (placeholderRef.current) {
            observer.observe(placeholderRef.current);
        }

        return () => {
            observer.disconnect();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const loadRemainingUnloadedImages = async (givenTime) => {

        const notLoadedObject = mappedLatestTimes.find((entry) => entry.time === givenTime)
        if (notLoadedObject) {
            const notLoadedObjectIndex = mappedLatestTimes.findIndex((item) => item.time === notLoadedObject?.time);
            const lastTime = notLoadedObject.time;

            dispatch(getOxBlueCameraImageOneAtATime({ id: selectedCameraId, date: selectedDate, time: lastTime })).unwrap()
                .then((item: IOxBlueCameraImageFeed) => {
                    const updatedLatestTimes = [...mappedLatestTimes];
                    const updatedItem = {
                        ...notLoadedObject,
                        url: item.path || "",
                        isLoaded: true
                    };
                    updatedLatestTimes[notLoadedObjectIndex] = updatedItem;
                    // setMappedLatestTimes(updatedLatestTimes);
                    setImageSrc(item.path || "")
                    return item.path
                });
        }
    }

    return (
        <>
            <If condition={Boolean(imageSrc)}>
                <div>
                    <img style={{
                        flex: '1 0 auto',
                        borderRadius: "10px",
                        border: selectedTimes === item.time ? `2px solid ${colorPrimary}` : ""
                    }} alt='site' width={"100%"} src={imageSrc || ""} />
                </div>
            </If>
            <If condition={!Boolean(imageSrc)}>
                <div
                    ref={placeholderRef}
                    id={item.time}
                    className='flex items-center justify-center'
                    style={{
                        backgroundColor: NEUTRAL_5_COLOR,
                        borderRadius: "10px",
                        minHeight: "170px",
                        border: selectedTimes === item.time ? `2px solid ${colorPrimary}` : ""
                    }} >
                    <img alt='placeholder-ox-blue' src={OxBlueImagePlaceholder} />
                </div>
            </If>
        </>
    );
};

export default LazyImg;
