/* eslint-disable react-hooks/exhaustive-deps */
import { TabContainer } from '@components/Utils/TabContainer';
import { CustomFieldsGenericList } from '@components/CustomFieldsGeneric/CustomFieldsGenericList';
import { useParams } from 'react-router-dom';
import { LocationCard } from './LocationCard';
import { LocationSitesTable } from './LocationSitesTable';
import { AddressGenericCardMap } from '@components/AddressGeneric/AddressGenericCardMap';
import { CustomCardLayout } from '@components/Utils/CustomCardLayout';
import { useLayoutEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { LocationCompaniesList } from './LocationCompaniesList';
import './LocationOverviewTab.less'
import { theme } from 'antd';

const gap = 16;
const halfGap = gap / 2;
const DesktopBreakPoint = 1024;

export const LocationOverviewTab = () => {  

    const { id } = useParams<"id">();

    const { token: { colorBorder } } = theme.useToken();

    // Layout Process -> TODO Convert in a Hook after Revision
    const { width: widthFullLayout = 0,  ref: fullLayoutRef } = useResizeDetector({refreshMode: 'debounce', refreshRate: 50 });
    const { height: heightLocationCard = 0,  ref: locationCardRef } = useResizeDetector({refreshMode: 'debounce', refreshRate: 50 });

    const [area1Height, setArea1Height] = useState(0);

    useLayoutEffect(() => {
        const height = heightLocationCard ?? 0;
        let newMaxHeight = height;
        if (widthFullLayout > DesktopBreakPoint) {
            newMaxHeight = height > 0 ? ((height /  2) - halfGap) : 0;
        }
        if (widthFullLayout < DesktopBreakPoint) {
            newMaxHeight = height > 0 ? (height - halfGap) : 0;
        }
        if (newMaxHeight !== area1Height) { setArea1Height(newMaxHeight) };
    }, [heightLocationCard])

    const paddingCard = { paddingLeft: 16, paddingBottom: 16, paddingRight: 16, paddingTop: 0 };

    return (
        <TabContainer>
            <div 
                ref={fullLayoutRef} 
                className={`${widthFullLayout < DesktopBreakPoint ? "locationOverviewTabContainerSM" : "locationOverviewTabContainerXl" } pl-20 pr-20 pt-20`}
            >
                <div id="area-0">
                    <div ref={locationCardRef} >
                        <LocationCard widthLayoutParent={widthFullLayout}/>
                    </div>
                </div>
                <div 
                    id="area-1"
                    className='overflow-scroll'
                    style={{ maxHeight: area1Height }}
                >   
                    <CustomCardLayout 
                        bordered={true}
                        className='h-full overflow-scroll'
                        styles={{
                            body: { height: '100%', ...paddingCard }
                        }}
                        style={{ height: area1Height, borderColor: colorBorder }}
                    >
                        <LocationSitesTable tableCustomizedProps={{  scroll: { y: area1Height - (gap * 2) - 88 - 7 } }} />
                    </CustomCardLayout>
                </div>
                <div 
                    id="area-2" 
                    style={{ maxHeight: area1Height }}>
                    <CustomCardLayout 
                        bordered={true} 
                        className='h-full'
                        style={{ height: area1Height, borderColor: colorBorder }}  
                        styles={{
                            header: { borderColor: colorBorder },
                            body: { height: '100%', ...paddingCard }
                        }}
                    >
                        <LocationCompaniesList />
                    </CustomCardLayout>
                </div>
                <div id="area-3">
                    <CustomCardLayout 
                        bordered={true} 
                        className='w-full h-full' 
                        style={{ borderColor: colorBorder }} 
                        styles={{
                            header: { borderColor: colorBorder },
                            body: { height: '100%' }
                        }}
                    >
                        <AddressGenericCardMap referenceType="LOCATION" referenceId={id} />
                    </CustomCardLayout>
                </div>
            </div>

            <CustomFieldsGenericList referenceId={id} referenceType={"LOCATION"} />
        </TabContainer>
    )
}
