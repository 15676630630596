import { BackButton } from "@components/Utils/BackButton"
import { TabContainer } from "@components/Utils/TabContainer"
import { Badge, Button, Col, Form, Input, Row, Switch, theme } from "antd";
import { useAppDispatch, useAppSelector } from "@store/store";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { getTemplateById, getTemplateLineItems, saveBudgetTemplateComplete } from "@store/slices/budget-template-extended";
import { getCostGroupingsExtended } from "@store/slices/cost-grouping";
import { getAllCostCategories } from "@store/slices/cost-category";
import { getAllCostSubCategories } from "@store/slices/cost-sub-category";
import { useTranslation } from "react-i18next";
import { useLayoutContex } from "@providers/LayoutProvider";
import "@styles/components/budget-template-edit.less";
import { SearchTable } from "@components/Utils/SearchTable";
import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { asyncLaunchNotification } from "@store/slices/notification";
import { IBudgetTemplateSave } from "@models/budget-template.model";

export const BudgetTemplateEdit = () => {

    const { token: { 
        colorPrimary, 
        colorBgContainer, 
        colorBorderSecondary,
        colorText, 
        colorWarningText,
        colorFillTertiary,
        colorFillSecondary,
        colorTextSecondary,
        colorTextTertiary,
        colorSuccessText,
        colorErrorHover,
        colorFillQuaternary } } = theme.useToken();
    
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation();
    const { entity: template } = useAppSelector((state) => state.BudgetTemplateExtended);
    const { setHeaderLabelEntityState } = useLayoutContex()

    const [lineItems, setLineItems] = useState<any[]>([])

    const { entities: costGroupings } = useAppSelector((state) => state.CostGrouping);
    const { entities: costCategories } = useAppSelector((state) => state.CostCategory);
    const { entities: costSubCategories } = useAppSelector((state) => state.CostSubCategory);
    const { lineItemList } = useAppSelector((state) => state.BudgetTemplateExtended);

    const [unsavedChangesCount, setUnsavedChangesCount] = useState<number>(0)
    const [templateLoaded, setTemplateLoaded] = useState<boolean>(false)
    const [templateName, setTemplateName] = useState<string>('')
    const [templateActive, setTemplateActive] = useState<boolean>(false)
    const [expandedCostGroupingIds, setExpandedCostGroupingIds] = useState<number[]>([])
    const [expandedCostCategoryIds, setExpandedCostCategoryIds] = useState<number[]>([])
    const [expandedCostGroupingIdsInTemplate, setExpandedCostGroupingIdsInTemplate] = useState<number[]>([])
    const [expandedCostCategoryIdsInTemplate, setExpandedCostCategoryIdsInTemplate] = useState<number[]>([])

    const [availableItemsSearch, setAvailableItemsSearch] = useState<string>('')
    const [templateSearch, setTemplateSearch] = useState<string>('')

    useEffect(() => {
        setTemplateName(template?.name || '')
        setHeaderLabelEntityState(template?.name || '');
        setTemplateActive(template?.active || false)
        setTemplateLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template]);

    const navigateBack = () => {
        navigate('/admin/module-configuration/budget/templates')
    }

    const saveTemplate = () => {
        const name = (templateName || '').trim()
        
        // Check basic errors
        let error = '';
        if (!name) {
            error = t("admin.nameFieldIsRequired");
        }

        if (error) {
            dispatch(asyncLaunchNotification({
                type: "error",
                config: {
                  message: `Changes could not be saved`,
                  description: error
                }
            }))
            return;
        }
        const templateSaveData: IBudgetTemplateSave = {
            id: template?.id || 0,
            name: name,
            active: templateActive,
            costSubCategoryIds: lineItems.map(x => x.costSubCategory?.id)
        };
        dispatch(saveBudgetTemplateComplete(templateSaveData));
        setUnsavedChangesCount(0)
    }

    useEffect(() => {
        setHeaderLabelEntityState(t("admin.createBudgetTemplate"));

        if (params.templateId) {
            dispatch(getTemplateById(params.templateId))
            dispatch(getTemplateLineItems(params.templateId));
        }
        dispatch(getCostGroupingsExtended())
        dispatch(getAllCostCategories())
        dispatch(getAllCostSubCategories())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.templateId]);

    const costGroupingsGrouped = useMemo(() => {
        return costGroupings.map((cg) => {
            return {
                ...cg,
                costCategories: costCategories.filter(x => x.costGrouping?.id === cg.id).map((cc) => {
                    return {
                        ...cc,
                        costSubcategories: costSubCategories.filter(x => x.costCategory?.id === cc.id)
                    }
                })
            }
        });
    }, [costGroupings, costCategories, costSubCategories]);

    const costGroupingsTemplate = useMemo(() => {
        const costSubCategoryIds = lineItems.map(x => x.costSubCategory?.id || 0);
        const costSubCategoriesInTemplate = costSubCategories.filter(x => costSubCategoryIds.indexOf(x.id) !== -1);
        const costCategoryIds = costSubCategoriesInTemplate.map(x => x.costCategory?.id || 0);
        const costCategoriesInTemplate = costCategories.filter(x => costCategoryIds.indexOf(x.id || 0) !== -1);
        const costGroupingIds = costCategoriesInTemplate.map(x => x.costGrouping?.id || 0);
        const allCostGroupingsInTemplate = costGroupings.filter(x => costGroupingIds.indexOf(x.id || 0) !== -1)
            .map((cg) => {
                const costCategoriesForGrouping = costCategoriesInTemplate.filter(x => x.costGrouping?.id === cg.id);
                return {
                    ...cg,
                    expanded: expandedCostGroupingIdsInTemplate.indexOf(cg.id || 0) >= 0,
                    costCategoryCount: costCategoriesForGrouping.length,
                    costSubCategoryCount: 0,
                    costCategories: costCategoriesForGrouping.map((cc) => {
                        const costSubCategoriesForCat = costSubCategoriesInTemplate.filter(x => x.costCategory?.id === cc.id);
                        const costSubCategoriesForCatCount = costSubCategoriesForCat.length;
                        return {
                            ...cc,
                            expanded: expandedCostCategoryIdsInTemplate.indexOf(cc.id || 0) >= 0,
                            costSubCategoryCount: costSubCategoriesForCatCount,
                            costSubCategories: costSubCategoriesForCat.filter(x => x.name && x.name?.toLowerCase().indexOf(templateSearch?.toLowerCase() || '') >= 0)
                        }
                    })
                }
            });
        for (let i = 0; i < allCostGroupingsInTemplate.length; i++) {
            allCostGroupingsInTemplate[i].costSubCategoryCount = allCostGroupingsInTemplate[i].costCategories.map(x => x.costSubCategories.length).reduce((acc, cur) => acc + cur, 0)
        }
        return allCostGroupingsInTemplate;
    }, [lineItems, templateSearch, expandedCostGroupingIdsInTemplate, expandedCostCategoryIdsInTemplate, costCategories, costGroupings, costSubCategories]);

    const costGroupingsDisplay = useMemo(() => {
        const allCostGroupings = costGroupingsGrouped.map((cg) => {
            return {
                id: cg.id,
                name: cg.name,
                order: cg.order,
                categoryCount: cg.categoryCount,
                expanded: expandedCostGroupingIds.indexOf(cg.id || 0) >= 0,
                inTemplate: costGroupingsTemplate.some(x => x.id === cg.id),
                costSubcategoryCount: 0,
                costCategories: cg.costCategories.map((cc) => {
                    return {
                        id: cc.id,
                        name: cc.name,
                        order: cc.order,
                        expanded: expandedCostCategoryIds.indexOf(cc.id || 0) >= 0,
                        inTemplate: costGroupingsTemplate.flatMap(x => x.costCategories).flatMap(x => x.costSubCategories).filter(x => x.costCategory?.id === cc.id).length > 0,
                        costSubcategoryCount: cc.costSubcategories.length,
                        costSubcategories: cc.costSubcategories.filter(x => x.name && x.name?.toLowerCase().indexOf(availableItemsSearch?.toLowerCase() || '') >= 0)
                            .map(csc => {
                                return {
                                    ...csc,
                                    inTemplate: lineItems.some(x => x.costSubCategory.id === csc.id)
                                }
                            })
                    }
                })
            }
        })
        for (let i = 0; i < allCostGroupings.length; i++) {
            allCostGroupings[i].costSubcategoryCount = allCostGroupings[i].costCategories.map(x => x.costSubcategories.length).reduce((acc, cur) => acc + cur, 0)
        }
        return allCostGroupings;
    }, [costGroupingsGrouped, availableItemsSearch, expandedCostGroupingIds, expandedCostCategoryIds, costGroupingsTemplate, lineItems]);

    useEffect(() => {
        setLineItems(lineItemList);
    }, [lineItemList]);

    const expandCostCategory = (costCategoryId: number) => {
        const ids = [...expandedCostCategoryIds];
        const idx = expandedCostCategoryIds.indexOf(costCategoryId);
        if (idx === -1) {
            ids.push(costCategoryId);
        } else {
            ids.splice(idx, 1);
        }
        setExpandedCostCategoryIds(ids);
    }

    const expandCostGrouping = (costGroupingId: number) => {
        const ids = [...expandedCostGroupingIds];
        const idx = expandedCostGroupingIds.indexOf(costGroupingId);
        if (idx === -1) {
            ids.push(costGroupingId);
        } else {
            ids.splice(idx, 1);
        }
        setExpandedCostGroupingIds(ids);
    }

    const expandCostCategoryInTemplate = (costCategoryId: number) => {
        const ids = [...expandedCostCategoryIdsInTemplate];
        const idx = expandedCostCategoryIdsInTemplate.indexOf(costCategoryId);
        if (idx === -1) {
            ids.push(costCategoryId);
        } else {
            ids.splice(idx, 1);
        }
        setExpandedCostCategoryIdsInTemplate(ids);
    }

    const expandCostGroupingInTemplate = (costGroupingId: number) => {
        const ids = [...expandedCostGroupingIdsInTemplate];
        const idx = expandedCostGroupingIdsInTemplate.indexOf(costGroupingId);
        if (idx === -1) {
            ids.push(costGroupingId);
        } else {
            ids.splice(idx, 1);
        }
        setExpandedCostGroupingIdsInTemplate(ids);
    }

    const collapseAll = () => {
        setExpandedCostCategoryIds([])
        setExpandedCostGroupingIds([])
    }

    const expandAll = () => {
        setExpandedCostGroupingIds(costGroupings.map(x => x.id || 0));
        setExpandedCostCategoryIds(costCategories.map(x => x.id || 0));
    }

    const expandAllInTemplate = () => {
        setExpandedCostGroupingIdsInTemplate(costGroupings.map(x => x.id || 0));
        setExpandedCostCategoryIdsInTemplate(costCategories.map(x => x.id || 0));
    }

    const collapseAllInTemplate = () => {
        setExpandedCostCategoryIdsInTemplate([])
        setExpandedCostGroupingIdsInTemplate([])
    }

    const removeCostSubCategoryFromTemplate = (costSubCategoryId: number) => {
        const idx = lineItems.findIndex(x => x.costSubCategory.id === costSubCategoryId);
        if (idx !== -1) {
            const newItems = [...lineItems];
            newItems.splice(idx, 1);
            setLineItems(newItems);
            setUnsavedChangesCount(unsavedChangesCount + 1)
        }
    }

    const removeCostCategoryFromTemplate = (costCategoryId: number) => {
        const allCostSubCategoryIds = costSubCategories.filter(x => x.costCategory?.id === costCategoryId).map(x => x.id);
        const itemsToRemove = lineItems.filter(x => allCostSubCategoryIds.indexOf(x.costSubCategory.id) !== -1);
        if (itemsToRemove.length > 0) {
            const newItems = [...lineItems];
            for (let i = 0; i < itemsToRemove.length; i++) {
                const idx = newItems.findIndex(x => x.costSubCategory.id === itemsToRemove[i].costSubCategory.id);
                if (idx !== -1) {
                    newItems.splice(idx, 1);
                }
            }
            setLineItems(newItems);
            setUnsavedChangesCount(unsavedChangesCount + 1)
        }
    }

    const removeCostGroupingFromTemplate = (costGroupingId: number) => {
        const allCostCategoryIds = costCategories.filter(x => x.costGrouping?.id === costGroupingId).map(x => x.id);
        const allCostSubCategoryIds = costSubCategories.filter(x => allCostCategoryIds.indexOf(x.costCategory?.id) !== -1).map(x => x.id);
        const itemsToRemove = lineItems.filter(x => allCostSubCategoryIds.indexOf(x.costSubCategory.id) !== -1);
        if (itemsToRemove.length > 0) {
            const newItems = [...lineItems];
            for (let i = 0; i < itemsToRemove.length; i++) {
                const idx = newItems.findIndex(x => x.costSubCategory.id === itemsToRemove[i].costSubCategory.id);
                if (idx !== -1) {
                    newItems.splice(idx, 1);
                }
            }
            setLineItems(newItems);
            setUnsavedChangesCount(unsavedChangesCount + 1)
        }
    }

    const addCostSubCategoryToTemplate = (costSubCategoryId: number) => {
        const lineItem = {
            budgetTemplate: {
                id: template?.id || 0
            },
            costSubCategory: {
                id: costSubCategoryId
            },
            id: 0
        };
        const newLineItems = [...lineItems];
        newLineItems.push(lineItem);
        setLineItems(newLineItems);
        setUnsavedChangesCount(unsavedChangesCount + 1)
    }

    const addCostCategoryToTemplate = (costCategoryId: number) => {
        const costSubCategoryIds = costSubCategories.filter(x => x.costCategory?.id === costCategoryId).map(x => x.id);
        const newLineItems = [...lineItems];
        for (let i = 0; i < costSubCategoryIds.length; i++) {
            const idx = lineItems.findIndex(x => x.costSubCategory.id === costSubCategoryIds[i]);
            if (idx === -1) {
                const lineItem = {
                    budgetTemplate: {
                        id: template?.id || 0
                    },
                    costSubCategory: {
                        id: costSubCategoryIds[i]
                    },
                    id: 0
                };
                newLineItems.push(lineItem);
            }
        }
        setLineItems(newLineItems);
        setUnsavedChangesCount(unsavedChangesCount + 1)
    }

    const addCostGroupingToTemplate = (costGroupingId: number) => {
        const allCostCategoryIds = costCategories.filter(x => x.costGrouping?.id === costGroupingId).map(x => x.id);
        const costSubCategoryIds = costSubCategories.filter(x => allCostCategoryIds.indexOf(x.costCategory?.id) !== -1).map(x => x.id);
        const newLineItems = [...lineItems];
        for (let i = 0; i < costSubCategoryIds.length; i++) {
            const idx = lineItems.findIndex(x => x.costSubCategory.id === costSubCategoryIds[i]);
            if (idx === -1) {
                const lineItem = {
                    budgetTemplate: {
                        id: template?.id || 0
                    },
                    costSubCategory: {
                        id: costSubCategoryIds[i]
                    },
                    id: 0
                };
                newLineItems.push(lineItem);
            }
        }
        setLineItems(newLineItems);
        setUnsavedChangesCount(unsavedChangesCount + 1)
    }

    return <>
        <TabContainer>
            {(templateLoaded === true) && <div>
                <div className="flex flex-col overflow-auto">
                    <Row>
                        <Col span={24}>
                            <div className="flex flex-row items-center pl-10 pt-11 pb-11" style={{ borderTop: `1px solid ${colorBorderSecondary}`, borderBottom: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                                <BackButton onClick={navigateBack} >{t("admin.templateList")}</BackButton>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div style={{ background: `${colorBgContainer}`, padding: '1rem', paddingRight: '1.5rem'  }}>
                    <div style={{ borderBottom: `1px solid ${colorBorderSecondary}` }}>
                        <div className="flex flex-row" style={{ gap: '0.25rem' }}>
                            <div style={{ lineHeight: '28px', fontSize: '20px', fontWeight: 500, color: colorText, flex: 1, paddingBottom: '12px' }}>
                                {template.name}
                            </div>
                            <div>
                                <Button type='default' style={{ marginRight: '10px' }} onClick={navigateBack}>
                                    {t("generic.cancel")}
                                </Button>
                                <Button type='primary' onClick={saveTemplate}>
                                    {t("generic.save")}
                                </Button>
                                {(unsavedChangesCount > 0) && <Badge color='red' count={unsavedChangesCount} style={{ position: 'absolute', marginTop: '-25px', marginRight: '-8px' }} />}
                            </div>
                        </div>
                        {(unsavedChangesCount > 0) && <div style={{ color: colorWarningText, textAlign: 'right', marginBottom: '8px' }}>{t("admin.thereAreUnsavedChanges")}</div>}
                    </div>
                    <Row gutter={16} style={{ marginTop: '1rem' }}>
                        <Col span={14}>
                            <Form layout={'vertical'}>
                                <Form.Item
                                    label={t("admin.templateName")}
                                    rules={[
                                        { required: true, message: t("admin.nameFieldIsRequired") },
                                    ]}
                                >
                                    <Input
                                        value={templateName} 
                                        placeholder={t("generic.name")} 
                                        allowClear 
                                        onChange={(e) => { setTemplateName(e.target.value) }}
                                        style={{ width: '100%' }} />
                                </Form.Item>
                            </Form>
                            <div>
                                <Row gutter={16}>
                                    <Col span={"none"} className="pr-0" style={{ color: colorTextSecondary}}>
                                        {t("admin.templateStatus")}:
                                    </Col>
                                    <Col style={{ color: (templateActive ? colorSuccessText : colorTextTertiary)}}>
                                        {(templateActive ? t("generic.active") : t("generic.unpublished"))}
                                    </Col>
                                    <Col span={"none"} className="pr-0" style={{ flex: '1', textAlign: 'right' }}>
                                        <Form.Item valuePropName="checked">
                                            <Switch 
                                                checked={templateActive} style={{ marginBottom: '10px' }} onChange={(e) => { setTemplateActive(e) }}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col span={10} style={{ border: `1px solid ${colorFillSecondary}`, backgroundColor: colorFillTertiary, borderRadius: '0.5rem', padding: '1rem', color: colorTextSecondary }}>
                            <div className="flex flex-col" style={{ height: '100%' }}>
                                <div style={{ flex: 1 }}>
                                    <ol style={{ paddingLeft: '20px' }}>
                                        <li>{t("admin.budgetTemplate.addYourCostLinesFromTheList")}</li>
                                        <li>{t("admin.budgetTemplate.makeSureItsActive")}</li>
                                    </ol>
                                </div>
                                <div style={{ flex: 0, color: colorTextTertiary, borderTop: `1px solid ${colorFillSecondary}`, paddingTop: '1rem', fontSize: '12px', lineHeight: '20px' }}>
                                    {t("admin.budgetTemplate.noteAnyChangesToThisTemplateWill")} <strong>{t("admin.budgetTemplate.not")}</strong> {t("admin.budgetTemplate.beAppliedRetroactively")}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="template-lists-container">
                    <div className="panel-left">
                        <div style={{ padding: '1rem' }}>
                            <div className="panel-header" style={{ color: colorTextSecondary, backgroundColor: colorFillTertiary, border: `1px solid ${colorBorderSecondary}` }}>Available Items</div>
                            <div className="mt-10 mb-10" style={{ display: "flex", gap: '10px' }}>
                                <div style={{ flex: 1 }}>
                                    <SearchTable 
                                        style={{ width: '100%' }}
                                        placeholder={t("admin.quickFilter")}
                                        handleOnSearch={() => {}}
                                        value={availableItemsSearch}
                                        onChange={(e) => { setAvailableItemsSearch(e.target.value) }}
                                        onClear={() => { setAvailableItemsSearch('') }} />
                                </div>
                                <div className="action-pill" style={{ background: colorFillQuaternary, border: `1px solid ${colorFillTertiary}`, color: colorPrimary }}>
                                    <div onClick={expandAll} className="mr-5 pr-5" style={{ borderRight: `1px solid ${colorBorderSecondary}` }}>{t("generic.expandAll")}</div>
                                    <div onClick={collapseAll}>{t("generic.collapseAll")}</div>
                                </div>
                            </div>
                            {costGroupingsDisplay.filter(x => x.costSubcategoryCount > 0).map((cg) => {
                                return <>
                                    <div className="cost-group" style={{ color: colorTextTertiary, backgroundColor: colorFillTertiary, border: `1px solid ${colorBorderSecondary}`}}>
                                        <div className="cost-group-item">
                                            <i className={`chevron-down ${cg.expanded ? 'expanded' : ''}`} onClick={() => { expandCostGrouping(cg.id || 0) }}/>
                                            <span className='ml-10'>{cg.name}</span>
                                            <div style={{ flex: 1 }}>
                                                <Badge
                                                    count={cg.categoryCount}
                                                    className='ml-10'
                                                    style={{ backgroundColor: colorFillSecondary, color: colorTextTertiary }} />
                                            </div>
                                            {!cg.inTemplate && <PlusOutlined onClick={() => addCostGroupingToTemplate(cg.id || 0)} style={{ color: colorPrimary, fontSize: '18px' }} />}
                                            {cg.inTemplate && <CheckOutlined style={{ color: '#000', opacity: '0.1' }} />}
                                        </div>
                                        {cg.expanded && cg.costCategories.filter(x => x.costSubcategories.length > 0).map((cc) => {
                                            return <>
                                                <div className="cost-group-item ml-20">
                                                    <i className={`chevron-down ${cc.expanded ? 'expanded' : ''}`} onClick={() => { expandCostCategory(cc.id || 0) }}/>
                                                    <span className='ml-10'>{cc.name}</span>
                                                    <div style={{ flex: 1 }}>
                                                        <Badge
                                                            count={cc.costSubcategoryCount}
                                                            className='ml-10'
                                                            style={{ backgroundColor: colorFillSecondary, color: colorTextTertiary }} />
                                                    </div>
                                                    {!cc.inTemplate && <PlusOutlined onClick={() => addCostCategoryToTemplate(cc.id || 0)} style={{ color: colorPrimary, fontSize: '18px' }} />}
                                                    {cc.inTemplate && <CheckOutlined style={{ color: '#000', opacity: '0.1' }} />}
                                                </div>
                                                {cc.expanded && cc.costSubcategories.map((csc) => {
                                                    return <div className="cost-group-item ml-40">
                                                        <span className='ml-10'>{csc.name}</span>
                                                        <div style={{ flex: 1 }}>
                                                        </div>
                                                        {!csc.inTemplate && <PlusOutlined onClick={() => addCostSubCategoryToTemplate(csc.id || 0)} style={{ color: colorPrimary, fontSize: '18px' }} />}
                                                        {csc.inTemplate && <CheckOutlined style={{ color: '#000', opacity: '0.1' }} />}
                                                    </div>
                                                })}
                                            </>
                                        })}
                                    </div>
                                </>
                            })}
                        </div>
                    </div>
                    <div className="panel-right">
                        <div style={{ padding: '1rem' }}>
                            <div className="panel-header" style={{ color: colorTextSecondary, backgroundColor: colorFillTertiary, border: `1px solid ${colorBorderSecondary}` }}>{t("admin.inThisTemplate")}</div>
                            <div className="mt-10 mb-10" style={{ display: "flex", gap: '10px' }}>
                                <div style={{ flex: 1 }}>
                                    <SearchTable 
                                        style={{ width: '100%' }}
                                        placeholder={t("admin.quickFilter")}
                                        handleOnSearch={() => {}}
                                        value={templateSearch}
                                        onChange={(e) => { setTemplateSearch(e.target.value) }}
                                        onClear={() => { setTemplateSearch('') }} />
                                </div>
                                <div className="action-pill" style={{ background: colorFillQuaternary, border: `1px solid ${colorFillTertiary}`, color: colorPrimary }}>
                                    <div onClick={expandAllInTemplate} className="mr-5 pr-5" style={{ borderRight: `1px solid ${colorBorderSecondary}` }}>{t("generic.expandAll")}</div>
                                    <div onClick={collapseAllInTemplate}>{t("generic.collapseAll")}</div>
                                </div>
                            </div>
                            {costGroupingsTemplate.filter(x => x.costSubCategoryCount > 0).map((cg) => {
                                return <>
                                    <div className="cost-group" style={{ color: colorTextTertiary, backgroundColor: colorFillTertiary, border: `1px solid ${colorBorderSecondary}`}}>
                                        <div className="cost-group-item">
                                            <i className={`chevron-down ${cg.expanded ? 'expanded' : ''}`} onClick={() => { expandCostGroupingInTemplate(cg.id || 0) }}/>
                                            <span className='ml-10'>{cg.name}</span>
                                            <div style={{ flex: 1 }}>
                                                <Badge
                                                    count={cg.categoryCount}
                                                    className='ml-10'
                                                    style={{ backgroundColor: colorFillSecondary, color: colorTextTertiary }} />
                                            </div>
                                            <CloseOutlined 
                                                style={{ color: colorErrorHover, fontSize: '18px' }}
                                                onClick={() => removeCostGroupingFromTemplate(cg.id || 0) } />
                                        </div>
                                        {cg.expanded && cg.costCategories.filter(x => x.costSubCategories.length > 0).map((cc) => {
                                            return <>
                                                <div className="cost-group-item ml-20">
                                                    <i className={`chevron-down ${cc.expanded ? 'expanded' : ''}`} onClick={() => { expandCostCategoryInTemplate(cc.id || 0) }}/>
                                                    <span className='ml-10'>{cc.name}</span>
                                                    <div style={{ flex: 1 }}>
                                                        <Badge
                                                            count={cc.costSubCategoryCount}
                                                            className='ml-10'
                                                            style={{ backgroundColor: colorFillSecondary, color: colorTextTertiary }} />
                                                    </div>
                                                    <CloseOutlined 
                                                            style={{ color: colorErrorHover, fontSize: '18px' }}
                                                            onClick={() => removeCostCategoryFromTemplate(cc.id || 0)} />
                                                </div>
                                                {cc.expanded && cc.costSubCategories.map((csc) => {
                                                    return <div className="cost-group-item ml-40">
                                                        <span className='ml-10'>{csc.name}</span>
                                                        <div style={{ flex: 1 }}>
                                                        </div>
                                                        <CloseOutlined 
                                                            style={{ color: colorErrorHover, fontSize: '18px' }}
                                                            onClick={() => removeCostSubCategoryFromTemplate(csc.id || 0)} />
                                                    </div>
                                                })}
                                            </>
                                        })}
                                    </div>
                                </>
                            })}
                        </div>
                    </div>
                </div>
            </div>}
        </TabContainer>
    </>
}