import { Card, Col, Row, Skeleton, theme } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { StaticImageMap } from "@components/Google/StaticMap/StaticImageMap";
import { EmptyLabel } from "@components/Utils/EmptyLabel";
import { IResultSite } from "@models/search";
import { formatDatePipe } from "@shared/util/date-utils";
import { useNavigate } from "react-router-dom";
import { TextEllipsis } from "@components/Utils/TextEllipsisContent";
import { ResultHighlightedString } from "./ResultHighlightedString";
import { If } from "@components/Utils/Structural";
import { MapMarkerSvgIcon } from "@components/Icons/MapMarkerSvgIcon";

interface ISitesCardResultProps {
    site: IResultSite
}

export const ResultSitesCard = (props: ISitesCardResultProps) => {

    const { site } = props;

    const { token: { fontSize, colorFillQuaternary, colorTextSecondary, colorTextQuaternary, colorPrimary } } = theme.useToken();

    const navigate = useNavigate();

    const onCLickCard = (event: any) => {
        if (!site.id || !site.locationId) return

        if (event.metaKey) {
            window.open(`${window.location.origin}/location/${site.locationId}/sites/${site.id}`)
        } else {
            navigate(`/location/${site.locationId}/sites/${site.id}`);
        }
    }

    return (
        <Card onClick={onCLickCard} className='mb-15 cursor-pointer' hoverable bodyStyle={{ margin: 0, padding: 0 }} style={{ borderRadius: 4 }}>
            <div className='flex flex-row justify-between'>
                <div className='flex flex-col pt-5 pr-10 pb-5 pl-10 w-full'>
                    <span style={{ fontSize: fontSize + 2 }} className='pt-5 pl-15 font-semibold'>
                        {site?.name}
                    </span>
                    <Row justify={"space-between"} className='flex flex-row items-center justify-between pl-15 pt-8 pb-8 mt-15 w-full' style={{ backgroundColor: colorFillQuaternary, borderRadius: 4, color: colorTextSecondary }}>
                        <Col span={6} className="pr-15">
                            <div className="flex flex-row w-full">
                                <span className='font-semibold'>Brand</span>:
                                <TextEllipsis text={site?.brandName} style={{ color: colorTextSecondary }} className="ml-5" />
                            </div>
                        </Col>
                        <Col span={6} className="pr-15">
                            <div className="flex flex-row w-full">
                                <span className='font-semibold'>Market</span>:
                                <TextEllipsis text={site?.marketName} style={{ color: colorTextSecondary }} className="ml-5" />
                            </div>
                        </Col>
                        <Col span={6} className="pr-15">
                            <span className='font-semibold'>Open Date</span>: {(site?.openDate && formatDatePipe(site?.openDate)) || <EmptyLabel />}
                        </Col>
                        <Col span={6} className="pr-15" >
                            <TextEllipsis text={site?.cityName} style={{ color: colorTextSecondary }} />
                        </Col>
                    </Row>
                    <Row className='mt-10 pl-15' style={{ fontSize: fontSize - 2 }}>
                        <ResultHighlightedString matchText={site.matchText} />
                    </Row>
                    <Row className='mt-15 pl-15 pb-10'>
                        <span className='pr-10'>Go to Site</span>
                        <RightOutlined style={{ fontSize: fontSize - 3, fontWeight: 700, color: colorPrimary }} />
                    </Row>
                </div>
                <div className='pt-10 pr-10 pb-10 pr-10 h-full'>
                    <If condition={!!(site?.latitude && site?.longitude)}>
                        <StaticImageMap
                            width={134}
                            height={156}
                            markers={[{ latitude: site?.latitude || "", longitude: site?.longitude || "" }]}
                        />
                    </If>
                    <If condition={!Boolean(site?.latitude && site?.longitude)}>
                        <Skeleton.Node style={{ width: 134, height: 156, borderRadius: 4 }} className="relative w-full flex items-center">
                            <div className="flex items-center justify-center">
                                <MapMarkerSvgIcon width='3rem' height='3rem' iconStyles={{ color: colorTextQuaternary }} />
                            </div>
                        </Skeleton.Node>
                    </If>
                </div>
            </div>
        </Card>
    )
}
