/* eslint-disable react-hooks/exhaustive-deps */
import { ArgsProps } from 'antd/es/notification/interface';
import { useAppSelector } from "@store/store";
import { createContext, useContext, useEffect, useMemo } from "react";
import { usePreferencesProvider } from "./PreferencesProvider";
import { notification } from 'antd';

interface INotificationContext {
    openNotification: (settings: NotificationSettings) => void
}

export type NotificationSettings = {
    type: 'success' | 'error' | 'info' | 'warning' | 'open',
    config: Omit<ArgsProps, "props">
}

export const NotificationContext = createContext({} as INotificationContext);

export const NotificationsProvider = ({ children }: any) => {

    const configNotification = useAppSelector(state => state.Notification);

    const { soundNotification, placementNotification } = usePreferencesProvider();

    const [api, contextHolder] = notification.useNotification();

    const playNotificationSound = () => {
        if (!soundNotification) return;

        const audioSelected = new Audio(soundNotification);
    
        if (audioSelected && audioSelected?.play) {
            const audioPromise = audioSelected.play();
            if(audioPromise !== undefined){
                audioPromise.then(() => {
                    // Autoplay started
                    audioSelected.muted = false;
                }).catch(error => {
                    // Autoplay was prevented.
                    audioSelected.muted = true;
                });
            }
        }
    }

    const openNotification = (settings: NotificationSettings) => {
        const propsConfig = settings.config;
        const { message, description, placement } = propsConfig;
        const config: ( ArgsProps | React.Key) = {
            ...propsConfig,
            message: message ? message : `Notification`,
            ...description && { description: description } ,
            placement: placement ? placement : placementNotification,
        };
        api[settings.type](config);
        playNotificationSound();
    };

    useEffect(() => {
        if(configNotification.readyToLaunch){
            openNotification(configNotification.settings);
        }
    }, [configNotification])
    
    const value = {
        openNotification
    }

    const Provider = useMemo(() => {
        return (
            <NotificationContext.Provider value={value}>
                {contextHolder}
                {children}
            </NotificationContext.Provider>
        )
    }, [])

    return Provider;
}

export const useNotificationsProvider = (): INotificationContext => {
    return useContext(NotificationContext) as INotificationContext;
};
