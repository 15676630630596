import { RfiStatusEnum } from "@models/enumerations/rfi-status-enum.model";
import { IRfiCategory } from "@models/rfi-category.model";
import { IRfi } from "@models/rfi.model";
import { IUser } from "@models/user.model";
import { OnRowEvent } from "@models/utils/table.utils.model";
import { formatDatePipe } from "@shared/util/date-utils";
import { richCommentJsonToPlainText } from "@shared/util/rich-comment-utils";
import { dateSorter } from "@shared/util/table-utils";
import { getUserDisplayName } from "@shared/util/user-utils";
import { getEntities } from "@store/slices/rfi";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Table, theme } from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export interface IRfiTableProps {
    showOnlyOpen?: boolean,
}

export const RfiTable = (props: IRfiTableProps) => {

    const { showOnlyOpen } = props;

    const { token: { colorBorderSecondary } } = theme.useToken();

    const { projectId } = useParams<"projectId">();

    const { entities: rfiList, totalItems, loading } = useAppSelector((state) => state.Rfi);
    const dispatch = useAppDispatch();
    const [tableParams, setTableParams] = useState({
        size: 25,
        current: 0,
        sort: `number,asc`,
        projectId,
        openState: showOnlyOpen
    })
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const filterRfis = () => {
        const { current, size, sort } = tableParams;
        dispatch(
            getEntities({
                page: current,
                size: size,
                sort: sort,
                query: buildFilters()
            })
        );
    }

    useEffect(() => {
        if (projectId) {
            setTableParams((oldValue) => {
                return {
                    ...oldValue,
                    projectId: projectId
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    useEffect(() => {
        setTableParams((oldValue) => {
            return {
                ...oldValue,
                current: 0,
                openState: showOnlyOpen
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOnlyOpen]);

    useEffect(() => {
        filterRfis();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableParams]);

    const buildFilters = (): string => {
        let filter = '';

        if (tableParams.projectId) {
            filter = `projectId.equals=${projectId}`
        }

        if (tableParams.openState === true) {
            if (filter.length > 0) filter += '&'
            filter += 'status.equals=OPEN'
        }

        return filter;
    }

    const onChange: TableProps<IRfi>['onChange'] = (pagination, filters, sorter, extra) => {
        setTableParams((oldValue) => {
            return {
                ...oldValue,
                ...pagination?.pageSize && { size: pagination.pageSize },
                current: pagination.current !== undefined ? pagination.current - 1 : 0
            }
        })
    };

    const TableComponentWrapper = (node: any) => {
        const style = { border: `1px solid ${colorBorderSecondary}`, borderRadius: 4 }
        return (
            <table style={{ ...node.style, ...style }}>
                { node.children.map((child: any) => child) }
            </table>
        ); 
    }

    const onRowClick = (event: OnRowEvent<IRfi>) => {
        const { record } = event;
        navigate(`${pathname}/${record.id}`);
    }

    return (
        <>
            <Table
                loading={loading}
                rowKey="id"
                size="small"
                columns={columns}
                dataSource={rfiList}
                onChange={onChange}
                onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => onRowClick({event, record, rowIndex})
                    };
                }}
                pagination={{ current: tableParams.current + 1, pageSize: tableParams.size, total: totalItems }}
                components={{
                    table: (node: any) => TableComponentWrapper(node)
                }}
                rowClassName="cursor-pointer"
            />
        </>
    );
};

const columns: ColumnsType<IRfi> = [
    {
        title: <div className="text-center">#RFI</div>,
        dataIndex: 'number',
        className: ' text-center',
        sorter: ({ id: a }, { id: b }) => Number(a) - Number(b),
        defaultSortOrder: 'ascend',
        width: 60,
    },
    {
        title: 'Submitted',
        dataIndex: 'createdDate',
        ellipsis: true,
        render: (value: string) => { return formatDatePipe(value) },
        sorter: (a, b) => (a?.createdDate && b?.createdDate) ? dateSorter(a?.createdDate, b?.createdDate) : 0,
    },
    {
        title: 'Required By Date',
        dataIndex: 'dueDate',
        ellipsis: true,
        render: (value: string) => { return formatDatePipe(value) },
        sorter: (a, b) => (a?.dueDate && b?.dueDate) ? dateSorter(a?.dueDate, b?.dueDate) : 0,
    },
    {
        title: 'Category',
        dataIndex: 'category',
        render: (value: IRfiCategory) => { return value?.name },
        ellipsis: true,
        sorter: ({ category: a }, { category: b }) => {
            if (String(a?.name) < String(b?.name)) {
                return -1;
            }
            if (String(a?.name) > String(b?.name)) {
                return 1;
            }
            return 0;
        },
    },
    {
        title: 'Question',
        dataIndex: 'questionPayload',
        render: (value: string) => { return richCommentJsonToPlainText(value) },
        ellipsis: true,
        width: '30%',
    },
    {
        title: 'Assigned to',
        dataIndex: 'assignedTo',
        render: (value: IUser) => { return getUserDisplayName(value) },
    },
    {
        title: 'Submitted by',
        render: (rfi: IRfi) => { return getCreatedByLabel(rfi) },
    },
    {
        title: <div className="text-center">Responses</div>,
        dataIndex: 'responsesQty',
        className: ' text-center',
        width: 100,
    },
    {
        title: <div className="text-center">Status</div>,
        dataIndex: 'status',
        className: ' capitalized  text-center',
        render: (value: RfiStatusEnum) => { return value?.toLowerCase() },
        width: 70,
    },
];

const getCreatedByLabel = (rfi: IRfi): String => {
    if (rfi?.createdByFirstName && rfi?.createdByLastName) {
        return rfi.createdByFirstName + ' ' + rfi.createdByLastName;
    } else if (rfi?.createdByFirstName) {
        return rfi.createdByFirstName;
    } else if (rfi?.createdByLastName) {
        return rfi.createdByLastName;
    } else {
        return rfi?.createdBy || '';
    }
}