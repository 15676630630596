import { Col, Empty, Row } from "antd";

export const PunchListEmptyState = () => {

    return (
      <>
        <Row justify={"center"} className="">
          <Col className="h-full">
            <Row align={"middle"} className="pt-40 mt-40 w-full items-center justify-center h-full">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="There are no items yet" />
            </Row>
          </Col>
        </Row>
      </>
    )
}
