import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { IUtil } from '@models/utils';
import { ICompanyBinding, ICompanyBindingExtended, IGetCompaniesRequest } from '@models/company-binding.model';
import { companyExtendedService } from '@services/company-bindings-extended.service';
import { CompanyReferenceEnum, CompanyReferenceEnumListOptions } from '@models/enumerations/company-reference-enum.model';
import { asyncLaunchNotification } from './notification';
import { companyBindingService } from '@services/company-binding.service';
import i18next from 'i18next';
import { IQueryParams } from '@models/pagination';
import { GENERIC_COMPANIES_SORTING_KEY } from '@constants/core';

interface IStateEntity extends IUtil {
  data: ICompanyBinding[];
  dataFullList: ICompanyBinding[];
  totalItems: number;
  loading: boolean;
  errorMessage: any;
}

interface IState {
  [CompanyReferenceEnum.LOCATION]: IStateEntity;
  [CompanyReferenceEnum.SITE]: IStateEntity;
  [CompanyReferenceEnum.PROJECT]: IStateEntity;
}

const getInitialState: IStateEntity = {
  data: [],
  dataFullList: [],
  totalItems: 0,
  loading: false,
  errorMessage: null,
};

const initialState: IState = {
  [CompanyReferenceEnum.LOCATION]: { ...getInitialState },
  [CompanyReferenceEnum.SITE]: { ...getInitialState },
  [CompanyReferenceEnum.PROJECT]: { ...getInitialState },
};

const sliceName: string = 'genericCompany';

const actionGetDataCompanies = async (payload: IGetCompaniesRequest) => {
  const data = await companyExtendedService.getCompanies(payload);
  return data;
};

const actionGetDataCompaniesFullList = async (payload: {
  referenceId: number | string | null;
  referenceType: CompanyReferenceEnumListOptions;
}) => {
  return await companyExtendedService.getCompanies({ ...payload, sort: GENERIC_COMPANIES_SORTING_KEY });
};

const actionCreateCompany = async (payload: ICompanyBindingExtended & IQueryParams, thunkAPI: any) => {
  const data = await companyBindingService.create(payload);
  thunkAPI.dispatch(
    asyncLaunchNotification({
      type: 'success',
      config: {
        message: `${i18next.t('generic.company')}`,
        description: `Successfully linked ${i18next.t('generic.company')}`,
      },
    })
  );
  const payloadGetCompanies: IGetCompaniesRequest = {
    referenceId: payload.referenceId,
    referenceType: CompanyReferenceEnum[payload.referenceType],
    page: payload.page,
    size: payload.size,
    sort: payload.sort,
  };
  thunkAPI.dispatch(getCompanies(payloadGetCompanies));
  thunkAPI.dispatch(
    getCompaniesFullList({ referenceId: payload.referenceId, referenceType: payload.referenceType, sort: GENERIC_COMPANIES_SORTING_KEY })
  );
  return data;
};

export const getLocationCompanies = createAsyncThunk(
  `${sliceName}/${CompanyReferenceEnum.LOCATION}/getLocationCompanies`,
  actionGetDataCompanies
);
export const getLocationCompaniesFullList = createAsyncThunk(
  `${sliceName}/${CompanyReferenceEnum.LOCATION}/getLocationCompaniesFullList`,
  actionGetDataCompaniesFullList
);

export const getSiteCompanies = createAsyncThunk(`${sliceName}/${CompanyReferenceEnum.SITE}/getSiteCompanies`, actionGetDataCompanies);
export const getSiteCompaniesFullList = createAsyncThunk(
  `${sliceName}/${CompanyReferenceEnum.SITE}/getSiteCompaniesFullList`,
  actionGetDataCompaniesFullList
);

export const getProjectCompanies = createAsyncThunk(
  `${sliceName}/${CompanyReferenceEnum.PROJECT}/getProjectCompanies`,
  actionGetDataCompanies
);
export const getProjectCompaniesFullList = createAsyncThunk(
  `${sliceName}/${CompanyReferenceEnum.PROJECT}/getProjectCompaniesFullList`,
  actionGetDataCompaniesFullList
);

export const createLocationCompany = createAsyncThunk(
  `${sliceName}/${CompanyReferenceEnum.LOCATION}/createLocationCompany`,
  actionCreateCompany
);
export const createSiteCompany = createAsyncThunk(`${sliceName}/${CompanyReferenceEnum.SITE}/createSiteCompany`, actionCreateCompany);
export const createProjectCompany = createAsyncThunk(
  `${sliceName}/${CompanyReferenceEnum.PROJECT}/createProjectCompany`,
  actionCreateCompany
);

export const getCompanies = createAsyncThunk(`${sliceName}/getCompanies`, async (payload: IGetCompaniesRequest, thunkAPI) => {
  const thunkToApply = selectThunk(`LIST-${payload.referenceType}`);
  thunkAPI.dispatch(thunkToApply(payload));
});
export const getCompaniesFullList = createAsyncThunk(`${sliceName}/getCompanies`, async (payload: IGetCompaniesRequest, thunkAPI) => {
  const thunkToApply = selectThunk(`FULL-LIST-${payload.referenceType}`);
  thunkAPI.dispatch(thunkToApply(payload));
});

export const createCompany = createAsyncThunk(`${sliceName}/getCompanies`, async (payload: ICompanyBinding & IQueryParams, thunkAPI) => {
  const thunkToApply = selectThunk(`CREATE-${payload.referenceType}`);
  thunkAPI.dispatch(thunkToApply(payload));
});

export const genericCompanySlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reset: state => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getLocationCompanies, getSiteCompanies, getProjectCompanies), (state, action) => {
        const entityIndex = getCompanyReferenceType(action.type);
        const { data, headers } = action.payload;
        state[entityIndex].data = data;
        state[entityIndex].errorMessage = null;
        state[entityIndex].loading = false;
        state[entityIndex].totalItems = headers['x-total-count'] && parseInt(headers['x-total-count'], 10);
      })
      .addMatcher(isFulfilled(getLocationCompaniesFullList, getSiteCompaniesFullList, getProjectCompaniesFullList), (state, action) => {
        const entityIndex = getCompanyReferenceType(action.type);
        const { data } = action.payload;
        state[entityIndex].dataFullList = data;
        state[entityIndex].errorMessage = null;
        state[entityIndex].loading = false;
      })
      .addMatcher(isFulfilled(createLocationCompany, createSiteCompany, createProjectCompany), (state, action) => {
        const entityIndex = getCompanyReferenceType(action.type);
        state[entityIndex].errorMessage = null;
        state[entityIndex].loading = false;
      })
      .addMatcher(
        isPending(
          getLocationCompanies,
          getSiteCompanies,
          getProjectCompanies,
          createLocationCompany,
          createSiteCompany,
          createProjectCompany
        ),
        (state, action) => {
          const entityIndex = getCompanyReferenceType(action.type);
          state[entityIndex].errorMessage = null;
          state[entityIndex].loading = true;
        }
      )
      .addMatcher(
        isRejected(
          getLocationCompanies,
          getSiteCompanies,
          getProjectCompanies,
          createLocationCompany,
          createSiteCompany,
          createProjectCompany
        ),
        (state, action) => {
          const entityIndex = getCompanyReferenceType(action.type);
          state[entityIndex].errorMessage = action?.error?.message || null;
          state[entityIndex].loading = false;
        }
      );
  },
});

export const { reset } = genericCompanySlice.actions;

// Helpers
const getCompanyReferenceType = (actionString: string) => actionString.split('/')[1] as CompanyReferenceEnumListOptions;

export const selectThunk = (CompanyReference: string) => {
  const mapThunksEnabled = {
    [`LIST-${CompanyReferenceEnum.LOCATION}`]: getLocationCompanies,
    [`LIST-${CompanyReferenceEnum.SITE}`]: getSiteCompanies,
    [`LIST-${CompanyReferenceEnum.PROJECT}`]: getProjectCompanies,
    [`FULL-LIST-${CompanyReferenceEnum.LOCATION}`]: getLocationCompaniesFullList,
    [`FULL-LIST-${CompanyReferenceEnum.SITE}`]: getSiteCompaniesFullList,
    [`FULL-LIST-${CompanyReferenceEnum.PROJECT}`]: getProjectCompaniesFullList,
    [`CREATE-${CompanyReferenceEnum.LOCATION}`]: createLocationCompany,
    [`CREATE-${CompanyReferenceEnum.SITE}`]: createSiteCompany,
    [`CREATE-${CompanyReferenceEnum.PROJECT}`]: createProjectCompany,
  };
  return mapThunksEnabled[CompanyReference as keyof typeof mapThunksEnabled] as any;
};

// Reducer
export default genericCompanySlice.reducer;
