
import { IChangeOrderReason } from '@models/change-order-reason.model';
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { changeOrderReasonsService } from '@services/change-order-reasons.service';
import { serializeAxiosError } from "../reducer.util";
import { cleanEntity } from '@shared/util/entity-utils';

export const getEnabledEntities = createAsyncThunk(
    'changeOrderReasons/fetch_enabled_entity_list',
    async () => {
        return changeOrderReasonsService.getEnabledChangeOrderReasons();
    },
    { serializeError: serializeAxiosError }
);

export const getEntities = createAsyncThunk(
    'changeOrderReasons/fetch_entity_list',
    async () => {
        return changeOrderReasonsService.getChangeOrderReasons();
    },
    { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
    `changeOrderReasons/create_entity`,
    async (entity: IChangeOrderReason, thunkAPI) => {
      const result = await changeOrderReasonsService.create(cleanEntity(entity));
      thunkAPI.dispatch(getEntities());
      return result;
    },
    { serializeError: serializeAxiosError }
  );
  
  export const updateEntity = createAsyncThunk(
    `changeOrderReasons/update_entity`,
    async (entity: IChangeOrderReason, thunkAPI) => {
      const result = entity.id && await changeOrderReasonsService.update(entity.id, cleanEntity(entity));
      thunkAPI.dispatch(getEntities());
      return result;
    },
    { serializeError: serializeAxiosError }
  );

interface IChangeOrdersReasonsState {
    loading: boolean;
    errorMessage: string | null;
    entities: ReadonlyArray<IChangeOrderReason>;
    allEntities: ReadonlyArray<IChangeOrderReason>;
}

const initialState: IChangeOrdersReasonsState = {
    loading: false,
    errorMessage: null,
    entities: [],
    allEntities: []
};

export const ChangeOrderReasonsSlice = createSlice({
    name: 'changeOrderReasons',
    initialState,
    reducers: {
        reset: (state,) => {
            return initialState;
        },
    },
    extraReducers(builder) {
        builder
            .addMatcher(isFulfilled(getEnabledEntities), (state, action) => {
                const { data } = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data,
                };
            })
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const { data } = action.payload;
                return {
                    ...state,
                    loading: false,
                    allEntities: data,
                };
            })
            .addMatcher(isPending(getEnabledEntities), state => {
                state.errorMessage = null;
                state.loading = true;
            })
            .addMatcher(isPending(getEntities), state => {
                state.errorMessage = null;
                state.loading = true;
            })
            .addMatcher(isFulfilled(createEntity, updateEntity), (state) => {
                state.loading = false;
            })
            .addMatcher(isPending(createEntity, updateEntity), state => {
                state.errorMessage = null;
            });
    }
});

export const { reset } = ChangeOrderReasonsSlice.actions;

// Reducer
export default ChangeOrderReasonsSlice.reducer;

