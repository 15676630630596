import { useLayoutEffect } from "react";
import { useResizeDetector } from 'react-resize-detector';
import { useLayoutContex } from "@providers/LayoutProvider";

export const usePageContentSize = () => {
  
    const { setLayoutSizeState } = useLayoutContex();
    const { width: layoutWidth, height: layoutHeight, ref: layoutRef } = useResizeDetector({
      refreshMode: 'debounce',
      refreshRate: 50,
    });

    useLayoutEffect(() => {
      setLayoutSizeState && setLayoutSizeState({ layoutWidth, layoutHeight })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutWidth, layoutHeight]);

    return [layoutRef];
};
