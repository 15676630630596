/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Tabs, TabsProps, theme } from 'antd';
import { ProjectOverviewTab } from '@components/Locations/Sites/Projects/ProjectOverviewTab';
import { useFeatureFlagsProvider } from '@providers/FeatureFlagsProvider';
import { useLayoutContex } from '@providers/LayoutProvider';
import { getEntity } from '@store/slices/projects';
import { AppDispatch, useAppSelector } from '@store/store';
import { FeatureFlagOptionEnum } from '@models/enumerations/feature-flag-option.enum';
import { ProjectDeliverablesTab } from './ProjectDeliverableTab/ProjectDeliverablesTab';
import { useBaseProjectTabUrl } from './RouteBaseProjectTabs';
import { ScheduleTab } from './ScheduleTab/ScheduleTab';
import { ProjectPhotoAlbumTab } from './PhotoAlbum/ProjectPhotoAlbumTab';
import ProjectDeliverableSettings from './SettingsTab/Settings';
import ProjectTags from './ProjectTags';
import { OxBlueTab } from './OxBlueTab/OxBlueTab';
import { useTranslation } from 'react-i18next';

export enum ProjectsTabsMap {
  OVERVIEW = 'overview',
  DELIVERABLE = 'deliverables',
  SCHEDULE = 'schedule',
  PHOTO_ALBUM = 'photos',
  SETTINGS = 'settings',
  RFI = 'rfi',
  CHANGE_ORDERS = 'change-orders',
  PUNCHLIST = 'punch-list',
  OX_BLUE = 'ox-blue',
  BUDGET = 'budget',
}

export interface IProjectTabsProps {
  tab: ProjectsTabsMap;
}

export interface IProjectTagsProps {
  tagLabel: string;
  tagValue: string;
}

export const ProjectTabs = (props: IProjectTabsProps) => {
  const { tab } = props;

  const {
    token: { colorBgLayout, colorBgContainer, marginLG },
  } = theme.useToken();

  const { setNewStylePropertiesInContentLayout, setHeaderLabelEntityState } = useLayoutContex();

  const dispatch = useDispatch<AppDispatch>();

  const { entity: projectEntity } = useAppSelector(state => state.Project);

  const { projectId } = useParams<'projectId'>();

  const projectTags: IProjectTagsProps[] = [];

  const { token } = theme.useToken();

  const { t } = useTranslation();

  if (projectEntity?.projectType?.name)
    projectTags.push({ tagLabel: `${t('project.projectType')}`, tagValue: projectEntity?.projectType?.name });

  if (projectEntity?.projectStatus?.name)
    projectTags.push({ tagLabel: `${t('project.projectStatus')}`, tagValue: projectEntity?.projectStatus?.name });

  const [defaultActiveKey, setDefaultActiveKey] = useState<`${ProjectsTabsMap}` | undefined>(ProjectsTabsMap.OVERVIEW);

  const { isFlagEnabled } = useFeatureFlagsProvider();
  const getItems = (): TabsProps['items'] => {
    const items: TabsProps['items'] = [];

    items.push({
      key: ProjectsTabsMap.OVERVIEW,
      label: `${t('project.navigation.overview')}`,
      disabled: false,
      children: defaultActiveKey === ProjectsTabsMap.OVERVIEW ? <ProjectOverviewTab /> : null,
    });
    items.push({
      key: ProjectsTabsMap.DELIVERABLE,
      label: `${t('project.navigation.deliverables')}`,
      // We set null when the tab is inactive to force the component to be recreated when the tab is activated, we do this
      // beacuse we want the attachments and comments to be refetched
      children: defaultActiveKey === ProjectsTabsMap.DELIVERABLE ? <ProjectDeliverablesTab /> : null,
      disabled: false,
    });
    items.push({
      key: ProjectsTabsMap.SCHEDULE,
      label: `${t('project.navigation.schedule')}`,
      children: defaultActiveKey === ProjectsTabsMap.SCHEDULE ? <ScheduleTab /> : null,
      disabled: false,
    });
    items.push({
      key: ProjectsTabsMap.PHOTO_ALBUM,
      label: `${t('project.navigation.photos')}`,
      children: defaultActiveKey === ProjectsTabsMap.PHOTO_ALBUM ? <ProjectPhotoAlbumTab /> : null,
      disabled: false,
    });
    if (isFlagEnabled(FeatureFlagOptionEnum.BUDGET) === true) {
      items.push({
        key: ProjectsTabsMap.BUDGET,
        label: `${t('project.navigation.budget')}`,
        children: <Outlet />,
        disabled: false,
      });
    }
    if (isFlagEnabled(FeatureFlagOptionEnum.RFI) === true) {
      items.push({
        key: ProjectsTabsMap.RFI,
        label: `${t('project.navigation.rfi')}`,
        children: <Outlet />,
        disabled: false,
      });
    }
    if (isFlagEnabled(FeatureFlagOptionEnum.CHANGE_ORDERS) === true) {
      items.push({
        key: ProjectsTabsMap.CHANGE_ORDERS,
        label: `${t('project.navigation.changeOrders')}`,
        children: <Outlet />,
        disabled: false,
      });
    }
    if (isFlagEnabled(FeatureFlagOptionEnum.PUNCHLIST) === true) {
      items.push({
        key: ProjectsTabsMap.PUNCHLIST,
        label: `${t('project.navigation.punchList')}`,
        children: <Outlet />,
        disabled: false,
      });
    }
    if (isFlagEnabled(FeatureFlagOptionEnum.OX_BLUE_INTEGRATION) === true && Boolean(projectEntity.oxblueProjectId)) {
      items.push({
        key: ProjectsTabsMap.OX_BLUE,
        label: `${t('project.navigation.oxBlue')}`,
        children: <OxBlueTab />,
        disabled: false,
      });
    }
    items.push({
      key: ProjectsTabsMap.SETTINGS,
      label: `${t('project.navigation.settings')}`,
      children: defaultActiveKey === ProjectsTabsMap.SETTINGS ? <ProjectDeliverableSettings /> : null,
      disabled: false,
    });
    return items;
  };

  const { entity: locationEntity } = useAppSelector(state => state.Location);

  useEffect(() => {
    if (projectId && projectEntity && String(projectEntity.id) !== projectId) {
      dispatch(getEntity(projectId));
    }
  }, [dispatch, projectId, projectEntity]);

  useEffect(() => {
    const headerLabel =
      locationEntity && locationEntity.name
        ? projectEntity && projectEntity.nickname
          ? `${locationEntity.name} - ${projectEntity.nickname} (#${projectEntity.id || ''})`
          : `${locationEntity.name} - ${t('generic.project')} #${projectEntity.id || ''}`
        : projectEntity && projectEntity.nickname
        ? `${projectEntity.nickname}`
        : `${t('generic.project')} #(${projectEntity?.id || ''})`;

    setHeaderLabelEntityState?.(headerLabel);

    const headerTags = document.getElementById('header-tags');
    const tagsContainer = document.createElement('div');

    const root = createRoot(tagsContainer!);
    root.render(<ProjectTags projectTags={projectTags} />);

    headerTags?.appendChild(tagsContainer);

    return () => {
      setHeaderLabelEntityState?.('');
      headerTags?.removeChild(tagsContainer);
    };
  }, [defaultActiveKey, projectEntity, locationEntity, token]);

  useEffect(() => {
    setNewStylePropertiesInContentLayout && setNewStylePropertiesInContentLayout();
  }, []);

  useEffect(() => {
    if (tab && Object.values<string>(ProjectsTabsMap).includes(tab)) {
      setDefaultActiveKey(tab as `${ProjectsTabsMap}`);
    }
    return () => {
      setDefaultActiveKey(undefined);
    };
  }, [tab]);

  const navigate = useNavigate();

  const { validBaseUrl } = useBaseProjectTabUrl();

  const onTabClick = (activeKey: string, e: React.KeyboardEvent | React.MouseEvent) => {
    if (e?.metaKey) {
      window.open(`${validBaseUrl}/${activeKey}`);
    } else {
      navigate(`${validBaseUrl}/${activeKey}`, { relative: 'route', replace: true });
      setDefaultActiveKey(activeKey as `${ProjectsTabsMap}`);
    }
  };

  return (
    <>
      <Tabs
        id="projectsTabBarMain"
        defaultActiveKey={defaultActiveKey}
        activeKey={defaultActiveKey}
        items={getItems()}
        className="custom-tabs imb-0 overflow-hidden"
        style={{ backgroundColor: colorBgLayout }}
        onTabClick={onTabClick}
        destroyInactiveTabPane={true}
        tabBarStyle={{
          backgroundColor: colorBgContainer,
          paddingLeft: `${marginLG}px`,
          marginBottom: 0,
        }}
      />
    </>
  );
};
