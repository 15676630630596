

import { ICompanyContact } from '../../models/company.contact.model';
import { IQueryParams } from '../../models/pagination';
import { http } from '../http/axios.instance';
import { StringORNumber } from './utils.repository';

const path = 'company-contacts';

export const companyContactRepository = {
  getAll: async (queryParams: IQueryParams) => {
    
    const { sort, page, size } = queryParams;
    const requestUrl = `${path}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  
    const promiseGetAll = await http.get<ICompanyContact[]>(requestUrl);
    return promiseGetAll;
  },
  getById: async (id: StringORNumber) => {

    const requestUrl = `${path}/${id}`;
    const promiseGetByID = await http.get<ICompanyContact>(requestUrl);
    return promiseGetByID;
  },
  getByCompanyId: async (id: StringORNumber) => {
    const requestUrl = `${path}/?companyId.equals=${id}`;
    const promiseGetByID = await http.get<ICompanyContact>(requestUrl);
    return promiseGetByID;
  },
  create: async (entity: ICompanyContact): Promise<ICompanyContact> => {

    const requestUrl = `${path}`;
    const promiseCreate = await http.post<ICompanyContact>(requestUrl, entity);
    return promiseCreate.data;
  },
  update: async (id: string | number, entity: ICompanyContact): Promise<ICompanyContact> => {

    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.put<ICompanyContact>(requestUrl, entity);
    return promiseUpdate.data;
  },
  updatePartial: async (id: string | number, entity: ICompanyContact): Promise<ICompanyContact> => {
    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.patch<ICompanyContact>(requestUrl, entity);
    return promiseUpdate.data;
  },
  delete: async (id: string | number) => {

    const requestUrl = `${path}/${id}`;
    const promiseDelete = await http.delete<ICompanyContact>(requestUrl);
    return promiseDelete;
  },
}