import { useToken } from "antd/es/theme/internal";
import React, { useRef } from "react";
import useHover from "../../HOOKs/UseHover";
import { componentProps } from "./Utils";

interface IProps extends componentProps {
    width?: string,
    height?: string
    iconStyles?: React.CSSProperties
}

export const MapMarkerSvgIcon: React.FC<IProps> = ({ className = "", width, height, iconStyles }) => {
    const arrowhoverRef = useRef(null);
    const isHoverInArrow = useHover(arrowhoverRef);
    const [, globalToken] = useToken();

    const defaultStyle: React.CSSProperties = {
        color: isHoverInArrow
        ? globalToken.colorPrimaryTextHover
        : globalToken.colorTextBase,
    }

    return (
        <svg 
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="1436"
            className={`animation-grow-in-hover ${className}`}
            width={width ? width : "1rem"}
            height={height ? height: "1rem"}
            ref={arrowhoverRef}
            style={{ ...defaultStyle, ...iconStyles }}>
                <path fill="currentColor" d="M725.333333 85.333333c-117.184 0-213.333333 94.506667-213.333333 210.752 0 52.202667 19.498667 100.202667 51.328 136.917334l0.170667 0.170666 0.128 0.170667s83.349333 94.08 115.626666 124.416a67.690667 67.690667 0 0 0 92.16 0c36.138667-33.984 115.712-124.501333 115.712-124.501333l0.128-0.128 0.085334-0.128A208.832 208.832 0 0 0 938.666667 296.085333C938.666667 179.818667 842.517333 85.333333 725.333333 85.333333z m0 64c83.093333 0 149.333333 65.578667 149.333334 146.752a143.978667 143.978667 0 0 1-35.626667 94.933334s0 0.042667-0.042667 0.064c-0.32 0.362667-83.306667 93.610667-111.424 120.042666-1.450667 1.365333-2.986667 1.365333-4.458666 0h-0.021334v-0.042666c-22.912-21.525333-111.146667-119.808-111.466666-120.170667A143.957333 143.957333 0 0 1 576 296.106667C576 214.912 642.24 149.333333 725.333333 149.333333z m-331.157333 20.885334a32 32 0 0 0-21.802667 9.066666L141.162667 347.456A31.978667 31.978667 0 0 0 128 373.333333v448a32 32 0 0 0 50.837333 25.877334L394.666667 690.24l215.829333 156.970667a32 32 0 0 0 37.674667 0l234.666666-170.666667c8.277333-6.016 13.162667-15.637333 13.162667-25.877333v-163.242667c-17.792 19.818667-42.410667 46.890667-64 69.653333v77.290667l-170.666667 124.138667v-158.293334a3813.845333 3813.845333 0 0 1-64-66.218666v224.512l-170.666666-124.138667v-368.853333l42.709333 31.018666L469.333333 296.106667c0-24.682667 3.733333-48.490667 10.410667-71.082667l-62.442667-45.418667a32 32 0 0 0-0.170666-0.170666 32 32 0 0 0-22.954667-9.194667zM725.333333 245.333333a53.333333 53.333333 0 0 0 0 106.666667 53.333333 53.333333 0 0 0 0-106.666667z m-362.666666 20.16v368.874667l-170.666667 124.138667V389.632l170.666667-124.138667zM661.333333 576c-0.341333-0.234667-0.256 0.128 0 0.789333V576z" p-id="1437">
                </path>
        </svg>
    );
};
