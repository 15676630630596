import { IQueryParamsGeneric } from '@models/utils';
import { IPhotoAlbum } from '@models/photo-album.model';
import { http } from '@infrastructure/http/axios.instance';
import { StringORNumber } from '../utils.repository';

export const path = 'photo-albums';

export const photoAlbumRepository = {
  getPhotoAlbums: async (queryParams: IQueryParamsGeneric) => {
    const { page = 0, size = 5, referenceId, referenceType } = queryParams;
    const requestUrl = `${path}?referenceId.equals=${referenceId}&referenceType.equals=${referenceType}&page=${page}&size=${size}`;
    const promiseGetAll = await http.get<IPhotoAlbum[]>(requestUrl);
    return promiseGetAll;
  },
  getPhotoAlbumById: async (id: StringORNumber) => {
    const requestUrl = `${path}/${id}`;
    const promiseGetByID = await http.get<IPhotoAlbum>(requestUrl);
    return promiseGetByID.data;
  },
  createPhotoAlbum: async (entity: Omit<IPhotoAlbum, "id">): Promise<IPhotoAlbum> => {
    const requestUrl = `${path}`;
    const promiseCreate = await http.post<IPhotoAlbum>(requestUrl, entity);
    return promiseCreate.data;
  },
  updatePhotoAlbum: async (id: string | number, entity: IPhotoAlbum): Promise<IPhotoAlbum> => {
    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.put<IPhotoAlbum>(requestUrl, entity);
    return promiseUpdate.data;
  },
  deletePhotoAlbum: async (id: string | number) => {
    const requestUrl = `${path}/${id}`;
    const promiseDelete = await http.delete<IPhotoAlbum>(requestUrl);
    return promiseDelete;
  },
}