import { Button, ConfigProvider, theme } from "antd";
import React from "react";

interface ICustomButtonTextProps extends React.ComponentPropsWithoutRef<typeof Button> {};

export const CustomButtonText = ({ children, ...restProps }: ICustomButtonTextProps) => {
    const { token: { colorPrimary, colorFillSecondary, colorFillQuaternary } } = theme.useToken();
    return (
        <ConfigProvider 
            theme={{ 
                token: { colorText: colorPrimary },
                components: {
                    Button: {
                      borderRadius: 4
                    }
                }
            }} 
        >
            <Button 
                type="text" 
                className="custom-btn-text w-full w-min" 
                style={{
                    background: colorFillQuaternary,
                    border: `1px solid ${colorFillSecondary}`,
                }}
                {...restProps} 
            >
                {children}
            </Button>
        </ConfigProvider>
    )
}