/* eslint-disable react-hooks/exhaustive-deps */
import ReactDOMServer from 'react-dom/server';
import { WrapperGoogleMap } from '@components/Google/Map/WrapperGoogleMap';
import { theme } from 'antd';
import { useResizeDetector } from 'react-resize-detector';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { IOptionMap } from './AddressGenericUpdate';

interface IMapBoxProps {
    optionMap: IOptionMap
}

const options = {
    fields: ["address_components", "formatted_address", "geometry","name"],
    types: ["address"]
};

const div = document.createElement("div")!;

export const AddressMap: React.FC<IMapBoxProps> = (props) => {

    const { optionMap } = props;

    const [markerOptions, setMarkerOptions] = useState<google.maps.marker.AdvancedMarkerElementOptions[] | []>([]);

    useEffect(() => {  
        setMarkerOptions(optionMap.markerOptions);
        return () => { setMarkerOptions([]); }
    }, [optionMap]);
 
    // Ref of the infoWindow Loaded
    const infoWindowLoaded = useRef<google.maps.InfoWindow | null>(null);

    const [serviceGeocoder, setServiceGeocoder] = useState<google.maps.Geocoder | null>(null);

    useEffect(() => {
       const geocoder =  new google.maps.Geocoder()
       setServiceGeocoder(geocoder);
    }, [])

    const InfoPlacePopover = (place: google.maps.places.PlaceResult) => {
        return (<div id="infowindow-content" style={{ color: "#000" }}>
            <span id="place-name" className="font-bold">{place.name}</span><br />
            <span id="place-address">{place?.formatted_address}</span>
        </div>)
    }

    const setInfoWindow = (map: google.maps.Map, marker: google.maps.marker.AdvancedMarkerElement, place: google.maps.places.PlaceResult) => {

    
        if (!infoWindowLoaded.current) {
            const infowindow = new google.maps.InfoWindow({
                content: ReactDOMServer.renderToString(InfoPlacePopover(place))
            });
            infoWindowLoaded.current = infowindow;
        }

        infoWindowLoaded.current.setContent(ReactDOMServer.renderToString(InfoPlacePopover(place)));
        infoWindowLoaded.current.open(map, marker as google.maps.marker.AdvancedMarkerElement);
        infoWindowLoaded.current.setPosition(marker.position as google.maps.LatLng);

        // infoWindowLoaded.current.addListener("closeclick", () => {
        //     marker.setClickable(true);
        // });

        marker.addListener("click", () => { 
            const infowindow = infoWindowLoaded.current;
            if (infowindow) {
                infowindow.open(map, marker as google.maps.marker.AdvancedMarkerElement);
                infowindow.setPosition(marker.position as google.maps.LatLng);
            }
        });
        
    }

    const handlerMarkerIsVisible = (map: google.maps.Map, marker: google.maps.marker.AdvancedMarkerElement) => {

        if (optionMap.placeResult) {
            if (map) {
                setInfoWindow(map, marker, optionMap.placeResult)
                return;
            }
        }

        if (!optionMap.placeResult && serviceGeocoder) {

            serviceGeocoder.geocode({ location: marker.position })
                .then((response) => {
                    if (response.results[0]) {

                        const request = {
                            placeId: response.results[0].place_id,
                            fields: options.fields
                        };

                        const servicePlaces = new google.maps.places.PlacesService(div);

                        servicePlaces.getDetails(request, (place, status) => {
                            if (status === google.maps.places.PlacesServiceStatus.OK && place) {
                                
                                setInfoWindow(map, marker, place)
                            }
                        })
                    }
                })
                .catch((e) => console.error("Geocoder failed due to: " + e));
        }

    }

    const WrapperGoogleMapComponentMemoized = useMemo(() => { 
        return <WrapperGoogleMap
            markers={[...markerOptions]}
            handlerMarkerIsVisible={handlerMarkerIsVisible}
        />
    }, [markerOptions]);

    const [height, setHeight] = useState('');

    const { width: widthContainerMap = 0,  ref: layoutRef } = useResizeDetector({
        refreshMode: 'debounce',
        refreshRate: 50,
    });

    useLayoutEffect(() => {

        const maxWidthContainerMap = widthContainerMap || 0; 
        const selectedAspectRation = { width: 5, height: 3};
        const height = `${( maxWidthContainerMap * selectedAspectRation.height ) / selectedAspectRation.width }px`;
        setHeight(height);

    }, [widthContainerMap])

    const { token } = theme.useToken();

    return (
            <div ref={layoutRef} className="w-full" style={{ border: `1px solid ${token.colorBorder}`, height }}>
                <div style={{ height }}>
                    {WrapperGoogleMapComponentMemoized}
                </div>
            </div>
        )
}