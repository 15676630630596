import { NotificationSettings } from "@providers/NotificationsProvider";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface INotificationState {
  readyToLaunch: boolean,
  settings: NotificationSettings
}

const initialState: INotificationState = {
  readyToLaunch: false,
  settings: {
      type: 'info',
      config: {
        message: 'notification'
      }
  }
};

export const asyncLaunchNotification = createAsyncThunk(
  'notification/asyncLaunchNotification',
  async (settings: NotificationSettings, thunkApi) => {
    return settings
  }
)

export const NotificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
        .addCase(asyncLaunchNotification.pending, (state, action) => {
            if (action?.meta?.arg) {
              state.settings.type = action?.meta?.arg?.type;
              state.settings.config = {
                ...state.settings.config,
                ...action?.meta?.arg?.config
              }
            }
            state.readyToLaunch = true
        })
        .addCase(asyncLaunchNotification.fulfilled, (state, action) => {
          state.readyToLaunch = false
        })
    }     
});
  
// Reducer
export default NotificationSlice.reducer;