import { BackButton } from "@components/Utils/BackButton";
import { Col, Row, theme } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

export interface IBackHeaderProps {
    label?: string;
}

export const BackHeader = (props: IBackHeaderProps) => {
    const { label } = props;
    const { token: { padding, colorBgContainer } } = theme.useToken();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const navigateToList = () => {
        const newPath = pathname.substring(0, pathname.lastIndexOf('/'));
        navigate(newPath);
    }

    return (
        <Row style={{ paddingLeft: `${padding}px`, paddingRight: `${padding}px`, backgroundColor: colorBgContainer }} className="pt-10 pb-10 w-full">
            <Col span={24}>
                <BackButton onClick={navigateToList} >{label ?? 'Change Orders Log'}</BackButton>
            </Col>
        </Row>
    );
}