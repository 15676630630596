import { useEffect, useState } from 'react';
import { Badge, Button, Divider, Pagination, Table, theme } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { PlusOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import CompanyDisplayOptions from './CompanyDisplayOptions';
import CompanyCreate from './CreateCompany';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@store/store';
import { ICompany } from '@models/company.model';
import { t } from 'i18next';
import { IAddress } from '@models/address.model';
import { EmptyCellTable } from '@components/Utils/EmptyCellTable';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { getEntities } from '@store/slices/company-extended';
import { PAGINATION_SIZE_COMPANY_TABLE } from '@constants/core';
import { SorterResult } from 'antd/es/table/interface';
import { isNumber } from '@shared/util/number-util';
import { useLayoutContex } from '@providers/LayoutProvider';
import { ChevronRightSvgIcon } from '@components/Icons/ChevronRightSvgIcon';
import { SettingsIcon } from '@components/Icons/SettingsIcon';
import { IHandleOnSearchProps, SearchTable } from '@components/Utils/SearchTable';
import { useCompaniesContainerContext } from './Companies';
import { DEFAULT_PAGINATION, PAGINATION_SIZE_OPTIONS } from '@components/Locations/LocationsVisualization/ToolbarComponents';

const { Text } = Typography;

const buildAddressWithCityState = (address: IAddress) => {
  if (address && address.city && address.stateProvince)
    return (
      <>
        {address.city} {address.stateProvince}
      </>
    );
  return <EmptyCellTable />;
};

export const buildCompleteAddressToRender = (address: IAddress) => {
  if (!address) {
    return <EmptyCellTable />;
  }
  let addressLabel = '';
  if (address.streetAddress) addressLabel += ` ${address.streetAddress} `;
  if (address.streetAddress2) addressLabel += ` ${address.streetAddress2} `;
  if (address.city) addressLabel += ` ${address.city} `;
  if (address.stateProvince) addressLabel += ` ${address.stateProvince} `;
  if (address.country) addressLabel += ` ${address.country} `;
  if (address.postalCode) addressLabel += ` ${address.postalCode} `;
  return <>{addressLabel}</>;
};

const initialColumnsState: { [key: string]: boolean } = {
  name: true,
  contactCount: true,
  cityState: true,
  address: true,
  button: true,
};

export const CompaniesTable = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { setHeaderLabelEntityState } = useLayoutContex();
  const { heightContainer } = useCompaniesContainerContext();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState(PAGINATION_SIZE_COMPANY_TABLE);
  const [sortingKey, setSortingKey] = useState<string>('name');
  const [sortingOrder, setSortingOrder] = useState<string>('asc');
  const [nameFilter, setNameFilter] = useState('');

  const getTableData = async () => {
    dispatch(
      getEntities({
        page: pageNumber - 1,
        size: itemsPerPage,
        sort: `${sortingKey},${sortingOrder}`,
        nameFilter: nameFilter,
      })
    );
  };

  useEffect(() => {
    getTableData();
    setHeaderLabelEntityState(t('generic.companies'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, itemsPerPage, sortingKey, nameFilter, sortingOrder]);

  const navigate = useNavigate();

  const { entities: companyList, totalItems, loading } = useAppSelector(state => state.Company);

  const [openDisplayOptions, setOpenDisplayOptions] = useState(false);
  const [columnsState, setColumnsState] = useState(initialColumnsState);

  const {
    token: { colorPrimary, colorBorderSecondary, colorFillSecondary, colorTextSecondary, colorBgElevated },
  } = theme.useToken();

  const showDrawer = () => {
    setOpenDisplayOptions(true);
  };

  const onClose = () => {
    setOpenDisplayOptions(false);
  };

  const navigateToCompanyDetails = record => {
    const urlToNavigate = `/companies/${record.id}`;
    navigate(`${urlToNavigate}`);
  };

  const ViewMoreCell = () => {
    const {
      token: { colorPrimaryText },
    } = theme.useToken();
    return (
      <div className="flex flex-row items-center select-none">
        <span className="ml-10" style={{ color: colorPrimaryText }}>
          View
        </span>
        <ChevronRightSvgIcon className="ml-10" style={{ color: colorPrimaryText }} />
      </div>
    );
  };

  const columns: ColumnsType<ICompany> = [
    {
      title: '',
      dataIndex: 'button',
      key: 'button',
      width: '10%',
      render: (text, record: ICompany) => (
        <div onClick={() => navigateToCompanyDetails(record)} className="cursor-pointer">
          <ViewMoreCell />
        </div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
      render: value => {
        return value || <EmptyCellTable />;
      },
      defaultSortOrder: 'ascend',
      sorter: true,
    },
    {
      title: 'Contacts',
      dataIndex: 'contactCount',
      key: 'contactCount',
      width: 100,
      render: value => contactsCountBadge(value),
    },
    {
      title: 'City, State',
      key: 'cityState',
      render: (address: IAddress) => buildAddressWithCityState(address),
      dataIndex: 'address',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: (address: IAddress) => buildCompleteAddressToRender(address),
    },
  ];

  const contactsCountBadge = (value: number) => {
    if (!isNumber(value)) value = 0;
    return (
      <Badge
        count={value || 0}
        size="small"
        showZero
        style={{
          backgroundColor: colorFillSecondary,
          color: colorTextSecondary,
          outline: 'none',
          width: '30px',
        }}
      />
    );
  };

  const [visibleColumns, setVisibleColumns] = useState(columns.filter(column => column?.key && columnsState[String(column.key)]));

  useEffect(() => {
    prepareDataToRender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnsState]);

  const prepareDataToRender = () => {
    const filteredVisibleData = columns.filter(column => column?.key && columnsState[String(column.key)]);
    setVisibleColumns(filteredVisibleData);
  };

  const [openAddCompanyDialog, setOpenAddCompanyDialog] = useState(false);

  const handleTableChange: TableProps<ICompany>['onChange'] = (pagination, filter, sorter) => {
    const _sorter = sorter as SorterResult<ICompany>;
    setSortingKey(String(_sorter?.field));
    setSortingOrder(_sorter?.order === 'descend' ? 'desc' : _sorter?.order === 'ascend' ? 'asc' : sortingOrder);
    setPageNumber(pagination.current !== undefined ? pagination.current - 1 : 0);
  };

  const handleOnSearch = (props?: IHandleOnSearchProps) => {
    const { valueToSearch = ' ' } = props as IHandleOnSearchProps;
    setNameFilter(valueToSearch);
  };

  const handleOnChange = (pageNumber: number, pageSize?: number) => {
    setItemsPerPage(pageSize || itemsPerPage);
    setPageNumber(pageNumber);
  };

  return (
    <div>
      <div
        style={{
          border: `1px solid ${colorBorderSecondary}`,
          margin: '10px 0',
          borderRadius: '5px',
          padding: '0.5rem 0.5rem 0.5rem 1rem',
          backgroundColor: colorBgElevated,
        }}
        className="flex justify-between"
      >
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <Text>{t('generic.company')}</Text>
          <Badge
            count={totalItems}
            size="small"
            className="ml-30 mr-30"
            style={{ backgroundColor: colorPrimary, outline: 'none', color: 'primary' }}
          />
          <Divider type="vertical" className="ml-20" style={{ height: '24px' }} plain />
          <Button onClick={showDrawer} className="ml-30" type="primary" ghost style={{ border: 'none' }} color={colorPrimary}>
            Display
            <SettingsIcon className="ml-5" color={colorPrimary} />
          </Button>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <SearchTable className="ml-20" handleOnSearch={handleOnSearch} placeholder="Quick Filter" onClear={() => setNameFilter('')} />
          <Pagination
            simple
            current={pageNumber}
            total={totalItems}
            pageSize={itemsPerPage}
            className="mr-20"
            onChange={handleOnChange}
            onShowSizeChange={handleOnChange}
            pageSizeOptions={[...PAGINATION_SIZE_OPTIONS, totalItems ? totalItems : 1]}
            showSizeChanger={totalItems ? totalItems > DEFAULT_PAGINATION : false}
          />
          <Button
            onClick={() => setOpenAddCompanyDialog(true)}
            style={{ backgroundColor: colorPrimary, color: 'white' }}
            icon={<PlusOutlined />}
          >
            New {t('generic.company')}
          </Button>
          <CompanyCreate
            pageNumber={pageNumber}
            itemsPerPage={itemsPerPage}
            sortingKey={sortingKey}
            open={openAddCompanyDialog}
            setOpen={setOpenAddCompanyDialog}
            setCompaniesData={companyList}
          />
        </div>
      </div>
      <Table
        style={{ border: `1px solid ${colorBorderSecondary}` }}
        size="small"
        rowKey="id"
        columns={visibleColumns}
        dataSource={companyList}
        onChange={handleTableChange}
        pagination={false}
        loading={loading}
        scroll={{ y: heightContainer - 150 }} // Subtracting the height of the header to make table scroll correct
      />
      <CompanyDisplayOptions
        columnsState={columnsState}
        setItemsPerPage={setItemsPerPage}
        setColumnsState={setColumnsState}
        openDisplayOptions={openDisplayOptions}
        onClose={onClose}
      />
    </div>
  );
};

export default CompaniesTable;
