import { Col, Row, theme, Card, Divider } from 'antd';
import { useEffect, useState } from 'react';
import { getAddressesList } from '@store/slices/generic-address';
import { useAppDispatch, useAppSelector } from '@store/store';
import { IAddress, IGetAddressRequest } from '@models/address.model';
import { AddressGenericManageList } from './AddressGenericManageList';
import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { AddressReferenceEnum, AddressReferenceEnumListOptions } from '@models/enumerations/address-reference-enum.model';
import { AddresGenericCreate } from './AddressGenericCreate';
import { If } from '@components/Utils/Structural';
import { isArrayWithValues } from '@shared/util/array-util';
import { CustomButtonText } from '@components/Utils/CustomButtonText';
import { PRIMARY_TYPE } from '@models/address-type.model';
import useVisible from '@HOOKs/UseVisible';
import { useTranslation } from 'react-i18next';
import { PhoneOutlined, MailOutlined } from '@ant-design/icons';

export interface IAddressGenericUpdateProps {
  referenceId?: StringORNumber;
  referenceType: AddressReferenceEnumListOptions;
}

export const AddressGenericCardHandler = (props: IAddressGenericUpdateProps) => {
  const { referenceId, referenceType } = props;
  const dispatch = useAppDispatch();
  const addressesList = useAppSelector(state => state.GenericAddress[referenceType]);
  const [addressToShow, setAddressToShow] = useState<IAddress | undefined>();

  useEffect(() => {
    if (referenceId) {
      const payload: IGetAddressRequest = {
        referenceId: referenceId,
        referenceType: AddressReferenceEnum[referenceType],
      };
      dispatch(getAddressesList(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceId]);

  useEffect(() => {
    if (isArrayWithValues(addressesList?.data)) {
      const addressSelected = addressesList?.data?.find(address => address.addressType?.description === PRIMARY_TYPE);
      setAddressToShow(addressSelected ?? addressesList?.data[0]);
    } else {
      setAddressToShow(undefined);
    }
  }, [addressesList]);

  return <Content referenceId={referenceId} referenceType={referenceType} address={addressToShow} />;
};

export interface IContentPros {
  address?: IAddress;
  referenceId?: StringORNumber;
  referenceType: AddressReferenceEnumListOptions;
}
export const Content = (props: IContentPros) => {
  const { address, referenceId, referenceType } = props;
  const hasAddressId: boolean = Boolean(address?.id);

  const {
    token: { colorBorderSecondary, colorFill, colorFillQuaternary, colorFillSecondary, colorTextTertiary },
  } = theme.useToken();
  const { t } = useTranslation();
  const [visibleAddressCreateDrawer, toggleVisibleAddressCreateDrawer] = useVisible();
  const [visibleAddressManageDrawer, toggleVisibleAddressManageDrawer] = useVisible();

  const TitleCard = ({ address }: { address: IAddress | undefined }) => {
    const {
      token: { colorTextSecondary, fontSizeHeading5 },
    } = theme.useToken();
    return (
      <Row justify={'space-between'} align="middle">
        <Col flex="1">
          <span style={{ color: colorTextSecondary, fontSize: fontSizeHeading5 }}>{address?.addressType?.description}</span>
        </Col>
        <Col>
          <CustomButtonText onClick={toggleVisibleAddressManageDrawer}>{t('generic.address.manageAddress')}</CustomButtonText>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <If condition={hasAddressId}>
            <Card
              title={<TitleCard address={address} />}
              styles={{
                header: {
                  paddingLeft: 0,
                  paddingRight: 0,
                  borderColor: colorBorderSecondary,
                },
                body: { padding: 0, paddingTop: 5 },
              }}
              style={{
                borderColor: colorBorderSecondary,
                backgroundColor: colorFillQuaternary,
                borderRadius: 8,
                overflow: 'hidden',
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 5,
                paddingBottom: 5,
              }}
            >
              <div
                className="flex flex-col"
                style={{
                  color: colorTextTertiary,
                }}
              >
                <span>{address?.streetAddress}</span>
                <If condition={!!address?.streetAddress2}>
                  <span>{address?.streetAddress2}</span>
                </If>
                <span>{`${address?.city ? `${address?.city},` : ''} ${address?.stateProvince || ''} ${address?.postalCode || ''}`}</span>
                <span>{address?.country}</span>
                <If
                  condition={
                    (!!address?.phone || !!address?.email) &&
                    (!!address?.streetAddress ||
                      !!address?.streetAddress2 ||
                      !!address?.city ||
                      !!address?.stateProvince ||
                      !!address?.postalCode ||
                      !!address?.country)
                  }
                >
                  <Divider type="horizontal" className="ml-0 mr-0 mt-5 mb-10" style={{ borderColor: colorFillSecondary }} />
                </If>
                <If condition={!!address?.phone}>
                  <span>
                    <PhoneOutlined style={{ fontSize: '15px', color: colorFill, transform: 'scaleX(-1)' }} /> {address?.phone}
                  </span>
                </If>
                <If condition={!!address?.email}>
                  <span>
                    <MailOutlined style={{ fontSize: '15px', color: colorFill }} /> {address?.email}
                  </span>
                </If>
              </div>
            </Card>
          </If>
          <If condition={!hasAddressId}>
            <Card
              styles={{
                header: {
                  paddingLeft: 0,
                  paddingRight: 0,
                  borderColor: colorBorderSecondary,
                },
                body: { padding: 0, paddingTop: 5 },
              }}
              style={{
                borderColor: colorBorderSecondary,
                backgroundColor: colorFillQuaternary,
                borderRadius: 8,
                overflow: 'hidden',
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 50,
                paddingBottom: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CustomButtonText onClick={toggleVisibleAddressCreateDrawer}>{t('generic.address.addAddress')}</CustomButtonText>
            </Card>
          </If>
        </Col>
      </Row>
      <Row>
        <AddresGenericCreate
          open={visibleAddressCreateDrawer}
          toggle={toggleVisibleAddressCreateDrawer}
          referenceId={referenceId}
          referenceType={referenceType}
        />
        <AddressGenericManageList
          open={visibleAddressManageDrawer}
          toggle={toggleVisibleAddressManageDrawer}
          referenceId={referenceId}
          referenceType={referenceType}
        />
      </Row>
    </>
  );
};
