import { configuration } from "../environments/env";
import { createContext, useContext, useEffect, useState } from "react";
import { Loader, Libraries } from "@googlemaps/js-api-loader"

export const LIBRARIES : Libraries = ["geometry","places","visualization","marker"]

interface IGoogleMapsContext {
    isLoaded: boolean,
    isLoading: boolean,
    loadError: unknown
}
export const GoogleMapsContext = createContext({} as IGoogleMapsContext);

const loader = new Loader({
    apiKey: configuration.GOOGLE_MAPS_API_KEY,
    libraries: LIBRARIES
});

const UNITED_STATES_CENTER_POSITION = {
    lat: 39.8282,
    lng: -98.5795   
}

export const DEFAULT_MAP_POSITION = UNITED_STATES_CENTER_POSITION;

export const GoogleMapsProvider = ({ children }: any) => {

    const [firstLoadIsDone, setFirstLoadIsDone] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadError, setLoadError] = useState(null);

    useEffect(() => {
        loadMapLibrary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadMapLibrary = () => {
        if (!firstLoadIsDone) {
            setFirstLoadIsDone(true);
            setIsLoading(true);
            initLoad()
        }
    }

    const initLoad = () => {
        loader.load().then((google) => {
            google && google?.maps?.version && setIsLoaded(true)
        }).catch((error) => {
            setLoadError(error)
            console.error("Error trying to load the Maps library : ", error);
        }).finally(() => setIsLoading(false))
    }

    const value = {
        isLoaded: isLoaded,
        isLoading: isLoading,
        loadError: loadError
    }

    return (
        <GoogleMapsContext.Provider value={value}>{children}</GoogleMapsContext.Provider>
    )
};

export const useGoogleMapsProvider = () => {
    return useContext(GoogleMapsContext);
};