import { useEffect, useState } from 'react';
import { Button, Col, Drawer, Form, Row, Select, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelector } from '@store/store';
import { getEntities as getCompanyRoles } from '@store/slices/company-roles';
import { getEntities as getCompanies } from '@store/slices/company';
import { getEntityByCompany, reset } from '@store/slices/company-contact';
import { CompanyReferenceEnum, CompanyReferenceEnumListOptions } from '@models/enumerations/company-reference-enum.model';
import { ICompanyBindingExtended } from '@models/company-binding.model';
import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { createCompany } from '@store/slices/generic-company';
import { useTranslation } from 'react-i18next';
import { IQueryParams } from '@models/pagination';

const { Option } = Select;
export interface ICompanyGenericCreateProps extends IQueryParams {
  open: boolean;
  toggle: () => void;
  referenceId?: StringORNumber;
  referenceType: CompanyReferenceEnumListOptions;
}

export const CompanyGenericCreate = (props: ICompanyGenericCreateProps) => {
  const { open, toggle, referenceId, referenceType, page, size, sort } = props;

  const dispatch = useDispatch<AppDispatch>();

  const { t } = useTranslation();

  const companyRoles = useAppSelector(state => state.CompanyRoles.entities);

  const companies = useAppSelector(state => state.Company.entities);

  const { entities: companyContacts } = useAppSelector(state => state.CompanyContact);

  const { entity: locationEntity, updating } = useAppSelector(state => state.Location);

  const [animateError, setAnimateError] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (referenceId && referenceType) {
      dispatch(reset());
      dispatch(getCompanyRoles({}));
      dispatch(getCompanies({}));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceId]);

  useEffect(() => {
    const primaryContact = companyContacts.find(contact => contact.primary);
    form.setFieldValue('companyContact', primaryContact ? primaryContact.id : null);
    if (form.getFieldValue('company')) form.validateFields();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyContacts]);

  const onClose = (): void => {
    toggle();
    resetDrawer();
    dispatch(reset());
  };

  const resetDrawer = () => {
    form.resetFields();
  };

  const onFinish = (entity: any): void => {
    let entityToCreate: Partial<ICompanyBindingExtended> = {
      company: entity?.company ? companies.find(company => company?.id === entity?.company) : null,
      companyRole: entity?.companyRole ? JSON.parse(entity?.companyRole as string) : null,
      referenceId,
      referenceType: CompanyReferenceEnum[referenceType],
    };

    if (entity.companyContact) {
      entityToCreate['companyContact'] = { id: entity?.companyContact ? JSON.parse(entity?.companyContact as string) : null }
    }

    dispatch(createCompany({ ...entityToCreate, page, size, sort })).then(() => onClose());
  };

  const playAnimationOfError = () => {
    setAnimateError(true);
    setTimeout(() => {
      setAnimateError(false);
    }, 500);
  };

  const onFinishFailed = (errorInfo: any) => {
    playAnimationOfError();
  };

  const onSubmit = () => {
    form.submit();
  };

  const [childrenDrawer, setChildrenDrawer] = useState(false);

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  const labelCompany = (
    <Row justify={'space-between'} className="w-full">
      {t('generic.companyName')}
    </Row>
  );

  const genericFilter = (input: string, option: any) => option?.label?.toLowerCase!()?.indexOf!(input?.toLowerCase!()) >= 0;

  const onChangeCompany = (companyIdSelected: Number) => {
    const isValidId = Number.isInteger(companyIdSelected);
    if (isValidId) {
      dispatch(getEntityByCompany(String(companyIdSelected)));
    }
  };

  return (
    <>
      <Drawer
        title={`New ${t('generic.company')}`}
        width={580}
        onClose={onClose}
        open={open}
        closable={false}
        styles={{
          body: { paddingBottom: 80 },
        }}
        extra={
          <Space>
            <Button type="primary" ghost onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={onSubmit} type="primary" loading={updating}>
              Save
            </Button>
          </Space>
        }
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          initialValues={locationEntity}
          onFinishFailed={onFinishFailed}
          className={`${animateError && 'error-animation'}`}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="companyRole"
                label={`${t('generic.company')} Role`}
                rules={[{ required: true, message: `Please enter a ${t('generic.company')} Role` }]}
              >
                <Select placeholder={`Please select a ${t('generic.company')} Role`} allowClear>
                  {companyRoles.map(item => (
                    <Option value={JSON.stringify(item)} key={item.id}>
                      {item.description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="company"
                label={labelCompany}
                className="form-input-company-label"
                rules={[{ required: true, message: `Please enter a ${t('generic.company')}` }]}
              >
                <Select
                  placeholder={`Please select a ${t('generic.company')}`}
                  allowClear
                  optionFilterProp="children"
                  showSearch
                  filterOption={genericFilter}
                  onChange={onChangeCompany}
                  options={companies.map(item => ({ value: item.id, label: item.name }))}
                ></Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="companyContact"
                label="Main Point of Contact"
                className="form-input-company-label"
                rules={[{ required: false, message: `Please select a ${t(`generic.company`)} ${t('generic.contact')}` }]}
              >
                <Select
                  placeholder={`--`}
                  allowClear
                  optionFilterProp="children"
                  showSearch
                  filterOption={genericFilter}
                  disabled={!form.getFieldValue('company')}
                  options={companyContacts.map(item => ({
                    value: item.id,
                    label: `${item.firstName} ${item.lastName}`,
                  }))}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {/* TODO Drawer of Company Next Sprint */}
        <Drawer
          title="Create a new Company"
          width={440}
          closable={false}
          onClose={onChildrenDrawerClose}
          open={childrenDrawer}
          extra={
            <Space>
              <Button type="primary" ghost onClick={onClose}>
                Cancel
              </Button>
              <Button onClick={onSubmit} type="primary" loading={updating}>
                Save
              </Button>
            </Space>
          }
        ></Drawer>
      </Drawer>
    </>
  );
};
