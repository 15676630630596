import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Checkbox, Drawer, Select, Space, theme } from 'antd';
import { NEUTRAL_2_COLOR } from '@providers/ThemeProvider';
import { useTranslation } from 'react-i18next';

interface ICompaniesDisplayOptions {
  openDisplayOptions: boolean;
  onClose: () => void;
  columnsState: { [key: string]: boolean };
  setColumnsState: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  setItemsPerPage: (itemsPerPage: number) => void;
}

const CompanyDisplayOptions: React.FC<ICompaniesDisplayOptions> = ({
  columnsState,
  setColumnsState,
  openDisplayOptions,
  onClose,
  setItemsPerPage,
}) => {
  const [selectedColumns, setSelectedColumns] = useState({ ...columnsState });

  const handleCheckboxChange = (checked: boolean, columnName: string) => {
    const updatedColumnsState = { ...selectedColumns, [columnName]: checked };
    setSelectedColumns(updatedColumnsState);
  };

  const handleSave = () => {
    setColumnsState(selectedColumns);
    onClose();
  };

  const defaultPaginationOptions = [
    { value: 10, label: '10 / Page' },
    { value: 5, label: '5 / Page' },
  ];

  const {
    token: { colorBorderSecondary, colorPrimary, fontSize },
  } = theme.useToken();

  function camelCaseToString(camelCase) {
    // Use a regular expression to insert a space before all uppercase letters
    let spacedString = camelCase.replace(/([a-z])([A-Z])/g, '$1 $2');

    // Capitalize the first letter of the resulting string
    spacedString = spacedString.charAt(0).toUpperCase() + spacedString.slice(1);

    return spacedString;
  }
  const columnOptions = Object.keys(columnsState).filter(item => item !== 'button');

  const { t } = useTranslation();

  return (
    <>
      <Drawer
        title="Display Options"
        placement="right"
        onClose={onClose}
        visible={openDisplayOptions}
        width={500}
        extra={
          <Space>
            <Button type="primary" ghost onClick={onClose}>
            {t('generic.cancel')}
            </Button>
            <Button type="primary" onClick={handleSave}>
              {t('generic.save')}
            </Button>
          </Space>
        }
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="mb-20 pl-10">
          <b>Default items about pagination</b>
          <Select
            defaultValue={10}
            style={{ width: 120 }}
            options={defaultPaginationOptions}
            onChange={e => {
              setItemsPerPage(e);
            }}
          />
        </div>
        <div
          className="font-medium"
          style={{
            height: '32px',
            color: colorPrimary,
            fontSize: fontSize + 2,
            border: `1px solid ${colorBorderSecondary}`,
            backgroundColor: `${NEUTRAL_2_COLOR}`,
            margin: '10px 0 10px 0',
            borderRadius: '5px',
            padding: '5px 0 0 10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          Default Fields
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {columnOptions.map(column => (
            <Checkbox
              key={column}
              checked={selectedColumns[column]}
              disabled={column === 'name'}
              style={{ borderRadius: '5px' }}
              onChange={e => handleCheckboxChange(e.target.checked, column)}
            >
              {camelCaseToString(column)}
            </Checkbox>
          ))}
        </div>
      </Drawer>
    </>
  );
};

export default CompanyDisplayOptions;
