import { INTERCOM_APP_ID } from '@constants/core';
import { useAuth } from '@providers/AuthProvider';

const useIntercom = () => {

    const { user } = useAuth();
  
    const shutdownIntercom = () => {
        if (window) {      
            window.Intercom && window.Intercom('shutdown');
        }
    }

    const bootIntercom = () => {
      if (window && window?.Intercom && INTERCOM_APP_ID && user?.email) {
        window?.Intercom!('boot', {
          app_id: INTERCOM_APP_ID,
          email: user.email,
          name: `${user.firstName} ${user.lastName}`,
          Instance: window.location.origin,
          custom_launcher_selector: '#launch_support',
          hide_default_launcher: true,
        });
      }
    };

    const showIntercom = () => {
      if (window) {
        window.Intercom && window.Intercom('show');
      }
    };

    const hideIntercom = () => {
      if (window) {
        window.Intercom && window.Intercom('hide');
      }
    };

    const updateIntercomBadge = (setUnreadCount: (count: number) => void) => {
      if (window) {
        window.Intercom &&
          window.Intercom('onUnreadCountChange', function (unreadCount: number) {
            setUnreadCount(unreadCount);
          });
      }
    };

    return {
      bootIntercom,
      shutdownIntercom,
      showIntercom,
      hideIntercom,
      updateIntercomBadge,
    };
};

export default useIntercom;