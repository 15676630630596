import { createAsyncThunk, createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { ILocation } from '@models/location.model';
import { locationService } from '@services/locations.service';
import { IQueryParamsGeneric } from "@models/utils";
import { getTotalItems } from "@shared/util/entity-utils";
import { IMyProjects, IMyUpcommingDeliverable, IMyPendingApprovals, IMyPastDeliverable } from '@models/dashboard.model';
import { dashboardService } from '@services/dashboard.service';
export interface IUtil {
  data: any;
}
export interface EntityListContent<T> {
  list: T[];
  loading: boolean;
  totalItems: number;
  errorMessage: unknown;
}

export interface IState extends IUtil {
  data: {
    myPendingApprovals: EntityListContent<IMyPendingApprovals>;
    myProjects: EntityListContent<IMyProjects>;
    myUpcomingDeliverables: EntityListContent<IMyUpcommingDeliverable>;
    myPastDeliverables: EntityListContent<IMyPastDeliverable>;
    upcomingOpenings: EntityListContent<ILocation>;
    recentOpenings: EntityListContent<ILocation>;
  };
}

const initialStateSkeleton = {
  list: [],
  totalItems: 0,
  loading: false,
  errorMessage: null,
};

const initialState: IState = {
  data: {
    myPendingApprovals: { ...initialStateSkeleton },
    myProjects: { ...initialStateSkeleton },
    myUpcomingDeliverables: { ...initialStateSkeleton },
    myPastDeliverables: { ...initialStateSkeleton },
    upcomingOpenings: { ...initialStateSkeleton },
    recentOpenings: { ...initialStateSkeleton },
  },
};

const sliceName: string = 'dashboard';

export const getMyPendingApprovals = createAsyncThunk(`${sliceName}/getMyPendingApprovals`, async (queryParams: IQueryParamsGeneric, thunkAPI) => {
  const result = await dashboardService.getMyPendingApprovals(queryParams);
  return result;
});

export const getMyProjects = createAsyncThunk(`${sliceName}/getMyProjects`, async (queryParams: IQueryParamsGeneric, thunkAPI) => {
  const result = await dashboardService.getMyProjects(queryParams);
  return result;
});

export const getMyUpcomingDeliverables = createAsyncThunk(
  `${sliceName}/getMyUpcomingDeliverables`,
  async (queryParams: IQueryParamsGeneric, thunkAPI) => {
    const result = await dashboardService.getMyDeliverables(queryParams);
    return result;
  }
);

export const getMyPastDeliverables = createAsyncThunk(
  `${sliceName}/getMyPastDeliverables`,
  async (queryParams: IQueryParamsGeneric, thunkAPI) => {
    const result = await dashboardService.getMyPastDeliverables(queryParams);
    return result;
  }
);

export const getUpcomingOpenings = createAsyncThunk(
  `${sliceName}/getUpcomingOpenings`,
  async (queryParams: IQueryParamsGeneric, thunkAPI) => {
    const result = await locationService.getAllWithMainAddress(queryParams);
    return result;
  }
);

export const getRecentOpenings = createAsyncThunk(`${sliceName}/getRecentOpenings`, async (queryParams: IQueryParamsGeneric, thunkAPI) => {
  const result = await locationService.getAllWithMainAddress(queryParams);
  return result;
});

export const Dashboard = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getMyProjects.fulfilled, (state, action) => {
        const { data, headers } = action.payload;
        state.data.myProjects.list = data;
        state.data.myProjects.totalItems = getTotalItems(headers);
        state.data.myProjects.errorMessage = null;
        state.data.myProjects.loading = false;
        return state;
      })
      .addCase(getMyPendingApprovals.fulfilled, (state, action) => {
        const { data, headers } = action.payload;
        state.data.myPendingApprovals.list = data;
        state.data.myPendingApprovals.totalItems = getTotalItems(headers);
        state.data.myPendingApprovals.errorMessage = null;
        state.data.myPendingApprovals.loading = false;
        return state;
      })
      .addCase(getMyUpcomingDeliverables.fulfilled, (state, action) => {
        const { data, headers } = action.payload;
        state.data.myUpcomingDeliverables.list = data;
        state.data.myUpcomingDeliverables.totalItems = getTotalItems(headers);
        state.data.myUpcomingDeliverables.errorMessage = null;
        state.data.myUpcomingDeliverables.loading = false;
        return state;
      })
      .addCase(getMyPastDeliverables.fulfilled, (state, action) => {
        const { data, headers } = action.payload;
        state.data.myPastDeliverables.list = data;
        state.data.myPastDeliverables.totalItems = getTotalItems(headers);
        state.data.myPastDeliverables.errorMessage = null;
        state.data.myPastDeliverables.loading = false;
        return state;
      })
      .addCase(getUpcomingOpenings.fulfilled, (state, action) => {
        const { data, headers } = action.payload;
        state.data.upcomingOpenings.list = data;
        state.data.upcomingOpenings.totalItems = getTotalItems(headers);
        state.data.upcomingOpenings.errorMessage = null;
        state.data.upcomingOpenings.loading = false;
        return state;
      })
      .addCase(getRecentOpenings.fulfilled, (state, action) => {
        const { data, headers } = action.payload;
        state.data.recentOpenings.list = data;
        state.data.recentOpenings.totalItems = getTotalItems(headers);
        state.data.recentOpenings.errorMessage = null;
        state.data.recentOpenings.loading = false;
        return state;
      })

      // Pendings
      .addMatcher(isPending(getMyPendingApprovals), state => {
        state.data.myPendingApprovals.errorMessage = null;
        state.data.myPendingApprovals.loading = true;
      })
      .addMatcher(isPending(getMyProjects), state => {
        state.data.myProjects.errorMessage = null;
        state.data.myProjects.loading = true;
      })
      .addMatcher(isPending(getMyUpcomingDeliverables), state => {
        state.data.myUpcomingDeliverables.errorMessage = null;
        state.data.myUpcomingDeliverables.loading = true;
      })
      .addMatcher(isPending(getMyPastDeliverables), state => {
        state.data.myPastDeliverables.errorMessage = null;
        state.data.myPastDeliverables.loading = true;
      })
      .addMatcher(isPending(getUpcomingOpenings), state => {
        state.data.upcomingOpenings.errorMessage = null;
        state.data.upcomingOpenings.loading = true;
      })
      .addMatcher(isPending(getRecentOpenings), state => {
        state.data.recentOpenings.errorMessage = null;
        state.data.recentOpenings.loading = true;
      })

      // Rejected
      .addMatcher(isRejected(getMyPendingApprovals), state => {
        state.data.myPendingApprovals.errorMessage = null;
        state.data.myPendingApprovals.loading = false;
      })
      .addMatcher(isRejected(getMyProjects), state => {
        state.data.myProjects.errorMessage = null;
        state.data.myProjects.loading = false;
      })
      .addMatcher(isRejected(getMyUpcomingDeliverables), state => {
        state.data.myUpcomingDeliverables.errorMessage = null;
        state.data.myUpcomingDeliverables.loading = false;
      })
      .addMatcher(isRejected(getMyPastDeliverables), state => {
        state.data.myPastDeliverables.errorMessage = null;
        state.data.myPastDeliverables.loading = false;
      })
      .addMatcher(isRejected(getUpcomingOpenings), state => {
        state.data.upcomingOpenings.errorMessage = null;
        state.data.upcomingOpenings.loading = false;
      })
      .addMatcher(isRejected(getRecentOpenings), state => {
        state.data.upcomingOpenings.errorMessage = null;
        state.data.upcomingOpenings.loading = false;
      });
  },
});

export const { reset } = Dashboard.actions;

// Reducer
export default Dashboard.reducer;