import { SVGProps } from 'react';

type IProps = SVGProps<SVGSVGElement>;

export const SettingsIcon = (props: IProps) => {
  const { ...restProps } = props;

  return (
    <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path
        d="M6.58333 9.58333H6.08333V10.0833V10.75H5.91667V8.25H6.08333V8.91667V9.41667H6.58333H10.75V9.58333H6.58333ZM3.08333 5.91667H3.58333V5.41667V4.75H3.75V7.25H3.58333V6.58333V6.08333H3.08333H1.25V5.91667H3.08333ZM8.91667 2.58333H8.41667V3.08333V3.75H8.25V1.25H8.41667V1.91667V2.41667H8.91667H10.75V2.58333H8.91667ZM3.75 9.58333H1.25V9.41667H3.75V9.58333ZM6.08333 2.58333H1.25V2.41667H6.08333V2.58333ZM5.91667 5.91667H10.75V6.08333H5.91667V5.91667Z"
        stroke={props.color}
      />
    </svg>
  );
};
