import { useAppSelector } from "@store/store";
import { Col, Skeleton, Divider, Row, theme, Avatar } from "antd";
import { ArrowRightOutlined, HourglassOutlined } from "@ant-design/icons";
import { getFirstItem, hasMoreThanOne, hasOnlyOne, isArrayWithValues } from '../../shared/util/array-util';
import { IHistorySlice } from '@models/audit-history';
import { IModifiedField } from '../../models/audit-history';
import { getUniqueId } from "@infrastructure/repositories/utils.repository";
import { useTranslation } from "react-i18next";
import { EntryTime } from "@components/Timeline/EntryTime";
import { ContainerContentHistory } from './ContainerContentHistory';
import { ChangeItemHistoryRender } from "./ChangeItemHistoryRender";
import { CustomCollapseViewDetails } from "./CustomCollapseViewDetails";
import { If } from "@components/Utils/Structural";

const AudioFieldLabels = Object.freeze({
    NOT_APPLICABLE: "NOT_APPLICABLE"
})

export const deliverableNotApplicableStatusMap = Object.freeze({
    'APPLICABLE': 'Applicable',
    'NOT_APPLICABLE': 'Not Applicable',
})


export const AuditHistoryDrawerContent = () => {

    const { t } = useTranslation();
    const { history, settings, loading } = useAppSelector((store) => store.AuditHistory);
    const { token: { colorBorder, fontSizeSM, colorText } } = theme.useToken();

    const renderGridStyle = { gridTemplateRows: '1fr', gridTemplateColumns: 'minmax(0px, max-content) max-content auto' };

    const isNotApplicableItem = (fields) => {
        let item = getFirstItem(fields)

        if (item && item.fieldLabel && item.fieldLabel === AudioFieldLabels.NOT_APPLICABLE)
            return true;
        return false
    }

    const isNotApplicableItemInModifiedFields = (fields) => {
        let item = getFirstItem(fields)
        if (item && item.fieldLabel && item.fieldLabel === AudioFieldLabels.NOT_APPLICABLE)
            return true;
        return false
    }

    const getNewValueForModifiedBooleanField = (fields: IModifiedField[]) => {
        if (!hasOnlyOne(fields)) return null
        let firstItem = getFirstItem(fields)
        if (firstItem.fieldType === "BOOLEAN" && firstItem.fieldLabel === "NOT_APPLICABLE") {
            if (firstItem.newValue === 'true') return 'True'
            return 'False'
        }
    }


    return (
        <ContainerContentHistory>
            <Skeleton loading={loading} paragraph={{ rows: 28 }} active={true} className="pl-30 pr-30 pt-15 z-20">
                <Row className="pl-15 pr-15 pb-40">
                    <Col span={24}>
                        <If condition={isArrayWithValues(history)}>
                            <>
                                {
                                    history.map((item: IHistorySlice, index) => (
                                        <Row className={`${index === 0 ? 'pt-8' : 'pt-15'}  pl-15 pr-15`} key={getUniqueId()}>
                                            <Col span={24}>
                                                {/* Header */}
                                                <Row>
                                                    <Col className="flex flex-row w-full items-center">
                                                        <div className="flex flex-row items-center mr-5">
                                                            <If condition={Boolean(item.lastModifiedByFullName)}>
                                                                <Avatar className="cursor-default bg-color-neutral-4" gap={4}>
                                                                    <span className="text-color-neutral-8">
                                                                        {`${item.lastModifiedByFullName?.slice(0, 2)}`?.toUpperCase!()}
                                                                    </span>
                                                                </Avatar>
                                                            </If>
                                                        </div>
                                                        <div className="flex flex-row justify-between w-full items-center">
                                                            <div className="flex flex-row flex-wrap">
                                                                <span className="flex font-bold ml-5" style={{ color: colorText }}>
                                                                    {item.lastModifiedByFullName}
                                                                </span>
                                                                <div className="flex">
                                                                    <If condition={hasMoreThanOne(item.modifiedFields)}>
                                                                        <span className="ml-5 inline text-color-neutral-7">
                                                                            {`${t("audit.made")} ${item.modifiedFields.length} ${t("audit.changes")}`}
                                                                        </span>
                                                                    </If>
                                                                    <If condition={hasOnlyOne(item.modifiedFields)}>
                                                                        <>
                                                                            <If condition={isNotApplicableItem(item.modifiedFields)}>
                                                                                <div className="flex flex-row items-center">
                                                                                    <span className="pr-4 pl-4 text-color-neutral-7">set deliverable as</span>
                                                                                    <span className="pl-4 text-color-neutral-8">
                                                                                        {getNewValueForModifiedBooleanField(item.modifiedFields) === "True" ? deliverableNotApplicableStatusMap.NOT_APPLICABLE : deliverableNotApplicableStatusMap.APPLICABLE}
                                                                                    </span>
                                                                                </div>
                                                                            </If>
                                                                            <If condition={!isNotApplicableItem(item.modifiedFields)}>
                                                                                <div className="flex flex-row items-center">
                                                                                    <span className="pr-4 pl-4 text-color-neutral-7">changed the</span>
                                                                                    <span className="pl-4 text-color-neutral-8">
                                                                                        {
                                                                                            item?.modifiedFields[0]?.fieldLabel && t(`audit.${settings.entityTranslation}.${item?.modifiedFields[0]?.fieldLabel}`, `${item.modifiedFields[0].fieldLabel}`)
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </If>

                                                                        </>
                                                                    </If>
                                                                </div>
                                                            </div>
                                                            <span className="text-color-neutral-6 w-max min-w-max" style={{ fontSize: fontSizeSM }}>
                                                                {/* { fromNow(item.lastModifiedDate) } */}
                                                                <EntryTime date={item.lastModifiedDate} />
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {/* Changes */}
                                                <Row>
                                                    <Col className="ml-40">
                                                        <If condition={hasMoreThanOne(item.modifiedFields)}>
                                                            <>
                                                                <CustomCollapseViewDetails contentExpanded={(
                                                                    <div className="pl-15 ml-4">
                                                                        {
                                                                            item.modifiedFields.map((modifiedField: IModifiedField) => (
                                                                                <div className="flex flex-col" key={getUniqueId()}>
                                                                                    <span className="mr-5 mb-2 text-color-neutral-8">
                                                                                        {t(`audit.${settings.entityTranslation}.${modifiedField.fieldLabel}`, `${modifiedField.fieldLabel}`)}
                                                                                    </span>
                                                                                    <div className="grid mb-4" style={renderGridStyle}>
                                                                                        <span className="text-color-neutral-7">
                                                                                            <ChangeItemHistoryRender
                                                                                                type={modifiedField?.fieldType}
                                                                                                value={modifiedField?.oldValue}
                                                                                            />
                                                                                        </span>
                                                                                        <span className="ml-8 mr-8" style={{ color: colorBorder }}>
                                                                                            <ArrowRightOutlined />
                                                                                        </span>
                                                                                        <span className="text-color-neutral-7">
                                                                                            <ChangeItemHistoryRender
                                                                                                type={modifiedField?.fieldType}
                                                                                                value={modifiedField?.newValue}
                                                                                            />
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                )} />
                                                            </>
                                                        </If>
                                                        <If condition={hasOnlyOne(item.modifiedFields)}>
                                                            <>
                                                                <If condition={!isNotApplicableItemInModifiedFields(item.modifiedFields)}>
                                                                    <div className="grid mt-4 mb-4 pl-3" style={renderGridStyle}>
                                                                        <span className="text-color-neutral-7">
                                                                            <ChangeItemHistoryRender
                                                                                type={item.modifiedFields[0]?.fieldType}
                                                                                value={item.modifiedFields[0]?.oldValue}
                                                                            />
                                                                        </span>
                                                                        <span className="ml-8 mr-8" style={{ color: colorBorder }}>
                                                                            <ArrowRightOutlined />
                                                                        </span>
                                                                        <span className="text-color-neutral-7">
                                                                            <ChangeItemHistoryRender
                                                                                type={item.modifiedFields[0]?.fieldType}
                                                                                value={item.modifiedFields[0]?.newValue}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </If>
                                                            </>

                                                        </If>
                                                    </Col>
                                                </Row>

                                                <If condition={Boolean(index !== history.length - 1)}>
                                                    <Row>
                                                        <Divider className="mb-0 mt-8" />
                                                    </Row>
                                                </If>
                                            </Col>
                                        </Row>
                                    ))
                                }
                            </>
                        </If>
                        <If condition={!isArrayWithValues(history)}>
                            <CustomizedRenderEmpty />
                        </If>
                    </Col>
                </Row>
            </Skeleton>
        </ContainerContentHistory>
    );
}

const CustomizedRenderEmpty = () => {
    const { token: { colorTextTertiary, fontSizeHeading2 } } = theme.useToken();
    const { t } = useTranslation();
    return (
        <div style={{ textAlign: 'center', color: colorTextTertiary }} className='mt-20'>
            <HourglassOutlined className="pb-10" style={{ fontSize: fontSizeHeading2 }} />
            <p className='mt-5'>{t("audit.emptyStateMessage")}</p>
        </div>
    )
};