import { createContext, useContext, useState } from "react";

export const VisualizationsContext = createContext({} as {
    settings: any
});

export const VisualizationProvider = ({ children }: any) => {

    const [settings] = useState<any>();

    const value = {
        settings
    }

    return (
        <VisualizationsContext.Provider value={value}>{children}</VisualizationsContext.Provider>
    )
};

export const useVisualizationsProvider = () => {
    return useContext(VisualizationsContext);
};