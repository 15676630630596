import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getEntities as getProjectStatuses, reorderProjectStatuses } from "@store/slices/project-statuses";
import { Card, Switch, theme, Tag } from "antd";
import useVisible from "@HOOKs/UseVisible";
import { IProjectStatus } from "@models/project-status.model";
import { ProjectStatusEdit } from "./ProjectStatusEdit";
import { EditOutlined } from "@ant-design/icons";
import { DragHandle } from "@components/Icons/DragHandle";
import { NEUTRAL_7_COLOR } from "@providers/ThemeProvider";
import { asyncLaunchNotification } from "@store/slices/notification";

export const ProjectStatusesCard = (props: any) => {

  const dispatch = useAppDispatch();

  const { token: { colorPrimary, colorTextDisabled, colorBgBase, colorText } } = theme.useToken();

  const loadStatuses = () => {
    dispatch(
      getProjectStatuses({})
    );
}

  useEffect(() => {
    loadStatuses();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [sortedStatuses, setSortedStatuses] = useState<IProjectStatus[]>([])

  const { entities: projectStatuses } = useAppSelector((state) => state.ProjectStatuses);

  useEffect(() => {
    setSortedStatuses(JSON.parse(JSON.stringify(projectStatuses)));
  }, [projectStatuses]);

  const [openEditStatus, toogleEditStatus] = useVisible();
  const [statusSelected, setStatusSelected] = useState<IProjectStatus>({})
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const onModifiedChanges = (projectStatus: IProjectStatus) => {
    loadStatuses();
  }

  const toggleEdit = () => {
    setIsEditing(!isEditing)
  };

  const editStatus = (projectStatus : IProjectStatus) => {
    setStatusSelected(projectStatus)
    toogleEditStatus()
  }

  const createNewStatus = () => {
    setStatusSelected({})
    toogleEditStatus();
  }

  const [draggedItem, setDraggedItem] = useState<IProjectStatus | null>(null);

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>, item: IProjectStatus) => {
    setDraggedItem(item);
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>, item: IProjectStatus) => {
    event.preventDefault();
    setSortedStatuses((prevItems) => {
      const newItems = [...prevItems];
      const draggedItemIndex = prevItems.findIndex((i) => i.id === draggedItem?.id);
      const itemIndex = prevItems.findIndex((i) => i.id === item.id);
      newItems.splice(draggedItemIndex, 1);
      newItems.splice(itemIndex, 0, draggedItem!);
      return newItems;
    });
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
    const ids = sortedStatuses.map(s => Number(s.id));
    if (sortedStatuses.filter(s => Number(s.id)).length !== ids.length) {
      dispatch(asyncLaunchNotification({
        type: "error",
        config: {
            message: `Invalid Status IDs`,
            description: `Status IDs are invalid. Please refresh and try again.`
        }
      }));
      return false;
    }
    dispatch(reorderProjectStatuses(ids));
  };

  const ListItem = (status: IProjectStatus) => {
    return <li style={{ color: NEUTRAL_7_COLOR, paddingBottom: '4px' }}>{status.name} {status.showOnDashboard && <Tag>Show on dashboard</Tag>}</li>
  }
  
  const ListItemEditable = (status: IProjectStatus) => {
    return <div style={{ display: 'flex', backgroundColor: colorBgBase, border: '1px solid #F0F0F0', borderRadius: '4px', padding: '4px 8px', marginBottom: '4px' }}>
      <div>
        <div style={{ paddingTop: '4px', marginRight: '8px' }}><DragHandle /></div>
      </div>
      <div style={{ paddingTop: '3px', color: colorText }}>
        {status.name} {status.showOnDashboard && <Tag>Show on dashboard</Tag>}
      </div>
      <span style={{ flex: '1', textAlign: 'right' }}>
        <span onClick={() => editStatus(status)} style={{ cursor: 'pointer', color: colorPrimary }}>
          <EditOutlined /> Edit
        </span>
      </span>
    </div>
  }

  return (
    <>
      <ProjectStatusEdit open={openEditStatus} toogle={toogleEditStatus} projectStatus={statusSelected} onModifiedChanges={onModifiedChanges} />
      <Card title="Status" bordered={false} style={{ width: 400, marginBottom: '16px' }} extra={<div><span style={{ color: (isEditing ? colorPrimary : colorTextDisabled), marginRight: '8px', cursor: 'pointer' }} onClick={toggleEdit}>{isEditing ? 'Managing' : 'Manage'}</span><Switch checked={isEditing} onClick={toggleEdit} /></div>}>
        <ul style={{ paddingLeft: '15px' }}>
            {sortedStatuses.map(status => 
            <div
                draggable={isEditing}
                onDragStart={(event) => handleDragStart(event, status)}
                onDragEnter={(event) => handleDragEnter(event, status)}
                onDragEnd={handleDragEnd} 
                key={status.id}>
                <>
                {isEditing && <ListItemEditable {...status} />}
                {!isEditing && <ListItem {...status} />}
                </>
            </div>
            )}
        </ul>
        {isEditing && <>
            <div style={{ borderTop: '1px solid #F5F5F5' }}></div>
            <span onClick={createNewStatus} style={{ marginTop: '10px', display: 'inline-block', cursor: 'pointer', color: colorPrimary }}>+ Add New Status</span>
        </>}
        </Card>
    </>
  )
}