import { Logo } from "@components/Logo/Logo";
import { configuration } from '../../../environments/env';

interface IBrandLogoProps {}

export const BrandLogo = (props: IBrandLogoProps) => {
    return (
        <>
            {
                configuration?.LOGO_LOGIN_CARD 
                    ? <img className="flex relative mt-20 mb-35 items-center" style={{ width: '50px' }} src={configuration?.LOGO_LOGIN_CARD} alt="" />
                    : <Logo classStyle="flex relative mt-20 mb-35 items-center" isCollapsed={true} />
            }
        </>
    )
}
