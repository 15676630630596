import { RichTextEditor } from "@components/Comment/RichTextEditor"
import { ConfigProvider } from "antd"
import { COLOR_TEXT_BASE, WHITE, useThemeProvider } from '@providers/ThemeProvider';

interface IQuestionInputProps {
    value?: string;
    initialValue?: string;
    onChange?: (value: string) => void;
}

export const QuestionInput = (props: IQuestionInputProps) => {
    const { themeConfig } = useThemeProvider();

    const triggerChange = (value: string) => {
        props.onChange?.(value);
    }

    return (
        <ConfigProvider theme={{ token: { colorTextBase: themeConfig.darkMode ? WHITE : COLOR_TEXT_BASE } }}>
            <RichTextEditor showSend={false} placeholderText='Write a new question here....' onChange={triggerChange} defaultValue={props.initialValue} mentionsDisabled={true} />
        </ConfigProvider>
    )
}