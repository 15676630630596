
import { http } from '../http/axios.instance';
import { IQueryParamsGeneric } from '@models/utils';
import { IMyUpcommingDeliverable, IMyProjects, IMyPastDeliverable } from '@models/dashboard.model';

const path = 'dashboard';

export const dashboardRepository = {
  getMyDeliverables: async (queryParams: IQueryParamsGeneric) => {
    const params = new URLSearchParams({
      ...queryParams,
      cacheBuster: `${new Date().getTime()}`,
      allStatuses: 'true',
    });
    const requestUrl = `${path}/my-deliverables?${params.toString()}`;

    const promiseGetAll = await http.get<IMyUpcommingDeliverable[]>(requestUrl);
    return promiseGetAll;
  },
  getMyPastDeliverables: async (queryParams: IQueryParamsGeneric) => {
    const params = new URLSearchParams({
      ...queryParams,
      cacheBuster: `${new Date().getTime()}`,
      allStatuses: 'true',
    });
    const requestUrl = `${path}/my-past-deliverables?${params.toString()}`;

    const promiseGetAll = await http.get<IMyPastDeliverable[]>(requestUrl);
    return promiseGetAll;
  },
  getMyProjects: async (queryParams: IQueryParamsGeneric) => {
    const params = new URLSearchParams({
      ...queryParams,
      cacheBuster: `${new Date().getTime()}`,
      allStatuses: 'true',
    });
    const requestUrl = `${path}/my-projects?${params.toString()}`;

    const promiseGetAll = await http.get<IMyProjects[]>(requestUrl);
    return promiseGetAll;
  },
  getMyPendingApprovals: async (queryParams: IQueryParamsGeneric) => {
    const params = new URLSearchParams({
      ...queryParams,
      cacheBuster: `${new Date().getTime()}`,
    });
    const requestUrl = `${path}/my-pending-approvals?${params.toString()}`;

    const promiseGetAll = await http.get<IMyProjects[]>(requestUrl);
    return promiseGetAll;
  },
};