import { DownOutlined } from "@ant-design/icons";
import AttachmentsList from "@components/Attachment/AttachmentsList";
import { AttachmentReferenceEnum } from "@models/enumerations/attachment-reference-enum.model";
import { useAppSelector } from "@store/store";
import { Button, Col, Row, theme } from "antd";
import { IChangeOrderStepProps } from "./Stepper";
import { useTranslation } from 'react-i18next';
import { ChangeOrdersStatusIds } from "@models/enumerations/change-orders-status";

export const StepTwo = (props: IChangeOrderStepProps) => {

    const { token: { colorPrimary } } = theme.useToken();

    const { entity } = useAppSelector((state) => state.ChangeOrder);
    const { t } = useTranslation();


    const reviewClick = () => {
        props.complete?.();
    }

    return (<>
        <Row>
            <Col span={24} className="text-center mt-20 pl-10 pr-10">
                <div>{t("changeOrder.stepper.step2Title")}</div>
                <DownOutlined style={{ marginTop: 10, color: colorPrimary }} />
            </Col>
        </Row>
        <Row>
            <Col className="pl-10 pr-10">
                {
                    entity && <AttachmentsList entityType={AttachmentReferenceEnum.CHANGE_ORDER_REVISION} referenceId={entity.currentRevisionId!} />
                }
            </Col>
        </Row>
        <Row className="pt-20">
            <Col span={12} className='text-left pl-10 pr-10'>
                {entity?.lastStatusTransition?.status?.id !== ChangeOrdersStatusIds.PENDING_ESTIMATE_RESUBMISSION &&
                    <Button type="primary" ghost onClick={props.dismiss}>
                        {t("changeOrder.stepper.keepDraftButton")}
                    </Button>
                }
            </Col>
            <Col span={12} className='text-right pl-10 pr-10'>
                <Button type="primary" onClick={reviewClick}>
                    {t("changeOrder.stepper.reviewButton")}
                </Button>
            </Col>
        </Row>
    </>);
}
