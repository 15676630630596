import { blue } from "@ant-design/colors";
import { CheckCircleTwoTone, FileImageOutlined } from "@ant-design/icons";
import UploadModal from "@components/Attachment/UploadModal";
import { If } from "@components/Utils/Structural";
import { AttachmentReferenceEnum } from "@models/enumerations/attachment-reference-enum.model";
import { ILocation } from "@models/location.model";
import { useNotificationsProvider } from "@providers/NotificationsProvider";
import { getEntity } from "@store/slices/location";
import { useAppDispatch } from "@store/store";

interface IUploadKeyPhotoProps {
  location: ILocation,
  openModal: boolean,
  toogleModal: () => void
}

export const ACCEPT_IMAGES_TO_UPLOAD =  "image/png, image/jpg, image/jpeg, image/webp"

export const UploadKeyPhoto = (props: IUploadKeyPhotoProps) => {

    const dispatch = useAppDispatch();

    const { location, openModal, toogleModal } = props;

    const { openNotification } = useNotificationsProvider();

    const titleModal = "Drag and Drop a Key Image";

    const getAllAttachments = () => {}

    const handleCloseuploadModals = () => {
        toogleModal();
    }

    const onSuccessUploadedFile = () => {
        openNotification({
            type: "info",
            config: {
                icon: <CheckCircleTwoTone style={{ fontSize: 24 }} twoToneColor={[blue[5],blue[0]]} />,
                message: `Key Image Uploaded`,
                description: `You will see the preview once the processing is complete`
            }
        });
        location?.id && dispatch(getEntity(location?.id));
    }
    
    const uploadPropsModal = {
        accept: ACCEPT_IMAGES_TO_UPLOAD
    }

    return (
        <If condition={Boolean(openModal && location?.id)}>
            <UploadModal 
                entityType={AttachmentReferenceEnum.LOCATION_KEY_PHOTO} 
                referenceId={+location?.id!} 
                attachmentId={null} 
                getAllAttachments={getAllAttachments} 
                handleCloseuploadModals={handleCloseuploadModals} 
                onUploaded={onSuccessUploadedFile}
                titleModal={titleModal}
                draggerContent={<DraggerContent />}
                uploadPropsModal={uploadPropsModal}
            />
        </If>
    )
}

export const DraggerContent = () => {
    return (
      <>
        <p className="ant-upload-drag-icon">
          <FileImageOutlined />
        </p>
        <p className="ant-upload-text">Click or drag an image to this area to upload</p>
        <p className="ant-upload-hint">
            Supported formats: JPG, PNG, WEBP, JPEG...
        </p>
      </>
    )
}

