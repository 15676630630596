import { RcFile } from 'antd/es/upload';
import { isArrayWithValues } from './array-util';

export const ONE_KB = 1000;

export const convertBytes = (bytes: number): string => {
  if (!Number.isInteger(bytes)) {
    return '';
  }
  const unidades = ['bytes', 'KB', 'MB', 'GB'];
  let index = 0;
  while (bytes >= ONE_KB && index < unidades.length - 1) {
    bytes /= ONE_KB;
    index++;
  }
  return `${bytes.toFixed(1)} ${unidades[index]}`;
};

export const isAnValidImage = (extension: string) => {
  return ['png', 'jpg', 'jpeg', 'webp', 'heic', 'heif'].includes(extension);
};

export const getNameOfFileName = (filename: string) => {
  const stringSplitted = filename?.split('.') || [];
  return stringSplitted.slice(0, stringSplitted.length - 1).join('.') || '';
};

export const getExtensionOfFileName = (filename: string) => {
  const extension = filename?.split('.').pop() || '';
  return extension;
};

export const isBlob = (file: unknown) => {
  return file instanceof Blob;
};

export const sizeInMB = (size: number | undefined): number => {
  if (!size) {
    return 0;
  }
  return size / 1024 / 1024;
};

export const sumFileSizes = (fileList: RcFile[]) => {
  if (!isArrayWithValues(fileList)) {
    return 0;
  }
  return fileList.reduce<number>((acc, file) => {
    return acc + sizeInMB(file.size);
  }, 0);
};
