import { IPunchListItemPage, IPunchListItemPageUI } from "@models/punch-list.model";

export class PunchListPaginationMapper  {

  public static pagination (listPages: IPunchListItemPage[]): IPunchListItemPageUI[] {
    const listProcessed = listPages.map((item: IPunchListItemPage, i) => {
        return {
            ...item, 
            ...(listPages[i + 1]) && { nextPage: { ...listPages[i + 1], index: i + 1 } },
            ...(listPages[i - 1]) && { prevPage: { ...listPages[i - 1], index: i - 1 } },
        } as IPunchListItemPageUI
    });
    return listProcessed;
  }

  public static toDomain (response: IPunchListItemPage[]): IPunchListItemPageUI[] {

    const dataTransformedToDomain = this.pagination(response)

    return dataTransformedToDomain || [];
  }

}