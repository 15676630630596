export interface IAddressType {
  id?: number;
  description?: string | null;
  main?: boolean | null;
  onePerEntity?: boolean | null;
}

export const defaultValue: Readonly<IAddressType> = {
  main: false,
  onePerEntity: false,
};

export const PRIMARY_TYPE = "Primary"