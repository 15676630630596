
import { isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from "../reducer.util";
import { createAsyncThunk } from "@reduxjs/toolkit"
import { cleanEntity, getTotalItems } from '@shared/util/entity-utils';
import { punchListStatusService } from '@services/punch-list-statuses.service';
import { serializeGenericHandleError } from '@store/thunk.util';
import { IPunchListItemStatus, defaultStatusValue } from '@models/punch-list.model';

const nameOfEntity = "punch-list-status";
const service = punchListStatusService;

export const getPunchListStatuses = createAsyncThunk(
  `${nameOfEntity}/fetch_entity_list`,
  async () => {
      return service.getPunchlistStatuses()
  },
  { serializeError: serializeGenericHandleError }
);

export const createEntity = createAsyncThunk(
  `${nameOfEntity}/create_entity`,
  async (entity: IPunchListItemStatus, thunkAPI) => {
    const result = await service.create(cleanEntity(entity) as IPunchListItemStatus);
    thunkAPI.dispatch(getPunchListStatuses());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  `${nameOfEntity}/update_entity`,
  async (entity: IPunchListItemStatus, thunkAPI) => {
    const result = entity.id && await service.update(entity.id, cleanEntity(entity) as IPunchListItemStatus);
    thunkAPI.dispatch(getPunchListStatuses());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const reorderStatuses = createAsyncThunk(
  `${nameOfEntity}/reorder`,
  async (ids: number[], thunkAPI) => {
    const result = await service.reorder(ids);
    thunkAPI.dispatch(getPunchListStatuses());
    return result;
  },
  { serializeError: serializeGenericHandleError }
);

const initialState: EntityState<IPunchListItemStatus> = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultStatusValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

export const PunchListStatusSlice = createEntitySlice({
    name: nameOfEntity,
    initialState,
    reducers: {
      clearEntity: (state,) => {
        state.entity = defaultStatusValue;
      },
    },
    extraReducers(builder) {
        builder
          .addMatcher(isFulfilled(getPunchListStatuses), (state, action) => {
            const { data, headers } = action.payload;
            return {
              ...state,
              loading: false,
              entities: data,
              totalItems: getTotalItems(headers),
            };
          })
          .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
            state.updating = false;
            state.loading = false;
            state.updateSuccess = true;
            state.entity = action.payload as IPunchListItemStatus;
          })
          .addMatcher(isPending(createEntity, updateEntity), state => {
            state.errorMessage = null;
            state.updateSuccess = false;
            state.updating = true;
          })
          .addMatcher(isPending(getPunchListStatuses), state => {
            state.errorMessage = null;
            state.updateSuccess = false;
            state.loading = true;
          })
      }
  });
  
  // Reducer
  export default PunchListStatusSlice.reducer;
  