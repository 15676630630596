import { useEffect, useState } from 'react';
import { TableCustomized } from '@components/TableCustomized/TableCustomized'
import { Col, Divider, Row, theme } from 'antd';
import { useAppDispatch, useAppSelector } from '@store/store';
import { EmptyCellTable } from '@components/Utils/EmptyCellTable';
import { ISite } from '@models/site.model';
import { formatDatePipe } from '@shared/util/date-utils';
import { useNavigate, useParams } from 'react-router-dom';
import { clearEntity, getEntity } from "@store/slices/location";
import { useTranslation } from 'react-i18next';
import { CustomButtonText } from '@components/Utils/CustomButtonText';
import { ChevronRightSvgIcon } from '@components/Icons/ChevronRightSvgIcon';
import { isArrayWithValues } from '@shared/util/array-util';
import { If } from '@components/Utils/Structural';

export interface ILocationSitesTableProps {
    tableCustomizedProps?: React.ComponentPropsWithoutRef<typeof TableCustomized>
}
export const LocationSitesTable = (props: ILocationSitesTableProps) => {

    const dispatch = useAppDispatch();
    
    const { id } = useParams<"id">();

    useEffect(() => {
        if (id) {
          dispatch(getEntity(id));
        }
        return () => { dispatch(clearEntity()) }
    }, [dispatch, id]);
    
    const { entity: locationEntity } = useAppSelector((state) => state.Location);

    return (
        <>  
            <If condition={(isArrayWithValues(locationEntity?.siteList))}>
              
                <div className='relative'>
                    <HeaderCard />
                    <BodyList />
                </div>
            </If>
            <If condition={!(isArrayWithValues(locationEntity?.siteList))}>
                <EmptyState />
            </If>
        </>
    )
}


const HeaderCard = () => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    // Header Table Styles Variables
    const {
      token: { fontSizeHeading5, colorTextSecondary, colorFillSecondary, colorBgContainer },
    } = theme.useToken();

    return (
      <div className="block sticky top-0" style={{ background: colorBgContainer, zIndex: 10 }}>
        <Row justify={'space-between'} align="middle" style={{ background: colorBgContainer, paddingTop: 10 }}>
          <Col className="pt-3 pb-3">
            <span className="font-semibold pl-15" style={{ fontSize: fontSizeHeading5, color: colorTextSecondary }}>
              {t('generic.sites')}
            </span>
          </Col>
          <Col>
            <CustomButtonText onClick={() => navigate('create-site')}>{`${t('generic.add')} ${t('generic.site')}`}</CustomButtonText>
          </Col>
        </Row>
        <Divider className="mt-8 mb-8" style={{ borderColor: colorFillSecondary }} />
      </div>
    );
    
}

const EmptyState = () => {
    const { token: { colorFillTertiary, colorTextQuaternary } } = theme.useToken();
    const { t } = useTranslation();
    return (
        <div className='grid h-full' style={{ gridTemplateColumns: "1fr", gridTemplateRows: "min-content 1fr" }}>
            <HeaderCard />
            <div className='h-full w-full flex flex-row items-center justify-center' style={{ background: colorFillTertiary,  border: `1px solid ${colorFillTertiary}`, borderRadius: 8 }} >
                <span style={{ color: colorTextQuaternary}}>
                    No { t("generic.sites") } yet
                </span>
            </div>
        </div>
    )
}

const BodyList = () => {
    const { entity: locationEntity } = useAppSelector((state) => state.Location);
    return (
        <div className="grid" style={{ gridTemplateColumns: "1fr", gridTemplateRows: "max-content" }}>         
            <div>
                {
                    locationEntity?.siteList?.map((site: ISite) => {
                        return (
                            <BodyListItem site={site} />
                        )
                    })
                }
            </div>
            {/* <Divider /> */}
        </div>
    )
}

const BodyListItem = ({ site }: { site: ISite }) => {

    const navigate = useNavigate();

    const { token: { colorFillTertiary, colorFillQuaternary, colorPrimaryBorder, colorPrimary, fontSizeLG, colorTextTertiary } } = theme.useToken();

    const [onActive, setOnActive] = useState<boolean>(false);

    const onClickRow =  (event: any) => { 
        const urlToNavigate = `sites/${site?.id}`;
        event.metaKey ? window.open(`${window.location.href}/${urlToNavigate}`) : navigate(`${urlToNavigate}`) 
    }

    return (
        <>
            <div 
                onClick={onClickRow}
                className="pt-3 pb-3 mb-4"
                onMouseEnter={() => setOnActive(true)}
                onMouseLeave={() => setOnActive(false)}
                style={{ 
                    border: `1px solid ${ onActive ? colorFillTertiary : "transparent"}`,
                    backgroundColor: onActive ? colorFillQuaternary : "transparent",
                    borderRadius: "8px",
                    cursor: onActive? "pointer" : "default"
                }}
            >
                <Row className='items-center'>
                    <Col flex="none" style={{ width: 30 }}>
                        <ChevronRightSvgIcon className="ml-15 mr-10" style={{ color: onActive ? colorPrimaryBorder: colorPrimary }}/>
                    </Col>
                    <Col flex="auto">
                        <span className="font-semibold" style={{ 
                            color: onActive ? colorPrimary : colorTextTertiary,
                            fontSize: fontSizeLG 
                        }}>
                            { site?.name || <EmptyCellTable /> }
                        </span>
                    </Col>
                    <Col flex="none" style={{ width: 100 }}>
                        <span 
                            className='pr-5'
                            style={{
                                color: onActive ? colorPrimary : colorTextTertiary
                            }}
                        >
                            { (site?.openDate && formatDatePipe(site?.openDate)) || <EmptyCellTable /> }
                        </span>
                    </Col>
                    <Col flex="none" style={{ width: 150 }}>
                        <span style={{
                            color: onActive ? colorPrimary : colorTextTertiary
                        }}>
                            { site?.status?.description || <EmptyCellTable /> }
                        </span>
                    </Col>
                </Row>
            </div>
        </>
    )
}