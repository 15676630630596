import { useEffect } from 'react'
import { Modal, ModalFuncProps } from 'antd';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelector } from '../../store/store';


export const DisabledAccountNotification = () => {

    const dispatch = useDispatch<AppDispatch>();

    const configNotification = useAppSelector(state => state.DisabledAccountNotification);

    const [modal, contextHolder, ] = Modal.useModal();

    const openNotification = (settings: Partial<ModalFuncProps>) => {
        Modal.destroyAll();
        modal.error({
            title: 'Your account is not enabled',
            content: 'Please contact an administrator',
            okText: "logout",
            ...settings
        });
    };

    useEffect(() => {
        const { readyToLaunch, open } = configNotification;
        if(readyToLaunch && !open){
            openNotification(configNotification.settings);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, configNotification])

    return (
        <>{contextHolder}</>
    )
}
