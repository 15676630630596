import { createSlice, isFulfilled } from '@reduxjs/toolkit';
import { IBudgetTemplate, IBudgetTemplateExtended, IBudgetTemplateSave, defaultValue } from '@models/budget-template.model';
import { serializeAxiosError } from '../reducer.util';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { budgetTemplateService } from '@services/budget-template.service';
import { IBudgetTemplateLineItem } from '@models/budget-template-line-item.model';
import { asyncLaunchNotification } from './notification';

export const getEntitiesExtended = createAsyncThunk(
  'budget-template-extended/fetch_entity_list',
  async (search: String) => {
    return await budgetTemplateService.getAllExtended(search);
  },
  { serializeError: serializeAxiosError }
);

export const getTemplateLineItems = createAsyncThunk(
  'budget-template-extended/fetch_template_line_items',
  async (templateId: string | number, thunkAPI) => {
    return await budgetTemplateService.getAllLineItems(templateId);
  },
  { serializeError: serializeAxiosError }
);

export const getTemplateById = createAsyncThunk(
  'budget-template-extended/fetch_entity_by_id',
  async (budgetTemplateId: string, thunkAPI) => {
    const budgetTemplate = await budgetTemplateService.getTemplateById(budgetTemplateId);
    return budgetTemplate;
  },
  { serializeError: serializeAxiosError }
);

export const saveBudgetTemplateComplete = createAsyncThunk(
  'budget-template-extended/save_complete',
  async (template: IBudgetTemplateSave, thunkAPI) => {
    const response = await budgetTemplateService.saveTemplateComplete(template);
    thunkAPI.dispatch(asyncLaunchNotification({
      type: "success",
      config: {
        message: `Budget Template Saved`,
        description: `Budget saved successfully`
      }
    }));

    if (response?.data?.id) {
      thunkAPI.dispatch(getTemplateById(`${response?.data?.id}`))
      thunkAPI.dispatch(getTemplateLineItems(response?.data?.id));
    }

    return response;
  },
  { serializeError: serializeAxiosError }
);

interface IInitialState {
  loading: boolean;
  errorMessage: string | null;
  entities: IBudgetTemplateExtended[];
  lineItemList: IBudgetTemplateLineItem[],
  entity: IBudgetTemplateExtended,
  updating: boolean;
  totalItems: number;
  updateSuccess: boolean;
}

const initialState: IInitialState = {
  loading: false,
  errorMessage: null,
  entities: [],
  lineItemList: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export const slice = createSlice({
  name: 'budget_templates_extended',
  initialState,
  reducers: {
    clearEntity: state => {
      state.entity = defaultValue;
    },
  },
  extraReducers(builder) {
    
    //  getTemplatesDeliverables
    builder.addCase(getTemplateLineItems.fulfilled, (state, action) => {
      state.lineItemList = action.payload.data;
    })

    .addMatcher(isFulfilled(getTemplateById), (state, action) => {
      state.loading = false;
      state.entity = action.payload.data as IBudgetTemplate;
    })

    .addMatcher(isFulfilled(getEntitiesExtended), (state, action) => {
      const { data } = action.payload;

      return {
        ...state,
        loading: false,
        entities: data,
        totalItems: data.length,
      };
    });
  },
});

export const { clearEntity } = slice.actions;

// Reducer
export default slice.reducer;
