import { http } from '../http/axios.instance';
import { ILinkProjectRequestPayload, IProcoreProject, IUnLinkProjectRequestPayload } from '@models/procore-project.model';

const path = "procore"

export const projectProjectsRepository = {
  getProcoreProjects: async () => {

    const requestUrl = `${path}/projects`;
    const promiseGet = await http.get<IProcoreProject[]>(requestUrl);
    return promiseGet.data;
  },
  linkProcoreProjectToAPP: async (payload: ILinkProjectRequestPayload) => {
    const { id: projectIdAPP } = payload;

    const requestUrl = `projects-extended/${projectIdAPP}/update-procore-id`;
    const promiseGet = await http.patch(requestUrl, payload);
    return promiseGet.data;
  },
  unlinkProcoreProjectToAPP: async (payload: IUnLinkProjectRequestPayload) => {
    const { id: projectIdAPP } = payload;

    const requestUrl = `projects-extended/${projectIdAPP}/update-procore-id`;
    const promiseGet = await http.patch(requestUrl, payload);
    return promiseGet.data;
  }
}