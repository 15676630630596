
import { IRfiDistribution } from '@models/rfi-distribution.model';
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { rfiDistributionService } from '@services/rfi-distribution.service';
import { serializeAxiosError } from "../reducer.util";

export interface ICreateDistributionsArgs {
    rfiId: string | number;
    distributions: IRfiDistribution[];
    onSuccess: () => void;
}

export const getEntities = createAsyncThunk(
    'rfiDistribution/fetch_entity_list',
    async (rfiId: string | number) => {
        return rfiDistributionService.getRfiDistributions(rfiId);
    },
    { serializeError: serializeAxiosError }
);

export const createDistributions = createAsyncThunk(
    'rfiDistribution/create_many',
    async ({ rfiId, distributions, onSuccess }: ICreateDistributionsArgs) => {
        const result = await rfiDistributionService.createDistributions(rfiId, distributions);
        onSuccess();
        return result;
    },
    { serializeError: serializeAxiosError }
);

interface IRfiDistributionState {
    loading: boolean;
    errorMessage: string | null;
    updating: boolean;
    updateSuccess: boolean;
    entities: ReadonlyArray<IRfiDistribution>;
}

const initialState: IRfiDistributionState = {
    loading: false,
    errorMessage: null,
    updating: false,
    updateSuccess: false,
    entities: []
};

export const RfiDistributionSlice = createSlice({
    name: 'rfiDistributions',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const { data } = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data
                };
            })
            .addMatcher(isPending(getEntities), state => {
                state.errorMessage = null;
                state.loading = true;
            })
            .addMatcher(isFulfilled(createDistributions), (state, action) => {
                state.updating = false;
                state.loading = false;
                state.updateSuccess = true;
            })
            .addMatcher(isPending(createDistributions), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.updating = true;
            });
    }
});

// Reducer
export default RfiDistributionSlice.reducer;

