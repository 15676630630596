import { costSubcategoryRepository } from "@infrastructure/repositories/cost-sub-category.repository";

export const costSubCategoryService = {
    create: costSubcategoryRepository.create,
    update: costSubcategoryRepository.update,
    reorder: costSubcategoryRepository.reorder,
    getAllCostSubcategories: costSubcategoryRepository.getAll,
    getCostSubcategories: costSubcategoryRepository.getCostSubcategories,
    getActiveCostSubcategories: costSubcategoryRepository.getActiveCostSubcategories,
    getActiveCostSubcategoriesByCategoriesIds: costSubcategoryRepository.getActiveCostSubcategoriesByCategoriesIds
}