import { countOpenRfi } from '@store/slices/rfi';
import { useAppDispatch, useAppSelector } from '@store/store';
import { Button, Card, Checkbox, Col, Divider, Row, Space, theme } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export interface IRfiListBarProps {
    openFilterChange: (checked: boolean) => void,
}

export const RfiListBar = (props: IRfiListBarProps) => {

    const { projectId } = useParams<"projectId">();

    const { token: { colorTextTertiary } } = theme.useToken();

    const { openEntitiesQuantity: openRfiQty } = useAppSelector((state) => state.Rfi);
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        if (projectId) {
            dispatch(
                countOpenRfi(projectId)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    const handleOpenRfiFilterChange = (event: CheckboxChangeEvent) => {
        props.openFilterChange(event.target.checked);
    }

    return (
        <Card
            bodyStyle={{ paddingLeft: 12, paddingRight: 12, paddingTop: 8, paddingBottom: 8 }}
            style={{ borderRadius: '4px' }}>
            <Row className="w-full">
                <Col span={12} className='text-left flex flex-row items-center'>
                    <Space className='font-bold pr-20'>RFI Log</Space>
                    <Divider type="vertical" style={{height: '70%'}}></Divider>
                    <Space className='pl-20 pr-20'>
                        <Checkbox onChange={handleOpenRfiFilterChange}>
                            <span>Show Open RFIs</span>
                        </Checkbox>
                    </Space>
                </Col>
                <Col span={12} className='text-right'>
                    <Space className='pl-20 pr-20' style={{ color: colorTextTertiary }}>{openRfiQty} Open RFIs</Space>
                    <Space className='pl-20'>
                        <Button type="primary" onClick={() => navigate(`${pathname}/new`)}>
                            + Create new
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Card>
    );
}