import { Grid, Drawer } from "antd";
import React from "react";
const { useBreakpoint } = Grid;

interface ICustomDrawerProps extends React.ComponentPropsWithRef<typeof Drawer> {};

const MOBILE_SETTINGS: Pick<ICustomDrawerProps, "placement" | "height"  >= {
    placement: "bottom",
    height: "95%"
}

export const CustomDrawer = ({ children, ...restProps }: ICustomDrawerProps) => {

    const { xs } = useBreakpoint()

    return (
        <Drawer 
            {  ...xs ? {...MOBILE_SETTINGS } : {}}
            {...restProps} 
        >
            {children}
        </Drawer>
    )
}