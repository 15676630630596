import { ICustomField, ICustomFieldExtended, ICustomFieldExtendedUI } from './custom-field.model';
import { CustomFieldGroupTypeEnum } from './enumerations/custom-field-group-type-enum.model';
export interface ICustomFieldGroup {
  id?: number;
  name?: string | null;
  referenceId?: string | number | null;
  referenceType?: CustomFieldGroupTypeEnum | null;
  order?: number | null;
  customFieldsChildren?: ICustomField[]
}

export interface ICustomFieldGroupExtendedCount {
  id?: number;
  name?: string | null;
  referenceType?: CustomFieldGroupTypeEnum | null;
  order?: number | null;
  fieldCount?: number | null;
}

export interface ICustomFieldGroupExtended  extends Omit<ICustomFieldGroup, "customFieldsChildren"> {
  customFieldsChildren?: ICustomFieldExtended[]
}

export interface ICustomFieldGroupExtendedUI  extends ICustomFieldGroupExtended {
  customFieldsChildren?: ICustomFieldExtendedUI[]
}


export const defaultValue: Readonly<ICustomFieldGroup> = {};
