import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { MentionNode } from "./Nodes/MentionNode";

import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import MentionsPlugin from "./Plugins/MentionsPlugin";

import type { EditorState } from 'lexical';
import { isArrayWithValues } from '@shared/util/array-util';

const theme = {
    ltr: "ltr",
    rtl: "rtl",
    placeholder: "editor-placeholder",
    paragraph: "editor-paragraph",
    quote: "editor-quote",
    heading: {
        h1: "editor-heading-h1",
        h2: "editor-heading-h2",
        h3: "editor-heading-h3",
        h4: "editor-heading-h4",
        h5: "editor-heading-h5"
    },
    list: {
        nested: {
            listitem: "editor-nested-listitem"
        },
        ol: "editor-list-ol",
        ul: "editor-list-ul",
        listitem: "editor-listitem"
    },
    image: "editor-image",
    link: "editor-link",
    text: {
        bold: "editor-text-bold",
        italic: "editor-text-italic",
        overflowed: "editor-text-overflowed",
        hashtag: "editor-text-hashtag",
        underline: "editor-text-underline",
        strikethrough: "editor-text-strikethrough",
        underlineStrikethrough: "editor-text-underlineStrikethrough",
        code: "editor-text-code"
    },
    code: "editor-code",
    codeHighlight: {
        atrule: "editor-tokenAttr",
        attr: "editor-tokenAttr",
        boolean: "editor-tokenProperty",
        builtin: "editor-tokenSelector",
        cdata: "editor-tokenComment",
        char: "editor-tokenSelector",
        class: "editor-tokenFunction",
        "class-name": "editor-tokenFunction",
        comment: "editor-tokenComment",
        constant: "editor-tokenProperty",
        deleted: "editor-tokenProperty",
        doctype: "editor-tokenComment",
        entity: "editor-tokenOperator",
        function: "editor-tokenFunction",
        important: "editor-tokenVariable",
        keyword: "editor-tokenAttr",
        namespace: "editor-tokenVariable",
        number: "editor-tokenProperty",
        operator: "editor-tokenOperator",
        prolog: "editor-tokenComment",
        property: "editor-tokenProperty",
        punctuation: "editor-tokenPunctuation",
        regex: "editor-tokenVariable",
        selector: "editor-tokenSelector",
        string: "editor-tokenSelector",
        symbol: "editor-tokenProperty",
        tag: "editor-tokenProperty",
        url: "editor-tokenOperator",
        variable: "editor-tokenVariable"
    }
}

export interface IViewerProps {
    payload: string
}

export const ViewerRichText = (props: IViewerProps) => {

    const { payload } = props;

    const isValidPayload = () => {
        try {
            const payloadParsed = JSON.parse(payload)
            return Boolean(isArrayWithValues(payloadParsed?.root?.children))
        } catch(e) {
            return false; 
        }
    }

    const onLexicalError = (error: any) => { console.error(error); }

    const Placeholder = () => { return <div className="editor-placeholder"></div>; }

    const onChange = (editorState: EditorState) =>  {}

    const initialConfig = {
        namespace: 'CommentViewer', 
        editable: false,
        theme,
        onError: onLexicalError,
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode,
            MentionNode
        ],
        editorState: payload,
    };
    
    return (
        <>
            { isValidPayload() && (
                <LexicalComposer initialConfig={{ ...initialConfig }} >
                    <RichTextPlugin
                        contentEditable={<ContentEditable className='editor-input-viewer' />}  
                        placeholder={Placeholder}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <MentionsPlugin />
                    <OnChangePlugin onChange={onChange} />
                    <HistoryPlugin />
                    <ListPlugin />
                </LexicalComposer>
                ) 
            }
        </>
    )
}


