
import { DeleteOutlined, EyeOutlined, RetweetOutlined } from '@ant-design/icons';
import { Button, Divider, Table, Tag, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';

import { Callable } from '@faker-js/faker/utils/types';
import { IAttachment } from '@models/attachment.model';
import { DATE_FORMAT_DEFAULT } from '@shared/util/date-utils';
import { DownloadAttachmentButton } from './DownloadAttachmentButton';
import { getExtensionOfFileName, isAnValidImage } from '@shared/util/document-utils';
import { isArrayWithValues } from '@shared/util/array-util';
import { DerivativesEnum } from '@models/enumerations/derivatives-enum.model';
import { EmptyCellTable } from '@components/Utils/EmptyCellTable';

export interface INestedTableProps {
  attachmentRecord: IAttachment
  onViewAction: Callable,
  onDeleteAction: Callable,
  showModalForNewRevision: Callable,
}

export default function NestedTable(props: INestedTableProps) {

  const { attachmentRecord, onViewAction, onDeleteAction, showModalForNewRevision } = props;

  const isAnImage = (record: IAttachment) => {
    const filename = record?.fileName
    const extension = filename ? getExtensionOfFileName(filename) : "";
    return isAnValidImage(extension);
  }

  const hasPreviewOfImagen = (record: IAttachment) => {
    if (isAnImage(record)) { return true }
    const derivatives = isArrayWithValues(record?.derivatives) ? record?.derivatives : [];
    return derivatives?.some((img) => img.derivativeType === DerivativesEnum.PREVIEW);
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'fileName',
      key: 'fileName',
      ellipsis: {
        showTitle: false,
      },
      render: (value: string, record: any) => {
        return value
          ? (<Tooltip placement="top" title={value}><Typography.Text ellipsis={true}>{value}</Typography.Text></Tooltip>)
          : <EmptyCellTable />
      }

    },
    {
      title: 'Version',
      dataIndex: 'key',
      key: 'key',
      width: 60,
      render: (value: number, record: any) => attachmentRecord.versions && <Tag>v{attachmentRecord.versions?.length - value - 1}</Tag>
    },
    {
      title: 'Creator',
      dataIndex: 'createdBy',
      width: 100,
      key: 'createdBy2',
      ellipsis: {
        showTitle: false,
      },
      render: (value: string, record: any) => < Tooltip placement="top" title={record?.createdByFirstName + " " + record?.createdByLastName}> <Typography.Text style={{ width: '100px' }} ellipsis={true}>{record?.createdByFirstName + " " + record?.createdByLastName}</Typography.Text> </Tooltip >
    },
    {
      title: 'Upload Date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      ellipsis: {
        showTitle: false,
      },
      width: 110,
      render: (value: string, record: any) => {
        if (value) {
          return dayjs(value)?.format(DATE_FORMAT_DEFAULT)
        }
        return <></>
      }
    },

    {
      key: 'action',
      width: 150,
      render: (_: any, revisionRecord: IAttachment) => (
        <>
          <Tooltip placement="top" title={"Upload revision"}>
            <Button size='small' type='link' onClick={() => showModalForNewRevision(attachmentRecord.id)} > <RetweetOutlined style={{ marginInlineStart: 0 }} /></Button >
          </Tooltip>
          <Divider type="vertical" className="ml-0 mr-0" />
          <Tooltip placement="top" title={"Preview"}>
            <Button size='small' disabled={!hasPreviewOfImagen(revisionRecord)} type='link' onClick={() => onViewAction(revisionRecord, revisionRecord.versions)} ><EyeOutlined /></Button >
          </Tooltip>
          <Divider type="vertical" className="ml-0 mr-0" />
          {revisionRecord.fileName && <DownloadAttachmentButton attachmentId={String(revisionRecord.id)} fileName={revisionRecord.fileName} />}
          <Divider type="vertical" className="ml-0 mr-0" />
          <Tooltip placement="top" title={"Delete"}>
            <Button disabled={revisionRecord.versions != null} size='small' type='link' onClick={() => onDeleteAction(revisionRecord)} ><DeleteOutlined /></Button>
          </Tooltip>
        </>
      ),
    },
  ]

  let versionsWithKeys = attachmentRecord.versions || []
  versionsWithKeys = versionsWithKeys.slice(1)

  versionsWithKeys = versionsWithKeys?.map((el, key) => { return { ...el, key } })

  return (
    <>
      <Table
        size='small'
        className='w-full'
        pagination={false}
        showHeader={false}
        dataSource={versionsWithKeys}
        columns={columns}
      />


    </>
  )
}