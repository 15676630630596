import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { DownOutlined, CheckOutlined, LoadingOutlined, WarningOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Dropdown, Button, Divider, theme, Typography, Tooltip, Row, Col, Tag } from 'antd';

import { getLatestByProjectId, getOneByProjectId } from '@store/slices/budget-extended';
import { useAppDispatch, useAppSelector } from '@store/store';
import { If } from '@components/Utils/Structural';
import { useEffect, useState } from 'react';
import { getAllByProjectId } from '@store/slices/budget-revision';
import { BudgetStatusEnum, IBudgetRevision } from '@models/budget.model';
import { formatDatePipe } from '@shared/util/date-utils';
const { Text } = Typography;

interface IProps {
  onExpandAll: () => void;
  onCollapseAll: () => void;
  onGenerateExcel: () => void;
  loadingExcel: boolean;
  onGeneratePdf: () => void;
  loadingPdf: boolean;
}

export const BudgetDetailHeader = ({ onExpandAll, onCollapseAll, onGenerateExcel, loadingExcel, onGeneratePdf, loadingPdf }: IProps) => {
  const { updating, errorMessage } = useAppSelector(state => state.BudgetExtended);
  const { entities: budgetRevisions } = useAppSelector(state => state.BudgetRevision);
  const { entity: budget } = useAppSelector(state => state.BudgetExtended);
  const [selectedVersion, setSelectedVersion] = useState<number | null>(null);
  const { projectId } = useParams();
  const {
    token: { colorFillSecondary, colorFillTertiary, colorFillQuaternary, colorTextTertiary, colorTextSecondary, colorPrimaryText, colorPrimaryTextHover },
  } = theme.useToken();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (projectId) dispatch(getAllByProjectId(projectId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);


  useEffect(() => {
    if (budget) {
      setSelectedVersion(budget.id!);
    }
  }, [budget]);

  useEffect(() => {
    if (budgetRevisions.length > 0 && !budgetRevisions.find(revision => revision.id === selectedVersion)) {
      setSelectedVersion(budgetRevisions[0].id!);
    }
  }, [budgetRevisions, selectedVersion]);

  const versionNumber = (version?: number) => {
    return (
      <div
        className="px-8 py-4"
        style={{ border: `1px solid ${colorFillSecondary}`, backgroundColor: colorFillTertiary, borderRadius: '0.25rem' }}
      >
        <span style={{ color: colorTextSecondary }}>{t('budget.v')}. {version || '--'}</span>
      </div>
    )
  }
  const menuItem = (revision: IBudgetRevision) => {
    return (
      <div className='mt-10 mb-10'>
        <Row>
          <Col>
            <Row>
              <Col>{versionNumber(revision.revisionNumber)}</Col>
            </Row>
            <Row>
              <Text className="mt-5" style={{
                color: revision.id === selectedVersion ? colorPrimaryTextHover : colorTextTertiary, fontWeight: "400",
              }}>
                {revision.status === BudgetStatusEnum.APPROVED && `${t('budget.approval.approvedOn')} ${formatDatePipe(revision.approvalDate)}`}
                {revision.status === BudgetStatusEnum.REJECTED && `${t('budget.approval.rejectedOn')} ${formatDatePipe(revision.approvalDate)}`}
                {revision.status === BudgetStatusEnum.DRAFT && t('budget.status.inProgress')}
              </Text>
            </Row>
          </Col>
          <Col className="flex items-center ml-20" >
            {
              revision.id === selectedVersion &&
              <Tag style={{ borderRadius: "4px", borderColor: "transparent" }}>
                <Text style={{ color: colorTextSecondary, fontWeight: "400" }}>
                  {t('budget.current')}
                </Text>
              </Tag>
            }
          </Col>
        </Row>
      </div>
    )
  }

  const versionItems = budgetRevisions.map((revision) => ({
    key: revision.id!,
    label: menuItem(revision),
    onClick: () => dispatch(getOneByProjectId({ projectId: projectId!, budgetId: revision.id! }))
  }));



  return (
    <div className="flex justify-between mb-16">
      <div
        className="flex items-center px-12 py-4"
        style={{
          border: `1px solid ${colorFillTertiary}`,
          backgroundColor: colorFillQuaternary,
          gap: '10px',
          borderRadius: '0.25rem',
        }}
      >
        <Text style={{ fontSize: '16px', fontWeight: 500, color: colorTextTertiary }}>
          {t('generic.project')} #{projectId} {t('budget.budget')}
        </Text>

        {versionNumber(budgetRevisions?.find(revision => revision?.id === selectedVersion)?.revisionNumber)}

        <Dropdown menu={{
          items: versionItems, selectable: true,
          defaultSelectedKeys: budget?.id ? [budget?.id.toString()] : undefined,
          selectedKeys: budget?.id ? [budget?.id.toString()] : undefined
        }}
        >
          <DownOutlined style={{ color: colorTextTertiary }} />
        </Dropdown>
      </div>
      <div className="flex items-center" style={{ gap: '1rem' }}>
        {errorMessage && <ErrorMessage />}
        {updating && <SavingChangesMessage />}
        {!updating && !errorMessage && <ChangesSavedMessage />}
        <div style={{ borderRadius: '1rem', border: `1px solid ${colorFillTertiary}`, backgroundColor: colorFillQuaternary }}>
          <Button type="link" style={{ color: colorPrimaryText, fontSize: '12px' }} onClick={onExpandAll}>
            {t('budget.expandAll')}
          </Button>
          <Divider type="vertical" />
          <Button type="link" style={{ color: colorPrimaryText, fontSize: '12px' }} onClick={onCollapseAll}>
            {t('budget.colapseAll')}
          </Button>
        </div>
        <div style={{ borderRadius: '1rem', border: `1px solid ${colorFillTertiary}`, backgroundColor: colorFillQuaternary }}>
          <Tooltip title={t('budget.exportToExcel')}>
            <Button type="link" onClick={onGenerateExcel} loading={loadingExcel}>
              <If condition={!loadingExcel}>
                <FileExcelOutlined style={{ fontSize: '20px' }} />
              </If>
            </Button>
          </Tooltip>
          <Tooltip title={t('budget.exportToPdf')}>
            <Button type="link" onClick={onGeneratePdf} loading={loadingPdf}>
              <If condition={!loadingPdf}>
                <FilePdfOutlined style={{ fontSize: '20px' }} />
              </If>
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

const ChangesSavedMessage = () => {
  const {
    token: { colorSuccessText },
  } = theme.useToken();
  const { t } = useTranslation();
  return (
    <div className="flex" style={{ gap: '0.5rem' }}>
      <span style={{ color: colorSuccessText, fontSize: '12px' }}>{t('budget.changesSaved')}</span>
      <CheckOutlined style={{ fontSize: '12px', color: colorSuccessText }} />
    </div>
  );
};

const SavingChangesMessage = () => {
  const {
    token: { colorTextTertiary },
  } = theme.useToken();
  const { t } = useTranslation();
  return (
    <div className="flex" style={{ gap: '0.5rem' }}>
      <span style={{ color: colorTextTertiary, fontSize: '12px' }}>{t('budget.saving')}</span>
      <LoadingOutlined style={{ fontSize: '12px', color: colorTextTertiary }} />
    </div>
  );
};

const ErrorMessage = () => {
  const { projectId } = useParams();
  const dispatch = useAppDispatch();

  const onClick = () => {
    if (!projectId) return;
    dispatch(getLatestByProjectId(Number(projectId)));
  };
  const {
    token: { colorTextTertiary, colorWarningBg, colorWarningBorder, colorWarningText },
  } = theme.useToken();
  const { t } = useTranslation();
  return (
    <div className="flex px-8 py-4" style={{ gap: '0.5rem', backgroundColor: colorWarningBg }}>
      <WarningOutlined style={{ fontSize: '12px', color: colorWarningBorder }} />
      <span style={{ color: colorTextTertiary, fontSize: '12px' }}>
        {t('budget.errorSaving')},{' '}
        <span style={{ color: colorWarningText, textDecoration: 'underline', cursor: 'pointer' }} onClick={onClick}>
          {t('budget.retry')}
        </span>
      </span>
    </div>
  );
};
