import { FeatureFlagsProvider } from '@providers/FeatureFlagsProvider';
import { LayoutPage } from '../components/Layout/LayoutPage'
import { AuthRequired } from '../routes/Util'
import { Outlet } from 'react-router-dom';
import { MicrosoftClarity } from '@components/Head/MicrosoftClarity';

export default function AppLayoutPage() {

  const Page = () => (
    <FeatureFlagsProvider>
      <LayoutPage>
        {/* MicrosoftClarity component is here and not at a higher level because it depends on the feature flags provider. */}
        <MicrosoftClarity />
        <Outlet />
      </LayoutPage>
    </FeatureFlagsProvider>
  );
  return <AuthRequired toComponent={<Page />} />;
}
