import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { isString } from 'lodash';
import { IViews } from './PhotoTabLayout';
import { If } from '@components/Utils/Structural';

export const NavigationMobilePhotoAlbum = () => {

    let [searchURLParams, setSearchURLParams] = useSearchParams();
    const [viewToShow, setViewToShow] = useState<IViews | null>(null);

    useEffect(() => {
        const view = searchURLParams.get("view");
        if (isString(view) && ["list","details"].includes(view!)) {
            setViewToShow(view as IViews)
        } else {
            setViewToShow(null);
        }
    }, [searchURLParams])

    const navigationList = () => {
        setSearchURLParams((prev) => {
            prev.set("view", "list");
            return prev;
        })
    }

    return (
        <span>
            <If condition={Boolean(viewToShow === "details")}>
                <span onClick={navigationList}>List Albums</span>
            </If>
        </span>
    )
}