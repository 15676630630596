import { IBudgetApprovalRangeSetting } from "./budget-approval-range-setting.model";
import { IRole } from "./role.model";
import { IUser } from "./user.model";

export interface IBudgetApproverSetting {
  id?: number;
  order?: number | null;
  budgetApprovalRangeSetting?: IBudgetApprovalRangeSetting | null;
  role?: IRole | null;
  user?: IUser | null;
}

export interface IBudgetApproverSettingExtended {
  id?: number;
  guid?: string;
  role?: IRole | null;
  user?: IUser | null;
  order?: number | null;
}

export const defaultValue: Readonly<IBudgetApproverSetting> = {};
