import { referenceTypeEnum } from "./enumerations/reference-type-enum.model";

export interface IAclPermission {
  id?: number;
  referenceType?: referenceTypeEnum | null;
  referenceId?: number | null;
  aclTargetId?: number | null;
  referenceName?: any;
}

export const defaultValue: Readonly<IAclPermission> = {};
