export enum ChangeOrdersStatusIds {
    PENDING_PUBLISHING = 1,
    CANCELED = 2,
    PENDING_INITIAL_REVIEW = 3,
    PENDING_ESTIMATE_RESUBMISSION = 4,
    PENDING_DETAIL_RESUBMISSION = 5,
    PENDING_DETAIL_SUBMISSION = 6,
    PENDING_FINAL_REVIEW = 7,
    REJECTED = 8,
    APPROVED = 9,
}

export enum ChangeOrdersStatusDescription {
    PENDING_PUBLISHING = 'Pending Publishing',
    PENDING_INITIAL_REVIEW = 'Pending Initial Review',
    PENDING_ESTIMATE_RESUBMISSION = 'Pending Estimate Resubmission',
    PENDING_DETAIL_SUBMISSION = 'Pending Detail Submission',
    PENDING_FINAL_REVIEW = 'Pending Final Review',
    APPROVED = 'Approved',
    PENDING_DETAIL_RESUBMISSION = 'Pending Detail Resubmission',
    REJECTED = 'Rejected',
    CANCELED = 'Canceled',
}