import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { openGraphService } from '@services/open-graph.service.';
import { serializeGenericHandleError } from '@store/thunk.util';

export interface IOpenGraph {
  title: string;
  description: string;
  image: string;
  favIcon: string;
  touchIcon: string;
}

interface IStateOpenGraph {
  entity: IOpenGraph;
  loading: boolean;
  errorMessage: any;
}

const initialState: IStateOpenGraph = {
  entity: {
    title: '',
    description: '',
    image: '',
    favIcon: '',
    touchIcon: '',
  },
  loading: false,
  errorMessage: null,
};

const sliceName: string = 'openGraph';

export const getOpenGraph = createAsyncThunk(
  `${sliceName}/getOpenGraph`,
  async (payload: any, thunkAPI) => {
    const data = await openGraphService.getOpenGraph(payload);
    return data;
  },
  { serializeError: serializeGenericHandleError }
);

export const openGraphSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addMatcher(isPending(getOpenGraph), state => {
        state.loading = true;
        state.errorMessage = null;
        state.entity = initialState.entity;
      })
      .addMatcher(isFulfilled(getOpenGraph), (state, action) => {
        state.loading = false;
        state.entity = action.payload;
        state.errorMessage = null;
      })
      .addMatcher(
        action => action.type.endsWith('/rejected'),
        (state, action) => {
          state.loading = false;
          state.errorMessage = action.error.message;
        }
      );
  },
});

export const { reset } = openGraphSlice.actions;
export default openGraphSlice.reducer;
