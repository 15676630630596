/* eslint-disable react-hooks/exhaustive-deps */
import { DATE_FORMAT_DEFAULT, generateUniqueStringWithTimestamp, removeTimeZone } from "@shared/util/date-utils";
import { Button, Col, DatePicker, Form, Input, Row, Space, theme } from "antd";
import { useEffect } from "react";
import { DescriptionAlbumInput } from "./DescriptionAlbumInput";
import { useTranslation } from "react-i18next";
import { CustomDrawer } from "@components/Utils/CustomDrawer";
import { useErrorAnimation } from "@HOOKs/UseErrorAnimation";
import { createEntity, getEntityById, getPhotoAlbumEntities, updateEntity } from "@store/slices/photo-album";
import { useAppDispatch } from "@store/store";
import { IPhotoAlbum } from "@models/photo-album.model";
import { useSearchParams } from "react-router-dom";
import { asyncLaunchNotification } from "@store/slices/notification";
import dayjs from "dayjs";
import { formatDatePipe } from '@shared/util/date-utils';
import { usePhotoAlbumContextProvider } from "./PhotoAlbumProvider";

interface IManagerAlbumDrawerProps {
    mode: "EDITION" | "CREATION"
    open: boolean;
    toogle: () => void;
    album?: IPhotoAlbum
}

export const ManagerAlbumDrawer = (props: IManagerAlbumDrawerProps) => {

    const { 
        open, 
        toogle, 
        mode = "CREATION", 
        album = {} as IPhotoAlbum
    } = props;

    const { token: { colorTextTertiary }} = theme.useToken();

    const { t } = useTranslation();

    const [form] = Form.useForm();

    const [animateError, playAnimationOfError] = useErrorAnimation();

    const onSubmit = () => {
        form.validateFields(["name", "albumDate"])
            .then(() => {
                onFinish(form.getFieldsValue(["name", "albumDate", "description"]))
            })
            .catch(onFinishFailed);
    };

    const onFinishFailed = () => { playAnimationOfError(); }

    useEffect(() => {
        if(mode === "EDITION" && album) {
            album?.name && form.setFieldValue("name", album.name);
            album?.albumDate && form.setFieldValue("albumDate", dayjs(album.albumDate));
            album?.description && form.setFieldValue("description", album.description);
        } 
    }, [mode, album, form])

    useEffect(() => {
        if(mode === "CREATION" && form) {
            form.setFieldValue("name", `Album ${formatDatePipe(dayjs().toISOString())}`);
            form.setFieldValue("albumDate", dayjs());
        } 
    }, [mode, form])

    const dispatch = useAppDispatch();

    const { referenceId, referenceType } = usePhotoAlbumContextProvider();

    let [, setURLSearchParams] = useSearchParams();

    const onFinish = (entity: any) => {
         if(!referenceId) return;

        const payload: Omit<IPhotoAlbum, 'id' > = {
            referenceId: +referenceId,
            referenceType: referenceType,
            ...entity
        }

        if (mode === "CREATION") {
            dispatch(createEntity(payload)).unwrap()
                .then((albumCreated) => {
                    
                    // Get Again All Albums
                    dispatch(getPhotoAlbumEntities({
                        referenceId: referenceId,
                        referenceType: referenceType
                    }));

                    dispatch(getEntityById(String(albumCreated.id))); 

                    setURLSearchParams((prev) => {
                        prev.set("albumSelected", String(albumCreated.id))
                        return prev;
                    });

                    // Close Drawer
                    toogle();

                    // Notify Success Event
                    dispatch(asyncLaunchNotification({
                        type: "success",
                        config: {
                            message: "Album created",
                            description: "Now you can upload photos"
                        }             
                    }));

                })
        }

        if (mode === "EDITION") {
            const onSuccess = () => {
                
                // Close Drawer.
                toogle();

                // Launch Notification.
                dispatch(asyncLaunchNotification({
                    type: "success",
                    config: {
                        message: "Changes Saved Successfully",
                        description: "",
                        className: "singleNotification"
                    }             
                }));
            }

            const photoAlbum = {
                ...album, 
                ...entity,
                albumDate: removeTimeZone(entity?.albumDate) || entity?.albumDate
            };
            dispatch(updateEntity({entity: photoAlbum, onSuccess}))
        }
        
        
    }

    const LabelDescription = (
        <span>Description <span style={{ color: colorTextTertiary }}>(Optional)</span></span>
    )
    
    return (
        <CustomDrawer
            title={`${mode === "EDITION" ? t("generic.edit") : t("generic.add") } Album`}
            width={580}
            onClose={toogle}
            open={open}
            closable={true}
            styles={{ body: { paddingBottom: 80 }}}
            extra={
                <Space>
                    <Button type="primary" ghost onClick={toogle}>
                        {t("generic.cancel")}
                    </Button>
                    <Button type="primary" onClick={onSubmit} 
                    >
                        {mode === "EDITION" ? t("generic.save") : t("generic.create")}
                    </Button>
                </Space>
            }
        >

            <Form
                layout="vertical"
                form={form}
                onFinishFailed={onFinishFailed}
                validateTrigger={false}
                className={`${animateError && "error-animation"}`}
            >

                <Row gutter={16} className="mt-20">
                    <Col span={24}>
                        <Form.Item 
                            name="name" 
                            label={`Album Name`}
                            rules={[
                                { required: true, message: `Please enter a Album Name` },
                            ]}
                        >
                            <Input placeholder={`Please enter Album Name`} allowClear />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16} className="mt-20">
                    <Col span={24}>
                        <Form.Item 
                            name="albumDate" 
                            label={"Date"}
                            rules={[
                                { required: true, message: `Please enter a Album Date` },
                            ]}
                        >
                            <DatePicker
                                className="w-full"
                                format={DATE_FORMAT_DEFAULT}
                                allowClear={true}
                                placeholder={`Please select Album Date`}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                                        
                <Row gutter={16} className="mt-20">
                    <Col span={24}>
                        <Form.Item 
                            key={mode === "EDITION" ? album.id : generateUniqueStringWithTimestamp()}
                            name="description" 
                            label={LabelDescription}
                        >
                           { mode === "EDITION" && album?.description ?  <DescriptionAlbumInput initialValue={album?.description} /> : <DescriptionAlbumInput /> }
                        </Form.Item>
                    </Col>
                </Row>
                
            </Form>

        </CustomDrawer>
    )
}
