import { ISite } from '../../models/site.model';
import { http } from '../http/axios.instance';
import { StringORNumber } from './utils.repository';

const path = 'sites';

export const sitesRepository = {
  getById: async (id: StringORNumber) => {

    const requestUrl = `${path}/${id}`;
    const promiseGetByID = await http.get<ISite>(requestUrl);
    return promiseGetByID;
  },
  create: async (entity: ISite): Promise<ISite> => {

    const requestUrl = `${path}`;
    const promiseCreate = await http.post<ISite>(requestUrl, entity);
    return promiseCreate.data;
  },
  update: async (id: string | number, entity: ISite): Promise<ISite> => {

    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.put<ISite>(requestUrl, entity);
    return promiseUpdate.data;
  },
  delete: async (id: string | number) => {

    const requestUrl = `${path}/${id}`;
    const promiseDelete = await http.delete<ISite>(requestUrl);
    return promiseDelete;
  },
}