
import { IChangeOrderStatus } from '@models/change-order-status.model';
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { changeOrderStatusService } from '@services/change-order-status.service';
import { serializeAxiosError } from "../reducer.util";

export const getEntities = createAsyncThunk(
    'changeOrderStatus/fetch_entity_list',
    async () => {
        return changeOrderStatusService.getChangeOrderStatuses();
    },
    { serializeError: serializeAxiosError }
);

interface IChangeOrdersStatusState {
    loading: boolean;
    errorMessage: string | null;
    entities: ReadonlyArray<IChangeOrderStatus>;
}

const initialState: IChangeOrdersStatusState = {
    loading: false,
    errorMessage: null,
    entities: []
};

export const ChangeOrderStatusSlice = createSlice({
    name: 'changeOrderStatus',
    initialState,
    reducers: {
        reset: (state,) => {
            return initialState;
        },
    },
    extraReducers(builder) {
        builder
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const { data } = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data,
                };
            })
            .addMatcher(isPending(getEntities), state => {
                state.errorMessage = null;
                state.loading = true;
            })
    }
});

export const { reset } = ChangeOrderStatusSlice.actions;

// Reducer
export default ChangeOrderStatusSlice.reducer;

