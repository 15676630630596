import { useRollbar } from '@rollbar/react';
import { Button } from "antd";
import { useAuth } from "@providers/AuthProvider";

import { BugOutlined } from '@ant-design/icons';
import { configuration } from '../../environments/env';

export const RollbarTestComponentError = () => {

    const rollbar = useRollbar();

    const { user: userLogged } = useAuth();

    const launchError = () => {
        rollbar.error(
            '[FE] - Testing - New Error in RollbarTestComponentError in launchError Method', 
            "This is an manual Error Example", 
            { user: { user: userLogged }, appVersion: configuration?.VERSION }
        );
    }

    return (
        <>
            { userLogged && userLogged?.authorities?.includes("ROLE_ADMIN") && <Button type="primary" danger onClick={launchError} >Launch Error <BugOutlined /></Button> }    
        </>
    )
}
