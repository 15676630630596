import AttachmentsList from "@components/Attachment/AttachmentsList";
import CommentsList from "@components/Comment/CommentsList";
import { TabContainer } from "@components/Utils/TabContainer";
import { AttachmentReferenceEnum } from "@models/enumerations/attachment-reference-enum.model";
import { CommentReferenceEnum } from "@models/enumerations/comment-reference-enum.model";
import { getEntity } from "@store/slices/rfi";
import { getEntities as getDistributions } from "@store/slices/rfi-distribution";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Card, Col, Row, Skeleton, theme } from "antd";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BackHeader } from "./shared/BackHeader";
import { CloseRfi } from "./shared/CloseRfi";
import { Rfi } from "./shared/Rfi";

export const RfiView = () => {

    const { rfiId } = useParams<"rfiId">();

    const { entity, loading } = useAppSelector((state) => state.Rfi);
    const { entities: distributionsList } = useAppSelector((state) => state.RfiDistribution);

    const dispatch = useAppDispatch();

    const { token: { padding } } = theme.useToken();

    useEffect(() => {
        if (rfiId) {
            dispatch(getEntity(rfiId));
            dispatch(getDistributions(rfiId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rfiId])

    return (
      <TabContainer>
        <BackHeader />
        <Skeleton loading={loading} paragraph={{ rows: 10, style: { maxWidth: '648px' } }} active={true}>
          <Row style={{ paddingLeft: `${padding}px`, paddingRight: `${padding}px` }} className="pt-20 w-full">
            <Col flex="none" style={{ width: '100%', maxWidth: '648px' }}>
              <Card style={{ borderRadius: '4px' }} bodyStyle={{ paddingLeft: 10, paddingRight: 10 }}>
                <Rfi
                  id={entity?.id}
                  number={entity?.number}
                  categoryName={entity?.category?.name}
                  questionPayload={entity?.questionPayload}
                  scheduleImpact={entity?.scheduleImpact?.toString()}
                  costImpact={entity?.costImpact?.toString()}
                  dueDate={entity?.dueDate ? new Date(entity!.dueDate!) : undefined}
                  creatorUserId={entity?.createdById}
                  assignedTo={entity?.assignedTo}
                  following={distributionsList.map(distribution => distribution.user!)}
                  titleExtra={<CloseRfi status={entity?.status?.toString()} id={entity?.id} />}
                  addFollowersEnabled={true}
                />
              </Card>
              <Card className="mt-20" style={{ borderRadius: '4px' }} bodyStyle={{ paddingLeft: 10, paddingRight: 10, paddingTop: 0 }}>
                <Row className="mt-20">
                  <Col span={24}>
                    {rfiId && <CommentsList entityType={CommentReferenceEnum.RFI} referenceId={Number(rfiId)} title={'Activity'} />}
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col flex="auto" style={{ maxWidth: '550px' }} className="pl-20">
              {rfiId && <AttachmentsList entityType={AttachmentReferenceEnum.RFI} referenceId={Number(rfiId)} />}
            </Col>
          </Row>
        </Skeleton>
      </TabContainer>
    );
}