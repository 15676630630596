import React, { useState, useEffect } from 'react';
import { ConfigProvider, Table, theme } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import { AppDispatch, RootState, useAppSelector } from '@store/store';
import { getMyProjects } from '@store/slices/dashboard';
import { useNavigate } from 'react-router-dom';
import { OnRowEvent } from '@models/utils/table.utils.model';
import { EmptyCellTable } from '@components/Utils/EmptyCellTable';
import { useThemeProvider } from '@providers/ThemeProvider';
import { IMyProjects } from '@models/dashboard.model';
import type { ColumnsType, TableProps } from 'antd/es/table';
import '@styles/components/table.less';
import { dateSorter, defaultSorter } from '@shared/util/table-utils';
import { useTranslation } from 'react-i18next';
import { getEntities as getBrands } from '@store/slices/brand';

const TitleBrand = () => {
  const { t } = useTranslation();
  return <span>{`${t('location.brand')}`}</span>;
};

const TitleOpenDate = () => {
  const { t } = useTranslation();
  return <span>{`${t('location.openDate')}`}</span>;
};

const TitleProject = () => {
  const { t } = useTranslation();
  return <span>{`${t('generic.project')}`}</span>;
};

const TitleProjectStatus = () => {
  const { t } = useTranslation();
  return <span>{`${t('project.projectStatus')}`}</span>;
};

const LocationHashSymbollabel = () => {
  const { t } = useTranslation();
  return <span>{t('generic.location')}</span>;
};

const columns: ColumnsType<IMyProjects> = [
  {
    title: <LocationHashSymbollabel />,
    dataIndex: 'locationName',
    key: 'locationName',
    className: 'border-left-in-hover',
    ellipsis: true,
    sorter: (a, b) => defaultSorter(a?.locationName, b?.locationName),
    render: value => {
      return value || <EmptyCellTable />;
    },
  },
  {
    title: <TitleBrand />,
    dataIndex: 'brandDescription',
    key: 'brandDescription',
    ellipsis: true,
    sorter: (a, b) => defaultSorter(a?.brandDescription, b?.brandDescription),
    render: value => {
      return value || <EmptyCellTable />;
    },
  },
  {
    title: <TitleProject />,
    dataIndex: 'projectId',
    key: 'projectId',
    ellipsis: true,
    sorter: (a, b) => defaultSorter(a?.projectId, b?.projectId),
    render: (_, record: IMyProjects) => {
      return record?.projectNickname ? (
        `${record.projectNickname} (#${record.projectId})`
      ) : record?.projectId ? (
        `#${record.projectId}`
      ) : (
        <EmptyCellTable />
      );
    },
  },
  {
    title: <TitleProjectStatus />,
    dataIndex: 'projectStatusName',
    key: 'projectStatusName',
    ellipsis: true,
    sorter: (a, b) => defaultSorter(a?.projectStatusName, b?.projectStatusName),
    render: value => {
      return value || <EmptyCellTable />;
    },
  },
  {
    title: <TitleOpenDate />,
    dataIndex: 'openDate',
    key: 'openDate',
    ellipsis: true,
    sorter: (a, b) => (a?.openDate && b?.openDate ? dateSorter(a?.openDate, b?.openDate) : 0),
    render: value => {
      return value || <EmptyCellTable />;
    },
  },
];

export const MyProjectsTable: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { themeConfig } = useThemeProvider();
  const algorithmSelected = themeConfig?.darkMode ? [theme.compactAlgorithm, theme.darkAlgorithm] : theme.compactAlgorithm;
  const {
    data: {
      myProjects: { list, loading, totalItems },
    },
  } = useSelector((state: RootState) => state.Dashboard);

  const [tableParams, setTableParams] = useState({
    size: 20,
    current: 0,
  });

  //autohide columns
  const brands = useAppSelector(state => state.Brand.entities);
  useEffect(() => {
    if (brands.length === 0) {
      dispatch(getBrands({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [filteredColumns, setFilteredColumns] = useState<ColumnsType<IMyProjects>>([]);

  useEffect(() => {
    const tempColumns = brands.length > 1 ? columns : columns.filter(column => column.key !== 'brandDescription');
    setFilteredColumns(tempColumns);
  }, [brands, filteredColumns]);

  const filterLocations = () => {
    const { current, size } = tableParams;
    dispatch(
      getMyProjects({
        page: current,
        size: size,
        allStatuses: false,
      })
    );
  };

  useEffect(() => {
    filterLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableParams]);

  const onChange: TableProps<IMyProjects>['onChange'] = pagination => {
    setTableParams(oldValue => {
      return {
        ...oldValue,
        ...(pagination?.pageSize && { size: pagination.pageSize }),
        current: pagination.current !== undefined ? pagination.current - 1 : 0,
      };
    });
  };

  const onRowClick = (eventOnClick: OnRowEvent<IMyProjects>) => {
    const { event, record } = eventOnClick;
    const { locationId = '', siteId = '', projectId = '' } = record;

    const urlToNavigate = `/location/${locationId}/sites/${siteId}/project/${projectId}`;
    event?.metaKey ? window.open(`${urlToNavigate}`) : navigate(`${urlToNavigate}`);
  };

  const addUniqueKey = (list: IMyProjects[]) => {
    return list.map((item, index) => ({ ...item, id: item?.projectId || index }));
  };

  return (
    <div className="overflow-y-auto">
      <div>
        <ConfigProvider theme={{ token: { borderRadius: 0 }, algorithm: algorithmSelected }}>
          <Table
            loading={loading}
            rowKey="id"
            size="small"
            columns={filteredColumns}
            dataSource={addUniqueKey(list)}
            onChange={onChange}
            onRow={(record, rowIndex) => ({ onClick: event => onRowClick({ event, record, rowIndex }) })}
            className={list?.length > 0 ? 'app-table' : ''}
            rowClassName={'table-with-selector'}
            pagination={{ pageSize: tableParams.size, total: totalItems }}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};