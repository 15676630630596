

import { ICompanyContact } from '../../models/company.contact.model';
import { http } from '../http/axios.instance';
import { IQueryParams } from '@models/pagination';

const path = 'company-contacts-extended';

export const companyContactsExtendedRepository = {
  getAll: async (queryParams: IQueryParams) => {
    const { sort, page, size, query } = queryParams;

    const requestUrl = `${path}${size ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}${
      query ? `${query}&` : ''
    }cacheBuster=${new Date().getTime()}`;

    const promiseGetAll = await http.get<ICompanyContact[]>(requestUrl);
    return promiseGetAll;
  },
  create: async (entity: ICompanyContact): Promise<ICompanyContact> => {
    const requestUrl = `${path}`;
    const promiseCreate = await http.post<ICompanyContact>(requestUrl, entity);
    return promiseCreate.data;
  },
  get: async (id: string | number): Promise<ICompanyContact> => {
    const requestUrl = `${path}?id.equals=${id}`;
    const promiseCreate = await http.get<[ICompanyContact]>(requestUrl);
    return promiseCreate.data && promiseCreate.data[0] ? promiseCreate.data[0] : {};
  },
};